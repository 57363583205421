import React, {Component} from 'react';
import App from "../../App";
import {Button, ButtonGroup, Dropdown, DropdownButton, Form, Pagination} from "react-bootstrap";
import {FaPrint, FiSearch} from "react-icons/all";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Moment from "moment";

class NewUserReport extends Component {
  state = {
    startDate: null,
    endDate: null,
  }

  handleOnApply = (e, picker) => {
    const startDate = Moment(picker.startDate).format('Y-M-D');
    const endDate = Moment(picker.endDate).format('Y-M-D')
    this.setState({
      startDate: startDate,
      endDate: endDate,
    })
  }

  render() {
    return (
      <App>
        <div className="page-header">
          <h2 className="page-title">New User Report</h2>

          <div className="ml-auto">
            <DropdownButton
              className={"mr-3"}
              as={ButtonGroup}
              id={`fileExport`}
              variant={"default"}
              size={"sm"}
              title={'Export'}>
              <Dropdown.Item eventKey="1">Xcel</Dropdown.Item>
              <Dropdown.Item eventKey="2">PDF</Dropdown.Item>
              <Dropdown.Item eventKey="3">CSV</Dropdown.Item>
            </DropdownButton>

            <Button size={"sm"} variant={"default"} className="mb-0 mr-2"><FaPrint/> Print</Button>
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
            <Form className="d-flex v-middle">
              <div className="form-inline">
                <Form.Group controlId={"customerType"} className={"ml-3"}>
                  <Form.Label className={"mr-2"}>Filter By: </Form.Label>
                  <Form.Control size={"sm"} as={"select"}>
                    <option value="">--Select--</option>
                    <option value="1">Newest Join</option>
                    <option value="2">Most Order Amount</option>
                    <option value="3">Most Order Quantity</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group className={"ml-2"}>
                  <Form.Label className={"sr-only"}>Date: </Form.Label>
                  <DateRangePicker
                    onApply={this.handleOnApply}>
                    <input type="text"
                           value={this.state.startDate && this.state.endDate ? `${this.state.startDate} to ${this.state.endDate}` : ''}
                           onChange={() => {}}
                           style={{textAlign: 'left', minWidth: '150px'}}
                           placeholder={"Select date range"}
                           className="form-control form-control-sm"/>
                  </DateRangePicker>
                </Form.Group>
              </div>
              <div className={"ml-auto form-inline"}>
                <Form.Group controlId={"searchInput"}>
                  <Form.Label className="sr-only">Search</Form.Label>
                  <Form.Control type={"search"} size={"sm"} placeholder={"Search..."}/>
                </Form.Group>

                <Button size={"sm"} className={"ml-2"} variant={"primary"}><FiSearch/></Button>
              </div>
            </Form>

            <hr/>

            <table className="table table-striped">
              <thead>
              <tr>
                <th style={{width: '30px'}}>#</th>
                <th>User Name</th>
                <th>Phone</th>
                <th>Joining Date</th>
                <th>Order Amount (৳)</th>
                <th className="nowrap" style={{width: '1%'}}>Order Quantity</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>1</td>
                <td>John Doe</td>
                <td>+880123456789</td>
                <td>20 June, 2020</td>
                <td>6,000</td>
                <td className={"text-center"}>3</td>
              </tr>
              <tr>
                <td>2</td>
                <td>John Doe</td>
                <td>+880123456789</td>
                <td>20 June, 2020</td>
                <td>6,000</td>
                <td className={"text-center"}>3</td>
              </tr>
              <tr>
                <td>3</td>
                <td>John Doe</td>
                <td>+880123456789</td>
                <td>20 June, 2020</td>
                <td>6,000</td>
                <td className={"text-center"}>3</td>
              </tr>
              <tr>
                <td>4</td>
                <td>John Doe</td>
                <td>+880123456789</td>
                <td>20 June, 2020</td>
                <td>6,000</td>
                <td className={"text-center"}>3</td>
              </tr>
              <tr>
                <td>5</td>
                <td>John Doe</td>
                <td>+880123456789</td>
                <td>20 June, 2020</td>
                <td>6,000</td>
                <td className={"text-center"}>3</td>
              </tr>
              </tbody>
            </table>

            <div className="mt-3">
              <Pagination>
                <Pagination.First/>
                <Pagination.Prev/>
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Ellipsis/>
                <Pagination.Item>{10}</Pagination.Item>
                <Pagination.Item>{11}</Pagination.Item>
                <Pagination.Item active disabled>{12}</Pagination.Item>
                <Pagination.Item>{13}</Pagination.Item>
                <Pagination.Item>{14}</Pagination.Item>
                <Pagination.Ellipsis/>
                <Pagination.Item>{20}</Pagination.Item>
                <Pagination.Next/>
                <Pagination.Last/>
              </Pagination>
            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default NewUserReport;
