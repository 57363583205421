import {getDeviceType, getLocalAuthData} from "../helper/utils";
import axios from "axios";
import {API_BASE_URL} from "../helper/env";

export const updateShippingOption = (data, id) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.put(API_BASE_URL + `/shipping-option/${id}`, data, {
            headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject('Something went wrong')
        })
    })
}

export const addShippingOption = (data) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.post(API_BASE_URL + `/shipping-option`, data, {
            headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject('Something went wrong')
        })
    })
}

export const deleteShippingOption = (id) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.delete(API_BASE_URL + `/shipping-option/${id}`, {
            headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject('Something went wrong')
        })
    })
}

export const deleteShippingOptionPermanent = (id) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.delete(API_BASE_URL + `/shipping-option/${id}/permanent`, {
            headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject('Something went wrong')
        })
    })
}

export const shippingOptions = (params = {}) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + `/shipping-option`, {
            headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()},
            params: params
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject('Something went wrong')
        })
    })
}

export const getSingleShippingOption = (id) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + `/shipping-option/${id}`, {
            headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()},
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject('Something went wrong')
        })
    })
}