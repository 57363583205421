import React, {Component} from 'react';
import App from "../../App";
import {Button, ButtonGroup, Dropdown, DropdownButton, Form, Spinner} from "react-bootstrap";
import {FaFileExport, FaPrint, FiSearch} from "react-icons/all";
import Moment from "moment";

import Pagination from 'react-js-pagination';
import {withRouter} from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {fileDownload, getTableData} from "../../services/commonServices";

import moment from "moment";
import {useJsonToCsv} from "react-json-csv";
import {FaFilePdf} from "@react-icons/all-files/fa/FaFilePdf";
const {saveAsCsv} = useJsonToCsv();

class VoucherReport extends Component {
  state = {
    startDate: null,
    endDate: null,
    reportData: [],
    queryData: {
      limit: 20
    },
    pagination: {},
  }


  getData = (queryData) =>{
    getTableData('/reports/vouchers' ,queryData).then(res => {
      this.setState({
        reportData: res.data,
        pagination: res.paginate
      })
    })
  }

  componentDidMount() {
    this.getData(this.state.queryData)
  }

  handlePageChange = async (pageNumber) => {
    const history = this.props.history
    history.push(`${history.location.pathname}?page=${pageNumber}`)
    await this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber}, ()=>{
      this.getData(this.state.queryData);
    });
  }



  handleExport = async () =>{
    this.setState({export_loading: true})

    let queryData = {...this.state.queryData}
    queryData.export = 'xlsx'
    this.setState({queryData}, ()=>{
      fileDownload(`/reports/vouchers`, this.state.queryData).then((response)=>{
        console.log(response)
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'ekshop-vouchers-report.xlsx');
        document.body.appendChild(link);
        link.click();
        this.setState({export_loading: true})
      })
      queryData.export = null
      this.setState({
        queryData
      })
    })

    /*let queryData = "";
    // noinspection JSMismatchedCollectionQueryUpdate
    let mData = [];

    this.state.reportData.length > 0 && this.state.reportData.map((data,index) => {

      mData.push({
        'Index': index+1,
        'Voucher Type': data?.voucher_type,
        'Voucher Name': data?.total_voucher,
        'Total Voucher': data?.usage_limit,
        'Usage Limit': moment(data?.start_time).format("hh:mm a DD/MM/YYYY"),
        'Start Time': moment(data?.end_time).format("hh:mm a DD/MM/YYYY"),

        'End Time': data?.stores,
        'Stores': data?.product_skus,
        'Product Skus': data?.minimum_order_value,
        'Min Order Value': data?.maximum_discount_amount,
        'Maximum Order Discount': data?.discount_value,
        'Discount Value': data?.discount_value,
        'Discount Type': data?.discount_type,

      });

    });

    const filename = 'EkShop-Voucher Report',
        fields = {
          'Index': "Index",
          'Voucher Type': "Voucher Type",
          'Voucher Name': "Voucher Name",
          'Total Voucher': "Total Voucher",
          'Usage Limit': "Usage Limit",
          'Start Time': "Start Time",
          'End Time': "End Time",
          'Stores': "Stores",
          'Product Skus': "Product Skus",
          'Min Order Value': "Min Order Value",
          'Maximum Order Discount': "Maximum Order Discount",
          'Discount Value': "Discount Value",
          'Discount Type': "Discount Type",
        },
        style = {
          padding: "30px",
          maxHeight: "20px"
        },
        data = mData,
        text = "Convert Json to Csv";
    saveAsCsv({data, fields, filename});*/

  }


  handleOnChangeInput = (e) => {
    const queryData = {...this.state.queryData}
    queryData[e.target.name] = e.target.value;
    this.setState({queryData}, () => {
      console.log(this.state.formData)
      this.getData(this.state.queryData)
    })
  }


  handleOnApply = (e, picker) => {
    const queryData = {...this.state.queryData}
    const startDate = Moment(picker.startDate).format('DD/MM/YYYY hh:mm:ss')
    const endDate = Moment(picker.endDate).format('DD/MM/YYYY hh:mm:ss')
    queryData['from_date'] = startDate;
    queryData['to_date'] = endDate;

    this.setState({
      queryData
    }, () => {
      this.getData(this.state.queryData)
    })
  }

  render() {
    return (
      <App>
        <div className="page-header">
          <h2 className="page-title">Voucher Report</h2>

          <div className="ml-auto">
            <Button variant="primary"
                    size={`sm`}
                    onClick={() => this.handleExport('xlsx')}
                    disabled={this.state.export_loading}>
              {this.state.export_loading ?
                  <>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span>Loading...</span>
                  </>
                  : <><FaFilePdf/><span>Export</span></>}
            </Button>
            {/*<Button onClick={() => {
              this.handleExport()
            }} size={"sm"} variant={"default"} className="mb-0 mr-2"><FaFileExport/> Export</Button>*/}
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
            <Form className="d-flex v-middle">
              <div className="form-inline mr-2">
                <Form.Group className={'mr-2'}>
                  <Form.Label className={"sr-only"}>Date Range Picker</Form.Label>
                  <input
                      type="search"
                      id="orderNumber"
                      className='form-control form-control-sm'
                      placeholder="Search Key"
                      name="search_string"
                      onChange={this.handleOnChangeInput}
                  />
                </Form.Group>
              </div>
            </Form>

            <hr/>
            <div className="table-responsive">
            <table className="table table-striped">
              <thead>
              <tr>
                <th style={{width: '30px'}}>SL</th>
                <th>Voucher Type</th>
                <th>Voucher NAme</th>
                <th>Total Voucher</th>
                <th>Usage Limit</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Stores</th>
                <th>Product Skus</th>
                <th>Min Order Value</th>
                <th>Maximum Order Discount</th>
                <th>Discount Value</th>
                <th>Discount Type</th>

              </tr>
              </thead>
              <tbody>
              {this.state.reportData.length > 0 && this.state.reportData.map((data, i) => {
                return (
                    <tr>
                      <td>{i+1}</td>
                      <td>{data?.voucher_type}</td>
                      <td>{data?.voucher_name}</td>
                      <td>{data?.total_voucher}</td>
                      <td>{data?.usage_limit}</td>
                      <td>{moment(data?.start_time).format("hh:mm a DD/MM/YYYY")}</td>
                      <td>{moment(data?.end_time).format("hh:mm a DD/MM/YYYY")}</td>
                      <td>{data?.stores}</td>
                      <td>{data?.product_skus}</td>
                      <td>{data?.minimum_order_value}</td>

                      <td>{data?.maximum_discount_amount}</td>
                      <td>{data?.discount_value}</td>
                      <td>{data?.discount_type}</td>

                    </tr> )
              })}
              </tbody>
            </table>
            </div>
            <div className="mt-3">
              <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={this.state?.pagination?.page}
                  itemsCountPerPage={this.state?.pagination?.limit}
                  totalItemsCount={this.state?.pagination?.totalDocs ? this.state?.pagination?.totalDocs : 0}
                  pageRangeDisplayed={10}
                  onChange={this.handlePageChange.bind(this)}/>
            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(VoucherReport);
