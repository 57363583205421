import React, {Component} from 'react';
import App from "../../App";
import {FaLongArrowAltLeft, FaStore} from "react-icons/all";
import "../../assets/scss/invoice.scss";
// import logo from "../../assets/img/logo.png";
import {withRouter, Link} from "react-router-dom";
import {getOrderDetails, parcelStatusChange} from "../../services/orderServices";
import moment from "moment";
import TrackingInfo from "../../components/TrackingInfo";
import Barcode from "react-barcode";
import {toast} from "react-toastify";
import {Button, Form, Modal} from "react-bootstrap";
import {getLocalAuthData} from "../../helper/utils";

class OrderDetails extends Component {
  state = {
    orderDetail: null,
    isLoading: true,
    status: null,
    changingStoreId: null,
    changeStatusMsg: '',
    isShowingStatusChangeConfirmModal: false,
    isSubmitting: false,
    isDisputeResolvedModalShow:false
  }

  componentDidMount() {
    const orderId = this.props.match.params.id

    getOrderDetails(orderId)
      .then(res => {
        this.setState({orderDetail: res, isLoading: false})
      })
      .catch(errMsg => {
        console.error(errMsg)
        this.setState({isLoading: false})
      });
  }

  hideChangeStatusModal = () => {
    this.setState({isShowingStatusChangeConfirmModal: false})
  }

  renderPaymentStatus = (status) => {
    let payment_status = {
      0:"Unpaid",
      1:"Partially Paid",
      2:"Paid",
      3:"Cancelled",
      4:"Failed",
      5:"Refund",
      6:"Bank deposit payment in review",
      7:"Bank deposit declined",
    }
    return payment_status[status];
  }

  submitChangeStatusForm = (e) => {
    e.preventDefault()
    const orderId = this.props.match.params.id

    this.setState({isSubmitting: true}, () => {
      parcelStatusChange(this.state.changingStoreId, {
        status: this.state.status,
        message: this.state.changeStatusMsg
      })
        .then(res => {
          this.setState({
            isSubmitting: false,
            message: '',
            changingStoreId: null,
            isShowingStatusChangeConfirmModal: false
          }, () => {
            getOrderDetails(orderId)
              .then(res => {
                this.setState({orderDetail: res})
              })
              .catch(errMsg => {
                console.error(errMsg)
              })
          })
          toast.success(res.message)
        })
        .catch(errMsg => {
          toast.error(errMsg)
          this.setState({isSubmitting: false})
        })
    })
  }

  handleDisputeResolveInfoModalOpen = (id, item_info) => {
    console.log(item_info);
    this.setState({
      isDisputeResolvedModalShow: true,
      item_info: item_info,
      item_id: id
    }, () => {
      console.log(this.state);
    });
  }
  handleDisputeResolveInfoModalClose = () => {
    this.setState({isDisputeResolvedModalShow: false})
  }

  renderPromotionLabel = (type) => {
    if (type === '1') {
      return <span className={'promo-badge'}>Quantity</span>
    } else if (type === '2') {
      return <span className={'promo-badge'}>Buy 1 Get 1</span>
    } else if (type === '3') {
      return <span className={'promo-badge'}>Free Gift</span>
    } else if (type === '4') {
      return <span className={'promo-badge'}>Bundle Offer</span>
    }
  }

  render() {
    const {orderDetail} = this.state

    return (
      <App layout="boxed">
        <div className="main-content">

          {getLocalAuthData()?.permission?.includes('order_details') ?
          <>
          <div className="page-header">
            <h2 className="page-title">Order Details</h2>
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/orders/parcel/list`}
                    className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Order List</Link>
            </div>
          </div>
          <Modal show={this.state.isShowingStatusChangeConfirmModal} onHide={this.hideChangeStatusModal}>
            <Modal.Header closeButton>
              <Modal.Title>Change Status Message</Modal.Title>
            </Modal.Header>
            <Form onSubmit={this.submitChangeStatusForm}>
              <Modal.Body>
                <Form.Control as={`textarea`}
                              onChange={(e) => {
                                this.setState({changeStatusMsg: e.target.value})
                              }}
                              placeholder={`Enter you message here...`} rows={3}/>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="default" onClick={this.hideChangeStatusModal}>
                  Close
                </Button>
                <Button disabled={this.state.isSubmitting}
                        variant="primary" type={`submit`}>
                  {this.state.isSubmitting ? 'Submitting...': 'Submit'}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          {this.state.isLoading ?
            <div className={`card-block`}>
              <div className="block-body">
                Loading...
              </div>
            </div>
            :
            <div className="card-block">
              {/*<div className="block-header">
                  <h4 className="block-title">Invoice</h4>
                </div>*/}
              <Modal show={this.state.isDisputeResolvedModalShow} onHide={this.handleDisputeModalClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Product Dispute Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <span>Message: Dispute {this.state?.item_info?.product_dispute?.status}</span>

                  {this.state?.item_info?.product_dispute?.status === 'Resolved' &&
                  <>
                    <br/><span>Method: {this.state?.item_info?.disputeResolveInfo?.method}</span>
                    {this.state?.item_info?.disputeResolveInfo?.method === 'MFS' &&
                    <>
                      <br/><span><strong>Mobile Number:</strong> {this.state?.item_info?.disputeResolveInfo?.mobile_number}</span>
                      <br/><span><strong>Operator Info:</strong> {this.state?.item_info?.disputeResolveInfo?.operator_name}</span>
                      <br/><span><strong>Account Type:</strong> {this.state?.item_info?.disputeResolveInfo?.account_type}</span>
                    </>
                    }
                    <br/><span>Note: {this.state?.item_info?.disputeResolveInfo?.note}</span>
                    <br/><span>Refund Amount: {this.state?.item_info?.refund_amount}</span>
                  </>
                  }
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="default" onClick={this.handleDisputeResolveInfoModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>

              <div className="block-body">
                <table className="invoice-table">
                  <tbody>
                  {/*<tr className="v-top">
                      <td>
                        <img src={logo} alt="" className="brand-logo"/>
                      </td>
                      <td className="text-right">
                        <span className="d-block">8048 Edgemont St. Hudson, NH 03051</span>
                        <span className="d-block">+8801234567898</span>
                        <span className="d-block">example@ekshop.com</span>
                        <span className="d-block">http://google.com</span>
                      </td>
                    </tr>*/}

                  <tr>
                    <td colSpan="2">
                      <h3 className="invoice-title">{orderDetail?.order_code}</h3>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="2">
                      <table>
                        <tbody>
                        <tr>
                          <td style={{paddingBottom: '20px'}}>
                            <h4 className="invoice-title-alt">Bill To</h4>
                            <span className="d-block">{orderDetail?.delivery_address?.recipient_name}</span>
                            <span className="d-block">{orderDetail?.delivery_address?.recipient_phone}</span>
                            <span className="d-block">{orderDetail?.delivery_address?.address}</span>
                          </td>
                          <td style={{paddingBottom: '20px'}}>
                            <h4 className="invoice-title-alt">Ship To</h4>
                            <span className="d-block">{orderDetail?.delivery_address?.recipient_name}</span>
                            <span className="d-block">{orderDetail?.delivery_address?.recipient_phone}</span>
                            <span className="d-block">{orderDetail?.delivery_address?.address}</span>
                          </td>

                          <td style={{paddingBottom: '20px'}}>
                            <h4 className="invoice-title-alt">Payment Method</h4>
                            <span className="d-block">{orderDetail?.payment_method?.name}</span>
                          </td>
                          <td style={{paddingBottom: '20px'}}>
                            <h4 className="invoice-title-alt">Payment Status</h4>
                            <span className="d-block">{this.renderPaymentStatus(orderDetail?.payment_status)}</span>
                          </td>

                          <td style={{paddingBottom: '20px'}}>
                            <span className="d-block mb-2">
                              <strong>Created Date:</strong> {moment(orderDetail?.createdAt).format('ll')}
                            </span>
                            <span className="d-block">
                                <Barcode width={1} value={orderDetail?.order_code} height={50} margin={0}/>
                            </span>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={2}>
                      <table className="table table-bordered">
                        <thead>
                        <tr>
                          <th>Item</th>
                          <th className={'text-center'}>Quantity</th>
                          <th className="text-right">Amount (৳)</th>
                        </tr>
                        </thead>

                        <tbody>
                        {orderDetail?.order_parcels?.map((store, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td colSpan={4}>
                                <div className="d-flex">
                                  <div className="">
                                    <span className="store-name">
                                      <span className="icon"><FaStore/> </span> <span className="text">{store.store_name}</span>
                                    </span>
                                    <span className={`badge ml-4 badge-info`}>{store?.status}</span>
                                  </div>

                                  <div className="ml-auto">
                                    {/*<select size={`sm`}
                                            className={`simple-form-control`}
                                            onChange={(e) => {
                                              this.setState({
                                                status: e.target.value,
                                                changingStoreId: store._id,
                                                isShowingStatusChangeConfirmModal: true
                                              })
                                            }}

                                            defaultValue={store?.status}>
                                      <option value="Pending">Pending</option>
                                      <option value="Confirmed">Confirmed</option>
                                      <option value="Processing">Processing</option>
                                      <option value="Picked">Picked</option>
                                      <option value="Shipped">Shipped</option>
                                      <option value="Delivered">Delivered</option>
                                      <option value="Cancelled">Cancelled</option>
                                    </select>*/}
                                  </div>
                                </div>
                              </td>
                            </tr>

                            {store.order_items.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <span className="d-block"><strong>
                                    {(item?.product_info?.[0]) ? <a href={`${process.env.PUBLIC_URL}/products/details/${item?.product_info[0]?._id}`} target={`_blank`}>
                                      {item?.product_name} {item?.bundle_offer && this.renderPromotionLabel(item?.bundle_offer?.promotion_type)}
                                    </a>: <><span>{item?.product_name} {item?.bundle_offer && this.renderPromotionLabel(item?.bundle_offer?.promotion_type)}</span></>}
                                  </strong></span>
                                  <span className="d-block text-muted">{item?.product_sku_attr_combo?.join('-')}</span>

                                  {/*START DISPUTE STATUS*/}
                                  {item?.product_dispute?.status === 'Resolved' &&
                                  <>
                                    <span className="d-block text-muted">Refund Amount: {item?.refund_amount?item?.refund_amount:0}</span>
                                    <span className="d-block text-muted">Note: {item?.disputeResolveInfo?.note}</span>
                                    <a href={'#'} onClick={() => this.handleDisputeResolveInfoModalOpen(item._id, item)} size={"sm"} variant={true}>
                                      Dispute Info
                                    </a>
                                  </>
                                  }
                                  {item?.product_dispute?.status === 'Pending' &&
                                  <>
                                    <span className="d-block text-muted">Dispute Status: {item?.product_dispute?.status}</span>
                                  </>
                                  }
                                  {/*END DISPUTE STATUS*/}

                                </td>
                                <td className={`text-center`}>{item.quantity}</td>
                                <td className="text-right">৳{item?.item_total_price?.toFixed(2)}</td>
                              </tr>
                            ))}

                            <tr>
                              <TrackingInfo
                                timelineData={store.order_status_progress}
                                activityData={store.order_activity_tracks}/>
                            </tr>
                          </React.Fragment>
                        ))}

                        <tr className="text-bold">
                          <td colSpan={2} className="text-right">Subtotal</td>
                          <td className="text-right">৳{orderDetail?.total_amount?.toFixed(2)}</td>
                        </tr>

                        <tr className="text-bold">
                          <td colSpan={2} className="text-right">Discount</td>
                          <td className="text-right">৳{orderDetail?.discount_amount?.toFixed(2)}</td>
                        </tr>

                        {orderDetail?.voucher_code_discount && <tr className="text-bold">
                          <td colSpan={2} className="text-right">Ekshop Discount</td>
                          <td className="text-right">৳{(orderDetail?.voucher_code_discount ? orderDetail?.voucher_code_discount : 0.00).toFixed(2)}</td>
                        </tr>}

                        {(orderDetail?.wallet_amount > 0) && <tr className="text-bold">
                          <td colSpan={2} className="text-right">Wallet Amount</td>
                          <td className="text-right">৳{orderDetail?.wallet_amount.toFixed(2)}</td>
                        </tr>}

                        <tr className="text-bold">
                          <td colSpan={2} className="text-right">Grand Total</td>
                          <td className="text-right">৳{orderDetail?.grand_total?.toFixed(2)}</td>
                        </tr>
                        {(orderDetail?.order_parcels?.refund_amount > 0) &&
                        <tr className="text-bold">
                          <td colSpan={2} className="text-right">Refund Amount</td>
                          <td className="text-right">৳{orderDetail?.order_parcels?.refund_amount.toFixed(2)}</td>
                        </tr>}
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  {/*<tr>
                      <td colSpan={2} style={{fontSize: '.875rem', lineHeight: 1.2}}>
                        <p className="text-muted mb-1">* Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                        <p className="text-muted">* Lorem ipsum dolor sit amet, consectetur adipisicing elit. A,
                          officia!</p>
                      </td>
                    </tr>*/}
                  </tbody>
                </table>
              </div>
            </div>
          }
          </> :null}
        </div>
      </App>
    );
  }
}

export default withRouter(OrderDetails);
