import React, {Component} from 'react';
import App from "../../App";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Moment from "moment";
import {Button, Dropdown, DropdownButton, Form, FormControl, InputGroup, Modal} from "react-bootstrap";
import {getDeviceType, getLocalAuthData} from "../../helper/utils";
import axios from "axios";
import {API_BASE_URL} from "../../helper/env";
import {getChildLocations} from "../../services/locationServices";
import {getMerchantDetails, getMerchantList, getMerchantTypeList, getMerchantPaymentMethods} from "../../services/merchantServices";
import {getStoreDetails, getStoreList} from "../../services/storeServices";
import Select from "react-select";
import styleReactSelect from "../../assets/styleReactSelect";
import {addProduct, getProductListAlt} from "../../services/productServices";
import {getCommissionList, getCommissionSummaryList, disburseCommission} from "../../services/commissionServices";
import {toast} from "react-toastify";
import {Link} from 'react-router-dom';
import {getTableData, saveData} from "../../services/commonServices";

const today = Moment(new Date()).format('M/D/Y');

class SellerPayment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            seller_payment_summary: null,
            paymentHistories: [],
            pagination: null,
            user_info: null,

            merchant_types: [],
            merchantList: [],
            StoreList: [],
            merchant_type: '',
            store_id: '',
            queryData: {
                from_date: '',
                to_date: '',
                merchant_type: '',
                store_id: '',
            },
            activePage: 1,
            isLoading: true,
            locations: [],
            businessChildLocations: [],
            changingStoreId: null,
            isSubmitting: false,

            startDate: today,
            endDate: today,
            disburseModal: false,

            merchant_payment_methods: [],
            merchant_info: {},
            merchant_payment_summary: {},

            disburseFormData: {
                user_type: 'merchant',
                user_id: null,
                store_id: null,
                payment_method_id: null,
                transaction_amount: null,
                transaction_number: '',
                transaction_note: '',
            }
        }
    }

    componentDidMount() {
        const authToken = getLocalAuthData();
        const headers = {
            'Content-Type': 'application/json',
            'x-api-client': 'web',
            'x-auth-token': authToken.token,
        }

        getMerchantTypeList().then(res => {
            console.log("merchant Type fetching");
            this.setState({...this.state, merchant_types: res})
        }).catch(err => {
            console.log(err.message)
        });

        // getMerchantList({lite_data: 1}).then(res => {
        //     this.setState({...this.state, merchantList: res, isLoading: false})
        // }).catch(err => {
        //     console.log(err.message)
        // });

        getStoreList({lite_data: 1}).then(res => {
            console.log("store Type fetching");
            this.setState({...this.state, storeList: res.items})
            console.log(this.state.storeList);
        }).catch(err => {
            console.log(err.message)
        });

    }

    renderMerchantType = () => {
        const merchantTypes = this.state.merchant_types;
        if (merchantTypes) {
            return (
                <>
                    {merchantTypes.map((items, index) =>
                        <option key={index} value={`${items._id}`}>{items.type_name}</option>
                    )}
                </>
            )
        }
    };

    renderStoreList = () => {
        const storeList = this.state.storeList;
        if (storeList.length > 0) {
            return (
                storeList.map((items, index) =>
                    <option key={index} value={`${items._id}`}>{items.store_name}</option>
                )
            )
        }
    };

    handleSearchFormDataChange = (e) => {
        const fieldName = e.target.name;
        const value = e.target.value;

        if (fieldName === "merchant_type") {
            this.setState({...this.state, merchant_type: value})
            getStoreList({merchant_type: value, lite_data: 1}).then((res) => {
                this.setState({storeList: res.items, merchant_type: value})
                // this.renderStoreList()
            }).catch(function (e) {
                console.log(e);
            });
        } else if (fieldName === 'store_id') {
            this.setState({...this.state, store_id: value})
        }
    };

    handleOnApply = () => {
        let params = {merchant_type: this.state.merchant_type, store_id: this.state.store_id, user_type: 'merchant'}
        getTableData(`/seller-payment/summary`, params).then(res => {
            this.setState({
                paymentHistories: res.items,
                pagination: res.pagination,
                isLoading: false,
                seller_payment_summary: (res?.seller_payment_summary !== undefined) ? res.seller_payment_summary : null,
                user_info: (res?.user_info !== undefined) ? res.user_info : null
            })
        }).catch(function (e) {
            console.log(e.message);
        });
    }

    handleCloseDisburseModal = () => {
        this.setState({
            disburseModal: false
        })
    }

    handleShowDisburseModal = (user_id, store_id) => {

        if ((this.state.user_id != undefined && user_id != this.state.user_id) || (this.state.store_id != undefined && store_id != this.state.store_id)) {

            this.setState({...this.state, user_id: user_id, store_id: store_id});

            getMerchantPaymentMethods({user_id: user_id, status: 'active'}).then((res) => {
                this.setState({merchant_payment_methods: res?.items, disburseModal: true});
            }).catch(errMsg => {
                toast.error(errMsg)
            });

            let params = {user_id: user_id, store_id: store_id}
            getTableData(`/seller-payment/summary`, params).then(res => {
                this.setState({...this.state, merchant_payment_summary: res?.seller_payment_summary, user_info: res?.user_info});
            }).catch(errMsg => {
                toast.error(errMsg)
            });
        }
        this.setState({disburseModal: true});

    }


    handleInputOnChange = (e) => {
        const disburseFormData = {...this.state.disburseFormData};
        disburseFormData[e.target.name] = e.target.value
        this.setState({disburseFormData});
        console.log(this.state.disburseFormData);
    }

    submitDisburseForm = (e) => {
        e.preventDefault()

        this.setState({isSubmitting: true}, () => {
            const disburseData = {...this.state.disburseFormData}
            disburseData['user_id'] = this.state.user_id;
            disburseData['store_id'] = this.state.store_id;
            saveData(`/seller-payment/disburse`, disburseData).then(res => {
                // this.props.history.push(`${process.env.PUBLIC_URL}/products/list`);
                toast.success(res.message);
                if (res.status === 1) {
                    this.setState({isSubmitting: false, disburseModal: false})
                } else {
                    this.setState({isSubmitting: false})
                }
            }).catch(errMsg => {
                toast.error(errMsg);
                this.setState({isSubmitting: false})
            });
        })
    };

    renderPaymentMethods = () => {
        const payment_methods = this.state.payment_methods;
        if (payment_methods.length > 0) {
            return (
                payment_methods.map((item, index) =>
                    <option key={index} value={`${item._id}`}>{item.bank_name - (item.bank_account_number)}</option>
                )
            )
        }
    };

    render() {
        return (
            <App layout="">
                <div className="main-content">
                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">Seller Payment</h4>
                        </div>

                        {getLocalAuthData()?.permission?.includes('buyer_commission_list') ?
                            <div className="block-body">
                                <div className="d-flex">
                                    <div className="form-inline">
                                        {this.state.merchant_types?.length > 0 &&
                                        <div className="form-group mr-3 mt-2">
                                            <label htmlFor="barndName" className="sr-only">Merchant Type</label>
                                            <select id="merchantType" name="merchant_type"
                                                    onChange={this.handleSearchFormDataChange}
                                                    className='form-control form-control-sm'>
                                                <option value="">--Select Type--</option>
                                                {this.renderMerchantType()}
                                            </select>
                                        </div>}

                                        {this.state.storeList?.length > 0 &&
                                        <div className="form-group mr-3 mt-2">
                                            <label htmlFor="barndName" className="sr-only">Store</label>
                                            <select
                                                id="store" name="store_id"
                                                onChange={this.handleSearchFormDataChange}
                                                className='form-control form-control-sm'>
                                                <option value="">--Select Store--</option>
                                                {this.renderStoreList()}
                                            </select>
                                        </div>}

                                        <Button size={"sm"} variant={"primary"} onClick={this.handleOnApply}>Generate</Button>
                                    </div>

                                    {/*<div className="ml-auto">
                                  <DropdownButton variant={"default"} size={"sm"} id="dropdown-basic-button" title="Export">
                                    <Dropdown.Item href="#/action-1">CSV</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">EXCEL</Dropdown.Item>
                                  </DropdownButton>
                                </div>*/}
                                </div>

                                <div className="commission-info-cards mt-4">
                                    <div className="commission-info">
                                        <span className="title">Seller Total Transactions</span>
                                        <span className="value">৳ {this.state.seller_payment_summary?.total_transaction_amount ? this.state.seller_payment_summary?.total_transaction_amount.toFixed(2) : 0.00}</span>
                                    </div>
                                    <div className="commission-info">
                                        <span className="title">Seller Total Earn</span>
                                        <span className="value">৳ {this.state.seller_payment_summary?.total_seller_earn ? this.state.seller_payment_summary?.total_seller_earn.toFixed(2) : 0.00}</span>
                                    </div>
                                    <div className="commission-info">
                                        <span className="title">Seller Total Paid</span>
                                        <span className="value">৳ {this.state.seller_payment_summary?.total_seller_paid ? this.state.seller_payment_summary?.total_seller_paid.toFixed(2) : 0.00}</span>
                                    </div>
                                    <div className="commission-info">
                                        <span className="title">Seller Total Due</span>
                                        <span className="value">৳ {this.state.seller_payment_summary?.total_seller_earn ? ((Number)(this.state?.seller_payment_summary?.total_seller_earn) - (Number)(this.state?.seller_payment_summary?.total_seller_paid)).toFixed(2) : 0.00}</span>
                                    </div>
                                </div>

                                {this.state.paymentHistories?.length > 0 ?
                                    <>
                                        <Modal size={"lg"} show={this.state.disburseModal} onHide={this.handleCloseDisburseModal}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Seller Payment Disbursement</Modal.Title>
                                            </Modal.Header>

                                            <Form onSubmit={this.submitDisburseForm}>
                                                <Modal.Body>
                                                    <div className="commission-info-cards mt-4">
                                                        <div className="commission-info">
                                                            <span className="title">Total Transactions</span>
                                                            <span className="value">{this.state?.merchant_payment_summary?.total_transaction_amount}</span>
                                                        </div>
                                                        <div className="commission-info">
                                                            <span className="title">Total Seller Payment</span>
                                                            <span className="value">৳ {this.state?.merchant_payment_summary?.total_seller_earn}</span>
                                                        </div>
                                                        <div className="commission-info">
                                                            <span className="title">Total Paid</span>
                                                            <span className="value">৳ {this.state?.merchant_payment_summary?.total_seller_paid}</span>
                                                        </div>
                                                        <div className="commission-info">
                                                            <span className="title">Total Due</span>
                                                            <span className="value">৳ {((Number)(this.state?.merchant_payment_summary?.total_seller_earn) - (Number)(this.state?.merchant_payment_summary?.total_seller_paid))}</span>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div className="card-block">
                                                        <div className="block-header">
                                                            <h4 className="block-title">Transaction Methods</h4>
                                                        </div>

                                                        <div className="block-body">
                                                            {
                                                                this.state?.merchant_payment_methods.length > 0 ?
                                                                    this.state.merchant_payment_methods.map((item, index) => (

                                                                        <div className="brand-col" key={'payment-options'}>
                                                                            <div className="brand">

                                                                                <div className="brand-info">

                                                                                    <Form.Check
                                                                                        custom inline required
                                                                                        type="radio" name="payment_method_id"
                                                                                        id={`transaction-method-${index}`}
                                                                                        onChange={this.handleInputOnChange}
                                                                                        value={item?._id}
                                                                                        label={(item?.bank_account_number ? item?.bank_account_number : item?.mobile_number) + " - " + item?.method_type}/>

                                                                                    <br/>
                                                                                    {/* BANK SERVICE*/}
                                                                                    {
                                                                                        item?.method_type === 'Bank' ? <>
                                                                                            <span className="order-date">{item?.bank_name}</span><br/>
                                                                                            <span className="order-date">{item?.bank_account_name}</span><br/>
                                                                                            <span className="order-date">{item?.bank_account_number}</span>
                                                                                        </> : ''
                                                                                    }


                                                                                    {/* MOBILE FINANCE SERVICE */}
                                                                                    {
                                                                                        item?.method_type === 'Mobile' ? <>
                                                                                            <span className="order-date">{item?.mobile_number}</span><br/>
                                                                                            <span className="order-date">{item?.operator_name}</span><br/>
                                                                                            <span className="order-date">{item?.account_type}</span>
                                                                                        </> : ''
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )) :
                                                                    <div className={`alert alert-warning text-center text-bold`}>
                                                                        User Transaction Method not found
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>

                                                    <table className="info-table table-lg">
                                                        <tbody>
                                                        <tr>
                                                            <td className="text-bold">Transaction Amount: <span className="text-danger">*</span></td>
                                                            <td className="text-bold">:</td>
                                                            <td className="text-bold"><Form.Control size={"sm"} min={1} type={"number"} name='transaction_amount' onChange={this.handleInputOnChange}/></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="text-bold">Transaction Number: <span className="text-danger">*</span></td>
                                                            <td className="text-bold">:</td>
                                                            <td className="text-bold"><Form.Control size={"sm"} name='transaction_number' onChange={this.handleInputOnChange}/></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="text-bold">Note</td>
                                                            <td className="text-bold">:</td>
                                                            <td className="text-bold"><Form.Control as={"textarea"} rows={3} name='transaction_note' onChange={this.handleInputOnChange}/></td>
                                                        </tr>

                                                        </tbody>
                                                    </table>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="default" onClick={this.handleCloseDisburseModal}>
                                                        Close
                                                    </Button>
                                                    <Button variant="primary" type={`submit`} disabled={this.state.isSubmitting}>
                                                        {this.state.isSubmitting ? 'Please Wait...' : 'Submit'}
                                                    </Button>
                                                </Modal.Footer>
                                            </Form>
                                        </Modal>

                                        <div className="mt-4">
                                            <table className="order-table table table-striped">
                                                <thead>
                                                <tr>
                                                    <th style={{width: '1%'}}>Sl</th>
                                                    <th>Merchant Info</th>
                                                    <th>Store Info</th>
                                                    <th>Total Transaction (৳)</th>
                                                    <th>Product Price (৳)</th>
                                                    <th>Admin Commission (৳)</th>
                                                    <th>Merchant Commission (৳)</th>
                                                    <th>Admin Discount (৳)</th>
                                                    <th>Seller Discount (৳)</th>
                                                    <th>Seller Earn (৳)</th>
                                                    <th>Wallet Payment(৳)</th>
                                                    <th>ekShop Receive(৳)</th>
                                                    <th>Disbursed amount(৳)</th>
                                                    <th>Due amount(৳)</th>
                                                    <th style={{width: '11%'}}>Action</th>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                {this.state.paymentHistories.length > 0 ?
                                                    this.state.paymentHistories.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{this.state.pagination.pagingCounter + index}</td>
                                                            <td>
                                                                <div className="order-item">
                                                                    <h4 className="order-id">{item?.user_info?.name}</h4>
                                                                    <span className="order-date">{item?.user_info?.phone}</span>
                                                                    <span className="order-date">{item?.user_info?.email}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="order-item">
                                                                    <h4 className="order-id">{item?.store_info?.store_name}</h4>
                                                                </div>
                                                            </td>
                                                            <td>{item?.total_transaction_amount?.toFixed(2)}</td>
                                                            <td>{item?.product_price?.toFixed(2)}</td>
                                                            <td>{item?.admin_commission?.toFixed(2)}</td>
                                                            <td>{item?.merchant_commission?.toFixed(2)}</td>
                                                            <td>{item?.admin_discount?.toFixed(2)}</td>
                                                            <td>{item?.merchant_discount?.toFixed(2)}</td>
                                                            <td>
                                                                <span style={{color: "Green"}}>
                                                                    <strong>{item?.seller_earn?.toFixed(2)}</strong>
                                                                </span>
                                                            </td>
                                                            <td>{item?.wallet_payment?.toFixed(2)}</td>
                                                            <td>{item?.ekshop_received?.toFixed(2)}</td>
                                                            <td><span style={{color: "orange"}}><strong>{item?.seller_paid?.toFixed(2)}</strong></span></td>
                                                            <td>
                                                                <span style={{color: "Red"}}>
                                                                    <strong>{((Number)(item?.seller_earn) - (Number)(item?.seller_paid)).toFixed(2)}</strong>
                                                                </span>
                                                            </td>
                                                            <td style={{width: '11%'}}>
                                                                <Button className={'w-100'} onClick={() => this.handleShowDisburseModal(item?.user, item?.store)}
                                                                        size={"sm"} variant="outline-secondary">Disburse</Button>
                                                                {/* <Link className={'w-100 my-1 btn btn-sm btn-outline-info'} to={'/finance/buyer-commission-transaction'} */}
                                                                <Link className={'w-100 my-1 btn btn-sm btn-outline-info'} to={`/seller-payment-history/${item?.store_info?.merchant}`}
                                                                      size={"sm"}>Transaction History</Link>

                                                                {/*<Link className={'w-100 btn btn-sm btn-outline-primary'} to={`/finance/buyer-commission-order/${item?._id}`}
                                                                    size={"sm"} variant="outline-primary">Orders</Link>*/}
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <tr>
                                                        <td colSpan={6} className={'text-center text-bold'}>Please select your required store for commission overview</td>
                                                    </tr>
                                                }

                                                </tbody>
                                            </table>
                                        </div>

                                    </>
                                    :
                                    <div className={`alert alert-warning text-center text-bold mt-4`}>
                                        Please select your required store for commission overview
                                    </div>
                                }
                            </div>
                            : null}
                    </div>
                </div>

            </App>
        );
    }
}

export default SellerPayment;
