import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {FaLongArrowAltLeft, IoMdCheckmark} from "react-icons/all";
import {Multiselect} from "react-widgets";
import Moment from "moment";
import {toast} from "react-toastify";
import {submitCampaign} from "../../services/promotionServices";
import DatePicker from "react-datepicker";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Pagination from "react-js-pagination";
import {getProductListSecondary} from "../../services/productServices";
import {getStoreList} from "../../services/storeServices";
import BrowseFiles from "../../components/BrowseFiles";
import {getLocalAuthData} from "../../helper/utils";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {UploadAdapter} from "../../adapters/ckUploadAdapter";
import CKEditor from "@ckeditor/ckeditor5-react";
import Select from "react-select";
import styleReactSelect from "../../assets/styleReactSelect";
import {getMerchantTypeList} from "../../services/merchantServices";
import moment from "moment";
const today = Moment(new Date()).format('Y-M-D');


class AddCampaignOffer extends Component {


    state = {
        formData: {
            campaign_title: '',
            campaign_details: '',
            start_date: moment(new Date("12:00 AM "+today)).format("hh:mm a Y-M-D"),
            end_date: moment(new Date("11:59 PM "+today)).format("hh:mm a Y-M-D"),
            registration_last_date: new Date(),
            terms_and_conditions: "",
            campaign_banner: [{url: '#', img: null, mobile_img: null}],
            campaign_cover_image: "",
            stores: [],
            campaign_type: "public" // public or private
        },
        start_date: moment(new Date("12:00 AM "+today)).format("hh:mm a Y-M-D"),
        end_date: moment(new Date("11:59 PM "+today)).format("hh:mm a Y-M-D"),
        isFormValidated: false,
        isSubmitting: false,
        merchants: [],
        merchantList: [],
        categoryList: [],
        products: [],
        merchantTypes: [],
        selectedProductList: [],
        isLoading: true,
        selectedStores: [],
        isStoreModalStoreShowing: false,
        defaultQuerySettings: {
            merchantList: {},
            storeList: {status: 1, approval_status: 1, lite_data: 1, limit: 20},
            productList: {limit: 20}
        },
        selectedStoreList: [],
        isStoreLoading: false,
        selectAll: true,
    };

    componentDidMount() {

        /*const formData = {...this.state.formData}
        formData.registration_last_date = moment().format("hh:mm a DD/MM/YYYY")
        this.setState(formData)*/

        getMerchantTypeList().then(res => {
            const merchantTypes = []
            res.forEach(item => {
                merchantTypes.push({id: item._id, label: item.type_name, value: item._id})
            });
            this.setState({merchant_types: merchantTypes})
        }).then(errMsg => {
            console.log('Error Message: ', errMsg)
        })


        /*getStoreList(this.state.defaultQuerySettings.storeList).then(res => {
            const stores = []
            res.items.forEach(item => {
                stores.push({label: item.store_name, value: item._id})
            })
            this.setState({storeList: stores})
        })*/
    }

    handleInputOnChange = (e) => {
        const formData = {...this.state.formData}
        formData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({formData}, () => {
            console.log(this.state.formData)
        })
    }

    handleOnApply = (e, picker) => {
        const formData = {...this.state.formData};
        const start_date = moment(picker.start_date).format('hh:mm a Y-M-D');
        const end_date = moment(picker.end_date).format('hh:mm a Y-M-D')

        formData.start_date = start_date;
        formData.end_date = end_date;

        this.setState({
            formData,
            start_date: start_date,
            end_date: end_date,
        }, () => {
            console.log(this.state.formData)
        })
    }

    handleSubmitForm = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        const formData = {...this.state.formData};
        console.log(formData)

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({isFormValidated: true})
        } else {
            submitCampaign(this.state.formData).then(res => {
                if (res.status === 2) {
                    toast.warning(res.message);
                } else {
                    toast.success(res.message);
                    this.props.history.push(`/promotions/campaign`);
                }
            }).catch(errMsg => {
                toast.error(errMsg)
            })

            this.setState({isSubmitting: true});

            setTimeout(() => {
                this.setState({isSubmitting: false, isFormValidated: false})
            }, 2000);
        }
    }

    /* HANDLE STORE */


    handleSearchStore = (val) => {
        getStoreList({
            ...this.state.defaultQuerySettings.storeList,
            search_string: val
        }).then(res => {
            this.setState({
                stores: res.stores,
                storePagination: res.pagination
            }, () => console.log(this.state));
        })
    }

    handleRemoveSelectedStore = (index) => {
        const selectedStoreList = [...this.state.selectedStoreList]
        const formData = {...this.state.formData}
        selectedStoreList.splice(index, 1);
        // formData.stores.splice(index, 1);
        this.setState({selectedStoreList, formData})
    }

    handleHideStoreModal = () => {
        this.setState({isStoreModalStoreShowing: false})
    }

    handleSelectedStoreOnChange = (e, item) => {
        console.log(item)
        const formData = {...this.state.formData};
        const selectedStoreList = [...this.state.selectedStoreList];

        if (e.target.checked) {
            if (e.target.type === 'checkbox') {
                selectedStoreList.push(item);
                formData.stores.push(item._id);
            } else {
                selectedStoreList[0] = item;
                formData.stores[0] = item;
            }
        } else {
            selectedStoreList.splice(formData.stores.indexOf(item._id), 1);
            formData.stores.splice(formData.stores.indexOf(item._id), 1);
        }

        this.setState({selectedStoreList, formData}, () => {
            console.log(this.state.formData)
        });
    }

    isStoreIncluded = (id, selectedStoreObj) => {
        const storeArr = selectedStoreObj.filter((obj) => {
            return obj._id === id
        })
        return storeArr.length > 0
    }

    handleShowSelectStoreModal = () => {
        this.setState({isStoreLoading: true}, () => {
            getStoreList({
                limit: 20,
                search_string: ""
            }).then(res => {
                this.setState({
                    stores: res.items,
                    storePagination: res.pagination,
                    isStoreModalStoreShowing: true,
                    isStoreLoading: false
                })
            }).catch(err => {
                this.setState({isStoreLoading: false})
            })
        })

    }

    handleAddStore = (selectedStores) => {
        console.log(selectedStores)
        const formData = {...this.state.formData}
        const stores = []

        selectedStores.forEach(item => {
            const store = this.state.stores.filter(obj => {
                return obj._id === item
            })
            stores.push(store[0])
        })

        formData.stores = selectedStores
        this.setState({
            selectedStoreList: stores,
            formData,
            isStoreModalStoreShowing: false,
            //selectedStores: []
        })
    }

    handleImageOnBrowse = (images, type = '') => {
        const formData = {...this.state.formData}
        if (type === 'cover_image') {
            formData.campaign_cover_image = images[0]?.actual_img
        } else if (type === 'mobile_img') {
            console.log(images[0]);
            formData.campaign_banner[0].mobile_img = images[0]?.actual_img
        } else {
            console.log(images[0]);
            formData.campaign_banner[0].img = images[0]?.actual_img
        }
        this.setState({formData}, () => {
            console.log(formData);
        })
    }

    handleOnRemoveItem = (type = '') => {
        const formData = {...this.state.formData}
        if (type === 'cover_image') {
            formData.campaign_cover_image = null
        }else if (type === 'mobile_img') {
            formData.campaign_banner[0].mobile_img = null
        } else {
            formData.campaign_banner[0].img = null
        }
        this.setState({formData})
    }

    handleOnStoreSearch = (e) => {
        const self = this
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout)
        }
        this.setState({
            storeKeyword: e.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
                getStoreList({
                    limit: 20,
                    search_string: self.state.storeKeyword
                }).then(res => {
                    self.setState({
                        stores: res.items,
                        storePagination: res.pagination,
                        isStoreModalStoreShowing: true,
                        isStoreLoading: false
                    })
                }).catch(err => {
                    self.setState({isStoreLoading: false})
                })
            }, 700)
        });
    }

    handleCkEditorOnChange = (name, data, isMultiLang, lang) => {
        const formData = {...this.state.formData, [name]: data};
        this.setState({formData})
    }


    handleSelectOnChange = (name, value) => {
        let formData = {...this.state.formData}
        console.log(formData)
        console.log(formData)
        const items = []
        if (value?.length > 0) {
            value.forEach(item => {
                items.push(item.id)

            })
        }
        if (name === 'merchant_types') {
            formData[name] = items
        }
        this.setState({formData}, () => {
            console.log(this.state.formData)
            if (name === 'merchant_types') {
                getStoreList({
                    ...this.state.defaultQuerySettings.merchantList,
                    merchant_types: JSON.stringify(this.state.formData.merchant_types)
                }).then(res => {
                    this.setState({
                        stores: res.items,
                        storePagination: res.pagination,
                        isStoreModalStoreShowing: true,
                        isStoreLoading: false
                    })
                })
            }
        })
    }

    unCheck(i,e,data) {
        const formData = {...this.state.formData};

        let ref = 'ref_' + i;
        if (e=== true){
            this.refs[ref].checked = true
            formData.stores.push(data._id)
            this.setState({formData})
        }else {
            this.refs[ref].checked = false
            formData.stores = []
            this.setState({formData})
        }
        // this.refs[ref].checked = !this.refs[ref].checked;
    }

    render() {
        return (
            <App layout="boxed">
                <div className="page-header">
                    <h2 className="page-title">Add Campaign</h2>
                    <div className="ml-auto">
                        <Link to={`${process.env.PUBLIC_URL}/promotions/campaign`}
                              className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Campaign List</Link>
                    </div>
                </div>
                {getLocalAuthData()?.permission?.includes('campaign_add') ?
                    <div className="main-content">

                        {getLocalAuthData()?.permission?.includes('campaign_edit') ?
                            <Form
                                noValidate validated={this.state.isFormValidated}
                                onSubmit={this.handleSubmitForm}>
                                <div className="card-block">

                                    <div className="block-header">
                                        <h4 className="block-title">Define Campaign</h4>
                                    </div>

                                    <div className="block-body">

                                        <Form.Group as={Row} controlId="campaignTitle">
                                            <Form.Label column sm="3">
                                                Campaign Title <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control name={'campaign_title'} type="text"
                                                              onChange={this.handleInputOnChange} placeholder=""/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="CampaignDetails">
                                            <Form.Label column sm="3">
                                                Campaign Details <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    data={this.state?.formData?.campaign_details || ``}
                                                    onInit={editor => {
                                                        editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
                                                            return new UploadAdapter(loader);
                                                        };
                                                    }}
                                                    config={{
                                                        image: {
                                                            resizeUnit: 'px',
                                                            toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],
                                                            styles: [
                                                                'full',
                                                                'alignLeft',
                                                                'alignRight'
                                                            ]
                                                        }
                                                    }
                                                    }
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        this.handleCkEditorOnChange('campaign_details', data);
                                                    }}
                                                />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="termsAndConditions">
                                            <Form.Label column sm="3">
                                                Terms and Conditions <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    data={this.state?.formData?.terms_and_conditions || ``}
                                                    onInit={editor => {
                                                        editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
                                                            return new UploadAdapter(loader);
                                                        };
                                                    }}
                                                    config={{
                                                        image: {
                                                            resizeUnit: 'px',
                                                            toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],
                                                            styles: [
                                                                'full',
                                                                'alignLeft',
                                                                'alignRight'
                                                            ]
                                                        }
                                                    }
                                                    }
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        this.handleCkEditorOnChange('terms_and_conditions', data);
                                                    }}
                                                />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formStartFrom">
                                            <Form.Label column sm="3">
                                                Date Range <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <DateRangePicker
                                                    timePicker={true}
                                                    containerStyles={{textAlign: 'left', width: '100%', display: 'block'}}
                                                    dateFormat="hh:mm a yyyy-MM-dd"
                                                    onApply={this.handleOnApply}>
                                                    <input type="button"
                                                           value={`${this.state.start_date} to ${this.state.end_date}`}
                                                           style={{textAlign: 'left'}}
                                                           className="form-control"/>
                                                </DateRangePicker>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formEndTime">
                                            <Form.Label column sm="3">
                                                Joining Last Date <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <DatePicker selected={this.state.formData.registration_last_date}
                                                            showTimeSelect
                                                            dateFormat="hh:mm a dd/MM/yyyy"
                                                            minDate={new Date()}
                                                            required
                                                            className={'form-control'} onChange={date => {
                                                    const formData = {...this.state.formData}
                                                    formData.registration_last_date = date
                                                    this.setState({formData}, () => {
                                                        console.log('Deposit Info: ', this.state.formData);
                                                    })
                                                }}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formCoverImage">
                                            <Form.Label column sm="3">
                                                Campaign Cover Image
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <BrowseFiles
                                                    userId={getLocalAuthData()?._id}
                                                    isSigle
                                                    image_category={'banner'}
                                                    onRemoveItem={() => this.handleOnRemoveItem('cover_image')}
                                                    selected_image_size_template={'default'}
                                                    helpText={'Image size 800 x 800'}
                                                    images={this.state.formData.campaign_cover_image ? [{thumb_img: this.state.formData.campaign_cover_image}] : []}
                                                    onGetFiles={(images) => this.handleImageOnBrowse(images, 'cover_image')}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formImages">
                                            <Form.Label column sm="3">
                                                Campaign Banner
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <BrowseFiles
                                                    userId={getLocalAuthData()?._id}
                                                    isSigle
                                                    image_category={'banner'}
                                                    onRemoveItem={this.handleOnRemoveItem}
                                                    selected_image_size_template={'banner_1920x430'}
                                                    helpText={'Image size 1920 x 430'}
                                                    images={this.state.formData.campaign_banner[0]?.img ? [{thumb_img: this.state.formData.campaign_banner[0]?.img}] : []}
                                                    onGetFiles={this.handleImageOnBrowse}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formImages">
                                            <Form.Label column sm="3">
                                                Campaign Mobile Banner
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <BrowseFiles
                                                    userId={getLocalAuthData()?._id}
                                                    isSigle
                                                    image_category={'banner'}
                                                    onRemoveItem={() => this.handleOnRemoveItem('mobile_img')}
                                                    selected_image_size_template={'banner_755x390'}
                                                    helpText={'Image size 755 x 390'}
                                                    images={this.state.formData.campaign_banner[0]?.mobile_img ? [{thumb_img: this.state.formData.campaign_banner[0]?.mobile_img}] : []}
                                                    onGetFiles={(images) => this.handleImageOnBrowse(images, 'mobile_img')}/>
                                            </Col>
                                        </Form.Group>

                                        {/*

                                <Form.Group as={Row} controlId="formEndTime">
                                    <Form.Label column sm="3">
                                        Category <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Multiselect
                                            onChange={value => this.handleOnChangeCategory(value)}
                                            placeholder="Select varient"
                                            data={this.state.categories}/>
                                    </Col>
                                </Form.Group>


                                <Form.Group as={Row} controlId="formVoucherType">
                                    <Form.Label column sm="3">
                                        Shop Category <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control as="select" placeholder="">
                                            <option value="all">All</option>
                                            <option value="1">Uddakta Mall</option>
                                            <option value="2">Location Entrepreneur</option>
                                            <option value="3">Global Entrepreneur</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formVoucherDisplayArea">
                                    <Form.Label column sm="3">
                                        Shops
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Check
                                            custom inline type="radio"
                                            name="display_area"
                                            id={`apply-to-entire-shop`}
                                            label={`All Shop`}
                                        />
                                        <Form.Check
                                            custom inline type="radio"
                                            name="display_area"
                                            id={`apply-to-specific-product`}
                                            label={`Specific Shop`}
                                        />
                                    </Col>
                                </Form.Group>

                                */}

                                        {/*<Modal show={this.state.isStoreModalStoreShowing} size={'lg'}
                       onHide={this.handleHideStoreModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Select Store</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="mb-3 form-inline">
                      <Form.Group>
                        <Form.Label srOnly>Search</Form.Label>
                        <Form.Control type={`search`}
                                      size={`sm`}
                                      onChange={(e) => {
                                        this.setState({storeSearchString: e.target.value})
                                      }}
                                      placeholder={`Search...`}/>
                      </Form.Group>

                      <Button type={`button`} size={`sm`} variant={`info`}
                              onClick={() => {
                                this.handleSearchStore(this.state.storeSearchString || '')
                              }} className={`ml-2`}>Search</Button>
                    </div>

                    {this.state?.stores?.length < 1 &&
                    <div className="alert alert-warning text-bold text-center">
                      no store found
                    </div>}

                    {this.state?.stores?.length > 0 &&
                    <>
                      <div className="product-checkbox-list">
                        {this.state?.stores?.map((item, index) => (
                          <label className={`product-item`} key={this.state.storePagination?.page + index}
                                 htmlFor={`store-${index}`}>
                            <input id={`store-${index}`}
                                   onChange={e => this.handleSelectedStoreOnChange(e, item._id)}
                                   name={`store`}
                                   type="checkbox"/>
                            <span className={`product`}>
                              <span className="product-img">
                                <img src={item?.store_logo} alt=""/>
                              </span>
                              <span className="product-short-info">
                                <span className="product-name">{item?.store_name}</span>
                              </span>
                              <span className="icon"><IoMdCheckmark/></span>
                            </span>
                          </label>
                        ))}
                      </div>

                      <br/>

                      <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.storePagination?.page}
                        itemsCountPerPage={this.state.storePagination?.limit}
                        totalItemsCount={this.state.storePagination?.totalDocs}
                        onChange={(page) => {
                            getStoreList({page: page, limit: 20}).then(res => {
                                this.setState({
                                    stores: res.items,
                                    productPagination: res.pagination
                                }, () => console.log(this.state));
                            })
                        }}
                      />
                    </>
                    }
                  </Modal.Body>

                  <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleHideStoreModal}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={() => this.handleAddStore(this.state.selectedStores)}>
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>*/}

                                        <Modal show={this.state.isStoreModalStoreShowing} size={'lg'}
                                               onHide={this.handleHideStoreModal}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Select Store</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>

                                                <Form.Group>
                                                    <Select
                                                        isMulti
                                                        placeholder="Select Merchant Type..."
                                                        onChange={(value) => {
                                                            this.handleSelectOnChange('merchant_types', value)
                                                        }}
                                                        options={this.state.merchant_types ? this.state.merchant_types : []}
                                                        name="merchant_types"
                                                        noOptionsMessage={() => 'Select Merchant Type...'}
                                                    />
                                                </Form.Group>

                                                <div className="mb-12 form-inline">
                                                    <Form.Group>
                                                        <Form.Label srOnly>Search</Form.Label>
                                                        <Form.Control type={`search`}
                                                                      size={`sm`}
                                                                      onChange={this.handleOnStoreSearch}
                                                                      placeholder={`Search...`}/>
                                                    </Form.Group>
                                                    <br/>
                                                    <Form.Group style={{marginLeft: '10px'}} controlId="formBasicCheckbox">
                                                        <Form.Check onClick={(e) => {
                                                            const formData = {...this.state.formData};
                                                            const selectedStoreList = [...this.state.selectedStoreList];
                                                            if (e.target.checked === true) {
                                                                this.setState({
                                                                    selectedStoreList: this.state.stores,
                                                                    selectAll: false
                                                                },()=>{
                                                                    /* this.state.selectedStoreList.map((data, i) => {
                                                                       formData.stores.push(data._id)
                                                                     })*/
                                                                })
                                                            } else {
                                                                this.setState({
                                                                    selectedStoreList: [],
                                                                    selectAll: true
                                                                })
                                                                formData.stores = []
                                                            }
                                                            this.state.stores.map((data,i)=>{
                                                                this.unCheck(i, e.target.checked,data)
                                                            })
                                                        }} type="checkbox" label={this.state.selectAll ? "Select All":"Unselect All"}/>
                                                    </Form.Group>

                                                </div>

                                                {this.state?.stores?.length < 1 &&
                                                <div className="alert alert-warning text-bold text-center">
                                                    no store found
                                                </div>}

                                                {this.state?.stores?.length > 0 &&
                                                <>
                                                    <div className="product-checkbox-list">
                                                        {this.state?.stores?.map((item, index) => (
                                                            <label className={`product-item`}
                                                                   key={item._id}
                                                                   htmlFor={`store-${item._id}`}>
                                                                <input
                                                                    ref={'ref_' + index}
                                                                    id={`store-${item._id}`}
                                                                    onChange={e => this.handleSelectedStoreOnChange(e, item)}
                                                                    name={`product`}
                                                                    defaultChecked={this.isStoreIncluded(item._id, this.state.selectedStoreList)}
                                                                    type="checkbox"/>
                                                                <span className={`product`}>
                              <span className="product-img">
                                <img src={item?.store_logo} alt=""/>
                              </span>
                              <span className="product-short-info">
                                <span
                                    className="product-name">{item?.store_name}</span>
                              </span>
                              <span className="icon"><IoMdCheckmark/></span>
                            </span>
                                                            </label>
                                                        ))}
                                                    </div>

                                                    <br/>

                                                    <Pagination
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                        activePage={this.state.storePagination?.page}
                                                        itemsCountPerPage={this.state.storePagination?.limit}
                                                        totalItemsCount={this.state.storePagination?.totalDocs}
                                                        onChange={(page) => {
                                                            getStoreList({page: page, limit: 20}).then(res => {
                                                                this.setState({
                                                                    stores: res.items,
                                                                    storePagination: res.pagination
                                                                }, () => console.log(this.state));
                                                            })
                                                        }}
                                                    />
                                                </>
                                                }
                                            </Modal.Body>

                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={this.handleHideStoreModal}>
                                                    Close
                                                </Button>

                                                <Button variant="primary" onClick={() => this.handleHideStoreModal()}>
                                                    Done
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>

                                        {
                                            <Form.Group as={Row} controlId="formStores">
                                                <Form.Label column sm="3">Stores</Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <table style={{maxHeight: '50px', overflowY: 'auto'}} className={`table table-striped table-bordered`}>
                                                        <thead className={`sm`}>
                                                        <tr>
                                                            <th style={{width: '50px'}}/>
                                                            <th>Store Name</th>
                                                            <th style={{width: '1%'}}/>
                                                        </tr>
                                                        </thead>

                                                        <tbody>
                                                        {this.state?.selectedStoreList?.length > 0 ?
                                                            <>
                                                                {this.state.selectedStoreList.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <img src={item?.store_logo} style={{width: '40px'}} alt=""/>
                                                                        </td>
                                                                        <td>{item?.store_name}</td>
                                                                        <td><span style={{cursor: 'pointer'}}
                                                                                  onClick={() => this.handleRemoveSelectedStore(index)}
                                                                                  className={`text-bold text-danger`}>&times;</span></td>
                                                                    </tr>
                                                                ))}
                                                            </>
                                                            :
                                                            <tr>
                                                                <td colSpan={3} className={`text-center text-bold`}>
                                                                    No store selected
                                                                </td>
                                                            </tr>
                                                        }
                                                        </tbody>
                                                    </table>

                                                    <Button size="sm"
                                                            disabled={this.state.isStoreLoading}
                                                            onClick={() => this.handleShowSelectStoreModal()}
                                                            variant="link"> {!this.state.isStoreLoading ? 'Select Stores' : 'Please wait...'}</Button>
                                                </Col>
                                            </Form.Group>
                                        }


                                        <Form.Group as={Row} controlId="formCampaignType">
                                            <Form.Label column sm="3">
                                                Campaign Type <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>

                                                <Form.Check
                                                    custom
                                                    inline required
                                                    type="radio"
                                                    name="campaign_type"
                                                    id={`custom-radio-1`}
                                                    checked={this.state?.formData?.campaign_type === 'public'}
                                                    onChange={this.handleInputOnChange}
                                                    value={`public`}
                                                    label={`Public`}
                                                />


                                                <Form.Check
                                                    custom
                                                    inline
                                                    type="radio"
                                                    name="campaign_type"
                                                    id={`custom-radio-2`}
                                                    required
                                                    checked={this.state?.formData?.campaign_type === 'public'}
                                                    onChange={this.handleInputOnChange}
                                                    value={`private`}
                                                    label={`Private`}
                                                />


                                                <Form.Control.Feedback type="invalid">
                                                    Please select a campaign type.
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>

                                    </div>
                                </div>


                                <div className="card-block">
                                    <div className="block-body text-right">
                                        <Button type={'submit'} size="lg" variant="primary">Save</Button>
                                    </div>
                                </div>

                            </Form> : null}

                    </div>
                    : null}
            </App>
        );
    }

}

export default withRouter(AddCampaignOffer);
