import React, {Component} from 'react';
import App from "../../App";
import {FaLongArrowAltLeft} from "react-icons/all";
import {Link, withRouter} from "react-router-dom";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import "../../assets/scss/add-product.scss";
import {getChildLocations, getLocationList} from "../../services/locationServices";
import {getLocalAuthData} from "../../helper/utils";
import {addNewItem} from "../../services/commonServices";
import {toast} from "react-toastify";
import Select from "react-select";
import styleReactSelect from "../../assets/styleReactSelect";
import {getMerchantList} from "../../services/merchantServices";
import DatePicker from "react-datepicker";

class AddAssistedSeller extends Component {
    state = {
        existing_locations: [],
        genders: [{id: 1, label: "Male", value: "Male"}, {id: 2, label: "Female", value: "Female"}],
        formData: {
            name: "",
            phone: "",
            email: "",
            address: "",
            postcode: "",
            location_ids: [],
            locations: [],
            gender: "male",
            birthdate: "",
        },
        isSubmitting: false,
        isFormValidated: false,
        merchantList: [],
        merchants: [],
        storeExistingStatus: 2,
        storeExistingMsg: null,
        splittedPhone: ['+880', ''],
        formSubmitError: "",
    };

    componentDidMount() {
        getLocationList().then(res => {
            this.setState({existing_locations: [res]}, () => {
                console.log(this.state);
            })
        })
    }

    handleGetMerchantList = (string) => {
        if (string.length > 2) {
            this.setState({formSubmitError: ""});
            getMerchantList({limit: 10, search_string: string})
                .then(res => {
                    const merchantList = [];
                    res.forEach(item => {
                        const merchant = {
                            id: item._id,
                            label: item.name,
                            value: item._id
                        }
                        merchantList.push(merchant);
                    })
                    this.setState({merchants: res, merchantList})
                })
        }
    }

    handleAssistedSellerInputOnChange = (e) => {
        this.setState({formSubmitError: ""});
        const formData = {...this.state.formData};
        formData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({formData}, () => {
            console.log(this.state.formData);
        });
    }

    handleSelectInputOnChange = (name, value) => {
        this.setState({formSubmitError: ""});
        const formData = {...this.state.formData};
        formData[name] = value.value;
        this.setState({formData}, () => {
            console.log(this.state.formData);
        });
    }

    inputSellerPhoneOnChange = (e, index) => {
        this.setState({formSubmitError: ""})
        const splittedPhone = [...this.state.splittedPhone]
        splittedPhone[index] = e.target.value
        const formData = {...this.state.formData}
        formData.phone = splittedPhone.join('-')
        this.setState({splittedPhone, formData})
    }

    handleLocationInputOnChange = (e, index, typePurpose) => {
        this.setState({formSubmitError: ""});
        const existing_locations = [...this.state.existing_locations];
        if (typePurpose === 'assistedSellerLocations') {

        } else {
            this.handleReturnInputOnChange(e)
        }

        const formData = {...this.state.formData};
        let location_ids = formData.location_ids;
        let locations = formData.locations;

        if (e.target.value !== '') {

            if (index >= 0 && existing_locations.length > index) {
                existing_locations.splice(index + 1, existing_locations.length - index)
                location_ids.splice(index, location_ids.length - index)
                locations.splice(index, locations.length - index)
            }

            let selected_location_array = existing_locations[index]
            let selected_location = selected_location_array.find(element => element._id === e.target.value);
            location_ids.push(selected_location._id)
            locations.push(selected_location)
            formData.location_ids = location_ids
            formData.locations = locations
            this.setState({formData}, () => {
                console.log(this.state.formData.locations);
            });

            getChildLocations(e.target.value).then(res => {
                if (res.length > 0) {
                    existing_locations[index + 1] = res
                }
                this.setState({existing_locations}, () => {
                    console.log("locations");
                    console.log(this.state);
                })
            }).catch(errMsg => {
                console.log(errMsg)
                // childLocations.splice(index, childLocations.length - index)
                // this.setState({[typePurpose]: childLocations})
            })
        } else {

            if (index > 0 && existing_locations.length > index) {
                existing_locations.splice(index + 1, existing_locations.length - index)
                location_ids.splice(index, location_ids.length - 1)
                locations.splice(index, locations.length - 1)
            }
            formData.location_ids = location_ids
            formData.locations = locations
            this.setState({formData, existing_locations}, () => {
                console.log(this.state.formData.locations);
            });
        }
    }


    handleSubmitForm = (e) => {
        e.preventDefault();

        const form = e.currentTarget;
        if ((form.checkValidity() === false) || (this.state.merchantList.length<1)) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({isFormValidated: true, formSubmitError: "Please fill the required field!"})
            window.scrollTo({top: 0, behavior: 'smooth'});
        } else {
            this.setState({isSubmitting: true}, () => {
                addNewItem(`/assisted-seller`, this.state.formData).then(res => {
                    if (res.status === 1) {
                        this.setState({isSubmitting: false, isFormValidated: false});
                        this.props.history.push('/assisted-sellers');
                        toast.success(res.message);
                    } else {
                        this.setState({isSubmitting: false, isFormValidated: false});
                        toast.error(res.message);
                    }
                }).catch(errMsg => {
                    this.setState({isSubmitting: false, isFormValidated: false});
                    this.setState({isFormValidated: false})
                    toast.error(errMsg);
                })
            })
        }
    }

    render() {
        return (
            <App layout="boxed">

                <div className="page-header">
                    <h2 className="page-title">Add Assisted Seller</h2>
                    <div className="ml-auto">
                        <Link to={`${process.env.PUBLIC_URL}/assisted-sellers`}
                              className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Assisted Seller List</Link>
                    </div>
                </div>

                <div className="main-content">

                    {getLocalAuthData()?.permission?.includes('assisted_add') ?
                        <><Form noValidate
                                validated={this.state.isFormValidated}
                                onSubmit={this.handleSubmitForm}>

                            <div className="card-block">
                                <div className="block-header">
                                    <h4 className="block-title">Describe the Assisted Seller</h4>
                                </div>

                                <div className="block-body">
                                    {this.state.formSubmitError && <Alert variant={"danger"}>{this.state.formSubmitError}</Alert>}

                                    <Form.Group as={Row} controlId="formMerchant">
                                        <Form.Label column sm="3">
                                            Merchant <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Select
                                                styles={styleReactSelect}
                                                placeholder="Type for Select Merchant..."
                                                onInputChange={this.handleGetMerchantList}
                                                id="merchant_id" name="merchant_id"
                                                onChange={(value) => {
                                                    this.handleSelectInputOnChange('merchant_id', value)
                                                }}
                                                options={this.state.merchantList}
                                                noOptionsMessage={() => 'Please insert new value...'}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please select a merchant
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>


                                    <Form.Group as={Row} controlId="contactPerson">
                                        <Form.Label column sm="3"> Name <span
                                            className="text-danger">*</span></Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control required onChange={this.handleAssistedSellerInputOnChange}
                                                          name={"name"}
                                                          type={"text"}
                                                          minLength="3" maxLength="200"/>
                                            {<span className="text-help text-muted">{'Minimum 3 latter and maximum 200 latter allowed'}</span>}

                                            <Form.Control.Feedback type="invalid">
                                                Seller name required
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>


                                    <Form.Group as={Row} controlId="formPhone">
                                        <Form.Label column sm="3"> Phone Number <span
                                            className="text-danger">*</span></Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <div className="input-group">
                                                <Form.Control
                                                    as={'select'} name={'code'} required
                                                    value={this.state?.splittedPhone ? this.state?.splittedPhone[0] : ''}
                                                    onChange={e => this.inputSellerPhoneOnChange(e, 0)}
                                                    style={{width: `80px`, paddingRight: '10px', flex: '0 0 80px'}}>

                                                    <option value="">---</option>
                                                    {this.state?.existing_locations?.length > 0 && this.state.existing_locations[0].map((location, index) => (
                                                        <>
                                                            {
                                                                location.code &&
                                                                <option key={index} value={location.code}>{location.code}</option>
                                                            }
                                                        </>
                                                    ))}
                                                </Form.Control>

                                                <Form.Control
                                                    required type="text" placeholder={`Phone number`}
                                                    onChange={e => this.inputSellerPhoneOnChange(e, 1)}
                                                    maxLength="10" minLength="10"/>

                                                <Form.Control.Feedback type="invalid">
                                                    Seller phone required
                                                </Form.Control.Feedback>
                                            </div>

                                            {this.state.phoneExistingMsg && <Form.Text className={'text-danger'}>
                                                {this.state.phoneExistingMsg}
                                            </Form.Text>}
                                        </Col>

                                    </Form.Group>


                                    <Form.Group as={Row} controlId="assistedSellerAddress">
                                        <Form.Label column sm="3"> Address <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control required onChange={this.handleAssistedSellerInputOnChange}
                                                          name={"address"}
                                                          type={"text"}/>

                                            <Form.Control.Feedback type="invalid">
                                                Seller address required
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>

                                    {this.state?.existing_locations.length > 0 &&
                                    this.state?.existing_locations.map((location, index) => (
                                        <Form.Group key={index} as={Row} controlId={`location${location[0].type}`}>
                                            <Form.Label column sm="3">{location[0]?.type} {(index < 4) && <span className="text-danger">*</span>}</Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control as="select" name={location[0].type.toLowerCase()} required
                                                              value={this.state.formData?.location_ids[index] ? this.state.formData?.location_ids[index] : ""}
                                                              onChange={(e) => this.handleLocationInputOnChange(e, index, 'assistedSellerLocations')}>
                                                    <option value="">--Select--</option>
                                                    {location.map((item, index) => (
                                                        <option key={index} value={item._id}>{item.name}</option>
                                                    ))}
                                                </Form.Control>

                                                <Form.Control.Feedback type="invalid">
                                                    Seller {location[0].type} required
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                    ))}


                                    <Form.Group as={Row} controlId="formPostcode">
                                        <Form.Label column sm="3"> Postcode <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control required name={"postcode"} type={"text"}
                                                          value={this.state?.formData?.postcode}
                                                          onChange={this.handleAssistedSellerInputOnChange}/>

                                            <Form.Control.Feedback type="invalid">
                                                Seller postcode required
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>


                                    <Form.Group as={Row} controlId="formGender">
                                        <Form.Label column sm="3">
                                            Gender <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={8}>
                                            <div className="input-group">
                                                <Form.Control as={`select`} required
                                                              value={this.state?.formData?.gender ? this.state?.formData?.gender : ""}
                                                              onChange={this.handleAssistedSellerInputOnChange}
                                                              style={{width: '150px', flex: '0 0 150px'}}
                                                              name={`gender`}>
                                                    <option value="">Select Gender</option>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                </Form.Control>

                                                <Form.Control.Feedback type="invalid">
                                                    Seller gender required
                                                </Form.Control.Feedback>
                                            </div>

                                            <Form.Control.Feedback type="invalid">
                                                Please provide seller gender.
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>


                                    <Form.Group as={Row} controlId="formBirthdate">
                                        <Form.Label column sm="3"> Date Of Birth <span className="text-danger">*</span></Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <DatePicker selected={this.state?.formData?.birthdate}
                                                        dateFormat="dd/MM/yyyy"
                                                        maxDate={new Date()}
                                                        required
                                                        className={'form-control'}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        hint={'dd/MM/yyyy'}
                                                        onChange={date => {
                                                            const formData = {...this.state.formData}
                                                            formData.birthdate = date
                                                            this.setState({formData})
                                                        }}/>
                                            {<span className="text-help text-muted">{'DD/MM/YYYY'}</span>}

                                            <Form.Control.Feedback type="invalid">
                                                Seller birth date required
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>

                                </div>
                            </div>

                            <div className="card-block">
                                <div className="block-body text-right">
                                    <Button size="lg" className="mr-3" variant="default">Cancel</Button>
                                    <Button type={'submit'} size="lg"
                                            variant="primary">{this.state.isSubmitting ? 'Saving...' : 'Save'}</Button>
                                </div>
                            </div>

                        </Form>
                        </>
                        : null}


                </div>
            </App>
        );
    }

}

export default withRouter(AddAssistedSeller);
