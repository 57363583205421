import React, {Component} from "react"
import "../../assets/scss/business-block-card.scss"
import "../../assets/scss/dashboard.scss"
import Chart from "chart.js"

class SaleOrderChart extends Component {
  salesAndOrderChartRef = React.createRef()

  componentDidMount() {
    setTimeout(() => {
      if (Object.keys(this.props.orderVsSalesReports).length > 0) {
        const salesAndOrdersRef = this.salesAndOrderChartRef.current.getContext("2d")
        new Chart(salesAndOrdersRef, {
          type: "line",
          data: {
            labels: Object.keys(this.props.orderVsSalesReports.dataset),
            datasets: [
              {
                yAxisID: 'A',
                label: "Sales",
                data: Object.values(this.props.orderVsSalesReports.dataset).map(o => o.value),
                borderWidth: 1,
                pointBorderWidth: 0,
                pointBorderColor: '#FF9B0D',
                borderColor: '#FF9B0D',
                fill: 'none',
                backgroundColor: 'rgba(255,155,13,0.25)'
              },
              {
                yAxisID: 'B',
                label: "Orders",
                data: Object.values(this.props.orderVsSalesReports.dataset).map(o => o.count),
                borderWidth: 1,
                pointBorderColor: '#5E6CC5',
                borderColor: '#5E6CC5',
                backgroundColor: 'rgba(94,108,197,0.25)'
              }
            ]
          },
          options: {
            tooltips: {
              mode: 'index',
              intersect: false,
            },
            scales: {
              xAxes: [{
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: 'Date'
                },
                ticks: {
                  padding: 10
                }
              }],
              yAxes: [{
                id: 'A',
                position: 'left',
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: 'Sales(BDT)'
                },
                ticks: {
                  beginAtZero: true,
                  userCallback: function (label) {
                    if (Math.floor(label) === label) {
                      return label;
                    }
                  },
                }
              }, {
                id: 'B',
                position: 'right',
                display: true,
                stacked: false,
                scaleLabel: {
                  display: true,
                  labelString: 'Orders(number)'
                },
                ticks: {
                  beginAtZero: true,
                  userCallback: function (label) {
                    if (Math.floor(label) === label) {
                      return label;
                    }
                  },
                }
              }]
            },
          }
        })
      }
    }, 1000)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.componentDidMount();
  }

  render() {
    return (
      <div className="card-block">
        <div className="block-body">
          <div className="stats-cards">
            <div className="stats-card">
              <span className="title">Total Sales</span>
              <span className="value text-success">BDT {this.props?.orderVsSalesReports?.total_sale}</span>
            </div>
            <div className="stats-card">
              <span className="title">Total Orders</span>
              <span className="value text-info">{this.props?.orderVsSalesReports?.total_order}</span>
            </div>
          </div>

          <canvas
            style={{height: '320px', width: '100%'}}
            ref={this.salesAndOrderChartRef}
          />
        </div>
      </div>
    )
  }
}

export default SaleOrderChart
