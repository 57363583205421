import React, {useState} from 'react'
import {FiUploadCloud} from 'react-icons/all'

const BrowseButton = ({id, name, onChange, multiple, size, required, isImageUploading}) => {
  const [browsedImages, setBrowsedImages] = useState([])
  const handleOnChange = (e) => {
    setBrowsedImages(e.target.files)
    if (onChange) onChange(e)
  }

  const renderBrowsedImagesText = () => {
    if (browsedImages.length > 0 && browsedImages.length < 3) {
      const images = []
      Object.keys(browsedImages).forEach(key => images.push(browsedImages[key].name))
      return (<>{images.join(', ')}</>)
    } else if (browsedImages.length > 2) {
      return (<>{browsedImages.length} Images Selected</>)
    } else {
      return (<>Browse Image</>)
    }
  }

  return (
    <label className={!size ? 'btn-browse-image' : `btn-browse-image btn-browse-image-${size}`} htmlFor={id ? id : 'browse-img'}>
      <input onChange={handleOnChange}
             required={!!required}
             disabled={isImageUploading}
             multiple={multiple ? multiple : false} name={name ? name : ''} type='file' id={id ? id : 'browse-img'}/>
      <span className='browse-text'>
        <FiUploadCloud/>
        {renderBrowsedImagesText()}
      </span>
    </label>
  )
}

export default BrowseButton
