// noinspection DuplicatedCode,JSUnresolvedVariable

import React, { Component } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import "../assets/scss/business-block-card.scss";
import HomeProduct from "./HomeProduct";
import { FiMove, FiSettings, FiTrash, IoMdCheckmark } from "react-icons/all";
import { isEmptyObj } from "../helper/utils";
import { getActiveProductList } from "../services/productServices";
import LoadingSpinner from "./LoadingSpinner";
import {getTableData, updateItem} from "../services/commonServices";
import { getStoreList } from "../services/storeServices";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import {toast} from "react-toastify";

class ProductsBlock extends Component {
  state = {
    isSettingModalShowing: false,
    settingData: { params: {} },
    productList: [],
    customProductList: [],
    selectedCustomProductList: [],
    isDataFetching: false,
    isConfigured: false,
    customProductModalShow: false,
    customProductQuery: {},
    storeList: [],
    isLoadingStoreData: false,
  };

  componentDidMount() {
    //console.log("this.props.dragHandleProps", this.props.dragHandleProps);
    if (!isEmptyObj(this.props.settings?.params)) {
      this.setState(
        {
          isDataFetching: true,
          isConfigured: true,
          settingData: this.props.settings,
        },
        () => {
          if (this.props.settings.params.sort_by === "3") {
            let obj = this.props.settings.params;
            obj["setting_id"] = this.props.setting_id;
            getTableData("/home-page-products", obj)
              .then((res) => {
                this.setState({
                  selectedCustomProductList: res.products.settings.products,
                  isDataFetching: false,
                });
              })
              .catch(() => {
                this.setState({ isDataFetching: false });
              });
          }

          getActiveProductList({...this.props.settings.params, duplicate: true})
            .then((res) => {
              this.setState({
                productList: res.products,
                customProductList: res.products,
                isDataFetching: false,
              });
            })
            .catch(() => {
              this.setState({ isDataFetching: false });
            });
        }
      );
    }

    /*setTimeout(() => {
      console.log("In component",this.props.dragHandleProps);
      this.props.toggleDragableFlag()
    }, 10000)*/
  }

  handleSettingModalShow = () => {
    this.setState({ isSettingModalShowing: true });
  };

  handleSettingModalHide = () => {
    this.setState({ isSettingModalShowing: false });
  };

  handleSettingInputOnChange = (e) => {
    const settingData = { ...this.state.settingData };
    if (e.target.type !== "checkbox") {
      if (e.target.name === "title" || e.target.name === "title_local") {
        settingData[e.target.name] = e.target.value;
      } else {
        settingData.params[e.target.name] = e.target.value;
      }
    } else {
      if (e.target.checked) {
        settingData.params[e.target.name] = e.target.value;
      } else {
        delete settingData.params[e.target.name];
      }
    }
    this.setState({ settingData });
  };

  handleOnSaveSettingForm = () => {
    this.props.actions.onChangeSettings(this.state.settingData, () => {
      this.handleSettingModalHide();
      this.setState({ isConfigured: true, isDataFetching: true }, () => {
        if (this.state.settingData.params.sort_by === "3")
          // custom product
          return getActiveProductList({})
            .then((data) => {
              //console.log(data)
              this.setState({
                customProductList: data.products,
                isDataFetching: false,
              });
            })
            .catch(() => this.setState({ isDataFetching: false }));
        else
          return getActiveProductList(this.props.settings.params)
            .then((res) =>
              this.setState({
                productList: res.products,
                isDataFetching: false,
              })
            )
            .catch(() => this.setState({ isDataFetching: false }));
      });
    });
  };

  isTrue = () => {
    let isDisabled;
    if (typeof this.props.settings?.is_disabled !== "string") {
      isDisabled = this.props?.is_disabled;
    } else {
      isDisabled = this.props?.is_disabled === "true";
    }

    return isDisabled;
  };

  handleInputOnChange = (e) => {
    this.setState(
      { isDataFetching: true, customProductQuery: e.target.value },
      () => {
        getActiveProductList({ search_string: this.state.customProductQuery })
          .then((data) =>
            this.setState({
              customProductList: data.products,
              isDataFetching: false,
            })
          )
          .catch(() => this.setState({ isDataFetching: false }));
      }
    );
  };

  handleSearch = (query) => {
    this.setState({ isLoadingStoreData: true }, () => {
      getStoreList({ search_string: query, lite_data: 1 })
        .then((res) => {
          this.setState({ storeList: res.items, isLoadingStoreData: false });
        })
        .catch((errMsg) => {
          this.setState({ isLoadingStoreData: false });
        });
    });
  };

  handleSearchProduct = () => {
    if (this.state.customProductQuery) {
      getActiveProductList(this.state.customProductQuery)
        .then((data) =>
          this.setState({
            customProductList: data.products,
            isDataFetching: false,
          })
        )
        .catch(() => this.setState({ isDataFetching: false }));
    }
  };

  handleSelectedProductOnChange = (e, product) => {
    if (e.target.checked) {
      let selectedCustomProductList = this.state.selectedCustomProductList;
      selectedCustomProductList.push(product);
      this.setState(
        {
          selectedCustomProductList: selectedCustomProductList,
        },
        () => {
          const onlyIds = this.state.selectedCustomProductList.map(
            (a) => a._id
          );
          this.props.settings.products = onlyIds.filter(
            (item, pos) => onlyIds.indexOf(item) === pos
          );
        }
      );
    } else {
      let selectedCustomProductList =
        this.state.selectedCustomProductList.filter(
          (item) => item?.sku_info?._id !== product?.sku_info?._id
        );
      this.setState(
        {
          selectedCustomProductList: selectedCustomProductList,
        },
        () => {
          const onlyIds = this.state.selectedCustomProductList.map(
            (a) => a._id
          );
          this.props.settings.products = onlyIds.filter(
            (item, pos) => onlyIds.indexOf(item) === pos
          );
        }
      );
    }
  };

  isProductIncluded = (product) => {
    if (this.state.selectedCustomProductList.length === 0) return false;
    return (
      this.state.selectedCustomProductList.filter(
        (item) => item._id === product._id
      ).length !== 0
    );
  };


  handleDeleteListenerV2 = async (product, index , categoryId ) => {
    let selectedCustomProductList =
        this.state.productList.filter(
            (item) => item?.sku_info?._id !== product?.sku_info?._id
        );
    this.setState(
        {
          productList: selectedCustomProductList,
        },
        () => {
          const onlyIds = this.state.productList.map(
              (a) => a._id
          );
          this.props.settings.products = onlyIds.filter(
              (item, pos) => onlyIds.indexOf(item) === index
          );
          //this.deleteProduct(categoryId , product._id)
        }
    );
  }

  handleDeleteListener = async (product, index , categoryId ) => {

    let selectedCustomProductList =
        this.state.selectedCustomProductList.filter(
            (item) => item?.sku_info?._id !== product?.sku_info?._id
        );
    this.setState(
        {
          selectedCustomProductList: selectedCustomProductList,
        },
        () => {
          const onlyIds = this.state.selectedCustomProductList.map(
              (a) => a._id
          );
          this.props.settings.products = onlyIds.filter(
              (item, pos) => onlyIds.indexOf(item) === pos
          );
        }
    );
    this.deleteProduct(categoryId , product._id)
  }

  deleteProduct =(categoryId , productId)=>{
    updateItem('/front/categories-with-products/'+categoryId+'/'+productId , {}).then((res)=>{
      console.log(res)
      if (res.status === 1){
        toast.success(res.message)
        window.location.href ='/homepage-management'
      }else {
        //toast.warning(res.message)
      }
    })
  }


  render() {
    const { settings, handler, actions, index, dragHandleProps } = this.props;

    return (
      <div className="card-block position-relative">
        {this.state.isDataFetching && <LoadingSpinner />}
        <div className="block-header">
          <h4 className="block-title">
            {handler && dragHandleProps && (
              <span className={"btn-draggable"} {...handler.dragHandleProps}>
                <FiMove />
              </span>
            )}
            {settings?.title || "Untitled"}
            {!dragHandleProps && (
              <span className={"text-danger"} style={{ fontSize: "12px" }}>
                please save to drag
              </span>
            )}
          </h4>

          <div className={"block-actions"}>
            <Form.Check
              inline
              checked={!settings?.is_disabled}
              type="switch"
              id={`isDisabled-${index}`}
              onChange={actions.toggleBlockSectionDisable}
              label={settings?.is_disabled ? "Off" : "On"}
            />

            <span
              onClick={this.handleSettingModalShow}
              className={"btn-block-action"}
            >
              <FiSettings />
            </span>

            <span
              onClick={actions.onDelete}
              className={"btn-block-action ml-3"}
            >
              <FiTrash />
            </span>
          </div>
        </div>

        <Modal
          show={this.state.isSettingModalShowing}
          onHide={this.handleSettingModalHide}
        >
          <Modal.Header closeButton>
            <Modal.Title>Block Settings</Modal.Title>
          </Modal.Header>
          <Form>
            <Modal.Body>
              <Form.Group controlId="blockTitle">
                <Form.Label>Block Title (ENG)</Form.Label>
                <Form.Control
                  type="text"
                  name={"title"}
                  defaultValue={settings?.title}
                  onChange={this.handleSettingInputOnChange}
                  placeholder="Enter block title in english"
                />
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Block Title (বাংলা)</Form.Label>
                <Form.Control type="text"
                              name={'title_local'}
                              defaultValue={settings?.title_local}
                              onChange={this.handleSettingInputOnChange}
                              placeholder="Enter block title in local language"/>
              </Form.Group>

              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  custom
                  defaultChecked={settings?.is_flagship?.toString() === "true"}
                  name={"is_flagship"}
                  value={"true"}
                  onChange={this.handleSettingInputOnChange}
                  type="checkbox"
                  label="Is Flagship"
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Display Product</Form.Label>
                <Form.Control
                  name={"sort_by"}
                  defaultValue={settings?.params?.sort_by}
                  onChange={this.handleSettingInputOnChange}
                  as="select"
                >
                  <option label={"--Select--"} />
                  <option value={1}>Top Selling Product</option>
                  <option value={2}>Popular Product</option>
                  <option value={3}>Custom Products</option>
                </Form.Control>
              </Form.Group>

              {this.state?.settingData?.settings?.params?.sort_by !== 3 && (
                <Form.Group controlId="dataLimit">
                  <Form.Label>Limit</Form.Label>
                  <Form.Control
                    type="number"
                    name={"limit"}
                    onChange={this.handleSettingInputOnChange}
                    defaultValue={settings?.params?.limit}
                    placeholder="Enter the product limit"
                  />
                </Form.Group>
              )}

                <Form.Group controlId="dataLimit">
                  <Form.Label>Keywords</Form.Label>
                  <Form.Control
                    type="text"
                    name={"search_string"}
                    onChange={this.handleSettingInputOnChange}
                    defaultValue={settings?.params?.search_string}
                    placeholder="Enter your keywords "
                  />
                </Form.Group>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleSettingModalHide}>
                Close
              </Button>
              <Button variant="primary" onClick={this.handleOnSaveSettingForm}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <div className="block-body">
          {!isEmptyObj(settings.params) && this.state.isConfigured ? (
            this.state.settingData.params.sort_by === "3" ? (
              <>
                <Row>
                  {this.state.selectedCustomProductList.map((product) => (
                    <Col lg={2} md={3} xs={3}>
                      {/*<HomeProduct id={this.props.setting_id} data={product} />*/}
                      <HomeProduct id={this.props.setting_id} data={product} sku_info={product.default_sku_info}
                          handleDeleteListener={()=>{
                            this.handleDeleteListener(product , index , this.props.setting_id)
                          }}/>
                    </Col>
                  ))}
                  <Col lg={2} md={3} xs={3}>
                    <Button
                      onClick={() =>
                        this.setState({
                          customProductModalShow: true,
                        })
                      }
                    >
                      Add more
                    </Button>
                  </Col>
                </Row>
                <Modal
                  size="lg"
                  show={this.state.customProductModalShow}
                  onHide={() => {
                    return this.setState({
                      customProductModalShow: false,
                    });
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Select Product</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="mb-3 form-inline">
                      <Form.Label srOnly>Search</Form.Label>
                      <Form.Control
                        className="mr-1 my-1"
                        type={`search`}
                        name="search_string"
                        size={`sm`}
                        placeholder={`Type any keyword`}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            this.handleInputOnChange(e);
                          } else {
                          }
                        }}
                        onChange={(e) => {
                          if (e.keyCode !== 13) {
                            this.setState({
                              customProductQuery: {
                                ...this.state.customProductQuery,
                                [e.target.name]: e.target.value,
                              },
                            });
                          }
                        }}
                      />
                      <div className="position-relative">
                        <AsyncTypeahead
                          filterBy={() => true}
                          id="inputStoreList"
                          isLoading={this.state.isLoadingStoreData}
                          labelKey="store_name"
                          minLength={3}
                          size={"sm"}
                          onKeyDown={() => {
                            this.setState({customProductQuery: {...this.state.customProductQuery, sid: ""}});
                          }}
                          onSearch={this.handleSearch}
                          options={this.state.storeList}
                          placeholder="Search store..."
                          renderMenuItemChildren={(store, props) => (
                            <React.Fragment>
                              <span
                                onClick={() => {
                                  this.setState({
                                    customProductQuery: {
                                      ...this.state.customProductQuery,
                                      sid: store._id,
                                    },
                                  },()=>{
                                    console.log(this.state.customProductQuery)});
                                }}
                              >
                                {store.store_name}
                              </span>
                            </React.Fragment>
                          )}
                        />
                      </div>
                      <Form.Label srOnly>Product Type</Form.Label>
                      <Form.Control
                        className="m-1"
                        as={"select"}
                        size="sm"
                        defaultValue={""}
                        name="campaign_product"
                        onChange={(e) => {
                          if (e.keyCode !== 13) {
                            this.setState({
                              customProductQuery: {
                                ...this.state.customProductQuery,
                                [e.target.name]: e.target.value,
                              },
                            });
                          }
                        }}
                      >
                        <option value="">--Product Type--</option>
                        <option value="true">Campaign Product</option>
                        <option value="false">Regular Product</option>
                      </Form.Control>
                      <Button
                        type={`button`}
                        onClick={this.handleSearchProduct}
                        size={`sm`}
                        variant={`info`}
                        className={`ml-2`}
                      >
                        Search
                      </Button>
                    </div>
                    <div className="product-checkbox-list">
                      {this.state.isDataFetching && (
                        <LoadingSpinner text={`Loading...`} />
                      )}
                      {this.state.customProductList.map((product, index) => (
                        <label
                          className={`product-item`}
                          key={product._id}
                          htmlFor={`product-${product._id}`}
                        >
                          <input
                            id={`product-${product._id}`}
                            name={`product`}
                            type="checkbox"
                            onChange={(e) =>
                              this.handleSelectedProductOnChange(e, product)
                            }
                            defaultChecked={this.isProductIncluded(product)}
                          />
                          <span className={`product`}>
                            <span className="product-img">
                              <img src={product?.mid_img} alt="" />
                            </span>
                            <span className="product-short-info">
                              <span className="product-name">
                                {product.name}{" "}
                                {product?.sku_info?.attr_combo
                                  ? product?.sku_info?.attr_combo.join("-")
                                  : ""}
                              </span>
                            </span>
                            <span className="icon">
                              <IoMdCheckmark />
                            </span>
                          </span>
                        </label>
                      ))}
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        return this.setState({
                          customProductModalShow: false,
                        });
                      }}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            ) : (
              <>
                {this.state.productList?.length > 0 ? (
                  <Row>
                    {this.state.productList.map((product) => (
                      <Col lg={2} md={3} xs={3}>
                        {/*<HomeProduct
                          id={this.props.setting_id}
                          data={product}
                        />*/}
                        <HomeProduct id={this.props.setting_id} data={product} sku_info={product.default_sku_info ? product.default_sku_info : product.sku_info} disable={true}
                            handleDeleteListener={()=>{
                              this.handleDeleteListener(product , index , this.props.setting_id)
                              this.handleDeleteListenerV2(product , index , this.props.setting_id)
                            }}/>
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <div className={"alert alert-warning text-bold text-center"}>
                    No product found.
                  </div>
                )}
              </>
            )
          ) : (
            <div className={"alert alert-warning text-center"}>
              This block has no configuration. Please{" "}
              <span
                onClick={this.handleSettingModalShow}
                style={{ cursor: "pointer" }}
                className="text-primary"
              >
                click here
              </span>{" "}
              to configure
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ProductsBlock;
