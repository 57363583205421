import React from 'react';
import {Link} from "react-router-dom";
import "../assets/scss/partner-card.scss";
import placeholderImg from "../assets/img/placeholder-img.jpg";

const PartnerCard = ({data}) => {
  return (
    <Link to={`/store/details/${data?.store_url || data?._id}`} className="partner-card">
      <img src={data.store_logo || placeholderImg} alt=""/>
    </Link>
  );
};

export default PartnerCard;