import React, {Component} from 'react';
import App from "../../App";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Moment from "moment";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {getLocalAuthData} from "../../helper/utils";

const today = Moment(new Date()).format('M/D/Y');

class CommissionOverview extends Component {
  state = {
    startDate: today,
    endDate: today
  }

  handleOnApply = (e, picker) => {
    const startDate = Moment(picker.startDate).format('M/D/Y');
    const endDate = Moment(picker.endDate).format('M/D/Y')
    this.setState({
      startDate: startDate,
      endDate: endDate,
    })
  }

  render() {
    return (
      <App layout="boxed">
        <div className="main-content">
          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Commission Overview</h4>
            </div>

            { getLocalAuthData()?.permission?.includes('commission_overview_list') ?
            <div className="block-body">
              <div className="form-inline">
                <DateRangePicker onApply={this.handleOnApply} startDate={this.state.startDate}
                                 endDate={this.state.endDate}>
                  <button
                    className="form-control form-control-sm">{`${this.state.startDate} to ${this.state.endDate}`}</button>
                </DateRangePicker>

                <select name="" className="form-control form-control-sm ml-2 mr-2">
                  <option value="">All Store</option>
                  <option value="1">Store one</option>
                  <option value="1">Store Two</option>
                  <option value="1">Store Three</option>
                </select>

                <Button size={"sm"} variant={"primary"}>Generate</Button>
              </div>

              <div className="commission-info-cards mt-4">
                <div className="commission-info">
                  <span className="title">Total Orders</span>
                  <span className="value">120</span>
                </div>
                <div className="commission-info">
                  <span className="title">Total Amount</span>
                  <span className="value">৳ 12,000.00</span>
                </div>
                <div className="commission-info">
                  <span className="title">Total Commission</span>
                  <span className="value">৳ 120.00</span>
                </div>
              </div>

              <div className="mt-4">
                <table className="order-table table table-striped">
                  <thead>
                  <tr>
                    <th style={{width: '1%'}}>#</th>
                    <th>Order</th>
                    <th>Customer</th>
                    <th>Store</th>
                    <th className="text-center">Amount(৳)</th>
                    <th className="text-right">Commission(৳)</th>
                  </tr>
                  </thead>

                  <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      <div className="order-item">
                        <h4 className="order-id"><Link to={`${process.env.PUBLIC_URL}/orders/details/123`}>#EK212</Link></h4>
                        <span className="order-date">24/02/2020</span>
                        <span className="order-time">4:30pm</span>
                      </div>
                    </td>
                    <td>
                      Md. Jalil Uddin
                    </td>
                    <td>
                      ABC Store
                    </td>
                    <td className="text-center">
                      1300.00
                    </td>
                    <td className="text-right">
                      13.00
                    </td>
                  </tr>

                  <tr>
                    <td>2</td>
                    <td>
                      <div className="order-item">
                        <h4 className="order-id"><Link to={`${process.env.PUBLIC_URL}/orders/details/123`}>#EK212</Link></h4>
                        <span className="order-date">24/02/2020</span>
                        <span className="order-time">4:30pm</span>
                      </div>
                    </td>
                    <td>
                      Md. Jalil Uddin
                    </td>
                    <td>
                      ABC Store
                    </td>
                    <td className="text-center">
                      1300.00
                    </td>
                    <td className="text-right">
                      13.00
                    </td>
                  </tr>

                  <tr>
                    <td>3</td>
                    <td>
                      <div className="order-item">
                        <h4 className="order-id"><Link to={`${process.env.PUBLIC_URL}/orders/details/123`}>#EK212</Link></h4>
                        <span className="order-date">24/02/2020</span>
                        <span className="order-time">4:30pm</span>
                      </div>
                    </td>
                    <td>
                      Md. Jalil Uddin
                    </td>
                    <td>
                      ABC Store
                    </td>
                    <td className="text-center">
                      1300.00
                    </td>
                    <td className="text-right">
                      13.00
                    </td>
                  </tr>

                  <tr>
                    <td>4</td>
                    <td>
                      <div className="order-item">
                        <h4 className="order-id"><Link to={`${process.env.PUBLIC_URL}/orders/details/123`}>#EK212</Link></h4>
                        <span className="order-date">24/02/2020</span>
                        <span className="order-time">4:30pm</span>
                      </div>
                    </td>
                    <td>
                      Md. Jalil Uddin
                    </td>
                    <td>
                      ABC Store
                    </td>
                    <td className="text-center">
                      1300.00
                    </td>
                    <td className="text-right">
                      13.00
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={4} className="text-right text-bold">
                      Total
                    </td>
                    <td className="text-center text-bold">
                      3900.00
                    </td>
                    <td className="text-right text-bold">
                      39.00
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
                :null}
          </div>
        </div>
      </App>
    );
  }
}

export default CommissionOverview;
