import React, { Component } from 'react';
import App from "../../App";
import {
  AiOutlineArrowLeft,
  FaStore,
  FiFilter,
  FiPlus,
  TiArrowUnsorted
} from "react-icons/all";
import { Link, withRouter, Redirect } from "react-router-dom";
import Rating from "react-rating";
import star from "../../assets/img/star.svg";
import starFull from "../../assets/img/star-full.svg";
import Review from "../../components/Review";
import Question from "../../components/Question";
import {
  getProductDetails,
  getProductList,
  hardDeleteProduct,
  changeProductStatus
} from "../../services/productServices";
import moment from "moment";
import axios from "axios";
import { API_BASE_URL } from "../../helper/env";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { getReviewList } from "../../services/reviewServices";
import { getLocalAuthData, getDeviceType } from "../../helper/utils";
import {activityLogPostData, updateItemPartial} from "../../services/commonServices";

class ProductDetails extends Component {
  state = {
    reviews: [],
    productDetails: null,
    isDetailLoading: false,
    redirect: false,
    status: "",
    btnText: "",
    complain: "",
    details: [],
    activeBlockTab: 'Highlights'
  }

  renderAvailabilityStatus = (qnt) => {
    if (qnt === 0) {
      return <span className="badge badge-warning">Out of Stock</span>
    } else if (qnt > 0 && qnt <= 5) {
      return <span className="badge badge-warning">Low</span>
    } else {
      return <span className="badge badge-success">Available</span>
    }
  }

  componentDidMount() {
    this.setState({ isDetailLoading: true })
    const productId = this.props.match.params.id

    getProductDetails(productId)
      .then(res => {
        console.log(res);

        let btnText = "";
        let sta = "";
        if (res.status === 1) {
          btnText = "Block"
          sta = 2
        } else {
          sta = 1
          btnText = "Unblock"
        }

        this.setState({ productDetails: res, isDetailLoading: false, status: sta, btnText: btnText })
      }).catch(errMsg => {
        console.log(errMsg);
        this.setState({ isDetailLoading: false })
      })

    this.handleGetReviews()

  }

  getComplain = (id) => {
    id = this.props.match.params.id
    const authData = getLocalAuthData();

    axios.get(API_BASE_URL + `/complain?product_id=${id}`, {
      headers: { 'x-auth-token': authData.token, 'x-api-client': getDeviceType() }
    }).then(res => {
      // this.state.complain = res.data
      this.setState({ details: res.data })



    }).catch(err => {
    })
  }

  handleStatusChange = async (e, id) => {
    updateItemPartial(`/complain/${id}`, {status: e.target.value}).then(res => {
        console.log(124, res);
    }).catch((err) => {
        console.log(err.message);
        toast.error(err.message)
    });
}

  handleGetReviews = () => {
    const productId = this.props.match.params.id
    getReviewList(productId, { page: this.state.reviewPagination?.page || 1, limit: 20 })
      .then(res => {
        this.setState({ reviews: res.product_reviews, reviewPagination: res.pagination })
      })
  }

  handleHardDeleteProduct = (id ,name,store_name) => {
    console.log(id);
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to delete this product?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => hardDeleteProduct(id)
            .then(msg => {
              toast.success(msg);
              activityLogPostData('was delete this product , store name : '+store_name,'/products/details/'+id,name)
              this.setState({ redirect: true })
            })
            .catch(errMsg => {
              toast.error(errMsg)
            })
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }

  handleProductStatus = (id, name,status,store_name) => {
    console.log(id);
    confirmAlert({
      title: 'Change Status',
      message: 'Are you sure to change this product status?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => changeProductStatus(id, status)
            .then(msg => {
              toast.success(msg);
              activityLogPostData('was changed product block/unBlock status , store name : '+store_name,'/products/details/'+id,name)

              getProductDetails(this.props.match.params.id)
                .then(res => {
                  console.log(res);

                  let btnText = "";
                  let sta = "";
                  if (status === 1) {
                    btnText = "Block"
                    sta = 2
                  } else {
                    sta = 1
                    btnText = "Unblock"
                  }

                  this.setState({ productDetails: res, isDetailLoading: false, status: sta, btnText: btnText })
                }).catch(errMsg => {
                  console.log(errMsg);
                  this.setState({ isDetailLoading: false })
                })
            })
            .catch(errMsg => {
              toast.error(errMsg)
            })
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }



  handleProductDetails = (id) => {
    return new Promise((resolve, reject) => {
      axios.get(API_BASE_URL + `/product/${this.props.match.params.id}`)
        .then(res => {
          console.log(res.data.product);
          if (res.data.status === 1) {
            this.setState({ productDetails: null, isDetailLoading: false })
            this.setState({ productDetails: res.data.product, isDetailLoading: false });
            toast.success(res.data.message)
          } else {
            toast.error(res.data.message);
          }
        })
        .catch(err => {
          reject(err.message);
        })
    })
  }

  renderStatus = (status) => {
    if (status === 1) {
      return <span className="text-success -badge -badge-success">Active</span>
    } else if (status === 2) {
      return <span className="text-warning -badge -badge-warning">Blocked</span>
    }
  }

  handleBlockTab = (label) => {
    this.setState({ activeBlockTab: label });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to='/products/list' />
    }
    const details = this.state.productDetails;

    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Product Details</h2>
          <div className="ml-auto">
            <Link to={`${process.env.PUBLIC_URL}/products/list`}
              className="btn btn-sm btn-link mr-2"><AiOutlineArrowLeft /> Back to
              Product List</Link>

            {getLocalAuthData()?.permission?.includes('product_add_') ? <Link to={`${process.env.PUBLIC_URL}/products/add`} className="btn btn-sm btn-secondary"><FiPlus /> Add
              Product</Link> : null}

          </div>
        </div>

        {getLocalAuthData()?.permission?.includes('product_item_view') ? <>
          <div className="main-content">
            <div className="card-block">
              <div className="block-body">
                <div className="product-list">
                  <div className="product-item">
                    <div className="product lg">
                      <div className="product-thumb">
                        <img src={details?.mid_img} alt="" />
                      </div>

                      <div className="product-desc">
                        <h4 className="product-title">{details?.name}</h4>
                        <p className="date mb-0"><em>Created at
                            : </em> {moment(details?.createdAt).format('LL')} | {this.renderStatus(details?.status)}</p>
                        <p className=""><span
                          className="d-inline-block mr-2"><FaStore /></span> {details?.store.store_name}</p>

                        {details?.sku_type === 1 &&
                          <div className="product-attr">
                            <div className="attr">
                              <span className="attr-title">SKU</span>
                              <span className="attr-value">{details?.sku_info[0]?.sku}</span>
                            </div>
                            <div className="attr">
                              <span className="attr-title">Sale Price</span>
                              <span className="attr-value">৳ {details?.sku_info[0]?.special_price?.toFixed(2)}</span>
                            </div>
                            <div className="attr">
                              <span className="attr-title">Regular Price</span>
                              <span className="attr-value">৳ {details?.sku_info[0]?.price?.toFixed(2)}</span>
                            </div>
                            <div className="attr">
                              <span className="attr-title">Available</span>
                              <span className="attr-value">{details?.sku_info[0].quantity}</span>
                            </div>
                            <div className="attr">
                              <span className="attr-title">Stock</span>
                              <span
                                className="attr-value">{this.renderAvailabilityStatus(details?.sku_info[0].quantity)}</span>
                            </div>
                          </div>
                        }
                      </div>

                      <div className="actions">
                        <>
                          {getLocalAuthData()?.permission?.includes('product_edit') ? <Link to={`/products/edit/${details?._id}`}
                            className="btn-action btn-block btn-outline-primary">Edit</Link> : null}
                        </>
                        <>
                          {getLocalAuthData()?.permission?.includes('product_block_unblock') ?
                            <button className="btn-action btn-block btn-outline-warning"
                              onClick={() => this.handleProductStatus(this.state.productDetails._id,this.state.productDetails.name, this.state.status ,this.state.productDetails.store.store_name)}>{this.state.btnText}</button> : null}</>
                        <>
                          {getLocalAuthData()?.permission?.includes('product_delete') ?
                            <button className="btn-action btn-block btn-outline-danger"
                              onClick={() => this.handleHardDeleteProduct(this.state.productDetails._id , this.state.productDetails.name,this.state.productDetails.store.store_name)}>Delete
                          </button> : null}</>

                      </div>
                    </div>
                  </div>
                </div>

                {details?.sku_type !== 1 &&
                  <>
                    <hr />
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Combination</th>
                          <th>Price (৳)</th>
                          <th>Special Price (৳)</th>
                          <th>Available Quantity</th>
                          <th style={{ width: '100px' }} className={"text-center"}>Stock</th>
                        </tr>
                      </thead>

                      <tbody>
                        {details?.sku_info.map((item, index) => (
                          <tr key={index}>
                            <td>{item.attr_combo.join('-')}</td>
                            <td>{item.price}</td>
                            <td>{item.special_price}</td>
                            <td>{item.quantity}</td>
                            <td className={"text-center"}>{this.renderAvailabilityStatus(item.quantity)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                }
              </div>
            </div>

            {details?.reviews?.length > 0 &&
              <div className="card-block">
                <div className="block-header">
                  <h4 className="block-title">Review</h4>
                </div>

                <div className="block-body">
                  <div className="total-rating-block">
                    <div className="total-rating">
                      <p className="rating-qnt"><span>4.5</span><span className="text-muted out-of">/5</span></p>

                      <Rating
                        className="review-rating rating-lg"
                        readonly={true}
                        emptySymbol={
                          <img src={star} className="rating-icon" alt="" />
                        }
                        fullSymbol={
                          <img
                            src={starFull}
                            className="rating-icon"
                            alt=""
                          />
                        }
                        initialRating={0} />

                      <div><small>0 Ratings</small></div>
                    </div>

                    <div className="rating-segment">
                      <div className="segment-item">
                        <Rating
                          className="review-rating mr-3"
                          readonly={true}
                          emptySymbol={
                            <img src={star} className="rating-icon" alt="" />
                          }
                          fullSymbol={
                            <img
                              src={starFull}
                              className="rating-icon"
                              alt=""
                            />
                          }
                          initialRating={5} />

                        <span className="rating-bar">
                          <span style={{ width: '0%' }} className="rating-progress" />
                        </span>
                        <span className="rating-qnt">0</span>
                      </div>

                      <div className="segment-item">
                        <Rating
                          className="review-rating mr-3"
                          readonly={true}
                          emptySymbol={
                            <img src={star} className="rating-icon" alt="" />
                          }
                          fullSymbol={
                            <img
                              src={starFull}
                              className="rating-icon"
                              alt=""
                            />
                          }
                          initialRating={4} />

                        <span className="rating-bar">
                          <span style={{ width: '0%' }} className="rating-progress" />
                        </span>

                        <span className="rating-qnt">0</span>
                      </div>

                      <div className="segment-item">
                        <Rating
                          className="review-rating mr-3"
                          readonly={true}
                          emptySymbol={
                            <img src={star} className="rating-icon" alt="" />
                          }
                          fullSymbol={
                            <img
                              src={starFull}
                              className="rating-icon"
                              alt=""
                            />
                          }
                          initialRating={3} />

                        <span className="rating-bar">
                          <span style={{ width: '0%' }} className="rating-progress" />
                        </span>

                        <span className="rating-qnt">0</span>
                      </div>

                      <div className="segment-item">
                        <Rating
                          className="review-rating mr-3"
                          readonly={true}
                          emptySymbol={
                            <img src={star} className="rating-icon" alt="" />
                          }
                          fullSymbol={
                            <img
                              src={starFull}
                              className="rating-icon"
                              alt=""
                            />
                          }
                          initialRating={2} />

                        <span className="rating-bar">
                          <span style={{ width: '0%' }} className="rating-progress" />
                        </span>

                        <span className="rating-qnt">0</span>
                      </div>

                      <div className="segment-item">
                        <Rating
                          className="review-rating mr-3"
                          readonly={true}
                          emptySymbol={
                            <img src={star} className="rating-icon" alt="" />
                          }
                          fullSymbol={
                            <img
                              src={starFull}
                              className="rating-icon"
                              alt=""
                            />
                          }
                          initialRating={1} />

                        <span className="rating-bar">
                          <span style={{ width: '0%' }} className="rating-progress" />
                        </span>

                        <span className="rating-qnt">0</span>
                      </div>
                    </div>
                  </div>

                  <div className="product-review-header">
                    <div className="product-title">Product Reviews</div>
                    <div className="review-filters">
                      <div className="review-filter">
                        <TiArrowUnsorted />
                        <span className="text-muted">Sort: </span> Relevance
                      </div>
                      <div className="review-filter">
                        <FiFilter />
                        <span className="text-muted">Filter: </span> All
                      </div>
                    </div>
                  </div>

                  <div className="product-reviews">
                    {details?.reviews.map((review, index) => (
                      <Review key={index} data={review} />
                    ))}
                  </div>
                </div>
              </div>}

            <div className="card-block">
              <ul className="desc-header-nav">
                <li className={this.state.activeBlockTab === 'Highlights' ? 'active' : ''} value="">
                  <span onClick={() => this.handleBlockTab('Highlights')}>Highlights</span>
                </li>
                <li className={this.state.activeBlockTab === 'Description' ? 'active' : ''} value="">
                  <span onClick={() => this.handleBlockTab('Description')}>Description</span>
                </li>
                {this.state.reviews?.length > 0 &&
                  <li className={this.state.activeBlockTab === 'Reviews' ? 'active' : ''}>
                    <span onClick={() => this.handleBlockTab('Reviews')}>Reviews</span>
                  </li>}
                <li className={this.state.activeBlockTab === 'Question' ? 'active' : ''}>
                  <span onClick={() => this.handleBlockTab('Question')}>Question</span>
                </li>
                <li className={this.state.activeBlockTab === 'FAQ' ? 'active' : ''}>
                  <span onClick={() => this.handleBlockTab('FAQ')}>FAQ</span>
                </li>
                <li className={this.state.activeBlockTab === 'Bulk Requests' ? 'active' : ''}>
                  <span onClick={() => this.handleBlockTab('Bulk Requests')}>Bulk Requests</span>
                </li>
                <li className={this.state.activeBlockTab === 'Complain' ? 'active' : ''}>
                  <span onClick={() => {
                    this.handleBlockTab('Complain');
                    this.getComplain();
                  }}>Complain</span>
                  {/* onClick={() => this.getComplain()} */}
                </li>
              </ul>

              <div className="block-body">
                {this.state.activeBlockTab === 'Highlights' &&
                  <div className={`entry-content`}
                    dangerouslySetInnerHTML={{ __html: details?.highlights }} />}

                {this.state.activeBlockTab === 'Description' &&
                  <div className={`entry-content`}
                    dangerouslySetInnerHTML={{ __html: details?.product_description }} />}

                {this.state.activeBlockTab === 'Reviews' &&
                  <>
                    {this.state.reviews?.length > 0 &&
                      <>
                        <div className="total-rating-block">
                          <div className="total-rating">
                            <p className="rating-qnt"><span>{details?.avg_score}</span><span
                              className="text-muted out-of">/5</span></p>

                            <Rating
                              className="review-rating rating-lg"
                              readonly={true}
                              emptySymbol={
                                <img src={star} className="rating-icon" alt="" />
                              }
                              fullSymbol={
                                <img
                                  src={starFull}
                                  className="rating-icon"
                                  alt=""
                                />
                              }
                              initialRating={details?.avg_score} />

                            <div><small>{details?.t_n_of_score} Ratings</small></div>
                          </div>

                          <div className="rating-segment">
                            <div className="segment-item">
                              <Rating
                                className="review-rating mr-3"
                                readonly={true}
                                emptySymbol={
                                  <img src={star} className="rating-icon" alt="" />
                                }
                                fullSymbol={
                                  <img
                                    src={starFull}
                                    className="rating-icon"
                                    alt=""
                                  />
                                }
                                initialRating={5} />

                              <span className="rating-bar">
                                <span style={{ width: '0%' }} className="rating-progress" />
                              </span>
                              <span className="rating-qnt">{details?.review_cumulative_score?.five_star}</span>
                            </div>

                            <div className="segment-item">
                              <Rating
                                className="review-rating mr-3"
                                readonly={true}
                                emptySymbol={
                                  <img src={star} className="rating-icon" alt="" />
                                }
                                fullSymbol={
                                  <img
                                    src={starFull}
                                    className="rating-icon"
                                    alt=""
                                  />
                                }
                                initialRating={4} />

                              <span className="rating-bar">
                                <span style={{ width: '0%' }} className="rating-progress" />
                              </span>

                              <span className="rating-qnt">{details?.review_cumulative_score?.four_star}</span>
                            </div>

                            <div className="segment-item">
                              <Rating
                                className="review-rating mr-3"
                                readonly={true}
                                emptySymbol={
                                  <img src={star} className="rating-icon" alt="" />
                                }
                                fullSymbol={
                                  <img
                                    src={starFull}
                                    className="rating-icon"
                                    alt=""
                                  />
                                }
                                initialRating={3} />

                              <span className="rating-bar">
                                <span style={{ width: '0%' }} className="rating-progress" />
                              </span>

                              <span className="rating-qnt">{details?.review_cumulative_score?.three_star}</span>
                            </div>

                            <div className="segment-item">
                              <Rating
                                className="review-rating mr-3"
                                readonly={true}
                                emptySymbol={
                                  <img src={star} className="rating-icon" alt="" />
                                }
                                fullSymbol={
                                  <img
                                    src={starFull}
                                    className="rating-icon"
                                    alt=""
                                  />
                                }
                                initialRating={2} />

                              <span className="rating-bar">
                                <span style={{ width: '0%' }} className="rating-progress" />
                              </span>

                              <span className="rating-qnt">{details?.review_cumulative_score?.two_star}</span>
                            </div>

                            <div className="segment-item">
                              <Rating
                                className="review-rating mr-3"
                                readonly={true}
                                emptySymbol={
                                  <img src={star} className="rating-icon" alt="" />
                                }
                                fullSymbol={
                                  <img
                                    src={starFull}
                                    className="rating-icon"
                                    alt=""
                                  />
                                }
                                initialRating={1} />

                              <span className="rating-bar">
                                <span style={{ width: '0%' }} className="rating-progress" />
                              </span>

                              <span className="rating-qnt">{details?.review_cumulative_score?.one_star}</span>
                            </div>
                          </div>
                        </div>

                        <div className="product-review-header">
                          <div className="product-title">Product Reviews</div>
                          {/*<div className="review-filters">
                      <div className="review-filter">
                        <TiArrowUnsorted/>
                        <span className="text-muted">Sort: </span> Relevance
                      </div>
                      <div className="review-filter">
                        <FiFilter/>
                        <span className="text-muted">Filter: </span> All
                      </div>
                    </div>*/}
                        </div>

                        <div className="product-reviews">
                          {this.state.reviews.map((review, index) => (
                            <Review key={index} getReviews={this.handleGetReviews} data={review} />
                          ))}
                        </div>
                      </>
                    }
                  </>}

                {this.state.activeBlockTab === 'Question' &&
                  <>
                    {details?.product_qas?.length > 0 ?
                      <div className="q-a-list">
                        {details?.product_qas.map((question, i) => (
                          <Question key={i} data={question} getProductDetail={() => this.handleProductDetails()} />
                        ))}
                      </div>
                      :
                      <div className={'alert alert-warning'}>
                        <strong>No Questions</strong>
                      </div>
                    }
                  </>}

                {this.state.activeBlockTab === 'FAQ' &&
                  <>
                    {details?.faq?.length > 0 ?
                      <div className="q-a-list">
                        {details.faq.map((item, index) => (
                          <div key={index} className="item">
                            <div className="question">
                              <span className="symbol">Q</span>
                              <span className="text">{item.question}</span>
                            </div>

                            <div className="answer">
                              <span className="symbol">A</span>
                              <span className='text'>{item.answer}</span>
                            </div>

                            {/*<div className="q-a-actions">
                          <span onClick={this.handleToggleReplyBox} className="text-info"><FiEdit/> Edit</span>
                          <span color="text-danger"><FiTrash/> Delete</span>
                      </div>*/}
                          </div>
                        ))
                        }
                      </div>
                      :
                      <div className={'alert alert-warning'}>
                        <strong>No FAQ Found</strong>
                      </div>}
                  </>
                }

                {this.state.activeBlockTab === 'Bulk Requests' && <>
                  <table className="order-table table table-striped">
                    <thead>
                      <tr>
                        <th style={{ width: '1%' }}>#</th>
                        <th>Date</th>
                        <th style={{ width: '150px' }}>Customer Info</th>
                        <th>Product Info</th>
                        <th>Message</th>
                        <th style={{ width: '1%' }}>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {details?.bulk_requests?.length > 0 ?
                        details.bulk_requests.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td style={{ width: '150px' }}>
                                <div className="order-item">
                                  <span className="order-date">{moment(item.createdAt).format('ll')}</span>
                                  <span className="order-time">{moment(item.createdAt).format('LT')}</span>
                                </div>
                              </td>
                              <td style={{ width: '150px' }}>
                                <div className="order-item">
                                  <h4 className="order-id">{item?.user_info?.name}</h4>
                                  <ul className="ordered-items">
                                    <li>{item.user_info?.phone}</li>
                                    <li>{item.user_info?.email}</li>
                                  </ul>
                                </div>
                              </td>
                              <td>
                                <Link target={'_blank'}
                                  to={`/products/details/${item?.product_info?._id}`}>{item?.product_info?.name}</Link>
                              </td>
                              <td>{item.message}</td>
                              <td>{item.quantity}</td>
                            </tr>
                          )
                        })
                        :
                        <tr>
                          <td colSpan={7} className={`text-bold text-center`}>
                            No data found
                            </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </>
                }




                {this.state.activeBlockTab === 'Complain' && <>

                  {this.state.details?.items?.length > 0 ?
                    <div className="q-a-list">
                      <table className="order-table table table-striped">
                                <thead>
                                <tr>
                                    <th style={{width: "19%"}}>Product Name</th>
                                    <th style={{width: "13%"}}>User Name</th>
                                    <th style={{width: "13%"}}>Store Name</th>
                                    <th style={{width: "25%"}}>Complain</th>
                                    <th style={{width: "13%"}}>Change Status</th>
                                    <th style={{width: "13%"}}>Delete</th>
                                </tr>
                                </thead>

                                <tbody>
                                {this.state.details.items.map((item, index) => (
                                    <tr>
                                        <th style={{width: "19%"}}>{item?.user_info?.name}</th>
                                        <th style={{width: "13%"}}>{item?.user_info?.name}</th>
                                        <th style={{width: "13%"}}>{item?.store_info?.store_name}</th>
                                        <th style={{width: "25%"}}>{item?.complain?.map((complains) => (
                                            <li key={complains.toString()}>{complains}</li>
                                        ))}</th>

                                        <th style={{width: "13%"}}>
                                            <select
                                            defaultValue ={item.status}
                                                className="form-control form-control-sm"
                                                onChange={(e) => this.handleStatusChange(e, item._id)}>
                                                <option value="">--Select--</option>
                                                <option value="1">onReview</option>
                                                <option value="2">solved</option>
                                            </select>
                                        </th>

                                        <th style={{width: "1%"}}>
                                            {/* <button onClick={() => this.deletePermanent(complan._id)} className="btn-action btn-block btn-outline-danger">
                                                Delete
                                            </button> */}

                                        </th>
                                    </tr>
                                ))}
                                </tbody>

                            </table>
                      <div>
                        {/* <table className="order-table table table-striped">
                          <thead>
                            <tr>
                              <th >User Name</th>
                              <th >Store Name</th>
                              <th >Complain</th>
                              <th >Change Status</th>
                              <th >Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.details.items.map((item, index) => (
                              <div key={index} className="item">
                                <tr>
                                  <th >{item.user_info.name}</th>
                                  <th >{item.store_info.store_name}</th>
                                  <th >Complain</th>
                                  <th >Change Status</th>
                                  <th >Delete</th>
                                </tr>
                                <div className="question">
                                  <span className="symbol">Q</span>
                                  <span className="text"></span>
                                  <span className="text"></span>
                                </div>
                                <div className="question">
                                  <span className="text">{item.store_info.store_name}</span>
                                </div>
                              </div>
                            ))
                            }

                          </tbody>
                        </table> */}

                      </div>


                    </div>
                    :
                    <div className={'alert alert-warning'}>
                      <strong>No Complain Found</strong>
                    </div>}
                </>

                }

              </div>
            </div>
          </div>
        </> : null}

      </App>
    );
  }
}

export default withRouter(ProductDetails);
