import axios from 'axios';
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData} from "../helper/utils";

export const getSummeryReports = () => {
  const authData = getLocalAuthData()
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}//report/summary`, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data.summary_reports)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        //console.log(err)
        reject(err.message)
      })
  })
}