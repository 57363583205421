import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {getChildLocations, getLocationList} from "../../services/locationServices";
import BrowseFiles from "../../components/BrowseFiles";
import {getMerchantType} from "../../services/merchantTypeServices";
import {addMerchant} from "../../services/merchantServices";
import {toast} from "react-toastify";
import {getLocalAuthData} from "../../helper/utils";
import {checkIsExisting, getTableData} from "../../services/commonServices";
import {getBankList} from "../../services/settingServices";
import Select from "react-select";
import styleReactSelect from "../../assets/styleReactSelect";

class AddEntrepreneur extends Component {
  state = {
    activeBlockTab: 'General',
    countries: [],
    businessChildLocations: [],
    merchantTypes: [],
    formData: {
      bank_info: {},
      is_flagship: false,
      ecommerce_partner: false,
      commission_available_for: {
        admin: true,
        merchant: true,
      },
    },
    isLoading: false,
    merchant_id: null,
    isAdding: false,
    merchantInfo: null,
    isFormValidated: false,
    phoneExistingStatus: 2,
    phoneExistingMsg: null,
    emailExistingStatus: 2,
    emailExistingMsg: null,
    businessNameExistingStatus: 2,
    businessNameExistingMsg: null,
    splittedPhone: ['+880', ''],
    bankList: []
  };

  componentDidMount() {
    const authData = getLocalAuthData();
    this.setState({merchant_id: authData.merchant_id})

    getTableData('/district', {lite_data: true}).then(res => {
      const districtList = [];
      res.items.forEach(item => {
        const district = {
          id: item._id,
          value: item._id,
          label: item.name
        }
        districtList.push(district)
      })
      this.setState({districtList})
    })

    getLocationList()
      .then(res => {
        console.log('Res: ', res)
        this.setState({countries: res})
      })

    getMerchantType()
      .then(res => {
        this.setState({merchantTypes: res}, () => {
          console.log(this.state.merchantTypes);
        })
      })

    getBankList()
      .then(res => {
        this.setState({bankList: res})
      })
      .catch(errMsg => {
        console.log(errMsg)
      })
  }

  handleBlockTab = label => {
    this.setState({
      activeBlockTab: label
    })
  };

  handleLocationInputOnChange = (e, index, typePurpose) => {
    const childLocations = [...this.state[typePurpose]];
    this.handleInputOnChange(e);
    if (e.target.value !== '') {
      getChildLocations(e.target.value)
        .then(res => {
          childLocations.splice(index, childLocations.length - index);
          if (res.length > 0) {
            childLocations[index] = res
          }
          this.setState({[typePurpose]: childLocations})
        })
        .catch(errMsg => {
          console.log(errMsg)
          childLocations.splice(index, childLocations.length - index)
          this.setState({[typePurpose]: childLocations})
        })
    } else {
      childLocations.splice(index, childLocations.length - index);
      this.setState({[typePurpose]: childLocations})
    }
  }

  handleInputOnChange = (e) => {
    const formData = {...this.state.formData}
    formData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({formData})
  }

  handleCommissionAvailability = (e) => {
    const formData = {...this.state.formData}
    formData['commission_available_for'][e.target.name] = e.target.checked;
    this.setState({formData})
    console.log(this.state.formData);
  }

  handleBankInputOnChange = (e) => {
    const formData = {...this.state.formData}
    formData.bank_info[e.target.name] = e.target.value;
    this.setState({formData})
  }

  handleSubmitForm = e => {
    e.preventDefault();
    const formData = {...this.state.formData};
    formData.phone = this.state.splittedPhone.join('-')

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({isFormValidated: true})
    } else {
      this.setState({formData, isAdding: true}, () => {
        addMerchant(this.state.formData)
          .then(res => {
            this.setState({isAdding: false, isFormValidated: false});
            toast.success(res.message)
            this.props.history.push(`${process.env.PUBLIC_URL}/entrepreneurs/list`);
          })
          .catch(errMsg => {
            this.setState({isAdding: false, isFormValidated: false});
            toast.error(errMsg);
          })
      })
    }
  }

  handleOnBrowsePic = (files, name) => {
    const formData = {...this.state.formData}
    formData[name] = files[0].actual_img;
    this.setState({formData})
  }

  handleBankOnBrowsePic = (files, name) => {
    const formData = {...this.state.formData}
    formData.bank_info[name] = files[0].actual_img;
    this.setState({formData})
  }

  handleRemoveImage = (name) => {
    const formData = {...this.state.formData}
    formData[name] = ''
    this.setState({formData})
  }

  handleBankRemoveImage = (name) => {
    const formData = {...this.state.formData}
    formData.bank_info[name] = ''
    this.setState({formData})
  }

  checkIsExisting = (type, data, msgKey, statusKey) => {
    checkIsExisting(type, data)
      .then(res => {
        if (res.status === 2) {
          this.setState({[msgKey]: res.message, [statusKey]: res.status})
        } else {
          this.setState({[msgKey]: null, [statusKey]: res.status})
        }
      })
      .catch(errMsg => {
        console.log(errMsg)
      })
  }

  inputPhoneOnChange = (e, index) => {
    const splittedPhone = [...this.state.splittedPhone]
    splittedPhone[index] = e.target.value
    this.setState({splittedPhone})
  }



  handleSelectMultiOnChange = (array, name) => {
    const formData = {...this.state.formData};
    const data = [];
    if (array) {
      array.forEach(val => {
        data.push({id:val.id, label:val.label,value:val.label})
      })
    }
    formData[name] = data;
    this.setState({formData})
  }



  render() {
    const {formData} = this.state;

    return (
      <App layout="boxed">

        {getLocalAuthData()?.permission?.includes('entrepreneurs_add') ?  <>
          <div className="page-header">
            <h2 className="page-title">Add Merchant</h2>
          </div>
          <div className="card-block">
            <div className="block-body">
              {this.state.isLoading ?
                  <div>Loading...</div>
                  :
                  <>
                    {this.state.activeBlockTab === 'General' &&
                    <Form noValidate validated={this.state.isFormValidated}
                          onSubmit={this.handleSubmitForm}>
                      <Form.Group as={Row} controlId="">
                        <Form.Label column sm="3"/>

                        <Col sm="9" md={8} lg={7}>
                          <strong>Seller Account</strong>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="displayOrShopName">
                        <Form.Label column sm="3">
                          Merchant Type <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          {this.state.merchantTypes.length > 0 &&
                          this.state.merchantTypes.map((type, index) => {
                            return (
                                <Form.Check
                                    key={index}
                                    custom
                                    required
                                    checked={type._id === formData?.merchant_type}
                                    type="radio"
                                    name="merchant_type"
                                    value={type._id}
                                    id={`merchantType-${index}`}
                                    label={type.type_name}
                                    onChange={this.handleInputOnChange}
                                />
                            )
                          })
                          }
                          <Form.Control.Feedback type="invalid">Field required</Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="nameId">
                        <Form.Label column sm="3">
                          Owner Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control
                              name={"owner_name"}
                              required
                              defaultValue={formData?.owner_name}
                              onChange={this.handleInputOnChange} type="text"/>
                          <Form.Control.Feedback type="invalid">Please enter owner
                            name</Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="sellerEmail">
                        <Form.Label column sm="3">
                          Email Address <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control name={"email"}
                                        required
                                        onBlur={() => this.checkIsExisting('email', {
                                          email: this.state.formData.email
                                        }, 'emailExistingMsg', 'emailExistingStatus')}
                                        defaultValue={formData?.email}
                                        onChange={this.handleInputOnChange}
                                        type="email"/>

                          {this.state.emailExistingMsg && <Form.Text className={'text-danger'}>
                            {this.state.emailExistingMsg}
                          </Form.Text>}

                          <Form.Control.Feedback type="invalid">Please enter a valid email
                            address</Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="phoneNumber">
                        <Form.Label column sm="3">
                          Phone Number <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <div className="input-group">
                            <Form.Control as={'select'}
                                          name={'code'}
                                          required
                                          value={this.state?.splittedPhone ? this.state?.splittedPhone[0] : ''}
                                          onChange={e => this.inputPhoneOnChange(e, 0)}
                                          style={{
                                            width: `80px`,
                                            paddingRight: '10px',
                                            flex: '0 0 80px'
                                          }}>

                              <option value="">---</option>
                              {this.state.countries.map((country, index) => (
                                  <option key={index} value={country.code}>{country.code}</option>
                              ))}
                            </Form.Control>

                            <Form.Control required
                                          onBlur={() => this.checkIsExisting('phone', {
                                            country_code: this.state.splittedPhone[0],
                                            phone: this.state.splittedPhone[1]
                                          }, 'phoneExistingMsg', 'phoneExistingStatus')}
                                          onChange={e => this.inputPhoneOnChange(e, 1)}
                                          type="text" placeholder={`Phone number`}
                                          maxLength="10" minLength="10"/>
                          </div>

                          {this.state.phoneExistingMsg && <Form.Text className={'text-danger'}>
                            {this.state.phoneExistingMsg}
                          </Form.Text>}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="">
                        <Form.Label column sm="3">
                          ID Type <span className="text-danger">*</span>
                        </Form.Label>

                        <Col sm="9" md={8} lg={7}>
                          <Form.Control
                              onChange={this.handleInputOnChange}
                              name={"id_type"}
                              required
                              value={formData?.id_type}
                              as={"select"}>0
                            <option value="">--Select--</option>
                            <option value="NID">NID</option>
                          </Form.Control>

                          <Form.Control.Feedback type="invalid">Please select ID
                            type</Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="">
                        <Form.Label column sm="3">
                          Identification Number <span className="text-danger">*</span>
                        </Form.Label>

                        <Col sm="9" md={8} lg={7}>
                          <Form.Control name={'identification_number'}
                                        required
                                        defaultValue={formData?.identification_number}
                                        onChange={this.handleInputOnChange}
                                        type={"text"}/>

                          <Form.Control.Feedback type="invalid">Please enter Identification
                            Number.</Form.Control.Feedback>
                        </Col>
                      </Form.Group>



                      {/*District Branding*/}
                      <Form.Group as={Row} controlId="forStores">
                        <Form.Label column sm="3">
                          District Brand
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Select
                              isMulti
                              /*value={this.state.formData.district_brand}*/
                              styles={styleReactSelect}
                              placeholder="Select District..."
                              onChange={(value) => {
                                this.handleSelectMultiOnChange(value, 'district_brand')
                              }}
                              options={this.state.districtList}
                              name="attributes"
                              noOptionsMessage={() => 'No district found'}
                          />
                        </Col>
                      </Form.Group>




                      <Form.Group as={Row} controlId="">
                        <Form.Label column sm="3">
                          Upload ID Copy - Front Side <span className="text-danger">*</span>
                        </Form.Label>

                        <Col sm="9" md={8} lg={7}>
                          <BrowseFiles
                              isSingle={true}
                              image_category={'profile'}
                              images={this.state.formData?.id_front_img ? [{thumb_img: this.state.formData.id_front_img}] : []}
                              onRemoveItem={() => this.handleRemoveImage('id_front_img')}
                              onGetFiles={(files) => {
                                this.handleOnBrowsePic(files, 'id_front_img')
                              }}/>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="">
                        <Form.Label column sm="3">
                          Upload ID Copy - Back Side <span className="text-danger">*</span>
                        </Form.Label>

                        <Col sm="9" md={8} lg={7}>
                          <BrowseFiles
                              image_category={'profile'}
                              images={this.state.formData?.id_back_img ? [{thumb_img: this.state.formData.id_back_img}] : []}
                              onRemoveItem={() => this.handleRemoveImage('id_back_img')}
                              onGetFiles={(files) => {
                                this.handleOnBrowsePic(files, 'id_back_img')
                              }}/>
                        </Col>
                      </Form.Group>




                      {/*Business Information*/}
                      <Form.Group as={Row} controlId="displayOrShopName">
                        <Form.Label column sm="3"/>
                        <Col sm="9" md={8} lg={7}>
                          <strong>Business Information</strong>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="displayOrShopName">
                        <Form.Label column sm="3">
                          Business Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control
                              name={"name"}
                              required
                              onBlur={() => this.checkIsExisting('business_name', {
                                name: this.state.formData.name
                              }, 'businessNameExistingMsg', 'businessNameExistingStatus')}
                              defaultValue={formData?.name}
                              onChange={this.handleInputOnChange}
                              type="text"/>

                          {this.state.businessNameExistingMsg && <Form.Text className={'text-danger'}>
                            {this.state.businessNameExistingMsg}
                          </Form.Text>}

                          <Form.Control.Feedback type="invalid">Please enter business
                            name.</Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="displayBusinessRegNumber">
                        <Form.Label column sm="3">
                          Business Registration Number
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control
                              name={"business_registration_number"}
                              defaultValue={formData?.business_registration_number}
                              onChange={this.handleInputOnChange}
                              type="text"/>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="">
                        <Form.Label column sm="3">
                          Legal Form
                        </Form.Label>

                        <Col sm="9" md={8} lg={7}>
                          <Form.Control
                              defaultValue={formData?.legal_form}
                              onChange={this.handleInputOnChange}
                              name={"legal_form"}
                              as="select">
                            <option value="">--Select Legal Form--</option>
                            <option value="Proprietorship">Proprietorship</option>
                            <option value="Partnership company">Partnership company</option>
                            <option value="Limited company">Limited company</option>
                          </Form.Control>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="">
                        <Form.Label column sm="3">
                          Address <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control name={"address"}
                                        required
                                        defaultValue={formData?.address}
                                        onChange={this.handleInputOnChange}
                                        type={"text"}/>

                          <Form.Control.Feedback type="invalid">Please enter
                            address.</Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}
                                  controlId="formCountries">
                        <Form.Label column sm="3">
                          Country <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control
                              name={"country"}
                              required
                              defaultValue={this.state.formData.country}
                              onChange={(e) => this.handleLocationInputOnChange(e, 0, 'businessChildLocations')}
                              as="select">
                            <option value="">--Select Country--</option>
                            {this.state.countries.map((country, index) => {
                              let selected = false;
                              if (formData?.country === country._id) {
                                selected = true;
                              }
                              return (
                                  <option
                                      key={index}
                                      value={country._id}
                                      selected={selected}
                                  >{country.name}</option>
                              );
                            })}
                          </Form.Control>

                          <Form.Control.Feedback type="invalid">Please select
                            country.</Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      {this.state.businessChildLocations.length > 0 &&
                      this.state.businessChildLocations.map((location, index) => (
                          <Form.Group key={index} as={Row} controlId={`location${location[0].type}`}>
                            <Form.Label column sm="3">{location[0].type}</Form.Label>
                            <Col sm="9" md={8} lg={7}>
                              <Form.Control as="select"
                                            name={location[0].type.toLowerCase()}
                                            onChange={(e) => this.handleLocationInputOnChange(e, index + 1, 'businessChildLocations')}>
                                <option value="">--Select--</option>
                                {location.map((item, index) => (
                                    <option key={index} value={item._id}
                                        // selected={formData && formData.[item.name] === item._id ? "true" : "false"}
                                    >{item.name}</option>
                                ))}
                              </Form.Control>
                            </Col>
                          </Form.Group>
                      ))
                      }

                      <Form.Group as={Row} controlId="">
                        <Form.Label column sm="3">
                          Postcode <span className="text-danger">*</span>
                        </Form.Label>

                        <Col sm="9" md={8} lg={7}>
                          <Form.Control
                              name={"postcode"}
                              required
                              defaultValue={formData?.postcode}
                              onChange={this.handleInputOnChange}
                              type={"text"}/>

                          <Form.Control.Feedback type="invalid">Please enter
                            postcode.</Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="">
                        <Form.Label column sm="3">
                          Geolocation
                        </Form.Label>

                        <Col sm="9" md={8} lg={7}>
                          <Row>
                            <Col sm={'6'}>
                              <Form.Control
                                  name={"latitude"}
                                  defaultValue={formData?.latitude}
                                  onChange={this.handleInputOnChange}
                                  placeholder={'Latitude'}
                                  type={"text"}/>
                            </Col>
                            <Col sm={'6'}>
                              <Form.Control
                                  name={"longitude"}
                                  defaultValue={formData?.longitude}
                                  onChange={this.handleInputOnChange}
                                  placeholder={'Longitude'}
                                  type={"text"}/>
                            </Col>
                          </Row>
                        </Col>
                      </Form.Group>

                      {/*Bank Information*/}
                      <Form.Group as={Row} controlId="">
                        <Form.Label column sm="3"/>

                        <Col sm="9" md={8} lg={7}>
                          <strong>Bank Information</strong>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="">
                        <Form.Label column sm="3">
                          Bank Account Name <span className="text-danger">*</span>
                        </Form.Label>

                        <Col sm="9" md={8} lg={7}>
                          <Form.Control name={"bank_account_name"}
                                        required
                                        defaultValue={formData?.bank_info?.bank_account_name}
                                        onChange={this.handleBankInputOnChange}
                                        type={"text"}/>

                          <Form.Control.Feedback type="invalid">Please select bank account
                            name.</Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="">
                        <Form.Label column sm="3">
                          Account Number <span className="text-danger">*</span>
                        </Form.Label>

                        <Col sm="9" md={8} lg={7}>
                          <Form.Control
                              name={"bank_account_number"}
                              required
                              defaultValue={formData?.bank_info?.bank_account_number}
                              onChange={this.handleBankInputOnChange}
                              type={"text"}/>
                          <Form.Control.Feedback type="invalid">Please enter bank account
                            number.</Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="">
                        <Form.Label column sm="3">
                          Bank Name <span className="text-danger">*</span>
                        </Form.Label>

                        <Col sm="9" md={8} lg={7}>
                          {/*<Form.Control
                        name={"bank_name"}
                        required
                        onChange={this.handleBankInputOnChange}
                        as={"select"}>
                        <option value="">--Select Bank--</option>
                        <option
                          selected={formData?.bank_info?.bank_name === 'Agrani Bank Ltd'}
                          value="Agrani Bank Ltd">Agrani Bank Ltd
                        </option>
                        <option
                          selected={formData?.bank_info?.bank_name === 'Sonali Bank Ltd'}
                          value="Sonali Bank Ltd">Sonali Bank Ltd
                        </option>
                        <option
                          selected={formData?.bank_info?.bank_name === 'Estern Bank Lit'}
                          value="Estern Bank Lit">Estern Bank Lit
                        </option>
                        <option
                          selected={formData?.bank_info?.bank_name === 'Dutch Bangla Bank Ltd'}
                          value="Dutch Bangla Bank Ltd">Dutch Bangla Bank Ltd
                        </option>
                      </Form.Control>*/}

                          <Form.Control
                              name={"bank_name"}
                              required
                              value={this.state.formData.bank_info.bank_name}
                              onChange={(e) => {
                                this.handleBankInputOnChange(e)
                                const bank = this.state.bankList.filter(obj => {
                                  return obj._id === e.target.value
                                })

                                if (bank.length > 0) {
                                  this.setState({branchNameList: bank[0].branches})
                                } else {
                                  this.setState({branchNameList: []})
                                }
                              }}
                              as={"select"}>
                            <option value="">--Select Bank--</option>
                            {this.state.bankList.map((bank, index) => (
                                <option
                                    key={index}
                                    selected={formData?.bank_info?.bank_name === 'Agrani Bank Ltd'}
                                    value={bank._id}>{bank.name}</option>
                            ))}
                          </Form.Control>

                          <Form.Control.Feedback type="invalid">Please select a
                            bank</Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="">
                        <Form.Label column sm="3">
                          Branch Name
                        </Form.Label>

                        <Col sm="9" md={8} lg={7}>
                          <Form.Control
                              required
                              name={"bank_account_branch"}
                              defaultValue={formData?.bank_info?.bank_account_branch || ''}
                              onChange={this.handleBankInputOnChange} type={"text"}/>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="">
                        <Form.Label column sm="3">
                          Upload Bank Documents <span className="text-danger">*</span>
                        </Form.Label>

                        <Col sm="9" md={8} lg={7}>
                          <BrowseFiles
                              images={this.state.formData?.bank_info?.bank_account_cheque_img ? [{thumb_img: this.state.formData.bank_info?.bank_account_cheque_img}] : []}
                              onRemoveItem={() => this.handleBankRemoveImage('bank_account_cheque_img')}
                              image_category={'profile'}
                              onGetFiles={(files) => {
                                this.handleBankOnBrowsePic(files, 'bank_account_cheque_img')
                              }}/>
                        </Col>
                      </Form.Group>

                      <hr/>
                      {/*Administrative Setting*/}
                      <Form.Group as={Row} controlId="">
                        <Form.Label column sm="3">
                          <strong>Administrative Setting</strong>
                        </Form.Label>

                        <Col sm="9" md={8} lg={7}></Col>
                      </Form.Group>


                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Flagship
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Check
                              custom inline type="checkbox"
                              name="is_flagship" id={`isFlagship`}
                              label={``} value={true} onChange={this.handleInputOnChange}
                              checked={this.state.formData?.is_flagship}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Ecommerce Partner
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Check
                              custom inline type="checkbox"
                              name="ecommerce_partner" id={`ecommercePartner`}
                              label={``} value={true} onChange={this.handleInputOnChange}
                              checked={this.state.formData?.ecommerce_partner}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Commission Available For
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Check
                              custom inline type="checkbox"
                              name="admin" id={`commissionAvailableForAdmin`}
                              label={`Admin`} value={true} checked={this.state.formData.commission_available_for.admin}
                              onChange={this.handleCommissionAvailability}
                          />
                          <Form.Check
                              custom inline type="checkbox"
                              name="merchant" id={`commissionAvailableForMerchant`}
                              label={`Merchant`} value={true}
                              checked={this.state.formData.commission_available_for.merchant}
                              onChange={this.handleCommissionAvailability}
                          />
                        </Col>
                      </Form.Group>


                      <div className="mt-3">
                        <Button size="lg"
                                type={"submit"}
                                disabled={this.state.isAdding}
                                variant="primary">{this.state.isAdding ? 'Adding...' : 'Add'}</Button>
                      </div>
                    </Form>
                    }

                    {this.state.activeBlockTab === 'Invoice Number' &&
                    <>
                      <Form.Group as={Row} controlId="generationType">
                        <Form.Label column sm="3">
                          Generation Type
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control as="select">
                            <option value="">Use Autoincrement Number</option>
                            <option value="">Use Order Number</option>
                            <option value="">Provide number manually</option>
                          </Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="generationType">
                        <Form.Label column sm="3">
                          Invoice Prefix
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control type="text"/>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="generationType">
                        <Form.Label column sm="3">
                          Next Number (Insert numbers only)
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control type="number"/>
                        </Col>
                      </Form.Group>
                    </>
                    }
                  </>
              }
            </div>
          </div>
        </>:null}

      </App>
    );
  }
}

//export default AddEntrepreneur;
export default withRouter(AddEntrepreneur);
