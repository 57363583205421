import axios from "axios";
import {API_BASE_URL} from "../helper/env";
import {toast} from 'react-toastify';
import {getLocalAuthData} from "../helper/utils";

export const getBrandList = (params) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, error) => {
    axios.get(API_BASE_URL + '/brand', {
        headers: {
            "x-auth-token": authData.token,
            "x-api-client": 'web'
        },
        params: params
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        error(err.message);
      })
  });
}

export const addBrand = (data, callback) => {
  return new Promise((resolve, reject) => {
    axios.post(API_BASE_URL + '/brand', data)
      .then(res => {
        if(res.data.status === 1) {
          resolve(res)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const deleteBrand = (id, callback) => {
  axios.delete(API_BASE_URL + `/brand/${id}/permanent`)
    .then(res => {
      if(res.data.status === 1) {
        toast.success(res.data.message);
        callback();
      } else {
        toast.error(res.data.message);
      }
    })
    .catch(err => {
      toast.error(err.message);
    })
}

export const brandDetails = (id) => {
    const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `/brand/${id}`, {
        headers: {
            "x-auth-token": authData.token,
            "x-api-client": 'web'
        }
    })
      .then(res => {
        if(res.data.status === 1) {
          resolve(res.data.brand);
        } else {
          toast.error(res.data.message);
          reject(res.data.message);
        }
      })
      .catch(err => {
        toast.error(err.message);
      })
  })
}

export const brandUpdate = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(API_BASE_URL + `/brand/${id}`, data)
      .then(res => {
        if(res.data.status === 1) {
          resolve(res);
        } else {
          reject(res);
        }
      })
      .catch(err => {
        reject(err.message);
      })
  })
}
