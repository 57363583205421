import React, {Component} from "react";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import Category from "./Category";
import icShoppingCart from "../temp/img/buying-cart.png";
import icHotel from "../temp/img/hotel.png";
import icHospital from "../temp/img/hospital.png";
import {FiPlus, FiCheck} from "react-icons/fi";
import {FiMove, FiSettings, FiTrash} from "react-icons/all";
import {getData} from "../services/baseServices";
import {isEmptyObj} from "../helper/utils";
import {getProductListSecondary} from "../services/productServices";
import StoreCard from "./StoreCard";

class CategoryBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowingModal: false,
      featuredCategories: [
        {
          id: 1,
          category: 'Shopping',
          image: 'https://ekshop-dev.s3-us-west-1.amazonaws.com/1604919099791_deeasamsungmcorebmvrworiginalimafuahczehquymdjpg'
        },
        {
          id: 2,
          category: 'Hotel',
          image: 'https://ekshop-dev.s3-us-west-1.amazonaws.com/1604919099791_deeasamsungmcorebmvrworiginalimafuahczehquymdjpg'
        },
        {
          id: 3,
          category: 'Hospital',
          image: 'https://ekshop-dev.s3-us-west-1.amazonaws.com/1604919099791_deeasamsungmcorebmvrworiginalimafuahczehquymdjpg'
        }
      ],
      isSettingModalShowing: false,
      settingData: {params: {}},
      categoryList: [],
      isDataFetching: false,
      isConfigured: false
    }
  }

  componentDidMount() {
    if (!isEmptyObj(this.props.settings?.params)) {
      this.setState({isDataFetching: true, isConfigured: true, settingData: this.props.settings}, () => {
        this.getCategories()
      })
    }
  }

  handleShowModal = () => {
    this.setState({isShowingModal: true})
  };

  handleModalClose = () => {
    this.setState({isShowingModal: false});
  };

  handleSettingModalShow = () => {
    this.setState({isSettingModalShowing: true})
  }

  handleSettingModalHide = () => {
    this.setState({isSettingModalShowing: false})
  }

  handleSettingInputOnChange = (e) => {
    const settingData = {...this.state.settingData}
    if (e.target.type !== 'checkbox') {
      if (e.target.name === 'title' || e.target.name === 'title_local') {
        settingData[e.target.name] = e.target.value
      } else {
        settingData.params[e.target.name] = e.target.value
      }
    } else {
      if (e.target.checked) {
        settingData.params[e.target.name] = e.target.value
      } else {
        delete settingData.params[e.target.name]
      }
    }
    this.setState({settingData})
  }

  handleOnSaveSettingForm = () => {
    this.props.actions.onChangeSettings(this.state.settingData, () => {
      this.getCategories()
      this.handleSettingModalHide()
    })
  }

  // https://parallaxlogic.dev/ekshop-admin/api/front/category-with-product

  getCategories = () => {
    this.setState({isDataFetching: true})
    getData(`/front/category-with-product`, this.props.settings.params)
      .then(res => {
        console.log(res.items)
        this.setState({isDataFetching: false, categoryList: res.items, isConfigured: true})
      })
      .catch(errMsg => {
        console.log(errMsg)
        this.setState({isDataFetching: false})
      })
  }

  render() {
    const {handler, settings, isDisabled, actions, index, dragHandleProps} = this.props

    return (
      <>
        <Modal show={this.state.isShowingModal} onHide={() => this.handleModalClose()} animation={true}>
          <Modal.Header closeButton>
            <Modal.Title>Select Categories</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Group controlId="citySelect">
              <Form.Label className="sr-only">Search</Form.Label>
              <Form.Control type="text" placeholder="Search"/>
            </Form.Group>

            <div className="category-list">
              <h4 className="list-title">Category List</h4>

              <div className="category-list-inner">
                <label htmlFor="category1" className="category-item">
                  <input id="category1" type="checkbox"/>

                  <span className="category-card">
                    <span className="category-image">
                      <img
                        src={'https://ekshop-dev.s3-us-west-1.amazonaws.com/1604919099791_deeasamsungmcorebmvrworiginalimafuahczehquymdjpg'}
                        alt=""/>
                    </span>

                    <span className="category-name">
                      <span>Hospital</span> <span className="check-marker"><FiCheck/></span>
                    </span>
                  </span>
                </label>

                <label htmlFor="category2" className="category-item">
                  <input id="category2" type="checkbox"/>

                  <span className="category-card">
                    <span className="category-image">
                      <img
                        src={'https://ekshop-dev.s3-us-west-1.amazonaws.com/1604919099791_deeasamsungmcorebmvrworiginalimafuahczehquymdjpg'}
                        alt=""/>
                    </span>

                    <span className="category-name">
                      <span>Hotel</span> <span className="check-marker"><FiCheck/></span>
                    </span>
                  </span>
                </label>

                <label htmlFor="category3" className="category-item">
                  <input id="category3" type="checkbox"/>

                  <span className="category-card">
                    <span className="category-image">
                      <img
                        src={'https://ekshop-dev.s3-us-west-1.amazonaws.com/1604919099791_deeasamsungmcorebmvrworiginalimafuahczehquymdjpg'}
                        alt=""/>
                    </span>

                    <span className="category-name">
                      <span>Shopping</span> <span className="check-marker"><FiCheck/></span>
                    </span>
                  </span>
                </label>

                <label htmlFor="category4" className="category-item">
                  <input id="category4" type="checkbox"/>

                  <span className="category-card">
                    <span className="category-image">
                      <img
                        src={'https://ekshop-dev.s3-us-west-1.amazonaws.com/1604919099791_deeasamsungmcorebmvrworiginalimafuahczehquymdjpg'}
                        alt=""/>
                    </span>

                    <span className="category-name">
                      <span>Shopping</span> <span className="check-marker"><FiCheck/></span>
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="default" onClick={() => this.handleModalClose()}>
              Close
            </Button>
            <Button variant="primary" onClick={() => this.handleModalClose()}>
              Add Category
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="card-block">
          <div className="block-header">
            <h4 className="block-title">
              {handler && dragHandleProps && <span className={'btn-draggable'} {...handler.dragHandleProps}><FiMove/></span>}
              {settings?.title || 'Untitled'}
              {!dragHandleProps && <span className={"text-danger"} style={{"fontSize": "12px"}}>please save to drag</span>}
            </h4>

            <div className={'block-actions'}>
              <Form.Check
                inline
                defaultChecked={!settings?.is_disabled}
                type="switch"
                onChange={actions.toggleBlockSectionDisable}
                id={`isDisabled-${index}`}
                label={settings?.is_disabled ? 'Off' : 'On'}/>

              <span onClick={this.handleSettingModalShow} className={'btn-block-action'}>
                <FiSettings/>
              </span>

              <span onClick={actions.onDelete} className={'btn-block-action ml-3'}>
                <FiTrash/>
              </span>
            </div>
          </div>

          <Modal show={this.state.isSettingModalShowing} onHide={this.handleSettingModalHide}>
            <Modal.Header closeButton>
              <Modal.Title>Block Settings</Modal.Title>
            </Modal.Header>
            <Form>
              <Modal.Body>
                <Form.Group controlId="blockTitle">
                  <Form.Label>Block Title (ENG)</Form.Label>
                  <Form.Control type="text" name={'title'}
                                defaultValue={settings.title}
                                onChange={this.handleSettingInputOnChange} placeholder="Enter block title in english"/>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Block Title (বাংলা)</Form.Label>
                  <Form.Control type="text"
                                name={'title_local'}
                                defaultValue={settings?.title_local}
                                onChange={this.handleSettingInputOnChange}
                                placeholder="Enter block title in local language"/>
                </Form.Group>

                <Form.Group controlId="displayCategory">
                  <Form.Label>Display Product</Form.Label>
                  <Form.Control name={'sort_by'}
                                defaultValue={settings?.params?.sort_by}
                                onChange={this.handleSettingInputOnChange} as="select">
                    <option label={'--Select--'}/>
                    <option value={1}>Top Categories</option>
                    <option value={2}>Featured Categories</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="dataLimit">
                  <Form.Label>Limit</Form.Label>
                  <Form.Control type="number" name={'limit'}
                                defaultValue={settings?.params?.limit}
                                onChange={this.handleSettingInputOnChange} placeholder="Enter the category limit"/>
                </Form.Group>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleSettingModalHide}>
                  Close
                </Button>
                <Button variant="primary" onClick={this.handleOnSaveSettingForm}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          <div className="block-body">
            {(!isEmptyObj(settings.params) && this.state.isConfigured) ?
              <>
                {this.state.categoryList.length > 0 ?
                  <Row>
                    {this.state.categoryList.map((cat, index) => <Col key={index} lg={2} md={3} xs={6}>
                      <Category img={cat?.product?.mid_img} name={cat?.name}/>
                    </Col>)}

                    {/*<Col lg={3} md={6}>
                  <ButtonAddStore/>
                </Col>*/}
                  </Row>
                  :
                  <div className={'alert alert-warning text-center'}>
                    No category found.
                  </div>
                }
              </>
              :
              <div className={'alert text-center alert-warning'}>
                This block has no configuration.
                Please <span onClick={this.handleSettingModalShow}
                             style={{cursor: "pointer"}}
                             className="text-primary">click here</span> to configure
              </div>
            }
          </div>
        </div>
      </>
    );
  }
}

export default CategoryBlock;
