import React, {Component} from 'react'
import Chart from "chart.js"
import {Col, Row} from "react-bootstrap"
import "../../assets/scss/campaign-stats.scss"

class LastMonthStats extends Component {
  orderStatsChartRef = React.createRef()
  componentDidMount() {
    setTimeout(() => {
      // console.log(Object.values(this.props.lastMonthOrderReports.datasets).map(o => o.name))
      // console.log(Object.values(this.props.lastMonthOrderReports.datasets).map(o => o.value))
      const orderStatsRef = this.orderStatsChartRef.current.getContext("2d")
      let last_month_order_reports = this.props?.lastMonthOrderReports
      if(last_month_order_reports && last_month_order_reports?.datasets){
        let datasets = Object.values(last_month_order_reports?.datasets)
        new Chart(orderStatsRef, {
          type: 'doughnut',
          data: {
            labels: (datasets && datasets.length > 0) ? datasets?.map(o => o?.name) : ["N/A"],
            datasets: [{
              label: (datasets && datasets.length > 0) ? datasets?.map(o => o?.name) : ["N/A"],
              backgroundColor: ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9", "#c45850","#D4CD20" , "#c50850"],
              data: (datasets && datasets.length > 0) ? datasets?.map(o => o?.value) : [0]
            }]
          },
          options: {
            rotation: -0.7 * Math.PI,
            legend: {
              display: true,
              position: 'right'
            }
          }
        })
      }
    }, 1000)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.componentDidMount()
  }

  render() {
    return (
      <Row>
        <Col md={6}>
          <div className="card-block">
            <div className="block-body">
              <div className="info-stats-list">
                {this.props.lastMonthOrderReports && this.props.lastMonthOrderReports.datasets && this.props.lastMonthOrderReports.datasets.map(o => (
                  <div className="info-stats">
                    <div className="stats-text">
                      <h4 className="title">{o.name}</h4>
                      <p className="sub-title">Last Month {o.name}</p>
                    </div>
                    <div className="stats-value">
                      {o.value}
                    </div>
                  </div>
                ))
                }
              </div>
            </div>
          </div>
        </Col>

        <Col md={6}>
          <div className="card-block">
            <div className="block-body">
              <div className="body-header">
                <h4 className="body-title">Last Month Order Statistics</h4>
                <p className="body-sub-title">Check Last Month Order Status</p>
              </div>

              <canvas
                style={{height: '260px', width: '100%'}}
                ref={this.orderStatsChartRef}
              />
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}

export default LastMonthStats
