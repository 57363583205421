import React, {Component} from 'react';
import watch from "../../temp/watch.jpg";
import App from "../../App";
import {Button, Col, Form, Pagination, Row} from "react-bootstrap";
import {FaFileExport, FiPlus, FiUpload, HiOutlineClipboardList} from "react-icons/all";
import {Link} from "react-router-dom";
import {list, changeItemStatus, deleteItem, hardDeleteItem} from "../../services/walletRedeemPackageServices";
import {confirmAlert} from "react-confirm-alert";
import {toast} from "react-toastify";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {getDeviceType, getLocalAuthData} from "../../helper/utils";
import axios from "axios";
import {API_BASE_URL} from "../../helper/env";
import moment from "moment";
import Select from "react-select";
import styleReactSelect from "../../assets/styleReactSelect";
import Moment from "moment";
import {activityLogPostData} from "../../services/commonServices";
import {RiMovie2Line} from "@react-icons/all-files/ri/RiMovie2Line";
import {FaRegCreditCard} from "@react-icons/all-files/fa/FaRegCreditCard";
import {FaWallet} from "@react-icons/all-files/fa/FaWallet";
import {IoMdSettings} from "@react-icons/all-files/io/IoMdSettings";
import {BiPackage} from "@react-icons/all-files/bi/BiPackage";

class WRPOfferList extends Component {

    state = {
        tableList: [],
        isLoading: true,
        activePage: 1,
        pagination: {},
        status: null,

        queryData: {
            from_date: '',
            to_date: '',
            package_type: '',
            status: '',
            search_string: ''
        },
        packageTypes: [
            {
                id: 1,
                label: "Top-up",
                value: "Top-up"
            },
            {
                id: 2,
                label: "Free Shipping",
                value: "Free Shipping"
            },
            {
                id: 3,
                label: "Purchase",
                value: "Purchase"
            }
        ],

    }

    componentDidMount() {
        list().then(res => {
            this.setState({tableList: res.items, isLoading: false})
        }).catch(errMsg => {
            this.setState({isLoading: false})
        })
    }

    handlePageChange = async (pageNumber) => {
        await this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber});
        await this.getQueryData();
    }

    changeStatus = (statusObj, order_id, callback = () => null) => {
        const headers = {
            'Content-Type': 'application/json',
            'x-api-client': getDeviceType(),
            'x-auth-token': getLocalAuthData().token,
        }
        axios.patch(`${API_BASE_URL}/wallet-redeem-package/${order_id}/change-status`, statusObj,
            {headers: headers}
        ).then(response => {
            callback()
            toast.success(response.data.message);
        }).catch(error => {
            callback()
            toast.error('Something went wrong');
        });
    };

    getQueryData = () => {

        list(this.state.queryData).then(res => {
            this.setState({tableList: res.items, isLoading: false})
        }).catch(errMsg => {
            this.setState({isLoading: false})
        })

    }

    handleSearchFormDataChange = (e) => {
        const merchant_type = e.target.name;
        const merchant_type_id = e.target.value;
        this.setState({queryData: {...this.state.queryData, [e.target.name]: e.target.value}});
    };

    handleOnApply = (e, picker) => {
        const queryData = {...this.state.queryData};
        const startDate = Moment(picker.startDate).format('DD/MM/YYYY');
        const endDate = Moment(picker.endDate).format('DD/MM/YYYY')

        queryData.from_date = startDate;
        queryData.to_date = endDate;

        this.setState({
            queryData,
            from_date: startDate,
            to_date: endDate,
        }, () => {
            console.log(this.state.queryData);
        })
    }

    setQueryData = async (e) => {
        await this.setState({queryData: {...this.state.queryData, [e.target.name]: e.target.value}});
    }


    handleSearchFormDataChange = (e) => {
        this.setState({queryData: {...this.state.queryData, [e.target.name]: e.target.value}}, () => {
            console.log(this.state.queryData);
        });
    };

    handleSoftDelete = (id) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure to delete this settings?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteItem(id).then(res => {

                        list().then(res => {
                            this.setState({tableList: res.items, isLoading: false})
                        }).catch(errMsg => {
                            this.setState({isLoading: false})
                        })

                        toast.success(res.message)
                    }).catch(errMsg => {
                        toast.error(errMsg)
                    })
                },
                {label: 'No'}
            ]
        });
    }

    handleHardDelete = (id,name) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure to delete this settings permanently?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => hardDeleteItem(id).then(res => {

                        list().then(res => {

                            this.setState({tableList: res.items, isLoading: false})
                            activityLogPostData('was delete this package from wallet redeem package offer','/wallet/redeem-package/edit/'+id,name)

                        }).catch(errMsg => {
                            this.setState({isLoading: false})
                        })

                        toast.success(res.message)
                    }).catch(errMsg => {
                        toast.error(errMsg)
                    })
                },
                {label: 'No'}
            ]
        });
    }

    handleToggleStatusUpdate = (id, status, index,name) => {
        confirmAlert({
            title: 'Status Update',
            message: 'Are you sure to change this status?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => changeItemStatus(id, {status: status})
                        .then(res => {
                            const tableList = [...this.state.tableList]
                            tableList[index].status = status
                            this.setState({tableList})
                            toast.success(res.message)
                            activityLogPostData('was change wallet redeem package status','/wallet/redeem-package/edit/'+id,name)
                        })
                        .catch(errMsg => {
                            toast.error(errMsg)
                        })
                },
                {label: 'No'}
            ]
        });
    }

    renderList = () => {
        const packageTypes = this.state.packageTypes;
        if (packageTypes.length > 0) {
            return (
                packageTypes.map((items, index) =>
                    <option key={index} value={`${items.value}`}>{items.label}</option>
                )
            )
        }
    };

    render() {
        return (
            <App layout="boxed">

                <div className="main-content">


                    <div className="d-flex justify-content-center align-items-center">
                            <li className="list-unstyled mx-3">
                                <Link to={'/settings'} class={"d-flex justify-content-center align-items-center flex-column"}>
                                    <span className="card nav-icon card-body" style={{borderRadius: "10px"}}>
                                        <IoMdSettings size={35}/>
                                    </span>
                                    <span className="nav-text">Wallet Setting</span>
                                </Link>
                            </li>

                            <li className="list-unstyled mx-3">
                                <Link to={'/wallet/redeem-package'} class={"d-flex justify-content-center align-items-center flex-column"}>
                                    <span className="card nav-icon card-body" style={{borderRadius: "10px"}}>
                                        <BiPackage size={35}/>
                                    </span>
                                    <span className="nav-text">Package</span></Link>
                            </li>
                    </div>



                    <div className="page-header">
                        <h2 className="page-title">Wallet Redeem Package Offer</h2>
                        {getLocalAuthData()?.permission?.includes('wallet_redeem_package_offer_add') ? <div className="ml-auto">

                            <Link to={`${process.env.PUBLIC_URL}/wallet/redeem-package/add`}
                                  className="btn btn-sm btn-secondary"><FiPlus/> Add Wallet Redeem Package Offer</Link>
                        </div>:null}
                    </div>

                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">Offer List</h4>
                        </div>

                        <div className="block-body">

                            <form onSubmit={(e)=> e.preventDefault()} className="form-inline mb-3">

                                <div className="form-group mr-3 mt-2">
                                    <DateRangePicker
                                        initialSettings={{}}
                                        containerStyles={{textAlign: 'left', width: '100%', display: 'block'}}
                                        onApply={this.handleOnApply}>
                                        <input type="button"
                                               placeholder={`Select date range`}
                                               value={(this.state?.queryData?.from_date && this.state?.queryData?.to_date) ? `${this.state?.queryData?.from_date} - ${this.state?.queryData?.to_date}` : 'Select date range'}
                                               style={{textAlign: 'left', minWidth: '150px'}}
                                               className="form-control form-control-sm"
                                               onApply={this.handleOnApply}/>
                                    </DateRangePicker>
                                </div>

                                <div className="form-group mr-3 mt-2">
                                    <label htmlFor="packageType" className="sr-only">Package Types</label>
                                    <select type="search" id="packageType" className='form-control form-control-sm'
                                            id="store" name="package_type"
                                            onChange={this.handleSearchFormDataChange}
                                            className='form-control form-control-sm'>
                                        <option value="">--Select Package Type--</option>
                                        {this.renderList()}
                                    </select>
                                </div>

                                <div className="form-group mr-3 mt-2">
                                    <label htmlFor="status" className="sr-only">Status</label>
                                    <select type="search" id="status" name="status" className='form-control form-control-sm'
                                            onChange={this.handleSearchFormDataChange}>
                                        <option key={0} value="">--Select Status--</option>
                                        <option key={1} value="1">Active</option>
                                        <option key={2} value="2">Disabled</option>
                                    </select>
                                </div>

                                <div className="form-group mr-3 mt-2">
                                    <label htmlFor="sellerSKU" className="sr-only">Search</label>
                                    <input type="search" id="search_string" name="search_string"
                                           onChange={this.handleSearchFormDataChange}
                                           className='form-control form-control-sm'
                                           placeholder="Search String"
                                           onKeyDown={(e)=>{
                                            if(e.keyCode === 13){
                                              this.getQueryData()
                                            }
                                          }}/>
                                </div>

                                <Button size="sm" variant="secondary" onClick={this.getQueryData}>Search</Button>

                            </form>

                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th style={{width: '1%'}}>#</th>
                                    <th>Package Title</th>
                                    <th>Duration</th>
                                    <th>Package Type</th>
                                    <th>Redeem Point</th>
                                    <th>Offer Value</th>
                                    <th>Status</th>
                                    <th style={{width: '120px'}}/>
                                </tr>
                                </thead>
                                <tbody>

                                {(getLocalAuthData()?.permission?.includes('wallet_redeem_package_offer_list') && this.state.tableList?.length > 0) ?
                                    this.state.tableList.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item?.package_title}</td>
                                            <td>
                                                <span>Start: <strong>{moment(item?.start_time).format("ll")}</strong></span>
                                                <br/>
                                                <span>End: <strong>{moment(item?.end_time).format("ll")}</strong></span>
                                            </td>

                                            <td>{item?.package_type}</td>
                                            <td>{item?.redeem_point}</td>
                                            <td>{item?.package_type === 'Free Shipping' ? item?.package_type : item?.offer_value}</td>

                                            <td>
                                                {item.status === "1" ? <span className={`badge badge-success`}>Active</span>
                                                    :
                                                    <span className={`badge badge-secondary`}>Disabled</span>}
                                            </td>

                                            <td className="nowrap text-center">
                                                {getLocalAuthData()?.permission?.includes('wallet_redeem_package_offer_edit') ?<Link to={`/wallet/redeem-package/edit/${item._id}`}
                                                       className={`text-bold text-primary mr-3`}>Edit</Link>:null}

                                                {getLocalAuthData()?.permission?.includes('wallet_redeem_package_offer_active_disabled') ?
                                                <span className={item?.status === "1" ? 'text-bold text-warning mr-3' : 'text-bold text-success mr-3'}
                                                      onClick={() => this.handleToggleStatusUpdate(item._id, item?.status === "1" ? "2" : "1", index , item?.package_title)}
                                                      style={{cursor: 'pointer'}}>
                                                  {item?.status === "1" ? 'Disabled' : 'Activate'}
                                                </span>:null}

                                                {getLocalAuthData()?.permission?.includes('wallet_redeem_package_offer_delete') ?
                                                <span className={`text-bold text-danger`}
                                                      onClick={() => this.handleHardDelete(item._id,item?.package_title)}
                                                      style={{cursor: 'pointer'}}>Delete</span>:null}
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td colSpan={7} className={`text-bold text-center`}>No item found</td>
                                    </tr>
                                }

                                </tbody>
                            </table>

                            <div className="mt-3">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={this.state.pagination?.page}
                                    itemsCountPerPage={this.state.pagination?.limit}
                                    totalItemsCount={this.state.pagination?.totalDocs}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />

                                {/*<Pagination>
                                    <Pagination.First/>
                                    <Pagination.Prev/>
                                    <Pagination.Item>{1}</Pagination.Item>
                                    <Pagination.Ellipsis/>
                                    <Pagination.Item>{10}</Pagination.Item>
                                    <Pagination.Item>{11}</Pagination.Item>
                                    <Pagination.Item active disabled>{12}</Pagination.Item>
                                    <Pagination.Item>{13}</Pagination.Item>
                                    <Pagination.Item>{14}</Pagination.Item>
                                    <Pagination.Ellipsis/>
                                    <Pagination.Item>{20}</Pagination.Item>
                                    <Pagination.Next/>
                                    <Pagination.Last/>
                                </Pagination>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </App>
        );
    }
}

export default WRPOfferList;
