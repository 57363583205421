import React, {Component} from 'react';
import "../assets/scss/category.scss";
import {Link} from "react-router-dom";
import 'react-sortable-tree/style.css';
import SortableTree, {toggleExpandedForAll} from "react-sortable-tree";
import axios from "axios";
import config from "../helper/env";
import {toast} from "react-toastify";
import {activityLogPostData} from "../services/commonServices";

//https://openbase.io/js/react-sortable-tree

class CategoryTree extends Component {
  constructor(props) {
    console.log(props)
    super(props);
    this.state = {
      categoryType: props.categoryType,
      searchString: "",
      searchFocusIndex: 0,
      searchFoundCount: 0,
      treeData: [],
    };

  }

  componentWillReceiveProps(props) {
    this.setState({categoryType: props.categoryType})

    let string;
    if (props.categoryType === "") {
      string = "";
    } else {
      string = `?category_type=${props.categoryType}`;
    }
    axios.get(`${config.API_BASE_URL}/category${string}`)
      .then(res => {
        let treeData = [];
        if (res.data.categories.length > 0) {
          treeData = this.createChildren(res.data.categories);
        }
        this.setState({treeData: treeData});
      }).catch(() => console.log("Error"));
  }

  componentDidMount() {
    axios.get(`${config.API_BASE_URL}/category`)
      .then(res => {
        let treeData = [];
        if (res.data.categories.length > 0) {
          treeData = this.createChildren(res.data.categories);
        }
        this.setState({treeData: treeData});
      }).catch(() => console.log("Error"));
  }

  createChildren = (data) => {
    let children = [];
    if (data.length > 0) {
      data.map((child) => {
        children.push({
          id: child._id,
          title: child.name,
          subtitle: '',
          children: child.child_cat.length > 0 ? this.createChildren(child.child_cat) : []
        })
      });
    }
    return children;
  }

  handleTreeOnChange = treeData => {
    this.setState({treeData});
  };

  editCategory = rowInfo => {
    /*let path = `/categories/add`;
    let history = userHistory();
    history.push(path);*/
    // this.props.history.push("/categories/add");
  };

  handleSearchOnChange = e => {
    this.setState({
      searchString: e.target.value
    });
  };

  selectPrevMatch = () => {
    const {searchFocusIndex, searchFoundCount} = this.state;

    this.setState({
      searchFocusIndex:
        searchFocusIndex !== null
          ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
          : searchFoundCount - 1
    });
  };

  selectNextMatch = () => {
    const {searchFocusIndex, searchFoundCount} = this.state;

    this.setState({
      searchFocusIndex:
        searchFocusIndex !== null
          ? (searchFocusIndex + 1) % searchFoundCount
          : 0
    });
  };

  toggleNodeExpansion = expanded => {
    this.setState(prevState => ({
      treeData: toggleExpandedForAll({treeData: prevState.treeData, expanded})
    }));
  };

  deletePermanent(id) {
    axios.delete(`${config.API_BASE_URL}/category/${id}/permanent`).then(res => {
      console.log(res.data.message);
      console.log(res);
      if (res.data.status === 1) {
        toast.success(res.data.message);
        activityLogPostData('was delete this category','/categories/list/',this.state.formData.name)

        /*RECALLL CATEGORY LIST*/
        axios.get(`${config.API_BASE_URL}/category`).then(res => {
          let treeData = [];
          if (res.data.categories.length > 0) {
            treeData = this.createChildren(res.data.categories);
          }
          this.setState({treeData: treeData});
        }).catch(() => console.log("Error"));

      } else if (res.data.status === 2) {
        toast.warning(res.data.message);
      }
    }).catch(() => {
      toast.error("Something went wrong, please refresh and try again");
      console.log("Error")
    });
  }

  render() {
    const {
      treeData,
      searchString,
      searchFocusIndex,
      searchFoundCount
    } = this.state;
    return (
      <div>
        <div className="bar-wrapper">
          <button onClick={this.toggleNodeExpansion.bind(this, true)}
                  className="btn btn-outline-success btn-sm">
            Expand all {this.state.categoryType}
          </button>
          &nbsp; &nbsp;
          <button className="btn btn-outline-success btn-sm"
                  onClick={this.toggleNodeExpansion.bind(this, false)}>
            Collapse all
          </button>
          &nbsp; &nbsp;

          <label>Search: </label> &nbsp; &nbsp;
          <input onChange={this.handleSearchOnChange}/> &nbsp; &nbsp;

          <button className="btn btn-outline-success btn-sm" onClick={this.selectPrevMatch}>
            &lt;
          </button>
          <button className="btn btn-outline-success btn-sm" onClick={this.selectNextMatch}>
            &gt;
          </button>
          <label>
            {searchFocusIndex} / {searchFoundCount}
          </label>
        </div>

        <div style={{height: 800}}>
          <SortableTree
            treeData={treeData}
            onChange={this.handleTreeOnChange}
            searchQuery={searchString}
            searchFocusOffset={searchFocusIndex}
            searchFinishCallback={matches =>
              this.setState({
                searchFoundCount: matches.length,
                searchFocusIndex:
                  matches.length > 0 ? searchFocusIndex % matches.length : 0
              })
            }
            generateNodeProps={rowInfo => ({
              buttons: [
                <Link
                    className="btn btn-outline-success btn-sm"
                    style={{verticalAlign: "middle"}}
                    to={`${process.env.PUBLIC_URL}/category/edit/${rowInfo.node.id}`}
                > Edit
                </Link>,

                <button
                    className="btn btn-outline-danger btn-sm ml-1"
                    style={{verticalAlign: "middle"}}
                    onClick={() => this.deletePermanent(rowInfo.node.id)}
                > Delete
                </button>
              ]
            })}/>
        </div>
      </div>
    );
  }
}

export default CategoryTree;
