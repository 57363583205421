import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import Select from "react-select";
import styleReactSelect from "../../assets/styleReactSelect";
import {getMerchantList, getMerchantTypeList} from "../../services/merchantServices";
import {getStoreList} from "../../services/storeServices";
import {getCategoryList} from "../../services/categoryServices";
import {getProductListAlt, getProductListSecondary} from "../../services/productServices";
import {IoIosArrowRoundBack, IoMdCheckmark} from "react-icons/all";
import {addCommissionSettings} from "../../services/commissionSettingsServices";
import {toast} from "react-toastify";
import {Link, useHistory, withRouter} from "react-router-dom";
import Pagination from "react-js-pagination";
import {getLocalAuthData} from "../../helper/utils";
import {activityLogPostData, saveData} from "../../services/commonServices";
import BrowseFiles from "../../components/BrowseFiles";
import {GrSend} from "@react-icons/all-files/gr/GrSend";
import {IoIosSend} from "@react-icons/all-files/io/IoIosSend";

class AddNotification extends Component {
    state = {
        merchants: [],
        merchantList: [],
        categoryList: [],
        products: [],
        merchantTypes: [],
        selectedProductList: [],
        isLoading: true,
        selectedProducts: [],
        formData: {
            users: [],
            stores: []
        },
        isProductModalProductShowing: false,
        isSubmitting: false,
        defaultQuerySettings: {
            merchantList: {lite_data: 1},
            storeList: {merchants: this.merchants || [], status: 1, approval_status: 1, lite_data: 1},
            productList: {limit: 20}
        },
        isProductLoading: false,
        isEnableMerchantElement : false,
        notification_element : false,
        isEnableSpecificField: false
    }

    componentDidMount() {

    }

    handleInputOnChange = (e) => {
        const formData = {...this.state.formData}
        formData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({formData})
    }

    handleShowSelectProductModal = () => {
        this.setState({isProductLoading: true}, () => {
            getProductListSecondary({
                merchants: JSON.stringify(this.state.formData?.merchants || []),
                stores: JSON.stringify(this.state.formData?.stores || []),
                categories: JSON.stringify(this.state.formData?.categories || [])
            }).then(res => {
                this.setState({
                    products: res.products,
                    productPagination: res.pagination,
                    isProductModalProductShowing: true,
                    isProductLoading: false
                })
            })
                .catch(err => {
                    this.setState({isProductLoading: false})
                })
        })

    }

    handleSubmitForm = (e) => {
        e.preventDefault()
        this.setState({isSubmitting: true}, () => {
            let formData = {...this.state.formData}
            console.log(formData)
            /* const data = {
                 users: formData?.merchants,
                 title: formData?.title,
                 message: formData.message,
                 user_type: formData?.user_type ? formData?.user_type: 'merchant',
                 img: formData?.img,
             }*/

            saveData('/fcm-notification', formData).then((res) => {
                console.log(res)
                if (res.status === 1){
                    this.setState({
                        isSubmitting: false
                    },()=>{
                        toast.success("Notification Send Successfully!")
                        //window.location.reload()
                    })
                }
            })
        })
    }

    handleSearchFormDataChange(e) {

        if (e.target.name === 'user_type' && e.target.value === 'customer'){
            this.setState({
                isEnableMerchantElement : false,
                notification_element: true
            })
        }else if(e.target.name === 'user_type' && e.target.value === 'merchant') {
            this.setState({
                isEnableMerchantElement : true,
                notification_element: false,
                isEnableSpecificField: false
            })
        }

        if (e.target.name === 'customer_user_type' && e.target.value === '2'){
            this.setState({
                isEnableSpecificField : true
            },()=>{
                this.state.formData.merchant_type = []
                this.state.formData.users = []

            })
        }else if (e.target.name === 'customer_user_type' && e.target.value === '1'){
            this.setState({
                isEnableSpecificField : false
            },()=>{
                this.state.formData.merchant_types = []
                this.state.formData.users = []
            })
        }else if (e.target.name === 'customer_user_type' && e.target.value === ''){
            this.setState({
                isEnableSpecificField : false
            },()=>{
                this.state.formData.merchant_types = []
                this.state.formData.users = []
            })
        }
        this.setState({formData: {...this.state.formData, [e.target.name]: e.target.value}}, () => {
            console.log(this.state.formData)
        })

    }


    handleRemoveImage = (name) => {
        const formData = {...this.state.formData}
        formData['image'] = ''
        this.setState({formData}, () => {
            console.log(this.state.formData)
        })
    }

    handleOnBrowsePic = (files, name) => {
        const formData = {...this.state.formData}
        formData['image'] = files[0].actual_img;
        this.setState({formData}, () => {
            console.log(this.state.formData)
        })
    }

    render() {
        return (
            <App layout={`boxed`}>
                <div className="page-header">
                    <div/>
                    {/*<div className="ml-auto">
                        <Link to={`${process.env.PUBLIC_URL}/notice/list`}><IoIosArrowRoundBack/> Back to
                            List</Link>
                    </div>*/}
                </div>


                <>
                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">Send Notification <IoIosSend/></h4>
                        </div>

                        <div className="block-body">
                            <Form onSubmit={this.handleSubmitForm}>
                                    <Form.Group as={Row} controlId="formAdminCommission">
                                        <Form.Label column sm="3">
                                            Title <span className="text-danger">*</span>
                                        </Form.Label>

                                        <Col sm="4" md={8} lg={7}>
                                            <Form.Control type={`text`}
                                                          onChange={this.handleInputOnChange}
                                                          defaultValue={this.state.formData?.admin_commission_percentage}
                                                          name={'title'}
                                                          min={0} max={100} step={.1} required placeholder={`Enter title`}/>
                                        </Col>


                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formMerchantCommission">
                                        <Form.Label column sm="3">
                                            Message <span className="text-danger">*</span>
                                        </Form.Label>

                                        <Col sm="4" md={8} lg={7}>
                                            <Form.Control type={`text`}
                                                          as="textarea"
                                                          onChange={this.handleInputOnChange}
                                                          defaultValue={this.state.formData?.merchant_commission_percentage}
                                                          name={'message'}
                                                          min={0} max={100} step={.1} required placeholder={`Enter message`}/>
                                        </Col>


                                    </Form.Group>

                                   {/* <Form.Group as={Row} controlId="formMerchant">
                                        <Form.Label column sm="3">
                                            Type
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <select name={`user_type`} onChange={(e) => this.handleSearchFormDataChange(e)} type="search" id="status" className='form-control form-control-sm'>
                                                <option value="">--select type--</option>
                                                <option value="customer">For Customer</option>
                                            </select>
                                        </Col>
                                    </Form.Group>*/}


                                    <Form.Group as={Row} controlId="ffsd">
                                        <Form.Label column sm="3">
                                            Send to
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <select name={`customer_user_type`} onChange={(e) => this.handleSearchFormDataChange(e)} type="search" id="status" className='form-control form-control-sm'>
                                                <option value="">--select user--</option>
                                                <option value="1">All User</option>
                                                <option value="2">Specific number</option>
                                            </select>
                                        </Col>
                                    </Form.Group>


                                    {this.state.isEnableSpecificField && <Form.Group as={Row} controlId="formAdminCommission">
                                        <Form.Label column sm="3">
                                            Phone or Email  <span className="text-danger">*</span>
                                        </Form.Label>

                                        <Col sm="4" md={8} lg={7}>
                                            <Form.Control type={`text`}
                                                          onChange={this.handleInputOnChange}
                                                          name={'topic'}
                                                          min={0} max={100} step={.1} required placeholder={``}/>
                                        </Col>
                                    </Form.Group>}


                                    <Form.Group as={Row} controlId="formMerchant">
                                        <Form.Label column sm="3">
                                            Notification Image
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <BrowseFiles
                                                isSingle={true}
                                                image_category={'profile'}
                                                images={this.state.formData?.img ? [{thumb_img: this.state.formData.img}] : []}
                                                onRemoveItem={() => this.handleRemoveImage('image')}
                                                onGetFiles={(files) => {
                                                    this.handleOnBrowsePic(files, 'image')
                                                }}/>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formSave">
                                        <Form.Label column sm="3"/>
                                        <Col sm="9" md={8} lg={7}>
                                            <Button type={`submit`}
                                                    disabled={this.state.isSubmitting}
                                                    variant={'primary'}>{this.state.isSubmitting ? 'Sending...' : 'Send'}</Button>
                                        </Col>
                                    </Form.Group>
                                </Form>
                        </div>
                    </div>
                </>

            </App>
        );
    }
}

export default withRouter(AddNotification);
