import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import BrowseFiles from "../../components/BrowseFiles";
import {
    getTableData,
    getDetail,
    addNewItem,
    updateItem,
    updateItemPartial,
    deleteItem,
    activityLogPostData
} from "../../services/commonServices";
import {toast} from "react-toastify";
import SupportFaq from "../../components/Barnd";
import {confirmAlert} from "react-confirm-alert";
import "../../assets/scss/barnd-list.scss";
import Pagination from "react-js-pagination";
import {getLocalAuthData} from '../../helper/utils'
import {FiEdit, FiTrash} from "react-icons/all";

class SupportFaqs extends Component {
    state = {
        supportFaqData: {
            question: "Not specified",
            answer: "Not specified",
            support_topic_id: null
        },
        supportFaqList: [],
        supportTopicList: [],
        supportFaqDetails: null,
        isLoading: false,
        queryData: {
            sort_direction: -1,
            limit: 50,
        },
        activePage: 1,
        limit: 50,
        pagination: {}
    }

    componentDidMount() {
        this.getSupportFaqs()
        this.getSupportTopic()
    }

    getSupportFaqs = () => {
        getTableData('/support-faq', this.state.queryData).then(res => {
            this.setState({supportFaqList: res.items, isLoading: false, pagination: res.pagination})
        }).catch(errMsg => {
            toast.error(errMsg);
        })
    }

    getSupportTopic = () => {
        getTableData('/support-topic', {page: 1, limit: 100}).then(res => {
            this.setState({supportTopicList: res.items})
        }).catch(errMsg => {
            toast.error(errMsg);
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({queryData: {...this.state.queryData, page: pageNumber, limit: this.state.limit}}, () => {
            this.getSupportFaqs();
        });
    }

    handleOnChangeInput = (e) => {
        const supportFaqData = {...this.state.supportFaqData};
        const queryData = {...this.state.queryData};
        supportFaqData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        console.log(e.target.name);
        if (e.target.name === 'name') {
            queryData.search_string = e.target.value;
        } else {
            queryData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        }

        this.setState({supportFaqData, queryData}, () => {
            console.log(this.state);
        });
    }

    handleSubmitSupportFaqForm = () => {
        this.setState({isLoading: true});
        addNewItem('/support-faq', this.state.supportFaqData)
            .then(res => {
                this.setState({isLoading: false});
                this.resetUpdateForm();
                this.getSupportFaqs()
                toast.success(res.data.message);
                activityLogPostData('was create support faqs','/support-faq/',this.state.supportFaqData.question)
            })
            .catch(errMsg => {
                this.setState({isLoading: false});
                toast.error(errMsg);
            })
    }

    handleUpdateSupportFaq = () => {
        this.setState({isLoading: true}, () => {
            updateItem(`/support-faq/${this.state.supportFaqDetails._id}`, this.state.supportFaqData)
                .then(res => {
                    this.setState({isLoading: false})
                    this.resetUpdateForm();
                    this.getSupportFaqs()
                    toast.success(res.data.message)
                    activityLogPostData('was update support faq','/support-faq/',this.state?.supportFaqData?.question)
                })
                .catch(errMsg => {
                    toast.error(errMsg);
                    this.setState({isLoading: false});
                })
        });
    }

    handleGetSupportFaqDetails = (id) => {
        getDetail(`/support-faq/${id}`).then(res => {
            this.setState({supportFaqDetails: res.supportFaq})
        })
    }

    handleDeleteSupportFaq = (id ,name) => {
        confirmAlert({
            title: 'Permanent Delete',
            message: 'Are you sure to delete permanently this Support Faq?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {deleteItem(`/support-faq/${id}/permanent`).then(res=> {
                            this.getSupportFaqs()
                        activityLogPostData('was delete support faq','/support-faq/',name)
                        }
                    ).catch(err => console.log(err))}
                },
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });
    };

    resetUpdateForm = () => {
        this.setState({supportFaqDetails: null, supportFaqData: {question: null, answer: null}}, () => {
            document.getElementById('supportFaqForm').reset()
        })
    }

    handleOnRemoveItem = () => {
        const supportFaqData = {...this.state.supportFaqData}
        supportFaqData.logo = null
        this.setState({supportFaqData})
    }

    searchSupportFaq = () => {
        this.getSupportFaqs()
    }

    render() {
        return (
            <App layout='boxed'>
                <div className="page-header">
                    <h2 className="page-title">SupportFaqs</h2>
                </div>

                <div className="main-content">
                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">{!this.state.supportFaqDetails ? 'Add Support Faq' : 'Update Support Faq'}</h4>
                        </div>

                        <div className="block-body">
                            <Form onSubmit={(e) => {
                                e.preventDefault()
                                if (this.state.supportFaqDetails) {
                                    this.handleUpdateSupportFaq()
                                } else {
                                    this.handleSubmitSupportFaqForm()
                                }
                            }} id={"supportFaqForm"}>

                                <Form.Group as={Row} controlId={"ticketDetails"}>
                                    <Form.Label column sm="3">
                                        Topic <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="2" md={8} lg={7}>
                                        <Form.Control
                                            onChange={this.handleOnChangeInput}
                                            as={"select"}
                                            name={"support_topic_id"}
                                            required >
                                            <option value="">--Select Topic--</option>
                                            {this.state?.supportTopicList.length > 0 && this.state?.supportTopicList.map((item, index) => (
                                                <option key={index} value={item._id}>
                                                    {" "}
                                                    {item.name}{" "}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="supportFaqQuestion">
                                    <Form.Label column sm="3">
                                        Question <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type={"text"}
                                                      required
                                                      defaultValue={this.state.supportFaqDetails?.question}
                                                      name="question"
                                                      onChange={this.handleOnChangeInput}
                                                      placeholder={"Support Faq Question"}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="supportFaqAnswer">
                                    <Form.Label column sm="3">
                                        Answer <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type={"text"}
                                                      required
                                                      defaultValue={this.state.supportFaqDetails?.answer}
                                                      name="answer"
                                                      onChange={this.handleOnChangeInput}
                                                      placeholder={"Support Faq Answer"}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formCategory">
                                    <Col sm="3"/>
                                    <Col sm="9" md={8} lg={7}>
                                        {!this.state.supportFaqDetails ?
                                            <>
                                                {getLocalAuthData()?.permission?.includes('support_faq_add') ?
                                                    <Button type={`submit`}
                                                            disabled={this.state.isLoading}
                                                            variant={"primary"}>{this.state.isLoading ? 'Adding Support Faq...' : 'Add Support Faq'}</Button> : null}

                                                {getLocalAuthData()?.permission?.includes('support_faq_search') ?
                                                    <Button type={"button"} onClick={this.searchSupportFaq} variant={"default"} className={'ml-3'}>Search</Button>
                                                    : null}

                                            </>
                                            :
                                            <div>
                                                <Button variant={"primary"}
                                                        type={`submit`}
                                                        disabled={this.state.isLoading}
                                                        className={"mr-2"}>{this.state.isLoading ? 'Updating...' : 'Update'}</Button>

                                                <Button type={"reset"} onClick={this.resetUpdateForm} variant={"default"}>Reset</Button>
                                            </div>
                                        }
                                    </Col>
                                </Form.Group>

                            </Form>
                        </div>
                    </div>

                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">Support Faq List</h4>
                        </div>

                        <div className="block-body">
                            <div className="block-body">
                                {this.state.isLoading ?
                                    <>Loading...</>
                                    :
                                    <table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th style={{width: '20px'}}>#</th>
                                            <th>Question</th>
                                            <th>Answer</th>
                                            <th style={{width: `1%`}}/>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {(getLocalAuthData()?.permission?.includes('support_faq_list') && this.state.supportFaqList?.length > 0) ?
                                            this.state.supportFaqList.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.question}</td>
                                                    <td>{item.answer}</td>
                                                    <td className="nowrap text-center">

                                                        {getLocalAuthData()?.permission?.includes('support_faq_edit') ?
                                                            <button className="btn btn-sm btn-outline-primary mr-3" onClick={() => this.handleGetSupportFaqDetails(item._id)}>Edit</button>
                                                            : null}

                                                        {getLocalAuthData()?.permission?.includes('support_faq_delete') ?
                                                            <button className="btn btn-sm btn-outline-danger mr-3" onClick={() => this.handleDeleteSupportFaq(item._id,item?.question)}>Delete</button>
                                                            : null}
                                                    </td>
                                                </tr>
                                            )) :
                                            <tr>
                                                <td colSpan={7} className={`text-bold text-center`}>No item found</td>
                                            </tr>
                                        }
                                        </tbody>
                                    </table>
                                }
                                <div className="mt-3">
                                    <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={this.state.pagination?.page}
                                        itemsCountPerPage={this.state.pagination?.limit}
                                        totalItemsCount={this.state.pagination?.totalDocs}
                                        pageRangeDisplayed={20}
                                        onChange={this.handlePageChange.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </App>
        );
    }
}

export default SupportFaqs;
