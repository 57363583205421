import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import BrowseFiles from "../../components/BrowseFiles";
import {
    getTableData,
    getDetail,
    addNewItem,
    updateItem,
    updateItemPartial,
    deleteItem,
    activityLogPostData
} from "../../services/commonServices";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import "../../assets/scss/barnd-list.scss";
import Pagination from "react-js-pagination";
import { getLocalAuthData } from '../../helper/utils'
import {FiEdit, FiTrash} from "react-icons/all";

class SupportTopics extends Component {
    state = {
        supportTopicData: {
            name: null
        },
        supportTopicList: [],
        supportTopicDetails: null,
        isLoading: false,
        queryData: {
            sort_direction: -1,
            limit: 50,
        },
        activePage: 1,
        limit: 50,
        pagination: {},
        page: 1
    }

    componentDidMount() {
        this.getSupportTopics()
    }

    getSupportTopics = () => {
        const queryData = {...this.state.queryData};
        queryData.search_string = "";
        this.setState({isLoading: true, queryData: queryData});
        getTableData('/support-topic', this.state.queryData).then(res => {
            this.setState({supportTopicList: res.items, isLoading: false, pagination: res.pagination})
        }).catch(errMsg => {
            toast.error(errMsg);
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({queryData: {...this.state.queryData, page: pageNumber, limit: this.state.limit}}, () => {
            this.getSupportTopics();
        });
    }

    handleOnChangeInput = (e) => {
        const supportTopicData = {...this.state.supportTopicData};
        const queryData = {...this.state.queryData};
        supportTopicData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        console.log(e.target.name);
        if (e.target.name === 'name') {
            queryData.search_string = e.target.value;
        } else {
            queryData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        }

        this.setState({supportTopicData, queryData}, () => {
            console.log(this.state);
        });
    }

    handleSubmitSupportTopicForm = () => {
        
        addNewItem('/support-topic', this.state.supportTopicData)
            .then(res => {
                this.setState({isLoading: false});
                this.resetUpdateForm();
                this.getSupportTopics();
                toast.success(res.data.message);
                activityLogPostData('was added new support topic','/support-topic/',this.state.supportTopicData.name)
            })
            .catch(errMsg => {
                this.setState({isLoading: false});
                toast.error(errMsg);
            })
    }

    handleUpdateSupportTopic = () => {
        this.setState({isLoading: true}, () => {
            updateItem(`/support-topic/${this.state.supportTopicDetails._id}`, this.state.supportTopicData)
                .then(res => {
                    this.setState({isLoading: false})
                    this.resetUpdateForm();
                    this.getSupportTopics();
                    toast.success(res.data.message);
                    activityLogPostData('was update support topic','/support-topic/',this.state.supportTopicDetails._id);
                })
                .catch(errMsg => {
                    toast.error(errMsg);
                    this.setState({isLoading: false});
                })
        });
    }

    handleGetSupportTopicDetails = (id) => {
        getDetail(`/support-topic/${id}`).then(res => {
            this.setState({supportTopicDetails: res.supportTopic})
        })
    }

    handleDeleteSupportTopic = (id) => {
        confirmAlert({
            title: 'Permanent Delete',
            message: 'Are you sure to delete permanently this Support Topic?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteItem(`/support-topic/${id}/permanent`).then(res => {
                            this.getSupportTopics()
                        activityLogPostData('was delete this support topic','/support-topic/',id)
                        }
                    ).catch(err => console.log(err))
                }
                ,
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });
    };

    resetUpdateForm = () => {
        this.setState({supportTopicDetails: null, supportTopicData: {name: null}}, () => {
            document.getElementById('supportTopicForm').reset()
        })
    }

    handleOnRemoveItem = () => {
        const supportTopicData = {...this.state.supportTopicData}
        supportTopicData.logo = null
        this.setState({supportTopicData})
    }

    searchSupportTopic = () => {
        this.getSupportTopics()
    }

    render() {
        return (
            <App layout='boxed'>
                <div className="page-header">
                    <h2 className="page-title">SupportTopics</h2>
                </div>

                <div className="main-content">
                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">{!this.state.supportTopicDetails ? 'Add Support Topic' : 'Update Support Topic'}</h4>
                        </div>

                        <div className="block-body">
                            <Form onSubmit={(e) => {
                                e.preventDefault()
                                if (this.state.supportTopicDetails) {
                                    this.handleUpdateSupportTopic()
                                } else {
                                    this.handleSubmitSupportTopicForm()
                                }
                            }} id={"supportTopicForm"}>

                                <Form.Group as={Row} controlId="supportTopicName">
                                    <Form.Label column sm="3">
                                        Topic Name <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type={"text"}
                                                      required
                                                      defaultValue={this.state.supportTopicDetails?.name}
                                                      name="name"
                                                      onChange={this.handleOnChangeInput}
                                                      placeholder={"Support Topic Name"}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formCategory">
                                    <Col sm="3"/>
                                    <Col sm="9" md={8} lg={7}>
                                        {!this.state.supportTopicDetails ?
                                            <>
                                                {getLocalAuthData()?.permission?.includes('support_topic_add') ?
                                                    <Button type={`submit`}
                                                            disabled={this.state.isLoading}
                                                            variant={"primary"}>{this.state.isLoading ? 'Adding Support Topic...' : 'Add Support Topic'}</Button> : null}

                                                {getLocalAuthData()?.permission?.includes('support_topic_search') ?
                                                    <Button type={"button"} onClick={this.searchSupportTopic} variant={"default"} className={'ml-3'}>Search</Button>
                                                    : null}

                                            </>
                                            :
                                            <div>
                                                <Button variant={"primary"}
                                                        type={`submit`}
                                                        disabled={this.state.isLoading}
                                                        className={"mr-2"}>{this.state.isLoading ? 'Updating...' : 'Update'}</Button>

                                                <Button type={"reset"} onClick={this.resetUpdateForm} variant={"default"}>Reset</Button>
                                            </div>
                                        }
                                    </Col>
                                </Form.Group>

                            </Form>
                        </div>
                    </div>

                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">Support Topic List</h4>
                        </div>

                        <div className="block-body">
                            <div className="block-body">
                                {/*{this.renderBank()}*/}
                                {this.state.isLoading ?
                                    <>Loading...</>
                                    :
                                    <table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th style={{width: '20px'}}>#</th>
                                            <th>Name</th>
                                            <th style={{width: `1%`}}/>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {(getLocalAuthData()?.permission?.includes('support_topic_list') && this.state.supportTopicList?.length > 0) ?
                                            this.state.supportTopicList.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td className="nowrap text-center">

                                                        {getLocalAuthData()?.permission?.includes('support_topic_edit') ?
                                                            <button className="btn btn-sm btn-outline-primary mr-3" onClick={() => this.handleGetSupportTopicDetails(item._id)}>Edit</button>
                                                            : null}

                                                        {getLocalAuthData()?.permission?.includes('support_topic_delete') ?
                                                            <button className="btn btn-sm btn-outline-danger mr-3" onClick={() => this.handleDeleteSupportTopic(item._id)}>Delete</button>
                                                            : null}
                                                    </td>
                                                </tr>
                                            )) :
                                            <tr>
                                                <td colSpan={7} className={`text-bold text-center`}>No item found</td>
                                            </tr>
                                        }
                                        </tbody>
                                    </table>
                                }
                                <div className="mt-3">
                                    <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={this.state.pagination?.page || 1}
                                        itemsCountPerPage={this.state.pagination?.limit || 10}
                                        totalItemsCount={this.state.pagination?.totalDocs || 10}
                                        pageRangeDisplayed={20}
                                        onChange={this.handlePageChange.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </App>
        );
    }
}

export default SupportTopics;
