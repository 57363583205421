import {Badge, Button, Col, Form, Modal, Row} from "react-bootstrap";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {FiPlus, FiTrash} from "react-icons/all";
import React, {Component} from 'react';
import {Reorder, getLocalAuthData} from "../helper/utils";
import {addItem, getData} from "../services/baseServices";
import {getItemStyle, getSectionListStyle} from "../helper/dragDropStyles";
import {getTableData, postData, updateItemPartial} from "../services/commonServices";

import AddBlockButton from "../components/AddBlockButton";
import {AiFillEye} from "@react-icons/all-files/ai/AiFillEye";
import App from "../App";
import BrowseFiles from "../components/BrowseFiles";
import {HOME_BLOCK_SECTIONS} from "../helper/constant";
import {Link} from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";
import MainSlide from "../components/MainSlide";
import Menu from "../components/Menu";
import PopupBanner from "../components/PopupBanner";
import {SHOP_BASE_URL} from "../helper/env";
import Select from "react-select";
import TopPromotionalBanner from "../components/TopPromotionalBanner";
import slideSample from "../assets/img/slide-sample.jpg";
import styleReactSelect from "../assets/styleReactSelect";
import {toast} from "react-toastify";

class HomepageManagement extends Component {
  state = {
    sections: [],
    activeSectionsData: [],
    dataIsFetching: true,
    isSaving: false,
    dragHandleProps: true,
    isDragDisabled: false,
    xModal: false,
    previewModal: false,
    templateList: [],
    templateFormData: [],
    selectedTemplateName: "",
    active_template : ""
  }

  componentDidMount() {

    this.getTemplate();

    getData(`/component-setting/get-homepage-settings?template=${this.state.selectedTemplateName}`)
      .then(res => {
        this.setState({
          sections: this.formatActiveBlockSection(res?.homepage_settings),
          activeSectionsData: res?.homepage_settings,
          dataIsFetching: false,
          active_template: res.active_template,
          selectedTemplateName: res.active_template
        })
      })
      .catch(() => {
        this.setState({dataIsFetching: false})
      })
  }

  toggleDraggableFlag = () => {
    // this.setState({dragHandleProps: !this.state.dragHandleProps}, ()=>{
    //   console.log("in main", this.state.dragHandleProps)
    // })
  }

  formatActiveBlockSection = (activeBlockSections = []) => {
    const sections = []
    activeBlockSections.forEach((section) => {
      const eachSection = HOME_BLOCK_SECTIONS.filter(sec => section.section_type === sec.section_type)
      sections.push({
        ...eachSection[0],
        settings: section.settings,
        setting_id: section._id,
        customDraggable: false,
        // settings: section.settings.params?.limit ? section.settings : {...section.settings, params: {limit: 6}}
      })
    })
    return sections
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.type === "SECTIONS") {
      const sections = Reorder(
        this.state.sections,
        result.source.index,
        result.destination.index
      )

      const activeSectionsData = Reorder(
        this.state.activeSectionsData,
        result.source.index,
        result.destination.index
      )

      if (result.source.index !== result.destination.index) {
        this.setState({sections, activeSectionsData}, () => {
          // this.handleOnChangeHomepageData()
        });
      }
    }
  }

  onDragUpdate = (result) => {
    //console.log('result: ', result)
  }

  handleOnChangeSettings = (settings, index, cb) => {
    const sections = [...this.state.sections]
    const activeSections = [...this.state.activeSectionsData]
    sections[index].settings = settings
    activeSections[index].settings = settings
    this.setState({sections, activeSections}, () => {
      // this.handleOnChangeHomepageData()
      cb()
    })
  }

  handleAddBlockSections = (blocks, callback = () => null) => {
    const sections = [...this.state.sections]
    const activeSectionsData = [...this.state.activeSectionsData]

    blocks.forEach(block => {
      // delete block.block_name
      sections.push({
        ...block,
        settings: {
          is_disabled: false,
          params: {}
        }
      })
      activeSectionsData.push({
        section_type: block.section_type,
        settings: {
          is_disabled: false,
          params: {}
        }
      })
    })

    this.setState({sections, activeSectionsData}, () => {
      callback()
    })
  }

  handleToggleBlockSectionDisable = (e, index) => {
    const sections = [...this.state.sections]
    const activeSectionsData = [...this.state.activeSectionsData]

    if (e.target.checked) {
      sections[index].settings.is_disabled = false
      activeSectionsData[index].settings.is_disabled = false
    } else {
      sections[index].settings.is_disabled = true
      activeSectionsData[index].settings.is_disabled = true
    }

    this.setState({sections, activeSectionsData}, () => {
      // this.handleOnChangeHomepageData()
    })
  }

  handleRemoveBlockSection = (index) => {
    const sections = [...this.state.sections]
    const activeSectionsData = [...this.state.activeSectionsData]
    sections.splice(index, 1)
    activeSectionsData.splice(index, 1)
    this.setState({sections, activeSectionsData}, () => {
      // this.handleOnChangeHomepageData()
    })
  }

  handleOnChangeHomepageData = () => {
    this.setState({isSaving: true}, () => {
      addItem(`/component-setting/save-homepage-settings?template_name=${this.state.selectedTemplateName}`, this.state.activeSectionsData)
        .then((res) => {
          this.setState({isSaving: false})
          toast.success(res.message)
        })
        .catch(errMsg => {
          this.setState({isSaving: false})
          toast.success(errMsg)
        })
    })
  }

  previewHomepageData = () => {
    this.setState({isSaving: true}, () => {
      addItem(`/component-setting/preview-homepage-settings?template_name=${this.state.selectedTemplateName}`, this.state.activeSectionsData)
        .then((res) => {
          this.setState({isSaving: false})
         // toast.success(res.message)
        })
        .catch(errMsg => {
          this.setState({isSaving: false})
          toast.success(errMsg)
        })
    })
  }


  /*
  *  TODO : Template
  * */

  handleOnChangeTemplateForm =(e)=>{
    const templateFormData = {...this.state.templateFormData}
    templateFormData.name = e.target.value
    this.setState({
      templateFormData,
    })
  }

  getTemplate =()=>{
    getTableData('/component-setting/get-homepage-template').then((res)=>{
      console.log(res)
      this.setState({templateList: res?.data})
    })
  }
  createTemplate =(e)=>{
    e.preventDefault()
    postData('/component-setting/add-template',this.state.templateFormData).then((res)=>{
      console.log(res)
      if (res.status === 1){
        toast.success(res.message)
        this.getTemplate()
        this.handleModalClose()
      }else {
        toast.warning(res.message)
      }
    })

  }

  handleModalShow =()=>{
    this.setState({xModal: true})
  }

  handleModalClose =()=>{
    this.setState({xModal:false})
  }

  handleChangeTemplate =(e)=>{
    this.setState({
      selectedTemplateName: e.target.value
    })
    getData(`/component-setting/get-homepage-settings?template=${e.target.value}`)
        .then(res => {
          this.setState({
            sections: this.formatActiveBlockSection(res?.homepage_settings),
            activeSectionsData: res?.homepage_settings,
            dataIsFetching: false
          })
        })
        .catch(() => {
          this.setState({dataIsFetching: false})
        })
  }

  handleActiveTemplate =()=>{
    updateItemPartial(`/component-setting/template/`, {template_name : this.state.selectedTemplateName}).then((res)=>{
      console.log(res)
      this.setState({active_template: this.state.selectedTemplateName})
    })
  }


  handlePreviewTemplate = ()=>{
    this.previewHomepageData()
    this.handlePreviewModalShow()
  }

  handlePreviewModalShow =()=>{
    this.setState({previewModal: true})
  }

  handlePreviewModalClose =()=>{
    this.setState({previewModal:false})
  }


  render() {
    return (
      <App layout="boxed">
        <Modal show={this.state.xModal} onHide={this.handleModalClose}>
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>Add new Template</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mr-1 my-2">
                  <Form.Label srOnly>Name</Form.Label>
                  <Form.Control type={`text`} name="name" size={`sm`}
                                placeholder={`Enter template name`}
                                onChange={this.handleOnChangeTemplateForm}
                  />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.handleModalClose} variant="secondary">
                Close
              </Button>
              <Button onClick={this.createTemplate} variant="primary"
                      type={`submit`}>
                Create
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>


        <Modal
            size="xl"
            show={this.state.previewModal}
            onHide={this.handlePreviewModalClose}
            dialogClassName="modal-390w"
            aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Preview {this.state.selectedTemplateName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe scrolling="no" style={{
              height:'80vw',
            }} src={`${SHOP_BASE_URL}?preview=${this.state.selectedTemplateName}`} frameBorder="0" height="100%" width="100%"/>
          </Modal.Body>
        </Modal>


        <div className="page-header">
          <h3 className="page-title">Homepage Management</h3>
        </div>
        <div className="card-block">
          <div className="block-header">
            <h4 className="block-title">Template Setting <Badge pill variant="warning">{this.state.active_template} ☑</Badge></h4>
            <div onClick={this.handleModalShow} className="btn btn-sm btn-secondary"><FiPlus/> Add new Template</div>
          </div>

          <div className="block-body">
            <form className="form-inline mb-3">
            <div className="form-group mr-3">
             {/* <label htmlFor="status" className="sr-only"></label>*/}
              <select id="status"
                      name="country"
                      onChange={this.handleChangeTemplate}
                      className='form-control form-control-sm'>
                <option value="">--Select Template--</option>
                {this.state.templateList && this.state.templateList.map((data,i)=>(
                    <option value={data?.name}>{data?.name}</option>
                ))}
              </select>
            </div>
              {this.state.selectedTemplateName !== "" &&<Button onClick={this.handleActiveTemplate} size="sm" variant="secondary">{this.state.selectedTemplateName === this.state.active_template ? 'Already Active' : 'Make Active'} {<Badge pill variant="warning">{this.state.selectedTemplateName}</Badge>}</Button>}
            </form>

            <Button onClick={this.handlePreviewTemplate} size="sm" variant="primary">Preview <AiFillEye/></Button>

          </div>
        </div>

        <MainSlide template={this.state.selectedTemplateName}/>



        <PopupBanner/>
        <TopPromotionalBanner />

        {/*<FeaturedServices/>*/}

        <div className={'position-relative'}>
          {this.state.dataIsFetching ?
            <LoadingSpinner/>
            :
            <DragDropContext onDragEnd={this.onDragEnd} onDragUpdate={this.onDragUpdate}>
              <Droppable droppableId="sectionsWrap" type="SECTIONS">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} style={getSectionListStyle(snapshot.isDraggingOver)}>
                    {this.state.sections.map((sec, index) => (
                      <Draggable isDragDisabled={this.state.isDragDisabled} key={index} draggableId={`section-${index}`} index={index}>
                        {(provided, snapshot) => (
                          <div ref={provided.innerRef}
                               {...provided.draggableProps}
                               style={getItemStyle(
                                 snapshot.isDragging,
                                 provided.draggableProps.style
                               )}>
                            {sec.component({
                              settings: sec.settings,
                              handler: provided,
                              index: index,
                              setting_id: sec.setting_id,
                              dragHandleProps: this.state.dragHandleProps,
                              toggleDragableFlag: this.toggleDraggableFlag,
                              toggleBlockSectionDisable: (e) => this.handleToggleBlockSectionDisable(e, index),
                              onDelete: () => this.handleRemoveBlockSection(index),
                              onChangeSettings: (settings, cb) => {
                                this.setState({isDragDisabled: true})
                                this.handleOnChangeSettings(settings, index, cb)
                              }
                            })}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          }
        </div>

        <AddBlockButton data={HOME_BLOCK_SECTIONS} action={this.handleAddBlockSections}/>

        <Button block size={'lg'} onClick={this.handleOnChangeHomepageData}
                disabled={this.state.isSaving}
                className={'mt-4'} variant={'primary'}>
          {!this.state.isSaving ? 'Save Changes' : 'Saving the Changes'}
        </Button>

        {/*<SectionCategories/>*/}
      </App>
    );
  }
}

export default HomepageManagement;
