import React, {Component} from 'react';
import App from "../../App";
import {FaLongArrowAltLeft, FiPlus, IoMdClose} from "react-icons/all";
import {Link, withRouter} from "react-router-dom";
import {Alert, Button, Col, Form, Modal, Row} from "react-bootstrap";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CreatableSelect from 'react-select/creatable';
import "../../assets/scss/add-product.scss";
import styleReactSelect from "../../assets/styleReactSelect";
import BrowseFiles from "../../components/BrowseFiles";
import {connect} from "react-redux";
import ChooseCategory from "../../components/ChooseCategory";
import {getBrandList} from "../../services/brandServices";
import {UploadAdapter} from "../../adapters/ckUploadAdapter";
import {addProduct} from "../../services/productServices";
import {categoryDetails} from "../../services/categoryServices";
import SelectCollection from "../../components/SelectCollection";
import {toast} from "react-toastify";
import Select from "react-select";
import AsyncSelect from "react-select";
import {getBDDivisions, getMerchantList} from "../../services/merchantServices";
import {getStoreList} from "../../services/storeServices";
import {getLocalAuthData} from "../../helper/utils"
import {activityLogPostData, getTableData, postData, updateItem, updateItemPartial} from "../../services/commonServices";

class ProjectSettings extends Component {
    state = {
        data:{}
    }

    componentDidMount() {
        this.handleGetProjectSetting()
    }


    handleGetProjectSetting = ()=>{
        getTableData('/project-setting').then((res)=>{
            this.setState({data: res})
        })
    }


    handleCkEditorOnChange = (name, data, isMultiLang, lang) => {
        const dataX = {...this.state.data, [name]: data};
        console.log(dataX)
        this.state.data = dataX
        /*this.setState({dataX},()=>{
            console.log(this.state.data)
        })*/
    }

    handleSubmit =(e)=>{
        e.preventDefault()
        if (this.state.data?._id === undefined){
            postData('/project-setting/', this.state.data).then((res)=>{
                console.log(res)
                toast.success(res.message)

                this.setState({
                    data: res?.data
                })
            })
        }else {
            updateItem('/project-setting/'+this.state.data?._id , this.state.data).then((res)=>{
                console.log(res)
                toast.success(res.message)
            })
        }


    }


    handleNameOnChange = (e) => {

        const data = {...this.state.data, [e.target.name]: e.target.value}
        this.setState({data},()=>{
            console.log(this.state.data)
        })
    }


    render() {

        return (
            <App layout="boxed">
                    <>
                        <div className="page-header">
                            <h2 className="page-title">Project Settings</h2>
                            <div className="ml-auto">
                               {/* <Link to={`${process.env.PUBLIC_URL}/products/list`}
                                      className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Product List</Link>*/}
                            </div>
                        </div>
                        <div className="main-content">
                            {this.state.isLoadingData ?
                                <div className={`card-block`}>
                                    <div className={`block-body`}>Loading...</div>
                                </div>
                                :
                                <Form
                                    noValidate
                                    validated={this.state.isFormValidated}
                                    onSubmit={this.handleSubmit}>
                                    <div className="card-block">
                                        <div className="block-header">
                                            <h4 className="block-title">Project Settings</h4>
                                        </div>

                                        <div className="block-body">

                                            <Form.Group as={Row} controlId="formName">
                                                <Form.Label column sm="3">
                                                    Contact Number <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control type="text"
                                                                  defaultValue={this.state.data?.contact_number}
                                                                  required
                                                                  onChange={(e) => {
                                                                      this.handleNameOnChange(e)
                                                                  }}
                                                                  name="contact_number" placeholder=""/>
                                                    <Form.Control.Feedback type="invalid">

                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>



                                            <Form.Group as={Row} controlId="formName">
                                                <Form.Label column sm="3">
                                                    Contact Email <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control type="text"
                                                                  required
                                                                  defaultValue={this.state.data?.contact_email}
                                                                  onChange={(e) => {
                                                                      this.handleNameOnChange(e)
                                                                  }}
                                                                  name="contact_email" placeholder=""/>
                                                    <Form.Control.Feedback type="invalid">

                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>




                                            <Form.Group as={Row} controlId="formHighlight">
                                                <Form.Label column sm="3">
                                                    About Us <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={this.state.data?.about_us}
                                                        onInit={editor => {
                                                            editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
                                                                return new UploadAdapter(loader);
                                                            };
                                                        }}
                                                        config={{
                                                            image: {
                                                                resizeUnit: 'px',
                                                                toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],
                                                                styles: [
                                                                    'full',
                                                                    'alignLeft',
                                                                    'alignRight'
                                                                ]
                                                            }
                                                        }
                                                        }
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            this.handleCkEditorOnChange('about_us', data);
                                                        }}
                                                    />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="formHighlight">
                                                <Form.Label column sm="3">
                                                    Privacy & Confidentiality <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={this.state.data?.privacy_and_confidentiality}
                                                        onInit={editor => {
                                                            editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
                                                                return new UploadAdapter(loader);
                                                            };
                                                        }}
                                                        config={{
                                                            image: {
                                                                resizeUnit: 'px',
                                                                toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],
                                                                styles: [
                                                                    'full',
                                                                    'alignLeft',
                                                                    'alignRight'
                                                                ]
                                                            }
                                                        }
                                                        }
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            this.handleCkEditorOnChange('privacy_and_confidentiality', data);
                                                        }}
                                                    />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="formHighlight">
                                                <Form.Label column sm="3">
                                                    Terms & Conditions <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={this.state.data?.terms_and_conditions}
                                                        onInit={editor => {
                                                            editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
                                                                return new UploadAdapter(loader);
                                                            };
                                                        }}
                                                        config={{
                                                            image: {
                                                                resizeUnit: 'px',
                                                                toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],
                                                                styles: [
                                                                    'full',
                                                                    'alignLeft',
                                                                    'alignRight'
                                                                ]
                                                            }
                                                        }
                                                        }
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            this.handleCkEditorOnChange('terms_and_conditions', data);
                                                        }}
                                                    />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="formHighlight">
                                                <Form.Label column sm="3">
                                                    Return and Refund Policy <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={this.state.data?.return_and_refund_policy}
                                                        onInit={editor => {
                                                            editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
                                                                return new UploadAdapter(loader);
                                                            };
                                                        }}
                                                        config={{
                                                            image: {
                                                                resizeUnit: 'px',
                                                                toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],
                                                                styles: [
                                                                    'full',
                                                                    'alignLeft',
                                                                    'alignRight'
                                                                ]
                                                            }
                                                        }
                                                        }
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            this.handleCkEditorOnChange('return_and_refund_policy', data);
                                                        }}
                                                    />
                                                </Col>
                                            </Form.Group>


                                            <Form.Group as={Row} controlId="formName">
                                                <Form.Label column sm="3">
                                                    Map (Iframe) <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control type="text"
                                                                  defaultValue={this.state.data?.map}
                                                                  required
                                                                  onChange={(e) => {
                                                                      this.handleNameOnChange(e)
                                                                  }}
                                                                  name="map" placeholder=""/>
                                                    <Form.Control.Feedback type="invalid">

                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>




                                            <Button size="lg"
                                                    type={`submit`}
                                                    variant="primary">{this.state.isSubmitting ? 'Publishing...' : 'Publish'}</Button>

                                        </div>
                                    </div>



                                </Form>
                            }

                        </div>
                    </>
            </App>
        );
    }
}



export default withRouter(ProjectSettings);
