import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import BrowseFiles from "../../components/BrowseFiles";
// import Bank from "../../components/Bank";
import axios from "axios";
import config from "../../helper/env";
import {ToastsContainer, ToastsStore} from "react-toasts";
import {toast} from "react-toastify";
import {getDeviceType, getLocalAuthData, headerData} from "../../helper/utils";
import {Link} from "react-router-dom";
import {FiEdit, FiTrash} from "react-icons/all";
import {confirmAlert} from "react-confirm-alert";
import {toggleStatusUpdate} from "../../services/bankServices";

class Banks extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            bankData: [],
            editFormData: null,
            editStatus: false
        }
    }

    componentDidMount() {
        axios.get(`${config.API_BASE_URL}/bank`, headerData)
            .then(res => {
                console.log(res.data.items);
                this.setState({bankData: res.data.items});
            }).catch(e => this.setState({error: true}));
    }

    renderData = () => {
        axios.get(`${config.API_BASE_URL}/bank`, headerData)
            .then(res => {
                console.log(res.data.items);
                this.setState({bankData: res.data.items});
            }).catch(e => this.setState({error: true}));
    }

    handleImage = (files) => {
        this.setState({
            formData: {
                ...this.state.formData,
                image: files[0].thumb_img
            }
        });
    }

    handleFormDataChange = (e) => {
        this.setState({formData: {...this.state.formData, [e.target.name]: e.target.value}});
        setTimeout(() => {
            console.log(this.state.formData);
        }, 3000);
    };

    deleteBank = (id) => {
        axios.delete(`${config.API_BASE_URL}/bank/${id}/permanent/`, headerData) //Permanent delete
            .then(response => {
                if (response.data.status === 1) {
                    ToastsStore.success(response.data.message);
                    this.renderData();
                }
                if (response.data.status === 2) {
                    ToastsStore.error(response.data.message);
                }
            }).catch(error => {
            ToastsStore.error("Error");
        });
    };

    editBank = (id) => {
        axios.get(`${config.API_BASE_URL}/bank/${id}`, headerData).then(res => {
            this.setState({
                editFormData: res.data.bank,
                formData: {
                    ...this.state.formData,
                    name: res.data.bank.name,
                },
                editStatus: true
            });
            window.scrollTo({top: 0, behavior: 'smooth'});
        }).catch(error => {
            ToastsStore.error("Error");
        });
    };

    renderBank = () => {
        const bankList = this.state.bankData;
        if (bankList) {
            return (
                <Row>
                    {bankList.map((bank, index) => {
                            return (
                                <Col md={3}>
                                    {/*<Bank
                    imageLink={bank.image} bankId={bank._id}
                    deleteBank={() => this.deleteBank(bank._id)}
                    editBank={() => this.editBank(bank._id)}
                  />*/}
                                </Col>
                            )
                        }
                    )}
                </Row>
            )
        }

        return (
            <Form.Control as="select">
                <option>Fetching Bank</option>
            </Form.Control>
        )
    };

    handleToggleStatusUpdate = (id, status, index) => {
        confirmAlert({
            title: 'Status Update',
            message: 'Are you sure to change this status?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => toggleStatusUpdate(id, {status: status}).then(res => {

                        // Update row data
                        const bankData = [...this.state.bankData]
                        bankData[index].status = status
                        this.setState({bankData})
                        toast.success(res.message)

                    }).catch(errMsg => {
                        toast.error(errMsg)
                    })
                }, {
                    label: 'No',
                }
            ]
        });
    }

    handleSubmitForm = () => {
        const data = this.state.formData;
        let url = `${config.API_BASE_URL}/bank`;
        if (this.state.editStatus === true) {
            url = `${config.API_BASE_URL}/bank/` + this.state.editFormData._id;
            axios.put(url, data, headerData).then(response => {
                if (response.data.status === 1) {
                    toast.success(response.data.message);
                    this.renderData();
                }
                if (response.data.status === 2) {
                    toast.error(response.data.message);
                }
            }).catch(error => {
                toast.error("Something went wrong please try again after refresh the page.");
            });
        } else {
            axios.post(url, data, headerData).then(response => {
                if (response.data.status === 1) {
                    toast.success(response.data.message);
                    this.renderData();
                }
                if (response.data.status === 2) {
                    toast.error(response.data.message);
                }
            }).catch(error => {
                toast.error("Something went wrong please try again after refresh the page.");
            });
        }
    };

    render() {
        return (
            <App layout={"boxed"}>
                <div className="page-header">
                    <h2 className="page-title">Bank Management</h2>
                </div>

                <div className="main-content">
                    <div className="card-block">

                        <div className="block-body">
                            <>
                                {getLocalAuthData()?.permission?.includes('bank_add') ?
                                    <Form id={"bankForm"}>
                                        <Form.Group as={Row} controlId="bankName">
                                            <Form.Label column sm="3">
                                                Bank Name <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control type={"text"}
                                                              defaultValue={this.state.editFormData ? this.state.editFormData.name : ""}
                                                              name="name" onChange={this.handleFormDataChange}
                                                              placeholder={"Bank name"}/>
                                            </Col>
                                        </Form.Group>


                                        <Form.Group as={Row} controlId="bankFormSubmit">
                                            <Form.Label column sm="3"/>
                                            <Col sm="9" md={8} lg={7}>
                                                <Button
                                                    variant={"primary"}
                                                    onClick={this.handleSubmitForm}
                                                >Save</Button>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                    : <span className={`text-bold text-center`}> Permission Denied</span>}
                            </>

                        </div>
                    </div>

                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">Bank List</h4>
                        </div>

                        <div className="block-body">
                            {/*{this.renderBank()}*/}
                            {this.state.isLoading ?
                                <>Loading...</>
                                :
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th style={{width: '20px'}}>#</th>
                                        <th>Bank Name</th>
                                        <th>Status</th>
                                        <th style={{width: `1%`}}/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {(getLocalAuthData()?.permission?.includes('bank_list') && this.state.bankData?.length > 0) ?
                                        this.state.bankData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.name}</td>
                                                <td>{(item?.status === 1) ? 'Active' : "Inactive"}</td>

                                                <td className="nowrap text-center">
                                                    {getLocalAuthData()?.permission?.includes('bank_edit') ?
                                                    <span className="action text-bold text-default mr-3 cursor-pointer" onClick={() => this.editBank(item._id)}><FiEdit/> </span> : null}
                                                    {getLocalAuthData()?.permission?.includes('bank_active_inactive') ?
                                                    <span className={item?.status === "1" ? 'text-bold text-warning mr-3' : 'text-bold text-success mr-3'}
                                                          onClick={() => this.handleToggleStatusUpdate(item._id, item?.status === 1 ? 2 : 1, index)}
                                                          style={{cursor: 'pointer'}}>{item?.status === 1 ? 'Inactive' : 'Active'}
                                                    </span> : null }
                                                    {getLocalAuthData()?.permission?.includes('bank_delete') ?
                                                    <span className="action text-bold text-danger mr-3 cursor-pointer" onClick={() => this.deleteBank(item._id)}><FiTrash/> Delete</span>
                                                        :null}
                                                </td>
                                            </tr>
                                        )) :
                                        <tr>
                                            <td colSpan={7} className={`text-bold text-center`}>No item found</td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                            }
                        </div>

                    </div>

                </div>
                <ToastsContainer store={ToastsStore}/>
            </App>
        );
    }
}

export default Banks;
