import React, {Component} from 'react';
import App from "../../App";
import {Link, withRouter} from "react-router-dom";
import {FiArrowLeft} from "react-icons/all";
import {Button, Col, Form, Row} from "react-bootstrap";
import {addCollection} from "../../services/collectionServices";
import {toast} from "react-toastify";
import {getLocalAuthData} from "../../helper/utils";
import {activityLogPostData} from "../../services/commonServices";

class CreateCollection extends Component {
  state = {
    isCreating: false
  }

  handleCreateCollection = () => {
    if (this.state?.name && this.state?.collection_slug) {
      addCollection({
        name: this.state.name,
        collection_slug: this.state.collection_slug
      })
        .then(res => {
          this.props.history.push(`${process.env.PUBLIC_URL}/collections`);
          toast.success('Collection successfully created');
          activityLogPostData('was create new collection','/collections/',this.state.name)
        })
        .catch(errMsg => {
          toast.error(errMsg);
        })
    }
  }

  handleOnInputChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    })
  }

  render() {
    return (
      <App layout={"boxed"}>
        <div className="main-content">
          <div className="page-header">
            { getLocalAuthData()?.permission?.includes('create_collection') ?
            <h2 className="page-title">New Collection</h2>:null}
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/collections`} className="btn btn-sm btn-link"><FiArrowLeft/> Back to Collection List</Link>
            </div>
          </div>
          { getLocalAuthData()?.permission?.includes('create_collection') ?
          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Collection Details</h4>
            </div>
            <div className="block-body">
              <Form.Group as={Row} controlId="formCollectionName">
                <Form.Label column sm="3">
                  Collection Name <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    onChange={this.handleOnInputChange}
                    type="text" placeholder="Collection Name"
                    name="name"/>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formCollectionSlug">
                <Form.Label column sm="3">
                  Collection Slug <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    onChange={this.handleOnInputChange}
                    type="text" placeholder="Collection Slug" name="collection_slug"/>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col sm="3"/>
                <Col sm="9" md={8} lg={7}>
                  <Button
                    disabled={this.state.isCreating}
                    onClick={() => this.handleCreateCollection()}
                    variant={"primary"}>Save</Button>
                </Col>
              </Form.Group>
            </div>
          </div>
              :<>
              <span>Permission Denied</span>
              </>}
        </div>
      </App>
    );
  }
}

export default withRouter(CreateCollection);
