import React, {Component} from 'react';
import App from "../../App";
import {Badge, Button, Col, Form, Modal, Row} from "react-bootstrap";
import {FiCheck, FiPlus, FiUpload, IoMdClose} from "react-icons/all";

import Pagination from 'react-js-pagination';
import {withRouter, Link} from "react-router-dom";
import "../../assets/scss/order-list.scss";
import axios from "axios";
import {API_BASE_URL} from "../../helper/env";
import {ToastsContainer, ToastsStore} from "react-toasts";
import {getChildLocations} from "../../services/locationServices";
import sampleImportFile from "../../assets/resources/bulk-merchant-upload-sample.csv";
import {
  getMerchantList,
  merchantBulkImport,
  profileStatusUpdate,
  getMerchant,
  deleteMerchant,
  revertDeletedMerchant,
  deleteMerchantPermanently,
  resendMerchantPassword
} from "../../services/merchantServices";
import {toast} from "react-toastify";
import {IN_VERIFICATION, NEED_VERIFICATION, VERIFIED} from "../../helper/variables";
import {confirmAlert} from "react-confirm-alert";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import {getLocalAuthData, objToUrlPrams, urlPramsToObj} from "../../helper/utils";
import Select from "react-select";
import styleReactSelect from "../../assets/styleReactSelect";
import {activityLogPostData, getTableData, updateItem, updateItemPartial} from "../../services/commonServices";

class EntrepreneurList extends Component {
  state = {
    activeBlockTab: 'All',
    showAdvanceSearchOptions: false,
    entrepreneurList: [],
    queryData: [],
    activePage: 1,
    isLoading: true,
    locations: [],
    businessChildLocations: [],
    entrepreneurType: [],
    pagination: {},
    isImporting: false,
    isExporting: false,
    limit: 20,
    userType: null,
    statusChangingData: {
      status: null,
      message: '',
      action: null,
      userId: ''
    },
    isShowingStatusChangingModal: false,
    isShowingBulkImportModal: false,
    bulkImportFormData: {
      merchant_type: null,
      country: null,
      profile_status: 'Verified',
      is_customer: true
    },
    isUploading: false,
    message: "",
    invalid_rows: [],
    importCompletedModal: false
  };

  handleBlockTab = label => {
    this.setState({
      activeBlockTab: label
    })
  };

  componentDidMount() {
    const authData = getLocalAuthData()
    const history = this.props.history
    let page = 1

    this.setState({userType: authData?.type})
    if(history.location.search) {
      const params = urlPramsToObj(history)
      if('page' in params) {
        page = params.page
      }
    }

    getMerchant({limit: this.state.limit, page: page})
        .then(res => {
          this.setState({entrepreneurList: res.items, isLoading: false, pagination: res.pagination});
        })

    axios.get(`${API_BASE_URL}/merchantType`)
        .then(res => {
          let merchantTypes = [];
          res.data.merchant_type_obj.forEach(item => {
            merchantTypes.push({id: item._id, value: item._id, label: item.type_name          })
          });
          this.setState({merchantTypes, entrepreneurType: res.data.merchant_type_obj});
        }).catch(e => this.setState({error: true}));

    axios.get(`${API_BASE_URL}/location?type=Country`)
        .then(res => {
          this.setState({locations: res.data.locations});
        }).catch(e => console.log("Error"));
  }

  getQueryData = async () => {
    let queryData = "";
    await Object.entries(this.state.queryData).forEach(([key, val]) => {
          queryData = queryData.concat(key + "=" + val + "&");
        }
    )
    this.setState({isLoading: true});
    axios.get(`${API_BASE_URL}/merchant?limit=${this.state.limit}&${queryData}`)
        .then(res => {
          this.setState({entrepreneurList: res.data.items, pagination: res.data.pagination, isLoading: false});
        }).catch(e => console.log("Error"));
  }

  handleShowAdvanceSearchOptions = () => {
    if (this.state.showAdvanceSearchOptions === "false") {
      this.setState({
        queryData: {
          ...this.state.queryData,
          is_flagship: "",
          profile_status: "",
        }
      })
    }
    this.setState({
      showAdvanceSearchOptions: !this.state.showAdvanceSearchOptions,
    })
  }

  handlePageChange = async (pageNumber) => {
    const history = this.props.history
    history.push(`${history.location.pathname}?page=${pageNumber}`)
    await this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber});
    this.getQueryData();
  }


  changeStatus = async (statusObj, merchant_id,name) => {
    const data = statusObj;
    updateItemPartial(`/merchant/${merchant_id}/changeStatus`,data).then((res)=>{
      if (res.status === 1) {
        toast.success(res.message);
        activityLogPostData('was change this entrepreneurs status ','/entrepreneurs/details/'+merchant_id ,name )
      }
      if (res.status === 2) {
        toast.error(res.message);
      }
    })

  };

  changeFlagshipStatus = async (statusObj, merchant_id) => {
    const data = statusObj;
    axios.patch(`${API_BASE_URL}/merchant/${merchant_id}/changeFlagshipStatus`, data)
        .then(response => {
          if (response.data.status === 1) {
            toast.success(response.data.message);
          }
          if (response.data.status === 2) {
            toast.error(response.data.message);
          }
        }).catch(error => {
      toast.error("Error");
    });
  };

  renderType = () => {
    const entrepreneurType = this.state.entrepreneurType;
    if (entrepreneurType) {
      return (
          <select id="merchant_type" className='form-control form-control-sm'
                  name="merchant_type"
                  onChange={this.handleSearchFormDataChange}
          >
            <option value="">--Select Type--</option>
            {
              entrepreneurType.map((type, index) =>
                  <option
                      key={type._id}
                      value={type._id}
                  >{type.type_name}</option>)
            }
          </select>
      )
    }
    return (
        <Form.Control as="select">
          <option>Fetching Type</option>
        </Form.Control>
    )
  };

  handleProfileStatusUpdate = () => {
    const data = {
      profile_status: this.state.statusChangingData.status,
      message: this.state.statusChangingData.message
    }

    profileStatusUpdate(this.state.statusChangingData.userId, data)
        .then(res => {
          getMerchantList()
              .then(res => {
                this.setState({entrepreneurList: res, isShowingStatusChangingModal: false})
              })
              .catch(errMsg => {
                console.log('Error message: ', errMsg)
              })
          toast.success(res.message);
        })
        .catch(errMsg => {
          toast.error(errMsg);
        })
  }

  handleInputMessageOnChange = (e) => {
    const statusChangingData = {...this.state.statusChangingData}
    statusChangingData.message = e.target.value
    this.setState({statusChangingData})
  }

  handleShowStatusChangingModal = (userId, status, action) => {
    if (action === 'Approve') {
      const data = {
        profile_status: status
      }

      confirmAlert({
        title: 'Confirm',
        message: `Are you sure to ${action.toLowerCase()} this entrepreneur?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => profileStatusUpdate(userId, data)
                .then(res => {
                  getMerchantList({page: this.state.pagination.page, limit: this.state.limit})
                      .then(res => {
                        this.setState({entrepreneurList: res})
                      })
                      .catch(errMsg => {
                        console.log('Error message: ', errMsg)
                      })
                  toast.success(res.message);
                })
                .catch(errMsg => {
                  toast.error(errMsg);
                })
          },
          {
            label: 'No',
            // onClick: () => alert('Click No')
          }
        ]
      })
    } else {
      this.setState({
        statusChangingData: {
          status: status,
          action: action,
          userId: userId
        }
      }, () => {
        this.setState({isShowingStatusChangingModal: true})
      })
    }
  }

  handleHideStatusChangingModal = () => {
    this.setState({
      status: null,
      action: null,
      message: '',
      userId: ''
    }, () => {
      this.setState({isShowingStatusChangingModal: false})
    })
  }

  renderProfileStatus = (userId, status) => {
    if (status === NEED_VERIFICATION) {
      return <span className={`badge badge-warning`}>Profile Not Complete</span>
    } else if (status === IN_VERIFICATION) {
      return (
          <>
        <span
            style={{cursor: 'pointer'}}
            onClick={() => this.handleShowStatusChangingModal(userId, 'Verified', 'Approve')}
            className={`text-bold text-success mr-4`}><FiCheck/> Approve</span>
            <span
                style={{cursor: 'pointer'}}
                onClick={() => this.handleShowStatusChangingModal(userId, 'Declined', 'Declined')}
                className={`text-bold text-danger`}><IoMdClose/> Decline</span>
          </>
      )
    } else if (status === VERIFIED) {
      return <span className={`badge badge-success`}>Verified</span>
    } else {
      return <span className={`badge badge-danger`}>Declined</span>
    }
  }

  renderList = () => {
    const entrepreneurList = this.state.entrepreneurList;
    if (entrepreneurList) {
      return (
          <>
            {entrepreneurList.map((item, index) => {
                  let selected1 = true;
                  let selected2 = false;

                  let flagshipselected1 = false;
                  let flagshipselected2 = true;

                  if (item.status === '2') {
                    selected2 = true;
                    selected1 = false;
                  }

                  if (item.is_flagship === true) {
                    flagshipselected1 = true;
                    flagshipselected2 = false;
                  }

                  return (
                      <tr key={index}>
                        {/* eslint-disable-next-line react/no-direct-mutation-state */}
                        <td>{this.state?.pagination?.pagingCounter + index}</td>
                        <td>
                          <div className="order-item">
                            <h4 className="order-id mb-0">
                              <Link to={`${process.env.PUBLIC_URL}/entrepreneurs/details/${item._id}`} target={'_blank'}>
                                {item.name}
                              </Link>
                            </h4>
                            <ul className="ordered-items">
                              {item?.merchant_code && <li>ID: <strong>{item?.merchant_code}</strong></li>}
                              <li>{item?.merchant_type_info?.type_name}</li>
                              <li>{item?.ecommerce_partner?"Ecommerce Partner":''}</li>
                              <li>{item?.logistic_partner?"Logistic Partner":''}</li>
                            </ul>
                          </div>
                        </td>

                        <td>
                          <div className="order-item">
                            <h4 className="order-id">{item?.user_info?.name}</h4>
                            <ul className="ordered-items">
                              <li>{item?.user_info?.phone}</li>
                              <li>{item?.user_info?.email}</li>
                              <li>
                                <a href={'#'} size={`sm`} onClick={() => this.handleResendPassword(item?.user_info?._id)} variant={`outline-danger`}>Resend Password</a>
                              </li>
                              <li><Badge variant="info">{item?.muktopath_user === true && item?.muktopath_user !== undefined && 'MuktoPath user'}</Badge></li>
                            </ul>
                          </div>
                        </td>
                        <td className={`text-center`}>{item.total_store < 1 ? 0 : item.total_store}</td>
                        <td className={`nowrap text-center`}>
                          {this.renderProfileStatus(item?.user_info?._id, item?.user_info?.profile_status)}
                          {item?.deleted &&
                          <><br/><span className={`badge badge-danger`}>Deleted</span></>
                          }
                          <br/>
                          {item?.old_merchant === true && <Badge pill variant="secondary">Old Merchant</Badge>}
                        </td>
                        <td>
                          { getLocalAuthData()?.permission?.includes('entrepreneurs_active_inactive') ?
                              <select style={{width: '120px'}}
                                      className="form-control form-control-sm"
                                      onChange={(e) => {
                                        this.changeStatus({status: e.target.value}, item._id , item?.name)
                                      }}
                              >
                                <option value="1" selected={selected1}>Active</option>
                                <option value="2" selected={selected2}>Inactive</option>
                              </select> : null}
                        </td>

                        <td>
                          { getLocalAuthData()?.permission?.includes('entrepreneurs_flagship_on_off') ?
                              <select style={{width: '60px'}}
                                      className="form-control form-control-sm"
                                      onChange={(e) => {
                                        this.changeFlagshipStatus({is_flagship: e.target.value}, item._id)
                                      }}
                              >
                                <option value="true" selected={flagshipselected1}>On</option>
                                <option value="false" selected={flagshipselected2}>Off</option>
                              </select>: null}
                        </td>

                        <td style={{width: '150px'}}>
                          { getLocalAuthData()?.permission?.includes('entrepreneurs_stores') ?
                              <Link to={`/stores/list?merchant=${item._id}`}
                                    className="btn btn-block btn-sm btn-outline-secondary" target={'_blank'}>Stores</Link>
                              : null }
                          { getLocalAuthData()?.permission?.includes('entrepreneurs_view') ?
                              <Link to={`/entrepreneurs/details/${item._id}`}
                                    className="btn btn-block btn-sm btn-outline-secondary" target={'_blank'}>View</Link> :null}

                          { getLocalAuthData()?.permission?.includes('entrepreneurs_edit') ?
                              <Link to={`${process.env.PUBLIC_URL}/entrepreneurs/edit/${item._id}`}
                                    className="btn btn-block btn-sm btn-outline-info" target={'_blank'}>Edit</Link>:null}

                          {( getLocalAuthData()?.permission?.includes('entrepreneurs_commissions') && item.user_info?.profile_status === VERIFIED) &&
                          <a target={'_blank'}
                             rel="noopener noreferrer"
                             href={`/merchant-commission/${item._id}`}
                             className="btn btn-block btn-sm btn-outline-info" target={'_blank'}>Commissions</a>}

                          {/*{
                            ( getLocalAuthData()?.permission?.includes('entrepreneurs_delete') && item?.deleted) ? <><Button block size={`sm`} onClick={() => this.handleRevertDeletedMerchant(item._id)} variant={`outline-danger`}>Revert Delete</Button></>
                                : <><Button block size={`sm`} onClick={() => this.handleDeleteMerchant(item._id)} variant={`outline-danger`}>Delete</Button></>
                          }*/}

                          {/*{(getLocalAuthData()?.permission?.includes('entrepreneurs_delete') && this.state.userType && this.state.userType === 11) &&
                          <button
                              className="btn btn-block btn-sm btn-outline-warning"
                              onClick={() => this.handlePermanentDeleteMerchant(item._id)}
                          >Hard Delete
                          </button>}*/}
                        </td>
                      </tr>
                  )
                }
            )}
          </>
      )
    }

    return (
        <td colSpan={7} className={`text-center`}>
          <strong>No entrepreneur found</strong>
        </td>
    )
  };

  handleLocationInputOnChange = (e, index, typePurpose) => {
    const childLocations = [...this.state[typePurpose]];
    if (typePurpose === 'businessChildLocations') {
      this.handleSearchFormDataChange(e)
    } else {
      this.handleSearchFormDataChange(e)
    }
    if (e.target.value !== '') {
      getChildLocations(e.target.value)
          .then(res => {
            childLocations.splice(index, childLocations.length - index);
            if (res.length > 0) {
              childLocations[index] = res
            }
            this.setState({[typePurpose]: childLocations})
          })
          .catch(errMsg => {
            console.log(errMsg)
            childLocations.splice(index, childLocations.length - index)
            this.setState({[typePurpose]: childLocations})
          })
    } else {
      childLocations.splice(index, childLocations.length - index);
      this.setState({[typePurpose]: childLocations})
    }
  }

  renderLocations = () => {
    const locations = this.state.locations;
    if (locations) {
      return (
          <>
            {locations.map((items, index) =>
                <option key={index} value={`${items._id}`}>{items.name}</option>
            )}
          </>
      )
    }
  };

  handleResendPassword = (id) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to resend merchant password?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => resendMerchantPassword(id).then(res => {
            if (res.status === 1) {
              toast.success(res.message);
            } else {
              toast.success(res.message);
            }
          }).catch(errMsg => {
            toast.error(errMsg)
          })
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }

  handleDeleteMerchant = (id) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to delete this merchant?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteMerchant(id)
              .then(res => {
                toast.success('Successfully deleted');
                getMerchant()
                    .then(res => {
                      this.setState({entrepreneurList: res.items, pagination: res.pagination, isLoading: false}, () => {
                      });
                    })
              })
              .catch(errMsg => {
                toast.error(errMsg)
              })
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }

  handleRevertDeletedMerchant = (id) => {
    confirmAlert({
      title: 'Revert Deleted Merchant',
      message: 'Are you sure to revert deleted merchant?',
      buttons: [
        {
          label: 'Revert',
          onClick: () => revertDeletedMerchant(id).then(res => {
            if (res.status === 1) {
              toast.success(res.message);
              getMerchant().then(res => {
                this.setState({entrepreneurList: res.items, pagination: res.pagination, isLoading: false}, () => {
                });
              });
            } else {
              toast.warning(res.message);
            }
          }).catch(errMsg => {
            toast.error(errMsg)
          })
        },
        {label: 'No'}
      ]
    });
  }

  handlePermanentDeleteMerchant = (id) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to delete this merchant permanently?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteMerchantPermanently(id)
              .then(res => {
                toast.success('Successfully deleted');
                getMerchant()
                    .then(res => {
                      this.setState({entrepreneurList: res.items, pagination: res.pagination, isLoading: false}, () => {
                      });
                    })
              })
              .catch(errMsg => {
                toast.error(errMsg)
              })
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }

  handleSearchFormDataChange = (e) => {
    this.setState({queryData: {...this.state.queryData, [e.target.name]: (e.target.type === 'checkbox' ? e.target.checked : e.target.value), page: 1}});
  };

  handleMerchantBulkImporthandleMerchantBulkImport = (e) => {
    const file = e.target.files[0];
    this.setState({isImporting: true}, () => {
      merchantBulkImport(file)
          .then(res => {
            this.setState({isImporting: false});
            getMerchantList()
                .then(res => {
                  console.log(res)
                  this.setState({entrepreneurList: res})
                })
                .catch(errMsg => {
                  console.log('Error message: ', errMsg)
                })
            toast.success(res.message);
          })
          .catch(errMsg => {
            this.setState({isImporting: false});
            toast.error(errMsg);
          })
    })
  }

  handleOnApply = (e, picker) => {
    const queryData = {...this.state.queryData};

    queryData.from_date = moment(picker.startDate).format('DD/MM/YYYY');
    queryData.to_date = moment(picker.endDate).format('DD/MM/YYYY');

    this.setState({queryData}, () => {
      console.log(this.state)
    })
  }

  showBulkImporterModal = () => {
    this.setState({isShowingBulkImportModal: true})
  }

  hideBulkImporterModal = () => {
    this.setState({isShowingBulkImportModal: false})
  }

  selectMerchantTypeOnChange = (value, name) => {
    const bulkImportFormData = {...this.state.bulkImportFormData}
    bulkImportFormData[name] = value.value
    this.setState({bulkImportFormData})
  }

  selectOnChange = (e) => {
    const bulkImportFormData = {...this.state.bulkImportFormData}
    bulkImportFormData[e.target.name] = e.target.value
    this.setState({bulkImportFormData})
    console.log(this.state.bulkImportFormData);
  }

  onFileUploadOnChange = (e) => {
    const file = e.target.files[0];
    const bulkImportFormData = {...this.state.bulkImportFormData}
    bulkImportFormData[e.target.name] = file
    this.setState({bulkImportFormData})
  }

  handleImportBulkProduct = (e) => {
    e.preventDefault()
    this.setState({isUploading: true}, () => {

      console.log(this.state.bulkImportFormData);
      merchantBulkImport(this.state.bulkImportFormData).then(res => {
        console.log(res);
        this.setState({
          storeList: [],
          bulkImportFormData: null,
          isLoading: true,
          isShowingBulkImportModal: false,
          isUploading: false,
          message: res.message,
          invalid_rows: res.invalidRows,
          importCompletedModal: true
        });
        toast.success(res.message);
      }).catch(errMsg => {
        this.setState({isUploading: false})
        toast.error(errMsg)
      })
    })
  }

  handleImportCompletedModal = () => {
    this.setState({...this.state, importCompletedModal: false}, () => {
          getMerchant().then(res => {
            this.setState({entrepreneurList: res.items, isLoading: false, pagination: res.pagination, invalid_rows:[], message: ""})
          }).catch(errMsg => {
            console.log('Error message: ', errMsg)
            this.setState({isLoading: false})
          })
        }
    )
  }

  handleExportFileV2 = async ()=>{
    console.log('test')
    const result = await axios.get(`${API_BASE_URL}/merchant?limit=20`)
    console.log(result)
    /* result.data.items.map((data , index) =>{

     axios.get(`${API_BASE_URL}/dashboard/`+data._id).then((res)=>{
         console.log(res)
      })

    })*/

  }


  handleExportFile = async (fileType) => {
    let queryData = "";
    Object.entries(this.state.queryData).forEach(([key, val]) => {
          queryData = queryData.concat(key + "=" + val + "&");
        }
    )
    await this.setState({isExporting: true});
    axios.get(`${API_BASE_URL}/merchant?export=${fileType}&${queryData}`)
        .then(res => {
          console.log(res)
          this.setState({isExporting: false});
        }).catch(e => console.log("Error"));
  }

  render() {
    return (
        <App layout="">

          <Modal show={this.state.importCompletedModal}
                 onHide={this.hideBulkImporterModal}>
            <Modal.Header closeButton>
              <Modal.Title>Import Completed</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <span>Message : {this.state.message}</span>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={this.handleImportCompletedModal}> OK </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.isShowingBulkImportModal}
                 onHide={this.hideBulkImporterModal}>
            <Form onSubmit={this.handleImportBulkProduct}>
              <Modal.Header closeButton>
                <Modal.Title>Import Merchant</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group as={Row} controlId="formMerchantImport">
                  <Form.Label column sm="3"> Merchant Type <span className="text-danger">*</span></Form.Label>
                  <Col sm="9" md={9} lg={9}>
                    <Select styles={styleReactSelect} required
                            placeholder="Select Merchant..." options={this.state.merchantTypes}
                            onChange={(value) => this.selectMerchantTypeOnChange(value, 'merchant_type')}
                            name="merchant_type" noOptionsMessage={() => 'Sorry!! No Merchant Type Found'}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formMerchant">
                  <Form.Label column sm="3"> Country </Form.Label>
                  <Col sm="9" md={9} lg={9}>
                    <select id="country" name="country" className='form-control form-control-sm'
                            onChange={this.selectOnChange}>
                      <option value="">--Select Country--</option>
                      {this.renderLocations()}
                    </select>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formMerchantImport">
                  <Form.Label column sm="3"> Profile Status </Form.Label>
                  <Col sm="9" md={9} lg={9}>
                    <select id="profileStatus" name="profile_status" className='form-control form-control-sm'
                            onChange={this.selectOnChange}>
                      <option value="Verified">Verified</option>
                      <option value="Need Verification">Need Verification</option>
                      <option value="In Verification">In Verification</option>
                    </select>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formMerchantImport">
                  <Form.Label column sm="3"> Register As Customer </Form.Label>
                  <Col sm="9" md={9} lg={9}>
                    <select id="isCustomer" name="is_customer" className='form-control form-control-sm'
                            onChange={this.selectOnChange}>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formFile">
                  <Form.Label column sm="3">
                    File <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={9} lg={9}>
                    <Form.Control type={`file`} required onChange={this.onFileUploadOnChange} name={`file`}/>
                  </Col>
                </Form.Group>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.hideBulkImporterModal}> Close </Button>
                <Button variant="primary" disabled={this.state.isUploading} type={`submit`}>
                  {this.state.isUploading ? 'Importing...' : 'Import Now'}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          <div className="main-content">
            <div className="page-header">
              <h2 className="page-title">Entrepreneurs</h2>
              <div className="ml-auto">
                {/*<label htmlFor="productImport" className="btn mb-0 btn-sm btn-default mr-2">
                <input type="file"
                       disabled={this.state.isImporting}
                       onChange={this.handleMerchantBulkImport}
                       id="productImport"
                       className="sr-only"/>
                <FiUpload/> {this.state.isImporting ? 'Importing...' : 'Import'}
              </label>*/}



                <label htmlFor="productImport">
                  <input type="file"
                         disabled={this.state.isExporting}
                         onChange={() => this.handleExportFile('xlsx')}
                         id="productImport"
                         className="sr-only"/>
                </label>

                {/*  <Button
              onClick={()=>{
                console.log(`${API_BASE_URL}/merchant?export=${'xlsx'}&${objToUrlPrams(this.state.queryData)}`)
              }}>
                TEST
              </Button>*/}
                {console.log(this.state.queryData)}
                {getLocalAuthData()?.permission?.includes('entrepreneurs_export') ?
                    <a href={`${API_BASE_URL}/merchant?export=${'xlsx'}&${objToUrlPrams(this.state.queryData)}`}
                       download={'merchants.xlsx'}
                       className="btn mb-0 btn-sm btn-default mr-2">
                      <FiUpload/> {this.state.isImporting ? 'exporting...' : 'Export'}
                    </a>
                    :null}

                {getLocalAuthData()?.permission?.includes('entrepreneurs_import') ?
                    <Button size={`sm`} variant={'default'}
                            onClick={this.showBulkImporterModal}
                            className={`mr-2`}>
                      <FiUpload/> Import
                    </Button> : null}

                <a href={sampleImportFile} className="text-muted mr-3"
                   download={"bulk-merchant-upload-sample.csv"}><small>(Download sample file)</small></a>

                {/*<Button size={"sm"} variant={"defalut"}
                      className="mb-0 ml-2"><FaFileExport/> Export</Button>*/}
                {getLocalAuthData()?.permission?.includes('entrepreneurs_add') ?
                    <Link to={`${process.env.PUBLIC_URL}/entrepreneurs/add`}
                          className="btn btn-sm btn-secondary"><FiPlus/> Add
                      Entrepreneur</Link>
                    :null}
              </div>
            </div>

            <Modal show={this.state.isShowingStatusChangingModal}
                   onHide={this.handleHideStatusChangingModal}>
              <Modal.Header closeButton>
                <Modal.Title>Enter Message</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group controlId={'modalMessage'}>
                  <Form.Label>Message</Form.Label>
                  <Form.Control as={'textarea'}
                                onChange={this.handleInputMessageOnChange}
                                rows={3} placeholder={'Enter message'}/>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="default" onClick={this.handleHideStatusChangingModal}>
                  Close
                </Button>
                <Button variant="primary" onClick={this.handleProfileStatusUpdate}>
                  Confirm Decline
                </Button>
              </Modal.Footer>
            </Modal>
            {getLocalAuthData()?.permission?.includes('entrepreneurs_list') ?
                <div className="card-block">
                  <div className="block-body">
                    <form className="form-inline mb-3">
                      <div className="form-group mr-3">
                        <label htmlFor="status" className="sr-only">Locations</label>
                        <select id="status" name="country" className='form-control form-control-sm'
                                onChange={(e) => this.handleLocationInputOnChange(e, 0, 'businessChildLocations')}>
                          <option value="">--Select Country--</option>
                          {this.renderLocations()}
                        </select>
                      </div>

                      {this.state.businessChildLocations.length > 0 &&
                      this.state.businessChildLocations.map((location, index) => (
                          <div className="form-group mr-3" key={index}>
                            <label htmlFor={`location${location[0].type}`}
                                   className="sr-only">{location[0].type}</label>
                            <select id={`location${location[0].type}`}
                                    name={location[0].type.toLowerCase()}
                                    className='form-control form-control-sm'
                                    onChange={(e) => this.handleLocationInputOnChange(e, index + 1, 'businessChildLocations')}>
                              <option value="">--Select--</option>
                              {location.map((item, index) => (
                                  <option key={index} value={item._id}>{item.name}</option>
                              ))}
                            </select>
                          </div>
                      ))
                      }

                      <div className="form-group mr-3">
                        <label htmlFor="searchInput" className="sr-only">Search Key</label>
                        <input
                            type="search"
                            id="orderNumber"
                            className='form-control form-control-sm'
                            placeholder="Search Key"
                            name="search_string"
                            onChange={this.handleSearchFormDataChange}
                        />
                      </div>

                      <div className="form-group mr-3">
                        <label htmlFor="barndName" className="sr-only">Status</label>
                        { getLocalAuthData()?.permission?.includes('entrepreneurs_active_inactive') ?
                            <select
                                id="status"
                                name="status"
                                className='form-control form-control-sm'
                                onChange={this.handleSearchFormDataChange}>
                              <option value="">--Select Status--</option>
                              <option value="1">Active</option>
                              <option value="2">Inactive</option>
                            </select>:null}


                      </div>

                      <div className="form-group mr-3">
                        <label htmlFor="barndName" className="sr-only">Status</label>
                        <select
                            id="muktopath_user"
                            name="muktopath_user"
                            className='form-control form-control-sm'
                            onChange={this.handleSearchFormDataChange}>
                          <option value="">--Select User Type--</option>
                          <option value="true">MuktoPaath User</option>
                          <option value="false">General User</option>
                        </select>
                      </div>


                      <div className="form-group mr-3">
                        <label htmlFor="barndName" className="sr-only">Status</label>
                        {this.renderType()}
                      </div>

                      <Button className="mr-2" size="sm" variant="secondary"
                              onClick={this.getQueryData}>Search</Button>

                      <Button size="sm" onClick={this.handleShowAdvanceSearchOptions}
                              variant={this.state.showAdvanceSearchOptions ? 'default' : 'link text-muted'}>Advance
                        Search</Button>
                    </form>
                    {this.state.showAdvanceSearchOptions && <div className="advance-search-options">
                      <div className="form-inline">
                        <Form.Group>
                          <Form.Control
                              className={`mr-2`}
                              as="select"
                              size={"sm"}
                              name="profile_status"
                              onChange={this.handleSearchFormDataChange}
                          >
                            <option value="">--Select Profile Status--</option>
                            <option value="Need Verification">Need Verification</option>
                            <option value="In Verification">In Verification</option>
                            <option value="Verified">Verified</option>
                            <option value="Declined">Declined</option>
                          </Form.Control>


                          <Form.Control
                              as="select"
                              className={"mr-2"}
                              name="is_flagship" size={"sm"}
                              onChange={this.handleSearchFormDataChange}
                          >
                            <option value="">--Select Flagship--</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </Form.Control> :null}
                        </Form.Group>

                        <Form.Group>
                          <DateRangePicker
                              style={{display: 'inline-block'}}
                              initialSettings={{}}
                              containerStyles={{textAlign: 'left', width: '100%', display: 'block'}}
                              onApply={this.handleOnApply}>
                            <input type="button"
                                   placeholder={`Select date range`}
                                   value={(this.state.queryData.from_date && this.state.queryData.to_date) ? `${this.state.queryData.from_date} - ${this.state.queryData.to_date}` : 'Select date range'}
                                   style={{textAlign: 'left', minWidth: '150px'}}
                                   className="form-control form-control-sm"/>
                          </DateRangePicker>
                        </Form.Group>


                        <Form.Group as={Row} controlId={'showInPublic'}>
                          <Form.Label column sm="3"></Form.Label>
                          <Col sm="9" md={8} lg={7}>
                            <Form.Check
                                type="checkbox"
                                id="deletedProduct"
                                label="Deleted"
                                name={"deleted"}
                                onChange={this.handleSearchFormDataChange}
                                checked={this.state?.queryData?.deleted}
                                custom
                            />
                          </Col>
                        </Form.Group>


                      </div>
                    </div>}

                    {this.state.isLoading ?
                        <div>Loading...</div>
                        :
                        <>
                          <div className="table-responsive">
                            <table className="order-table table table-striped">
                              <thead>
                              <tr>
                                <th style={{width: '20px'}}>#</th>
                                <th>Business Name</th>
                                <th>Contact Info</th>
                                <th className={`text-center`}>Store</th>
                                <th className={`nowrap text-center`}>Profile Status</th>
                                <th style={{width: '110px'}}>Status</th>
                                <th style={{width: '60px'}}>Flagship Status</th>
                                <th style={{width: '120px'}}/>
                              </tr>
                              </thead>
                              <tbody>
                              {this.renderList()}
                              </tbody>
                            </table>
                          </div>

                          <div className="mt-3">
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={this.state?.pagination?.page}
                                itemsCountPerPage={this.state?.pagination?.limit}
                                totalItemsCount={this.state?.pagination?.totalDocs?this.state?.pagination?.totalDocs:0}
                                pageRangeDisplayed={10}
                                onChange={this.handlePageChange.bind(this)}
                            />
                          </div>
                        </>
                    }
                  </div>
                </div>
                :null}

          </div>
          <ToastsContainer store={ToastsStore}/>
        </App>
    );
  }
}

export default withRouter(EntrepreneurList);
