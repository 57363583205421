import React, {useEffect, useState} from "react";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {FiMove, FiSettings, FiTrash} from "react-icons/all";
import "../assets/scss/announce-card.scss";
import PartnerCard from "./PartnerCard";
import {FiCheck} from "react-icons/fi";
import {getMerchantType} from "../services/merchantTypeServices";
import LoadingSpinner from "./LoadingSpinner";
import {getStoreList} from "../services/storeServices";
import {isEmptyObj} from "../helper/utils";

const StoreBlock = ({handler, settings, actions, index, dragHandleProps}) => {
  const [addStoreCardModalShow, setAddAddStoreCardModalShow] = useState(false)
  const [isSettingModalShowing, setIsSettingModalShowing] = useState(false)
  const [settingData, setSettingData] = useState({params: {}})
  const [isDataFetching, setIsDataFetching] = useState(true)
  const [merchantTypes, setMerchantTypes] = useState([])
  const [storeList, setStoreList] = useState([])
  const [isConfigured, setIsConfigured] = useState(false)

  useEffect(() => {

    settings.params = {...settings.params , ...{status: 1,deleted: false}}
    getMerchantType()
      .then(res => {
        setMerchantTypes(res)
        setIsDataFetching(false)
      })
      .catch(errMsg => {
        console.error(errMsg)
        setIsDataFetching(false)
      })

    if (!isEmptyObj(settings.params)) {
      setSettingData(settings)
      setIsConfigured(true)
      getStoreList(settings.params).then(res => {
        setStoreList(res.items)
        console.log(res.items)
        setIsDataFetching(false)
      })
        .catch(errMsg => {
          console.error('', errMsg)
          setIsDataFetching(false)
        })
    } else {
      setIsConfigured(false)
    }
  }, [])

  const handleAddAnnounceCardModalClose = () => setAddAddStoreCardModalShow(false);
  const handleAddStoreCardModalShow = () => setAddAddStoreCardModalShow(true);

  const handleSettingModalHide = () => setIsSettingModalShowing(false)
  const handleSettingModalShow = () => setIsSettingModalShowing(true)

  const handleSettingInputOnChange = (e) => {
    const newSettingData = {...settingData}
    if (e.target.type !== 'checkbox') {
      if (e.target.name === 'title' || e.target.name === 'title_local') {
        newSettingData[e.target.name] = e.target.value
      } else {
        newSettingData.params[e.target.name] = e.target.value
      }
    } else {
      if (e.target.checked) {
        newSettingData.params[e.target.name] = e.target.value
      } else {
        delete newSettingData.params[e.target.name]
      }
    }
    setSettingData(newSettingData)
  }

  const handleOnSaveSettingForm = () => {
    actions.onChangeSettings(settingData, () => {
      handleSettingModalHide()
      setIsConfigured(true)
      getStoreList(settingData.params).then(res => {
        setStoreList(res.items)
        setIsDataFetching(false)
      })
        .catch(errMsg => {
          console.error('', errMsg)
          setIsDataFetching(false)
        })
    })
  }

  return (
    <div className="card-block position-relative">
      {isDataFetching &&
      <LoadingSpinner/>
      }

      <div className="block-header">
        <h4 className="block-title">

          {handler && dragHandleProps &&
          <span className={'btn-draggable'}  {...handler.dragHandleProps} >
              <FiMove/>
            </span>
          }
          {settings?.title || 'Untitled'}
          {!dragHandleProps && <span className={"text-danger"} style={{"fontSize": "12px"}}>please save to drag</span>}
        </h4>
        <div className={'block-actions'}>
          <Form.Check
            inline
            defaultChecked={!settings?.is_disabled}
            type="switch"
            onChange={actions.toggleBlockSectionDisable}
            id={`isDisabled-${index}`}
            label={settings?.is_disabled ? 'Off' : 'On'}/>
          <span onClick={handleSettingModalShow} className={'btn-block-action'}>
            <FiSettings/>
          </span>

          <span onClick={actions.onDelete} className={'btn-block-action ml-3'}>
            <FiTrash/>
          </span>
        </div>
      </div>

      <Modal show={isSettingModalShowing} onHide={handleSettingModalHide}>
        <Modal.Header closeButton>
          <Modal.Title>Block Settings</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Block Title (ENG)</Form.Label>
              <Form.Control type="text"
                            name={'title'}
                            defaultValue={settings?.title}
                            onChange={handleSettingInputOnChange}
                            placeholder="Enter block title in English"/>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Block Title (বাংলা)</Form.Label>
              <Form.Control type="text"
                            name={'title_local'}
                            defaultValue={settings?.title_local}
                            onChange={handleSettingInputOnChange}
                            placeholder="Enter block title in local language"/>
            </Form.Group>

            <Form.Group controlId="formBasicCheckbox">
              <Form.Check custom
                          checked={settings?.params?.is_flagship}
                          name={'is_flagship'}
                          value={'true'}
                          onChange={handleSettingInputOnChange}
                          type="checkbox" label="Is Flagship"/>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Merchant Type</Form.Label>
              <Form.Control
                name={'merchant_type'}
                defaultValue={settings?.params?.merchant_type}
                onChange={handleSettingInputOnChange} as="select">
                <option label={'--Select--'}/>
                {merchantTypes.map((type, index) => type?.show_in_public && (
                  <option value={type._id}>{type?.type_name}</option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="dataLimit">
              <Form.Label>Limit</Form.Label>
              <Form.Control type="number"
                            defaultValue={settings?.params?.limit}
                            name={'limit'}
                            onChange={handleSettingInputOnChange} placeholder="Enter the store limit"/>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleSettingModalHide}>
              Close
            </Button>
            <Button variant="primary" onClick={handleOnSaveSettingForm}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <div className="block-body">
        <Modal show={addStoreCardModalShow} onHide={handleAddAnnounceCardModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Select Store</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="business-card-list">
              <label htmlFor="business2" className="business-card-item">
                <input type="checkbox" id="business2"/>

                <div className="item-inner">
                  <div className="card-thumb">
                    <div className="ratio ratio-4-3 bg-contain"
                         style={{
                           backgroundImage: `url(https://evaly.com.bd/static/images/logo.svg)`
                         }}/>
                  </div>

                  <div className="business-card-desc">
                    <div className="">
                      <h4 className="business-card-title">Store Name</h4>
                      <p className="business-location">Dec 21, 2021</p>
                    </div>

                    <span className="check-marker">
                      <FiCheck/>
                    </span>
                  </div>
                </div>
              </label>

              <label htmlFor="business3" className="business-card-item">
                <input type="checkbox" id="business3"/>

                <div className="item-inner">
                  <div className="card-thumb">
                    <div className="ratio ratio-4-3 bg-contain"
                         style={{
                           backgroundImage: `url(https://evaly.com.bd/static/images/logo.svg)`
                         }}/>
                  </div>

                  <div className="business-card-desc">
                    <div className="">
                      <h4 className="business-card-title">Store Name</h4>
                      <p className="business-location">Dec 21, 2021</p>
                    </div>

                    <span className="check-marker">
                      <FiCheck/>
                    </span>
                  </div>
                </div>
              </label>

              <label htmlFor="business4" className="business-card-item">
                <input type="checkbox" id="business4"/>

                <div className="item-inner">
                  <div className="card-thumb">
                    <div className="ratio ratio-4-3 bg-contain"
                         style={{
                           backgroundImage: `url(https://evaly.com.bd/static/images/logo.svg)`
                         }}/>
                  </div>

                  <div className="business-card-desc">
                    <div className="">
                      <h4 className="business-card-title">Store Name</h4>
                      <p className="business-location">Dec 21, 2021</p>
                    </div>

                    <span className="check-marker">
                      <FiCheck/>
                    </span>
                  </div>
                </div>
              </label>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="default" onClick={handleAddAnnounceCardModalClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAddAnnounceCardModalClose}>
              Add Slide
            </Button>
          </Modal.Footer>
        </Modal>

        {(!isEmptyObj(settings.params) && isConfigured) ?
          <>
            {storeList.length > 0 ?
              <Row>
                {storeList.map((store, index) => <Col key={index} md={2} sm={3} xs={6}>
                  <PartnerCard data={store}/>
                </Col>)}
              </Row>
              :
              <div className={'alert alert-warning text-bold text-center'}>
                No store found.
              </div>
            }
          </>
          :
          <div className={'text-center'}>
            <div className={'alert alert-warning'}>
              This block has no configuration.
              Please <span onClick={handleSettingModalShow}
                           style={{cursor: "pointer"}}
                           className="text-primary">click here</span> to configure
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default StoreBlock;
