// noinspection DuplicatedCode,JSUnresolvedVariable

import React, {Component} from 'react'
import App from "../../App"
import Pagination from 'react-js-pagination'
import {Link, withRouter} from "react-router-dom"
import "../../assets/scss/order-list.scss"
import axios from "axios"
import {API_BASE_URL} from "../../helper/env"
import {getLocalAuthData} from "../../helper/utils"
import {toast} from "react-toastify"
import moment from "moment"
import {Button} from "react-bootstrap"
import DateRangePicker from "react-bootstrap-daterangepicker"
import {useJsonToCsv} from 'react-json-csv'
import ReactJson from 'react-json-view'
import {updateItemPartial} from "../../services/commonServices";
import {HiOutlineClipboardList} from "react-icons/all";
import {LinearProgress} from "@material-ui/core";
import {RiMovie2Line} from "@react-icons/all-files/ri/RiMovie2Line";
import {FaRegCreditCard} from "@react-icons/all-files/fa/FaRegCreditCard";
const {saveAsCsv} = useJsonToCsv()

class OrderServicesList extends Component {
  state = {
    orders: [],
    merchantType: [],
    storeList: [],
    queryData: {},
    activePage: 1,
    pagination: {},
    activeBlockTab: 'All',
    isLoading: true,
    locations: [],
    businessChildLocations: [],
    status: null,
    changingStoreId: null,
    changeStatusMsg: '',
    isShowingStatusChangeConfirmModal: false,
    showCancelMessageRequired: false,
    isSubmitting: false,
    selected_order_id: "",
    selected_deposit_info: {},
    depositInfoModal: false,
    isSubmittingDepositPaymentStatus: false,
    allServices: [],
    isLoading2: false
  }

  handleBlockTab = (label) => {
    this.setState({
      activeBlockTab: label,
      queryData: {
        ...this.state.queryData,
        status: label !== "All" ? label : "",
        page: 1,
      },
      activePage: 1
    }, () => {
      // this.getQueryData()
    })
  }

  componentDidMount() {
    this.getList()
    this.getServices()
  }

  getServices = () => {
    axios.get(`${API_BASE_URL}/hoichoi`).then(success => {
      if (success?.data?.items?.length > 0) {
        this.setState({allServices: success.data.items})
      }
    })
  }

  getList = () => {

    this.setState({
      isLoading2: true
    })

    const authToken = getLocalAuthData()
    const headers = {
      'Content-Type': 'application/json',
      'x-api-client': 'web',
      'x-auth-token': authToken.token,
    }
    axios.get(`${API_BASE_URL}/order-services`, {headers: headers, params: this.state.queryData})
      .then(res => {
        this.setState({orders: res.data.items, pagination: res.data.pagination, isLoading: false ,isLoading2:false})
      })
      .catch(err => {
        toast.error(err.message)
        this.setState({isLoading: false})
      })
  }

  handlePageChange = async (pageNumber) => {
    await this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber})
    await this.getQueryData()
  }

  getQueryData = () => {
    const headers = {
      'Content-Type': 'application/json',
      'x-api-client': 'web',
      'x-auth-token': getLocalAuthData().token,
    }
    let queryData = ""
    Object.entries(this.state.queryData).map(([key, val]) => {
      return queryData = queryData.concat(key + "=" + val + "&")
    })
    axios.get(`${API_BASE_URL}/order-services`, {headers: headers})
      .then(res => {
        this.setState({orders: res?.data?.items, pagination: res?.data?.pagination, isLoading: false})
      })
      .catch(err => {
        toast.error(err.message)
        this.setState({isLoading: false})
      })
  }

  renderPaymentStatus = (status) => {
    let payment_status = {
      0: "Unpaid",
      1: "Partially Paid",
      2: "Paid",
      3: "Cancelled",
      4: "Failed",
      5: "Refund",
      6: "Bank deposit payment in review",
      7: "Bank deposit declined",
    }
    return payment_status[status]
  }

  reSubscribe = (orderInfo) => {
    const headers = {
      'Content-Type': 'application/json',
      'x-api-client': 'web',
      'x-auth-token': getLocalAuthData().token,
    }
    axios.post(`${API_BASE_URL}/hoichoi/subscribe?order_id=` + orderInfo.order_code, {}, {headers: headers}).then((res) => {
      if (res.data.status === 2) {
        toast.error(res.data.message)
      } else {
        toast.success(res.data.message)
        this.getList()
      }
    }).catch(error => {
      toast.error(error.message)
    })
  }

  setQueryData = (e) => {
    this.setState({queryData: {...this.state.queryData, [e.target.name]: e.target.value}}, () => {
      this.getList()
    })
  }

  handleOnApply = (e, picker) => {
    const queryData = {...this.state.queryData}

    queryData.start_date = moment(picker.startDate).format('DD-MM-YYYY')
    queryData.end_date = moment(picker.endDate).format('DD-MM-YYYY')

    this.setState({queryData}, () => {
      this.getList()
    })
  }

  export = () => {
    const filename = 'EkShop-Service Order'
    const fields = {
      'index': "No.",
      'order_code': "ORDER ID",
      'createdAt': "PURCHASE DATE",
      'package_name': "PACKAGE NAME",
      'started_from': "STARTED FROM",
      'customer_name': "CUSTOMER NAME",
      'customer_phone': "CUSTOMER PHONE",
      'customer_email': "CUSTOMER EMAIL",
      'transaction_id': "TRANSACTION ID",
      'total_amount': "TOTAL AMOUNT",
      'payment_method_name': "PAYMENT METHOD NAME",
      'payment_status': "PAYMENT STATUS",
    }
    const data = []
    this.state.orders.map((item, index) => {
      data.push({
        'index': index + 1,
        'order_code': item?.order_code ?? '',
        'createdAt': `${moment(data?.createdAt).format('hh:mm a dd/mm/yyyy')}`,
        'package_name': item?.is_service?.package_info?.name ?? '',
        'started_from': `${moment(item?.is_service?.start_date).format('hh:mm a dd/mm/yyyy')}`,
        'customer_name': item?.customer_info?.name ?? '',
        'customer_phone': item?.customer_info?.phone ?? '',
        'customer_email': item?.customer_info?.email ?? '',
        'transaction_id': item?.transaction_id ?? '',
        'total_amount': item?.total_amount.toFixed(2) ?? '',
        'payment_method_name': item?.payment_method?.name?.replace("_", " ")?.replace(/(^\w)|(\s+\w)/g, letter => letter.toUpperCase()),
        'payment_status': this.renderPaymentStatus(item?.payment_status),
      });
    })

    saveAsCsv({data, fields, filename})
  }


  /* Update Payment */
  getPaymentStatus = (order_id, order_parcel_id) => {
    updateItemPartial(`/payment/get-ssl-payment-status`, {order_id: order_id, order_parcel_id:order_parcel_id}).then(res => {
      if (res.status === 1) {
        this.getList();
        toast.success(res.message)
      } else {
        toast.warning(res.message)
      }
    }).catch(errMsg => {
      toast.error(errMsg);
    })
  }

  render() {
    return (
      <App>
        <div className="main-content">


          <div className="d-flex justify-content-center align-items-center">
            { getLocalAuthData()?.permission?.includes('menu_orders_list') ?
                <li className="list-unstyled mx-3"><Link to={'/orders/hoichoi-subscription'} class={"d-flex justify-content-center align-items-center flex-column"}><span className="card nav-icon card-body" style={{borderRadius: "10px"}}><HiOutlineClipboardList size={35}/></span> <span className="nav-text">Hoichoi Servie Order list</span></Link>
                </li> :null}
            { getLocalAuthData()?.permission?.includes('menu_orders_list') ?
                <li className="list-unstyled mx-3"><Link to={'/services/hoichoi/list'} class={"d-flex justify-content-center align-items-center flex-column"}><span className="card nav-icon card-body" style={{borderRadius: "10px"}}><RiMovie2Line size={35}/></span> <span className="nav-text">Hoichoi Package</span></Link>
                </li> :null}

            { getLocalAuthData()?.permission?.includes('menu_orders_list') ?
                <li className="list-unstyled mx-3"><Link to={'/services/hoichoi/buy'} class={"d-flex justify-content-center align-items-center flex-column"}><span className="card nav-icon card-body" style={{borderRadius: "10px"}}><FaRegCreditCard size={35}/></span> <span className="nav-text">Hoichoi Bulk Purchase</span></Link>
                </li> :null}
          </div>


          <div className="page-header">
            <h2 className="page-title">Hoichoi subscription request list</h2>
          </div>
          {this.state.isLoading ?
            <div className={`card-block`}>
              <div className="block-body">
                Loading...
              </div>
            </div>
            :
            <>
              {getLocalAuthData()?.permission?.includes('order_parcel_list_view') ?
                <div className="card-block">
                  <ul className="desc-header-nav">
                    {/*<li className={this.state.activeBlockTab === 'All' ? 'active' : ''} value="">
                      <span onClick={() => this.handleBlockTab('All')}>All</span>
                    </li>*/}
                    {/*<li className={this.state.activeBlockTab === 'Hoichoi' ? 'active' : ''}>
                      <span onClick={() => this.handleBlockTab('Hoichoi')}>Hoichoi</span>
                    </li>*/}
                  </ul>
                  <div className="block-body">
                    <form className="form-inline mb-3">


                      <div className="form-group mr-3 mt-2">
                        <label htmlFor="paymentStatus" className="sr-only">Payment Status</label>
                        <select value={this.state.queryData?.payment_status ? this.state.queryData?.payment_status : ""} id="paymentStatus" name="payment_status" onChange={this.setQueryData} className='form-control form-control-sm'>
                          <option value="">--Select Payment Status--</option>
                          <option value="0">Unpaid</option>
                          <option value="2">Paid</option>
                          <option value="3">Payment Canceled</option>
                          <option value="4">Payment Failed</option>
                        </select>
                      </div>
                      <div className="form-group mr-3 mt-2">
                        <label htmlFor="paymentStatus" className="sr-only">Services</label>
                        <select value={this.state.queryData?.package_id ? this.state.queryData?.package_id : ""} id="paymentStatus" name="package_id" onChange={this.setQueryData} className='form-control form-control-sm'>
                          <option value="">--Select Package Name--</option>
                          {this.state.allServices.map(item => <option value={item._id}>{item.name}</option>)}
                        </select>
                      </div>

                      <div className="form-group mr-3 mt-2">
                        <label htmlFor="orderCode" className="sr-only">Order Code</label>
                        <input type="search" id="orderCode" name="order_code"
                               onChange={this.setQueryData}
                               className='form-control form-control-sm'
                               placeholder="Customer"
                               value={this.state.queryData?.order_code ? this.state.queryData?.order_code : ""}
                               onKeyDown={(e) => {
                                 if (e.keyCode === 13) {
                                   this.getQueryData()
                                 }
                               }}/>
                      </div>

                      <div className="form-group mr-3 mt-2">
                        <DateRangePicker
                          containerStyles={{textAlign: 'left', width: '100%', display: 'block'}}
                          onApply={this.handleOnApply}>
                          <input type="button"
                                 placeholder={`Select date range`}
                                 value={(this.state.queryData.start_date && this.state.queryData.end_date) ? `${this.state.queryData.start_date} - ${this.state.queryData.end_date}` : 'Select date range'}
                                 style={{textAlign: 'left', minWidth: '150px'}}
                                 className="form-control form-control-sm"/>
                        </DateRangePicker>
                      </div>

                      <Button className="mt-2 mr-2" size="sm" variant="secondary" onClick={() => {
                        this.setState({
                          queryData: {}
                        }, () => {
                          this.getList()
                        })
                      }
                      }>Clear</Button>

                      <Button className="mt-2" size="sm" variant="secondary" onClick={() => {
                        this.export()
                      }
                      }>Export</Button>
                    </form>

                    {this.state.isLoading2 ? <LinearProgress/> :
                        <>
                      {this.state.orders?.length > 0 ?
                          <>
                            <div className="table-responsive">
                              <table className="order-table table table-striped">
                                <thead>
                                <tr>
                                  <th style={{width: '1%'}}>#</th>
                                  <th>Order Id</th>
                                  <th>Purchase Date</th>
                                  <th>Package Details</th>
                                  <th>Customer Details</th>
                                  <th>Order Item</th>
                                  <th>Amount (৳)</th>
                                  <th>Payment Method</th>
                                  <th style={{width: '1%'}}>Payment Status</th>
                                  <th>Last Status</th>
                                  <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.orders.map((item, index) => {
                                  return (
                                      <tr key={'service-order-' + index}>
                                        <td>{index + 1}</td>
                                        <td><span className="order-id"><Link>{item.order_code}</Link></span></td>
                                        <td>
                                          <span className="order-date">{moment(item.createdAt).format('ll')}</span>
                                          <br/>
                                          <span className="order-date">{moment(item.createdAt).format('LT')}</span>
                                        </td>
                                        <td>
                                          {item?.is_service?.package_info?.name && <p>Package name: {item?.is_service?.package_info?.name}</p>}
                                          {item?.is_service?.start_date && <p className="order-date">Started from: {moment(item?.is_service?.start_date).format('ll') + moment(item?.is_service?.start_date).format('LT')}</p>}
                                        </td>
                                        <td>
                                          {item?.customer_info?.name && <p>Name: {item?.customer_info?.name}</p>}
                                          {item?.customer_info?.phone && <p>Phone: {item?.customer_info?.phone !== "-" ? item?.customer_info?.phone : item?.is_service?.hoichoi_subscription_id}</p>}
                                          {item?.customer_info?.email && <p>Email: {item?.customer_info?.email}</p>}
                                        </td>
                                        <td>
                                          <div className="order-item">
                                            <h4 className="order-id">{item?.is_service?.name}</h4>
                                            <ul className="ordered-items">
                                              <li>Transaction Id: {item?.transaction_id}</li>
                                            </ul>
                                          </div>
                                        </td>
                                        <td>{item?.total_amount.toFixed(2)}</td>
                                        <td>{item?.payment_method?.name?.replace("_", " ")?.replace(/(^\w)|(\s+\w)/g, letter => letter.toUpperCase())}</td>
                                        <td>{this.renderPaymentStatus(item?.payment_status)}</td>
                                        <td>
                                          <ReactJson src={item?.is_service?.success_info || item?.is_service?.error_info} theme="monokai" collapsed={true} name={false}/>
                                          {item?.payment_status_message}
                                        </td>
                                        {/*<td>{item?.is_service && (!item?.is_service?.success_info || Object.keys(item?.is_service?.success_info).length < 1) && item?.payment_status === 2 && (item?.is_service?.error_info && Object.keys(item?.is_service?.error_info).length > 0) ? <>{item?.is_service?.error_info?.Message || item?.is_service?.error_info?.error || ''}</> : <></>}</td>*/}


                                        <td>

                                          {
                                            (item?.payment_method?.name === 'online_payment' && item?.payment_status != 2) &&
                                            <>
                                              <Button type={'button'} className={'btn btn-sm'}
                                                      onClick={() => {
                                                        this.getPaymentStatus(item?._id, '')
                                                      }}>Get Payment Status</Button>
                                            </>
                                          }
                                          {item?.is_service && (!item?.is_service?.success_info || Object.keys(item?.is_service?.success_info).length < 1) && item?.payment_status === 2 &&
                                          <Button type={'button'} className={'btn btn-sm'} onClick={() => this.reSubscribe(item)}>Subscribe Now</Button>
                                          }
                                        </td>

                                      </tr>
                                  )
                                })}
                                </tbody>
                              </table>
                            </div>
                            <div className="mt-3">
                              <Pagination itemClass="page-item" linkClass="page-link" activePage={this.state.pagination?.page} itemsCountPerPage={this.state.pagination?.limit} totalItemsCount={this.state.pagination?.totalDocs} onChange={this.handlePageChange.bind(this)}/>
                            </div>
                          </>
                          :
                          <div className={`alert alert-warning text-center text-bold`}>No services order found</div>
                      }
                    </>}

                  </div>
                </div> : null}
            </>
          }
        </div>
      </App>
    )
  }
}

export default withRouter(OrderServicesList)
