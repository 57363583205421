import React, { useEffect, useState } from "react";
import App from "../../App";
import Moment from "moment";
import { BsArrowLeft, FiClock, RiReplyLine } from "react-icons/all";
import { Button, Form } from "react-bootstrap";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import {
    supportTicketAdd,
    supportTicketDetails,
    supportTicketUpdate,
    supportTicketChangeStatus,
    ticketRepliesList,
} from "../../services/support-ticketService";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import {getDetail} from "../../services/liveServices";

const AgentDetails = () => {
    const [isReplyFormOpen, setIsReplyFormOpen] = useState(false);
    const [ticketDetails, setTicketDetails] = useState({});
    const [address, setAddress] = useState([])
    const [formData, setFormData] = useState({});
    const [status, setStatus] = useState(null);
    const [repliesData, setRepliesData] = useState([]);
    const [isSubmitted, setSubmitted] = useState(false);
    const [isStatusUpdated, setIsStatusUpdated] = useState(false);


    const { id } = useParams();

    useEffect(() => {
       getDetail('/agent/'+id ).then((res)=>{
           console.log(res)
           setTicketDetails(res.items)
           setAddress(res.items.address)
       }).catch((err) =>{
           console.log(err)
       })
    }, []);



    const onChangeListener = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        e.currentTarget.reset();
        console.log(formData);
        const result = await ticketRepliesList(id, formData);
        console.log(result);
        if (result.status === 1) {
            toast.success(result.message);
            setSubmitted(!isSubmitted);
        } else {
            toast.error(result.message);
        }
    };

    const handleStatusChange = (e) =>{
        const status = e.target.value;
        confirmAlert({
            title: 'Change status',
            message: `Are you sure to ${e.target.value} this ticket?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: (e) => {
                        setStatus(status);
                        setIsStatusUpdated(true);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        setStatus("");
                    }
                }
            ]
        });
    }

    const ticketDetailsElement = () => {
        return (
            <>
                <div className="ticket-header mb-3">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h3 className="ticket-title">#{ticketDetails?.code}</h3>
                            <br></br>
                            <div className="d-block">
                                Name: <strong>{ticketDetails?.name || ""}</strong>
                            </div>

                            <div className="d-block">
                                Phone: <strong>+880{ticketDetails?.phone || ""}</strong>
                            </div>

                            <div className="d-block">
                                NID: <strong>{ticketDetails?.nid || ""}</strong>
                            </div>
                            <div className="d-block">
                                Address: {address.map( (data , index) => (<>{data.name} , </>) )

                            }
                            </div>
                        </div>

                        <div>
                            {ticketDetails.status === "Pending" && (
                                <Form.Control
                                    name={"status"}
                                    onChange={(e) => handleStatusChange(e)}
                                    as={"select"}
                                    size={"sm"}
                                    value={status}
                                >
                                    <option label="--Select--" />
                                    <option value="Solved">Solved</option>
                                    <option value="Reject">Reject</option>
                                    <option value="Close">Close</option>
                                </Form.Control>
                            )}
                        </div>
                    </div>

                    <div style={{marginTop:'50px'}} className="item-attr mt-3">
                        <div className="attr">
                            <span className="attr-title">E-Commerce Partner</span>
                            <span className="attr-value">
                {ticketDetails.agent}
              </span>
                        </div>
                        <div className="attr">
                            <span className="attr-title">Apply Date</span>
                            <span className="attr-value">
                {Moment(ticketDetails.createdAt).format("ll")}
              </span>
                        </div>
                        <div className="attr">
                            <span className="attr-title">Current Status</span>
                            <span className="attr-value">
                <span className="status-badge bg-info-light">
                  {ticketDetails.status}
                </span>
              </span>
                        </div>
                    </div>
                </div>

                <p>{ticketDetails.message}</p>
            </>
        );
    };


    return (
        <App layout={"boxed"}>
            <div className="page-header">
                <Link to={"/agent"} className={"btn btn-sm btn-link"}>
                    <BsArrowLeft /> Back to Agent
                </Link>
            </div>

            <div className="card-block">
                <div className="block-body">
                    {ticketDetailsElement()}
                </div>
            </div>
        </App>
    );
};

export default AgentDetails;
