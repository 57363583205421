import React, {Component, useState} from 'react';
import App from "../App";
import FilterBar from "../components/FilterBar";
import "../assets/scss/dashboard.scss";
import Chart from "chart.js";
import {Button, Col, Row} from "react-bootstrap";
import salesPerDay from "../static-data/salesPerDay";
import transactionPerDay from "../static-data/transactionPerDay";
import visitorsData from "../static-data/visitorsData";
import CampaignStats from "../components/dashboard/CampaignStats";
import DashboardOrderStats from "../components/dashboard/DashboardOrderStats";
import SaleOrderChart from "../components/dashboard/SaleOrderChart";
import UsersStats from "../components/dashboard/UsersStats";
import OrderCancelChart from "../components/dashboard/OrderCancelChart";
import LastMonthStats from "../components/dashboard/LastMonthStats";
import moment from "moment"
import Moment from "moment";
import {fetchDashboardOrderV2, fetchDashboardOrder} from "../redux/dashboard/actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Toast from "react-bootstrap/Toast";
import TransactionReport from "../components/dashboard/TransactionReport";
import MerchantReport from "../components/dashboard/MerchantReport";
import {Scrollbars} from "react-custom-scrollbars";


class Dashboard extends Component {
    salesAndOrderChartRef = React.createRef();
    // salesPerDayChartRef = React.createRef();
    // transactionPerDayChartRef = React.createRef();
    orderCanceledStatsChartRef = React.createRef();
    visitorsChartRef = React.createRef();

    // const [show, setShow] = useState(false);

    constructor(props) {
        super(props)
        this.state = {
            start_time: moment().subtract(3, 'days'),
            end_time: moment(),
            show: false,
            transaction: []
        }
    }

    componentDidMount() {


        // const salesPerDayRef = this.salesPerDayChartRef.current.getContext("2d");
        // const transactionPerDayRef = this.transactionPerDayChartRef.current.getContext("2d");
        // const visitorsRef = this.visitorsChartRef.current.getContext("2d");
        // new Chart(salesPerDayRef, salesPerDay);
        // new Chart(transactionPerDayRef, transactionPerDay);
        // new Chart(visitorsRef, visitorsData);
        this.getDashboardDate();
    }

    handleOnApply = (e, picker) => {
        const startDate = Moment(picker.startDate).format('M/D/Y')
        const endDate = Moment(picker.endDate).format('M/D/Y')
        this.setState({
            start_time: startDate,
            end_time: endDate,
        }, () => {
            this.getDashboardDate();
        })
    }

    getDashboardDate = async () => {
        // all
        // await this.props.fetchDashboardOrderV2({
        //     start_time: moment(this.state.start_time).format('DD-MM-YYYY'),
        //     end_time: moment(this.state.end_time).format('DD-MM-YYYY'),
        // });
        await this.props.fetchDashboardOrder({
            report_by: 1,
            start_time: moment(this.state.start_time).format('DD-MM-YYYY'),
            end_time: moment(this.state.end_time).format('DD-MM-YYYY'),
        });


        // Campaign stats
       await this.props.fetchDashboardOrder({
            report_by: 4,
            start_time: moment(this.state.start_time).format('DD-MM-YYYY'),
            end_time: moment(this.state.end_time).format('DD-MM-YYYY'),
        });

        // Last Month Order Statistics
      await  this.props.fetchDashboardOrder({
            report_by: 5,
            start_time: moment(this.state.start_time).format('DD-MM-YYYY'),
            end_time: moment(this.state.end_time).format('DD-MM-YYYY'),
        });

        //user 3
       await this.props.fetchDashboardOrder({
            report_by: 3,
            start_time: moment(this.state.start_time).format('DD-MM-YYYY'),
            end_time: moment(this.state.end_time).format('DD-MM-YYYY'),
        });

        //order vs sales 2
       await this.props.fetchDashboardOrder({
            report_by: 2,
            start_time: moment(this.state.start_time).format('DD-MM-YYYY'),
            end_time: moment(this.state.end_time).format('DD-MM-YYYY'),
        });


        // trans 8
       await this.props.fetchDashboardOrder({
            report_by: 8,
            start_time: moment(this.state.start_time).format('DD-MM-YYYY'),
            end_time: moment(this.state.end_time).format('DD-MM-YYYY'),
        });

        // Merchant 9
        await this.props.fetchDashboardOrder({
            report_by: 9,
            start_time: moment(this.state.start_time).format('DD-MM-YYYY'),
            end_time: moment(this.state.end_time).format('DD-MM-YYYY'),
        });

    }

    onButtonClick = (type) => {
        let startDate, endDate;
        if (type === 1) { // ALL
            startDate = moment('01-01-2018', 'MM-DD-YYYY')
            endDate = moment().clone().endOf('day')
        }
        if (type === 2) { // Today
            startDate = moment().clone().startOf('day')
            endDate = moment().clone().endOf('day')
        }
        if (type === 3) { // This Week
            startDate = moment().clone().startOf('week')
            endDate = moment().clone().endOf('week')
        }
        if (type === 4) { // This Month
            startDate = moment().clone().startOf('month')
            endDate = moment().clone().endOf('month')
        }
        if (type === 5) { // This Year
            startDate = moment().clone().startOf('year')
            endDate = moment().clone().endOf('year')
        }
        startDate = Moment(startDate).format('M/D/Y')
        endDate = Moment(endDate).format('M/D/Y')
        this.setState({
            start_time: startDate,
            end_time: endDate,
        }, () => {
            this.getDashboardDate();
        })
    }

    render() {
        console.log("++++++++++++++++++++++++++++++", this.props.dashboard)
        /*console.log(this.props?.dashboard?.dashboardOrder.transaction_reports[0].unpaid_cod)*/
        const transaction = this.props?.dashboard?.dashboardOrder.transaction_reports
        //console.log(this.props?.dashboard?.dashboardOrder.merchant_types_reports)

        return (
            <App>
                <FilterBar startTime={this.state.start_time} endTime={this.state.end_time}
                           handleOnApply={this.handleOnApply}
                           onButtonClick={this.onButtonClick}/>

                <div className="main-content dashboard-content">

                    <DashboardOrderStats orderReports={this.props.dashboard.dashboardOrder}/>

                    <SaleOrderChart orderVsSalesReports={this.props.dashboard.dashboardOrder.order_vs_sales_reports}/>

                    <UsersStats usersReports={this.props.dashboard.dashboardOrder.users_reports}/>

                    <CampaignStats campaignReports={this.props.dashboard.dashboardOrder}/>

                    <LastMonthStats
                        lastMonthOrderReports={this.props.dashboard.dashboardOrder.last_month_order_reports}/>

                    <TransactionReport test={this.props?.dashboard?.dashboardOrder} />

                    {/*<MerchantReport test2={this.props?.dashboard?.dashboardOrder} />*/}

                    {this.props.dashboard.dashboardOrder.merchant_types_reports && <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">Merchant Report</h4>
                        </div>
                        <div className="block-body">
                            <Scrollbars style={{height: '400px'}}>
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Total</th>
                                    <th>Unpaid COD</th>
                                    <th>Unpaid Bank Payment</th>
                                    <th>Unpaid Online Payment</th>

                                    <th>Partial COD</th>
                                    <th>Partial Bank Payment</th>
                                    <th>Partial Online Payment</th>

                                    <th>Paid COD</th>
                                    <th>Paid Bank Paymnet</th>
                                    <th>Paid Online Payment</th>

                                    <th>Cancelled COD</th>
                                    <th>Cancelled Bank Paymnet</th>
                                    <th>Cancelled Online Payment</th>

                                    <th>Failed COD</th>
                                    <th>Failed Bank Payment</th>
                                    <th>Failed Online Payment</th>

                                    <th>Refund COD</th>
                                    <th>Refund Bank Payment</th>
                                    <th>Refund Online Payment</th>

                                    <th>Deposit Review COD</th>
                                    <th>Deposit Review Bank Payment</th>
                                    <th>Deposit Review Online Payment</th>

                                    <th>Deposit Declined COD</th>
                                    <th>Deposit Declined Bank Payment</th>
                                    <th>Deposit Declined Online Payment</th>
                                </tr>
                                </thead>

                                <tbody>
                                 {this.props.dashboard.dashboardOrder.merchant_types_reports && this.props.dashboard.dashboardOrder.merchant_types_reports.map((data, i) => {
                                return (
                                    <tr>
                                        <td>{data?.merchant_type_info?.type_name}</td>
                                        <td>{data?.total

                                        }</td>
                                        <td>{data?.unpaid_cod}</td>
                                        <td>{data?.unpaid_bank_payment}</td>

                                        <td>{data?.unpaid_online_payment}</td>

                                        <td>{data?.partially_cod}</td>
                                        <td>{data?.partially_bank_payment}</td>
                                        <td>{data?.partially_online_payment}</td>

                                        <td>{data?.paid_cod}</td>
                                        <td>{data?.paid_bank_payment}</td>
                                        <td>{data?.paid_online_payment}</td>

                                        <td>{data?.cancelled_cod}</td>
                                        <td>{data?.cancelled_cod}</td>
                                        <td>{data?.cancelled_cod}</td>

                                        <td>{data?.failed_cod}</td>
                                        <td>{data?.failed_bank_payment}</td>
                                        <td>{data?.failed_online_payment}</td>

                                        <td>{data?.refund_cod}</td>
                                        <td>{data?.refund_bank_payment}</td>
                                        <td>{data?.refund_online_payment}</td>

                                        <td>{data?.bank_deposit_review_cod}</td>
                                        <td>{data?.bank_deposit_review_bank_payment}</td>
                                        <td>{data?.bank_deposit_review_online_payment}</td>

                                        <td>{data?.bank_deposit_declined_cod}</td>
                                        <td>{data?.bank_deposit_declined_bank_payment}</td>
                                        <td>{data?.bank_deposit_declined_online_payment}</td>

                                    </tr>
                                )
                            })}
                                </tbody>
                            </table>
                            </Scrollbars>
                        </div>
                    </div>}

                  {/*  <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">Transaction Report</h4>
                        </div>

                        {this.props.transaction_reports.length > 0 && this.props.transaction_reports.map((data, i)=>{

                            return(
                                <div className="block-body">
                                    <table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Unpaid</th>
                                            <th>Paid Payment</th>
                                            <th>Cancel Payment</th>
                                            <th>Failed Payment</th>
                                            <th>Refund Payment</th>
                                            <th>Bank Deposit Review</th>
                                            <th>Bank Deposit Declined</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        <tr>
                                            <td>COD</td>
                                            <td>{data?.total}</td>
                                            <td>5000</td>
                                            <td>3000</td>
                                            <td>10000</td>
                                            <td>5000</td>
                                            <td>3000</td>
                                            <td>3000</td>

                                        </tr>
                                        <tr>
                                            <td>Online Payment</td>
                                            <td>110000000</td>
                                            <td>11323</td>
                                            <td>12121</td>

                                            <td>10000</td>
                                            <td>5000</td>
                                            <td>3000</td>
                                            <td>3000</td>
                                        </tr>
                                        <tr>
                                            <td>Bank Payment</td>
                                            <td>12121212</td>
                                            <td>232323</td>
                                            <td>211212</td>

                                            <td>10000</td>
                                            <td>5000</td>
                                            <td>3000</td>
                                            <td>3000</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )

                        } )}
                    </div>*/}

                    {/*<OrderCancelChart cancelOrderReports={this.props.dashboard.dashboardOrder.cancel_order_reports}/>*/}

                    {/*<div className="card-block">*/}
                    {/*  <div className="block-header">*/}
                    {/*    <h4 className="block-title">Key Performance Indicator</h4>*/}
                    {/*  </div>*/}

                    {/*  <div className="block-body">*/}
                    {/*    <Row>*/}
                    {/*      <Col md={6}>*/}
                    {/*        <div className="body-header">*/}
                    {/*          <h4 className="body-title">৳ 27,887</h4>*/}
                    {/*          <p className="body-sub-title">Sales per Day</p>*/}
                    {/*        </div>*/}


                    {/*        <canvas*/}
                    {/*          style={{height: '320px', width: '100%'}}*/}
                    {/*          ref={this.salesPerDayChartRef}*/}
                    {/*        />*/}

                    {/*      </Col>*/}
                    {/*      <Col md={6}>*/}
                    {/*        <div className="body-header">*/}
                    {/*          <h4 className="body-title">৳ 14,856</h4>*/}
                    {/*          <p className="body-sub-title">Transaction Per Day</p>*/}
                    {/*        </div>*/}


                    {/*        <canvas*/}
                    {/*          style={{height: '320px', width: '100%'}}*/}
                    {/*          ref={this.transactionPerDayChartRef}*/}
                    {/*        />*/}
                    {/*      </Col>*/}
                    {/*    </Row>*/}
                    {/*  </div>*/}
                    {/*</div>*/}


                    {/*    <MerchantStats labels={labels}
                         title={'Top Merchants'}
                         datasets={topMerchantsDatasets}/>

          <MerchantStats labels={labels}
                         title={'Low Performing Merchants'}
                         datasets={lowPerformingMerchantDatasets}/>

          <Row>
            <Col md={6}>
              <DashboardProductList title={'Top Products'}/>
            </Col>
            <Col md={6}>
              <DashboardProductList title={'Lowest Selling Products'}/>
            </Col>
          </Row>*/}


                    {/*<div className="card-block d-none">*/}
                    {/*  <div className="block-body">*/}
                    {/*    <div className="stats-cards">*/}
                    {/*      <div className="stats-card">*/}
                    {/*        <span className="title">Last Month Visitors</span>*/}
                    {/*        <span className="value text-info">5220</span>*/}
                    {/*      </div>*/}
                    {/*    </div>*/}


                    {/*    <canvas*/}
                    {/*      style={{height: '320px', width: '100%'}}*/}
                    {/*      ref={this.visitorsChartRef}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</div>*/}


                   {/* <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">Merchant Type Report</h4>
                        </div>

                        <div className="block-body">
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Unpaid</th>
                                    <th>Partial</th>
                                    <th>Paid Payment</th>
                                    <th>Cancelled Payment</th>
                                    <th>Failed Payment</th>
                                    <th>Refund Payment</th>
                                    <th>Deposit Review</th>
                                    <th>Deposit Declined</th>
                                </tr>
                                </thead>

                                <tbody>

                                {this.props?.dashboard?.dashboardOrder.merchant_types_reports &&
                                this.props?.dashboard?.dashboardOrder.merchant_types_reports.map((data , i)=>{
                                    return( <tr>
                                        <td>
                                            {data?.type.merchant_type_info.type_name}
                                        </td>
                                        <td>{data?.unpaid_cod}</td>
                                        <td>{data?.partially_cod}</td>
                                        <td>{data?.paid_cod}</td>
                                        <td>{data?.cancelled_cod}</td>
                                        <td>{data?.failed_cod}</td>
                                        <td>{data?.refund_cod}</td>
                                        <td>{data?.bank_deposit_review_cod}</td>
                                        <td>{data?.bank_deposit_declined_cod}</td>
                                    </tr>)
                                })
                                }
                                 <tr>
                    <td>
                    Post Office
                      <img src="https://static-01.daraz.com.bd/other/shop/745f0ca2a0eb5672da1b870ea3d09af7.jpeg" alt=""
                          style={{height: '40px'}}/>
                    </td>
                    <td>
                    80000
                    </td>
                    <td>30</td>
                    <td>30</td>
                    <td>50</td>
                    <td>30</td>
                    <td>50</td>
                    <td>5</td>
                  </tr>
                  <tr>
                    <td>
                      <img src="https://static-01.daraz.com.bd/other/shop/189dae83710668379f671e9a77153397.jpeg" alt=""
                          style={{height: '40px'}}/>
                    </td>
                    <td>
                      123,5
                    </td>
                    <td>120</td>
                    <td>30</td>
                    <td>50</td>
                    <td>30</td>
                    <td>50</td>
                    <td>5</td>
                  </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>}*/}


                    {/* <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Latest Orders</h4>
              <Link className="btn-view-more" to={`${process.env.PUBLIC_URL}/orders/list`}>View More</Link>
            </div>

            <div className="block-body">
              <table className="order-table table table-striped">
                <thead>
                <tr>
                  <th style={{width: '1%'}}>#</th>
                  <th>Order Info</th>
                  <th>Customer Info</th>
                  <th>Seller Info</th>
                  <th>Payment (৳)</th>
                  <th style={{width: '150px'}}>Status</th>
                  <th style={{width: '120px'}}/>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    <div className="order-item">
                      <h4 className="order-id"><Link to={`${process.env.PUBLIC_URL}/orders/details/123`}>#EK212</Link>
                      </h4>
                      <span className="order-date">24/02/2020</span>
                      <span className="order-time">4:30pm</span>
                    </div>
                  </td>
                  <td>
                    <div className="order-item">
                      <h4 className="order-id"><Link to={`${process.env.PUBLIC_URL}/customres/details/123`}>Md. Abdul
                        Alim</Link></h4>
                      <ul className="ordered-items">
                        <li>+8801925821707</li>
                        <li>abdul.alim@gmail.com</li>
                      </ul>
                    </div>
                  </td>
                  <td>
                    <div className="order-item">
                      <h4 className="order-id"><Link to={`${process.env.PUBLIC_URL}/stores/details/123`}>ABC
                        Store</Link></h4>
                      <ul className="ordered-items">
                        <li>+8801xxxxxxxx</li>
                        <li>abc@xyz.com</li>
                      </ul>
                    </div>
                  </td>
                  <td>
                    <div className="order-item">
                      <h4 className="order-id"><Link
                        to={`${process.env.PUBLIC_URL}/transactions/details/123`}>#TREK83178641324</Link></h4>
                      <ul className="ordered-items">
                        <li>(৳)1250.00</li>
                        <li>Paid</li>
                        <li>COD</li>
                      </ul>
                    </div>
                  </td>
                  <td>
                    <select style={{width: '150px'}} className="form-control form-control-sm">
                      <option value="">New Order</option>
                      <option value="1">Warehouse Left</option>
                      <option value="2">In Transit</option>
                      <option value="3">Delivered</option>
                      <option value="4">Cancelled</option>
                      <option value="5">Failed to Delivered</option>
                    </select>
                  </td>
                  <td>
                    <Link to={`${process.env.PUBLIC_URL}/orders/details/123`}
                          className="btn btn-block btn-sm btn-outline-secondary">View</Link>
                    <Link to={`${process.env.PUBLIC_URL}/orders/details/123`}
                          className="btn btn-block btn-sm btn-outline-info">Invoice</Link>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    <div className="order-item">
                      <h4 className="order-id"><Link to={`${process.env.PUBLIC_URL}/orders/details/123`}>#EK212</Link>
                      </h4>
                      <span className="order-date">24/02/2020</span>
                      <span className="order-time">4:30pm</span>
                    </div>
                  </td>
                  <td>
                    <div className="order-item">
                      <h4 className="order-id"><Link to={`${process.env.PUBLIC_URL}/customers/details/123`}>Md. Abdul
                        Alim</Link></h4>
                      <ul className="ordered-items">
                        <li>+8801925821707</li>
                        <li>abdul.alim@gmail.com</li>
                      </ul>
                    </div>
                  </td>
                  <td>
                    <div className="order-item">
                      <h4 className="order-id"><Link to={`${process.env.PUBLIC_URL}/stores/details/12`}>XYZ Store</Link>
                      </h4>
                      <ul className="ordered-items">
                        <li>+8801xxxxxxxx</li>
                        <li>abc@xyz.com</li>
                      </ul>
                    </div>
                  </td>
                  <td>
                    <div className="order-item">
                      <h4 className="order-id"><Link
                        to={`${process.env.PUBLIC_URL}/transactions/details/123`}>#TREK83178641324</Link></h4>
                      <ul className="ordered-items">
                        <li>(৳)10550.00</li>
                        <li>Paid</li>
                        <li>COD</li>
                      </ul>
                    </div>
                  </td>
                  <td>
                    <select style={{width: '150px'}} className="form-control form-control-sm">
                      <option value="">New Order</option>
                      <option value="1">Warehouse Left</option>
                      <option value="2">In Transit</option>
                      <option value="3">Delivered</option>
                      <option value="4">Cancelled</option>
                      <option value="5">Faild to Delivered</option>
                    </select>
                  </td>
                  <td>
                    <Link to={`${process.env.PUBLIC_URL}/orders/details/123`}
                          className="btn btn-block btn-sm btn-outline-secondary">View</Link>
                    <Link to={`${process.env.PUBLIC_URL}/orders/details/123`}
                          className="btn btn-block btn-sm btn-outline-info">Invoice</Link>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    <div className="order-item">
                      <h4 className="order-id"><Link to={`${process.env.PUBLIC_URL}/orders/details/123`}>#EK212</Link>
                      </h4>
                      <span className="order-date">24/02/2020</span>
                      <span className="order-time">4:30pm</span>
                    </div>
                  </td>
                  <td>
                    <div className="order-item">
                      <h4 className="order-id"><Link to={`${process.env.PUBLIC_URL}/customers/details/123`}>Md. Abdul
                        Alim</Link></h4>
                      <ul className="ordered-items">
                        <li>+8801925821707</li>
                        <li>abdul.alim@gmail.com</li>
                      </ul>
                    </div>
                  </td>
                  <td>
                    <div className="order-item">
                      <h4 className="order-id"><Link to={`${process.env.PUBLIC_URL}/stores/details/123`}>Store
                        Name</Link></h4>
                      <ul className="ordered-items">
                        <li>+8801xxxxxxxx</li>
                        <li>abc@xyz.com</li>
                      </ul>
                    </div>
                  </td>
                  <td>
                    <div className="order-item">
                      <h4 className="order-id"><Link
                        to={`${process.env.PUBLIC_URL}/transactions/details/123`}>#TREK83178641324</Link></h4>
                      <ul className="ordered-items">
                        <li>(৳)7550.00</li>
                        <li>Paid</li>
                        <li>COD</li>
                      </ul>
                    </div>
                  </td>
                  <td>
                    <select style={{width: '150px'}} className="form-control form-control-sm">
                      <option value="">New Order</option>
                      <option value="1">Warehouse Left</option>
                      <option value="2">In Transit</option>
                      <option value="3">Delivered</option>
                      <option value="4">Cancelled</option>
                      <option value="5">Faild to Delivered</option>
                    </select>
                  </td>
                  <td>
                    <Link to={`${process.env.PUBLIC_URL}/orders/details/123`}
                          className="btn btn-block btn-sm btn-outline-secondary">View</Link>
                    <Link to={`${process.env.PUBLIC_URL}/orders/details/123`}
                          className="btn btn-block btn-sm btn-outline-info">Invoice</Link>
                  </td>
                </tr>

                </tbody>
              </table>
            </div>
          </div>

          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Top Customers</h4>
            </div>

            <div className="block-body">
              <Row>
                <Col md={4}><CustomerCard/></Col>
                <Col md={4}><CustomerCard/></Col>
                <Col md={4}><CustomerCard/></Col>
                <Col md={4}><CustomerCard/></Col>
                <Col md={4}><CustomerCard/></Col>
                <Col md={4}><CustomerCard/></Col>
              </Row>
            </div>
          </div>*/}
                </div>
            </App>
        );
    }
}

const mapStateToProps = state => {
    return {
        dashboard: state.dashboard
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchDashboardOrderV2: (data) => dispatch(fetchDashboardOrderV2(data)),
        fetchDashboardOrder: (data) => dispatch(fetchDashboardOrder(data)),

    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
