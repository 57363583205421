import React, {Component} from 'react';
import App from "../../App";
import "../../assets/scss/category-list.scss";
import {FiPlus} from "react-icons/all";
import {Link} from "react-router-dom";
import CategoryTree from "../../components/CategoryTree";
import Select from "react-select";
import { getLocalAuthData } from "../../helper/utils"

class CategoryList extends Component {

  categoryType = "";

  constructor(props) {
    super(props);
    this.state = {
      categories: []
    }
  }


  render() {
    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Categories</h2>
          { getLocalAuthData()?.permission?.includes('categories_add') ? <div className="ml-auto">
            <Link to={`${process.env.PUBLIC_URL}/categories/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add
              Category</Link>
          </div>: null }
        </div>

        <div className="main-content">
          {getLocalAuthData()?.permission?.includes('categories_list') ?
              <div className="card-block">
                <div className="block-header">
                  <h4 className="block-title">Category List</h4>
                  <div style={{minWidth: "150px"}}>
                    <Select options={[
                      {value: 'product', label: 'Product'},
                      {value: 'store', label: 'Store'},
                      {value: 'service', label: 'Service'},
                      {value: '', label: 'Clear'},
                    ]} onChange={(e) => {
                      this.categoryType = e.value;
                      this.setState({
                        categoryType: this.categoryType,
                      })
                    }}/>
                  </div>

                </div>

                <div className="block-body">
                  <CategoryTree categoryType={this.categoryType}/>
                </div>

              </div>
              : null}

        </div>
      </App>
    );
  }
}

export default CategoryList;
