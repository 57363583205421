import React, {Component} from 'react';
import App from "../../App";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {Button, Form, Modal, Row, Col} from "react-bootstrap";
import moment from "moment";
import {Link, withRouter} from "react-router-dom";
import {getDisputes} from "../../services/disputeServices";
import Pagination from "react-js-pagination";
import LoadingSpinner from "../../components/LoadingSpinner";
import {getLocalAuthData} from "../../helper/utils";
import {getMerchantPaymentMethods} from "../../services/merchantServices";
import {toast} from "react-toastify";
import {disputeResolve, getDisputeDetail} from "../../services/disputeServices";
import {getCommissionSummaryList} from "../../services/commissionServices";
import {activityLogPostData} from "../../services/commonServices";

class Disputes extends Component {
  state = {
    pagination: {},
    isLoading: true,
    settings: {
      user_type: 'merchant',
      // limit: 20
    },
    isDataPartLoading: false,
    dispute_list: [],
    dispute_id: '',
    dispute_resolve_methods: [
        {
            name: "Online Payment Refund",
            is_enable: true,
            image: ""
        }, {
            name: "Wallet",
            is_enable: true,
            image: ""
        }, {
          name: "MFS",
          is_enable: true,
          image: ""
        }, {
          name: "No Refund",
          is_enable: true,
          image: ""
        }
    ],
    disputeResolveFormData: {
        dispute_id: '',
        note: '',
    },
    isDisputeResolvedModalShow: false,
  }

  componentDidMount() {
    getDisputes().then(res => {
        this.setState({dispute_list: res.item, isLoading: false}, () => {})
      }).catch(errMsg => {
        console.error(errMsg)
        this.setState({isLoading: false})
      });
  }

  renderBadge = (status) => {
    let badge_status = {
      Pending:"warning",
      Cancelled:"secondary",
      Closed:"success",
      Resolved:"success",
    }
    return badge_status[status];
  }

    getOrderStatus = (status) => {
        const payment_status = {
            0: "Unpaid",
            1: "Partially Paid",
            2: "Paid",
            3: "Cancelled",
            4: "Failed"
        }
        return payment_status[status];
    }

  handleCloseDisputeResolve = () => {
    this.setState({
      disputeResolveModal: false
    })
  }

  handleShowDisputeResolveModal = (dispute_id) => {
    console.log("Outside",dispute_id);
    if ((this.state.dispute_id != undefined && dispute_id != this.state.dispute_id)) {
      this.setState({dispute_id: dispute_id, disputeResolveModal: true});
        console.log("Inside",dispute_id);
        getDisputeDetail(dispute_id).then(res => {

            if(res.status === 1) {
                this.setSate({dispute_info: res.dispute_info})
            }else{
                this.setSate({dispute_info: res.dispute_info})
            }

        }).catch(errMsg => {
            toast.error(errMsg);
            this.setState({isSubmitting: false})
        });
    }
    this.setState({disputeResolveModal: true});

  }


    handleInputOnChange = (e) => {
        const disputeResolveFormData = {...this.state.disputeResolveFormData};
        disputeResolveFormData[e.target.name] = e.target.value
        this.setState({disputeResolveFormData});
        console.log(this.state.disputeResolveFormData);
    }


    submitDisputeResolveForm = (e) => {
    e.preventDefault()

    this.setState({isSubmitting: true}, () => {
      const disputeResolveData = {...this.state.disputeResolveFormData}
      disputeResolveData['dispute_id'] = this.state.dispute_id;
      disputeResolve(disputeResolveData).then(res => {
        if (res.status === 1) {
          toast.success(res.message);
            activityLogPostData('was resolved dispute , status : '+disputeResolveData?.payment_option,'/disputes',this.state.dispute_id)
            this.setState(...this.state, {isSubmitting: false, isDisputeResolvedModalShow: false});

            getDisputes().then(res => {
                this.setState({dispute_list: res.item, isLoading: false}, () => {})
            }).catch(errMsg => {
                console.error(errMsg)
                this.setState({isLoading: false})
            });

        } else {
          toast.warning(res.message);
          this.setState(...this.state, {isSubmitting: false})
        }
      }).catch(errMsg => {
        toast.error(errMsg);
        this.setState({isSubmitting: false})
      });
    });
  }

    handleDisputeResolveInfoModalOpen = (id, item_info) => {
        console.log(item_info);
        this.setState({
            isDisputeResolvedModalShow: true,
            item_info: item_info,
            item_id: id
        }, () => {
            console.log(this.state);
        });
    }
    handleDisputeResolveInfoModalClose = () => {
        this.setState({isDisputeResolvedModalShow: false})
    }

  render() {
    const {dispute_list} = this.state;
    return (
      <App layout="boxed">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Dispute List</h2>
          </div>

            {getLocalAuthData()?.permission?.includes('disputes_list') ?
                <div className="card-block position-relative">
                    <div className="block-body">
                        {!this.state.isLoading ?
                            <React.Fragment>
                                {this.state.isDataPartLoading &&
                                <LoadingSpinner/>
                                }


                                <Modal size={"lg"} show={this.state.disputeResolveModal} onHide={this.handleCloseDisputeResolve}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Dispute Resolve</Modal.Title>
                                    </Modal.Header>

                                    <Form onSubmit={this.submitDisputeResolveForm}  >
                                        <Modal.Body>

                                            <div className="card-block">
                                                <div className="block-header">
                                                    <h4 className="block-title">Refund Option</h4>
                                                </div>

                                                <div className="block-body"  key={'modal-body'}>
                                                    {
                                                        this.state?.dispute_resolve_methods.length > 0 ?
                                                            this.state.dispute_resolve_methods.map((item, index) => (

                                                                <div className="brand-col" key={'payment-options'+index}>
                                                                    <div className="brand">

                                                                        <div className="brand-info">

                                                                            <Form.Check
                                                                                custom inline required
                                                                                type="radio" name="payment_option"
                                                                                id={`payment-options-${index}`}
                                                                                onChange={this.handleInputOnChange}
                                                                                value={item?.name}
                                                                                label={item?.name}/>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )) :
                                                            <div className={`alert alert-warning text-center text-bold`}>
                                                                Transaction Method not found, please setup method for refund
                                                            </div>
                                                    }
                                                </div>
                                            </div>


                                            <table className="info-table table-lg">
                                                <tbody>

                                                {this.state.disputeResolveFormData.payment_option !== 'No Refund' &&
                                                <><tr>
                                                    <td className="text-bold">Amount to be refund: <span className="text-danger">*</span></td>
                                                    <td className="text-bold">:</td>
                                                    <td className="text-bold">
                                                        <Form.Control size={"sm"} name='refund_amount' required onChange={this.handleInputOnChange} min={0} type={"number"} max={this.state?.dispute_info?.refund_amount}/>
                                                    </td>
                                                </tr><br/></>
                                                }

                                                {this.state.disputeResolveFormData.payment_option === 'MFS' &&
                                                <tr>
                                                    <td className="text-bold">Mobile Number: <span className="text-danger">*</span></td>
                                                    <td className="text-bold">:</td>
                                                    <td className="text-bold"><Form.Control size={"sm"} name='mobile_number' required onChange={this.handleInputOnChange}/></td>
                                                </tr>
                                                }


                                                {this.state.disputeResolveFormData.payment_option === 'MFS' &&
                                                <tr>
                                                    <td className="text-bold">Operator Name: <span className="text-danger">*</span></td>
                                                    <td className="text-bold">:</td>
                                                    <td className="text-bold">
                                                        <Form.Control name={'operator_name'} required
                                                                      onChange={this.handleInputOnChange}
                                                                      as={'select'}>
                                                            <option label={'--Select an operator--'}/>
                                                            <option value={'Nagad'}>Nagad</option>
                                                            <option value={'bKash'}>bKash</option>
                                                            <option value={'Rocket'}>Rocket</option>
                                                        </Form.Control>
                                                    </td>
                                                </tr>
                                                }

                                                {this.state.disputeResolveFormData.payment_option === 'MFS' &&
                                                <tr>
                                                    <td className="text-bold">Account Type: <span className="text-danger">*</span></td>
                                                    <td className="text-bold">:</td>
                                                    <td className="text-bold">
                                                        <Form.Check
                                                            custom
                                                            inline
                                                            onChange={this.handleInputOnChange} required
                                                            checked={this.state.disputeResolveFormData.account_type === 'Personal'}
                                                            value={'Personal'}
                                                            label="Personal"
                                                            name={'account_type'}
                                                            type={'radio'}
                                                            id={`account-type-1`}/>

                                                        <Form.Check
                                                            custom
                                                            inline
                                                            onChange={this.handleInputOnChange}
                                                            checked={this.state.disputeResolveFormData.account_type === 'Merchant'}
                                                            value={'Merchant'}
                                                            label="Merchant"
                                                            name={'account_type'}
                                                            type={'radio'}
                                                            id={`account-type-2`}/>
                                                    </td>
                                                </tr>
                                                }

                                                {this.state.disputeResolveFormData.payment_option === 'MFS' &&
                                                <tr>
                                                    <td className="text-bold">Transaction Number: <span className="text-danger">*</span></td>
                                                    <td className="text-bold">:</td>
                                                    <td className="text-bold"><Form.Control size={"sm"} name='transaction_number' required onChange={this.handleInputOnChange}/></td>
                                                </tr>
                                                }

                                                {
                                                    <tr>
                                                        <td className="text-bold">Note <span className="text-danger">*</span></td>
                                                        <td className="text-bold">:</td>
                                                        <td className="text-bold"><Form.Control as={"textarea"}  required rows={3} name='note' onChange={this.handleInputOnChange}/></td>
                                                    </tr>
                                                }

                                                </tbody>
                                            </table>


                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="default" onClick={this.handleCloseDisputeResolve}>
                                                Close
                                            </Button>
                                            <Button variant="primary" type={`submit`} disabled={this.state.isSubmitting}>
                                                {this.state.isSubmitting ? 'Please Wait...' : 'Submit'}
                                            </Button>
                                        </Modal.Footer>
                                    </Form>
                                </Modal>

                                <Modal show={this.state.isDisputeResolvedModalShow} onHide={this.handleDisputeResolveInfoModalClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Product Dispute Status</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <span>Message: Dispute {this.state?.item_info?.status}</span>

                                        {this.state?.item_info?.status === 'Resolved' &&
                                        <>
                                            <br/><span>Method: {this.state?.item_info?.resolve_info?.method}</span>
                                            {this.state?.item_info?.resolve_info?.method === 'MFS' &&
                                            <>
                                                <br/><span><strong>Mobile Number:</strong> {this.state?.item_info?.resolve_info?.mobile_number}</span>
                                                <br/><span><strong>Operator Info:</strong> {this.state?.item_info?.resolve_info?.operator_name}</span>
                                                <br/><span><strong>Account Type:</strong> {this.state?.item_info?.resolve_info?.account_type}</span>
                                            </>
                                            }
                                            <br/><span>Note: {this.state?.item_info?.resolve_info?.note}</span>
                                            <br/><span>Refund Amount: {this.state?.item_info?.refund_amount}</span>
                                        </>
                                        }
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="default" onClick={this.handleDisputeResolveInfoModalClose}>
                                            Ok
                                        </Button>
                                    </Modal.Footer>
                                </Modal>


                                <table className="table table-striped">

                                    <thead>
                                    <tr>
                                        <th style={{width: '1%'}}>#</th>
                                        <th>Dispute Info</th>
                                        <th>Customer Info</th>
                                        <th>Store Info</th>
                                        <th>Order Info</th>
                                        <th>Reason</th>
                                        <th>Product Info</th>
                                        <th style={{width: '1%'}}/>
                                    </tr>
                                    </thead>

                                    <tbody>

                                    { dispute_list.length > 0 ?
                                        dispute_list.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <strong>{item.dispute_id}</strong>
                                                    <br/><span>{moment(item.createdAt).format('ll')}</span>
                                                    <br/>Status: <span className={`badge badge-${this.renderBadge(item.status)}`}>{item.status}</span>
                                                </td>
                                                <td>
                                                    {item?.user_info?.name}
                                                    {item?.user_info?.phone && <><br/><span>{item?.user_info?.phone}</span></>}
                                                    {item?.user_info?.email && <><br/><span>{item?.user_info?.email}</span></>}
                                                </td>
                                                <td>
                                                    <Link to={`/stores/details/${item?.store_info?._id}`} target={"_blank"}>{item?.store_info?.store_name}</Link>
                                                    {item?.store_info?.phone && <><br/><span>{item?.store_info?.phone}</span></>}
                                                    {item?.store_info?.email && <><br/><span>{item?.store_info?.email}</span></>}
                                                </td>

                                                <td>
                                                    <Link to={`/orders/parcel/${item?.order_parcel_info?._id}`} target={"_blank"}>{item?.order_parcel_info?.order_parcel_code}</Link>
                                                    {item?.order_parcel_info && <><br/><span>Status: {item?.order_parcel_info?.status}</span></>}
                                                    {item?.order_parcel_info && <><br/><span>Payment Status: {this.getOrderStatus(item?.order_parcel_info?.payment_status)}</span></>}
                                                </td>



                                                <td>{item.message}</td>


                                                <td><Link to={`/products/details/${item?.product_info?._id}`} target={"_blank"}>{item.product_info.name}</Link></td>
                                                <td>
                                                    {getLocalAuthData()?.permission?.includes('disputes_resolve') ?
                                                    <>{item?.status === 'Pending' &&
                                                      <><Button onClick={() => this.handleShowDisputeResolveModal(item?._id)} size={"sm"} variant="outline-secondary">Resolve</Button></>
                                                      }</>
                                                    :null}
                                                    {getLocalAuthData()?.permission?.includes('disputes_dispute_info') ?
                                                     <>
                                                         {item?.status === 'Resolved' &&
                                                         <><Button onClick={() => this.handleDisputeResolveInfoModalOpen(item?._id, item)} size={"sm"} variant="outline-secondary">Dispute Info</Button></>
                                                         }
                                                         {/*<Link to="/messages" size={"sm"} variant={"default"} className={"btn btn-sm btn-default"}>Chat</Link>*/}
                                                     </>
                                                        :null}

                                                </td>
                                            </tr>
                                        )) :
                                        <tr>
                                            <td colSpan={5}>No dispute found</td>
                                        </tr>
                                    }

                                    </tbody>
                                </table>

                            </React.Fragment>
                            :
                            <div>
                                Loading...
                            </div>
                        }
                    </div>
                </div>
                : null}


        </div>
      </App>
    );
  }
}

export default withRouter(Disputes);
