import React, { useState, useEffect } from "react";
import App from "../../App";
import { Link } from "react-router-dom";
import Moment from "moment";
import {FcLink, FiClock, IoIosArrowForward, RiReplyLine} from "react-icons/all";
import "../../assets/scss/support-ticket.scss";
import {Badge, Button} from "react-bootstrap";
import Pagination from "react-js-pagination";
import {getTableData} from "../../services/commonServices";
import {BsCardImage} from "@react-icons/all-files/bs/BsCardImage";

const NotificationList = () => {
    const [notificationList, setNotificationList] = useState(null);
    const [pagination, setPagination] = useState({});
    const [queryParams, setQueryParams] = useState({
        limit: 10,
        page: 1,
    });

    useEffect(() => {
        const fetchData = async () => {
            const result = await getTableData('/fcm-notification', queryParams);
            console.log(result)
            setNotificationList(result?.items);
            setPagination({ ...pagination, ...result.pagination });
        };
        fetchData();
    }, []);


    const handlePageChange = async (pageNumber) => {
        const params = { ...queryParams, page: pageNumber };
        setQueryParams(params);

        const result = await getTableData('/fcm-notification', params);
        setNotificationList(result?.items);
        setPagination({ ...pagination, ...result.pagination });
    };


    const ActivityLogComponent = () => {
        return (
            <>

                <div className="table-responsive">
                    <table className="order-table table table-striped">
                        <thead>
                        <tr>
                            <th style={{width: '20px'}}>#</th>
                            <th>Send By</th>
                            <th>Image</th>
                            <th>Title</th>
                            <th>Message</th>
                            <th className="nowrap text-center">Date</th>
                            {/* <th style={{width: `1%`}}/>*/}
                        </tr>
                        </thead>
                        <tbody>
                        {notificationList && notificationList.map((data, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    {data?.send_by}
                                </td>
                                <td>
                                    <td>{data?.image ? <img src={data?.image} width={`70px`} height={`70px`} alt={``}/> : <><BsCardImage width={`70px`} height={`70px`}/></>}</td>
                                </td>
                                <td>
                                    {data?.title}
                                </td>
                                <td>
                                    {data?.message}
                                </td>


                                <td className="nowrap text-center">{
                                    Moment(data.createdAt).format('hh : mm a DD-MM-YYYY')}</td>
                                {/* <td className="nowrap text-center">
                                    {getLocalAuthData()?.permission?.includes('activity_log_delete') ?  <Button
                                        onClick={()=>handleDelete(data._id)}
                                        className="btn-action btn-outline-primary" >DELETE
                                    </Button> : null}
                                </td>*/}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div className="mt-3">
                    <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={pagination?.page}
                        itemsCountPerPage={pagination?.limit}
                        totalItemsCount={pagination?.totalDocs ? pagination?.totalDocs : 0}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}
                    />
                </div>


            </>
        )
    }



    return (
        <App layout={"boxed"}>
            <div className="page-header">
                <h2 className="page-title">Notification</h2>

                <Link to={"/notification/add"} className={"btn btn-sm btn-secondary"}>
                    + Send Notification
                </Link>
            </div>

            <div className="card-block">
                <div className="block-header">
                    <h4 className="block-title">Notification List</h4>
                </div>

                <div className="block-body">
                    <div className="ticketing-list">{ActivityLogComponent()}</div>
                </div>
            </div>
        </App>
    );
};

export default NotificationList;
