import React, {Component} from 'react';
import App from "../../App";
import {Badge, Button, Form, Row, Spinner} from "react-bootstrap";
import Pagination from 'react-js-pagination';
import {FaFileExport} from "react-icons/all";
import "../../assets/scss/order-list.scss";
import axios from "axios";
import {API_BASE_URL} from "../../helper/env";
import {ToastsContainer, ToastsStore} from "react-toasts";
import {getChildLocations} from "../../services/locationServices";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import {deleteCustomerPermanently} from "../../services/customerServices";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import {Link} from "react-router-dom";
import {getDeviceType, getLocalAuthData} from "../../helper/utils";
import {activityLogPostData, fileDownload} from "../../services/commonServices";
import {FaFilePdf} from "@react-icons/all-files/fa/FaFilePdf";

class Customers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      locations: [],
      businessChildLocations: [],
      queryData: {
        from_date: '',
        end_date: ''
      },
      activePage: 1,
      pagination: {},
      isLoading: true,
      limit:50
    }
  }

  handlePageChange = async (pageNumber) => {
    await this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber});
    this.getQueryData();
  }

  componentDidMount() {
    const authData = getLocalAuthData()

      axios.get(`${API_BASE_URL}/customer?limit=${this.state.limit}`, {
        headers: {
          "x-auth-token": authData.token,
          "x-api-client": getDeviceType()
        }
      })
        .then(res => {
          this.setState({customers: res.data.items, pagination: res.data.pagination, isLoading: false});
        }).catch(e => console.log("Error"));

    axios.get(`${API_BASE_URL}/location?type=Country`, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": getDeviceType()
      }
    })
      .then(res => {
        this.setState({locations: res.data.locations});
      }).catch(e => console.log("Error"));
  }

  changeStatus = async (statusObj, customer_id) => {
    axios.patch(`${API_BASE_URL}/customer/${customer_id}/changeStatus`, statusObj)
      .then(response => {
        if (response.data.status === 1) {
          toast.success(response.data.message);
        }
        if (response.data.status === 2) {
          toast.error(response.data.message);
        }
      }).catch(error => {
      toast.error("Error");
    });
  };

  handlePermanentDeleteCustomer = (id) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to delete this customer permanently?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteCustomerPermanently(id)
            .then(res => {
              toast.success('Successfully deleted');
              const authData = getLocalAuthData();
              axios.get(`${API_BASE_URL}/customer?limit=${this.state.limit}`, {
                headers: {
                  "x-auth-token": authData.token,
                  "x-api-client": getDeviceType()
                }
              })
                .then(res => {
                  console.log(res.data.items);
                  this.setState({customers: res.data.items, pagination: res.data.pagination, isLoading: false});
                }).catch(e => console.log("Error"));
            })
            .catch(errMsg => {
              toast.error(errMsg)
            })
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }

  getQueryData = async (e) => {
    e.preventDefault()
    this.setState({isLoading: true});
    let queryData = "";
    await Object.entries(this.state.queryData).map(([key, val]) => {
        queryData = queryData.concat(key + "=" + val + "&");
      }
    )
    const authData = getLocalAuthData();
    axios.get(`${API_BASE_URL}/customer?limit=${this.state.limit}&${queryData}`, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": getDeviceType()
      }
    })
      .then(res => {
        console.log(res.data.items);
        this.setState({customers: res.data.items, pagination: res.data.pagination, isLoading: false});
      }).catch(e => console.log("Error"));
  }

  handleSearchFormDataChange = async (e) => {
    await this.setState({queryData: {...this.state.queryData, [e.target.name]: e.target.value}});
    setTimeout(() => {
    }, 2000)
  };

  handleLocationInputOnChange = (e, index, typePurpose) => {
    const childLocations = [...this.state[typePurpose]];
    if (typePurpose === 'businessChildLocations') {
      this.handleSearchFormDataChange(e)
    } else {
      this.handleSearchFormDataChange(e)
    }
    if(e.target.value !== '') {
      getChildLocations(e.target.value)
        .then(res => {
          childLocations.splice(index, childLocations.length - index);
          if (res.length > 0) {
            childLocations[index] = res
          }
          this.setState({[typePurpose]: childLocations})
        })
        .catch(errMsg => {
          console.log(errMsg)
          childLocations.splice(index, childLocations.length - index)
          this.setState({[typePurpose]: childLocations})
        })
    } else {
      childLocations.splice(index, childLocations.length - index);
      this.setState({[typePurpose]: childLocations})
    }
  }

  renderLocations = () => {
    const locations = this.state.locations;
    if (locations) {
      return (
        <>
          {locations.map((items, index) =>
            <option value={`${items._id}`}>{items.name}</option>
          )}
        </>
      )
    }
  };

  handleOnApply = (e, picker) => {
    const queryData = {...this.state.queryData};

    queryData.from_date = moment(picker.startDate).format('DD/MM/YYYY');
    queryData.to_date = moment(picker.endDate).format('DD/MM/YYYY');

    this.setState({queryData}, () => {
      console.log(this.state)
    })
  }

  handleExportFile = async (fileType) => {
    this.setState({export_loading: true})
    let queryData = {...this.state.queryData}
    queryData.export = 'xlsx'
    this.setState({queryData}, ()=>{
      console.log('this.state.queryData',this.state.queryData);

      fileDownload(`/customer`, this.state.queryData).then((response)=>{
        console.log(response)
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'ekshop-customer.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.setState({export_loading: false})
      })
      queryData.export = null
      this.setState({
        queryData
      })

    })


    // let queryData = "";
    // Object.entries(this.state.queryData).forEach(([key, val]) => {
    //       queryData = queryData.concat(key + "=" + val + "&");
    //     }
    // )
    // await this.setState({isExporting: true});
    // const authData = getLocalAuthData();
    // axios.get(`${API_BASE_URL}/customer?export=${fileType}&${queryData}`, {
    //   headers: {"x-auth-token": authData.token, "x-api-client": getDeviceType()}
    // }).then(res => {
    //   console.log(res)
    //   this.setState({isExporting: false});
    // }).catch(e => console.log("Error"));
  }

  render() {
    return (
      <App layout="boxed">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Customers</h2>

            {getLocalAuthData()?.permission?.includes('customer_export') ?
                <div className="ml-auto">

                  <Button variant="primary"
                          size={`sm`}
                          onClick={() => this.handleExportFile('xlsx')}
                          disabled={this.state.export_loading}>
                    {this.state.export_loading ?
                        <>
                          <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                          />
                          <span>Loading...</span>
                        </>
                        : <><FaFilePdf/><span>Export</span></>}
                  </Button>

                  {/*<Button size={"sm"} variant={"defalut"} className="mb-0 ml-2" onClick={() => this.handleExportFile('xlsx')}><FaFileExport/> Export</Button>*/}
                </div>
                : null}
          </div>


          {getLocalAuthData()?.permission?.includes('customer_list') ?
          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Customer List</h4>
            </div>

            <div className="block-body">

              <form onSubmit={this.getQueryData} className="form-inline mb-3">

                <div className="form-group mr-3">
                  <label htmlFor="status" className="sr-only">Locations</label>
                  <select id="status" name="country" className='form-control form-control-sm'
                          onChange={(e) => this.handleLocationInputOnChange(e, 0, 'businessChildLocations')}>
                    <option value="">--Select Country--</option>
                    {this.renderLocations()}
                  </select>
                </div>

                {this.state.businessChildLocations.length > 0 &&
                this.state.businessChildLocations.map((location, index) => (
                  <div className="form-group mr-3" key={index} as={Row}
                       controlId={`location${location[0].type}`}>
                    <label htmlFor={`location${location[0].type}`}
                           className="sr-only">{location[0].type}</label>
                    <select id={`location${location[0].type}`}
                            name={location[0].type.toLowerCase()}
                            className='form-control form-control-sm'
                            onChange={(e) => this.handleLocationInputOnChange(e, index + 1, 'businessChildLocations')}>
                      <option value="">--Select--</option>
                      {location.map((item, index) => (
                        <option key={index} value={item._id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                ))
                }
                <div className="form-group mr-3">
                  <label htmlFor="status" className="sr-only">Status</label>
                  <select id="status" name="status" className='form-control form-control-sm'
                          onChange={this.handleSearchFormDataChange}>
                    <option value="">--Select Status--</option>
                    <option value="1">Active</option>
                    <option value="2">Inactive</option>
                  </select>
                </div>

                <div className="form-group mr-3">
                  <label htmlFor="status" className="sr-only"/>
                  <select id="status" name="muktopath_user" className='form-control form-control-sm'
                          onChange={this.handleSearchFormDataChange}>
                    <option value="">--Select User type--</option>
                    <option value='true'>MuktoPaath User</option>
                    <option value='false'>General User</option>
                  </select>
                </div>

                <div className="form-group mr-3">
                  <label htmlFor="searchInput" className="sr-only">Search Key</label>
                  <input type="search" name="search_string" id="orderNumber"
                         onChange={this.handleSearchFormDataChange}
                         className='form-control form-control-sm' placeholder="Search Key"/>
                </div>

                <Form.Group className={`mr-2`}>
                  <DateRangePicker
                    initialSettings={{

                    }}
                    containerStyles={{textAlign: 'left', width: '100%', display: 'block'}}
                    onApply={this.handleOnApply}>
                    <input type="button"
                           placeholder={`Select date range`}
                           value={(this.state.queryData.from_date && this.state.queryData.to_date) ? `${this.state.queryData.from_date} - ${this.state.queryData.to_date}` : 'Select date range'}
                           style={{textAlign: 'left', minWidth: '150px'}}
                           className="form-control form-control-sm"/>
                  </DateRangePicker>
                </Form.Group>

                <Button
                    size="sm"
                    variant="secondary"
                    onClick={this.getQueryData}
                    onKeyDown={(e)=>{
                      if(e.keyCode === 13){
                        this.getQueryData(e).then(r => {})
                      }
                    }}
                >Search
                </Button>
              </form>

              {this.state.isLoading ? <div>Loading...</div> :

                  this.state?.customers && this.state?.customers?.length > 0 ?
                  <>
                    <table className="order-table table table-striped">
                      <thead>
                      <tr>
                        <th style={{width: '1%'}}>#</th>
                        <th style={{width: '250px'}}>Name</th>
                        <th style={{width: '250px'}}>Contact Info</th>
                        <th style={{width: '100'}}>Login Info</th>
                        <th style={{width: '150px'}}>Status</th>
                        <th style={{width: '120px'}}/>
                      </tr>
                      </thead>
                      <tbody>

                      {this.state.customers.map((attr, index) => {
                        let selected1 = 'selected';
                        let selected2 = '';
                        if (attr.status === 2) {
                          selected2 = "selected";
                          selected1 = "";
                        }
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <div className="order-item">
                                <strong className="order-id">{attr.name ? attr.name : attr.phone}</strong>
                              </div>
                            </td>
                            <td>
                              <div className="order-item">
                                <ul className="ordered-items">
                                  <li>Address:</li>
                                  <li>{attr.phone}</li>
                                  <li>{attr.email}</li>
                                  <li><Badge variant="info">{attr?.muktopath_user === true && attr?.muktopath_user !== undefined && 'MuktoPath user'}</Badge></li>
                                </ul>
                              </div>
                            </td>
                            <td>
                              <strong>Last Login Time: </strong> {attr?.login_info?.length>0?moment(attr?.login_info[attr?.login_info?.length-1]?.login_time).format("hh:mm a LL"): "Didn't Login Yet"}
                            </td>
                            <td>
                              {getLocalAuthData()?.permission?.includes('customer_active_inactive') ?
                              <select
                                style={{width: '150px'}}
                                className="form-control form-control-sm"
                                onChange={(e) => {
                                  this.changeStatus({status: e.target.value}, attr._id)
                                  activityLogPostData('was change this customer status ','/customers/details/'+attr._id ,attr?.name)
                                }}
                                name="status">
                                <option value="1" selected={selected1}>Active</option>
                                <option value="2" selected={selected2}>Inactive</option>
                              </select>
                                  :null}
                            </td>
                            <td>
                              {getLocalAuthData()?.permission?.includes('customer_details') ?
                                  <Link to={`/customers/details/${attr._id}`} className="btn-outline-primary btn-action" target={'_blank'}>View</Link>
                                  :null}

                              {/*{getLocalAuthData()?.permission?.includes('customer_delete') ?
                              <button
                                className="btn-action btn-outline-danger"
                                onClick={() => {
                                  this.handlePermanentDeleteCustomer(attr._id)
                                  activityLogPostData('was delete this customer','/customers/details/'+attr._id ,attr?.name)
                                }}>Delete</button>
                                  :null}*/}
                            </td>
                          </tr>
                        );
                      })}

                      </tbody>
                    </table>
                    <div className="mt-3">
                      <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.pagination?.page}
                        itemsCountPerPage={this.state.pagination.limit}
                        totalItemsCount={this.state.pagination.totalDocs}
                        pageRangeDisplayed={10}
                        onChange={this.handlePageChange.bind(this)}
                      />
                    </div>
                  </>
                  : <h5>No Data Found</h5>
              }

            </div>
          </div>
            :null}

        </div>
        <ToastsContainer store={ToastsStore}/>
      </App>
    );
  }
}

export default Customers;
