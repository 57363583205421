import {combineReducers} from 'redux'
import authReducer from './auth/authReducer';
import navbarReducer from './navbar-toggle/navbarToggleReducer';
import fileManagerReducer from "./file-manager/fileManagerReducer";
import dashboardReducer from './dashboard/reducer'

const rootReducer = combineReducers({
  auth: authReducer,
  navbar: navbarReducer,
  fileManager: fileManagerReducer,
  dashboard: dashboardReducer
});

export default rootReducer
