import axois from "axios";
import config, {API_BASE_URL} from "../helper/env";
import axios from "axios";
import {getDeviceType, getLocalAuthData} from "../helper/utils";

export const getSlider = (paramsObj = {}) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axois.get(`${API_BASE_URL}/slider`, {
            params: paramsObj,
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            if (res.data.status) {
                resolve(res.data)
            }
        }).catch(err => {
            reject(err.message)
        })
    })
}

export const getBanner = (paramsObj = {}) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axois.get(`${API_BASE_URL}/banner`, {
            params: paramsObj,
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            if (res.data.status === 1) {
                resolve(res.data)
            } else {
                reject(res.data)
            }
        }).catch(err => {
            reject(err.message)
        })
    })
}

export const submitBanner = (data) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axois.post(`${API_BASE_URL}/banner`, data, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            if (res.data.status === 1) {
                resolve(res.data)
            } else {
                reject(res.data)
            }
        }).catch(err => {
            reject(err.message)
        })
    })
}

export const submitSlider = () => {
    return new Promise((resolve, reject) => {
        axois.post(`${API_BASE_URL}/slider`)
            .then(res => {
                if (res.data.status) {
                    resolve(res.data.items)
                }
            })
            .catch(err => {
                reject(err.message)
            })
    })
}

export const deleteSlider = (id) => {
    return new Promise((resolve, reject) => {
        axios.delete(`${API_BASE_URL}/slider/${id}/permanent/`)
            // axois.post(`${API_BASE_URL}/slider`)
            .then(res => {
                if (res.data.status) {
                    resolve(res.data)
                }
            })
            .catch(err => {
                reject(err.message)
            })
    })
}

export const deleteBanner = (id) => {
    return new Promise((resolve, reject) => {
        axios.delete(`${API_BASE_URL}/banner/${id}/permanent/`)
            // axois.post(`${API_BASE_URL}/slider`)
            .then(res => {
                if (res.data.status) {
                    resolve(res.data)
                }
            })
            .catch(err => {
                reject(err.message)
            })
    })
}

export const updateBanner = (id) => {
    return new Promise((resolve, reject) => {
        axios.put(`${API_BASE_URL}/banner/${id}`)
            // axois.post(`${API_BASE_URL}/slider`)
            .then(res => {
                if (res.data.status) {
                    resolve(res.data)
                }
            })
            .catch(err => {
                reject(err.message)
            })
    })
}

export const detailsBanner = (id) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}/banner/${id}`, {
            headers: {
                "x-auth-token": authData.token,
                "x-api-client": 'web'
            }
        })
            .then(res => {
                if (res.data.status) {
                    resolve(res.data)
                }
            })
            .catch(err => {
                reject(err.message)
            })
    })
}