import React, {Component} from 'react';
import App from "../../App";
import {Button, ButtonGroup, Dropdown, DropdownButton, Form, Spinner} from "react-bootstrap";
import {FaFileExport, FaPrint, FiSearch} from "react-icons/all";
import Moment from "moment";

import Pagination from 'react-js-pagination';
import {withRouter} from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {fileDownload, getTableData} from "../../services/commonServices";

import moment from "moment";
import {useJsonToCsv} from "react-json-csv";
import {FaFilePdf} from "@react-icons/all-files/fa/FaFilePdf";
const {saveAsCsv} = useJsonToCsv();

class WalletReport extends Component {
  state = {
    startDate: null,
    endDate: null,
    reportData: [],
    queryData: {
      limit: 20
    },
    pagination: {},
  }


  getData = (queryData) =>{
    getTableData('/reports/wallet' ,queryData).then(res => {
      this.setState({
        reportData: res.items,
        pagination: res.paginate
      },()=>{
        console.log("----->> ",this.state)
      })
    })
  }

  componentDidMount() {
    this.getData(this.state.queryData)
  }

  handlePageChange = async (pageNumber) => {
    const history = this.props.history
    history.push(`${history.location.pathname}?page=${pageNumber}`)
    await this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber}, ()=>{
      this.getData(this.state.queryData);
    });
  }



  handleExport = async () =>{
    this.setState({export_loading: true})

    let queryData = {...this.state.queryData}
    queryData.export = 'xlsx'
    this.setState({queryData}, ()=>{
      fileDownload(`/reports/wallet`, this.state.queryData).then((response)=>{
        console.log(response)
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'ekshop-wallet-report.xlsx');
        document.body.appendChild(link);
        link.click();
        this.setState({export_loading: false})
      })
      queryData.export = null
      this.setState({
        queryData
      })
    })

   /* let queryData = "";
    // noinspection JSMismatchedCollectionQueryUpdate
    let mData = [];

    this.state.reportData.length > 0 && this.state.reportData.map((data,index) => {

      mData.push({
        'Index': index+1,
        'Order Id': data?.order_parcel_code,
        'Used Wallet Amount': data?.parcel_wallet_amount,
        'Ekshop Liability Percentage': data?.ekshop_liability_percentage,
        'Muktopaath Liability Percentage': data?.muktopaath_liability_percentage,
        'Ekshop Liability amount': (data?.ekshop_liability_percentage/100) * data?.parcel_wallet_amount,
        'Muktopaath Liability Amount': (data?.muktopaath_liability_percentage/100) * data?.parcel_wallet_amount,
        'Date': moment(data?.createdAt).format("hh:mm a DD/MM/YYYY"),
      });

    });

    const filename = 'EkShop-Wallet Report',
        fields = {
          'Index': "Index",
          'Order Id': "Order Id",
          'Used Wallet Amount': "Used Wallet Amount",
          'Ekshop Liability Percentage': "Ekshop Liability Percentage",
          'Muktopaath Liability Percentage': "Muktopaath Liability Percentage",
          'Ekshop Liability amount': "Ekshop Liability amount",
          'Muktopaath Liability Amount': "Muktopaath Liability Amount",
          'Date': "Date"
        },
        style = {
          padding: "30px",
          maxHeight: "20px"
        },
        data = mData,
        text = "Convert Json to Csv";
    saveAsCsv({data, fields, filename});*/

  }


  handleOnChangeInput = (e) => {
    const queryData = {...this.state.queryData}
    queryData[e.target.name] = e.target.value;
    this.setState({queryData}, () => {
      console.log(this.state.formData)
      this.getData(this.state.queryData)
    })
  }


  handleOnApply = (e, picker) => {
    const queryData = {...this.state.queryData}
    const startDate = Moment(picker.createdAt).format('DD/MM/YYYY hh:mm:ss')
    const endDate = Moment(picker.endDate).format('DD/MM/YYYY hh:mm:ss')
    queryData['from_date'] = startDate;
    queryData['to_date'] = endDate;

    this.setState({
      queryData
    }, () => {
      this.getData(this.state.queryData)
    })
  }

  render() {
    return (
      <App>
        <div className="page-header">
          <h2 className="page-title">Wallet Report</h2>

          <div className="ml-auto">
            <Button variant="primary"

                    size={`sm`}
                    onClick={() => this.handleExport('xlsx')}
                    disabled={this.state.export_loading}>
              {this.state.export_loading ?
                  <>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span>Loading...</span>
                  </>
                  : <><FaFilePdf/><span>Export</span></>}
            </Button>
            {/*<Button onClick={() => {
              this.handleExport()
            }} size={"sm"} variant={"default"} className="mb-0 mr-2"><FaFileExport/> Export</Button>*/}
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
            <Form className="d-flex v-middle">
              <div className="form-inline mr-2">

                <Form.Group className={'mr-2'}>
                  <Form.Label className={"sr-only"}>Date Range Picker</Form.Label>
                  <input
                      type="search"
                      id="orderNumber"
                      className='form-control form-control-sm'
                      placeholder="Search Key"
                      name="search_string"
                      onChange={this.handleOnChangeInput}
                  />
                </Form.Group>

                <Form.Group className={'mr-2'}>
                  <Form.Label className={"sr-only"}>Date Range Picker</Form.Label>
                  <DateRangePicker
                      onApply={this.handleOnApply}>
                    <input type="text"
                           style={{textAlign: 'left', minWidth: '150px'}}
                           placeholder={"Select date range"}
                           className="form-control form-control-sm"/>
                  </DateRangePicker>
                </Form.Group>
              </div>
            </Form>

            <hr/>
            <div className="table-responsive">
            <table className="table table-striped">
              <thead>
              <tr>
                <th style={{width: '30px'}}>SL</th>
                <th>Order Id</th>
                <th>Used Wallet Amount</th>
                <th>Ekshop Liability Percentage</th>
                <th>Muktopaath Liability Percentage</th>
                <th>Ekshop Liability amount</th>
                <th>Muktopaath Liability Amount</th>
                <th>Date</th>
              </tr>
              </thead>
              <tbody>
              {this.state.reportData && this.state.reportData.length > 0 && this.state.reportData.map((data, i) => {
                return (
                    <tr>
                      <td>{i+1}</td>
                      <td>{data?.order_parcel_code}</td>
                      <td>{data?.parcel_wallet_amount}</td>
                      <td>{data?.ekshop_liability_percentage}%</td>
                      <td>{data?.muktopaath_liability_percentage}%</td>
                      <td>{(data?.ekshop_liability_percentage/100) * data?.parcel_wallet_amount}</td>
                      <td>{(data?.muktopaath_liability_percentage/100) * data?.parcel_wallet_amount}</td>
                      <td>{moment(data?.createdAt).format("hh:mm a DD/MM/YYYY")}</td>
                    </tr> )
              })}
              </tbody>
            </table>
            </div>
            <div className="mt-3">
              <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={this.state?.pagination?.page}
                  itemsCountPerPage={this.state?.pagination?.limit}
                  totalItemsCount={this.state?.pagination?.totalDocs ? this.state?.pagination?.totalDocs : 0}
                  pageRangeDisplayed={10}
                  onChange={this.handlePageChange.bind(this)}/>
            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(WalletReport);
