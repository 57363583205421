import React, {Component} from 'react';
import App from "../../App";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {Button, Form} from "react-bootstrap";
import moment from "moment";
import {Link, withRouter} from "react-router-dom";
import {getCommissionList} from "../../services/commissionServices";
import Pagination from "react-js-pagination";
import LoadingSpinner from "../../components/LoadingSpinner";
import {getLocalAuthData} from "../../helper/utils";

class MerchantCommission extends Component {
  state = {
    queryData: {
      from_date: '',
      to_date: ''
    },
    commissionList: [],
    pagination: {},
    isLoading: true,
    settings: {
      user_type: 'merchant',
      limit: 20
    },
    isDataPartLoading: false
  }

  componentDidMount() {
    const merchantId = this.props.match.params.id
    getCommissionList({...this.state.settings, merchant_id: merchantId})
      .then(res => {
        this.setState({
          commissionList: res.items,
          pagination: res.pagination,
          isLoading: false,
          commissionSummary: res.commission_summary
        })
      })
      .catch(errMsg => {
        this.setState({isLoading: false})
      })
  }

  handleOnApply = (e, picker) => {
    const queryData = {...this.state.queryData};
    queryData.from_date = moment(picker.startDate).format('DD/MM/YYYY');
    queryData.to_date = moment(picker.endDate).format('DD/MM/YYYY');
    this.setState({queryData})
  }

  handleFilterInputOnChange = (e) => {
    const queryData = {...this.state.queryData}
    queryData[e.target.name] = e.target.value
    this.setState({queryData})
  }

  handleFilter = (e) => {
    e.preventDefault()
    const merchantId = this.props.match.params.id

    this.setState({isDataPartLoading: true}, () => {
      getCommissionList({...this.state.queryData, merchant_id: merchantId})
        .then(res => {
          this.setState({commissionList: res.items, pagination: res.pagination, isDataPartLoading: false})
        })
        .catch(errMsg => {
          this.setState({isDataPartLoading: false})
        })
    })
  }

  render() {
    return (
      <App layout="boxed">
        <div className="main-content">
          { getLocalAuthData()?.permission?.includes('merchant_commission_list') ?
              <>
                <div className="page-header">
                  <h2 className="page-title">Commission List</h2>
                </div>
                <div className="card-block position-relative">
                  <div className="block-body">
                    {!this.state.isLoading ?
                        <React.Fragment>
                          <div className="commission-info-cards mb-4">
                            <div className="commission-info"><span className="title">Transaction Amount</span><span
                                className="value">৳ {this.state.commissionSummary.total_transaction_amount.toFixed(2)}</span></div>
                            <div className="commission-info">
                              <span className="title">Commission Earn</span>
                              <span className="value">৳ {this.state.commissionSummary.commission_earn.toFixed(2)}</span>
                            </div>
                            <div className="commission-info"><span className="title">Commission Paid</span><span
                                className="value">৳ {this.state.commissionSummary.commission_paid.toFixed(2)}</span>
                            </div>
                            <div className="commission-info"><span className="title">Commission Due</span><span
                                className="value">৳ {this.state.commissionSummary?.commission_earn ? ((Number)(this.state.commissionSummary?.commission_earn) - (Number)(this.state.commissionSummary?.commission_paid)).toFixed(2) : 0.00}</span>
                            </div>
                          </div>

                          {this.state.isDataPartLoading &&
                          <LoadingSpinner/>
                          }

                          <form onSubmit={this.handleFilter} className="form-inline mb-3">
                            <div className="form-group mr-3">
                              <label htmlFor="searchInput" className="sr-only">Search Key</label>
                              <input
                                  type="search"
                                  onChange={this.handleFilterInputOnChange}
                                  id="orderNumber"
                                  className='form-control form-control-sm'
                                  placeholder="Search Key"
                                  name="search_string"/>
                            </div>

                            <Form.Group className={`mr-2`}>
                              <DateRangePicker
                                  initialSettings={{}}
                                  containerStyles={{textAlign: 'left', width: '100%', display: 'block'}}
                                  onApply={this.handleOnApply}>
                                <input type="button"
                                       placeholder={`Select date range`}
                                       value={(this.state.queryData.from_date && this.state.queryData.to_date) ? `${this.state.queryData.from_date} - ${this.state.queryData.to_date}` : 'Select date range'}
                                       style={{textAlign: 'left', minWidth: '150px'}}
                                       className="form-control form-control-sm"/>
                              </DateRangePicker>
                            </Form.Group>

                            <Button type={'submit'} varient={'primary'} size={'sm'}>Search</Button>
                          </form>

                          <table className="order-table table table-striped">
                            <thead>
                            <tr>
                              <th style={{width: '1%'}}>#</th>
                              <th>Date</th>
                              <th>Description</th>
                              <th>Statement Type</th>
                              <th style={{width: '15%'}} className={'nowrap text-right'}>Cash IN ৳ </th>
                              <th style={{width: '15%'}} className={'nowrap text-right'}>Cash Out ৳ </th>
                              <th style={{width: '5%'}}></th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.commissionList.length > 0 ?
                                this.state.commissionList.map((item, index) => (
                                    <tr key={index}>
                                      <td>{this.state.pagination.pagingCounter + index}</td>
                                      <td>{moment(item.createdAt).format('ll')}</td>
                                      <td>
                                        {
                                          item?.order_info?
                                              <>
                                                <Link to={`/orders/details/${item?.order_info?._id}`}>{item?.order_info?.order_parcel_code}</Link><br/>
                                                <span>Order Amount: {item?.product_info?.item_grand_total}</span><br/>
                                              </>
                                              :
                                              <span></span>
                                        }

                                        <span>Payment Method: {item?.payment_method?.method_type?item?.payment_method?.method_type:item?.payment_method}</span><br/>

                                        {item?.transaction_number?<><span>Transaction Number: {item?.transaction_number}</span><br/></>:''}

                                        {item?.transaction_note?<span>Remark: {item?.transaction_note}</span>:''}

                                      </td>
                                      <td>{item?.type}</td>
                                      <td className={'text-right'}>{item?.amount?'৳ '+item?.amount?.toFixed(2):'-'}</td>
                                      <td className={'text-right'}>{item?.debit?'৳ '+item?.debit?.toFixed(2):'-'}</td>
                                      <td className={'text-right'}></td>
                                    </tr>
                                ))
                                :
                                <tr>
                                  <td colSpan={6} className={'text-center text-bold'}>No commission found</td>
                                </tr>
                            }
                            </tbody>
                          </table>

                          <Pagination
                              className={'mt-4'}
                              itemClass="page-item"
                              linkClass="page-link"
                              activePage={this.state.pagination?.page}
                              itemsCountPerPage={this.state.pagination?.limit}
                              totalItemsCount={this.state.pagination?.totalDocs}
                              onChange={(page) => {
                                const settings = {...this.state.settings}
                                settings.page = page
                                this.setState({isLoading: true, settings}, () => {
                                  const merchantId = this.props.match.params.id
                                  getCommissionList({merchant_id: merchantId})
                                      .then(res => {
                                        this.setState({commissionList: res.items, pagination: res.pagination, isLoading: false})
                                      })
                                      .catch(errMsg => {
                                        this.setState({isLoading: false})
                                      })
                                })
                              }}
                          />
                        </React.Fragment>
                        :
                        <div>
                          Loading...
                        </div>
                    }
                  </div>
                </div>
              </>
              : null}


        </div>
      </App>
    );
  }
}

export default withRouter(MerchantCommission);