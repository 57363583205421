import React, {Component} from "react"
import {Col, Row, Form, Button} from "react-bootstrap"
import "../../assets/scss/business-block-card.scss"
import InfoCard from "../InfoCard"
// import DateRangePicker from "react-bootstrap-daterangepicker";
// import Moment from "moment";
import {
    AiOutlineCloseCircle,
    AiOutlineDollar,
    AiOutlineHourglass,
    AiOutlineWarning,
    FcSalesPerformance,
    GoListOrdered,
    BsCheckCircle, BsListCheck,
    FiFileText, FiPlus
} from "react-icons/all"
import {Link, withRouter} from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {BsCardImage} from "@react-icons/all-files/bs/BsCardImage";
import moment from "moment";
import Pagination from "react-js-pagination";
import {getDetail, getTableData} from "../../services/commonServices";

class DashboardOrderStats extends Component {
    state = {
        startDate: null,
        endDate: null,
        pagination: {},
        setData: [],
        queryParams: {
            limit: 3,
            page: 1,
        }
    }


    fetchData = async () => {
        const result = await getDetail('/notice', this.state.queryParams);
        console.log(result.items)
        this.setState({
            setData: result.items,
            pagination: result.pagination
        })

    };

    handlePageChange = async (pageNumber) => {
        const params = {...this.state.queryParams, page: pageNumber};
        this.setState({
            queryParams: params
        }, () => {
            console.log(this.state.queryParams)
            this.fetchData()
        })

    };

    componentDidMount() {
        this.fetchData()
    }


    render() {
        return (
            <Row>
                <Col xl={6} lg={12} md={12} className={"col-60"}>
                    <Row>
                        <Col className={"col-md-4"}>
                            <InfoCard title="Total Sales" className="yellow" icon={<FcSalesPerformance/>}
                                      value={`৳` + this.props.orderReports?.order_reports?.total_sales?.toFixed(2)}/>
                        </Col>
                        <Col className={"col-md-4"}>
                            <InfoCard title="Total Order" className="blue" icon={<GoListOrdered/>}
                                      value={this.props.orderReports?.order_reports?.total_order}/>
                        </Col>
                        <Col className={"col-md-4"}>
                            <InfoCard title="Confirmed Order" className="green" icon={<BsCheckCircle/>}
                                      value={this.props.orderReports?.order_reports?.confirmed_order}/>
                        </Col>

                        <Col className={"col-md-4"}>
                            <InfoCard title="Pending Order" className="green-light" icon={<AiOutlineWarning/>}
                                      value={`৳ ` + this.props.orderReports?.order_reports?.pending_order?.toFixed(2)}/>
                        </Col>

                        <Col className={"col-md-4"}>
                            <InfoCard title="Picked Order" className="blue" icon={<FiFileText/>}
                                      value={this.props.orderReports?.order_reports?.picked_order}/>
                        </Col>

                        <Col className={"col-md-4"}>
                            <InfoCard title="Processing Orders" className="orange" icon={<AiOutlineHourglass/>}
                                      value={this.props.orderReports?.order_reports?.processing_order}/>
                        </Col>

                        <Col className={"col-md-4"}>
                            <InfoCard title="Shipped Order" className="green" icon={<BsListCheck/>}
                                      value={this.props.orderReports?.order_reports?.shipped_order}/>
                        </Col>

                        <Col className={"col-md-4"}>
                            <InfoCard title="Delivered Order" className="green" icon={<BsListCheck/>}
                                      value={this.props.orderReports?.order_reports?.delivered_order}/>
                        </Col>

                        <Col className={"col-md-4"}>
                            <InfoCard title="Canceled Order" className="red" icon={<AiOutlineCloseCircle/>}
                                      value={this.props.orderReports?.order_reports?.cancel_order}/>
                        </Col>
                    </Row>
                </Col>
                <Col xl={6} lg={12} md={12} className={"col-40"}>
                    <Row>
                        <div className="col-12">
                            <div className="card-block">
                                <div className="block-header">
                                    <h4 className="block-title">Notice List</h4>
                                </div>
                                <div className="block-body position-relative">
                                    <table className="order-table table table-striped">
                                        <thead>
                                        <tr>
                                            <th style={{width: '50%'}}>Title</th>
                                            <th style={{width: '20%'}}>Date</th>
                                            <th style={{width: '30%', textAlign: 'right'}}>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody className="notice-list-dashboard">
                                        {this.state.setData?.map((item, index) => {
                                            return (<tr key={index}>
                                                <td>
                                                    <div className="order-item">
                                                        <p className={item?.notice_board_seen?.seen === false ? 'order-id font-weight-bolder mb-0' : 'order-id mb-0'}>{item?.title}</p>
                                                        <small className="font-weight-normal">{item?.message}</small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="order-item">
                                                        {moment(item?.createdAt).format('hh:mm DD/MM/YYYY')}
                                                    </div>
                                                </td>
                                                <td>
                                                    <Link className="btn-outline-primary btn-action"
                                                          to={`${process.env.PUBLIC_URL}/notice/view/${item._id}`}>Details</Link>


                                                    {/* <button onClick={() =>
                                                  history.push(`${process.env.PUBLIC_URL}/notice/edit/${item._id}`)
                                              } className="btn-outline-primary btn-action">Edit</button>*/}


                                                    {/*   <button onClick={() => handleDeleteX(item?._id)}
                                                className="btn-action btn-outline-danger">Delete</button>*/}


                                                    {/*{item.user_type === 'merchant' && item?.campaign_id &&
                                                    <Link to={`/promotions/campaign/details/${item?.campaign_id}`}
                                                          className="btn btn-outline-info btn-action btn-sm h-100">View</Link>
                                                    }*/}
                                                </td>
                                            </tr>)
                                        })}
                                        {/* <tr>
                                            <td>
                                                <div className="order-item">
                                                    <p className="order-id font-weight-bolder mb-0">Congratulation ,
                                                        your store was approved</p>
                                                    <small className="font-weight-normal">Campaign name : Staging
                                                        Test</small>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="order-item">
                                                    03:23 20/06/2021
                                                </div>
                                            </td>
                                            <td className={'text-right'}>
                                                <button className="btn-outline-primary btn-action">View</button>
                                                <button className="btn-outline-primary btn-action">Edit</button>
                                                   <button onClick={() => handleDeleteX(item?._id)}
                                                className="btn-action btn-outline-danger">Delete</button>

                                                <Link to={`#`}
                                                      className="btn btn-outline-info btn-action btn-sm h-100">Delete</Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="order-item">
                                                    <p className="order-id font-weight-normal mb-0">Congratulation ,
                                                        your store was approved</p>
                                                    <small className="font-weight-normal">Campaign name : Staging
                                                        Test</small>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="order-item">
                                                    03:25 23/06/2021
                                                </div>
                                            </td>
                                            <td className={'text-right'}>
                                                <button className="btn-outline-primary btn-action">View</button>
                                                <button className="btn-outline-primary btn-action">Edit</button>
                                                   <button onClick={() => handleDeleteX(item?._id)}
                                                className="btn-action btn-outline-danger">Delete</button>

                                                <Link to={`#`}
                                                      className="btn btn-outline-info btn-action btn-sm h-100">Delete</Link>
                                            </td>
                                        </tr>*/}

                                            </tbody>
                                            </table>
                                            <div className="mt-3">
                                              <Pagination
                                                  itemClass="page-item"
                                                  linkClass="page-link"
                                                  activePage={this.state.pagination?.page}
                                                  itemsCountPerPage={this.state.pagination?.limit}
                                                  totalItemsCount={this.state.pagination?.totalDocs}
                                                  pageRangeDisplayed={10}
                                                  onChange={this.handlePageChange.bind(this)}
                                              />
                                            </div>
                                            </div>
                                            </div>

                                            </div>
                                            </Row>
                                            </Col>

                                        {/* <Col className={"col-20"}>
          <InfoCard title="Admin can filter date by today, this month, this year" className="red"/>
          <Form.Group className={"mr-2"}>
            <Form.Label className={"sr-only"}>Date: </Form.Label>
            <DateRangePicker
                onApply={this.handleOnApply}>
              <input type="text"
                      value={this.state.startDate && this.state.endDate ? `${this.state.startDate} to ${this.state.endDate}` : ''}
                      onChange={() => {}}
                      style={{textAlign: 'left', minWidth: '150px'}}
                      placeholder={"Select date range"}
                      className="form-control form-control-sm"/>
            </DateRangePicker>
          </Form.Group>
        </Col> */}
                                            </Row>
                                            )
                                        }
                                        }

    export
    default
    withRouter(DashboardOrderStats)
