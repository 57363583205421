import React, {Component} from 'react';
import App from "../../App";
import {Button, Pagination} from "react-bootstrap";
import {FaFileExport, FiPlus, FiUpload} from "react-icons/all";
import {Link} from "react-router-dom";
import { getLocalAuthData } from "../../helper/utils"

class RewardPointSettingList extends Component {
  render() {
    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Reward Point Setting</h2>
          <div className="ml-auto">

            {getLocalAuthData()?.permission?.includes('reward_point_setting_import') ?
            <label htmlFor="productImport" className="btn mb-0 btn-sm btn-default mr-2">
              <input type="file"
                     id="productImport"
                     className="sr-only"/>
              <FiUpload/> Import
            </label> : null}
            <a href="/#" className="text-muted mr-3" download><small>(Download sample file)</small></a>
            {getLocalAuthData()?.permission?.includes('reward_point_setting_export') ?
            <Button size={"sm"} variant={"default"} className="mb-0 mr-2"><FaFileExport/> Export</Button>
                : null}
            {getLocalAuthData()?.permission?.includes('reward_point_setting_add') ?
            <Link to={`${process.env.PUBLIC_URL}/promotions/reward-point/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add Reward Point Setting</Link>
                : null}
          </div>
        </div>

        <div className="main-content">
          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Reward Point Setting List</h4>
            </div>


            {getLocalAuthData()?.permission?.includes('reward_point_setting_list') ?
                <div className="block-body">
              <form className="form-inline mb-3">
                <div className="form-group mr-3">
                  <label htmlFor="startTime" className="sr-only">Start Time</label>
                  <input type="search" id="startTime" className='form-control form-control-sm'
                         placeholder="Start Time"/>
                </div>
                <div className="form-group mr-3">
                  <label htmlFor="endTime" className="sr-only">End Time</label>
                  <input type="search" id="endTime" className='form-control form-control-sm' placeholder="End Time"/>
                </div>

                <div className="form-group mr-3">
                  <label htmlFor="barndName" className="sr-only">Promotion Type</label>
                  <select type="search" id="status" className='form-control form-control-sm'>
                    <option value="1">Quentity</option>
                    <option value="2">Buy X Get Y</option>
                    <option value="3">Free Gift</option>
                  </select>
                </div>

                <div className="form-group mr-3">
                  <label htmlFor="barndName" className="sr-only">Status</label>
                  <select type="search" id="status" className='form-control form-control-sm'>
                    <option value="1">Active</option>
                    <option value="2">Blocked</option>
                    <option value="3">New Registered</option>
                  </select>
                </div>

                <Button size="sm" variant="secondary">Search</Button>
              </form>

              <table className="table table-striped">
                <thead>
                <tr>
                  <th style={{width: '1%'}}>#</th>
                  <th>Promotion Name</th>
                  <th>Promotion Type</th>
                  <th>Duration</th>
                  <th>Level</th>
                  <th style={{width: '120px'}}/>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>1</td>
                  <td>10% Descount</td>
                  <td>Quantity</td>
                  <td>
                    <span className="start-date">02/03/2020</span><br/>
                    <span className="end-date">30/04/2020</span>
                  </td>
                  <td>Regular Channel</td>
                  <td>

                    {getLocalAuthData()?.permission?.includes('reward_point_setting_edit') ?
                        <button className="btn-action btn-block btn-outline-primary">Edit</button> : null}
                    {getLocalAuthData()?.permission?.includes('reward_point_setting_block_unblock') ?
                    <button className="btn-action btn-block btn-outline-warning">Block</button> : null}
                    {getLocalAuthData()?.permission?.includes('reward_point_setting_delete') ?
                    <button className="btn-action btn-block btn-outline-danger">Delete</button> : null}
                  </td>
                </tr>

                </tbody>
              </table>

              <div className="mt-3">
                <Pagination>
                  <Pagination.First/>
                  <Pagination.Prev/>
                  <Pagination.Item>{1}</Pagination.Item>
                  <Pagination.Ellipsis/>
                  <Pagination.Item>{10}</Pagination.Item>
                  <Pagination.Item>{11}</Pagination.Item>
                  <Pagination.Item active disabled>{12}</Pagination.Item>
                  <Pagination.Item>{13}</Pagination.Item>
                  <Pagination.Item>{14}</Pagination.Item>
                  <Pagination.Ellipsis/>
                  <Pagination.Item>{20}</Pagination.Item>
                  <Pagination.Next/>
                  <Pagination.Last/>
                </Pagination>
              </div>
            </div> : null}

          </div>
        </div>
      </App>
    );
  }
}

export default RewardPointSettingList;
