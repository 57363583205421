import React, {useEffect} from 'react';
import {Redirect, Route} from "react-router-dom";
import {userIsLoggedin} from "../authUtils";
import {getLocalAuthData} from "../../helper/utils";

function PrivateRoute({children, ...rest}) {
  const isAuthenticated = userIsLoggedin();

  return (
    <Route
      {...rest}
      render={({location}) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {from: location}
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
