import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import BrowseFiles from "../../components/BrowseFiles";
import axios from "axios";
import config from "../../helper/env";
import {ToastsContainer, ToastsStore} from "react-toasts";
import {toast} from "react-toastify";
import {getDeviceType, getLocalAuthData, headerData} from "../../helper/utils";
import {Link} from "react-router-dom";
import {FiEdit, FiTrash} from "react-icons/all";
import {confirmAlert} from "react-confirm-alert";
import {toggleStatusUpdate} from "../../services/bankServices";

class Actions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            items: [],
            editFormData: null,
            editStatus: false
        }
    }

    componentDidMount() {
        if (getLocalAuthData()?.permission?.includes('acl_action_view')){
            axios.get(`${config.API_BASE_URL}/acl-action`, headerData)
                .then(res => {
                    console.log(res.data.items);
                    this.setState({items: res.data.items});
                }).catch(e => this.setState({error: true}));
        }

    }

    renderData = () => {
        axios.get(`${config.API_BASE_URL}/acl-action`, headerData)
            .then(res => {
                console.log(res.data.items);
                this.setState({items: res.data.items});
            }).catch(e => this.setState({error: true}));
    }

    handleImage = (files) => {
        this.setState({
            formData: {
                ...this.state.formData,
                image: files[0].thumb_img
            }
        });
    }

    handleFormDataChange = (e) => {
        this.setState({formData: {...this.state.formData, [e.target.name]: e.target.value}});
        setTimeout(() => {
            console.log(this.state.formData);
        }, 3000);
    };

    deleteBank = (id) => {
        axios.delete(`${config.API_BASE_URL}/acl-action/${id}/permanent/`, headerData) //Permanent delete
            .then(response => {
                if (response.data.status === 1) {
                    ToastsStore.success(response.data.message);
                    this.renderData();
                }
                if (response.data.status === 2) {
                    ToastsStore.error(response.data.message);
                }
            }).catch(error => {
            ToastsStore.error("Error");
        });
    };

    edit = (id) => {
        axios.get(`${config.API_BASE_URL}/acl-action/${id}`, headerData).then(res => {
            this.setState({
                selected_id: res.data.aclAction._id,
                formData: {
                    ...this.state.formData,
                    label: res.data.aclAction.label,
                    tag: res.data.aclAction.tag,
                    type: res.data.aclAction.type,
                },
                editStatus: true
            });
            window.scrollTo({top: 0, behavior: 'smooth'});
        }).catch(error => {
            ToastsStore.error("Error");
        });
    };

    renderBank = () => {
        const items = this.state.items;
        if (items) {
            return (
                <Row>
                    {items.map((item, index) => {
                            return (
                                <Col md={3}>
                                    {/*<Bank
                                        imageLink={bank.image} bankId={bank._id}
                                        deleteBank={() => this.deleteBank(bank._id)}
                                        edit={() => this.edit(bank._id)}
                                    />*/}
                                </Col>
                            )
                        }
                    )}
                </Row>
            )
        }

        return (
            <Form.Control as="select">
                <option>Fetching Bank</option>
            </Form.Control>
        )
    };

    handleToggleStatusUpdate = (id, status, index) => {
        confirmAlert({
            title: 'Status Update',
            message: 'Are you sure to change this status?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => toggleStatusUpdate(id, {status: status}).then(res => {

                        // Update row data
                        const items = [...this.state.items]
                        items[index].status = status
                        this.setState({items})
                        toast.success(res.message)

                    }).catch(errMsg => {
                        toast.error(errMsg)
                    })
                }, {
                    label: 'No',
                }
            ]
        });
    }

    handleSubmitForm = (e) => {
        e.preventDefault();
        const formData = {...this.state.formData};
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({isFormValidated: true})
        } else {
            let url = `${config.API_BASE_URL}/acl-action`;
            if (this.state.editStatus === true) {
                url = `${config.API_BASE_URL}/acl-action/` + this.state.selected_id;
                axios.put(url, formData, headerData).then(response => {
                    if (response.data.status === 1) {
                        toast.success(response.data.message);
                        this.renderData();
                    }
                    if (response.data.status === 2) {
                        toast.error(response.data.message);
                    }
                }).catch(error => {
                    toast.error("Something went wrong please try again after refresh the page.");
                });
            } else {
                axios.post(url, formData, headerData).then(response => {
                    if (response.data.status === 1) {
                        toast.success(response.data.message);
                        this.renderData();
                    }
                    if (response.data.status === 2) {
                        toast.error(response.data.message);
                    }
                }).catch(error => {
                    toast.error("Something went wrong please try again after refresh the page.");
                });
            }
        }
    };

    render() {
        return (
            <App layout={"boxed"}>
                <div className="page-header">
                    <h2 className="page-title">Acl Action Management</h2>
                </div>

                <div className="main-content">
                    <div className="card-block">
                        <div className="block-body">
                           <>
                               {getLocalAuthData()?.permission?.includes('acl_action_add') ?
                                       <Form id={"bankForm"} onSubmit={this.handleSubmitForm}>
                                           <Form.Group as={Row} controlId="aclLabel">
                                               <Form.Label column sm="3">
                                                   Label <span className="text-danger">*</span>
                                               </Form.Label>
                                               <Col sm="9" md={8} lg={7}>
                                                   <Form.Control type={"text"} required
                                                                 defaultValue={this.state.formData ? this.state.formData.label : ""}
                                                                 name="label" onChange={this.handleFormDataChange}
                                                                 placeholder={"Label"}/>
                                               </Col>
                                           </Form.Group>

                                           <Form.Group as={Row} controlId="aclTag">
                                               <Form.Label column sm="3">
                                                   Tag <span className="text-danger">*</span>
                                               </Form.Label>
                                               <Col sm="9" md={8} lg={7}>
                                                   <Form.Control type={"text"} required
                                                                 defaultValue={this.state.formData ? this.state.formData.tag : ""}
                                                                 name="tag" onChange={this.handleFormDataChange}
                                                                 placeholder={"Tag"}/>
                                               </Col>
                                           </Form.Group>

                                           <Form.Group as={Row} controlId="aclType">
                                               <Form.Label column sm="3">
                                                   Type <span className="text-danger">*</span>
                                               </Form.Label>
                                               <Col sm="9" md={8} lg={7}>
                                                   <Form.Control type={"text"} required
                                                                 defaultValue={this.state.formData ? this.state.formData.type : ""}
                                                                 name="type" onChange={this.handleFormDataChange}
                                                                 placeholder={"Type"}/>
                                               </Col>
                                           </Form.Group>


                                           <Form.Group as={Row} controlId="aclFormSubmit">
                                               <Form.Label column sm="3"/>
                                               <Col sm="9" md={8} lg={7}>
                                                   <Button type={'submit'} variant={"primary"}>{(this.state.editStatus === true) ? 'Update' : 'Save'}</Button>
                                               </Col>
                                           </Form.Group>
                                       </Form>
                                       :
                                   <h5>Permission Denied!</h5>
                               }
                           </>

                        </div>
                    </div>

                    <div className="card-block">

                        <div className="block-header">
                            <h4 className="block-title">Acl Action List</h4>
                        </div>

                        <div className="block-body">
                            {/*{this.renderBank()}*/}
                            {this.state.isLoading ?
                                <>Loading...</>
                                :
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th style={{width: '20px'}}>#</th>
                                        <th>Label</th>
                                        <th>Tag</th>
                                        <th>Type</th>
                                        <th style={{width: `1%`}}/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.items?.length > 0 ?
                                        this.state.items.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.label}</td>
                                                <td>{item.tag}</td>
                                                <td>{item.type}</td>
                                                <td className="nowrap text-center">
                                                    {getLocalAuthData()?.permission?.includes('acl_action_edit') ?
                                                        <span className="btn btn-sm btn-outline-info mr-3" onClick={() => this.edit(item._id)}><FiEdit/> </span>
                                                        : null}
                                                    {getLocalAuthData()?.permission?.includes('acl_action_delete') ?
                                                    <span className="btn btn-sm btn-outline-danger mr-3" onClick={() => this.deleteBank(item._id)}><FiTrash/> Delete</span>
                                                        :null}
                                                </td>
                                            </tr>
                                        )) :
                                        <tr>
                                            <td colSpan={7} className={`text-bold text-center`}>No item found</td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                            }
                        </div>

                    </div>

                </div>
            </App>
        );
    }
}

export default Actions;
