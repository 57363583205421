import React, {Component} from 'react';
import App from "../../App";
import {FaLongArrowAltLeft} from "react-icons/all";
import {Link} from "react-router-dom";
import {Button, Col, Form, Row} from "react-bootstrap";
import "../../assets/scss/add-product.scss";
import axios from "axios";
import config from "../../helper/env";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import styleReactSelect from "../../assets/styleReactSelect";
import {toast} from "react-toastify";
import {getLocalAuthData} from "../../helper/utils";
import {activityLogPostData} from "../../services/commonServices";

class Edit extends Component {
  state = {
    formData: {},
    attributeValues: [],
    attributeInfo: null,
    showForm: false,
    isSubmitting: false
  }

  componentDidMount() {
    axios.get(`${config.API_BASE_URL}/attribute/${this.props.match.params.id}`)
      .then(res => {
        let attributes = [];
        if (res.data.attribute.values) {
          res.data.attribute.values.forEach(function (entry) {
            attributes.push({label: entry, value: entry, __isNew__: true});
          });
        }
        this.setState({
          attributeInfo: res.data.attribute,
          attributes: attributes,
          attributeValues: res.data.attribute.values,
          showForm: true,
          formData: {
            name: res.data.attribute.name,
            label: res.data.attribute.label,
            type: res.data.attribute.type,
            info: res.data.attribute.info,
          },
        });
      }).catch(e => {
      this.setState({showForm: true});
    });
  }

  handleFormDataChange = (e) => {
    this.setState({formData: {...this.state.formData, [e.target.name]: e.target.value}});
  };

  handleSubmitForm = (e) => {
    e.preventDefault();
    this.setState({
      formData: {
        ...this.state.formData,
        values: this.state.attributeValues,
        id: this.state.attributeInfo._id,
      },
      isSubmitting: true
    }, () => {
      axios.put(`${config.API_BASE_URL}/updateAttribute`, this.state.formData)
        .then(response => {
          if (response.data.status === 1) {
            this.setState({isSubmitting: false})
            toast.success(response.data.message);
            activityLogPostData('was edit this variant','/variant/edit/'+this.state.attributeInfo._id,this.state.formData.name)
          }
          if (response.data.status === 2) {
            toast.error(response.data.message);
          }
        }).catch(error => {
        toast.error("Something went wrong!");
      });
    });
  };

  handleAttrOnChange = (newValue) => {
    console.log(newValue);
    let attributes = [];
    if (newValue) {
      newValue.forEach((val) => {
        attributes.push(val.value);
      });
    }
    this.setState({
      attributeValues: attributes
    });
  }

  renderType = () => {
    let fieldType = [
      {name: "select", value: "Select"},
      {name: "multiselect", value: "Multiselect"},
      {name: "checkbox", value: "Checkbox"},
      {name: "radio", value: "Radio"},
      {name: "number", value: "Number"},
      {name: "text", value: "Text"},
      {name: "textarea", value: "Textarea"},
    ];

    if (this.state.attributeInfo) {
      return (
        <Form.Control
          as="select"
          name="type"
          onChange={this.handleFormDataChange}
        >
          <option defaultValue="">--Select Type--</option>
          {fieldType.map((type, index) => {
              let selected = false;
              if (this.state.attributeInfo.type === type.name) {
                selected = true;
              }
              return (
                <option key={index} defaultValue={type.name} selected={selected}>{type.value}</option>
              );
            }
          )}
        </Form.Control>
      )
    }
  };

  render() {
    return (
      <App layout="boxed">

        <div className="page-header">
          <h2 className="page-title">Variant Details</h2>
          <div className="ml-auto">
            <Link to={`${process.env.PUBLIC_URL}/variants/list`}
                  className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Variant
              List</Link>
          </div>
        </div>

        {(getLocalAuthData()?.permission?.includes('varient_edit') && this.state.showForm) &&
        <div className="main-content">
          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Define a Variant</h4>
            </div>

            <div className="block-body">
              <Form onSubmit={this.handleSubmitForm}>
                <Form.Group as={Row} controlId="formFieldName">
                  <Form.Label column sm="3">
                    Field Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control type="text" placeholder=""
                                  defaultValue={this.state.attributeInfo ? this.state.attributeInfo.name : ""}
                                  name="name"
                                  onChange={this.handleFormDataChange}/>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formFieldLabel">
                  <Form.Label column sm="3">
                    Field Label <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control type="text" placeholder=""
                                  defaultValue={this.state.attributeInfo ? this.state.attributeInfo.label : ""}
                                  name="label"
                                  onChange={this.handleFormDataChange}/>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formFieldType">
                  <Form.Label column sm="3"> Field Type </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    {this.renderType()}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formRequire">
                  <Form.Label column sm="3">
                    Values <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <CreatableSelect
                      defaultValue={this.state.attributes}
                      isMulti
                      styles={styleReactSelect}
                      placeholder="Please type your values..."
                      onChange={(newValue) => {
                        this.handleAttrOnChange(newValue);
                      }}
                      noOptionsMessage={() => 'Please type your values...'}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formInfo">
                  <Form.Label column sm="3">
                    Info <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control type="text" placeholder=""
                                  defaultValue={this.state.attributeInfo ? this.state.attributeInfo.info : ""}
                                  name="info"
                                  onChange={this.handleFormDataChange}/>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm="3"/>
                  <Col sm="9" md={8} lg={7}>
                    <Button size="lg"
                            type={`submit`}
                            variant="primary"
                            disabled={this.state.isSubmitting}>{this.state.isSubmitting ? 'Updating...' : 'Update'}</Button>
                  </Col>
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>}
      </App>
    );
  }
}

export default Edit;
