import React, {useEffect, useState} from "react";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {IoMdImages, FiUpload} from "react-icons/all";
import "../assets/scss/browse-files.scss";
import {connect} from "react-redux";
import {getFileList, handleFileUpload} from "../redux";
import FileItem from "./FileItem";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import styleReactSelect from "../assets/styleReactSelect";
import {getLocalAuthData} from "../helper/utils";
// import {getLocalAuthData} from "../helper/utils";

const BrowseFiles = ({
                         helpText,
                         handleFileUpload,
                         onGetFiles, filesData,
                         getFileList,
                         size,
                         images,
                         onRemoveItem,
                         isSingle,
                         userId,
                         image_category,
                         image_size_templates,
                         selected_image_size_template,
                         custom_width, custom_height
                     }) => {
    const [browseModalShow, setBrowseModalShow] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [browsedImage, setBrowsedImage] = useState([]);
    const [uploadedFile, setUploadedFile] = useState(null);

    const handleBrowseModalHide = () => {
        setSelectedFiles([])
        setBrowseModalShow(false)
    }

    const handleBrowseModalShow = () => {
        setBrowseModalShow(true)
        setUploadedFile(null)
    }

    useEffect(() => {
        const getFileListParams = {
            image_category: image_category ? image_category : "product", /*limit: 20000*/
        }
        getFileList(getFileListParams);
    }, []);


    const handleOnSelected = (e) => {

        console.log("handleOnSelected called")

        let copySelectedFiles = [...selectedFiles];
        const targetedValue = e.target.value;

        console.log(selectedFiles)
        const newSelectedFiles = selectedFiles.filter(path => {
            return e.target.value === path
        })

        if (e.target.checked) {
            if (e.target.type === 'checkbox') {
                if (!newSelectedFiles.length > 0) {
                    copySelectedFiles.push(targetedValue)
                    setSelectedFiles(copySelectedFiles)
                }
            } else {
                copySelectedFiles[0] = targetedValue
                setSelectedFiles(copySelectedFiles)
            }
        } else {
            const index = copySelectedFiles.indexOf(newSelectedFiles[0]);
            copySelectedFiles.splice(index, 1)
            setSelectedFiles(copySelectedFiles);
        }
    }

    const handleGetFiles = (files) => {



        let images = [];
        const selectedFiles = filesData.files.filter(obj => {
            return files.includes(obj._id)
        });
        selectedFiles.forEach(item => {
            images.push({
                actual_img: item.actual_img_path,
                mid_img: item.mid_img_path,
                thumb_img: item.thumb_img_path
            })
        })
        onGetFiles(images);
        setSelectedFiles([])
        setBrowseModalShow(false);
    }

    const setImageSizeTemplate = (value, name) => {
        selected_image_size_template = value.value;
    }

    const handleOnUploadOnChange = (e) => {

        console.log("handleOnUploadOnChange running")
        console.log(e.target.files.length)

        for ( var i =0; i<e.target.files.length; i++){

            const imageUrl = URL.createObjectURL(e.target.files[i])
            const name = e.target.files[i].name
            /* setBrowsedImage({
                 imageUrl: imageUrl,
                 imageName: e.target.files[i].name
             });*/

            setBrowsedImage(browsedImage => [...browsedImage , {
                imageUrl: imageUrl,
                imageName: name
            }])

            const data = {
                image: e.target.files[i],
                image_category: image_category ? image_category : "product",
                image_size_template: selected_image_size_template ? selected_image_size_template : "default",
                user_id: userId || ''
            }
            if (custom_width) {
                data.resize_width = custom_width
            }
            if (custom_height) {
                data.resize_height = custom_height
            }

            handleFileUpload(data, (id) => {

                const getFileListParams = {
                    image_category: image_category ? image_category : "product"
                }

                fileSelect(id)
                setUploadedFile(id)
                getFileList(getFileListParams)
                setBrowsedImage([])
            })

        }



    }

    const fileSelect = (id) => {
        let copySelectedFiles = [...selectedFiles]
        copySelectedFiles.push(id)
        setSelectedFiles(copySelectedFiles)
    }

    return (
        <>
            <Modal size={"lg"} show={browseModalShow} onHide={handleBrowseModalHide}>
                <Modal.Header closeButton>
                    <Modal.Title>File Manager</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="header-part">
                        <span>Supported images: png, gif, jpg, jpeg</span>

                        {image_size_templates ?
                            <>
                                <Col sm="4" md={4} lg={4}>
                                    <CreatableSelect
                                        defaultValue={image_size_templates.filter((obj) => {
                                            return obj.value === selected_image_size_template;
                                        })[0]}
                                        styles={styleReactSelect}
                                        onChange={(value) => setImageSizeTemplate(value, 'image_size_template')}
                                        // onChange={(value)=>setImageSizeTemplate}
                                        placeholder="Select select image size template..."
                                        options={image_size_templates}
                                        noOptionsMessage={() => 'Please select image size template'}
                                    />
                                </Col>
                            </> : ''
                        }
                    </div>

                    <div className="header-part">
                        <span className="text-bold">
                          {images?.length}
                            {images?.length > 1 ? ' Files' : ' File'} selected
                        </span>

                        <label htmlFor="fileUpload" className="btn-file-upload">

                            <input type="file"
                                   multiple
                                   onChange={handleOnUploadOnChange}
                                   accept="image/x-png,image/gif,image/jpeg"
                                   id="fileUpload"/>
                            <span className="icon"><FiUpload/></span>
                            <span className="text">Upload File</span>
                        </label>
                    </div>


                    {browsedImage &&
                    browsedImage.map((data , index)=>{
                        return(
                            <div key={index} className="image-uploading-progress-bar-list">
                                <div className="image-uploading-progress-bar">
                                    <div className="image" style={{backgroundImage: `url(${data.imageUrl})`}}/>
                                    <div className="image-info">
                                        <div className="info-header">
                                            <span className="name">{data.imageName}</span>
                                            <span className="percentage">{filesData.uploadComplete}%</span>
                                        </div>
                                        <div className="uploading-progress-bar">
                                    <span className="progress-status-bar"
                                          style={{width: `${filesData.uploadComplete}%`}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    }

                    {filesData.files.length > 0 ?
                        <div className="file-list">
                            {filesData.files.map((file, index) => {

                                return (
                                    <FileItem checked={uploadedFile} key={index} data={file}
                                              isSingle={isSingle ? isSingle : false}
                                              onChange={handleOnSelected}/>
                                )
                            })}
                        </div>
                        :
                        <div>
                            No file found
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={handleBrowseModalHide}>
                        Close
                    </Button>
                    <Button variant="primary"
                            disabled={!selectedFiles.length > 0}
                            onClick={() => handleGetFiles(selectedFiles)}>
                        Add {selectedFiles.length > 1 ? 'Images' : 'Image'}
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="fm-actions">
                <button onClick={handleBrowseModalShow} type="button"
                        className={size ? `btn-browse-image btn-browse-image-${size}` : `btn-browse-image`}>
                    <span className="icon">
                        <IoMdImages/>
                    </span>
                    <span className="text">Browse Image</span>
                </button>

                {images &&
                <>
                    {images.length > 0 && <span
                        className={"ml-2"}>{`${images.length} ${images.length > 1 ? ' images' : ' image'}`} selected</span>}

                    {images.length > 0 &&
                    <div className="selected-img-list">
                        {images.map((image, index) => (
                            <>
                                <div key={index} className={size ? `selected-image selected-image-${size}` : `selected-image`}
                                     style={{backgroundImage: `url(${image.thumb_img})`}}>
                                    {onRemoveItem &&
                                    <span onClick={() => onRemoveItem(index)} className="btn-remove-selected-file">&times;</span>}
                                </div>
                            </>
                        ))}
                    </div>}
                </>}
            </div>

            {helpText && <span className="text-help text-muted">{helpText}</span>}
        </>
    )
}

const mapStateToProps = state => {
    return {
        filesData: state.fileManager
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getFileList: (params) => dispatch(getFileList(params)),
        handleFileUpload: (data, cb) => dispatch(handleFileUpload(data, cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrowseFiles);


// import React, {useEffect, useState} from "react";
// import {Button, Col, Form, Modal, Row} from "react-bootstrap";
// import {IoMdImages, FiUpload} from "react-icons/all";
// import "../assets/scss/browse-files.scss";
// import {connect} from "react-redux";
// import {getFileList, handleFileUpload} from "../redux";
// import FileItem from "./FileItem";
// import CreatableSelect from "react-select/creatable/dist/react-select.esm";
// import styleReactSelect from "../assets/styleReactSelect";
//
// const BrowseFiles = ({
//                          helpText,
//                          handleFileUpload,
//                          onGetFiles, filesData,
//                          getFileList,
//                          size,
//                          images,
//                          onRemoveItem,
//                          isSingle,
//                          userId,
//                          image_category,
//                          image_size_templates,
//                          selected_image_size_template,
//                          custom_width, custom_height
//                      }) => {
//     const [browseModalShow, setBrowseModalShow] = useState(false);
//     const [selectedFiles, setSelectedFiles] = useState([]);
//     const [browsedImage, setBrowsedImage] = useState([]);
//     const [uploadedFile, setUploadedFile] = useState(null);
//
//     const handleBrowseModalHide = () => {
//         setSelectedFiles([])
//         setBrowseModalShow(false)
//     }
//
//     const handleBrowseModalShow = () => {
//         setBrowseModalShow(true)
//         setUploadedFile(null)
//     }
//
//     useEffect(() => {
//         const getFileListParams = {
//             image_category: image_category ? image_category : "product"
//         }
//         getFileList(getFileListParams);
//     }, []);
//
//     const handleOnSelected = (e) => {
//         let copySelectedFiles = [...selectedFiles];
//         const targetedValue = e.target.value;
//         const newSelectedFiles = selectedFiles.filter(path => {
//             return e.target.value === path
//         })
//
//         if (e.target.checked) {
//             if (e.target.type === 'checkbox') {
//                 if (!newSelectedFiles.length > 0) {
//                     copySelectedFiles.push(targetedValue)
//                     setSelectedFiles(copySelectedFiles)
//                 }
//             } else {
//                 copySelectedFiles[0] = targetedValue
//                 setSelectedFiles(copySelectedFiles)
//             }
//         } else {
//             const index = copySelectedFiles.indexOf(newSelectedFiles[0]);
//             copySelectedFiles.splice(index, 1)
//             setSelectedFiles(copySelectedFiles);
//         }
//     }
//
//     const handleGetFiles = async (files) => {
//         let images = [];
//         const selectedFiles = await filesData.files.filter(obj => {
//             return files.includes(obj._id)
//         });
//         await selectedFiles.forEach(item => {
//             images.push({
//                 actual_img: item.actual_img_path,
//                 mid_img: item.mid_img_path,
//                 thumb_img: item.thumb_img_path
//             })
//         })
//         onGetFiles(images);
//         setSelectedFiles([])
//         setBrowseModalShow(false);
//     }
//
//     const setImageSizeTemplate = (value, name) => {
//         selected_image_size_template = value.value;
//     }
//
//     const handleOnUploadOnChange = (e) => {
//
//
//         for (var i = 0; i < e.target.files.length; i++) {
//             const imageUrl = URL.createObjectURL(e.target.files[i])
//             const name = e.target.files[i].name
//                 /* setBrowsedImage({
//               imageUrl: imageUrl,
//               imageName: e.target.files[i].name
//           });*/
//
//             setBrowsedImage(browsedImage => [...browsedImage , {
//                 imageUrl: imageUrl,
//                 imageName: name
//             }])
//
//             const data = {
//                 image: e.target.files[i],
//                 image_category: image_category ? image_category : "product",
//                 image_size_template: selected_image_size_template ? selected_image_size_template : "default",
//                 user_id: userId || ''
//             }
//             if (custom_width) {
//                 data.resize_width = custom_width
//             }
//             if (custom_height) {
//                 data.resize_height = custom_height
//             }
//
//             handleFileUpload(data, (id) => {
//                 const getFileListParams = {
//                     image_category: image_category ? image_category : "product"
//                 }
//                 fileSelect(id)
//                 setUploadedFile(id)
//                 getFileList(getFileListParams)
//                 setBrowsedImage([])
//             })
//
//         }
//
//
//     }
//
//     const fileSelect = (id) => {
//         let copySelectedFiles = [...selectedFiles]
//         copySelectedFiles.push(id)
//         setSelectedFiles(id)
//     }
//
//     return (
//         <>
//             <Modal size={"lg"} show={browseModalShow} onHide={handleBrowseModalHide}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>File Manager</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <div className="header-part">
//                         <span>Supported images: png, gif, jpg, jpeg</span>
//
//                         {image_size_templates ?
//                             <>
//                                 <Col sm="4" md={4} lg={4}>
//                                     <CreatableSelect
//                                         defaultValue={image_size_templates.filter((obj) => {
//                                             return obj.value == selected_image_size_template;
//                                         })[0]}
//                                         styles={styleReactSelect}
//                                         onChange={(value) => setImageSizeTemplate(value, 'image_size_template')}
//                                         // onChange={(value)=>setImageSizeTemplate}
//                                         placeholder="Select select image size template..."
//                                         options={image_size_templates}
//                                         noOptionsMessage={() => 'Please select image size template'}
//                                     />
//                                 </Col>
//                             </> : ''
//                         }
//                     </div>
//
//                     <div className="header-part">
//                         <span className="text-bold">
//                           {images?.length}
//                             {images?.length > 1 ? ' Files' : ' File'} selected
//                         </span>
//
//                         <label htmlFor="fileUpload" className="btn-file-upload">
//
//                             <input type="file"
//                                    multiple
//                                    onChange={handleOnUploadOnChange}
//                                    accept="image/x-png,image/gif,image/jpeg"
//                                    id="fileUpload"/>
//                             <span className="icon"><FiUpload/></span>
//                             <span className="text">Upload File</span>
//                         </label>
//                     </div>
//
//                     {/*{browsedImage &&
//                     <div className="image-uploading-progress-bar-list">
//                         <div className="image-uploading-progress-bar">
//                             <div className="image" style={{backgroundImage: `url(${browsedImage.imageUrl})`}}/>
//                             <div className="image-info">
//                                 <div className="info-header">
//                                     <span className="name">{browsedImage.imageName}</span>
//                                     <span className="percentage">{filesData.uploadComplete}%</span>
//                                 </div>
//                                 <div className="uploading-progress-bar">
//                                     <span className="progress-status-bar"
//                                           style={{width: `${filesData.uploadComplete}%`}}/>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     }*/}
//
//                     {browsedImage &&
//                     browsedImage.map((data , index)=>{
//                         return(
//                             <div key={index} className="image-uploading-progress-bar-list">
//                                 <div className="image-uploading-progress-bar">
//                                     <div className="image" style={{backgroundImage: `url(${data.imageUrl})`}}/>
//                                     <div className="image-info">
//                                         <div className="info-header">
//                                             <span className="name">{data.imageName}</span>
//                                             <span className="percentage">{filesData.uploadComplete}%</span>
//                                         </div>
//                                         <div className="uploading-progress-bar">
//                                     <span className="progress-status-bar"
//                                           style={{width: `${filesData.uploadComplete}%`}}/>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         )
//                     })
//                     }
//
//                     {filesData.files.length > 0 ?
//                         <div className="file-list">
//                             {filesData.files.map((file, index) => {
//
//                                 return (
//                                     <FileItem checked={uploadedFile} key={index} data={file}
//                                               isSingle={isSingle ? isSingle : false}
//                                               onChange={handleOnSelected}/>
//                                 )
//                             })}
//                         </div>
//                         :
//                         <div>
//                             No file found
//                         </div>
//                     }
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="default" onClick={handleBrowseModalHide}>
//                         Close
//                     </Button>
//                     <Button variant="primary"
//                             disabled={!selectedFiles.length > 0}
//                             onClick={() => handleGetFiles(selectedFiles)}>
//                         Add {selectedFiles.length > 1 ? 'Images' : 'Image'}
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//
//             <div className="fm-actions">
//                 <button onClick={handleBrowseModalShow} type="button"
//                         className={size ? `btn-browse-image btn-browse-image-${size}` : `btn-browse-image`}>
//                     <span className="icon">
//                         <IoMdImages/>
//                     </span>
//                     <span className="text">Browse Image</span>
//                 </button>
//
//                 {images &&
//                 <>
//                     {images.length > 0 && <span
//                         className={"ml-2"}>{`${images.length} ${images.length > 1 ? ' images' : ' image'}`} selected</span>}
//
//                     {images.length > 0 &&
//                     <div className="selected-img-list">
//                         {images.map((image, index) => (
//                             <div key={index}
//                                  className={size ? `selected-image selected-image-${size}` : `selected-image`}
//                                  style={{backgroundImage: `url(${image.thumb_img})`}}>
//                                 <span onClick={() => onRemoveItem(index)}
//                                       className="btn-remove-selected-file">&times;</span>
//                             </div>
//                         ))}
//                     </div>}
//                 </>}
//             </div>
//
//             {helpText && <span className="text-help text-muted">{helpText}</span>}
//         </>
//     )
// }
//
// const mapStateToProps = state => {
//     return {
//         filesData: state.fileManager
//     }
// }
//
// const mapDispatchToProps = dispatch => {
//     return {
//         getFileList: (params) => dispatch(getFileList(params)),
//         handleFileUpload: (data, cb) => dispatch(handleFileUpload(data, cb))
//     }
// }
//
// export default connect(mapStateToProps, mapDispatchToProps)(BrowseFiles);
