/*
* Localhost */
// export default {
//   API_BASE_URL: "http://localhost:3002/api",
// }
// export const SHOP_BASE_URL = "https://ekshopbd-dev.web.app"
// export const API_BASE_URL = "http://localhost:3002/api";
// export const EP_INTEGRATION_BASE_URL = "https://parallaxlogic.dev/ekshop-merchant/api";
// export const SOCKET = 'http://localhost:4001';

/*
* Development */
// export default {
//   API_BASE_URL: "https://parallaxlogic.dev/ekshop-admin/api",
// }
// export const SHOP_BASE_URL = "https://ekshop.parallaxlogic.dev"
// export const API_BASE_URL = "https://parallaxlogic.dev/ekshop-admin/api";
// export const EP_INTEGRATION_BASE_URL = "https://parallaxlogic.dev/ekshop-merchant/api";
// export const SOCKET = 'https://socket.parallaxlogic.dev/';

/*
* Staging */
// export default {
//   API_BASE_URL: "https://parallaxlogic.dev/ekshop-sandbox-admin/api",
// }
// export const SHOP_BASE_URL = "https://ekshop-sandbox.parallaxlogic.dev"
// export const API_BASE_URL = "https://parallaxlogic.dev/ekshop-sandbox-admin/api";
// export const EP_INTEGRATION_BASE_URL = "https://parallaxlogic.dev/ekshop-sandbox-merchant/api";
// export const SOCKET = 'https://socket.parallaxlogic.dev/';


/*
* Live (Root) */
export default {
  API_BASE_URL: "https://api-admin.ekshop.gov.bd/api",
}
export const SHOP_BASE_URL = "https://ekshop.gov.bd"
export const API_BASE_URL = "https://api-admin.ekshop.gov.bd/api";
// export const API_BASE_URL = "https://api.ekshop.gov.bd:3002/api";
export const EP_INTEGRATION_BASE_URL = "https://api.ekshop.gov.bd:3004/api";
export const SOCKET = 'https://api.ekshop.gov.bd:4001/';



/*
* GOTIPATH Live (Root) */
// export default {
//   API_BASE_URL: "https://api.ekshop.gov.bd:3002/api",
// }
// export const SHOP_BASE_URL = "https://ekshop.gov.bd"
// export const API_BASE_URL = "https://api.ekshop.gov.bd:3002/api";
// export const EP_INTEGRATION_BASE_URL = "https://api.ekshop.gov.bd:3003/api";
// export const SOCKET = 'https://socket.ekshop.gov.bd';


export const OTP_RESEND_TIME = 60
