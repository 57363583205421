import moment from "moment";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import App from "../../App";
import { getLocalAuthData } from "../../helper/utils";
import { getTableData } from "../../services/commonServices";

class BuyerCommissionOrder extends Component {
  state = {
    orderList: [],
    isOrderListLoading: false,
  };
  componentDidMount() {
    const { id } = this.props.match.params;
    console.log("Testing id", id);
    const {merchant} = this.props.location.search;
    this.getOrderList(id);
  }

  getOrderList(id) {
    this.setState({ isOrderListLoading: true });
    getTableData(`/order-parcel`, {merchant: id})
      .then((res) => {
          console.log("order-parcel", res.items);
        this.setState({ isOrderListLoading: false, orderList: res.items });
      })
      .catch((err) => {
        this.setState({ isOrderListLoading: false });
        toast.error(err.message);
      });
  }

  render() {
    return (
      <App layout="">
        <div className="main-content">
          {this.state.isOrderListLoading ? (
            <div className="loading-wrapper">
                <p>Loading...</p>
            </div>
          ) : (
            <>
              {this.state.orderList?.length <= 0 ? (<div className="loading-wrapper">
              <p>No orders available</p>
            </div>
                
              ) : (
                <div className="card-block">
                  <div className="block-header">
                    <h4 className="block-title">
                      Buyer Commission Order Details
                    </h4>
                  </div>
                  <div className="block-body">
                    <div className="mt-4">
                      <table className="order-table table table-striped">
                        <thead>
                          <tr>
                            <th style={{ width: "1%" }}>Sl</th>
                            <th>Order Info</th>
                            <th>Amount</th>
                            <th>Buyer Commission</th>
                            <th>Status</th>
                            <th>View</th>
                          </tr>
                        </thead>

                        <tbody>
                            {
                                this.state.orderList.map((item, index) => {
                                   return <tr>
                            <td>#{index}</td>
                            <td>
                                <div className="order-item">
                                    <h4 className="order-id">
                                        <Link
                                            to={`/orders/parcel/${item?.order_parcels._id}`}
                                            target={'_blank'}>{item?.order_parcels?.order_parcel_code}</Link>
                                    </h4>
                                    <span
                                        className="order-date">{moment(item.createdAt).format(`ll`)}</span>
                                    <span
                                        className="order-time">{moment(item.createdAt).format(`LT`)}</span>
                                </div>
                            </td>
                            <td>{item?.order_parcels?.parcel_grand_total || 0}</td>
                            
                            <td>{item?.order_parcels?.merchant_commission || 0}</td>

                            <td>
                                <span>{item?.order_parcels?.status}</span>
                            </td>
                            <td>
                            {getLocalAuthData()?.permission?.includes('order_parcel_list_item_view') ?
                                                      <Link to={`${process.env.PUBLIC_URL}/orders/parcel/${item.order_parcels._id}`}
                                                            className="btn btn-block btn-sm btn-outline-info"
                                                            target={'_blank'}>View</Link> : null}

                                                  {getLocalAuthData()?.permission?.includes('order_invoice') ?
                                                      <Link to={`${process.env.PUBLIC_URL}/orders/invoice/${item._id}`}
                                                            className="btn btn-block btn-sm btn-outline-info"
                                                            target={'_blank'}>Invoice</Link> : null}
                            </td>
                          </tr>
                                })
                            }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </App>
    );
  }
}

export default withRouter(BuyerCommissionOrder);
