import React, {Component} from 'react';
import App from "../../App";
import {FaLongArrowAltLeft} from "react-icons/all";
import {Link, withRouter} from "react-router-dom";
import {Button, Col, Form, Row} from "react-bootstrap";
import "../../assets/scss/add-product.scss";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import styleReactSelect from "../../assets/styleReactSelect";
import {addVariant} from "../../services/variantServices";
import {toast} from "react-toastify";
import {getLocalAuthData} from "../../helper/utils";
import {activityLogPostData} from "../../services/commonServices";

class AddVariant extends Component {
  state = {
    formData: {},
    attributeValues: [],
    isShowingValue: false,
    isSubmitting: false,
    isFormValidated: false
  }

  handleFormDataChange = (e) => {
    const value = e.target.value;
    if (e.target.name === 'type') {
      if (value === 'text' || value === 'number' || value === 'textarea') {
        this.setState({formData: {...this.state.formData, [e.target.name]: value}, isShowingValue: false});
      } else {
        this.setState({formData: {...this.state.formData, [e.target.name]: value}, isShowingValue: true});
      }
    } else {
      this.setState({formData: {...this.state.formData, [e.target.name]: value}});
    }
  };

  handleSubmitForm = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({isFormValidated: true})
    } else {
      this.setState({isSubmitting: true});
      addVariant(this.state.formData)
        .then(res => {
          this.setState({isSubmitting: false, isFormValidated: false});
          this.props.history.push(`${process.env.PUBLIC_URL}/variants/list`)
          toast.success(res.message);
          activityLogPostData('was added new variant','/variants/list/',this.state.formData.name)
        })
        .catch(errMsg => {
          this.setState({isSubmitting: false, isFormValidated: false});
          toast.error(errMsg)
        })
    }
  };

  handleAttrOnChange = (newValue) => {
    console.log(newValue);
    let attributes = [];
    if (newValue) {
      newValue.forEach((val) => {
        attributes.push(val.value);
      });
    }
    this.setState({
      formData: {...this.state.formData, values: attributes}
    });
  }

  render() {
    return (
      <App layout="boxed">
        { getLocalAuthData()?.permission?.includes('varient_add') ?
          <>
            <div className="page-header">
              <h2 className="page-title">Add Variant</h2>
              <div className="ml-auto">
                <Link to={`${process.env.PUBLIC_URL}/variants/list`}
                      className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Variant
                  List</Link>
              </div>
            </div>
            <Form noValidate validated={this.state.isFormValidated}
                  onSubmit={this.handleSubmitForm} className="main-content">
              <div className="card-block">
                <div className="block-header">
                  <h4 className="block-title">Variant Details</h4>
                </div>

                <div className="block-body">
                  <Form.Group as={Row} controlId="attributeType">
                    <Form.Label column sm="3">
                      Attribute Type <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Check
                          custom
                          inline required
                          type="radio"
                          value={1}
                          onChange={this.handleFormDataChange}
                          name={'attribute_type'}
                          id={`attributeType-1`}
                          label={`key product information`}
                      />
                      <Form.Check
                          custom
                          inline required
                          type="radio"
                          value={2}
                          onChange={this.handleFormDataChange}
                          name={'attribute_type'}
                          id={`attributeType-2`}
                          label={`Product variant`}
                      />

                      <Form.Control.Feedback type="invalid">Please choose a attribute type.</Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formFieldName">
                    <Form.Label column sm="3">
                      Field Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control type="text"
                                    required
                                    placeholder="Field name" name="name"
                                    onChange={this.handleFormDataChange}/>

                      <Form.Control.Feedback type="invalid">Please enter field name.</Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formFieldLabel">
                    <Form.Label column sm="3">
                      Field Label <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control type="text"
                                    required
                                    placeholder="Field Label"
                                    name="label"
                                    onChange={this.handleFormDataChange}/>

                      <Form.Control.Feedback type="invalid">Please enter field label.</Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formFieldType">
                    <Form.Label column sm="3">Field Type <span className="text-danger">*</span></Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control as="select"
                                    name="type"
                                    required
                                    onChange={this.handleFormDataChange}>
                        <option value="">--Select Type--</option>
                        <option value="text">Text</option>
                        <option value="number">Number</option>
                        <option value="select">Select</option>
                        <option value="multiselect">Multiselect</option>
                        <option value="checkbox">Checkbox</option>
                        <option value="radio">Radio</option>
                        <option value="textarea">Textarea</option>
                      </Form.Control>

                      <Form.Control.Feedback type="invalid">Please choose a filed type.</Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  {this.state.isShowingValue &&
                  <Form.Group as={Row} controlId="formRequire">
                    <Form.Label column sm="3">
                      Values <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <CreatableSelect
                          required
                          isMulti
                          styles={styleReactSelect}
                          placeholder="Please type your values..."
                          onChange={(newValue) => {
                            this.handleAttrOnChange(newValue);
                          }}
                          noOptionsMessage={() => 'Please type your values...'}
                      />
                    </Col>
                  </Form.Group>
                  }

                  <Form.Group as={Row} controlId="formInfo">
                    <Form.Label column sm="3">
                      Info <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control type="text"
                                    required
                                    placeholder="Info" name="info"
                                    onChange={this.handleFormDataChange}/>

                      <Form.Control.Feedback type="invalid">Please enter info.</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </div>
              </div>

              <div className="card-block">
                <div className="block-body text-right">
                  <Link to={`${process.env.PUBLIC_URL}/variants/list`}
                        className={"btn btn-lg btn-default mr-2"}>Cancel</Link>
                  <Button size="lg" type={`submit`}
                          disabled={this.state.isSubmitting}
                          variant="primary">
                    {this.state.isSubmitting ? 'Saving...' : 'Save'}
                  </Button>
                </div>
              </div>
            </Form>
          </>:null}
      </App>

    );
  }
}

export default withRouter(AddVariant);
