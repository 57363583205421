import React, {Component} from 'react';
import DeliveryProgressTimeline from "./DeliveryProgressTimeline";
import OrderActivities from "./OrderActivities";
import {TiArrowSortedDown, TiArrowSortedUp} from "react-icons/all";

class TrackingInfo extends Component {
  state = {
    isShowing: false
  }

  render() {
    const {timelineData, activityData} = this.props;
    return (
      <td style={{padding: 0, backgroundColor: "transparent"}} colSpan={3}>
        {this.state.isShowing &&
        <>
          {timelineData && timelineData?.length > 0 &&
          <>
            <DeliveryProgressTimeline
              data={timelineData}
              style={{margin: 0}}/>
            <hr/>
          </>}

          {activityData?.length > 0 &&
          <OrderActivities
            data={activityData}
            style={{padding: '0 15px 7px'}}/>
          }
        </>
        }
        <span onClick={() => {
          this.setState({isShowing: !this.state.isShowing})
        }} className={`btn-toggle-collapse`}>
          {this.state.isShowing ? 'Less' : 'More'}
          {this.state.isShowing ? <TiArrowSortedUp/> : <TiArrowSortedDown/>}
        </span>
      </td>
    );
  }
}

export default TrackingInfo;
