import React from "react";
import "../assets/scss/brand.scss";
import { getLocalAuthData } from '../helper/utils'


const Brand = ({data, deleteBrand, getBrandDetails}) => {
  return (
    <div className="brand">
      <div className="brand-logo">
        <img src={data.logo} alt=""/>
      </div>
      <div className="brand-info">
        <h4 className="brand-name">{data.name}</h4>

        <div className="brand-actions">
            {getLocalAuthData()?.permission?.includes('brand_edit') ?
                <span onClick={() => getBrandDetails(data._id)} className="action text-primary">Edit</span>
                : null}

            {getLocalAuthData()?.permission?.includes('brand_delete') ?
                <span onClick={() => deleteBrand(data._id)} className="action text-danger">Delete</span>
                : null}

        </div>
      </div>
    </div>
  )
}

export default Brand;
