import React, { Component } from "react";
import { EP_INTEGRATION_BASE_URL } from "../../helper/env";
import App from "../../App";
import { Link, withRouter } from "react-router-dom";
import { FiCheck, FiPlus, IoMdClose, MdArrowBack } from "react-icons/all";
import { changeMerchantInfo, merchantDetails } from "../../services/merchantServices";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import "react-fancybox/lib/fancybox.css";
import ReactFancyBox from "react-fancybox";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { getLocalAuthData} from '../../helper/utils'


class EntrepreneurDetails extends Component {
  state = {
    entrepreneurDetails: {},
    isLoading: true,
    notExisting: false,
    activeBlockTab: "Seller Account",
    profile_in_verification_fields: [],
    statusChangingData: {},
    isShowingStatusChangingModal: false,
  };

  componentDidMount() {
    const merchantId = this.props.match.params.id;
    console.log(merchantId);
    merchantDetails(merchantId)
      .then((res) => {
        console.log("res", res);
        this.setState({
          entrepreneurDetails: { ...res, ...this.entrepreneurDetails },
          isLoading: false,
        });
      })
      .catch((errMsg) => {
        this.setState({ isLoading: false, notExisting: true });
        console.log(errMsg);
      });
  }

  handleBlockTab = (label) => {
    this.setState({ activeBlockTab: label });
  };

  handleShowStatusChangingModal = (userId, key, status, action) => {
    if (action === 'Approve') {
      const data = {
        profile_status: status,
        message: "",
        field_name: key
      }

      confirmAlert({
        title: 'Confirm',
        message: `Are you sure to ${action.toLowerCase()} this entrepreneur?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => changeMerchantInfo(userId, data)
              .then(res => {
                toast.success(res.message);
              })
              .catch(errMsg => {
                toast.error(errMsg);
              })
          },
          {
            label: 'No',
            // onClick: () => alert('Click No')
          }
        ]
      })
    } else {
      this.setState({
        statusChangingData: {
          status: status,
          action: action,
          userId: userId,
          field_name: key
        }
      }, () => {
        this.setState({isShowingStatusChangingModal: true})
      })
    }
  }

  checkUpdates = (key) => {
    const index = this.state.entrepreneurDetails?.user_info?.profile_in_verification_fields?.findIndex(
      (item) => item.field_name === key
    );
    if (index > -1) {
      return (
        <span className="ml-5">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => this.handleShowStatusChangingModal(this.state.entrepreneurDetails?._id, key, 'Verified', 'Approve')}
            className={`text-bold text-success mr-4`}
          >
            <FiCheck /> Approve
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => this.handleShowStatusChangingModal(this.state.entrepreneurDetails?._id, key, 'Declined', 'Declined')}
            className={`text-bold text-danger`}
          >
            <IoMdClose /> Decline
          </span>
        </span>
      );
    } else {
      return null;
    }
  };

  handleHideStatusChangingModal = () => {
    this.setState({
      status: null,
      action: null,
      message: '',
      userId: '',
      field_name: '',
    }, () => {
      this.setState({isShowingStatusChangingModal: false})
    })
  }

  handleProfileStatusUpdate = () => {
    const data = {
      profile_status: this.state.statusChangingData.status,
      message: this.state.statusChangingData.message,
      field_name: this.state.statusChangingData.field_name
    }
    changeMerchantInfo(this.state.statusChangingData.userId, data)
      .then(res => {
        toast.success(res.message);
      })
      .catch(errMsg => {
        toast.error(errMsg);
      })
  }

  handleInputMessageOnChange = (e) => {
    const statusChangingData = {...this.state.statusChangingData}
    statusChangingData.message = e.target.value
    this.setState({statusChangingData})
  }

  render() {
    const { entrepreneurDetails } = this.state;

    return (
      <App layout={`boxed`}>
          <Modal show={this.state.isShowingStatusChangingModal}
                onHide={this.handleHideStatusChangingModal}>
          <Modal.Header closeButton>
            <Modal.Title>Enter Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId={'modalMessage'}>
              <Form.Label>Message</Form.Label>
              <Form.Control as={'textarea'}
                            onChange={this.handleInputMessageOnChange}
                            rows={3} placeholder={'Enter message'}/>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="default" onClick={this.handleHideStatusChangingModal}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleProfileStatusUpdate}>
              Confirm Decline
            </Button>
          </Modal.Footer>
        </Modal>

        {getLocalAuthData()?.permission?.includes('entrepreneurs_details') ? <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Entrepreneur Details</h2>
            <div className="ml-auto">
              <Link
                  to={`${process.env.PUBLIC_URL}/entrepreneurs/list`}
                  className={`btn btn-sm btn-link`}
              >
                <MdArrowBack/> Back to List
              </Link>
              <Link
                  to={`${process.env.PUBLIC_URL}/entrepreneurs/add`}
                  className="btn btn-sm btn-secondary">
                <FiPlus/> Add Entrepreneur
              </Link>
            </div>
          </div>
          <div className="main-content">
            <div className="card-block">
              <ul className="desc-header-nav">
                <li
                    className={
                      this.state.activeBlockTab === "Seller Account"
                          ? "active"
                          : ""
                    }
                    value=""
                >
                  <span onClick={() => this.handleBlockTab("Seller Account")}>
                    Seller Account
                  </span>
                </li>
                <li
                    className={
                      this.state.activeBlockTab === "Business Information"
                          ? "active"
                          : ""
                    }
                    value=""
                >
                  <span
                      onClick={() => this.handleBlockTab("Business Information")}
                  >
                    Business Information
                  </span>
                </li>
                <li
                    className={
                      this.state.activeBlockTab === "Settings" ? "active" : ""
                    }
                    value=""
                >
                  <span onClick={() => this.handleBlockTab("Settings")}>
                    Settings
                  </span>
                </li>
              </ul>

              {this.state.activeBlockTab === "Seller Account" && (
                  <div className="block-body">
                    {this.state.isLoading ? (
                        <div>Loading...</div>
                    ) : (
                        <>
                          {this.state.notExisting ? (
                              <div
                                  className={`alert alert-warning text-bold text-center`}
                              >
                                Merchant doesn't exist
                              </div>
                          ) : (
                              <>
                                {entrepreneurDetails.user_info?.profile_status ===
                                "Declined" && (
                                    <Alert variant={"danger"}>
                                      <strong>Your profile has been declined</strong>{" "}
                                      <br/>
                                      {entrepreneurDetails.user_info?.declined_reason
                                          ? entrepreneurDetails.user_info.declined_reason
                                          : "No Message"}
                                    </Alert>
                                )}
                                <div className="table-responsive">
                                  <table className="info-table">
                                    <tbody>
                                    <tr>
                                      <td style={{width: "250px"}}>
                                        Business Name
                                      </td>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      >
                                        :
                                      </td>
                                      <td className={`text-bold`}>
                                        {entrepreneurDetails?.name}

                                      </td>
                                    </tr>
                                    {/*<tr>
                          <td style={{width: '250px'}}>Profile Status</td>
                          <td style={{width: '20px', textAlign: 'center'}}>:</td>
                          <td className={`text-bold`}>{entrepreneurDetails?.name}</td>
                        </tr>*/}
                                    <tr>
                                      <td style={{width: "250px"}}>Seller ID</td>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      >
                                        :
                                      </td>
                                      <td>{entrepreneurDetails?.merchant_code}</td>
                                    </tr>
                                    <tr>
                                      <td style={{width: "250px"}}>
                                        Merchant Type
                                      </td>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      >
                                        :
                                      </td>
                                      <td>
                                        {entrepreneurDetails?.merchant_type_name}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{width: "250px"}}>Owner Name</td>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      >
                                        :
                                      </td>
                                      <td>
                                        {entrepreneurDetails?.user_info?.name}
                                        {this.checkUpdates("owner_name")}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{width: "250px"}}>
                                        Email Address
                                      </td>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      >
                                        :
                                      </td>
                                      <td>
                                        {entrepreneurDetails?.user_info?.email}{" "}
                                        &nbsp;&nbsp;&nbsp;
                                        {entrepreneurDetails?.user_info
                                            ?.email_verified ? (
                                            <span className="badge badge-success">
                                        Verified
                                      </span>
                                        ) : (
                                            <span className="badge badge-warning">
                                        Not Verified
                                      </span>
                                        )}
                                        <span className="badge badge-success"></span>
                                        {this.checkUpdates("email")}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{width: "250px"}}>
                                        Phone Number
                                      </td>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      >
                                        :
                                      </td>
                                      <td>
                                        {entrepreneurDetails?.user_info.phone}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{width: "250px"}}>ID Type</td>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      >
                                        :
                                      </td>
                                      <td>
                                        {entrepreneurDetails?.id_type}{" "}
                                        {this.checkUpdates("id_type")}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{width: "250px"}}>
                                        Identification Number
                                      </td>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      >
                                        :
                                      </td>
                                      <td>
                                        {entrepreneurDetails?.identification_number}
                                        {this.checkUpdates("identification_number")}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{width: "250px"}}>
                                        Upload ID Copy - Front Side
                                      </td>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      >
                                        :
                                      </td>
                                      <td>
                                        {entrepreneurDetails?.id_front_img && (
                                            <ReactFancyBox
                                                style={{maxHeight: "70px"}}
                                                thumbnail={
                                                  entrepreneurDetails?.id_front_img
                                                }
                                                image={
                                                  entrepreneurDetails?.id_front_img
                                                }
                                            />
                                        )}
                                        {this.checkUpdates("id_front_img")}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{width: "250px"}}>
                                        Upload ID Copy - Back Side
                                      </td>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      >
                                        :
                                      </td>
                                      <td>
                                        {entrepreneurDetails?.id_back_img && (
                                            <ReactFancyBox
                                                thumbnail={
                                                  entrepreneurDetails?.id_back_img
                                                }
                                                image={entrepreneurDetails?.id_back_img}
                                            />
                                        )}
                                        {this.checkUpdates("id_back_img")}
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </>
                          )}
                        </>
                    )}
                  </div>
              )}

              {this.state.activeBlockTab === "Business Information" && (
                  <div className="block-body">
                    {this.state.isLoading ? (
                        <div>Loading...</div>
                    ) : (
                        <>
                          {this.state.notExisting ? (
                              <div
                                  className={`alert alert-warning text-bold text-center`}
                              >
                                Merchant doesn't exist
                              </div>
                          ) : (
                              <>
                                {entrepreneurDetails.user_info?.profile_status ===
                                "Declined" && (
                                    <Alert variant={"danger"}>
                                      <strong>Your profile has been declined</strong>{" "}
                                      <br/>
                                      {entrepreneurDetails.user_info?.declined_reason
                                          ? entrepreneurDetails.user_info.declined_reason
                                          : "No Message"}
                                    </Alert>
                                )}
                                <div className="table-responsive">
                                  <table className="info-table">
                                    <tbody>
                                    <tr>
                                      <td style={{width: "250px"}}/>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      />
                                      <td className={`text-bold`}>
                                        Business Information
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{width: "250px"}}>
                                        Business Registration Number
                                      </td>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      >
                                        :
                                      </td>
                                      <td>
                                        {
                                          entrepreneurDetails?.business_registration_number
                                        }
                                        {this.checkUpdates("business_registration_number")}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{width: "250px"}}>Legal Form</td>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      >
                                        :
                                      </td>
                                      <td>{entrepreneurDetails?.legal_form}
                                        {this.checkUpdates("legal_form")}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{width: "250px"}}>Address</td>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      >
                                        :
                                      </td>
                                      <td>{entrepreneurDetails?.address}
                                        {this.checkUpdates("address")}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{width: "250px"}}/>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      />
                                      <td className={`text-bold`}>
                                        Bank Information
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{width: "250px"}}>
                                        Bank Account Name
                                      </td>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      >
                                        :
                                      </td>
                                      <td>
                                        {
                                          entrepreneurDetails?.bank_info
                                              ?.bank_account_name
                                        }
                                        {this.checkUpdates("bank_account_name")}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{width: "250px"}}>
                                        Account Number
                                      </td>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      >
                                        :
                                      </td>
                                      <td>
                                        {
                                          entrepreneurDetails?.bank_info
                                              ?.bank_account_number
                                        }
                                        {this.checkUpdates("bank_account_number")}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{width: "250px"}}>Bank Name</td>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      >
                                        :
                                      </td>
                                      <td>
                                        {entrepreneurDetails?.bank_info?.bank_name}
                                        {this.checkUpdates("bank_name")}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{width: "250px"}}>
                                        Branch Name
                                      </td>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      >
                                        :
                                      </td>
                                      <td>
                                        {
                                          entrepreneurDetails?.bank_info
                                              ?.bank_account_branch
                                        }
                                        {this.checkUpdates("bank_account_branch")}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{width: "250px"}}>
                                        Cheque Copy
                                      </td>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      >
                                        :
                                      </td>
                                      <td>
                                        {entrepreneurDetails?.bank_info
                                            ?.bank_account_cheque_img && (
                                            <ReactFancyBox
                                                style={{maxHeight: "70px"}}
                                                thumbnail={
                                                  entrepreneurDetails?.bank_info
                                                      ?.bank_account_cheque_img
                                                }
                                                image={
                                                  entrepreneurDetails?.bank_info
                                                      ?.bank_account_cheque_img
                                                }
                                            />
                                        )}
                                        {this.checkUpdates("bank_account_cheque_img")}
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </>
                          )}
                        </>
                    )}
                  </div>
              )}

              {this.state.activeBlockTab === "Settings" && (
                  <div className="block-body">
                    {this.state.isLoading ? (
                        <div>Loading...</div>
                    ) : (
                        <>
                          {this.state.notExisting ? (
                              <div
                                  className={`alert alert-warning text-bold text-center`}
                              >
                                Merchant doesn't exist
                              </div>
                          ) : (
                              <>
                                {entrepreneurDetails.user_info?.profile_status ===
                                "Declined" && (
                                    <Alert variant={"danger"}>
                                      <strong>Your profile has been declined</strong>{" "}
                                      <br/>
                                      {entrepreneurDetails.user_info?.declined_reason
                                          ? entrepreneurDetails.user_info.declined_reason
                                          : "No Message"}
                                    </Alert>
                                )}
                                <div className="table-responsive">
                                  <table className="info-table">
                                    <tbody>
                                    <tr>
                                      <td style={{width: "250px"}}/>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      />
                                      <td className={`text-bold`}>
                                        Integration Setting
                                      </td>
                                    </tr>

                                    <tr>
                                      <td style={{width: "250px"}}>
                                        ekShop API Base Path
                                      </td>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      >
                                        :
                                      </td>
                                      <td>{EP_INTEGRATION_BASE_URL}</td>
                                    </tr>

                                    <tr>
                                      <td style={{width: "250px"}}>
                                        ekShop Product Sync API
                                      </td>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      >
                                        :
                                      </td>
                                      <td>{`${EP_INTEGRATION_BASE_URL}/partner/productSync`}</td>
                                    </tr>

                                    <tr>
                                      <td style={{width: "250px"}}>
                                        ekShop Product Sync API
                                      </td>
                                      <td
                                          style={{
                                            width: "20px",
                                            textAlign: "center",
                                          }}
                                      >
                                        :
                                      </td>
                                      <td>{`${EP_INTEGRATION_BASE_URL}/partner/productSync`}</td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </>
                          )}
                        </>
                    )}
                  </div>
              )}
            </div>
          </div>
        </div> :null }

      </App>
    );
  }
}

export default withRouter(EntrepreneurDetails);
