import axios from 'axios';
import {getLocalAuthData} from "../helper/utils";
import {API_BASE_URL} from "../helper/env";

export const getCustomerDetails = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/customer/${id}`, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": 'web'
      }
    })
      .then(res => {
        if(res.data.status === 1) {
          resolve(res.data)
        }
      })
      .catch(err => {
        //console.log(err.message)
      })
  })
}


export const deleteCustomer = (id) => {
  const authData = getLocalAuthData();

  return new Promise((resolve, reject) => {
    axios.delete(`${API_BASE_URL}/customer/${id}`, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": 'web'
      }
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        //console.log('Catch error: ', err)
        reject('Something went wrong!!')
      })
  })
}

export const deleteCustomerPermanently = (id) => {
  const authData = getLocalAuthData();

  return new Promise((resolve, reject) => {
    axios.delete(`${API_BASE_URL}/customer/${id}/permanent`, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": 'web'
      }
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        //console.log('Catch error: ', err)
        reject('Something went wrong!!')
      })
  })
}
