import React, {Component} from 'react';
import {Button, ButtonGroup, Dropdown, DropdownButton, Form, Pagination} from "react-bootstrap";
import {FaPrint, FiSearch} from "react-icons/all";
import App from "../../App";
import {getSummeryReports} from "../../services/reportServices";

class SummaryReport extends Component {
  state = {
    summeryReports: []
  }

  componentDidMount() {
    getSummeryReports()
      .then(res => {
        this.setState({summeryReports: res})
      })
      .catch(errMsg => {
        console.log(errMsg)
      })
  }

  render() {
    return (
      <App>
        <div className="page-header">
          <h2 className="page-title">Summary Report</h2>

          <div className="ml-auto">

          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
            <Form className="d-flex v-middle" onSubmit={(e)=> e.preventDefault()}>
              <div className="form-inline">
                <Form.Group controlId={"customerType"} className={'mr-2'}>
                  <Form.Label className={"mr-2"}>Filter By: </Form.Label>
                  <Form.Control size={"sm"} as={"select"}>
                    <option value="2020">2020</option>
                    <option value="2019">2019</option>
                    <option value="2018">2018</option>
                    <option value="2017">2017</option>
                    <option value="2016">2016</option>
                    <option value="2015">2015</option>
                    <option value="2014">2014</option>
                    <option value="2013">2013</option>
                    <option value="2012">2012</option>
                    <option value="2011">2011</option>
                    <option value="2010">2010</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId={"searchInput"}>
                  <Form.Label className="sr-only">Search</Form.Label>
                  <Form.Control type={"search"} size={"sm"} placeholder={"Search..."}
                  onKeyDown={(e)=>{
                    if(e.keyCode === 13){
                      //Codes to run on enter key press
                    }
                  }}/>
                </Form.Group>

                <Button size={"sm"} className={"ml-2"} variant={"primary"}><FiSearch/></Button>
              </div>

              <div className={"ml-auto form-inline"}>
                <DropdownButton
                  className={"mr-3"}
                  as={ButtonGroup}
                  id={`fileExport`}
                  variant={"default"}
                  size={"sm"}
                  title={'Export'}>
                  <Dropdown.Item eventKey="1">Excel</Dropdown.Item>
                  <Dropdown.Item eventKey="2">PDF</Dropdown.Item>
                  <Dropdown.Item eventKey="3">CSV</Dropdown.Item>
                </DropdownButton>

                <Button size={"sm"} variant={"default"} className="mb-0 mr-2"><FaPrint/> Print</Button>
              </div>
            </Form>

            <hr/>

            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                <tr>
                  <th style={{width: '30px'}}>#</th>
                  <th>Title</th>
                  <th>January</th>
                  <th>February</th>
                  <th>March</th>
                  <th>April</th>
                  <th>May</th>
                  <th>June</th>
                  <th>July</th>
                  <th>August</th>
                  <th>September</th>
                  <th>October</th>
                  <th>November</th>
                  <th>December</th>
                </tr>
                </thead>
                <tbody>
                {this.state.summeryReports.length > 0 ?
                  this.state.summeryReports.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.title}</td>
                      <td>{item.January}</td>
                      <td>{item.February}</td>
                      <td>{item.March}</td>
                      <td>{item.April}</td>
                      <td>{item.May}</td>
                      <td>{item.June}</td>
                      <td>{item.July}</td>
                      <td>{item.August}</td>
                      <td>{item.September}</td>
                      <td>{item.October}</td>
                      <td>{item.November}</td>
                      <td>{item.December}</td>
                    </tr>
                  ))
                  :
                  <tr>
                    <td colSpan={14} className={'text-center'}><strong>No data found</strong></td>
                  </tr>
                }

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default SummaryReport;