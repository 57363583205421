import axios from "axios";
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData} from "../helper/utils";

export const addNewItem = (url, data) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.post(`${API_BASE_URL}${url}`, data, {
            headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
        }).then(res => {

            resolve(res)
        }).catch(err => {
            console.error(err)
            reject(err.message)
        })
    })
}

export const getDetail = (url,{} = {}) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}${url}`, {
             headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            console.error(err)
            reject(err.message)
        })
    })
}



export const updateItem = (url, data) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.put(`${API_BASE_URL}${url}`, data, {
            headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
        }).then(res => {
            resolve(res)
        }).catch(err => {
            console.error(err)
            reject(err.message)
        })
    })
}