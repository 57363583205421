import React, {Component} from 'react';
import App from "../../App";
import {Link} from "react-router-dom";
import {
  commissionSettingsStatusChange,
  deleteCommissionSettings, getCommissionSettingDetails,
  getCommissionSettingList, toggleStatusUpdate
} from "../../services/commissionSettingsServices";
import {confirmAlert} from "react-confirm-alert";
import {toast} from "react-toastify";
import {Button, Modal} from "react-bootstrap";
import {GoLinkExternal, IoMdCheckmark} from "react-icons/all";
import {API_BASE_URL, SHOP_BASE_URL} from "../../helper/env";
import {getLocalAuthData, objToUrlPrams} from "../../helper/utils";
import {activityLogPostData} from "../../services/commonServices";

class CustomCommissionSettings extends Component {
  state = {
    settingList: [],
    isLoading: true,
    isShowingCommissionDetailModal: false
  }

  componentDidMount() {
    getCommissionSettingList()
      .then(res => {
        this.setState({settingList: res.items, isLoading: false})
      })
      .catch(errMsg => {
        this.setState({isLoading: false})
      })
  }

  handleDeleteCommissionSetting = (id) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to delete this settings?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteCommissionSettings(id)
            .then(res => {
              getCommissionSettingList()
                .then(res => {
                  this.setState({settingList: res.items, isLoading: false})
                })
                .catch(errMsg => {
                  this.setState({isLoading: false})
                })
              toast.success(res.message)
            })
            .catch(errMsg => {
              toast.error(errMsg)
            })
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }

  handleToggleStatusUpdate = (id, status, index) => {
    confirmAlert({
      title: 'Status Update',
      message: 'Are you sure to change this status?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => toggleStatusUpdate(id, {status: status})
            .then(res => {
              const settingList = [...this.state.settingList]
              settingList[index].status = status
              this.setState({settingList})
              toast.success(res.message)
              activityLogPostData('was change commission setting status','/commission-settings/custom-settings/edit/'+id,null)
            })
            .catch(errMsg => {
              toast.error(errMsg)
            })
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }

  handleShowCommissionDetailModal = (id) => {
    console.log(id)
    getCommissionSettingDetails(id).then(res => {
      console.log("res");
      console.log(res);
      if (res.status === 1) {
        this.setState({selectedCommission: res.commission_info}, () => {
          console.log(this.state.selectedCommission);
        });
      } else {
        toast.warning(res.message);
      }
    }).catch(errMsg => {
      this.setState({isLoading: false})
    })
    this.setState({isShowingCommissionDetailModal: true})
  }

  handleHideCommissionDetailModal = () => {

    this.setState({isShowingCommissionDetailModal: false})
  }

  render() {
    return (
      <App layout={`boxed`}>
        <Modal show={this.state.isShowingCommissionDetailModal} onHide={this.handleHideCommissionDetailModal} size="lg">
          <Modal.Body>
            <table className="info-table">
              <tbody>
              <tr>
                <td>Commission</td>
                <td style={{width: '20px'}}>:</td>
                <td className={'pl-2'}>

                  <span>
                    Admin: <strong>{this.state.selectedCommission?.admin_commission_percentage}% </strong>
                    <span ml={2}> Max: {this.state.selectedCommission?.admin_max_commission_value && <strong>{this.state.selectedCommission?.admin_max_commission_value}</strong> }</span>
                  </span>
                  <br/>
                  <span>
                    Merchant: <strong>{this.state.selectedCommission?.merchant_commission_percentage}%</strong>
                    <span ml={2}> Max: {this.state.selectedCommission?.merchant_max_commission_value && <strong>{this.state.selectedCommission?.merchant_max_commission_value}</strong> }</span>
                  </span>

                </td>
              </tr>
              <tr>
                <td>Store</td>
                <td style={{width: '20px'}}>:</td>
                <td className={'pl-2'}>
                  {(this.state.selectedCommission?.stores?.length > 0) ?
                      <>
                        {this.state.selectedCommission?.stores.map((item, index) => (
                            <Link to={`/stores/details/${item._id}`}
                                  className={'badge badge-primary'} target={'_blank'}>
                              {item.store_name}
                            </Link>
                        ))}
                      </>
                      :
                      <></>
                  }
                </td>
              </tr>
              <tr>
                <td>Merchant</td>
                <td style={{width: '20px'}}>:</td>
                <td className={'pl-2'}>
                  {(this.state.selectedCommission?.merchants?.length > 0) ?
                      <>
                        {this.state.selectedCommission?.merchants.map((item, index) => (
                            <Link to={`/entrepreneurs/details/${item._id}`}
                                  className={'badge badge-secondary'} target={'_blank'}>
                              {item.name}
                            </Link>
                        ))}
                      </>
                      :
                      <></>
                  }
                </td>
              </tr>
              <tr>
                <td>Category</td>
                <td style={{width: '20px'}}>:</td>
                <td className={'pl-2'}>
                  {(this.state.selectedCommission?.categories?.length > 0) ?
                      <>
                        {this.state.selectedCommission?.categories.map((item, index) => (
                            <span className={'badge badge-secondary'} target={'_blank'}>
                              {item.name}
                            </span>
                        ))}
                      </>
                      :
                      <></>
                  }
                </td>
              </tr>
              <tr>
                <td>Products</td>
                <td style={{width: '20px'}}>:</td>
                <td className={'pl-2'}>
                  <div className="product-checkbox-list">

                    {(this.state.selectedCommission?.products?.length > 0) ?
                        <>
                          {this.state.selectedCommission?.products.map((item, index) => (
                              <label className={`product-item`} htmlFor={`product-id`}>
                                <span className={`product`} style={{paddingRight: '10px', lineHeight: '1.2'}}>
                                  <span className="product-img">
                                    <img src={item.thumb_img} alt=""/>
                                  </span>
                                  <span className="product-short-info">
                                    <span className="product-name">{item?.name}</span>
                                    <a target={'_blank'}
                                       rel="noopener noreferrer"
                                       className={'ml-3 text-primary'}
                                       href={`${SHOP_BASE_URL}/products/details/${item?.slug?item?.slug:item?._id}`}><GoLinkExternal/></a>
                                  </span>
                                </span>
                              </label>
                          ))}
                        </>
                        :
                        <></>
                    }


                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleHideCommissionDetailModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {getLocalAuthData()?.permission?.includes('custom_setting_add') ?
        <div className="page-header"><h2 className="page-title">Commission Settings</h2>
          <div className="ml-auto">
            <Link className={`btn btn-sm btn-secondary`}
                  to={`${process.env.PUBLIC_URL}/commission-settings/custom-settings/add`}>Add Commission Setting</Link>
          </div>
        </div> : null}


         {getLocalAuthData()?.permission?.includes('custom_setting_list') ?
            <div className="card-block">
              <div className="block-body">
                {this.state.isLoading ?
                    <>Loading...</>
                    :
                    <table className="table table-striped">
                      <thead>
                      <tr>
                        <th style={{width: '20px'}}>#</th>
                        <th>Commission (%)</th>
                        <th>Store</th>
                        <th>Merchant</th>
                        <th>Category</th>
                        <th>Product</th>
                        <th>Status</th>
                        <th style={{width: `1%`}}/>
                      </tr>
                      </thead>

                      <tbody>
                      {this.state.settingList?.length > 0 ?
                          this.state.settingList.map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>

                        <span>
                          Admin: <strong>{item?.admin_commission_percentage}% </strong>
                          <span ml={2}> Max: {item?.admin_max_commission_value && <strong>{item?.admin_max_commission_value}</strong> }</span>
                        </span>
                                  <br/>
                                  <span>
                          Merchant: <strong>{item?.merchant_commission_percentage}%</strong>
                          <span ml={2}> Max: {item?.merchant_max_commission_value && <strong>{item?.merchant_max_commission_value}</strong> }</span>
                        </span>

                                </td>
                                <td>{item?.stores.length}</td>
                                <td>{item?.merchants.length}</td>
                                <td>{item?.categories.length}</td>
                                <td>{item?.products.length}</td>
                                <td>
                                  {item.status === "1" ? <span className={`badge badge-success`}>Active</span>
                                      :
                                      <span className={`badge badge-secondary`}>Disabled</span>}
                                </td>
                                <td className="nowrap text-center">
                        <span className={`text-bold text-info mr-3`}
                              onClick={() => this.handleShowCommissionDetailModal(item._id)}
                              style={{cursor: 'pointer'}}>View</span>
                                  <Link to={`/commission-settings/custom-settings/edit/${item._id}`}
                                        className={`text-bold text-primary mr-3`}>Edit</Link>
                                  <span className={item?.status === "1" ? 'text-bold text-warning mr-3' : 'text-bold text-success mr-3'}
                                        onClick={() => this.handleToggleStatusUpdate(item._id, item?.status === "1" ? "2" : "1", index)}
                                        style={{cursor: 'pointer'}}>
                          {item?.status === "1" ? 'Disabled' : 'Activate'}
                        </span>
                                  <span className={`text-bold text-danger`}
                                        onClick={() => this.handleDeleteCommissionSetting(item._id)}
                                        style={{cursor: 'pointer'}}>Delete</span>
                                </td>
                              </tr>
                          ))
                          :
                          <tr>
                            <td colSpan={7} className={`text-bold text-center`}>No item found</td>
                          </tr>
                      }
                      </tbody>
                    </table>
                }
              </div>
            </div>
            : null}



      </App>

    );
  }
}

export default CustomCommissionSettings;
