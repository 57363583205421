import React, {Component} from "react";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import "../assets/scss/business-block-card.scss";
import HomeProduct from "./HomeProduct";
import {FiChevronRight, FiMove, FiSettings, FiTrash, IoMdCheckmark} from "react-icons/all";
import {isEmptyObj} from "../helper/utils";
import {getActiveProductList} from "../services/productServices";
import LoadingSpinner from "./LoadingSpinner";
import {getTableData, getDetail, updateItem} from "../services/commonServices";
import {AsyncTypeahead} from "react-bootstrap-typeahead"
import {getStoreList} from "../services/storeServices";
import {toast} from "react-toastify";

// noinspection DuplicatedCode,JSUnresolvedVariable
class ProductsBlock extends Component {
    state = {
        isSettingModalShowing: false,
        showSelectCategoriesModal: false,
        settingData: {params: {}},
        selectedCategories: [],
        categories: [],
        selectedCategoriesWithProducts: [
            {
                category: {
                    _id: "",
                    name: "",
                },
                show: true,
                products: []
            }
        ],
        productList: [],
        customProductList: [],
        selectedCustomProductList: [],
        isDataFetching: false,
        isConfigured: false,
        customProductModalShow: false,
        customProductQuery: {},
        isLoadingStoreData: false,
        storeList: [],
    }

    componentDidMount() {
        if (!isEmptyObj(this.props?.settings)) {
            this.setState({
                categories: this.props?.settings?.categories,
                isDataFetching: true,
                isConfigured: true,
                settingData: this.props.settings,
                selectedCategoryId: (this.props?.settings?.categories) ? this.props?.settings?.categories[0] : ""
            }, () => {
                console.log("this.props.settings>>", this.props.settings);
                if (this.props?.setting_id) {
                    getDetail('/front/categories-with-products', {setting_id: this.props.setting_id}).then(res => {
                        console.log("res.products>>", res.products);
                        console.log("res.categories>>", res.categories);
                        this.setState({
                            selectedCustomProductList: res.products,
                            selectedCategories: res.categories,
                            sliders: res.sliders,
                            isDataFetching: false,
                        })
                        this.setState({isDataFetching: false})
                    }).catch(() => {
                        this.setState({isDataFetching: false})
                    })
                } else {
                    this.setState({isDataFetching: false})
                }
            })
        }
    }

    handleSettingModalShow = () => {
        this.setState({isSettingModalShowing: true})
    }

    handleSettingModalHide = () => {
        this.setState({isSettingModalShowing: false})
    }

    handleSettingInputOnChange = (e) => {
        const settingData = {...this.state.settingData}
        if (e.target.type !== 'checkbox') {
            if (e.target.name === 'title' || e.target.name === 'title_local') {
                settingData[e.target.name] = e.target.value
            } else {
                settingData.params[e.target.name] = e.target.value
            }
        } else {
            if (e.target.checked) {
                settingData.params[e.target.name] = e.target.value
            } else {
                delete settingData.params[e.target.name]
            }
        }
        this.setState({settingData})
    }

    handleOnSaveSettingForm = () => {
        this.props.actions.onChangeSettings(this.state.settingData, () => {
            this.handleSettingModalHide()
            this.setState({isConfigured: true, isCategoryDataFetching: true}, () => {
                return getTableData(`/category`, {display_in_front: 1, category_type: "product"})
                    .then((res) => {

                        // let selectedCategoriesWithProducts = this.state.selectedCategoriesWithProducts;
                        if (res.categories.length > 0) {
                            res.categories.map(function (category) {
                                // selectedCategoriesWithProducts.includes(category._id)
                            });
                        }

                        this.setState({categories: res.categories, isCategoryDataFetching: false})
                    })
                    .catch(() => this.setState({isDataFetching: false}))
            })
        })
    }

    handleSelectedCategoryOnChange = (e, category) => {
        if (e.target.checked) {
            let selectedCategories = this.state.selectedCategories;
            selectedCategories.push(category)
            this.setState({
                selectedCategories: selectedCategories
            }, () => {

                /* SET CATEGORIES IN SETTING PARAMS */
                const categoryIds = this.state.selectedCategories.map((a) => a._id);
                this.props.settings.categories = categoryIds.filter((item, pos) => categoryIds.indexOf(item) === pos)

                const productIds = this.state.selectedCustomProductList.map((a) => a._id);
                this.props.settings.products = productIds.filter((item, pos) => productIds.indexOf(item) === pos)
            })
        } else {
            let selectedCategories = this.state.selectedCategories.filter(item => item._id !== category._id);
            this.setState({
                selectedCategories: selectedCategories
            }, () => {

                /* SET CATEGORIES IN SETTING PARAMS */
                const categoryIds = this.state.selectedCategories.map((a) => a._id);
                this.props.settings.categories = categoryIds.filter((item, pos) => categoryIds.indexOf(item) === pos)

                const productIds = this.state.selectedCustomProductList.map((a) => a._id);
                this.props.settings.products = productIds.filter((item, pos) => productIds.indexOf(item) === pos)
            })
        }
    }

    isCategoryIncluded = (category) => {
        console.log('SELECTED CATEGORY', this.state.selectedCategories)
        console.log('CATEGORY', this.state.categories)
        category = this.state.categories

        if (this.state.selectedCategories.length === 0)
            return false;


        return this.state.selectedCategories.filter((item, pos) => category.indexOf(item._id) === pos).length !== 0
        //return this.state.selectedCategories.filter(item => item?._id === category[0] ).length !== 0
        //return this.state.selectedCategories.filter(({ _id,pos }) => category.includes(_id)).length !== 0;
    }

    isTrue = () => {
        let isDisabled
        if (typeof (this.props.settings?.is_disabled) !== 'string') {
            isDisabled = this.props?.is_disabled
        } else {
            isDisabled = this.props?.is_disabled === 'true'
        }

        return isDisabled
    }

    handleInputOnChange = (e) => {
        this.setState({isDataFetching: true, customProductQuery: e.target.value}, () => {
            getActiveProductList({search_string: this.state.customProductQuery})
                .then(data => this.setState({customProductList: data.products, isDataFetching: false}))
                .catch(() => this.setState({isDataFetching: false}));
        });
    }

    handleSearchProduct = () => {
        getActiveProductList({
            ...this.state.customProductQuery,
            category_id: this.state.selectedCategoryId,duplicate: true
        })
            .then(data => this.setState({
                customProductModalShow: true,
                customProductList: data.products,
                isDataFetching: false
            }))
            .catch(() => this.setState({isDataFetching: false}))
    }

    handleSelectedProductOnChange = (e, product) => {
        if (e.target.checked) {
            let selectedCustomProductList = this.state.selectedCustomProductList;
            selectedCustomProductList.push(product)
            this.setState({
                selectedCustomProductList: selectedCustomProductList
            }, () => {

                /* SET CATEGORIES IN SETTING PARAMS */
                const categoryIds = this.state.selectedCategories.map((a) => a._id);
                this.props.settings.categories = categoryIds.filter((item, pos) => categoryIds.indexOf(item) === pos)

                const productIds = this.state.selectedCustomProductList.map((a) => a._id);
                this.props.settings.products = productIds.filter((item, pos) => productIds.indexOf(item) === pos)

                console.log("*********", this.props.settings);

            })
        } else {
            let selectedCustomProductList = this.state.selectedCustomProductList.filter(item => item.sku_info._id !== product.sku_info._id);
            this.setState({
                selectedCustomProductList: selectedCustomProductList
            }, () => {

                /* SET CATEGORIES IN SETTING PARAMS */
                const categoryIds = this.state.selectedCategories.map((a) => a._id);
                this.props.settings.categories = categoryIds.filter((item, pos) => categoryIds.indexOf(item) === pos)

                const productIds = this.state.selectedCustomProductList.map((a) => a._id);
                this.props.settings.products = productIds.filter((item, pos) => productIds.indexOf(item) === pos)

                console.log("*********", this.props.settings);
            })
        }
    }

    isProductIncluded = (product) => {
        if (this.state.selectedCustomProductList.length === 0)
            return false;
        return this.state.selectedCustomProductList.filter(item => item._id === product._id).length !== 0
    }

    handleCategoryProduct = (category) => {
        this.setState({selectedCategoryId: category._id}, () => {
            this.handleSearchProduct()
        })
    }

    handleSearch = (query) => {
        this.setState({isLoadingStoreData: true}, () => {
            getStoreList({search_string: query, lite_data: 1})
                .then(res => {
                    this.setState({storeList: res.items, isLoadingStoreData: false});
                })
                .catch(errMsg => {
                    this.setState({isLoadingStoreData: false})
                })
        })
    };

    handleDeleteListener = async (product, index , categoryId ) => {
        let selectedCustomProductList = this.state.selectedCustomProductList.filter(item => item.sku_info._id !== product.sku_info._id);
        this.setState({
            selectedCustomProductList: selectedCustomProductList
        }, () => {

            /* SET CATEGORIES IN SETTING PARAMS */
            const categoryIds = this.state.selectedCategories.map((a) => a._id);
            this.props.settings.categories = categoryIds.filter((item, pos) => categoryIds.indexOf(item) === pos)

            const productIds = this.state.selectedCustomProductList.map((a) => a._id);
            this.props.settings.products = productIds.filter((item, pos) => productIds.indexOf(item) === pos)
            console.log("*********", this.props.settings);
            this.deleteProduct(categoryId , product._id)

        })
    }

    deleteProduct =(categoryId , productId)=>{
      updateItem('/front/categories-with-products/'+categoryId+'/'+productId , {}).then((res)=>{
        console.log(res)
        if (res.status === 1){
          toast.success(res.message)
          window.location.href ='/homepage-management'
        }else {
         // toast.warning(res.message)
        }
      })
    }


    render() {
        const {settings, handler, actions, index, dragHandleProps} = this.props;
        console.log('#########Start###########')
        console.log(this.state?.categories?.length)
        console.log(this.state?.categories)
        console.log('#########End###########')
        return (
            <div className="card-block position-relative">
                {this.state.isDataFetching &&
                <LoadingSpinner/>
                }
                <div className="block-header">
                    <h4 className="block-title">
                        {handler && dragHandleProps &&
                        <span className={'btn-draggable'} {...handler.dragHandleProps}> <FiMove/> </span>}
                        {settings?.title || 'Untitled'}
                        {!dragHandleProps &&
                        <span className={"text-danger"} style={{"fontSize": "12px"}}>please save to drag</span>}
                    </h4>

                    <div className={'block-actions'}>
                        <Form.Check
                            inline
                            checked={!settings?.is_disabled}
                            type="switch"
                            id={`isDisabled-${index}`}
                            onChange={actions.toggleBlockSectionDisable}
                            label={settings?.is_disabled ? 'Off' : 'On'}/>

                        <span onClick={this.handleSettingModalShow} className={'btn-block-action'}>
              <FiSettings/>
            </span>

                        <span onClick={actions.onDelete} className={'btn-block-action ml-3'}>
            <FiTrash/>
          </span>
                    </div>
                </div>

                {/* CONFIGURATION MODAL */}
                <Modal size={"lg"} show={this.state.isSettingModalShowing} onHide={this.handleSettingModalHide}>

                    <Modal.Header closeButton>
                        <Modal.Title>Block Settings</Modal.Title>
                    </Modal.Header>

                    <Form>

                        <Modal.Body>

                            <Form.Group controlId="blockTitle">
                                <Form.Label>Block Title (ENG)</Form.Label>
                                <Form.Control type="text" name={'title'}
                                              defaultValue={settings?.title}
                                              onChange={this.handleSettingInputOnChange}
                                              placeholder="Enter block title in english"/>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Block Title (বাংলা)</Form.Label>
                                <Form.Control type="text"
                                              name={'title_local'}
                                              defaultValue={settings?.title_local}
                                              onChange={this.handleSettingInputOnChange}
                                              placeholder="Enter block title in local language"/>
                            </Form.Group>

                        </Modal.Body>

                        <Modal.Footer>

                            <Button variant="secondary" onClick={this.handleSettingModalHide}>Close</Button>
                            <Button variant="primary" onClick={this.handleOnSaveSettingForm}>Save Changes</Button>

                        </Modal.Footer>

                    </Form>

                </Modal>

                {/* CATEGORY WISE PRODUCT SECTION */}
                <div className="block-body">
                    <Row>

                        {(settings?.title && this.state.isConfigured) ?
                            <>
                                <Col lg={3} md={4} xs={4}>
                                    {(settings?.title && this.state.isConfigured) ?
                                        <>
                                            <Row>{/*selectedCategoriesWithProducts*/}

                                                <Col lg={12} md={12} xs={12} style={{}} class={"partner-card"}>

                                                    <ul className="category-list">
                                                        {this.state.selectedCategories.map(cat => (
                                                            <li key={cat._id}
                                                                onClick={() => this.handleCategoryProduct(cat)}>
                                                                <span className='text'>{cat.name}</span>
                                                                <span className="caret-icon"><FiChevronRight/></span>
                                                            </li>
                                                        ))}

                                                        <li>
                                                            <hr/>
                                                        </li>
                                                        <li>
                              <span
                                  onClick={() => {
                                      // setTimeout(() => {
                                      this.setState({selectCategoriesModalShow: true})
                                      this.handleOnSaveSettingForm()
                                      // }, 1000)
                                  }}>+ Manage Category</span>
                                                        </li>
                                                        <li>
                                                            <hr/>
                                                        </li>

                                                    </ul>

                                                </Col>

                                            </Row>

                                            <Modal show={this.state.selectCategoriesModalShow} onHide={() => {
                                                return this.setState({
                                                    selectCategoriesModalShow: false,
                                                })
                                            }}>

                                                <Modal.Header closeButton>
                                                    <Modal.Title>Select Category</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>

                                                    <div className="product-checkbox-list">
                                                        {
                                                            this.state.isCategoryDataFetching &&
                                                            <LoadingSpinner text={`Loading...`}/>
                                                        }
                                                        {this.state?.categories?.length > 0 && this.state.categories.map((category, index) => (
                                                            <label className={`product-item`} key={index}
                                                                   htmlFor={`product-${index}`}>
                                                                <input id={`product-${index}`} name={`product`}
                                                                       type="checkbox"
                                                                       onChange={e => this.handleSelectedCategoryOnChange(e, category)}
                                                                       defaultChecked={this.isCategoryIncluded(category)}
                                                                />
                                                                <span className={`product`}>
                                  <span className="product-short-info">
                                    <span className="product-name">{category?.name}</span>
                                  </span>
                                  <span className="icon"><IoMdCheckmark/></span>
                                </span>
                                                            </label>
                                                        ))}
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={() => {
                                                        return this.setState({
                                                            selectCategoriesModalShow: false,
                                                        })
                                                    }}>Close</Button>
                                                </Modal.Footer>

                                            </Modal>

                                        </>
                                        :
                                        <div className={'alert alert-warning text-center'}>
                                            Please select required categories <span
                                            onClick={this.handleSettingModalShow} style={{cursor: "pointer"}}
                                            className="text-primary">click here</span>
                                        </div>
                                    }
                                </Col>

                                <Col lg={9} md={8} xs={8}>
                                    <>
                                        <Row>
                                            {this.state?.selectedCustomProductList && this.state?.selectedCustomProductList.map((product , index) => {
                                                return (
                                                    <>
                                                        {(this.state.selectedCategoryId === product.category || this.state.selectedCategoryId === product?.category?._id) &&
                                                        <>
                                                            <Col lg={2} md={3} xs={3}>
                                                                <HomeProduct id={this.props.setting_id} data={product} sku_info={product.sku_info}
                                                                             handleDeleteListener={() => {
                                                                                 this.handleDeleteListener(product, index, this.props.setting_id)
                                                                             }}/>
                                                            </Col>
                                                        </>
                                                        }
                                                    </>
                                                )
                                            })}

                                        </Row>

                                        {/* CATEGORY WISE PRODUCTS FILTER AND SELECTION */}

                                        <Modal size="lg" show={this.state.customProductModalShow} onHide={() => {
                                            return this.setState({
                                                customProductModalShow: false,
                                            })
                                        }}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Select Product</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="mb-3 form-inline">
                                                    <Form.Group className="mr-1 my-2">
                                                        <Form.Label srOnly>Search</Form.Label>
                                                        <Form.Control type={`search`} name="search_string" size={`sm`}
                                                                      placeholder={`Type & Search`}
                                                                      onKeyDown={(e) => {
                                                                          if (e.keyCode === 13) {
                                                                              this.handleInputOnChange(e)
                                                                          } else {

                                                                          }
                                                                      }}
                                                                      onChange={(e) => {
                                                                          if (e.keyCode !== 13) {
                                                                              this.setState({
                                                                                  customProductQuery: {
                                                                                      ...this.state.customProductQuery,
                                                                                      [e.target.name]: e.target.value,
                                                                                  },
                                                                              });
                                                                          }
                                                                      }}
                                                        />
                                                    </Form.Group>

                                                    <div className="position-relative m-1">
                                                        <AsyncTypeahead
                                                            filterBy={() => true}
                                                            id="inputStoreList"
                                                            isLoading={this.state.isLoadingStoreData}
                                                            labelKey="store_name"
                                                            minLength={3}
                                                            size={"sm"}
                                                            onKeyDown={() => {
                                                                this.setState({customProductQuery: {...this.state.customProductQuery, sid: ""}});
                                                            }}
                                                            onSearch={this.handleSearch}
                                                            options={this.state.storeList}
                                                            placeholder="Search store..."
                                                            renderMenuItemChildren={(store, props) => (
                                                                <React.Fragment>
                                                                  <span onClick={() => {
                                                                      this.setState({customProductQuery: {...this.state.customProductQuery, sid: store._id}})
                                                                  }}>
                                                                    {store.store_name}
                                                                  </span>
                                                                </React.Fragment>
                                                            )}
                                                        />
                                                    </div>
                                                    <Form.Group className="m-1">
                                                        <Form.Label srOnly>Product Type</Form.Label>
                                                        <Form.Control
                                                            className="m-1"
                                                            as={"select"}
                                                            size="sm"
                                                            defaultValue={""}
                                                            name="campaign_product"
                                                            onChange={(e) => {
                                                                this.setState({customProductQuery: {...this.state.customProductQuery, [e.target.name]: e.target.value}});
                                                            }}
                                                        >
                                                            <option value="">--Product Type--</option>
                                                            <option value="true">Campaign Product</option>
                                                            <option value="false">Regular Product</option>
                                                        </Form.Control>
                                                    </Form.Group>

                                                    <Button type={`button`} onClick={this.handleSearchProduct}
                                                            size={`sm`} variant={`info`}
                                                            className={`ml-2`}>Search</Button>
                                                </div>
                                                <div className="product-checkbox-list">
                                                    {
                                                        this.state.isDataFetching &&
                                                        <LoadingSpinner text={`Loading...`}/>
                                                    }
                                                    {this.state.customProductList.map((product, index) => (
                                                        <label className={`product-item`} key={product._id}
                                                               htmlFor={`product-${product._id}`}>
                                                            <input id={`product-${product._id}`} name={`product`}
                                                                   type="checkbox"
                                                                   onChange={e => this.handleSelectedProductOnChange(e, product)}
                                                                   defaultChecked={this.isProductIncluded(product)}
                                                            />
                                                            <span className={`product`}>
                                                              <span className="product-img">
                                                                <img src={product?.mid_img} alt=""/>
                                                              </span>
                                                              <span className="product-short-info">
                                                                <span
                                                                    className="product-name">{product.name} {product?.sku_info?.attr_combo ? product?.sku_info?.attr_combo.join('-') : ''}</span>
                                                              </span>
                                                              <span className="icon"><IoMdCheckmark/></span>
                                                            </span>
                                                        </label>
                                                    ))}
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={() => {
                                                    return this.setState({
                                                        customProductModalShow: false,
                                                    })
                                                }}>Close</Button>
                                            </Modal.Footer>

                                        </Modal>

                                    </>
                                </Col>

                            </>
                            :
                            <div className={'alert alert-warning text-center'}>
                                This block has no configuration. Please <span onClick={this.handleSettingModalShow}
                                                                              style={{cursor: "pointer"}}
                                                                              className="text-primary">click here</span> to
                                configure
                            </div>
                        }
                    </Row>
                </div>

            </div>
        );
    }
}

export default ProductsBlock;
