import axios from "axios";
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData} from "../helper/utils";

export const getStoreList = (paramsObj = {}) => {
  const authData = getLocalAuthData();

  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/store`, {
      params: paramsObj,
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.message)
    })
  })
}

export const getStoreListV2 = (paramsObj = {}) => {
  const authData = getLocalAuthData();

  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/shopping-mall/stores`, {
      params: paramsObj,
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.message)
    })
  })
}

export const saveStore = (data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.post(`${API_BASE_URL}/store`, data, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': 'web'
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const deleteStore = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.delete(`${API_BASE_URL}/store/${id}/permanent`, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': 'web'
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const getStoreDetails = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/store/${id}`, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": "web"
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data.store_info);
        } else {
          reject(res.data.message);
        }
      })
      .catch(err => {
        //console.log('Store details error: ', err);
        reject('Something went wrong');
      })
  })
}

export const submitIntegrationSetting = (data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.post(`${API_BASE_URL}/store/integration-setting`, data, {
      headers: {'x-auth-token': authData.token, 'x-api-client': 'web'}
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.message)
    })
  })
}

export const updateStore = (data, id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.put(`${API_BASE_URL}/store/${id}`, data, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": "web"
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data);
        } else {
          reject(res.data.messages);
        }
      })
      .catch(err => {
        //console.log('Update store error: ', err);
        reject("Something went wrong!")
      })
  })
}

export const approvalStatusUpdate = (id, data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.patch(`${API_BASE_URL}/store/${id}/changeApprovalStatus`, data, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch(err => {
        //console.log(err);
        reject('Something went wrong');
      })
  })
}

export const statusUpdate = (id, data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.patch(`${API_BASE_URL}/store/${id}/changeStatus`, data, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch(err => {
        //console.log(err);
        reject('Something went wrong');
      })
  })
}
