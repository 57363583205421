import React, {Component} from 'react';
import App from "../../App";
import {Button, Pagination} from "react-bootstrap";
import {FaFileExport, FiPlus, FiUpload} from "react-icons/all";
import {Link} from "react-router-dom";
import watch from "../../temp/watch.jpg";
import { getLocalAuthData } from "../../helper/utils"

class EventList extends Component {
  render() {
    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Event Setting</h2>
          <div className="ml-auto">

            { getLocalAuthData()?.permission?.includes('events_import') ?
                <label htmlFor="productImport" className="btn mb-0 btn-sm btn-default mr-2">
                  <input type="file"
                         id="productImport"
                         className="sr-only"/>
                  <FiUpload/> Import
                </label>: null}

            <a href="/#" className="text-muted mr-3" download><small>(Download sample file)</small></a>
            { getLocalAuthData()?.permission?.includes('events_export') ?
            <Button size={"sm"} variant={"default"} className="mb-0 mr-2"><FaFileExport/> Export</Button>
                :null}

            {getLocalAuthData()?.permission?.includes('event_add') ?
            <Link to={`${process.env.PUBLIC_URL}/promotions/event/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add Event</Link> :null}
          </div>
        </div>

        <div className="main-content">
          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Event Setting List</h4>
            </div>

            { getLocalAuthData()?.permission?.includes('events_list') ?
            <div className="block-body">
              <form className="form-inline mb-3">
                <div className="form-group mr-3">
                  <label htmlFor="startTime" className="sr-only">Start Time</label>
                  <input type="search" id="startTime" className='form-control form-control-sm' placeholder="Start Time"/>
                </div>
                <div className="form-group mr-3">
                  <label htmlFor="endTime" className="sr-only">End Time</label>
                  <input type="search" id="endTime" className='form-control form-control-sm' placeholder="End Time"/>
                </div>

                <div className="form-group mr-3">
                  <label htmlFor="barndName" className="sr-only">Status</label>
                  <select type="search" id="status" className='form-control form-control-sm'>
                    <option value="1">Active</option>
                    <option value="2">Inactive</option>
                  </select>
                </div>

                <Button size="sm" variant="secondary">Search</Button>
              </form>

              <table className="table table-striped">
                <thead>
                <tr>
                  <th style={{width: '1%'}}>#</th>
                  <th>Event Title</th>
                  <th>Event Banner</th>
                  <th>Event Date</th>
                  <th>Products Categories</th>
                  <th>Total Products</th>
                  <th>Total Seller</th>
                  <th style={{width: '120px'}}/>
                </tr>
                </thead>
                <tbody>
                <td>1</td>
                <td>Eid Campaign</td>
                <td>
                  <img src={watch} alt="" width={"50px"}/>
                </td>
                <td>
                  <span className="start-date">25/04/2020</span>
                </td>
                <td>All Categories (12)</td>
                <td>All Products (129385)</td>
                <td>All Sellers (231)</td>
                <td>
                  {getLocalAuthData()?.permission?.includes('events_edit') ?
                      <button className="btn-action btn-block btn-outline-primary">Edit</button>
                      :null}
                  {getLocalAuthData()?.permission?.includes('events_block') ?
                      <button className="btn-action btn-block btn-outline-warning">Blcok</button>
                      :null}
                  {getLocalAuthData()?.permission?.includes('events_delete') ?
                      <button className="btn-action btn-block btn-outline-danger">Delete</button> :null}
                </td>
                </tbody>
              </table>

              <div className="mt-3">
                <Pagination>
                  <Pagination.First/>
                  <Pagination.Prev/>
                  <Pagination.Item>{1}</Pagination.Item>
                  <Pagination.Ellipsis/>
                  <Pagination.Item>{10}</Pagination.Item>
                  <Pagination.Item>{11}</Pagination.Item>
                  <Pagination.Item active disabled>{12}</Pagination.Item>
                  <Pagination.Item>{13}</Pagination.Item>
                  <Pagination.Item>{14}</Pagination.Item>
                  <Pagination.Ellipsis/>
                  <Pagination.Item>{20}</Pagination.Item>
                  <Pagination.Next/>
                  <Pagination.Last/>
                </Pagination>
              </div>
            </div>
                :null}
          </div>
        </div>
      </App>
    );
  }
}

export default EventList;
