import StoreBlock from "../components/StoreBlock";
import ProductsBlock from "../components/ProductsBlock";
import CategoriesWithProductsBlock from "../components/CategoriesWithProductsBlock";
import CategoriesAndProductsBlock from "../components/CategoriesAndProductsBlock";
import CategoryBlock from "../components/CategoryBlock";
import StoreCardBlock from "../components/StoreCardBlock";
import React from "react";
import ShoppingMallBlock from "../components/ShoppingMallBlock";

export const HOME_BLOCK_SECTIONS = [
  {
    section_type: 'store',
    block_name: 'Store',
    component: (obj) => <StoreBlock handler={obj?.handler}
                                    settings={obj?.settings}
                                    index={obj.index}
                                    dragHandleProps={obj.dragHandleProps}
                                    actions={{
                                      onDelete: obj?.onDelete,
                                      onChangeSettings: obj.onChangeSettings,
                                      toggleBlockSectionDisable: obj.toggleBlockSectionDisable
                                    }}/>
  },
  {
    section_type: 'product',
    block_name: 'Product',
    component: (obj) => <ProductsBlock handler={obj?.handler}
                                       settings={obj?.settings}
                                       setting_id={obj?.setting_id}
                                       index={obj.index}
                                       dragHandleProps={obj.dragHandleProps}
                                       toggleDragableFlag={() => obj.toggleDragableFlag()}
                                       actions={{
                                         onDelete: obj?.onDelete,
                                         onChangeSettings: obj.onChangeSettings,
                                         toggleBlockSectionDisable: obj.toggleBlockSectionDisable
                                       }}/>
  },
  {
    section_type: 'category_with_product',
    block_name: 'Categories and Products With Slider',
    component: (obj) => <CategoriesWithProductsBlock handler={obj?.handler}
                                                     settings={obj?.settings}
                                                     setting_id={obj?.setting_id}
                                                     index={obj.index}
                                                     dragHandleProps={obj.dragHandleProps}
                                                     actions={{
                                                       onDelete: obj?.onDelete,
                                                       onChangeSettings: obj.onChangeSettings,
                                                       toggleBlockSectionDisable: obj.toggleBlockSectionDisable
                                                     }}/>
  },
  {
    section_type: 'category_with_product_section_2',
    block_name: 'Categoris and Products',
    component: (obj) => <CategoriesAndProductsBlock handler={obj?.handler}
                                                    settings={obj?.settings}
                                                    setting_id={obj?.setting_id}
                                                    index={obj.index}
                                                    dragHandleProps={obj.dragHandleProps}
                                                    actions={{
                                                      onDelete: obj?.onDelete,
                                                      onChangeSettings: obj.onChangeSettings,
                                                      toggleBlockSectionDisable: obj.toggleBlockSectionDisable
                                                    }}/>
  },
  {
    section_type: 'shopping_mall_section_1', // with slider, top sell, recommendation
    block_name: 'Shopping Mall With Slider and Recommendation',
    component: (obj) => <ShoppingMallBlock handler={obj?.handler}
                                           settings={obj?.settings}
                                           setting_id={obj?.setting_id}
                                           index={obj.index}
                                           dragHandleProps={obj.dragHandleProps}
                                           actions={{
                                             onDelete: obj?.onDelete,
                                             onChangeSettings: obj.onChangeSettings,
                                             toggleBlockSectionDisable: obj.toggleBlockSectionDisable
                                           }}/>
  },
  {
    section_type: 'category',
    block_name: 'Category',
    component: (obj) => <CategoryBlock handler={obj?.handler}
                                       settings={obj?.settings}
                                       index={obj.index}
                                       dragHandleProps={obj.dragHandleProps}
                                       actions={{
                                         onDelete: obj?.onDelete,
                                         onChangeSettings: obj.onChangeSettings,
                                         toggleBlockSectionDisable: obj.toggleBlockSectionDisable
                                       }}/>
  },
  {
    section_type: 'store_with_product',
    block_name: 'Store with Product',
    component: (obj) => <StoreCardBlock handler={obj?.handler}
                                        settings={obj?.settings}
                                        index={obj.index}
                                        dragHandleProps={obj.dragHandleProps}
                                        actions={{
                                          onDelete: obj?.onDelete,
                                          onChangeSettings: obj.onChangeSettings,
                                          toggleBlockSectionDisable: obj.toggleBlockSectionDisable
                                        }}/>
  }
]

export const ACTIVE_HOME_BLOCK_SECTIONS = [
  {
    section_type: 'store',
    settings: {
      title: 'Featured Stores',
      is_disabled: false,
      is_flagship: true,
      params: {
        merchant_type: '5f2a7db3e7abc4290b6c6215',
        limit: 4
      }
    }
  },
  {
    section_type: 'product',
    settings: {
      title: 'Top Selling Products', // required,
      is_disabled: false, // required
      params: {
        limit: 6
      }
    }
  },
  {
    section_type: 'category',
    settings: {
      title: 'Top Categories',
      is_disabled: true,
      params: {
        limit: 6
      }
    }
  },
  {
    section_type: 'store_with_product',
    settings: {
      title: 'Uddokta Mall',
      is_disabled: false,
      params: {
        limit: 4
      }
    }
  }
]