import React, {Component} from 'react';
import App from "../../App";
import Moment from "moment";
import {Button, Dropdown, DropdownButton, Form, FormControl, InputGroup, Modal} from "react-bootstrap";
import {getDeviceType, getLocalAuthData} from "../../helper/utils";
import axios from "axios";
import {API_BASE_URL} from "../../helper/env";
import {getChildLocations} from "../../services/locationServices";
import {getMerchantDetails, getMerchantList, getMerchantTypeList, getMerchantPaymentMethods} from "../../services/merchantServices";
import {getStoreDetails, getStoreList} from "../../services/storeServices";
import Select from "react-select";
import styleReactSelect from "../../assets/styleReactSelect";
import {addProduct, getProductListAlt} from "../../services/productServices";
import {getCommissionList, getCommissionSummaryList, disburseCommission} from "../../services/commissionServices";
import {toast} from "react-toastify";
import { Link } from 'react-router-dom';

class DeliveryCharge extends Component {

    constructor(props) {
        super(props);
        this.state = {
            commission_summary: null,
            user_info: null,

            merchantList: [],
            StoreList: [],
            merchant_type: '',
            store_id: '',
            locations: [],
            isSubmitting: false,

            disburseModal: false,

            merchant_payment_methods: [],
            merchant_commission_summary: {},

            disburseFormData: {
                user_type: 'merchant',
                user_id: null,
                store_id: null,
                payment_method_id: null,
                transaction_amount: null,
                transaction_number: '',
                transaction_note: '',
            }
        }
    }

    handleCloseDisburseModal = () => {
        this.setState({
            disburseModal: false
        })
    }

    handleShowDisburseModal = (user_id, store_id) => {

        if ((this.state.user_id != undefined && user_id != this.state.user_id) || (this.state.store_id != undefined && store_id != this.state.store_id)) {

            this.setState({...this.state, user_id: user_id, store_id: store_id});

            getMerchantPaymentMethods({user_id: user_id, status: 'active'}).then((res) => {
                this.setState({merchant_payment_methods: res?.items, disburseModal: true});
            }).catch(errMsg => {
                toast.error(errMsg)
            });

            getCommissionSummaryList({user_id: user_id, store_id: store_id}).then((res) => {
                this.setState({...this.state, merchant_commission_summary: res?.commission_summary, user_info: res?.user_info});
            }).catch(errMsg => {
                toast.error(errMsg)
            });
        }
        this.setState({disburseModal: true});

    }


    handleInputOnChange = (e) => {
        const disburseFormData = {...this.state.disburseFormData};
        disburseFormData[e.target.name] = e.target.value
        this.setState({disburseFormData});
        console.log(this.state.disburseFormData);
    }

    submitDisburseForm = (e) => {
        e.preventDefault()

        this.setState({isSubmitting: true}, () => {
            const disburseData = {...this.state.disburseFormData}
            disburseData['user_id'] = this.state.user_id;
            disburseData['store_id'] = this.state.store_id;
            disburseCommission(disburseData).then(res => {
                // this.props.history.push(`${process.env.PUBLIC_URL}/products/list`);
                toast.success(res.message);
                if (res.status === 1) {
                    this.setState({isSubmitting: false, disburseModal: false})
                } else {
                    this.setState({isSubmitting: false})
                }
            }).catch(errMsg => {
                toast.error(errMsg);
                this.setState({isSubmitting: false})
            });
        })
    };

    render() {
        return (
            <App layout="">
                <div className="main-content">
                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">Delivery Charge</h4>
                        </div>
                        <div className="block-body">
                            <Modal size={"lg"} show={this.state.disburseModal} onHide={this.handleCloseDisburseModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Commission Disbursement</Modal.Title>
                                </Modal.Header>

                                <Form onSubmit={this.submitDisburseForm}>
                                    <Modal.Body>
                                        <div className="commission-info-cards mt-4">
                                            <div className="commission-info">
                                                <span className="title">Total Transactions</span>
                                                <span className="value">৳110000</span>
                                            </div>
                                            <div className="commission-info">
                                                <span className="title">Total Commission</span>
                                                <span className="value">৳ 5500</span>
                                            </div>
                                            <div className="commission-info">
                                                <span className="title">Total Paid</span>
                                                <span className="value">৳ 0</span>
                                            </div>
                                            <div className="commission-info">
                                                <span className="title">Total Due</span>
                                                <span className="value">৳ 5500</span>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="card-block">
                                            <div className="block-header">
                                                <h4 className="block-title">Transaction Methods</h4>
                                            </div>

                                            <div className="block-body">
                                                {
                                                   
                                                    <div className={`alert alert-warning text-center text-bold`}>
                                                        User Transaction Method not found
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <table className="info-table table-lg">
                                            <tbody>
                                            <tr>
                                                <td className="text-bold">Transaction Amount: <span className="text-danger">*</span></td>
                                                <td className="text-bold">:</td>
                                                <td className="text-bold"><Form.Control size={"sm"} min={1} type={"number"} name='transaction_amount' onChange={this.handleInputOnChange}/></td>
                                            </tr>

                                            <tr>
                                                <td className="text-bold">Transaction Number: <span className="text-danger">*</span></td>
                                                <td className="text-bold">:</td>
                                                <td className="text-bold"><Form.Control size={"sm"} name='transaction_number' onChange={this.handleInputOnChange}/></td>
                                            </tr>

                                            <tr>
                                                <td className="text-bold">Note</td>
                                                <td className="text-bold">:</td>
                                                <td className="text-bold"><Form.Control as={"textarea"} rows={3} name='transaction_note' onChange={this.handleInputOnChange}/></td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="default" onClick={this.handleCloseDisburseModal}>
                                            Close
                                        </Button>
                                        <Button variant="primary" type={`submit`} disabled={this.state.isSubmitting}>
                                            {this.state.isSubmitting ? 'Please Wait...' : 'Submit'}
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            </Modal>

                            <div className="mt-4">
                                <table className="order-table table table-striped">
                                    <thead>
                                        <tr>
                                            <th style={{width: '1%'}}>Sl</th>
                                            <th>Company Name</th>
                                            <th>Total Amount (৳)</th>
                                            <th>Paid Amount (৳)</th>
                                            <th>Due Amount (৳)</th>
                                            <th style={{width: '11%'}}>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>
                                                <div className="order-item">
                                                    <h4 className="order-id">Artisan Ltd</h4>
                                                </div>
                                            </td>
                                            <td>50000</td>
                                            <td>40000</td>
                                            <td>10000</td>
                                            <td style={{width: '11%'}}>
                                                <Button className={'w-100'} onClick={() => this.handleShowDisburseModal()}
                                                    size={"sm"} variant="outline-secondary">Disburse</Button>
                                                <Link className={'w-100 my-1 btn btn-sm btn-outline-info'} to={'/finance/delivery-charge-transaction'}
                                                    size={"sm"}>Transaction History</Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            
            </App>
        );
    }
}

export default DeliveryCharge;
