export const getItemStyle = (isDragging, draggableStyle) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    textAlign: "right",

    // change background colour if dragging
    // background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
  };
};

export const getSectionListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#f3f3f3" : "transparent",
  padding: '30px 30px 0',
  borderRadius: '10px',
  border: '1px dashed #d5d5d5',
  marginBottom: 30
});

export const getAnswerListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: 4,
  width: 250
});