// noinspection JSUnresolvedVariable,DuplicatedCode
// noinspection DuplicatedCode

import React, {Component} from 'react';
import App from "../../App";
import {Link, withRouter} from "react-router-dom";
import {
  AiOutlineTransaction, AiOutlineUser,
  FaFileExport, FaSellsy,
  FaStore, FaTruckPickup, FcApprove, FcShipped, FiFile,
  FiPlus, GiCash,
  GoLinkExternal, GrDeliver, GrTransaction,
  ImCancelCircle,
  MdArrowBack
} from "react-icons/all";
import moment from "moment";
import Product from "../../components/Product";
import Store from "../../components/Store";
import Pagination from "react-js-pagination";
import {confirmAlert} from "react-confirm-alert";
import {
  getDetail,
  getTableData,
  updateItemPartial,
  activityLogPostData, postData
} from "../../services/commonServices";
import {toast} from "react-toastify";
import {Button, Col, FormCheck, Modal, Row} from "react-bootstrap";
import {API_BASE_URL, SHOP_BASE_URL} from "../../helper/env";
import {getDeviceType, getLocalAuthData, objectToFormData} from '../../helper/utils'
import {array} from "prop-types";
import {updateDepositPaymentStatus} from "../../services/orderServices";
import {useJsonToCsv} from "react-json-csv";
import "../../assets/scss/business-block-card.scss"
import InfoCard from "../../components/InfoCard"
import {
  AiOutlineHourglass,
  BsListCheck,
  FiFileText
} from "react-icons/all"
import axios from "axios";
import parser from "html-react-parser"
import CountdownTimer from "../../components/CountdownTimer";
import '../../assets/scss/count-down-timer.css'

const {saveAsCsv} = useJsonToCsv();

class CampaignDetails extends Component {
  state = {
    campaignQueryData:{
      campaign_id: this.props.match.params.id,
      report_by: 4,
    },
    orderListQuery: {},
    remove_selected: false,
    approved_selected: false,
    isLoading: true,
    activeBlockTab: 'Campaign Detail',
    campaignDetails: {},
    notExisting: false,

    registered_stores_query: {limit: 20},
    registered_stores: [],
    approved_stores_query: {limit: 20},
    approved_stores: [],
    registered_products_query: {limit: 20},
    registered_products: [],
    approved_products_query: {limit: 20},
    approved_products: [],
    order_list: [],
    order_list_query: {limit: 20},
    pagination: {},
    limit: 10,
    selected_product_ids: [],
    selected_product_ids_for_approved: [],

    userType: null,
    isShowingProductRegisterModal: false,
    store_products: [],
    store_product_pagination: {},


    total_orders: 0,
    total_sells: 0,
    total_pending_orders: 0,
    total_confirm_orders: 0,
    total_cancel_order: 0,
    total_picked_order: 0,
    total_shipped_order: 0,
    total_delivered_order: 0,
    total_cod: 0,
    total_online_transaction: 0,
    total_bank_transaction: 0,
    total_approved_bank_transaction: 0,
    muktopaath_user_order: 0,

    total_pending_sells: 0,

    total_confirmed_sells: 0,
    total_cancel_sells: 0,
    total_picked_sells: 0,
    total_shipped_sells: 0,
    total_delivered_sells: 0,
    total_cod_sells: 0,
    total_online_transaction_sells: 0,
    total_bank_transaction_sells: 0,
    total_approved_bank_transaction_sells: 0,
    total_muktopath_sells: 0,

    orders: [],
    merchantType: [],
    storeList: [],
    queryData: {
      from_date: '',
      to_date: '',
      campaign_order: true,
      merchant_type_find: "",
      store_id: "",
    },
    activePage: 1,
    locations: [],
    businessChildLocations: [],
    status: null,
    changingStoreId: null,
    changeStatusMsg: '',
    isShowingStatusChangeConfirmModal: false,
    showCancelMessageRequired: false,
    isSubmitting: false,
    selected_order_id: "",
    selected_deposit_info: {},
    depositInfoModal: false,
    isSubmittingDepositPaymentStatus: false,
    isUploadDepositModal: false,
    depositInfo: {
      deposit_date: new Date(),
      bank_name: 'Bank Asia',
      account_number: '06933001008'
    },

  }

  changeStatus = (statusObj, order_id, callback = () => null) => {
    const headers = {
      'Content-Type': 'application/json',
      'x-api-client': getDeviceType(),
      'x-auth-token': getLocalAuthData().token,
    }
    axios.patch(`${API_BASE_URL}/order/parcel/${order_id}/change-status`, statusObj,
      {headers: headers}
    )
      .then(response => {
        callback()
        toast.success(response.data.message);

        activityLogPostData('was change order status', '/orders/parcel/' + order_id, null).then()


      })
      .catch(() => {
        callback()
        toast.error('Something went wrong');
      });
  };

  hideChangeStatusModal = () => {
    this.setState({isShowingStatusChangeConfirmModal: false})
  }

  submitChangeStatusForm = (e) => {
    e.preventDefault()
    if (this.state.changeStatusMsg === '' && this.state.status === 'Cancelled') {
      return this.setState({showCancelMessageRequired: true});
    }
    this.setState({isSubmitting: true, showCancelMessageRequired: false}, () => {
      this.changeStatus({
        status: this.state.status,
        message: this.state.changeStatusMsg
      }, this.state.changingStoreId, () => {

        const authToken = getLocalAuthData();
        const headers = {
          'Content-Type': 'application/json',
          'x-api-client': 'web',
          'x-auth-token': authToken.token,
        }

        axios.get(`${API_BASE_URL}/order-parcel`, {
          headers: headers,
          params: {
            page: this.state.pagination.page,
            status: this.state.activeBlockTab,
            campaign_id: this.state.campaignDetails._id,
          }
        })
          .then(res => {
            this.setState({orders: res.data.items, pagination: res.data.pagination, isLoading: false});
          })
          .catch(() => {
            this.setState({isLoading: false})
          });

        this.setState({
          isSubmitting: false,
          message: '',
          changingStoreId: null,
          isShowingStatusChangeConfirmModal: false
        })
      })
    })
  }

  renderPaymentStatus = (status) => {
    let payment_status = {
      0: "Unpaid",
      1: "Partially Paid",
      2: "Paid",
      3: "Cancelled",
      4: "Failed",
      5: "Refund",
      6: "Bank deposit payment in review",
      7: "Bank deposit declined",
    }
    return payment_status[status];
  }

  /* Product Component */
  renderStatus = (status) => {
    if (status === 1) {
      return ""/*<>|| <span className="text-success -badge -badge-success">/!*Active*!/</span></>*/
    } else if (status === 2) {
      return <> || <span className="text-warning -badge -badge-warning">Blocked</span></>
    }
  }

  renderIsDeleted = (value) => {
    if (value === true)
      return <> || <span className="text-danger -badge -badge-warning">Deleted</span></>
  }

  renderBStatus = (status) => {
    let sta = "";
    if (status === 1) {
      sta = "2"
    } else {
      sta = "1"
    }
    return sta;
  }

  renderPublishStatus = (status) => {
    let sta = {1: "2", 2: "3", 3: "2"};
    console.log(status);
    console.log(sta[status]);
    return sta[status];
  }

  renderPublishStatusText = (status) => {
    let sta = {1: "Drafted", 2: "Published", 3: "Unpublished"};
    let staText = {1: "success", 2: "warning", 3: "danger"};
    let staBadge = {1: "badge-success", 2: "badge-warning", 3: "badge-danger"};
    return <>|| <span className={`text-${staText[status]} -badge -${staBadge[status]}`}> {sta[status]} </span></>;
  }

  publishStatusText = (status) => {
    let sta = {1: "Drafted", 2: "Published", 3: "Unpublished"};
    return sta[status];
  }

  renderBtnText = (status) => {
    let btnText = "";
    if (status === 1) {
      btnText = "Block"
    } else {
      btnText = "Unblock"
    }
    return btnText
  }

  renderPublishBtnText = (status) => {
    let btnText = {1: "Publish", 2: "Unpublish", 3: "Publish"};
    return btnText[status]
  }

  renderAvailabilityStatus = (qnt) => {
    if (qnt === 0) {
      return <span className="badge badge-warning">Out of Stock</span>
    } else if (qnt > 0 && qnt <= 5) {
      return <span className="badge badge-warning">Low</span>
    } else {
      return <span className="badge badge-success">Available</span>
    }
  }

  handleOnChangeV2 = (e, id) => {
    console.log(e.target.checked)
    if (e.target.checked === true) {
      this.setState({
        selected_product_ids: [...this.state.selected_product_ids, id]

      }, () => {
        console.log(this.state.selected_product_ids)
        if (this.state.selected_product_ids.length >= 0) {
          this.setState({
            remove_selected: true
          })
          console.log('CONDITION 1')
        } else {
          this.setState({
            remove_selected: false
          })
          console.log('CONDITION 2')
        }
      })
    } else if (e.target.checked === false){
      if (this.state.selected_product_ids.length <= 1){
        this.setState({
          remove_selected: false
        })
      }
      this.state.selected_product_ids !== undefined && this.state.selected_product_ids.map((data, index) => {
        if (data === id) {
          this.state.selected_product_ids.splice(index, 1);
        }
      }, () => {
        console.log('pop role ids', this.state.selected_product_ids);
        if (this.state.selected_product_ids.length > 0) {
          this.setState({
            remove_selected: true
          })
          console.log('CONDITION 3')
        } else {
          this.setState({
            remove_selected: false
          })
          console.log('CONDITION 4')
        }
      });
    }

  }

  handleOnChangeV3 = (e, id) => {
    if (e.target.checked === true) {
      this.setState({
        selected_product_ids_for_approved: [...this.state.selected_product_ids_for_approved, id]

      }, () => {
        if (this.state.selected_product_ids_for_approved.length >= 0) {
          this.setState({
            approved_selected: true
          })
        } else {
          this.setState({
            approved_selected: false
          })
        }
      })
    } else {
      if (this.state.selected_product_ids_for_approved.length <= 1){
        this.setState({
          approved_selected: false
        })
      }
      this.state.selected_product_ids_for_approved !== undefined && this.state.selected_product_ids_for_approved.map((data, index) => {
        if (data === id) {
          this.state.selected_product_ids_for_approved.splice(index, 1);
        }
      }, () => {
        if (this.state.selected_product_ids_for_approved.length >= 0) {
          this.setState({
            approved_selected: true
          })
        } else {
          this.setState({
            approved_selected: false
          })
        }
      });
    }

  }

  /* DEPOSIT MODAL START */
  handleDepositModalShow = (orderId, amount) => {
    const depositInfo = {...this.state.depositInfo}
    depositInfo.order_id = orderId
    depositInfo.amount = amount.toString()
    this.setState({depositInfo, isUploadDepositModal: true})
  }

  handleDepositModalClose = () => {
    this.setState({isUploadDepositModal: false})
  }

  uploadDepositInfo = (data) => {
    return new Promise((resolve, reject) =>
      axios.post(`${API_BASE_URL}/payment/save-bank-deposit`, data, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-client': 'web',
          'x-auth-token': getLocalAuthData().token,
        }
      })
        .then(res => res.data.status === 1 ? resolve(res.data) : reject(res.data.message))
        .catch(err => reject(err.message)))
  }

  handleSubmitDepositInfo = (e) => {
    e.preventDefault()
    if (!this.state.depositInfo.order_id) alert('Order id is Required')
    this.uploadDepositInfo(this.state.depositInfo).then(res => {
      toast.success(res.message)
      this.getParcels();
      this.setState({isUploadDepositModal: false})
    })
      .catch(err => {
        toast.error(err.message)
      })
  }

  uploadImage = (data) => {
    return new Promise((resolve, reject) =>
      axios.post(`${API_BASE_URL}/images`, data, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-client': 'web',
          'x-auth-token': getLocalAuthData().token,
        }
      })
        .then(res => resolve(res.data))
        .catch(err => reject(err.message)))
  }

  depositInputOnChange = async (e) => {
    const depositInfo = {...this.state.depositInfo}
    if (e.target.type !== 'file') {
      depositInfo[e.target.name] = e.target.value
      this.setState({depositInfo})
    } else {
      this.setState({isImageUploading: true})
      this.uploadImage(objectToFormData({image: e.target.files[0], image_category: 'payment'}))
        .then(res => {
          depositInfo.deposit_slip = res?.image?.actual_img_path
          this.setState({depositInfo, isImageUploading: false})
        })
        .catch(() => {
          this.setState({isImageUploading: false})
        })
    }
  }


  getReport = () =>{
    getTableData('/dashboard', this.state.campaignQueryData).then((res)=>{
      this.setState({
        campaign_reports : res?.campaign_reports
      })
    })
  }

  /* END */
  componentDidMount() {
    const authData = getLocalAuthData()
    this.setState({userType: authData?.type})

    const campaignId = this.props.match.params.id

    this.getReport()

   /* getTableData('/dashboard', this.state.campaignQueryData).then((res)=>{
      this.setState({
        campaign_reports : res?.campaign_reports
      })
    })*/

    //this.getReportList(campaignId)

    const authToken = getLocalAuthData();
    const headers = {
      'Content-Type': 'application/json',
      'x-api-client': 'web',
      'x-auth-token': authToken.token,
    }
    axios.get(`${API_BASE_URL}/merchantType`, {headers: headers})
      .then(res => {
        this.setState({merchantType: res.data.merchant_type_obj});
      })
      .catch(err => {
      });


    getDetail(`/campaign/${campaignId}`, {}).then(res => {
      this.setState({campaignDetails: res.campaign, isLoading: false}, () => {
        // this.getOrderList(campaignId);
      })

      const registered_stores = this.getRegisteredStores(res.campaign.registered_stores);

      const approved_stores = this.getApprovedStores(res.campaign.stores);


      const registered_products = this.getRegisteredProducts(res.campaign.registered_products);

      const approved_products = this.getApprovedProducts(res.campaign.products);

      Promise.all([registered_stores, approved_stores, registered_products, approved_products]).then(res => {
        this.setState({isLoading: false})
      }).catch((err) => {
        this.setState({isLoading: false})
      })
    }).catch(errMsg => {
      this.setState({isLoading: false, notExisting: true})
      this.setState({isLoading: false})
    })
  }


  getReportList = (campaignId) => {

    console.log(this.state.orderListQuery)


    this.setState({
      total_orders: 0,
      total_sells: 0,
      total_pending_orders: 0,
      total_confirm_orders: 0,
      total_cancel_order: 0,
      total_picked_order: 0,
      total_shipped_order: 0,
      total_delivered_order: 0,
      total_cod: 0,
      total_online_transaction: 0,
      total_bank_transaction: 0,
      total_approved_bank_transaction: 0,
      muktopaath_user_order: 0,

      total_pending_sells: 0,

      total_confirmed_sells: 0,
      total_cancel_sells: 0,
      total_picked_sells: 0,
      total_shipped_sells: 0,
      total_delivered_sells: 0,
      total_cod_sells: 0,
      total_online_transaction_sells: 0,
      total_bank_transaction_sells: 0,
      total_approved_bank_transaction_sells: 0,
      total_muktopath_sells: 0,
    }, () => {

    })

    getTableData('/order-parcel',
      {
        ...this.state.orderListQuery,
        campaign_id: campaignId,
        campaign_order: true
      }).then(res => {


    /*  let total_order_pending = 0;
      let total_confirmed_order = 0;
      let total_cancelled_order = 0;
      let total_picked_order = 0;
      let total_shipped_order = 0;
      let total_delivered_order = 0;

      let total_cod = 0;
      let total_online_transaction = 0;
      let total_bank_transaction = 0;
      let total_approved_bank_transaction = 0;
      let total_muktopaath_user_order = 0;

      let total_orders = 0;
      let total_sell = 0;
      let total_pending_sells = 0;

      let total_confirmed_sells = 0;
      let total_cancel_sells = 0;
      let total_picked_sells = 0;
      let total_shipped_sells = 0;
      let total_delivered_sells = 0;
      let total_cod_sells = 0;
      let total_online_transaction_sells = 0;
      let total_bank_transaction_sells = 0;
      let total_approved_bank_transaction_sells = 0;
      let total_muktopath_sells = 0;
*/
/*

      res.items.map((data, index) => {

        this.setState({
          total_orders: res.items.length
        })

        total_orders++
        this.setState({
          total_orders: total_orders
        })


        if (data?.order_parcels?.status === "Pending") {
          total_order_pending++;
          total_pending_sells = total_pending_sells + data?.order_parcels?.parcel_grand_total
          this.setState({
            total_pending_orders: total_order_pending,
            total_pending_sells: total_pending_sells
          })
        } else if (data?.order_parcels?.status === "Confirmed") {
          total_confirmed_order++
          total_confirmed_sells = total_confirmed_sells + data?.order_parcels?.parcel_grand_total
          this.setState({
            total_confirm_orders: total_confirmed_order,
            total_confirmed_sells: total_confirmed_sells
          })
        } else if (data?.order_parcels?.status === "Cancelled") {
          total_cancelled_order++
          total_cancel_sells = total_cancel_sells + data?.order_parcels?.parcel_grand_total
          this.setState({
            total_cancel_order: total_cancelled_order,
            total_cancel_sells: total_cancel_sells
          })
        } else if (data?.order_parcels?.status === "Picked") {
          total_picked_order++
          total_picked_sells = total_picked_sells + data?.order_parcels?.parcel_grand_total
          this.setState({
            total_picked_order: total_picked_order,
            total_picked_sells: total_picked_sells
          })
        } else if (data?.order_parcels?.status === "Shipped") {
          total_shipped_order++
          total_shipped_sells = total_shipped_sells + data?.order_parcels?.parcel_grand_total
          this.setState({
            total_picked_order: total_shipped_order,
            total_shipped_sells: total_shipped_sells
          })
        } else if (data?.order_parcels?.status === "Delivered") {
          total_delivered_order++
          this.setState({
            total_picked_order: total_delivered_order
          })
        }

        if (data?.payment_method?.name === "COD") {
          total_cod++
          total_cod_sells = total_cod_sells + data?.order_parcels?.parcel_grand_total
          this.setState({
            total_cod: total_cod,
            total_cod_sells: total_cod_sells
          })
        }

        if (data?.payment_method?.name === "online_payment") {
          total_online_transaction++
          total_online_transaction_sells = total_online_transaction_sells + data?.order_parcels?.parcel_grand_total
          this.setState({
            total_online_transaction: total_online_transaction,
            total_online_transaction_sells: total_online_transaction_sells
          })
        }

        if (data?.payment_method?.name === "Bank Payment") {
          total_bank_transaction++
          total_bank_transaction_sells = total_online_transaction_sells + data?.order_parcels?.parcel_grand_total
          this.setState({
            total_bank_transaction: total_bank_transaction,
            total_bank_transaction_sells: total_bank_transaction_sells
          })
        }

        if (data?.order_parcels?.deposit_info?.deposit_status === "Approved") {
          total_approved_bank_transaction++
          total_approved_bank_transaction_sells = total_approved_bank_transaction_sells + data?.order_parcels?.parcel_grand_total
          this.setState({
            total_approved_bank_transaction: total_approved_bank_transaction,
            total_bank_transaction_sells: total_approved_bank_transaction_sells
          })
        }

        if (data?.customer_info?.muktopath_user === true) {
          total_muktopaath_user_order++
          total_muktopath_sells = total_muktopath_sells + data?.order_parcels?.parcel_grand_total
          this.setState({
            total_muktopaath_user_order: total_muktopaath_user_order,
            total_muktopath_sells: total_muktopath_sells
          })
        }

        if (data?.order_parcels?.parcel_grand_total) {
          total_sell = total_sell + data?.order_parcels?.parcel_grand_total
          this.setState({
            total_sells: total_sell
          })
        }


      })
*/


    }).catch((err) => {
      this.setState({isLoading: false})
    })
  }

  getRegisteredStores = () => {
    const campaignId = this.props.match.params.id
    getTableData(`/campaign/admin/stores`, {campaign_id: campaignId, registered_stores: true}).then(res => {
      this.setState({registered_stores: res.items, registeredStoresPagination: res.pagination});
    }).catch(() => {
    });
  }

  getApprovedStores = () => {
    const campaignId = this.props.match.params.id
    getTableData(`/campaign/admin/stores`, {campaign_id: campaignId, approved_stores: true}).then(res => {
      this.setState({approved_stores: res.items, approvedStoresPagination: res.pagination});
    }).catch(() => {
    });
  }

  getRegisteredProducts = () => {
    const campaignId = this.props.match.params.id
    getTableData(`/campaign/admin/products`, {campaign_id: campaignId, registered_products: true}).then(res => {
      this.setState({registered_products: res.products, registeredProductsPagination: res.pagination});
    }).catch(() => {
    });
  }

  getApprovedProducts = () => {
    const campaignId = this.props.match.params.id
    getTableData(`/campaign/admin/products`, {campaign_id: campaignId, approved_products: true}).then(res => {
      this.setState({approved_products: res.products, approvedProductsPagination: res.pagination});
    }).catch(() => {
    });
  }

  handleBlockTab = (label) => {
    this.setState({activeBlockTab: label});
  }


  //Send notice to merchant when approved
  sendNotice = (id) =>{
    const campaignId = this.props.match.params.id
    const title = "Congratulation , your store was approved."
    const message = "Campaign name : "+this.state?.campaignDetails?.campaign_title
    const product = null
    const userType = "merchant"

    const formData  = {
      title: title,
      message: message,
      store_id: id,
      campaign_id: campaignId,
      product_id: product,
      user_type: userType
    }

    postData('/notice', formData).then((res)=>{
      console.log(res)
    })

  }

  /* START STORE LIST */
  approveCampaignStore = (id) => {
    confirmAlert({
      title: 'Approve',
      message: 'Are you sure to approve this store?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let stores = [...this.state.campaignDetails.stores]
            if (!stores.includes(id)) {
              stores.push(id)
              updateItemPartial(`/campaign/${this.state.campaignDetails._id}/partial`, {stores: stores}).then(res => {

                if (res.status === 1) {
                  let campaignDetails = {...this.state.campaignDetails}
                  campaignDetails['stores'] = stores
                  this.setState({campaignDetails}, () => {
                    toast.success(res.message)
                    this.sendNotice(id)
                    this.getApprovedStores(stores);
                  });
                } else {
                  toast.warning(res.message)
                }

              }).catch(errMsg => {
                toast.error(errMsg)
              })
            } else {
              toast.warning("Already approved");
            }
          }
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }

  removeCampaignStore = (id) => {
    confirmAlert({
      title: 'Remove',
      message: 'Are you sure to remove this store?',
      buttons: [{
        label: 'Yes',
        onClick: () => {
          const campaignId = this.props.match.params.id
          return updateItemPartial(`/campaign/${campaignId}/remove`, {store: id}).then(res => {
            if (res.status === 1) {
              toast.success(res.message)
              this.getApprovedStores();
            } else {
              toast.warning(res.message)
            }
          }).catch(errMsg => {
            toast.error(errMsg)
          })
        }
      }, {
        label: 'No',
      }]
    });
  }
  /* END STORE LIST */

  /* START PRODUCT LIST RELATED */
  approveCampaignProduct = (id) => {
    confirmAlert({
      title: 'Approve',
      message: 'Are you sure to approve this product?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let products = [...this.state.campaignDetails.products]
            if (!products.includes(id)) {
              products.push(id)
              updateItemPartial(`/campaign/${this.state.campaignDetails._id}/partial`, {products: products}).then(res => {

                if (res.status === 1) {
                  let campaignDetails = {...this.state.campaignDetails}
                  campaignDetails['products'] = products
                  this.setState({campaignDetails}, () => {
                    toast.success(res.message)
                    this.getApprovedProducts(this.state.campaignDetails.products);
                  });
                } else {
                  toast.warning(res.message)
                }

              }).catch(errMsg => {
                toast.error(errMsg)
              })
            } else {
              toast.warning("Already approved");
            }
          }
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }

  removeCampaignProduct = (id) => {
    confirmAlert({
      title: 'Remove',
      message: 'Are you sure to remove this product?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let products = [...this.state.campaignDetails.products]
            products.splice(products.indexOf(id), 1);
            updateItemPartial(`/campaign/${this.state.campaignDetails._id}/partial`, {products: products}).then(res => {

              if (res.status === 1) {
                let campaignDetails = {...this.state.campaignDetails}
                campaignDetails['products'] = products
                this.setState({campaignDetails}, () => {
                  toast.success(res.message)
                  this.getApprovedProducts(products);
                });
              } else {
                toast.warning(res.message)
              }

            }).catch(errMsg => {
              toast.error(errMsg)
            })
          }
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }

  approvedSelectedProductV2 = async () => {
    let products = [...this.state.campaignDetails.products]

    await this.state.selected_product_ids_for_approved.map((id, index) => {
      products.push(id)
    })
    await updateItemPartial(`/campaign/${this.state.campaignDetails._id}/partial`, {products: products}).then(res => {

      if (res.status === 1) {
        let campaignDetails = {...this.state.campaignDetails}
        campaignDetails['products'] = products
        this.setState({campaignDetails}, () => {
          toast.success(res.message)
          this.getApprovedProducts(this.state.campaignDetails.products);
          window.location.href = '/promotions/campaign/details/' + this.props.match.params.id
        });
      } else {
        toast.warning(res.message)
      }

    }).catch(errMsg => {
      toast.error(errMsg)
    })
  }

  removeCampaignProductV2 = async () => {
    let products = [...this.state.campaignDetails.products]
    await this.state.selected_product_ids.map((id, index) => {
      products.splice(products.indexOf(id), 1);
    })

    console.log('deleted product', products)
    await updateItemPartial(`/campaign/${this.state.campaignDetails._id}/partial`, {products: products}).then(res => {

      if (res.status === 1) {
        let campaignDetails = {...this.state.campaignDetails}
        campaignDetails['products'] = products
        this.setState({campaignDetails}, () => {
          toast.success(res.message)
          this.getApprovedProducts(products);

          window.location.href = '/promotions/campaign/details/' + this.props.match.params.id

        });
      } else {
        toast.warning(res.message)
      }

    }).catch(errMsg => {
      toast.error(errMsg)
    })


    await this.getApprovedProducts(products)
  }

  handlePageChange = (pageNumber) => {
    this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber}, () => {
      this.getQueryData();
    });
  }
  /* END PRODUCT LIST RELATED */

  /* START HANDLE STORE SETTING */
  handleInputOnChange = (e) => {
    const formData = {...this.state.formData}
    formData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({formData})
  }

  handleInputOnChangeV2 = (e) => {
    this.setState({campaignQueryData: {...this.state.campaignQueryData, [e.target.name]: e.target.value}}, () => {
      console.log(this.state.campaignQueryData)
    });
  }
  /* END HANDLE STORE SETTING */

  handleProductListModal = (storeId, campaignId) => {
    console.log(storeId);
    console.log(campaignId);
    this.setState({isShowingProductRegisterModal: true, selectedCampaignId: campaignId, storeId: storeId}, () => {
      this.getStoreProducts(storeId, 1);
    })
  }

  getStoreProducts = (storeId, page) => {
    getTableData(`/product/`, {store_id: storeId, campaign_product: true, page: page, limit: 20}).then(res => {
      if (res.status === 1) {
        this.setState({store_products: res.products, store_product_pagination: res.pagination})
      } else {
        toast.warning(res.message);
      }
    }).catch(err => {
      console.log(err);
      toast.error(err.message)
    })
  }

  handleCloseProductRegisterModal = (e) => {
    this.setState({
      isShowingProductRegisterModal: false,
      selectedCampaignId: "",
      store_products: [],
      store_product_pagination: {}
    })
  }

  addProductInCampaign = (productId) => {

    getDetail(`/campaign/${this.state.selectedCampaignId}`).then(res => {

      if (res.status === 1) {

        let campaign_info = res.campaign;
        let registered_products = campaign_info.registered_products;

        if (!registered_products.includes(productId)) {
          registered_products.push(productId)
          updateItemPartial(`/campaign/${this.state.selectedCampaignId}/partial`, {registered_products: registered_products}).then(res => {

            if (res.status === 1) {
              let campaignDetails = {...this.state.campaignDetails}
              campaignDetails['registered_products'] = registered_products
              this.setState({campaignDetails}, () => {
                toast.success(res.message)
                this.getRegisteredProducts(registered_products);
              });
            } else {
              toast.warning(res.message)
            }

          }).catch(err => {
            console.log(err);
            toast.error(err.message);
          })
        } else {
          toast.warning("product is already registered");
        }


      } else {
        toast.warning(res.message)
      }
    }).catch(errMsg => {
      console.log(errMsg);
      toast.error(errMsg.message)
    });

  }

  handleStoreProductsPageChange = async (pageNumber) => {
    console.log(pageNumber);
    this.getStoreProducts(this.state.storeId, pageNumber);
  }

  handleRegisteredStorePageChange = async (page) => {
    let registered_stores_query = {...this.state.registered_stores_query}
    registered_stores_query['page'] = page;
    this.setState({...this.state.registered_stores_query, page: page}, () => {
      this.getRegisteredStores(this.state.campaignDetails.registered_stores);
    });
  }

  handleApprovedStorePageChange = async (page) => {
    let approved_stores_query = {...this.state.approved_stores_query}
    approved_stores_query['page'] = page;
    this.setState({approved_stores_query}, () => {
      this.getApprovedStores(this.state.campaignDetails.stores);
    });
  }

  handleRegisteredProductsPageChange = async (page) => {
    let registered_products_query = {...this.state.registered_products_query}
    registered_products_query['page'] = page;
    this.setState({registered_products_query}, () => {
      console.log(this.state.registered_products_query);
      this.getRegisteredProducts(this.state.campaignDetails.registered_products);
    })
  }

  handleApprovedProductsPageChange = async (page) => {
    let approved_products_query = {...this.state.approved_products_query}
    approved_products_query['page'] = page;
    this.setState({approved_products_query}, () => {
      this.getApprovedProducts(this.state.campaignDetails.products);
    });
  }

  ShowStatusOption = (Status) => {
    let options = array;
    if (Status === "Delivered") {
      options = [{
        value: 'Delivered',
        label: 'Delivered'
      }
      ]
    } else if (Status === "Cancelled") {
      options = [{
        value: 'Cancelled',
        label: 'Cancelled'
      }
      ]
    } else {
      options = [{
        value: 'Pending',
        label: 'Pending',
      }, {
        value: 'Confirmed',
        label: 'Confirmed'
      }, {
        value: 'Processing',
        label: 'Processing'
      }, {
        value: 'Picked',
        label: 'Picked'
      }, {
        value: 'Shipped',
        label: 'Shipped'
      }, {
        value: 'Delivered',
        label: 'Delivered'
      }, {
        value: 'Cancelled',
        label: 'Cancelled'
      }
      ]
    }
    return options;
  }

  renderSelectedShippingEstimatedTime = (shippingOptions = [], shipping_option) => {
    if (shippingOptions.length > 0) {
      let selected_shipping_option = shippingOptions.filter(obj => obj.shipping_option === shipping_option)
      if (selected_shipping_option?.length > 0) {
        return selected_shipping_option[0].estimated_time;
      }
    } else {
      return "";
    }
  }

  showDepositInfo = (order_id, deposit_info, payment_status) => {
    this.setState({
      selected_order_id: order_id,
      selected_deposit_info: deposit_info,
      depositInfoModal: true,
      current_payment_status: payment_status
    }, () => {
      console.log(this.state.selected_order_id);
      console.log(this.state.selected_deposit_info);
    });
  }

  updateDepositPaymentStatus = (order_id, payment_status) => {
    if (order_id && payment_status) {
      updateDepositPaymentStatus({order_id: order_id, payment_status: payment_status}).then(async (res) => {
        if (res.status === 1) {
          toast.success(res.message);

          /* RECALL LIST */
          await this.getQueryData();
          this.setState({depositInfoModal: false});

        } else {
          toast.error(res.message);
        }
      }).catch(function (e) {
        console.log(e.message);
      });
    } else {
      console.log("order Id or payment status missing");
    }
  }

  handleCloseDepositInfoModal = () => {
    this.setState({depositInfoModal: false});
  }

  handleExportFileReport = async (fileType) => {
    let mData = [];


    // for quantity
    mData.push({
      '': "Quantity",
      'Total Orders': this.state?.campaign_reports?.total_order,
      'Total Sells': '',
      'Total Pending Order': this.state?.campaign_reports?.pending_order,
      'Total Confirmed Order': this.state?.campaign_reports?.confirmed_order,
      'Total Processing Order': this.state?.campaign_reports?.processing_order,
      'Total Cancel': this.state?.campaign_reports?.cancel_order,
      'Total Picked': this.state?.campaign_reports?.picked_order,
      'Total Shipped': this.state?.campaign_reports?.shipped_order,
      'Total Deliver Order': this.state?.campaign_reports?.delivered_order,
      'Total COD': this.state?.campaign_reports?.total_cod,
      'Total Online Transaction': this.state?.campaign_reports?.total_online_transaction,
      'Total bank Transaction': this.state?.campaign_reports?.total_bank_transaction,
      'Total Approved Bank Transaction': this.state?.campaign_reports?.total_approved_bank_transaction,
      'MuktoPaath User Orders': this.state?.campaign_reports?.total_muktopaath_order,

    });

    //for amount
    mData.push({
      '': "Amount",
      'Total Orders': '',
      'Total Sells': this.state?.campaign_reports?.total_sells,
      'Total Pending Order': this.state?.campaign_reports?.total_pending_sells,
      'Total Confirmed Order': this.state?.campaign_reports?.total_confirmed_sells,
      'Total Processing Order': this.state?.campaign_reports?.processing_sells,
      'Total Cancel': this.state?.campaign_reports?.total_cancel_sells,
      'Total Picked': this.state?.campaign_reports?.total_picked_sells,
      'Total Shipped': this.state?.campaign_reports?.total_shipped_sells,
      'Total Deliver Order': this.state?.campaign_reports?.total_delivered_sells,
      'Total COD': this.state?.campaign_reports?.total_cod_sells,
      'Total Online Transaction': this.state?.campaign_reports?.total_online_transaction_sells,
      'Total bank Transaction': this.state?.campaign_reports?.total_bank_transaction_sells,
      'Total Approved Bank Transaction': this.state?.campaign_reports?.total_approved_transaction_sells,
      'MuktoPaath User Orders': this.state?.campaign_reports?.total_muktopaath_sells,

    });

    const filename = 'EkShop-Campaign Report',
      fields = {
        '': "",
        'Total Orders': "Total Orders",
        'Total Sells': "Total Sells",
        'Total Pending Order': "Total Pending Order",
        'Total Confirmed Order': "Total Confirmed Order",
        'Total Cancel': "Total Cancel",
        'Total Picked': "Total Picked",
        'Total Shipped': "Total Shipped",
        'Total Deliver Order': "Total Deliver Order",
        'Total COD': "Total COD",
        'Total Online Transaction': "Total Online Transaction",
        'Total bank Transaction': "Total bank Transaction",
        'Total Approved Bank Transaction': "Total Approved Bank Transaction",
        'MuktoPaath User Orders': "MuktoPaath User Orders",
      },
      style = {
        padding: "30px",
        maxHeight: "20px"
      },
      data = mData,
      text = "Convert Json to Csv";
    saveAsCsv({data, fields, filename});

    activityLogPostData('was export campaign report ', '/promotions/campaign/details/'+this.props.match.params.id, this.state?.campaignDetails?.campaign_title ).then()

  }



  renderMerchantType = () => {
    const merchantType = this.state.merchantType;
    if (merchantType) {
      return (
        <>
          {merchantType.map((items, index) =>
            <option key={index} value={`${items._id}`}>{items.type_name}</option>
          )}
        </>
      )
    }
  };

  renderStoreId = () => {
    const approved_stores = this.state.approved_stores;
    if (approved_stores) {
      return (
        <>
          {approved_stores.map((items, index) =>
            <option key={index} value={`${items.id}`}>{items.label}</option>
          )}
        </>
      )
    }
  };

  handleSearchFormDataChange = (e) => {
    const merchant_type = e.target.name;
    const merchant_type_id = e.target.value;
    if (merchant_type === "merchant_type") {
      const headers = {
        'Content-Type': 'application/json',
        'x-api-client': getDeviceType(),
        'x-auth-token': getLocalAuthData().token,
      }
      axios.get(`${API_BASE_URL}/store?merchant_type=${merchant_type_id}&lite_data=1&campaign_order=true`, {headers: headers})
        .then(res => {
          this.setState({storeList: res.data.items});
        })
        .catch(err => {
          console.log(err)
        });
    }
    this.setState({
      queryData: {...this.state.queryData, [e.target.name]: e.target.value},
      order_list_query: {...this.state.queryData, [e.target.name]: e.target.value}
    });
  };

  handleSearchFormDataChangeV2 = (e) => {

    const formData = {...this.state.formData};
    formData[e.target.name] = e.target.value;
    this.setState({formData, productList: []})


  };


  exportList = async (fileType)  =>  {
    let mData = [];


    this.state.approved_products.map((data, index)=>{



      mData.push({
        'Index': index++ ,
        'product Name': data?.name ,
        'Create Date':  moment(data?.createdAt).format('hh:mm a dd/mm/yyyy'),
        'Status': this.publishStatusText(data.publish_status),
        'Store Name': data?.store_info?.store_name,
        'Min Price':data.min_price,
        'Max Price': data.max_price,
        // 'Available': data.sku_info?.quantity,
        // 'Stock': this.renderAvailabilityStatus(data.sku_info?.quantity),
        // 'Rating': data?.avg_score/data?.t_n_of_score,
        'Total Sold': data?.t_sold,
        'Campaign Orders': data?.c_orders ? data?.c_orders : 'Not available',
      });
    })



    const filename = 'EkShop-Approved Product Report',
        fields = {
          'Index': "Index",
          'product Name': "product Name",
          'Create Date': "Create Date",
          'Status': "Status",
          'Store Name': "Store Name",
          'Min Price': "Min Price",
          'Max Price': "Max Price",
          // 'Available': "Available",
          // 'Stock': "Stock",
          // 'Rating': "Rating",
          'Total Sold': "Total Sold",
          'Campaign Orders': "Campaign Orders",
        },
        style = {
          padding: "40px",
          maxHeight: "20px"
        },
        data = mData,
        text = "Convert Json to Csv";
    saveAsCsv({data, fields, filename});

    activityLogPostData('was export Approved Campaign Product list', '/promotions/campaign/details').then()

  }


  getQueryData = () => {
    const headers = {
      'Content-Type': 'application/json',
      'x-api-client': 'web',
      'x-auth-token': getLocalAuthData().token,
    }
    let queryData = "";
    // eslint-disable-next-line array-callback-return
    Object.entries(this.state.queryData).map(([key, val]) => {
        queryData = queryData.concat(key + "=" + val + "&");
      }
    )


    console.log(this.state.order_list_query)


    this.setState({order_list: []}, () => {

      /* if (this.state.queryData.store_id === "" && this.state.queryData.merchant_type_find === ""){
          this.state.approved_stores.map((data ,index)=>{
                  getTableData('/order-parcel' ,{campaign_id: this.state?.campaignDetails?._id, campaign_order: true , /!*store_id : "60091beae3dbf50a4d2332d4"*!/ store_id: data?._id}).then(res=>{
                      this.setState({
                          order_list : {...this.state.order_list, ...res}
                      })})

          })
      }else if (this.state.queryData.store_id !== "" && this.state.queryData.merchant_type_find === ""){
           this.state.approved_stores.map((data ,index)=> {

               getTableData('/order-parcel', {...this.state.queryData, campaign_order: true, campaign_id: this.state?.campaignDetails?._id, store_id: data._id
               }).then(res => {
                   this.setState({
                       order_list: {...this.state.order_list, ...res}
                   }, () => {
                   })
               })
           })

       }else if (this.state.queryData.store_id === "" && this.state.queryData.merchant_type_find !== ""){
           this.state.approved_stores.map((data ,index)=>{

               getTableData('/order-parcel' ,{...this.state.queryData , campaign_order: true ,merchant_type_find : this.state.queryData.merchant_type_find , store_id: data?._id }).then(res=>{
                   this.setState({
                       order_list : {...this.state.order_list, ...res}
                   },()=>{})})
           })
       }else  if (this.state.queryData.store_id !== "" && this.state.queryData.merchant_type_find !== ""){
           getTableData('/order-parcel' ,{campaign_id: this.state?.campaignDetails?._id, campaign_order: true ,merchant_type_find : this.state.queryData.merchant_type_find , store_id: this.state.queryData.store_id }).then(res=>{
               this.setState({
                   order_list : {...this.state.order_list, ...res}
               },()=>{})})
       }

*/


    })


  }

  render() {
    const {campaignDetails, registered_stores, approved_stores, registered_products, approved_products} = this.state

    return (
      <App layout={"boxed"}>
        <div className="main-content">
          {getLocalAuthData()?.permission?.includes('campaign_details') ?
            <>
              <div className="page-header">
                {campaignDetails !== undefined ?<h2 className="page-title">
                  Campaign Details ({campaignDetails?.campaign_title})
                  <a target={'_blank'} rel="noopener noreferrer" className={'ml-3 text-primary'} href={`${SHOP_BASE_URL}/campaigns/${campaignDetails?.campaign_slug}`}><GoLinkExternal/></a>
                </h2>: null}
                <div className="ml-auto">
                  <Link to={`${process.env.PUBLIC_URL}/promotions/campaign`} className={`btn btn-sm btn-link`}><MdArrowBack/> Back to List</Link>
                  {this.state.activeBlockTab === 'Approved Products' ?  <button onClick={this.exportList} className="btn btn-sm btn-primary" style={{marginRight : 10}}><FiFile/> Export List</button> : null}
                  {getLocalAuthData()?.permission?.includes('campaign_add') && <Link to={`${process.env.PUBLIC_URL}/promotions/campaign/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add Campaign</Link>}

                </div>
              </div>
              <>
                {getLocalAuthData()?.permission?.includes('campaign_details') &&
                <>{campaignDetails !== undefined ?
                    <div className="card-block">
                  <ul className="desc-header-nav">
                    <li className={this.state.activeBlockTab === 'Campaign Detail' ? 'active' : ''} value="">
                      <span onClick={() => this.handleBlockTab('Campaign Detail')}>Campaign Detail</span>
                    </li>
                    <li className={this.state.activeBlockTab === 'Registered Stores' ? 'active' : ''} value="">
                      <span onClick={() => this.handleBlockTab('Registered Stores')}>Registered Stores
                        <span className={'text-danger ml-3'}>{this.state?.registeredStoresPagination?.totalDocs}</span>
                      </span>
                    </li>
                    <li className={this.state.activeBlockTab === 'Approved Stores' ? 'active' : ''} value="">
                      <span onClick={() => this.handleBlockTab('Approved Stores')}>
                        Approved Stores<span className={'text-success ml-3'}>{this.state?.approvedStoresPagination?.totalDocs}</span>
                      </span>
                    </li>
                    <li className={this.state.activeBlockTab === 'Registered Products' ? 'active' : ''} value="">
                      <span onClick={() => this.handleBlockTab('Registered Products')}>
                        Registered Products<span className={'text-danger ml-3'}>{this.state?.registeredProductsPagination?.totalDocs}</span>
                      </span>
                    </li>
                    <li className={this.state.activeBlockTab === 'Approved Products' ? 'active' : ''} value="">
                      <span onClick={() => this.handleBlockTab('Approved Products')}>
                        Approved Products<span className={'text-success ml-3'}>{this.state?.approvedProductsPagination?.totalDocs}</span>
                      </span>
                    </li>
                    <li className={this.state.activeBlockTab === 'Order Report' ? 'active' : ''} value="">
                      <span onClick={() => this.handleBlockTab('Order Report')}>Order Report</span>
                    </li>
                  </ul>
                  {this.state.activeBlockTab === 'Campaign Detail' &&
                  <div className="card-block">
                    <div className="merchant-campaign-details">
                      <div className="campaign-details-banner">
                        <img src={campaignDetails?.campaign_banner ? campaignDetails?.campaign_banner[0]?.img : ''} alt="banner Image"/>
                        <div className="campaign-text">
                          <h4 className="campaign-title">{campaignDetails?.campaign_title}</h4>
                          <p className="campaign-descriptin">
                            <strong>Short Description: </strong>{parser('' + campaignDetails?.campaign_details + '')}
                          </p>
                        </div>

                        <div className="campaign-daterange">
                          {/*Start date : {moment(campaignDetails?.start_date).format('ll')}*/}
                          {/*TODO: Offer will start*/}
                          {(moment().diff(campaignDetails?.start_date ,'minutes',true) < 0) ?
                              <>
                                <span className="campaign-daterange-title">Campaign will start</span>
                                <CountdownTimer endTime={campaignDetails?.start_date} decrement={0} type={2}/>
                              </>
                              :
                              <>{moment().diff(campaignDetails?.start_date ,'minutes',true) > 0 &&
                                 moment().diff(campaignDetails?.end_date ,'minutes',true) < 0 &&
                              /*TODO: Offer will end*/
                              <>
                                <span className="campaign-daterange-title">Campaign will end</span>
                                <CountdownTimer endTime={campaignDetails?.end_date} decrement={0} type={2}/>
                              </>
                              }</>
                          }
                        </div>
                        <div className="campaign-date-count-area">
                          <div className="last-registration-date"><h5>Start date <span><span>: </span>{moment(campaignDetails?.start_date).format('ll')} {moment(campaignDetails?.start_date).format('hh:mm a')} </span></h5></div>
                          <div className="last-registration-date"><h5>End date <span><span>: </span>{moment(campaignDetails?.end_date).format('ll')} {moment(campaignDetails?.end_date).format('hh:mm a  ')}</span></h5></div>
                          <div className="campaing-coundown-date">
                           <h5>Registration last date <span><span>: </span>{moment(campaignDetails?.registration_last_date).format('ll')} {moment(campaignDetails?.registration_last_date).format('hh:mm a')}</span></h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  }

                  {this.state.activeBlockTab === 'Registered Stores' && <>
                    <div className="block-body">
                      {registered_stores?.length > 0 ?
                        <>
                          <div className="block-header">
                            <h4 className="block-title">Registered Store List</h4>
                          </div>
                          <div className="block-body position-relative">
                            <Store data={{stores: registered_stores, pagingCounter: this.state.registeredStoresPagination.pagingCounter}} approvedStores={this.state.approved_stores} approveCampaignStore={this.approveCampaignStore}/>
                            <Pagination itemClass="page-item" linkClass="page-link" activePage={this.state?.registeredStoresPagination?.page} itemsCountPerPage={this.state?.registeredStoresPagination?.limit} totalItemsCount={this.state?.registeredStoresPagination?.totalDocs} pageRangeDisplayed={20} onChange={this.handleRegisteredStorePageChange.bind(this)}/>
                          </div>
                        </>
                        :
                        <div className="alert alert-warning text-bold text-center">No store found.</div>
                      }
                    </div>
                  </>}

                  <Modal show={this.state.isShowingProductRegisterModal}
                         onHide={this.handleCloseProductRegisterModal} size={'xl'}>
                    <Modal.Header closeButton>
                      <Modal.Title>Join Campaign</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="card-block">
                        <div className="block-header">
                          <h4 className="block-title">Store Products</h4>
                        </div>
                        <div className="block-body">
                          {this.state.store_products?.length > 0 ?
                            <>
                              <div className="product-list mt-3">
                                {this.state.store_products.map((product, index) => (
                                  <div key={index} className="product-item">
                                    <Product data={product} registeredProducts={this.state.registered_products} approvedProducts={this.state.approved_products} addProductInCampaign={this.addProductInCampaign}/>
                                  </div>
                                ))}
                              </div>
                              <div className="mt-3">
                                <Pagination itemClass="page-item" linkClass="page-link" activePage={this.state.store_product_pagination?.page} itemsCountPerPage={this.state.store_product_pagination?.limit} totalItemsCount={this.state.store_product_pagination?.totalDocs} pageRangeDisplayed={20} onChange={this.handleStoreProductsPageChange.bind(this)}/>
                              </div>
                            </>
                            :
                            <div className="alert alert-warning text-bold text-center">No product added yet.</div>
                          }
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                  </Modal>

                  {this.state.activeBlockTab === 'Approved Stores' && <>
                    <div className="block-body">
                      {approved_stores?.length > 0 ?
                        <>
                          <div className="block-header">
                            <h4 className="block-title">Approved Store List</h4>
                          </div>
                          <div className="block-body position-relative">
                            <Store data={{stores: approved_stores, pagingCounter: this.state.approvedStoresPagination.pagingCounter}} removeCampaignStore={this.removeCampaignStore} handleProductListModal={this.handleProductListModal} campaignInfo={campaignDetails}/>
                            <Pagination itemClass="page-item" linkClass="page-link" activePage={this.state?.approvedStoresPagination?.page} itemsCountPerPage={this.state?.approvedStoresPagination?.limit} totalItemsCount={this.state?.approvedStoresPagination?.totalDocs} pageRangeDisplayed={20} onChange={this.handleApprovedStorePageChange.bind(this)}/>
                          </div>
                        </>
                        :
                        <div className="alert alert-warning text-bold text-center">No store found.</div>
                      }
                    </div>
                  </>}

                  {this.state.activeBlockTab === 'Registered Products' && <>
                    <div className="block-body">
                      <>
                        {this.state.approved_selected ?
                          <Button onClick={() => {
                            this.approvedSelectedProductV2().then(r => {
                            })
                          }}>APPROVED SELECTED</Button>
                          : null}
                      </>
                      {this.state.registered_products?.length > 0 ?
                        <>
                          <div className="product-list mt-3">
                            {this.state.registered_products.map((data, index) => (
                              <div key={index} className="product-item">
                                <div className="product">
                                  <FormCheck onChange={(e) => {
                                    this.handleOnChangeV3(e, data._id)
                                  }} type="checkbox" label=""/>
                                  <Link to={`${process.env.PUBLIC_URL}/products/details/${data._id}`}
                                        className="product-thumb">
                                    <img src={data.thumb_img} alt=""/>
                                  </Link>

                                  <div className="product-desc">
                                    <h4 className="product-title">
                                      <Link
                                        to={`${process.env.PUBLIC_URL}/products/details/${data._id}`}>{data.name}</Link>
                                      <a target={'_blank'}
                                         rel="noopener noreferrer"
                                         className={'ml-3 text-primary'}
                                         href={`${SHOP_BASE_URL}/products/details/${data._id}`}><GoLinkExternal/></a>
                                    </h4>
                                    <p className="date mb-0"><em>Created at
                                      : </em> {moment(data.createdAt).format('LL')} {this.renderStatus(data.status)} {this.renderPublishStatusText(data.publish_status)}
                                    </p>
                                    <p className=""><span
                                      className="d-inline-block mr-2"><FaStore/></span>
                                      {data?.store_info?.store_name} {data?.store_info?.deleted ? this.renderIsDeleted(data?.store_info?.deleted) : ''} {data?.store_info?.status ? this.renderStatus(data?.store_info?.status) : ''}
                                    </p>
                                    {data?.sku_info &&
                                    <div className="product-attr">
                                      {/*<div className="attr">
              <span className="attr-title">SKU</span>
              <span className="attr-value">{data.sku_info.sku}</span>
            </div>*/}
                                      {data.sku_info?.special_price ?
                                        <div className="attr">
                                          <span className="attr-title">Sale Price</span>
                                          <span
                                            className="attr-value">৳ {data.sku_info?.special_price?.toFixed(2)}</span>
                                        </div> : ''}
                                      <div className="attr">
                                        <span className="attr-title">Regular Price</span>
                                        <span className="attr-value">৳ {data.sku_info?.price?.toFixed(2)}</span>
                                      </div>
                                      <div className="attr">
                                        <span className="attr-title">Available</span>
                                        <span className="attr-value">{data.sku_info.quantity}</span>
                                      </div>
                                      <div className="attr">
                                        <span className="attr-title">Stock</span>
                                        <span
                                          className="attr-value">{this.renderAvailabilityStatus(data.sku_info.quantity)}</span>
                                      </div>
                                    </div>}

                                    <div className="product-attr mt-2">
                                      {data?.max_price ?
                                        <div className="attr">
                                          <span className="attr-title">Price Range</span>
                                          <span
                                            className="attr-value">৳ {data?.min_price?.toFixed(2)} {data?.max_price ? " - " + data?.max_price?.toFixed(2) : ''}</span>
                                        </div> : ''}
                                      <div className="attr">
                                        <span className="attr-title">Rating</span>
                                        <span className="attr-value">{data?.avg_score}/{data?.t_n_of_score}</span>
                                      </div>
                                      <div className="attr">
                                        <span className="attr-title">Total Sold </span>
                                        <span className="attr-value">{data?.t_sold}</span>
                                      </div>
                                    </div>

                                  </div>

                                  <div className="actions">

                                    {getLocalAuthData()?.permission?.includes('product_item_view') ? <Link to={`${process.env.PUBLIC_URL}/products/details/${data._id}`} className="btn btn-block btn-sm btn-outline-info">View</Link> : null}

                                    {getLocalAuthData()?.permission?.includes('product_edit') ? <Link to={`${process.env.PUBLIC_URL}/products/edit/${data._id}`} className="btn-action btn-block btn-outline-primary">Edit</Link> : null}

                                    {(getLocalAuthData()?.permission?.includes('product_setting') && this.handleProductSettings) && <button className="btn btn-block btn-sm btn-outline-primary" onClick={() => this.props.handleProductSettings(this.props.data._id)}>Product Settings</button>}

                                    {(getLocalAuthData()?.permission?.includes('product_block_unblock') && this.props.handleProductStatus) && <button className="btn-action btn-block btn-outline-warning" onClick={() => this.props.handleProductStatus(this.props.data._id, this.renderBStatus(this.props.data.status), data?.name, data?.store_info?.store_name)}>{this.renderBtnText(this.props.data.status)}</button>}

                                    {(getLocalAuthData()?.permission?.includes('product_publish_unpublish') && this.props.handleProductPublishStatus) && <button className="btn btn-block btn-sm btn-outline-success" onClick={async () => this.props.handleProductPublishStatus(this.props.data._id, data?.name, this.renderPublishStatus(this.props.data.publish_status), data?.store_info?.store_name)}>{this.renderPublishBtnText(this.props.data.publish_status)}</button>}

                                    {(getLocalAuthData()?.permission?.includes('product_delete') && this.props.productDelete && this.props.data?.deleted) && <><Button block size={`sm`} onClick={() => this.props.handleRevertDeletedProduct(this.props.data._id, data?.name, data?.store_info?.store_name)} variant={`outline-danger`}>Revert Delete</Button></>}

                                    {(getLocalAuthData()?.permission?.includes('product_delete') && this.props.productDelete && this.props.data?.deleted === false) && <><Button block size={`sm`} onClick={() => this.props.productDelete(this.props.data._id, data?.name, data?.store_info?.store_name)} variant={`outline-danger`}>Delete</Button></>}

                                    {this.state.approved_products?.length > 0
                                    && this.state.approved_products.findIndex((elm) => elm._id.toString() === data._id.toString()) !== -1
                                    && <span className="badge badge-success">Approved</span>}

                                    {((this.state.approved_products?.length === 0) || (this.state.approved_products.findIndex((elm) => elm._id.toString() === data._id.toString()) === -1))
                                    && <Button block size={`sm`} variant={`outline-danger`} onClick={() => this.approveCampaignProduct(data._id)}>
                                      Approve
                                    </Button>}

                                    {getLocalAuthData()?.permission?.includes('product_add_to_campaign') && this.props.addProductInCampaign && <button onClick={() => this.props.addProductInCampaign(this.props.data._id)} className="btn btn-block btn-sm btn-outline-primary">Add to Campaign</button>}

                                    {(getLocalAuthData()?.permission?.includes('product_hard_delete') && this.state.userType && this.state.userType === '11') && <button onClick={() => this.props.productHardDelete(data._id, data?.name, data?.store_info?.store_name)} className="btn-action btn-block btn-outline-danger">Hard Delete</button>}

                                  </div>
                                </div>
                                <div className="block-body">
                                  {this.state.store_products?.items?.length > 0 && <div className="mt-3"><Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={this.state.store_product_pagination?.page}
                                    itemsCountPerPage={this.state.store_product_pagination?.limit}
                                    totalItemsCount={this.state.store_product_pagination?.totalDocs}
                                    pageRangeDisplayed={20}
                                    onChange={this.handleStoreProductsPageChange.bind(this)}
                                  />
                                  </div>
                                  }
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="mt-3">
                            <Pagination
                              itemClass="page-item"
                              linkClass="page-link"
                              activePage={this.state?.registeredProductsPagination?.page}
                              itemsCountPerPage={this.state?.registeredProductsPagination?.limit}
                              totalItemsCount={this.state?.registeredProductsPagination?.totalDocs}
                              pageRangeDisplayed={20}
                              onChange={this.handleRegisteredProductsPageChange.bind(this)}
                            />
                          </div>
                        </>
                        :
                        <div className="alert alert-warning text-bold text-center">
                          No product added yet.
                        </div>
                      }
                    </div>
                  </>}

                  {this.state.activeBlockTab === 'Approved Products' && <>
                    <div className="block-body">
                      {this.state.approved_products?.length > 0 ?
                        <>
                          {this.state.remove_selected ? <Button onClick={() => {
                            this.removeCampaignProductV2()
                          }}>REMOVE SELECTED</Button> : null}
                          <div className="product-list mt-3">
                            {this.state.approved_products.map((data, index) => (
                              <div key={index} className="product-item">
                                {/*PRODUCT*/}
                                <div className="product">
                                  <FormCheck onChange={(e) => {
                                    this.handleOnChangeV2(e, data._id)
                                  }} type="checkbox" label=""/>

                                  <Link to={`${process.env.PUBLIC_URL}/products/details/${data._id}`}
                                        className="product-thumb">
                                    <img src={data.thumb_img} alt=""/>
                                  </Link>

                                  <div className="product-desc">
                                    <h4 className="product-title">
                                      <Link
                                        to={`${process.env.PUBLIC_URL}/products/details/${data._id}`}>{data.name}</Link>
                                      <a target={'_blank'}
                                         rel="noopener noreferrer"
                                         className={'ml-3 text-primary'}
                                         href={`${SHOP_BASE_URL}/products/details/${data._id}`}><GoLinkExternal/></a>
                                    </h4>
                                    <p className="date mb-0"><em>Created at
                                      : </em> {moment(data.createdAt).format('LL')} {this.renderStatus(data.status)} {this.renderPublishStatusText(data.publish_status)}
                                    </p>
                                    <p className=""><span
                                      className="d-inline-block mr-2"><FaStore/></span>
                                      {data?.store_info?.store_name} {data?.store_info?.deleted ? this.renderIsDeleted(data?.store_info?.deleted) : ''} {data?.store_info?.status ? this.renderStatus(data?.store_info?.status) : ''}
                                    </p>
                                    {data?.sku_info &&
                                    <div className="product-attr">
                                      {/*<div className="attr">
              <span className="attr-title">SKU</span>
              <span className="attr-value">{data.sku_info.sku}</span>
            </div>*/}
                                      {data.sku_info?.special_price ?
                                        <div className="attr">
                                          <span className="attr-title">Sale Price</span>
                                          <span
                                            className="attr-value">৳ {data.sku_info?.special_price?.toFixed(2)}</span>
                                        </div> : ''}
                                      <div className="attr">
                                        <span className="attr-title">Regular Price</span>
                                        <span className="attr-value">৳ {data.sku_info?.price?.toFixed(2)}</span>
                                      </div>
                                      <div className="attr">
                                        <span className="attr-title">Available</span>
                                        <span className="attr-value">{data.sku_info.quantity}</span>
                                      </div>
                                      <div className="attr">
                                        <span className="attr-title">Stock</span>
                                        <span
                                          className="attr-value">{this.renderAvailabilityStatus(data.sku_info.quantity)}</span>
                                      </div>
                                    </div>}

                                    <div className="product-attr mt-2">
                                      {data?.max_price ?
                                        <div className="attr">
                                          <span className="attr-title">Price Range</span>
                                          <span
                                            className="attr-value">৳ {data?.min_price?.toFixed(2)} {data?.max_price ? " - " + data?.max_price?.toFixed(2) : ''}</span>
                                        </div> : ''}
                                      <div className="attr">
                                        <span className="attr-title">Rating</span>
                                        <span className="attr-value">{data?.avg_score}/{data?.t_n_of_score}</span>
                                      </div>
                                      <div className="attr">
                                        <span className="attr-title">Total Sold </span>
                                        <span className="attr-value">{data?.t_sold}</span>
                                      </div>

                                      <div className="attr">
                                        <span className="attr-title">Campaign Orders</span>
                                        <span className="attr-value">{data?.c_orders ? data?.c_orders : 0}</span>
                                      </div>


                                    </div>

                                  </div>

                                  <div className="actions">

                                    {getLocalAuthData()?.permission?.includes('product_item_view') ?
                                      <Link to={`${process.env.PUBLIC_URL}/products/details/${data._id}`}
                                            className="btn btn-block btn-sm btn-outline-info">View</Link> : null}
                                    {getLocalAuthData()?.permission?.includes('product_edit') ?
                                      <Link to={`${process.env.PUBLIC_URL}/products/edit/${data._id}`}
                                            className="btn-action btn-block btn-outline-primary">Edit</Link> : null}

                                    {(getLocalAuthData()?.permission?.includes('product_setting') && this.handleProductSettings) &&
                                    <button className="btn btn-block btn-sm btn-outline-primary"
                                            onClick={() => this.props.handleProductSettings(this.props.data._id)}>Product
                                      Settings</button>}

                                    {(getLocalAuthData()?.permission?.includes('product_block_unblock') && this.props.handleProductStatus) &&
                                    <button className="btn-action btn-block btn-outline-warning"
                                            onClick={() => this.props.handleProductStatus(this.props.data._id, this.renderBStatus(this.props.data.status), data?.name, data?.store_info?.store_name)}>{this.renderBtnText(this.props.data.status)}</button>}

                                    {(getLocalAuthData()?.permission?.includes('product_publish_unpublish') && this.props.handleProductPublishStatus) &&
                                    <button className="btn btn-block btn-sm btn-outline-success"
                                            onClick={async () => this.props.handleProductPublishStatus(this.props.data._id, data?.name, this.renderPublishStatus(this.props.data.publish_status), data?.store_info?.store_name)}>{this.renderPublishBtnText(this.props.data.publish_status)}</button>}

                                    {(getLocalAuthData()?.permission?.includes('product_delete') && this.props.productDelete && this.props.data?.deleted) &&
                                    <><Button block size={`sm`}
                                              onClick={() => this.props.handleRevertDeletedProduct(this.props.data._id, data?.name, data?.store_info?.store_name)}
                                              variant={`outline-danger`}>Revert Delete</Button></>
                                    }

                                    {(getLocalAuthData()?.permission?.includes('product_delete') && this.props.productDelete && this.props.data?.deleted === false) &&
                                    <><Button block size={`sm`}
                                              onClick={() => this.props.productDelete(this.props.data._id, data?.name, data?.store_info?.store_name)}
                                              variant={`outline-danger`}>Delete</Button></>
                                    }

                                    {(getLocalAuthData()?.permission?.includes('product_approve_remove') && this.props.approveCampaignProduct) &&
                                    <>
                                      <Button block size={`sm`}
                                              onClick={() => this.props.approveCampaignProduct(data._id)}
                                              variant={`outline-danger`}>Approve
                                        {this.state.approvedIds.length > 0 && this.props?.approvedIds.map((data, index) => (<>{data === this.props.data._id && <>d</>}</>))}</Button>
                                    </>
                                    }

                                    {(getLocalAuthData()?.permission?.includes('product_approve_remove') && this.removeCampaignProduct) &&
                                    <><Button block size={`sm`} onClick={() => this.removeCampaignProduct(data._id)}
                                              variant={`outline-danger`}>Remove</Button></>
                                    }

                                    {(getLocalAuthData()?.permission?.includes('product_add_to_campaign') && this.props.addProductInCampaign) &&
                                    <>
                                      <button onClick={() => this.props.addProductInCampaign(this.props.data._id)}
                                              className="btn btn-block btn-sm btn-outline-primary">Add to Campaign
                                      </button>
                                    </>
                                    }

                                    {
                                      (getLocalAuthData()?.permission?.includes('product_hard_delete') && this.state.userType && this.state.userType === '11') &&
                                      <>
                                        <button
                                          onClick={() => this.props.productHardDelete(data._id, data?.name, data?.store_info?.store_name)}
                                          className="btn-action btn-block btn-outline-danger">Hard Delete
                                        </button>
                                      </>
                                    }

                                  </div>
                                </div>
                                {/*END*/}
                                {/*<Product data={product}
                                                                             removeCampaignProduct={this.removeCampaignProduct}/>*/}
                                <div className="block-body">


                                  {this.state.store_products?.items?.length > 0 ?
                                    <>


                                      <div className="mt-3">
                                        <Pagination
                                          itemClass="page-item"
                                          linkClass="page-link"
                                          activePage={this.state.store_product_pagination?.page}
                                          itemsCountPerPage={this.state.store_product_pagination?.limit}
                                          totalItemsCount={this.state.store_product_pagination?.totalDocs}
                                          pageRangeDisplayed={20}
                                          onChange={this.handleStoreProductsPageChange.bind(this)}
                                        />
                                      </div>
                                    </>
                                    :
                                    /*<div className="alert alert-warning text-bold text-center">
                                        No product added yet.
                                    </div>*/
                                    <></>
                                  }
                                </div>

                              </div>
                            ))}
                          </div>

                          <div className="mt-3">
                            <Pagination
                              itemClass="page-item"
                              linkClass="page-link"
                              activePage={this.state?.approvedProductsPagination?.page}
                              itemsCountPerPage={this.state?.approvedProductsPagination?.limit}
                              totalItemsCount={this.state?.approvedProductsPagination?.totalDocs}
                              pageRangeDisplayed={20}
                              onChange={this.handleApprovedProductsPageChange.bind(this)}
                            />
                          </div>
                        </>
                        :
                        <div className="alert alert-warning text-bold text-center">
                          No product added yet.
                        </div>
                      }
                    </div>
                  </>}

                  {this.state.activeBlockTab === 'Order Report' && <>
                    <div className="block-body">
                      <form className="form-inline mb-3">
                        {/*<div className="form-group mr-3 mt-2">
                          <label htmlFor="barndName" className="sr-only">Merchant Type</label>
                          <select id="merchantType" name="merchant_type_find"
                                  onChange={(e) => {
                                    this.handleInputOnChangeV2(e)
                                  }}
                                  className='form-control form-control-sm'>
                            <option value="">--Select Type--</option>
                            {this.renderMerchantType()}
                          </select>
                        </div>*/}
                        <div className="form-group mr-3 mt-2">
                          <label htmlFor="barndName" className="sr-only">Sort by Store</label>
                          <select id="store_id" name="store_id"
                                  onChange={this.handleInputOnChangeV2}
                                  className='form-control form-control-sm'>
                            <option value="">--Select Store--</option>
                            {this.renderStoreId()}
                          </select>
                        </div>
                        <Button className="mt-2" size="sm" variant="secondary"
                                onClick={() => this.getReport() }>Search</Button>
                      </form>


                      <Button
                        size={"sm"}
                        variant={"defalut"} className="mb-0 ml-2"
                        onClick={() => this.handleExportFileReport('xlsx')}><FaFileExport/> Export
                      </Button>

                      <Row>
                        <Col md={3}>
                          <InfoCard
                            title="Total Orders" className="blue" icon={<FiFileText/>}
                            value={this.state?.campaign_reports?.total_order}/>
                        </Col>

                        <Col md={3}>
                          <InfoCard
                            title="Total Sells" className="blue" icon={<FaSellsy/>}
                            isCurrency={true}
                            value={this.state?.campaign_reports?.total_sells}/>
                        </Col>

                        <Col md={3}>
                          <InfoCard
                            total={"Pending sells : " + this.state?.campaign_reports?.total_pending_sells}
                            title="Total Pending Orders" className="orange"
                            icon={<AiOutlineHourglass/>}
                            value={this.state?.campaign_reports?.pending_order}/>
                        </Col>

                        <Col md={3}>
                          <InfoCard
                            total={"Confirmed sells : " + this.state?.campaign_reports?.total_confirmed_sells}
                            title="Total Confirm Orders" className="green" icon={<BsListCheck/>}
                            value={this.state?.campaign_reports?.confirmed_order}/>
                        </Col>

                        <Col md={3}>
                          <InfoCard
                            total={"Cancelled sells : " + this.state?.campaign_reports?.total_cancel_sells}
                            title="Total Cancel Orders" className="red" icon={<ImCancelCircle/>}
                            value={this.state?.campaign_reports?.cancel_order}/>
                        </Col>

                        <Col md={3}>
                          <InfoCard
                            total={"Picked sells : " + this.state?.campaign_reports?.total_picked_sells}
                            title="Total Picked Orders" className="blue" icon={<FaTruckPickup/>}
                            value={this.state?.campaign_reports?.picked_order}/>
                        </Col>

                        <Col md={3}>
                          <InfoCard
                            total={"Shipped sells : " + this.state?.campaign_reports?.total_shipped_sells}
                            title="Total Shipped Orders" className="green" icon={<FcShipped/>}
                            value={this.state?.campaign_reports?.shipped_order}/>
                        </Col>

                        <Col md={3}>
                          <InfoCard
                              total={"Processing order sells : " + this.state?.campaign_reports?.processing_sells}
                              title="Processing Order" className="green"
                              icon={<AiOutlineTransaction/>}
                              value={this.state?.campaign_reports?.processing_order}/>
                        </Col>

                        <Col md={3}>
                          <InfoCard
                            total={"Delivered sells : " + this.state?.campaign_reports?.total_delivered_sells}
                            title="Total Delivered Orders" className="orange" icon={<GrDeliver/>}
                            value={this.state?.campaign_reports?.delivered_order}/>
                        </Col>


                        <Col md={3}>
                          <InfoCard
                            total={"COD sells : " + this.state?.campaign_reports?.total_cod_sells}
                            title="Total COD" className="blue" icon={<GiCash/>}
                            value={this.state?.campaign_reports?.total_cod}/>
                        </Col>

                        <Col md={3}>
                          <InfoCard
                            total={"Online Transaction sells : " + this.state?.campaign_reports?.total_online_transaction_sells}
                            title="Total Online Transaction" className="green" icon={<AiOutlineTransaction/>}
                            value={this.state?.campaign_reports?.total_online_transaction}/>
                        </Col>

                        <Col md={3}>
                          <InfoCard
                            total={"Total Bank Transaction sells : " + this.state?.campaign_reports?.total_bank_transaction_sells}
                            title="Total Bank Transaction" className="blue" icon={<GrTransaction/>}
                            value={this.state?.campaign_reports?.total_bank_transaction}/>
                        </Col>

                        <Col md={3}>
                          <InfoCard
                            total={"Approved Bank Transaction sells : " + this.state?.campaign_reports?.total_approved_transaction_sells}
                            title="Approved Bank Transaction" className="orange" icon={<FcApprove/>}
                            value={this.state?.campaign_reports?.total_approved_bank_transaction}/>
                        </Col>


                        <Col md={3}>
                          <InfoCard
                            total={"MuktoPaath sells : " + this.state?.campaign_reports?.total_muktopaath_sells}
                            title="Muktopaath User Order" className="blue" icon={<AiOutlineUser/>}
                            value={this.state?.campaign_reports?.total_muktopaath_order}/>
                        </Col>

                      </Row>

                    </div>
                  </>}
                </div> :
                    <div className={`alert alert-warning text-center text-bold`}>
                      Campaign not found!
                    </div>
                }</>
                }
              </>
            </>
            : null}
          {this.state.activeBlockTab === 'Campaign Detail' && <>
            {campaignDetails !== undefined ? <div className="card-block">
              <div className="campaign-terms-conditions">
                <h3 className="term-condition-title">Terms and Conditions</h3>
                <p className="term-condition-des">{parser('' + campaignDetails?.terms_and_conditions + '')}</p>
              </div>
            </div> : null}
          </>
          }
        </div>
      </App>
    );
  }

}

export default withRouter(CampaignDetails);
