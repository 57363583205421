import axios from 'axios';
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData, objectToFormData} from "../helper/utils";

export const getMerchant = (paramsObj = {}) => {
  const authData = getLocalAuthData()

  return new Promise((resolve, error) => {
    axios.get(API_BASE_URL + `/merchant`, {
      headers: {
        'x-auth-token': authData?.token,
        'x-api-client': getDeviceType()
      },
      params: paramsObj
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data);
        }
      })
      .catch(err => {
        error(err.message)
      })
  })
}

export const getMerchantDetails = (id) => {
  const authData = getLocalAuthData()
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/merchant/${id}`, {
      headers: {
        "x-auth-token": authData?.token || '',
        "x-api-client": getDeviceType()
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data.item)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const getMerchantPaymentMethods = (paramsObj = {}) => {
  const authData = getLocalAuthData();

  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/merchant-payment-method`, {
      params: paramsObj,
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    })
      .then(res => {
        if (res.data.status === 1 || res.data.status === 2) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

// Merchant type
export const getMerchantTypeList = () => {
  const authData = getLocalAuthData();

  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/merchantType`, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": "web"
      }
    }).then(res => {
      if (res.data.status === 1 || res.data.status === 2) {
        resolve(res.data.merchant_type_obj)
      } else {
        reject(res.data.message)
      }
    }).catch(err => {
      console.log('Getting Merchant Type Error: ', err);
      reject('Something went wrong!')
    })
  })
}

// Merchant type details
export const getMerchantTypeDetails = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/merchantType/${id}`, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": "web"
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data.merchant_type_obj[0])
        } else {
          reject(res.data.message);
        }
      })
      .catch(err => {
        console.log('Getting merchant type details error: ', err);
        reject('Something went wrong!')
      })
  })
}

export const addMerchantType = (data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.post(`${API_BASE_URL}/merchantType`, data, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": "web"
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        console.log('Adding merchant type error: ', err);
        reject('Something went wrong')
      })
  })
}

export const updateMerchantType = (data, id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.put(`${API_BASE_URL}/merchantType/${id}`, data, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": "web"
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        console.log('Update merchant type error: ', err)
        reject('Something went wrong');
      })
  })
}

export const deleteMerchantType = (id) => {
  const authData = getLocalAuthData();

  return new Promise((resolve, reject) => {
    axios.delete(`${API_BASE_URL}/merchantType/${id}`, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": "web"
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        console.log('Type delete error: ', err);
        reject('Something went wrong');
      })
  })
}

export const getMerchantList = (paramsObj = {}) => {
  const authData = getLocalAuthData();

  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/merchant`, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      },
      params: paramsObj
    })
      .then(res => {
        if (res.data.status === 1 || res.data.status === 2) {
          resolve(res.data.items)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        console.log(err);
        reject('Something went wrong')
      })
  })
}

export const merchantBulkImport = (dataObj) => {
  // const data = {file: file};
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.post(`${API_BASE_URL}/merchant/import`, objectToFormData(dataObj), {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(reject(res.data.message))
        }
      })
      .catch(err => {
        console.log(err)
        reject('Something went wrong')
      })
  })
}

export const profileStatusUpdate = (id, data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.patch(`${API_BASE_URL}/user/${id}/changeProfileStatus`, data, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch(err => {
        console.log(err);
        reject('Something went wrong');
      })
  })
}

export const merchantDetails = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/merchant/${id}`, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data.item);
        } else {
          reject(res.data.message);
        }
      })
      .catch(err => {
        console.log(err);
        reject(err.message);
      })
  })
}

export const deleteMerchant = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.delete(`${API_BASE_URL}/merchant/${id}`, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": "web"
      }
    }).then(res => {
      if (res.data.status === 1) {
        resolve(res.data)
      } else {
        reject(res.data.message)
      }
    })
      .catch(err => {
        console.log('Type delete error: ', err);
        reject('Something went wrong');
      })
  })
}

export const revertDeletedMerchant = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.patch(`${API_BASE_URL}/merchant/revert-deleted-merchant/${id}`, {
      headers: {"x-auth-token": authData.token, "x-api-client": "web"}
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      console.log('Type delete error: ', err);
      reject('Something went wrong');
    })
  })
}

export const resendMerchantPassword = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.patch(`${API_BASE_URL}/resend-password/${id}`, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": "web"
      }
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      console.log('Type delete error: ', err);
      reject('Something went wrong');
    });
  });
}

export const deleteMerchantPermanently = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.delete(`${API_BASE_URL}/merchant/${id}/permanent`, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": "web"
      }
    }).then(res => {
      if (res.data.status === 1) {
        resolve(res.data)
      } else {
        reject(res.data.message)
      }
    })
      .catch(err => {
        console.log('Type delete error: ', err);
        reject('Something went wrong');
      })
  })
}

export const updateProfile = (data, id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.put(`${API_BASE_URL}/merchant/${id}`, data, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": "web"
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        console.log(err);
        reject('Something went wrong')
      })
  })
}

export const addMerchant = (data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.post(`${API_BASE_URL}/merchant`, data, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": "web"
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        console.log(err);
        reject('Something went wrong')
      })
  })
}

export const changeMerchantInfo = (userId, data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.post(`${API_BASE_URL}/merchant/edit/${userId}`, data, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": "web"
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        console.log(err);
        reject('Something went wrong')
      })
  })
}

export const getBDDivisions = (locationId) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/location/child-location/${locationId}`, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": "web"
      }
    }).then(res => {
      if (res.data.status === 1) {
        resolve(res.data.locations)
      } else {
        reject(res.data.message)
      }
    }).catch(err => {
      reject('Something went wrong')
    })
  })
}