import React, {Component} from 'react';
import '../assets/scss/header.scss';
import {Link, withRouter} from "react-router-dom";
import logo from "../assets/img/logo.png";
import blankAvatar from "../assets/img/blank-avatar.png";
import {FiLogOut, FiMenu, FiUser, RiEye2Fill, RiEye2Line} from "react-icons/all";
import {connect} from "react-redux";
import {handleToggleNavbar, logout} from "../redux";
import HeaderPartItem from "../components/HeaderPartItem";
import {Scrollbars} from "react-custom-scrollbars";
import ReportGrid from "../components/ReportGrid";

import {getNotifications, updateReadStatus} from "../services/notificationServices";
import {getMerchantTypeList} from "../services/merchantServices";
import {toast} from "react-toastify";
import Product from "../components/Product";
import {getLocalAuthData} from "../helper/utils";
import sound_url from '../assets/sound/order_notification.mp3'

//Socket for emmitting notification
import {SOCKET} from "../helper/env";
import io from 'socket.io-client'
import {Button} from "react-bootstrap";



const event = 'admin_channel'
//const socket = io.connect(`${SOCKET}?event=${event}`)
var connectionOptions =  {
    transports: ['websocket'],
    jsonp: false
};

const event2 = 'live_video_event'
const socket2 = io.connect(`${SOCKET}?event=${event2}`,connectionOptions)

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowingAccountDropdown: false,
            notifications: [],
            notificationCount: null
        }
    }


    componentDidMount() {


        socket2.on(event2, (data) =>{
            console.log(">>>>>",data)
        })

        getNotifications().then(res => {
            if (res.status === 1) {
                //console.log(res)
                var unReadNotifications = []
                res.items.forEach((element) => {
                    if (element.is_read?.admin == false) {
                        unReadNotifications.push(element)
                    }
                })

                this.setState({
                    notifications: unReadNotifications,
                    notificationCount: unReadNotifications.length
                })
            }
        }).catch(function (errMsg) {
            //console.log(errMsg)
        });


        //Socket
        //io.connect(`${SOCKET}?event=${event}`).off()
        io.connect(`${SOCKET}?event=${event}`).on(event, (data) => {
            //console.log("Socket Response")

            console.log(data)

            this.setState({notifications: [data, ...this.state.notifications],
                notificationCount : this.state.notificationCount == null ? 1 : this.state.notificationCount+1},()=>{
                new Audio(sound_url).play();
            })
            //console.log(this.state.notifications)
        })


    }


    handleToggleAccountDropdown = () => {
        this.setState({isShowingAccountDropdown: !this.state.isShowingAccountDropdown})
    };


    changeStatus(id) {

        //console.log("Click ID ::: " + id);
        updateReadStatus(id).then(res => {
            //console.log(res)
        }).catch(function (errMsg) {
            //console.log(errMsg)
        });

    }

    notificationListItem(props) {

        /*ORDER_PLACED -- NEW_MERCHANT_ADD */

        // onClick={updateReadStatus(props._id)}

        var redirectLink = `${process.env.PUBLIC_URL}/orders/parcel/${props.type_id}`;
        if (props.type == "NEW_MERCHANT_ADD")
            redirectLink = `/entrepreneurs/details/${props.type_id}`
        return (
            <div onClick={() => this.changeStatus(props._id)}>
                <Link to={redirectLink} className="notification-content"
                      target={'_blank'}>
                    <h4 className="title">{props.title}</h4>
                    <span className="time meta-info">{props.description}</span>
                </Link>
            </div>
        )
    }


    render() {
        return (
            <div className={this.props.navbar.isNavbarShowing ? 'header' : 'header collapsed-sidebar'}>
                <div className="header-brand">
                    <Link to={`${process.env.PUBLIC_URL}/`} className="brand-logo"><img src={logo} alt=""/></Link>
                </div>

                <div className="header-content">
                    <div className="layout-actions">
                        {/*<button
              onClick={() => this.props.handleNavbarToggle()}
              className="sidebar-toggle"><FiMenu/></button>*/}

                        <Link to={`${process.env.PUBLIC_URL}/`} className="brand-logo"><img src={logo} alt=""/></Link>
                    </div>

                    <div className={'header-right-part'}>

                        {getLocalAuthData()?.permission?.includes('activity_log_list') ? <div style={{marginRight:'30px'}}>
                           <Link target={`_blank`} to={`/activity-log/all`}><RiEye2Line style={{height:'22px' ,width:'22px'}}/></Link>
                       </div> : null}

                        <HeaderPartItem title={'Reports'}
                                        focused
                                        icon={
                                            <span className="svg-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                     width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"/>
                                        <rect fill="#000000" opacity="0.3" x="17" y="4" width="3" height="13" rx="1.5"/>
                                        <rect fill="#000000" opacity="0.3" x="12" y="9" width="3" height="8" rx="1.5"/>
                                        <path
                                          d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z"
                                          fill="#000000" fillRule="nonzero"/>
                                        <rect fill="#000000" opacity="0.3" x="7" y="11" width="3" height="6" rx="1.5"/>
                                    </g>
                                </svg>
                              </span>
                            }>
              <Scrollbars style={{height: '320px'}}>
                <ReportGrid/>
              </Scrollbars>
            </HeaderPartItem>

            <HeaderPartItem pulse
                            pulseColor={'primary'}
                            focused
                            title={'Notifications'}
                            actionTo={'#'}
                            icon={
                              <span className="svg-icon">
                                  <div style={{position: 'relative'}}>

                                      {this.state.notificationCount !== null && <span className={`icon-button__badge`} style={{
                                          marginTop: '20px'
                                      }}>{this.state.notificationCount}</span> }

                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                                         height="24px"
                                         viewBox="0 0 24 24" version="1.1">
                                      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                          <rect x="0" y="0" width="24" height="24"/>
                                          <path
                                            d="M12.7037037,14 L15.6666667,10 L13.4444444,10 L13.4444444,6 L9,12 L11.2222222,12 L11.2222222,14 L6,14 C5.44771525,14 5,13.5522847 5,13 L5,3 C5,2.44771525 5.44771525,2 6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,13 C19,13.5522847 18.5522847,14 18,14 L12.7037037,14 Z"
                                            fill="#000000" opacity="0.3"/>
                                          <path
                                            d="M9.80428954,10.9142091 L9,12 L11.2222222,12 L11.2222222,16 L15.6666667,10 L15.4615385,10 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 L9.80428954,10.9142091 Z"
                                            fill="#000000"/>
                                      </g>
                                    </svg>
                                  </div>
                              </span>
                                        }>


                            <Scrollbars style={{height: '220px'}}>


                                {this.state.notifications.map((item, index) => (
                                    <div key={index} className="notification-item">
                                        {this.notificationListItem(item)}
                                    </div>
                                ))}

                            </Scrollbars>
                        </HeaderPartItem>

                        <div className="mini-account">
                            <div className="user-name">{getLocalAuthData().name}</div>
                            <button type="button"
                                    onClick={() => this.handleToggleAccountDropdown()}
                                    className="account-user">
                                <div className="user-avatar">
                                    <img src={blankAvatar} alt=""/>
                                </div>
                            </button>

                            {this.state.isShowingAccountDropdown &&
                            <div className="account-dropdown">
                                <Link to={`${process.env.PUBLIC_URL}/`} className="dropdown-link"><span
                                    className="icon"><FiUser/></span> <span className="text">My Profile</span></Link>
                                <Link to={`${process.env.PUBLIC_URL}/reset-password`} className="dropdown-link"><span
                                    className="icon"><FiUser/></span> <span
                                    className="text">Reset Password</span></Link>
                                <span
                                    onClick={() => this.props.logout(() => {
                                        this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                                    })}
                                    className="dropdown-link"><span className="icon"><FiLogOut/></span> <span
                                    className="text">Logout</span></span>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        navbar: state.navbar
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: cb => dispatch(logout(cb)),
        handleNavbarToggle: () => dispatch(handleToggleNavbar())
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
