import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {FiCheck, FiPlus, FiUpload, IoMdClose} from "react-icons/all";


import Pagination from 'react-js-pagination';
import {withRouter, Link, useHistory} from "react-router-dom";
import "../../assets/scss/order-list.scss";
import axios from "axios";
import config, {API_BASE_URL} from "../../helper/env";
import {ToastsContainer, ToastsStore} from "react-toasts";
import {getChildLocations, getLocationList} from "../../services/locationServices";
import sampleImportFile from "../../assets/resources/bulk-merchant-upload-sample.csv";
import {checkIsExisting,getDetail, getTableData, deleteItem, updateItemPartial} from "../../services/commonServices";

import {
    getMerchantList,
    merchantBulkImport,
    profileStatusUpdate,
    getMerchant,
    deleteMerchant,
    revertDeletedMerchant,
    deleteMerchantPermanently,
    resendMerchantPassword
} from "../../services/staffService";
import {toast} from "react-toastify";
import {IN_VERIFICATION, NEED_VERIFICATION, VERIFIED} from "../../helper/variables";
import {confirmAlert} from "react-confirm-alert";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import {getDeviceType, getLocalAuthData, headerData, objToUrlPrams, urlPramsToObj} from "../../helper/utils";
import Select from "react-select";
import styleReactSelect from "../../assets/styleReactSelect";
import {deleteBrand, getBrandList} from "../../services/brandServices";

class staffList extends Component {

    state = {
        countries: [''],
        phoneExistingStatus: 2,
        emailExistingStatus: 2,
        phoneExistingMsg: null,
        emailExistingMsg: null,
        whichOneUpdate: '',
        isShowModal: false,
        selected_id: '',
        formData: {
            role_ids: [],
            roles: []
        },
        all_role: [],
        my_role: [],
        activeBlockTab: 'All',
        showAdvanceSearchOptions: false,
        staffList: [],
        activePage: 1,
        isLoading: true,
        locations: [],
        businessChildLocations: [],
        entrepreneurType: [],
        pagination: {},
        isImporting: false,
        isExporting: false,
        limit: 20,
        userType: null,
        statusChangingData: {
            status: null,
            message: '',
            action: null,
            userId: ''
        },
        isShowingStatusChangingModal: false,
        isShowingBulkImportModal: false,
        bulkImportFormData: {
            merchant_type: null,
            country: null,
            profile_status: 'Verified',
            is_customer: true
        },
        isUploading: false,
        message: "",
        invalid_rows: [],
        importCompletedModal: false,
        queryData: {limit: 5, page: 1},
        splittedPhone: ['','']
    };


    handleShowModal = (item, whichOne) => {

        console.log(item._id)

        this.setState({
            whichOneUpdate: whichOne,
            user_id: item._id
        })

        axios.get(`${config.API_BASE_URL}/staff/${item._id}`, headerData).then(res => {

            const splittedPhone = item.phone.split("-")

            this.setState({
                splittedPhone: [splittedPhone[0] , splittedPhone[1]],
                formData: {
                    ...this.state.formData,
                    role_ids: res.data.data.role_ids,
                    roles: res.data.data.roles,
                    name: item.name,
                    phone: item.phone,
                    email: item.email
                }

            }, () => {
                console.log(this.state.splittedPhone)
                this.setState({
                    isShowModal: true,
                    editStatus: true
                })

            });
        }).catch(e => this.setState({error: true}));

        this.setState({
            selected_id: item._id
        });
       // console.log(this.state.formData.roles)

    }

    handleHideModal = () => {
        this.setState({
            isShowModal: false,
            editStatus: false
        });
    }

    handleBlockTab = label => {
        this.setState({
            activeBlockTab: label
        })
    };

    componentDidMount() {


        const authData = getLocalAuthData()
        const history = this.props.history
        let page = 1

        getLocationList()
            .then(res => {
                this.setState({countries: res})
            }, ()=> {
                console.log(this.state.countries)
            })


        this.setState({userType: authData?.type})
        if (history.location.search) {
            const params = urlPramsToObj(history)
            if ('page' in params) {
                page = params.page
            }
        }

        this.getTableData()

        axios.get(`${config.API_BASE_URL}/acl-role`, headerData).then(res => {
            this.setState({all_role: res.data.items});
            console.log('acl role', res.data.items)
        }).catch(e => this.setState({error: true}));


    }

    getTableData = async () => {
        getTableData(`/staff`, this.state.queryData).then(res => {
            this.setState({staffList: res.items, isLoading: false, pagination: res.pagination});
        })
    }

    handlePageChange = async (pageNumber) => {
        const history = this.props.history
        history.push(`${history.location.pathname}?page=${pageNumber}`)
        await this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber}, ()=>{
            this.getTableData();
        });
    }

    changeStatus = async (statusObj, merchant_id) => {
        const data = statusObj;
        axios.patch(`${API_BASE_URL}/merchant/${merchant_id}/changeStatus`, data)
            .then(response => {
                if (response.data.status === 1) {
                    toast.success(response.data.message);
                }
                if (response.data.status === 2) {
                    toast.error(response.data.message);
                }
            }).catch(error => {
            toast.error("Error");
        });
    };

    handleProfileStatusUpdate = () => {
        const data = {
            profile_status: this.state.statusChangingData.status,
            message: this.state.statusChangingData.message
        }

        profileStatusUpdate(this.state.statusChangingData.userId, data)
            .then(res => {
                getMerchantList()
                    .then(res => {
                        this.setState({staffList: res, isShowingStatusChangingModal: false})
                    })
                    .catch(errMsg => {
                        console.log('Error message: ', errMsg)
                    })
                toast.success(res.message);
            })
            .catch(errMsg => {
                toast.error(errMsg);
            })
    }

    handleShowStatusChangingModal = (userId, status, action) => {
        if (action === 'Approve') {
            const data = {
                profile_status: status
            }

            confirmAlert({
                title: 'Confirm',
                message: `Are you sure to ${action.toLowerCase()} this entrepreneur?`,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => profileStatusUpdate(userId, data)
                            .then(res => {
                                getMerchantList({page: this.state.pagination.page, limit: this.state.limit})
                                    .then(res => {
                                        this.setState({staffList: res})
                                    })
                                    .catch(errMsg => {
                                        console.log('Error message: ', errMsg)
                                    })
                                toast.success(res.message);
                            })
                            .catch(errMsg => {
                                toast.error(errMsg);
                            })
                    },
                    {
                        label: 'No',
                        // onClick: () => alert('Click No')
                    }
                ]
            })
        } else {
            this.setState({
                statusChangingData: {
                    status: status,
                    action: action,
                    userId: userId
                }
            }, () => {
                this.setState({isShowingStatusChangingModal: true})
            })
        }
    }

    handleHideStatusChangingModal = () => {
        this.setState({
            status: null,
            action: null,
            message: '',
            userId: ''
        }, () => {
            this.setState({isShowingStatusChangingModal: false})
        })
    }


    delete = (_id) =>{

        confirmAlert({
            title: 'Delete',
            message: 'Are you sure to delete this Staff?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () =>
                        deleteItem(`/staff/${_id}/permanent`).then(res => {
                            this.setState({isDataLoading: true}, () => {
                                this.getTableData()
                            })
                            toast.success(res.message);
                        }).catch(errMsg => {
                            toast.error(errMsg);
                        })
                },
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });

    }

/*() => deleteItem(`/staff/${item?._id}/permanent`).then(res => {
this.setState({isDataLoading: true}, () => {
    //this.getTableData()
})
toast.success(res.message);
}).catch(errMsg => {
toast.error(errMsg);
})*/

    renderList = () => {
        const staffList = this.state.staffList;
        if (staffList) {
            return (
                <>
                    { getLocalAuthData()?.permission?.includes('staff_list')  && staffList.map((item, index) => {
                            let selected1 = true;
                            let selected2 = false;

                            let flagshipselected1 = false;
                            let flagshipselected2 = true;

                            if (item.status === '2') {
                                selected2 = true;
                                selected1 = false;
                            }

                            if (item.is_flagship === true) {
                                flagshipselected1 = true;
                                flagshipselected2 = false;
                            }

                            return (
                                <tr key={index}>
                                    {/* eslint-disable-next-line react/no-direct-mutation-state */}
                                    <td>{this.state?.pagination?.pagingCounter + index}</td>
                                    <td>
                                        <div className="order-item">
                                            <h4 className="order-id mb-0">
                                                <Link to={`${process.env.PUBLIC_URL}/entrepreneurs/details/${item._id}`}
                                                      target={'_blank'}>
                                                    {item.name}
                                                </Link>
                                            </h4>
                                            <ul className="ordered-items">
                                                {item?.merchant_code && <li>ID: <strong>{item?._id}</strong></li>}
                                                <li>{item?.role}</li>
                                                <li>{item?.ecommerce_partner ? "Ecommerce Partner" : ''}</li>
                                                <li>{item?.logistic_partner ? "Logistic Partner" : ''}</li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td>
                                        {item?.email}
                                    </td>
                                    <td className={`text-center`}>
                                        {item?.phone}
                                    </td>

                                    <td className={`nowrap text-center`}>
                                    </td>

                                    <td>
                                        {item?.roles !== undefined &&
                                          item?.roles.map( (data , index)=> (<span className={`badge badge-success`}> {data.name}</span>))
                                        }

                                    </td>

                                    <td>
                                    </td>

                                    <td>
                                        <Link
                                            to={`/activity-log/${item._id}`}
                                            className="btn-outline-primary btn-action">
                                            Activity
                                        </Link>
                                        {getLocalAuthData()?.permission?.includes('staff_edit') ? <Button onClick={() => {
                                            this.handleShowModal(item, 'update_info')
                                        }} className="btn-outline-primary btn-action">EDIT</Button> : null}
                                        {getLocalAuthData()?.permission?.includes('staff_delete') ?
                                            <Button onClick={ () => this.delete(item?._id)} className="btn-action btn-outline-primary" >Delete</Button> : null}

                                        {getLocalAuthData()?.permission?.includes('staff_set_role') ?
                                            <Button onClick={() => {
                                            this.handleShowModal(item, 'update_role')
                                        }} className="btn-action btn-outline-primary" >SET ROLE</Button> : null}

                                    </td>

                                </tr>
                            )
                        }
                    )}
                </>
            )
        }

        return (
            <td colSpan={7} className={`text-center`}>
                <strong>No Staff found</strong>
            </td>
        )
    };

    handleLocationInputOnChange = (e, index, typePurpose) => {
        const childLocations = [...this.state[typePurpose]];
        if (typePurpose === 'businessChildLocations') {
            this.handleSearchFormDataChange(e)
        } else {
            this.handleSearchFormDataChange(e)
        }
        if (e.target.value !== '') {
            getChildLocations(e.target.value)
                .then(res => {
                    childLocations.splice(index, childLocations.length - index);
                    if (res.length > 0) {
                        childLocations[index] = res
                    }
                    this.setState({[typePurpose]: childLocations})
                })
                .catch(errMsg => {
                    console.log(errMsg)
                    childLocations.splice(index, childLocations.length - index)
                    this.setState({[typePurpose]: childLocations})
                })
        } else {
            childLocations.splice(index, childLocations.length - index);
            this.setState({[typePurpose]: childLocations})
        }
    }

    renderLocations = () => {
        const locations = this.state.locations;
        if (locations) {
            return (
                <>
                    {locations.map((items, index) =>
                        <option key={index} value={`${items._id}`}>{items.name}</option>
                    )}
                </>
            )
        }
    };


    handleSearchFormDataChange = (e) => {
        this.setState({
            queryData: {
                ...this.state.queryData,
                [e.target.name]: (e.target.type === 'checkbox' ? e.target.checked : e.target.value),
                page: 1
            }
        });
    };


    handleOnApply = (e, picker) => {
        const queryData = {...this.state.queryData};

        queryData.from_date = moment(picker.startDate).format('DD/MM/YYYY');
        queryData.to_date = moment(picker.endDate).format('DD/MM/YYYY');

        this.setState({queryData}, () => {
            console.log(this.state)
        })
    }


    handleImportBulkProduct = (e) => {
        e.preventDefault()
        this.setState({isUploading: true}, () => {

            console.log(this.state.bulkImportFormData);
            merchantBulkImport(this.state.bulkImportFormData).then(res => {
                console.log(res);
                this.setState({
                    storeList: [],
                    bulkImportFormData: null,
                    isLoading: true,
                    isShowingBulkImportModal: false,
                    isUploading: false,
                    message: res.message,
                    invalid_rows: res.invalidRows,
                    importCompletedModal: true
                });
                toast.success(res.message);
            }).catch(errMsg => {
                this.setState({isUploading: false})
                toast.error(errMsg)
            })
        })
    }


    handleSelectedItemOnChange = (e, roleId) => {
        if (e.target.checked === true) {
            // Add new tag permission to formData permission  Array
            this.setState({
                formData: {
                    ...this.state.formData,
                    role_ids: [...this.state.formData.role_ids, roleId]
                }
            }, () => {

                this.state.all_role.map((data, index) => {
                    if (data._id === roleId) {
                        this.setState({
                            formData: {
                                ...this.state.formData,
                                roles: [...this.state.formData.roles, {id: data._id, name: data.type}]
                            }
                        })
                    }
                })

                console.log('push role ids', this.state.formData.role_ids);

            })


        } else {
            // remove new tag permission to formData permission Array if Found
            {
                this.state.formData.role_ids !== undefined && this.state.formData.role_ids.map((data, index) => {
                    if (data === roleId) {
                        this.state.formData.role_ids.splice(index, 1);
                    }
                }, () => {
                    console.log('pop role ids', this.state.formData.role_ids);
                });
            }


            { this.state.formData.roles !== undefined && this.state.formData.roles.map((data, index) => {
                    if (data.id === roleId) {
                        this.state.formData.roles.splice(index, 1);
                    } else {

                    }
                })
            }

        }

    }


    handleSubmitForm = (e) => {
         e.preventDefault();
         const formData = {...this.state.formData};
         console.log('ROLES',this.state.formData)
         formData.phone = this.state.splittedPhone.join('-')

        if (formData.phone === "-" || formData.phone === "+880-"|| formData.phone === "+1-"){
            toast.warning('Please select phone number')
        }else {
            const form = e.currentTarget;
            let url = `${config.API_BASE_URL}/staff`;
            url = `${config.API_BASE_URL}/staff/` + this.state.selected_id;
            axios.put(url, formData, headerData).then(response => {
                if (response.data.status === 1) {
                    toast.success('Staff Update Successfully!'); // NOt work response.data.message
                    //this.renderData();
                    this.getTableData();
                    this.handleHideModal();
                    //window.location.reload();
                }
                if (response.data.status === 2) {
                    toast.error(response.data.message);
                }
            }).catch(error => {
                toast.error("Something went wrong please try again after refresh the page.");
            });
        }



    }

    isItemSelected = (id) => {
        this.state.formData.role_ids.includes(id)
    }

    handleFormDataChange = (e) => {
        this.setState({formData: {...this.state.formData, [e.target.name]: e.target.value}});
        setTimeout(() => {
            console.log(this.state.formData);
        }, 3000);
    };

    checkIsExisting = (type, data, msgKey, statusKey) => {

        console.log(data)

        checkIsExisting(type, data)
            .then(res => {
                if (res.status === 2) {
                    this.setState({[msgKey]: res.message, [statusKey]: res.status})
                } else {
                    this.setState({[msgKey]: null, [statusKey]: res.status})
                }
            })
            .catch(errMsg => {
                console.log(errMsg)
            })
    }

    inputPhoneOnChange = (e, index) => {
        const splittedPhone = [...this.state.splittedPhone]
        splittedPhone[index] = e.target.value
        this.setState({splittedPhone})
        console.log(this.state.splittedPhone)
    }


    render() {
        return (
            <App layout="">



                <Modal show={this.state.isShowModal}
                       onHide={this.handleHideModal}>
                    <Modal.Header closeButton>
                        { this.state.whichOneUpdate === 'update_role' ? <Modal.Title>Set Permission</Modal.Title> :
                            <Modal.Title>Edit Admin Staff</Modal.Title>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        { this.state.whichOneUpdate === 'update_role' ?

                            this.state.all_role.map((item, index) => (
                                    <>
                                        <label key={index} htmlFor={`permission-${index}`}>
                                            <input id={`permission-${index}`}
                                                   key={index}
                                                   name={`permissions`}
                                                   onChange={e => this.handleSelectedItemOnChange(e, item._id)}
                                                   label={item.type}
                                                   defaultChecked={this.state.formData.role_ids.includes(item._id)}
                                                   type="checkbox"/>
                                            <span className="product-name ml-3">{item.type}</span>
                                        </label><br/>
                                    </>
                                ))

                            :
                            <>

                                <Form id={"bankForm"} onSubmit={this.handleSubmitForm}>
                                    <Form.Group as={Row} controlId="aclLabel">
                                        <Form.Label column sm="3">
                                            Name <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control type={"text"} required
                                                          defaultValue={this.state.formData ? this.state.formData.name : ""}
                                                          name="name" onChange={this.handleFormDataChange}
                                                          placeholder={"Name"}/>
                                        </Col>
                                    </Form.Group>



                                    <Form.Group as={Row} controlId="sellerEmail">
                                        <Form.Label column sm="3">
                                            Email Address <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control name={"email"}
                                                          required
                                                          defaultValue={this.state.formData?.email}
                                                          onBlur={() => this.checkIsExisting('email', {
                                                              email: this.state.formData.email,
                                                              user_id: this.state?.selected_id
                                                          }, 'emailExistingMsg', 'emailExistingStatus')}
                                                          onChange={this.handleFormDataChange}
                                                          placeholder={"Email"}
                                                          type="email"/>

                                            {this.state.emailExistingMsg && <Form.Text className={'text-danger'}>
                                                {this.state.emailExistingMsg}
                                            </Form.Text>}

                                            <Form.Control.Feedback type="invalid">Please enter a valid email
                                                address</Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>



{/*

                                    <Form.Group as={Row} controlId="aclTag">
                                        <Form.Label column sm="3">
                                            Phone <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control type={"text"} required
                                                          onBlur={() => this.checkIsExisting("phone", {
                                                              email: this.state.formData.email,
                                                              user_id: this.state?.userId
                                                          }, 'phoneExistingMsg', 'phoneExistingStatus')}
                                                          defaultValue={this.state.formData ? this.state.formData.phone : ""}
                                                          name="phone" onChange={this.handleFormDataChange}
                                                          placeholder={"Phone"}/>

                                            {this.state.phoneExistingMsg && <Form.Text className={'text-danger'}>
                                                {this.state.phoneExistingMsg}
                                            </Form.Text>}
                                        </Col>
                                    </Form.Group>

*/}


                                    <Form.Group as={Row} controlId="phoneNumber">
                                        <Form.Label column sm="3">
                                            Phone Number <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <div className="input-group">
                                                <Form.Control as={'select'}
                                                              name={'code'}
                                                              required
                                                              value={this.state.splittedPhone[0]}
                                                              onChange={e => this.inputPhoneOnChange(e, 0)}
                                                              style={{
                                                                  width: `80px`,
                                                                  paddingRight: '10px',
                                                                  flex: '0 0 80px'
                                                              }}>

                                                    <option value="">---</option>
                                                    {this.state.countries.map((country, index) => (
                                                        <option key={index} value={country.code}>{country.code}</option>
                                                    ))}
                                                </Form.Control>

                                                <Form.Control required maxLength="10" minLength="10"
                                                              onBlur={() => this.checkIsExisting('phone', {
                                                                  country_code: this.state.splittedPhone[0],
                                                                  user_id: this.state?.selected_id,
                                                                  phone: this.state.splittedPhone[1]
                                                              }, 'phoneExistingMsg', 'phoneExistingStatus')}
                                                              value={this.state?.splittedPhone[1]}
                                                              onChange={e => this.inputPhoneOnChange(e, 1)}
                                                              type="Phone number"/>
                                            </div>

                                            {this.state.phoneExistingMsg && <Form.Text className={'text-danger'}>
                                                {this.state.phoneExistingMsg}
                                            </Form.Text>}
                                        </Col>
                                    </Form.Group>


                                    <Form.Group as={Row} controlId="aclLabel">
                                        <Form.Label column sm="3">
                                            Password <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control type={"password"} required
                                                          defaultValue={this.state.formData ? this.state.formData.password : ""}
                                                          name="password" onChange={this.handleFormDataChange}
                                                          placeholder={"password"}/>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </>

                        }


                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={(e) => this.handleSubmitForm(e)} variant="primary"> UPDATE </Button>
                    </Modal.Footer>
                </Modal>


                <div className="main-content">
                    <div className="page-header">
                        <h2 className="page-title">Staff</h2>
                        <div className="ml-auto">

                            {getLocalAuthData()?.permission?.includes('staff_add') ? <Link to={`${process.env.PUBLIC_URL}/staff/add`}
                                   className="btn btn-sm btn-secondary"><FiPlus/> Add Staff</Link> : null}
                        </div>
                    </div>

                    <Modal show={this.state.isShowingStatusChangingModal}
                           onHide={this.handleHideStatusChangingModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Enter Message</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group controlId={'modalMessage'}>
                                <Form.Label>Message</Form.Label>
                                <Form.Control as={'textarea'}
                                              onChange={this.handleInputMessageOnChange}
                                              rows={3} placeholder={'Enter message'}/>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="default" onClick={this.handleHideStatusChangingModal}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={this.handleProfileStatusUpdate}>
                                Confirm Decline
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="card-block">
                        <div className="block-body">
                            <form className="form-inline mb-3">

                                <div className="form-group mr-3">
                                    <label htmlFor="searchInput" className="sr-only">Search Key</label>
                                    <input
                                        type="search"
                                        id="orderNumber"
                                        className='form-control form-control-sm'
                                        placeholder="Search Key"
                                        name="search_string"
                                        onChange={this.handleSearchFormDataChange}
                                    />
                                </div>

                                <div className="form-group mr-3">
                                    <label htmlFor="barndName" className="sr-only">Status</label>
                                    <select
                                        id="status"
                                        name="status"
                                        className='form-control form-control-sm'
                                        onChange={this.handleSearchFormDataChange}
                                    >
                                        <option value="">--Select Status--</option>
                                        <option value="1">Active</option>
                                        <option value="2">Inactive</option>
                                    </select>
                                </div>


                                <Button className="mr-2" size="sm" variant="secondary"
                                        onClick={this.getTableData}>Search</Button>


                            </form>


                            {this.state.isLoading ?
                                <div>Loading...</div>
                                :
                                <>
                                    <div className="table-responsive">
                                        <table className="order-table table table-striped">
                                            <thead>
                                            <tr>
                                                <th style={{width: '20px'}}>#</th>
                                                <th>Name</th>
                                                <th>Contact Info</th>
                                                <th className={`text-center`}>Phone</th>
                                                <th className={`nowrap text-center`}/>
                                                <th style={{width: '110px'}}>Status</th>
                                                <th style={{width: '60px'}}/>
                                                <th style={{width: '120px'}}/>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.renderList()}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="mt-3">
                                        <Pagination
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            activePage={this.state?.pagination?.page}
                                            itemsCountPerPage={this.state?.pagination?.limit}
                                            totalItemsCount={this.state?.pagination?.totalDocs ? this.state?.pagination?.totalDocs : 0}
                                            pageRangeDisplayed={10}
                                            onChange={this.handlePageChange.bind(this)}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <ToastsContainer store={ToastsStore}/>
            </App>
        );
    }
}

export default withRouter(staffList);
