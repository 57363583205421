import axios from "axios";
import {getDeviceType, getLocalAuthData} from "../helper/utils";
import {API_BASE_URL} from "../helper/env";

export const getCommissionSettingList = () => {
    const authData = getLocalAuthData()

    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}/commission`, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                console.error(err)
                reject('Something went wrong')
            })
    })
}

export const getGeneralSettings = () => {
    const authData = getLocalAuthData()
    return new Promise(async (resolve, reject) => {
        axios.get(`${API_BASE_URL}/commission/general-setting`, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data.general_commission)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                console.error(err)
                reject(err.message)
            })
    })
}

export const getCommissionSettingDetails = (id) => {
    const authData = getLocalAuthData()

    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}/commission/${id}`, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                console.error(err)
                reject('Something went wrong')
            })
    })
}

export const addCommissionSettings = (data) => {
    const authData = getLocalAuthData()

    return new Promise((resolve, reject) => {
        axios.post(`${API_BASE_URL}/commission`, data, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                console.error(err)
                reject('Something went wrong')
            })
    })
}

export const updateCommissionSettings = (id, data) => {
    const authData = getLocalAuthData()

    return new Promise((resolve, reject) => {
        axios.put(`${API_BASE_URL}/commission/${id}`, data, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                console.error(err)
                reject('Something went wrong')
            })
    })
}

export const deleteCommissionSettings = (id) => {
    const authData = getLocalAuthData()

    return new Promise((resolve, reject) => {
        axios.delete(`${API_BASE_URL}/commission/${id}/permanent`, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                console.error(err)
                reject('Something went wrong')
            })
    })
}

export const toggleStatusUpdate = (id, data) => {
    const authData = getLocalAuthData()

    return new Promise((resolve, reject) => {
        axios.post(`${API_BASE_URL}/commission/updateStatus/${id}`, data, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                console.error(err)
                reject('Something went wrong')
            })
    })
}

export const commissionSettingsStatusChange = (id, data) => {
    const authData = getLocalAuthData()

    return new Promise((resolve, reject) => {
        axios.post(`${API_BASE_URL}/commission/updateStatus/${id}`, data, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                console.error(err)
                reject('Something went wrong')
            })
    })
}

export const getCommissionSettingsDetails = (id) => {
    const authData = getLocalAuthData()

    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}/commission/${id}`, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            console.log(res.data);
            resolve(res.data)
        }).catch(err => {
            console.error(err)
            reject('Something went wrong')
        })
    })
}


