import axios from 'axios';
import {API_BASE_URL} from "../helper/env";
import {getLocalAuthData} from "../helper/utils";

export const getLocationList = (paramsObj = {}) => {
  const authData = getLocalAuthData();
  const defaultParamsObj = {type: 'Country', ...paramsObj}

  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/location`, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': 'web'
      },
      params: defaultParamsObj
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data.locations);
        } else {
          reject(res.data.message);
        }
      })
      .catch(err => {
        reject(err.message);
      })
  })
}

export const getParentLocationByType = (type) => {
  const authData = getLocalAuthData();

  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/location/parent-location-by-type`, {
      params: {
        type: type
      },
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': 'web'
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data.locations);
        } else {
          reject(res.data.message);
        }
      })
      .catch(err => {
        reject(err.message);
      })
  })
}

export const getChildLocations = (id) => {
  const authData = getLocalAuthData();

  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/location/child-location/${id}`, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': 'web'
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data.locations);
        } else {
          reject(res.data.message)
        }
      })
  })
}

export const submitLocationForm = (data) => {
  const authData = getLocalAuthData();

  return new Promise((resolve, reject) => {
    axios.post(`${API_BASE_URL}/location`, data, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': 'web'
      }
    })
      .then(res => {
        if (res.data.status) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const updateLocation = (data, id) => {
  const authData = getLocalAuthData();

  return new Promise((resolve, reject) => {
    axios.put(`${API_BASE_URL}/location/${id}`, data, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': 'web'
      }
    })
      .then(res => {
        if (res.data.status) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const deleteLocation = (id) => {
  const authData = getLocalAuthData();

  return new Promise((resolve, reject) => {
    //TODO: this heard delete updated to soft delete
    axios.delete(`${API_BASE_URL}/location/${id}/permanent`, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': 'web'
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
    // .catch()
  })
}

export const getLocationDetails = (id) => {
  const authData = getLocalAuthData();

  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/location/${id}`, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': 'web'
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data.location)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}
