import React, {useEffect, useState} from 'react';
import Header from "./layouts/Header";
import Sidebar from "./layouts/Sidebar";
import {connect} from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import {collapseNavbar} from "./redux/navbar-toggle/navbarToggleActions";


function App({layout, children, navbar, handleCollapseNavbar}) {




    useEffect(() => {
        if (window.outerWidth < 1400) {
            handleCollapseNavbar()
        }
    }, [])

    return (
        <>
            <Header/>

            <div className={navbar.isNavbarShowing ? 'container-wrap' : 'container-wrap collapsed-sidebar'}>
                <Sidebar/>





                <div className="main-container">
                    {layout !== 'boxed' ? children
                        :
                        <div className="layout-boxed">
                            {children}
                        </div>
                    }

                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {
        navbar: state.navbar
    }
};

const mapDispatchToProps = dispatch => {
    return {
        handleCollapseNavbar: () => dispatch(collapseNavbar())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
