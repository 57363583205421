// noinspection JSUnresolvedVariable

import React, {Component} from 'react';
import App from "../../App";
import {Button, Modal, Form, Badge, Spinner, Row, Col} from "react-bootstrap";
import Pagination from 'react-js-pagination';
import {
    AiOutlineHourglass, AiOutlineTransaction, AiOutlineUser,
    BsListCheck,
    FaFileExport,
    FaSellsy, FaTruckPickup, FcApprove, FcShipped,
    FiFileText,
    FiMessageSquare, GiCash, GrDeliver, GrTransaction,
    ImCancelCircle
} from "react-icons/all";
import {Link, withRouter} from "react-router-dom";
import "../../assets/scss/order-list.scss";
import axios from "axios";
import {API_BASE_URL} from "../../helper/env";
import {getDeviceType, getLocalAuthData, objectToFormData} from "../../helper/utils";
import {toast} from "react-toastify";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import {getChildLocations} from "../../services/locationServices";
import {array} from "prop-types";
import {updateDepositPaymentStatus} from "../../services/orderServices";
// noinspection ES6CheckImport
import {
    useJsonToCsv
} from 'react-json-csv';
import {activityLogPostData, getTableData, postData} from '../../services/commonServices';
import DatePicker from "react-datepicker";
import BrowseButton from "./BrowseButton";
import InfoCard from "../../components/InfoCard";

const {saveAsCsv} = useJsonToCsv();

// noinspection DuplicatedCode
class OthersOrderParcelList extends Component {
    state = {
        orders_list: [],
        orders: [],
        merchantType: [],
        storeList: [],
        queryData: {
            from_date: '',
            to_date: ''
        },
        activePage: 1,
        pagination: {},
        activeBlockTab: 'All',
        isLoading: true,
        locations: [],
        businessChildLocations: [],
        status: null,
        changingStoreId: null,
        changeStatusMsg: '',
        isShowingStatusChangeConfirmModal: false,
        showCancelMessageRequired: false,
        isSubmitting: false,
        selected_order_id: "",
        selected_deposit_info: {},
        depositInfoModal: false,
        isSubmittingDepositPaymentStatus: false,
        isUploadDepositModal: false,
        depositInfo: {
            deposit_date: new Date(),
            bank_name: 'Bank Asia',
            account_number: '06933001008'
        },



        total_orders :0,
        total_sells :0,
        total_pending_orders :0,
        total_confirm_orders :0,
        total_cancel_order :0,
        total_picked_order :0,
        total_shipped_order :0,
        total_delivered_order :0,
        total_cod :0,
        total_online_transaction :0,
        total_bank_transaction :0,
        total_approved_bank_transaction : 0,
        muktopaath_user_order :0,

        total_pending_sells: 0,

        total_confirmed_sells : 0,
        total_cancel_sells : 0,
        total_picked_sells : 0,
        total_shipped_sells : 0,
        total_delivered_sells : 0,
        total_cod_sells : 0,
        total_online_transaction_sells : 0,
        total_bank_transaction_sells : 0,
        total_approved_bank_transaction_sells : 0,
        total_muktopath_sells : 0,


    }


    handleBlockTab = (label) => {

        if (label !== "Report"){
            this.setState({
                activeBlockTab: label,
                queryData: {
                    ...this.state.queryData,
                    status: label !== "All" ? label : "",
                    page: 1,
                },
                activePage: 1
            }, () => {
                this.getQueryData();
            });
        }else {
            this.setState({
                activeBlockTab: label,

            }, () => {

            });
        }

    };

    componentDidMount() {
        this.getParcels();

        const authToken = getLocalAuthData();
        const headers = {
            'Content-Type': 'application/json',
            'x-api-client': 'web',
            'x-auth-token': authToken.token,
        }

        axios.get(`${API_BASE_URL}/merchantType`, {headers: headers})
            .then(res => {
                this.setState({merchantType: res.data.merchant_type_obj});
            })
            .catch(err => {
                console.log(err)
            });



        axios.get(`${API_BASE_URL}/campaign`, {headers: headers})
            .then(res => {
                this.setState({campaign_list: res.data.items});
            })
            .catch(err => {
                console.log(err)
            });


        axios.get(`${API_BASE_URL}/location?type=Country`)
            .then(res => {
                this.setState({locations: res.data.locations});
            }).catch(() => console.log("Error"));
    }

    getParcels = async (url, config) => {

        let total_orders = 0
        let total_order_pending = 0;
        let total_confirmed_order = 0;
        let total_cancelled_order = 0;
        let total_picked_order = 0;
        let total_shipped_order = 0;
        let total_delivered_order = 0;

        let total_cod = 0;
        let total_online_transaction = 0;
        let total_bank_transaction = 0;
        let total_approved_bank_transaction = 0;
        let total_muktopaath_user_order = 0;

        let total_sell = 0;
        let total_pending_sells = 0;

        let total_confirmed_sells = 0;
        let total_cancel_sells = 0;
        let total_picked_sells = 0;
        let total_shipped_sells = 0;
        let total_delivered_sells = 0;
        let total_cod_sells = 0;
        let total_online_transaction_sells = 0;
        let total_bank_transaction_sells = 0;
        let total_approved_bank_transaction_sells = 0;
        let total_muktopath_sells = 0;





        const authToken = getLocalAuthData();
        const headers = {
            'Content-Type': 'application/json',
            'x-api-client': 'web',
            'x-auth-token': authToken.token,
        }

        await axios.get(`${API_BASE_URL}/order-parcel?muktopath_user=true&`, {headers: headers})
            .then(res => {
                this.setState({orders: res.data.items, pagination: res.data.pagination, isLoading: false , orders_list: res.data.items});
            },()=>{
            })
            .catch(() => {
                this.setState({isLoading: false})
            });

        await this.state?.orders.length > 0 && this.state.orders.map((data,index)=>{

            total_orders++;
            this.setState({
                total_orders : total_orders
            })

            if (data?.order_parcels?.status === "Pending"){
                total_order_pending++;
                total_pending_sells = total_pending_sells + data?.order_parcels?.parcel_grand_total
                this.setState({
                    total_pending_orders : total_order_pending ,
                    total_pending_sells : total_pending_sells
                })
            }
            else if (data?.order_parcels?.status === "Confirmed"){
                total_confirmed_order++
                total_confirmed_sells = total_confirmed_sells+data?.order_parcels?.parcel_grand_total
                this.setState({
                    total_confirm_order : total_confirmed_order,
                    total_confirmed_sells: total_confirmed_sells
                })
            }
            else if (data?.order_parcels?.status === "Cancelled"){
                total_cancelled_order++
                total_cancel_sells = total_cancel_sells +data?.order_parcels?.parcel_grand_total
                this.setState({
                    total_cancel_order : total_cancelled_order,
                    total_cancel_sells: total_cancel_sells
                })
            }
            else if (data?.order_parcels?.status === "Picked"){
                total_picked_order++
                total_picked_sells = total_picked_sells+ data?.order_parcels?.parcel_grand_total
                this.setState({
                    total_picked_order : total_picked_order,
                    total_picked_sells : total_picked_sells
                })
            }

            else if (data?.order_parcels?.status === "Shipped"){
                total_shipped_order++
                total_shipped_sells = total_shipped_sells+data?.order_parcels?.parcel_grand_total
                this.setState({
                    total_picked_order : total_shipped_order,
                    total_shipped_sells : total_shipped_sells
                })
            }
            else if (data?.order_parcels?.status === "Delivered"){
                total_delivered_order++
                this.setState({
                    total_picked_order : total_delivered_order
                })
            }

            if (data?.payment_method?.name === "COD"){
                total_cod++
                total_cod_sells = total_cod_sells + data?.order_parcels?.parcel_grand_total
                this.setState({
                    total_cod : total_cod,
                    total_cod_sells : total_cod_sells
                })
            }

            if (data?.payment_method?.name === "online_payment"){
                total_online_transaction++
                total_online_transaction_sells = total_online_transaction_sells + data?.order_parcels?.parcel_grand_total
                this.setState({
                    total_online_transaction : total_online_transaction,
                    total_online_transaction_sells : total_online_transaction_sells
                })
            }

            if (data?.payment_method?.name === "Bank Payment"){
                total_bank_transaction++
                total_bank_transaction_sells = total_online_transaction_sells+data?.order_parcels?.parcel_grand_total
                this.setState({
                    total_bank_transaction : total_bank_transaction,
                    total_bank_transaction_sells : total_bank_transaction_sells
                })
            }

            if (data?.order_parcels?.deposit_info?.deposit_status === "Approved"){
                total_approved_bank_transaction++
                total_approved_bank_transaction_sells = total_approved_bank_transaction_sells + data?.order_parcels?.parcel_grand_total
                this.setState({
                    total_approved_bank_transaction : total_approved_bank_transaction,
                    total_bank_transaction_sells: total_approved_bank_transaction_sells
                })
            }

            if (data?.customer_info?.muktopath_user === true){
                total_muktopaath_user_order++
                total_muktopath_sells = total_muktopath_sells + data?.order_parcels?.parcel_grand_total
                this.setState({
                    total_muktopaath_user_order : total_muktopaath_user_order,
                    total_muktopath_sells : total_muktopath_sells
                })
            }

            if (data?.order_parcels?.parcel_grand_total){
                total_sell = total_sell +data?.order_parcels?.parcel_grand_total
                this.setState({
                    total_sells : total_sell
                })
            }


        })

    }

    handlePageChange = async (pageNumber) => {
        await this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber});
        await this.getQueryData();
    }

    changeStatus = (statusObj, order_id, callback = () => null) => {
        const headers = {
            'Content-Type': 'application/json',
            'x-api-client': getDeviceType(),
            'x-auth-token': getLocalAuthData().token,
        }
        axios.patch(`${API_BASE_URL}/order/parcel/${order_id}/change-status`, statusObj,
            {headers: headers}
        )
            .then(response => {
                callback()
                toast.success(response.data.message);
                activityLogPostData('was change order status', '/orders/parcel/' + order_id, null).then()
            })
            .catch(() => {
                callback()
                toast.error('Something went wrong');
            });
    };

    getQueryData = () => {
        const headers = {
            'Content-Type': 'application/json',
            'x-api-client': 'web',
            'x-auth-token': getLocalAuthData().token,
        }
        let queryData = "";
        // eslint-disable-next-line array-callback-return
        Object.entries(this.state.queryData).map(([key, val]) => {
                queryData = queryData.concat(key + "=" + val + "&");
            }
        )
        axios.get(`${API_BASE_URL}/order-parcel?${queryData}`, {headers: headers})
            .then(res => {
                this.setState({orders: res.data.items, pagination: res.data.pagination});
            }).catch(() => console.log("Error"));
    }

    handleSearchFormDataChange = (e) => {
        const merchant_type = e.target.name;
        const merchant_type_id = e.target.value;
        if (merchant_type === "merchant_type") {
            const headers = {
                'Content-Type': 'application/json',
                'x-api-client': getDeviceType(),
                'x-auth-token': getLocalAuthData().token,
            }
            axios.get(`${API_BASE_URL}/store?merchant_type=${merchant_type_id}&lite_data=1`, {headers: headers})
                .then(res => {
                    this.setState({storeList: res.data.items});
                })
                .catch(err => {
                    console.log(err)
                });
        }
        this.setState({queryData: {...this.state.queryData, [e.target.name]: e.target.value}});
    };

    setQueryData = async (e) => {
        await this.setState({queryData: {...this.state.queryData, [e.target.name]: e.target.value}});
    }



    renderStoreList = () => {
        const storeList = this.state.storeList;
        if (storeList.length > 0) {
            return (
                storeList.map((items, index) =>
                    <option key={index} value={`${items._id}`}>{items.store_name}</option>
                )
            )
        }
    };

    hideChangeStatusModal = () => {
        this.setState({isShowingStatusChangeConfirmModal: false})
    }

    submitChangeStatusForm = (e) => {
        e.preventDefault()
        if (this.state.changeStatusMsg === '' && this.state.status === 'Cancelled') {
            return this.setState({showCancelMessageRequired: true});
        }
        this.setState({isSubmitting: true, showCancelMessageRequired: false}, () => {
            this.changeStatus({
                status: this.state.status,
                message: this.state.changeStatusMsg
            }, this.state.changingStoreId, () => {

                const authToken = getLocalAuthData();
                const headers = {
                    'Content-Type': 'application/json',
                    'x-api-client': 'web',
                    'x-auth-token': authToken.token,
                }

                axios.get(`${API_BASE_URL}/order-parcel`, {
                    headers: headers,
                    params: {
                        page: this.state.pagination.page,
                        status: this.state.activeBlockTab
                    }
                })
                    .then(res => {
                        this.setState({orders: res.data.items, pagination: res.data.pagination, isLoading: false});
                    })
                    .catch(() => {
                        this.setState({isLoading: false})
                    });

                this.setState({
                    isSubmitting: false,
                    message: '',
                    changingStoreId: null,
                    isShowingStatusChangeConfirmModal: false
                })
            })
        })
    }

    handleLocationInputOnChange = (e, index, typePurpose) => {
        const childLocations = [...this.state[typePurpose]];
        if (typePurpose === 'businessChildLocations') {
            this.handleSearchFormDataChange(e)
        } else {
            this.handleSearchFormDataChange(e)
        }
        if (e.target.value !== '') {
            getChildLocations(e.target.value)
                .then(res => {
                    childLocations.splice(index, childLocations.length - index);
                    if (res.length > 0) {
                        childLocations[index] = res
                    }
                    this.setState({[typePurpose]: childLocations})
                })
                .catch(errMsg => {
                    console.log(errMsg)
                    childLocations.splice(index, childLocations.length - index)
                    this.setState({[typePurpose]: childLocations})
                })
        } else {
            childLocations.splice(index, childLocations.length - index);
            this.setState({[typePurpose]: childLocations})
        }
    }

    renderLocations = () => {
        const locations = this.state.locations;
        if (locations) {
            return (
                <>
                    {locations.map((items) =>
                        <option value={`${items._id}`}>{items.name}</option>
                    )}
                </>
            )
        }
    };

    handleOnApply = (e, picker) => {
        const queryData = {...this.state.queryData};

        queryData.from_date = moment(picker.startDate).format('DD/MM/YYYY');
        queryData.to_date = moment(picker.endDate).format('DD/MM/YYYY');

        this.setState({queryData}, () => {
            console.log(this.state)
        })
    }

    renderPaymentStatus = (status) => {
        let payment_status = {
            0: "Unpaid",
            1: "Partially Paid",
            2: "Paid",
            3: "Cancelled",
            4: "Failed",
            5: "Refund",
            6: "Bank deposit payment in review",
            7: "Bank deposit declined",
        }
        return payment_status[status];
    }

    ShowStatusOption = (Status) => {
        let options;
        if (Status === "Delivered") {
            options = [{
                value: 'Delivered',
                label: 'Delivered'
            }
            ]
        } else if (Status === "Cancelled") {
            options = [{
                value: 'Cancelled',
                label: 'Cancelled'
            }
            ]
        } else {
            options = [{
                value: 'Pending',
                label: 'Pending',
            }, {
                value: 'Confirmed',
                label: 'Confirmed'
            }, {
                value: 'Processing',
                label: 'Processing'
            }, {
                value: 'Picked',
                label: 'Picked'
            }, {
                value: 'Shipped',
                label: 'Shipped'
            }, {
                value: 'Delivered',
                label: 'Delivered'
            }, {
                value: 'Cancelled',
                label: 'Cancelled'
            }
            ]
        }
        return options;
    }

    showDepositInfo = (order_id, deposit_info, payment_status) => {
        this.setState({
            selected_order_id: order_id,
            selected_deposit_info: deposit_info,
            depositInfoModal: true,
            current_payment_status: payment_status
        }, () => {
            console.log(this.state.selected_order_id);
            console.log(this.state.selected_deposit_info);
        });
    }

    updateDepositPaymentStatus = (order_id, payment_status) => {
        if (order_id && payment_status) {
            updateDepositPaymentStatus({order_id: order_id, payment_status: payment_status}).then(async (res) => {
                if (res.status === 1) {
                    toast.success(res.message);

                    /* RECALL LIST */
                    await this.getQueryData();
                    this.setState({depositInfoModal: false});

                } else {
                    toast.error(res.message);
                }
            }).catch(function (e) {
                console.log(e.message);
            });
        } else {
            console.log("order Id or payment status missing");
        }
    }

    handleCloseDepositInfoModal = () => {
        this.setState({depositInfoModal: false});
    }

    renderSelectedShippingEstimatedTime = (shippingOptions = [], shipping_option) => {
        if (shippingOptions.length > 0) {
            let selected_shipping_option = shippingOptions.filter(obj => obj.shipping_option === shipping_option)
            if (selected_shipping_option?.length > 0) {
                return selected_shipping_option[0].estimated_time;
            }
        } else {
            return "";
        }
    }




    handleExportFile = async (fileType) => {
        let queryData = "";
        // noinspection JSMismatchedCollectionQueryUpdate
        let mData = [];

        Object.entries(this.state.queryData).forEach(([key, val]) => {
                queryData = queryData.concat(key + "=" + val + "&");
            }
        )
        await this.setState({isExporting: true});
        let payment_status = ['Unpaid', 'Partially paid', 'Paid', 'Cancelled', 'Failed', 'Refund', 'Bank Deposit Payment In Review', 'Bank Deposit Declined']
        let total = this.state?.pagination?.limit
        let page = this.state?.pagination?.page
        let a = parseInt(total) * parseInt(page)
        let b = a - parseInt(total)
        let i = b+1


        this.state.orders && this.state.orders.map((data) => {

            let date = moment(data?.createdAt);
            let customer_phone = data?.customer_phone ? (data?.customer_phone?.split('-').join('-').split('+')[1]).toString() : "--"


            let productName = ''
            let productCategory = ''
            let quantity = ''

            data.order_parcels.order_parcel_items.map((data1, index1)=>{

                productName += "\n\n"+data1?.product_info?.name
                productCategory += "\n\n"+data1?.product_info?.category_info?.name
                quantity += "\n\n"+data1?.quantity

                console.log('Loop & Length' ,data.order_parcels.order_parcel_items.length + "  "+ index1 )
                if (data.order_parcels.order_parcel_items.length-1 === index1){
                    mData.push({
                        'Index': i++ ,
                        'Order Id':  data?.order_parcels?.order_parcel_code ,/*,*/
                        'Order Date':  moment(data?.createdAt).format('hh:mm a dd/mm/yyyy'),
                        'Product Category': '"'+productCategory+'"',
                        'Product Name': '"'+productName+'"',
                        'Quantity':'"'+quantity+'"',
                        'Customer Name': data?.customer_name,
                        'Customer Number': customer_phone,
                        'Customer Email': data?.customer_email,
                        'Payment Method': data?.payment_method?.name,
                        'Payment Status': payment_status[data?.order_parcels?.payment_status],
                        'Shipping Method': data.order_parcels?.selected_shipping_option ? data.order_parcels?.selected_shipping_option : 'ekShop Fulfillment' ,
                        'Shipping Charge': data?.order_parcels?.parcel_shipping_cost,
                        'Store Info': data?.order_parcels?.store_info?.store_name,
                        'Status': data?.order_parcels?.status,
                        'Price': data?.order_parcels?.parcel_grand_total,
                    });
                }
            })

        });
        // noinspection JSUnusedLocalSymbols
        const filename = 'EkShop-Parcel Order',
            fields = {
                'Index' : "Index",
                'Order Id' : "Order Info",
                'Order Date' : "Order Date",
                'Product Category': "Product Category",
                'Product Name': "Product Name",
                'Quantity': "Quantity",
                'Customer Name': "Customer Name",
                'Customer Number': "Customer Number",
                'Customer Email': "Customer Email",
                'Payment Method': "Payment Method",
                'Payment Status': "Payment Status",
                'Shipping Method': "Shipping Method",
                'Store Info': "Store Info",
                'Status': "Status",
                'Price': "Price",
            },
            style = {
                padding: "30px",
                maxHeight:"20px"
            },
            data = mData,
            text = "Convert Json to Csv";
        saveAsCsv({data, fields, filename});

        activityLogPostData('was export order parcel list', '/orders/parcel/list').then()

    }



    handleContactBtn = (e, id, key) => {
        e.preventDefault();
        let data = {};

        if (key === "user_id") {
            data.user_id = id
        } else {
            data.store_id = id
        }

        postData(`/paired`, data)
            .then((res) => {
                localStorage.setItem('pairId', res.paired._id);
                this.props.history.push('/messages');
            })
            .catch((err) => {
                console.log(err);
            });
    }

    /* DEPOSIT MODAL START */
    handleDepositModalShow = (orderId, amount) => {
        const depositInfo = {...this.state.depositInfo}
        depositInfo.order_id = orderId
        depositInfo.amount = amount.toString()
        this.setState({depositInfo, isUploadDepositModal: true})
    }

    handleDepositModalClose = () => {
        this.setState({isUploadDepositModal: false})
    }

    uploadDepositInfo = (data) => {
        return new Promise((resolve, reject) =>
            axios.post(`${API_BASE_URL}/payment/save-bank-deposit`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-client': 'web',
                    'x-auth-token': getLocalAuthData().token,
                }
            })
                .then(res => res.data.status === 1 ? resolve(res.data) : reject(res.data.message))
                .catch(err => reject(err.message)))
    }

    handleSubmitDepositInfo = (e) => {
        e.preventDefault()
        if (!this.state.depositInfo.order_id) alert('Order id is Required')
        this.uploadDepositInfo(this.state.depositInfo).then(res => {
            toast.success(res.message)
            this.getParcels();
            this.setState({isUploadDepositModal: false})
        })
            .catch(err => {
                toast.error(err.message)
            })
    }

    uploadImage = (data) => {
        return new Promise((resolve, reject) =>
            axios.post(`${API_BASE_URL}/images`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-client': 'web',
                    'x-auth-token': getLocalAuthData().token,
                }
            })
                .then(res => resolve(res.data))
                .catch(err => reject(err.message)))
    }

    depositInputOnChange = async (e) => {
        const depositInfo = {...this.state.depositInfo}
        if (e.target.type !== 'file') {
            depositInfo[e.target.name] = e.target.value
            this.setState({depositInfo})
        } else {
            this.setState({isImageUploading: true})
            this.uploadImage(objectToFormData({image: e.target.files[0], image_category: 'payment'}))
                .then(res => {
                    depositInfo.deposit_slip = res?.image?.actual_img_path
                    this.setState({depositInfo, isImageUploading: false})
                })
                .catch(() => {
                    this.setState({isImageUploading: false})
                })
        }
    }

    renderMerchantType = () => {
        const merchantType = this.state.merchantType;
        if (merchantType) {
            return (
                <>
                    {merchantType.map((items, index) =>
                        <option key={index} value={`${items._id}`}>{items.type_name}</option>
                    )}
                </>
            )
        }
    };

    renderCampaignId = () => {

        const campaign_list = this.state.campaign_list;
        if (campaign_list) {
            return (
                <>
                    {campaign_list.map((items, index) =>
                        <>{items.status === "1" && <option key={index} value={`${items._id}`}>{items.campaign_title}</option>}</>
                    )}
                </>
            )
        }
    };


    /* DEPOSIT MODAL END */

    render() {
        // noinspection EqualityComparisonWithCoercionJS
        return (
            <App>
                <div className="main-content">
                    <div className="page-header">
                        <h2 className="page-title">Others Order Parcel List</h2>
                        <div className="ml-auto">

                            {/* <Button size={"sm"} variant={"defalut"} className="mb-0 ml-2"
                      onClick={() => this.handleExportFile2('xlsx')}><FaFileExport/>Export Product Report</Button>*/}
                            {getLocalAuthData()?.permission?.includes('order_parcel_list_export') ?
                                <Button size={"sm"} variant={"defalut"} className="mb-0 ml-2"
                                        onClick={() => this.handleExportFile('xlsx')}><FaFileExport/> Export</Button> : null}
                        </div>
                    </div>
                    {this.state.isLoading ?
                        <div className={`card-block`}>
                            <div className="block-body">
                                Loading...
                            </div>
                        </div>
                        :
                        <>
                            {getLocalAuthData()?.permission?.includes('order_parcel_list_view') ?
                                <div className="card-block">
                                    <div className="block-body">


                                        {this.state.orders?.length > 0 ?
                                            <>
                                                <Modal show={this.state.isShowingStatusChangeConfirmModal} onHide={this.hideChangeStatusModal}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Change Status Message</Modal.Title>
                                                    </Modal.Header>
                                                    <Form onSubmit={this.submitChangeStatusForm}>
                                                        <Modal.Body>
                                                            {this.state.status === 'Cancelled' ?
                                                                <Form.Control as={`textarea`}
                                                                              onChange={(e) => {
                                                                                  this.setState({changeStatusMsg: e.target.value})
                                                                              }}
                                                                              placeholder={`Enter you message here...`} rows={3}/>
                                                                : <span><h5>Are you sure you want to change the order status?</h5></span>}
                                                            {this.state.status === 'Cancelled' && this.state.showCancelMessageRequired ?
                                                                <span><h5 style={{'color': "red"}}><i>Cancel message is required!</i></h5></span>
                                                                : ''}
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            {this.state.status === 'Cancelled' && this.state.showCancelMessageRequired ?
                                                                <>
                                                                    <Button variant="default" onClick={this.hideChangeStatusModal}>
                                                                        Close
                                                                    </Button>
                                                                    <Button disabled={this.state.isSubmitting}
                                                                            variant="primary" type={`submit`}>
                                                                        {this.state.isSubmitting ? 'Submitting...' : 'Submit'}
                                                                    </Button>
                                                                </>
                                                                :
                                                                <>
                                                                    <Button variant="default" onClick={this.hideChangeStatusModal}>
                                                                        No
                                                                    </Button>
                                                                    <Button disabled={this.state.isSubmitting}
                                                                            variant="primary" type={`submit`}>
                                                                        {this.state.isSubmitting ? 'Submitting...' : 'Yes'}
                                                                    </Button>
                                                                </>
                                                            }
                                                        </Modal.Footer>
                                                    </Form>
                                                </Modal>

                                                <Modal size={"lg"} show={this.state.depositInfoModal} onHide={this.handleCloseDepositInfoModal}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Deposit Payment Information</Modal.Title>
                                                    </Modal.Header>

                                                    <Modal.Body>
                                                        {(this.state?.current_payment_status == 2 || this.state?.current_payment_status == 6 || this.state?.current_payment_status == 7) ?
                                                            <table className="info-table table-lg">
                                                                <tbody>

                                                                <tr>
                                                                    <td className="text-bold">Bank Name: <span className="text-danger">*</span></td>
                                                                    <td className="text-bold">:</td>
                                                                    <td className="text-bold">{this.state?.selected_deposit_info?.bank_name}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td className="text-bold">Account Number: <span className="text-danger">*</span></td>
                                                                    <td className="text-bold">:</td>
                                                                    <td className="text-bold">{this.state?.selected_deposit_info?.account_number}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td className="text-bold">Branch Name: <span className="text-danger">*</span></td>
                                                                    <td className="text-bold">:</td>
                                                                    <td className="text-bold">{this.state?.selected_deposit_info?.branch_name}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td className="text-bold">Deposit By: <span className="text-danger">*</span></td>
                                                                    <td className="text-bold">:</td>
                                                                    <td className="text-bold">{this.state?.selected_deposit_info?.deposit_by}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td className="text-bold">Deposit Date: <span className="text-danger">*</span></td>
                                                                    <td className="text-bold">:</td>
                                                                    <td
                                                                        className="text-bold">{moment(this.state?.selected_deposit_info?.deposit_date).format('ll')}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td className="text-bold">Slip Number: <span className="text-danger">*</span></td>
                                                                    <td className="text-bold">:</td>
                                                                    <td className="text-bold">{this.state?.selected_deposit_info?.slip_number}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td className="text-bold">Desposit Status:</td>
                                                                    <td className="text-bold">:</td>
                                                                    <td className="text-bold">{this.state?.selected_deposit_info?.deposit_status}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td colSpan={"3"}>
                                                                        <img src={this.state?.selected_deposit_info?.deposit_slip}
                                                                             style={{maxWidth: '100%', height: "auto"}} alt=""/>
                                                                    </td>
                                                                </tr>

                                                                </tbody>
                                                            </table>
                                                            :
                                                            <>
                                                                {this.state?.current_payment_status === 0 ? <>
                                                                    <span>Please wait for deposit upload</span></> : ''}
                                                            </>
                                                        }


                                                    </Modal.Body>

                                                    <Modal.Footer>
                                                        <Button variant="default" onClick={this.handleCloseDepositInfoModal}>
                                                            Close
                                                        </Button>

                                                        {(this.state?.current_payment_status === 6) &&
                                                        <>
                                                            <Button variant="primary" type={`button`}
                                                                    disabled={this.state.isSubmittingDepositPaymentStatus === 2}
                                                                    onClick={() => this.updateDepositPaymentStatus(this.state.selected_order_id, 2)}>
                                                                {this.state.isSubmittingDepositPaymentStatus ? 'Please Wait...' : 'Approve'}
                                                            </Button>
                                                            <Button variant="warning" type={`button`}
                                                                    disabled={this.state.isSubmittingDepositPaymentStatus === 7}
                                                                    onClick={() => this.updateDepositPaymentStatus(this.state.selected_order_id, 7)}>
                                                                {this.state.isSubmittingDepositPaymentStatus ? 'Please Wait...' : 'Declined'}
                                                            </Button>
                                                        </>
                                                        }

                                                    </Modal.Footer>
                                                </Modal>
                                                <Modal show={this.state.isUploadDepositModal} onHide={this.handleDepositModalClose}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Upload Deposit Info</Modal.Title>
                                                    </Modal.Header>
                                                    <Form onSubmit={this.handleSubmitDepositInfo}>
                                                        <Modal.Body>
                                                            <Form.Group controlId={'deposit_by'}>
                                                                <Form.Label>Deposit By <span className={"text-danger"}>*</span></Form.Label>
                                                                <Form.Control name={'deposit_by'}
                                                                              required
                                                                              onChange={this.depositInputOnChange}
                                                                              type={'text'}/>
                                                            </Form.Group>
                                                            <Form.Group controlId={'deposit_date'}>
                                                                <Form.Label>Deposit Date <span className={"text-danger"}>*</span></Form.Label>
                                                                <DatePicker selected={this.state.depositInfo.deposit_date}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            maxDate={new Date()}
                                                                            required
                                                                            className={'form-control'} onChange={date => {
                                                                    const depositInfo = {...this.state.depositInfo}
                                                                    depositInfo.deposit_date = date
                                                                    this.setState({depositInfo}, () => {
                                                                        console.log('Deposit Info: ', this.state.depositInfo);
                                                                    })
                                                                }} classes={""}/>
                                                            </Form.Group>
                                                            <Form.Group controlId={'branch_name'}>
                                                                <Form.Label>Deposited Branch <span className={"text-danger"}>*</span></Form.Label>
                                                                <Form.Control name={'branch_name'}
                                                                              required
                                                                              onChange={this.depositInputOnChange}
                                                                              type={'text'}/>
                                                            </Form.Group>
                                                            <Form.Group controlId={'slip_number'}>
                                                                <Form.Label>Slip Number <span className={"text-danger"}>*</span></Form.Label>
                                                                <Form.Control name={'slip_number'}
                                                                              required
                                                                              onChange={this.depositInputOnChange}
                                                                              type={'text'}/>
                                                            </Form.Group>
                                                            <Form.Group controlId={'deposit_slip'}>
                                                                <Form.Label className={'d-block'}>Deposit Slip</Form.Label>
                                                                <div className="d-flex align-items-center">
                                                                    <BrowseButton required isImageUploading={this.state.isImageUploading}
                                                                                  onChange={this.depositInputOnChange}
                                                                                  id={'deposit_slip'} name={'deposit_slip'}/>
                                                                    {this.state.isImageUploading &&
                                                                    <div style={{transform: 'translateY(-4px)'}}>
                                                                        <Spinner animation="border"
                                                                                 className={'ml-2'} size="sm"
                                                                                 variant="info"/></div>}
                                                                </div>
                                                            </Form.Group>
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button variant="default" onClick={this.handleDepositModalClose}>Close</Button>
                                                            <Button variant="secondary" type="submit">Upload Deposit Info</Button>
                                                        </Modal.Footer>
                                                    </Form>
                                                </Modal>


                                                <div className="table-responsive">
                                                    <table className="order-table table table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th style={{width: '1%'}}>#</th>
                                                            <th>Order Info</th>
                                                            <th>Customer Info</th>
                                                            <th>Store Info</th>
                                                            <th>Payment Info</th>
                                                            <th>Shipping Info</th>
                                                            <th style={{width: '10%'}}>Status Message</th>
                                                            <th style={{width: '3%'}}>Status</th>
                                                            <th style={{width: '10%'}}/>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.orders.map((item, index) => {
                                                            return (
                                                                <tr key={this.state.pagination.pagingCounter + index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>
                                                                        <div className="order-item">
                                                                            <h4 className="order-id">
                                                                                <Link
                                                                                    to={`${process.env.PUBLIC_URL}/orders/parcel/${item.order_parcels._id}`}
                                                                                    target={'_blank'}>{item?.order_parcels?.order_parcel_code}</Link>
                                                                            </h4>
                                                                            <span className="order-date">{moment(item.createdAt).format(`ll`)}</span>
                                                                            <span className="order-time">{moment(item.createdAt).format(`LT`)}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="order-item">
                                                                            <h4 className="order-id">
                                                                                <Link
                                                                                    to={`${process.env.PUBLIC_URL}/customers/details/${item.customer_info?._id}`}
                                                                                    target={'_blank'}>{item.customer_info?.name}</Link>
                                                                            </h4>
                                                                            <ul className="ordered-items">
                                                                                <li>{item.customer_info?.phone}</li>
                                                                                <li>{item.customer_info?.email}</li>
                                                                                <li>
                                                                                    <Link
                                                                                        onClick={(e) => this.handleContactBtn(e, item?.customer_info?._id, "user_id")}
                                                                                        to={`/`} target={'_blank'}>
                                                                                        <span className="icon"><FiMessageSquare/></span> Contact Customer
                                                                                    </Link>
                                                                                </li>
                                                                                <li><Badge variant="info">{item.customer_info?.muktopath_user === true && item.customer_info?.muktopath_user !== undefined && 'MuktoPath user'}</Badge></li>
                                                                            </ul>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="order-item">
                                                                            <h4 className="order-id">
                                                                                <Link
                                                                                    to={`${process.env.PUBLIC_URL}/stores/details/${item?.order_parcels?.store_info?._id}`}
                                                                                    target={'_blank'}>{item?.order_parcels?.store_info?.store_name}</Link>
                                                                            </h4>
                                                                            <ul className="ordered-items">
                                                                                <li>
                                                                                    <Link
                                                                                        onClick={(e) => this.handleContactBtn(e, item?.order_parcels?.store_info?._id, "store_id")}
                                                                                        to={`/`}>
                                                                                        <span className="icon"><FiMessageSquare/></span> Contact Seller
                                                                                    </Link>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="order-item">
                                                                            <h4
                                                                                className="order-id">৳{item?.order_parcels?.parcel_grand_total.toFixed(2)}</h4>
                                                                            <ul className="ordered-items">
                                                                                <li>{item.payment_method?.name ? item.payment_method?.name : 'COD'}</li>
                                                                                <li>{this.renderPaymentStatus(item.order_parcels.payment_status)}</li>
                                                                            </ul>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="order-item">
                                                                            <h4
                                                                                className="order-id">{item?.order_parcels?.selected_shipping_option ? item?.order_parcels?.selected_shipping_option : "ekShop Fulfillment"}</h4>
                                                                            <ul className="ordered-items">
                                                                                <li>৳{item?.order_parcels?.parcel_shipping_cost?.toFixed(2)}</li>
                                                                                <li><span
                                                                                    className="d-block">{this.renderSelectedShippingEstimatedTime(item.order_parcels?.possible_shipping_options, item.order_parcels?.selected_shipping_option || "ekShop Fulfillment")}</span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </td>
                                                                    <td>{item?.order_parcels?.status_message || '-'}</td>

                                                                    {/* disabling status selection dropdown if deposit form is not updated */}
                                                                    <td>
                                                                        {
                                                                            (
                                                                                (item?.payment_method?.name === "Bank Payment" && item?.payment_status === 6) ||
                                                                                (item?.payment_method?.name === "Bank Payment" && item?.payment_status === 2) ||
                                                                                item?.payment_method?.name === "COD" ||
                                                                                item?.payment_method?.name === "online_payment"
                                                                            ) &&
                                                                            <>
                                                                                <select style={{width: '150px'}}
                                                                                        value={item.order_parcels.status}
                                                                                        className="form-control form-control-sm"
                                                                                        onChange={(e) => {
                                                                                            this.setState({
                                                                                                status: e.target.value,
                                                                                                changingStoreId: item.order_parcels._id,
                                                                                                isShowingStatusChangeConfirmModal: true
                                                                                            })
                                                                                        }}>
                                                                                    {this.ShowStatusOption(item.order_parcels.status).map((option, index) => (
                                                                                        <option key={index} selected={item.order_parcels.status === option.value}
                                                                                                value={option.value}>{option.label}</option>
                                                                                    ))}
                                                                                </select>
                                                                                {item?.payment_status === 6 ? <>
                                                                                    <span>Please check uploaded deposit</span></> : ''}
                                                                            </>
                                                                        }
                                                                        {
                                                                            (item?.payment_method?.name === "Bank Payment" && item?.payment_status === 0) &&
                                                                            <>
                                                                                {item?.payment_status === 0 ? <>
                                                                                    <select style={{width: '150px'}}
                                                                                            value={item.order_parcels.status}
                                                                                            className="form-control form-control-sm"
                                                                                            onChange={(e) => {
                                                                                                this.setState({
                                                                                                    status: e.target.value,
                                                                                                    changingStoreId: item.order_parcels._id,
                                                                                                    isShowingStatusChangeConfirmModal: true
                                                                                                })
                                                                                            }}>
                                                                                        {item.order_parcels.status === 'Cancelled' && this.ShowStatusOption(item.order_parcels.status).map((option, index) => (
                                                                                            <option key={index} selected={item.order_parcels.status === option.value}
                                                                                                    value={option.value}>{option.label}</option>
                                                                                        ))}
                                                                                        {item.order_parcels.status === 'Pending' && <>
                                                                                            <option key={0} selected={item.order_parcels.status === 'Pending'}
                                                                                                    value={'Pending'}>{'Pending'}</option>
                                                                                            <option key={6} selected={item.order_parcels.status === 'Cancelled'}
                                                                                                    value={'Cancelled'}>{'Cancelled'}</option>
                                                                                        </>}
                                                                                    </select>
                                                                                    {item.order_parcels.status === 'Pending' &&
                                                                                    <span>Please wait for deposit upload</span>}</> : ''}
                                                                            </>
                                                                        }
                                                                    </td>

                                                                    <td>
                                                                        {getLocalAuthData()?.permission?.includes('order_parcel_list_item_view') ?
                                                                            <Link to={`${process.env.PUBLIC_URL}/orders/parcel/${item.order_parcels._id}`}
                                                                                  className="btn btn-block btn-sm btn-outline-info"
                                                                                  target={'_blank'}>View</Link> : null}

                                                                        {getLocalAuthData()?.permission?.includes('order_invoice') ?
                                                                            <Link to={`${process.env.PUBLIC_URL}/orders/invoice/${item._id}`}
                                                                                  className="btn btn-block btn-sm btn-outline-info"
                                                                                  target={'_blank'}>Invoice</Link> : null}

                                                                        {(item.payment_method?.name === 'Bank Payment' && (item?.deposit_info === undefined || item?.deposit_info === null)) && item.order_parcels.status === 'Pending' &&
                                                                        <Button to="#"
                                                                                className="btn-action btn-block btn-outline-info"
                                                                                onClick={() => {
                                                                                    this.handleDepositModalShow(item._id, item?.order_parcels?.parcel_grand_total)
                                                                                }}>Upload
                                                                            Deposit Slip</Button>}
                                                                        {(item?.payment_method?.name === "Bank Payment") && <button
                                                                            onClick={() => this.showDepositInfo(item?._id, item?.deposit_info, item?.payment_status, item?.payment_status)}
                                                                            className="btn btn-block btn-sm btn-outline-info">Deposit
                                                                            Info</button>}

                                                                        {/*<Link to={`${process.env.PUBLIC_URL}/orders/details/${item._id}`}
                                        className="btn btn-block btn-sm btn-outline-secondary" target={'_blank'}>Order Detail</Link>*/}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}

                                                        </tbody>
                                                    </table>
                                                </div>


                                                <div className="mt-3">
                                                    <Pagination
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                        activePage={this.state.pagination?.page}
                                                        itemsCountPerPage={this.state.pagination?.limit}
                                                        totalItemsCount={this.state.pagination?.totalDocs}
                                                        onChange={this.handlePageChange.bind(this)}/>
                                                </div>

                                            </>
                                            :
                                            <div className={`alert alert-warning text-center text-bold`}>
                                                No order found
                                            </div>
                                        }
                                    </div>
                                </div> : null}
                        </>
                    }

                </div>
            </App>
        );
    }
}

export default withRouter(OthersOrderParcelList);
