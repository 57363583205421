import React, {Component} from 'react';
import App from "../../App";
import {FiPlus} from "react-icons/all";
import {Link} from "react-router-dom";
import "../../assets/scss/order-list.scss";
import {changeStatus, deleteVariant, getVariantList} from "../../services/variantServices";
import {Alert} from "react-bootstrap";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import Pagination from "react-js-pagination";
import {
  getDetail,
  getTableData,
  deleteItem,
  updateItemPartial,
  activityLogPostData
} from "../../services/commonServices";
import {getLocalAuthData} from "../../helper/utils";

class VariantList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attributes: [],
      isLoading: true,
      queryData: {
        limit: 10, page: 1, search_string: ''
      }
    }
  }

  componentDidMount() {
    this.getVariantList()
  }

  getVariantList = () => {
    getTableData("/attributeList", this.state.queryData).then(res => {
      this.setState({attributes: res.items, pagination: res.pagination, isLoading: false});
    }).catch(() => {
      this.setState({isLoading: false});
    })
  }

  handleChangeStatus = (statusObj,name) => {
    changeStatus(statusObj)
      .then(res => {
        toast.success(res.message)
        activityLogPostData('was change variant item status','/variants/list/',name)
      })
      .catch(errMsg => {
        toast.error(errMsg)
      })
  };

  handleDeleteVariant = (id, isPermanent,name) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to delete this variant?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteVariant(id, isPermanent)
            .then(res => {
              getVariantList()
                .then(res => {
                  this.setState({attributes: res});
                })
              toast.success(res.message);
              activityLogPostData('was delete this variant','/variants/edit/'+id,name)
            })
            .catch(errMsg => {
              toast.error(errMsg)
            })
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }

  handlePageChange = async (pageNumber) => {
    let queryData = {...this.state.queryData};
    queryData['page'] = pageNumber
    this.setState({queryData}, ()=>{
      this.getVariantList();
    });
  }

  render() {
    return (
      <App layout="boxed">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Variant</h2>

            { getLocalAuthData()?.permission?.includes('varient_add') ?
              <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/variants/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add
                Variant</Link>
            </div>:null}

          </div>


          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Variant List</h4>
            </div>

            <div className="block-body">
              {this.state.isLoading ?
                <div>Loading...</div>
                :
                <>
                  {
                    this.state.attributes.length > 0 ?
                      <>
                        <table className="order-table table table-striped">
                          <thead>
                          <tr>
                            <th style={{width: '1%'}}>#</th>
                            <th>Name</th>
                            <th>Attribute Type</th>
                            <th>Status</th>
                            <th style={{width: '1%'}}/>
                          </tr>
                          </thead>
                          <tbody>

                          { (getLocalAuthData()?.permission?.includes('varient_list')) ? this.state.attributes.map((attr, index) => {
                            return (
                              <tr key={index}>
                                <td>{this.state?.pagination?.pagingCounter + index}</td>
                                <td>{attr.name}</td>
                                <td>{attr?.attribute_type === 1 ? 'Key product information' : 'Product variant\n'}</td>
                                <td>
                                  {getLocalAuthData()?.permission?.includes('varient_active_inactive') ? <select
                                    defaultValue={attr.status}
                                    style={{width: '150px'}}
                                    className="form-control form-control-sm"
                                    onChange={(e) => {
                                      this.handleChangeStatus({id: attr._id, status: e.target.value} ,attr.name )
                                    }}
                                    name="status"
                                  >
                                    <option value="1">Active</option>
                                    <option value="2">Inactive</option>
                                  </select>:null}
                                </td>
                                <td className={"nowrap"}>
                                  {getLocalAuthData()?.permission?.includes('varient_edit') ? <Link to={`${process.env.PUBLIC_URL}/variant/edit/${attr._id}`}
                                         className={"mr-3 text-info text-bold"}>Edit</Link>:null}
                                  {getLocalAuthData()?.permission?.includes('varient_delete') ? <span
                                    style={{cursor: 'pointer'}}
                                    onClick={() => this.handleDeleteVariant(attr._id, true , attr.name)}
                                    className={"text-danger text-bold"}>Delete</span>:null}

                                </td>
                              </tr>
                            );
                          }):null}

                          </tbody>
                        </table>

                      </>
                      :
                      <Alert variant={"warning"}>
                        No variant found
                      </Alert>
                  }
                </>
              }
            </div>

            <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={this.state.pagination?.page}
                itemsCountPerPage={this.state.pagination?.limit}
                totalItemsCount={this.state.pagination?.totalDocs}
                pageRangeDisplayed={20}
                onChange={this.handlePageChange.bind(this)}
            />

          </div>
        </div>
      </App>
    );
  }
}

export default VariantList;
