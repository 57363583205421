import React, {Component} from "react";
import {Modal, Button, Col, Form, Row} from "react-bootstrap";
import "../assets/scss/business-block-card.scss";
import {FiMove, FiSettings, FiTrash} from "react-icons/all";
import StoreCard from "./StoreCard";
import {isEmptyObj} from "../helper/utils";
import {getStoreList, getStoreListV2} from "../services/storeServices";
import {getMerchantType} from "../services/merchantTypeServices";
import LoadingSpinner from "./LoadingSpinner";

class StoreBlock extends Component {
  state = {
    isSettingModalShowing: false,
    settingData: {params: {limit: 4}},
    storeList: [],
    isDataFetching: false,
    merchantTypes: []
  }

  componentDidMount() {

    let obj = {isDataFetching: false,isConfigured: true, settingData: this.props.settings};
    this.setState(obj)

    getMerchantType().then(res => {
      this.setState({merchantTypes: res})
    }).catch(errMsg => {
      console.error(errMsg)
    })

    if (!isEmptyObj(this.props.settings.params)) {
      this.setState({isConfigured: true, isDataFetching: true})

      getStoreListV2(this.props.settings.params).then(res => {
        this.setState({storeList: res.items, isDataFetching: false})
      }).catch(errMsg => {
        console.error('', errMsg)
        this.setState({isDataFetching: false})
      })
    }
  }

  handleSettingModalShow = () => {
    this.setState({isSettingModalShowing: true})
  }

  handleSettingModalHide = () => {
    this.setState({isSettingModalShowing: false})
  }

  handleSettingInputOnChange = (e) => {
    const settingData = {...this.state.settingData}
    if (e.target.type !== 'checkbox') {
      if (e.target.name === 'title' || e.target.name === "title_local") {
        settingData[e.target.name] = e.target.value
      } else {
        settingData.params[e.target.name] = e.target.value
      }
    } else {
      if (e.target.checked) {
        settingData.params[e.target.name] = e.target.value
      } else {
        delete settingData.params[e.target.name]
      }
    }
    this.setState({settingData})
  }

  handleOnSaveSettingForm = () => {
    this.props.actions.onChangeSettings(this.state.settingData, () => {
      this.handleSettingModalHide()
      getStoreListV2(this.props.settings.params).then(res => {
        this.setState({storeList: res.items, isDataFetching: false, isConfigured: true})
      }).catch(errMsg => {
        this.setState({isDataFetching: false})
      })
    })
  }

  render() {
    const {settings, actions, handler, isDisabled, index, dragHandleProps} = this.props;

    return (
      <div className="card-block position-relative">
        {this.state.isDataFetching &&
        <LoadingSpinner/>
        }

        <div className="block-header">
          <h4 className="block-title">{handler && dragHandleProps &&
          <span className={'btn-draggable'} {...handler.dragHandleProps}>
              <FiMove/>
            </span>} {settings?.title || 'Untitled'} {!dragHandleProps &&
          <span className={"text-danger"} style={{"fontSize": "12px"}}>please save to drag</span>}</h4>

          <div className={'block-actions'}>
            <Form.Check
              inline
              defaultChecked={!isDisabled}
              type="switch"
              id={`isDisabled-${index}`}
              onChange={actions.toggleBlockSectionDisable}
              label={isDisabled ? 'Off' : 'On'}/>

            <span onClick={this.handleSettingModalShow} className={'btn-block-action'}>
              <FiSettings/>
            </span>

            <span onClick={actions.onDelete} className={'btn-block-action ml-3'}>
              <FiTrash/>
            </span>
          </div>
        </div>

        <Modal show={this.state.isSettingModalShowing} onHide={this.handleSettingModalHide}>
          <Modal.Header closeButton>
            <Modal.Title>Block Settings</Modal.Title>
          </Modal.Header>
          <Form>
            <Modal.Body>
              <Form.Group controlId="blockTitle">
                <Form.Label>Block Title</Form.Label>
                <Form.Control type="text" name={'title'}
                              defaultValue={settings?.title}
                              onChange={this.handleSettingInputOnChange} placeholder="Enter block title"/>
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Block Title (বাংলা)</Form.Label>
                <Form.Control type="text"
                              name={'title_local'}
                              defaultValue={settings?.title_local}
                              onChange={this.handleSettingInputOnChange}
                              placeholder="Enter block title in local language"/>
              </Form.Group>

              <Form.Group controlId="displayCategory">
                <Form.Label>Merchant Type</Form.Label>
                <Form.Control name={'merchant_type'}
                              defaultValue={settings?.params?.merchant_type}
                              onChange={this.handleSettingInputOnChange} as="select">
                  <option label={'--Select--'}/>
                  {this.state.merchantTypes?.length > 0 &&
                  this.state.merchantTypes.map((item, index) => (
                    <option key={'merchant-list-' + index} value={item._id}>{item.type_name}</option>
                  ))
                  }
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="dataLimit">
                <Form.Label>Limit</Form.Label>
                <Form.Control type="number" name={'limit'}
                              defaultValue={settings?.params?.limit}
                              onChange={this.handleSettingInputOnChange} placeholder="Enter the store limit"/>
              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleSettingModalHide}>
                Close
              </Button>
              <Button variant="primary" onClick={this.handleOnSaveSettingForm}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <div className="block-body">
          {(!isEmptyObj(settings.params) && this.state.isConfigured) ?
            <>
              {this.state.storeList.length > 0 ?
                <Row>
                  {this.state.storeList.map((store, index) => <Col key={'store-list-' + index} lg={3} md={6} xs={1}>
                    <StoreCard data={store}/>
                  </Col>)}

                  {/*<Col lg={3} md={6}>
                  <ButtonAddStore/>
                </Col>*/}
                </Row>
                :
                <div className={'alert alert-warning text-center'}>
                  No store found.
                </div>
              }
            </>
            :
            <div className={'alert text-center alert-warning'}>
              This block has no configuration.
              Please <span onClick={this.handleSettingModalShow}
                           style={{cursor: "pointer"}}
                           className="text-primary">click here</span> to configure
            </div>
          }
        </div>
      </div>
    );
  }
}

export default StoreBlock;
