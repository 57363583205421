import React, {useEffect, useState} from "react";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import slideSample from "../assets/img/slide-sample.jpg";
import "../assets/scss/main-slide.scss";
import {FiMove, FiPlus, FiTrash} from "react-icons/all";
import slideImg from "../temp/slide-img.jpg";
import {getLocalAuthData, Reorder} from "../helper/utils";
import BrowseFiles from "./BrowseFiles";
import {addItem, getData, permanentDeleteItem} from "../services/baseServices";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import {deleteStore, getStoreList} from "../services/storeServices";
/*import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";*/
import App from "../App";
import {getItemStyle, getSectionListStyle} from "../helper/dragDropStyles";
import { Container, Draggable } from 'react-smooth-dnd';
import {updateItemPartial} from "../services/commonServices";
import { SketchPicker } from 'react-color'


const MainSlide = ({template}) => {
  const [showAddSlideModal, setShowAddSlideModal] = useState(false)
  const [banners, setBanners] = useState([])
  const [formData, setFormData] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [color, setColor] = useState("#FFFFFF")





  useEffect(() => {
    getBanners()
  }, [template])

  useEffect(() => {

    getBanners()

    //updateItemPartial('/update-index-banner-v2').then((res)=>{})

  }, [])

  const getBanners = () => {
    setBanners([])
    getData(`/banner`, {location: 'home-slider',template: template})
      .then(res => {
        // res.items
        setBanners(res.items)
      })
  }

  const handleAddSlideModalClose = () => setShowAddSlideModal(false);
  const handleAddSlideModalShow = () => {
    setFormData({banner_type: 1, image_size_template: 'homepage_slider_web', location: 'home-slider',template:template})
    setShowAddSlideModal(true)
  };

  const handleDeleteBanner = (id) => {
    confirmAlert({
      title: 'Permanent Delete',
      message: 'Are you sure to delete this banner?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => permanentDeleteItem(`/banner/${id}/permanent/`)
            .then(res=> {
              getBanners()
              toast.success(res.message)
            })
            .catch(errMsg => {
              toast.error(errMsg)
            })
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });


  }

  const handleInputOnChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value})
  }

  const handleImageOnChange = (images, name) => {
    setFormData({...formData, [name]: images[0].actual_img})
  }

  const handleSaveSlider = () => {
    setIsSubmitting(true)
    addItem(`/banner`, formData)
      .then(res => {
        toast.success(res.message)
        handleAddSlideModalClose()
        getBanners()
        setIsSubmitting(false)
      })
      .catch(errMsg => {
        toast.error(errMsg)
        setIsSubmitting(false)
      })
  }


  const applyDrag = (arr, dragResult) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;

    const result = banners;
    let itemToAdd = payload;

    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }

    return result;
  };

  const generateItems = (count, creator) => {
    const result = [];
    for (let i = 0; i < count; i++) {
      result.push(creator(i));
    }
    return result;
  };



  function onDrop(dropResult) {
    const { removedIndex, addedIndex, payload, element } = dropResult;
    console.log(element)
    console.log(dropResult  )

    let data = banners;
    var b = data[removedIndex];
    data[removedIndex] = data[addedIndex];
    data[addedIndex] = b;
    setBanners([])
    setBanners(data);
    console.log(banners)

    const dataX = {
      added_id: banners[addedIndex]._id,
      added_index: addedIndex,

      remove_id: banners[removedIndex]._id,
      remove_index: removedIndex
    }

    updateItemPartial('/banner-v2', dataX).then((res)=>{
       if (res.status === 1){
         toast.success(res.message)
         getBanners()
       }
    })


  }

  const handleChange = (color) => {
    console.log(color.hex)
    setFormData({...formData, ['color']: color.hex})
    //this.setState({ color: color.rgb })
  };

  const  handleChangeComplete = (color) => {
    setColor(color.hex)
  };


  return (
    <div className="card-block">
      <div className="block-header">
        <h4 className="block-title">Main Slider</h4>
        <button onClick={handleAddSlideModalShow} className="btn btn-outline-secondary btn-sm btn-add-slide">
          <span className="action"><FiPlus/></span>
        </button>
      </div>

      <div className="block-body pb-2">
        <Modal show={showAddSlideModal} onHide={handleAddSlideModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Home Page Slide</Modal.Title>
          </Modal.Header>
          <Form onSubmit={(e) => {
            e.preventDefault()
            handleSaveSlider()
          }}>
            <Modal.Body>
              <Form.Group as={Row} controlId="sliderImg">
                <Form.Label column sm="4">
                  Slide Image <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <BrowseFiles
                    userId={getLocalAuthData()?._id}
                    isSigle={false}
                    image_category={'banner'}
                    onRemoveItem={() => {
                      const newFormData = {...formData}
                      delete newFormData.image
                      setFormData(newFormData)
                    }}
                    selected_image_size_template={'homepage_slider_web'}
                    images={formData?.image ? [{thumb_img: formData?.image}] : []}
                    helpText={'Image size 1035 x 353'}
                    onGetFiles={(images) => handleImageOnChange(images, 'image')}/>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="sliderImgMobile">
                <Form.Label column sm="4">
                  Slide Image (Mobile) <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <BrowseFiles
                    userId={getLocalAuthData()?._id}
                    isSigle={false}
                    image_category={'banner'}
                    onRemoveItem={() => {
                      const newFormData = {...formData}
                      delete newFormData.m_image
                      setFormData(newFormData)
                    }}
                    selected_image_size_template={'homepage_slider_mobile'}
                    images={formData?.m_image ? [{thumb_img: formData?.m_image}] : []}
                    helpText={'Image size 755 x 390'}
                    onGetFiles={(images) => handleImageOnChange(images, 'm_image')}/>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formPromotionName">
                <Form.Label column sm="4">
                  Slide Link <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={8}>
                  <Form.Control type="text" onChange={handleInputOnChange} required={'#'} name={'link'} placeholder=""/>
                </Col>
              </Form.Group>


              <Form.Group as={Row} controlId="formPromotionName">
                <Form.Label column sm="4">
                  Background Color <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={8}>
                  <SketchPicker color={color} onChangeComplete={handleChangeComplete } onChange={handleChange}/>
                </Col>
              </Form.Group>


            </Modal.Body>
            <Modal.Footer>
              <Button variant="default" onClick={handleAddSlideModalClose}>
                Close
              </Button>
              <Button variant="primary" type={'submit'}>
                {isSubmitting ? 'adding slide' : 'Add Slide'}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <div className="d-flex">
         {/* {banners.sort((a, b) => a.objKey > b.objKey ? 1:-1).map((item, index) => (
            <Col key={index} md={3}>
              <div className="main-slide">
                <img src={item?.image} alt=""/>
                <div className="slide-actions">
                  <div className="actions">
                    <span onClick={() => handleDeleteBanner(item?._id)} className="action"><FiTrash/></span>
                    <span className="action"><FiMove/></span>
                  </div>
                </div>
                <span>Slider {index+1}</span>
              </div>
            </Col>
          ))}*/}


          <div>
            <Container orientation="horizontal" onDrop={e => {onDrop(e)}} style={{display: 'flex', flexWrap: 'wrap', marginLeft: '-15px', marginRight: '-15px'}}>
                {banners.map((item , index) => {
                  return (
                    <Draggable key={index} className="col-md-3 mb-3">
                      <div key={index}>
                        <div className="main-slide">
                          <img src={item?.image} alt=""/>
                          <div className="slide-actions">
                            <div className="actions">
                              <span onClick={() => handleDeleteBanner(item?._id)} className="action"><FiTrash/></span>
                              <span className="action"><FiMove/></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Draggable>
                  );
                })}
            </Container>
          </div>
        </div>
      
      </div>
    </div>
  )
}

export default MainSlide;
