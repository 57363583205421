// noinspection JSUnresolvedVariable

import React, {Component} from 'react';
import App from "../../App";
import {Button, Modal, Form, Badge, Spinner, Row, Col} from "react-bootstrap";
import Pagination from 'react-js-pagination';
import {
    AiOutlineHourglass, AiOutlineTransaction, AiOutlineUser,
    BsListCheck,
    FaFileExport,
    FaSellsy, FaTruckPickup, FcApprove, FcSalesPerformance, FcShipped,
    FiFileText,
    FiMessageSquare, GiCash, GoListOrdered, GrDeliver, GrTransaction,
    ImCancelCircle
} from "react-icons/all";
import {Link, withRouter} from "react-router-dom";
import "../../assets/scss/order-list.scss";
import axios from "axios";
import {API_BASE_URL} from "../../helper/env";
import {getDeviceType, getLocalAuthData, objectToFormData} from "../../helper/utils";
import {toast} from "react-toastify";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import {getChildLocations} from "../../services/locationServices";
import {array} from "prop-types";
import {updateDepositPaymentStatus} from "../../services/orderServices";
// noinspection ES6CheckImport
import {
    useJsonToCsv
} from 'react-json-csv';
import {activityLogPostData, fileDownload, getTableData, postData, updateItemPartial} from '../../services/commonServices';
import DatePicker from "react-datepicker";
import BrowseButton from "./BrowseButton";
import InfoCard from "../../components/InfoCard";
import {LinearProgress} from "@material-ui/core";
import ReactJson from "react-json-view";
import {FaFilePdf} from "@react-icons/all-files/fa/FaFilePdf";

const {saveAsCsv} = useJsonToCsv();

// noinspection DuplicatedCode
class OrderParcelList extends Component {
    state = {
        report: {},
        orders_list: [],
        orders: [],
        merchantType: [],
        storeList: [],
        activityData: {},
        queryData: {
            from_date: '',
            to_date: '',
            limit: 50
        },
        activePage: 1,
        pagination: {},
        checkedIndex: [],
        selectedCheckboxIndex: [],
        actionData: " ",
        cancelActionModalShow: false,
        selectAllToggle: false,
        activeBlockTab: 'All',
        isLoading: true,
        locations: [],
        businessChildLocations: [],
        status: null,
        changingStoreId: null,
        changeStatusMsg: '',
        isShowingStatusChangeConfirmModal: false,
        showCancelMessageRequired: false,
        isSubmitting: false,
        selected_order_id: "",
        selected_deposit_info: {},
        depositInfoModal: false,
        isSubmittingDepositPaymentStatus: false,
        isUploadDepositModal: false,
        depositInfo: {
            deposit_date: new Date(),
            bank_name: 'Bank Asia',
            account_number: '06933001008'
        },


        total_orders: 0,
        total_sells: 0,
        total_pending_orders: 0,
        total_confirm_orders: 0,
        total_cancel_order: 0,
        total_picked_order: 0,
        total_shipped_order: 0,
        total_delivered_order: 0,
        total_cod: 0,
        total_online_transaction: 0,
        total_bank_transaction: 0,
        total_approved_bank_transaction: 0,
        muktopaath_user_order: 0,

        total_pending_sells: 0,

        total_confirmed_sells: 0,
        total_cancel_sells: 0,
        total_picked_sells: 0,
        total_shipped_sells: 0,
        total_delivered_sells: 0,
        total_cod_sells: 0,
        total_online_transaction_sells: 0,
        total_bank_transaction_sells: 0,
        total_approved_bank_transaction_sells: 0,
        total_muktopath_sells: 0,
        isLoadingQuery: false,
        take_action: false
    }


    handleBlockTab = (label) => {

        if (label !== "Report") {
            this.setState({
                activeBlockTab: label,
                queryData: {
                    ...this.state.queryData,
                    status: label !== "All" ? label : "",
                    page: 1,
                },
                activePage: 1
            }, () => {
                this.getQueryData(0);
            });
        } else {
            this.setState({
                activeBlockTab: label,

            }, () => {

            });
        }

    };

    getReport = () =>{

        getTableData('/order-parcel-report').then((res)=>{

            this.setState({
                report: res.data[0]
            },()=>{
                console.log(this.state.report)
            })

        })

    }

    getOrderReport=()=>{
        getTableData('/order-parcelV2',this.state.queryData).then((res)=>{
            console.log(res)
            this.setState({
                order_report: res.items[0]
            })
        })
    }
    getDistrictList =()=>{
        getTableData('/district').then((res)=>{
            console.log(res)
            this.setState({
                district_list: res.items
            })
        })
    }

    componentDidMount() {

        this.getReport();
        this.getParcels(this.state.queryData);
        this.getOrderReport()
        this.getDistrictList()

        const authToken = getLocalAuthData();
        const headers = {
            'Content-Type': 'application/json',
            'x-api-client': 'web',
            'x-auth-token': authToken.token,
        }

        axios.get(`${API_BASE_URL}/merchantType`, {headers: headers})
            .then(res => {
                this.setState({merchantType: res.data.merchant_type_obj});
            })
            .catch(err => {
                console.log(err)
            });


        axios.get(`${API_BASE_URL}/campaign`, {headers: headers})
            .then(res => {
                this.setState({campaign_list: res.data.items});
            })
            .catch(err => {
                console.log(err)
            });


        axios.get(`${API_BASE_URL}/location?type=Country`)
            .then(res => {
                this.setState({locations: res.data.locations});
            }).catch(() => console.log("Error"));
    }

    getParcels = async (orderListQuery = {}) => {

        const authToken = getLocalAuthData();
        const headers = {
            'Content-Type': 'application/json',
            'x-api-client': 'web',
            'x-auth-token': authToken.token,
        }
        await axios.get(`${API_BASE_URL}/order-parcel`, {headers: headers, params: orderListQuery} )
            .then(res => {
                this.setState({
                    orders: res.data.items,
                    pagination: res.data.pagination,
                    isLoading: false,
                    orders_list: res.data.items,
                    checkedIndex: new Array(res.data.items.length).fill(false)
                });
            }, () => {

            })
            .catch(() => {
                this.setState({isLoading: false})
            });


    }

    handlePageChange = async (pageNumber) => {

        await this.setState({
            selectAllToggle: false, /*!this.state.selectAllToggle,*/
            checkedIndex: new Array(this.state.checkedIndex.length).fill( false/*!this.state.selectAllToggle*/)
        })

        await this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber, take_action:false ,isLoadingQuery:true});
        await this.getQueryData(0);
    }

    changeStatus = (statusObj, order_id, callback = () => null) => {
        console.log(statusObj)
        const headers = {
            'Content-Type': 'application/json',
            'x-api-client': getDeviceType(),
            'x-auth-token': getLocalAuthData().token,
        }
        axios.patch(`${API_BASE_URL}/order/parcel/${order_id}/change-status`, statusObj,
            {headers: headers}
        )
            .then(response => {
                callback()
                toast.success(response.data.message);
                activityLogPostData('was change order status , order status : ', '/orders/parcel/' + order_id, statusObj.status).then()
            })
            .catch(() => {
                callback()
                toast.error('Something went wrong');
            });
    };

    getQueryData = (page) => {
        if (page === 1){
            this.state.queryData.page = 1
        }

        this.setState({
            isLoadingQuery: true
        })
        const headers = {
            'Content-Type': 'application/json',
            'x-api-client': 'web',
            'x-auth-token': getLocalAuthData().token,
        }
        let queryData = "";
        // eslint-disable-next-line array-callback-return
        Object.entries(this.state.queryData).map(([key, val]) => {
                queryData = queryData.concat(key + "=" + val + "&");
            }
        )
        axios.get(`${API_BASE_URL}/order-parcel?${queryData}`, {headers: headers})
            .then(res => {

                this.setState({
                    isLoadingQuery: false,
                    orders: res.data.items, pagination: res.data.pagination,
                    checkedIndex: new Array(res.data.items.length).fill(false)
                });

            }).catch(() => console.log("Error"));
    }


    handleSearchFormDataChange = (e) => {
        const merchant_type = e.target.name;
        const merchant_type_id = e.target.value;
        if (merchant_type === "merchant_type") {
            const headers = {
                'Content-Type': 'application/json',
                'x-api-client': getDeviceType(),
                'x-auth-token': getLocalAuthData().token,
            }
            axios.get(`${API_BASE_URL}/store?merchant_type=${merchant_type_id}&lite_data=1&status=1&approval_status=1`, {headers: headers})
                .then(res => {
                    this.setState({storeList: res.data.items});
                })
                .catch(err => {
                    console.log(err)
                });
        }
        this.setState({queryData: {...this.state.queryData, [e.target.name]: e.target.value}});
    };

    setQueryData = async (e) => {
        await this.setState({queryData: {...this.state.queryData, [e.target.name]: e.target.value}});
    }

    setActionData = async (e) => {
        await this.setState({actionData: e.target.value});
    }


    renderStoreList = () => {
        const storeList = this.state.storeList;
        if (storeList.length > 0) {
            return (
                storeList.map((items, index) =>
                    <option key={index} value={`${items._id}`}>{items.store_name}</option>
                )
            )
        }
    };

    hideChangeStatusModal = () => {
        this.setState({isShowingStatusChangeConfirmModal: false})
    }


    hideActionModal = () => {

        this.setState({cancelActionModalShow: false})
    }


    submitChangeStatusForm = (e) => {
        e.preventDefault()
        if (this.state.changeStatusMsg === '' && this.state.status === 'Cancelled') {
            return this.setState({showCancelMessageRequired: true});
        }
        this.setState({isSubmitting: true, showCancelMessageRequired: false}, () => {
            this.changeStatus({
                status: this.state.status,
                message: this.state.changeStatusMsg
            }, this.state.changingStoreId, () => {

                const authToken = getLocalAuthData();
                const headers = {
                    'Content-Type': 'application/json',
                    'x-api-client': 'web',
                    'x-auth-token': authToken.token,
                }

                axios.get(`${API_BASE_URL}/order-parcel`, {
                    headers: headers,
                    params: {
                        page: this.state.pagination.page,
                        status: this.state.activeBlockTab
                    }
                })
                    .then(res => {
                        this.setState({orders: res.data.items, pagination: res.data.pagination, isLoading: false});
                    })
                    .catch(() => {
                        this.setState({isLoading: false})
                    });

                this.setState({
                    isSubmitting: false,
                    message: '',
                    changingStoreId: null,
                    isShowingStatusChangeConfirmModal: false
                })
            })
        })
    }


    submitAction = () => {

        if (this.state.actionData == "Cancelled") {
            let array = []
            this.state.checkedIndex.map((item, index) => {
                if (item)
                    array.push(index)
            })
            this.setState({selectedCheckboxIndex: array, cancelActionModalShow: true})

        } else {
            toast.warning("please select an action first");
        }


    }

    submitActionModal = (e) => {
        e.preventDefault()


        if (this.state.changeStatusMsg === '') {
            return this.setState({showCancelMessageRequired: true});
        }

        let count = 0;
        this.state.checkedIndex.map((item, index) => {
            if (item) {
                count++
                let order_id = this.state.orders[index]._id
                console.log(order_id)

                this.setState({isSubmitting: true, showCancelMessageRequired: false}, () => {
                    this.changeStatus({
                        status: "Cancelled",
                        message: this.state.changeStatusMsg
                    }, this.state.orders[index].order_parcels._id, () => {

                        if (count === this.state.selectedCheckboxIndex.length) {

                            const authToken = getLocalAuthData();
                            const headers = {
                                'Content-Type': 'application/json',
                                'x-api-client': 'web',
                                'x-auth-token': authToken.token,
                            }

                            axios.get(`${API_BASE_URL}/order-parcel`, {
                                headers: headers,
                                params: {
                                    page: this.state.pagination.page,
                                    status: this.state.activeBlockTab
                                }
                            })
                                .then(res => {
                                    this.setState({orders: res.data.items, pagination: res.data.pagination, isLoading: false});
                                })
                                .catch(() => {
                                    this.setState({isLoading: false})
                                });

                            this.setState({
                                isSubmitting: false,
                                message: '',
                                changingStoreId: null,
                                isShowingStatusChangeConfirmModal: false,
                                cancelActionModalShow: false,
                                checkedIndex: new Array(this.state.orders.length).fill(false)
                            })

                        }


                    })
                })
            }


        })


    }

    handleLocationInputOnChange = (e, index, typePurpose) => {
        const childLocations = [...this.state[typePurpose]];
        if (typePurpose === 'businessChildLocations') {
            this.handleSearchFormDataChange(e)
        } else {
            this.handleSearchFormDataChange(e)
        }
        if (e.target.value !== '') {
            getChildLocations(e.target.value)
                .then(res => {
                    childLocations.splice(index, childLocations.length - index);
                    if (res.length > 0) {
                        childLocations[index] = res
                    }
                    this.setState({[typePurpose]: childLocations})
                })
                .catch(errMsg => {
                    console.log(errMsg)
                    childLocations.splice(index, childLocations.length - index)
                    this.setState({[typePurpose]: childLocations})
                })
        } else {
            childLocations.splice(index, childLocations.length - index);
            this.setState({[typePurpose]: childLocations})
        }
    }

    renderLocations = () => {
        const locations = this.state.locations;
        if (locations) {
            return (
                <>
                    {locations.map((items) =>
                        <option value={`${items._id}`}>{items.name}</option>
                    )}
                </>
            )
        }
    };

    handleOnApply = (e, picker) => {
        const queryData = {...this.state.queryData};

        queryData.from_date = moment(picker.startDate).format('DD/MM/YYYY');
        queryData.to_date = moment(picker.endDate).format('DD/MM/YYYY');

        this.setState({queryData}, () => {
            console.log(this.state)
        })
    }

    renderPaymentStatus = (status) => {
        let payment_status = {
            0: "Unpaid",
            1: "Partially Paid",
            2: "Paid",
            3: "Cancelled",
            4: "Failed",
            5: "Refund",
            6: "Bank deposit payment in review",
            7: "Bank deposit declined",
        }
        return payment_status[status];
    }

    ShowStatusOption = (Status) => {
        let options;
        if (Status === "Delivered") {
            options = [{
                value: 'Delivered',
                label: 'Delivered'
            }
            ]
        } else if (Status === "Cancelled") {
            options = [{
                value: 'Cancelled',
                label: 'Cancelled'
            }
            ]
        } else {
            options = [{
                value: 'Pending',
                label: 'Pending',
            }, {
                value: 'Confirmed',
                label: 'Confirmed'
            }, {
                value: 'Processing',
                label: 'Processing'
            }, {
                value: 'Picked',
                label: 'Picked'
            }, {
                value: 'Shipped',
                label: 'Shipped'
            }, {
                value: 'Delivered',
                label: 'Delivered'
            }, {
                value: 'Cancelled',
                label: 'Cancelled'
            }
            ]
        }
        return options;
    }

    showDepositInfo = (order_id, deposit_info, payment_status , a, order_code , order_idV2) => {

        const activityData = {...this.state.activityData}
        activityData.order_code = order_code;
        activityData.order_id = order_idV2

        this.setState({activityData})

        this.setState({
            selected_order_id: order_id,
            selected_deposit_info: deposit_info,
            depositInfoModal: true,
            current_payment_status: payment_status
        }, () => {
            console.log(this.state.selected_order_id);
            console.log(this.state.selected_deposit_info);
        });
    }

    updateDepositPaymentStatus = (order_id, payment_status , approvedOrDecline) => {
        if (order_id && payment_status) {
            updateDepositPaymentStatus({order_id: order_id, payment_status: payment_status}).then(async (res) => {
                if (res.status === 1) {
                    toast.success(res.message);

                    /* RECALL LIST */
                    await this.getQueryData(0);
                    this.setState({depositInfoModal: false});

                    if (approvedOrDecline === "2"){ //approved
                        activityLogPostData('was Approved bank deposit ', '/orders/parcel/'+this.state.activityData.order_id ,this.state.activityData.order_code )
                    }else {
                        activityLogPostData('was Declined bank deposit ', '/orders/parcel/'+this.state.activityData.order_id ,this.state.activityData.order_code )

                    }
                }

                 else {
                    toast.error(res.message);
                }
            }).catch(function (e) {
                console.log(e.message);
            });
        } else {
            console.log("order Id or payment status missing");
        }
    }

    handleCloseDepositInfoModal = () => {
        this.setState({depositInfoModal: false});
    }

    renderSelectedShippingEstimatedTime = (shippingOptions = [], shipping_option) => {
        if (shippingOptions.length > 0) {
            let selected_shipping_option = shippingOptions.filter(obj => obj.shipping_option === shipping_option)
            if (selected_shipping_option?.length > 0) {
                return selected_shipping_option[0].estimated_time;
            }
        } else {
            return "";
        }
    }


    handleExportFile = async (fileType) => {
        this.setState({export_loading: true})

        let queryData = {...this.state.queryData}
        queryData.export = 'xlsx'
        this.setState({queryData}, ()=>{
            console.log('this.state.queryData',this.state.queryData);

            fileDownload(`/order-parcel`, this.state.queryData).then((response)=>{
                console.log(response)
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'ekshop-order-list.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
                this.setState({
                    export_loading: false
                })
            })
            queryData.export = null
            this.setState({
                queryData,
            })

        })



        // let queryData = "";
        // let mData = [];
        //
        // Object.entries(this.state.queryData).forEach(([key, val]) => {
        //         queryData = queryData.concat(key + "=" + val + "&");
        //     }
        // )
        // await this.setState({isExporting: true});
        // let payment_status = ['Unpaid', 'Partially paid', 'Paid', 'Cancelled', 'Failed', 'Refund', 'Bank Deposit Payment In Review', 'Bank Deposit Declined']
        // let total = this.state?.pagination?.limit
        // let page = this.state?.pagination?.page
        // let a = parseInt(total) * parseInt(page)
        // let b = a - parseInt(total)
        // let i = b + 1
        //
        //
        // this.state.orders && this.state.orders.map((data) => {
        //
        //     let date = moment(data?.createdAt);
        //     let customer_phone = data?.customer_phone //? (data?.customer_phone?.split('-').join('-').split('+')[1]).toString() : "--"
        //
        //
        //     let productName = ''
        //     let productCategory = ''
        //     let quantity = ''
        //
        //     data.order_parcels.order_parcel_items.map((data1, index1) => {
        //
        //         productName += "\n\n" + data1?.product_info?.name
        //         productCategory += "\n\n" + data1?.product_info?.category_info?.name
        //         quantity += "\n\n" + data1?.quantity
        //
        //         console.log('Loop & Length', data.order_parcels.order_parcel_items.length + "  " + index1)
        //         if (data.order_parcels.order_parcel_items.length - 1 === index1) {
        //             mData.push({
        //                 'Index': i++,
        //                 'Order Id': data?.order_parcels?.order_parcel_code,/*,*/
        //                 'Order Date': moment(data?.createdAt).format('hh:mm a DD/MM/YYYY'),
        //                 'Product Category': '"' + productCategory + '"',
        //                 'Product Name': '"' + productName + '"',
        //                 'Quantity': '"' + quantity + '"',
        //                 'Customer Name': data?.customer_name,
        //                 'Customer Number': customer_phone,
        //                 'Customer Email': data?.customer_email,
        //                 'Payment Method': data?.payment_method?.name,
        //                 'Payment Status': payment_status[data?.order_parcels?.payment_status],
        //                 'Shipping Method': data.order_parcels?.selected_shipping_option ? data.order_parcels?.selected_shipping_option : 'ekShop Fulfillment',
        //                 'Shipping Charge': data?.order_parcels?.parcel_shipping_cost,
        //                 'Store Info': data?.order_parcels?.store_info?.store_name,
        //                 'Status': data?.order_parcels?.status,
        //                 'Price': data?.order_parcels?.parcel_grand_total,
        //             });
        //         }
        //     })
        //
        // });
        // // noinspection JSUnusedLocalSymbols
        // const filename = 'EkShop-Parcel Order',
        //     fields = {
        //         'Index': "Index",
        //         'Order Id': "Order Info",
        //         'Order Date': "Order Date",
        //         'Product Category': "Product Category",
        //         'Product Name': "Product Name",
        //         'Quantity': "Quantity",
        //         'Customer Name': "Customer Name",
        //         'Customer Number': "Customer Number",
        //         'Customer Email': "Customer Email",
        //         'Payment Method': "Payment Method",
        //         'Payment Status': "Payment Status",
        //         'Shipping Method': "Shipping Method",
        //         'Store Info': "Store Info",
        //         'Status': "Status",
        //         'Price': "Price",
        //     },
        //     style = {
        //         padding: "30px",
        //         maxHeight: "20px"
        //     },
        //     data = mData,
        //     text = "Convert Json to Csv";
        // saveAsCsv({data, fields, filename});
        //
         activityLogPostData('was export order parcel list', '/orders/parcel/list').then()

    }


    handleExportReportFile = async (fileType) => {
        let mData = [];


        // for quantity
        mData.push({
            '': "Quantity",
            'Total Orders': this.state?.report?.total_order,
            'Total Sells': '',
            'Total Pending Order': this.state?.report?.pending_order,
            'Total Confirmed Order': this.state?.report?.confirmed_order,
            'Total Processing Order': this.state?.report?.processing_order,
            'Total Cancel': this.state?.report?.cancelled_order,
            'Total Picked': this.state?.report?.picked_order,
            'Total Shipped': this.state?.report?.shipped_order,
            'Total Deliver Order': this.state?.report?.delivered_order,
            'Total COD': this.state?.report?.total_cod,
            'Total Online Transaction': this.state?.report?.total_online_transaction,
            'Total bank Transaction': this.state?.report?.total_bank_transaction,
            'Total Approved Bank Transaction': this.state?.report?.approved_bank_transaction,
            'MuktoPaath User Orders': this.state?.report?.total_muktopaath_order,

        });

        //for amount
        mData.push({
            '': "Amount",
            'Total Orders': '',
            'Total Sells': this.state?.report?.total_sells,
            'Total Pending Order': this.state?.report?.pending_sells,
            'Total Confirmed Order': this.state?.report?.confirmed_sells,
            'Total Processing Order': this.state?.report?.processing_sells,
            'Total Cancel': this.state?.report?.cancelled_sells,
            'Total Picked': this.state?.report?.picked_sells,
            'Total Shipped': this.state?.report?.shipped_sells,
            'Total Deliver Order': this.state?.report?.delivered_sells,
            'Total COD': this.state?.report?.total_cod_sells,
            'Total Online Transaction': this.state?.report?.total_online_transaction_sells,
            'Total bank Transaction': this.state?.report?.total_bank_transaction,
            'Total Approved Bank Transaction': this.state?.report?.approved_bank_transaction_sells,
            'MuktoPaath User Orders': this.state?.report?.total_muktopaath_order_sells,

        });

        const filename = 'EkShop-Order Report',
            fields = {
                '': "",
                'Total Orders': "Total Orders",
                'Total Sells': "Total Sells",
                'Total Pending Order': "Total Pending Order",
                'Total Confirmed Order': "Total Confirmed Order",
                'Total Cancel': "Total Cancel",
                'Total Picked': "Total Picked",
                'Total Shipped': "Total Shipped",
                'Total Deliver Order': "Total Deliver Order",
                'Total COD': "Total COD",
                'Total Online Transaction': "Total Online Transaction",
                'Total bank Transaction': "Total bank Transaction",
                'Total Approved Bank Transaction': "Total Approved Bank Transaction",
                'MuktoPaath User Orders': "MuktoPaath User Orders",
            },
            style = {
                padding: "30px",
                maxHeight: "20px"
            },
            data = mData,
            text = "Convert Json to Csv";
        saveAsCsv({data, fields, filename});

        activityLogPostData('was export order report', '/orders/parcel/list').then()

    }



    handleContactBtn = (e, id, key) => {
        e.preventDefault();
        let data = {};

        if (key === "user_id") {
            data.user_id = id
        } else {
            data.store_id = id
        }

        postData(`/paired`, data)
            .then((res) => {
                localStorage.setItem('pairId', res.paired._id);
                this.props.history.push('/messages');
            })
            .catch((err) => {
                console.log(err);
            });
    }

    /* Update Payment */
    getPaymentStatus = (order_id, order_parcel_id) => {
        updateItemPartial(`/payment/get-ssl-payment-status`, {order_id: order_id, order_parcel_id:order_parcel_id}).then(res => {
            if (res.status === 1) {
                this.getParcels();
                toast.success(res.message)
            } else {
                toast.warning(res.message)
            }
        }).catch(errMsg => {
            toast.error(errMsg);
        })
    }

    /* DEPOSIT MODAL START */
    handleDepositModalShow = (orderId, amount , order_code ,order_id) => {
        const depositInfo = {...this.state.depositInfo}
        const activityData = {...this.state.activityData}
        console.log(depositInfo)
        depositInfo.order_id = orderId
        activityData.order_number = order_id
        activityData.order_code = order_code
        depositInfo.amount = amount.toString()
        this.setState({depositInfo , activityData, isUploadDepositModal: true},()=>{
            console.log(this.state.activityData)
        })
    }

    handleDepositModalClose = () => {
        this.setState({isUploadDepositModal: false})
    }

    uploadDepositInfo = (data) => {
        return new Promise((resolve, reject) =>
            axios.post(`${API_BASE_URL}/payment/save-bank-deposit`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-client': 'web',
                    'x-auth-token': getLocalAuthData().token,
                }
            })
                .then(res => res.data.status === 1 ? resolve(res.data) : reject(res.data.message))
                .catch(err => reject(err.message)))
    }

    handleSubmitDepositInfo = (e) => {
        e.preventDefault()
        console.log("=============",this.state.depositInfo)
        if (!this.state.depositInfo.order_id) alert('Order id is Required')
        this.uploadDepositInfo(this.state.depositInfo).then(res => {
            toast.success(res.message)
            this.getParcels();
            activityLogPostData("was uploaded deposit slip","/orders/parcel/"+this.state.activityData.order_number , this.state.activityData.order_code)
            this.setState({isUploadDepositModal: false})
        })
            .catch(err => {
                toast.error(err.message)
            })
    }

    uploadImage = (data) => {
        return new Promise((resolve, reject) =>
            axios.post(`${API_BASE_URL}/images`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-client': 'web',
                    'x-auth-token': getLocalAuthData().token,
                }
            })
                .then(res => resolve(res.data))
                .catch(err => reject(err.message)))
    }

    depositInputOnChange = async (e) => {
        const depositInfo = {...this.state.depositInfo}
        if (e.target.type !== 'file') {
            depositInfo[e.target.name] = e.target.value
            this.setState({depositInfo})
        } else {
            this.setState({isImageUploading: true})
            this.uploadImage(objectToFormData({image: e.target.files[0], image_category: 'payment'}))
                .then(res => {
                    depositInfo.deposit_slip = res?.image?.actual_img_path
                    this.setState({depositInfo, isImageUploading: false})
                })
                .catch(() => {
                    this.setState({isImageUploading: false})
                })
        }
    }

    renderMerchantType = () => {
        const merchantType = this.state.merchantType;
        if (merchantType) {
            return (
                <>
                    {merchantType.map((items, index) =>
                        <option key={index} value={`${items._id}`}>{items.type_name}</option>
                    )}
                </>
            )
        }
    };

    renderCampaignId = () => {
        const campaign_list = this.state.campaign_list;
        if (campaign_list) {
            return (
                <>
                    {campaign_list.map((items, index) =>
                        <>{<option key={index} value={`${items._id}`}>{items.campaign_title}</option>}</>
                    )}
                </>
            )
        }
    };

    renderDistrictId = () => {
        const district_list = this.state.district_list;
        if (district_list) {
            return (
                <>
                    {district_list.map((items, index) =>
                        <>{<option key={index} value={`${items.name}`}>{items.name}</option>}</>
                    )}
                </>
            )
        }
    };


    handleSelectAllToggle(e) {

        this.setState({
            selectAllToggle: e.target.checked, /*!this.state.selectAllToggle,*/
            take_action: e.target.checked,
            checkedIndex: new Array(this.state.checkedIndex.length).fill( e.target.checked/*!this.state.selectAllToggle*/)
        })
    }

    handleOnCheckboxChange = (position) => {
        const updatedState = this.state.checkedIndex.map((item, index) =>
            index === position ? !item : item
        );
        this.setState({checkedIndex: updatedState},()=>{

            let length = this.state.checkedIndex.filter(function(item){
                return item === true;
            }).length;

            if (length > 0){
                this.setState({
                    take_action: true
                })
            }else if (length === 0){
                this.setState({
                    take_action: false
                })
            }
        })


    };

    onLimitChange = (e) =>{

        const newQuery = {...this.state.queryData, limit: +e.target.value}
        this.setState({queryData: newQuery, isLoading: true}, ()=>{
            this.getParcels(this.state.queryData);
        });
    }

    clearFilter =(value)=>{

        switch (value){
            case 1:
                this.setState({
                    queryData: {}
                })
                break;
            case 2:
                this.setState({
                    selectAllToggle: false,
                    actionData: "",
                    take_action:"",
                    checkedIndex: new Array(this.state.checkedIndex.length).fill(false)
                })
                break;

        }

    }

    CampaignList =(item)=>{
        if (item?.order_parcels?.campaigns && item?.order_parcels?.campaigns.length > 0){
            return(item?.order_parcels?.campaigns.map((data, i)=>{
                return(<><a target={`_blank`} href={`/promotions/campaign/details/${data?.id}`}><Badge variant={`info`}>{data?.name}</Badge></a></>)
            }))
        }else {
            return null
        }

    }


    /* DEPOSIT MODAL END */

    render() {
        // noinspection EqualityComparisonWithCoercionJS
        return (
            <App>
                <div className="main-content">


                    <Row>
                        <Col xl={6} lg={12} md={12} className={"col-60"}>
                            <Row>
                                <Col className={"col-md-6"}>
                                    <InfoCard title="Total Sales" className="yellow" icon={<FcSalesPerformance/>}
                                              value={`৳`+this.state?.order_report?.total_sells}/>
                                </Col>

                                <Col className={"col-md-6"}>
                                    <InfoCard title="Total Order" className="blue" icon={<GoListOrdered/>}
                                              value={this.state?.order_report?.total_order}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>


                    <div className="page-header">
                        <h2 className="page-title">Order List</h2>
                        <div className="ml-auto">

                            {/* <Button size={"sm"} variant={"defalut"} className="mb-0 ml-2"
                      onClick={() => this.handleExportFile2('xlsx')}><FaFileExport/>Export Product Report</Button>*/}
                           {/* {getLocalAuthData()?.permission?.includes('order_parcel_list_export') ?
                                <Button size={"sm"} variant={"defalut"} className="mb-0 ml-2"
                                        onClick={() => this.handleExportFile('xlsx')}><FaFileExport/> </Button> : null}*/}

                            {getLocalAuthData()?.permission?.includes('order_parcel_list_export') &&
                            <Button variant="primary"

                                    size={`sm`}
                                    onClick={() => this.handleExportFile('xlsx')}
                                    disabled={this.state.export_loading}>
                                {this.state.export_loading ?
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span>Loading...</span>
                                    </>
                                    : <><FaFilePdf/><span>Export</span></>}
                            </Button>
                            }

                        </div>
                    </div>
                    {this.state.isLoading ?
                        <div className={`card-block`}>
                            <div className="block-body">
                                Loading...
                            </div>
                        </div>
                        :
                        <>
                            {getLocalAuthData()?.permission?.includes('order_parcel_list_view') ?
                                <div className="card-block">
                                    <ul className="desc-header-nav">
                                        <li className={this.state.activeBlockTab === 'All' ? 'active' : ''} value="">
                                            <span onClick={() => this.handleBlockTab('All')}>All</span>
                                        </li>
                                        <li className={this.state.activeBlockTab === 'Pending' ? 'active' : ''}>
                                            <span onClick={() => this.handleBlockTab('Pending')}>Pending</span>
                                        </li>
                                        <li className={this.state.activeBlockTab === 'Confirmed' ? 'active' : ''}>
                                            <span onClick={() => this.handleBlockTab('Confirmed')}>Confirm</span>
                                        </li>
                                        <li className={this.state.activeBlockTab === 'Processing' ? 'active' : ''}>
                                            <span onClick={() => this.handleBlockTab('Processing')}>Processing</span>
                                        </li>
                                        <li className={this.state.activeBlockTab === 'Picked' ? 'active' : ''}>
                                            <span onClick={() => this.handleBlockTab('Picked')}>Picked</span>
                                        </li>
                                        <li className={this.state.activeBlockTab === 'Shipped' ? 'active' : ''}>
                                            <span onClick={() => this.handleBlockTab('Shipped')}>Shipped</span>
                                        </li>
                                        <li className={this.state.activeBlockTab === 'Delivered' ? 'active' : ''}>
                                            <span onClick={() => this.handleBlockTab('Delivered')}>Delivered</span>
                                        </li>
                                        <li className={this.state.activeBlockTab === 'Cancelled' ? 'active' : ''}>
                                            <span onClick={() => this.handleBlockTab('Cancelled')}>Cancelled</span>
                                        </li>

                                        {/*<li className={this.state.activeBlockTab === 'Others' ? 'active' : ''}>

                                            {getLocalAuthData()?.permission?.includes('order_parcel_list_item_view') ?

                                                <span>
                                           <Link to={`${process.env.PUBLIC_URL}/others-orders/parcel/list`}
                                                 target={'_blank'}>Others</Link></span>


                                                : null}

                                            <span onClick={() => this.handleBlockTab('Others')}>Others</span>
                                        </li>*/}

                                        <li className={this.state.activeBlockTab === 'Report' ? 'active' : ''}>
                                            <span
                                                onClick={() => this.handleBlockTab('Report')}>Report</span>
                                        </li>
                                    </ul>
                                    {this.state.activeBlockTab === 'Report' ?

                                        <div className="block-body">

                                            <Button size={"sm"} variant={"defalut"} className="mb-0 ml-2"
                                                    onClick={() => this.handleExportReportFile('xlsx')}><FaFileExport/> Export Report</Button>

                                            <Row>

                                                <Col md={3}>
                                                    <InfoCard
                                                        title="Total Orders" className="blue" icon={<FiFileText/>}
                                                        value={this.state?.report?.total_order}/>
                                                </Col>

                                                <Col md={3}>
                                                    <InfoCard
                                                        title="Total Sells" className="blue" icon={<FaSellsy/>}
                                                        isCurrency={true}
                                                        value={this.state?.report?.total_sells}/>
                                                </Col>

                                                <Col md={3}>
                                                    <InfoCard
                                                        total={"Pending sells : " + this.state?.report?.pending_sells}
                                                        title="Total Pending Orders" className="orange"
                                                        icon={<AiOutlineHourglass/>}
                                                        value={this.state?.report?.pending_order}/>
                                                </Col>

                                                <Col md={3}>
                                                    <InfoCard
                                                        total={"Confirmed sells : " + this.state?.report?.confirmed_sells}
                                                        title="Total Confirm Orders" className="green"
                                                        icon={<BsListCheck/>}
                                                        value={this.state.report?.confirmed_order}/>
                                                </Col>

                                                <Col md={3}>
                                                    <InfoCard
                                                        total={"Cancelled sells : " + this.state?.report?.cancelled_sells}
                                                        title="Total Cancel Orders" className="red"
                                                        icon={<ImCancelCircle/>}
                                                        value={this.state?.report?.cancelled_order}/>
                                                </Col>

                                                <Col md={3}>
                                                    <InfoCard
                                                        total={"Picked sells : " + this.state?.report?.picked_sells}
                                                        title="Total Picked Orders" className="blue"
                                                        icon={<FaTruckPickup/>}
                                                        value={this.state?.report?.picked_order}/>
                                                </Col>

                                                <Col md={3}>
                                                    <InfoCard
                                                        total={"Shipped sells : " + this.state?.report?.shipped_sells}
                                                        title="Total Shipped Orders" className="green"
                                                        icon={<FcShipped/>}
                                                        value={this.state.report.shipped_order}/>
                                                </Col>

                                                <Col md={3}>
                                                    <InfoCard
                                                        total={"Processing order sells : " + this.state?.report?.processing_sells}
                                                        title="Processing Order" className="green"
                                                        icon={<AiOutlineTransaction/>}
                                                        value={this.state?.report?.processing_order}/>
                                                </Col>


                                                <Col md={3}>
                                                    <InfoCard
                                                        total={"Delivered sells : " + this.state?.report?.delivered_sells}
                                                        title="Total Delivered Orders" className="orange"
                                                        icon={<GrDeliver/>}
                                                        value={this.state?.report?.delivered_order}/>
                                                </Col>


                                                <Col md={3}>
                                                    <InfoCard
                                                        total={"COD sells : " + this.state?.report?.total_cod_sells}
                                                        title="Total COD" className="blue" icon={<GiCash/>}
                                                        value={this.state?.report?.total_cod}/>
                                                </Col>

                                                <Col md={3}>
                                                    <InfoCard
                                                        total={"Online Transaction sells : " + this.state?.report?.total_online_transaction_sells}
                                                        title="Total Online Transaction" className="green"
                                                        icon={<AiOutlineTransaction/>}
                                                        value={this.state?.report?.total_online_transaction}/>
                                                </Col>


                                                <Col md={3}>
                                                    <InfoCard
                                                        total={"Total Bank Transaction sells : " + this.state?.report?.total_bank_transaction_sells}
                                                        title="Total Bank Transaction" className="blue"
                                                        icon={<GrTransaction/>}
                                                        value={this.state?.report?.total_bank_transaction}/>
                                                </Col>

                                                <Col md={3}>
                                                    <InfoCard
                                                        total={"Approved Bank Transaction sells : " + this.state?.report?.approved_bank_transaction_sells}
                                                        title="Approved Bank Transaction" className="orange"
                                                        icon={<FcApprove/>}
                                                        value={this.state?.report?.approved_bank_transaction}/>
                                                </Col>


                                                <Col md={3}>
                                                    <InfoCard
                                                        total={"MuktoPaath sells : " + this.state?.report?.total_muktopaath_order_sells}
                                                        title="Muktopaath User Order" className="blue"
                                                        icon={<AiOutlineUser/>}
                                                        value={this.state?.report?.total_muktopaath_order}/>
                                                </Col>

                                            </Row>

                                        </div>

                                        :
                                        <div className="block-body">
                                            <form className="form-inline mb-3">

                                                {!this.state.take_action && <>

                                                    <div className="form-group mr-3 mt-2">
                                                        <label htmlFor="status" className="sr-only">Locations</label>
                                                        <select id="status" name="country"
                                                                value={this.state.queryData?.country ? this.state.queryData?.country : ""}

                                                                className='form-control form-control-sm'
                                                                onChange={(e) => this.handleLocationInputOnChange(e, 0, 'businessChildLocations')}>
                                                            <option value="">--Select Country--</option>
                                                            {this.renderLocations()}
                                                        </select>
                                                    </div>

                                                    {this.state.businessChildLocations.length > 0 &&
                                                    this.state.businessChildLocations.map((location, index) => (
                                                        <div className="form-group mr-3 mt-2" key={index}>
                                                            <label htmlFor={`location${location[0].type}`}
                                                                   className="sr-only">{location[0].type}</label>
                                                            <select id={`location${location[0].type}`}
                                                                    name={location[0].type.toLowerCase()}
                                                                    value={this.state.queryData?.[location[0].type.toLowerCase()] ? this.state.queryData?.[location[0].type.toLowerCase()] : ""}
                                                                    className='form-control form-control-sm'
                                                                    onChange={(e) => this.handleLocationInputOnChange(e, index + 1, 'businessChildLocations')}>
                                                                <option value="">--Select--</option>
                                                                {location.map((item, index) => (
                                                                    <option key={index}
                                                                            value={item._id}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    ))
                                                    }
                                                    <div className="form-group mr-3 mt-2">
                                                        <label htmlFor="barndName" className="sr-only">Merchant Type</label>
                                                        <select id="merchantType" name="merchant_type"
                                                                onChange={this.handleSearchFormDataChange}
                                                                value={this.state.queryData?.merchant_type ? this.state.queryData?.merchant_type : ""}
                                                                className='form-control form-control-sm'>
                                                            <option value="">--Select Type--</option>
                                                            {this.renderMerchantType()}
                                                        </select>
                                                    </div>

                                                    {this.state.storeList?.length > 0 &&
                                                    <div className="form-group mr-3 mt-2">
                                                        <label htmlFor="storeId" className="sr-only">Store</label>
                                                        <select
                                                            id="storeId" name="store_id"
                                                            className='form-control form-control-sm'
                                                            value={this.state.queryData?.store_id ? this.state.queryData?.store_id : ""}
                                                            onChange={this.handleSearchFormDataChange}>
                                                            <option value="">--Select Store--</option>
                                                            {this.renderStoreList()}
                                                        </select>
                                                    </div>}


                                                    <div className="form-group mr-3 mt-2">
                                                        <label htmlFor="paymentStatus" className="sr-only">Payment
                                                            Status</label>
                                                        <select id="paymentStatus" name="order_payment_status"
                                                                onChange={this.setQueryData}
                                                                value={this.state.queryData?.order_payment_status ? this.state.queryData?.order_payment_status : ""}
                                                                className='form-control form-control-sm'>
                                                            <option value="">--Select Payment Status--</option>
                                                            <option value="0">Unpaid</option>
                                                            <option value="1">Partially Paid</option>
                                                            <option value="2">Paid</option>
                                                            <option value="3">Online Payment Canceled</option>
                                                            <option value="4">Online Payment Failed</option>
                                                            <option value="5">Refund</option>
                                                        </select>
                                                    </div>

                                                    <div className="form-group mr-3 mt-2">
                                                        <label htmlFor="paymentMethod" className="sr-only">Payment
                                                            Method</label>
                                                        <select id="paymentMethod" name="payment_method"
                                                                onChange={this.setQueryData}
                                                                value={this.state.queryData?.payment_method ? this.state.queryData?.payment_method : ""}
                                                                className='form-control form-control-sm'>
                                                            <option value="">--Select Payment Method--</option>
                                                            <option value="COD">COD</option>
                                                            <option value="online_payment">Online</option>
                                                            <option value="bank_deposit">Bank Deposit</option>
                                                        </select>
                                                    </div>



                                                    <div className="form-group mr-3 mt-2">
                                                        <label htmlFor="barandName" className="sr-only">Sort by
                                                            Store</label>
                                                        <select id="wallet" name="wallet"
                                                                onChange={this.setQueryData}
                                                                value={this.state.queryData?.wallet ? this.state.queryData?.wallet : ""}
                                                                className='form-control form-control-sm'>
                                                            <option value="">--Filter Wallet user--</option>
                                                            <option value="true">Wallet User</option>

                                                        </select>
                                                    </div>


                                                    <div className="form-group mr-3 mt-2">
                                                        <label htmlFor="district_brand" className="sr-only"/>
                                                        <select id="district_brand" name="district_brand"
                                                                onChange={this.setQueryData}
                                                            //value={this.state.queryData?.campaign_id ? this.state.queryData?.campaign_id : ""}
                                                                className='form-control form-control-sm'>
                                                            <option value="">--Filter District Order--</option>
                                                            {this.renderDistrictId()}
                                                        </select>
                                                    </div>



                                                    <div className="form-group mr-3 mt-2">
                                                        {/* <label htmlFor="campaignOrder" className="sr-only">Product </label>*/}
                                                        <select id="campaignOrder" name="muktopath_user"
                                                                onChange={this.setQueryData}
                                                                value={this.state.queryData?.muktopath_user ? this.state.queryData?.muktopath_user : ""}
                                                                className='form-control form-control-sm'>
                                                            <option value="">--Filter User Type--</option>
                                                            <option value="">All Orders</option>
                                                            <option value="true">MuktoPaath User Orders</option>
                                                            <option value="false">General User Orders</option>
                                                        </select>
                                                    </div>

                                                    <div className="form-group mr-3 mt-2">
                                                        <label htmlFor="campaignOrder" className="sr-only"></label>
                                                        <select id="campaignOrder" name="campaign_order"
                                                                onChange={this.setQueryData}
                                                                value={this.state.queryData?.campaign_order ? this.state.queryData?.campaign_order : ""}
                                                                className='form-control form-control-sm'>
                                                            <option value="">--Filter Campaign Order--</option>
                                                            <option value="all">All Orders</option>
                                                            <option value="true">Campaign Orders</option>
                                                            <option value="false">Regular Orders</option>
                                                        </select>
                                                    </div>

                                                    <div className="form-group mr-3 mt-2">
                                                        <label htmlFor="barandName" className="sr-only"></label>
                                                        <select id="store_id" name="campaign_id"
                                                                onChange={this.setQueryData}
                                                                value={this.state.queryData?.campaign_id ? this.state.queryData?.campaign_id : ""}
                                                                className='form-control form-control-sm'>
                                                            <option value="">--Select Campaign--</option>
                                                            {this.renderCampaignId()}
                                                        </select>
                                                    </div>


                                                    <div className="form-group mr-3 mt-2">
                                                        <label htmlFor="orderParcelCode" className="sr-only">Order
                                                            Number</label>
                                                        <input type="search" id="orderParcelCode"
                                                               className='form-control form-control-sm'
                                                               placeholder="Order Number" name="order_parcel_code"
                                                               onChange={this.handleSearchFormDataChange}
                                                               value={this.state.queryData?.order_parcel_code ? this.state.queryData?.order_parcel_code : ""}
                                                               onKeyDown={(e) => {
                                                                   if (e.keyCode === 13) {
                                                                       this.getQueryData(0)
                                                                   }
                                                               }}/>
                                                    </div>

                                                    <div className="form-group mr-3 mt-2">
                                                        <label htmlFor="customerSearch" className="sr-only">Customer</label>
                                                        <input type="search" id="customerSearch" name="customer_search"
                                                               onChange={this.handleSearchFormDataChange}
                                                               className='form-control form-control-sm'
                                                               placeholder="Customer"
                                                               value={this.state.queryData?.customer_search ? this.state.queryData?.customer_search : ""}
                                                               onKeyDown={(e) => {
                                                                   if (e.keyCode === 13) {
                                                                       this.getQueryData(0)
                                                                   }
                                                               }}/>
                                                    </div>


                                                    <div className="form-group mr-3 mt-2">
                                                        <DateRangePicker
                                                            initialSettings={{}}
                                                            containerStyles={{
                                                                textAlign: 'left',
                                                                width: '100%',
                                                                display: 'block'
                                                            }}
                                                            onApply={this.handleOnApply}>
                                                            <input type="button"
                                                                   placeholder={`Select date range`}
                                                                   value={(this.state.queryData.from_date && this.state.queryData.to_date) ? `${this.state.queryData.from_date} - ${this.state.queryData.to_date}` : 'Select date range'}
                                                                   style={{textAlign: 'left', minWidth: '150px'}}
                                                                   className="form-control form-control-sm"/>
                                                        </DateRangePicker>
                                                    </div>
                                                    <Button onClick={()=>{this.clearFilter(1) }} style={{marginRight:'10px'}} className="mt-2" size="sm" variant="secondary">Clear</Button>

                                                    <Button className="mt-2" size="sm" variant="secondary"
                                                            onClick={()=> {
                                                                this.getQueryData(1);
                                                                this.getOrderReport()
                                                            }}>Search</Button>
                                                </>}


                                                {this.state.take_action && <>
                                                    <div className="form-group mr-3 mt-2">
                                                        <label htmlFor="select_action" className="sr-only">Select
                                                            Action</label>
                                                        <select id="select_action" name="select_action"
                                                                onChange={this.setActionData}
                                                                value={this.state.actionData}
                                                                className='form-control form-control-sm'>
                                                            <option value="">--Select Action --</option>
                                                            <option value="Cancelled">Cancel Order</option>
                                                        </select>
                                                    </div>


                                                    <Button className="mt-2" size="sm" variant="primary"
                                                            style={{marginRight: 15}}
                                                            onClick={this.submitAction}>Submit Action</Button>

                                                    <Button onClick={()=>{this.clearFilter(2)}} style={{marginLeft:'10px'}} className="mt-2" size="sm" variant="secondary">Clear</Button>
                                                </>}





                                            </form>

                                            {this.state.orders?.length > 0 ?
                                                <>

                                                    <Modal show={this.state.cancelActionModalShow}
                                                           onHide={this.hideActionModal}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Cancel {this.state.selectedCheckboxIndex.length} Orders?</Modal.Title>
                                                        </Modal.Header>
                                                        <Form onSubmit={this.submitActionModal}>
                                                            <Modal.Body>
                                                                <Form.Control as={`textarea`}
                                                                              onChange={(e) => {
                                                                                  this.setState({changeStatusMsg: e.target.value})
                                                                              }}
                                                                              placeholder={`Enter you message here...`}
                                                                              rows={3}/>

                                                                {this.state.showCancelMessageRequired ?
                                                                    <span><h5 style={{'color': "red"}}><i>Cancel message is required!</i></h5></span>
                                                                    : ''}
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <>
                                                                    <Button variant="default"
                                                                            onClick={this.hideActionModal}>
                                                                        Close
                                                                    </Button>
                                                                    <Button disabled={this.state.isSubmitting}
                                                                            variant="primary" type={`submit`}>
                                                                        {this.state.isSubmitting ? 'Submitting...' : 'Submit'}
                                                                    </Button>
                                                                </>
                                                            </Modal.Footer>
                                                        </Form>
                                                    </Modal>


                                                    <Modal show={this.state.isShowingStatusChangeConfirmModal}
                                                           onHide={this.hideChangeStatusModal}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Change Status Message</Modal.Title>
                                                        </Modal.Header>
                                                        <Form onSubmit={this.submitChangeStatusForm}>
                                                            <Modal.Body>
                                                                {this.state.status === 'Cancelled' ?
                                                                    <Form.Control as={`textarea`}
                                                                                  onChange={(e) => {
                                                                                      this.setState({changeStatusMsg: e.target.value})
                                                                                  }}
                                                                                  placeholder={`Enter you message here...`}
                                                                                  rows={3}/>
                                                                    :
                                                                    <span><h5>Are you sure you want to change the order status?</h5></span>}
                                                                {this.state.status === 'Cancelled' && this.state.showCancelMessageRequired ?
                                                                    <span><h5 style={{'color': "red"}}><i>Cancel message is required!</i></h5></span>
                                                                    : ''}
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                {this.state.status === 'Cancelled' && this.state.showCancelMessageRequired ?
                                                                    <>
                                                                        <Button variant="default"
                                                                                onClick={this.hideChangeStatusModal}>
                                                                            Close
                                                                        </Button>
                                                                        <Button disabled={this.state.isSubmitting}
                                                                                variant="primary" type={`submit`}>
                                                                            {this.state.isSubmitting ? 'Submitting...' : 'Submit'}
                                                                        </Button>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Button variant="default"
                                                                                onClick={this.hideChangeStatusModal}>
                                                                            No
                                                                        </Button>
                                                                        <Button disabled={this.state.isSubmitting}
                                                                                variant="primary" type={`submit`}>
                                                                            {this.state.isSubmitting ? 'Submitting...' : 'Yes'}
                                                                        </Button>
                                                                    </>
                                                                }
                                                            </Modal.Footer>
                                                        </Form>
                                                    </Modal>

                                                    <Modal size={"lg"} show={this.state.depositInfoModal}
                                                           onHide={this.handleCloseDepositInfoModal}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Deposit Payment Information</Modal.Title>
                                                        </Modal.Header>

                                                        <Modal.Body>
                                                            {(this.state?.current_payment_status == 2 || this.state?.current_payment_status == 6 || this.state?.current_payment_status == 7) ?
                                                                <table className="info-table table-lg">
                                                                    <tbody>

                                                                    <tr>
                                                                        <td className="text-bold">Bank Name: <span
                                                                            className="text-danger">*</span></td>
                                                                        <td className="text-bold">:</td>
                                                                        <td className="text-bold">{this.state?.selected_deposit_info?.bank_name}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="text-bold">Account Number: <span
                                                                            className="text-danger">*</span></td>
                                                                        <td className="text-bold">:</td>
                                                                        <td className="text-bold">{this.state?.selected_deposit_info?.account_number}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="text-bold">Branch Name: <span
                                                                            className="text-danger">*</span></td>
                                                                        <td className="text-bold">:</td>
                                                                        <td className="text-bold">{this.state?.selected_deposit_info?.branch_name}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="text-bold">Deposit By: <span
                                                                            className="text-danger">*</span></td>
                                                                        <td className="text-bold">:</td>
                                                                        <td className="text-bold">{this.state?.selected_deposit_info?.deposit_by}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="text-bold">Deposit Date: <span
                                                                            className="text-danger">*</span></td>
                                                                        <td className="text-bold">:</td>
                                                                        <td
                                                                            className="text-bold">{moment(this.state?.selected_deposit_info?.deposit_date).format('ll')}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="text-bold">Slip Number: <span
                                                                            className="text-danger">*</span></td>
                                                                        <td className="text-bold">:</td>
                                                                        <td className="text-bold">{this.state?.selected_deposit_info?.slip_number}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="text-bold">Desposit Status:</td>
                                                                        <td className="text-bold">:</td>
                                                                        <td className="text-bold">{this.state?.selected_deposit_info?.deposit_status}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td colSpan={"3"}>
                                                                            <img
                                                                                src={this.state?.selected_deposit_info?.deposit_slip}
                                                                                style={{
                                                                                    maxWidth: '100%',
                                                                                    height: "auto"
                                                                                }} alt=""/>
                                                                        </td>
                                                                    </tr>

                                                                    </tbody>
                                                                </table>
                                                                :
                                                                <>
                                                                    {this.state?.current_payment_status === 0 ? <>
                                                                        <span>Please wait for deposit upload</span></> : ''}
                                                                </>
                                                            }


                                                        </Modal.Body>

                                                        <Modal.Footer>
                                                            <Button variant="default"
                                                                    onClick={this.handleCloseDepositInfoModal}>
                                                                Close
                                                            </Button>

                                                            {(this.state?.current_payment_status === 6) &&
                                                            <>
                                                                <Button variant="primary" type={`button`}
                                                                        disabled={this.state.isSubmittingDepositPaymentStatus === 2}
                                                                        onClick={() => {this.updateDepositPaymentStatus(this.state.selected_order_id, 2 ,"2")
                                                                        }}>
                                                                    {this.state.isSubmittingDepositPaymentStatus ? 'Please Wait...' : 'Approve'}
                                                                </Button>
                                                                <Button variant="warning" type={`button`}
                                                                        disabled={this.state.isSubmittingDepositPaymentStatus === 7}
                                                                        onClick={() => this.updateDepositPaymentStatus(this.state.selected_order_id, 7,"1")}>
                                                                    {this.state.isSubmittingDepositPaymentStatus ? 'Please Wait...' : 'Declined'}
                                                                </Button>
                                                            </>
                                                            }

                                                        </Modal.Footer>
                                                    </Modal>
                                                    <Modal show={this.state.isUploadDepositModal}
                                                           onHide={this.handleDepositModalClose}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Upload Deposit Info</Modal.Title>
                                                        </Modal.Header>
                                                        <Form onSubmit={this.handleSubmitDepositInfo}>
                                                            <Modal.Body>
                                                                <Form.Group controlId={'deposit_by'}>
                                                                    <Form.Label>Deposit By <span
                                                                        className={"text-danger"}>*</span></Form.Label>
                                                                    <Form.Control name={'deposit_by'}
                                                                                  required
                                                                                  onChange={this.depositInputOnChange}
                                                                                  type={'text'}/>
                                                                </Form.Group>
                                                                <Form.Group controlId={'deposit_date'}>
                                                                    <Form.Label>Deposit Date <span
                                                                        className={"text-danger"}>*</span></Form.Label>
                                                                    <DatePicker
                                                                        selected={this.state.depositInfo.deposit_date}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        maxDate={new Date()}
                                                                        required
                                                                        className={'form-control'} onChange={date => {
                                                                        const depositInfo = {...this.state.depositInfo}
                                                                        depositInfo.deposit_date = date
                                                                        this.setState({depositInfo}, () => {
                                                                            console.log('Deposit Info: ', this.state.depositInfo);
                                                                        })
                                                                    }} classes={""}/>
                                                                </Form.Group>
                                                                <Form.Group controlId={'branch_name'}>
                                                                    <Form.Label>Deposited Branch <span
                                                                        className={"text-danger"}>*</span></Form.Label>
                                                                    <Form.Control name={'branch_name'}
                                                                                  required
                                                                                  onChange={this.depositInputOnChange}
                                                                                  type={'text'}/>
                                                                </Form.Group>
                                                                <Form.Group controlId={'slip_number'}>
                                                                    <Form.Label>Slip Number <span
                                                                        className={"text-danger"}>*</span></Form.Label>
                                                                    <Form.Control name={'slip_number'}
                                                                                  required
                                                                                  onChange={this.depositInputOnChange}
                                                                                  type={'text'}/>
                                                                </Form.Group>
                                                                <Form.Group controlId={'deposit_slip'}>
                                                                    <Form.Label className={'d-block'}>Deposit
                                                                        Slip</Form.Label>
                                                                    <div className="d-flex align-items-center">
                                                                        <BrowseButton required
                                                                                      isImageUploading={this.state.isImageUploading}
                                                                                      onChange={this.depositInputOnChange}
                                                                                      id={'deposit_slip'}
                                                                                      name={'deposit_slip'}/>
                                                                        {this.state.isImageUploading &&
                                                                        <div style={{transform: 'translateY(-4px)'}}>
                                                                            <Spinner animation="border"
                                                                                     className={'ml-2'} size="sm"
                                                                                     variant="info"/></div>}
                                                                    </div>
                                                                </Form.Group>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button variant="default"
                                                                        onClick={this.handleDepositModalClose}>Close</Button>
                                                                <Button variant="secondary" type="submit">Upload Deposit
                                                                    Info</Button>
                                                            </Modal.Footer>
                                                        </Form>
                                                    </Modal>

                                                    {this.state.isLoadingQuery && <LinearProgress/>}

                                                    <div className="table-responsive">
                                                        <table className="order-table table table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th><input
                                                                    type="checkbox"
                                                                    id={'id'}
                                                                    checked={this.state.selectAllToggle}
                                                                    onChange={(e) => this.handleSelectAllToggle(e)}
                                                                /></th>
                                                                <th style={{width: '1%'}}>#</th>
                                                                <th>Order Info</th>
                                                                <th>Customer Info</th>
                                                                <th>Store Info</th>
                                                                <th>Campaign</th>
                                                                <th>Payment Info</th>
                                                                <th>Shipping Info</th>
                                                                <th style={{width: '10%'}}>Status Message</th>
                                                                <th style={{width: '3%'}}>Status</th>
                                                                <th style={{width: '10%'}}/>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.state.orders.map((item, index) => {
                                                                return (
                                                                    <tr key={this.state.pagination.pagingCounter + index}>


                                                                        <td><input
                                                                            type="checkbox"
                                                                            id={`custom-checkbox-${index}`}
                                                                            checked={this.state.checkedIndex[index]}
                                                                            onChange={() => this.handleOnCheckboxChange(index)}
                                                                        />
                                                                        </td>


                                                                        <td>{this.state.pagination.pagingCounter + index}</td>
                                                                        <td>
                                                                            <div className="order-item">
                                                                                <h4 className="order-id">
                                                                                    <Link
                                                                                        to={`${process.env.PUBLIC_URL}/orders/parcel/${item.order_parcels._id}`}
                                                                                        target={'_blank'}>{item?.order_parcels?.order_parcel_code}</Link>
                                                                                </h4>
                                                                                <span
                                                                                    className="order-date">{moment(item.createdAt).format(`ll`)}</span>
                                                                                <span
                                                                                    className="order-time">{moment(item.createdAt).format(`LT`)}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="order-item">
                                                                                <h4 className="order-id">
                                                                                    <Link
                                                                                        to={`${process.env.PUBLIC_URL}/customers/details/${item.customer_info?._id}`}
                                                                                        target={'_blank'}>{item.customer_info?.name}</Link>
                                                                                </h4>
                                                                                <ul className="ordered-items">
                                                                                    <li>{item.customer_info?.phone}</li>
                                                                                    <li>{item.customer_info?.email}</li>
                                                                                    <li>
                                                                                        <Link
                                                                                            onClick={(e) => this.handleContactBtn(e, item?.customer_info?._id, "user_id")}
                                                                                            to={`/`} target={'_blank'}>
                                                                                            <span
                                                                                                className="icon"><FiMessageSquare/></span> Contact
                                                                                            Customer
                                                                                        </Link>
                                                                                    </li>
                                                                                    <li><Badge
                                                                                        variant="info">{item.customer_info?.muktopath_user === true && item.customer_info?.muktopath_user !== undefined && 'MuktoPath user'}</Badge>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="order-item">
                                                                                <h4 className="order-id">
                                                                                    <Link
                                                                                        to={`${process.env.PUBLIC_URL}/stores/details/${item?.order_parcels?.store_info?._id}`}
                                                                                        target={'_blank'}>{item?.order_parcels?.store_info?.store_name}</Link>
                                                                                </h4>
                                                                                <ul className="ordered-items">
                                                                                    <li>
                                                                                        <Link
                                                                                            onClick={(e) => this.handleContactBtn(e, item?.order_parcels?.store_info?._id, "store_id")}
                                                                                            to={`/`}>
                                                                                            <span
                                                                                                className="icon"><FiMessageSquare/></span> Contact
                                                                                            Seller
                                                                                        </Link>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            <div className="order-item">
                                                                                {(item?.order_parcels?.campaigns && item?.order_parcels?.campaigns.length > 0) ?
                                                                                    <>
                                                                                        {this.CampaignList(item)}
                                                                                    </> :
                                                                                    'Regular Order'
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="order-item">
                                                                                <h4
                                                                                    className="order-id">৳{item?.order_parcels?.parcel_grand_total.toFixed(2)}</h4>
                                                                                <ul className="ordered-items">
                                                                                    <li>{item.payment_method?.name ? item.payment_method?.name : 'COD'}</li>
                                                                                    <li>{this.renderPaymentStatus(item.order_parcels.payment_status)}</li>
                                                                                    <li>{item?.order_parcels?.parcel_wallet_amount > 0 &&
                                                                                    <Badge pill variant="info">used wallet amount</Badge>
                                                                                    }</li>
                                                                                    {item?.payment_status_message && <><li><Badge pill variant="info">{item?.payment_status_message}</Badge></li></>}

                                                                                </ul>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="order-item">
                                                                                <h4
                                                                                    className="order-id">{item?.order_parcels?.selected_shipping_option ? item?.order_parcels?.selected_shipping_option : "ekShop Fulfillment"}</h4>
                                                                                <ul className="ordered-items">
                                                                                    <li>৳{item?.order_parcels?.parcel_shipping_cost?.toFixed(2)}</li>
                                                                                    <li><span
                                                                                        className="d-block">{this.renderSelectedShippingEstimatedTime(item.order_parcels?.possible_shipping_options, item.order_parcels?.selected_shipping_option || "ekShop Fulfillment")}</span>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </td>
                                                                        <td>{item?.order_parcels?.status_message || '-'}</td>

                                                                        {/* disabling status selection dropdown if deposit form is not updated */}
                                                                        <td>
                                                                            {
                                                                                (
                                                                                    (item?.payment_method?.name === "Bank Payment" && item?.payment_status === 6) ||
                                                                                    (item?.payment_method?.name === "Bank Payment" && item?.payment_status === 2) ||
                                                                                    item?.payment_method?.name === "COD" ||
                                                                                    item?.payment_method?.name === "online_payment"
                                                                                ) &&
                                                                                <>
                                                                                    <select style={{width: '150px'}}
                                                                                            value={item.order_parcels.status}
                                                                                            className="form-control form-control-sm"
                                                                                            onChange={(e) => {
                                                                                                this.setState({
                                                                                                    status: e.target.value,
                                                                                                    changingStoreId: item.order_parcels._id,
                                                                                                    isShowingStatusChangeConfirmModal: true
                                                                                                })
                                                                                            }}>
                                                                                        {this.ShowStatusOption(item.order_parcels.status).map((option, index) => (
                                                                                            <option key={index}
                                                                                                    selected={item.order_parcels.status === option.value}
                                                                                                    value={option.value}>{option.label}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                    {item?.payment_status === 6 ? <>
                                                                                        <span>Please check uploaded deposit</span></> : ''}
                                                                                </>
                                                                            }
                                                                            {
                                                                                (item?.payment_method?.name === "Bank Payment" && item?.payment_status === 0) &&
                                                                                <>
                                                                                    {item?.payment_status === 0 ? <>
                                                                                        <select style={{width: '150px'}}
                                                                                                value={item.order_parcels.status}
                                                                                                className="form-control form-control-sm"
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        status: e.target.value,
                                                                                                        changingStoreId: item.order_parcels._id,
                                                                                                        isShowingStatusChangeConfirmModal: true
                                                                                                    })
                                                                                                }}>
                                                                                            {item.order_parcels.status === 'Cancelled' && this.ShowStatusOption(item.order_parcels.status).map((option, index) => (
                                                                                                <option key={index}
                                                                                                        selected={item.order_parcels.status === option.value}
                                                                                                        value={option.value}>{option.label}</option>
                                                                                            ))}
                                                                                            {item.order_parcels.status === 'Pending' && <>
                                                                                                <option key={0}
                                                                                                        selected={item.order_parcels.status === 'Pending'}
                                                                                                        value={'Pending'}>{'Pending'}</option>
                                                                                                <option key={6}
                                                                                                        selected={item.order_parcels.status === 'Cancelled'}
                                                                                                        value={'Cancelled'}>{'Cancelled'}</option>
                                                                                            </>}
                                                                                        </select>
                                                                                        {item.order_parcels.status === 'Pending' &&
                                                                                        <span>Please wait for deposit upload</span>}</> : ''}
                                                                                </>
                                                                            }
                                                                        </td>

                                                                        <td>
                                                                            {getLocalAuthData()?.permission?.includes('order_parcel_list_item_view') ?
                                                                                <Link
                                                                                    to={`${process.env.PUBLIC_URL}/orders/parcel/${item.order_parcels._id}`}
                                                                                    className="btn btn-block btn-sm btn-outline-info"
                                                                                    target={'_blank'}>View</Link> : null}

                                                                            {getLocalAuthData()?.permission?.includes('order_invoice') ?
                                                                                <Link
                                                                                    to={`${process.env.PUBLIC_URL}/orders/invoice/${item._id}`}
                                                                                    className="btn btn-block btn-sm btn-outline-info"
                                                                                    target={'_blank'}>Invoice</Link> : null}

                                                                            {(item?.payment_method?.name === 'online_payment' && item?.order_parcels?.payment_status != 2) &&
                                                                            <>
                                                                                <Button to="#"
                                                                                        className="btn-action btn-block btn-outline-info"
                                                                                        onClick={() => {
                                                                                            this.getPaymentStatus(item?._id, item?.order_parcels?._id)
                                                                                        }}>Get Payment Status</Button>
                                                                            </>
                                                                            }

                                                                            {item?.order_parcels?.invoice_url?<Link className="btn-action btn-block btn-outline-info" to={`${item?.order_parcels?.invoice_url}`} target="_blank" download><FaFileExport/> Download Invoice</Link>: ""}

                                                                            {(item.payment_method?.name === 'Bank Payment' && (item?.deposit_info === undefined || item?.deposit_info === null)) && item.order_parcels.status === 'Pending' &&
                                                                            <Button to="#"
                                                                                    className="btn-action btn-block btn-outline-info"
                                                                                    onClick={() => {
                                                                                        this.handleDepositModalShow(item._id, item?.order_parcels?.parcel_grand_total , item?.order_parcels?.order_parcel_code ,item?.order_parcels?._id)
                                                                                    }}>Upload
                                                                                Deposit Slip</Button>}
                                                                            {(item?.payment_method?.name === "Bank Payment") &&
                                                                            <button
                                                                                onClick={() => this.showDepositInfo(item?._id, item?.deposit_info, item?.payment_status, item?.payment_status , item?.order_parcels?.order_parcel_code ,item?.order_parcels?._id)}
                                                                                className="btn btn-block btn-sm btn-outline-info">Deposit
                                                                                Info</button>}

                                                                            {/*<Link to={`${process.env.PUBLIC_URL}/orders/details/${item._id}`}
                                        className="btn btn-block btn-sm btn-outline-secondary" target={'_blank'}>Order Detail</Link>*/}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}

                                                            </tbody>
                                                        </table>
                                                    </div>


                                                    <div className="mt-3 d-flex">
                                                        <Pagination
                                                            itemClass="page-item"
                                                            linkClass="page-link"
                                                            activePage={this.state.pagination?.page}
                                                            itemsCountPerPage={this.state.pagination?.limit}
                                                            totalItemsCount={this.state.pagination?.totalDocs}
                                                            onChange={this.handlePageChange.bind(this)}/>
                                                            <div style={{width: '75px', marginLeft: '15px'}}>
                                                                <Form.Control as="select" name="limit" value={this.state.queryData?.limit} onChange={this.onLimitChange}>
                                                                    <option value="20">20</option>
                                                                    <option value="40">40</option>
                                                                    <option value="50">50</option>
                                                                    <option value="70">70</option>
                                                                    <option value="100">100</option>
                                                                </Form.Control>
                                                            </div>
                                                            {console.log("this.state.queryData", this.state.queryData)}
                                                    </div>

                                                </>
                                                :
                                                <div className={`alert alert-warning text-center text-bold`}>
                                                    No order found
                                                </div>
                                            }
                                        </div>
                                    }
                                </div> : null}
                        </>
                    }

                </div>
            </App>
        );
    }
}

export default withRouter(OrderParcelList);
