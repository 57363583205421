import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import "../assets/scss/home-product.scss";
import {discountPercentage} from "../helper/utils";
import {Button} from "@material-ui/core";
import {getTableData, updateItem} from "../services/commonServices";
import {toast} from "react-toastify";
import {SHOP_BASE_URL} from "../helper/env";
import {GoLinkExternal} from "react-icons/all";

const HomeProduct = ({id,data, sku_info, className, handleDeleteListener, disable }) => {

    const handleProductDelete = (productId)=>{
       // window.alert(productId+" "+id)
        updateItem('/front/categories-with-products/'+id+'/'+data._id , {}).then((res)=>{
            console.log(res)
            if (res.status === 1){
                toast.success(res.message)
                window.location.href ='/homepage-management'
            }else {
                toast.warning(res.message)
            }
        })

    }

    useEffect(()=>{

    },[])



  return (
      <div className={className ? `home-product ${className}` : 'home-product'}>
         {/* <Link to={`/products/details/${data._id}`}
                className={className ? `home-product ${className}` : 'home-product'}>*/}
              <div className="ratio ratio-1-1 product-thumb">
                  <div className="ratio-inner">
                      <div><img src={data.mid_img ? data.mid_img : data.thumb_img} alt=""/></div>

                  </div>
              </div>
              <div className="product-info">
                  <h4 className="product-title">{data.name}</h4>
                  <p className="pricing">
                      {(sku_info?.special_price && sku_info?.special_price < sku_info?.price) ?
                          <>
                              <span className="current-price">৳ {sku_info?.special_price?sku_info?.special_price:sku_info?.price}</span>
                              <span className="regular-price"><strike>৳ {sku_info?.price}</strike> - {discountPercentage(sku_info?.price, sku_info?.special_price)}%</span>
                          </>
                          :
                          <span className="current-price">৳ {sku_info?.price}</span>
                      }
                  </p>
                  <Link to={`/products/details/${data._id}`}>VIEW</Link>
                  <a target={'_blank'}
                     rel="noopener noreferrer"
                     className={'ml-3 text-primary'}
                     href={`${SHOP_BASE_URL}/products/details/${data.slug ? data.slug : data._id}`}><GoLinkExternal/></a>
                  <br/>
                  {/*<Button style={{color: '#ec0d0d'}} onClick={()=> {
                      handleProductDelete(data?._id)
                  }}>Delete</Button>*/}
                  {disable === true ? "" : <Button style={{color: '#ec0d0d'}} onClick={handleDeleteListener}>Delete</Button>}

              </div>
        {/*  </Link>*/}
      </div>

  );
};

export default HomeProduct;