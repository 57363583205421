import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {getLocationDetails, getParentLocationByType, updateLocation} from "../../services/locationServices";
import styleReactSelect from "../../assets/styleReactSelect";
import Select from 'react-select';
import {toast} from "react-toastify";
import {Link, withRouter} from "react-router-dom";
import PlacesAutocomplete from "../../components/PlacesAutocomplete";
import {MdKeyboardBackspace} from "react-icons/all";
import {getLocalAuthData} from "../../helper/utils";

class LocationEdit extends Component {
  state = {
    locationTypes: [
      {
        id: 1,
        type: 'Country'
      },
      {
        id: 2,
        type: 'Division',
        isParentLocationType: true
      },
      {
        id: 3,
        type: 'State',
        isParentLocationType: true
      },
      {
        id: 4,
        type: 'District',
        isParentLocationType: true
      },
      {
        id: 5,
        type: 'Upazilla',
        isParentLocationType: true
      },
      {
        id: 6,
        type: 'Union',
        isParentLocationType: true
      }
    ],
    nextLocationTypes: [],
    parentLocationList: [],
    location: {},
    isDataLoading: false,
    isSubmitting: false
  }

  componentDidMount() {
    const locationId = this.props.match.params.id;
    this.setState({isDataLoading: true}, () => {
      getLocationDetails(locationId)
        .then(res => {
          const location = {
            name: res.name,
            parent_location: res.parent_location,
            latitude: res.latitude,
            longitude: res.longitude,
            type: res.type,
            next_location_type: res.next_location_type,
            is_required: res.is_required
          }

          if (res.type === 'Country') {
            location.code = res?.code
            this.setState({location, isDataLoading: false})
          } else {
            this.setState({location, isDataLoading: false})
          }

          this.setParentLocationByType(res.type);
          this.setNextLocationType(res.type);
        })
    })
  }

  handleTypeOnChange = e => {
    this.setState({parentLocationList: []});
    const location = {...this.state.location};
    const locationTypes = [...this.state.locationTypes];
    const locationType = this.state.locationTypes.filter(obj => {
      return obj.type === e.target.value
    })

    if (locationType[0]?.isParentLocationType) {
      // Set parent location
      this.setParentLocationByType(locationType[0]?.type)
    } else {
      delete location.parent_location;
    }
    location[e.target.name] = e.target.value
    this.setState({parentLocationList: [], location});

    // Set next location type
    if (e.target.value !== '') {
      const index = this.state.locationTypes.indexOf(locationType[0]);
      locationTypes.splice(0, (index + 1));
      this.setState({nextLocationTypes: locationTypes});
    } else {
      this.setState({nextLocationTypes: []})
    }
  }

  setParentLocationByType = (type) => {
    getParentLocationByType(type)
      .then(res => {
        const locationList = [];
        res.forEach(item => {
          locationList.push({
            id: item._id,
            label: item.name,
            value: item.name,
            type: item.type
          })
        })
        this.setState({parentLocationList: locationList})
      })
  }

  setNextLocationType = (type) => {
    const locationTypes = [...this.state.locationTypes];
    const locationType = this.state.locationTypes.filter(obj => {
      return obj.type === type
    })
    const index = this.state.locationTypes.indexOf(locationType[0]);
    locationTypes.splice(0, (index + 1));
    this.setState({nextLocationTypes: locationTypes});
  }

  handleInputOnChange = (e) => {
    const location = {...this.state.location};
    location[e.target.name] = e.target.value;
    this.setState({location});
  }

  onChangeParentLocation = (value, name) => {
    const location = {...this.state.location};
    location[name] = value.id;
    this.setState({location});
  }

  handleFormOnSubmit = (e) => {
    e.preventDefault();
    this.setState({isSubmitting: true});
    const locationId = this.props.match.params.id;
    updateLocation(this.state.location, locationId)
      .then(res => {
        this.setState({isSubmitting: false});
        this.props.history.push(`${process.env.PUBLIC_URL}/locations`);
        toast.success(res.message);
      })
      .catch(errMsg => {
        this.setState({isSubmitting: false});
        toast.error(errMsg)
      })
  }

  handleOnSelectLocation = (locationObj) => {
    const location = {...this.state.location}
    location.latitude = locationObj.lat;
    location.longitude = locationObj.lng;
    this.setState({location});
  }

  createObjForSelect = (prevObj, id) => {
    const newObj = prevObj.filter((obj) => {
      return obj.id === id
    });
    return newObj[0];
  }

  render() {
    return (
      <App layout={"boxed"}>

        {getLocalAuthData()?.permission?.includes('location_edit') ?
        <>
          <div className="page-header">
            <h2 className="page-title">Update Location</h2>

            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/locations`} className={"btn btn-link"}><MdKeyboardBackspace/> Back to
                Location List</Link>
            </div>
          </div>

          <div className="main-content">
            <div className="card-block">
              <div className="block-header">
                <h4 className="block-title">Location Details</h4>
              </div>

              <div className="block-body">
                {!this.state.isDataLoading ?
                    <Form onSubmit={this.handleFormOnSubmit}>
                      <Form.Group as={Row} controlId="serviceName">
                        <Form.Label column sm="3">
                          Location Type <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control name={"type"}
                                        defaultValue={this.state.location.type}
                                        onChange={this.handleTypeOnChange}
                                        required as={"select"}>
                            <option value="">--Select--</option>
                            {this.state.locationTypes.map((type, index) => (
                                <option key={index} value={type.type}>{type.type}</option>
                            ))}
                          </Form.Control>
                        </Col>
                      </Form.Group>
                      {this.state.parentLocationList.length > 0 &&
                      <Form.Group as={Row} controlId="parentLocation">
                        <Form.Label column sm="3">
                          Parent Location ({this.state.parentLocationList[0].type})
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Select
                              styles={styleReactSelect}
                              defaultValue={this.createObjForSelect(this.state.parentLocationList, this.state.location.parent_location)}
                              placeholder={`Select ${this.state.parentLocationList[0].type}...`}
                              onChange={(value) => this.onChangeParentLocation(value, 'parent_location')}
                              options={this.state.parentLocationList}
                              name="attributes"
                          />
                        </Col>
                      </Form.Group>
                      }

                      {this.state.location.type === 'Country' &&
                      <Form.Group as={Row} controlId="serviceName">
                        <Form.Label column sm="3">
                          Country Code <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <div className="input-group">
                            <Form.Control type={"text"} name={"code"}
                                          style={{flex: '0 0 150px'}}
                                          defaultValue={this.state.location.code}
                                          onChange={this.handleInputOnChange}
                                          required
                                          placeholder={"Country Code"}/>
                          </div>
                        </Col>
                      </Form.Group>
                      }

                      <Form.Group as={Row} controlId="serviceName">
                        <Form.Label column sm="3">
                          Location Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control type={"text"} name={"name"}
                                        defaultValue={this.state.location.name}
                                        onChange={this.handleInputOnChange}
                                        required
                                        placeholder={"Name"}/>
                        </Col>
                      </Form.Group>

                      {this.state.nextLocationTypes.length > 0 &&
                      <Form.Group as={Row} controlId="nextLocationType">
                        <Form.Label column sm="3">
                          Next Location Type <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control name={"next_location_type"}
                                        onChange={this.handleInputOnChange}
                                        required
                                        defaultValue={this.state.location.next_location_type}
                                        as={"select"}>
                            <option value="">--Select--</option>
                            {this.state.nextLocationTypes.map((type, index) => (
                                <option key={index}
                                        value={type.type}>{type.type}</option>
                            ))}
                          </Form.Control>
                        </Col>
                      </Form.Group>}

                      <Form.Group as={Row} controlId="geoLocation">
                        <Form.Label column sm="3">
                          GEO Location
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <PlacesAutocomplete
                              onSelectLocation={this.handleOnSelectLocation}
                              placeholder={"Enter your location"}/>

                          <Row className={"mt-3"}>
                            <Col md={6}>
                              <Form.Control
                                  name={"latitude"}
                                  value={this.state.location?.latitude}
                                  onChange={this.handleInputOnChange}
                                  placeholder={"Latitude"}/>
                            </Col>
                            <Col md={6}>
                              <Form.Control
                                  name={"longitude"}
                                  value={this.state.location?.longitude}
                                  onChange={this.handleInputOnChange}
                                  placeholder={"Longitude"}/>
                            </Col>
                          </Row>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="">
                        <Form.Label column sm="3">
                          Required
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Check
                              custom
                              inline
                              defaultChecked={this.state.location.is_required}
                              value={true}
                              onChange={this.handleInputOnChange}
                              type="radio"
                              name={`is_required`}
                              id={`isRequiredYes`}
                              label={`Yes`}
                              required
                          />

                          <Form.Check
                              custom
                              inline
                              value={false}
                              defaultChecked={!this.state.location.is_required}
                              onChange={this.handleInputOnChange}
                              type="radio"
                              name={`is_required`}
                              id={`isRequiredNo`}
                              label={`No`}
                              required
                          />
                        </Col>
                      </Form.Group>

                      <Row>
                        <Col sm={3}/>
                        <Col md={6}>
                          <Button variant={"primary"}
                                  disabled={this.state.isSubmitting}
                                  type="submit">{this.state.isSubmitting ? 'Updating...' : 'Update'}</Button>
                        </Col>
                      </Row>
                    </Form>
                    :
                    <div>Loading...</div>
                }
              </div>
            </div>
          </div>
        </>: null}

      </App>
    );
  }
}

export default withRouter(LocationEdit);
