import React from "react";
import {FiEdit, FiTrash} from "react-icons/all";
import "../assets/scss/service.scss";
import {getLocalAuthData} from "../helper/utils";

const Service = (props) => {
  return (
    <div className="service-box">
      <img src={`${props.imageLink}`} alt=""/>
      <div className="actions">

          {getLocalAuthData()?.permission?.includes('service_edit')? <span className="action" onClick={props.editService}><FiEdit/></span> : null}
          {getLocalAuthData()?.permission?.includes('service_delete')?
        <span className="action" onClick={props.deleteService}><FiTrash/></span> : null}
      </div>
    </div>
  )
}

export default Service