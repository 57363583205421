import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-widgets/dist/css/react-widgets.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'antd/dist/antd.css';
import './assets/scss/style.scss';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router, Link, Route, Switch} from "react-router-dom";
import PrivateRoute from "./auth/components/PrivateRoute";
import Settings from "./views/settings/Settings";
import AssistedSellers from "./views/assisted-seller/AssistedSellers";
import AddAssistedSeller from "./views/assisted-seller/AddAssistedSeller";
import EditAssistedSeller from "./views/assisted-seller/EditAssistedSeller";
import AssistedSellerDetails from "./views/assisted-seller/AssistedSellerDetails";
import Login from "./views/Login";
import {Provider} from "react-redux";
import store from "./redux/store";
import ResetPassword from "./views/profile/ResetPassword";
import ProductList from "./views/products/ProductList";
import AddProduct from "./views/products/AddProduct";
import OrderList from "./views/orders/OrderList";
import OrderParcelList from "./views/orders/OrderParcelList";
import OrderProductList from "./views/orders/OrderProductList";
import OrderDetails from "./views/orders/OrderDetails";
import OrderParcelDetails from "./views/orders/OrderParcelDetails";
import Invoice from "./views/orders/Invoice";
import SellerVoucher from "./views/promotions/SellerVoucher";
import AddSellerVoucher from "./views/promotions/AddSellerVoucher";
import CategoryList from "./views/categories/CategoryList";
import AddCategory from "./views/categories/AddCategory";
import EditCategory from "./views/categories/EditCategory";
import VariantList from "./views/variants/VariantList";
import AddVariant from "./views/variants/AddVariant";
import EditVariant from "./views/variants/Edit";
import EntrepreneurList from "./views/entrepreneurs/EntrepreneurList";
import AddEntrepreneur from "./views/entrepreneurs/AddEntrepreneur";
import EditEntrepreneur from "./views/entrepreneurs/Edit";
import Customers from "./views/customers/Customers";
import AddCustomer from "./views/customers/AddCustomer";
import BundleOfferList from "./views/bundles/BundleOfferList";
import AddBundleOffer from "./views/bundles/AddBundleOffer";
import EditBundleOffer from "./views/bundles/EditBundleOffer";
import ProductDetails from "./views/products/ProductDetails";
import CommissionOverview from "./views/finance/CommissionOverview";
import OrderReport from "./views/reports/OrderReport";
import ReturnAndRefundReport from "./views/reports/ReturnAndRefundReport";
import SalesReport from "./views/reports/SalesReport";
import CustomerDetails from "./views/customers/CustomerDetails";
import Dashboard from "./views/Dashboard";
import BuyerCommission from "./views/finance/BuyerCommission";
import EkshopCommission from "./views/finance/EkshopCommission";
import Messages from "./views/messages/Messages";
import FreeShippingOfferList from "./views/free_shipping_offers/FreeShippingOfferList";
import AddFreeShippingOffer from "./views/free_shipping_offers/AddFreeShippingOffer";
import RewardPointSettingList from "./views/reward_point_settings/RewardPointSettingList";
import AddRewardPointSetting from "./views/reward_point_settings/AddRewardPointSetting";
import EventList from "./views/Events/EventList";
import AddEvent from "./views/Events/AddEvent";
import CampaignOfferList from "./views/campaings/CampaignOfferList";
import AddCampaignOffer from "./views/campaings/AddCampaignOffer";
import HomepageManagement from "./views/HomepageManagement";
import Brands from "./views/brands/Brands";
import Collections from "./views/collections/Collections";
import CreateCollection from "./views/collections/CreateCollection";
import Banners from "./views/banners/Banners";
import Services from "./views/services/Services";
import OnboardingScreen from "./views/onboarding-screens/onboarding-screens";
import EditProduct from "./views/products/EditProduct";
import EditCollection from "./views/collections/EditCollection";
import Locations from "./views/locations/Locations";
import LocationAdd from "./views/locations/LocationAdd";
import TransactionReport from "./views/reports/TransactionReport";
import LocationEdit from "./views/locations/LocationEdit";
import CustomerReport from "./views/reports/CustomerReport";
import SellerReport from "./views/reports/SellerReport";
import CategoryReport from "./views/reports/CategoryReport";
import CommissionReport from "./views/reports/CommissionReport";
import NewUserReport from "./views/reports/NewUserReport";
import ProductReport from "./views/reports/ProductReport";
import SearchReport from "./views/reports/SearchReport";
import {ToastContainer} from "react-toastify";
import MerchantTypes from "./views/merchant-type/MerchantTypes";
import EntrepreneurDetails from "./views/entrepreneurs/EntrepreneurDetails";
import StoreList from "./views/store/StoreList";
import CreateStore from "./views/store/CreateStore";
import EditStore from "./views/store/EditStore";
import GeneralCommissionSettings from "./views/commission-settings/GeneralCommissionSettings";
import CustomCommissionSettings from "./views/commission-settings/CustomCommissionSettings";
import AddCustomCommissionSettings from "./views/commission-settings/AddCustomCommissionSettings";
import EditCustomCommissionSettings from "./views/commission-settings/EditCustomCommissionSettings";
import EditSellerVoucher from "./views/promotions/EditSellerVoucher";
import axios from "axios"
import {getDeviceType, getLocalAuthData} from "./helper/utils";
import SummaryReport from "./views/reports/SummaryReport";
import MerchantCommission from "./views/commission/MerchantCommission";
import AdminCommissions from "./views/commission/AdminCommissions";
import BulkRequests from "./views/bulk-requests/BulkRequests";
import Disputes from "./views/disputes/Disputes";
import Banks from "./views/banks/Banks";
import AclAction from "./views/acl-action/Actions";
import AclRole from "./views/acl-role/Roles";
import ShippingOptions from "./views/shipping-options/ShippingAddressSettings";
import StoreDetails from "./views/store/StoreDetails";
import WalletRedeemPackage from "./views/wallet-redeem-packages/WRPOfferList.js";
import AddWalletRedeemPackage from "./views/wallet-redeem-packages/AddWRPOffer";
import EditWalletRedeemPackage from "./views/wallet-redeem-packages/EditWRPOffer";
import CampaignDetails from "./views/campaings/CampaignDetails";
import EditCampaignOffer from "./views/campaings/EditCampaignOffer";
import StaffList from "./views/staff-management/staffList";
import StaffAdd from "./views/staff-management/staffAdd";
import SupportTicket from "./views/support-ticket/SupportTicket";
import SupportFaq from "./views/support-faq/SupportFaq";
import SupportTopic from "./views/support-topic/SupportTopic";
import CreateTicket from "./views/support-ticket/CreateTicket";
import TicketDetails from "./views/support-ticket/TicketDetails";
import ComplainList from './views/complain/ComplainList';
import Live from './views/live/Live';
import CreateLive from './views/live/create-live';
import EditLive from './views/live/edit-live'
import Agent from './views/agent/Agent'
import AgentDetails from './views/agent/AgentDetails'
import PartnerOnboardList from "./views/partner-onboard/PartnerOnboardList";
import CreatePartnerOnboard from "./views/partner-onboard/CreatePartnerOnBoard";
import EditPartnerOnboard from "./views/partner-onboard/EditPartnerOnboard";
import OrderServicesList from "./views/orders/OrderServicesList";
import HoichoiServiceCreate from "./views/hoichoi/HoichoiServiceCreate";
import HoichoiServiceList from "./views/hoichoi/HoichoiServiceList";
import ActivityLog from "./views/staff-management/activityLog";
import DeliveryReport from "./views/reports/DeliveryReport";
import CampaignReport from "./views/reports/CampaignReport";
import StoreReport from "./views/reports/StoreReport";
import VoucherReport from "./views/reports/VoucherReport";
import NoticeBoardList from "./views/notices/NoticeBoardList";
import AddNotice from "./views/notices/AddNotice";
import EditNotice from "./views/notices/EditNotice";
import MenuManagement from "./views/menu-management/MenuManagement";
import OthersOrderParcelList from "./views/orders/OthersOrderParcelList";
import CustomerOrderPlace from "./views/orders/CustomerOrderPlace";
import NoticeView from "./views/notices/NoticeView";
import AddNotification from "./views/fcm-notification/AddNotification";

import BuyerCommissionTransaction from "./views/finance/BuyerCommissionTransaction";
import BuyerCommissionOrder from "./views/finance/BuyerCommissionOrder";
import EkshopCommissionTransaction from "./views/finance/EkshopCommissionTransaction";
import CommissionWallet from "./views/finance/CommissionWallet";
import DeliveryCharge from "./views/finance/DeliveryCharge";
import DeliveryChargeTransaction from "./views/finance/DeliveryChargeTransaction";
import StorePaymentTransaction from "./views/finance/StorePaymentTransaction";
import NotificationList from "./views/fcm-notification/NotificationList";
import SellerPayment from "./views/finance/SellerPayment";
import SellerPaymentHistory from "./views/finance/SellerPaymentHistory";
import StorePayment from "./views/finance/StorePayment";
import HoichoiServiceBuy from "./views/hoichoi/HoichoiServiceBuy";
import ProjectSettings from "./views/project-settings/project-settings";
import News from "./views/news/News";
import WalletReport from "./views/reports/WalletReport";
import HoichoiServiceEdit from "./views/hoichoi/HoichoiServiceEdit";
import District from "./views/district/District";
import DistrictReport from "./views/reports/DistrictReport";

const authData = getLocalAuthData()

axios.defaults.headers.common['x-auth-token'] = authData?.token || ''
axios.defaults.headers.common['x-api-client'] = getDeviceType()

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if(error?.response?.status === 410) {
    localStorage.removeItem('user')
    window.location.href = '/'
    //window.location.reload()
  }
})

if (getLocalAuthData()?.token && !getLocalAuthData()?.permission) {
  console.log(!getLocalAuthData()?.permission)
  localStorage.removeItem('user')
  window.location.href = '/'
  //window.location.reload()
}

ReactDOM.render(
  <Provider store={store}>
    {/*<Router  basename="/ekshop-admin">*/}
    <Router>
      <Switch>
        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/`}>
          <Dashboard/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/project-settings`}>
          <ProjectSettings/>
        </PrivateRoute>


        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/reset-password`}>
          <ResetPassword/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/homepage-management`}>
          <HomepageManagement/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/products/list`}>
          <ProductList/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/products/details/:id`}>
          <ProductDetails/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/products/add`}>
          <AddProduct/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/products/edit/:id`}>
          <EditProduct/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/district-branding`}>
          <District/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/orders/list`}>
          <OrderList/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/orders/customer-orderplace`}>
          <CustomerOrderPlace/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/complains/list`}>
          <ComplainList/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/orders/parcel/list`}>
          <OrderParcelList/>
        </PrivateRoute>

          <PrivateRoute exact path={`${process.env.PUBLIC_URL}/others-orders/parcel/list`}>
          <OthersOrderParcelList/>
        </PrivateRoute>


        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/orders/hoichoi-subscription`}>
          <OrderServicesList/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/services/hoichoi/list`}>
          <HoichoiServiceList/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/services/hoichoi/add`}>
          <HoichoiServiceCreate/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/services/hoichoi/buy`}>
          <HoichoiServiceBuy/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/services/hoichoi/edit/:id`}>
           <HoichoiServiceEdit/>
        </PrivateRoute>



        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/orders/product/list`}>
          <OrderProductList/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/orders/details/:id`}>
          <OrderDetails/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/orders/parcel/:id`}>
          <OrderParcelDetails/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/bulk-requests`}>
          <BulkRequests/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/orders/invoice/:id`}>
          <Invoice/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/locations`}>
          <Locations/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/locations/add`}>
          <LocationAdd/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/locations/edit/:id`}>
          <LocationEdit/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/entrepreneurs/list`}>
          <EntrepreneurList/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/entrepreneurs/details/:id`}>
          <EntrepreneurDetails/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/entrepreneurs/edit/:id`} component={EditEntrepreneur}>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/entrepreneurs/add`}>
          <AddEntrepreneur/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/merchant-commission/:id`}>
          <MerchantCommission/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/seller-payment-history/:id`}>
          <SellerPaymentHistory/>
        </PrivateRoute>

        <PrivateRoute exact path="/disputes">
          <Disputes/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/customers`}>
          <Customers/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/customers/details/:id`}>
          <CustomerDetails/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/customers/add`}>
          <AddCustomer/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/assisted-sellers`}>
          <AssistedSellers/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/assisted-sellers/add`}>
          <AddAssistedSeller/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/assisted-sellers/edit/:id`}>
          <EditAssistedSeller/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/assisted-sellers/details/:id`}>
          <AssistedSellerDetails/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/seller-voucher`}>
          <SellerVoucher/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/seller-voucher/add`}>
          <AddSellerVoucher/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/seller-voucher/edit/:id`}>
          <EditSellerVoucher/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/bundle-offer`}>
          <BundleOfferList/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/bundle-offer/add`}>
          <AddBundleOffer/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/bundle-offer/edit/:id`}>
          <EditBundleOffer/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/free-shipping`}>
          <FreeShippingOfferList/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/free-shipping/add`}>
          <AddFreeShippingOffer/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/reward-point`}>
          <RewardPointSettingList/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/reward-point/add`}>
          <AddRewardPointSetting/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/event`}>
          <EventList/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/event/add`}>
          <AddEvent/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/campaign`}>
          <CampaignOfferList/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/notification/add`}>
          <AddNotification/>
        </PrivateRoute>


        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/notice/list`}>
          <NoticeBoardList/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/notice/add`}>
          <AddNotice/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/notice/edit/:id`}>
          <EditNotice/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/notice/view/:id`}>
          <NoticeView/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/campaign/add`}>
          <AddCampaignOffer/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/campaign/details/:id`}>
          <CampaignDetails/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/activity-log/:id`}>
          <ActivityLog/>
        </PrivateRoute>

         <PrivateRoute exact path={`${process.env.PUBLIC_URL}/notification/list`}>
          <NotificationList/>
        </PrivateRoute>


        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/promotions/campaign/edit/:id`}>
          <EditCampaignOffer/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/finance/commission-overview`}>
          <CommissionOverview/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/finance/buyer-commission`}>
          <BuyerCommission/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/finance/buyer-commission-transaction`}>
          <BuyerCommissionTransaction/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/finance/buyer-commission-order/:id`}>
          <BuyerCommissionOrder/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/finance/ekshop-commission-transaction`}>
          <EkshopCommissionTransaction/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/finance/seller-payment`}>
          <SellerPayment/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/finance/store-payment`}>
          <StorePayment/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/finance/delivery-charge`}>
          <DeliveryCharge/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/finance/delivery-charge-transaction`}>
          <DeliveryChargeTransaction/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/finance/wallet`}>
          <CommissionWallet/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/finance/ekshop-commission`}>
          <EkshopCommission/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/commissions`}>
          <AdminCommissions/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/commission-settings/general-settings`}>
          <GeneralCommissionSettings/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/commission-settings/custom-settings`}>
          <CustomCommissionSettings/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/commission-settings/custom-settings/add`}>
          <AddCustomCommissionSettings/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/commission-settings/custom-settings/edit/:id`}>
          <EditCustomCommissionSettings/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/reports/summary`}>
          <SummaryReport/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/reports/transactions`}>
          <TransactionReport/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/reports/order`}>
          <OrderReport/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/reports/customers`}>
          <CustomerReport/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/reports/seller`}>
          <SellerReport/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/reports/category`}>
          <CategoryReport/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/reports/commission`}>
          <CommissionReport/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/reports/new-user`}>
          <NewUserReport/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/reports/product`}>
          <ProductReport/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/reports/delivery`}>
          <DeliveryReport/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/reports/campaign`}>
          <CampaignReport/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/reports/district`}>
          <DistrictReport/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/reports/store`}>
          <StoreReport/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/reports/voucher`}>
          <VoucherReport/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/reports/wallet`}>
          <WalletReport/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/reports/search`}>
          <SearchReport/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/reports/return-and-refund`}>
          <ReturnAndRefundReport/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/reports/sales`}>
          <SalesReport/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/stores/list`}>
          <StoreList/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/stores/create`}>
          <CreateStore/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/stores/details/:id`}>
          <StoreDetails/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/stores/edit/:id`}>
          <EditStore/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/categories/list`}>
          <CategoryList/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/categories/add`}>
          <AddCategory/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/category/edit/:id`} component={EditCategory}>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/variants/list`}>
          <VariantList/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/collections`}>
          <Collections/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/collections/new`}>
          <CreateCollection/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/collections/edit/:id`}>
          <EditCollection/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/wallet/redeem-package`}>
          <WalletRedeemPackage/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/wallet/redeem-package/add`}>
          <AddWalletRedeemPackage/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/wallet/redeem-package/edit/:id`}>
          <EditWalletRedeemPackage/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/brands`}>
          <Brands/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/services`}>
          <Services/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/onboarding-screens`}>
          <OnboardingScreen/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/banners`}>
          <Banners/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/merchant-types`}>
          <MerchantTypes/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/variants/add`}>
          <AddVariant/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/variant/edit/:id`} component={EditVariant}>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/messages`}>
          <Messages/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/banks`}>
          <Banks/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/news`}>
          <News/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/acl-actions`}>
          <AclAction/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/acl-roles`}>
          <AclRole/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/stores/live`}>
          <Live/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/stores/create-live`}>
          <CreateLive/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/stores/edit-live/:id`}>
          <EditLive/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/agent`}>
          <Agent/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/agent/details/:id`}>
          <AgentDetails/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/partner-onboard/list`}>
          <PartnerOnboardList/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/partner-onboard/add`}>
          <CreatePartnerOnboard/>
        </PrivateRoute>
        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/partner-onboard-edit/:id`}>
          <EditPartnerOnboard/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/staff/list`}>
          <StaffList/>
        </PrivateRoute>
        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/staff/add`}>
          <StaffAdd/>
        </PrivateRoute>

        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/shipping-options`}>
          <ShippingOptions/>
        </PrivateRoute>


        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/menu-management`}>
          <MenuManagement/>
        </PrivateRoute>



        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/settings`}>
          <Settings/>
        </PrivateRoute>

        <Route exact path={`${process.env.PUBLIC_URL}/login`}>
          <Login/>
        </Route>

        <Route exact path="/support-ticket">
          <SupportTicket/>
        </Route>

        <Route exact path="/support-faq">
          <SupportFaq/>
        </Route>

        <Route exact path="/support-topic">
          <SupportTopic/>
        </Route>

        <Route exact path="/create-ticket">
          <CreateTicket/>
        </Route>

        <Route exact path="/support-ticket/:id">
          <TicketDetails/>
        </Route>

        <Route>
          <p>404 | Page not found</p>
          <p><Link to={`${process.env.PUBLIC_URL}/`}>Back to Home</Link></p>
        </Route>
      </Switch>
    </Router>

    <ToastContainer position="bottom-right" autoClose={3000}/>
  </Provider>
  , document.getElementById('root'));

serviceWorker.unregister();
