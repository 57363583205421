import React, {Component} from 'react';
import App from "../../App";
import {Button, Modal, Form, Spinner} from "react-bootstrap";
import Pagination from 'react-js-pagination';
import {FaFileExport, FiMessageSquare} from "react-icons/all";
import {Link} from "react-router-dom";
import "../../assets/scss/order-list.scss";
import axios from "axios";
import {API_BASE_URL} from "../../helper/env";
import {getDeviceType, getLocalAuthData} from "../../helper/utils";
import {toast} from "react-toastify";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import {getChildLocations} from "../../services/locationServices";
import {getStoreList} from "../../services/storeServices";
import {AsyncTypeahead} from "react-bootstrap-typeahead"
import {array} from "prop-types";
import {updateDepositPaymentStatus} from "../../services/orderServices";
import {LinearProgress} from "@material-ui/core";
import {
  JsonToCsv,
  useJsonToCsv
} from 'react-json-csv';
import {activityLogPostData, fileDownload} from "../../services/commonServices";
import {FaFilePdf} from "@react-icons/all-files/fa/FaFilePdf";
const { saveAsCsv } = useJsonToCsv();



class OrderParcelList extends Component {

    state = {
      orders: [],
      merchantType: [],
      storeList: [],
      queryData: {
        from_date: '',
        to_date: ''
      },
      activePage: 1,
      pagination: {},
      activeBlockTab: 'All',
      isLoading: true,
      locations: [],
      businessChildLocations: [],
      status: null,
      changingStoreId: null,
      changeStatusMsg: '',
      isShowingStatusChangeConfirmModal: false,
      showCancelMessageRequired: false,
      isSubmitting: false,
      selected_order_id: "",
      selected_deposit_info: {},
      depositInfoModal: false,
      isSubmittingDepositPaymentStatus: false,
      isLoadingQuery: false
    }


  handleBlockTab = (label) => {
    this.setState({
      activeBlockTab: label,
      queryData: {
        ...this.state.queryData,
        status: label !== "All" ? label : "",
        page: 1,
      },
      activePage: 1
    }, () => {
      this.getQueryData();
    });
  };

  componentDidMount() {

    this.setState({
      isLoadingQuery:true
    })

    const authToken = getLocalAuthData();
    const headers = {
      'Content-Type': 'application/json',
      'x-api-client': 'web',
      'x-auth-token': authToken.token,
    }
    axios.get(`${API_BASE_URL}/order-products`, {headers: headers})
      .then(res => {
        this.setState({orders: res.data.items, pagination: res.data.pagination, isLoading: false ,isLoadingQuery:false});
      })
      .catch(err => {
        this.setState({isLoading: false})
      });

    /*axios.get(`${API_BASE_URL}/merchantType`, {headers: headers})
      .then(res => {
        this.setState({merchantType: res.data.merchant_type_obj});
      })
      .catch(err => {
        console.log(err)
      });

    axios.get(`${API_BASE_URL}/location?type=Country`)
      .then(res => {
        this.setState({locations: res.data.locations});
      }).catch(e => console.log("Error"));*/
  }

  handlePageChange = async (pageNumber) => {
    await this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber});
    await this.getQueryData();
  }

  changeStatus = (statusObj, order_id, callback = () => null) => {
    const headers = {
      'Content-Type': 'application/json',
      'x-api-client': getDeviceType(),
      'x-auth-token': getLocalAuthData().token,
    }
    axios.patch(`${API_BASE_URL}/order/parcel/${order_id}/change-status`, statusObj,
      {headers: headers}
    )
      .then(response => {
        callback()
        toast.success(response.data.message);
      })
      .catch(error => {
        callback()
        toast.error('Something went wrong');
      });
  };

  getQueryData = () => {

    this.setState({isLoadingQuery: true})

    const headers = {
      'Content-Type': 'application/json',
      'x-api-client': 'web',
      'x-auth-token': getLocalAuthData().token,
    }
    let queryData = "";
    // eslint-disable-next-line array-callback-return
    Object.entries(this.state.queryData).map(([key, val]) => {
        queryData = queryData.concat(key + "=" + val + "&");
      }
    )
    axios.get(`${API_BASE_URL}/order-products?${queryData}`, {headers: headers})
      .then(res => {
        this.setState({isLoadingQuery: false,orders: res.data.items, pagination: res.data.pagination});
      }).catch(e => {});
  }

  handleSearchFormDataChange = (e) => {
    const merchant_type = e.target.name;
    const merchant_type_id = e.target.value;
    if (merchant_type === "merchant_type") {
      const headers = {
        'Content-Type': 'application/json',
        'x-api-client': getDeviceType(),
        'x-auth-token': getLocalAuthData().token,
      }
      axios.get(`${API_BASE_URL}/store?merchant_type=${merchant_type_id}&lite_data=1`, {headers: headers})
        .then(res => {
          this.setState({storeList: res.data.items});
        })
        .catch(err => {
          //console.log(err)
        });
    }
    this.setState({queryData: {...this.state.queryData, [e.target.name]: e.target.value}});
  };

  setQueryData = async (e) => {

     await this.setState({queryData: {...this.state.queryData, [e.target.name]: e.target.value}});
  }

  renderMerchantType = () => {
    const merchantType = this.state.merchantType;
    if (merchantType) {
      return (
        <>
          {merchantType.map((items, index) =>
            <option key={index} value={`${items._id}`}>{items.type_name}</option>
          )}
        </>
      )
    }
  };

  renderStoreList = () => {
    const storeList = this.state.storeList;
    if (storeList.length > 0) {
      return (
        storeList.map((items, index) =>
          <option key={index} value={`${items._id}`}>{items.store_name}</option>
        )
      )
    }
  };

  hideChangeStatusModal = () => {
    this.setState({isShowingStatusChangeConfirmModal: false})
  }

  submitChangeStatusForm = (e) => {
    e.preventDefault()
    if (this.state.changeStatusMsg === '' && this.state.status === 'Cancelled') {
      return this.setState({showCancelMessageRequired: true});
    }
    this.setState({isSubmitting: true, showCancelMessageRequired: false}, () => {
      this.changeStatus({
        status: this.state.status,
        message: this.state.changeStatusMsg
      }, this.state.changingStoreId, () => {

        const authToken = getLocalAuthData();
        const headers = {
          'Content-Type': 'application/json',
          'x-api-client': 'web',
          'x-auth-token': authToken.token,
        }

        axios.get(`${API_BASE_URL}/order-parcel`, {
          headers: headers,
          params: {
            page: this.state.pagination.page,
            status: this.state.activeBlockTab
          }
        })
          .then(res => {
            this.setState({orders: res.data.items, pagination: res.data.pagination, isLoading: false});
          })
          .catch(err => {
            this.setState({isLoading: false})
          });

        this.setState({
          isSubmitting: false,
          message: '',
          changingStoreId: null,
          isShowingStatusChangeConfirmModal: false
        })
      })
    })
  }

  handleLocationInputOnChange = (e, index, typePurpose) => {
    const childLocations = [...this.state[typePurpose]];
    if (typePurpose === 'businessChildLocations') {
      this.handleSearchFormDataChange(e)
    } else {
      this.handleSearchFormDataChange(e)
    }
    if (e.target.value !== '') {
      getChildLocations(e.target.value)
        .then(res => {
          childLocations.splice(index, childLocations.length - index);
          if (res.length > 0) {
            childLocations[index] = res
          }
          this.setState({[typePurpose]: childLocations})
        })
        .catch(errMsg => {
          //console.log(errMsg)
          childLocations.splice(index, childLocations.length - index)
          this.setState({[typePurpose]: childLocations})
        })
    } else {
      childLocations.splice(index, childLocations.length - index);
      this.setState({[typePurpose]: childLocations})
    }
  }

  renderLocations = () => {
    const locations = this.state.locations;
    if (locations) {
      return (
        <>
          {locations.map((items, index) =>
            <option value={`${items._id}`}>{items.name}</option>
          )}
        </>
      )
    }
  };

  handleOnApply = (e, picker) => {
    const queryData = {...this.state.queryData};

    queryData.from_date = moment(picker.startDate).format('DD/MM/YYYY');
    queryData.to_date = moment(picker.endDate).format('DD/MM/YYYY');

    this.setState({queryData}, () => {
      //console.log(this.state)
    })
  }

  renderPaymentStatus = (status) => {
    let payment_status = {
      0: "Unpaid",
      1: "Partially Paid",
      2: "Paid",
      3: "Cancelled",
      4: "Failed",
      5: "Refund",
      6: "Bank deposit payment in review",
      7: "Bank deposit declined",
    }
    return payment_status[status];
  }

  ShowStatusOption = (Status) => {
    let options = array;
    if (Status === "Delivered") {
      options = [{
        value: 'Delivered',
        label: 'Delivered'
      }
      ]
    } else if (Status === "Cancelled") {
      options = [{
        value: 'Cancelled',
        label: 'Cancelled'
      }
      ]
    } else {
      options = [{
        value: 'Pending',
        label: 'Pending',
      }, {
        value: 'Confirmed',
        label: 'Confirmed'
      }, {
        value: 'Processing',
        label: 'Processing'
      }, {
        value: 'Picked',
        label: 'Picked'
      }, {
        value: 'Shipped',
        label: 'Shipped'
      }, {
        value: 'Delivered',
        label: 'Delivered'
      }, {
        value: 'Cancelled',
        label: 'Cancelled'
      }
      ]
    }
    return options;
  }


  showDepositInfo = (order_id, deposit_info, payment_status) => {
    this.setState({
      selected_order_id: order_id,
      selected_deposit_info: deposit_info,
      depositInfoModal: true,
      current_payment_status: payment_status
    }, () => {
      //console.log(this.state.selected_order_id);
      //console.log(this.state.selected_deposit_info);
    });
  }

  updateDepositPaymentStatus = (order_id, payment_status) => {
    if (order_id && payment_status) {
      updateDepositPaymentStatus({order_id: order_id, payment_status: payment_status}).then(async (res) => {
        if (res.status === 1) {
          toast.success(res.message);

          /* RECALL LIST */
          await this.getQueryData();
          this.setState({depositInfoModal: false});

        } else {
          toast.error(res.message);
        }
      }).catch(function (e) {
        //console.log(e.message);
      });
    } else {
      //console.log("order Id or payment status missing");
    }
  }

  handleCloseDepositInfoModal = () => {
    this.setState({depositInfoModal: false});
  }

  renderSelectedShippingEstimatedTime = (shippingOptions = [], shipping_option) => {
    if (shippingOptions.length > 0) {
      let selected_shipping_option = shippingOptions.filter(obj => obj.shipping_option === shipping_option)
      if (selected_shipping_option?.length > 0) {
        return selected_shipping_option[0].estimated_time;
      }
    } else {
      return "";
    }
  }

  handleExportFile = async (fileType) => {
    this.setState({export_loading: true})

    fileDownload(`/order-products`, {export: 'xlsx'}).then((response)=>{
      console.log(response)
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'ekshop-order-products.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      this.setState({
        export_loading: false
      })
    })

    // let queryData = "";
    // let mData = [];
    //
    // Object.entries(this.state.queryData).forEach(([key, val]) => {
    //     queryData = queryData.concat(key + "=" + val + "&");
    //   }
    // )
    // await this.setState({isExporting: true});
    //
    // const authToken = getLocalAuthData();
    // const headers = {
    //   'Content-Type': 'application/json',
    //   'x-api-client': 'web',
    //   'x-auth-token': authToken.token,
    // }
    //
    // axios.get(`${API_BASE_URL}/order?export=${fileType}&${queryData}`,{headers:headers })
    //   .then(res => {
    //     const excelData = res.data.items;
    //     console.log(excelData);
    //
    //     excelData.map( (data , index) =>{
    //
    //       mData.push({
    //          'index': index,
    //          'Order Info' : data.order_code,
    //          'Order Date' : data.createdAt,
    //          'Customer Name' : data.customer_info.name,
    //          'Customer Number' : data.customer_info.number,
    //          'Customer Email' : data.customer_info.email,
    //          'Payment Method' : data.payment_method.name,
    //          'Total Order Parcel': data.total_order_parcels,
    //          'Status' : data.status,
    //          'Price' : data.grand_total
    //
    //       });
    //
    //     });
    //
    //
    //    const filename = 'EkShop-Parcel Order',
    //    fields = {
    //     'index': "Index",
    //     'Order Info' : "Order Info",
    //     'Order Date' : "Order Date",
    //     'Customer Name' : "Customer Name",
    //     'Customer Number' : "Customer Number",
    //     'Customer Email' : "Customer Email",
    //     'Total Order Parcel': "Total Order Parcel",
    //     'Payment Method' : "Payment Method",
    //     'Status' : "Status",
    //     'Price' : "Price"
    //    },
    //    style = {
    //      padding: "15px",
    //
    //    },
    //    data = mData
    //    ,
    //    text = "Convert Json to Csv";
    //    saveAsCsv({data, fields, filename});
    //    activityLogPostData('was export product order list ', '/product/list')
    //     this.setState({isExporting: false});
    //   }).catch(e => {});
  }

  handleExportFile2 = async (fileType) => {
    let queryData = "";
    let mData = [];

    Object.entries(this.state.queryData).forEach(([key, val]) => {
        queryData = queryData.concat(key + "=" + val + "&");
      }
    )
    await this.setState({isExporting: true});

    const authToken = getLocalAuthData();
    const headers = {
      'Content-Type': 'application/json',
      'x-api-client': 'web',
      'x-auth-token': authToken.token,
    }

    axios.get(`${API_BASE_URL}/order-products?${queryData}`,{headers:headers })
      .then(res => {
       

        const excelData = res.data.items;
        //console.log(excelData);

        excelData.map( (data , index) =>{

          mData.push({
             'index': index,
             'Order Date' : moment(data.createdAt).format('DD-MM-YYYY'),
             'Parcel Name' : data.product_info.name,
             'Attribute Combo' : data.product_sku_attr_combo.join('-'),
             'Quantity' : data.quantity,
             'Price' : data.price,
             'Discount' : data.discount,
             'Status' : data.status
          });

        });


       const filename = 'EkShop-Parcel Order Items',
       fields = {
         'index': "Index",
         'Order Date' : "Order Date",
         'Parcel Name' : "Parcel Name",
         'Attribute Combo' : "Attribute Combo",
         'Quantity' : "Quantity",
         'Price' : "Price",
         'Discount' : "Discount",
         'Status' : "Status"
       },
       style = {padding: "15px"}, data = mData,
       text = "Convert Json to Csv";
       saveAsCsv({data, fields, filename});


        this.setState({isExporting: false});
      }).catch(e => {});
  }

  handleSearch = (query) => {
    this.setState({isLoadingStoreData: true , isLoadingQuery: true}, () => {
      getStoreList({search_string: query, lite_data: 1})
          .then(res => {
            this.setState({storeList: res.items, isLoadingStoreData: false, isLoadingQuery:false});
          })
          .catch(errMsg => {
            this.setState({isLoadingStoreData: false})
          })
    })
  };


  handleOnCancel = (event, picker) => {
    console.log(event);
    console.log(event);
    picker.element.val('');
    const queryData = {...this.state.queryData};
    queryData.from_date = "";
    queryData.to_date = "";
    this.setState({queryData}, () => {
      // console.log(this.state)
    })
  };

  render() {
    return (
      <App>
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Order Product List</h2>
            <div className="ml-auto">

        {/*    <Button size={"sm"} variant={"defalut"} className="mb-0 ml-2"
                      onClick={() => this.handleExportFile2('xlsx')}><FaFileExport/>Export Product Report</Button>
*/}
              {getLocalAuthData()?.permission?.includes('order_product_list_export') ?

                  <Button variant="primary"
                          size={`sm`}
                          onClick={() => this.handleExportFile('xlsx')}
                          disabled={this.state.export_loading}>
                    {this.state.export_loading ?
                        <>
                          <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                          />
                          <span>Loading...</span>
                        </>
                        : <><FaFilePdf/><span>Export</span></>}
                  </Button>
                  :null}
            </div>
          </div>

          { this.state.isLoading ?
            <div className={`card-block`}>
              <div className="block-body">
                Loading...
              </div>
            </div>
            :
            <>
              {getLocalAuthData()?.permission?.includes('order_product_list') ?
                  <div className="card-block">
                    <ul className="desc-header-nav">
                      <li className={this.state.activeBlockTab === 'All' ? 'active' : ''} value="">
                        <span onClick={() => this.handleBlockTab('All')}>All</span>
                      </li>
                      <li className={this.state.activeBlockTab === 'Pending' ? 'active' : ''}>
                        <span onClick={() => this.handleBlockTab('Pending')}>Pending</span>
                      </li>
                      <li className={this.state.activeBlockTab === 'Confirmed' ? 'active' : ''}>
                        <span onClick={() => this.handleBlockTab('Confirmed')}>Confirm</span>
                      </li>
                      <li className={this.state.activeBlockTab === 'Processing' ? 'active' : ''}>
                        <span onClick={() => this.handleBlockTab('Processing')}>Processing</span>
                      </li>
                      <li className={this.state.activeBlockTab === 'Picked' ? 'active' : ''}>
                        <span onClick={() => this.handleBlockTab('Picked')}>Picked</span>
                      </li>
                      <li className={this.state.activeBlockTab === 'Shipped' ? 'active' : ''}>
                        <span onClick={() => this.handleBlockTab('Shipped')}>Shipped</span>
                      </li>
                      <li className={this.state.activeBlockTab === 'Delivered' ? 'active' : ''}>
                        <span onClick={() => this.handleBlockTab('Delivered')}>Delivered</span>
                      </li>
                      <li className={this.state.activeBlockTab === 'Cancelled' ? 'active' : ''}>
                        <span onClick={() => this.handleBlockTab('Cancelled')}>Cancelled</span>
                      </li>
                    </ul>

                    <div className="block-body">
                      <form className="form-inline mb-3">

                        {/*<div className="form-group mr-3 mt-2">
                    <label htmlFor="status" className="sr-only">Locations</label>
                    <select id="status" name="country" className='form-control form-control-sm'
                            onChange={(e) => this.handleLocationInputOnChange(e, 0, 'businessChildLocations')}>
                      <option value="">--Select Country--</option>
                      {this.renderLocations()}
                    </select>
                  </div>*/}

                        {/*{this.state.businessChildLocations.length > 0 &&
                  this.state.businessChildLocations.map((location, index) => (
                    <div className="form-group mr-3 mt-2" key={index}>
                      <label htmlFor={`location${location[0].type}`}
                             className="sr-only">{location[0].type}</label>
                      <select id={`location${location[0].type}`}
                              name={location[0].type.toLowerCase()}
                              className='form-control form-control-sm'
                              onChange={(e) => this.handleLocationInputOnChange(e, index + 1, 'businessChildLocations')}>
                        <option value="">--Select--</option>
                        {location.map((item, index) => (
                          <option key={index} value={item._id}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  ))
                  }*/}

                        {/* <div className="form-group mr-3 mt-2">
                    <label htmlFor="barndName" className="sr-only">Merchant Type</label>
                    <select id="merchantType" name="merchant_type"
                            onChange={this.handleSearchFormDataChange}
                            className='form-control form-control-sm'>
                      <option value="">--Select Type--</option>
                      {this.renderMerchantType()}
                    </select>
                  </div>*/}

                        {/*{this.state.storeList?.length > 0 &&
                  <div className="form-group mr-3 mt-2">
                    <label htmlFor="storeId" className="sr-only">Store</label>
                    <select
                      id="storeId" name="store_id"
                      className='form-control form-control-sm'
                      onChange={this.handleSearchFormDataChange}>
                      <option value="">--Select Store--</option>
                      {this.renderStoreList()}
                    </select>
                  </div>}*/}


                        <div className="form-group mr-3">
                          <label htmlFor="inputStoreList" className={'sr-only'}>Store List</label>
                          <div className="position-relative">
                            <AsyncTypeahead
                                filterBy={() => true}
                                id="inputStoreList"
                                isLoading={this.state.isLoadingStoreData}
                                labelKey="store_name"
                                minLength={3}
                                size={'sm'}
                                onSearch={this.handleSearch}
                                options={this.state.storeList}
                                placeholder="Search store..."
                                renderMenuItemChildren={(store, props) => (
                                    <React.Fragment>
                          <span onClick={() => {
                            this.setState({queryData: {...this.state.queryData, 'store_id': store._id}});
                          }}>{store.store_name}</span>
                                    </React.Fragment>
                                )}
                            />
                          </div>
                        </div>


                        {/*<div className="form-group mr-3 mt-2">
                    <label htmlFor="paymentStatus" className="sr-only">Payment Status</label>
                    <select id="paymentStatus" name="order_payment_status"
                            onChange={this.setQueryData}
                            className='form-control form-control-sm'>
                      <option value="">--Select Payment Status--</option>
                      <option value="0">Unpaid</option>
                      <option value="1">Partially Paid</option>
                      <option value="2">Paid</option>
                      <option value="3">Online Payment Canceled</option>
                      <option value="4">Online Payment Failed</option>
                      <option value="5">Refund</option>
                    </select>
                  </div>*/}

                        {/*<div className="form-group mr-3 mt-2">
                    <label htmlFor="paymentMethod" className="sr-only">Payment Method</label>
                    <select id="paymentMethod" name="payment_method"
                            onChange={this.setQueryData}
                            className='form-control form-control-sm'>
                      <option value="">--Select Payment Method--</option>
                      <option value="COD">COD</option>
                      <option value="online_payment">Online</option>
                      <option value="bank_deposit">Bank Deposit</option>
                    </select>
                  </div>*/}

                        {/*<div className="form-group mr-3 mt-2">
                    <label htmlFor="campaignOrder" className="sr-only">Product </label>
                    <select id="campaignOrder" name="campaign_order"
                            onChange={this.setQueryData}
                            className='form-control form-control-sm'>
                      <option value="">--Filter Campaign Order--</option>
                      <option value="all">All Orders</option>
                      <option value="true">Campaign Orders</option>
                      <option value="false">Regular Orders</option>
                    </select>
                  </div>*/}

                        {/*<div className="form-group mr-3 mt-2">
                    <label htmlFor="orderParcelCode" className="sr-only">Order Number</label>
                    <input type="search" id="orderParcelCode" className='form-control form-control-sm'
                           placeholder="Order Number" name="order_parcel_code"
                           onChange={this.handleSearchFormDataChange}/>
                  </div>*/}

                        {/*<div className="form-group mr-3 mt-2">
                    <label htmlFor="customerSearch" className="sr-only">Customer</label>
                    <input type="search" id="customerSearch" name="customer_search"
                           onChange={this.handleSearchFormDataChange}
                           className='form-control form-control-sm'
                           placeholder="Customer"/>
                  </div>*/}

                        {/*<div className="form-group mr-3 mt-2">
                    <label htmlFor="shopSKU" className="sr-only">Product</label>
                    <input type="search" id="product" name="product_search"
                           onChange={this.handleSearchFormDataChange}
                           className='form-control form-control-sm'
                           placeholder="Product"/>
                  </div>*/}

                        <div className="form-group mr-3 mt-2">
                          <DateRangePicker
                              initialSettings={{
                                autoUpdateInput: false,
                                locale: {
                                  cancelLabel: 'Clear',
                                },
                              }}
                              containerStyles={{
                                textAlign: 'left',
                                width: '100%',
                                display: 'block'
                              }}
                              onCancel={(e, picker)=>this.handleOnCancel(e, picker)}
                              onApply={this.handleOnApply}>
                            <input type="button"
                                   placeholder={`Select date range`}
                                   value={(this.state.queryData.from_date && this.state.queryData.to_date) ? `${this.state.queryData.from_date} - ${this.state.queryData.to_date}` : 'Select date range'}
                                   style={{textAlign: 'left', minWidth: '150px'}}
                                   className="form-control form-control-sm"/>
                          </DateRangePicker>
                        </div>

                        <Button className="mt-2" size="sm" variant="secondary" onClick={this.getQueryData}>Search</Button>
                      </form>

                      {this.state.orders?.length > 0 ?
                          <>
                            <Modal show={this.state.isShowingStatusChangeConfirmModal} onHide={this.hideChangeStatusModal}>
                              <Modal.Header closeButton>
                                <Modal.Title>Change Status Message</Modal.Title>
                              </Modal.Header>
                              <Form onSubmit={this.submitChangeStatusForm}>
                                <Modal.Body>
                                  {this.state.status === 'Cancelled' ?
                                      <Form.Control as={`textarea`}
                                                    onChange={(e) => {
                                                      this.setState({changeStatusMsg: e.target.value})
                                                    }}
                                                    placeholder={`Enter you message here...`} rows={3}/>
                                      : <span><h5>Are you sure you want to change the order status?</h5></span>}
                                  {this.state.status === 'Cancelled' && this.state.showCancelMessageRequired ?
                                      <span><h5 style={{'color': "red"}}><i>Cancel message is required!</i></h5></span>
                                      : ''}
                                </Modal.Body>
                                <Modal.Footer>
                                  {this.state.status === 'Cancelled' && this.state.showCancelMessageRequired ?
                                      <>
                                        <Button variant="default" onClick={this.hideChangeStatusModal}>
                                          Close
                                        </Button>
                                        <Button disabled={this.state.isSubmitting}
                                                variant="primary" type={`submit`}>
                                          {this.state.isSubmitting ? 'Submitting...' : 'Submit'}
                                        </Button>
                                      </>
                                      :
                                      <>
                                        <Button variant="default" onClick={this.hideChangeStatusModal}>
                                          No
                                        </Button>
                                        <Button disabled={this.state.isSubmitting}
                                                variant="primary" type={`submit`}>
                                          {this.state.isSubmitting ? 'Submitting...' : 'Yes'}
                                        </Button>
                                      </>
                                  }
                                </Modal.Footer>
                              </Form>
                            </Modal>

                            {this.state.isLoadingQuery && <LinearProgress/>}

                            <div className="table-responsive">
                              <table className="order-table table table-striped">
                                <thead>
                                <tr>
                                  <th style={{width: '1%'}}>#</th>
                                  <th>Product Info</th>
                                  <th>Order Time</th>
                                  <th>Price</th>
                                  <th>Quantity</th>
                                  <th>Discount Amount</th>
                                  <th>Grand Total</th>
                                  <th style={{width: '3%'}}>Status</th>
                                  <th style={{width: '10%'}}/>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.orders.map((item, index) => {
                                  return (
                                      <tr key={this.state.pagination.pagingCounter + index}>
                                        <td>{index + 1}</td>
                                        <td>
                                          <div className="order-item">
                                            <h4 className="order-id">
                                              <Link
                                                  to={`${process.env.PUBLIC_URL}/products/details/${item?.product_id}`}
                                                  target={'_blank'}>{item?.product_info?.name}</Link>
                                            </h4>
                                            <span className="order-date">{item?.product_sku_attr_combo?item?.product_sku_attr_combo?.join('-'):''}</span>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="order-item">
                                            <span className="order-date">{moment(item.createdAt).format(`ll`)}</span>
                                            <span className="order-time">{moment(item.createdAt).format(`LT`)}</span>
                                          </div>
                                        </td>
                                        <td>৳{item?.price?.toFixed(2)}</td>
                                        <td>{item?.quantity}</td>
                                        <td>৳{item?.item_discount_amount?.toFixed(2)}</td>
                                        <td>৳{item?.item_grand_total?.toFixed(2)}</td>
                                        <td>{item?.status}</td>
                                        <td>
                                          <Link to={`${process.env.PUBLIC_URL}/orders/parcel/${item?.order_parcel_id}`}
                                                className="btn btn-block btn-sm btn-outline-info" target={'_blank'}>View</Link>
                                          <Link to={`${process.env.PUBLIC_URL}/orders/invoice/${item.order_id}`}
                                                className="btn btn-block btn-sm btn-outline-info" target={'_blank'}>Invoice</Link>

                                          {(item?.payment_method?.name === "Bank Payment") && <button
                                              onClick={() => this.showDepositInfo(item?.order_id, item?.deposit_info, item?.payment_status, item?.payment_status)}
                                              className="btn btn-block btn-sm btn-outline-info" target={'_blank'}>Deposit Info</button>}

                                        </td>
                                      </tr>
                                  );
                                })}

                                </tbody>
                              </table>
                            </div>


                            <div className="mt-3">
                              <Pagination
                                  itemClass="page-item"
                                  linkClass="page-link"
                                  activePage={this.state.pagination?.page}
                                  itemsCountPerPage={this.state.pagination?.limit}
                                  totalItemsCount={this.state.pagination?.totalDocs}
                                  onChange={this.handlePageChange.bind(this)}/>
                            </div>

                          </>
                          :
                          <div className={`alert alert-warning text-center text-bold`}>
                            No order found
                          </div>
                      }
                    </div>
                  </div>:null}
            </>
          }

        </div>
      </App>
    );
  }
}

export default OrderParcelList;
