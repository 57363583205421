import React, {Component} from 'react';
import App from "../../App";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import BrowseFiles from "../../components/BrowseFiles";
import Service from "../../components/Service";
import axios from "axios";
import config from "../../helper/env";
import {ToastsContainer, ToastsStore} from "react-toasts";
import {toast} from "react-toastify";
import {getDeviceType, getLocalAuthData} from "../../helper/utils";
import {getCategoryList} from "../../services/categoryServices";
import { getMerchantType } from '../../services/merchantTypeServices';
import { getCampaignList } from '../../services/promotionServices';
import { withRouter } from 'react-router';
import {activityLogPostData} from "../../services/commonServices";

class Services extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bannerArray:[],
      formData: {},
      serviceData: [],
      editFormData: null,
      editStatus: false,
      categories:'',
      isFormValidated: true,
      merchantTypes: [],
      campaignList: [],
      formSubmitError: ""
    }
  }


  componentDidMount() {
    getCategoryList('service').then(res => {
      this.setState({categories: res})
    });
    this.loadMerchantTypes();
    this.loadCampaignList();

    axios.get(`${config.API_BASE_URL}/service`)
      .then(res => {
        console.log(res.data.items);
        this.setState({serviceData: res.data.items});
      }).catch(e => this.setState({error: true}));
  }

  renderData = () => {
    axios.get(`${config.API_BASE_URL}/service`)
      .then(res => {
        console.log(res.data.items);
        this.setState({serviceData: res.data.items});
      }).catch(e => this.setState({error: true}));
  }

  handleImage = (files) => {
    this.setState({
      formData: {
        ...this.state.formData,
        image: files[0].actual_img
      }
    });
  }

  handleImageOnBrowse = (images, type = '') => {
    const formData = {...this.state.formData}
    var array = [];
    console.log(images[0].actual_img);
    images.map((data,i)=>{
      array.push({
        img: data.actual_img
      })
    })
    formData.banner = array
    this.setState({
      bannerArray: array
    })
    this.setState({formData}, () => {
      console.log(formData);
    })
  }


  loadMerchantTypes = () => {
    getMerchantType()
      .then(res => {
        this.setState({merchantTypes: res})
      })
      .catch(errMsg => {
        console.error(errMsg)
      })
  }

  loadCampaignList = () =>{
    getCampaignList()
      .then(res => this.setState({campaignList: res.items}))
      .catch(err=> console.log("Error",err))
  }

  handleFormDataChange = (e) => {
    this.setState({formSubmitError: ""});
    if(e.target.name === "merchant_type" || e.target.name === "campaign"){
      const formData = {...this.state.formData};
      const settings = {
        params:{
          [e.target.name] : e.target.value
        }
      }
      this.setState({formData: {...this.state.formData, settings}})
    }else if(this.state.editStatus && e.target.name === "is_premium" && e.target.value == 0){
      debugger;
      this.setState({formData: {...this.state.formData, service_type: "", settings: {}, [e.target.name]: e.target.value}})
    }else{
      this.setState({formData: {...this.state.formData, [e.target.name]: e.target.value}}, ()=>{
        console.log(this.state.formData);
      });
    }
  };

  deleteService = (id) => {
    const authData = getLocalAuthData()
    axios.delete(`${config.API_BASE_URL}/service/${id}/permanent/`, {
      headers: {
        'x-auth-token': authData.token,
        'x-api-client': getDeviceType()
      }
    }) //Permanent delete
      .then(response => {
        if (response.data.status === 1) {
          toast.success(response.data.message);
          activityLogPostData('was delete this service','/services',id)
          this.renderData();
        }
        if (response.data.status === 2) {
          toast.error(response.data.message);
        }
      }).catch(error => {
        toast.error("Error");
    });
  };

  editService = (id) => {
    axios.get(`${config.API_BASE_URL}/service/${id}`)
      .then(res => {
        console.log('91', res.data);
        this.setState({
          formData: res.data.service,
          editStatus: true
        });
        activityLogPostData('was edit this service','/services',this.state.formData.name)
      }).catch(error => {
      toast.error("Error");
    });
  };

  renderService = () => {
    const serviceList = this.state.serviceData;
    if (serviceList) {
      return (
        <Row>
          {serviceList.map((service, index) => {
              return (
                <Col md={3}>
                  <Service
                    imageLink={service.image}
                    serviceId={service._id}
                    deleteService={() => this.deleteService(service._id)}
                    editService={() => this.editService(service._id)}
                  />
                </Col>
              )
            }
          )}
        </Row>
      )
    }

    return (
      <Form.Control as="select">
        <option>Fetching Service</option>
      </Form.Control>
    )
  };

  handleSubmitForm = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false || !this.state.formData?.image) {
      this.setState({isFormValidated: false, formSubmitError: "Please fill the required field"});
      window.scrollTo({top: 0, behavior: 'smooth'})
      e.stopPropagation();
    }else{
      console.log("Inside", this.state.formData)
      this.setState({isFormValidated: true, formSubmitError: ""})
      const data = this.state.formData;
      let url = `${config.API_BASE_URL}/service`;
      if (this.state.editStatus === true) {
        url = `${config.API_BASE_URL}/service/` + this.state.formData._id;
        axios.put(url, data)
          .then(response => {
            if (response.data.status === 1) {
              toast.success(response.data.message);
              activityLogPostData('was create new service','/services',this.state.formData.name)
              this.setState({formData: {}})
              form.reset();
              this.renderData();
            }
            if (response.data.status === 2) {
              toast.error(response.data.message);
            }
          }).catch(error => {
            toast.error("Error");
          });
      } else {
        axios.post(url, data)
          .then(response => {
            if (response.data.status === 1) {
              toast.success(response.data.message);
              activityLogPostData('was create new service','/services',this.state.formData.name)
              this.setState({formData: {}})
              form.reset();
              this.renderData();
            }
            if (response.data.status === 2) {
              toast.error(response.data.message);
            }
          }).catch(error => {
            toast.error("Error");
          });
      }
    }

  };

  render() {
    console.log("formData", this.state.formData)
    return (
      <App layout={"boxed"}>
        <div className="page-header">
          <h2 className="page-title">Add Service</h2>
        </div>

        <div className="main-content">

          {getLocalAuthData()?.permission?.includes('service_add') ?
              <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Service Details</h4>
            </div>

            <div className="block-body">
              {
                this.state.formSubmitError &&
                  <Alert variant={"danger"}>{this.state.formSubmitError}</Alert>
              }

              <Form noValidate className={this.state.isFormValidated? "": " was-validated"} onSubmit={(e)=>this.handleSubmitForm(e)} id={"serviceForm"}>
                <Form.Group as={Row} controlId="serviceName">
                  <Form.Label column sm="3">
                    Service Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control type={"text"}
                                  required
                                  value={this.state.formData?.name}
                                  name="name" onChange={this.handleFormDataChange}
                                  placeholder={"Service name"}/>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="serviceName">
                  <Form.Label column sm="3">
                    Service Name Local <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control type={"text"}
                                  required
                                  value={this.state.formData?.name_local ? this.state.formData?.name_local:''}
                                  name="name_local" onChange={this.handleFormDataChange}
                                  placeholder={"Service name local"}/>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="serviceName">
                  <Form.Label column sm="3">
                    Service Link <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        type={"text"}
                        required
                        name="link"
                        onChange={this.handleFormDataChange}
                        placeholder={"Service link"}
                        value={this.state.formData?.link}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="serviceName">
                  <Form.Label column sm="3">
                    Service Image <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <BrowseFiles
                        onGetFiles={this.handleImage}
                        image_category={'service'}
                        helpText={'Image size 300 x 300'}
                        images={this.state?.formData?.image ? [this.state?.formData?.image] : ''}
                        onRemoveItem={() => {
                          const newFormData = {...this.state.formData}
                          delete newFormData.image
                          this.setState({formData: newFormData})
                        }}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formImages">
                  <Form.Label column sm="3">
                    Service Banner
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <BrowseFiles
                        image_category={'banner'}
                        onRemoveItem={() => {
                          const newFormData = {...this.state.formData}
                          delete newFormData.banner
                          this.setState({formData: newFormData})
                        }}
                        helpText={'Image size 1920 x 430'}
                        /*images={this.state.formData.banner[0]?.img ? [{thumb_img: this.state.formData.banner[0]?.img}] : []}*/
                        onGetFiles={this.handleImageOnBrowse}/>
                    <>
                      {this.state?.bannerArray.map((data, index)=>{
                        return(
                            <img style={{marginLeft:'7px'}} width={`40px`} height={`40px`} src={data?.img} alt={``}/>
                        )
                      })}
                    </>
                  </Col>
                </Form.Group>



                {/* Banner multiple image */}
                {/* <Form.Group as={Row} controlId="serviceName">
                  <Form.Label column sm="3">
                    Banner Images <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <BrowseFiles
                        onGetFiles={this.handleImage}
                        image_category={'service'}
                        helpText={'Multiple images can be uploaded at once.Maximum 8 pictures. Image size 1920 x 540'}
                        images={this.state?.formData?.image ? [this.state?.formData?.image] : ''}
                        onRemoveItem={() => {
                          const newFormData = {...this.state.formData}
                          delete newFormData.image
                          this.setState({formData: newFormData})
                        }}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formImages">
                          <Form.Label column sm="3">
                            Images <span className="text-danger">*</span>
                          </Form.Label>
                          <Col sm="9" md={8} lg={7}>
                            <BrowseFiles
                                userId={this.state.merchant}
                                image_category={'product'}
                                onRemoveItem={this.handleImageOnRemove}
                                images={this.state.mainImages}
                                onGetFiles={this.handleAddProductImages}
                                helpText="Multiple images can be uploaded at once.Maximum 8 pictures. Size between 330x330 and 800x800 px. Obscene image is strictly prohibited."/>
                          </Col>
                        </Form.Group> */}


                <hr/>
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Is Premium <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Check
                        custom
                        required
                        inline
                        type="radio"
                        name="is_premium"
                        id={`is_premium_yes`}
                        onChange={this.handleFormDataChange}
                        value={1}
                        label={`Yes`}
                        checked={this.state.formData?.is_premium == 1}
                    />
                    <Form.Check
                        custom
                        required
                        inline
                        type="radio"
                        name="is_premium"
                        id={`is_premium_no`}
                        onChange={this.handleFormDataChange}
                        value={0}
                        label={`No`}
                        checked={this.state.formData?.is_premium == 0}
                    />
                  </Col>
                </Form.Group>


                {this.state.formData?.is_premium == 1 &&
                <>
                  <Form.Group as={Row} controlId="serviceType">
                    <Form.Label column sm="3">
                      Service Type <span className="text-danger">*</span>
                    </Form.Label>

                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                          required
                          onChange={this.handleFormDataChange}
                          name={"service_type"} as={"select"}
                          value={this.state.formData?.service_type}>
                        <option value="">--Select Service Type--</option>
                        <option value={"shop_mall"}>Shopping Mall</option>
                        <option value={"campaigns"}>Campaigns</option>
                        <option value={"promotions"}>Promotions</option>
                        <option value={"campaign_details"}>Campaign Details</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>

                  {this.state.formData?.service_type && this.state.formData?.service_type === 'shop_mall' &&
                  <Form.Group as={Row} controlId="category">
                    <Form.Label column sm="3">
                      Merchant Type <span className="text-danger">*</span>
                    </Form.Label>

                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                          required
                          onChange={this.handleFormDataChange}
                          name={"merchant_type"} as={"select"}
                          value={this.state.formData?.settings?.params?.merchant_type}>
                        <option value="">--Select Merchant Type--</option>
                        {this.state.merchantTypes?.length > 0 &&
                        this.state.merchantTypes.map((item, index) => (
                            <option key={index} value={item._id}>{item.type_name}</option>
                        ))
                        }
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  }

                  {this.state.formData?.service_type && this.state.formData?.service_type === 'campaign_details' &&
                  <Form.Group as={Row} controlId="category">
                    <Form.Label column sm="3">
                      Campaign <span className="text-danger">*</span>
                    </Form.Label>

                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                          required
                          onChange={this.handleFormDataChange}
                          name={"campaign_slug"} as={"select"}
                          value={this.state.formData?.settings?.params?.campaign_slug}>
                        <option value="">--Select Campaign--</option>
                        {this.state.campaignList?.length > 0 &&
                        this.state.campaignList.map((item, index) => (
                            <option key={index} value={item.campaign_slug}>{item.campaign_title}</option>
                        ))
                        }
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  }
                </>
                }


                <hr/>


                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Show As Service <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Check
                        custom
                        required
                        inline
                        type="radio"
                        name="show_as_service"
                        id={`show-as-service`}
                        onChange={this.handleFormDataChange}
                        value={1}
                        label={`Yes`}
                        checked={this.state.formData?.show_as_service == 1}
                    />
                    <Form.Check
                        custom
                        required
                        inline
                        type="radio"
                        name="show_as_service"
                        id={`show-as-service-no`}
                        onChange={this.handleFormDataChange}
                        value={0}
                        label={`No`}
                        checked={this.state.formData?.show_as_service == 0}
                    />
                  </Col>
                </Form.Group>



                {(this.state.formData?.show_as_service === "1" || this.state.formData?.show_as_service === 1) && <Form.Group as={Row} controlId="serviceName">
                  <Form.Label column sm="3">
                    Service Code <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control type={"text"}
                                  required
                                  value={this.state.formData?.service_code}
                                  name="service_code" onChange={this.handleFormDataChange}
                                  placeholder={"Service Code"}/>
                  </Col>
                </Form.Group>}


                {(this.state.formData?.show_as_service === "1" || this.state.formData?.show_as_service === 1) &&
                <>
                  <Form.Group as={Row} controlId="category">
                    <Form.Label column sm="3">
                      Category {this.state.formData?.show_as_service === "1" && <span className="text-danger">*</span>}
                    </Form.Label>

                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                          required = {this.state.formData?.show_as_service === "1"? true: false}
                          onChange={this.handleFormDataChange}
                          name={"category"} as={"select"}
                          value={this.state.formData?.category}>
                        <option value="">--Select Category--</option>
                        {this.state.categories.length > 0 &&
                        this.state.categories.map((cat, index) => <option key={index} value={cat._id}>{cat.name}</option>)
                        }
                      </Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="serviceIframe">
                    <Form.Label column sm="3">
                      Service Iframe Link
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                          type={"text"}
                          name="service_iframe_link"
                          onChange={this.handleFormDataChange}
                          placeholder={"Service Iframe Link"}
                          value={this.state.formData?.service_iframe_link}
                      />
                    </Col>
                  </Form.Group>

                </>
                }

                <Form.Group as={Row} controlId="serviceName">
                  <Form.Label column sm="3"/>
                  <Col sm="9" md={8} lg={7}>
                    <Button
                        variant={"primary"}
                        type="submit"
                    >Save</Button>
                  </Col>
                </Form.Group>
              </Form>
            </div>
          </div> : null}

          <div className="card-block">

            <div className="block-header">
              <h4 className="block-title">Service List</h4>
            </div>

            {getLocalAuthData()?.permission?.includes('service_list') ? <div className="block-body">
              {this.renderService()}
            </div> :null}

          </div>

        </div>
        <ToastsContainer store={ToastsStore}/>
      </App>
    );
  }
}

export default withRouter(Services);
