import React from 'react';
import {Link} from "react-router-dom";
import {
  AiOutlineOrderedList, AiOutlinePercentage,
  AiOutlineTransaction,
  RiCustomerService2Line,
  RiProductHuntLine,
  GiStorkDelivery,
  SiCampaignmonitor,
  FaFileInvoiceDollar,
  AiOutlineUserSwitch, BiSearchAlt, FiBox,
  FiTag, FiUserPlus,
  HiOutlineClipboardList
} from "react-icons/all";
import "../assets/scss/report-grid.scss";
import {BiWalletAlt} from "@react-icons/all-files/bi/BiWalletAlt";

const ReportGrid = () => {
  return (
    <div className={'nav-item-grid'}>
      <div className="nav-col">
        <Link className={'nav-item-box'} to={'/reports/transactions'}>
                  <span className="nav-icon">
                    <AiOutlineTransaction/>
                  </span>
          <span className="nav-text">Transaction Reports</span>
        </Link>
      </div>
      
      <div className="nav-col">
        <Link className={'nav-item-box'} to={'/reports/customers'}>
                  <span className="nav-icon">
                    <RiCustomerService2Line/>
                  </span>
          <span className="nav-text">Customer Reports</span>
        </Link>
      </div>

      <div className="nav-col">
        <Link className={'nav-item-box'} to={'/reports/order'}>
                  <span className="nav-icon">
                    <AiOutlineOrderedList/>
                  </span>
          <span className="nav-text">Orders Reports</span>
        </Link>
      </div>

      <div className="nav-col">
        <Link className={'nav-item-box'} to={'/reports/product'}>
                  <span className="nav-icon">
                    <RiProductHuntLine/>
                  </span>
          <span className="nav-text">Product Reports</span>
        </Link>
      </div>

      <div className="nav-col">
        <Link className={'nav-item-box'} to={'/reports/delivery'}>
                  <span className="nav-icon">
                  <GiStorkDelivery/>
                  </span>
          <span className="nav-text">Delivery Reports</span>
        </Link>
      </div>

      <div className="nav-col">
        <Link className={'nav-item-box'} to={'/reports/campaign'}>
                  <span className="nav-icon">
                  <SiCampaignmonitor/>
                    {/* <FiBox/> */}
                  </span>
          <span className="nav-text">Campaign Reports</span>
        </Link>
      </div>

      <div className="nav-col">
        <Link className={'nav-item-box'} to={'/reports/store'}>
                  <span className="nav-icon">
                    <FiBox/>
                  </span>
          <span className="nav-text">Store Reports</span>
        </Link>
      </div>

      <div className="nav-col">
        <Link className={'nav-item-box'} to={'/reports/voucher'}>
                  <span className="nav-icon">
                  <FaFileInvoiceDollar/>
                  </span>
          <span className="nav-text">Voucher Reports</span>
        </Link>
      </div>

      <div className="nav-col">
        <Link className={'nav-item-box'} to={'/reports/wallet'}>
                  <span className="nav-icon">
                  <BiWalletAlt/>
                  </span>
          <span className="nav-text">Wallet Reports</span>
        </Link>
      </div>

      {/*<div className="nav-col">*/}
      {/*  <Link className={'nav-item-box'} to={'/reports/summary'}>*/}
      {/*            <span className="nav-icon">*/}
      {/*              <HiOutlineClipboardList/>*/}
      {/*            </span>*/}
      {/*    <span className="nav-text">Summery Reports</span>*/}
      {/*  </Link>*/}
      {/*</div>*/}

      {/*<div className="nav-col">*/}
      {/*  <Link className={'nav-item-box'} to={'/reports/seller'}>*/}
      {/*            <span className="nav-icon">*/}
      {/*              <AiOutlineUserSwitch/>*/}
      {/*            </span>*/}
      {/*    <span className="nav-text">Seller Reports</span>*/}
      {/*  </Link>*/}
      {/*</div>*/}

      {/*<div className="nav-col">*/}
      {/*  <Link className={'nav-item-box'} to={'/reports/category'}>*/}
      {/*            <span className="nav-icon">*/}
      {/*              <FiTag/>*/}
      {/*            </span>*/}
      {/*    <span className="nav-text">Category Reports</span>*/}
      {/*  </Link>*/}
      {/*</div>*/}

      {/*<div className="nav-col">*/}
      {/*  <Link className={'nav-item-box'} to={'/reports/commission'}>*/}
      {/*            <span className="nav-icon">*/}
      {/*              <AiOutlinePercentage/>*/}
      {/*            </span>*/}
      {/*    <span className="nav-text">Commission Reports</span>*/}
      {/*  </Link>*/}
      {/*</div>*/}

      {/*<div className="nav-col">*/}
      {/*  <Link className={'nav-item-box'} to={'/reports/search'}>*/}
      {/*            <span className="nav-icon">*/}
      {/*              <BiSearchAlt/>*/}
      {/*            </span>*/}
      {/*    <span className="nav-text">Search Reports</span>*/}
      {/*  </Link>*/}
      {/*</div>*/}

      {/*<div className="nav-col">*/}
      {/*  <Link className={'nav-item-box'} to={'/reports/new-user'}>*/}
      {/*            <span className="nav-icon">*/}
      {/*              <FiUserPlus/>*/}
      {/*            </span>*/}
      {/*    <span className="nav-text">New User Reports</span>*/}
      {/*  </Link>*/}
      {/*</div>*/}
    </div>
  );
};

export default ReportGrid;