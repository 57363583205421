import React, {Component} from 'react';
import App from "../../App";
import {Link, withRouter} from "react-router-dom";
import {Button, Form, Modal} from "react-bootstrap";
import {deleteCustomerPermanently, getCustomerDetails} from "../../services/customerServices";
import {getOrderList} from "../../services/orderServices";
import Moment from "react-moment";
import {FiMessageSquare} from "react-icons/all";
import moment from "moment";
import Pagination from "react-js-pagination";
import {array} from "prop-types";
import {getDeviceType, getLocalAuthData} from "../../helper/utils";
import axios from "axios";
import {API_BASE_URL} from "../../helper/env";
import {toast} from "react-toastify";
import {getChildLocations} from "../../services/locationServices";
import {confirmAlert} from "react-confirm-alert";

class CustomerDetails extends Component {
  state = {
    activeBlockTab: 'Orders',
    userData: {},
    queries: {
      limit: 20
    },
    orderList: [],
    pagination: {},
    isDataLoading: true
  };

  componentDidMount() {
    const customerId = this.props.match.params.id

    getCustomerDetails(customerId)
      .then(res => {
        this.setState({userData: res.customer, isDataLoading: false}, () => {
          console.log('dd', this.state)
        })
      })
      .catch(err => {
        alert(err)
        this.setState({isDataLoading: false})
      })

    getOrderList({...this.state.queries, customer: customerId})
      .then(res => {
        console.log('order list: ', res)
        this.setState({orderList: res.items, pagination: res.pagination})
      })
      .catch(errMsg => {
        console.log(errMsg)
      })
  }

  handlePageChange = async (pageNumber) => {
    await this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber});
    await this.getQueryData();
  }

  changeStatus = (statusObj, order_id, callback = () => null) => {
    const headers = {
      'Content-Type': 'application/json',
      'x-api-client': getDeviceType(),
      'x-auth-token': getLocalAuthData().token,
    }
    axios.patch(`${API_BASE_URL}/order/parcel/${order_id}/change-status`, statusObj,
      {headers: headers}
    )
      .then(response => {
        callback()
        toast.success(response.data.message);
      })
      .catch(error => {
        callback()
        toast.error('Something went wrong');
      });
  };

  getQueryData = () => {
    const headers = {
      'Content-Type': 'application/json',
      'x-api-client': 'web',
      'x-auth-token': getLocalAuthData().token,
    }
    let queryData = "";

    const customerId = this.props.match.params.id

    // eslint-disable-next-line array-callback-return
    Object.entries({...this.state.queryData, customer: customerId}).map(([key, val]) => {
        queryData = queryData.concat(key + "=" + val + "&");
      }
    )
    axios.get(`${API_BASE_URL}/order-parcel?${queryData}`, {headers: headers})
      .then(res => {
        this.setState({orderList: res.data.items, pagination: res.data.pagination});
      }).catch(e => console.log("Error"));
  }

  handleSearchFormDataChange = (e) => {
    const merchant_type = e.target.name;
    const merchant_type_id = e.target.value;
    if (merchant_type === "merchant_type") {
      const headers = {
        'Content-Type': 'application/json',
        'x-api-client': getDeviceType(),
        'x-auth-token': getLocalAuthData().token,
      }
      axios.get(`${API_BASE_URL}/store?merchant_type=${merchant_type_id}`, {headers: headers})
        .then(res => {
          this.setState({storeList: res.data.items});
        })
        .catch(err => {
          console.log(err)
        });
    }
    this.setState({queryData: {...this.state.queryData, [e.target.name]: e.target.value}});
  };

  renderMerchantType = () => {
    const merchantType = this.state.merchantType;
    if (merchantType) {
      return (
        <>
          {merchantType.map((items, index) =>
            <option key={index} value={`${items._id}`}>{items.type_name}</option>
          )}
        </>
      )
    }
  };

  renderStoreList = () => {
    const storeList = this.state.storeList;
    if (storeList.length > 0) {
      return (
        storeList.map((items, index) =>
          <option key={index} value={`${items._id}`}>{items.store_name}</option>
        )
      )
    }
  };

  hideChangeStatusModal = () => {
    this.setState({isShowingStatusChangeConfirmModal: false})
  }

  submitChangeStatusForm = (e) => {
    e.preventDefault()
    const customerId = this.props.match.params.id

    if (this.state.changeStatusMsg === '' && this.state.status === 'Cancelled') {
      return this.setState({showCancelMessageRequired: true});
    }
    this.setState({isSubmitting: true, showCancelMessageRequired: false}, () => {
      this.changeStatus({
        status: this.state.status,
        message: this.state.changeStatusMsg
      }, this.state.changingStoreId, () => {

        const authToken = getLocalAuthData();
        const headers = {
          'Content-Type': 'application/json',
          'x-api-client': 'web',
          'x-auth-token': authToken.token,
        }

        axios.get(`${API_BASE_URL}/order-parcel`, {
          headers: headers,
          params: {
            page: this.state.pagination.page,
            customer: customerId
          }
        })
          .then(res => {
            this.setState({orderList: res.data.items, pagination: res.data.pagination, isLoading: false});
          })
          .catch(err => {
            this.setState({isLoading: false})
          });

        this.setState({
          isSubmitting: false,
          message: '',
          changingStoreId: null,
          isShowingStatusChangeConfirmModal: false
        })
      })
    })
  }

  handleLocationInputOnChange = (e, index, typePurpose) => {
    const childLocations = [...this.state[typePurpose]];
    if (typePurpose === 'businessChildLocations') {
      this.handleSearchFormDataChange(e)
    } else {
      this.handleSearchFormDataChange(e)
    }
    if (e.target.value !== '') {
      getChildLocations(e.target.value)
        .then(res => {
          childLocations.splice(index, childLocations.length - index);
          if (res.length > 0) {
            childLocations[index] = res
          }
          this.setState({[typePurpose]: childLocations})
        })
        .catch(errMsg => {
          console.log(errMsg)
          childLocations.splice(index, childLocations.length - index)
          this.setState({[typePurpose]: childLocations})
        })
    } else {
      childLocations.splice(index, childLocations.length - index);
      this.setState({[typePurpose]: childLocations})
    }
  }

  renderLocations = () => {
    const locations = this.state.locations;
    if (locations) {
      return (
        <>
          {locations.map((items, index) =>
            <option value={`${items._id}`}>{items.name}</option>
          )}
        </>
      )
    }
  };

  handleOnApply = (e, picker) => {
    const queryData = {...this.state.queryData};

    queryData.from_date = moment(picker.startDate).format('DD/MM/YYYY');
    queryData.to_date = moment(picker.endDate).format('DD/MM/YYYY');

    this.setState({queryData}, () => {
      console.log(this.state)
    })
  }

  renderPaymentStatus = (status) => {
    let payment_status = {
      0: "Unpaid",
      1: "Partially Paid",
      2: "Paid",
      3: "Cancelled",
      4: "Failed"
    }
    return payment_status[status];
  }

  ShowStatusOption = (Status) => {
    let options = array;
    if (Status === "Delivered") {
      options = [{
        value: 'Delivered',
        label: 'Delivered'
      }
      ]
    } else if (Status === "Cancelled") {
      options = [{
        value: 'Cancelled',
        label: 'Cancelled'
      }
      ]
    } else {
      options = [{
        value: 'Pending',
        label: 'Pending',
      }, {
        value: 'Confirmed',
        label: 'Confirmed'
      }, {
        value: 'Processing',
        label: 'Processing'
      }, {
        value: 'Picked',
        label: 'Picked'
      }, {
        value: 'Shipped',
        label: 'Shipped'
      }, {
        value: 'Delivered',
        label: 'Delivered'
      }, {
        value: 'Cancelled',
        label: 'Cancelled'
      }
      ]
    }
    return options;
  }

  handleBlockTab = label => {
    this.setState({
      activeBlockTab: label
    })
  };

  changeCustomerStatus = async (statusObj, customer_id) => {
    axios.patch(`${API_BASE_URL}/customer/${customer_id}/changeStatus`, statusObj)
      .then(response => {
        if (response.data.status === 1) {
          toast.success(response.data.message);
        }
        if (response.data.status === 2) {
          toast.error(response.data.message);
        }
      }).catch(error => {
      toast.error("Error");
    });
  };

  handlePermanentDeleteCustomer = (id) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to delete this customer permanently?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteCustomerPermanently(id)
            .then(res => {
              toast.success('Successfully deleted');
              axios.get(`${API_BASE_URL}/customer?limit=${this.state.limit}`)
                .then(res => {
                  console.log(res.data.items);
                  this.setState({customers: res.data.items, pagination: res.data.pagination, isLoading: false});
                }).catch(e => console.log("Error"));
            })
            .catch(errMsg => {
              toast.error(errMsg)
            })
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }

  render() {
    const {userData} = this.state

    return (
      <App layout="boxed">
        {getLocalAuthData()?.permission?.includes('customer_details') ?
            <div className="main-content">
              {!this.state.isDataLoading ?
                  <>
                    <div className="card-block">
                      <div className="block-body">
                        <table className="order-table table table-striped">
                          <tbody>
                          <tr>
                            <td>
                              <div className="order-item">
                                <ul className="ordered-items">
                                  {userData?.name && <li className="text-bold">{userData?.name}</li>}
                                  {userData?.phone && <li>{userData?.phone}</li>}
                                  {userData?.email && <li>{userData?.email}</li>}
                                </ul>
                              </div>
                            </td>
                            <td>ORDERS: {userData?.total_order}</td>
                            <td style={{width: '150px'}}>
                              <select style={{width: '150px'}}
                                      defaultValue={userData?.status.toString()}
                                      onChange={(e) => {
                                        this.changeCustomerStatus({status: e.target.value}, this.props.match.params.id)
                                      }}
                                      className="form-control form-control-sm">
                                <option value="1">Active</option>
                                <option value="2">Inactive</option>
                              </select>
                            </td>
                            <td style={{width: '120px'}}>
                              <button onClick={() => this.handlePermanentDeleteCustomer(this.props.match.params.id)}
                                      className="btn-action btn-outline-danger">Delete</button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="card-block">
                      <ul className="desc-header-nav">
                        <li className={this.state.activeBlockTab === 'Orders' ? 'active' : ''}>
                          <span onClick={() => this.handleBlockTab('Orders')}>Orders</span>
                        </li>
                        {/*<li className={this.state.activeBlockTab === 'Shipping Address' ? 'active' : ''}>
                <span onClick={() => this.handleBlockTab('Shipping Address')}>Shipping Address</span>
              </li>*/}
                      </ul>

                      <div className="block-body">
                        {this.state.activeBlockTab === 'Orders' && <>
                          {/*<form className="form-inline mb-3">
                      <div className="form-group mr-3">
                        <label htmlFor="searchInput" className="sr-only">Order Number</label>
                        <input type="search" id="orderNumber" className='form-control form-control-sm'
                               placeholder="Order Number"/>
                      </div>

                      <div className="form-group mr-3">
                        <label htmlFor="shopSKU" className="sr-only">Product</label>
                        <input type="search" id="product" className='form-control form-control-sm'
                               placeholder="Product"/>
                      </div>

                      <Button size="sm" variant="secondary">Search</Button>
                    </form>*/}

                          {this.state.orderList?.length > 0 ?
                              <>
                                <Modal show={this.state.isShowingStatusChangeConfirmModal} onHide={this.hideChangeStatusModal}>
                                  <Modal.Header closeButton>
                                    <Modal.Title>Change Status Message</Modal.Title>
                                  </Modal.Header>
                                  <Form onSubmit={this.submitChangeStatusForm}>
                                    <Modal.Body>
                                      {this.state.status === 'Cancelled' ?
                                          <Form.Control as={`textarea`}
                                                        onChange={(e) => {
                                                          this.setState({changeStatusMsg: e.target.value})
                                                        }}
                                                        placeholder={`Enter you message here...`} rows={3}/>
                                          : <span><h5>Are you sure you want to change the order status?</h5></span>}
                                      {this.state.status === 'Cancelled' && this.state.showCancelMessageRequired ?
                                          <span><h5 style={{'color': "red"}}><i>Cancel message is required!</i></h5></span>
                                          : ''}
                                    </Modal.Body>
                                    <Modal.Footer>
                                      {this.state.status === 'Cancelled' && this.state.showCancelMessageRequired ?
                                          <>
                                            <Button variant="default" onClick={this.hideChangeStatusModal}>
                                              Close
                                            </Button>
                                            <Button disabled={this.state.isSubmitting}
                                                    variant="primary" type={`submit`}>
                                              {this.state.isSubmitting ? 'Submitting...' : 'Submit'}
                                            </Button>
                                          </>
                                          :
                                          <>
                                            <Button variant="default" onClick={this.hideChangeStatusModal}>
                                              No
                                            </Button>
                                            <Button disabled={this.state.isSubmitting}
                                                    variant="primary" type={`submit`}>
                                              {this.state.isSubmitting ? 'Submitting...' : 'Yes'}
                                            </Button>
                                          </>
                                      }
                                    </Modal.Footer>
                                  </Form>
                                </Modal>

                                <table className="order-table table table-striped">
                                  <thead>
                                  <tr>
                                    <th style={{width: '1%'}}>#</th>
                                    <th>Order Info</th>
                                    <th>Store Info</th>
                                    <th>Payment Info</th>
                                    {/*<th>Status Message</th>*/}
                                    <th style={{width: '150px'}}>Status</th>
                                    <th style={{width: '120px'}}/>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  {this.state.orderList.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>
                                            <div className="order-item">
                                              <h4 className="order-id">
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/order/parcel/${item.order_parcels._id}`}>{item?.order_parcels?.order_parcel_code}</Link>
                                              </h4>
                                              <span className="order-date">{moment(item.createdAt).format(`ll`)}</span>
                                              <span className="order-time">{moment(item.createdAt).format(`LT`)}</span>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="order-item">
                                              <h4 className="order-id">
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/store/details/${item?.order_parcels?.store_info?._id}`}>{item?.order_parcels?.store_info?.store_name}</Link>
                                              </h4>
                                              <ul className="ordered-items">
                                                <li>
                                                  <Link to={`${process.env.PUBLIC_URL}/messenger`}>
                                                    <span className="icon"><FiMessageSquare/></span> Contact Seller
                                                  </Link>
                                                </li>
                                              </ul>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="order-item">
                                              <h4 className="order-id">{item.order_parcels.parcel_grand_total}</h4>
                                              <ul className="ordered-items">
                                                <li>{item.payment_method?.name ? item.payment_method?.name : 'COD'}</li>
                                                <li>{this.renderPaymentStatus(item.order_parcels.payment_status)}</li>
                                              </ul>
                                            </div>
                                          </td>
                                          {/*<td>{item?.order_parcels?.status_message || '-'}</td>*/}
                                          <td>
                                            <select style={{width: '150px'}}
                                                    defaultValue={item.order_parcels.status}
                                                    className="form-control form-control-sm"
                                                    onChange={(e) => {
                                                      this.setState({
                                                        status: e.target.value,
                                                        changingStoreId: item.order_parcels._id,
                                                        isShowingStatusChangeConfirmModal: true
                                                      })
                                                    }}>

                                              {this.ShowStatusOption(item.order_parcels.status).map((option, index) => (
                                                  <option key={index} selected={item.order_parcels.status === option.value}
                                                          value={option.value}>{option.label}</option>
                                              ))}
                                            </select>

                                          </td>

                                          <td>
                                            <Link to={`${process.env.PUBLIC_URL}/orders/parcel/${item.order_parcels._id}`}
                                                  className="btn btn-block btn-sm btn-outline-secondary">View</Link>
                                            <Link to={`${process.env.PUBLIC_URL}/orders/invoice/${item._id}`}
                                                  className="btn btn-block btn-sm btn-outline-info">Invoice</Link>
                                          </td>
                                        </tr>
                                    );
                                  })}

                                  </tbody>
                                </table>

                                <div className="mt-3">
                                  {/*<Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.pagination?.page}
                        itemsCountPerPage={this.state.pagination?.limit}
                        totalItemsCount={this.state.pagination?.totalDocs}
                        onChange={this.handlePageChange.bind(this)}/>*/}
                                </div>

                              </>
                              :
                              <div className={`alert alert-warning text-center text-bold`}>
                                No order found
                              </div>
                          }
                        </>}
                      </div>
                    </div>
                  </>
                  :
                  <div className={'card-block'}>
                    <div className="block-body">
                      Loading...
                    </div>
                  </div>
              }
            </div>
            : null}


      </App>
    );
  }
}

export default withRouter(CustomerDetails);
