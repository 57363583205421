import axios from "axios";
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData} from "../helper/utils";

export const getSellerVoucher = () => {
    const authData = getLocalAuthData()
    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}/voucher`, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data.items);
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                reject(err.message)
            })
    })
}

export const submitSellerVoucher = (data) => {
    const authData = getLocalAuthData()
    return new Promise((resolve, reject) => {
        axios.post(`${API_BASE_URL}/voucher`, data, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.message);
        })
    })
}

export const getBundleOffer = (limit, page, promotion_type = "", status = "") => {
    const authData = getLocalAuthData()
    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}/bundle-offer?limit=${limit}&promotion_type=${promotion_type}&status=${status}`, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                if (res.data.status === 1 || res.data.status === 2) {
                    resolve(res.data);
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                reject(err.message)
            })
    })
}

export const submitBundleOffer = (data) => {
    const authData = getLocalAuthData()

    return new Promise((resolve, reject) => {
        axios.post(`${API_BASE_URL}/bundle-offer`, data, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {

                resolve(res.data)

            })
            .catch(err => {
                reject(err.message);
            })
    })
}

export const updateBundleOffer = (id, data) => {
    const authToken = getLocalAuthData()

    return new Promise((resolve, reject) => {
        axios.put(`${API_BASE_URL}/bundle-offer/${id}`, data, {
            headers: {
                'x-auth-token': authToken.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                reject(err.message);
            })
    })
}

/*export const submitSellerVoucher = (data, callback) => {
    return new Promise((resolve, reject) => {
        axios.post(API_BASE_URL + '/voucher', data)
            .then(res => {
                if(res.data.data.voucher_obj.status == "1") {
                    toast.success(res.data.data.message);
                    this.props.history.push("/")
                    callback()
                } else {
                    toast.error(res.data.data.message);
                }
            })
            .catch(err => {
                toast.error(err.message);
            })
    })
}*/

export const deleteVoucher = (id) => {
    const authData = getLocalAuthData()
    return new Promise((resolve, reject) => {
        axios.delete(`${API_BASE_URL}/voucher/${id}/permanent`, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data.message)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                reject(err.message)
            })
    })
}

export const deleteBundle = (id) => {
    const authData = getLocalAuthData()
    return new Promise((resolve, reject) => {
        axios.delete(`${API_BASE_URL}/bundle-offer/${id}/permanent`, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data.message)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                reject(err.message)
            })
    })
}

export const changeBundleStatus = (id, data) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.patch(`${API_BASE_URL}/bundle-offer/changeStatus/${id}`, data, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data);
                } else {
                    reject(res.data.message);
                }
            })
            .catch(err => {
                //console.log(err);
                reject('Something went wrong');
            })
    })
}


export const getBundleOfferDetails = (id) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}/bundle-offer/${id}`, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data.bundle_offer);
                } else {
                    reject(res.data.message);
                }
            })
            .catch(err => {
                //console.log(err);
                reject('Something went wrong');
            })
    })
}

export const getSellerVoucherDetails = (id) => {
    const authData = getLocalAuthData()
    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}/voucher/${id}`, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data.item)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                console.error(err)
                reject('Something went wrong')
            })
    })
}

export const updateSellerVoucher = (id, data) => {
    const authData = getLocalAuthData()
    return new Promise((resolve, reject) => {
        axios.put(`${API_BASE_URL}/voucher/${id}`, data, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                console.error(err)
                reject('Something went wrong')
            })
    })
}

export const submitCampaign = (data) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.post(`${API_BASE_URL}/campaign`, data, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.message);
        })
    })
}

export const updateCampaign = (data, id) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.put(`${API_BASE_URL}/campaign/${id}`, data, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.message);
        })
    })
}

export const getCampaignList = (params = {}) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}/campaign`, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            },
            params: params
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.message);
        });
    });
}

