import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {toast} from "react-toastify";
import {
  addMerchantType, deleteMerchantType,
  getMerchantTypeDetails,
  getMerchantTypeList,
  updateMerchantType
} from "../../services/merchantServices";
import moment from "moment";
import {getLocalAuthData} from "../../helper/utils";
import {confirmAlert} from "react-confirm-alert";
import {deleteCollection, getCollectionList} from "../../services/collectionServices";

class MerchantTypes extends Component {

  state = {
    merchantTypes: [],
    formData: {
      show_in_public: true
    },
    isLoading: true,
    editingMerchantType: null,
    isSubmitting: false
  }

  componentDidMount() {
    getMerchantTypeList()
      .then(res => {
        this.setState({merchantTypes: res, isLoading: false})
      })
      .catch(errMsg => {
        this.setState({isLoading: false})
        toast.error(errMsg);
      })
  }

  handleInputOnChange = (e) => {
    const formData = {...this.state.formData};
    formData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({formData});
  }

  handleSubmitForm = (e) => {
    e.preventDefault();
    this.setState({isSubmitting: true})

    if (!this.state.editingMerchantType) {
      addMerchantType(this.state.formData)
        .then(res => {
          this.handleUiAndData(res)
        })
        .catch(errMsg => {
          toast.error(errMsg);
          this.setState({isSubmitting: false});
        })
    } else {
      updateMerchantType(this.state.formData, this.state.editingMerchantType)
        .then(res => {
          this.handleUiAndData(res)
        })
        .catch(errMsg => {
          toast.error(errMsg)
          this.setState({isSubmitting: false});
        })
    }
  }

  handleUiAndData = (res) => {
    document.getElementById("merchantTypeForm").reset();
    this.setState({formData: {}, editingMerchantType: null, isSubmitting: false});
    getMerchantTypeList()
      .then(res => {
        this.setState({merchantTypes: res, isLoading: false})
      })
      .catch(errMsg => {
        this.setState({isLoading: false})
        toast.error(errMsg);
      });
    toast.success(res.message);
  }

  handleSetFormData = (id) => {
    getMerchantTypeDetails(id)
      .then(res => {
        const formData = {...this.state.formData}
        formData.type_name = res.type_name;
        formData.show_in_public = res.show_in_public;
        this.setState({formData, editingMerchantType: id})
      })
  }

  handleDeleteType = (id) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to delete this type?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteMerchantType(id)
            .then(res => {
              getMerchantTypeList()
                .then(res => {
                  this.setState({merchantTypes: res, isLoading: false})
                })
                .catch(errMsg => {
                  this.setState({isLoading: false})
                  toast.error(errMsg);
                })
              toast.success(res.message);
            })
            .catch(errMsg => {
              toast.error(errMsg);
            })
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }

  render() {
    return (
      <App layout={"boxed"}>

        <>
        <div className="page-header">
          <h2 className="page-title">Merchant Types</h2>
        </div>
        <div className="main-content">
          {getLocalAuthData()?.permission?.includes('merchant_types_add') ?
          <div className="card-block">
            <div className="block-header">
              <h4
                className="block-title">{!this.state.editingMerchantType ? 'Add Merchant Type' : 'Update Merchant Type'}</h4>
            </div>

            <div className="block-body">
              <Form onSubmit={this.handleSubmitForm} id={"merchantTypeForm"}>
                <Form.Group as={Row} controlId="brandName">
                  <Form.Label column sm="3">
                    Merchant Type <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control type={"text"}
                                  required={true}
                                  name={"type_name"}
                                  defaultValue={this.state.formData?.type_name}
                                  onChange={this.handleInputOnChange}
                                  placeholder={"Type name"}/>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId={'showInPublic'}>
                  <Form.Label column sm="3"/>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Check
                      type="checkbox"
                      id="showInPublic"
                      label="Show in Public"
                      name={"show_in_public"}
                      onChange={this.handleInputOnChange}
                      checked={this.state.formData.show_in_public}
                      custom
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formCategory">
                  <Col sm="3"/>
                  <Col sm="9" md={8} lg={7}>
                    <Button type={"submit"}
                            disabled={this.state.isSubmitting}
                            variant={"primary"}>
                      {this.state.editingMerchantType ? (this.state.isSubmitting ? 'Updating...' : 'Update') : (this.state.isSubmitting ? 'Saving...' : 'Save')}
                    </Button>

                    {this.state.editingMerchantType &&
                    <Button type={"reset"}
                            onClick={() => {
                              document.getElementById("merchantTypeForm").reset();
                              this.setState({
                                formData: {show_in_public: true},
                                editingMerchantType: null
                              })
                            }}
                            className={"ml-3"}
                            variant={"default"}>Reset</Button>
                    }
                  </Col>
                </Form.Group>
              </Form>
            </div>
          </div> : null}

          {getLocalAuthData()?.permission?.includes('merchant_types_list') ?
          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Merchant Type List</h4>
            </div>
            <div className="block-body">
              {this.state.isLoading ?
                <div>Loading...</div>
                :
                <table className="table table-striped">
                  <thead>
                  <tr>
                    <th style={{width: '20px'}}>#</th>
                    <th>Type</th>
                    <th>Created At</th>
                    <th>Qnt. of Stores</th>
                    <th width={'1%'}/>
                  </tr>
                  </thead>

                  <tbody>
                  {this.state.merchantTypes.map((type, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{type.type_name}</td>
                      <td>{moment(type.created_at).format("ll")}</td>
                      <td>30</td>
                      <td className={"nowrap"}>
                        <span style={{cursor: "pointer"}}
                              onClick={() => this.handleSetFormData(type._id)}
                              className={"text-bold text-info mr-4"}>Edit</span>
                        <span style={{cursor: "pointer"}}
                              onClick={() => {
                                this.handleDeleteType(type._id)
                              }}
                              className={"text-bold text-danger"}>Delete</span>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              }
            </div>
          </div>
              : null}
        </div>
        </>

      </App>
    );
  }
}

export default MerchantTypes;
