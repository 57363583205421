import React from "react";
import "../assets/scss/category.scss";

const Category = ({img, name}) => {
  return (
    <div className="category">
      <div className="category-img ratio ratio-1-1">
        <div className="ratio-inner">
          <img src={img} alt={name}/>
        </div>
      </div>

      <div className="category-name">{name}</div>
    </div>
  )
};

export default Category;
