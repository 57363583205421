import React, {Component} from 'react';
import App from "../../App";
import {FiTrash} from "react-icons/all";
import "../../assets/scss/banner-management.scss";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import BrowseFiles from "../../components/BrowseFiles";
import {
  getBanner,
  deleteSlider,
  deleteBanner,
  detailsBanner,
  submitBanner
} from "../../services/bannerService";
import axios from "axios";
import config, {API_BASE_URL} from "../../helper/env";
import {ToastsContainer, ToastsContainerPosition, ToastsStore} from "react-toasts";
import Service from "../../components/Service";
import {getMerchantTypeList} from "../../services/merchantServices";
import {getStoreList} from "../../services/storeServices";
import {toast} from "react-toastify";
import styleReactSelect from "../../assets/styleReactSelect";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import {getLocalAuthData} from '../../helper/utils'

class Banners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchant_types: [],
      merchantList: [],
      StoreList: [],
      merchant_type: '',
      store_id: '',
      queryData: {
        from_date: '',
        to_date: '',
        merchant_type: '',
        store_id: '',
      },
      pagination: null,


      selected_image_size_template: 'banner_1500x556',
      image_size_templates: [
        {label: "Banner_1920x430", value: "banner_1920x430"},
        {label: "Banner_1500x556", value: "banner_1500x556"},
        {label: "Banner_750x280", value: "banner_750x280"},
        {label: "Banner_400x150", value: "banner_400x150"},
        {label: "Banner_400x400", value: "banner_400x400"},
        {label: "Default_800x800", value: "default"},
      ],

      banner_locations: [
        // {label: "Home Slider", value: "home-slider"},
        // {label: "Category", value: "category"},
        // {label: "Collection", value: "collection"},
        // {label: "New Product", value: "new-product"},
        // {label: "Popular Product", value: "popular-product"},
        // {label: "Ekshop Mall Banner", value: "ekshop-mall-banner"},
        // {label: "Ekshop Mall Service", value: "ekshop-mall-service"},
        // {label: "Home Ekshop Mall", value: "ekshop-mall"},
        // {label: "Promotion Event", value: "promotion-event"},
        // {label: "Store Homepage Before Category", value: "category"},
        // {label: "Store Main Banner", value: "store-top"},
        // {label: "Store All Products", value: "all-product"},
        {label: "Ekshop Mall Slider", value: "ekshop-mall-slider"}, // done
        {label: "Home Uddakta Mall", value: "uddakta-mall"},
        {label: "Promotion Top", value: "promotion-top"},
        {label: "Service", value: "service"},
        {label: "Campaign", value: "campaign"},
        {label: "E-commerce Partner", value: "e-commerce-partner"},
        {label: "District Branding", value: "district-branding"},
      ],
      isSubmitting: false,

      showSliderModal: false,
      formData: {
        banner_type: 1
      },
      bannerData: [],
      sliderData: [],
      detailsBannerData: {},
      showAddBannerModal: false,
    }
  }

  componentDidMount() {
    this.setState({...this.state, selected_image_size_template: 'banner_1500x556'})
    this.getBanner({store_id: '', banner_management: true})

    /*getMerchantTypeList().then(res => {
      this.setState({...this.state, merchant_types: res})
    }).catch(() => {
      ToastsStore.error("Error");
    })*/
  }

  getBanner = (paramsObj = {}) => {
    getBanner(paramsObj).then(res => {
      console.log('rashed => ', res)
      if (res.status === 1) {
        this.setState({bannerData: res.items, pagination: res.pagination})
      } else if (res.status === 2) {
        this.setState({bannerData: res.items, pagination: res.pagination})
        toast.warning(res.message)
      }
    }).catch(function (errMsg) {
      toast.error(errMsg)
    });
  }

  handleSearchFormDataChange = (e) => {
    const fieldName = e.target.name;
    const value = e.target.value;

    if (fieldName === "merchant_type") {
      this.setState({...this.state, merchant_type: value})
      getStoreList({merchant_type: value, lite_data: 1}).then((res) => {
        this.setState({storeList: res.items, merchant_type: value})

        if (res.status === 1) {
          this.setState({storeList: res.items})
          toast.success(res.message)
        } else if (res.status === 2) {
          this.setState({storeList: res.items})
          toast.warning(res.message)
        }

      }).catch(function () {
        ToastsStore.error("Error");
      });
    } else if (fieldName === 'store_id') {
      this.setState({...this.state, store_id: value})
    } else {
      this.setState({
        ...this.state,
        [e.target.name]: (e.target.type === 'checkbox' ? e.target.checked : e.target.value)
      })
    }
  };

  setSearchDataOnChange = (value, name) => {
    this.setState({...this.state, [name]: value.value})
  }

  executeSearch = () => {
    this.getBanner({store_id: this.state.store_id, location: this.state.location, banner_management: true});
  }

  handleInputOnChange = (e) => {
    const formData = {...this.state.formData};
    formData[e.target.name] = e.target.value
    this.setState({formData});
  }

  selectOnChange = (value, name) => {
    const formData = {...this.state.formData, [name]: value.value}
    if (name === 'image_size_template') {
      this.setState({selected_image_size_template: value.value})
    }
    this.setState({formData})
  }

  renderMerchantType = () => {
    const merchantTypes = this.state.merchant_types;
    if (merchantTypes) {
      return (
        <>
          {merchantTypes.map((items, index) =>
            <option key={index} value={`${items._id}`}>{items.type_name}</option>
          )}
        </>
      )
    }
  };

  renderStoreList = () => {
    const storeList = this.state.storeList;
    if (storeList.length > 0) {
      return (
        storeList.map((items, index) =>
          <option key={index} value={`${items._id}`}>{items.store_name}</option>
        )
      )
    }
  };

  handleAddBannerModalClose = () => {
    this.setState({showAddBannerModal: false})
  }

  handleSliderModal = () => {
    this.setState({showSliderModal: !this.state.showSliderModal})
  }

  handleAddBannerModalShow = (id) => {
    if (id !== null) {
      detailsBanner(id)
        .then(res => {
          this.setState({detailsBannerData: res})
        }).catch()
    }
    this.setState({showAddBannerModal: true})
  }

  handleImage = (files) => {
    this.setState({
      formData: {
        ...this.state.formData,
        image: files[0].actual_img,
        m_image: files[0].actual_img,
        mid_image: files[0].mid_img,
        thumb_image: files[0].actual_img,
        thumb_img: files[0].actual_img
      }
    });
  }

  handleImageOnRemove = () => {
    const formData = {...this.state.formData}
    delete formData['image'];
    this.setState({formData})
  }

  handleFormDataChange = (e) => {
    this.setState({formData: {...this.state.formData, [e.target.name]: e.target.value}});
  };

  deleteSlider = (id) => {
    deleteSlider(id)
      .then(response => {
        if (response.status === 1) {
          ToastsStore.success(response.message);
          this.getSlider({});
        }
        if (response.status === 2) {
          ToastsStore.error(response.message);
        }
        this.setState({sliderData: response})
      }).catch();
  };

  deleteBanner = (id) => {
    deleteBanner(id).then(response => {
      if (response.status === 1) {
        toast.success(response.message);
      } else if (response.status === 2) {
        toast.warning(response.message);
      }
      this.getBanner({});
    }).catch(errMsg => {
      toast.error(errMsg);
    });
  };

  renderBannerType = (type) => {
    if (type === 1) {
      return <span className="text-success -badge -badge-success">Landscape</span>
    } else if (type === 2) {
      return <span className="text-warning -badge -badge-warning">Portrait</span>
    } else if (type === 3) {
      return <span className="text-warning -badge -badge-warning">Square</span>
    }
  }

  handleBannerSubmitForm = (e) => {
    e.preventDefault()

    this.setState({isSubmitting: true}, () => {
      submitBanner(this.state.formData).then(res => {
        if (res.status === 1) {
          toast.success(res.message);
          this.setState({isSubmitting: false, showAddBannerModal: false})
          this.getBanner({});
        } else {
          toast.warning(res.message);
          this.setState({isSubmitting: false})
        }
      }).catch(errMsg => {
        toast.error(errMsg);
        this.setState({isSubmitting: false})
      });
    });
  };

  editBanner = (id) => {
    axios.get(`${config.API_BASE_URL}/service/${id}`)
      .then(res => {
        this.setState({
          editFormData: res.data.service,
          formData: {
            ...this.state.formData,
            name: res.data.service.name,
            link: res.data.service.link,
            image: res.data.service.image
          },
          editStatus: true
        });
      }).catch(() => {
      ToastsStore.error("Error");
    });
  };

  handleSliderSubmitForm = () => {
    const data = this.state.formData;
    let url = `${API_BASE_URL}/slider`;
    axios.post(url, data).then(response => {
      if (response.data.status === 1) {
        ToastsStore.success(response.data.message);
        this.getSlider({}).then();
      } else {
        ToastsStore.error(response.data.message);
      }
    }).catch(() => {
      ToastsStore.error("Error");
    });
  };

  render() {
    return (
      <App layout={"boxed"}>
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Slider & Banners</h2>
          </div>
          <div className="card-block">
            <Modal size={"lg"} show={this.state.showAddBannerModal} onHide={this.handleAddBannerModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>Add Banner</Modal.Title>
              </Modal.Header>
              <Form onSubmit={this.handleBannerSubmitForm}>
                <Modal.Body>
                  <Form.Group as={Row} controlId="formImageSizeTemplate">
                    <Form.Label column sm="3">
                      Image Size Template
                    </Form.Label>
                    <Col sm="9" md={9} lg={9}>
                      <CreatableSelect
                        styles={styleReactSelect}
                        options={this.state.image_size_templates}
                        placeholder="Select select image size template..."
                        defaultValue={this.state.image_size_templates.filter((obj) => {
                          return obj.value === this.state.selected_image_size_template;
                        })[0]}
                        onChange={(value) => this.selectOnChange(value, 'image_size_template')}
                        noOptionsMessage={() => 'Please select image size template'}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPromotionName">
                    <Form.Label column sm="3">
                      Banner Image <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <BrowseFiles size={"sm"}
                                   onRemoveItem={this.handleImageOnRemove}
                                   onGetFiles={this.handleImage}
                                   image_category={'banner'}
                                   images={this.state?.formData?.image ? [this.state?.formData?.image] : ''}
                                   image_size_templates={this.state.image_size_templates}
                                   selected_image_size_template={this.state.selected_image_size_template}
                                   helpText={"Image size 1500 x 556"}/>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Banner Type <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Check custom inline type="radio" name="banner_type"
                                  defaultChecked={this.state?.formData?.banner_type === 1}
                                  id={`banner-type-landscape`} onChange={this.handleInputOnChange}
                                  value={1} label={`Landscape`}/>
                      <Form.Check custom inline type="radio" name="banner_type"
                                  id={`banner-type-portrait`} onChange={this.handleInputOnChange}
                                  value={2} label={`Portrait`}/>
                      <Form.Check custom inline type="radio" name="banner_type"
                                  id={`banner-type-square`} onChange={this.handleInputOnChange}
                                  value={3} label={`Square`}/>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formImageSizeTemplate">
                    <Form.Label column sm="3">
                      Banner Place
                    </Form.Label>
                    <Col sm="9" md={9} lg={9}>
                      <CreatableSelect
                        styles={styleReactSelect}
                        defaultValue={this?.state?.location}
                        placeholder="Select select banner location..."
                        onChange={(value) => this.selectOnChange(value, 'location')}
                        options={this.state.banner_locations}
                        noOptionsMessage={() => 'Please select banner location'}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPromotionName">
                    <Form.Label column sm="3">
                      Banner Link
                    </Form.Label>
                    <Col sm="9" md={9} lg={9}>
                      <Form.Control type="text" placeholder="" name={'link'} onChange={this.handleInputOnChange}/>
                    </Col>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="default" onClick={this.handleAddBannerModalClose}> Close </Button>
                  <Button variant="primary" type={'submit'} disabled={this.state.isSubmitting}>
                    {this.state.isSubmitting ? 'Please Wait...' : 'Save'} </Button>
                </Modal.Footer>
              </Form>
            </Modal>

            <div className="block-header">
              <h4 className="block-title">Banners List</h4>
              <div className="">
                {getLocalAuthData()?.permission?.includes('banner_add_banners') ?
                  <Button size={"sm"} onClick={() => this.handleAddBannerModalShow()} variant={"secondary"}>Add
                    Banner</Button>
                  : null}
              </div>
            </div>

            <div className="block-body">
              <div className="d-flex">
                <div className="form-inline">
                  {this.state.merchant_types?.length > 0 &&
                  <div className="form-group mr-3 mt-2">
                    <label htmlFor="barndName" className="sr-only">Merchant Type</label>
                    <select id="merchantType" name="merchant_type"
                            onChange={this.handleSearchFormDataChange}
                            className='form-control form-control-sm'>
                      <option value="">--Select Type--</option>
                      {this.renderMerchantType()}
                    </select>
                  </div>}
                  {this.state.storeList?.length > 0 &&
                  <div className="form-group mr-3 mt-2">
                    <label htmlFor="barndName" className="sr-only">Store</label>
                    <select
                      id="store" name="store_id"
                      onChange={this.handleSearchFormDataChange}
                      className='form-control form-control-sm'>
                      <option value="">--Select Store--</option>
                      {this.renderStoreList()}
                    </select>
                  </div>}
                  {this.state?.image_size_templates?.length > 0 &&
                  <div className="form-group mr-3 mt-2">
                    <label htmlFor="bannerLocation" className="sr-only">Location</label>
                    <select
                      id={'bannerLocation'} name="location"
                      onChange={this.handleSearchFormDataChange}
                      className='form-control form-control-sm'>
                      <option value="">--Select Banner Location--</option>
                      {
                        this.state.banner_locations.map((item, index) =>
                          <option key={index} value={`${item.value}`}>{item.label}</option>
                        )
                      }
                    </select>
                  </div>}
                  <Button size={"sm"} variant={"primary"} onClick={this.executeSearch}>Search</Button>
                </div>
              </div>

              {(getLocalAuthData()?.permission?.includes('banner_list') && this.state?.bannerData?.length > 0) ?
                <>
                  <div className="mt-4">
                    <table className="order-table table table-striped">
                      <thead>
                      <tr>
                        <th style={{width: '1%'}}>#</th>
                        <th style={{width: '35%'}}>Banner Image</th>
                        <th style={{width: '20%'}}>Banner Type</th>
                        <th style={{width: '20%'}}>Banner Location</th>
                        <th style={{width: '20%'}}>Banner Dimension</th>
                        <th style={{width: '1%'}}/>
                      </tr>
                      </thead>

                      <tbody>
                      {
                        this.state.bannerData.length > 0 ? this.state.bannerData.map((banner, index) => (
                            <>
                              <tr key={index}>
                                <td>{this.state.pagination.pagingCounter + index}</td>
                                <td>
                                  <div className="banner-container">
                                    <img src={banner.thumb_image} alt=""/>
                                    <div className="actions"/>
                                  </div>
                                </td>
                                <td>
                                  <div className="order-item">
                                    <h4 className="order-id">{this.renderBannerType(banner?.banner_type)}</h4>
                                  </div>
                                </td>
                                <td>{banner?.location}</td>
                                <td>{banner?.image_size_template}</td>
                                <td style={{width: '1%'}}>
                                  {/*<span className="action" onClick={() => this.handleAddBannerModalShow(banner._id)}><FiEdit/></span>*/}
                                  {getLocalAuthData()?.permission?.includes('banner_delete') ?
                                    <span className="action"
                                          onClick={() => this.deleteBanner(banner._id)}><FiTrash/></span>
                                    : null}
                                </td>
                              </tr>
                            </>
                          ))
                          :
                          <tr>
                            <td colSpan={6} className={'text-center text-bold'}>No commission found</td>
                          </tr>
                      }
                      </tbody>
                    </table>
                  </div>
                </>
                :
                <div className={`alert alert-warning text-center text-bold mt-4`}>
                  No Banner found
                </div>
              }
            </div>

          </div>
        </div>
        <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_RIGHT}/>
      </App>
    );
  }
}

export default Banners;
