import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {getChildLocations, getLocationList} from "../../services/locationServices";
import BrowseFiles from "../../components/BrowseFiles";
import {getMerchantType} from "../../services/merchantTypeServices";
import {addMerchant} from "../../services/merchantServices";
import {toast} from "react-toastify";
import {getLocalAuthData, headerData} from "../../helper/utils";
import {activityLogPostData, checkIsExisting} from "../../services/commonServices";
import {getBankList} from "../../services/settingServices";
import axios from "axios";
import {API_BASE_URL} from "../../helper/env";
import {FaLongArrowAltLeft} from "react-icons/all";

class staffAdd extends Component {

  //
  // bank_info: {},
  // is_flagship: false,
  // ecommerce_partner: false,
  // commission_available_for: {
  //   admin: true,
  //   merchant: true,
  // },

  state = {
    activeBlockTab: 'General',
    countries: [''],
    businessChildLocations: [],
    merchantTypes: [],
    formData: {
      is_admin: true,
      type: 1,
      country_code: '+880',
      phone: ''
    },
    isLoading: false,
    merchant_id: null,
    isAdding: false,
    merchantInfo: null,
    isFormValidated: false,
    phoneExistingStatus: 2,
    phoneExistingMsg: null,
    emailExistingStatus: 2,
    emailExistingMsg: null,
    businessNameExistingStatus: 2,
    businessNameExistingMsg: null,
    splittedPhone: ['', ''],
    bankList: []
  };

  componentDidMount() {
    const authData = getLocalAuthData();
    //console.log('LOCAL DATA', authData)


    this.setState({merchant_id: authData.merchant_id})

    getLocationList()
      .then(res => {
        //console.log('Res: ', res)
        this.setState({countries: res})
      })

    getMerchantType()
      .then(res => {
        this.setState({merchantTypes: res}, () => {
          //console.log(this.state.merchantTypes);
        })
      })

    getBankList()
      .then(res => {
        this.setState({bankList: res})
      })
      .catch(errMsg => {
        //console.log(errMsg)
      })
  }

  handleBlockTab = label => {
    this.setState({
      activeBlockTab: label
    })
  };

  handleLocationInputOnChange = (e, index, typePurpose) => {
    const childLocations = [...this.state[typePurpose]];
    this.handleInputOnChange(e);
    if (e.target.value !== '') {
      getChildLocations(e.target.value)
        .then(res => {
          childLocations.splice(index, childLocations.length - index);
          if (res.length > 0) {
            childLocations[index] = res
          }
          this.setState({[typePurpose]: childLocations})
        })
        .catch(errMsg => {
          //console.log(errMsg)
          childLocations.splice(index, childLocations.length - index)
          this.setState({[typePurpose]: childLocations})
        })
    } else {
      childLocations.splice(index, childLocations.length - index);
      this.setState({[typePurpose]: childLocations})
    }
  }

  handleInputOnChange = (e) => {
    const formData = {...this.state.formData}
    formData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({formData})
  }

  handleCommissionAvailability = (e) => {
    const formData = {...this.state.formData}
    formData['commission_available_for'][e.target.name] = e.target.checked;
    this.setState({formData})
    //console.log(this.state.formData);
  }

  handleBankInputOnChange = (e) => {
    const formData = {...this.state.formData}
    formData.bank_info[e.target.name] = e.target.value;
    this.setState({formData})
  }

  handleSubmitForm = e => {
    e.preventDefault();
    const authData = getLocalAuthData();
    const formData = {...this.state.formData};
    formData.phone = this.state.splittedPhone.join('-')

    //console.log(formData)

    if (formData.phone === "-" || formData.phone === "+880-"|| formData.phone === "+1-"){
      toast.warning('Please select phone number')
    }else {
      axios.post(`${API_BASE_URL}/staff`, formData, {
        headers: {
          "x-auth-token": authData.token,
          "x-api-client": "web"
        }
      }).then(response => {
        //console.log(response)
        if (response.data.status === 1) {
          toast.success(response.data.message);
          activityLogPostData("[Staff Management] Create new staff")
          //this.renderData();
          //window.location.reload();
        }
        if (response.data.status === 2) {
          toast.error(response.data.message);
        }
      }).catch(error => {
        toast.error("Something went wrong please try again after refresh the page.");
      });
    }


  }

  handleBankOnBrowsePic = (files, name) => {
    const formData = {...this.state.formData}
    formData.bank_info[name] = files[0].actual_img;
    this.setState({formData})
  }

  handleRemoveImage = (name) => {
    const formData = {...this.state.formData}
    formData[name] = ''
    this.setState({formData})
  }

  handleBankRemoveImage = (name) => {
    const formData = {...this.state.formData}
    formData.bank_info[name] = ''
    this.setState({formData})
  }

  checkIsExisting = (type, data, msgKey, statusKey) => {


    checkIsExisting(type, data)
      .then(res => {


        if (res.status === 2) {
          this.setState({[msgKey]: res.message, [statusKey]: res.status})

        } else {
          this.setState({[msgKey]: null, [statusKey]: res.status})
        }

      })
      .catch(errMsg => {
        //console.log(errMsg)
      });

  }

  inputPhoneOnChange = (e, index) => {

    let val = e.target.value;
    if (val.charAt('0') === '0'){
       val = val.substring(1);
    }


    const splittedPhone = [...this.state.splittedPhone]
    splittedPhone[index] = e.target.value
    this.setState({splittedPhone})
    //console.log(this.state.splittedPhone)

 /*   this.setState({
      formData: {
        ...this.state.formData,
        phone: '+880-'.concat(val)
      }
    })*/
  }

  render() {
    const {formData} = this.state;

    return (
      <App layout="boxed">
        {getLocalAuthData()?.permission?.includes('staff_add') ? <>
          <div className="page-header">
            <h2 className="page-title">Add Admin Staff</h2>
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/staff/list`}
                    className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Staff List</Link>
            </div>
          </div>
          <div className="card-block">
            <div className="block-body">
              {this.state.isLoading ?
                  <div>Loading...</div>
                  :
                  <>
                    {this.state.activeBlockTab === 'General' &&
                    <Form noValidate validated={this.state.isFormValidated}
                          onSubmit={this.handleSubmitForm}>
                      <Form.Group as={Row} controlId="">
                        <Form.Label column sm="3"/>


                      </Form.Group>


                      <Form.Group as={Row} controlId="nameId">
                        <Form.Label column sm="3">
                          Owner Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control
                              name={"name"}
                              required
                              defaultValue={formData?.owner_name}
                              onChange={this.handleInputOnChange} type="text"/>
                          <Form.Control.Feedback type="invalid">Please enter owner
                            name</Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="sellerEmail">
                        <Form.Label column sm="3">
                          Email Address <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control name={"email"}
                                        required
                                        onBlur={() => this.checkIsExisting('email', {
                                          email: this.state.formData.email
                                        }, 'emailExistingMsg', 'emailExistingStatus')}
                                        defaultValue={formData?.email}
                                        onChange={this.handleInputOnChange}
                                        type="email"/>

                          {this.state.emailExistingMsg && <Form.Text className={'text-danger'}>
                            {this.state.emailExistingMsg}
                          </Form.Text>}

                          <Form.Control.Feedback type="invalid">Please enter a valid email
                            address</Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="phoneNumber">
                        <Form.Label column sm="3">
                          Phone Number <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <div className="input-group">

                            {/* <Form.Control as={'select'}
                                      name={'country_code'}
                                      required
                                      value={this.state?.splittedPhone ? this.state?.splittedPhone[0] : ''}
                                      onChange={e => this.inputPhoneOnChange(e, 0)}
                                      style={{
                                        width: `80px`,
                                        paddingRight: '0px',
                                        flex: '0 0 80px'
                                      }}>

                          <option value="">---</option>
                          {this.state.countries.map((country, index) => (
                            <option key={index} value={country.code}>{country.code}</option>
                          ))}
                        </Form.Control>

                        <Form.Control required
                                      name={'phone'}
                                      onBlur={() => this.checkIsExisting('phone', {
                                        country_code: this.state.splittedPhone[0],
                                        phone: this.state.splittedPhone[1]
                                      }, 'phoneExistingMsg', 'phoneExistingStatus')}
                                      onChange={ e =>this.inputPhoneOnChange(e,0)}
                                      type="text" placeholder={`Phone number`}/>*/}

                            <Form.Group as={Row} controlId="phoneNumber">

                              <Col sm="9" md={8} lg={12}>
                                <div className="input-group">
                                  <Form.Control as={'select'}
                                                name={'code'}
                                                required
                                                value={this.state?.splittedPhone ? this.state?.splittedPhone[0] : ''}
                                                onChange={e => this.inputPhoneOnChange(e, 0)}
                                                style={{
                                                  width: `80px`,
                                                  paddingRight: '10px',
                                                  flex: '0 0 80px'
                                                }}>

                                    <option value="">---</option>
                                    {this.state.countries.map((country, index) => (
                                        <option key={index} value={country.code}>{country.code}</option>
                                    ))}
                                  </Form.Control>

                                  <Form.Control required maxLength="10" minLength="10"
                                                onBlur={() => this.checkIsExisting('phone', {
                                                  country_code: this.state.splittedPhone[0],
                                                  user_id: this.state?.selected_id,
                                                  phone: this.state.splittedPhone[1]
                                                }, 'phoneExistingMsg', 'phoneExistingStatus')}
                                                defaultValue={this.state?.splittedPhone[1]}
                                                onChange={e => this.inputPhoneOnChange(e, 1)}
                                                type="Phone number"/>
                                </div>

                                {this.state.phoneExistingMsg && <Form.Text className={'text-danger'}>
                                  {this.state.phoneExistingMsg}
                                </Form.Text>}
                              </Col>
                            </Form.Group>

                          </div>

                        </Col>
                      </Form.Group>


                      <Form.Group as={Row} controlId="">
                        <Form.Label column sm="3">
                          Password <span className="text-danger">*</span>
                        </Form.Label>

                        <Col sm="9" md={8} lg={7}>
                          <Form.Control name={'password'}
                                        required
                                        defaultValue={formData?.identification_number}
                                        onChange={this.handleInputOnChange}
                                        type={"password"}/>

                          <Form.Control.Feedback type="invalid">Please enter Identification
                            Number.</Form.Control.Feedback>
                        </Col>
                      </Form.Group>


                      <div className="mt-3">
                        <Button
                            size="lg"
                            type={"submit"}
                            disabled={this.state.isAdding}
                            variant="primary">{this.state.isAdding ? 'Adding...' : 'Add'}</Button>
                      </div>
                    </Form>
                    }

                    {this.state.activeBlockTab === 'Invoice Number' &&
                    <>
                      <Form.Group as={Row} controlId="generationType">
                        <Form.Label column sm="3">
                          Generation Type
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control as="select">
                            <option value="">Use Autoincrement Number</option>
                            <option value="">Use Order Number</option>
                            <option value="">Provide number manually</option>
                          </Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="generationType">
                        <Form.Label column sm="3">
                          Invoice Prefix
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control type="text"/>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="generationType">
                        <Form.Label column sm="3">
                          Next Number (Insert numbers only)
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control type="number"/>
                        </Col>
                      </Form.Group>
                    </>
                    }
                  </>
              }
            </div>
          </div>
        </> : <span className={`text-danger`}>Permission denied</span>}
      </App>
    );
  }
}

//export default AddEntrepreneur;
export default withRouter(staffAdd);
