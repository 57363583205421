import React, {Component} from 'react';
import App from "../../App";
import {FaLongArrowAltLeft} from "react-icons/all";
import {Link} from "react-router-dom";
import {Button, Col, Form, Row} from "react-bootstrap";
import "../../assets/scss/add-product.scss";
import { getLocalAuthData } from '../../helper/utils'

class AddCustomer extends Component {

    render() {
        return (
            <App layout="boxed">

                {getLocalAuthData()?.permission?.includes('customer_add') ?
                    <>
                        <div className="page-header">
                            <h2 className="page-title">Add Customer</h2>
                            <div className="ml-auto">
                                <Link to={`${process.env.PUBLIC_URL}/products/list`} className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Customer List</Link>
                            </div>
                        </div>
                        <div className="main-content">
                            <div className="card-block">
                                <div className="block-header">
                                    <h4 className="block-title">Describe the Customer</h4>
                                </div>

                                <div className="block-body">
                                    <Form>
                                        <Form.Group as={Row} controlId="formFieldName">
                                            <Form.Label column sm="3">
                                                Name <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control type="text" placeholder=""/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formEmail">
                                            <Form.Label column sm="3">
                                                Email <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control type="text" placeholder=""/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formContactNumber">
                                            <Form.Label column sm="3">
                                                Contact Number <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control type="text" placeholder=""/>
                                            </Col>
                                        </Form.Group>

                                    </Form>
                                </div>
                            </div>

                            <div className="card-block">
                                <div className="block-body text-right">
                                    <Button size="lg" className="mr-3" variant="default">Cancel</Button>
                                    <Button size="lg" variant="primary">Save</Button>
                                </div>
                            </div>
                        </div>
                    </>
                    : null}


            </App>
        );
    }
}

export default AddCustomer;
