import React, {Component} from "react";
import App from "../../App";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {toast} from "react-toastify";
import {withRouter} from "react-router-dom";
import {FiEdit, FiTrash} from "react-icons/all";
import {
    addShippingOption,
    deleteShippingOptionPermanent,
    shippingOptions,
    updateShippingOption,
    getSingleShippingOption,
} from "../../services/shippingMethodServices";
import {getChildLocations} from "../../services/locationServices";
import axios from "axios";
import {API_BASE_URL} from "../../helper/env";
import Pagination from "react-js-pagination";
import {getLocalAuthData} from "../../helper/utils";
import {confirmAlert} from "react-confirm-alert";

class ShippingAddressSettings extends Component {
    state = {
        search_string: "",
        isSubmittingShippingMethods: false,
        selectedShippingPartners: [],
        shippingChildLocations: [],
        shipping_methods: [],
        shippingFormData: {},
        queryData: {
            global_setting: "",
            page: 1,
            limit: 20,
        },
        existing_locations: [],
        selectedShippingId: '',
        editStatus: false,
    };

    componentDidMount() {
        this.getShippingOptions(this.state.queryData);

        axios.get(`${API_BASE_URL}/location?type=Country`).then((res) => {
            let existing_locations = [...this.state.existing_locations];
            existing_locations[0] = res.data.locations;
            this.setState({existing_locations, locations: res.data.locations, countries: res.data.locations}, () => {
                    console.log(this.state);
                }
            );
        }).catch((e) => console.log("Error4"));

        this.setNewShippingFormData()
    }

    setNewShippingFormData = () => {
        this.setState({
            shippingFormData: {
                shipping_option: "ekShop Fulfillment",
                location_hierarchy: [],
                locations: [],
                destination_location: "",
                estimated_time: "",
                fee: 0,
            },
            existing_locations: this.state.existing_locations.splice(0, 1)
        }, () => {

        })
    }

    getShippingOptions = (params = {}) => {
        shippingOptions(params)
            .then((res) => {
                this.setState({
                    shipping_options: res.items,
                    pagination: res.pagination,
                });
            })
            .catch((errMsg) => {
                toast.error(errMsg.message);
            });
    };

    handleOnChangeShippingForm = (e) => {
        console.log(e.target.name);
        const shippingFormData = {...this.state.shippingFormData};
        shippingFormData[e.target.name] = e.target.value;
        this.setState({shippingFormData}, () => {
            console.log("this.state");
            console.log(this.state.shippingFormData);
        });
    };

    submitChangeShippingMethods = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        let shippingFormData = {...this.state.shippingFormData};

        this.setState({isSubmittingShippingMethods: true}, () => {
            if (this.state.editStatus === false) {
                addShippingOption(this.state.shippingFormData)
                    .then((res) => {
                        if (res.status === 1) {
                            this.getShippingOptions({});
                            this.setState({isSubmittingShippingMethods: false});
                            this.setNewShippingFormData()
                            toast.success(res.message);
                            window.location.reload()
                        } else {
                            this.setState({isSubmittingShippingMethods: false});
                            toast.warning(res.message);
                        }
                    })
                    .catch((errMsg) => {
                        toast.error(errMsg);
                    });
            } else if (this.state.editStatus === true && this.state.selectedShippingId !== '') {
                console.log(this.state.shippingFormData)
                console.log(this.state.selectedShippingId)
                updateShippingOption(this.state.shippingFormData, this.state.selectedShippingId).then((res) => {
                    if (res.status === 1) {
                        this.getShippingOptions({});
                        this.setState({isSubmittingShippingMethods: false});
                        this.setNewShippingFormData()
                        toast.success(res.message);
                        window.location.reload()
                    } else {
                        this.setState({isSubmittingShippingMethods: false});
                        toast.warning(res.message);
                    }
                }).catch((errMsg) => {
                    toast.error(errMsg);
                });
            } else {
                toast.warning("Required Data missing, refresh and try again or try later.");
            }
        });
    };

    handleCloseProductSettingModal = () => {
        this.setState({selectedStoreId: "", isShowingStoreSettingModal: false});
    };

    handleShippingLocationInputOnChange = (e, index, typePurpose) => {
        const existing_locations = [...this.state.existing_locations];
        if (typePurpose === "shippingChildLocations") {
        } else {
            this.handleReturnInputOnChange(e);
        }

        const shippingFormData = {...this.state.shippingFormData};
        // const updatedShippingAddress = {...this.state.updatedShippingAddress};
        let location_hierarchy = shippingFormData.location_hierarchy;
        let locations = shippingFormData.locations;

        if (e.target.value !== "") {
            if (index >= 0 && existing_locations.length > index) {
                existing_locations.splice(index + 1, existing_locations.length - index);
                location_hierarchy.splice(index, location_hierarchy.length - index);
                locations.splice(index, locations.length - index)
            }

            let selected_location_array = existing_locations[index];
            let selected_location = selected_location_array.find(
                (element) => element._id === e.target.value
            );
            location_hierarchy.push(selected_location._id);
            locations.push(selected_location)
            shippingFormData.location_hierarchy = location_hierarchy;
            shippingFormData.destination_location = selected_location._id;
            shippingFormData.locations = locations

            //   updatedShippingAddress.location_hierarchy = location_hierarchy
            //   updatedShippingAddress.locations = locations

            this.setState({shippingFormData}, () => {
                console.log(this.state.shippingFormData.location_hierarchy);
            });
            // this.setState({shippingFormData, updatedShippingAddress}, () => {
            //     console.log(this.state.shippingFormData.locations);
            // });

            getChildLocations(e.target.value)
                .then((res) => {
                    if (res.length > 0) {
                        existing_locations[index + 1] = res;
                    }
                    this.setState({existing_locations}, () => {
                        console.log("locations");
                        console.log(this.state);
                    });
                })
                .catch((errMsg) => {
                    console.log(errMsg);
                    // childLocations.splice(index, childLocations.length - index)
                    // this.setState({[typePurpose]: childLocations})
                });
        } else {
            if (index > 0 && existing_locations.length > index) {
                existing_locations.splice(index + 1, existing_locations.length - index);
                location_hierarchy.splice(index, location_hierarchy.length - 1);
                // locations.splice(index, locations.length - 1)
            }
            shippingFormData.location_hierarchy = location_hierarchy;
            // shippingFormData.locations = locations

            this.setState({shippingFormData, existing_locations}, () => {
                console.log(this.state.shippingFormData.locations);
            });
        }
    };

    editShippingOption = (id) => {
        getSingleShippingOption(id)
            .then((res) => {
                if (res.status === 1) {
                    this.setState(
                        {existing_locations: [this.state.existing_locations[0]], selectedShippingId: id, editStatus: true},
                        () => {
                            res.shipping_option.location_hierarchy.forEach((item, index) => {
                                getChildLocations(item)
                                    .then((res) => {
                                        const existing_locations = [
                                            ...this.state.existing_locations,
                                        ];
                                        existing_locations[index + 1] = res;
                                        this.setState({existing_locations});
                                    })
                                    .catch((errMsg) => {
                                        console.log(errMsg);
                                    });

                                this.setState({isShowEditShippingAddressModal: true});
                            });
                            this.setState({shippingFormData: res.shipping_option});
                        }
                    );
                } else {
                    toast.error("Error2");
                }
            })
            .catch((err) => {
                toast.error("Error3");
            });
    };

    deleteShippingOption = (id) => {
        confirmAlert({
            title: "Delete",
            message: "Are you sure to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () =>
                        deleteShippingOptionPermanent(id)
                            .then((res) => {
                                if (res.status === 1) {
                                    this.getShippingOptions({
                                        store_id: this.state.selectedStoreId,
                                    });
                                    this.setNewShippingFormData()
                                    toast.warning(res.message);
                                } else if (res.status === 2) {
                                    toast.warning(res.message);
                                }
                            })
                            .catch((errMsg) => {
                                console.log(errMsg);
                                toast.error(errMsg.message);
                            }),
                },
                {
                    label: "No",
                    // onClick: () => alert('Click No')
                },
            ],
        });
    };

    handlePageChange = async (pageNumber) => {
        this.setState(
            {
                queryData: {...this.state.queryData, page: pageNumber},
                activePage: pageNumber,
            },
            () => {
                this.getShippingOptions(this.state.queryData);
            }
        );
    };

    searchShippingOptions = async () => {
        this.getShippingOptions({
            destination_location: this.state.shippingFormData.location_hierarchy.slice(-1).pop(),
            shipping_option: this.state.shippingFormData.shipping_option
        })
    }

    searchShippingOptionsV2 = async  () => {
        this.getShippingOptions({
            search_string: this.state.search_string
        })
    }

    handleOnChangeSearchString = (e) => {
        console.log(e.target.name + " "+e.target.value);
        this.setState({
           search_string: e.target.value
        }, ()=>{
            console.log(this.state.search_string)
        })
    };

    render() {
        console.log("Formdata", this.state.shippingFormData);
        return (
            <App layout={"boxed"}>
                {getLocalAuthData()?.permission?.includes("shipping_options_add") ? (
                    <div className="card-block">


                        <Form onSubmit={this.submitChangeShippingMethods}>
                            <div className="block-header">
                                <h4 className="block-title">Add Shipping Methods</h4>

                                <div>
                                    <Button
                                        disabled={this.state.isSubmittingShippingMethods}
                                        variant="primary"
                                        type={`button`}
                                        onClick={this.searchShippingOptions}
                                        className={"btn btn-sm ml-5"}>
                                        {"Search"}
                                    </Button>

                                    <Button
                                        disabled={this.state.isSubmittingShippingMethods}
                                        variant="primary"
                                        type={`button`}
                                        onClick={this.setNewShippingFormData}
                                        className={"btn btn-sm ml-5"}>
                                        {"Reset"}
                                    </Button>

                                    <Button
                                        disabled={this.state.isSubmittingShippingMethods}
                                        variant="primary"
                                        type={`submit`}
                                        className={"btn btn-sm ml-5"}>
                                        {this.state.isSubmittingShippingMethods
                                            ? "Submitting..."
                                            : "Save"}
                                    </Button>
                                </div>
                            </div>

                            <div className="block-body">
                                <Form.Group as={Row} controlId="formShippingOptions">
                                    <Form.Label column sm="2">Shipping Option</Form.Label>
                                    <Col sm="4" md={4} lg={4}>
                                        <Form.Check
                                            custom
                                            inline
                                            type="radio"
                                            name="shipping_option"
                                            id={`shippingSellerDelivery`}
                                            label={`Seller Delivery`}
                                            value={"Seller Delivery"}
                                            onChange={(e) => this.handleOnChangeShippingForm(e)}
                                            checked={this.state.shippingFormData.shipping_option === "Seller Delivery"}
                                        />

                                        <Form.Check
                                            custom
                                            inline
                                            type="radio"
                                            name="shipping_option"
                                            id={`shippingEkshopFulfillment`}
                                            label={`ekShop Fulfillment`}
                                            value={"ekShop Fulfillment"}
                                            onChange={(e) => this.handleOnChangeShippingForm(e)}
                                            checked={this.state.shippingFormData.shipping_option === "ekShop Fulfillment"}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formCountry">
                                    {this.state?.existing_locations.length > 0 &&
                                    this.state?.existing_locations.map((location, index) => {
                                        return (
                                            <>
                                                {location?.length > 0 && (
                                                    <>
                                                        <Form.Label column sm="2">
                                                            {location[0].type}
                                                        </Form.Label>
                                                        <Col sm="4" md={4} lg={4}>
                                                            <Form.Control
                                                                as="select"
                                                                className="mb-3"
                                                                name={location[0].type.toLowerCase()}
                                                                required={location[0].type === 'Country' ? true : false}
                                                                value={
                                                                    this.state.shippingFormData
                                                                        ?.location_hierarchy[index]
                                                                        ? this.state.shippingFormData
                                                                            ?.location_hierarchy[index]
                                                                        : ""
                                                                }
                                                                onChange={(e) =>
                                                                    this.handleShippingLocationInputOnChange(
                                                                        e,
                                                                        index,
                                                                        "shippingChildLocations"
                                                                    )
                                                                }
                                                            >
                                                                <option value="">--Select--</option>
                                                                {location.map((item, index) => (
                                                                    <option key={index} value={item._id}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </Form.Control>

                                                            <Form.Control.Feedback type="invalid">
                                                                Please select {location[0].type.toLowerCase()}
                                                                .
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                    </>
                                                )}
                                            </>
                                        );
                                    })}
                                </Form.Group>

                                <Form.Group key={"deliveryFee"} as={Row}>
                                    <Form.Label column sm="2">
                                        Fee <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="4" md={4} lg={4}>
                                        <Form.Control
                                            // key={`${Math.floor((Math.random() * 1000))}-min`}
                                            type="number"
                                            onChange={(e) => this.handleOnChangeShippingForm(e)}
                                            defaultValue={this.state.shippingFormData?.fee}
                                            name={"fee"}
                                            id={`deliveryFee`}
                                            min={0}
                                            step={0.5}
                                            required
                                            placeholder={`Enter Delivery Fee`}
                                        />
                                    </Col>

                                    <Form.Label column sm="2">
                                        Estimated Time <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="4" md={4} lg={4}>
                                        <Form.Control
                                            type="text"
                                            onChange={(e) => this.handleOnChangeShippingForm(e)}
                                            defaultValue={this.state.shippingFormData?.estimated_time}
                                            name={"estimated_time"}
                                            id={`estimatedTime`}
                                            required
                                            placeholder={`Estimated Delivery Time`}
                                        />
                                    </Col>
                                </Form.Group>
                            </div>
                        </Form>
                    </div>
                ) : null}
                <div className="card-block">
                    <div className="block-header">
                        <h4 className="block-title">Available Shipping Options</h4>

                            <Form.Control
                                style={{ width: "30%" , marginLeft: "5%"}}
                                type="text"
                                onChange={(e) => this.handleOnChangeSearchString(e)}
                                onKeyDown={ (e)=>{
                                    if (e.keyCode === 13){
                                        this.searchShippingOptionsV2()
                                    }
                                }}
                                placeholder={`Search`}/>

                            <Button
                                style={{marginRight: "auto"}}
                                disabled={this.state.isSubmittingShippingMethods}
                                variant="primary"
                                type={`button`}
                                onClick={this.searchShippingOptionsV2}
                                className={"btn btn-sm ml-5"}>
                                {"Search Location"}
                            </Button>


                    </div>



                    <div className="block-body">
                        {this.state.isLoading ? (
                            <>Loading...</>
                        ) : (
                            <>
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th style={{width: "20px"}}>#</th>
                                        <th>Shipping Option</th>
                                        <th>Location</th>
                                        <th>Fee</th>
                                        <th>Time</th>
                                        <th style={{width: `1%`}}/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {getLocalAuthData()?.permission?.includes(
                                        "shipping_options_list"
                                    ) && this.state.shipping_options?.length > 0 ? (
                                        this.state.shipping_options.map((item, index) => (
                                            <tr key={index}>
                                                <td>{this.state.pagination.pagingCounter + index}</td>
                                                <td>
                                                    {item.shipping_option}
                                                    &nbsp;{" "}
                                                    {!item?.store_id && !item?.product_id && (
                                                        <span className={"badge badge-primary"}>
                                                          Global Settings
                                                        </span>
                                                    )}
                                                </td>
                                                <td>{item?.destination_location_info}</td>
                                                <td>{item?.fee}</td>
                                                <td>{item?.estimated_time}</td>

                                                <td className="nowrap text-center">

                                                    {getLocalAuthData()?.permission?.includes("shipping_options_add") ? (
                                                        <span className="action text-bold text-default mr-3 pointer-event btn btn-outline-info btn-sm"
                                                              onClick={() => this.editShippingOption(item._id)}>
                                                          <FiEdit/>{"Edit"}
                                                        </span>
                                                    ) : null}

                                                    {getLocalAuthData()?.permission?.includes("shipping_options_delete") ? (
                                                        <Button size={`sm`} onClick={() => this.deleteShippingOption(item._id)} variant={`outline-danger`}><FiTrash/>Delete</Button>
                                                    ) : null}

                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={7} className={`text-bold text-center`}>
                                                No item found
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                                <div className="mt-3">
                                    <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={this.state?.pagination?.page}
                                        itemsCountPerPage={this.state?.pagination?.limit}
                                        totalItemsCount={this.state?.pagination?.totalDocs}
                                        pageRangeDisplayed={20}
                                        onChange={this.handlePageChange.bind(this)}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </App>
        );
    }
}

export default withRouter(ShippingAddressSettings);
