import {getDeviceType, getLocalAuthData} from "../helper/utils";
import axios from "axios";
import {API_BASE_URL} from "../helper/env";

export const updateStorePaymentMethods = (data, id) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.patch(API_BASE_URL + `/store/update-available-payment-methods/${id}`, data, {
            headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject('Something went wrong')
        })
    })
}

export const updateProductPaymentMethods = (data, id) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.patch(API_BASE_URL + `/product/update-available-payment-methods/${id}`, data, {
            headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject('Something went wrong')
        })
    })
}

export const storePaymentMethods = (id) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + `/store/available-payment-methods/${id}`, {
            headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject('Something went wrong')
        })
    })
}

export const productPaymentMethods = (id) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + `/product/available-payment-methods/${id}`, {
            headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject('Something went wrong')
        })
    })
}