import React, {Component} from "react"
import {Link, withRouter} from "react-router-dom"
import App from "../../App"
import {FiPlus, HiOutlineClipboardList} from "react-icons/all"
import {getLocalAuthData} from "../../helper/utils";
import axios from "axios";
import {API_BASE_URL} from "../../helper/env";
import {toast} from "react-toastify";
import LoadingSpinner from "../../components/LoadingSpinner";
import {Button} from "react-bootstrap";
import {confirmAlert} from "react-confirm-alert";
import {deleteCollection, deletePackage, getCollectionList} from "../../services/collectionServices";
import {activityLogPostData} from "../../services/commonServices";
import {RiMovie2Line} from "@react-icons/all-files/ri/RiMovie2Line";
import {FaRegCreditCard} from "@react-icons/all-files/fa/FaRegCreditCard";

// noinspection DuplicatedCode
class HoichoiServiceList extends Component {
    state = {
        packages: [],
        isLoading: true
    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        axios.get(`${API_BASE_URL}/hoichoi`).then(res => {
            this.setState({
                packages: res.data.items,
                isLoading: false
            })
        }).catch(err => {
            toast.error(err.message)
        })
    }

    handleDeleteCollection = (id, name) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure to delete this package?',
            buttons: [{
                label: 'Yes',
                onClick: () => deletePackage(id)
                    .then(() => {
                        toast.success('Successfully deleted');
                        activityLogPostData('was delete this hoichoi service', '/services/hoichoi/list', name)
                        this.getData()
                    })
                    .catch(errMsg => {
                        toast.error(errMsg)
                    })
            }, {
                label: 'No',
            }]
        });
    }

    render() {
        return (
            <App layout="boxed">
                <div className="main-content">

                    <div className="d-flex justify-content-center align-items-center">
                        {getLocalAuthData()?.permission?.includes('menu_orders_list') ?
                            <li className="list-unstyled mx-3"><Link to={'/orders/hoichoi-subscription'} class={"d-flex justify-content-center align-items-center flex-column"}><span className="card nav-icon card-body" style={{borderRadius: "10px"}}><HiOutlineClipboardList size={35}/></span> <span className="nav-text">Hoichoi Servie Order list</span></Link>
                            </li> : null}
                        {getLocalAuthData()?.permission?.includes('menu_orders_list') ?
                            <li className="list-unstyled mx-3"><Link to={'/services/hoichoi/list'} class={"d-flex justify-content-center align-items-center flex-column"}><span className="card nav-icon card-body" style={{borderRadius: "10px"}}><RiMovie2Line size={35}/></span> <span className="nav-text">Hoichoi Package</span></Link>
                            </li> : null}

                        {getLocalAuthData()?.permission?.includes('menu_orders_list') ?
                            <li className="list-unstyled mx-3"><Link to={'/services/hoichoi/buy'} class={"d-flex justify-content-center align-items-center flex-column"}><span className="card nav-icon card-body" style={{borderRadius: "10px"}}><FaRegCreditCard size={35}/></span> <span className="nav-text">Hoichoi Bulk Purchase</span></Link>
                            </li> : null}
                    </div>

                    <div className="page-header">
                        <h2 className="page-title">Hoichoi Services List</h2>
                        <div className="ml-auto">
                            <Link to={`${process.env.PUBLIC_URL}/services/hoichoi/add`}
                                  className="btn btn-sm btn-secondary"><FiPlus/> Add
                                Service</Link>
                        </div>
                    </div>

                    <div className="card-block position-relative">
                        <div className="block-body">
                            <React.Fragment>
                                {
                                    this.state.isLoading ? (
                                        <LoadingSpinner/>
                                    ) : (
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th style={{width: '1%'}}>#</th>
                                                <th>Name</th>
                                                <th>Price (Old price)</th>
                                                <th>Plan Code</th>
                                                <th>Time</th>
                                                <th>Device</th>
                                                <th>Simultaneous</th>
                                                <th/>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.packages.length > 0 ?
                                                    this.state.packages.map((eachPackage, index) => {
                                                        return (
                                                            <tr key={'packages-' + index}>
                                                                <td>{index + 1}</td>
                                                                <td>{eachPackage?.name}</td>
                                                                <td>{eachPackage?.price} ({eachPackage?.old_price})</td>
                                                                <td>{eachPackage?.planCode}</td>
                                                                <td>{eachPackage?.time} {eachPackage?.time_type === '1' ? 'Days' : eachPackage?.time_type === '2' ? 'Months' : 'Years'}</td>
                                                                <td>{eachPackage?.device}</td>
                                                                <td>{eachPackage?.simultaneous}</td>
                                                                <td className="nowrap">
                                                                    <Link
                                                                        to={`/services/hoichoi/edit/${eachPackage?._id}`}
                                                                        style={{marginRight:'10px'}}
                                                                        className="btn btn-outline-warning btn-sm">Edit</Link>
                                                                    <button type="button" className="btn btn-outline-danger btn-sm"
                                                                            onClick={() => this.handleDeleteCollection(eachPackage._id, eachPackage?.name)}>Delete
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }) : (
                                                <tr>
                                                <td colSpan={8}>No dispute found</td>
                                                </tr>
                                                )
                                                }
                                                </tbody>
                                                </table>
                                                )
                                            }
                                            </React.Fragment>
                                        </div>
                                    </div>
                                    </div>
                                    </App>
                                    )
                                }
                                }

    export
    default

    withRouter(HoichoiServiceList)
