import React, { Component } from 'react';
import App from "../../App";
import { getStoreDetails, submitIntegrationSetting } from "../../services/storeServices";
import { saveData, getTableData, deleteItem, updateItemPartial, getDetail } from "../../services/commonServices";
import { Link, withRouter } from "react-router-dom";
import { FiPlus, MdArrowBack } from "react-icons/all";
import moment from "moment";
import Product from "../../components/Product";
import Pagination from "react-js-pagination";
import { confirmAlert } from "react-confirm-alert";
import {
    changeProductPublishStatus,
    changeProductStatus,
    deleteProduct, getProductList,
    getProductListSecondary,
    hardDeleteProduct,
    revertDeletedProduct
} from "../../services/productServices";
import { toast } from "react-toastify";
import { Alert, Button, Col, Form, Row, Modal } from "react-bootstrap";
import { EP_INTEGRATION_BASE_URL } from "../../helper/env";
import styleReactSelect from "../../assets/styleReactSelect";
import Select from "react-select";
import { submitCampaign } from "../../services/promotionServices";
import { getLocalAuthData } from "../../helper/utils";
import { size } from 'underscore';

class StoreDetails extends Component {
    state = {
        isLoading: true,
        activeBlockTab: 'Store Detail',
        storeDetails: {
            warehouse_locations: [],
            integration_settings: {}
        },
        notExisting: false,

        productQueryData: { limit: 20, page: 1, search_string: '' },
        orderQueryData: { limit: 20, page: 1, order_number: '' },
        store_products: [],
        pagination: {},

        platform_technologies: [
            { id: 1, value: 'Wordpress', label: 'Wordpress' },
            { id: 2, value: 'PrestaShop', label: 'PrestaShop' },
            { id: 3, value: 'Megento', label: 'Megento' },
            { id: 4, value: 'NodeJS', label: 'NodeJS' },
            { id: 5, value: 'ASP.net', label: 'ASP.net' },
            { id: 6, value: 'Java', label: 'java' },
            { id: 7, value: 'Rubi on Rail', label: 'Rubi on Rail' },
            { id: 8, value: 'Python Django', label: 'Python Django' }
        ],

        isIntegrationSettingValidated: false,
        isSubmitting: false,
        isShowingModal: false,

    }

    handleShowModal = () => {
        this.setState({ isShowingModal: true })
    };

    handleModalClose = () => {
        this.setState({ isShowingModal: false });
    };


    componentDidMount() {
        const storeId = this.props.match.params.id

        getStoreDetails(storeId).then(res => {
            let productQueryData = { ...this.state.productQueryData, store_id: res._id }
            let orderQueryData = { ...this.state.productQueryData, store_id: res._id }
            this.setState({ storeDetails: res, isLoading: false, orderQueryData, productQueryData }, () => {
                this.getStoreProducts()
                this.getStoreOrders()
                this.getStoreApiSetting()
            })
        }).catch(errMsg => {
            this.setState({ isLoading: false, notExisting: true })
            console.log(errMsg)
            this.setState({ isLoading: false })
        })
    }

    getStoreProducts = () => {
        getProductListSecondary({ ...this.state.productQueryData }).then(res => {
            this.setState({ store_products: res.products, isLoading: false, pagination: res.pagination }, () => {
                console.log(this.state.store_products);
            })
        }).then(errMsg => {
            this.setState({ isLoading: false })
        })
    }

    getStoreOrders = () => {

    }

    getStoreApiSetting = () => {
        getDetail(`/store/get-api-setting/${this.state.storeDetails._id}`, {}).then(res => {
            this.setState({ integration_setting: res.integration_setting })
        }).catch(errMsg => {
            console.log(errMsg);
            toast.error("Something went wrong please refresh and try again");
        })
    }

    handleBlockTab = (label) => {
        this.setState({ activeBlockTab: label });
    }


    /* START PRODUCT LIST RELATED */

    handleProductDelete = (id) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure to delete this product?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteProduct(id)
                        .then(msg => {
                            toast.success(msg);
                            getProductListSecondary({
                                ...this.state.queryData,
                                page: this.state.productList.length === 1 ? this.state.queryData.page - 1 : this.state.queryData.page,
                                limit: 10
                            }).then(res => {
                                this.setState({
                                    ...this.state.queryData,
                                    productList: res.products,
                                    isLoading: false,
                                    pagination: res.pagination
                                })
                            })
                        })
                        .catch(errMsg => {
                            toast.error(errMsg)
                        })
                },
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });
    }

    handleRevertDeletedProduct = (id) => {
        confirmAlert({
            title: 'Revert Deleted Product',
            message: 'Are you sure to revert deleted product?',
            buttons: [
                {
                    label: 'Revert',
                    onClick: () => revertDeletedProduct(id).then(res => {
                        if (res.status === 1) {
                            toast.success('Successfully deleted');
                            getProductListSecondary({
                                ...this.state.queryData,
                                page: this.state.productList.length === 1 ? this.state.queryData.page - 1 : this.state.queryData.page,
                                limit: 10
                            }).then(res => {
                                this.setState({ productList: res.products, isLoading: false, pagination: res.pagination })
                            })
                        } else {
                            toast.warning(res.message);
                        }
                    }).catch(errMsg => {
                        toast.error(errMsg)
                    })
                },
                { label: 'No' }
            ]
        });
    }

    handleHardDeleteProduct = (id) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure to delete this product?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => hardDeleteProduct(id)
                        .then(msg => {
                            toast.success(msg);
                            getProductListSecondary({
                                ...this.state.queryData,
                                page: this.state.productList.length === 1 ? this.state.queryData.page - 1 : this.state.queryData.page,
                                limit: 10
                            })
                                .then(res => {
                                    this.setState({
                                        productList: res.products,
                                        isLoading: false,
                                        pagination: res.pagination
                                    })
                                })
                            /*getProductList()
                              .then(res => {
                                this.setState({productList: res.products, isLoading: false, pagination: res.pagination})
                              })*/
                        })
                        .catch(errMsg => {
                            toast.error(errMsg)
                        })
                },
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });
    }

    handlePageChange = (pageNumber) => {
        this.setState({ productQueryData: { ...this.state.productQueryData, page: pageNumber } }, () => {
            this.getStoreProducts();
        });
    }

    handleProductStatus = (id, status) => {
        confirmAlert({
            title: 'Change Status',
            message: 'Are you sure to change this product status?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => changeProductStatus(id, status)
                        .then(msg => {
                            toast.success(msg);
                            getProductListSecondary({
                                ...this.state.queryData,
                                limit: 10
                            })
                                .then(res => {
                                    this.setState({
                                        productList: res.products,
                                        isLoading: false,
                                        pagination: res.pagination
                                    })
                                })
                        })
                        .catch(errMsg => {
                            toast.error(errMsg)
                        })
                },
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });
    }

    handleProductPublishStatus = (id, status) => {
        console.log(id);
        confirmAlert({
            title: 'Publish/Unpublish Status',
            message: 'Are you sure to change this product status?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => changeProductPublishStatus(id, status)
                        .then(msg => {
                            toast.success(msg);
                            getProductListSecondary({
                                ...this.state.queryData,
                                limit: 10
                            })
                                .then(res => {
                                    this.setState({
                                        productList: res.products,
                                        isLoading: false,
                                        pagination: res.pagination
                                    })
                                })
                        })
                        .catch(errMsg => {
                            toast.error(errMsg)
                        })
                },
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });
    }

    /* END PRODUCT LIST RELATED */


    /* START HANDLE STORE SETTING */

    handleAPIInputOnChange = (e) => {
        const integrationSettingData = { ...this.state.integrationSettingData }
        integrationSettingData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({ integrationSettingData }, () => {
            console.log(this.state.integrationSettingData);
        })
    }

    handleAPISelectOnChange = (value, name) => {
        const integrationSettingData = { ...this.state.integrationSettingData };
        integrationSettingData[name] = value;
        this.setState({ integrationSettingData })
    }

    handleSubmitIntegrationSetting = (e) => {

        e.preventDefault();
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ isIntegrationSettingValidated: true })
        } else {

            this.setState({ isSubmitting: true });
            saveData(`/store/save-api-setting/${this.state.storeDetails._id}`, { ...this.state.integrationSettingData }).then(res => {
                if (res.status === 2) {
                    toast.warning(res.message);
                } else {
                    toast.success(res.message);
                }
                this.setState({ isSubmitting: false, isIntegrationSettingValidated: false })
            }).catch(errMsg => {
                toast.error(errMsg)
                this.setState({ isSubmitting: false, isIntegrationSettingValidated: false })
            })

        }
    }

    viewApiDetail = (apiTag) => {
        console.log(apiTag);
    }

    /* END HANDLE STORE SETTING */

    render() {
        const { integration_setting, storeDetails } = this.state
        return (
            <App layout={"boxed"}>
                <div className="main-content">
                    <div className="page-header">
                        <h2 className="page-title">Store Details</h2>
                        <div className="ml-auto">
                            <Link to={`${process.env.PUBLIC_URL}/entrepreneurs/list`}
                                className={`btn btn-sm btn-link`}><MdArrowBack /> Back to List</Link>
                            {getLocalAuthData()?.permission?.includes('create_stores') ? <Link to={`${process.env.PUBLIC_URL}/stores/list`}
                                className="btn btn-sm btn-secondary"><FiPlus /> Add
                                Store</Link> : null}
                        </div>
                    </div>


                    {getLocalAuthData()?.permission?.includes('store_view') ?
                        <div className="card-block">

                            <ul className="desc-header-nav">
                                <li className={this.state.activeBlockTab === 'Store Detail' ? 'active' : ''} value="">
                                    <span onClick={() => this.handleBlockTab('Store Detail')}>Store Detail</span>
                                </li>
                                <li className={this.state.activeBlockTab === 'Products' ? 'active' : ''} value="">
                                    <span onClick={() => this.handleBlockTab('Products')}>Products</span>
                                </li>
                                <li className={this.state.activeBlockTab === 'Assisted Seller' ? 'active' : ''} value="">
                                    <span onClick={() => this.handleBlockTab('Assisted Seller')}>Assisted Seller</span>
                                </li>
                                <li className={this.state.activeBlockTab === 'API Settings' ? 'active' : ''} value="">
                                    <span onClick={() => this.handleBlockTab('API Settings')}>API Settings</span>
                                </li>
                            </ul>

                            {this.state.activeBlockTab === 'Store Detail' &&
                                <div className="block-body">
                                    <table className="info-table">
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '250px' }}>Merchant</td>
                                                <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                <td className={`text-bold`}>{storeDetails?.merchant?.name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '250px' }}>Store Name</td>
                                                <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                <td className={`text-bold`}>{storeDetails?.store_name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '250px' }}>Logo</td>
                                                <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                <td className={`text-bold`}>
                                                    <img src={storeDetails?.store_logo} style={{ maxWidth: '100%', height: "auto" }}
                                                        alt="" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '250px' }}>Category</td>
                                                <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                <td className={`text-bold`}>{storeDetails?.category_info?.name}</td>
                                            </tr>

                                            <tr>
                                                <td style={{ width: '250px' }}>Address</td>
                                                <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                <td className={`text-bold`}>{storeDetails?.address}</td>
                                            </tr>

                                            {storeDetails?.locations && (storeDetails?.locations?.length > 0 &&
                                                storeDetails?.locations.map((location, index) => (
                                                    <tr key={index}>
                                                        <td style={{ width: '250px' }}>{location?.type}</td>
                                                        <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                        <td className={`text-bold`}>{location?.name}</td>
                                                    </tr>
                                                ))
                                            )}

                                            <tr>
                                                <td style={{ width: '250px' }}>Postcode</td>
                                                <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                <td className={`text-bold`}>{storeDetails?.postcode}</td>
                                            </tr>

                                            <tr>
                                                <td style={{ width: '250px' }} />
                                                <td style={{ width: '20px', textAlign: 'center' }} />
                                                <td className={`text-bold text-info`}><strong>Warehouse Location</strong></td>
                                            </tr>


                                            {storeDetails?.warehouse_locations.length > 0 &&
                                                <>
                                                    <tr>
                                                        <td style={{ width: '250px' }}>Address</td>
                                                        <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                        <td className={`text-bold`}>{storeDetails?.warehouse_locations[0]?.address}</td>
                                                    </tr>

                                                    <tr>
                                                        <td style={{ width: '250px' }}>Address</td>
                                                        <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                        <td className={`text-bold`}>{storeDetails?.warehouse_locations[0]?.address}</td>
                                                    </tr>

                                                    {storeDetails?.warehouse_locations[0]?.array && (storeDetails?.warehouse_locations[0]?.array?.length > 0 &&
                                                        storeDetails?.warehouse_locations[0]?.array?.map((location, index) => (
                                                            <tr key={index}>
                                                                <td style={{ width: '250px' }}>{location?.type}</td>
                                                                <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                                <td className={`text-bold`}>{location?.name}</td>
                                                            </tr>
                                                        ))
                                                    )}

                                                    <tr>
                                                        <td style={{ width: '250px' }}>Postcode</td>
                                                        <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                        <td className={`text-bold`}>{storeDetails?.warehouse_locations[0]?.postcode}</td>
                                                    </tr>
                                                </>
                                            }


                                            <tr>
                                                <td style={{ width: '250px' }} />
                                                <td style={{ width: '20px', textAlign: 'center' }} />
                                                <td className={`text-bold text-info`}><strong>Return Location</strong></td>
                                            </tr>

                                            <tr>
                                                <td style={{ width: '250px' }}>Address</td>
                                                <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                <td className={`text-bold`}>{storeDetails?.return_location?.address}</td>
                                            </tr>

                                            {storeDetails?.return_location?.array && (storeDetails?.return_location?.array?.length > 0 &&
                                                storeDetails?.return_location?.array?.map((location, index) => (
                                                    <tr key={index}>
                                                        <td style={{ width: '250px' }}>{location?.type}</td>
                                                        <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                        <td className={`text-bold`}>{location?.name}</td>
                                                    </tr>
                                                ))
                                            )}

                                            <tr>
                                                <td style={{ width: '250px' }}>Postcode</td>
                                                <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                <td className={`text-bold`}>{storeDetails?.return_location?.postcode}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>}

                            {this.state.activeBlockTab === 'Assisted Seller' &&
                                <div className="block-body">
                                    <table className="info-table">
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '250px' }}>Name</td>
                                                <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                <td className={`text-bold`}>{storeDetails?.assisted_seller_info?.name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '250px' }}>Phone</td>
                                                <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                <td className={`text-bold`}>{storeDetails?.assisted_seller_info?.phone}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '250px' }}>Address</td>
                                                <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                <td className={`text-bold`}>{storeDetails?.assisted_seller_info?.address}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '250px' }}>Postcode</td>
                                                <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                <td className={`text-bold`}>{storeDetails?.assisted_seller_info?.postcode}</td>
                                            </tr>


                                            {storeDetails?.assisted_seller_info?.locations && (storeDetails?.assisted_seller_info?.locations?.length > 0 &&
                                                storeDetails?.assisted_seller_info?.locations.map((location, index) => (
                                                    <tr key={index}>
                                                        <td style={{ width: '250px' }}>{location?.type}</td>
                                                        <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                        <td className={`text-bold`}>{location?.name}</td>
                                                    </tr>
                                                ))
                                            )}
                                            <tr>
                                                <td style={{ width: '250px' }}>Birthdate</td>
                                                <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                <td className={`text-bold`}>{storeDetails?.assisted_seller_info?.birthdate}</td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </div>}


                            {this.state.activeBlockTab === 'Products' && <>
                                <div className="block-body">
                                    {this.state.store_products?.length > 0 ?
                                        <>

                                            <div className="product-list mt-3">
                                                {this.state.store_products.map((product, index) => (
                                                    <div key={index} className="product-item">
                                                        <Product
                                                            productDelete={this.handleProductDelete}
                                                            handleRevertDeletedProduct={this.handleRevertDeletedProduct}
                                                            productHardDelete={this.handleHardDeleteProduct}
                                                            handleProductStatus={this.handleProductStatus}
                                                            handleProductPublishStatus={this.handleProductPublishStatus}
                                                            data={product} />
                                                    </div>
                                                ))}
                                            </div>

                                            <div className="mt-3">
                                                <Pagination
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    activePage={this.state.pagination?.page}
                                                    itemsCountPerPage={this.state.pagination?.limit}
                                                    totalItemsCount={this.state.pagination?.totalDocs}
                                                    pageRangeDisplayed={10}
                                                    onChange={this.handlePageChange.bind(this)}
                                                />
                                            </div>
                                        </>
                                        :
                                        <div className="alert alert-warning text-bold text-center">
                                            No product added yet. Please <Link
                                                to={`${process.env.PUBLIC_URL}/products/add`}>Add
                                            product</Link>
                                        </div>
                                    }
                                </div>

                            </>
                            }


                            {this.state.activeBlockTab === 'API Settings' &&
                                <div className="block-body">
                                    {this.state.isLoading ?
                                        <div>Loading...</div>
                                        :
                                        <>
                                            {this.state.notExisting ?
                                                <div className={`alert alert-warning text-bold text-center`}>
                                                    Store doesn't exist
                                            </div>
                                                :
                                                <>
                                                    <div className="table-responsive">
                                                        <Form onSubmit={this.handleSubmitIntegrationSetting}>
                                                            <table className="info-table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ width: '250px' }} />
                                                                        <td style={{ width: '20px', textAlign: 'center' }} />
                                                                        <td className={`text-bold`}>
                                                                           
                                                                             Integration Setting (ekShop Inbound)
                                                                </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td style={{ width: '250px' }}>ekShop API Base Path</td>
                                                                        <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                                        <td>{EP_INTEGRATION_BASE_URL}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td style={{ width: '250px' }}>ekShop Product Sync API</td>
                                                                        <td style={{ width: '20px', textAlign: 'center' }}>:</td>

                                                                        <td>{`${EP_INTEGRATION_BASE_URL}/partner/productSync`}
                                                                            {/* Start */}


                                                                            {/*  */}
                                                                            <Modal size="lg" show={this.state.isShowingModal} onHide={() => this.handleModalClose()} animation={true}>
                                                                                <form>
                                                                                    <Modal.Header closeButton>
                                                                                        <Modal.Title>
                                                                                            Product Synchronization
                                                                                    </Modal.Title>
                                                                                    </Modal.Header>
                                                                                    <Modal.Body>
 <pre>{`
API Link:{EkshopApiBaseURL}/partner/productSync
Method:POST
Headers: {Content-Type: "application/json",
x-auth-token:"x-auth-token will be provided in get session api"}
`}
</pre><div style={{ backgroundColor:'#d9d9d9', marginTop:'40px' }}>
<pre> 
<h5 style={{ color:'#FF9B0D',marginLeft:'15px', marginTop:'15px'}} >Request Body:</h5>
    {`  
    [
          {
            "product_reference_id": "50084",
            "name": "8 in 1 Multifunctional Foldable Cloth Hanger",
            "category": "Appliances & Decoration",
            "brand": "", 
            "model":"",
            "keywords": [],
            "highlights":"8 In 1 multifunctional foldable cloth hanger has 360 degree rotation.
             You can keep on all your clothes on this particular foldable
             hanger.",  
             `}</pre>
             <pre>
             "product_description":
            <ul>
                <li>Retractable hanger.</li>
                <li>Pull clothes off easily.</li>
                <li>Fits in any closet.</li>
                <li>Savestons of space while keeping your closet neat and organized.</li>
                <li>Color: As same as picture.</li>
            </ul>
             </pre>
             
<pre>{`
 "video_url":"",
 "images": [
 {
    "actual_img":
        "https://s3-ap-southeast-1.amazonaws.com/priyoshop/content/Images/thumbs/0083134_8-in-1-
        multifunctional-foldable-cloth-hanger_550.jpeg",
    "mid_img":
        "https://s3-ap-southeast-1.amazonaws.com/priyoshop/content/Images/thumbs/0083134_8-in-1-
        multifunctional-foldable-cloth-hanger_415.jpeg",
    "thumb_img":
        "https://s3-ap-southeast-1.amazonaws.com/priyoshop/content/Images/thumbs/0083134_8-in-1-
        multifunctional-foldable-cloth-hanger_220.jpeg"
 }
 ],
 "attributes": [
     {
         "attribute_name": "Color",   
         "data": [{"val": "Black"}, {"val":  "Blue"}, {"val":"White"}]
     },
     {
           "attribute_name": "size",
           "data": [{"val": "S"},  {"val": "M"},  {"val":"L"}]
     }
 ],
 "sku_information":  [
     {    
         "is_enabled": true,
         "is_default": true,
         "attr_combo": ["Black","S"],
         "sku":"S-1088-50084",
         "sku_reference_id":"S-1088-50084",
         "quantity": 100,
          "minimum_order_quantity": 10
          "maximum_order_quantity":20, 
          "price": 1190,
          "special_price": 0,
           "images": null
     }
  ],
 "faq":  [], 
 "deleted": false,
 "publish_status":2
}   
] 
`}</pre> 
</div> 
<div style={{ backgroundColor:'#f2f2f2',marginTop:'50px', marginBottom:'50px' }}>     
<ul >
    <li>product_reference_id is your system id for product identification</li>
    <li>keywords is an array of string</li>
    <li>sku_reference_id is your system id for identify product sku/variant</li>
    <li>price and special_price in sku_information object: price is defined as regular price.If any product has a sell price that will be the special price</li>
    <li>this is a number type field, possible value 1 or 2. 1= simple product, 2 = variable product</li>
    <li>attributes:Could be null. If skype is 2 for a variable product then "attributes"field will be mandatory.</li>
    <li>publish_status:1 = Drafted, 2= Published, 3= Unpublished</li>
</ul>
</div>
 <div style={{ backgroundColor:'#d9d9d9'}}>
 <h5 style={{ color:'#FF9B0D', marginLeft:'15px', paddingTop:'15px'}} > Response:</h5><pre>{`
{ 
    "request_status":200,
    "message":"success",
    "data":[
        {
            "product_reference_id":"725699"
            "publish_status":2,
            "deleted":false,
            "sku_information":[
                {
                    "is_enabled":true,
                    "sku_reference_id":"S-1088-50084",
                    "quantity":100,
                    "price":1190,
                    "special_price":0,
                }
        ]
    },

    {
        "product_reference_id":"725699"
        "publish_status":2,
        "deleted":false,
        "sku_information":[
            {
                "is_enabled":true,
                "sku_reference_id":"S-1088-50084",
                "quantity":100,
                "price":1190,
                "special_price":0,
            }
        ]
    }
]
}
`}
     </pre>
 </div>
                                                                                        
                                                                                    </Modal.Body>

                                                                                    <Modal.Footer>
                                                                                        <Button variant="secondary" onClick={() => this.handleModalClose()}>
                                                                                            Close
                                                                                    </Button>

                                                                                    </Modal.Footer>

                                                                                </form>

                                                                            </Modal>
                                                                            {/* <span className={"ml-3 btn btn-sm btn-outline-success"}
                                                                                onClick={() => this.viewApiDetail('product-sync')}>API Detail</span> */}
                                                                            {/* end */}
                                                                            <span className={"ml-3 btn btn-sm btn-outline-success"}
                                                                                onClick={() => this.handleShowModal()}>API Detail</span>
                                                                            <br /><strong>Method: </strong> POST
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td style={{ width: '250px' }}>Update Product Information</td>
                                                                        <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                                        <td>
                                                                            {`${EP_INTEGRATION_BASE_URL}/partner/productSync`}
                                                                            <span className={"ml-3 btn btn-sm btn-outline-success"}
                                                                               >API Detail</span>
                                                                            <br /><strong>Method: </strong> PATCH
                                                                </td>
                                                                    </tr>


                                                                    <tr>
                                                                        <td style={{ width: '250px' }} />
                                                                        <td style={{ width: '20px', textAlign: 'center' }} />
                                                                        <td className={`text-bold`}>
                                                                            Integration Setting (ekShop OutBound API)
                                                                </td>
                                                                    </tr>

                                                                    {/*<tr>
                                                            <td style={{width: '250px'}}>Base Technology</td>
                                                            <td style={{width: '20px', textAlign: 'center'}}>:</td>
                                                            <td>
                                                                <Select
                                                                    styles={styleReactSelect}
                                                                    placeholder="Select Technology..."
                                                                    onChange={(value) => {
                                                                        this.handleAPISelectOnChange(value, 'platform_technology')
                                                                    }}
                                                                    options={this.state.platform_technologies}
                                                                    name="platform_technology"
                                                                    noOptionsMessage={() => 'Select Technology...'}
                                                                />
                                                            </td>
                                                        </tr>*/}

                                                                    <tr>
                                                                        <td style={{ width: '250px' }}>Partner API Base Path</td>
                                                                        <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                                        <td>
                                                                            <Form.Control type={`string`} required
                                                                                onChange={this.handleAPIInputOnChange}
                                                                                defaultValue={integration_setting?.api_base_path}
                                                                                name={'api_base_path'}
                                                                                min={1} max={1000}
                                                                                placeholder={`API Base Path`} />
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td style={{ width: '250px' }}>Sync Product Info <span
                                                                            className="text-danger">*</span></td>
                                                                        <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                                        <td>
                                                                            <Form.Control type={`string`} required
                                                                                onChange={this.handleAPIInputOnChange}
                                                                                defaultValue={integration_setting?.sync_product_info}
                                                                                name={'sync_product_info'}
                                                                                min={1} max={1000}
                                                                                placeholder={`Sync Product Info`} />
                                                                            <strong>Method: </strong> PATCH
                                                                </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td style={{ width: '250px' }}>Place Order API</td>
                                                                        <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                                        <td>
                                                                            <Form.Control type={`string`} required
                                                                                onChange={this.handleAPIInputOnChange}
                                                                                defaultValue={integration_setting?.place_order_api}
                                                                                name={'place_order_api'}
                                                                                min={1} max={1000}
                                                                                placeholder={`Place Order API`} />
                                                                            <strong>Method: </strong> POST
                                                                </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td style={{ width: '250px' }}>Sync Order Info</td>
                                                                        <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                                                                        <td>
                                                                            <Form.Control type={`string`}
                                                                                onChange={this.handleInputOnChange}
                                                                                defaultValue={integration_setting?.sync_order_info}
                                                                                name={'sync_order_info'}
                                                                                min={1} max={1000}
                                                                                placeholder={`Sync Order Info`} />
                                                                            <strong>Method: </strong> PATCH
                                                                </td>
                                                                    </tr>


                                                                </tbody>
                                                            </table>

                                                            <Form.Group as={Row} controlId="formStore">
                                                                <Form.Label column sm="3" />
                                                                <Col sm="9" md={8} lg={7}>
                                                                    <Button type={`submit`}
                                                                        disabled={this.state.isSubmitting}
                                                                        variant={'primary'}>{this.state.isSubmitting ? 'Updating...' : 'Update'}</Button>
                                                                </Col>
                                                            </Form.Group>
                                                        </Form>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                            }

                        </div> : null}

                </div>
            </App>
        );
    }
}

export default withRouter(StoreDetails);