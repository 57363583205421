import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import Select from "react-select";
import styleReactSelect from "../../assets/styleReactSelect";
import {getMerchantList, getMerchantTypeList} from "../../services/merchantServices";
import {getStoreList} from "../../services/storeServices";
import {getCategoryList} from "../../services/categoryServices";
import {getProductListAlt, getProductListSecondary} from "../../services/productServices";
import {IoIosArrowRoundBack, IoMdCheckmark} from "react-icons/all";
import {addCommissionSettings} from "../../services/commissionSettingsServices";
import {toast} from "react-toastify";
import {Link, useHistory, withRouter} from "react-router-dom";
import Pagination from "react-js-pagination";
import {getLocalAuthData} from "../../helper/utils";
import {activityLogPostData, getDetail, getTableData, saveData, updateItem} from "../../services/commonServices";
import BrowseFiles from "../../components/BrowseFiles";

class AddNotice extends Component {
    state = {
        selectedStores:[],
        selectedOption: [],
        merchant_typesx: [],
        merchants: [],
        merchantList: [],
        categoryList: [],
        products: [],
        merchantTypes: [],
        selectedProductList: [],
        isLoading: true,
        selectedProducts: [],
        formData: {
            users:[]
        },
        isProductModalProductShowing: false,
        isSubmitting: false,
        defaultQuerySettings: {
            merchantList: {lite_data: 1},
            storeList: {merchants: this.merchants || [], status: 1, approval_status: 1, lite_data: 1},
            productList: {limit: 20}
        },
        isProductLoading: false
    }


    getNoticeById = () =>{
        const noticeId = this.props.match.params.id;
        getTableData(`/notice/${noticeId}`).then((res)=>{

            const result = res.items;
            this.setState({
                formData: {
                    title: result?.title,
                    message: result?.message,
                    img: result?.img,
                    users: result?.users,
                    type: result?.type,
                    merchants: result?.merchants,
                    merchant_types: result?.merchant_types,
                    user_type: result?.user_type,
                    stores: result?.stores,

                }
            },()=>{


                result.merchant_types.map((data)=>{

                    console.log(data)

                    //const merchant_typesx = this.state.merchant_typesx;

                    getTableData('/merchantType/'+data).then((res) => {
                        const merchantTypes =  this.state.merchant_typesx
                        const merchantSingleArray = []
                        console.log('FIELD ONE',res)
                        res.merchant_type_obj.map((item,i) => {
                            merchantTypes.push({id: item._id, label: item.type_name, value: item._id})
                            merchantSingleArray.push(item._id)
                        });

                        this.setState({merchant_typesx: merchantTypes},()=>{
                            getStoreList({
                                ...this.state.defaultQuerySettings.merchantList,
                                merchant_types: JSON.stringify(merchantSingleArray)
                            }).then(res => {
                                const storeList = []
                                res.items.forEach(item => {
                                    storeList.push({id: item._id, value: item.user, label: item.store_name})
                                    //storeList.push({id: item._id, value: item._id, label: item.name})
                                })
                                this.setState({storeList: storeList, isLoading: false})
                            })
                        })




                    }).then(errMsg => {
                        console.log('Error Message: ', errMsg)
                    })


                   /* data.map((data)=>{

                        getTableData('/merchantType/'+data).then((res) => {
                            const merchantTypes = []
                            console.log('FIELD ONE',res)
                            res.merchant_type_obj.map((item,i) => {
                                merchantTypes.push({id: item._id, label: item.type_name, value: item._id})
                                //{id: item._id, label: item.type_name, value: item._id}
                                if (res.merchant_type_obj.length-1 === i){
                                    this.setState({
                                        merchant_typesx: merchantTypes
                                    })
                                }

                            });

                            //this.setState({ ...this.state.merchant_typesx ,merchant_typesx: merchantTypes})

                        }).then(errMsg => {
                            console.log('Error Message: ', errMsg)
                        })
                    })*/

                   /* getTableData('/merchantType/'+data).then((data)=>{
                        console.log(data.merchant_type_obj)




                        /!*getMerchantList({
                            ...this.state.defaultQuerySettings.merchantList,
                            merchant_types: JSON.stringify(data.merchant_type_obj)
                            /!*merchant_types: JSON.stringify(this.state.formData.merchant_types)*!/
                        }).then(res => {
                            const merchantList = []
                            res.forEach(item => {
                                merchantList.push({id: item._id, value: item.user, label: item.name})
                            })
                            this.setState({merchantList: merchantList, isLoading: false})
                        })*!/

                       /!* data.merchant_type_obj.map((data)=>{
                            //merchant_types
                        })*!/
                    })*/
                })
            })
        }).catch((err)=>{
            toast.error(err)
        })
    }

    getMerchantType = () =>{

      /*  getDetail('/merchantType', {merchant_type_ids:this.state?.formData?.merchant_types}).then((res)=>{
            const result = res.merchant_type_obj;
            console.log(result)
            const merchantList = []
           /!* res.forEach(item => {
                merchantList.push({id: item._id, value: item.user, label: item.name})
            })
            this.setState({merchantList: merchantList, isLoading: false})*!/
        })*/

       /* getMerchantList({
            lite_data: 1,
            user: this.state?.formData?.users
        }).then(res => {
            console.log(res)
            const merchantList = []
            res.forEach(item => {
                merchantList.push({id: item._id, value: item.user, label: item.name})
            })
            this.setState({merchantList: merchantList, isLoading: false})
        })*/
    }

    componentDidMount() {

        this.getNoticeById()

        this.getMerchantType()

        getMerchantTypeList().then(res => {
            const merchantTypes = []
            res.forEach(item => {
                merchantTypes.push({id: item._id, label: item.type_name, value: item._id})
            });
            this.setState({merchant_types: merchantTypes})
        }).then(errMsg => {
            console.log('Error Message: ', errMsg)
        })

        /*CATEGORY LIST*/
        getCategoryList("product").then(res => {
            const categories = []
            res.forEach(item => {
                categories.push({id: item._id, label: item.name, value: item._id})
            })
            this.setState({categoryList: categories, isLoading: false})
        }).catch(errMsg => {
            console.log(errMsg)
            this.setState({isLoading: false})
        })

        getMerchantList(this.state.defaultQuerySettings.merchantList).then(res => {
            const merchantList = []
            res.forEach(item => {
                merchantList.push({id: item._id, value: item.user, label: item.name})
            });
            this.setState({merchantList: merchantList, isLoading: false})
        })

        getStoreList(this.state.defaultQuerySettings.storeList).then(res => {
            const selectedOption = {...this.state.selectedOption}
            const selectedStores = []
            const stores = []
            res.items.forEach(item => {
                stores.push({label: item.store_name, value: item._id})

                /*this.state.formData.stores.map((data)=>{
                    if (data.item_id){
                        selectedStores.push({label: item.store_name, value: item._id})
                    }
                })
                this.setState({
                    selectedOption: selectedStores
                })*/
            })
            this.setState({storeList: stores})
        })

        getProductListSecondary(this.state.defaultQuerySettings.productList).then(res => {
            this.setState({
                products: res.products,
                productPagination: res.pagination,
                limit: 20
            })
        })
    }

    handleInputOnChange = (e) => {
        const formData = {...this.state.formData}
        formData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({formData})
    }

    handleStoreOnChange = (value) => {
        const formData = {...this.state.formData}

        /*if (value?.length > 0) {
          const stores = []

          value.forEach(item => {
            stores.push(item.value)
          })
          formData.stores = stores

          this.setState({formData}, () => {
            getProductListAlt({store_id: stores[0]})
              .then(res => {
                this.setState({products: res})
              })
          })
        } else {
          getProductListAlt()
            .then(res => {
              formData.stores = []
              this.setState({products: res, formData})
            })
        }*/

        getProductListAlt({sid: value.value}).then(res => {
            formData.stores = [value.value]
            this.setState({products: res, formData, isLoading: false})
        })
    }

    handleShowSelectProductModal = () => {
        this.setState({isProductLoading: true}, () => {
            getProductListSecondary({
                merchants: JSON.stringify(this.state.formData?.merchants || []),
                stores: JSON.stringify(this.state.formData?.stores || []),
                categories: JSON.stringify(this.state.formData?.categories || [])
            }).then(res => {
                this.setState({
                    products: res.products,
                    productPagination: res.pagination,
                    isProductModalProductShowing: true,
                    isProductLoading: false
                })
            })
                .catch(err => {
                    this.setState({isProductLoading: false})
                })
        })

    }

    handleAddProduct = (selectedProducts) => {
        console.log(selectedProducts)
        const formData = {...this.state.formData}
        const products = []

        selectedProducts.forEach(item => {
            const product = this.state.products.filter(obj => {
                return obj._id === item
            })
            products.push(product[0])
        })

        formData.products = selectedProducts
        console.log("products");
        console.log(products);
        this.setState({
            selectedProductList: products,
            formData,
            isProductModalProductShowing: false,
            selectedProducts: []
        })
    }

    handleSelectOnChange = (name, value) => {
        console.log(value)
        const formData = {...this.state.formData}
        const selectedOption = {...this.state.formData}
        const items = []
        const items2 = []

        if (name === 'stores'){
            this.setState({selectedOption: value})
        }

        if (value?.length > 0) {
            value.forEach(item => {
                items.push(item.id)
                items2.push(item.value)
            })
        }

        if (name === "merchant_types"){
            this.setState({
                merchant_typesx: value
            },()=>{
            })
        }

        if (name === "merchants"){
            this.setState({
                merchantx: value
            })
        }

        formData[name] = items
        formData['users'] = items2

        console.log(formData);
        this.setState({formData}, () => {
            if (name === 'merchant_types') {
                getStoreList({
                    ...this.state.defaultQuerySettings.merchantList,
                    merchant_types: JSON.stringify(this.state.formData.merchant_types)
                }).then(res => {
                    const storeList = []
                    res.items.forEach(item => {
                        storeList.push({id: item._id, value: item.user, label: item.store_name})
                        //storeList.push({id: item._id, value: item._id, label: item.name})
                    })
                    this.setState({storeList: storeList, isLoading: false})
                })
            }
        })
    }

    handleSelectedProductOnChange = (e, id) => {
        const selectedProducts = [...this.state.selectedProducts];

        if (e.target.checked) {
            if (e.target.type === 'checkbox') {
                selectedProducts.push(id);
            } else {
                selectedProducts[0] = id;
            }
        } else {
            selectedProducts.splice(selectedProducts.indexOf(id), 1);
        }

        this.setState({selectedProducts});
    }

    handleRemoveSelectedProduct = (index) => {
        const selectedProductList = [...this.state.selectedProductList]
        const formData = {...this.state.formData}
        selectedProductList.splice(index, 1);
        formData.products.splice(index, 1);
        this.setState({selectedProductList, formData})
    }

    handleHideProductModal = () => {
        this.setState({isProductModalProductShowing: false})
    }

    /*   handleSubmitForm = (e) => {
           e.preventDefault()
           this.setState({isSubmitting: true}, () => {
               addCommissionSettings(this.state.formData)
                   .then(res => {
                       this.props.history.push(`/commission-settings/custom-settings`)
                       // TODO: It will be updated
                       // toast.success(res.message)
                       this.setState({isSubmitting: false})
                       toast.success('Commission setting successfully added')
                       activityLogPostData('was create new commission setting','/commission-settings/custom-settings/',e.target.admin_commission_percentage)
                   })
                   .catch(errMsg => {
                       toast.error(errMsg)
                       this.setState({isSubmitting: false})
                   })
           })
       }*/

    handleMerchantTypeOnChange = (e) => {
        const value = e.target.value
        this.setState({merchants: [], merchantList: []})
        getMerchantList({/*merchant_type: value*/})
            .then(res => {
                const merchantList = []
                const formData = {...this.state.formData}
                res.forEach(item => {
                    const merchant = {
                        id: item._id,
                        value: item._id,
                        label: item.name
                    }
                    console.log(merchant)
                    merchantList.push(merchant)
                })
                formData.merchant_type = value
                formData.merchants = []
                this.setState({merchants: res, merchantList, formData})
            })
    }

    handleSearchProduct = (val) => {
        getProductListSecondary({
            merchants: JSON.stringify(this.state.formData?.merchants || []),
            stores: JSON.stringify(this.state.formData?.stores || []),
            categories: JSON.stringify(this.state.formData?.categories || []),
            limit: 20,
            search_string: val
        }).then(res => {
            this.setState({
                products: res.products,
                productPagination: res.pagination
            }, () => console.log(this.state));
        })
    }


    handleSubmitForm =  (e) => {
        e.preventDefault()
        this.setState({isSubmitting: true}, () => {
            let formData = {...this.state.formData}
            console.log(formData)
            let array = []
            this.state.selectedOption.map((data)=>{
                array.push(data.value)
            })
            formData.stores = array
            const noticeId = this.props.match.params.id;
            updateItem('/notice/'+noticeId , formData).then((res)=>{
                console.log(res)
                if (res.status === 1){
                    this.setState({
                        isSubmitting:false
                    },()=>{
                        toast.success(res.message)
                        this.props.history.push('/notice/list')
                    })
                }
            })

        })
    }
    handleSearchFormDataChange(e){
        this.setState({formData: {...this.state.formData, [e.target.name]: e.target.value}},()=>{
            console.log(this.state.formData)
        })

    }


    handleRemoveImage = (name) => {
        const formData = {...this.state.formData}
        formData['img'] = ''
        this.setState({formData},()=>{
            console.log(this.state.formData)
        })
    }

    handleOnBrowsePic = (files, name) => {
        const formData = {...this.state.formData}
        formData['img'] = files[0].actual_img;
        this.setState({formData},()=>{
            console.log(this.state.formData)
        })
    }

    render() {


        return (
            <App layout={`boxed`}>
                <div className="page-header">
                    <div/>
                    <div className="ml-auto">
                        <Link to={`${process.env.PUBLIC_URL}/notice/list`}><IoIosArrowRoundBack/> Back to
                            List</Link>
                    </div>
                </div>


                <>
                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">Edit Notice </h4>
                        </div>

                        <div className="block-body">
                            {this.state.isLoading ?
                                <div>Loading...</div>
                                :
                                <Form onSubmit={this.handleSubmitForm}>
                                    {/*<Form.Group as={Row} controlId="formStore">
                  <Form.Label column sm="3">
                    Commission (%) <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control type={`number`} max={100} min={0}
                                  name={`value`}
                                  onChange={this.handleInputOnChange}
                                  required placeholder={`Enter commission value`}/>
                  </Col>
                </Form.Group>*/}

                                    <Form.Group as={Row} controlId="formAdminCommission">
                                        <Form.Label column sm="3">
                                            Title <span className="text-danger">*</span>
                                        </Form.Label>

                                        <Col sm="4" md={8} lg={7}>
                                            <Form.Control type={`text`}
                                                          onChange={this.handleInputOnChange}
                                                          defaultValue={this.state.formData?.title}
                                                          name={'title'}
                                                          min={0} max={100} step={.1} required placeholder={`Enter title`}/>
                                        </Col>


                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formMerchantCommission">
                                        <Form.Label column sm="3">
                                            Message <span className="text-danger">*</span>
                                        </Form.Label>

                                        <Col sm="4" md={8} lg={7}>
                                            <Form.Control type={`text`}
                                                          onChange={this.handleInputOnChange}
                                                          defaultValue={this.state.formData?.message}
                                                          name={'message'}
                                                          min={0} max={100} step={.1} required placeholder={`Enter message`}/>
                                        </Col>


                                    </Form.Group>



                                    <Form.Group as={Row} controlId="formMerchantType">
                                        <Form.Label column sm="3">
                                            Merchant Types
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Select
                                                isMulti
                                                value={this.state.merchant_typesx}
                                                styles={styleReactSelect}
                                                placeholder="Select Merchant Type..."
                                                onChange={(value) => {
                                                    console.log(value)
                                                    this.handleSelectOnChange('merchant_types', value)
                                                }}
                                                options={this.state.merchant_types}
                                                name="merchant_types"
                                                noOptionsMessage={() => 'Select Merchant Type...'}
                                            />
                                        </Col>
                                    </Form.Group>

                                    {this.state?.storeList?.length > 0 &&
                                    <Form.Group as={Row} controlId="formStore">
                                        <Form.Label column sm="3">
                                            Stores
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Select
                                                value={this.state.selectedOption}
                                                isMulti
                                                styles={styleReactSelect}
                                                placeholder="Select store..."
                                                onChange={(value) => this.handleSelectOnChange('stores', value)}
                                                options={this.state.storeList}
                                                name="stores"
                                                noOptionsMessage={() => 'Please insert new value...'}
                                            />
                                        </Col>
                                    </Form.Group>
                                    }

                                    {/*
                                    {this.state.merchantList.length > 0 &&
                                    <Form.Group as={Row} controlId="formMerchant">
                                        <Form.Label column sm="3">
                                            Merchants
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Select
                                                value = {this.state.merchants}
                                                isMulti
                                                styles={styleReactSelect}
                                                placeholder="Select merchant..."
                                                onChange={(value) => {
                                                    this.handleSelectOnChange('merchants', value)
                                                }}
                                                options={this.state.merchantList}
                                                name="merchants"
                                                noOptionsMessage={() => 'Please insert new value...'}
                                            />
                                        </Col>
                                    </Form.Group>
                                    }*/}

                                    {/* {this.state?.storeList?.length > 0 &&
                                    <Form.Group as={Row} controlId="formStore">
                                        <Form.Label column sm="3">
                                            Stores
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Select
                                                isMulti
                                                styles={styleReactSelect}
                                                placeholder="Select store..."
                                                onChange={(value) => this.handleSelectOnChange('stores', value)}
                                                options={this.state.storeList}
                                                name="stores"
                                                noOptionsMessage={() => 'Please insert new value...'}
                                            />
                                        </Col>
                                    </Form.Group>
                                    }*/}


                                    {/* <Form.Group as={Row} controlId="formCategory">
                                        <Form.Label column sm="3">
                                            Categories
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Select
                                                isMulti
                                                styles={styleReactSelect}
                                                placeholder="Select category..."
                                                onChange={(value) => {
                                                    this.handleSelectOnChange('categories', value)
                                                }}
                                                options={this.state.categoryList}
                                                name="categories"
                                                noOptionsMessage={() => 'Select category...'}
                                            />
                                        </Col>
                                    </Form.Group>*/}

                                    {/*<Modal show={this.state.isProductModalProductShowing}
                                           onHide={this.handleHideProductModal}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Select Product</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="mb-3 form-inline">
                                                <Form.Group>
                                                    <Form.Label srOnly>Search</Form.Label>
                                                    <Form.Control type={`search`}
                                                                  size={`sm`}
                                                                  onChange={(e) => {
                                                                      this.setState({productSearchString: e.target.value})
                                                                  }}
                                                                  placeholder={`Search...`}/>
                                                </Form.Group>

                                                <Button type={`button`} size={`sm`} variant={`info`}
                                                        onClick={() => {
                                                            this.handleSearchProduct(this.state.productSearchString || '')
                                                        }} className={`ml-2`}>Search</Button>
                                            </div>

                                            {this.state.products.length < 1 &&
                                            <div className="alert alert-warning text-bold text-center">
                                                no product found
                                            </div>}

                                            {this.state.products.length > 0 &&
                                            <>
                                                <div className="product-checkbox-list">
                                                    {this.state.products.map((product, index) => (
                                                        <label className={`product-item`} key={this.state.productPagination?.pagingCounter + index} htmlFor={`product-${index}`}>
                                                            <input id={`product-${index}`}
                                                                   onChange={e => this.handleSelectedProductOnChange(e, product._id)}
                                                                   name={`product`}
                                                                   type="checkbox"/>
                                                            <span className={`product`}>
                              <span className="product-img">
                                <img src={product.thumb_img} alt=""/>
                              </span>
                              <span className="product-short-info">
                                <span className="product-name">{product.name}</span>
                              </span>
                              <span className="icon"><IoMdCheckmark/></span>
                            </span>
                                                        </label>
                                                    ))}
                                                </div>

                                                <br/>

                                                <Pagination
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    activePage={this.state.productPagination?.page}
                                                    itemsCountPerPage={this.state.productPagination?.limit}
                                                    totalItemsCount={this.state.productPagination?.totalDocs}
                                                    onChange={(page) => {
                                                        getProductListSecondary({
                                                            merchants: JSON.stringify(this.state.formData?.merchants || []),
                                                            stores: JSON.stringify(this.state.formData?.stores || []),
                                                            categories: JSON.stringify(this.state.formData?.categories || []),
                                                            page: page,
                                                            limit: 20
                                                        }).then(res => {
                                                            this.setState({
                                                                products: res.products,
                                                                productPagination: res.pagination
                                                            }, () => console.log(this.state));
                                                        })
                                                    }}
                                                />
                                            </>
                                            }
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={this.handleHideProductModal}>
                                                Close
                                            </Button>
                                            <Button variant="primary"
                                                    onClick={() => this.handleAddProduct(this.state.selectedProducts)}>
                                                Save Changes
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    {
                                        <Form.Group as={Row} controlId="formProducts">
                                            <Form.Label column sm="3">Products</Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <table className={`table table-striped table-bordered`}>
                                                    <thead className={`sm`}>
                                                    <tr>
                                                        <th style={{width: '50px'}}/>
                                                        <th>Product Name</th>
                                                        <th style={{width: '1%'}}/>
                                                    </tr>
                                                    </thead>

                                                    <tbody>
                                                    {this.state.selectedProductList.length > 0 ?
                                                        <>
                                                            {this.state.selectedProductList.map((product, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <img src={product?.thumb_img} style={{width: '40px'}} alt=""/>
                                                                    </td>
                                                                    <td>{product?.name}</td>
                                                                    <td><span style={{cursor: 'pointer'}}
                                                                              onClick={() => this.handleRemoveSelectedProduct(index)}
                                                                              className={`text-bold text-danger`}>&times;</span></td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                        :
                                                        <tr>
                                                            <td colSpan={3} className={`text-center text-bold`}>
                                                                No product selected
                                                            </td>
                                                        </tr>
                                                    }
                                                    </tbody>
                                                </table>

                                                <Button size="sm"
                                                        disabled={this.state.isProductLoading}
                                                        onClick={() => this.handleShowSelectProductModal()}
                                                        variant="link"> {!this.state.isProductLoading ? 'Select Products' : 'Please wait...'}</Button>
                                            </Col>
                                        </Form.Group>
                                    }*/}

                                    <Form.Group as={Row} controlId="formMerchant">
                                        <Form.Label column sm="3">
                                            Type
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <select value={this.state?.formData?.user_type} name={`user_type`} onChange={(e)=>this.handleSearchFormDataChange(e)} type="search" id="status" className='form-control form-control-sm'>
                                                <option value="">--select type--</option>
                                                <option value="merchant">For Merchant</option>
                                             {/*   <option value="customer">For Customer</option>*/}
                                            </select>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formMerchant">
                                        <Form.Label column sm="3">
                                            Notice Cover
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <BrowseFiles
                                                isSingle={true}
                                                image_category={'profile'}
                                                images={this.state.formData?.img ? [{thumb_img: this.state.formData.img}] : []}
                                                onRemoveItem={() => this.handleRemoveImage('com_logo')}
                                                onGetFiles={(files) => {
                                                    this.handleOnBrowsePic(files, 'com_logo')
                                                }}/>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formSave">
                                        <Form.Label column sm="3"/>
                                        <Col sm="9" md={8} lg={7}>
                                            <Button type={`submit`}
                                                    disabled={this.state.isSubmitting}
                                                    variant={'primary'}>{this.state.isSubmitting ? 'Saving...' : 'Save'}</Button>
                                        </Col>
                                    </Form.Group>
                                </Form>

                            }
                        </div>
                    </div>
                </>

            </App>
        );
    }
}

export default withRouter(AddNotice);
