import React, {Component} from 'react'
import App from "../../App"
import {Button, Form, Spinner} from "react-bootstrap"
import DateRangePicker from "react-bootstrap-daterangepicker"
import Moment from "moment"
import {FaFileExport} from "react-icons/all"
import {toast} from "react-toastify";
import moment from "moment";
import {fileDownload, getTableData} from "../../services/commonServices";
import {useJsonToCsv} from "react-json-csv";
import Pagination from 'react-js-pagination';
import {urlPramsToObj} from "../../helper/utils";
import {withRouter} from "react-router-dom";
import {FaFilePdf} from "@react-icons/all-files/fa/FaFilePdf";

const {saveAsCsv} = useJsonToCsv();

class OrderReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            locations: [],
            orders: [],
            pagination: {},
            queryData: {
                limit: 20
            }
        }
    }

    getData(queryData) {
        getTableData('/reports/orders', this.state.queryData).then((res) => {
            console.log(res)
            if (res.status === 1) {
                this.setState({orders: res.data , pagination: res.paginate}, () => {
                })
            } else {
                toast.error('Something went wrong.')
            }
        })

    }

    componentDidMount() {
       /* const history = this.props.history
        let page = 1
        if (history.location.search) {
            const params = urlPramsToObj(history)
            if ('page' in params) {
                page = params.page
            }
        }*/
        this.getData()
    }

    handleOnApply = (e, picker) => {
        const queryData = {...this.state.queryData}
        const startDate = Moment(picker.startDate).format('DD-MM-YYYY')
        const endDate = Moment(picker.endDate).format('DD-MM-YYYY')
        queryData['from_date'] = startDate;
        queryData['to_date'] = endDate;

        this.setState({
            queryData
        }, () => {
            this.getData(this.state.queryData)
        })
    }
    handleOnChangeInput = (e) => {
        const queryData = {...this.state.queryData}
        queryData[e.target.name] = e.target.value;
        this.setState({queryData}, () => {
            console.log(this.state.formData)
            this.getData(this.state.queryData)
        })
    }

    handlePageChange = async (pageNumber) => {
        const history = this.props.history
        history.push(`${history.location.pathname}?page=${pageNumber}`)
        await this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber}, ()=>{
            this.getData(this.state.queryData);
        });
    }

    handleExport = async () => {

        this.setState({export_loading: true})

        let queryData = {...this.state.queryData}
        queryData.export = 'xlsx'
        this.setState({queryData}, ()=>{
            fileDownload(`/reports/orders`, this.state.queryData).then((response)=>{
                console.log(response)
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'ekshop-orders-report.xlsx');
                document.body.appendChild(link);
                link.click();
                this.setState({
                    export_loading: false
                })
            })
            queryData.export = null
            this.setState({
                queryData
            })
        })

        /*let queryData = "";
        // noinspection JSMismatchedCollectionQueryUpdate
        let mData = [];

        this.state.orders && this.state.orders.map((data1, index1) => {

            mData.push({
                'Index': index1 + 1,
                'Date': moment(data1?._id?.createdAt).format('dd-mm-yyyy'),
                'Cancelled Order': data1?.cancelled_order,
                'Processing Order': data1?.processing_order,
                'Pending Order': data1?.pending_order,
                'Delivered Order': data1?.delivered_order,
                'Discount Order': data1?.discount_order,
                'Total Order': data1?.total_order,
            });

        });

        const filename = 'EkShop-Order Report',
            fields = {
                'Index': "Index",
                'Date': "Date",
                'Cancelled Order': "Cancelled Order",
                'Processing Order': "Processing Order",
                'Pending Order': "Pending Order",
                'Delivered Order': "Delivered Order",
                'Discount Order': "Discount Order",
                'Total Order': "Total Order",

            },
            style = {
                padding: "30px",
                maxHeight: "20px"
            },
            data = mData,
            text = "Convert Json to Csv";
        saveAsCsv({data, fields, filename});*/

    }

    render() {
        return (
            <App>
                <div className="page-header">
                    <h2 className="page-title">Order Report</h2>
                    <div className="ml-auto">
                        <Button variant="primary"

                                size={`sm`}
                                onClick={() => this.handleExport('xlsx')}
                                disabled={this.state.export_loading}>
                            {this.state.export_loading ?
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span>Loading...</span>
                                </>
                                : <><FaFilePdf/><span>Export</span></>}
                        </Button>
                        {/*<Button onClick={() => {
                            this.handleExport()
                        }} size={"sm"} variant={"default"} className="mb-0 mr-2"><FaFileExport/> Export</Button>*/}
                    </div>
                </div>

                <div className="card-block">
                    <div className="block-body">
                        <Form className="d-flex v-middle">
                            <div className={"form-inline"}>
                                <Form.Group controlId={"customerType"} className={'mr-2 mb-2'}>
                                    <Form.Label className="mr-2">Filter By: </Form.Label>
                                    <Form.Control name={`division`} onChange={(e) => {
                                        this.handleOnChangeInput(e)
                                    }} size={"sm"} as={"select"}>
                                        <option value="">All Divisions</option>
                                        <option value="Dhaka">Dhaka</option>
                                        <option value="Rajshahi">Rajshahi</option>
                                        <option value="Barishal">Barishal</option>
                                        <option value="Khulna">Khulna</option>
                                        <option value="Mymensingh">Mymensingh</option>
                                        <option value="Chattogram">Chattogram</option>
                                        <option value="Sylhet">Sylhet</option>
                                        <option value="Rangpur">Rangpur</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className={'mr-2'}>
                                    <Form.Label className={"sr-only"}>Date Range Picker</Form.Label>
                                    <DateRangePicker
                                        onApply={this.handleOnApply}>
                                        <input type="text"
                                               style={{textAlign: 'left', minWidth: '150px'}}
                                               placeholder={"Select date range"}
                                               className="form-control form-control-sm"/>
                                    </DateRangePicker>
                                </Form.Group>
                            </div>
                        </Form>

                        <hr/>

                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th style={{width: '30px'}}>SL</th>
                                    <th>Date</th>
                                    {/*<th>Order Number</th>
                                    <th>Customer Info</th>
                                    <th>Division</th>
                                    <th>District</th>
                                    <th>Merchant Name</th>
                                    <th>Merchant Type</th>
                                    <th>Store Name</th>
                                    <th>Store Category</th>
                                    <th>Qty of Product</th>*/}
                                    {/*<th>Order Status</th>
                                    <th>Payment Type</th>
                                    <th>Payment Status</th>
                                    <th>Discount Type</th>
                                    <th>Shipping Type</th>*/}
                                    <th>Cancelled order</th>
                                    <th>Processing order</th>
                                    <th>Pending order</th>
                                    <th>Delivered order</th>
                                    {/*<th>Shipping count</th>*/}
                                    <th>Discount order</th>
                                    <th className="nowrap" style={{width: '1%'}}>Total order</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.orders.length > 0 && this.state.orders.map((data, i) => {
                                    return (
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{moment(data?._id.createdAt).format("MM-DD-YYYY")}</td>
                                            <td>{data?.cancelled_order}</td>
                                            <td>{data?.processing_order}</td>
                                            <td>{data?.pending_order}</td>
                                            <td>{data?.delivered_order}</td>
                                            <td>{data?.discount_order}</td>
                                            <td>{data?.total_order}</td>
                                        </tr>
                                    )
                                })
                                }
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-3">
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={this.state?.pagination?.page}
                                itemsCountPerPage={this.state?.pagination?.limit}
                                totalItemsCount={this.state?.pagination?.totalDocs ? this.state?.pagination?.totalDocs : 0}
                                pageRangeDisplayed={this.state?.pagination?.limit}
                                onChange={this.handlePageChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </App>
        );
    }
}

export default withRouter(OrderReport);
