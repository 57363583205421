import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import App from "../../App";
import "../../assets/scss/invoice.scss";
import InvoiceComponent from "../../components/InvoiceComponent";
import { getOrderDetails } from "../../services/orderServices";

class Invoice extends Component {
  state = {
    orderDetail: null,
    isLoading: true
  }

  componentDidMount() {
    const orderId = this.props.match.params.id

    getOrderDetails(orderId)
      .then(res => {
        this.setState({orderDetail: res, isLoading: false}, () => {
          //console.log('res: ', this.state.orderDetail)
        })
      })
      .catch(errMsg => {
        //console.error(errMsg)
        this.setState({isLoading: false})
      })
  }

  renderPaymentStatus = (status) => {
    let payment_status = {
      0:"Unpaid",
      1:"Partially Paid",
      2:"Paid",
      3:"Cancelled",
      4:"Failed",
      5:"Refund",
      6:"Bank deposit payment in review",
      7:"Bank deposit declined",
    }
    return payment_status[status];
  }

  render() {
    const {orderDetail} = this.state

    return (
      <App layout="boxed">
        <div className="main-content">

          {this.state.isLoading ?
            <div className={`card-block`}>
              <div className="block-body">
                Loading...
              </div>
            </div>
            :
            <>
              {orderDetail?.order_parcels?.length>0?orderDetail?.order_parcels?.map((store, index) => (
                <InvoiceComponent store={store} paymentStatus={this.renderPaymentStatus(store?.payment_status)}
                                  orderDetail={orderDetail}
                                  key={index}/>
              )):<><h2>Order parcel not available</h2></>}
            </>
          }
        </div>
      </App>
    );
  }
}

export default withRouter(Invoice);
