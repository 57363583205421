import React, {Component} from 'react';
import "../assets/scss/auth.scss";
import logo from '../assets/img/logo.png';
import Form from "react-bootstrap/esm/Form";
import {Button} from "react-bootstrap";
import {FiArrowLeft} from "react-icons/fi";
import {connect} from "react-redux";
import {clearErrMsg, login} from "../redux";
import {Redirect, withRouter} from "react-router-dom";
import {userIsLoggedin} from "../auth/authUtils";
import {getDeviceType, getLocalAuthData} from "../helper/utils";
import {changePassword, handleResetPassword} from "../services/authServices";
import {toast} from "react-toastify";
import InputPassword from "../components/InputPassword";
import {API_BASE_URL, OTP_RESEND_TIME} from "../helper/env";
import {activityLogPostData} from "../services/commonServices";
import axios from "axios";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isForgetPassword: false,
      login_id: '',
      password: '',
      isVerifiedCode: false,
      isLoading: false,
      otp: null, // TODO: have ot update
      resendTime: OTP_RESEND_TIME,
      isSentOnce: false
    }
  }

  handleForgetPassword = () => {
    this.props.clearErrMsg()
    this.setState({isForgetPassword: true})
  };

  handleBackToLogin = () => {
    this.props.clearErrMsg()
    this.setState({isForgetPassword: false})
  };

  handleLogin = async (e) => {
    e.preventDefault();

    const data = {
      login_id: this.state.login_id,
      password: this.state.password,
      device_id: "",
      device_token: btoa(Math.random() + navigator.userAgent + Date()),
      device_type: getDeviceType(),
      os: navigator.platform
    }

    // await axios.post(`${API_BASE_URL}/password-login`, data).then(async res => {
    //   if (res.data.status === 1 && res.data?.user_login_session !== undefined) {
    //     const user = localStorage.setItem('user', JSON.stringify(res.data.user_login_session));
    //     const authData = getLocalAuthData();
    //     console.log("user:", user);
    //     console.log("authData:", authData);
    //     setTimeout(() => {
    //       this.setState({login_id: '', password: ''})
    //       toast.success('Login successfully!')
    //       // window.location.reload()
    //       this.props.history.push(`${process.env.PUBLIC_URL}/`);
    //     }, 1000)
    //   } else {
    //     toast.error(res.data.message)
    //   }
    // }).catch(err => {
    //   console.log('Error: ', err);
    //   toast.error('Something went wrong!')
    // })

    this.props.login(data, () => {
      this.setState({login_id: '', password: ''})
      activityLogPostData('was logged in','/' , this.state.login_id)
      // window.location.reload()
      this.props.history.push(`${process.env.PUBLIC_URL}/`);
    })
  }

  handleOnInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  containerHeader = () => {
    if (!this.state.isForgetPassword && !this.state.isVerifiedCode) {
      return (
        <>
          <h4 className="auth-title">Login</h4>
          <p>to continue to admin panel</p>
        </>
      )
    } else if (this.state.isForgetPassword) {
      return (
        <>
          <h4 className="auth-title">Reset Password</h4>
          <p>Enter your registered login ID</p>
        </>
      )
    } else {
      return (
        <>
          <h4 className="auth-title">Reset Password</h4>
          <p>Enter your OTP and new password</p>
        </>
      )
    }
  }

  handleResetPassword = (e) => {
    e.preventDefault();
    this.setState({isLoading: true, resendTime: OTP_RESEND_TIME}, () => {
      handleResetPassword({login_id: this.state.login_id})
        .then(res => {
          toast.success(res.message)
          this.setState({
            isForgetPassword: false,
            isVerifiedCode: true,
            // login_id: '',
            password: '',
            isLoading: false,
            otp: res.otpValue,
            isOTPSent: true,
            isSentOnce: true
          }, () => {
            const resendTimeInterval = setInterval(() => {
              this.setState({resendTime: this.state.resendTime - 1}, () => {
                if (this.state.resendTime <= 0) {
                  clearInterval(resendTimeInterval);
                  this.setState({isOTPSent: false})
                }
              })
            }, 1000)
            document.getElementById('changePassForm').reset();
          });
        })
        .catch(errMsg => {
          toast.error(errMsg);
          this.setState({isLoading: false});
        })
    })
  }

  renderOtpButton = () => {
    if (this.state.isOTPSent && this.state.isSentOnce) {
      return <span className="icon-text before-verify disabled">
                <span className="text">OTP sent! Resend OTP in {this.state.resendTime} <span
                  className={"text-lowercase"}>s</span></span>
              </span>
    } else if (this.state.isLoading) {
      return <span className="icon-text before-verify disabled">
                 <span className="text">Sending OTP...</span>
               </span>
    } else {
      return <span className="icon-text before-verify">
                Did not receive OTP? <span style={{cursor: "pointer"}}
                                           onClick={this.handleResetPassword}
                                           className="text text-primary">Resend OTP</span>
              </span>
    }
  }

  handleChangePassword = (e) => {
    window.alert('yes')
    e.preventDefault();
    this.setState({isLoading: true}, () => {
      changePassword({token: this.state.token, password: this.state.password})
        .then(res => {
          toast.success(res.message);
          this.setState({
            isForgetPassword: false,
            isVerifiedCode: false,
            login_id: '',
            password: '',
            isLoading: false
          });
          document.getElementById('loginForm').reset();
        })
        .catch(errMsg => {
          toast.error(errMsg);
          this.setState({isLoading: false});
        })
    })
  }

  containerForm = () => {
    if (!this.state.isForgetPassword && !this.state.isVerifiedCode) {
      return (
        <Form id={'loginForm'} onSubmit={this.handleLogin}>
          {this.props.authData.errMsg &&
          <div className="mb-2 text-bold text-danger">
            * {this.props.authData.errMsg}
          </div>}
          <Form.Group controlId="loginForm.email">
            <Form.Label className="sr-only">Login ID</Form.Label>
            <Form.Control onChange={this.handleOnInputChange}
                          type="email"
                          name="login_id"
                          required
                          defaultValue={this.state.login_id}
                          placeholder="Email address"/>
          </Form.Group>
          <Form.Group controlId="loginForm.password">
            <Form.Label className="sr-only">Password</Form.Label>
            <InputPassword onChange={this.handleOnInputChange}
                           required
                           name="password"
                           autoComplete="off"
                           placeholder="Password"/>
          </Form.Group>
          <span
            onClick={() => this.handleForgetPassword()}
            style={{cursor: 'pointer'}}
            className="d-block text-primary mb-3">Forget your password?</span>
          <Button variant="primary"
                  type="submit"
                  disabled={this.props.authData.loading}
                  block>{this.props.authData.loading ? 'Logging...' : 'login'}</Button>
        </Form>
      )
    } else if (this.state.isForgetPassword) {
      return (
        <Form id={"resetPassForm"} onSubmit={this.handleResetPassword}>
          <Form.Group controlId="resetPassForm.loginID">
            <Form.Label className="sr-only">Email</Form.Label>
            <Form.Control name={"login_id"}
                          defaultValue={this.state.login_id}
                          onChange={this.handleOnInputChange}
                          type="email" placeholder="Email address"/>
          </Form.Group>

          <Button variant="primary"
                  type={"submit"}
                  disabled={this.state.isLoading}
                  block>{this.state.isLoading ? 'Sending OTP...' : 'Reset Password'}</Button>
          <Button variant="link"
                  onClick={() => this.handleBackToLogin()}
                  block><FiArrowLeft/> Back to Login</Button>
        </Form>
      )
    } else {
      return (
        <Form onSubmit={this.handleChangePassword} id={"changePassForm"}>
          <Form.Group controlId="changePassForm.OTP">
            <Form.Label className="sr-only">OTP</Form.Label>
            <Form.Control name={"token"}
                          type="text"
                          defaultValue={''}
                          onChange={this.handleOnInputChange}
                          placeholder="OTP"/>

            <Form.Text>{this.renderOtpButton()}</Form.Text>
            {(this.state.otp && process.env.NODE_ENV === 'development') && <Form.Text>OTP is: {this.state.otp}</Form.Text>}
          </Form.Group>

          <Form.Group controlId="changePassForm.password">
            <Form.Label className="sr-only">New Password</Form.Label>
            <InputPassword
              name={"password"}
              onChange={this.handleOnInputChange}
              placeholder="New Password" minLength={6}/>
          </Form.Group>
          <Button variant="primary"
                  type="submit"
                  disabled={this.state.isLoading}
                  block>{this.state.isLoading ? 'Submitting...' : 'Change Password'}</Button>
        </Form>
      )
    }
  }

  render() {
    if (userIsLoggedin())
      return (<Redirect to={{pathname: '/'}}/>);

    return (
      <div className="auth-wrap">
        <div className="auth-container">
          <div className="auth-header">
            <img className="logo" src={logo} alt="Citygrabs Eat"/>
            {this.containerHeader()}
          </div>

          <div className="auth-body">
            {this.containerForm()}
          </div>

          <div className="auth-footer text-center">
            &copy; 2020 <strong>Ekshop</strong>. All right reserved
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authData: state.auth
  }
};

const mapDispatchToProps = dispatch => {
  return {
    login: (data, cb) => dispatch(login(data, cb)),
    clearErrMsg: () => dispatch(clearErrMsg())
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
