import "../assets/scss/main-slide.scss";

import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {FiMove, FiPlus, FiTrash} from "react-icons/all";
import React, {useEffect, useState} from "react";
import {addItem, getData, permanentDeleteItem} from "../services/baseServices";
import {deleteStore, getStoreList} from "../services/storeServices";

import BrowseFiles from "./BrowseFiles";
import {confirmAlert} from "react-confirm-alert";
import {getLocalAuthData} from "../helper/utils";
import slideImg from "../temp/slide-img.jpg";
import slideSample from "../assets/img/slide-sample.jpg";
import {toast} from "react-toastify";

const TopPromotionalBanner = () => {
  const [showAddSlideModal, setShowAddSlideModal] = useState(false)
  const [banners, setBanners] = useState([])
  const [formData, setFormData] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    getBanners()
  }, [])

  const getBanners = () => {
    getData(`/site-banner`, {location: 'home-promotional-banner'}).then(res => {
      setBanners(res.items)
      console.log("topPromotionalBanner: ",res.items)
    })
  }

  const handleAddSlideModalClose = () => setShowAddSlideModal(false);
  const handleAddSlideModalShow = () => {
    setFormData({banner_type: 1, image_size_template: 'banner_1920x150', location: 'home-promotional-banner'})
    setShowAddSlideModal(true)
  };

  const handleDeleteBanner = (id) => {
    confirmAlert({
      title: 'Permanent Delete',
      message: 'Are you sure to delete this banner?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => permanentDeleteItem(`/banner/${id}/permanent/`)
            .then(res=> {
              getBanners()
              toast.success(res.message)
            })
            .catch(errMsg => {
              toast.error(errMsg)
            })
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });


  }

  const handleInputOnChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value})
  }

  const handleImageOnChange = (images, name) => {
    setFormData({...formData, [name]: images[0].actual_img})
  }

  const handleSaveSlider = () => {
    setIsSubmitting(true)
    addItem(`/banner`, formData)
      .then(res => {
        toast.success(res.message)
        handleAddSlideModalClose()
        getBanners()
        setIsSubmitting(false)
      })
      .catch(errMsg => {
        toast.error(errMsg)
        setIsSubmitting(false)
      })
  }

  return (
    <div className="card-block">
      <div className="block-header">
        <h4 className="block-title">Promotional Banner</h4>
        <div onClick={handleAddSlideModalShow} className="btn btn-outline-secondary btn-sm btn-add-slide">
              {/* <img src={slideSample} alt=""/>
              <div className="slide-actions">
                <div className="actions"> */}
                  <span className="action"><FiPlus/></span>
                {/* </div>
              </div> */}
            </div>
      </div>

      <div className="block-body">
        <Modal show={showAddSlideModal} onHide={handleAddSlideModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Home Page Top Promotional Banner</Modal.Title>
          </Modal.Header>
          <Form onSubmit={(e) => {
            e.preventDefault()
            handleSaveSlider()
          }}>
            <Modal.Body>
              <Form.Group as={Row} controlId="sliderImg">
                <Form.Label column sm="4">
                Promotional Banner Image <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <BrowseFiles
                    userId={getLocalAuthData()?._id}
                    isSigle={false}
                    image_category={'promotional_banner'}
                    onRemoveItem={() => {
                      const newFormData = {...formData}
                      delete newFormData.image
                      setFormData(newFormData)
                    }}
                    selected_image_size_template={'banner_1920x150'}
                    images={formData?.image ? [{thumb_img: formData?.image}] : []}
                    helpText={'Image size 1920x150'}
                    onGetFiles={(images) => handleImageOnChange(images, 'image')}/>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="sliderImgMobile">
                <Form.Label column sm="4">
                Promotional Banner Image (Mobile) <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <BrowseFiles
                    userId={getLocalAuthData()?._id}
                    isSigle={false}
                    image_category={'promotional_banner'}
                    onRemoveItem={() => {
                      const newFormData = {...formData}
                      delete newFormData.m_image
                      setFormData(newFormData)
                    }}
                    selected_image_size_template={'banner_755x390'}
                    images={formData?.m_image ? [{thumb_img: formData?.m_image}] : []}
                    helpText={'Image size 755 x 390'}
                    onGetFiles={(images) => handleImageOnChange(images, 'm_image')}/>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formPromotionName">
                <Form.Label column sm="4">
                  Banner Link <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={8}>
                  <Form.Control type="text" onChange={handleInputOnChange} required={'#'} name={'link'} placeholder=""/>
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="default" onClick={handleAddSlideModalClose}>
                Close
              </Button>
              <Button variant="primary" type={'submit'}>
                {isSubmitting ? 'adding slide' : 'Add Slide'}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <div className="d-flex">
          <div className="row">
            {banners.map((item, index) => (
              <div key={index} className="col-md-3 mb-3">
                <div className="main-slide">
                  <img src={item?.img} alt=""/>
                  <div className="slide-actions">
                    <div className="actions">
                      <span onClick={() => handleDeleteBanner(item?._id)} className="action"><FiTrash/></span>
                      {/*<span className="action"><FiMove/></span>*/}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopPromotionalBanner;
