import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import BrowseFiles from "../../components/BrowseFiles";
import {addBrand, brandDetails, brandUpdate, deleteBrand, getBrandList} from "../../services/brandServices";
import {toast} from "react-toastify";
import Brand from "../../components/Barnd";
import {confirmAlert} from "react-confirm-alert";
import "../../assets/scss/barnd-list.scss";
import Pagination from "react-js-pagination";
import { getLocalAuthData } from '../../helper/utils'

class Brands extends Component {
  state = {
    brandData: {
      name: null,
      logo: null
    },
    brandList: [],
    brandDetails: null,
    isLoading: false,
    queryData: {
      sort_direction: -1,
      limit: 50,
    },
    activePage: 1,
    limit: 50,
    pagination: {}
  }

  componentDidMount() {
    this.getBrands()
  }

  getBrands = () => {
    getBrandList(this.state.queryData).then(res => {
      this.setState({brandList: res.items, isLoading: false, pagination: res.pagination})
    }).catch(errMsg => {
      toast.error(errMsg);
    })
  }

  handlePageChange = (pageNumber) => {
    this.setState({queryData: {...this.state.queryData, page: pageNumber, limit: this.state.limit}}, () => {
     this.getBrands();
    });
  }

  handleOnChangeInput = (e) => {
    const brandData = {...this.state.brandData};
    const queryData = {...this.state.queryData};
    brandData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    //console.log(e.target.name);
    if(e.target.name === 'name') {
      queryData.search_string = e.target.value;
    }else{
      queryData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    }

    this.setState({brandData, queryData}, ()=>{
      //console.log(this.state);
    });
  }

  handleImageOnBrowse = (images) => {
    const brandData = {...this.state.brandData}
    if(images.length > 0) {
      brandData.logo = images[0].thumb_img
    }
    this.setState({brandData})
  }

  handleSubmitBrandForm = () => {
    this.setState({isLoading: true});
    addBrand(this.state.brandData)
      .then(res => {
        this.setState({isLoading: false});
        this.resetUpdateForm();
        getBrandList()
          .then(res => {
            this.setState({brandList: res.items})
          })
          .catch(errMsg => {
            toast.error(errMsg);
          })
        toast.success(res.data.message);
      })
      .catch(errMsg => {
        this.setState({isLoading: false});
        toast.error(errMsg);
      })
  }

  handleUpdateBrand = () => {
    this.setState({isLoading: true}, () => {
      brandUpdate(this.state.brandDetails._id, this.state.brandData)
        .then(res => {
          this.setState({isLoading: false})
          this.resetUpdateForm();
          getBrandList()
            .then(res => {
              this.setState({brandList: res.items})
            })
            .catch(errMsg => {
              toast.error(errMsg);
            })
          toast.success(res.data.message)
        })
        .catch(errMsg => {
          toast.error(errMsg);
          this.setState({isLoading: false});
        })
    });
  }

  handleGetBrandDetails = (id) => {
    brandDetails(id)
      .then(res => {
        this.setState({
          brandDetails: res, brandData: {name: res.name, logo: res.logo}
        })
      })
  }

  handleDeleteBrand = (id) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to delete this brand?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteBrand(id, () => {
            getBrandList()
              .then(res => {
                this.setState({brandList: res.items})
              })
              .catch(errMsg => {
                toast.error(errMsg);
              })
          })
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  };

  resetUpdateForm = () => {
    this.setState({brandDetails: null, brandData: {name: null, logo: null}}, () => {
      document.getElementById('brandForm').reset()
    })
  }

  handleOnRemoveItem = () => {
    const brandData = {...this.state.brandData}
    brandData.logo = null
    this.setState({brandData})
  }

  searchBrand = () =>{
    this.getBrands()
  }

  render() {
    return (
      <App layout='boxed'>
        <div className="page-header">
          <h2 className="page-title">Brands</h2>
        </div>

        <div className="main-content">
          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">{!this.state.brandDetails ? 'Add Brand' : 'Update Brand'}</h4>
            </div>

            <div className="block-body">
              <Form onSubmit={(e) => {
                e.preventDefault()
                if (this.state.brandDetails) {
                  this.handleUpdateBrand()
                } else {
                  this.handleSubmitBrandForm()
                }
              }} id={"brandForm"}>

                <Form.Group as={Row} controlId="brandName">
                  <Form.Label column sm="3">
                    Brand Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control type={"text"}
                                  required
                                  defaultValue={this.state.brandDetails?.name}
                                  name="name"
                                  onChange={this.handleOnChangeInput}
                                  placeholder={"Brand name"}/>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId={'isApproved'}>
                      <Form.Label column sm="3"/>
                      <Col sm="9" md={8} lg={7}>
                          <Form.Check
                              type="checkbox"
                              id="isApproved"
                              label="Approve"
                              name={"is_approved"}
                              onChange={this.handleOnChangeInput}
                              checked={this.state?.brandDetails?.is_approved}
                              custom/>
                      </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formCategory">
                  <Form.Label column sm="3">
                    Brand Logo <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <BrowseFiles
                      isSingle={true}
                      image_category={'logo'}
                      onRemoveItem={this.handleOnRemoveItem}
                      images={this.state.brandData.logo ? [{thumb_img: this.state.brandData.logo}] : []}
                      onGetFiles={this.handleImageOnBrowse}/>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formCategory">
                  <Col sm="3"/>
                  <Col sm="9" md={8} lg={7}>
                    {!this.state.brandDetails ?
                      <>
                      {getLocalAuthData()?.permission?.includes('brand_add') ?
                        <Button type={`submit`}
                              disabled={this.state.isLoading}
                              variant={"primary"}>{this.state.isLoading ? 'Adding Brand...' : 'Add Brand'}</Button> : null}

                         {getLocalAuthData()?.permission?.includes('brand_search') ?
                            <Button type={"button"} onClick={this.searchBrand} variant={"default"} className={'ml-3'}>Search</Button>
                            : null}

                      </>
                      :
                      <div>
                        <Button variant={"primary"}
                                type={`submit`}
                                disabled={this.state.isLoading}
                                className={"mr-2"}>{this.state.isLoading ? 'Updating...' : 'Update'}</Button>

                        <Button type={"reset"} onClick={this.resetUpdateForm} variant={"default"}>Reset</Button>
                      </div>
                    }
                  </Col>
                </Form.Group>

              </Form>
            </div>
          </div>

          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Brand List</h4>
            </div>

            <div className="block-body">
              {this.state.brandList.length > 0 ?
                <>
                  <div className={"brand-list"}>
                    {this.state.brandList.map(brand => (
                      <>
                      {getLocalAuthData()?.permission?.includes('brand_list') ?
                          <div className="brand-col" key={brand._id}>
                            <Brand deleteBrand={this.handleDeleteBrand} getBrandDetails={this.handleGetBrandDetails} data={brand}/>
                          </div>
                          :null}
                      </>
                    ))}
                  </div>
                  <div className="mt-3">
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={this.state.pagination?.page}
                      itemsCountPerPage={this.state.pagination?.limit}
                      totalItemsCount={this.state.pagination?.totalDocs}
                      pageRangeDisplayed={20}
                      onChange={this.handlePageChange.bind(this)}
                      />
                  </div>
                </>
                :
                <div className={"alert alert-warning text-center text-bold"}>No brand added yet</div>
              }
            </div>
          </div>

        </div>
      </App>
    );
  }
}

export default Brands;
