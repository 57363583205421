import React, {Component} from "react"
import {Link, withRouter} from "react-router-dom"
import App from "../../App"
import {FiPlus, HiOutlineClipboardList} from "react-icons/all"
import {getLocalAuthData} from "../../helper/utils";
import axios from "axios";
import {API_BASE_URL} from "../../helper/env";
import {toast} from "react-toastify";
import LoadingSpinner from "../../components/LoadingSpinner";
import {Button, Form, Spinner} from "react-bootstrap";
import {confirmAlert} from "react-confirm-alert";
import {deleteCollection, deletePackage, getCollectionList} from "../../services/collectionServices";
import {activityLogPostData} from "../../services/commonServices";
import moment from "moment"
import {Input, DatePicker} from "antd"
import axois from "axios";
import {RiMovie2Line} from "@react-icons/all-files/ri/RiMovie2Line";
import {FaRegCreditCard} from "@react-icons/all-files/fa/FaRegCreditCard";
import "../../assets/css/package_pricing.css"

// noinspection DuplicatedCode
class HoichoiServiceBuy extends Component {
    state = {
        packages: [],
        packageDetails: {},
        isLoading: false,
        isLoading2: false,
        formData: {
            startDate: moment(),
            endDate: moment(),
        },
        isCheckedBox: false,
        phone_number: "",
        selected_package_id: "",
    }

    componentDidMount() {
        this.getData()
    }

    renderPackageList = () => {
        const packages = this.state.packages;
        if (packages) {
            return (
                <>
                    {packages.map((items, index) =>
                        /* <option onChange={() => {
                             this.setState({selected_package_id: items._id, packageDetails: items})
                         }} key={index} value={`${items._id}`}>{items?.name}</option>*/

                        <Form.Check
                            key={index}
                            custom
                            required
                            type="radio"
                            value={items._id}
                            onChange={() => {
                                this.setState({selected_package_id: items._id, packageDetails: items})
                            }}
                            id={`merchantType-${index}`}
                            name="merchant_type"
                            label={items?.name}
                        />
                    )}
                </>
            )
        }
    };


    getData() {
        this.setState({
            isLoading2: true
        })
        axios.get(`${API_BASE_URL}/hoichoi`).then(res => {
            this.setState({
                packages: res.data.items,
                isLoading2: false
            })
        }).catch(err => {
            toast.error(err.message)
        })
    }

    handleDeleteCollection = (id, name) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure to delete this package?',
            buttons: [{
                label: 'Yes',
                onClick: () => deletePackage(id)
                    .then(() => {
                        toast.success('Successfully deleted');
                        activityLogPostData('was delete this hoichoi service', '/services/hoichoi/list', name)
                        this.getData()
                    })
                    .catch(errMsg => {
                        toast.error(errMsg)
                    })
            }, {
                label: 'No',
            }]
        });
    }


    handlePackageClick = () => {
        this.setState({isLoading: true})
        const authData = getLocalAuthData()
        const {match: {params}} = this.props;

        this.setState({isLoading: true})
        axois.post(API_BASE_URL + '/cart-service', {
            package_id: this.state.selected_package_id,
            startDate: this.state.formData.startDate,
            phone_number: this.state.phone_number
        }, {
            headers: {"x-auth-token": authData.token, "x-api-client": "web"}
        }).then(res => {
            this.setState({
                isLoading: false,
                isDateModalOpen: false,
                isHoichoiModalSuccessOpen: true
            })
            if (res.data.status === 1) {
                let post_data = []
                post_data['tran_id'] = "TNXID" + Date.now()

                //  CUSTOMER INFORMATION
                post_data['cus_name'] = this.state.defaultAddress?.recipient_name
                post_data['cus_email'] = this.state.defaultAddress?.email || "info@demo.com"
                post_data['cus_add1'] = this.state.defaultAddress?.address
                post_data['cus_add2'] = ""
                post_data['cus_city'] = ""
                post_data['cus_state'] = ""
                post_data['cus_postcode'] = this.state.defaultAddress?.postcode
                post_data['cus_country'] = this.state.defaultAddress?.country?.name
                post_data['cus_phone'] = this.state.defaultAddress?.recipient_phone

                // SHIPMENT INFORMATION
                post_data['ship_name'] = this.state.defaultAddress?.recipient_name
                post_data['ship_add1'] = this.state.defaultAddress?.address
                post_data['ship_add2'] = ""
                post_data['ship_city'] = "test"
                post_data['ship_state'] = "test"
                post_data['ship_postcode'] = this.state.defaultAddress?.postcode
                post_data['ship_country'] = "Bangladesh"
                post_data['shipping_method'] = "NO"

                // # CART PARAMETERS
                post_data['cart'] = "json_encode($cart)"

                // CART SUMMERT
                post_data['total_amount'] = 150 //Required
                post_data['product_amount'] = 150 //Required
                post_data['vat'] = "0"
                post_data['discount_amount'] = 0
                post_data['convenience_fee'] = 0
                post_data['product_name'] = "N/A"
                post_data['product_category'] = "N/A"
                post_data['product_profile'] = "N/A"

                let dataObject = Object.assign({}, post_data)


                let dataObj = {
                    cart_item: {},
                    payment_method: 'online_payment',
                    delivery_address: this.state.deliveryAddress,
                    use_wallet: {},
                    billing_address: this.state.deliveryAddress,
                    tran_id: post_data['tran_id'],
                }

                let direct_api_url = API_BASE_URL + "/payment/get-ssl-session-service/" + res.data.order._id;
                const config = {
                    method: 'get',
                    url: direct_api_url,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };

                const planCode = this.state.packageDetails.planCode
                const startDate = this.state.startDate
                const endDate = this.state.endDate
                const that = this
                return new Promise((resolve, error) => {
                    axios(config).then(function (response) {
                        let obj = response.data
                        if (obj.status === 1) {
                            console.log("=======>>><<<<", res)
                            toast.success(res.data.message)
                            activityLogPostData(`was buy Hoichoi package`, `/orders/hoichoi-subscription`, "")


                            //window.location.href = obj.data.GatewayPageURL
                        }
                    }).catch(function (error) {
                        that.setState({
                            isLoading: false
                        })
                        toast.error(error.message)
                    });
                });


            } else {
                toast.error(res.data.message)
            }
        }).catch(err => {
            toast.error(err.message)
        })


    }

    disabledDate(current) {
        return current && current < moment().endOf('day');
    }


    render() {
        return (
            <App layout="boxed">
                <div className="main-content">


                    <div className="d-flex justify-content-center align-items-center">
                        {getLocalAuthData()?.permission?.includes('menu_orders_list') ?
                            <li className="list-unstyled mx-3"><Link to={'/orders/hoichoi-subscription'} class={"d-flex justify-content-center align-items-center flex-column"}><span className="card nav-icon card-body" style={{borderRadius: "10px"}}><HiOutlineClipboardList size={35}/></span> <span className="nav-text">Hoichoi Servie Order list</span></Link>
                            </li> : null}
                        {getLocalAuthData()?.permission?.includes('menu_orders_list') ?
                            <li className="list-unstyled mx-3"><Link to={'/services/hoichoi/list'} class={"d-flex justify-content-center align-items-center flex-column"}><span className="card nav-icon card-body" style={{borderRadius: "10px"}}><RiMovie2Line size={35}/></span> <span className="nav-text">Hoichoi Package</span></Link>
                            </li> : null}

                        {getLocalAuthData()?.permission?.includes('menu_orders_list') ?
                            <li className="list-unstyled mx-3"><Link to={'/services/hoichoi/buy'} class={"d-flex justify-content-center align-items-center flex-column"}><span className="card nav-icon card-body" style={{borderRadius: "10px"}}><FaRegCreditCard size={35}/></span> <span className="nav-text">Hoichoi Bulk Purchase</span></Link>
                            </li> : null}
                    </div>


                    <div className="page-header">
                        <h2 className="page-title">Buy Hoichoi Package</h2>
                        <div className="ml-auto">
                            <Link to={`${process.env.PUBLIC_URL}/services/hoichoi/add`}
                                  className="btn btn-sm btn-secondary"><FiPlus/> Add
                                Service</Link>
                        </div>
                    </div>


                    <div className="card-block position-relative">
                        <form>
                            <div className="block-body">
                                <React.Fragment>
                                    <div className="block-body">

                                        <div className="d-flex  justify-content-end">
                                            {/* <div className="form-group mr-3 mt-2">
                                                <label htmlFor="barndName" className="sr-only">Select Package</label>
                                                {this.renderPackageList()}

                                                <select id="merchantType" name="merchant_type"
                                                    onChange={(e) => {
                                                        this.setState({selected_package_id: e.target.value})
                                                    }}
                                                    className='form-control form-control-sm'>
                                                <option value="">--Select Type--</option>
                                                {this.renderPackageList()}
                                            </select>
                                            </div>*/}
                                            <div className="form-group mr-3 mt-2">
                                                <DatePicker
                                                    disabledDate={this.disabledDate}
                                                    className="form-control"
                                                    defaultValue={moment(this.state.formData.startDate, 'DD-MM-YYYY')}
                                                    onChange={date => {
                                                        const formDate = this.state.formData;
                                                        formDate.startDate = date;
                                                        if (this.state.packageDetails.time_type === '1') {
                                                            formDate.endDate = moment(date).add(this.state.packageDetails.time, 'days');
                                                        } else if (this.state.packageDetails.time_type === '2') {
                                                            formDate.endDate = moment(date).add(this.state.packageDetails.time, 'months');
                                                        } else if (this.state.packageDetails.time_type === '3') {
                                                            formDate.endDate = moment(date).add(this.state.packageDetails.time, 'years');
                                                        }

                                                        this.setState({formDate}, () => {
                                                            console.log(this.state.formData)
                                                        })
                                                    }}
                                                />
                                            </div>

                                            <div className="form-group mr-3 mt-2">
                                                <label htmlFor="barndName" className="sr-only">Phone Number</label>
                                                <Input
                                                    required
                                                    onChange={(e) => {
                                                        this.setState({
                                                            phone_number: e.target.value
                                                        })
                                                    }} placeholder="Enter your phone number"/>
                                            </div>

                                            <div className="form-group mr-3 mt-2">
                                                <label htmlFor="barndName" className="sr-only">Phone Number</label>
                                                <Button
                                                    size=""
                                                    /*disabled={!this.state.isCheckedBox}*/
                                                    block
                                                    variant="primary"
                                                    onClick={() => this.handlePackageClick()}>
                                                    {this.state.isLoading && <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />}
                                                    {this.state.isLoading ? ' Loading...' : 'Buy Now'}
                                                </Button>

                                            </div>

                                        </div>

                                        <div id="generic_price_table">
                                            <section style={{paddingTop:'15px'}}>
                                        <div className="container">
                                            <div className="row">
                                                {this.state.packages.map((pkg, index) => {
                                                    return pkg.planCode !== null && (

                                                        <div key={'hoichoi-package-id-' + index} className="col-md-4 mb-4">
                                                        <div className="generic_content active clearfix">
                                                            <div className="generic_head_price clearfix">
                                                                <div className="generic_head_content clearfix">
                                                                    <div className="head_bg"/>
                                                                    <div className="head">
                                                                        <span>{pkg?.name}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="generic_price_tag clearfix">
                                                                      <span className="price">
                                                                        <span className="sign">৳</span>
                                                                        <span className="currency">{pkg?.price}</span>
                                                                        <span className="cent"> BDT</span>
                                                                          {/*<span*/}
                                                                          {/*  className="month">/{pkg?.time_type === '1' ? 'Day' : pkg?.time_type === '2' ? 'Month' : 'Year'}</span>*/}
                                                                      </span>
                                                                </div>
                                                            </div>
                                                            <div className="generic_feature_list">
                                                                <ul>
                                                                    <li><span>{pkg?.time} {pkg?.time_type === '1' ? 'Day' : pkg?.time_type === '2' ? 'Month' : 'Year'}</span> plan
                                                                        basis
                                                                    </li>
                                                                    <li><span>{pkg?.simultaneous}</span> Simultaneous Stream</li>
                                                                </ul>
                                                            </div>
                                                            <div className="generic_price_btn clearfix">

                                                                <Form.Check
                                                                    key={index}
                                                                    custom
                                                                    required
                                                                    checked={this.state.selected_package_id === pkg?._id}
                                                                    type="radio"
                                                                    value={pkg?._id}
                                                                    onChange={() => {
                                                                        this.setState({selected_package_id: pkg?._id, packageDetails: pkg})
                                                                    }}
                                                                    label={`Buy now`}
                                                                    id={`merchantType-${index}`}
                                                                />

                                                               {/* <Form.Check
                                                                    key={index}
                                                                    custom
                                                                    required
                                                                    type="radio"
                                                                    value={pkg?._id}
                                                                    onChange={() => {
                                                                        this.setState({selected_package_id: pkg?._id, packageDetails: pkg})
                                                                    }}
                                                                    id={`merchantType-${index}`}
                                                                />*/}
                                                               {/* <a className href={'/hoichoi-service/' + pkg?._id}>Subscribe</a>*/}
                                                                {/*<a className onClick={() => this.handlePackageClick(pkg.plan_code)}>Subscribe</a>*/}
                                                            </div>
                                                        </div>

                                                    </div>)
                                                })}
                                            </div>
                                            <br/>
                                        </div>
                                            </section>
                                        </div>

                                    </div>


                                </React.Fragment>
                            </div>
                        </form>
                    </div>

                    {this.state.isLoading2 && <div className="page-header"><LoadingSpinner/></div>}

                </div>
            </App>
        )
    }
}

export default withRouter(HoichoiServiceBuy)
