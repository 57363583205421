import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FaLongArrowAltLeft} from "react-icons/all";
import Product from "../../components/Product";

class AddFreeShippingOffer extends Component {
    render() {
        return (
            <App layout="boxed">
                <div className="page-header">
                    <h2 className="page-title">Add Free Shipping Offer</h2>
                    <div className="ml-auto">
                        <Link to={`${process.env.PUBLIC_URL}/promotions/free-shipping-offers`} className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Free Shipping Offer List</Link>
                    </div>
                </div>

                <div className="main-content">


                    <Form>
                        <div className="card-block">

                            <div className="block-header">
                                <h4 className="block-title">Define Offer</h4>
                            </div>

                            <div className="block-body">
                                {/*Promotion name*/}
                                <Form.Group as={Row} controlId="formPromotionName">
                                    <Form.Label column sm="3">
                                        Promotion Name <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type="text" placeholder=""/>
                                    </Col>
                                </Form.Group>

                                {/*Budget*/}
                                <Form.Group as={Row} controlId="formPromotionName">
                                    <Form.Label column sm="3">
                                        Budget <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type="text" placeholder=""/> BDT
                                    </Col>
                                </Form.Group>

                                {/*Condition*/}
                                <Form.Group as={Row} controlId="formVoucherType">
                                    <Form.Label column sm="3">
                                        Condition <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={4} lg={4}>
                                        <Form.Control as="select" placeholder="">
                                            <option value="1">No condition</option>
                                            <option value="2">Shop item quantity above</option>
                                            <option value="3">Shop order amount above</option>
                                        </Form.Control>
                                    </Col>

                                    <Col sm="9" md={4} lg={4}>
                                        <Form.Control type="text" placeholder=""/>
                                    </Col>
                                </Form.Group>

                                {/*Period*/}
                                <Form.Group as={Row} controlId="formVoucherType">
                                    <Form.Label column sm="3">
                                        Period <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="8" md={7} lg={6}>
                                        <Form.Control as="select" placeholder="">
                                            <option value="1">Long term</option>
                                            <option value="2">Specific Period</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formStartFrom">
                                    <Form.Label column sm="3">
                                        Start From <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type="text" placeholder=""/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formEndTime">
                                    <Form.Label column sm="3">
                                        End Time <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type="text" placeholder=""/>
                                    </Col>
                                </Form.Group>


                                {/*Region*/}
                                <Form.Group as={Row} controlId="formVoucherType">
                                    <Form.Label column sm="3">
                                        Region <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control as="select" placeholder="">
                                            <option value="1">All</option>
                                            <option value="2">Specific Region</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                <div className="card-block">
                                    <div className="block-body">

                                        <Form.Group as={Row} controlId="formBuyQuantity">
                                            <Form.Label column sm="3">
                                                Country <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="6" md={2} lg={2}>
                                                <Form.Control type="text" placeholder=""/>
                                            </Col>

                                            <Form.Label column sm="2">
                                                Cities <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="6" md={2} lg={2}>
                                                <Form.Control type="text" placeholder=""/>
                                            </Col>

                                            <Form.Label column sm="2">
                                                <Link onClick={console.log('hello')}>x</Link>
                                            </Form.Label>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formBuyQuantity">
                                            <Form.Label column sm="3">
                                                Country <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="6" md={2} lg={2}>
                                                <Form.Control type="text" placeholder=""/>
                                            </Col>

                                            <Form.Label column sm="2">
                                                Cities <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="6" md={2} lg={2}>
                                                <Form.Control type="text" placeholder=""/>
                                            </Col>

                                            <Form.Label column sm="2">
                                                <Link onClick={console.log('hello')}>x</Link>
                                            </Form.Label>
                                        </Form.Group>

                                        <Button size="sm" variant="primary"> + Add Region</Button>
                                    </div>
                                </div>


                                {/*APPLY to ENTIRE SHOP or SPECIFIC PRODUCT*/}
                                <Form.Group as={Row} controlId="formVoucherDisplayArea">
                                    <Form.Label column sm="3">
                                        Apply to
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Check
                                            custom inline type="radio"
                                            name="display_area"
                                            id={`apply-to-entire-shop`}
                                            label={`Entire Shop`}
                                        />
                                        <Form.Check
                                            custom inline type="radio"
                                            name="display_area"
                                            id={`apply-to-specific-product`}
                                            label={`Specific Product`}
                                        />
                                    </Col>
                                </Form.Group>


                                <div className="card-block">
                                    <div className="block-body">
                                        <div className="product-list">
                                            <div className="product-item"><Product/></div>
                                        </div>
                                        <Button size="sm" variant="primary"> + Add Product</Button>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className="card-block">
                            <div className="block-body text-right">
                                <Button size="lg" className="mr-3" variant="default">Cancel</Button>
                                <Button size="lg" variant="primary">Save</Button>
                            </div>
                        </div>

                    </Form>

                </div>
            </App>
        );
    }
}

export default AddFreeShippingOffer;
