import React, {Component} from 'react';
import App from "../../App";
import {
    Button,
    Col,
    Form,
    Row,
    Modal, Badge
} from "react-bootstrap";

import axios from "axios";
import config, {API_BASE_URL} from "../../helper/env";
import {ToastsContainer, ToastsStore} from "react-toasts";
import {toast} from "react-toastify";
import {getDeviceType, getLocalAuthData, headerData, urlPramsToObj} from "../../helper/utils";
import {Link, withRouter} from "react-router-dom";
import {FiEdit, FiTrash, FiPlus, IoMdCheckmark, AiOutlineLink, BsEye, FaFileExport} from "react-icons/all";
import {confirmAlert} from "react-confirm-alert";
import {toggleStatusUpdate} from "../../services/bankServices"
import {activityLogPostData, deleteItem, getTableData, updateItemPartial} from "../../services/commonServices";
import Pagination from "react-js-pagination";
import moment from "moment";
import {useJsonToCsv} from "react-json-csv";

class PartnerOnboardList extends Component {


    // TODO: Action Example
    //{ getLocalAuthData()?.permission?.includes('pro_addhjgjh') ?  <Button>TEST</Button> : null}
    //

    constructor(props) {
        super(props);
        this.state = {
            formData: {
                permission: []
            },
            items: [],
            permission_list: [],
            selected_id: '',
            editFormData: null,
            editStatus: false,
            isShowModal: false,
            definedPermission: [],
            queryData: {limit: 20, page: 1},
            LiveList: [],
            LiveDetails: null,
            isLoading: false,
            pagination: {},
        }
    }


    componentDidMount() {
        this.getAgentData()
    }


    getAgentData = () => {
        const queryData = {...this.state.queryData};
        queryData.search_string = "";
        getTableData('/partner', this.state.queryData).then(res => {

            console.log(res)
            this.setState({LiveList: res.items, isLoading: false, pagination: res.pagination})
        }).catch(errMsg => {
            toast.error(errMsg);
        })
    }

    handlePageChange = async (pageNumber) => {
        console.log(pageNumber)
        const history = this.props.history
        history.push(`${history.location.pathname}?page=${pageNumber}`)
        await this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber}, ()=>{
            this.getAgentData();
        });
    }


    handleChangeStatus = (e , id ,name)=>{
        console.log(e.target.value)

        if (e.target.value !== "false"){
            updateItemPartial(`/partner/changeStatus/${id}`,{ status: e.target.value}).then(res => {
                console.log(res)
                if (res.status === 1){
                    this.getAgentData();
                    toast.success(res.message)
                    activityLogPostData('was change this partner onboard','/partner-onboard/list/',name)
                }else {
                    toast.warning(res.message)
                }
                //  this.setState({LiveList: res.items, isLoading: false, pagination: res.pagination})
            }).catch(errMsg => {
                toast.error(errMsg);
            })
        }
    }

    handleDeleteItem = (id ,name) => {
        confirmAlert({
            title: 'Permanent Delete',
            message: 'Are you sure to delete permanently this Agent?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteItem(`/partner/${id}/permanent`).then(res =>
                        {
                            if (res.status === 1){
                                toast.success(res.message)
                                activityLogPostData('was delete partner onboard','/partner-onboard/list/',name)
                                this.getAgentData()
                            }else {
                                toast.error(res.message)
                            }
                        }
                    ).catch(err => console.log(err))
                }
                ,
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });
    };


    handleExportFile = async (fileType) => {
        let queryData = "";
        Object.entries(this.state.queryData).forEach(([key, val]) => {
                queryData = queryData.concat(key + "=" + val + "&");
        })
        await this.setState({isExporting: true});
        const authData = getLocalAuthData();
        axios.get(`${API_BASE_URL}/agent?export=${fileType}&${queryData}`, {
            headers: {"x-auth-token": authData.token, "x-api-client": getDeviceType()}
        }).then(res => {
            console.log(res)
            this.setState({isExporting: false});
        }).catch(e => console.log("Error"));
    }



    render() {
        return (
            <App layout={"boxed"}>
                <div className="page-header">
                    <h2 className="page-title">Partner Onboard</h2>
                    <Link to={"/partner-onboard/add"} className={"btn btn-sm btn-secondary"}>
                        + Create New Partner Onboard
                    </Link>
                   {/* <div className="ml-auto">
                        <a href={`${API_BASE_URL}/agent/?export=xlsx`}>
                            <Button size={"sm"} variant={"default"}

                                    className="mb-0 ml-2"><FaFileExport/> Export</Button></a>
                    </div>*/}
                </div>

                <div className="main-content">

                    <div className="card-block">

                        <div className="block-header">
                            <h4 className="block-title">Partner onboard List</h4>
                        </div>


                        <div className="block-body">
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th style={{width: '20px'}}>#</th>
                                    <th>COMPANY LOGO</th>
                                    <th>NAME</th>
                                    <th>BANNER</th>
                                    <th>URL</th>
                                    <th style={{width: `1%`}}/>
                                </tr>
                                </thead>
                                <tbody>



                                {( this.state.LiveList?.length > 0) ?
                                    this.state.LiveList.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <img src={item?.com_logo}
                                                     width={'50'}
                                                     height={'50'}/>
                                            </td>
                                            <td>{item?.com_name}</td>
                                            <td><img src={item?.banner}
                                                     width={'50'}
                                                     height={'50'}/></td>
                                            <td><a href={item?.url} target="_blank" ><AiOutlineLink style={{color: "blue"}}/></a> </td>

                                            <td className="nowrap text-center">
                                                <Link
                                                    to={"/partner-onboard-edit/" + item._id}>
                                                    <span className="btn btn-sm btn-outline-primary mr-3">
                                                       Edit
                                                    </span>
                                                </Link>
                                                <span
                                                    onClick={() => this.handleDeleteItem(item._id , item?.com_name)}
                                                    className="btn btn-sm btn-outline-danger mr-3">Delete</span>
                                            </td>
                                        </tr>
                                    )) :
                                    <tr>
                                        <td colSpan={7} className={`text-bold text-center`}>No item found</td>
                                    </tr>
                                }


                                </tbody>
                            </table>

                            <div className="mt-3">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={this.state?.pagination?.page}
                                    itemsCountPerPage={this.state?.pagination?.limit}
                                    totalItemsCount={this.state?.pagination?.totalDocs ? this.state?.pagination?.totalDocs : 0}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}/>
                            </div>
                        </div>

                    </div>

                </div>
            </App>
        );
    }
}

export default withRouter(PartnerOnboardList);
