import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { FiPlus, FiTrash } from "react-icons/all";
import { toast } from "react-toastify";
import slideSample from "../assets/img/slide-sample.jpg";
import "../assets/scss/main-slide.scss";
import { getLocalAuthData } from "../helper/utils";
import { addItem, permanentDeleteItem } from "../services/baseServices";
import BrowseFiles from "./BrowseFiles";

const SectionSlide = ({section_info}) => {
  const [showAddSlideModal, setShowAddSlideModal] = useState(false)
  const [banners, setBanners] = useState([])
  const [formData, setFormData] = useState(null)
  const [imageArray, setImageArray] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    getBanners()
  }, [])
  const banner_location = 'category-with-product-' /*+ section_info.settings.title*/
  const getBanners = () => {
    setBanners(section_info.slider_images ?? [])
    // getData(`/banner`, {location: banner_location})
    //   .then(res => {
    //     // res.items
    //     setBanners(res.items)
    //   })
  }

  const handleAddSlideModalClose = () => setShowAddSlideModal(false);
  const handleAddSlideModalShow = () => {
    setFormData({banner_type: 1, image_size_template: 'banner_263x629', location: banner_location})
    setShowAddSlideModal(true)
  };

  // noinspection DuplicatedCode
  const handleDeleteBanner = (id) => {
    confirmAlert({
      title: 'Permanent Delete',
      message: 'Are you sure to delete this banner?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            permanentDeleteItem(`/banner/${id}/permanent/`).then(res => {
              let slider_images = section_info?.slider_images?.length ? section_info?.slider_images : []
              slider_images = slider_images.filter(item => {
                return item._id !== id
              })
              section_info.slider_images = slider_images
              getBanners()
              toast.success("Slider Image deleted successfully please save your updated setting")
            }).catch(errMsg => {
              toast.error(errMsg)
            })
          }
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });


  }

  const handleInputOnChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value})
  }

  const handleImageOnChange = async (images, name) => {
   /* let array = []
    await images.map((data,i)=>{
      array.push(images[i].actual_img)
    })*/

    //await setImageArray(array)
    await setFormData({...formData, [name]: images[0].actual_img})
  }

  const handleSaveSlider = () => {
    setIsSubmitting(true)
    /*console.log(imageArray)
    console.log(formData)*/
    addItem(`/banner`, formData)
      .then(res => {
        let slider_images = section_info?.slider_images?.length ? section_info?.slider_images : []
        // noinspection JSUnresolvedVariable
        let new_slider = {
          banner_type: res.banner.banner_type,
          createdAt: res.banner.createdAt,
          image: res.banner.image,
          m_image: res.banner.m_image,
          link: res.banner.link,
          location: res.banner.location,
          _id: res.banner._id,
        }
        slider_images.push(new_slider)
        section_info.slider_images = slider_images

        toast.success(res.message)
        handleAddSlideModalClose()
        getBanners()
        setIsSubmitting(false)

      })
      .catch(errMsg => {
        toast.error(errMsg)
        setIsSubmitting(false)
      })
  }

  console.log("banners", banners);
  console.log("section_info", section_info);

  return (
    <div className="card-block">
      <div className="block-header">
        <h4 className="block-title">Slider</h4>
      </div>

      <div className="block-body">
        <Modal show={showAddSlideModal} onHide={handleAddSlideModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Slide</Modal.Title>
          </Modal.Header>
          <Form onSubmit={(e) => {
            e.preventDefault()
            handleSaveSlider()
          }}>
            <Modal.Body>
              <Form.Group as={Row} controlId="sliderImg">
                <Form.Label column sm="4">
                  Slide Image <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <BrowseFiles
                    userId={getLocalAuthData()?._id}
                    isSingle={true}
                    image_category={'category_product_section_banner'}
                    onRemoveItem={() => {
                      const newFormData = {...formData}
                      delete newFormData.image
                      setFormData(newFormData)
                    }}
                    selected_image_size_template={'banner_263x629'}
                    images={formData?.image ? [{thumb_img: formData?.image}] : []}
                    helpText={'Image size 1920 x 430'}
                    onGetFiles={(images) => handleImageOnChange(images, 'image')}/>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="sliderImgMobile">
                <Form.Label column sm="4">
                  Slide Image (Mobile) <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <BrowseFiles
                    userId={getLocalAuthData()?._id}
                    isSingle={true}
                    image_category={'category_product_section_banner'}
                    onRemoveItem={() => {
                      const newFormData = {...formData}
                      // noinspection JSUnresolvedVariable
                      delete newFormData.m_image
                      setFormData(newFormData)
                    }}
                    selected_image_size_template={'banner_263x629'}
                    images={formData?.m_image ? [{thumb_img: formData?.m_image}] : []}
                    helpText={'Image size 755 x 390'}
                    onGetFiles={(images) => handleImageOnChange(images, 'm_image')}/>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formPromotionName">
                <Form.Label column sm="4">
                  Slide Link <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={8}>
                  <Form.Control type="text" onChange={handleInputOnChange} required={'#'} name={'link'} placeholder=""/>
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="default" onClick={handleAddSlideModalClose}>
                Close
              </Button>
              <Button variant="primary" type={'submit'}>
                {isSubmitting ? 'adding slide' : 'Add Slide'}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Row>
          {banners.map((item, index) => (
            <Col key={index} md={3}>
              <div className="main-slide">
                <img src={item?.image} alt=""/>
                <div className="slide-actions">
                  <div className="actions">
                    <span onClick={() => handleDeleteBanner(item?._id)} className="action"><FiTrash/></span>
                    {/*<span className="action"><FiMove/></span>*/}
                  </div>
                </div>
              </div>
            </Col>
          ))}

          <Col md={3}>
            <div onClick={handleAddSlideModalShow} className="main-slide btn-add-slide">
              <img src={slideSample} alt=""/>
              <div className="slide-actions">
                <div className="actions">
                  <span className="action"><FiPlus/></span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SectionSlide;
