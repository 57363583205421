import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import axios from "axios";
import config from "../../helper/env";
import {ToastsContainer, ToastsStore} from "react-toasts";
import {toast} from "react-toastify";
import {getDeviceType, getLocalAuthData} from "../../helper/utils";
import InputPassword from "../../components/InputPassword";
import {activityLogPostData} from "../../services/commonServices";

class ResetPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      editFormData: null,
      editStatus: false
    }
  }

  handleFormDataChange = (e) => {
    this.setState({formData: {...this.state.formData, [e.target.name]: e.target.value}}, () => {
      console.log(this.state);
    });
  };

  handleSubmitForm = () => {
    const data = this.state.formData;
    let url = `${config.API_BASE_URL}/change-password`;
    const authData = getLocalAuthData()
    let headers = {headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}}
    axios.post(url, data, headers).then(response => {
      if (response.data.status === 1) {
        toast.success(response.data.message);
        this.setState({formData: {old_password: '', new_password: '', confirm_password: ''}});
        activityLogPostData('has change password','/reset-password')
        window.location.reload();
      }
      if (response.data.status === 2) {
        toast.error(response.data.message);
      }
    }).catch(() => {
      toast.error("Something went wrong please try again after refresh the page.");
    });
  };

  render() {
    return (
      <App layout={"boxed"}>
        <div className="page-header">
          <h2 className="page-title">Reset Password</h2>
        </div>

        <div className="main-content">
          <div className="card-block">
            {getLocalAuthData()?.permission?.includes('reset_passdword') ?
            <div className="block-body">
              <Form id={"bankForm"}>
                <Form.Group as={Row} controlId="oldPassword">
                  <Form.Label column sm="3">
                    Old Password <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <InputPassword required
                                   name="old_password" onChange={this.handleFormDataChange}
                                   autoComplete="off"
                                   placeholder={"Old Password"} minLength={6}/>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="newPassword">
                  <Form.Label column sm="3">
                    New Password <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <InputPassword onChange={this.handleFormDataChange}
                                   required
                                   name="new_password"
                                   autoComplete="off"
                                   placeholder="New Password" minLength={6}/>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="reEnterNewPassword">
                  <Form.Label column sm="3">
                    Re-enter New Password <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <InputPassword onChange={this.handleFormDataChange}
                                   required
                                   name="confirm_password"
                                   autoComplete="off"
                                   placeholder="Re-enter New Password" minLength={6}/>
                  </Col>
                </Form.Group>


                <Form.Group as={Row} controlId="bankFormSubmit">
                  <Form.Label column sm="3"/>
                  <Col sm="9" md={8} lg={7}>
                    <Button variant={"primary"} onClick={this.handleSubmitForm}>Save</Button>
                  </Col>
                </Form.Group>
              </Form>
            </div>
            :null}
          </div>

        </div>
        <ToastsContainer store={ToastsStore}/>
      </App>
    );
  }
}

export default ResetPassword;
