import React from 'react'
import {Component} from 'react'
import App from "../../App"
import {Button, Form} from "react-bootstrap"
import {FaFileExport} from "react-icons/all"
import Moment from "moment"
import {useJsonToCsv} from "react-json-csv"
import Pagination from 'react-js-pagination'
import {withRouter} from "react-router-dom"
import DateRangePicker from "react-bootstrap-daterangepicker"
import {fileDownload, getTableData} from "../../services/commonServices"
import moment from "moment"

const {saveAsCsv} = useJsonToCsv()

class DeliveryReport extends Component {
    state = {
        startDate: null,
        endDate: null,
        reportData: [],
        queryData: {
            limit: 20
        },
        pagination: {},
    }


    getData = (queryData) => {
        getTableData('/reports/deliveries', queryData).then(res => {
            this.setState({
                reportData: res.data,
                pagination: res.paginate
            })
        })
    }

    componentDidMount() {
        this.getData(this.state.queryData)
    }

    handlePageChange = async (pageNumber) => {
        const history = this.props.history
        history.push(`${history.location.pathname}?page=${pageNumber}`)
        await this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber}, () => {
            this.getData(this.state.queryData)
        })
    }


    handleExport = async () => {
        this.setState({export_loading: true})
        let queryData = {...this.state.queryData}
        queryData.export = 'xlsx'
        this.setState({queryData}, ()=>{
            fileDownload(`/reports/deliveries`, this.state.queryData).then((response)=>{
                console.log(response)
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'ekshop-deliveries-report.xlsx');
                document.body.appendChild(link);
                link.click();
                this.setState({
                    export_loading: false
                })
            })
            queryData.export = null
            this.setState({
                queryData
            })
        })

        // let queryData = ""
        // // noinspection JSMismatchedCollectionQueryUpdate
        // let mData = []
        //
        // this.state.reportData.length > 0 && this.state.reportData.map((data, index) => {
        //
        //     mData.push({
        //         'Index': index + 1,
        //         'Pending Order': data?.pending_order,
        //         'Confirmed Order': data?.confirmed_order,
        //         'Cancelled Order': data?.cancelled_order,
        //         'Delivered Order': data?.delivered_order,
        //         'Date': moment(data?.date).format("hh:mm a DD-MM-YYYY"),
        //     })
        //
        // })
        //
        // const filename = 'EkShop-Delivery Report',
        //     fields = {
        //         'Index': "Index",
        //         'Pending Order': "Pending Order",
        //         'Confirmed Order': "Confirmed Order",
        //         'Cancelled Order': "Cancelled Order",
        //         'Delivered Order': "Delivered Order",
        //         'Date': "Date",
        //
        //     },
        //     style = {
        //         padding: "30px",
        //         maxHeight: "20px"
        //     },
        //     data = mData,
        //     text = "Convert Json to Csv"
        // saveAsCsv({data, fields, filename})

    }


    handleOnApply = (e, picker) => {
        const queryData = {...this.state.queryData}
        const startDate = Moment(picker.startDate).format('DD-MM-YYYY')
        const endDate = Moment(picker.endDate).format('DD-MM-YYYY')
        queryData['from_date'] = startDate
        queryData['to_date'] = endDate

        this.setState({
            queryData
        }, () => {
            this.getData(this.state.queryData)
        })
    }


    render() {
        return (
            <App>
                <div className="page-header">
                    <h2 className="page-title">Delivery Report</h2>

                    <div className="ml-auto">
                        <Button onClick={() => {
                            this.handleExport()
                        }} size={"sm"} variant={"default"} className="mb-0 mr-2"><FaFileExport/> Export</Button>
                    </div>
                </div>

                <div className="card-block">
                    <div className="block-body">
                        <Form className="d-flex v-middle">
                            <div className="form-inline">
                                <Form.Group className={'mr-2'}>
                                    <Form.Label className={"sr-only"}>Date Range Picker</Form.Label>
                                    <DateRangePicker
                                        onApply={this.handleOnApply}>
                                        <input type="text"
                                               style={{textAlign: 'left', minWidth: '150px'}}
                                               placeholder={"Select date range"}
                                               className="form-control form-control-sm"/>
                                    </DateRangePicker>
                                </Form.Group>

                            </div>
                        </Form>

                        <hr/>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Date</th>
                                    <th>delivered order</th>
                                    <th>cancelled order</th>
                                    <th>confirmed order</th>
                                    <th>Pending order</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.reportData.length > 0 && this.state.reportData.map((data, i) => {
                                    return (
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{moment(data?.date).format("hh:mm a DD/MM/YYYY")}</td>
                                            <td>{data?.delivered_order}</td>
                                            <td>{data?.cancelled_order}</td>
                                            <td>{data?.confirmed_order}</td>
                                            <td>{data?.pending_order}</td>
                                        </tr>)
                                })}
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-3">
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={this.state?.pagination?.page}
                                itemsCountPerPage={this.state?.pagination?.limit}
                                totalItemsCount={this.state?.pagination?.totalDocs ? this.state?.pagination?.totalDocs : 0}
                                pageRangeDisplayed={10}
                                onChange={this.handlePageChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </App>
        )
    }
}

export default withRouter(DeliveryReport)
