import React, {Component} from 'react';
import App from "../../App";
import "../../assets/scss/product.scss"
import {FaLongArrowAltLeft, FiMessageSquare, FiPlus, IoMdClose} from "react-icons/all";
import {Link, withRouter} from "react-router-dom";
import {Alert, Badge, Button, Col, Form, Modal, Row} from "react-bootstrap";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CreatableSelect from 'react-select/creatable';
import "../../assets/scss/add-product.scss";
import styleReactSelect from "../../assets/styleReactSelect";
import BrowseFiles from "../../components/BrowseFiles";
import {connect} from "react-redux";
import {
    getChildLocations,
    getLocationList,
} from "../../services/locationServices";
import ChooseCategory from "../../components/ChooseCategory";
import {getBrandList} from "../../services/brandServices";
import {UploadAdapter} from "../../adapters/ckUploadAdapter";

import {addProduct, getProductListAlt, getProductListSecondary} from "../../services/productServices";
import {
    addUser,
    getBillingAddresses,
    getShippingAddresses,
    addBillingAddress,
    addShippingAddress,
    getCart,
    removeCart,
    addToCart,
    placeOrder
} from "../../services/customerOrderPlaceServices";
import {categoryDetails, getCategoryList} from "../../services/categoryServices";
import SelectCollection from "../../components/SelectCollection";
import {toast} from "react-toastify";
import Select from "react-select";
import AsyncSelect from "react-select";
import {getBDDivisions, getMerchantList, getMerchantTypeList} from "../../services/merchantServices";
import {getStoreList} from "../../services/storeServices";
import {getLocalAuthData} from "../../helper/utils"
import {activityLogPostData, applyVoucherCode, getTableData, updateCartShippingOption, updateItemPartial} from "../../services/commonServices";

import moment from "moment";
import {addCommissionSettings} from "../../services/commissionSettingsServices";
import QuantityCounter from "../../components/QuantityCounter";
import Pagination from "react-js-pagination";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {LinearProgress} from "@material-ui/core";

class CustomerOrderPlace extends Component {
    state = {
        isLoadingQuery : false,
        fridge: false,
        cartSummery: null,
        user_id: null,
        customerNumber: null,
        customerName: null,
        is_shipping_and_billing_same: true,
        isShowAddBillingAddressModal: false,
        isShowShippingAddressModal: false,
        isBillingAddressAddFromValidation: false,
        isShippingAddressAddFromValidation: false,
        countries: [],
        existing_locations: [{}],
        existing_locationsShipping: [{}],
        updateShipping: null,
        customerData: null,
        shippingAddress: {
            is_first_time: true
        },
        billingAddress: {
            is_first_time: true
        },
        loadedShippingAddress: null,
        loadedBillingAddress: null,
        merchants: [],
        merchantList: [],
        categoryList: [],
        products: [],
        selectedProducts: [],
        checkedIndex: [],
        selectedCheckboxIndex: [],
        merchantTypes: [],
        selectedProductList: [],
        isLoading: true,

        addUserFormData: {
            country_code: "+880",
            type: 3
        },

        formData: {
            products: [],
            valueType: "2"
        },
        isProductModalProductShowing: false,
        isSubmitting: false,
        defaultQuerySettings: {
            merchantList: {lite_data: 1},
            storeList: {merchants: this.merchants || [], status: 1, approval_status: 1, lite_data: 1},
            productList: {limit: 20}
        },
        isProductLoading: false,
        cart_list: [],
        queryData: {limit: 8},
        entrepreneurType: [],
        checkedItems: [],
    }

    componentDidMount() {
        getMerchantTypeList().then(res => {
            const merchantTypes = []
            res.forEach(item => {
                merchantTypes.push({label: item.type_name, value: item._id})
            });
            this.setState({merchant_types: merchantTypes, entrepreneurType: merchantTypes})
        }).then(errMsg => {
            console.log('Error Message: ', errMsg)
        })
        /* getStoreList(this.state.defaultQuerySettings.storeList).then(res => {
             const stores = []
             res.items.forEach(item => {
                 stores.push({label: item.store_name, value: item._id})
             })
             this.setState({storeList: stores})
         })*/

        /*getProductListSecondary(this.state.defaultQuerySettings.productList).then(res => {

            console.log(res.products.length)

            this.setState({
                products: res.products,
                productPagination: res.pagination,
                limit: 20,
                checkedIndex: new Array(res.products.length).fill(false)
            })
        })*/
    }

    handleInputOnChange = (e) => {

        if (e.target.name === "name") {
            this.setState({customerData: {name: e.target.value}})
        }

        let value = e.target.value
        const formdata = {...this.state.addUserFormData}
        if (e.target.name === "phone" && e.target.value[0] === "0")
            value = e.target.value.substring(1)
        formdata[e.target.name] = value;
        this.setState({addUserFormData: formdata})
    }
    handleInputOnChange2 = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }


    handleAddBillingAddressModalHide = () => {
        this.setState({isShowAddBillingAddressModal: false});
    };

    handleAddShippingAddressModalHide = () => {
        this.setState({isShowShippingAddressModal: false});
    };

    handleOnChangeQnt = (value, skuId, bundle_id = null, user_id) => {
        this.setState({isSubmitting: true, fridge: true}, () => {
            this.addItemToCart(user_id, skuId, value, (cart_items) => {
                this.setState({cart_list: cart_items});
            });
        });
        // this.setState({isLoading: true})
        // this.setState({use_wallet: false, isUpdating: true}, () => {
        //     this.props.handleAddToCart(skuId, value, null, () => {
        //         this.setState({quantity: value, isUpdating: false}, () => {
        //             this.props.getCartItems(null, (res) => {
        //                 this.setState({isLoading: false})
        //                 this.setState({isUpdating: false})
        //             })
        //         })
        //     }, false, false, bundle_id)
        // })
    }

    handleAddBillingAddressModalShow = async (isEditing) => {
        await getLocationList().then((res) => {
            let existing_locations = [{}];
            existing_locations[0] = res;
            this.setState({existing_locations}, () => {
                if (!isEditing) {
                    this.setState({
                        billingAddress: {
                            name: this.state.customerData?.name,
                            country_code: this.state.customerData?.phone?.split("-")[0] || "+880",
                            phone: this.state.customerData?.phone?.split("-")[1] || "",
                            address: "",
                            postcode: "",
                            locations: [],
                            location_ids: [],
                            is_home_address: true,
                            is_office_address: false,
                            is_first_time: false
                        }
                    })
                } else {
                    this.setState({
                        billingAddress: {
                            name: this.state.loadedBillingAddress?.name,
                            country_code: this.state.loadedBillingAddress?.country_code,
                            phone: this.state.loadedBillingAddress?.phone,
                            address: this.state.loadedBillingAddress?.address,
                            postcode: this.state.loadedBillingAddress?.postcode,
                            email: this.state.loadedBillingAddress?.email,
                            locations: this.state.loadedBillingAddress?.locations,
                            location_ids: this.state.loadedBillingAddress?.location_ids,
                            is_home_address: this.state.loadedBillingAddress?.is_home_address,
                            is_office_address: this.state.loadedBillingAddress?.is_office_address,
                            // is_first_time: this.state.loadedShippingAddress?.is_first_time
                        }
                    })
                }
                this.setState({
                    isShowAddBillingAddressModal: true,
                });
            });
        });
    };

    handleAddShippingAddressModalShow = async (isEditing) => {
        await getLocationList().then((res) => {
            let existing_locationsShipping = [{}];
            existing_locationsShipping[0] = res;
            this.setState({existing_locationsShipping}, () => {
                if (!isEditing) {
                    this.setState({
                        shippingAddress: {
                            recipient_name: this.state.customerData?.name,
                            country_code: this.state.customerData?.phone?.split("-")[0] || "+880",
                            recipient_phone: this.state.customerData?.phone?.split("-")[1] || "",
                            address: "",
                            postcode: "",
                            locations: [],
                            location_ids: [],
                            is_home_address: true,
                            is_office_address: false,
                            is_first_time: false
                        }
                    })
                } else {
                    this.setState({
                        shippingAddress: {
                            recipient_name: this.state.loadedShippingAddress?.recipient_name,
                            country_code: this.state.loadedShippingAddress?.country_code,
                            recipient_phone: this.state.loadedShippingAddress?.recipient_phone,
                            address: this.state.loadedShippingAddress?.address,
                            postcode: this.state.loadedShippingAddress?.postcode,
                            email: this.state.loadedShippingAddress?.email,
                            locations: this.state.loadedShippingAddress?.locations,
                            location_ids: this.state.loadedShippingAddress?.location_ids,
                            is_home_address: this.state.loadedShippingAddress?.is_home_address,
                            is_office_address: this.state.loadedShippingAddress?.is_office_address,
                            // is_first_time: this.state.loadedShippingAddress?.is_first_time
                        }
                    }, () => {
                    })
                }
                this.setState({
                    isShowShippingAddressModal: true
                });
            });
        });
    };


    handleLocationInputOnChange = (e, index, typePurpose) => {
        const existing_locations = [...this.state.existing_locations];
        if (typePurpose === "addressBillingLocation") {
        } else {
            this.handleReturnInputOnChange(e);
        }

        const billingAddress = {...this.state.billingAddress};
        const updatedBillingAddress = {...this.state.updatedBillingAddress};
        let location_ids = billingAddress.location_ids;
        let locations = billingAddress.locations;

        if (e.target.value !== "") {
            if (index >= 0 && existing_locations?.length > index) {
                existing_locations.splice(index + 1, existing_locations.length - index);
                location_ids.splice(index, location_ids.length - index);
                locations.splice(index, locations.length - index);
            }

            let selected_location_array = existing_locations[index];
            let selected_location = selected_location_array.find(
                (element) => element._id === e.target.value
            );
            location_ids.push(selected_location._id);
            locations.push(selected_location);
            billingAddress.location_ids = location_ids;
            billingAddress.locations = locations;

            updatedBillingAddress.location_ids = location_ids;
            updatedBillingAddress.locations = locations;

            this.setState({billingAddress, updatedBillingAddress}, () => {
                // console.log(this.state.billingAddress.locations);
            });

            getChildLocations(e.target.value)
                .then((res) => {
                    if (res.length > 0) {
                        existing_locations[index + 1] = res;
                    }
                    this.setState({existing_locations}, () => {
                        // console.log("locations");
                        // console.log(this.state);
                    });
                })
                .catch((errMsg) => {
                    // console.log(errMsg);
                    // childLocations.splice(index, childLocations.length - index)
                    // this.setState({[typePurpose]: childLocations})
                });
        } else {
            if (index > 0 && existing_locations?.length > index) {
                existing_locations.splice(index + 1, existing_locations.length - index);
                location_ids.splice(index, location_ids.length - 1);
                locations.splice(index, locations.length - 1);
            }
            billingAddress.location_ids = location_ids;
            billingAddress.locations = locations;

            this.setState({billingAddress, existing_locations}, () => {
                // console.log(this.state.billingAddress.locations);
            });
        }
    };

    handleLocationInputOnChangeShipping = (e, index, typePurpose) => {
        const existing_locationsShipping = [...this.state.existing_locationsShipping];
        if (typePurpose === "addressShippedLocation") {
        } else {
            this.handleReturnInputOnChange(e);
        }

        const shippingAddress = {...this.state.shippingAddress};
        const updatedBillingAddress = {...this.state.updatedBillingAddress};
        let location_ids = shippingAddress.location_ids;
        let locations = shippingAddress.locations;

        if (e.target.value !== "") {
            if (index >= 0 && existing_locationsShipping?.length > index) {
                existing_locationsShipping.splice(index + 1, existing_locationsShipping.length - index);
                location_ids.splice(index, location_ids.length - index);
                locations.splice(index, locations.length - index);
            }

            let selected_location_array = existing_locationsShipping[index];
            let selected_location = selected_location_array.find(
                (element) => element._id === e.target.value
            );
            location_ids.push(selected_location._id);
            locations.push(selected_location);
            shippingAddress.location_ids = location_ids;
            shippingAddress.locations = locations;

            updatedBillingAddress.location_ids = location_ids;
            updatedBillingAddress.locations = locations;

            this.setState({shippingAddress, updatedBillingAddress}, () => {
                // console.log(this.state.shippingAddress.locations);
            });

            getChildLocations(e.target.value)
                .then((res) => {
                    if (res.length > 0) {
                        existing_locationsShipping[index + 1] = res;
                    }
                    this.setState({existing_locationsShipping}, () => {
                        console.log("locations");
                        console.log(this.state);
                    });
                })
                .catch((errMsg) => {
                    // childLocations.splice(index, childLocations.length - index)
                    // this.setState({[typePurpose]: childLocations})
                });
        } else {
            if (index > 0 && existing_locationsShipping?.length > index) {
                existing_locationsShipping.splice(index + 1, existing_locationsShipping.length - index);
                location_ids.splice(index, location_ids.length - 1);
                locations.splice(index, locations.length - 1);
            }
            shippingAddress.location_ids = location_ids;
            shippingAddress.locations = locations;

            this.setState({shippingAddress, existing_locationsShipping}, () => {
                // console.log(this.state.shippingAddress.locations);
            });
        }
    };

    handleInputOnChangeBilling = (e) => {
        this.setState({
            billingAddress: {
                ...this.state.billingAddress,
                [e.target.name]: e.target.value,
            },
        });
    };


    handleInputOnChangeShipping = (e) => {
        this.setState({
            shippingAddress: {
                ...this.state.shippingAddress,
                [e.target.name]: e.target.value,
            },
        });
    };


    handlePlaceOrder = () => {


        placeOrder({user_id: this.state.user_id, payment_method: "COD", delivery_address: this.state.shippingAddress?._id, billing_address: this.state.billingAddress?._id})
            .then(res => {
                console.log(res)
                if (res.status === 1) {
                    toast.success(res.message)
                    this.setState({cart_list: []})
                }
                activityLogPostData('Order Placed from admin', '/customers', "admin")
                //window.location.reload()
            })
            .catch(errMsg => {
                console.log("errMsg")
                toast.error(errMsg)
                this.setState({isSubmitting: false})
            })

    }


    getProducts = (formData) => {

        getProductListSecondary({
            stores: JSON.stringify(formData.stores),
        }).then(res => {

            this.setState({
                products: res.products,
                productPagination: res.pagination,
                isProductModalProductShowing: true,
                isProductLoading: false,
                checkedIndex: new Array(res.products.length).fill(false)
            })
        }).catch(err => {
            this.setState({isProductLoading: false})
        })

    }


    handleSelectOnChange = (name, value) => {
        const formData = {...this.state.formData}
        const items = []
        items.push(value.value)
        formData[name] = items

        getStoreList({
            ...this.state.defaultQuerySettings.storeList,
            merchants: JSON.stringify(this.state.formData.merchants || [])
        }).then(res => {
            const stores = []
            res.items.forEach(item => {
                stores.push({label: item.store_name, value: item._id})
            })
            this.setState({storeList: stores})
            this.getProducts(formData)
        })

    }

    handleSelectedProductOnChange = (e, id) => {
        const selectedProducts = [...this.state.selectedProducts];

        if (e.target.checked) {
            if (e.target.type === 'checkbox') {
                selectedProducts.push(id);
            } else {
                selectedProducts[0] = id;
            }
        } else {
            selectedProducts.splice(selectedProducts.indexOf(id), 1);
        }

        this.setState({selectedProducts});
    }

    handleRemoveSelectedProduct = (index) => {
        const selectedProductList = [...this.state.selectedProductList]
        const formData = {...this.state.formData}
        selectedProductList.splice(index, 1);
        formData.products.splice(index, 1);
        this.setState({selectedProductList, formData})
    }

    handleHideProductModal = () => {
        this.setState({isProductModalProductShowing: false})
    }

    handleSubmitForm = (e) => {
        e.preventDefault()
        this.setState({isSubmitting: true}, async () => {
            addUser(this.state.addUserFormData).then(async res => {

                    console.log("res", res)
                    this.setState({isSubmitting: false})
                    toast.success(res.message)
                    if (res.message === "User has been added successfully.") {
                        await activityLogPostData('register an user', '/customers', "admin")
                    }

                    // TODO
                    getTableData("/cart", {user_id: res.user._id}).then(response => {
                        this.setState({user_id: res.user._id, cart_list: response.cart, cartSummery: response.cart_summery}, () => {
                            let defaultProducts = [];
                            for (let i = 0; i < response.cart?.length; i++) {
                                for (let j = 0; j < response.cart[i].cart_items.length; j++) {
                                    if (response.cart[i].cart_items[j].selected_for_checkout === true) {
                                        this.setState({
                                            checkedItems: [
                                                ...this.state.checkedItems,
                                                response.cart[i].cart_items[j]._id
                                            ]
                                        }, () => {
                                        })
                                    }

                                    //this.setState({checkedItems: {...this.state.checkedItems, ...response.cart[i].cart_items} })
                                defaultProducts.push(response.cart[i].cart_items[j].selected_sku?._id);
                            }
                        }
                        this.setState({customerData: res.user, selectedProducts: defaultProducts})
                    });
                }).catch(errMsg => {
                    toast.error(errMsg)
                    // this.setState({isSubmitting: false})
                })


                this.setState({user_id: res.user._id}, async () => {

                    await getTableData('/delivery-address', {user_id: res.user._id}).then(res => {
                        res.items.map((item, index) => {
                            if (item.is_default) {
                                this.setState({shippingAddress: item, loadedShippingAddress: item})
                            }
                        })
                    }).catch(errMsg => {
                        toast.error(errMsg)
                        // this.setState({isSubmitting: false})
                    })

                    await getTableData("/billing-address", {user_id: res.user._id}).then(res => {
                        res.items.map((item, index) => {
                            if (item.is_default) {
                                this.setState({billingAddress: item, loadedBillingAddress: item}, () => {
                                    console.log("loadedShippingAddress inside submit", this.state.loadedBillingAddress);
                                });
                            }
                        })
                    }).catch(errMsg => {
                        toast.error(errMsg)
                        // this.setState({isSubmitting: false})
                    })
                });

                this.setState({isSubmitting: false})
                //toast.success(res.message)
            }).catch(errMsg => {
                toast.error(errMsg)
                this.setState({isSubmitting: false})
            });
        })
    }


    getDataFromId = () => {
        this.setState({isSubmitting: true}, () => {
            addUser(this.state.addUserFormData).then(res => {

                this.setState({isSubmitting: false})

                if (res.message === "User has been added successfully.") {
                    activityLogPostData('register an user', '/customers', "admin")
                }

                // TODO
                getTableData("/cart", {user_id: res.user._id}).then(response => {
                    this.setState({cart_list: response.cart, cartSummery: response.cart_summery}, () => {
                        let defaultProducts = [];
                        for (let i = 0; i < response.cart?.length; i++) {
                            for (let j = 0; j < response.cart[i].cart_items.length; j++) {
                                defaultProducts.push(response.cart[i].cart_items[j].selected_sku?._id);
                            }
                        }
                        this.setState({customerData: res.user, selectedProducts: defaultProducts})
                    });
                }).catch(errMsg => {
                    toast.error(errMsg)
                })

                this.setState({user_id: res.user._id}, () => {
                    getTableData('/delivery-address', {user_id: res.user._id}).then(res => {
                        res.items.map((item, index) => {
                            if (item.is_default) {
                                this.setState({shippingAddress: item, loadedShippingAddress: item})
                            }
                        })
                    }).catch(errMsg => {
                        toast.error(errMsg)
                    });


                    getTableData("/billing-address", {user_id: res.user._id}).then(res => {
                        res.items.map((item, index) => {
                            if (item.is_default) {
                                this.setState({billingAddress: item, loadedBillingAddress: item}, () => {
                                    console.log("loadedShippingAddress inside submit", this.state.loadedBillingAddress);
                                });
                            }
                        })
                    }).catch(errMsg => {
                        toast.error(errMsg)
                    })
                })

            }).catch(errMsg => {
                toast.error(errMsg)
                this.setState({isSubmitting: false})
            });
        });
    }


    handleMerchantTypeOnChange = (e) => {
        const value = e.target.value
        this.setState({merchants: [], merchantList: []})
        getMerchantList({/*merchant_type: value*/}).then(res => {
            const merchantList = []
            const formData = {...this.state.formData}
            res.forEach(item => {
                const merchant = {
                    id: item._id,
                    value: item._id,
                    label: item.name
                }
                merchantList.push(merchant)
            })
            formData.merchant_type = value
            formData.merchants = []
            this.setState({merchants: res, merchantList, formData})
        })
    }

    handleSearchProduct = (val) => {
        getProductListSecondary({
            merchants: JSON.stringify(this.state.formData?.merchants || []),
            stores: JSON.stringify(this.state.formData?.stores || []),
            categories: JSON.stringify(this.state.formData?.categories || []),
            limit: 20,
            search_string: val
        }).then(res => {
            this.setState({
                products: res.products,
                productPagination: res.pagination
            }, () => console.log(this.state));
        })
    }


    updatequantity = (newName, index) => {
        const {companies} = this.state;
        const newCompanies = [...companies];
        newCompanies[index] = newName;
        this.setState({companies: newCompanies});
    }


    handleRemove = (item, index) => {
        removeCart({user_id: this.state.user_id}, item._id).then(res => {
            this.setState({isSubmitting: false})
            toast.success(res.message)
        }).catch(errMsg => {
            toast.error(errMsg)
            this.setState({isSubmitting: false})
        })
        this.getDataFromId()
    }

    // Working here

    addItemToCart = (user_id, sku_id, quantity, cb = () => {
    }) => {
        addToCart({user_id, sku_id, quantity}).then(res => {
            getCart({user_id: this.state.user_id}).then(res => {
                this.setState({isSubmitting: false, cartSummery: res.cart_summery, cart_list: res.cart, fridge: false})
                cb(res.cart);
            }).catch(errMsg => {
                this.setState({isSubmitting: false})
            })

            activityLogPostData('add product to cart', '/customers', "admin")
        }).catch(errMsg => {
            toast.error(errMsg)
            this.setState({isSubmitting: false})
        })
    }

    removeItemFromCart = (user_id, sku_id, cb = () => {
    }) => {
        removeCart({user_id}, sku_id).then(res => {

            console.log("res")
            this.setState({isSubmitting: false})
            toast.success(res.message)


            getCart({user_id: this.state.user_id}).then(res => {
                cb(res);
            }).catch(errMsg => {
                console.log("errMsg")
                toast.error(errMsg)
                this.setState({isSubmitting: false})
            })
        })
            .catch(errMsg => {
                console.log("errMsg")
                toast.error(errMsg)
                this.setState({isSubmitting: false})
            })
    }


    handleOnCheckboxChange = (e, productItem) => {
        let products = this.state.selectedProducts;
        products.push(productItem.sku_info._id);
        this.addItemToCart(this.state.user_id, productItem.sku_info._id, productItem.sku_info.minimum_order_quantity, (cart_items) => {
            this.setState({cart_list: cart_items, selectedProducts: products},()=>{
                activityLogPostData('add product to cart', '/customers', "admin")
            });
        })

        /*if (e.target.checked) {
            console.log("e.target.checked", e.target.checked)
            products.push(productItem.sku_info._id);

            this.addItemToCart(this.state.user_id, productItem.sku_info._id, 1, (cart_items) => {
                this.setState({cart_list: cart_items, selectedProducts: products});
            })
        } else {
            const productIndex = products.indexOf(productItem.sku_info._id);
            if (productIndex > -1) {
                this.removeItemFromCart(this.state.user_id, productItem.sku_info._id, (cart_info) => {
                    const temp = products.filter(item => item !== productItem.sku_info._id);
                    this.setState({selectedProducts: temp, cart_list: cart_info.cart, cartSummery: cart_info.cart_summery});
                });
            }

            activityLogPostData('add product to cart', '/customers', "admin")
        }*/
        // this.state.checkedIndex.map((item, index) => {
        //         if (index === position)
        //             if (item === true) {
        //                 //item need to be removed from selected List
        //                 const productIndex = products.indexOf(productItem);
        //                 if (productIndex > -1) {
        //                     products.splice(productIndex, 1);
        //                 }
        //                 this.removeItemFromCart(this.state.user_id, productItem.sku_info._id);
        //                 activityLogPostData('add product to cart', '/customers', "admin")
        //             } else {
        //                 //add item to selected list
        //                 products = [...products, this.state.products[position]]
        //                 this.addItemToCart(this.state.user_id, productItem.sku_info._id, 1, (cart_items)=>{
        //                     this.setState({cart_list: cart_items});
        //                 })
        //             }
        //     }
        // );
        // this.setState({selectedProducts: products})
        // const updatedState = this.state.checkedIndex.map((item, index) =>
        //     index === position ? !item : item
        // );

        // this.setState({checkedIndex: updatedState})


    };

    handleShippingAsBillingRadio = (e) => {
        this.setState({is_shipping_and_billing_same: e.target.checked})
    }

    handleAddressEdit = (type) => {
        if (type === 'shipping') {
            this.handleAddShippingAddressModalShow(true)
        } else if (type === 'billing') {
            this.handleAddBillingAddressModalShow(true)
        } else {

        }
    }


    handleSearchFormDataChange = (e) => {
        this.setState({queryData: {...this.state.queryData, [e.target.name]: (e.target.type === 'checkbox' ? e.target.checked : e.target.value), page: 1}});
    };

    getQueryData = () => {
        this.setState({isLoadingQuery: true})
        getProductListSecondary(this.state.queryData).then((res => {
            this.setState({products: res?.products, productPagination: res.pagination,isLoadingQuery: false})
        }))

    }


    renderType = () => {
        const entrepreneurType = this.state.entrepreneurType;
        console.log("------->>", entrepreneurType)
        if (entrepreneurType) {
            return (
                <select id="merchant_type" className='form-control form-control-sm'
                        name="merchant_type"
                        onChange={this.handleSearchFormDataChange}
                >
                    <option value="">--Select Type--</option>
                    {
                        entrepreneurType.map((type, index) =>
                            <option
                                key={type.value}
                                value={type.value}
                            >{type.label}</option>)
                    }
                </select>
            )
        }
        return (
            <Form.Control as="select">
                <option>Fetching Type</option>
            </Form.Control>
        )
    };


    handleItemOnChange = (e, id, index) => {

        //this.setState({isResponse: false, isLoading: true})

        const checkedItems = [...this.state.checkedItems];
        let itemObj = null;
        let isChecked;

        isChecked = e.target.checked
        if (isChecked) {
            checkedItems.push(id);
        } else {
            checkedItems.splice(checkedItems.indexOf(id), 1);
        }

        this.setState({checkedItems, use_wallet: false}, async () => {
            itemObj = {
                item_ids: this.state.checkedItems
            }

            //cart_list
            /*this.props.checkUncheckCartItem(itemObj, () => {
                this.props.getCartItems(this.props.userToken, this.checkedItems);
            },()=>{

            });*/

        })

        updateItemPartial('/cart/item/check-uncheck', {item_ids: checkedItems, user_id: this.state.user_id}).then((res) => {
            getCart({user_id: this.state.user_id}).then(res => {
                console.log(res)
                this.setState({isSubmitting: false, cartSummery: res.cart_summery, fridge: false}, () => {
                    /* if (response.cart[i].cart_items[j].selected_for_checkout === true){
                         this.setState({
                             checkedItems: [
                                 ...this.state.checkedItems,
                                 response.cart[i].cart_items[j]._id
                             ]
                         })
                     }*/
                })


            }).catch(errMsg => {
                console.log("errMsg")
                toast.error(errMsg)
                this.setState({isSubmitting: false})
            })
        })

    }

    handlePageChange = (pageNumber) => {
        this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber}, () => {
            this.getQueryData();
        });
    }

    handleSearch = (query) => {
        this.setState({isLoadingStoreData: true}, () => {
            getStoreList({search_string: query, lite_data: 1})
                .then(res => {
                    this.setState({storeList: res.items, isLoadingStoreData: false});
                })
                .catch(errMsg => {
                    this.setState({isLoadingStoreData: false})
                })
        })
    };



    /* Get User Cart */
    getCart =()=> {
        let user_id = this.state.customerData?._id;
        getCart({user_id: user_id}).then(res => {
            this.setState({isSubmitting: false, cartSummery: res.cart_summery, cart_list: res.cart, fridge: false})
        }).catch(errMsg => {
            this.setState({isSubmitting: false})
        })
    }

    /* START APPLY COUPON */

    applyVoucherCode = () => {
        console.log(this.state);
        console.log(this.state.customerData?._id);
        if (this.state.voucher_code) {
            this.setState({isLoading: true})
            applyVoucherCode({voucher_code: this.state.voucher_code, user_id: this.state.customerData?._id})
                .then(res => {
                    // console.log('ABC', res)
                    if (res.status === 1) {
                        this.setState({isLoading: false})
                         this.getCart()
                         toast.success(res.message);
                    } else {
                        this.setState({isLoading: false})
                        toast.warning(res.message);
                    }
                })
                .catch(errMsg => {
                    this.setState({isLoading: false})
                    console.log(errMsg)
                })
        }
    }



    render() {
        console.log(this.state.product)
        console.log("shipping address", this.state.shippingAddress)
        return (
            <App layout="boxed">
                {getLocalAuthData()?.permission?.includes('product_add_') ?
                    <>
                        <div className="page-header">
                            <h2 className="page-title">Customer Order Place</h2>
                            <div className="ml-auto">
                                <Link to={`${process.env.PUBLIC_URL}/products/list`}
                                      className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Product List</Link>
                            </div>
                        </div>
                        <div className="main-content">
                            {this.state.isLoadingData ?
                                <div className={`card-block`}>
                                    <div className={`block-body`}>Loading...</div>
                                </div>
                                :
                                <Form noValidate validated={this.state.isFormValidated} onSubmit={this.handleSubmitForm}>
                                    <div className="card-block">
                                        <div className="block-header">
                                            <h4 className="block-title">Register Customer</h4>
                                        </div>

                                        <div className="block-body">
                                            <Form.Group as={Row} controlId="forNumber">
                                                <Form.Label column sm="3">
                                                    Customer Phone Number <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control type="number"
                                                                  required
                                                                  maxLength="10"
                                                                  onChange={this.handleInputOnChange}
                                                                  name="phone" placeholder=""/>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a valid number
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="formName">
                                                <Form.Label column sm="3">
                                                    Name <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control type="text"
                                                                  required
                                                                  value={this.state.customerData?.name}
                                                                  onChange={this.handleInputOnChange}
                                                                  name="name" placeholder=""/>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a valid name.
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <div className="row">
                                                <div className="col-sm-10">
                                                    <Button type={`submit`} variant="primary" className={"float-right"}>
                                                        Add User
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        this.state.customerData &&
                                        <div className="card-separation-area">
                                            <div className="row">
                                                <div
                                                    className={this.state.cart_list?.length > 0 ? "col-md-5" : "col-md-12"}>
                                                    <div className="card-block">
                                                        <div className="block-header">
                                                            <h4 className="block-title">Select Product</h4>
                                                        </div>


                                                        <div className="card-body">
                                                           {/* {this.state?.storeList?.length > 0 &&
                                                            <Form.Group as={Row} controlId="formStore">
                                                                <Form.Label column sm="3">
                                                                    Stores
                                                                </Form.Label>
                                                                <Col sm="9" md={8} lg={7}>
                                                                    <Select
                                                                        styles={styleReactSelect}
                                                                        placeholder="Select store..."
                                                                        onChange={(value) => this.handleSelectOnChange('stores', value)}
                                                                        options={this.state.storeList}
                                                                        name="stores"
                                                                        noOptionsMessage={() => 'Please insert new value...'}
                                                                    />
                                                                </Col>
                                                            </Form.Group>
                                                            }*/}

                                                                    <div className="table-responsive">
                                                                        <form className="form-inline mb-3">
                                                                    <div className="form-group mr-3">
                                                                        <label htmlFor="searchInput" className="sr-only">Search</label>
                                                                        <input type="search"
                                                                               name={'search_string'}
                                                                               onChange={this.handleSearchFormDataChange}
                                                                               id={'searchInput'}
                                                                               className="form-control form-control-sm" placeholder={'Search...'}
                                                                               onKeyDown={(e) => {
                                                                                   if (e.keyCode === 13) {
                                                                                       this.getQueryData()
                                                                                   }
                                                                               }}/>
                                                                    </div>


                                                                    <div className="form-group mr-3">
                                                                        <label htmlFor="status" className="sr-only">Status</label>
                                                                        <select id="status" name="status" className='form-control form-control-sm'
                                                                                onChange={this.handleSearchFormDataChange}>
                                                                            <option value="">--Select Status--</option>
                                                                            <option value="2">Blocked</option>
                                                                            <option value="1">Unblocked</option>
                                                                        </select>
                                                                    </div>

                                                                    <Form.Control as="select"
                                                                                  className={'mr-3'}
                                                                                  size={"sm"}
                                                                                  name='publish_status'
                                                                                  onChange={this.handleSearchFormDataChange}>
                                                                        <option value="">--Select--</option>
                                                                        <option value="2">Published</option>
                                                                        <option value="3">Unpublished</option>
                                                                    </Form.Control>


                                                                    <Form.Control as="select"
                                                                                  className={'mr-3'}
                                                                                  size={"sm"}
                                                                                  name='campaign_product'
                                                                                  onChange={this.handleSearchFormDataChange}>
                                                                        <option value="">--Select Product Type--</option>
                                                                        <option value="true">Campaign Product</option>
                                                                        <option value="false">Regular Product</option>
                                                                    </Form.Control>

                                                                    <div className="form-group mr-3">
                                                                        <label htmlFor="barndName" className="sr-only">Merchant Type</label>
                                                                        {this.renderType()}
                                                                    </div>


                                                                    <div className="form-group mr-3">
                                                                        <label htmlFor="inputStoreList" className={'sr-only'}>Store List</label>
                                                                        <div className="position-relative">
                                                                            <AsyncTypeahead
                                                                                filterBy={() => true}
                                                                                id="inputStoreList"
                                                                                isLoading={this.state.isLoadingStoreData}
                                                                                labelKey="store_name"
                                                                                minLength={3}
                                                                                size={'sm'}
                                                                                onSearch={this.handleSearch}
                                                                                options={this.state.storeList}
                                                                                placeholder="Search store..."
                                                                                renderMenuItemChildren={(store, props) => (
                                                                                    <React.Fragment>
                                                                                  <span onClick={() => {
                                                                                      this.setState({queryData: {...this.state.queryData, 'store_id': store._id}});
                                                                                  }}>{store.store_name}</span>
                                                                                    </React.Fragment>
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    </div>


                                                                    <Button className="mr-2" size="sm"
                                                                            variant="secondary"
                                                                            onClick={this.getQueryData}
                                                                    >Search</Button>

                                                                </form>
                                                                {this.state.isLoadingQuery && <LinearProgress/>}
                                                                <table className="order-table table table-striped">
                                                                    <thead>
                                                                    <tr>
                                                                        <th style={{width: '1%'}}>#</th>
                                                                        <th>Image</th>
                                                                        <th>Product Name</th>
                                                                        <th>Quantity</th>
                                                                        <th>Price</th>
                                                                        <th>Selection</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {this.state.products.map((item, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td>
                                                                                    <Link
                                                                                        to={`${process.env.PUBLIC_URL}/products/details/${item._id}`}
                                                                                        className="product-thumb">
                                                                                        <img style={{
                                                                                            height: "60px",
                                                                                            width: "60px"
                                                                                        }}
                                                                                             src={item.thumb_img} alt=""/>
                                                                                    </Link>
                                                                                </td>


                                                                                <td>{item.name}</td>
                                                                                <td>
                                                                                    {item?.sku_info?.quantity > 0 ? item?.sku_info?.quantity :
                                                                                        <Badge
                                                                                            variant="danger">Not
                                                                                            Available</Badge>}
                                                                                </td>
                                                                                <td>{item?.sku_info?.special_price ? item?.sku_info?.special_price : item?.sku_info?.price}</td>
                                                                                <td>

                                                                                    <Button
                                                                                        disabled={item?.sku_info?.quantity <= 0}
                                                                                        id={`custom-checkbox-${index}`}
                                                                                        /*checked={this.state.selectedProducts.includes(item?.sku_info?._id)}*/
                                                                                        onClick={(e) => this.handleOnCheckboxChange(e, item)}
                                                                                    >
                                                                                        Add to Cart
                                                                                    </Button>

                                                                                    {/* <input
                                                                                disabled={item?.sku_info?.quantity <= 0}
                                                                                type="checkbox"
                                                                                id={`custom-checkbox-${index}`}
                                                                                checked={this.state.selectedProducts.includes(item?.sku_info?._id)}
                                                                                onChange={(e) => this.handleOnCheckboxChange(e, item)}/>*/}

                                                                                </td>

                                                                            </tr>
                                                                        );
                                                                    })}

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>


                                                        <div className="mt-3">
                                                            <Pagination
                                                                itemClass="page-item"
                                                                linkClass="page-link"
                                                                activePage={this.state.productPagination?.page}
                                                                itemsCountPerPage={this.state.productPagination?.limit}
                                                                totalItemsCount={this.state.productPagination?.totalDocs}
                                                                pageRangeDisplayed={10}
                                                                onChange={this.handlePageChange.bind(this)}
                                                            />
                                                        </div>

                                                    </div>

                                                </div>

                                                {console.log("this.state.cart_list", this.state.cart_list?.length > 0)}

                                                {this.state.cart_list?.length > 0 ?
                                                    <div className="col-md-7 ">

                                                        {console.log("this.state.cartSummery", this.state.cartSummery)}

                                                        <div className="order-summery">
                                                            <div className="card-block h-auto">
                                                                <div className="block-header">
                                                                    <h4 className="block-card-title">Apply Coupon</h4>
                                                                </div>
                                                                <div className="block-body">
                                                                    <div className="row">
                                                                        <div className="col-md-5 col-sm-7">
                                                                            <div className="input-container d-flex mb-2">
                                                                                <input
                                                                                    className={`rbt-input-main form-control rbt-input form-control-md`}
                                                                                    type="text"  onChange={this.handleInputOnChange2} name={'voucher_code'}/>
                                                                                <Button
                                                                                    className="ml-2 btn-sm"
                                                                                    onClick={this.applyVoucherCode}
                                                                                    variant={"secondary"}>Apply</Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="order-summery">
                                                            <div className=" card-block">

                                                                <div className="block-header">
                                                                    <h4 className="block-card-title">Order Summery</h4>
                                                                </div>


                                                                    <div className="block-body">
                                                                    <p className="d-flex mb-3">
                                                                        <span className="mr-2">Subtotal</span>
                                                                        <span
                                                                            className="ml-auto">৳ {(this.state.cartSummery?.sub_total || 0.00).toFixed(2)}</span>
                                                                    </p>
                                                                    <p className="d-flex mb-3">
                                                                        <span className="mr-2">Delivery Charge</span>
                                                                        <span
                                                                            className="ml-auto">৳ {this.state.cartSummery?.shipping_cost?.toFixed(2) || 0.00}</span>
                                                                    </p>

                                                                    {
                                                                        this.state.cartSummery?.discount ?
                                                                            <>
                                                                                <p className="d-flex mb-3">
                                                                                    <span className="mr-2">Discount</span>
                                                                                    <span className="ml-auto">৳ {(this.state.cartSummery?.discount || 0.00).toFixed(2)}</span>
                                                                                </p>
                                                                            </> : ''
                                                                    }


                                                                    <p className="d-flex">
                                                                        <span className="mr-2 text-bold">Total</span>
                                                                        <span
                                                                            className="ml-auto text-secondary text-bold">৳ {this.state.cartSummery?.payable_amount?.toFixed(2) || 0.00}</span>
                                                                    </p>

                                                                    <Button block={true}
                                                                            disabled={this.state.fridge || this.state.placingOrder || !this.state.cart_list?.length > 0}
                                                                            onClick={this.handlePlaceOrder}
                                                                            variant={"primary"}>{this.state.placingOrder ? 'Order Placing...' : 'Place Order'}</Button>

                                                                </div>
                                                            </div>


                                                        </div>


                                                        <div className="card-block">
                                                            {console.log("this.state.cart_list", this.state.cart_list?.length > 0)}
                                                            <div className="block-header">
                                                                <h4 className="block-title">Selected Products</h4>
                                                            </div>

                                                            {
                                                                this.state.cart_list.map(item => {
                                                                    return <div className="card-body">
                                                                        <h5>{item?.store?.store_name}</h5>
                                                                        <div className="table-responsive">
                                                                            <table className="order-table table table-striped">
                                                                                <thead>
                                                                                <tr>
                                                                                    <th style={{width: '1%'}}>#</th>
                                                                                    <th>Image</th>
                                                                                    <th>Product Name</th>
                                                                                    <th>Quantity</th>
                                                                                    <th>Price</th>
                                                                                    <th>Selection</th>
                                                                                </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                {item?.cart_items?.map((cart_product, index) => {
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td>

                                                                                                <Form.Check
                                                                                                    custom
                                                                                                    defaultChecked={cart_product.selected_for_checkout}
                                                                                                    onChange={(e) => this.handleItemOnChange(e, cart_product._id)}
                                                                                                    type={'checkbox'}
                                                                                                    id={`checkoutCheck${cart_product._id}`}
                                                                                                    label={``}
                                                                                                />

                                                                                            </td>

                                                                                                <td>
                                                                                                    <Link
                                                                                                        to={`${process.env.PUBLIC_URL}/products/details/${cart_product._id}`}
                                                                                                        className="product-thumb">
                                                                                                        <img style={{
                                                                                                            height: "60px",
                                                                                                            width: "60px"
                                                                                                        }}
                                                                                                             src={cart_product.product.mid_img}
                                                                                                             alt=""/>
                                                                                                    </Link>
                                                                                                </td>


                                                                                            <td>{cart_product.product.name}</td>
                                                                                            <td>
                                                                                                <QuantityCounter
                                                                                                    maxValue={cart_product?.selected_sku?.quantity}
                                                                                                    // handleOnChange={(value) => console.log("")}
                                                                                                    handleOnChange={(value) => this.handleOnChangeQnt(value, cart_product?.selected_sku?._id, cart_product?.bundle_offer?._id, this.state.user_id)}
                                                                                                    value={cart_product.quantity} size={"sm"}/>
                                                                                            </td>
                                                                                            <td>{cart_product?.item_grand_total}</td>

                                                                                            <td>

                                                                                                <Button
                                                                                                    variant="danger"
                                                                                                    onClick={() => this.handleRemove(cart_product, index)}>
                                                                                                    Remove
                                                                                                </Button>


                                                                                                {/*<span style={{color: "red"}} onClick={ () => this.handleRemove(item,index)}>Remove</span>*/}

                                                                                            </td>

                                                                                        </tr>
                                                                                    );
                                                                                })}

                                                                                </tbody>
                                                                            </table>
                                                                        </div>


                                                                    </div>

                                                                })


                                                            }

                                                        </div>

                                                    </div>
                                                    : null}
                                            </div>
                                        </div>

                                    }

                                    {this.state.customerData ? <div className="card-block">
                                        <div className="block-header">
                                            <h4 className="block-title">Address Selection</h4>
                                        </div>


                                        <div className="card-body">

                                            <Modal
                                                show={this.state.isShowAddBillingAddressModal}
                                                onHide={this.handleAddBillingAddressModalHide}
                                                size="lg">
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Add Billing Address</Modal.Title>
                                                </Modal.Header>
                                                <Form key="addBillingAddress" noValidate validated={this.state.isBillingAddressAddFromValidation}
                                                      onSubmit={(e) => {
                                                          e.preventDefault();

                                                          const form = e.currentTarget;
                                                          if (form.checkValidity() === false) {
                                                              e.preventDefault();
                                                              e.stopPropagation();
                                                              this.setState({isBillingAddressAddFromValidation: true,});
                                                          } else {
                                                              this.setState({isSubmitting: true}, () => {
                                                                  const clone = this.state.billingAddress;
                                                                  delete clone.is_first_time;
                                                                  addBillingAddress(clone, {"user_id": this.state.user_id}).then(
                                                                      res => {
                                                                          console.log(res)
                                                                          this.setState({isSubmitting: false})
                                                                          this.handleAddBillingAddressModalHide()

                                                                      }
                                                                  ).catch(errMsg => {
                                                                      console.log(errMsg)
                                                                      toast.error(errMsg)
                                                                      this.setState({isSubmitting: false})
                                                                  })


                                                              });
                                                          }
                                                      }}>
                                                    <Modal.Body>
                                                        <Row>
                                                            <Col xs={6}>
                                                                <Form.Group controlId={"name"}>
                                                                    <Form.Label>Name <span
                                                                        className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        name={"name"}
                                                                        required
                                                                        onChange={this.handleInputOnChangeBilling}
                                                                        placeholder="Name"
                                                                        value={this.state.billingAddress?.name}
                                                                    />

                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please enter name.
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>

                                                                <div className="login-form-group">
                                                                    <Form.Label>
                                                                        Phone <span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <div className="input-group">
                                                                        <select
                                                                            name="country_code"
                                                                            className="form-control"
                                                                            required
                                                                            onChange={this.handleInputOnChangeBilling}
                                                                            value={
                                                                                this.state.billingAddress?.country_code || "+880"
                                                                            }
                                                                            style={{
                                                                                flex: "0 0 80px",
                                                                                padding: "0 10px 0 5px",
                                                                            }}
                                                                        >
                                                                            <option
                                                                                value={
                                                                                    this.state.billingAddress?.country_code || "+880"
                                                                                }
                                                                            >
                                                                                {this.state.billingAddress?.country_code || "+880"}
                                                                            </option>
                                                                            {this.state.countries.map(
                                                                                (country, index) => (
                                                                                    <option
                                                                                        key={index}
                                                                                        value={country.code}
                                                                                    >
                                                                                        {country.code}
                                                                                    </option>
                                                                                )
                                                                            )}
                                                                        </select>

                                                                        <Form.Control
                                                                            required
                                                                            minLength={10}
                                                                            maxLength={10}
                                                                            name={"phone"}
                                                                            onChange={this.handleInputOnChangeBilling}
                                                                            placeholder="Phone"
                                                                            value={this.state.billingAddress?.phone}
                                                                        />

                                                                        <Form.Control.Feedback type="invalid">
                                                                            Please provide a valid phone number.
                                                                        </Form.Control.Feedback>
                                                                    </div>
                                                                </div>

                                                                <Form.Group controlId={"email"}>
                                                                    <Form.Label>Email </Form.Label>
                                                                    <Form.Control
                                                                        type={"email"}
                                                                        required
                                                                        name={"email"}
                                                                        onChange={this.handleInputOnChangeBilling}
                                                                        placeholder="Email"
                                                                        value={this.state.billingAddress?.email}
                                                                    />

                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please provide a valid email address.
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>

                                                                <Form.Group controlId={"address"}>
                                                                    <Form.Label>
                                                                        Address{" "}
                                                                        <span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        required
                                                                        type={"text"}
                                                                        name={"address"}
                                                                        onChange={this.handleInputOnChangeBilling}
                                                                        placeholder="Address"
                                                                        value={this.state.billingAddress?.address || ""}
                                                                    />

                                                                    <Form.Control.Feedback type="invalid">
                                                                        Address is required.
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6}>
                                                                {this.state?.existing_locations?.length > 0 &&
                                                                this.state?.existing_locations.map(
                                                                    (location, index) => {
                                                                        return (
                                                                            <>
                                                                                {location?.length > 0 && (
                                                                                    <>
                                                                                        <Form.Group key={index} controlId={`location${location[0].type}`}>
                                                                                            <Form.Label column>
                                                                                                {location[0]?.type}{" "}
                                                                                                {index < 4 && (
                                                                                                    <span className="text-danger"> * </span>
                                                                                                )}
                                                                                            </Form.Label>
                                                                                            <Form.Control as="select" name={location[0].type.toLowerCase()} required value={(this.state.billingAddress?.location_ids && this.state.billingAddress)?.location_ids[index] ? this.state.billingAddress?.location_ids[index] : ""}
                                                                                                          onChange={(e) => this.handleLocationInputOnChange(e, index, "addressBillingLocation")}>
                                                                                                <option value=""> --Select--</option>
                                                                                                {location.map((item, index) => (<option key={index} value={item._id}>{item.name}</option>))}
                                                                                            </Form.Control>

                                                                                            <Form.Control.Feedback type="invalid"> Please select{" "} {location[0].type.toLowerCase()}
                                                                                                .
                                                                                            </Form.Control.Feedback>
                                                                                        </Form.Group>
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        );
                                                                    }
                                                                )}

                                                                <Form.Group controlId={"addressPostCode"}>
                                                                    <Form.Label>
                                                                        Post Code{" "}
                                                                        <span className={"text-danger"}>*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type={"text"}
                                                                        required
                                                                        name={"postcode"}
                                                                        onChange={this.handleInputOnChangeBilling}
                                                                        placeholder="Post Code"
                                                                        value={this.state.billingAddress?.postcode || ""}
                                                                    />
                                                                </Form.Group>

                                                                <Form.Group controlId={"address"}>
                                                                    <Form.Label>Address Type</Form.Label>

                                                                    <div>
                                                                        <Form.Check
                                                                            onChange={this.handleInputOnChangeBilling}
                                                                            custom
                                                                            inline
                                                                            label="Home Address"
                                                                            type={"radio"}
                                                                            value={"1"}
                                                                            id={`homeAddress`}
                                                                            name="address_type"
                                                                            defaultChecked={this.state.billingAddress?.is_home_address}
                                                                        />

                                                                        <Form.Check
                                                                            onChange={this.handleInputOnChangeBilling}
                                                                            custom
                                                                            inline
                                                                            label="Office Address"
                                                                            type={"radio"}
                                                                            id={`officeAddress`}
                                                                            value={"2"}
                                                                            name="address_type"
                                                                            defaultChecked={this.state.billingAddress?.is_office_address}
                                                                        />
                                                                    </div>
                                                                </Form.Group>


                                                            </Col>
                                                        </Row>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button
                                                            variant="default"
                                                            onClick={this.handleAddBillingAddressModalHide}
                                                        >
                                                            Close
                                                        </Button>
                                                        <Button type="submit" variant="primary">
                                                            Save Address
                                                        </Button>
                                                    </Modal.Footer>
                                                </Form>
                                            </Modal>

                                            <Modal show={this.state.isShowShippingAddressModal} onHide={this.handleAddShippingAddressModalHide} size="lg">
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Add Shipping Address</Modal.Title>
                                                </Modal.Header>
                                                <Form key="addShippingAddress" noValidate validated={this.state.isShippingAddressAddFromValidation}
                                                      onSubmit={(e) => {
                                                          e.preventDefault();

                                                          const form = e.currentTarget;
                                                          if (form.checkValidity() === false) {
                                                              e.preventDefault();
                                                              e.stopPropagation();
                                                              this.setState({
                                                                  isShippingAddressAddFromValidation: true,
                                                              });
                                                          } else {
                                                              this.setState({isSubmitting: true}, () => {
                                                                  const clone = this.state.shippingAddress;
                                                                  delete clone.is_first_time;
                                                                  addShippingAddress(clone, {"user_id": this.state.user_id}).then(
                                                                      res => {
                                                                          console.log(res)
                                                                          this.setState({isSubmitting: false})
                                                                          this.handleAddShippingAddressModalHide()
                                                                      }
                                                                  ).catch(errMsg => {
                                                                      console.log(errMsg)
                                                                      toast.error(errMsg)
                                                                      this.setState({isSubmitting: false})
                                                                  })

                                                              });
                                                          }
                                                      }}>

                                                    <Modal.Body>
                                                        <Row>
                                                            <Col xs={6}>
                                                                <Form.Group controlId={"name"}>
                                                                    <Form.Label>Name <span
                                                                        className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        name={"recipient_name"}
                                                                        required
                                                                        onChange={this.handleInputOnChangeShipping}
                                                                        placeholder="Name"
                                                                        value={this.state.shippingAddress?.recipient_name || ""}
                                                                    />

                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please enter name.
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>

                                                                <div className="login-form-group">
                                                                    <Form.Label>
                                                                        Phone <span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <div className="input-group">
                                                                        <select
                                                                            name="country_code"
                                                                            className="form-control"
                                                                            required
                                                                            onChange={this.handleInputOnChangeShipping}
                                                                            value={
                                                                                this.state.shippingAddress?.country_code || "+880"
                                                                            }
                                                                            style={{flex: "0 0 80px", padding: "0 10px 0 5px",}}>
                                                                            <option
                                                                                value={
                                                                                    this.state.shippingAddress?.country_code || "+880"
                                                                                }
                                                                            >
                                                                                {this.state.shippingAddress?.country_code || "+880"}
                                                                            </option>
                                                                            {this.state.countries.map(
                                                                                (country, index) => (
                                                                                    <option
                                                                                        key={index}
                                                                                        value={country.code}
                                                                                    >
                                                                                        {country.code}
                                                                                    </option>
                                                                                )
                                                                            )}
                                                                        </select>

                                                                        <Form.Control
                                                                            required
                                                                            minLength={10}
                                                                            maxLength={10}
                                                                            name={"recipient_phone"}
                                                                            onChange={this.handleInputOnChangeShipping}
                                                                            placeholder="Phone"
                                                                            value={this.state.shippingAddress?.recipient_phone || ""}
                                                                        />

                                                                        <Form.Control.Feedback type="invalid">
                                                                            Please provide a valid phone number.
                                                                        </Form.Control.Feedback>
                                                                    </div>
                                                                </div>

                                                                <Form.Group controlId={"email"}>
                                                                    <Form.Label>Email </Form.Label>
                                                                    <Form.Control
                                                                        type={"email"}
                                                                        required
                                                                        name={"email"}
                                                                        onChange={this.handleInputOnChangeShipping}
                                                                        placeholder="Email"
                                                                        value={this.state.shippingAddress?.email || ""}
                                                                    />

                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please provide a valid email address.
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>

                                                                <Form.Group controlId={"address"}>
                                                                    <Form.Label>
                                                                        Address{" "}
                                                                        <span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        required
                                                                        type={"text"}
                                                                        name={"address"}
                                                                        onChange={this.handleInputOnChangeShipping}
                                                                        placeholder="Address"
                                                                        value={this.state.shippingAddress?.address || ""}
                                                                    />

                                                                    <Form.Control.Feedback type="invalid">
                                                                        Address is required.
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6}>
                                                                {this.state?.existing_locationsShipping?.length > 0 &&
                                                                this.state?.existing_locationsShipping.map(
                                                                    (location, index) => {
                                                                        return (
                                                                            <>
                                                                                {location?.length > 0 && (
                                                                                    <>
                                                                                        <Form.Group key={index} controlId={`location${location[0].type}`}>
                                                                                            <Form.Label column>
                                                                                                {location[0]?.type}{" "}
                                                                                                {index < 4 && (<span className="text-danger">*</span>)}
                                                                                            </Form.Label>
                                                                                            <Form.Control as="select"
                                                                                                          name={location[0].type.toLowerCase()}
                                                                                                          required
                                                                                                          value={(this.state.shippingAddress?.location_ids && this.state.shippingAddress)?.location_ids[index] ? this.state.shippingAddress?.location_ids[index] : ""}
                                                                                                          onChange={(e) => this.handleLocationInputOnChangeShipping(e, index, "addressShippedLocation")}>
                                                                                                <option value="">--Select--</option>
                                                                                                {location.map(
                                                                                                    (item, index) => (
                                                                                                        <option key={index} value={item._id}>{item.name}</option>
                                                                                                    )
                                                                                                )}
                                                                                            </Form.Control>

                                                                                            <Form.Control.Feedback
                                                                                                type="invalid">
                                                                                                Please select{" "}
                                                                                                {location[0].type.toLowerCase()}
                                                                                                .
                                                                                            </Form.Control.Feedback>
                                                                                        </Form.Group>
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        );
                                                                    }
                                                                )}

                                                                <Form.Group controlId={"addressPostCode"}>
                                                                    <Form.Label>
                                                                        Post Code{" "}
                                                                        <span className={"text-danger"}>*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type={"text"}
                                                                        required
                                                                        name={"postcode"}
                                                                        onChange={this.handleInputOnChangeShipping}
                                                                        placeholder="Post Code"
                                                                        value={this.state.shippingAddress?.postcode || ""}
                                                                    />
                                                                </Form.Group>

                                                                <Form.Group controlId={"address"}>
                                                                    <Form.Label>Address Type</Form.Label>

                                                                    <div>
                                                                        <Form.Check
                                                                            onChange={this.handleInputOnChangeShipping}
                                                                            custom
                                                                            inline
                                                                            label="Home Address"
                                                                            type={"radio"}
                                                                            value={"1"}
                                                                            id={`homeAddress`}
                                                                            name="address_type"
                                                                            defaultChecked={this.state.billingAddress?.is_home_address}
                                                                        />

                                                                        <Form.Check
                                                                            onChange={this.handleInputOnChangeShipping}
                                                                            custom
                                                                            inline
                                                                            label="Office Address"
                                                                            type={"radio"}
                                                                            id={`officeAddress`}
                                                                            value={"2"}
                                                                            name="address_type"
                                                                            defaultChecked={this.state.billingAddress?.is_office_address}
                                                                        />
                                                                    </div>
                                                                </Form.Group>


                                                            </Col>
                                                        </Row>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="default" onClick={this.handleAddShippingAddressModalHide}>
                                                            Close
                                                        </Button>
                                                        <Button type="submit" variant="primary">
                                                            Save Address
                                                        </Button>
                                                    </Modal.Footer>
                                                </Form>
                                            </Modal>

                                            <Button size={"sm"}
                                                onClick={() => this.handleAddShippingAddressModalShow(false)}
                                                variant={"default"} className="mb-2" >
                                                <FiPlus/> Add New Shipping Address
                                            </Button>

                                            <Button style={{marginLeft: '10px'}} size={"sm"}
                                                onClick={() => this.handleAddBillingAddressModalShow(false)}
                                                variant={"default"} className="mb-2" >
                                                <FiPlus/> Add New Billing Address
                                            </Button>


                                            {this.state.loadedShippingAddress || this.state.loadedBillingAddress ? <div className="block-card">
                                                <div className="block-card-body">
                                                    <label className="address-item">
                                                        <input defaultChecked={this.state.is_shipping_and_billing_same}
                                                               type="checkbox"
                                                               onChange={this.handleShippingAsBillingRadio}
                                                               name={"is_shipping_and_billing_same"}
                                                        />
                                                        <span className="address-container ml-2"><strong>Is shipping and Billing same</strong></span>
                                                    </label>

                                                    <Row>
                                                        {this.state?.loadedShippingAddress ?
                                                            <Col key={'shippingAddress'} md={6}>


                                                                <div className="shipping-address">
                                                                    <label className="address-item"
                                                                           htmlFor={"shippingAddress-" + this.state?.loadedShippingAddress?._id}>

                                                                        {/*<input*/}
                                                                        {/*    id={"shippingAddress-" + this.state?.shippingAddress?._id}*/}
                                                                        {/*    type="radio"*/}
                                                                        {/*    checked={this.state?.shippingAddress?.is_default}*/}
                                                                        {/*    onChange={this.handleAddressOnChange}*/}
                                                                        {/*    name={"delivery_address"}*/}
                                                                        {/*    defaultValue={this.state?.shippingAddress?._id}/>*/}


                                                                        <span className="address-container">
                                            <span className="d-block" onClick={() => {
                                                console.log("Testing")
                                            }}><strong>Shipping Address</strong></span>
                                            <span
                                                className="d-block"><strong>{this.state?.loadedShippingAddress?.recipient_name}</strong></span>
                                            <span
                                                className="d-block"><strong>Phone: </strong> {this.state?.loadedShippingAddress?.country_code ? this.state?.loadedShippingAddress?.country_code + '-' : ''}{this.state?.loadedShippingAddress?.recipient_phone}</span>
                                                                            {this.state?.loadedShippingAddress?.email &&
                                                                            <span
                                                                                className="d-block"><strong>Email: </strong> {this.state?.loadedShippingAddress?.email}</span>}
                                                                            <span className="d-block">
                                              {console.log("Shipping", this.state?.shippingAddress)}
                                                                                <strong>Address: </strong> {this.state?.loadedShippingAddress?.address}, {this.state?.loadedShippingAddress?.locations?.slice().reverse()?.map((loc, index) => (
                                                                                <span>{loc?.name}{this.state?.loadedShippingAddress?.locations[index + 1] ? ", " : ""}</span>
                                                                            ))}
                                            </span>
                                            <span
                                                className="d-block"><strong>Postcode: </strong> {this.state?.loadedShippingAddress?.postcode}</span>
                                          </span>

                                                                    </label>

                                                                    {/* <span onClick={() => {
                                          this.handleEditShippingAddress(this.state?.shipping_address?._id)
                                        }}
                                              style={{cursor: 'pointer'}}
                                              className="action text-primary">Edit</span> */}

                                                                </div>
                                                                <span onClick={() => {
                                                                    this.handleAddressEdit("shipping");
                                                                }}
                                                                      style={{cursor: 'pointer'}}
                                                                      className="action btn btn-outline-primary btn-sm">Edit</span>
                                                            </Col> :
                                                            null
                                                        }


                                                        {
                                                            !this.state.is_shipping_and_billing_same && <>
                                                                {this.state?.loadedBillingAddress?.locations?.length > 0 ?
                                                                    <Col key={this.state?.loadedBillingAddress?._id} md={6}>
                                                                        {/* <Button
                                                                            size={"sm"}
                                                                            onClick={() => this.handleAddBillingAddressModalShow(false)}
                                                                            variant={"default"}
                                                                            className="mb-2"
                                                                        >
                                                                            <FiPlus/> Add New Billing Address
                                                                        </Button>*/}
                                                                        <div className="shipping-address">
                                                                            {/* <label className="address-item"
                                                                           htmlFor={"shippingAddress-" + this.state?.loadedBillingAddress?._id}> */}
                                                                            {/*<input*/}
                                                                            {/*    id={"billingAddress-" + this.state?.billingAddress?._id}*/}
                                                                            {/*    type="radio"*/}
                                                                            {/*    checked={this.state?.billingAddress?.is_default}*/}
                                                                            {/*    onChange={this.handleAddressOnChange}*/}
                                                                            {/*    name={"billing_address"}*/}
                                                                            {/*    defaultValue={this.state?.billingAddress?._id}/>*/}

                                                                            <span className="address-container">
                                            <span className="d-block" onClick={() => {
                                                console.log("Testing")
                                            }}><strong>Billing Address</strong></span>
                                            <span
                                                className="d-block"><strong>{this.state.loadedBillingAddress?.name}</strong></span>
                                            <span
                                                className="d-block"><strong>Phone: </strong> {this.state.loadedBillingAddress?.country_code ? this.state.loadedBillingAddress?.country_code + '-' : ''}{this.state.loadedBillingAddress?.phone}</span>
                                                                                {this.state.loadedBillingAddress?.email &&
                                                                                <span
                                                                                    className="d-block"><strong>Email: </strong> {this.state.loadedBillingAddress?.email}</span>}
                                                                                <span className="d-block">
                                              {console.log("Shipping", this.state?.billingAddress)}
                                                                                    <strong>Address: </strong> {this.state?.loadedBillingAddress?.address}, {this.state.loadedBillingAddress?.locations?.slice().reverse()?.map((loc, index) => (
                                                                                    <span>{loc?.name}{this.state.loadedBillingAddress?.locations[index + 1] ? ", " : ""}</span>
                                                                                ))}
                                            </span>
                                            <span
                                                className="d-block"><strong>Postcode: </strong> {this.state.loadedBillingAddress?.postcode}</span>
                                          </span>
                                                                            {/* </label> */}

                                                                            {/* <span onClick={() => {this.handleEditShippingAddress(billingAddress?._id)}}
                                            style={{cursor: 'pointer'}}
                                            className="action text-primary">Edit</span> */}

                                                                        </div>
                                                                        <span onClick={() => {
                                                                            this.handleAddressEdit("billing");
                                                                        }}
                                                                              style={{cursor: 'pointer'}}
                                                                              className="action btn btn-outline-primary btn-sm">Edit</span>


                                                                    </Col>
                                                                    :
                                                                    <Col key={'shippingAddress'} md={6}>
                                                                        <div className="shipping-address">
                                                                            {/* <label className="address-item"
                                                                           htmlFor={"billingAddress-" + this.state?.loadedBillingAddress?._id}> */}

                                                                            <input
                                                                                id={"billingAddress-" + this.state?.loadedBillingAddress?._id}
                                                                                type="radio"
                                                                                checked={this.state?.loadedBillingAddress?.is_default}
                                                                                onChange={this.handleAddressOnChange}
                                                                                name={"billing_address"}
                                                                                defaultValue={this.state?.loadedBillingAddress?._id}/>


                                                                            <span className="address-container">
                                        <span className="d-block" onClick={() => {
                                            console.log("Testing")
                                        }}><strong>Billing Information</strong></span>
                                        <span
                                            className="d-block"><strong>{this.state?.loadedBillingAddress?.recipient_name}</strong></span>
                                        <span
                                            className="d-block"><strong>Phone: </strong> {this.state?.loadedBillingAddress?.country_code ? this.state?.loadedBillingAddress?.country_code + '-' : ''}{this.state?.loadedBillingAddress?.recipient_phone}</span>
                                                                                {this.state?.loadedBillingAddress?.email &&
                                                                                <span
                                                                                    className="d-block"><strong>Email: </strong> {this.state?.loadedBillingAddress?.email}</span>}
                                                                                <span className="d-block">
                                          {console.log("Shipping", this.state?.shippingAddress)}
                                                                                    <strong>Address: </strong> {this.state?.loadedBillingAddress?.address}, {this.state?.loadedBillingAddress?.locations?.slice().reverse()?.map((loc, index) => (
                                                                                    <span>{loc?.name}{this.state?.loadedBillingAddress?.locations[index + 1] ? ", " : ""}</span>
                                                                                ))}
                                        </span>
                                        <span
                                            className="d-block"><strong>Postcode: </strong> {this.state?.loadedBillingAddress?.postcode}</span>
                                      </span>

                                                                            {/* </label> */}

                                                                            {/* <span onClick={() => {
                                      this.handleEditShippingAddress(this.state?.shipping_address?._id)
                                    }}
                                          style={{cursor: 'pointer'}}
                                          className="action text-primary">Edit</span> */}

                                                                        </div>
                                                                        <span onClick={() => {
                                                                            this.handleAddressEdit("billing");
                                                                        }}
                                                                              style={{cursor: 'pointer'}}
                                                                              className="action btn btn-outline-primary btn-sm">Edit</span>
                                                                    </Col>
                                                                }
                                                            </>
                                                        }

                                                    </Row>

                                                </div>
                                            </div> : null}


                                        </div>


                                    </div> : null}


                                </Form>
                            }
                        </div>
                    </> : null}
            </App>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        mediaFiles: state.fileManager.files,
    }
}

export default connect(mapStateToProps)(withRouter(CustomerOrderPlace));
