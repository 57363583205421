import React from 'react';
import "../assets/scss/store-card.scss";
import placeholderImg from "../assets/img/placeholder-img.jpg";

const StoreCard = ({data}) => {
  return (
    <div className="store-card ratio ratio-16-9">
      <div className="ratio-inner">
        <div>
          <div className="store-logo">
            <img src={data?.store_logo || placeholderImg} alt={data?.store_name}/>
          </div>
          <div className="store-info">
            <h4 className="store-name">{data?.store_name}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreCard;