import React, {Component} from 'react';
import App from "../../App";
import "../../assets/scss/category-list.scss";
import {FiPlus} from "react-icons/all";
import {Link} from "react-router-dom";
import LocationTree from "../../components/LocationTree";
import {getLocalAuthData} from "../../helper/utils";

class Locations extends Component {
  render() {
    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Locations</h2>
          <div className="ml-auto">
              {getLocalAuthData()?.permission?.includes('location_add') ?
                  <Link to={`${process.env.PUBLIC_URL}/locations/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add Location</Link>
                  : null}
          </div>
        </div>
        <div className="main-content">
          <div className="card-block">
            <div className="block-body">
              <LocationTree/>
            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default Locations;
