import React, {Component} from 'react';
import App from "../../App";
import {toast} from 'react-toastify';
import {Button, Col, Form, Row} from "react-bootstrap";
import {getChildLocations, getLocationList} from "../../services/locationServices";
import {getStoreDetails, updateStore} from "../../services/storeServices";
import {withRouter} from "react-router-dom";
import BrowseFiles from "../../components/BrowseFiles";
import {getCategoryList} from "../../services/categoryServices";
import {activityLogPostData, checkIsExisting, getTableData} from "../../services/commonServices";
import {getLocalAuthData} from "../../helper/utils";
import Select from "react-select";
import styleReactSelect from "../../assets/styleReactSelect";

class EditStore extends Component {
    state = {
        activeBlockTab: 'Business Information',
        countries: [],
        wareHouseChildLocations: [],
        returnChildLocations: [],
        assistedSellerChildLocations: [],
        generalInfoChildLocations: [],
        merchant: null,
        merchant_id: null,
        formData: {
            warehouse_locations: {},
            return_location: {},
            assisted_selling: false,
            assisted_seller_info: {}
        },
        isFormValidated: false,
        isSubmitting: false,
        isLoading: true,
        categories: [],
        generalInfoLocations: [],
        storeExistingStatus: 2,
        storeExistingMsg: null,
        splittedPhone: ['+880', ''],
    };

    componentDidMount() {
        const storeId = this.props.match.params.id
        getLocationList()
            .then(res => {
                this.setState({countries: res})
            })

        getStoreDetails(storeId)
            .then(res => {
                const formData = {
                    merchant: res.merchant?._id,
                    business_registration_number: res.merchant.business_registration_number,
                    store_name: res.store_name,
                    category: res.category,
                    store_logo: res.store_logo,

                    business_type: "1",
                    address: res.address,
                    country: res.country,
                    division: res?.division || '',
                    district: res?.district || '',
                    upazila: res?.upazila || '',
                    union: res?.union || '',
                    province: res?.province || '',
                    city: res?.city || '',
                    postcode: res.postcode || '',
                    is_same_as_warehouse_locations: res?.is_same_as_warehouse_locations,
                    warehouse_locations: {
                        contact_person: res?.warehouse_locations[0]?.contact_person,
                        contact_number: res?.warehouse_locations[0]?.contact_number,
                        address: res?.warehouse_locations[0]?.address,
                        country: res?.warehouse_locations[0]?.country,
                        division: res?.warehouse_locations[0]?.division,
                        district: res?.warehouse_locations[0]?.district,
                        upazila: res?.warehouse_locations[0]?.upazila,
                        union: res?.warehouse_locations[0]?.union,
                        province: res?.warehouse_locations[0]?.province,
                        city: res?.warehouse_locations[0]?.city,
                        postcode: res?.warehouse_locations[0]?.postcode
                    },

                    return_location: {
                        contact_person: res?.return_location?.contact_person,
                        contact_number: res?.return_location?.contact_number,
                        address: res?.return_location?.address,
                        country: res?.return_location?.country,
                        division: res?.return_location?.division,
                        district: res?.return_location?.district,
                        upazila: res?.return_location?.upazila,
                        union: res?.return_location?.union,
                        province: res?.return_location?.province,
                        city: res?.return_location?.city,
                        postcode: res?.return_location?.postcode
                    },

                    assisted_selling: res?.assisted_selling || false,
                    assisted_seller_id: res?.assisted_seller_id || null
                }

                res.locations.forEach((item, index) => {
                    getChildLocations(item._id)
                        .then(res => {
                            const generalInfoChildLocations = [...this.state.generalInfoChildLocations]
                            generalInfoChildLocations[index] = res
                            this.setState({generalInfoChildLocations})
                        })
                        .catch(errMsg => {
                            console.log(errMsg)
                        })
                })

                if (res.warehouse_locations[0]?.array) {
                    res.warehouse_locations[0].array.forEach((item, index) => {
                        getChildLocations(item._id)
                            .then(res => {
                                const wareHouseChildLocations = [...this.state.wareHouseChildLocations]
                                wareHouseChildLocations[index] = res
                                this.setState({wareHouseChildLocations})
                            })
                            .catch(errMsg => {
                                console.log(errMsg)
                            })
                    })
                }

                if (res.return_location?.array) {
                    res.return_location.array.forEach((item, index) => {
                        getChildLocations(item._id)
                            .then(res => {
                                const returnChildLocations = [...this.state.returnChildLocations]
                                returnChildLocations[index] = res
                                this.setState({returnChildLocations})
                            })
                            .catch(errMsg => {
                                console.log(errMsg)
                            })
                    })
                }

                let splittedPhone = [];

                this.setState({
                    formData,
                    generalInfoLocations: res.locations,
                    isLoading: false,
                    merchant_id: res.merchant._id,
                    merchant: res.merchant.name,
                    splittedPhone: splittedPhone,
                    assisted_seller_info: res.assisted_seller_info,
                })
            })
            .catch(errMsg => {
                this.setState({isLoading: false})
            })

        getCategoryList('store')
            .then(res => {
                this.setState({categories: res})
            })
    }

    handleBlockTab = label => {
        this.setState({
            activeBlockTab: label
        })
    };

    handleLocationInputOnChange = (e, index, typePurpose) => {
        const childLocations = [...this.state[typePurpose]];
        const value = e.target.value;
        if (typePurpose === 'generalInfoChildLocations') {
            this.handleInputOnChange(e)
        } else if (typePurpose === 'wareHouseChildLocations') {
            this.handleWareHouseInputOnChange(e)
        } else if (typePurpose === 'assistedSellerChildLocations') {
            this.handleAssistedSellerInputOnChange(e)
        } else {
            this.handleReturnInputOnChange(e)
        }

        if (e.target.value !== '') {
            getChildLocations(e.target.value)
                .then(res => {
                    childLocations.splice(index, childLocations.length - index);
                    if (res.length > 0) {
                        childLocations[index] = res
                    }
                    this.setState({[typePurpose]: childLocations})
                })
                .catch(errMsg => {
                    console.log(errMsg)
                    childLocations.splice(index, childLocations.length - index)
                    this.setState({[typePurpose]: childLocations})
                })
        } else {
            childLocations.splice(index, childLocations.length - index);
            this.setState({[typePurpose]: childLocations})
        }
    }

    handleInputOnChange = (e) => {
        const formData = {...this.state.formData};
        formData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({formData});
    }

    handleSubmitForm = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({isFormValidated: true})
        } else {
            this.setState({isSubmitting: true}, () => {
                updateStore(this.state.formData, this.props.match.params.id)
                    .then(res => {
                        this.setState({isSubmitting: false});
                        this.props.history.push('/stores/list');
                        toast.success(res.message);
                        activityLogPostData('was edit this store','/stores/edit/'+this.props.match.params.id,e.target.store_name)
                    })
                    .catch(errMsg => {
                        this.setState({isSubmitting: false});
                        toast.error(errMsg);
                    })
            });
        }
    }

    handleWareHouseInputOnChange = (e) => {
        const formData = {...this.state.formData};
        formData.warehouse_locations[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({formData});
    }

    handleReturnInputOnChange = (e) => {
        const formData = {...this.state.formData};
        formData.return_location[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({formData});
    }

    handleAddProductImages = async (files) => {
        const formData = {...this.state.formData}
        formData.store_logo = files[0].thumb_img;
        this.setState({formData});
    }

    handleCheckSameAddress = (e) => {
        const formData = {...this.state.formData};
        formData[e.target.name] = e.target.value === "true";
        if (e.target.value === 'true') {
            formData.return_location = this.state.formData.warehouse_locations;
            this.setState({formData, returnChildLocations: this.state.wareHouseChildLocations})
        } else {
            formData.return_location = {}
            this.setState({formData, returnChildLocations: []})
        }
    }

    handleOnRemoveItem = () => {
        const formData = {...this.state.formData}
        formData.store_logo = null
        this.setState({formData})
    }

    checkIsExisting = (type, data, msgKey, statusKey) => {
        checkIsExisting(type, data)
            .then(res => {
                if (res.status === 2) {
                    this.setState({[msgKey]: res.message, [statusKey]: res.status})
                } else {
                    this.setState({[msgKey]: null, [statusKey]: res.status})
                }
            })
            .catch(errMsg => {
                console.log(errMsg)
            })
    }

    handleGetAssistedSellerList = (string = '') => {
        if (string.length > 2) {
            this.getAssistedSellers(string)
        }
    }

    getAssistedSellers = (search_string = '') => {
        getTableData(`/assisted-seller`, {limit: 20, search_string: search_string, merchant_id: this.state.formData.merchant}).then(res => {
            if (res.status === 1) {
                const assisted_sellers = [];
                if (res.items.length > 0) {
                    res.items.forEach(item => {
                        assisted_sellers.push({id: item._id, label: item.name, value: item._id});
                    })
                }
                this.setState({assisted_sellers})
            } else {
                toast.warning("assisted seller not found");
            }
        })
    }

    handleSelectInputOnChange = (name, value) => {
        const formData = {...this.state.formData};
        formData[name] = value.value;
        this.setState({formData}, () => {
            console.log(this.state.formData);
        });
    }


    render() {
        const {formData} = this.state;
        return (
            <App layout={"boxed"}>
                <div className="page-header">
                    <h2 className="page-title">Update Stores</h2>
                </div>

                {getLocalAuthData()?.permission?.includes('store_edit') ?
                    <>
                        <div className="card-block">
                            <ul className="desc-header-nav">
                                <li className={this.state.activeBlockTab === 'Business Information' ? 'active' : ''}>
                                    <span onClick={() => this.handleBlockTab('Business Information')}>Business Information</span>
                                </li>
                            </ul>

                            <Form onSubmit={this.handleSubmitForm}
                                  noValidate validated={this.state.isFormValidated}
                                  className="block-body">
                                {!this.state.isLoading ?
                                    <>
                                        {this.state.activeBlockTab === 'Business Information' &&
                                        <>
                                            {/* General Information */}
                                            <Form.Group as={Row} controlId="">
                                                <Form.Label column sm="3"/>

                                                <Col sm="9" md={8} lg={7}>
                                                    <strong>General Information</strong>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="storeName">
                                                <Form.Label column sm="3">
                                                    Merchant
                                                </Form.Label>

                                                <Col sm="9" md={8} lg={7}>
                                                    <strong>{this.state.merchant}</strong>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="storeName">
                                                <Form.Label column sm="3">
                                                    Store Name <span className="text-danger">*</span>
                                                </Form.Label>

                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control
                                                        required
                                                        defaultValue={formData.store_name}
                                                        onBlur={() => this.checkIsExisting('store', {
                                                            store_name: this.state.formData.store_name,
                                                            store_id: this.props.match.params.id
                                                        }, 'storeExistingMsg', 'storeExistingStatus')}
                                                        onChange={this.handleInputOnChange}
                                                        name={"store_name"} type={"text"}/>

                                                    {this.state.storeExistingMsg && <Form.Text className={'text-danger'}>
                                                        {this.state.storeExistingMsg}
                                                    </Form.Text>}
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="storeName">
                                                <Form.Label column sm="3">
                                                    Logo <span className="text-danger">*</span>
                                                </Form.Label>

                                                <Col sm="9" md={8} lg={7}>
                                                    <BrowseFiles isSingle={true}
                                                                 image_category={'logo'}
                                                                 userId={this.state.merchant_id}
                                                                 onRemoveItem={this.handleOnRemoveItem}
                                                                 images={this.state.formData?.store_logo ? [{thumb_img: this.state.formData?.store_logo}] : []}
                                                                 onGetFiles={this.handleAddProductImages}/>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="category">
                                                <Form.Label column sm="3">
                                                    Category <span className="text-danger">*</span>
                                                </Form.Label>

                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control
                                                        required
                                                        value={formData.category}
                                                        onChange={this.handleInputOnChange}
                                                        name={"category"} as={"select"}>
                                                        <option value="">--Select Category--</option>
                                                        {this.state.categories?.map((cat, index) => <option key={index}
                                                                                                            value={cat._id}>{cat.name}</option>)}
                                                    </Form.Control>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="address">
                                                <Form.Label column sm="3">
                                                    Address <span className="text-danger">*</span>
                                                </Form.Label>

                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control
                                                        required
                                                        defaultValue={formData.address}
                                                        onChange={this.handleInputOnChange}
                                                        name={"address"} type={"text"}/>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formCountry">
                                                <Form.Label column sm="3"> Country <span className="text-danger">*</span></Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control as="select" name="country"
                                                                  required
                                                                  value={formData.country}
                                                                  onChange={(e) => this.handleLocationInputOnChange(e, 0, 'generalInfoChildLocations')}>
                                                        <option value="">--Select Country--</option>
                                                        {this.state.countries.length > 0 &&
                                                        this.state.countries.map((country, index) => (
                                                            <option key={index} value={country._id}>{country.name}</option>
                                                        ))}
                                                    </Form.Control>
                                                </Col>
                                            </Form.Group>
                                            {this.state.generalInfoChildLocations.length > 0 &&
                                            this.state.generalInfoChildLocations.map((location, index) => (
                                                location?.length > 0 &&
                                                <Form.Group key={index} as={Row} controlId={`location${location[0].type}`}>
                                                    <Form.Label column sm="3">{location[0].type}</Form.Label>
                                                    <Col sm="9" md={8} lg={7}>
                                                        <Form.Control as="select"
                                                                      defaultValue={this.state.generalInfoLocations[index + 1]?._id}
                                                                      name={location[0].type.toLowerCase()}
                                                                      onChange={(e) => this.handleLocationInputOnChange(e, index + 1, 'generalInfoChildLocations')}>
                                                            <option value="">--Select--</option>
                                                            {location.map((item, index) => (
                                                                <option key={index} value={item._id}>{item.name}</option>
                                                            ))}
                                                        </Form.Control>
                                                    </Col>
                                                </Form.Group>
                                            ))}

                                            <Form.Group as={Row} controlId="">
                                                <Form.Label column sm="3">
                                                    Postcode <span className="text-danger">*</span>
                                                </Form.Label>

                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control
                                                        defaultValue={formData.postcode}
                                                        onChange={this.handleInputOnChange}
                                                        name={"postcode"} type={"text"}/>
                                                </Col>
                                            </Form.Group>


                                            {/* Warehouse Details */}
                                            <Form.Group as={Row} controlId="">
                                                <Form.Label column sm="3"/>

                                                <Col sm="9" md={8} lg={7}>
                                                    <strong>Warehouse Details</strong>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="contactPerson">
                                                <Form.Label column sm="3">
                                                    In Charge Name <span className="text-danger">*</span>
                                                </Form.Label>

                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control
                                                        defaultValue={formData.warehouse_locations?.contact_person}
                                                        onChange={this.handleWareHouseInputOnChange}
                                                        name={"contact_person"} type={"text"}/>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="">
                                                <Form.Label column sm="3">
                                                    Phone Number <span className="text-danger">*</span>
                                                </Form.Label>

                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control
                                                        defaultValue={formData.warehouse_locations?.contact_number}
                                                        onChange={this.handleWareHouseInputOnChange}
                                                        name={"contact_number"} type={"text"}/>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="address">
                                                <Form.Label column sm="3">
                                                    Address <span className="text-danger">*</span>
                                                </Form.Label>

                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control
                                                        required
                                                        defaultValue={formData.warehouse_locations?.address}
                                                        onChange={this.handleWareHouseInputOnChange}
                                                        name={"address"} type={"text"}/>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formCountry">
                                                <Form.Label column sm="3"> Country </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control as="select" name="country"
                                                                  required
                                                                  value={formData.warehouse_locations?.country}
                                                                  onChange={(e) => this.handleLocationInputOnChange(e, 0, 'wareHouseChildLocations')}>
                                                        <option value="">--Select Country--</option>
                                                        {this.state.countries.map((country, index) => (
                                                            <option key={index} value={country._id}>{country?.name}</option>
                                                        ))}
                                                    </Form.Control>
                                                </Col>
                                            </Form.Group>
                                            {this.state.wareHouseChildLocations.length > 0 &&
                                            this.state.wareHouseChildLocations.map((location, index) => (
                                                location?.length > 0 &&
                                                <Form.Group key={index} as={Row} controlId={`location${location[0].type}`}>
                                                    <Form.Label column sm="3">{location[0].type}</Form.Label>
                                                    <Col sm="9" md={8} lg={7}>
                                                        <Form.Control as="select"
                                                                      value={formData.warehouse_locations[location[0].type.toLowerCase()]}
                                                                      name={location[0].type.toLowerCase()}
                                                                      onChange={(e) => this.handleLocationInputOnChange(e, index + 1, 'wareHouseChildLocations')}>
                                                            <option value="">--Select--</option>
                                                            {location.map((item, index) => (
                                                                <option key={index} value={item._id}>{item.name}</option>
                                                            ))}
                                                        </Form.Control>
                                                    </Col>
                                                </Form.Group>
                                            ))
                                            }

                                            <Form.Group as={Row} controlId="">
                                                <Form.Label column sm="3">
                                                    Postcode <span className="text-danger">*</span>
                                                </Form.Label>

                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control
                                                        required
                                                        defaultValue={formData.warehouse_locations?.postcode}
                                                        onChange={this.handleWareHouseInputOnChange}
                                                        name={"postcode"} type={"text"}/>
                                                </Col>
                                            </Form.Group>


                                            {/* Return Address Details */}
                                            <Form.Group as={Row} controlId="">
                                                <Form.Label column sm="3"/>
                                                <Col sm="9" md={8} lg={7}>
                                                    <strong>Return Address Details</strong>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="">
                                                <Form.Label column sm="3">
                                                    Copy from warehouse address
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        type="radio"
                                                        defaultChecked={formData.is_same_as_warehouse_locations}
                                                        name="is_same_as_warehouse_locations"
                                                        onChange={this.handleCheckSameAddress}
                                                        id={`sameAddressYes`}
                                                        label={`Yes`}
                                                        value={true}
                                                    />
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        type="radio"
                                                        value={false}
                                                        defaultChecked={!formData.is_same_as_warehouse_locations}
                                                        onChange={this.handleCheckSameAddress}
                                                        name="is_same_as_warehouse_locations"
                                                        id={`sameAddressNo`}
                                                        label={`No`}
                                                    />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="">
                                                <Form.Label column sm="3">
                                                    Contact Person
                                                </Form.Label>

                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control
                                                        required
                                                        defaultValue={formData?.return_location?.contact_person}
                                                        onChange={this.handleReturnInputOnChange}
                                                        name={"contact_person"} type={"text"}/>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="">
                                                <Form.Label column sm="3">
                                                    Contact Number
                                                </Form.Label>

                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control
                                                        required
                                                        defaultValue={formData?.return_location?.contact_number}
                                                        onChange={this.handleReturnInputOnChange}
                                                        name={"contact_number"} type={"text"}/>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="">
                                                <Form.Label column sm="3">
                                                    Address
                                                </Form.Label>

                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control
                                                        required
                                                        name={"address"}
                                                        defaultValue={formData?.return_location?.address}
                                                        onChange={this.handleReturnInputOnChange} type={"text"}/>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="formCountry">
                                                <Form.Label column sm="3"> Country </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control as="select"
                                                                  required
                                                                  value={formData?.return_location?.country}
                                                                  name="country"
                                                                  onChange={(e) => this.handleLocationInputOnChange(e, 0, 'returnChildLocations')}>
                                                        <option value="">--Select Country-</option>
                                                        {this.state.countries.map((country, index) => (
                                                            <option key={index} value={country._id}>{country.name}</option>
                                                        ))}
                                                    </Form.Control>
                                                </Col>
                                            </Form.Group>

                                            {this.state.returnChildLocations?.length > 0 &&
                                            this.state.returnChildLocations.map((location, index) => (
                                                location?.length > 0 &&
                                                <Form.Group key={index} as={Row} controlId={`location${location[0].type}`}>
                                                    <Form.Label column sm="3">{location[0].type}</Form.Label>
                                                    <Col sm="9" md={8} lg={7}>
                                                        <Form.Control as="select"
                                                                      value={formData.return_location[location[0]?.type.toLowerCase()]}
                                                                      name={location[0].type.toLowerCase()}
                                                                      onChange={(e) => this.handleLocationInputOnChange(e, index + 1, 'returnChildLocations')}>
                                                            <option value="">-- Select --</option>
                                                            {location.map((item, index) => (
                                                                <option key={index} value={item._id}>{item.name}</option>
                                                            ))}
                                                        </Form.Control>
                                                    </Col>
                                                </Form.Group>
                                            ))
                                            }

                                            <Form.Group as={Row} controlId="">
                                                <Form.Label column sm="3">
                                                    Postcode <span className="text-danger">*</span>
                                                </Form.Label>

                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control
                                                        required
                                                        defaultValue={formData.return_location?.postcode}
                                                        name={"postcode"}
                                                        onChange={this.handleReturnInputOnChange}
                                                        type={"text"}/>
                                                </Col>
                                            </Form.Group>
                                        </>
                                        }

                                        <hr/>
                                        {/*Assisted Selling*/}

                                        <Form.Group as={Row}>
                                            <Form.Label column sm="3">
                                                Assisted Selling
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Check
                                                    custom inline type="checkbox"
                                                    name="assisted_selling" id={`assistedSelling`}
                                                    label={``} value={true} onChange={(e) => {
                                                    this.handleInputOnChange(e);
                                                    this.handleGetAssistedSellerList('')
                                                }}
                                                    checked={this.state.formData?.assisted_selling}
                                                />
                                            </Col>
                                        </Form.Group>


                                        {this.state.formData?.assisted_selling &&
                                        <>
                                            {this.state?.assisted_seller_info ?
                                                <>
                                                    <Form.Group as={Row} controlId="formAssistedSeller">
                                                        <Form.Label column sm="3">
                                                            Assisted Seller <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Col sm="9" md={8} lg={7}>
                                                            <table className="order-table table table-striped">
                                                                <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div className="order-item">
                                                                            <h5>
                                                                                {this.state?.assisted_seller_info?.name}
                                                                                {this.state?.assisted_seller_info?.store_info ? ", Store:" + this.state?.assisted_seller_info?.store_info?.store_name : ''}
                                                                            </h5>
                                                                            <ul className="ordered-items">
                                                                                <li>{this.state?.assisted_seller_info?.phone}</li>
                                                                                <li>{this.state?.assisted_seller_info?.email}</li>
                                                                                <li>{"Address: " + this.state?.assisted_seller_info?.address}</li>
                                                                                <li>
                                                                                    {this.state?.assisted_seller_info?.locations?.forEach((item) => {
                                                                                        return <>{item?.type + ": " + item?.name}</>
                                                                                    })}
                                                                                </li>
                                                                                <li>{"Postcode: " + this.state?.assisted_seller_info?.postcode}</li>
                                                                                <li>{"Gender: " + this.state?.assisted_seller_info?.gender}</li>
                                                                            </ul>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                    </Form.Group>
                                                </> :
                                                <>
                                                    {(this.state.formData?.assisted_selling && (this.state?.assisted_seller_info === undefined || this.state?.assisted_seller_info === {})) &&
                                                    <>
                                                        <Form.Group as={Row} controlId="formAssistedSeller">
                                                            <Form.Label column sm="3">
                                                                Assisted Seller <span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Col sm="9" md={8} lg={7}>
                                                                <Select styles={styleReactSelect} required
                                                                        placeholder="Type for Select assisted seller..."
                                                                        onInputChange={this.handleGetAssistedSellerList}
                                                                        id="assisted_seller_id" name="assisted_seller_id"
                                                                        defaultValue={this.state?.formData?.assisted_seller_id}
                                                                        onChange={(value) => {
                                                                            this.handleSelectInputOnChange('assisted_seller_id', value)
                                                                        }}
                                                                        options={this.state.assisted_sellers}
                                                                        noOptionsMessage={() => 'Please insert new value...'}
                                                                />
                                                                {<span className="text-help text-muted">{'Please search and select assisted seller'}</span>}
                                                            </Col>
                                                        </Form.Group>
                                                    </>
                                                    }
                                                </>
                                            }
                                        </>
                                        }


                                        <div className="mt-3">
                                            <Button size="lg" type={"submit"}
                                                    disabled={this.state.isSubmitting}
                                                    variant="primary">{this.state.isSubmitting ? 'Updating...' : 'Update'}</Button>
                                        </div>
                                    </>
                                    :
                                    <div>Loading...</div>
                                }
                            </Form>
                        </div>
                    </> : <span> </span>}

            </App>
        );
    }
}

export default withRouter(EditStore);
