import axios from 'axios';
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData, objectToFormData} from "../helper/utils";

export const addUser = (data) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.post(API_BASE_URL + '/user', data, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                reject('Something went wrong')
            })
    })
}



export const addToCart = (data) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.post(API_BASE_URL + '/cart', data, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                reject('Something went wrong')
            })
    })
}


export const placeOrder = (data) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.post(API_BASE_URL + '/order',  data,{
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                reject('Something went wrong')
            })
    })
}


export const removeCart = (params, itemId) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.delete(API_BASE_URL + '/cart/item/'+itemId,  {
            params:params,
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                reject('Something went wrong')
            })
    })
}


export const getCart = (params) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + '/cart',  {
            params:params,
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                reject('Something went wrong')
            })
    })
}




export const addBillingAddress = (data, params) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.post(API_BASE_URL + '/billing-address', data, {
            params: params,
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                reject('Something went wrong')
            })
    })
}

export const addShippingAddress = (data, params) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.post(API_BASE_URL + '/delivery-address', data, {
            params:params,
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                reject('Something went wrong')
            })
    })
}


export const getBillingAddresses = (data, params) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + '/billing-address', data, {
            params:params,
            // headers: {
            //     'x-auth-token': authData.token,
            //     'x-api-client': getDeviceType()
            // }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                reject('Something went wrong')
            })
    })
}


export const getShippingAddresses = (data, params) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + '/delivery-address', data, {
            params:params,
            // headers: {
            //     'x-auth-token': authData.token,
            //     'x-api-client': getDeviceType()
            // }
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                reject('Something went wrong')
            })
    })
}

