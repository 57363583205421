import React, {useState, useEffect, Component} from "react";
import App from "../../App";
import { Link , withRouter} from "react-router-dom";
import Moment from "moment";
import {FaLongArrowAltLeft, FiClock, IoIosArrowForward, IoMdCheckmark, RiReplyLine} from "react-icons/all";
import "../../assets/scss/create-live.scss";
import { supportTicketList } from "../../services/support-ticketService";
import {Button, Col, Form, Modal, Row, ToggleButton} from "react-bootstrap";
import Pagination from "react-js-pagination";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import styleReactSelect from "../../assets/styleReactSelect";
import Select from "react-select";
import {getStoreList} from "../../services/storeServices";
import {getProductListSecondary, getSkuProductList} from "../../services/productServices";
import {toast} from "react-toastify";
import {submitBundleOffer} from "../../services/promotionServices";
import {saveData ,addNewItem} from "../../services/liveServices";
import {getLocalAuthData} from "../../helper/utils";
import DateRangePicker from "react-bootstrap-daterangepicker";
import LoadingSpinner from "../../components/LoadingSpinner";
import BrowseFiles from "../../components/BrowseFiles";
import DatePicker from "react-datepicker";

const today = Moment(new Date()).format('Y-M-D');


class CreateLive extends Component{

    state = {

        formData: {
            product_list: [],
            is_finish: false,
            store_name: ""
        },
        queryData: {limit: 20, search_string: ''},
        productListPagination: {},
        isProductModalProductShowing: false,
        product_list: [],
        storeList: [{
            id: null,
            value: null,
            label: "Type for search"
        }],
        productList: [],
        isLoading: true,
        productType: '',
        selectedProducts: [],
        selectedProducts_sku: [],
        Products_sku: [],
        isFormValidated: false,
        isSubmitting: false,
        selected_gift_products: [],
        selected_products: [],
        isProductListLoading: false,
        errorMessage: ""
    }

    componentDidMount() {
        const history = this.props.history;

        const storeList = getStoreList({status: 1, approval_status: 1, lite_data: 1, limit: 10})
            .then(res => {
                const storeList = [];

                res.items.forEach(item => {
                    const store = {
                        id: item._id,
                        value: item._id,
                        label: item.store_name
                    }

                    storeList.push(store)
                    this.setState({storeList})
                })
            })

        Promise.all([storeList])
            .then(() => {
                this.setState({isLoading: false})
            })
            .catch(() => {
                this.setState({isLoading: false})
            })
    }


    handleImageOnBrowse = (images) => {
        const brandData = {...this.state.brandData}
        if (images.length > 0) {
            brandData.logo = images[0].thumb_img

            const formData = {...this.state.formData}

            this.setState({formData})
        }
        this.setState({brandData})
    }


    filterSearchable = (inputValue) => {

        if (inputValue.length >= 2) {
            getStoreList({search_string: inputValue, lite_data: 1, limit: 50})
                .then(res => {
                    const storeList = [];

                    res.items.forEach(item => {
                        const store = {
                            id: item._id,
                            value: item._id,
                            label: item.store_name
                        }

                        storeList.push(store)
                        this.setState({storeList})
                    })
                }).catch(errMsg => {
                console.log(errMsg)
            })
        }


        return this.state.storeList;
    };

    promiseOptions = inputValue => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(this.filterSearchable(inputValue));
            }, 0);
        });
    };

    handlePromotionTypeOnChange = (e) => {
        const formData = {...this.state.formData}
        formData.product_list = []

        formData[e.target.name] = e.target.value
        this.setState({
            formData,
            product_list: [],
            selected_products: [],
            selected_gift_products: []
        })
    }

    handleInputOnChange = (e) => {
        const formData = {...this.state.formData};
        formData[e.target.name] = e.target.value;

        if (e.target.name === "title"){
            formData['slug'] =  e.target.value
        }

        this.setState({formData,  productList: []})

        const queryData = {...this.state.queryData};
        queryData.search_string = e.target.value;

        this.setState({queryData}, ()=>{
            this.getProducts();
        });
    }

    handleSearchProduct = (e) => {
        const formData = {...this.state.formData};
        const queryData = {...this.state.queryData};
        if (this.state.formData?.stores?.[0]) {
            this.getProducts(queryData);
        }
    }


    getProducts = (queryData = {}) => {
        let query = {
            ...queryData,
            limit: this.state.queryData.limit,
            // stores: JSON.stringify(this.state.formData?.stores || []),
            store_id: this.state.formData?.stores?.[0],
        }

        if (this.state.queryData.search_string) {
            query.search_string = this.state.queryData.search_string;
        }

        this.setState({isProductListLoading: true});
        getProductListSecondary(query).then(res => {
            if(res.status === 1){
                this.setState({productList: res.products, productListPagination: res.pagination, isProductListLoading: false, errorMessage: ""})
            }else{
                this.setState({productList: res.products, productListPagination: res.pagination, isProductListLoading: false, errorMessage: "No product available for this store"})
            }
        }).catch(err => {
            this.setState({isProductLoading: false, isProductListLoading: false})
        })
    }


    handleSelectMultiOnChange = (array, name) => {

        let query = {...this.state.queryData}
        const formData = {...this.state.formData};
        query.search_string = "";
        formData.product_list = [];

        formData.store_id  = array.id
        formData.store_name = array.label

        this.setState({queryData: query, selected_products: [], selected_gift_products: [], price_sum: 0, gift_sum: 0,formData })

        const data = [];
        if (name === "stores") {
            data.push(array.id)

            this.setState({product_list: []})
        } else {

            if (array) {
                array.forEach(val => {
                    data.push(val.id)
                })
            }
        }

        formData[name] = data;
        this.setState({formData}, () => {
            this.getProducts(query);
        })
    }



    /*START HANDLE MODAL*/
    handleShowSelectProductModal = (type) => {
        console.log(type)
        this.setState({
            isProductModalProductShowing: true,
            productType: type,
        })
    }

    handleHideProductModal = () => {
        this.setState({isProductModalProductShowing: false})
    }

    isProductIncluded = (id) => {
        let type = "product_list";

        if (this.state.formData[type] === undefined) return false;
        if (this.state.formData[type] === null) return false;
        if (this.state.formData[type].length === 0) return false;
        console.log('sku',id)
        console.log("matched product",this.state.productList.filter(item=> item._id === id))
        console.log("isIncluded",this.state.formData.product_list.includes(id))
        if (this.state.formData[type].includes(id))
            return true;
        else
            return false;
    }

    handleSelectedProductOnChange = (e, id, sku) => {
        let selected_products = [...this.state.selected_products];
        let selected_gift_products = [...this.state.selected_gift_products];
        let formData = {...this.state.formData};

        let checkedProduct = this.state.productList.filter(item => item._id === sku);

        if (this.state.productType === "products") {

            if (e.target.checked) {
                selected_products.push(checkedProduct[0])
                formData['product_list'].push(sku)
            } else {
                selected_products = selected_products.filter(item => item._id !== checkedProduct[0]._id);
                formData['product_list'] = formData['product_list'].filter(item => item !== sku);
            }

        } else {

            if (e.target.checked) {
                selected_gift_products.push(checkedProduct[0])
            } else {
                selected_gift_products = selected_gift_products.filter(item => item._id !== checkedProduct[0]._id);
            }

        }

        this.setState({selected_products, selected_gift_products, formData}, ()=>{
            console.log(this.state.formData);
        });
    }

    handleRegisteredStorePageChange = async (page) => {
        this.setState({...this.state.queryData, page: page}, () => {
            this.getProducts();
        });
    }

    handleRemoveSelectedProduct = (type, sku) => {
        let selected_products = [...this.state.selected_products];
        let selected_gift_products = [...this.state.selected_gift_products];
        let formData = {...this.state.formData};
        let checkedProduct = this.state.productList.filter(item => item._id === sku);

        if (type === "product_list") {
            selected_products = selected_products.filter(item => item !== checkedProduct[0]);
            formData['product_list'] = formData['product_list'].filter(item => item !== sku);
        } else {
            selected_gift_products = selected_gift_products.filter(item => item !== checkedProduct[0]);
        }

        this.setState({selected_products, selected_gift_products, formData})
    }
    /*END HANDLE MODAL*/

    handleOnApply = (e, picker) => {
        const formData = {...this.state.formData};
        const startDate = Moment(picker.startDate).format('Y-M-D');
        const endDate = Moment(picker.endDate).format('Y-M-D')

        formData.start_time = startDate;
        formData.end_time = endDate;

        this.setState({
            formData,
            startDate: startDate,
            endDate: endDate,
        })
    }

    handleSubmitForm = (e) => {
        e.preventDefault();
        const formData = {...this.state.formData};
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({isFormValidated: true})
        }else {
            console.log(".... >>")
            console.log(formData)

            if (this.state.formData.type === "null" || this.state.formData.type === null){
                toast.warning("Please Select Type");
            }else {
                addNewItem('/live',formData).then(res => {
                    console.log(res)
                    if (res.data.status === 2) {
                        toast.warning(res.message);

                    } else {
                        toast.success(res.data.message);
                        this.props.history.push("/stores/live");
                    }
                    //  toast.success("Live Added");
                }).catch(errMsg => {
                    toast.error(errMsg)
                })

                this.setState({isSubmitting: true});
                setTimeout(() => {
                    this.setState({isSubmitting: false, isFormValidated: false})
                }, 2000)
            }

        }

    }


 render() {
        return(
            <App layout="boxed">
                <div className="page-header">
                    <h2 className="page-title">Create new Live</h2>
                    <div className="ml-auto">
                        <Link to={`/stores/live`}
                              className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Live List</Link>
                    </div>
                </div>

                <div className="main-content">
                    
                        <Form
                            noValidate validated={this.state.isFormValidated}
                            onSubmit={this.handleSubmitForm}>
                            <div className="card-block">
                                <div className="block-header">
                                    <h4 className="block-title">Define Live Details</h4>
                                </div>

                                <div className="block-body">
                                 <Form.Group as={Row} controlId="formPromotionName">
                                        <Form.Label column sm="3">
                                           Title <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control type="text"
                                                          onChange={this.handleInputOnChange}
                                                          name="title"
                                                          required
                                                          placeholder=""/>
                                        </Col>
                                    </Form.Group>



                                    <Form.Group as={Row} controlId="formPromotionName">
                                        <Form.Label column sm="3">
                                            Live url <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control type="text"
                                                          onChange={this.handleInputOnChange}
                                                          name="live_url"
                                                          required
                                                          placeholder=""/>
                                        </Col>
                                    </Form.Group>



                                    <Form.Group as={Row} controlId="formVoucherType">
                                        <Form.Label column sm="3">
                                           Type <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control as="select"
                                                          required
                                                          name={`type`}
                                                          onChange={this.handlePromotionTypeOnChange} placeholder="">
                                                {/*<option value="1">Quantity</option>*/}
                                                <option value="null"
                                                        selected={this.state.formData.type === "null"}
                                                        label="--Select--"/>
                                                <option value="youtube" selected={this.state.formData.type === "youtube"}>
                                                    Youtube
                                                </option>
                                                <option value="facebook" selected={this.state.formData.type === "facebook"}>
                                                    Facebook
                                                </option>

                                            </Form.Control>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formEndTime">
                                        <Form.Label column sm="3">
                                           Live start time <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>

                                            <DatePicker selected={this.state.formData.start_time}
                                                        dateFormat="dd/MM/yyyy"
                                                        minDate={new Date()}
                                                        required
                                                        className={'form-control'} onChange={date => {
                                                const formData = {...this.state.formData}
                                                formData.start_time = date
                                                this.setState({formData}, () => {
                                                    console.log('Deposit Info: ', this.state.formData);
                                                })
                                            }}/>
                                        </Col>
                                    </Form.Group>



                                </div>
                            </div>

                            <div className="card-block">
                                <div className="block-body">
                                    <Form.Group as={Row} controlId="applicableFor">
                                        <Form.Label column sm="3">
                                            Applicable For <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control
                                                required
                                                name="apply_to"

                                                as="select" placeholder="">
                                                <option value="2">Specific Stores</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="forStores">
                                        <Form.Label column sm="3">
                                            Store(s) <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <AsyncSelect
                                                isMulti={false}
                                                required
                                                // value={this.state.attribute}
                                                styles={styleReactSelect}
                                                placeholder="Select Store..."

                                                onChange={(value) => {
                                                    this.handleSelectMultiOnChange(value, 'stores');
                                                }}
                                                cacheOptions
                                                defaultOptions
                                                loadOptions={this.promiseOptions}
                                                options={this.state.storeList}
                                                name="attributes"

                                                noOptionsMessage={() => 'Select store...'}
                                            />
                                        </Col>
                                    </Form.Group>

                                </div>
                            </div>

                            <>
                                <Modal show={this.state.isProductModalProductShowing}
                                       onHide={this.handleHideProductModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Select Product
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>

                                        <div className="mb-3 form-inline">
                                            <Form.Group>
                                                <Form.Label srOnly>Search</Form.Label>
                                                <Form.Control type={`search`}
                                                              onChange={(e)=>{this.handleInputOnChange(e);}}
                                                              onKeyDown={(e)=> {
                                                                  if(e.keyCode === 13){
                                                                      this.handleInputOnChange(e)
                                                                  }
                                                              }}
                                                              size={`sm`}
                                                              placeholder={`Type & Search`}/>
                                            </Form.Group>
                                            <Button type={`button`} onClick={(e) => this.handleSearchProduct(e)}
                                                    size={`sm`} variant={`info`}
                                                    className={`ml-2`}>Search</Button>

                                        </div>

                                        {
                                            this.state.errorMessage?
                                                <h5>{this.state.errorMessage}</h5> :
                                                <>
                                                    <div className="product-checkbox-list">
                                                        {
                                                            this.state.isProductListLoading && <LoadingSpinner text={`Loading...`}/>
                                                        }
                                                        {this.state.productList.map((product, index) => (
                                                            <label className={`product-item`} key={index}
                                                                   htmlFor={`product-${index}`}>
                                                                {console.log("isProductIncluded",this.isProductIncluded(product._id))}
                                                                {console.log("formData",this.state.formData)}
                                                                <input id={`product-${index}`}
                                                                       onChange={e => this.handleSelectedProductOnChange(e, product._id, product._id)}
                                                                       name={`product`}
                                                                       defaultChecked={this.isProductIncluded(product._id)}
                                                                       type="checkbox"/>
                                                                <span className={`product`}>
                                                                <span className="product-img">
                                                                  <img src={product?.thumb_img}
                                                                      alt=""/>
                                                                </span>
                                                                <span className="product-short-info">
                                                                   <span className="product-name">{product.name} </span>
                                                                </span>
                                                                <span className="icon"><IoMdCheckmark/></span>
                                                              </span>
                                                            </label>
                                                        ))}
                                                    </div>

                                                    <Pagination
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                        activePage={this.state?.productListPagination?.page}
                                                        itemsCountPerPage={this.state?.productListPagination?.limit}
                                                        totalItemsCount={this.state?.productListPagination?.totalDocs}
                                                        onChange={this.handleRegisteredStorePageChange.bind(this)}
                                                    />
                                                </>
                                        }
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={this.handleHideProductModal}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>


                                <div className="card-block">
                                    <div className="block-header">
                                        <h4 className="block-title">Product</h4>
                                    </div>

                                    <div className="block-body">
                                        <table className={`table table-striped`}>
                                            <thead className={`sm`}>
                                            <tr>
                                                <th style={{width: '50px'}}/>
                                                <th>Product Name</th>
                                                <th>Product Price</th>
                                                <th style={{width: '1%'}}/>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {this.state.selected_products.length > 0 ?
                                                <>

                                                    {this.state.selected_products.map((product, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <img src={product?.thumb_img} style={{width: '40px'}} alt=""/>
                                                                </td>
                                                                <td>{product?.name}</td>
                                                                <td>{product?.sku_info?.price}</td>
                                                                <td>
                                                                    <span style={{cursor: 'pointer'}} className={`text-bold text-danger`}
                                                                          onClick={() => this.handleRemoveSelectedProduct('product_list', product._id)}>&times;</span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </>
                                                :
                                                <tr>
                                                    <td colSpan={3} className={`text-center text-bold`}>
                                                        No product selected
                                                    </td>
                                                </tr>
                                            }

                                            </tbody>
                                        </table>

                                        <Button size="sm" variant="primary"
                                                onClick={() => this.handleShowSelectProductModal('products')}>
                                            {this.state.formData.promotion_type === '4' ? "Select Mother Product" : "Select Product"}
                                        </Button>
                                    </div>
                                </div>
                            </>

                            <div className="card-block">
                                <div className="block-body text-right">
                                    <Link to={`/stores/live`}
                                          className="btn btn-lg btn-default mr-3">Cancel</Link>
                                    <Button size="lg" type={`submit`}
                                            disabled={this.state.isSubmitting}
                                            variant="primary">{this.state.isSubmitting ? 'Saving...' : 'Save'}</Button>
                                </div>
                            </div>
                        </Form>
                </div>

            </App>
        )
 }


}

export default withRouter(CreateLive);
