import React, {Component} from 'react';
import watch from "../../temp/watch.jpg";
import App from "../../App";
import {Button, Col, Form, InputGroup, Modal, Pagination, Row} from "react-bootstrap";
import {BsCheck, FaFileExport, FiCheck, FiEdit, FiPlus, FiSearch, FiTrash, FiUpload, GoLinkExternal, IoMdClose, VscClose} from "react-icons/all";
import {Link, withRouter} from "react-router-dom";
import {urlPramsToObj , getLocalAuthData } from "../../helper/utils";
import {confirmAlert} from "react-confirm-alert";
import {toast} from "react-toastify";
import {getTableData, deleteItem, updateItemPartial, activityLogPostData} from "../../services/commonServices";
import LoadingSpinner from "../../components/LoadingSpinner";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {SHOP_BASE_URL} from "../../helper/env";
import { Switch } from 'antd';
import { patchEditItem } from '../../services/baseServices';


class CampaignOfferList extends Component {
    state = {
        campaignList: [],
        loading: false,
        pagination: null,
        isDataLoading: false,
        merchant: '',
        queryData: {
            limit: 50
        },

        locations: [],
        selectedcampaignOfferId: '',
        isShowingcampaignOfferSettingModal: false,

        showRegisteredStoresModal: false,
        registeredStoreList: [],

        showApprovedStoresModal: false,
        approvedStoreList: [],

        showRegisteredProductsModal: false,
        registeredProductList: [],

        showApprovedProductsModal: false,
        approvedProductList: [],

    }

    componentDidMount() {
        let merchant = null;
        this.setState({loading: true});
        const history = this.props.history;
        if (history.location.search) {
            let queryObj = urlPramsToObj(history);
            if (queryObj.merchant) {
                merchant = queryObj.merchant;
                this.setState({merchant: queryObj.merchant});
            }
        }

        this.getTableData()
    }

    handleOnApply = (e, picker) => {
        const queryData = {...this.state.queryData};

        queryData.from_date = moment(picker.startDate).format('DD/MM/YYYY');
        queryData.to_date = moment(picker.endDate).format('DD/MM/YYYY');

        this.setState({queryData}, () => {
            console.log(this.state)
        })
    }

    renderStatus = (status) => {
        if (status === 0) {
            return <span className={"badge badge-primary"}>Pending</span>
        } else if (status === 1) {
            return <span className={"badge badge-success"}>Active</span>
        } else {
            return <span className={"badge badge-warning"}>Blocked</span>
        }
    }

    handleDeleteItem = (id,name) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure to delete this campaign permanently?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteItem(`/campaign/${id}/permanent`).then(res => {
                        this.setState({isDataLoading: true}, () => {
                            this.getTableData()
                        })
                        toast.success(res.message);
                        activityLogPostData('was delete this campaign','/campaign/edit/'+id,name)
                    }).catch(errMsg => {
                        toast.error(errMsg);
                    })
                }, {label: 'No',}
            ]
        });
    }

    getTableData = (query = {}) => {
        getTableData(`/campaign`, {...this.state.queryData, ...query}).then(res => {
            this.setState({campaignList: res.items, pagination: res.pagination, isDataLoading: false});
        }).catch(errMsg => {
            this.setState({isDataLoading: false});
        })
    }

    handleRegisteredStores = (storeIds)=>{
        getTableData({stores: storeIds}).then(res=>{
            this.setState({registeredStoreList: res.items})
        }).catch(errMsg=>{
            console.log(errMsg);
            toast.error(errMsg.message);
        });
    }

    handleRegisteredProducts = (productIds)=>{
        getTableData({products: productIds}).then(res=>{
            this.setState({registeredProductList: res.items})
        }).catch(errMsg=>{
            console.log(errMsg);
            toast.error(errMsg.message);
        });
    }

    getQueryData = (paramObj = {}) => {
        const history = this.props.history
        let queryData = this.state.queryData;

        if (paramObj?.page) {
            queryData.page = paramObj.page
        }
        this.getTableData(queryData);
    }

    handleSearchFormDataChange(e){
        this.setState({queryData: {...this.state.queryData, [e.target.name]: e.target.value}})

    }

    onFeatureChange = (checked, id) =>{
        this.setState({isDataLoading: true}, ()=>{
            patchEditItem(`/campaign/make-feature/${id}`, {is_featured: checked}).then(res=>{
                toast.success(res.message);
                this.getTableData()
            }).catch(err=>{
                toast.error(err.message)
            })
        });
        
    }

    render() {
        return (
            <App layout="">
                <div className="main-content">
                    <div className="page-header">
                        <h2 className="page-title">Campaign Offers</h2>
                        <div className="ml-auto">
                            {/*<Button size={"sm"} variant={"default"} className="mb-0 mr-2"><FaFileExport/> Export</Button>*/}
                            {getLocalAuthData()?.permission?.includes("campaign_add") ?
                                <Link to={`${process.env.PUBLIC_URL}/promotions/campaign/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add Campaign</Link>
                                :null}
                        </div>
                    </div>

                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">Campaign List</h4>
                        </div>
                        <div className="block-body position-relative">
                            {this.state.isDataLoading &&
                            <LoadingSpinner/>
                            }

                            { this.state.isDataLoading ?
                                <div>Loading...</div>
                                :
                                <>
                                    <div className={`d-flex mb-3`}>
                                        <div className="form-inline">
                                            {getLocalAuthData()?.permission?.includes('campaign_search') ?
                                                <form className="form-inline mb-3" onSubmit={(e)=> e.preventDefault()}>
                                                    <Form.Group>
                                                        <DateRangePicker
                                                            style={{display: 'inline-block'}}
                                                            initialSettings={{}}
                                                            containerStyles={{textAlign: 'left', width: '100%', display: 'block'}}
                                                            onApply={this.handleOnApply}>
                                                            <input type="button"
                                                                   placeholder={`Select date range`}
                                                                   value={(this.state.queryData.from_date && this.state.queryData.to_date) ? `${this.state.queryData.from_date} - ${this.state.queryData.to_date}` : 'Select date range'}
                                                                   style={{textAlign: 'left', minWidth: '150px'}}
                                                                   className="form-control form-control-sm mml mr-3"/>
                                                        </DateRangePicker>
                                                    </Form.Group>


                                                    <div className="form-group mr-3">
                                                        <label htmlFor="barndName" className="sr-only">Status</label>
                                                        <select
                                                            id="status" name="status"
                                                            className='form-control form-control-sm'
                                                            onChange={(e)=>this.handleSearchFormDataChange(e)}>
                                                            <option value="">--Select Status--</option>
                                                            <option value="1">Active</option>
                                                            <option value="2">Inactive</option>
                                                        </select>
                                                    </div>

                                                    <div className="form-group mr-3">
                                                        <label htmlFor="searchInput" className="sr-only">Search Key</label>
                                                        <input
                                                            type="search"
                                                            id="orderNumber"
                                                            className='form-control form-control-sm'
                                                            placeholder="Search Key"
                                                            name="search_string"
                                                            onChange={(e)=>this.handleSearchFormDataChange(e)}
                                                            onKeyDown={(e)=>{
                                                                if(e.keyCode === 13){
                                                                  this.getQueryData()
                                                                }
                                                              }}
                                                        />
                                                    </div>

                                                    <Button className="mr-2" size="sm" variant="secondary"
                                                            onClick={this.getQueryData}>Search</Button>
                                                </form>
                                                :null}

                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        <table className="order-table table table-striped">
                                            <thead>
                                            <tr>
                                                <th style={{width: '1%'}}>#</th>
                                                <th>Campaign Title</th>
                                                <th>Campaign Banner</th>
                                                <th>Joining Date</th>
                                                <th>Start</th>
                                                <th>End</th>
                                                <th>status</th>
                                                <th>Is Featured</th>
                                                <th style={{width: '120px'}}/>
                                            </tr>
                                            </thead>

                                            <Modal show={this.state.isShowingStoreSettingModal} onHide={this.handleCloseAwaitingStoreModal} size={'lg'}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Campaign Registered Stores</Modal.Title>
                                                </Modal.Header>

                                                <Modal.Body>

                                                    <div className="card-block">
                                                        <Form onSubmit={this.submitChangePaymentMethods}>
                                                            <div className="block-header">
                                                                <h4 className="block-title">Payment Methods</h4>

                                                                <Button disabled={this.state.isSubmittingPaymentMethods}
                                                                        variant="primary" type={`submit`} className={'btn btn-sm'}>
                                                                    {this.state.isSubmittingPaymentMethods ? 'Submitting...' : 'Save'}
                                                                </Button>
                                                            </div>

                                                            <div className="block-body">
                                                                <Form.Group>
                                                                    <Col sm="12">
                                                                        <Form.Check
                                                                            custom inline type="checkbox" name="payment_methods" id={`paymentMethodCOD`}
                                                                            label={`Cash On Delivery`} value={"cash on delivery"}
                                                                            onChange={e => this.handleOnChangePaymentMethods(e, "cash on delivery")}
                                                                            checked={true}/>
                                                                    </Col>
                                                                </Form.Group>

                                                            </div>
                                                        </Form>
                                                    </div>

                                                    <div className="card-block">
                                                        <div className="block-header">
                                                            <h4 className="block-title">Available Shipping Options</h4>
                                                        </div>

                                                        <div className="block-body">
                                                            {this.state.isLoading ?
                                                                <>Loading...</>
                                                                :
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                    <tr>
                                                                        <th style={{width: '20px'}}>#</th>
                                                                        <th>Shipping Option</th>
                                                                        <th>Location</th>
                                                                        <th>Fee</th>
                                                                        <th>Time</th>
                                                                        <th style={{width: `1%`}}/>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {( getLocalAuthData()?.permission?.includes('campaign_list') &&this.state.shipping_options?.length > 0) ?
                                                                        this.state.shipping_options.map((item, index) => (
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td>{item.shipping_option}</td>
                                                                                <td>{item?.destination_location_info}</td>
                                                                                <td>{item?.fee}</td>
                                                                                <td>{item?.estimated_time}</td>

                                                                                <td className="nowrap text-center">
                                                                                    { getLocalAuthData()?.permission?.includes('campaign_edit') ? <span
                                                                                        className="action text-bold text-default mr-3"
                                                                                        onClick={() => this.editShippingOption(item._id)}><FiEdit/> </span> :null}
                                                                                    { getLocalAuthData()?.permission?.includes('campaign_delete') ?
                                                                                        <span className="action text-bold text-danger mr-3" onClick={() => this.deleteShippingOption(item._id)}><FiTrash/> Delete</span>
                                                                                        : null}
                                                                                </td>
                                                                            </tr>
                                                                        )) :
                                                                        <tr>
                                                                            <td colSpan={7} className={`text-bold text-center`}>No item found</td>
                                                                        </tr>
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            }
                                                        </div>
                                                    </div>
                                                </Modal.Body>

                                                <Modal.Footer>
                                                    <Button variant="default" onClick={this.handleCloseAwaitingStoreModal}>Close</Button>
                                                </Modal.Footer>
                                            </Modal>

                                            <tbody>
                                            { (getLocalAuthData()?.permission?.includes('campaign_list') && this.state.campaignList.length > 0) ?
                                                this.state.campaignList.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{this.state.pagination.pagingCounter + index}</td>
                                                        <td width={"20%"}>
                                                            <div className="order-item">
                                                                <h4 className="order-id mb-0">
                                                                    <Link to={`${process.env.PUBLIC_URL}/promotions/campaign/details/${item._id}`}>{item.campaign_title}</Link>
                                                                    <a target={'_blank'}
                                                                       rel="noopener noreferrer"
                                                                       className={'ml-3 text-primary'}
                                                                       href={`${SHOP_BASE_URL}/campaigns/${item.campaign_slug}`}><GoLinkExternal/></a>
                                                                </h4>
                                                                <ul className="ordered-items">
                                                                    <li>{"Created At:" + moment(item.createdAt).format('lll')}</li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                        <td><img src={item?.campaign_cover_image} width={'80px'} alt=""/></td>
                                                        <td>{moment(item?.registration_last_date).format('hh:mm  a DD/MM/YYYY')}</td>
                                                        <td>{moment(item?.start_date).format('hh:mm  a DD/MM/YYYY')}</td>
                                                        <td>{moment(item?.end_date).format('hh:mm  a DD/MM/YYYY')}</td>
                                                        {/*<td>{moment(item?.end_date).format('ll')}</td>*/}
                                                        <td className={`text-center`}>
                                                            {getLocalAuthData()?.permission?.includes('campaign_active_inactive') ? <select value={item.status} className="custom-control"
                                                                     onChange={(e) => {
                                                                         const value = e.target.value
                                                                         const campaignList = [...this.state.campaignList]
                                                                         campaignList[index].status = value
                                                                         this.setState({campaignList}, () => {
                                                                             updateItemPartial(`/campaign/${item._id}/partial`, {status: value}).then(res => {
                                                                                 toast.success(res.message)

                                                                                 // TODO : you can delete
                                                                                /* res.campaign.stores.map((storeId , index)=>{
                                                                                     updateItemPartial(`/store/${storeId}/changeStatus`, {status: value}).then((ress)=>{
                                                                                         console.log(ress)
                                                                                         activityLogPostData('was change campaign offer status','/complains/details/'+item._id ,item.campaign_title)
                                                                                     })
                                                                                 })*/

                                                                             }).then(errMsg => {
                                                                                 toast.error(errMsg)
                                                                             })
                                                                         })
                                                                     }}>
                                                                <option value="1">Active</option>
                                                                <option value="2">Inactive</option>
                                                            </select>:null}
                                                        </td>
                                                        <td className={`text-center`}>
                                                            {/* Working Here */}
                                                            {console.log("item?.is_featured", item?.is_featured)}
                                                            <Switch defaultChecked={item?.is_featured} 
                                                                checkedChildren={<BsCheck />}
                                                                unCheckedChildren={<VscClose />} 
                                                                size="default"
                                                                onChange={(checked)=>this.onFeatureChange(checked, item._id)} />
                                                        </td>
                                                        <td className="nowrap">
                                                            {getLocalAuthData()?.permission?.includes('campaign_view') ? <Link to={`/promotions/campaign/details/${item._id}`}
                                                                   className="btn btn-sm btn-outline-info mr-3"
                                                                   target={'_blank'}>View</Link>:null}
                                                            {getLocalAuthData()?.permission?.includes('campaign_edit') ? <Link to={`/promotions/campaign/edit/${item._id}`} className="btn btn-sm btn-outline-primary mr-3" target={'_blank'}>Edit</Link>:null}

                                                            {getLocalAuthData()?.permission?.includes('campaign_edit') ? <button className="btn btn-sm btn-outline-danger mr-3"
                                                                    onClick={() => this.handleDeleteItem(item._id,item?.campaign_title)}>Delete</button> :null}

                                                        </td>
                                                    </tr>
                                                ))
                                                :
                                                <tr>
                                                    <td colSpan={5} className={"text-bold text-center"}>
                                                        No campaign found. Please create <Link to={`${process.env.PUBLIC_URL}/promotions/campaign/add`}>new Campaign</Link>
                                                    </td>
                                                </tr>
                                            }
                                            </tbody>
                                        </table>
                                    </div>

                                    {this.state.pagination?.totalDocs > 0 &&
                                    <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={this.state.pagination?.page}
                                        itemsCountPerPage={this.state.pagination?.limit}
                                        totalItemsCount={this.state.pagination?.totalDocs}
                                        onChange={(page) => {
                                            const settings = {...this.state.settings}
                                            settings.page = page
                                            this.setState({isDataLoading: true, settings}, () => {
                                                getTableData({...this.state.settings}).then(res => {
                                                    this.setState({campaignList: res.items, pagination: res.pagination, isDataLoading: false});
                                                }).catch(errMsg => {
                                                    this.setState({isDataLoading: false});
                                                })
                                            })
                                        }}
                                    />
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </App>
        );
    }
}

export default withRouter(CampaignOfferList);
