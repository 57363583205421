import React, {Component} from 'react'
import "../assets/scss/filter-bar.scss"
import {ToggleButton, ToggleButtonGroup} from "react-bootstrap"
import moment from "moment"
import DateRangePicker from "react-bootstrap-daterangepicker"

class FilterBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      start_time: props.startTime,
      end_time: props.endTime,
    }
  }

  render() {
    return (
      <div className="filter-bar">
        <div className="">
          <span className="mr-2">Filter by: </span>
          <ToggleButtonGroup type="radio" name="options" defaultValue={1} className="mr-2">
            <ToggleButton variant={"default"} size={"sm"} value={1} onClick={() => {this.props.onButtonClick(1)}}>All</ToggleButton>
            <ToggleButton variant={"default"} size={"sm"} value={2} onClick={() => {this.props.onButtonClick(2)}}>Today</ToggleButton>
            <ToggleButton variant={"default"} size={"sm"} value={3} onClick={() => {this.props.onButtonClick(3)}}>This Week</ToggleButton>
            <ToggleButton variant={"default"} size={"sm"} value={4} onClick={() => {this.props.onButtonClick(4)}}>This Month</ToggleButton>
            <ToggleButton variant={"default"} size={"sm"} value={5} onClick={() => {this.props.onButtonClick(5)}}>This Year</ToggleButton>
            <ToggleButton variant={"default"} size={"sm"} value={6}>
              <DateRangePicker onApply={this.props.handleOnApply}
                               startDate={this.props.startTime}
                               endDate={this.state.end_time}>
                <span>{this.props.startTime && this.props.endTime ? moment(this.props.startTime).format('DD/MM/YYYY') + ' to ' + moment(this.props.endTime).format('DD/MM/YYYY') : 'Select Date'}</span>
              </DateRangePicker>
            </ToggleButton>
          </ToggleButtonGroup>
          {/*<Button size={"sm"} variant={"primary"}>Filter</Button>*/}
        </div>
      </div>
    )
  }
}

export default FilterBar
