import React, {Component} from 'react'
import Chart from "chart.js"
import {Col, Row} from "react-bootstrap"
import InfoCard from "../InfoCard"
import {
  AiOutlineCloseCircle,
  AiOutlineDollar,
  AiOutlineHourglass,
  AiOutlineWarning,
  FcSalesPerformance,
  GoListOrdered,
  BsCheckCircle, BsListCheck,
  FiFileText
} from "react-icons/all"
import "../../assets/scss/campaign-stats.scss"
import axios from "axios"
import config from "../../helper/env"
import {getTableData} from "../../services/commonServices";

let salesAndOrders = {
  type: "line",
  data: {
    datasets: [
      {
        yAxisID: 'A',
        label: "Sales",
        borderWidth: 1,
        pointBorderWidth: 0,
        pointBorderColor: '#FF9B0D',
        borderColor: '#FF9B0D',
        fill: 'none',
        backgroundColor: 'rgba(255,155,13,0.25)'
      },
      {
        yAxisID: 'B',
        label: "Orders",
        borderWidth: 1,
        pointBorderColor: '#5E6CC5',
        borderColor: '#5E6CC5',
        backgroundColor: 'rgba(94,108,197,0.25)'
      }
    ]
  },
  options: {
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Date'
        },
        ticks: {
          padding: 10
        }
      }],
      yAxes: [{
        id: 'A',
        position: 'left',
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Sales(BDT)'
        },
        ticks: {
          beginAtZero: true,
          userCallback: function (label) {
            if (Math.floor(label) === label) return label
          },
        }
      }, {
        id: 'B',
        position: 'right',
        display: true,
        stacked: false,
        scaleLabel: {
          display: true,
          labelString: 'Orders(number)'
        },
        ticks: {
          beginAtZero: true,
          userCallback: function (label) {
            if (Math.floor(label) === label) return label
          },
        }
      }]
    },
  }
}

class CampaignStats extends Component {

  constructor(props) {
    super(props)
    this.state = {
      order_vs_sales_reports: {
        total_sale: 0,
        total_order: 13,
      },
      isState: false,
      campaign_lists: [],
      campaign_reports: {
        /*title: null,
        title_bn: null,
        confirmed_order:0,
        total_sales: 0,
        need_approval: 0,
        picked_order: 0,
        shipped_order: 0,
        delivered_order:0,
        pending_order: 0,
        total_order: 0,
        pending_payment_order: 0,
        complete_order: 0,
        cancel_order: 0,
        merchant_commission: 0*/
      },
      start_time: props.startTime,
      end_time: props.endTime,
      campaignReports:{}
    }
  }

  // campaignSalesAndOrderChartRef = React.createRef()

  // componentDidMount() {
  //   this.renderData()
  // }

  renderData = (campaign_id = null) => {


    getTableData('/dashboard', {
      campaign_id: campaign_id,
      report_by: 4,
      start_time: this.state?.start_time?.format('DD-MM-YYYY'),
      end_time: this.state?.end_time?.format('DD-MM-YYYY'),
    }).then((res)=>{
      console.log(res)
      this.setState({
        campaignReports: res,
        isState:true
      },()=>{
        console.log(this.state.campaignReports)
      })
    })

    /*const campaignSalesAndOrdersRef = this.campaignSalesAndOrderChartRef.current.getContext("2d")

    axios.get(`${config.API_BASE_URL}/dashboard`, {
      params: {
        campaign_id: campaign_id,
        report_by: 4,
        start_time: this.state?.start_time?.format('DD-MM-YYYY'),
        end_time: this.state?.end_time?.format('DD-MM-YYYY'),
      }
    }).then(res => {
      let campaign_reports = []
      let order_vs_sales_reports = []
      if (res.data.campaign_reports) {
        campaign_reports = res.data.campaign_reports
        this.setState({campaign_reports: campaign_reports, campaign_lists: res.data.all_campaigns})
        order_vs_sales_reports = campaign_reports.monthlyOrderSales

        let datasets = Object.values(order_vs_sales_reports.dataset)
        salesAndOrders.data.datasets[1].data = datasets.map(function (o) {
          return o.count
        })
        salesAndOrders.data.datasets[0].data = datasets.map(function (o) {
          return o.value
        })
        salesAndOrders.data.labels = Object.keys(order_vs_sales_reports.dataset)
        new Chart(campaignSalesAndOrdersRef, salesAndOrders)
      }
      this.setState({order_vs_sales_reports: order_vs_sales_reports})
    }).catch(() => {})*/
  }



  render() {
    console.log("------------------------------",this.props)
    return (
      <div className="campaign-section">
        <div className="d-flex mb-4" style={{alignItems: 'center'}}>
          <h4
            className="campaign-section-title mb-0">{this.state.isState ? this.state?.campaignReports?.campaign_reports.campaign_info?.campaign_title
            : this.props?.campaignReports?.campaign_reports.campaign_info?.campaign_title
            }</h4>
          <div className={'ml-auto'}>
            <select onChange={e => this.renderData(e.target.value)} className={'form-control form-control-sm'}>
              <option key={''} value={"null"}>{`--Select Campaign--`}</option>
              {this.props?.campaignReports?.all_campaigns?.map((items, index) =>
                <option key={index} value={`${items._id}`}>{items.campaign_title}</option>
              )}
            </select>
          </div>
        </div>

        {this.state.isState ?
            <Row>
          <Col className={"col-20"}>
            <InfoCard title="Total Sales" className="yellow" icon={<FcSalesPerformance/>}
                      value={`৳ ` +this.state.campaignReports.campaign_reports.total_sells?.toFixed(2)}/>
          </Col>

          <Col className={"col-20"}>
            <InfoCard title="Total order" className="blue" icon={<GoListOrdered/>}
                      value={this.state?.campaignReports?.campaign_reports?.total_order}/>
          </Col>
          <Col className={"col-20"}>
            <InfoCard title="Confirm order" className="green" icon={<BsCheckCircle/>}
                      value={this.state.campaignReports.campaign_reports.confirmed_order}/>
          </Col>

          <Col className={"col-20"}>
            <InfoCard title="Pending Order" className="green-light" icon={<AiOutlineWarning/>}
                      value={this.state.campaignReports.campaign_reports.pending_order}/>
          </Col>
          <Col className={"col-20"}>
            <InfoCard title="Picked Order" className="blue" icon={<FiFileText/>}
                      value={this.state.campaignReports.campaign_reports.picked_order}/>
          </Col>
          <Col className={"col-20"}>
            <InfoCard title="Shipped Order" className="orange" icon={<AiOutlineHourglass/>}
                      value={this.state.campaignReports.campaign_reports.shipped_order}/>
          </Col>
          <Col className={"col-20"}>
            <InfoCard title="Delivered Order" className="green" icon={<BsListCheck/>}
                      value={this.state.campaignReports.campaign_reports.delivered_order}/>
          </Col>
          <Col className={"col-20"}>
            <InfoCard title="Canceled Order" className="red" icon={<AiOutlineCloseCircle/>}
                      value={this.state.campaignReports.campaign_reports.cancel_order}/>
          </Col>
              <Col className={"col-20"}>
            <InfoCard title="Processing Order" className="red" icon={<AiOutlineCloseCircle/>}
                      value={this.state.campaignReports?.campaign_reports?.processing_order}/>
          </Col>
        </Row> : <Row>
              <Col className={"col-20"}>
                <InfoCard title="Total Sales" className="yellow" icon={<FcSalesPerformance/>}
                          value={`৳ ` + this.props?.campaignReports?.campaign_reports?.total_sells ? this.props?.campaignReports?.campaign_reports?.total_sells?.toFixed(2) : "0".toFixed(2)}/>
              </Col>

              <Col className={"col-20"}>
                <InfoCard title="Total order" className="blue" icon={<GoListOrdered/>}
                          value={this.props?.campaignReports?.campaign_reports?.total_order}/>
              </Col>
              <Col className={"col-20"}>
                <InfoCard title="Confirm order" className="green" icon={<BsCheckCircle/>}
                          value={this.props?.campaignReports?.campaign_reports?.confirmed_order}/>
              </Col>

              <Col className={"col-20"}>
                <InfoCard title="Pending Order" className="green-light" icon={<AiOutlineWarning/>}
                          value={this.props?.campaignReports?.campaign_reports?.pending_order}/>
              </Col>
              <Col className={"col-20"}>
                <InfoCard title="Picked Order" className="blue" icon={<FiFileText/>}
                          value={this.props?.campaignReports?.campaign_reports?.picked_order}/>
              </Col>
              <Col className={"col-20"}>
                <InfoCard title="Shipped Order" className="orange" icon={<AiOutlineHourglass/>}
                          value={this.props?.campaignReports?.campaign_reports?.shipped_order}/>
              </Col>
              <Col className={"col-20"}>
                <InfoCard title="Delivered Order" className="green" icon={<BsListCheck/>}
                          value={this.props?.campaignReports?.campaign_reports?.delivered_order}/>
              </Col>
              <Col className={"col-20"}>
                <InfoCard title="Canceled Order" className="red" icon={<AiOutlineCloseCircle/>}
                          value={this.props?.campaignReports?.campaign_reports?.cancel_order}/>
              </Col>
              <Col className={"col-20"}>
                <InfoCard title="Processing Order" className="red" icon={<AiOutlineCloseCircle/>}
                          value={this.props?.campaignReports?.campaign_reports?.processing_order}/>
              </Col>
            </Row>
        }


        {/*<div className="card-block mb-0">*/}
        {/*  <div className="block-body">*/}
        {/*    <div className="stats-cards">*/}
        {/*      <div className="stats-card">*/}
        {/*        <span className="title">Total Sales</span>*/}
        {/*        <span className="value text-success">BDT {this.state.order_vs_sales_reports.total_sale}</span>*/}
        {/*      </div>*/}
        {/*      <div className="stats-card">*/}
        {/*        <span className="title">Total Orders</span>*/}
        {/*        <span className="value text-info">{this.state.order_vs_sales_reports.total_order}</span>*/}
        {/*      </div>*/}
        {/*    </div>*/}

        {/*    <canvas*/}
        {/*      style={{height: '320px', width: '100%'}}*/}
        {/*      ref={this.campaignSalesAndOrderChartRef}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <span/>
      </div>
    )
  }
}

export default CampaignStats
