import React, {Component} from 'react';
import App from "../../App";
import {FaLongArrowAltLeft, FiPlus, IoMdClose} from "react-icons/all";
import {Link, withRouter} from "react-router-dom";
import {Alert, Button, Col, Form, Modal, Row} from "react-bootstrap";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CreatableSelect from 'react-select/creatable';
import "../../assets/scss/add-product.scss";
import styleReactSelect from "../../assets/styleReactSelect";
import BrowseFiles from "../../components/BrowseFiles";
import {connect} from "react-redux";
import ChooseCategory from "../../components/ChooseCategory";
import {getBrandList} from "../../services/brandServices";
import {UploadAdapter} from "../../adapters/ckUploadAdapter";
import {addProduct} from "../../services/productServices";
import {categoryDetails} from "../../services/categoryServices";
import SelectCollection from "../../components/SelectCollection";
import {toast} from "react-toastify";
import Select from "react-select";
import AsyncSelect from "react-select";
import {getBDDivisions, getMerchantList} from "../../services/merchantServices";
import {getStoreList} from "../../services/storeServices";
import {getLocalAuthData} from "../../helper/utils"
import {activityLogPostData, getTableData} from "../../services/commonServices";

class AddProduct extends Component {
  state = {
    images:[],
    variantOptions: ["Red", "Green", "White", "Black", "Pink"],
    selectedCategory: '',
    selectedCategories: [],
    skuColorVariant: [],
    faq: {},
    productAttrs: [],
    kpiAttr: [],
    bulkRequestVariant: [1],
    attributes: [],
    attribute: null,
    isShowVariationModal: false,
    brands: [],
    categories: [],
    childCategories: [],
    selectedCatArr: [],
    merchants: [],
    merchant: null,
    merchantList: [],
    storeList: [],
    product: {
      gift_wrap_available: false,
      gift_wrap_cost: null,
      key_product_information: [],
      sku_type: "1",
      weight_unit: "1",
      images:[],
      sku_information: [{
        is_enabled: true,
        is_default: true,
        sku: "",
        quantity: 1,
        minimum_order_quantity: 1,
        maximum_order_quantity: '',
        price: '',
        special_price: '',
        discount: '',
        discount_value: '',
        images: [],
        bulk_availability: false,
        bulk_price: []
      }]
    },
    attrCombo: [],
    warranty_periods: [
      {label: "1 Month", value: "1"},
      {label: "2 Months", value: "2"},
      {label: "3 Months", value: "3 Months"},
      {label: "4 Months", value: "4 Months"},
      {label: "5 Months", value: "5 Months"},
      {label: "6 Months", value: "6 Months"},
      {label: "12 Months", value: "12 Months"},
      {label: "18 Months", value: "18 Months"},
      {label: "2 Years", value: "2 Years"},
      {label: "3 Years", value: "3 Years"},
      {label: "4 Years", value: "4 Years"},
      {label: "5 Years", value: "5 Years"},
      {label: "7 Years", value: "7 Years"},
      {label: "10 Years", value: "10 Years"},
    ],
    editFaqIndex: null,
    editSKUIndex: null,
    isFormValidated: false,
    isLoadingData: true,
    isSubmitting: false,
    campaign_product: false,
    is_featured: false,
    attributeErrorMessage: "",
    attComboError: "",
    districts: [],
    selectedDistricts: [],
  };

  componentDidMount() {
    /*Get Brand List*/
    const brandList = getBrandList()
      .then(res => {
        const brands = [...this.state.brands];
        res.forEach(item => {
          const brand = {
            id: item._id,
            value: item._id,
            label: item.name
          }
          brands.push(brand)
          this.setState({brands})
        })
      })
      .catch(errMsg => {
        console.log('Error Message: ', errMsg)
      })

    Promise.all([brandList])
      .then((values) => {
        this.setState({isLoadingData: false})
      })
      .catch(err => {
        this.setState({isLoadingData: false})
      })

    getBDDivisions('5f2a7c54e7abc4290b6c6212').then(data => {
      data.forEach(eachData => {
        getBDDivisions(eachData._id).then(districtsData => {
          this.setState({
            districts: this.state.districts.concat(districtsData.map(eachItem => ({value: eachItem._id, label: eachItem.name}))),
            selectedDistricts: this.state.districts.concat(districtsData.map(eachItem => ({value: eachItem._id, label: eachItem.name}))),
          })
        })
      })
    })
  }

  onMerchantChange = (id) => {
    getStoreList({merchant: id, approval_status: 1, lite_data: 1})
      .then(res => {
        const storeList = [];

        if (res.items?.length > 0) {
          res.items.forEach(item => {
            const store = {
              id: item._id,
              value: item._id,
              label: item.store_name
            }
            storeList.push(store)
          })
        }
        this.setState({storeList, merchant: id});
      })
  }

  onConfirmCategory = async (selectedCategories) => {
    const parentCat = selectedCategories[0].id;
    const lastCat = selectedCategories[selectedCategories.length - 1];
    categoryDetails(lastCat.id)
      .then(res => {
        const productAttrs = [];
        const kpiAttr = [];
        res.attributes.forEach(attr => {
          if (attr.details?.attribute_type === 1) {
            kpiAttr.push(attr);
          } else {
            productAttrs.push({
              value: attr._id,
              label: attr.details?.name,
              attr_options: attr.details?.values
            });
          }
        })
        this.setState({kpiAttr, productAttrs})
      });
    const relatedCategories = [];
    this.setState({selectedCategories: selectedCategories})

    selectedCategories.forEach((item, index) => {
      if (index > 0 && !relatedCategories.includes(item.id)) {
        relatedCategories.push(item.id);
      }
    })

    const product = {
      ...this.state.product,
      category: parentCat,
      related_categories: relatedCategories
    }
    this.setState({product});
  }

  renderKpiSection = (kpi, index) => {
    const type = kpi.details.type;
    if (type === 'text' || type === 'number' || type === 'email' || type === 'textarea') {
      return (
        <Form.Group as={Row}>
          <Form.Label column sm="3">
            {kpi.details.label}
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <Form.Control required={kpi.is_require}
                          onChange={
                            (e) => this.handleKpiFieldOnChange(kpi._id, kpi.details.label, e.target.value, index)
                          }
                          name="quantity" type={type} placeholder={kpi.details.info}/>
          </Col>
        </Form.Group>
      )
    } else if (type === 'select') {
      return <Form.Group as={Row}>
        <Form.Label column sm="3">
          {kpi.details.label}
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Form.Control required={kpi.is_require} as="select" custom>
            <option value="">--Select--</option>
            {kpi.details.values.map((opt, index) => (
              <option key={index} value={opt}>{opt}</option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

    } else if (type === 'multiselect') {
      const multiSelectOptions = [];
      kpi.details.values.forEach(value => {
        const optionsObj = {
          value: value,
          label: value
        }
        multiSelectOptions.push(optionsObj)
      })
      return <Form.Group as={Row}>
        <Form.Label column sm="3">
          {kpi.details.label}
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <CreatableSelect
            isMulti
            styles={styleReactSelect}
            placeholder="Select value..."
            onChange={(newValue) => {
              console.log(newValue);
            }}
            options={multiSelectOptions}
            noOptionsMessage={() => 'Please insert new value...'}
          />
        </Col>
      </Form.Group>
    } else if (type === 'radio' || type === 'checkbox') {
      return <Form.Group as={Row}>
        <Form.Label column sm="3">
          {kpi.details.label}
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          {kpi.details.values.map((value, i) => (
            <Form.Check
              custom
              inline required={kpi.is_require}
              value={value}
              type="radio"
              name={kpi.details.name}
              id={`kpi-${index}-${i}`}
              label={`Yes`}
            />
          ))}
        </Col>
      </Form.Group>
    }
  }

  handleKpiFieldOnChange = (id, title, value, index) => {
    const product = {...this.state.product};
    const kpi = {
      attr_id: id,
      attr_title: title,
      value: value
    }
    if (product.key_product_information[index]) {
      product.key_product_information[index] = kpi;
    } else {
      product.key_product_information.push(kpi);
    }
    this.setState({product});
  }

  /*handleOnChangeColorVariant = (variant) => {
    let newskuColorVariant = [];
    for (let i = 0; i < variant.length; i++) {
      const newObj = {
        id: i,
        color: variant[i],
        is_active: true,
        seller_sku: null,
        qnt: null,
        price: null,
        special_price: null
      }
      newskuColorVariant.push(newObj)
    }

    this.setState({skuColorVariant: newskuColorVariant});
  }*/

  handleAttrOnChange = (newValue) => {
    this.setState({
      attribute: newValue
    })
  }

  handleAddAttribute = (attrObj) => {
    if (attrObj) {
      let id = 1;
      let attrArr = this.state.product?.attributes || [];
      const attributes = [...this.state.attributes];

      if (attributes.length > 0) {
        id = attributes[attributes.length - 1].id + 1;
      }

      const newAttrObj = {
        id: id,
        ...attrObj
      }

      const productAttrObj = {
        attribute_name: attrObj.label,
        data: []
      }

      attrArr.push(productAttrObj);
      attributes.push(newAttrObj);

      const product = {...this.state.product, attributes: attrArr}

      this.setState({attributes, product}, () => {
        const productAttrs = [...this.state.productAttrs];
        const productAttrObj = this.state.productAttrs
          .filter((obj) => {
            return obj === attrObj
          })
        const index = productAttrs.indexOf(productAttrObj[0]);
        productAttrs.splice(index, 1);
        this.setState({productAttrs, attribute: null})
      })
    } else {
      alert('Select attribute first')
    }
  }

  handleRemoveAttr = (index, attr) => {
    const attributes = [...this.state.attributes];
    const productAttrs = [...this.state.productAttrs];
    const productAttributes = [...this.state.product.attributes]
    attributes.splice(index, 1);
    productAttributes.splice(index, 1);
    const product = {...this.state.product, attributes: productAttributes}
    this.setState({attributes, product}, () => {
      productAttrs.push({
        attr_options: attr.attr_options,
        label: attr.label,
        value: attr.value
      });
      this.setState({productAttrs});
    });
  }

  handleAttrOptionOnChange = (index, value) => {
    const attributes = [...this.state.attributes];
    attributes[index].selectedOptions = value;

    const productAttributes = [...this.state.product.attributes];
    productAttributes[index].data = this.formatOptions(value);

    const product = {...this.state.product, attributes: productAttributes};

    this.setState({attributes, product, attributeErrorMessage: ""}, () => {
      console.log('Attributes: ', this.state.attributes)
    });
  }

  formatOptions = (options) => {
    if (options) {
      let attrOpts = [];

      options.forEach(opt => {
        attrOpts.push({
          val: opt.label,
          img: ''
        })
      })
      return attrOpts;
    }
  }

  arrayToSelectObj = (arr) => {
    if (arr?.length === 0 || arr === undefined) {
      return
    }

    const newArr = [];
    arr.forEach(item => {
      newArr.push({
        label: item,
        value: item
      })
    })
    return newArr
  }

  handleAddVariation = () => {
    const skuInfo = {
      is_enabled: true,
      is_default: !this.state.product?.sku_information,
      attr_combo: this.state.attrCombo,
      sku: "SKU-12549",
      quantity: '',
      minimum_order_quantity: 1,
      maximum_order_quantity: '',
      price: '',
      special_price: '',
      discount: '',
      discount_value: '',
      images: [],
      bulk_availability: false,
      bulk_price: []
    }
    console.log(this.state.attrCombo);
    //console.log(arrayAlreadyHasArray(this.state.product.sku_information,this.state.attrCombo));
    let attributeMerged = '';
    this.state.attrCombo.forEach(function (entry) {
      attributeMerged = attributeMerged + entry;
      return attributeMerged;
    });

    if (arrayAlreadyHasArray(this.state.product.sku_information, attributeMerged) === true) {
      this.setState({attrCombo: [], isShowVariationModal: false}, () => {
        toast.error("Already Created");
      })
      return false;
    }
    const skuInfos = this.state.product?.sku_information || [];
    skuInfos.push(skuInfo);
    const product = {...this.state.product, sku_information: skuInfos};

    function arrayAlreadyHasArray(arr, attributeMerged) {
      for (var i = 0; i < arr.length; i++) {
        let checker = [];
        let checkVal = '';

        arr[i].attr_combo.forEach(function (entry, index) {
          checkVal = checkVal + entry;
          return checkVal;
        });

        if (checkVal === attributeMerged) {
          checker.push(true)
        } else {
          checker.push(false)
        }

        /*   for(var j = 0; j<arr[i].attr_combo.length; j++){
             console.log(arr[i].attr_combo[j],testArr[j]);
             if(arr[i].attr_combo[j] === testArr[j]){
               checker.push(true)
             } else {
               checker.push(false)
             }
           }*/
        if (checker.every(check => check === true)) {
          return true
        }
      }
      return false
    }


    this.setState({product, attrCombo: [], isShowVariationModal: false}, () => {
      console.log('Product Object: ', this.state.product);
    })
  }

  handleComboOnChange = (e, index) => {
    const attrCombo = [...this.state.attrCombo];
    attrCombo[index] = e.target.value;
    this.setState({attrCombo}, () => {
      console.log('Attribute Combo: ', this.state.attrCombo)
    });
  }

  handleOptionsImageChange = async (attrIndex, optIndex, images) => {
    const attributes = [...this.state.product.attributes];
    attributes[attrIndex].data[optIndex] = {
      ...attributes[attrIndex].data[optIndex],
      img: images[0].thumb_img
    };
    const product = {...this.state.product, attributes};
    this.setState({product}, () => {
      console.log('State: ', this.state)
    });
  }

  handleSetDefaultSku = (index) => {
    const sku_information = [...this.state.product?.sku_information];
    sku_information.forEach((obj, i) => {
      obj.is_default = index === i;
    });
    const product = {...this.state.product, sku_information};
    this.setState({product}, () => {
      console.log('Product: ', this.state.product);
    });
  };

  handleCloseVariationModal = () => {
    this.setState({
      isShowVariationModal: false
    })
  }

  handleShowVariationModal = () => {
    this.setState({
      isShowVariationModal: true,
      editSKUIndex: null
    })
  }

  handleAddProductImages = async (files) => {

    const product = {...this.state.product}
    const images = [...this.state.images , ...files]
    let array = [...this.state.images, ...files]
    this.setState({
      images: array.filter((v, i, a) => a.findIndex(t => (t.actual_img === v.actual_img)) === i)
    })

    product.images = array
    this.setState({product},()=>{
      console.log('this.state.images',this.state.images)
    });
  }

  handleNameOnChange = (e, index, lang) => {
    const item = {
      lng: lang,
      value: e.target.value
    }

    let itemList;
    if (this.state.product[e.target.name]) {
      itemList = this.state.product[e.target.name]
    } else {
      itemList = []
    }

    if (itemList[index]) {
      itemList[index] = item
    } else {
      itemList.push(item)
    }

    const product = {...this.state.product, [e.target.name]: itemList}
    this.setState({product})
  }

  createTableSelectOnChange = (value, name) => {
    const product = {
      ...this.state.product,
      [name]: value.value
    }
    this.setState({product})
  }

  handleInputOnChange = (e) => {
    const product = {...this.state.product};
    this.setState({attComboError: "", attributeErrorMessage: ""})
    console.log(product);
    if (e.target.name === 'sku_type') {
      if (e.target.value === "1") {
        product.sku_information[0] = {
          is_enabled: true,
          is_default: true,
          sku: "",
          quantity: '',
          minimum_order_quantity: 1,
          maximum_order_quantity: '',
          price: '',
          special_price: '',
          discount: '',
          discount_value: '',
          images: [],
          bulk_availability: false,
          bulk_price: []
        };
        this.setState({attributes: []});
        product.attributes = [];
      } else {
        product.sku_information = []
      }
    }

    product[e.target.name] = (e.target.type === 'checkbox' ? e.target.checked : e.target.value);
    this.setState({product});
  }

  handleCkEditorOnChange = (name, data, isMultiLang, lang) => {
    const product = {...this.state.product, [name]: data};
    this.setState({product})
  }

  handleProductDescOnChange = (name, data, index, lang) => {
    const desc = {
      lng: lang,
      value: data
    }
    let descList;
    if (this.state.product[name]) {
      descList = this.state.product[name]
    } else {
      descList = []
    }

    if (descList[index]) {
      descList[index] = desc
    } else {
      descList.push(desc)
    }

    const product = {...this.state.product, [name]: descList}
    this.setState({product})
  }

  calculateSkuPrice = (e, sku) => {
    let newSkuInfo;
    let oldSku = sku;
    sku[e.target.name] = e.target.value;
    if (e.target.name === "discount" && sku?.price !== "") {
      let price = (sku?.price != '') ? parseFloat(sku?.price) : 0;
      let special_price = 0.00;
      let discount = (e.target.value != '') ? parseFloat(e.target.value) : 0;
      let discount_value = 0.00;
      if (e.target.value !== '' && e.target.value > 0) {
        discount_value = ((price * (discount / 100))).toFixed(2);
        special_price = (price - discount_value).toFixed(2);
      }
      newSkuInfo = {...sku, price, discount, discount_value, special_price};
    } else if (e.target.name === "discount_value" && sku?.price !== "") {
      let price = (sku?.price != '') ? parseFloat(sku?.price) : 0;
      let special_price = 0.00;
      let discount = 0.00;
      let discount_value = (e.target.value != '') ? parseFloat(e.target.value) : 0;
      if (e.target.value !== '' && e.target.value > 0) {
        special_price = (price - discount_value).toFixed(2);
        discount = ((discount_value / price) * 100).toFixed(2);
      }
      newSkuInfo = {...sku, discount, special_price};
    } else if (e.target.name === "special_price" && sku?.price !== "") {
      let price = (sku?.price != '') ? parseFloat(sku?.price) : 0;
      let special_price = (e.target.value != '') ? parseFloat(e.target.value) : 0;
      let discount_value = 0.00;
      let discount = 0.00;
      if (special_price !== '' && special_price > 0) {
        discount_value = (price - special_price).toFixed(2);
        discount = (((price - special_price) / price) * 100).toFixed(2);
      }
      newSkuInfo = {...sku, discount, discount_value, special_price};
    } else if (e.target.name === "price" && sku?.discount !== "" && sku?.discount > 0 ) {
      let price = (e.target.value != '') ? parseFloat(e.target.value) : 0;
      let discount_value = 0.00;
      let special_price = 0.00;
      let discount = parseFloat(sku?.discount);
      if (e.target.value !== '' && e.target.value > 0) {
        discount_value = ((price * (discount / 100))).toFixed(2);
        special_price = (price - discount_value);
      }
      newSkuInfo = {...sku, discount_value, special_price};
    } else if (e.target.name === "price") {
      return sku;
    }

    return newSkuInfo == null ? oldSku : newSkuInfo;
  }

  handleSKUInputOnChange = (e, index) => {
    const sku_information = [...this.state.product.sku_information];
    if ((e.target.name === "discount" || e.target.name === "discount_value" || e.target.name === "special_price" || e.target.name === "price")) {
      sku_information[index] = this.calculateSkuPrice(e, sku_information[index])
    } else {
      sku_information[index][e.target.name] = e.target.value;
    }

    const product = {...this.state.product, sku_information};
    this.setState({product}, () => {
      //console.log(this.state.product);
    })
  }

  handleAddSkuImages = async (index, images) => {
    const sku_information = [...this.state.product.sku_information];
    sku_information[index].images = images;
    const product = {...this.state.product, sku_information};
    this.setState({product});
  }

  handleFaqInputOnChange = (e) => {
    const faq = {...this.state.faq, [e.target.name]: e.target.value};
    this.setState({faq});
  }

  handleAddFaq = () => {
    const faq = this.state.product?.faq || [];

    if (this.state.faq.question && this.state.faq.answer) {
      faq.push(this.state.faq);
      const product = {...this.state.product, faq};
      this.setState({product}, () => {
        this.setState({faq: {}}, () => {
          document.getElementById('formAddFaq').reset();
        })
      })
    } else {
      alert('Invalid data')
    }
  }

  handleAddProduct = (e) => {
    e.preventDefault();

    activityLogPostData('was added new product','/products/list/',e.target.name)

    const form = e.currentTarget;
    const isAnyFieldNull = Object.values(this.state.attributes).findIndex(item => item.selectedOptions === undefined || item.selectedOptions === null);
    const isAttComboExist = this.state.product.sku_information.findIndex(item => item.attr_combo === undefined || item.attr_combo.length === 0);
    this.setState({attributeErrorMessage: "", attComboError: ""})

    if (form.checkValidity() === false ||
      (isAnyFieldNull > -1 && parseInt(this.state.product?.sku_type) === 2)) {
      e.preventDefault();
      e.stopPropagation();
      if (isAnyFieldNull > -1 && parseInt(this.state.product?.sku_type) === 2) {
        this.setState({attributeErrorMessage: "Attribute value required"})
      }
      if ((isAttComboExist > -1 || this.state.product.sku_information.length === 0) && parseInt(this.state.product?.sku_type) === 2) {
        console.log("this is showing");
        this.setState({attComboError: "Please create attribute and product variation for variant products"})
      }
      this.setState({isFormValidated: true})
    } else {
      this.setState({isSubmitting: true}, () => {
        const product = {...this.state.product}
        product.publish_status = 2
        // product.districts = this.state.selectedDistricts
        addProduct(product)
          .then(res => {
            this.props.history.push(`${process.env.PUBLIC_URL}/products/list`);
            toast.success(res.message);
          })
          .catch(errMsg => {
            toast.error(errMsg);
            this.setState({isSubmitting: false})
          });
      })
    }
  }

  handleFaqOnYesNoOnChange = (e) => {
    const product = {...this.state.product}
    if (e.target.value === 'yes') {
      product.gift_wrap_available = true;
      product.gift_wrap_cost = null;
    } else {
      product.gift_wrap_available = false;
    }
    this.setState({product});
  }

  handleOnChangeBulkInput = (e, skuIndex, priceIndex) => {
    const product = {...this.state.product};
    product.sku_information[skuIndex].bulk_price[priceIndex][e.target.name] = e.target.value;
    this.setState({product});
  }

  handleAddBulkPricingRow = (index) => {
    const product = {...this.state.product};
    const priceObj = {
      min_qty: null,
      max_qty: null,
      price: null
    }
    product.sku_information[index].bulk_price.push(priceObj);
    this.setState({product});
  }

  handleIsBulkToggle = (e, index) => {
    const product = {...this.state.product}
    if (e.target.value === "yes") {
      product.sku_information[index].bulk_availability = true;
    } else {
      product.sku_information[index].bulk_availability = false;
      product.sku_information[index].bulk_price = [];
    }
    this.setState({product});
  }

  handleRemoveBulkPriceRow = (skuIndex, priceIndex) => {
    const product = {...this.state.product}
    product.sku_information[skuIndex].bulk_price.splice(priceIndex, 1);
    this.setState({product});
  }

  handleEditFaq = (index) => {
    const faq = this.state.product.faq[index];
    this.setState({faq: faq, editFaqIndex: index});
  }

  handleUpdateFaq = (index) => {
    const product = {...this.state.product};
    product.faq[index] = this.state.faq;
    this.setState({product, faq: {}, editFaqIndex: null}, () => {
      document.getElementById('formAddFaq').reset();
    })
  }

  handleDeleteFaq = (index) => {
    const product = {...this.state.product};
    product.faq.splice(index, 1);
    this.setState({product}, () => {
      console.log(this.state.product.faq);
    });
  }

  handleEditSKUInfo = (index) => {
    this.setState({
      editSKUIndex: index, isShowVariationModal: true,
      attrCombo: this.state.product.sku_information[index].attr_combo,
      attComboError: ""
    })
  }

  handleUpdateVariation = (index) => {
    const product = {...this.state.product};
    product.sku_information[index].attr_combo = this.state.attrCombo;
    this.setState({product, isShowVariationModal: false})
  }

  handleDeleteSku = (index) => {
    const product = {...this.state.product};
    product.sku_information.splice(index, 1);
    this.setState({product});
  }

  onChangeCollection = (valueArr) => {
    if (valueArr?.length > 0) {
      const product = {...this.state.product};
      let collectionArr = [];
      valueArr.forEach(value => {
        collectionArr.push(value.value)
      })
      product.collections = collectionArr;
      this.setState({product});
    }
  }

  handleOnChangeKeywordValue = (value) => {
    const product = {...this.state.product};
    const valueIdArr = [];
    if (value?.length > 0) {
      value.forEach(val => {
        valueIdArr.push(val.value);
      })
    }
    product.keywords = valueIdArr;
    this.setState({product});
  }

  handleOnRemoveAttributeImg = (index, otpIndex) => {
    const product = {...this.state.product}
    product.attributes[index].data[otpIndex].img = ''
    this.setState({product})
  }


  handleOnRemoveItem = (i) => {
    const images = [...this.state.images]
    const product = {...this.state.product}
    images.splice(i, 1)
    product.images.splice(i, 1)
    this.setState({images, product}, () => {
      console.log(this.state)
    })
  }

  // handleImageOnRemove = (index) => {
  //   const product = {...this.state.product}
  //   const mainImages = [...this.state.mainImages]
  //   product.images.splice(index, 1)
  //   mainImages.splice(index, 1)
  //   this.setState({
  //     product,
  //     mainImages
  //   })
  // }

  handleSkuImageOnRemove = (skuIndex, index) => {
    const product = {...this.state.product}
    product.sku_information[skuIndex].images.splice(index, 1)
    this.setState({product})
  }

  handleGetMerchantList = (string) => {
    if (string.length > 2) {
      getMerchantList({limit: 10, search_string: string})
        .then(res => {
          console.log('merchant list',res)
          const merchantList = [];
          res.forEach(item => {
            const merchant = {
              id: item._id,
              label: item.name,
              value: item._id
            }
            merchantList.push(merchant);
          })
          this.setState({merchants: res, merchantList})
        })
    }
  }

  shipLocationOnChanges = (value, type) => {
    if (type === 'district') {
      if (value === null) {
        this.setState({
          selectedDistricts: []
        })
      } else {
        this.setState({
          selectedDistricts: value.map(item => item.value)
        })
      }
    }
  }
  
  getMerchantDetails =(id)=>{
    //let product = {...this.state.product}
    getTableData(`/merchant/${id}`, {}).then(res => {
      console.log(res)
      const districtList = [];
      if (res.item?.district_brand && res.item?.district_brand.length > 0){
        res.item.district_brand.forEach(item => {
          const district = {
            id: item.id,
            value: item.id,
            label: item.label
          }
          districtList.push(district)
        })
      }
      //product.district_brand = districtList
      this.setState({district_brand: districtList})
    })
  }

  handleSelectMultiOnChange = (val, name) => {
    const product = {...this.state.product};
    const data = [];
    if (val) {
        data.push({id:val.id, label:val.label,value:val.id})
    }
    product[name] = data;
    this.setState({product:product})
  }

  render() {
    console.log(this.state.product)
    return (
      <App layout="boxed">
        {getLocalAuthData()?.permission?.includes('product_add_') ?
          <>
            <div className="page-header">
              <h2 className="page-title">Add Product</h2>
              <div className="ml-auto">
                <Link to={`${process.env.PUBLIC_URL}/products/list`}
                      className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Product List</Link>
              </div>
            </div>
            <div className="main-content">
              {this.state.isLoadingData ?
                <div className={`card-block`}>
                  <div className={`block-body`}>Loading...</div>
                </div>
                :
                <Form
                  noValidate
                  validated={this.state.isFormValidated}
                  onSubmit={this.handleAddProduct}>
                  <div className="card-block">
                    <div className="block-header">
                      <h4 className="block-title">What You're Selling</h4>
                    </div>

                    <div className="block-body">


                      <Form.Group as={Row} controlId={'productSettings'}>
                        <Form.Label column sm="3">
                          Product Setting
                        </Form.Label>
                        <Col sm="2" md={8} lg={7}>
                          <Form.Check
                            type="checkbox"
                            id="campaignProduct"
                            label="Campaign product"
                            name={"campaign_product"}
                            onChange={this.handleInputOnChange}
                            checked={this.state?.product?.campaign_product}
                            custom
                          />
                          <Form.Check
                            type="checkbox"
                            id="isFeatured"
                            label="Make Featured Product"
                            name={"is_featured"}
                            onChange={this.handleInputOnChange}
                            checked={this.state?.product?.is_featured}
                            custom
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formMerchant">
                        <Form.Label column sm="3">
                          Merchant <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Select
                            styles={styleReactSelect}
                            placeholder="Type for Select Merchant..."
                            onInputChange={this.handleGetMerchantList}
                            onChange={(value) => {
                              this.onMerchantChange(value.value);
                              this.getMerchantDetails(value.value);}
                            }
                            options={this.state.merchantList}
                            name="attributes"
                            noOptionsMessage={() => 'Please insert new value...'}
                          />
                        </Col>
                      </Form.Group>


                      {/*District Branding*/}
                      <Form.Group as={Row} controlId="forStores">
                        <Form.Label column sm="3">
                          District Brand
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Select
                              isMulti={false}
                              /*value={this.state.formData.district_brand}*/
                              styles={styleReactSelect}
                              placeholder="Select District..."
                              onChange={(value) => {
                                this.handleSelectMultiOnChange(value, 'district_brand')
                              }}
                              options={this.state.district_brand}
                              name="attributes"
                              noOptionsMessage={() => 'No district found'}
                          />
                        </Col>
                      </Form.Group>



                      <Form.Group as={Row} controlId="formStore">
                        <Form.Label column sm="3">
                          Store <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Select
                            styles={styleReactSelect}
                            placeholder="Type for Select store..."
                            onChange={(value) => this.createTableSelectOnChange(value, 'store')}
                            options={this.state.storeList}
                            name="attributes"
                            noOptionsMessage={() => 'Please insert new value...'}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formName">
                        <Form.Label column sm="3">
                          Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control type="text"
                                        required
                                        onChange={(e) => {
                                          this.handleNameOnChange(e, 0, 'en')
                                        }}
                                        name="name" placeholder=""/>
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid product name.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formCategory">
                        <Form.Label column sm="3">
                          Category <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <ChooseCategory
                            selectedCategories={[this.state.selectedCategories]}
                            onConfirm={this.onConfirmCategory}/>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formBrand">
                        <Form.Label column sm="3">
                          Brand <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <CreatableSelect
                            // isMulti
                            // value={this.state.attribute}
                            styles={styleReactSelect}
                            placeholder="Select Brand..."
                            onChange={(value) => this.createTableSelectOnChange(value, 'brand')}
                            options={this.state.brands}
                            name="attributes"
                            noOptionsMessage={() => 'Please type to select or create Brand...'}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formModel">
                        <Form.Label column sm="3">
                          Model
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control name="model"
                                        onChange={this.handleInputOnChange}
                                        type="text" placeholder=""/>
                        </Col>
                      </Form.Group>
                    </div>
                  </div>


                  <div className="card-block">
                    <div className="block-header">
                      <h4 className="block-title">Muktopaath Section</h4>
                    </div>

                    <div className="block-body">
                      <Form.Group as={Row} controlId={'productSettings'}>
                        <Form.Label column sm="3">
                          Muktopaath Product
                        </Form.Label>
                        <Col sm="2" md={8} lg={7}>
                      <Form.Check
                          type="checkbox"
                          id="isMuktopaath"
                          label="Muktopaath Product"
                          name={"muktopaath_product"}
                          onChange={this.handleInputOnChange}
                          checked={this.state?.product?.muktopaath_product}
                          custom
                      /></Col></Form.Group>


                      <Form.Group as={Row} controlId="formVideoUrl">
                        <Form.Label column sm="3">
                          Maximum buy by using muktopaath wallet
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control name="max_buy_by_muktopaath_wallet"
                                        onChange={this.handleInputOnChange}
                                        type="number"/>
                        </Col>
                      </Form.Group>

                    </div>
                  </div>


                  <div className="card-block">
                    <div className="block-header">
                      <h4 className="block-title">Basic Information</h4>
                    </div>

                    <div className="block-body">
                      <Form.Group as={Row} controlId="formModel">
                        <Form.Label column sm="3">
                          Tags
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <CreatableSelect
                            isMulti
                            styles={styleReactSelect}
                            placeholder="Insert keyword & press enter"
                            onChange={this.handleOnChangeKeywordValue}
                            noOptionsMessage={() => 'Insert keyword & press enter'}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formHighlight">
                        <Form.Label column sm="3">
                          Highlights <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <CKEditor
                            editor={ClassicEditor}
                            data={``}
                            onInit={editor => {
                              editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
                                return new UploadAdapter(loader);
                              };
                            }}
                            config={{
                              image: {
                                resizeUnit: 'px',
                                toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],
                                styles: [
                                  'full',
                                  'alignLeft',
                                  'alignRight'
                                ]
                              }
                            }
                            }
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              this.handleCkEditorOnChange('highlights', data);
                            }}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="productDescription">
                        <Form.Label column sm="3">
                          Product Description <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <CKEditor
                            editor={ClassicEditor}
                            data={``}
                            onInit={editor => {
                              editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
                                return new UploadAdapter(loader);
                              };
                            }}
                            config={{
                              image: {
                                resizeUnit: 'px',
                                toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],
                                styles: [
                                  'full',
                                  'alignLeft',
                                  'alignRight'
                                ]
                              }
                            }
                            }
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              this.handleProductDescOnChange('product_description', data, 0, 'en');
                            }}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formWarrantyType">
                        <Form.Label column sm="3">
                          Warranty Type <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Check
                            custom
                            inline
                            type="radio"
                            name="warranty_type"
                            id={`custom-radio-1`}
                            required
                            onChange={this.handleInputOnChange}
                            value={`International Manufacturer Warranty`}
                            label={`International Manufacturer Warranty`}
                          />
                          <Form.Check
                            custom
                            inline
                            type="radio"
                            name="warranty_type"
                            required
                            onChange={this.handleInputOnChange}
                            value={`Non-local warranty`}
                            id={`custom-radio-2`}
                            label={`Non-local warranty`}
                          />
                          <Form.Check
                            custom
                            inline
                            type="radio"
                            name="warranty_type"
                            required
                            onChange={this.handleInputOnChange}
                            value={`Local seller warranty`}
                            id={`custom-radio-3`}
                            label={`Local seller warranty`}
                          />
                          <Form.Check
                            custom
                            inline
                            type="radio"
                            name="warranty_type"
                            required
                            onChange={this.handleInputOnChange}
                            value={`No Warranty`}
                            id={`custom-radio-4`}
                            label={`No Warranty`}
                          />
                          <Form.Check
                            custom
                            inline
                            type="radio"
                            required
                            name="warranty_type"
                            onChange={this.handleInputOnChange}
                            value={`International Seller Warranty`}
                            id={`custom-radio-5`}
                            label={`International Seller Warranty`}
                          />

                          <Form.Control.Feedback type="invalid">
                            Please select a warranty type.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>


                      {this.state.product.warranty_type !== 'No Warranty' &&
                      <Form.Group as={Row} controlId="formWarrantyPeriod">
                        <Form.Label column sm="3">
                          Warranty Period
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <CreatableSelect
                            styles={styleReactSelect}
                            placeholder="Select Warranty Period..."
                            onChange={(value) => this.createTableSelectOnChange(value, 'warranty_period')}
                            options={this.state.warranty_periods}
                            name="warranty_period"
                            noOptionsMessage={() => 'Please insert new value...'}
                          />
                          {/*<Form.Control
                        required
                        name="warranty_period"
                        onChange={this.handleInputOnChange}
                        as="select" type="text">
                        <option value="">--Select--</option>
                        <option value="1">1 Month</option>
                        <option value="2">2 Months</option>
                        <option value="3">3 Months</option>
                        <option value="6">6 Months</option>
                        <option value="12">12 Months</option>
                        <option value="18">18 Months</option>
                        <option value="24">24 Months</option>
                        <option value="2.5">2.5 Years</option>
                        <option value="3">3 Years</option>
                        <option value="4">4 Years</option>
                        <option value="5">5 Years</option>
                        <option value="10">10 Years</option>
                      </Form.Control>*/}

                          <Form.Control.Feedback type="invalid">
                            Please select a warranty period.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                      }

                      <Form.Group as={Row} controlId="formWarrantyPolicy">
                        <Form.Label column sm="3">
                          Warranty Policy
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control
                            onChange={this.handleInputOnChange}
                            name="warranty_policy" type="text"/>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formDangerousGoods">
                        <Form.Label column sm="3">
                          Dangerous Goods <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Check
                            custom
                            inline
                            required
                            type="radio"
                            name="dangerous_goods"
                            onChange={this.handleInputOnChange}
                            value={`Battery`}
                            id={`custom-1`}
                            label={`Battery`}
                          />
                          <Form.Check
                            custom
                            inline
                            type="radio"
                            required
                            name="dangerous_goods"
                            onChange={this.handleInputOnChange}
                            value={`Liquid`}
                            id={`custom-2`}
                            label={`Liquid`}
                          />
                          <Form.Check
                            custom
                            inline
                            type="radio"
                            required
                            name="dangerous_goods"
                            onChange={this.handleInputOnChange}
                            value={`None`}
                            id={`custom-3`}
                            label={`None`}
                          />
                          <Form.Check
                            custom
                            inline
                            type="radio"
                            required
                            name="dangerous_goods"
                            onChange={this.handleInputOnChange}
                            value={`Flammable`}
                            id={`custom-4`}
                            label={`Flammable`}
                          />

                          <Form.Control.Feedback type="invalid">
                            Please select one.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formImages">
                        <Form.Label column sm="3">
                          Images <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          {/*<Form.Control type="file"/>*/}

                          <BrowseFiles
                            userId={this.state.merchant}
                            image_category={'product'}
                            onRemoveItem={this.handleOnRemoveItem}
                            images={this.state.images}
                            onGetFiles={this.handleAddProductImages}
                            helpText="Multiple images can be uploaded at once.Maximum 8 pictures. Size between 330x330 and 800x800 px. Obscene image is strictly prohibited."/>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formInTheBox">
                        <Form.Label column sm="3">
                          What's in the box <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control name="box_content"
                                        required
                                        onChange={this.handleInputOnChange}
                                        type="text"/>

                          <Form.Control.Feedback type="invalid">
                            Please provide valid information.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formPackageWeight">
                        <Form.Label column sm="3">
                          Package Weight <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <div className="input-group">
                            <Form.Control as={`select`}
                                          onChange={this.handleInputOnChange}
                                          style={{width: '100px', flex: '0 0 100px'}}
                                          name={`weight_unit`}>
                              <option value="1">Gram</option>
                              <option value="2">KG</option>
                              <option value="2">LITTER</option>
                            </Form.Control>
                            <Form.Control
                              name="weight"
                              required
                              step="0.01" min={1}
                              onChange={this.handleInputOnChange}
                              type="number"/>
                          </div>

                          <Form.Control.Feedback type="invalid">
                            Please provide valid weight.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formPackageDimensions">
                        <Form.Label column sm="3">
                          Package Dimensions (cm) <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="3" md={2} lg={2}>
                          <Form.Control
                            name="box_height"
                            required
                            step="0.01" min={1}
                            onChange={this.handleInputOnChange}
                            placeholder="Box height"
                            type="number"/>
                          <Form.Control.Feedback type="invalid">
                            Please provide valid height.
                          </Form.Control.Feedback>
                        </Col>
                        <Col sm="3" md={2} lg={2}>
                          <Form.Control
                            name="box_length"
                            required
                            step="0.01" min={1}
                            onChange={this.handleInputOnChange}
                            placeholder="Box length"
                            type="number"/>

                          <Form.Control.Feedback type="invalid">
                            Please provide valid length.
                          </Form.Control.Feedback>
                        </Col>
                        <Col sm="3" md={2} lg={2}>
                          <Form.Control
                            name="box_width"
                            required
                            step="0.01" min={1}
                            onChange={this.handleInputOnChange}
                            placeholder="Box width"
                            type="number"/>

                          <Form.Control.Feedback type="invalid">
                            Please provide valid length.
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formVideoUrl">
                        <Form.Label column sm="3">
                          Maximum buy by using muktopaath wallet
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control name="max_buy_by_muktopaath_wallet"
                                        onChange={this.handleInputOnChange}
                                        type="numbers"/>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formVideoUrl">
                        <Form.Label column sm="3">
                          Video URL
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control name="video_url"
                                        onChange={this.handleInputOnChange}
                                        type="text"/>
                        </Col>
                      </Form.Group>
                    </div>
                  </div>

                  {/*<div className="card-block">
                    <div className="block-header">
                      <h4 className="block-title">Ship location information</h4>
                    </div>

                    <div className="block-body">
                      <Form.Group as={Row} controlId="selectedDistricts">
                        <Form.Label column sm="3">Districts</Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Select
                            isMulti
                            options={this.state.districts}
                            defaultValue={this.state.selectedDistricts}
                            styles={styleReactSelect}
                            placeholder="Insert keyword & press enter"
                            onChange={(value) => {
                              this.shipLocationOnChanges(value, 'district')
                            }}
                            noOptionsMessage={() => 'Insert keyword & press enter'}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                  </div>*/}

                  {this.state.kpiAttr.length > 0 &&
                  <div className="card-block">
                    <div className="block-header">
                      <h4 className="block-title">Key Product Information</h4>
                    </div>

                    <div className="block-body">
                      {this.state.kpiAttr.map((kpi, index) => (
                        <React.Fragment key={index}>
                          {this.renderKpiSection(kpi, index)}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                  }

                  <div className="card-block">
                    <div className="block-header">
                      <h4 className="block-title">SKU Type</h4>
                    </div>
                    <div className="block-body">
                      <Form.Check
                        custom
                        inline
                        required
                        type="radio"
                        defaultChecked={this.state.product?.sku_type === "1"}
                        name="sku_type"
                        id={`sku-type-1`}
                        onChange={this.handleInputOnChange}
                        value={1}
                        label={`Simple Product`}
                      />
                      <Form.Check
                        custom
                        inline
                        type="radio"
                        required
                        defaultChecked={this.state.product?.sku_type === "2"}
                        name="sku_type"
                        id={`sku-type-2`}
                        onChange={this.handleInputOnChange}
                        value={2}
                        label={`Variable Product`}
                      />

                      <Form.Control.Feedback type="invalid">
                        Please please select a type.
                      </Form.Control.Feedback>
                    </div>
                  </div>

                  {this.state.product?.sku_type !== '1' ?
                    <>
                      <div className="card-block">
                        <div className="block-header">
                          <h4 className="block-title">Attribute</h4>
                        </div>

                        <div className="block-body">
                          {this.state.attributes.length > 0 &&
                          <>
                            <table className="table table-striped">
                              <thead>
                              <tr>
                                <th style={{width: '3%'}} className="nowrap">Attribute Name</th>
                                <th>Value</th>
                                <th style={{width: '50px'}}/>
                              </tr>
                              </thead>
                              <tbody>
                              {this.state.attributes.map((attr, index) => (
                                <React.Fragment key={attr.id}>
                                  <tr>
                                    <th>{attr.label}</th>
                                    <td>
                                      <CreatableSelect
                                        isMulti
                                        styles={styleReactSelect}
                                        placeholder="Select value..."
                                        onChange={(newValue) => {
                                          this.handleAttrOptionOnChange(index, newValue);
                                          console.log('Hello: ', attr.attr_options)
                                        }}
                                        options={this.arrayToSelectObj(attr?.attr_options)}
                                        noOptionsMessage={() => 'Please insert new value...'}
                                      />
                                      {attr.selectedOptions ? null : <small
                                        style={{color: this.state.attributeErrorMessage ? "red" : "orange"}}>Please
                                        enter the required value</small>}
                                    </td>
                                    <td>
                                  <span className="pointer-event"
                                        onClick={() => this.handleRemoveAttr(index, attr)}><IoMdClose
                                    style={{display: 'block'}}/></span>
                                    </td>
                                  </tr>
                                  {attr.selectedOptions &&
                                  <tr>
                                    <th className="pt-0 pb-0"/>
                                    <td className="pt-0 pb-0" colSpan={2}>
                                      <table className="info-table">
                                        <tbody>
                                        {attr.selectedOptions.map((option, optIndex) => (
                                          <tr key={optIndex}>
                                            <td style={{width: '100px'}} className="nowrap">{option.label}</td>
                                            <td style={{width: '30px'}} className="text-center">:</td>
                                            <td>
                                              <BrowseFiles
                                                userId={this.state.merchant}
                                                isSingle={true}
                                                image_category={'product'}
                                                onRemoveItem={() => this.handleOnRemoveAttributeImg(index, optIndex)}
                                                images={this.state.product?.attributes[index]?.data[optIndex]?.img !== '' ? [{thumb_img: this.state.product?.attributes[index]?.data[optIndex]?.img}] : []}
                                                onGetFiles={(images) => {
                                                  this.handleOptionsImageChange(index, optIndex, images)
                                                }} size={"sm"}/>
                                            </td>
                                          </tr>
                                        ))}
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  }
                                </React.Fragment>
                              ))}
                              </tbody>
                            </table>
                            <hr/>
                          </>
                          }

                          <Form.Group as={Row}>
                            <Form.Label column sm="3">
                              Product Attribute
                            </Form.Label>

                            <Col sm="9" md={8} lg={7}>
                              <CreatableSelect
                                value={this.state.attribute}
                                styles={styleReactSelect}
                                placeholder="Select Attribute..."
                                onChange={this.handleAttrOnChange}
                                options={this.state.productAttrs}
                                name="attributes"
                                noOptionsMessage={() => 'Please insert new value...'}
                              />
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row}>
                            <Form.Label column sm="3"/>
                            <Col sm="9" md={8} lg={7}>
                              <Button disabled={!this.state.attribute}
                                      onClick={() => this.handleAddAttribute(this.state.attribute)}
                                      variant={"primary"}><FiPlus/> Add</Button>
                            </Col>
                          </Form.Group>

                        </div>
                      </div>

                      {this.state?.attComboError &&
                      <Alert variant={"danger"}>{this.state?.attComboError}</Alert>
                      }

                      {this.state.attributes.length > 0 &&
                      <>
                        <div className="card-block">
                          <Modal size={"sm"} show={this.state.isShowVariationModal}
                                 onHide={this.handleCloseVariationModal}>
                            <Modal.Header closeButton>
                              {this.state.editSKUIndex || this.state.editSKUIndex === 0 ?
                                <Modal.Title>Update Combination</Modal.Title>
                                :
                                <Modal.Title>Create Combination</Modal.Title>
                              }
                            </Modal.Header>
                            <Modal.Body>
                              {this.state.attributes.map((attr, index) => (
                                <Form.Group key={index}>
                                  {console.log("I am here", this.state.attributes)}

                                  <Form.Label>{attr.label}</Form.Label>
                                  <Form.Control onChange={(e) => this.handleComboOnChange(e, index)}
                                                defaultValue={this.state.editSKUIndex || this.state.editSKUIndex === 0 ? this.state.product?.sku_information[this.state.editSKUIndex]?.attr_combo[index] : ''}
                                                as="select">
                                    {attr.selectedOptions ?
                                      <>
                                        <option key={index}
                                                value="">--Select--
                                        </option>
                                        {attr.selectedOptions.map((option, index) => (
                                          <option key={index} value={option.label}>{option.label}</option>
                                        ))}
                                      </>
                                      :
                                      <option value="">No Option</option>
                                    }
                                  </Form.Control>
                                  {!attr.selectedOptions &&
                                  <small style={{color: "red"}}>Please add required attribute value</small>}
                                </Form.Group>
                              ))}
                            </Modal.Body>

                            <Modal.Footer>
                              <Button variant="default" size={"sm"} onClick={this.handleCloseVariationModal}>
                                Close
                              </Button>
                              {this.state.editSKUIndex || this.state.editSKUIndex === 0 ?
                                <Button variant="primary" size={"sm"}
                                        onClick={() => this.handleUpdateVariation(this.state.editSKUIndex)}>
                                  Update Variation
                                </Button>
                                :
                                <Button variant="primary" size={"sm"} onClick={this.handleAddVariation}>
                                  Add Variation
                                </Button>
                              }
                            </Modal.Footer>
                          </Modal>

                          <div className="block-header">
                            <h3 className="block-title">Variations</h3>
                            <Button onClick={this.handleShowVariationModal} variant={"secondary"} size={"sm"}>+ Add
                              Variation</Button>
                          </div>

                          <div className="block-body">
                            {this.state.product?.sku_information?.length > 0 ?
                              this.state.product?.sku_information.map((sku, index) => (
                                <div key={index} className="variation-card">
                                  <div className="variation-header">
                                    <div className="">
                                      <h4 className="variation-title">{sku?.attr_combo?.join('-')}</h4>

                                      <Form.Check
                                        custom
                                        inline
                                        required
                                        checked={sku.is_default}
                                        onChange={() => this.handleSetDefaultSku(index)}
                                        type="radio"
                                        name="variations"
                                        id={`variation-${index}`}
                                        label={`Make Default`}
                                      />
                                    </div>

                                    <div className="variation-actions">
                              <span className="action mr-3 text-info"
                                    onClick={() => this.handleEditSKUInfo(index)}
                                    style={{cursor: "pointer"}}>Edit</span>
                                      <span className="action text-danger"
                                            onClick={() => this.handleDeleteSku(index)}
                                            style={{cursor: "pointer"}}>Delete</span>
                                    </div>
                                  </div>

                                  <div className="variation-body">
                                    <Form.Group as={Row}>
                                      <Form.Label name="sku" column sm="3">
                                        SKU <span className="text-danger">*</span>
                                      </Form.Label>

                                      <Col sm="9" md={9} lg={6}>
                                        <Form.Control size={"sm"}
                                                      name="sku"
                                                      required
                                                      onChange={(e) => this.handleSKUInputOnChange(e, index)}
                                                      type={"text"}/>
                                      </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                      <Form.Label column sm="3">
                                        Quantity <span className="text-danger">*</span>
                                      </Form.Label>

                                      <Col sm="9" md={9} lg={6}>
                                        <Form.Control name="quantity" size={"sm"}
                                                      required
                                                      step="1" min={1}
                                                      onChange={(e) => this.handleSKUInputOnChange(e, index)}
                                                      type={"number"}/>
                                      </Col>
                                    </Form.Group>

                                    <Form.Group as={Row}>
                                      <Form.Label column sm="3"> Minimum Order Quantity <span
                                        className={`text-danger`}>*</span> </Form.Label>

                                      <Col sm="9" md={9} lg={6}>
                                        <Form.Control name="minimum_order_quantity"
                                                      required
                                                      onChange={(e) => this.handleSKUInputOnChange(e, index)}
                                                      size={"sm"}
                                                      type={"number"}
                                                      defaultValue={1}/>
                                      </Col>
                                    </Form.Group>

                                    <Form.Group as={Row}>
                                      <Form.Label column sm="3"> Maximum Order Quantity </Form.Label>

                                      <Col sm="9" md={9} lg={6}>
                                        <Form.Control name="maximum_order_quantity"
                                                      onChange={(e) => this.handleSKUInputOnChange(e, index)}
                                                      size={"sm"}
                                                      type={"number"} min={1}/>
                                      </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                      <Form.Label column sm="3">
                                        Price (BDT) <span className="text-danger">*</span>
                                      </Form.Label>

                                      <Col sm="9" md={9} lg={6}>
                                        <Form.Control name="price" size={"sm"}
                                                      required
                                                      step="0.5" min={1}
                                                      onChange={(e) => this.handleSKUInputOnChange(e, index)}
                                                      type={"number"}/>
                                      </Col>
                                    </Form.Group>

                                    <Form.Group as={Row}>
                                      <Form.Label column sm="3">
                                        Discounted Price (BDT)
                                      </Form.Label>

                                      <Col sm="9" md={9} lg={6}>
                                        <Form.Control name="special_price"
                                                      onChange={(e) => {
                                                        this.handleSKUInputOnChange(e, index)
                                                      }}
                                                      required={this.state.product?.campaign_product}
                                                      size={"sm"}
                                                      step="0.01"
                                                      type={"number"}
                                                      value={this.state.product?.sku_information[index]?.special_price}/>
                                      </Col>
                                    </Form.Group>

                                    <Form.Group as={Row}>
                                      <Form.Label column sm="3">
                                        Discount (%)
                                      </Form.Label>

                                      <Col sm="9" md={9} lg={6}>
                                        <Form.Control name="discount"
                                                      onChange={(e) => {
                                                        this.handleSKUInputOnChange(e, index);
                                                      }}
                                                      size={"sm"}
                                                      step="0.01"
                                                      type={"number"}
                                                      value={this.state.product?.sku_information[index]?.discount}/>
                                      </Col>
                                    </Form.Group>

                                    <Form.Group as={Row}>
                                      <Form.Label column sm="3">
                                        Discount value (BDT)
                                      </Form.Label>

                                      <Col sm="9" md={9} lg={6}>
                                        <Form.Control name="discount_value"
                                                      onChange={(e) => {
                                                        this.handleSKUInputOnChange(e, index)
                                                      }}
                                                      size={"sm"}
                                                      step="0.01"
                                                      type={"number"}
                                                      value={this.state.product?.sku_information[index]?.discount_value}/>
                                      </Col>
                                    </Form.Group>

                                    <Form.Group as={Row}>
                                      <Form.Label column sm="3">
                                        Images
                                      </Form.Label>

                                      <Col sm="9" md={9} lg={9}>
                                        <BrowseFiles
                                          userId={this.state.merchant}
                                          size={"sm"}
                                          image_category={'product'}
                                          onRemoveItem={(i) => this.handleSkuImageOnRemove(index, i)}
                                          images={this.state.product.sku_information[index].images}
                                          onGetFiles={(files) => this.handleAddSkuImages(index, files)}/>
                                      </Col>
                                    </Form.Group>

                                    <hr/>
                                    <Form.Group as={Row} controlId="bulkVariant">
                                      <Form.Label column sm="3">
                                        Bulk Request Available
                                      </Form.Label>

                                      <Col sm="9" md={9} lg={9}>
                                        <Form.Check
                                          custom
                                          inline
                                          value={"yes"}
                                          checked={this.state.product.sku_information[index].bulk_availability}
                                          onChange={(e) => this.handleIsBulkToggle(e, index)}
                                          type="radio"
                                          name={`bulk_available${index}`}
                                          id={`isBulkAvailableYes${index}`}
                                          label={`Yes`}
                                        />

                                        <Form.Check
                                          custom
                                          inline
                                          value={"no"}
                                          checked={!this.state.product.sku_information[index].bulk_availability}
                                          onChange={(e) => this.handleIsBulkToggle(e, index)}
                                          type="radio"
                                          name={`bulk_available${index}`}
                                          id={`isBulkAvailableNo${index}`}
                                          label={`No`}
                                        />
                                      </Col>
                                    </Form.Group>

                                    {this.state.product.sku_information[index].bulk_availability &&
                                    <Form.Group as={Row} controlId="bulkVariant">
                                      <Form.Label column sm="3">
                                        Bulk Quantity
                                      </Form.Label>
                                      <Col sm="9" md={9} lg={9}>
                                        <table className="table-info" style={{width: '100%'}}>
                                          <tbody>
                                          {this.state.product?.sku_information[index]?.bulk_price.map((price, i) => (
                                            <tr key={i}>
                                              <td><Form.Control size={"sm"} type={"number"}
                                                                name={"min_qty"}
                                                                step="1" min={1}
                                                                onChange={(e) => this.handleOnChangeBulkInput(e, index, i)}
                                                                placeholder="Minimum quantity"/></td>
                                              <td><Form.Control size={"sm"} type={"number"}
                                                                name={"max_qty"}
                                                                step="1" min={1}
                                                                onChange={(e) => this.handleOnChangeBulkInput(e, index, i)}
                                                                placeholder="Maximum quantity"/></td>
                                              <td><Form.Control size={"sm"} type={"number"}
                                                                name={"price"}
                                                                step="0.5" min={1}
                                                                onChange={(e) => this.handleOnChangeBulkInput(e, index, i)}
                                                                placeholder="Price"/></td>
                                              <td style={{width: '20px', textAlign: 'center'}}>
                                    <span className="text-danger"
                                          style={{cursor: 'pointer'}}
                                          onClick={() => this.handleRemoveBulkPriceRow(index, i)}>&times;</span>
                                              </td>
                                            </tr>
                                          ))}
                                          </tbody>
                                        </table>
                                        <Button variant={"link"}
                                                onClick={() => this.handleAddBulkPricingRow(index)}
                                                size={"sm"}>
                                          {this.state.product?.sku_information[index]?.bulk_price.length > 0 ?
                                            <span>+ Add More</span>
                                            :
                                            <span>+ Add Pricing Row</span>
                                          }
                                        </Button>
                                      </Col>
                                    </Form.Group>
                                    }
                                  </div>
                                </div>
                              ))
                              :
                              <div className="alert alert-warning text-center">
                                <p>Please add variation</p>
                                <Button onClick={this.handleShowVariationModal} variant={"secondary"} size={"sm"}>+
                                  Add
                                  Variation</Button>
                              </div>
                            }
                          </div>
                        </div>
                      </>
                      }
                    </>
                    :
                    <div className="card-block">
                      <div className="block-header">
                        <h4 className="block-title">SKU Information</h4>
                      </div>
                      <div className="block-body">
                        <div className="variation-card">
                          <div className="variation-body">
                            <Form.Group as={Row}>
                              <Form.Label name="sku" column sm="3">
                                SKU <span className={`text-danger`}>*</span>
                              </Form.Label>

                              <Col sm="9" md={9} lg={6}>
                                <Form.Control size={"sm"}
                                              required
                                              onChange={(e) => this.handleSKUInputOnChange(e, 0)}
                                              name="sku"
                                              type={"text"}/>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Form.Label column sm="3">
                                Quantity <span className={`text-danger`}>*</span>
                              </Form.Label>

                              <Col sm="9" md={9} lg={6}>
                                <Form.Control name="quantity"
                                              required
                                              onChange={(e) => this.handleSKUInputOnChange(e, 0)}
                                              size={"sm"} min={1}
                                              type={"number"}/>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Form.Label column sm="3">
                                Minimum Order Quantity <span className={`text-danger`}>*</span>
                              </Form.Label>

                              <Col sm="9" md={9} lg={6}>
                                <Form.Control name="minimum_order_quantity"
                                              required
                                              onChange={(e) => this.handleSKUInputOnChange(e, 0)}
                                              size={"sm"}
                                              type={"number"}
                                              defaultValue={1} min={1}/>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Form.Label column sm="3">
                                Maximum Order Quantity
                              </Form.Label>

                              <Col sm="9" md={9} lg={6}>
                                <Form.Control name="maximum_order_quantity"
                                              onChange={(e) => this.handleSKUInputOnChange(e, 0)}
                                              size={"sm"}
                                              type={"number"} min={1}/>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Form.Label column sm="3">
                                Price (BDT) <span className={`text-danger`}>*</span>
                              </Form.Label>

                              <Col sm="9" md={9} lg={6}>
                                <Form.Control name="price"
                                              required
                                              step="0.01"
                                              onChange={(e) => {
                                                this.handleSKUInputOnChange(e, 0)
                                              }}
                                              size={"sm"} min={1}
                                              type={"number"}/>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Form.Label column sm="3">
                                Discounted Price (BDT)
                              </Form.Label>

                              <Col sm="9" md={9} lg={6}>
                                <Form.Control name="special_price"
                                              onChange={(e) => {
                                                this.handleSKUInputOnChange(e, 0)
                                              }}
                                              required={this.state.product?.campaign_product}
                                              size={"sm"}
                                              step="0.01" min={0}
                                              type={"number"}
                                              value={this.state.product?.sku_information[0]?.special_price}/>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Form.Label column sm="3">
                                Discount (%)
                              </Form.Label>

                              <Col sm="9" md={9} lg={6}>
                                <Form.Control name="discount"
                                              onChange={(e) => {
                                                this.handleSKUInputOnChange(e, 0)
                                              }}
                                              size={"sm"}
                                              step="0.01" min={0}
                                              type={"number"}
                                              value={this.state.product?.sku_information[0]?.discount}/>
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Form.Label column sm="3">
                                Discount value (BDT)
                              </Form.Label>

                              <Col sm="9" md={9} lg={6}>
                                <Form.Control name="discount_value"
                                              onChange={(e) => {
                                                this.handleSKUInputOnChange(e, 0)
                                              }}
                                              size={"sm"}
                                              step="0.01" min={0}
                                              type={"number"}
                                              value={this.state.product?.sku_information[0]?.discount_value}/>
                              </Col>
                            </Form.Group>

                            {/*<Form.Group as={Row}>
                          <Form.Label column sm="3">
                            Images
                          </Form.Label>

                          <Col sm="9" md={9} lg={9}>
                            <BrowseFiles
                              image_category={'product'}
                              size={"sm"}
                              onRemoveItem={(i) => this.handleSkuImageOnRemove(0, i)}
                              images={this.state.product.sku_information[0].images}
                              onGetFiles={(files) => this.handleAddSkuImages(0, files)}/>
                          </Col>
                        </Form.Group>*/}

                            <hr/>

                            <Form.Group as={Row} controlId="bulkVariant">
                              <Form.Label column sm="3">
                                Bulk Request Available
                              </Form.Label>

                              <Col sm="9" md={9} lg={9}>
                                <Form.Check
                                  custom
                                  inline
                                  value={"yes"}
                                  onChange={(e) => this.handleIsBulkToggle(e, 0)}
                                  type="radio"
                                  name={`bulk_available`}
                                  id={`isBulkAvailableYes`}
                                  label={`Yes`}
                                />

                                <Form.Check
                                  custom
                                  inline
                                  value={"no"}
                                  onChange={(e) => this.handleIsBulkToggle(e, 0)}
                                  type="radio"
                                  name={`bulk_available`}
                                  id={`isBulkAvailableNo`}
                                  label={`No`}
                                />
                              </Col>
                            </Form.Group>

                            {this.state.product.sku_information[0].bulk_availability &&
                            <Form.Group as={Row} controlId="bulkVariant">
                              <Form.Label column sm="3">
                                Bulk Quantity <span className={`text-danger`}>*</span>
                              </Form.Label>
                              <Col sm="9" md={9} lg={9}>
                                <table className="table-info" style={{width: '100%'}}>
                                  <tbody>
                                  {this.state.product?.sku_information[0]?.bulk_price.map((price, i) => (
                                    <tr key={i}>
                                      <td><Form.Control size={"sm"} type={"number"}
                                                        name={"min_qty"}
                                                        required
                                                        onChange={(e) => this.handleOnChangeBulkInput(e, 0, i)}
                                                        placeholder="Minimum quantity"/></td>
                                      <td><Form.Control size={"sm"} type={"number"}
                                                        name={"max_qty"}
                                                        required
                                                        onChange={(e) => this.handleOnChangeBulkInput(e, 0, i)}
                                                        placeholder="Maximum quantity"/></td>
                                      <td><Form.Control size={"sm"} type={"number"}
                                                        name={"price"}
                                                        required
                                                        step="0.01"
                                                        onChange={(e) => this.handleOnChangeBulkInput(e, 0, i)}
                                                        placeholder="Price"/></td>
                                      <td style={{width: '20px', textAlign: 'center'}}>
                                    <span className="text-danger"
                                          style={{cursor: 'pointer'}}
                                          onClick={() => this.handleRemoveBulkPriceRow(0, i)}>&times;</span>
                                      </td>
                                    </tr>
                                  ))}
                                  </tbody>
                                </table>
                                <Button variant={"link"} onClick={() => this.handleAddBulkPricingRow(0)}
                                        size={"sm"}>
                                  {this.state.product?.sku_information[0]?.bulk_price.length > 0 ?
                                    <span>+ Add More</span>
                                    :
                                    <span>+ Add Pricing Row</span>
                                  }
                                </Button>
                              </Col>
                            </Form.Group>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  <div className="card-block">
                    <div className="block-header">
                      <h4 className="block-title">Gift Wrapping</h4>
                    </div>

                    <div className="block-body">
                      <Form.Group as={Row} controlId="giftWrapping">
                        <Form.Label column sm="3">
                          Gift Wrapping Available
                        </Form.Label>

                        <Col sm="9" md={9} lg={9}>
                          <Form.Check
                            custom
                            inline
                            value={"yes"}
                            type="radio"
                            checked={this.state.product.gift_wrap_available}
                            onChange={this.handleFaqOnYesNoOnChange}
                            name="gift_wrapping"
                            id={`isGiftWrappingYes`}
                            label={`Yes`}
                          />

                          <Form.Check
                            custom
                            inline
                            checked={!this.state.product.gift_wrap_available}
                            value={"no"}
                            type="radio"
                            onChange={this.handleFaqOnYesNoOnChange}
                            name="gift_wrapping"
                            id={`isGiftWrappingNo`}
                            label={`No`}
                          />
                        </Col>
                      </Form.Group>

                      {this.state.product.gift_wrap_available &&
                      <Form.Group as={Row} controlId="bulkVariant">
                        <Form.Label column sm="3">
                          Gift Wrapping Cost (৳) <span className="text-danger">*</span>
                        </Form.Label>

                        <Col sm="9" md={9} lg={6}>
                          <Form.Control
                            required
                            name="gift_wrap_cost"
                            onChange={this.handleInputOnChange}
                            type={"number"}/>
                        </Col>
                      </Form.Group>
                      }
                    </div>
                  </div>

                  <SelectCollection onChange={this.onChangeCollection}/>

                  <div className="card-block">
                    <div className="block-header">
                      <h4 className="block-title">FAQ</h4>
                    </div>

                    <div className="block-body">
                      {this.state.product?.faq?.length > 0 &&
                      <div className="q-a-list mb-4">
                        {this.state.product.faq.map((faq, i) => (
                          <div key={i} className="item">
                            <div className="question">
                              <span className="symbol">Q</span>
                              <span className="text">{faq.question}</span>
                            </div>

                            <div className="answer">
                              <span className="symbol">A</span>
                              <span className='text'>{faq.answer}</span>
                            </div>

                            <div className="q-a-actions">
                      <span
                        onClick={() => this.handleEditFaq(i)}
                        className="text-info">Edit</span>
                              <span onClick={() => this.handleDeleteFaq(i)} color="text-danger">Delete</span>
                            </div>
                          </div>
                        ))}
                      </div>
                      }

                      <Form id="formAddFaq">
                        <Form.Group controlId="faqQ">
                          <Form.Label srOnly>Question</Form.Label>
                          <Form.Control
                            name="question"
                            defaultValue={this.state.faq?.question}
                            onChange={this.handleFaqInputOnChange}
                            type="text" placeholder="Question"/>
                        </Form.Group>

                        <Form.Group controlId="faqA">
                          <Form.Label srOnly>Answer</Form.Label>
                          <Form.Control
                            name="answer"
                            defaultValue={this.state.faq?.answer}
                            onChange={this.handleFaqInputOnChange} placeholder="Answer"/>
                        </Form.Group>

                        {this.state.editFaqIndex || this.state.editFaqIndex === 0 ?
                          <Button onClick={() => this.handleUpdateFaq(this.state.editFaqIndex)}
                                  variant={"secondary"}>Update FAQ</Button>
                          :
                          <Button onClick={this.handleAddFaq} variant={"secondary"}>Add FAQ</Button>
                        }
                      </Form>
                    </div>
                  </div>

                  <div className="card-block">
                    <div className="block-body text-right">
                      <Link to={`/products/list`} className={`btn btn-lg btn-default mr-2`}>Cancel</Link>
                      <Button size="lg" className="mr-3"
                              onClick={() => {
                                this.setState({isDrafting: true}, () => {
                                  const product = {...this.state.product}
                                  product.publish_status = 1
                                  addProduct(product)
                                    .then(res => {
                                      this.props.history.push(`${process.env.PUBLIC_URL}/products/list`);
                                      toast.success(res.message);
                                    })
                                    .catch(errMsg => {
                                      toast.error(errMsg);
                                      this.setState({isDrafting: false})
                                    });
                                })
                              }}
                              disabled={this.state.isDrafting}
                              variant="outline-primary">{this.state.isDrafting ? 'Saving to Draft...' : 'Save Draft'}</Button>

                      <Button size="lg"
                              type={`submit`}
                              disabled={this.state.isSubmitting}
                              variant="primary">{this.state.isSubmitting ? 'Publishing...' : 'Publish'}</Button>
                    </div>
                  </div>
                </Form>
              }
            </div>
          </> : null}
      </App>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mediaFiles: state.fileManager.files
  }
}

export default connect(mapStateToProps)(withRouter(AddProduct));
