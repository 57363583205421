import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {getChildLocations, getLocationList} from "../../services/locationServices";
import BrowseFiles from "../../components/BrowseFiles";
import {getMerchantType} from "../../services/merchantTypeServices";
import {getMerchantDetails, updateProfile} from "../../services/merchantServices";
import {toast} from "react-toastify";
import {getLocalAuthData} from "../../helper/utils";
import {activityLogPostData, checkIsExisting, getTableData} from "../../services/commonServices";
import {getBankList} from "../../services/settingServices";
import Select from "react-select";
import styleReactSelect from "../../assets/styleReactSelect";

class Edit extends Component {
  state = {
    activeBlockTab: 'General',
    countries: [],
    businessChildLocations: [],
    merchantTypes: [],
    formData: {
      bank_info: {}
    },
    isLoading: true,
    merchant_id: null,
    isUpdating: false,
    merchantInfo: null,
    isFormValidated: false,
    phoneExistingStatus: 2,
    phoneExistingMsg: null,
    emailExistingStatus: 2,
    emailExistingMsg: null,
    businessNameExistingStatus: 2,
    businessNameExistingMsg: null,
    splittedPhone: ['', ''],
    bankList: [],
    branchNameList: []
  };

  componentDidMount() {
    const merchantId = this.props.match.params.id;
    const authData = getLocalAuthData();
    this.setState({merchant_id: authData?.merchant_id})

    getTableData('/district', {lite_data: true}).then(res => {
      const districtList = [];
      res.items.forEach(item => {
        const district = {
          id: item._id,
          value: item._id,
          label: item.name
        }
        districtList.push(district)
      })
      this.setState({districtList})
    })

    getLocationList()
      .then(res => {
        this.setState({countries: res})
      })

    getMerchantType()
      .then(res => {
        this.setState({merchantTypes: res}, () => {
          console.log(this.state.merchantTypes);
        })
      })

    getBankList()
      .then(res => {
        this.setState({bankList: res})
        this.getMerchantDetails()
      })
      .catch(errMsg => {
        console.log(errMsg)
        this.getMerchantDetails()
      })

    setTimeout(() => {
      console.log(this.state.formData)
    }, 2000)
  }

  getMerchantDetails = () => {
    const merchantId = this.props.match.params.id;
    getMerchantDetails(merchantId)
      .then(res => {

        let attributes = [];
        if (res.district_brand) {
          res.district_brand.forEach(function (entry) {
            attributes.push({value: entry.label, label: entry.label});
          });
        }

        const formData = {
          ...this.state.formData,
          district_brand: attributes,
          user: res?.user_info?._id || null,
          merchant_type: res.merchant_type || null,
          owner_name: res.owner_name || null,
          name: res.name || null,
          phone: res.user_info.phone || null,
          phone_verified: res.phone_verified || true,
          email: res.user_info.email || null,

          id_type: res.id_type || null,
          identification_number: res.identification_number || null,
          id_front_img: res.id_front_img || null,
          id_back_img: res.id_back_img || null,

          latitude: res?.latitude || '',
          longitude: res?.longitude || '',

          bank_info: {
            bank_account_branch: res?.bank_info?.bank_account_branch,
            bank_account_cheque_img: res?.bank_info?.bank_account_cheque_img,
            bank_account_name: res?.bank_info?.bank_account_name,
            bank_account_number: res?.bank_info?.bank_account_number,
            bank_routing_number: res?.bank_info?.bank_routing_number,
            bank_name: res?.bank_info?.bank_name
          },
          is_flagship: res?.is_flagship || false,
          ecommerce_partner: res?.ecommerce_partner || false,
          commission_available_for: res?.commission_available_for || {
            admin: true,
            merchant: true,
          },

          business_registration_number: res?.business_registration_number || null,
          store_name: res?.store_name || null,
          category: res?.category || null,
          store_logo: res?.store_logo || null,
          business_type: res?.business_type || null,
          address: res?.address || null,
          country: res?.country || null,
          division: res?.division || null,
          district: res?.district || null,
          upazila: res?.upazila || null,
          union: res?.union || null,
          province: res.province || null,
          city: res?.city || null,
          postcode: res?.postcode || null,
          legal_form: res?.legal_form,
          state: res.state,
        }

        res.locations.forEach((item, index) => {
          getChildLocations(item._id)
            .then(res => {
              const businessChildLocations = [...this.state.businessChildLocations]
              businessChildLocations[index] = res
              this.setState({businessChildLocations})
            })
            .catch(errMsg => {
              console.log(errMsg)
            })
        })

        this.setState({
          formData,
          locations: res.locations,
          splittedPhone: res.user_info.phone.split('-'),
          userId: res?.user_info?._id,
          isLoading: false,
        }, () => {
          const bank = this.state.bankList.filter(obj => {
            return obj._id === this.state.formData.bank_info.bank_name
          })

          if (bank.length > 0) {
            this.setState({branchNameList: bank[0].branches})
          } else {
            this.setState({branchNameList: []})
          }
        })
      })
      .catch(errMsg => {
        alert(errMsg);
        this.setState({isLoading: false});
      })
  }

  handleBlockTab = label => {
    this.setState({
      activeBlockTab: label
    })
  };

  handleLocationInputOnChange = (e, index, typePurpose) => {
    const childLocations = [...this.state[typePurpose]];
    this.handleInputOnChange(e);
    getChildLocations(e.target.value)
      .then(res => {
        if (res.length > 0) {
          childLocations[index] = res
          this.setState({[typePurpose]: childLocations})
        } else {
          childLocations.splice(index, childLocations.length - index);
          this.setState({[typePurpose]: childLocations})
        }
      })
      .catch(errMsg => {
        console.log(errMsg)
      })
  }

  handleInputOnChange = (e) => {
    const formData = {...this.state.formData}
    formData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({formData})
  }

  handleCommissionAvailability = (e) => {
    const formData = {...this.state.formData}
    formData['commission_available_for'][e.target.name] = e.target.checked;
    this.setState({formData})
    console.log(this.state.formData);
  }

  handleBankInputOnChange = (e) => {
    const formData = {...this.state.formData}
    formData.bank_info[e.target.name] = e.target.value;
    this.setState({formData})
  }

  handleSubmitForm = e => {
    e.preventDefault();
    const formData = {...this.state.formData};
    formData.phone = this.state.splittedPhone.join('-')

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({isFormValidated: true})
    } else {
      this.setState({formData, isUpdating: true}, () => {
        updateProfile(this.state.formData, this.props.match.params.id)
          .then(res => {
            this.setState({isUpdating: false, isFormValidated: false});
            toast.success(res.message)
            activityLogPostData('was edit this entrepreneurs','/entrepreneurs/edit/'+this.props.match.params.id ,e.taget.owner_name )
          })
          .catch(errMsg => {
            this.setState({isUpdating: false, isFormValidated: false});
            toast.error(errMsg);
          })
      })
    }
  }

  handleOnBrowsePic = (files, name) => {
    console.log('Files', files)
    console.log('Name', name)
    const formData = {...this.state.formData}
    formData[name] = files[0].actual_img;
    this.setState({formData})
  }

  handleBankOnBrowsePic = (files, name) => {
    console.log('Files', files)
    console.log('Name', name)
    const formData = {...this.state.formData}
    formData.bank_info[name] = files[0].actual_img;
    this.setState({formData})
  }

  handleRemoveImg = (key) => {
    const formData = {...this.state.formData};
    formData[key] = "";
    this.setState({formData});
  }

  handleRemoveBankImg = (key) => {
    const formData = {...this.state.formData};
    formData.bank_info[key] = "";
    this.setState({formData});
  }

  checkIsExisting = (type, data, msgKey, statusKey) => {
    checkIsExisting(type, data)
      .then(res => {
        if (res.status === 2) {
          this.setState({[msgKey]: res.message, [statusKey]: res.status})
        } else {
          this.setState({[msgKey]: null, [statusKey]: res.status})
        }
      })
      .catch(errMsg => {
        console.log(errMsg)
      })
  }

  inputPhoneOnChange = (e, index) => {
    const splittedPhone = [...this.state.splittedPhone]
    splittedPhone[index] = e.target.value
    this.setState({splittedPhone})
  }


  handleSelectMultiOnChange = (array, name) => {

    const formData = {...this.state.formData};
    const data = [];
    if (array) {
      array.forEach(val => {
        data.push({id:val.id, label:val.label,value:val.label})
      })
    }
    formData[name] = data;
    this.setState({formData})
  }





  render() {
    const {formData} = this.state;

    return (
      <App layout="boxed">
        {getLocalAuthData()?.permission?.includes('entrepreneurs_edit') ?
       <>
         <div className="page-header">
           <h2 className="page-title">Update Merchant</h2>
         </div>
         <div className="card-block">
           <div className="block-body">
             {this.state.isLoading ?
                 <div>Loading...</div>
                 :
                 <>
                   {this.state.activeBlockTab === 'General' &&
                   <Form noValidate validated={this.state.isFormValidated}
                         onSubmit={this.handleSubmitForm}>
                     <Form.Group as={Row} controlId="">
                       <Form.Label column sm="3"/>

                       <Col sm="9" md={8} lg={7}>
                         <strong>Seller Account</strong>
                       </Col>
                     </Form.Group>

                     {/*TODO: Have to check on it*/}
                     {/*<Form.Group as={Row} controlId="sellerID">
                    <Form.Label column sm="3">
                      Seller ID
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      {this.state.merchant_id}
                    </Col>
                  </Form.Group>*/}

                     <Form.Group as={Row} controlId="displayOrShopName">
                       <Form.Label column sm="3">
                         Merchant Type <span className="text-danger">*</span>
                       </Form.Label>
                       <Col sm="9" md={8} lg={7}>
                         {this.state.merchantTypes.length > 0 &&
                         this.state.merchantTypes.map((type, index) => {
                           // if (type.show_in_public) {
                           return (
                               <Form.Check
                                   key={index}
                                   custom
                                   required
                                   checked={type._id === formData?.merchant_type}
                                   type="radio"
                                   name="merchant_type"
                                   value={type._id}
                                   id={`merchantType-${index}`}
                                   label={type.type_name}
                                   onChange={this.handleInputOnChange}
                               />
                           )
                           // }
                           // return <></>
                         })
                         }
                         <Form.Control.Feedback type="invalid">Field required</Form.Control.Feedback>
                       </Col>
                     </Form.Group>

                     <Form.Group as={Row} controlId="nameId">
                       <Form.Label column sm="3">
                         Owner Name <span className="text-danger">*</span>
                       </Form.Label>
                       <Col sm="9" md={8} lg={7}>
                         <Form.Control
                             name={"owner_name"}
                             required
                             defaultValue={formData?.owner_name}
                             onChange={this.handleInputOnChange} type="text"/>
                         <Form.Control.Feedback type="invalid">Please enter owner
                           name</Form.Control.Feedback>
                       </Col>
                     </Form.Group>

                     <Form.Group as={Row} controlId="sellerEmail">
                       <Form.Label column sm="3">
                         Email Address <span className="text-danger">*</span>
                       </Form.Label>
                       <Col sm="9" md={8} lg={7}>
                         <Form.Control name={"email"}
                                       required
                                       defaultValue={formData?.email}
                                       onBlur={() => this.checkIsExisting('email', {
                                         email: this.state.formData.email,
                                         user_id: this.state?.userId
                                       }, 'emailExistingMsg', 'emailExistingStatus')}
                                       onChange={this.handleInputOnChange}
                                       type="email"/>

                         {this.state.emailExistingMsg && <Form.Text className={'text-danger'}>
                           {this.state.emailExistingMsg}
                         </Form.Text>}

                         <Form.Control.Feedback type="invalid">Please enter a valid email
                           address</Form.Control.Feedback>
                       </Col>
                     </Form.Group>

                     <Form.Group as={Row} controlId="phoneNumber">
                       <Form.Label column sm="3">
                         Phone Number <span className="text-danger">*</span>
                       </Form.Label>
                       <Col sm="9" md={8} lg={7}>
                         <div className="input-group">
                           <Form.Control as={'select'}
                                         name={'code'}
                                         required
                                         value={this.state?.splittedPhone ? this.state?.splittedPhone[0] : ''}
                                         onChange={e => this.inputPhoneOnChange(e, 0)}
                                         style={{
                                           width: `80px`,
                                           paddingRight: '10px',
                                           flex: '0 0 80px'
                                         }}>

                             <option value="">---</option>
                             {this.state.countries.map((country, index) => (
                                 <option key={index} value={country.code}>{country.code}</option>
                             ))}
                           </Form.Control>

                           <Form.Control required maxLength="10" minLength="10"
                                         onBlur={() => this.checkIsExisting('phone', {
                                           country_code: this.state.splittedPhone[0],
                                           user_id: this.state?.userId,
                                           phone: this.state.splittedPhone[1]
                                         }, 'phoneExistingMsg', 'phoneExistingStatus')}
                                         defaultValue={this.state?.splittedPhone[1]}
                                         onChange={e => this.inputPhoneOnChange(e, 1)}
                                         type="Phone number"/>
                         </div>

                         {this.state.phoneExistingMsg && <Form.Text className={'text-danger'}>
                           {this.state.phoneExistingMsg}
                         </Form.Text>}
                       </Col>
                     </Form.Group>

                     <Form.Group as={Row} controlId="">
                       <Form.Label column sm="3">
                         ID Type <span className="text-danger">*</span>
                       </Form.Label>

                       <Col sm="9" md={8} lg={7}>
                         <Form.Control
                             onChange={this.handleInputOnChange}
                             name={"id_type"}
                             required
                             value={formData?.id_type}
                             as={"select"}>0
                           <option value="">--Select--</option>
                           <option value="NID">NID</option>
                         </Form.Control>

                         <Form.Control.Feedback type="invalid">Please select ID
                           type</Form.Control.Feedback>
                       </Col>
                     </Form.Group>

                     <Form.Group as={Row} controlId="">
                       <Form.Label column sm="3">
                         Identification Number <span className="text-danger">*</span>
                       </Form.Label>

                       <Col sm="9" md={8} lg={7}>
                         <Form.Control name={'identification_number'}
                                       required
                                       defaultValue={formData?.identification_number}
                                       onChange={this.handleInputOnChange}
                                       type={"text"}/>

                         <Form.Control.Feedback type="invalid">Please enter Identification
                           Number.</Form.Control.Feedback>
                       </Col>
                     </Form.Group>



                     {/*District Branding*/}
                     <Form.Group as={Row} controlId="forStores">
                       <Form.Label column sm="3">
                         District Brand
                       </Form.Label>
                       <Col sm="9" md={8} lg={7}>
                         <Select
                             isMulti
                             value={this.state.formData.district_brand}
                             styles={styleReactSelect}
                             placeholder="Select District..."
                             onChange={(value) => {
                               this.handleSelectMultiOnChange(value, 'district_brand')
                             }}
                             options={this.state.districtList}
                             name="attributes"
                             noOptionsMessage={() => 'No district found'}
                         />
                       </Col>
                     </Form.Group>



                     <Form.Group as={Row} controlId="">
                       <Form.Label column sm="3">
                         Upload ID Copy - Front Side <span className="text-danger">*</span>
                       </Form.Label>

                       <Col sm="9" md={8} lg={7}>
                         <BrowseFiles onGetFiles={(files) => {
                           this.handleOnBrowsePic(files, 'id_front_img')
                         }} image_category={'profile'}/>

                         {this.state.formData.id_front_img &&
                         <div className="existing-images">
                           <div className="existing-img">
                          <span onClick={() => this.handleRemoveImg('id_front_img')}
                                className="btn-remove">&times;</span>
                             <img src={this.state.formData.id_front_img} alt=""/>
                           </div>
                         </div>}
                       </Col>
                     </Form.Group>

                     <Form.Group as={Row} controlId="">
                       <Form.Label column sm="3">
                         Upload ID Copy - Back Side <span className="text-danger">*</span>
                       </Form.Label>

                       <Col sm="9" md={8} lg={7}>
                         <BrowseFiles onGetFiles={(files) => {
                           this.handleOnBrowsePic(files, 'id_back_img')
                         }} image_category={'profile'}/>

                         {this.state.formData.id_back_img &&
                         <div className="existing-images">
                           <div className="existing-img">
                          <span onClick={() => this.handleRemoveImg('id_back_img')}
                                className="btn-remove">&times;</span>
                             <img src={this.state.formData.id_back_img} alt=""/>
                           </div>
                         </div>}
                       </Col>
                     </Form.Group>




                     {/*Business Information*/}
                     <Form.Group as={Row} controlId="displayOrShopName">
                       <Form.Label column sm="3"/>
                       <Col sm="9" md={8} lg={7}>
                         <strong>Business Information</strong>
                       </Col>
                     </Form.Group>

                     <Form.Group as={Row} controlId="displayOrShopName">
                       <Form.Label column sm="3">
                         Business Name <span className="text-danger">*</span>
                       </Form.Label>
                       <Col sm="9" md={8} lg={7}>
                         <Form.Control
                             name={"name"}
                             required
                             onBlur={() => this.checkIsExisting('business_name', {
                               name: this.state.formData.name,
                               merchant_id: this.props.match.params.id
                             }, 'businessNameExistingMsg', 'businessNameExistingStatus')}
                             defaultValue={formData?.name}
                             onChange={this.handleInputOnChange}
                             type="text"/>

                         {this.state.businessNameExistingMsg && <Form.Text className={'text-danger'}>
                           {this.state.businessNameExistingMsg}
                         </Form.Text>}

                         <Form.Control.Feedback type="invalid">Please enter business
                           name.</Form.Control.Feedback>
                       </Col>
                     </Form.Group>
                     <Form.Group as={Row} controlId="displayBusinessRegNumber">
                       <Form.Label column sm="3">
                         Business Registration Number
                       </Form.Label>
                       <Col sm="9" md={8} lg={7}>
                         <Form.Control
                             name={"business_registration_number"}
                             defaultValue={formData?.business_registration_number}
                             onChange={this.handleInputOnChange}
                             type="text"/>
                       </Col>
                     </Form.Group>

                     <Form.Group as={Row} controlId="">
                       <Form.Label column sm="3">
                         Legal Form
                       </Form.Label>

                       <Col sm="9" md={8} lg={7}>
                         <Form.Control
                             defaultValue={formData?.legal_form}
                             onChange={this.handleInputOnChange}
                             name={"legal_form"}
                             as="select">
                           <option value="">--Select Legal Form--</option>
                           <option value="Proprietorship">Proprietorship</option>
                           <option value="Partnership company">Partnership company</option>
                           <option value="Limited company">Limited company</option>
                         </Form.Control>
                       </Col>
                     </Form.Group>

                     <Form.Group as={Row} controlId="">
                       <Form.Label column sm="3">
                         Address <span className="text-danger">*</span>
                       </Form.Label>
                       <Col sm="9" md={8} lg={7}>
                         <Form.Control name={"address"}
                                       required
                                       defaultValue={formData?.address}
                                       onChange={this.handleInputOnChange}
                                       type={"text"}/>

                         <Form.Control.Feedback type="invalid">Please enter
                           address.</Form.Control.Feedback>
                       </Col>
                     </Form.Group>

                     <Form.Group as={Row}
                                 controlId="formCountries">
                       <Form.Label column sm="3">
                         Country <span className="text-danger">*</span>
                       </Form.Label>
                       <Col sm="9" md={8} lg={7}>
                         <Form.Control
                             name={"country"}
                             required
                             defaultValue={this.state.formData.country}
                             onChange={(e) => this.handleLocationInputOnChange(e, 0, 'businessChildLocations')}
                             as="select">
                           <option value="">--Select Country--</option>
                           {this.state.countries.map((country, index) => {
                             let selected = false;
                             if (formData?.country === country._id) {
                               selected = true;
                             }
                             return (
                                 <option
                                     key={index}
                                     value={country._id}
                                     selected={selected}
                                 >{country.name}</option>
                             );
                           })}
                         </Form.Control>

                         <Form.Control.Feedback type="invalid">Please select
                           country.</Form.Control.Feedback>
                       </Col>
                     </Form.Group>

                     {this.state.businessChildLocations.length > 0 &&
                     this.state.businessChildLocations.map((location, index) => (
                         location?.length > 0 &&
                         <Form.Group key={index} as={Row} controlId={`location${location[0].type}`}>
                           <Form.Label column sm="3">{location[0].type}</Form.Label>
                           <Col sm="9" md={8} lg={7}>
                             <Form.Control as="select"
                                           defaultValue={this.state.locations[index + 1]?._id}
                                           name={location[0].type.toLowerCase()}
                                           onChange={(e) => this.handleLocationInputOnChange(e, index + 1, 'businessChildLocations')}>
                               <option value="">--Select--</option>
                               {location.map((item, index) => (
                                   <option key={index} value={item._id}
                                       // selected={formData && formData.[item.name] === item._id ? "true" : "false"}
                                   >{item.name}</option>
                               ))}
                             </Form.Control>
                           </Col>
                         </Form.Group>
                     ))
                     }

                     <Form.Group as={Row} controlId="">
                       <Form.Label column sm="3">
                         Postcode <span className="text-danger">*</span>
                       </Form.Label>

                       <Col sm="9" md={8} lg={7}>
                         <Form.Control
                             name={"postcode"}
                             required
                             defaultValue={formData?.postcode}
                             onChange={this.handleInputOnChange}
                             type={"text"}/>

                         <Form.Control.Feedback type="invalid">Please enter
                           postcode.</Form.Control.Feedback>
                       </Col>
                     </Form.Group>

                     <Form.Group as={Row} controlId="">
                       <Form.Label column sm="3">
                         Geolocation
                       </Form.Label>

                       <Col sm="9" md={8} lg={7}>
                         <Row>
                           <Col sm={'6'}>
                             <Form.Control
                                 name={"latitude"}
                                 defaultValue={formData?.latitude}
                                 onChange={this.handleInputOnChange}
                                 placeholder={'Latitude'}
                                 type={"text"}/>
                           </Col>
                           <Col sm={'6'}>
                             <Form.Control
                                 name={"longitude"}
                                 defaultValue={formData?.longitude}
                                 onChange={this.handleInputOnChange}
                                 placeholder={'Longitude'}
                                 type={"text"}/>
                           </Col>
                         </Row>
                       </Col>
                     </Form.Group>

                     {/*Bank Information*/}
                     <Form.Group as={Row} controlId="">
                       <Form.Label column sm="3"/>

                       <Col sm="9" md={8} lg={7}>
                         <strong>Bank Information</strong>
                       </Col>
                     </Form.Group>

                     <Form.Group as={Row} controlId="">
                       <Form.Label column sm="3">
                         Bank Account Name <span className="text-danger">*</span>
                       </Form.Label>

                       <Col sm="9" md={8} lg={7}>
                         <Form.Control name={"bank_account_name"}
                                       required
                                       defaultValue={formData?.bank_info?.bank_account_name}
                                       onChange={this.handleBankInputOnChange}
                                       type={"text"}/>

                         <Form.Control.Feedback type="invalid">Please select bank account
                           name.</Form.Control.Feedback>
                       </Col>
                     </Form.Group>

                     <Form.Group as={Row} controlId="">
                       <Form.Label column sm="3">
                         Account Number <span className="text-danger">*</span>
                       </Form.Label>

                       <Col sm="9" md={8} lg={7}>
                         <Form.Control
                             name={"bank_account_number"}
                             required
                             defaultValue={formData?.bank_info?.bank_account_number}
                             onChange={this.handleBankInputOnChange}
                             type={"text"}/>
                         <Form.Control.Feedback type="invalid">Please enter bank account
                           number.</Form.Control.Feedback>
                       </Col>
                     </Form.Group>

                     <Form.Group as={Row} controlId="">
                       <Form.Label column sm="3">
                         Bank Name <span className="text-danger">*</span>
                       </Form.Label>

                       <Col sm="9" md={8} lg={7}>
                         {/*<Form.Control
                        name={"bank_name"}
                        required
                        onChange={this.handleBankInputOnChange}
                        as={"select"}>
                        <option value="">--Select Bank--</option>
                        <option
                          selected={formData?.bank_info?.bank_name === 'Agrani Bank Ltd'}
                          value="Agrani Bank Ltd">Agrani Bank Ltd
                        </option>
                        <option
                          selected={formData?.bank_info?.bank_name === 'Sonali Bank Ltd'}
                          value="Sonali Bank Ltd">Sonali Bank Ltd
                        </option>
                        <option
                          selected={formData?.bank_info?.bank_name === 'Estern Bank Lit'}
                          value="Estern Bank Lit">Estern Bank Lit
                        </option>
                        <option
                          selected={formData?.bank_info?.bank_name === 'Dutch Bangla Bank Ltd'}
                          value="Dutch Bangla Bank Ltd">Dutch Bangla Bank Ltd
                        </option>
                      </Form.Control>*/}

                         <Form.Control
                             name={"bank_name"}
                             required
                             value={this.state.formData.bank_info.bank_name}
                             onChange={(e) => {
                               this.handleBankInputOnChange(e)
                               const bank = this.state.bankList.filter(obj => {
                                 return obj._id === e.target.value
                               })

                               if (bank.length > 0) {
                                 this.setState({branchNameList: bank[0].branches})
                               } else {
                                 this.setState({branchNameList: []})
                               }
                             }}
                             as={"select"}>
                           <option value="">--Select Bank--</option>
                           {this.state.bankList.map((bank, index) => (
                               <option
                                   key={index}
                                   selected={formData?.bank_info?.bank_name === 'Agrani Bank Ltd'}
                                   value={bank._id}>{bank.name}</option>
                           ))}
                         </Form.Control>

                         <Form.Control.Feedback type="invalid">Please select a
                           bank</Form.Control.Feedback>
                       </Col>
                     </Form.Group>

                     <Form.Group as={Row} controlId="">
                       <Form.Label column sm="3">
                         Branch Name
                       </Form.Label>

                       <Col sm="9" md={8} lg={7}>
                         <Form.Control
                             required
                             name={"bank_account_branch"}
                             defaultValue={formData?.bank_info?.bank_account_branch || ''}
                             onChange={this.handleBankInputOnChange} type={"text"}/>

                         {/*<Form.Control
                        as={'select'}
                        name={"bank_account_branch"}
                        disabled={this.state.branchNameList.length < 1}
                        value={formData?.bank_info?.bank_account_branch || ''}
                        onChange={this.handleBankInputOnChange}>
                        <option value="">--Select Branch--</option>
                        {this.state.branchNameList.length > 0 &&
                        this.state.branchNameList.map((branch, index) => (
                          <option key={index} value={branch._id}>{branch.name}</option>
                        ))
                        }
                      </Form.Control>*/}
                       </Col>
                     </Form.Group>

                     <Form.Group as={Row} controlId="">
                       <Form.Label column sm="3">
                         Upload Bank Documents <span className="text-danger">*</span>
                       </Form.Label>

                       <Col sm="9" md={8} lg={7}>
                         <BrowseFiles onGetFiles={(files) => {
                           this.handleBankOnBrowsePic(files, 'bank_account_cheque_img')
                         }} image_category={'profile'} helpText={`Check /Card/ Trade Licence`}/>

                         {this.state.formData.bank_info.bank_account_cheque_img &&
                         <div className="existing-images">
                           <div className="existing-img">
                          <span onClick={() => this.handleRemoveBankImg('bank_account_cheque_img')}
                                className="btn-remove">&times;</span>
                             <img src={this.state.formData.bank_info.bank_account_cheque_img} alt=""/>
                           </div>
                         </div>}
                       </Col>
                     </Form.Group>


                     <hr/>
                     {/*Administrative Setting*/}
                     <Form.Group as={Row} controlId="">
                       <Form.Label column sm="3">
                         <strong>Administrative Setting</strong>
                       </Form.Label>

                       <Col sm="9" md={8} lg={7}></Col>
                     </Form.Group>


                     <Form.Group as={Row}>
                       <Form.Label column sm="3">
                         Flagship
                       </Form.Label>
                       <Col sm="9" md={8} lg={7}>
                         <Form.Check
                             custom inline type="checkbox"
                             name="is_flagship" id={`isFlagship`}
                             label={``} value={true} onChange={this.handleInputOnChange}
                             checked={this.state.formData?.is_flagship}
                         />
                       </Col>
                     </Form.Group>

                     <Form.Group as={Row}>
                       <Form.Label column sm="3">
                         Ecommerce Partner
                       </Form.Label>
                       <Col sm="9" md={8} lg={7}>
                         <Form.Check
                             custom inline type="checkbox"
                             name="ecommerce_partner" id={`ecommercePartner`}
                             label={``} value={true} onChange={this.handleInputOnChange}
                             checked={this.state.formData?.ecommerce_partner}
                         />
                       </Col>
                     </Form.Group>

                     <Form.Group as={Row}>
                       <Form.Label column sm="3">
                         Commission Available For
                       </Form.Label>
                       <Col sm="9" md={8} lg={7}>
                         <Form.Check
                             custom inline type="checkbox"
                             name="admin" id={`commissionAvailableForAdmin`}
                             label={`Admin`} value={true} checked={this.state?.formData?.commission_available_for?.admin} onChange={this.handleCommissionAvailability}
                         />
                         <Form.Check
                             custom inline type="checkbox"
                             name="merchant" id={`commissionAvailableForMerchant`}
                             label={`Merchant`} value={true} checked={this.state?.formData?.commission_available_for?.merchant} onChange={this.handleCommissionAvailability}
                         />
                       </Col>
                     </Form.Group>


                     <div className="mt-3">
                       <Button size="lg"
                               type={"submit"}
                               disabled={this.state.isUpdating}
                               variant="primary">{this.state.isUpdating ? 'Updating...' : 'Update'}</Button>
                     </div>
                   </Form>
                   }

                   {this.state.activeBlockTab === 'Invoice Number' &&
                   <>
                     <Form.Group as={Row} controlId="generationType">
                       <Form.Label column sm="3">
                         Generation Type
                       </Form.Label>
                       <Col sm="9" md={8} lg={7}>
                         <Form.Control as="select">
                           <option value="">Use Autoincrement Number</option>
                           <option value="">Use Order Number</option>
                           <option value="">Provide number manually</option>
                         </Form.Control>
                       </Col>
                     </Form.Group>
                     <Form.Group as={Row} controlId="generationType">
                       <Form.Label column sm="3">
                         Invoice Prefix
                       </Form.Label>
                       <Col sm="9" md={8} lg={7}>
                         <Form.Control type="text"/>
                       </Col>
                     </Form.Group>
                     <Form.Group as={Row} controlId="generationType">
                       <Form.Label column sm="3">
                         Next Number (Insert numbers only)
                       </Form.Label>
                       <Col sm="9" md={8} lg={7}>
                         <Form.Control type="number"/>
                       </Col>
                     </Form.Group>
                   </>
                   }
                 </>
             }
           </div>
         </div>
       </>
        : null}
      </App>
    );
  }
}

export default Edit;
