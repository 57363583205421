import React, {Component} from 'react'
import App from "../../App"
import {Button, Form, Spinner} from "react-bootstrap"
import {FaFileExport} from "react-icons/all"
import Moment from "moment"
import {fileDownload, getTableData} from "../../services/commonServices"
import Pagination from 'react-js-pagination'
import {withRouter} from "react-router-dom"
import {useJsonToCsv} from "react-json-csv"
import DateRangePicker from "react-bootstrap-daterangepicker"
import {FaFilePdf} from "@react-icons/all-files/fa/FaFilePdf";

const {saveAsCsv} = useJsonToCsv()

class StoreReport extends Component {
    state = {
        startDate: null,
        endDate: null,
        reportData: [],
        pagination: {},
        queryData: {
            limit: 20
        }
    }

    getData = () => {
        getTableData('/reports/stores', this.state.queryData).then(res => {
            this.setState({
                reportData: res.data,
                pagination: res.paginate
            })
        })
    }

    componentDidMount() {
        this.getData(this.state.queryData)
    }

    handlePageChange = async (pageNumber) => {
        const history = this.props.history
        history.push(`${history.location.pathname}?page=${pageNumber}`)
        await this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber}, () => {
            this.getData(this.state.queryData)
        })
    }

    handleOnApply = (e, picker) => {
        const queryData = {...this.state.queryData}
        const startDate = Moment(picker.startDate).format('DD-MM-YYYY')
        const endDate = Moment(picker.endDate).format('DD-MM-YYYY')
        queryData['from_date'] = startDate
        queryData['to_date'] = endDate

        this.setState({
            queryData
        }, () => {
            this.getData(this.state.queryData)
        })
    }

    handleOnChangeInput = (e) => {
        const queryData = {...this.state.queryData}
        queryData[e.target.name] = e.target.value
        this.setState({queryData}, () => {
            this.getData(this.state.queryData)
        })
    }

    handleExport = async () => {
        this.setState({export_loading: true})
        let queryData = {...this.state.queryData}
        queryData.export = 'xlsx'
        this.setState({queryData}, ()=>{
            fileDownload(`/reports/stores`, this.state.queryData).then((response)=>{
                console.log(response)
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'ekshop-stores-report.xlsx');
                document.body.appendChild(link);
                link.click();
                this.setState({export_loading: false})
            })
            queryData.export = null
            this.setState({
                queryData
            })
        })

        /*let mData = []
        this.state.reportData && this.state.reportData.map((data, index) => {
            mData.push({
                'Index': index + 1,
                'Store Name': data?.store_name,
                'Merchant Type': data?.merchant_info?.merchant_type_info?.type_name,
                'Owner Name': data?.user_info?.name,
                'Phone': data?.user_info?.phone,
                'Email': data?.user_info?.email,
                'Country': data?.country_info?.name,
                'Division': data?.division_info?.name,
                'Upazila': data?.upazila_info?.name,
                'Total Product': data?.total_product,
            })

        })
        const filename = 'EkShop-Store Report',
            fields = {
                'Index': "Index",
                'Store Name': "Store Name",
                'Merchant Type': "Merchant Type",
                'Owner Name': "Owner Name",
                'Phone': "Phone",
                'Email': "Email",
                'Country': "Country",
                'Division': "Division",
                'Upazila': "Upazila",
                'Total Product': "Total Product",

            },
            style = {
                padding: "30px",
                maxHeight: "20px"
            },
            data = mData,
            text = "Convert Json to Csv"
        saveAsCsv({data, fields, filename})*/
    }

    render() {
        return (
            <App>
                <div className="page-header">
                    <h2 className="page-title">Store Report</h2>

                    <div className="ml-auto">
                        <Button variant="primary"

                                size={`sm`}
                                onClick={() => this.handleExport('xlsx')}
                                disabled={this.state.export_loading}>
                            {this.state.export_loading ?
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span>Loading...</span>
                                </>
                                : <><FaFilePdf/><span>Export</span></>}
                        </Button>
                        {/*<Button onClick={() => {
                            this.handleExport()
                        }} size={"sm"} variant={"default"} className="mb-0 mr-2"><FaFileExport/> Export</Button>*/}
                    </div>
                </div>

                <div className="card-block">
                    <div className="block-body">
                        <Form className="d-flex v-middle">
                            <div className="form-inline">

                                <Form.Group className={'mr-2'}>
                                    <Form.Label className={"sr-only"}>Date Range Picker</Form.Label>
                                    <input
                                        type="search"
                                        id="orderNumber"
                                        className='form-control form-control-sm'
                                        placeholder="Search Key"
                                        name="search_string"
                                        onChange={this.handleOnChangeInput}
                                    />
                                </Form.Group>

                                <Form.Group className={'mr-2'}>
                                    <Form.Label className={"sr-only"}>Date Range Picker</Form.Label>
                                    <DateRangePicker
                                        onApply={this.handleOnApply}>
                                        <input type="text"
                                               style={{textAlign: 'left', minWidth: '150px'}}
                                               placeholder={"Select date range"}
                                               className="form-control form-control-sm"/>
                                    </DateRangePicker>
                                </Form.Group>

                            </div>
                        </Form>

                        <hr/>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Store Name</th>
                                    <th>Merchant Type</th>
                                    <th>Owner Name</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Country</th>
                                    <th>Division</th>
                                    <th>Upazila</th>
                                    <th>Total Product</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.reportData.length > 0 && this.state.reportData.map((data, i) => {
                                    return (
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{data?.store_name}</td>
                                            <td>{data?.merchant_info?.merchant_type_info?.type_name}</td>
                                            <td>{data?.user_info?.name}</td>
                                            <td>{data?.user_info?.phone}</td>
                                            <td>{data?.user_info?.email}</td>
                                            <td>{data?.country_info?.name}</td>
                                            <td>{data?.division_info?.name}</td>
                                            <td>{data?.upazila_info?.name}</td>
                                            <td>{data?.total_product}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-3">
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={this.state?.pagination?.page}
                                itemsCountPerPage={this.state?.pagination?.limit}
                                totalItemsCount={this.state?.pagination?.totalDocs ? this.state?.pagination?.totalDocs : 0}
                                pageRangeDisplayed={this.state?.pagination?.limit}
                                onChange={this.handlePageChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </App>
        )
    }
}

export default withRouter(StoreReport)
