import axios from "axios";
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData} from "../helper/utils";

export const checkIsExisting = (type, data) => {
    let url
    if (type === 'phone') {
        url = `${API_BASE_URL}/check-phone-exist`
    } else if (type === 'email') {
        url = `${API_BASE_URL}/check-email-exist`
    } else if (type === 'business_name') {
        url = `${API_BASE_URL}/check-business-name-exist`
    } else if (type === 'store') {
        url = `${API_BASE_URL}/check-store-name-exist`
    }

    return new Promise((resolve, reject) => {
        axios.post(url, data)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                console.error(err)
                reject(err.message)
            })
    })
}

export const addNewItem = (url, data) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.post(`${API_BASE_URL}${url}`, data, {
            headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            console.error(err)
            reject(err.message)
        })
    })
}

export const addNewItemWithParams = (url, data , params) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.post(`${API_BASE_URL}${url}`, data, {
            params: params, headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            console.error(err)
            reject(err.message)
        })
    })
}

export const saveData = (url, data) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.post(`${API_BASE_URL}${url}`, data, {
            headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            console.error(err)
            reject(err.message)
        })
    })
}

export const updateItem = (url, data) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.put(`${API_BASE_URL}${url}`, data, {
            headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            console.error(err)
            reject(err.message)
        })
    })
}

export const getTableData = (url, paramsObj = {}) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}${url}`, {
            params: paramsObj, headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            console.error(err)
            reject(err.message)
        })
    })
}

export const getDetail = (url, paramsObj = {}) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}${url}`, {
            params: paramsObj, headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            console.error(err)
            reject(err.message)
        })
    })
}

export const updateItemPartial = (url, data) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.patch(`${API_BASE_URL}${url}`, data, {
            headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            console.error(err)
            reject(err.message)
        })
    })
}

export const deleteItem = (url) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.delete(`${API_BASE_URL}${url}`,{
            headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            console.error(err)
            reject(err.message)
        })
    })
}

export const postData = (url, data = {}) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
      axios.post(`${API_BASE_URL}${url}`, data, {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": authData.token,
            "x-api-client": getDeviceType(),
          },
        }).then((res) => {
          resolve(res.data);
        }).catch((err) => {
          console.error(err);
          reject(err.message);
        });
    });
  };


export const activityLogPostData =(title, url , name , user_name)  => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.post(`${API_BASE_URL}/activity-log`, {title: title,url:url,name:name , user_name: authData.name}, {
            headers: {
                "Content-Type": "application/json",
                "x-auth-token": authData.token,
                "x-api-client": getDeviceType(),
            },
        }).then((res) => {
            resolve(res.data);
        }).catch((err) => {
            console.error(err);
            reject(err.message);
        });
    });
}


export const updateCartShippingOption = (data) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.patch(`${API_BASE_URL}/cart/update-shipping-option`, data, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            console.log(err)
            reject(err.message)
        })
    })
}


export const fileDownload = (url, paramsObj = {}) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios
            .get(`${API_BASE_URL}${url}`, {
                params: paramsObj,
                headers: {
                    "Content-type": 'application/json',
                    "x-auth-token": authData?.token||'',
                    "x-api-client": getDeviceType(),
                },
                responseType: 'blob'
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                console.error(err);
                reject(err.message);
            });
    });
};



export const applyVoucherCode = (data) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.post(`${API_BASE_URL}/cart/apply/promo-code`, data, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            if (res.data.status === 1) {
                resolve(res.data)
            } else {
                resolve(res.data)
            }
        }).catch(err => {
            console.log(err)
            reject(err.message)
        })
    })
}
