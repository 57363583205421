import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {withRouter, Link} from "react-router-dom";
import {FaLongArrowAltLeft} from "react-icons/all";
import {Multiselect} from "react-widgets";
import Moment from "moment";
import {toast} from "react-toastify";
import {update, getDetails, changeItemStatus} from "../../services/walletRedeemPackageServices.js";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {getProductDetails} from "../../services/productServices";
import Select from "react-select";
import styleReactSelect from "../../assets/styleReactSelect";
import {getLocalAuthData} from "../../helper/utils";
import {activityLogPostData} from "../../services/commonServices";

const today = Moment(new Date()).format('Y-M-D');

class EditWRPOffer extends Component {
    state = {
        formData: {
            package_title: '',
            package_detail: '',
            start_time: null, //today
            end_time: null,  //today
            package_type: "Purchase",
            redeem_point: null,
            offer_value: null
        },
        startDate: today,
        endDate: today,
        isFormValidated: false,
        isSubmitting: false,
        packageTypes: [
            {
                id: 1,
                label: "Top-up",
                value: "Top-up"
            },
            /*{
                id: 2,
                label: "Free Shipping",
                value: "Free Shipping"
            },*/
            {
                id: 3,
                label: "Purchase",
                value: "Purchase"
            }
        ],
    };

    constructor(props) {
        super(props);
        const item_id = this.props.match.params.id;
        this.setState({item_id: item_id});
        getDetails(item_id).then(async res => {
            if (res.status === 1) {
                console.log(res?.item_info);
                toast.success(res.message);
                const formData = {...this.state.formData};
                formData.package_title = res?.item_info?.package_title;
                formData.package_detail = res?.item_info?.package_detail;
                formData.package_type = res?.item_info?.package_type;
                formData.redeem_point = res?.item_info?.redeem_point;
                formData.offer_value = res?.item_info?.offer_value;
                formData.start_time = res?.item_info?.start_time;
                formData.end_time = res?.item_info?.end_time;

                let selected_package_type = this.state.packageTypes.filter(function (itm) {
                    return (res?.item_info?.package_type.indexOf(itm.value) > -1);
                });

                this.setState({
                        formData,
                        isLoadingData: false,
                        startDate: res?.item_info?.start_time, endDate: res?.item_info?.end_time,
                        selected_package_type: selected_package_type[0]
                    },
                    () => {
                        // console.log("after form data save");
                        // console.log(this.state);
                    }
                );
            } else {
                toast.warning(res.message);
                this.setState({isLoadingData: false});
            }
        })
    }

    handleOnApply = (e, picker) => {
        const formData = {...this.state.formData};
        const startDate = Moment(picker.startDate).format('Y-M-D');
        const endDate = Moment(picker.endDate).format('Y-M-D')

        formData.start_time = startDate;
        formData.end_time = endDate;

        this.setState({
            formData,
            startDate: startDate,
            endDate: endDate,
        }, () => {
            console.log(this.state.formData);
        })
    }

    handleInputOnChange = (e) => {
        const formData = {...this.state.formData}
        formData[e.target.name] = (e.target.type === 'checkbox' ? e.target.checked : e.target.value)
        this.setState({formData})
    }

    handleSelectOnChange = (value, name) => {
        const formData = {...this.state.formData}
        formData[name] = value
        this.setState({formData}, () => {
            console.log(this.state.formData);
        }, () => {
            console.log(this.state.formData);
        })
    }

    handleSubmitForm = (e) => {
        const item_id = this.props.match.params.id

        e.preventDefault();
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({isFormValidated: true})
        } else {
            update(item_id, this.state.formData).then(res => {
                console.log(res);
                if (res.status === 2) {
                    toast.warning(res.message);
                } else {
                    toast.success(res.message);
                    this.props.history.push(`${process.env.PUBLIC_URL}/wallet/redeem-package`);
                    activityLogPostData('was update wallet package offer','/wallet/redeem-package/edit/'+item_id ,e.target.package_title)
                }

            }).catch(errMsg => {
                toast.error(errMsg)
            })

            this.setState({isSubmitting: true});
            setTimeout(() => {
                this.setState({isSubmitting: false, isFormValidated: false})
            }, 2000);
        }
    }

    render() {

        const {formData} = this.state

        return (
            <App layout="boxed">
                <div className="page-header">
                    <h2 className="page-title">Add Wallet Package Offer</h2>
                    <div className="ml-auto">
                        <Link to={`/wallet/redeem-package`} className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Package Offer List</Link>
                    </div>
                </div>

                {getLocalAuthData()?.permission?.includes('wallet_redeem_package_offer_edit') ?<div className="main-content">

                    <Form
                        noValidate validated={this.state.isFormValidated}
                        onSubmit={this.handleSubmitForm}>
                        <div className="card-block">

                            <div className="block-header">
                                <h4 className="block-title">Update Package Info</h4>
                            </div>

                            <div className="block-body">

                                <Form.Group as={Row} controlId="formPromotionName">
                                    <Form.Label column sm="3">
                                        Package Title <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type="text" placeholder=""
                                                      onChange={this.handleInputOnChange}
                                                      name={'package_title'}
                                                      defaultValue={this.state.formData?.package_title}
                                                      required/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formStartFrom">
                                    <Form.Label column sm="3">
                                        Package Details
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control as="textarea" placeholder="Package Detail" rows={3}
                                                      onChange={this.handleInputOnChange}
                                                      name={'package_detail'}
                                                      defaultValue={this.state.formData?.package_detail}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formStartFrom">
                                    <Form.Label column sm="3">
                                        Date Range
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <DateRangePicker containerStyles={{textAlign: 'left', width: '100%', display: 'block'}} onApply={this.handleOnApply}>
                                            <input type="button"
                                                   value={`${Moment(formData.start_time).format('Y-M-D')} to ${Moment(formData.end_time).format('Y-M-D')}`}
                                                   style={{textAlign: 'left'}}
                                                   className="form-control"/>
                                        </DateRangePicker>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formBrand">
                                    <Form.Label column sm="3">
                                        Package Type <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>

                                        {/* <Select
                                            styles={styleReactSelect}
                                            placeholder="Select Package type..."
                                            defaultValue={this.state.formData?.package_type}
                                            value={this.state.formData?.package_type}
                                            onChange={(value) => this.handleSelectOnChange(value.value, 'package_type')}
                                            options={this.state.packageTypes}
                                            name="package_type"
                                            required
                                        />*/}

                                        {this.state.selected_package_type && <Select
                                            styles={styleReactSelect}
                                            placeholder="Select Package type..."
                                            defaultValue={this.state.selected_package_type}
                                            onChange={(value) => this.handleSelectOnChange(value.value, 'package_type')}
                                            options={this.state.packageTypes}
                                            name="package_type"
                                            required/>}
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPromotionName">
                                    <Form.Label column sm="3">
                                        Redeem Point <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type="number" min={1} placeholder=""
                                                      onChange={this.handleInputOnChange}
                                                      name={'redeem_point'}
                                                      required
                                                      defaultValue={this.state.formData?.redeem_point}/>
                                    </Col>
                                </Form.Group>


                                <Form.Group as={Row} controlId="formRedeemAmount">
                                    <Form.Label column sm="3">
                                        Amount in Taka <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type="number" min={1} placeholder=""
                                                      onChange={this.handleInputOnChange}
                                                      name={'offer_value'}
                                                      defaultValue={this.state.formData?.offer_value}/>
                                    </Col>
                                </Form.Group>

                            </div>
                        </div>


                        <div className="card-block">
                            <div className="block-body text-right">
                                <Link to={`/wallet/redeem-package`} className={`btn btn-lg btn-default mr-2`}>Cancel</Link>

                                <Button size="lg" type={"submit"}
                                        disabled={this.state.isSubmitting}
                                        variant="primary">{this.state.isSubmitting ? 'Update...' : 'Update'}</Button>

                            </div>
                        </div>

                    </Form>

                </div>:null}
            </App>
        );
    }
}

export default withRouter(EditWRPOffer);
