import React, {useEffect, useState} from "react";
import {getLocalAuthData} from "../../helper/utils";
import {Link, useHistory, useParams} from "react-router-dom";
import {AiFillDelete, FcLink, FiPlus} from "react-icons/all";
import {Badge, Button, Form, Modal} from "react-bootstrap";
import Pagination from "react-js-pagination";
import App from "../../App";
import {activityLogPostData, deleteItem, getDetail, getTableData} from "../../services/commonServices";
import {ToastsContainer, ToastsStore} from "react-toasts";
import {supportTicketList} from "../../services/support-ticketService";
import Moment from "moment";
import {toast} from "react-toastify";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import axios from "axios";
import {API_BASE_URL} from "../../helper/env";

const ActivityLog = () => {

    const {id} = useParams();
    const [queryData, setQueryData] = useState({
        from_date: '',
        to_date: ''
    })
    const [selectedUser, setSelectedUser] = useState()
    const [stafflist, setStaffList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [activityList, setActivityList] = useState()
    const [userDetails, setUserDetails] = useState()
    const [pagination, setPagination] = useState({})
    const [queryParams, setQueryParams] = useState();

    useEffect(() => {

        const a = getLocalAuthData()?.permission?.includes('activity_log_list')
        if (a === true) {

            if (id === 'all') {
                setQueryParams({
                    limit: 50,
                    page: 1
                })


            } else {
                setQueryParams({
                    limit: 10,
                    page: 1,
                    uid: id
                })
                getDetail(`/staff/${id}`).then((res) => {
                    setUserDetails(res.data)
                }).catch((err) => {
                    console.log(err)
                })
            }

            //fetchData()
        }

    }, [])

    useEffect(() => {
        getAllStaffs()
    }, [])


    useEffect(() => {
        fetchData()

    }, [queryParams])


    const fetchData = () => {
        setIsLoading(true)
        console.log(queryParams)
        if (queryParams !== undefined) {
            getTableData(`/activity-log/`, queryParams).then((res) => {
                setActivityList(res.items)
                setPagination({...pagination, ...res.pagination});
                setIsLoading(false)
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    const getAllStaffs = () => {
        // setIsLoading(true)
        getTableData(`/staff`, {"limit": 1000}).then((res) => {

            console.log("staffs")
            console.log(res.items.name)

            setStaffList(res.items)
            // setIsLoading(false)
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleDelete = (idx) => {
        const a = getLocalAuthData()?.permission?.includes('activity_log_delete')
        if (a === true) {
            deleteItem(`/activity-log/${idx}/permanent`).then((res) => {
                toast.success(res.message);
                //activityLogPostData("[Staff Management] Create new staff")
                fetchData()

            })
        }

    }


    const handlePageChange = async (pageNumber) => {
        const params = {...queryParams, page: pageNumber};
        setQueryParams(params);
        fetchData()
    };


    const handleOnApply = (e, picker) => {

        setQueryParams({
            ...queryParams,
            from_date: moment(picker.startDate).format('DD/MM/YYYY'),
            to_date: moment(picker.endDate).format('DD/MM/YYYY')
        },)

        console.log(queryData)
    }


    const ActivityLogComponent = () => {
        return (
            <>

                <div className="table-responsive">
                    <table className="order-table table table-striped">
                        <thead>
                        <tr>
                            <th style={{width: '20px'}}>#</th>
                            <th>What</th>
                            <th>Detail</th>
                            <th>Who</th>
                            <th className="nowrap text-center">When</th>
                            {/* <th style={{width: `1%`}}/>*/}
                        </tr>
                        </thead>
                        <tbody>
                        {activityList && activityList.map((data, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>

                                <td>
                                    {/*{data?.user_name === null ? <>{userDetails?.name}</> : <>{data?.user_name}</>}*/} {data?.title} {data?.name}
                                    {/*<Badge*/}
                                    {/*    style={{marginLeft: '5px'}}*/}
                                    {/*    variant={`primary`}>{data?.name}</Badge>*/}
                                    {/*<a href={data.url}*/}
                                    {/*   target="_blank">*/}
                                    {/*    {<FcLink style={{marginLeft: '5px'}}*/}
                                    {/*    />}*/}
                                    {/*</a>*/}
                                </td>

                                <td>
                                    <a href={data.url}
                                       target="_blank">
                                        {data?.name ? <Badge
                                            style={{marginLeft: '5px'}}
                                            variant={`primary`}>{data?.name}</Badge> : <FcLink style={{marginLeft: '5px'}}/>}
                                        {/*{<FcLink style={{marginLeft: '5px'}}
                                        />}*/}
                                    </a>
                                </td>

                                <td>
                                    {data?.user_name}
                                </td>

                                <td className="nowrap text-center">
                                    {Moment(data.createdAt).format('hh : mm a DD-MM-YYYY')}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div className="mt-3">
                    <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={pagination?.page}
                        itemsCountPerPage={pagination?.limit}
                        totalItemsCount={pagination?.totalDocs ? pagination?.totalDocs : 0}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}
                    />
                </div>


            </>
        )
    }


    return (
        <App layout={``}>
            <div className="main-content">
                <div className="page-header">
                    <h2 className="page-title">Activity Log</h2>
                    <div className="ml-auto">
                    </div>
                </div>
                <div className="card-block">
                    <div className="block-body">


                        {id === 'all' ?   <form className="form-inline mb-3">

                            <div className="form-group mr-3 mt-2">
                                <label htmlFor="status" className="sr-only">Locations</label>
                                <select id="status" name="country"
                                        className='form-control form-control-sm'
                                        onChange={(e) => setQueryParams({
                                            ...queryParams,
                                            uid: e.target.value
                                        })
                                        }>

                                    <option value="">--Select User--</option>

                                    {stafflist.map((item, index) => <option key={index}
                                                                            value={item._id}>{item.name}</option>)}


                                </select>
                            </div>


                            <div className="form-group mr-3 mt-2">
                                <DateRangePicker
                                    initialSettings={{}}
                                    containerStyles={{
                                        textAlign: 'left',
                                        width: '100%',
                                        display: 'block'
                                    }}
                                    onApply={handleOnApply}>
                                    <input type="button"
                                           placeholder={`Select date range`}
                                           value={(queryData.from_date && queryData.to_date) ? `${queryData.from_date} - ${queryData.to_date}` : 'Select date range'}
                                           style={{textAlign: 'left', minWidth: '150px'}}
                                           className="form-control form-control-sm"/>
                                </DateRangePicker>
                            </div>


                        </form> : null}




                        {isLoading ? 'Loading...' :
                            <>
                                {activityList.length > 0 ? <> {getLocalAuthData()?.permission?.includes('activity_log_list') ?
                                    <ActivityLogComponent/> : null}</> : 'No Data Found'}
                            </>
                        }

                    </div>
                </div>
            </div>
            <ToastsContainer store={ToastsStore}/>
        </App>)
}


export default ActivityLog;
