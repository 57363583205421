import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FaLongArrowAltLeft} from "react-icons/all";
import {Multiselect} from "react-widgets";
import {getLocalAuthData} from "../../helper/utils";

class AddEvent extends Component {
    state = {
        categories: ["Category 1", "Category 2", "Category 3", "Category 4", "Category 5"],
    };

    handleOnChangeCategory = (vareint) => {
        //console.log("logging");
    }
    render() {
        return (
            <App layout="boxed">
                <div className="page-header">
                    {getLocalAuthData()?.permission?.includes('event_add') ?
                    <h2 className="page-title">Add Event</h2> : null}
                    <div className="ml-auto">
                        <Link to={`${process.env.PUBLIC_URL}/promotions/event`} className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Event List</Link>
                    </div>
                </div>

                {getLocalAuthData()?.permission?.includes('event_add') ?
                <div className="main-content">

                    <Form>
                        <div className="card-block">

                            <div className="block-header">
                                <h4 className="block-title">Define Offer</h4>
                            </div>

                            <div className="block-body">

                                <Form.Group as={Row} controlId="formPromotionName">
                                    <Form.Label column sm="3">
                                        Event Title * <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type="text" placeholder=""/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formStartFrom">
                                    <Form.Label column sm="3">
                                        Event Details <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control name="campaign_details"as="textarea" placeholder="Campaign Details" rows={3}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formStartFrom">
                                    <Form.Label column sm="3">
                                        Event Date <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type="text" placeholder=""/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formEndTime">
                                    <Form.Label column sm="3">
                                        Registration Last Date <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type="text" placeholder=""/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formImages">
                                    <Form.Label column sm="3">
                                        Event Banner
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type="file"/>
                                        <span className="text-muted">Multiple images can be uploaded at once.Maximum 8 pictures. Size between 330x330 and 5000x5000 px. Obscene image is strictly prohibited.</span>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formEndTime">
                                    <Form.Label column sm="3">
                                        Category <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Multiselect
                                            onChange={value => this.handleOnChangeCategory(value)}
                                            placeholder="Select varient"
                                            data={this.state.categories}/>
                                    </Col>
                                </Form.Group>


                                <Form.Group as={Row} controlId="formVoucherType">
                                    <Form.Label column sm="3">
                                        Shop Category <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control as="select" placeholder="">
                                            <option value="all">All</option>
                                            <option value="1">Uddakta Mall</option>
                                            <option value="2">Location Entrepreneur</option>
                                            <option value="3">Global Entrepreneur</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formVoucherDisplayArea">
                                    <Form.Label column sm="3">
                                        Shops
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Check
                                            custom inline type="radio"
                                            name="display_area"
                                            id={`apply-to-entire-shop`}
                                            label={`All Shop`}
                                        />
                                        <Form.Check
                                            custom inline type="radio"
                                            name="display_area"
                                            id={`apply-to-specific-product`}
                                            label={`Specific Shop`}
                                        />
                                    </Col>
                                </Form.Group>

                                <div className="card-block">
                                    <div className="block-body">
                                        <div className="product-list">
                                            <div className="product-item">{/*SHOP LIST WILL BE ADDED HERE*/}</div>
                                        </div>
                                        <Button size="sm" variant="primary"> + Select Shop</Button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="card-block">
                            <div className="block-body text-right">
                                <Button size="lg" className="mr-3" variant="default">Cancel</Button>
                                <Button size="lg" variant="primary">Save</Button>
                            </div>
                        </div>

                    </Form>

                </div> :null}
            </App>
        );
    }
}

export default AddEvent;
