import {getDeviceType, getLocalAuthData} from "../helper/utils";
import axios from "axios";
import {API_BASE_URL} from "../helper/env";

export const getCommissionList = (paramsObj = {}) => {
    const authData = getLocalAuthData()
    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}/commission-balance`, {
            headers: {
                'x-auth-token': authData?.token || '',
                'x-api-client': getDeviceType()
            },
            params: paramsObj
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                console.error(err)
                reject(err.message)
            })
    })
}

export const getCommissionSummaryList = (paramsObj = {}) => {
    const authData = getLocalAuthData()
    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}/commission-summary`, {
            headers: {
                'x-auth-token': authData?.token || '',
                'x-api-client': getDeviceType()
            },
            params: paramsObj
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                console.error(err)
                reject(err.message)
            })
    })
}

export const disburseCommission = (data) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.post(API_BASE_URL + '/commission/disburse', data, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            if (res.data.status === 1) {
                resolve(res.data)
            } else {
                reject(res.data.message)
            }
        }).catch(err => {
            reject('Something went wrong')
        })
    })
}