import React, { Component } from 'react'
import App from "../../App";

export default class StorePaymentTransaction extends Component {
    render() {
        return (
            <App layout="">
               <div className="main-content">
                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">Store Payment Transaction History</h4>
                        </div>
                        <div className="block-body">        
                            <div className="mt-4">
                                <table className="order-table table table-striped">
                                    <thead>
                                        <tr>
                                            <th style={{width: '1%'}}>Sl</th>
                                            <th>Date</th>
                                            <th>Account Info</th>
                                            <th>Transaction Id</th>
                                            <th>Note</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>
                                                <div className="order-item">
                                                    <span className="order-date">6/22/2021</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="order-item">
                                                    <h4 className="order-id">Md Abu Saleh</h4>
                                                    <span className="order-email">saleh@parallaxlogic.net</span>
                                                    <span className="order-mobile">01234567891</span>
                                                </div>
                                            </td>
                                            <td>12</td>
                                            <td>note here</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>
                                                <div className="order-item">
                                                    <span className="order-date">6/23/2021</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="order-item">
                                                    <h4 className="order-id">Md Abu Saleh</h4>
                                                    <span className="order-email">saleh@parallaxlogic.net</span>
                                                    <span className="order-mobile">01234567891</span>
                                                </div>
                                            </td>
                                            <td>13</td>
                                            <td>note here</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </App>
        
        )
    }
}
