import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {FaLongArrowAltLeft} from "react-icons/all";
import {Multiselect} from "react-widgets";
import Moment from "moment";
import {toast} from "react-toastify";
import {add} from "../../services/walletRedeemPackageServices.js";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {loginSuccess} from "../../redux";
import Select from "react-select";
import styleReactSelect from "../../assets/styleReactSelect";
import {getDetails} from "../../services/walletRedeemPackageServices";
import {getLocalAuthData} from "../../helper/utils";
import {activityLogPostData} from "../../services/commonServices";

const today = Moment(new Date()).format('Y-M-D');

class AddWRPOffer extends Component {
    state = {
        formData: {
            package_title: '',
            package_detail: '',
            start_time: today,
            end_time: today,
            package_type: "Purchase",
            redeem_point: null,
            offer_value: null
        },
        startDate: today,
        endDate: today,
        isFormValidated: false,
        isSubmitting: false,
        packageTypes: [
            {
                id: 1,
                label: "Top-up",
                value: "Top-up"
            },
            /*{
                id: 2,
                label: "Free Shipping",
                value: "Free Shipping"
            },*/
            {
                id: 3,
                label: "Purchase",
                value: "Purchase"
            }
        ],
    };

    constructor(props) {
        super(props);
        const formData = {...this.state.formData};
        this.setState({formData});
    }

    handleOnApply = (e, picker) => {
        const formData = {...this.state.formData};
        const startDate = Moment(picker.startDate).format('Y-M-D');
        const endDate = Moment(picker.endDate).format('Y-M-D')

        formData.start_time = startDate;
        formData.end_time = endDate;

        this.setState({
            formData,
            startDate: startDate,
            endDate: endDate,
        }, () => {
            // console.log(this.state.formData);
        })
    }

    handleInputOnChange = (e) => {
        const formData = {...this.state.formData}
        formData[e.target.name] = (e.target.type === 'checkbox' ? e.target.checked : e.target.value)
        this.setState({formData}, () => {
            // console.log(this.state.formData);
        })
    }

    handleSelectOnChange = (value, name) => {
        const formData = {...this.state.formData}
        formData[name] = value
        this.setState({formData}, () => {
            // console.log(this.state.formData);
        })
    }

    handleSubmitForm = async (e) => {

        e.preventDefault();
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({isFormValidated: true})
        } else {
            this.setState({isSubmitting: true});
            await add(this.state.formData).then((res) => {

                if (res.status === 1) {
                    toast.success(res.message);
                    activityLogPostData('was create new wallet offer','/wallet/redeem-package',e.target.package_title)
                    this.setState({isSubmitting: false, isFormValidated: false});
                    this.props.history.push(`${process.env.PUBLIC_URL}/wallet/redeem-package`);
                } else {
                    toast.warning(res.message);
                }

            }).catch(errMsg => {
                toast.error(errMsg)
            });

        }
    }

    render() {
        return (
            <App layout="boxed">
                <div className="page-header">
                    <h2 className="page-title">Add Wallet Redeem Package Offer</h2>
                    <div className="ml-auto">
                        <Link to={`${process.env.PUBLIC_URL}/wallet/redeem-package`} className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Wallet Redeem Package Offer List</Link>
                    </div>
                </div>

                {getLocalAuthData()?.permission?.includes('wallet_redeem_package_offer_add') ?<div className="main-content">


                    <Form
                        noValidate validated={this.state.isFormValidated}
                        onSubmit={this.handleSubmitForm}>
                        <div className="card-block">

                            <div className="block-header">
                                <h4 className="block-title">Define Package Info</h4>
                            </div>

                            <div className="block-body">

                                <Form.Group as={Row} controlId="formPromotionName">
                                    <Form.Label column sm="3">
                                        Package Title <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type="text" placeholder=""
                                                      onChange={this.handleInputOnChange}
                                                      name={'package_title'}
                                                      defaultValue={this.state.formData?.package_title}
                                                      required/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formStartFrom">
                                    <Form.Label column sm="3">
                                        Package Details
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control as="textarea" placeholder="Package Detail" rows={3}
                                                      onChange={this.handleInputOnChange}
                                                      name={'package_detail'}
                                                      defaultValue={this.state.formData?.package_detail}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formStartFrom">
                                    <Form.Label column sm="3">
                                        Date Range
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <DateRangePicker containerStyles={{textAlign: 'left', width: '100%', display: 'block'}} onApply={this.handleOnApply}>
                                            <input type="button"
                                                   value={`${this.state.startDate} to ${this.state.endDate}`}
                                                   style={{textAlign: 'left'}}
                                                   className="form-control"/>
                                        </DateRangePicker>
                                    </Col>
                                </Form.Group>


                                <Form.Group as={Row} controlId="formBrand">
                                    <Form.Label column sm="3">
                                        Package Type <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Select
                                            styles={styleReactSelect}
                                            placeholder="Select Package type..."
                                            defaultValue={this.state.formData?.package_type}
                                            onChange={(value) => this.handleSelectOnChange(value.value, 'package_type')}
                                            options={this.state.packageTypes}
                                            name="package_type"
                                            required
                                        />
                                    </Col>
                                </Form.Group>


                                <Form.Group as={Row} controlId="formPromotionName">
                                    <Form.Label column sm="3">
                                        Redeem Point <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type="number" min={1} placeholder=""
                                                      onChange={this.handleInputOnChange}
                                                      name={'redeem_point'}
                                                      required
                                                      defaultValue={this.state.formData?.redeem_point}/>
                                    </Col>
                                </Form.Group>


                                <Form.Group as={Row} controlId="formRedeemAmount">
                                    <Form.Label column sm="3">
                                        Offer Value <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type="number" min={1} placeholder=""
                                                      onChange={this.handleInputOnChange}
                                                      name={'offer_value'}
                                                      defaultValue={this.state.formData?.offer_value}/>
                                    </Col>
                                </Form.Group>

                            </div>
                        </div>


                        <div className="card-block">
                            <div className="block-body text-right">
                                <Link to={`/wallet/redeem-package`} className={`btn btn-lg btn-default mr-2`}>Cancel</Link>

                                <Button size="lg" type={"submit"}
                                        disabled={this.state.isSubmitting}
                                        variant="primary">{this.state.isSubmitting ? 'Saving...' : 'Save'}</Button>

                            </div>
                        </div>

                    </Form>

                </div>:null}

            </App>
        );
    }
}

export default withRouter(AddWRPOffer);
