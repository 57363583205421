import React, {Component,useState , useEffect} from 'react';
import App from "../../App";
import {Button, Col, Form, Row, ToggleButton} from "react-bootstrap";
import Pagination from 'react-js-pagination';
import {FaFileExport, FiPlus} from "react-icons/all";
import "../../assets/scss/order-list.scss";

import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import {Link, useHistory} from "react-router-dom";
import {getDeviceType, getLocalAuthData} from "../../helper/utils";
import {deleteItem, getDetail, getTableData, getTableDataV1, updateItem} from "../../services/commonServices";
import DatePicker from "react-datepicker";
import Moment from "moment";
import {BsCardImage} from "@react-icons/all-files/bs/BsCardImage";
import {confirmAlert} from "react-confirm-alert";
import {deleteLocation, getLocationList} from "../../services/locationServices";
import {toast} from "react-toastify";

const NoticeBoardList = () => {

    const history = useHistory()

    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({});
    const authData = getLocalAuthData();
    const [queryParams, setQueryParams] = useState({
        limit: 10,
        page: 1,
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const result = await getDetail('/notice',queryParams);
        console.log(result.items)
        setData(result.items);
        setPagination({ ...pagination, ...result.pagination });
    };

    const deleteData = async  (id) =>{

    }

    const handlePageChange = async (pageNumber) => {
        const params = { ...queryParams, page: pageNumber };
        setQueryParams(params);
        fetchData()
    };


    const handleOnApply = (e, picker) => {
        const startDate = moment(picker.startDate).format('DD/MM/YYYY');
        const endDate = moment(picker.endDate).format('DD/MM/YYYY')
        const params = {
            ...queryParams,
            from_date: startDate,
            to_date: endDate
        };
        setQueryParams(params);
        console.log(queryParams)
    }

    const handleDeleteX = (id) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure to delete this notice?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteItem('/notice/'+id+'/permanent')
                        .then(res => {
                            toast.success(res.message);
                            fetchData()
                        })
                        .catch(errMsg => {
                            toast.error(errMsg)
                        })
                },
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });
    }

    return(
        <App layout="">
            <div className="main-content">
                <div className="page-header">
                    <h2 className="page-title">Notices</h2>
                </div>

                <div className="card-block">
                    <div className="block-header">
                        <h4 className="block-title">Notice List</h4>
                        <div className="ml-auto">
                            <Link to={`${process.env.PUBLIC_URL}/notice/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add Notice</Link>
                        </div>
                    </div>

                    <div className="block-body position-relative">


                        <form onSubmit={(e)=> {
                            e.preventDefault()
                        }} className="form-inline mb-3">


                            <Form.Group className={`mr-3`}>
                                <div className="form-group mr-2">

                                    <DateRangePicker
                                        initialSettings={{}}
                                        containerStyles={{textAlign: 'left', width: '100%', display: 'block'}}
                                        onApply={handleOnApply}>
                                        <input type="button"
                                               placeholder={`Select date range`}
                                               value={(queryParams?.from_date && queryParams?.to_date) ? `${queryParams?.from_date} - ${queryParams?.to_date}` : 'Select date range'}
                                               style={{textAlign: 'left', minWidth: '150px'}}
                                               className="form-control form-control-sm"
                                        />
                                    </DateRangePicker>

                                    {/*  <DateRangePicker  className='form-control form-control-sm' onApply={()=>handleOnApply}
                                                 startDate={queryParams?.startDate || moment(new Date()).format('M/D/Y')}
                                                 endDate={queryParams?.endDate || moment(new Date()).format('M/D/Y')}>
                                    <span>{queryParams?.startDate && queryParams?.endDate ? queryParams?.startDate + ' to ' + queryParams?.endDate : 'Select Date'}</span>
                                </DateRangePicker>*/}
                                </div>
                            </Form.Group>
                            <div className="form-group mr-2">
                                <label htmlFor="searchInput" className="sr-only">Search Key</label>
                                <input type="search" name="search_string" id="orderNumber"
                                       className='form-control form-control-sm' placeholder="Search Key"
                                       onChange={(e)=>{
                                           const params = {
                                               ...queryParams,
                                               search_string: e.target.value,
                                           };
                                           setQueryParams(params);
                                       }}
                                       onKeyDown={(e)=>{
                                           if(e.keyCode === 13) {
                                               //this.getQueryData()
                                               fetchData()
                                           }
                                       }}/>
                            </div>

                            <Button size="sm" onClick={()=> fetchData()} variant="secondary" >Search</Button>
                        </form>

                        <table className="order-table table table-striped">
                            <thead>
                            <tr>
                                <th style={{width: '5%'}}>#</th>
                                <th style={{width: '15%'}}>Image</th>
                                <th style={{width: '30%'}}>Title</th>
                                <th style={{width: '20%'}}>Total Seen</th>
                                <th style={{width: '20%'}}>Date</th>
                                <th style={{width: '20%'}}>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data?.map((item , index)=>{
                                return(<tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{item?.img ? <img src={item?.img} width={`70px`} height={`70px`} alt={``}/> : <><BsCardImage width={`70px`} height={`70px`}/></>}</td>
                                    <td>
                                        <div className="order-item">
                                            <p className={item?.notice_board_seen?.seen === false ? 'order-id font-weight-bolder mb-0' : 'order-id mb-0'}>{item?.title}</p>
                                            <small className="font-weight-normal">{item?.message}</small>
                                        </div>
                                    </td>

                                    <td>
                                        <div className="order-item">
                                            {item?.total_seen}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="order-item">
                                            {moment(item?.createdAt).format('hh:mm DD/MM/YYYY')}
                                        </div>
                                    </td>
                                    <td>
                                        <button onClick={() =>
                                         history.push(`${process.env.PUBLIC_URL}/notice/view/${item._id}`)
                                        } className="btn-outline-primary btn-action">View</button>

                                        <button onClick={() =>
                                            history.push(`${process.env.PUBLIC_URL}/notice/edit/${item._id}`)
                                        } className="btn-outline-primary btn-action">Edit</button>


                                     {/*   <button onClick={() => handleDeleteX(item?._id)}
                                                className="btn-action btn-outline-danger">Delete</button>*/}


                                        {item.user_type === 'merchant' && item?.campaign_id &&
                                        <Link to={`/promotions/campaign/details/${item?.campaign_id}`} className="btn btn-outline-info btn-action btn-sm h-100">View</Link>
                                        }
                                    </td>
                                </tr>)
                            })}
                            </tbody>
                        </table>
                        <div className="mt-3">
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={pagination?.page}
                                itemsCountPerPage={pagination?.limit}
                                totalItemsCount={pagination?.totalDocs}
                                pageRangeDisplayed={10}
                                onChange={handlePageChange.bind(this)}
                            />
                        </div>


                    </div>
                </div>


            </div>
        </App>
    )

}

export default NoticeBoardList;