import React, {Component} from 'react';
import App from "../../App";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import BrowseFiles from "../../components/BrowseFiles";
import OnboardingScreen from "../../components/OnboardingScreen";
import axios from "axios";
import config from "../../helper/env";
import {ToastsContainer, ToastsStore} from "react-toasts";
import {toast} from "react-toastify";
import {getDeviceType, getLocalAuthData} from "../../helper/utils";
import {getCategoryList} from "../../services/categoryServices";
import { getMerchantType } from '../../services/merchantTypeServices';
import { getCampaignList } from '../../services/promotionServices';
import { withRouter } from 'react-router';
import {activityLogPostData, getDetail, saveData, deleteItem, updateItem} from "../../services/commonServices";

class OnboardingScreens extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bannerArray:[],
      formData: {},
      serviceData: [],
      editFormData: null,
      editStatus: false,
      categories:'',
      isFormValidated: true,
      merchantTypes: [],
      campaignList: [],
      formSubmitError: ""
    }
  }


  componentDidMount() {
    getDetail(`/onboarding-screens`, {}).then(res => {
      this.setState({items: res.items});
    }).catch(e => this.setState({error: true}));
  }

  renderData = () => {
    getDetail(`/onboarding-screens`, {}).then(res => {
      this.setState({items: res.items});
    }).catch(e => this.setState({error: true}));
  }

  handleImage = (files) => {
    this.setState({formData: {...this.state.formData,image: files[0].actual_img}});
  }

  handleImageOnBrowse = (images, type = '') => {
    const formData = {...this.state.formData}
    var array = [];

    images.map((data, i) => {
      array.push({
        img: data.actual_img
      })
    })
    formData.banner = array
    this.setState({bannerArray: array})
    this.setState({formData}, () => {
      console.log(formData);
    })
  }

  handleFormDataChange = (e) => {
    this.setState({formSubmitError: ""});
    if(e.target.name === "merchant_type" || e.target.name === "campaign"){
      const formData = {...this.state.formData};
      const settings = {
        params:{
          [e.target.name] : e.target.value
        }
      }
      this.setState({formData: {...this.state.formData, settings}})
    }else if(this.state.editStatus && e.target.name === "is_premium" && e.target.value == 0){
      debugger;
      this.setState({formData: {...this.state.formData, service_type: "", settings: {}, [e.target.name]: e.target.value}})
    }else{
      this.setState({formData: {...this.state.formData, [e.target.name]: e.target.value}}, ()=>{
        console.log(this.state.formData);
      });
    }
  };

  deleteItem = (id) => {
    const authData = getLocalAuthData()
    deleteItem(`/onboarding-screens/${id}/permanent/`, {}).then(res => {
      if (res.status === 1) {
        toast.success(res.message);
        activityLogPostData('was delete this Onboard screen', '/onboarding-screens', id)
        this.renderData();
      }
      if (res.status === 2) {
        toast.error(res.message);
      }
    }).catch(e => this.setState({error: true}));
  };

  editItem = (id) => {
    getDetail(`/onboarding-screens/${id}`, {}).then(res => {
      this.setState({formData: res.onboarding_screen, editStatus: true});
    }).catch(error => {
      toast.error("Error");
    });
  };

  renderService = () => {
    const items = this.state.items;
    if (items) {
      return (
        <Row>
          {items.map((item, index) => {
              return (
                <>
                  <Col md={3}>
                    <OnboardingScreen
                      imageLink={item.image}
                      serviceId={item._id}
                      deleteItem={() => this.deleteItem(item._id)}
                      editItem={() => this.editItem(item._id)}
                    />
                    <p><strong>Screen Heading:</strong> {item.screen_header}</p>
                    <p><strong>Screen short note:</strong> {item.screen_short_note}</p>
                  </Col>
                </>
              )
            }
          )}
        </Row>
      )
    }

    return (
      <Form.Control as="select">
        <option>Fetching Onboarding Screens</option>
      </Form.Control>
    )
  };

  handleSubmitForm = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false || !this.state.formData?.image) {
      this.setState({isFormValidated: false, formSubmitError: "Please fill the required field"});
      window.scrollTo({top: 0, behavior: 'smooth'})
      e.stopPropagation();
    }else{

      this.setState({isFormValidated: true, formSubmitError: ""})
      const data = this.state.formData;
      if (this.state.editStatus === true) {
        updateItem(`/onboarding-screens/` + this.state.formData._id, data).then(response => {
          if (response.status === 1) {
            toast.success(response.message);
            activityLogPostData('was create new onboarding screen', '/onboarding-screens', this.state.formData.name)
            this.setState({formData: {}})
            form.reset();
            this.renderData();
          }
          if (response.status === 2) {
            toast.error(response.message);
          }
        }).catch(error => {
          toast.error("Error");
        });

      } else {

        saveData(`/onboarding-screens`, data).then(response => {
          if (response.status === 1) {
            toast.success(response.message);
            activityLogPostData('was create new onboarding screen','/onboarding-screens',this.state.formData.name)
            this.setState({formData: {}})
            form.reset();
            this.renderData();
          }
          if (response.status === 2) {
            toast.error(response.message);
          }
        }).catch(error => {
          toast.error("Error");
        });

      }
    }

  };

  render() {
    return (
      <App layout={"boxed"}>
        <div className="page-header">
          <h2 className="page-title">Add Onboarding Screen</h2>
        </div>

        <div className="main-content">

          {getLocalAuthData()?.permission?.includes('service_add') ?
            <div className="card-block">
          <div className="block-header">
            <h4 className="block-title">Onboarding Screen </h4>
          </div>

          <div className="block-body">
            {
              this.state.formSubmitError &&
                <Alert variant={"danger"}>{this.state.formSubmitError}</Alert>
            }

            <Form noValidate className={this.state.isFormValidated? "": " was-validated"} onSubmit={(e)=>this.handleSubmitForm(e)} id={"serviceForm"}>
              <Form.Group as={Row} controlId="screenHeader">
                <Form.Label column sm="3">
                  Screen Header <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control type={"text"}
                                required
                                value={this.state.formData?.screen_header}
                                name="screen_header" onChange={this.handleFormDataChange}
                                placeholder={"Screen Header"}/>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="screenShortNote">
                <Form.Label column sm="3">
                  Screen Short Note <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                      type={"text"}
                      required
                      name="screen_short_note"
                      onChange={this.handleFormDataChange}
                      placeholder={"Screen Short Note"}
                      value={this.state.formData?.screen_short_note}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="image">
                <Form.Label column sm="3">
                  Screen Image <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <BrowseFiles
                      onGetFiles={this.handleImage}
                      image_category={'onboarding_screen'}
                      helpText={'Image size 1024 x 1024'}
                      images={this.state?.formData?.image ? [this.state?.formData?.image] : ''}
                      onRemoveItem={() => {
                        const newFormData = {...this.state.formData}
                        delete newFormData.image
                        this.setState({formData: newFormData})
                      }}/>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="onboardingScreenSave">
                <Form.Label column sm="3"/>
                <Col sm="9" md={8} lg={7}>
                  <Button variant={"primary"} type="submit">Save</Button>
                </Col>
              </Form.Group>
            </Form>
          </div>
        </div> : null}

            <div className="card-block">

          <div className="block-header">
            <h4 className="block-title">Onboarding Screen </h4>
          </div>

          {getLocalAuthData()?.permission?.includes('service_list') ? <div className="block-body">
            {this.renderService()}
          </div> :null}

        </div>

        </div>
        <ToastsContainer store={ToastsStore}/>
      </App>
    );
  }
}

export default withRouter(OnboardingScreens);
