import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import Select from "react-select";
import styleReactSelect from "../../assets/styleReactSelect";
import {getMerchantList, getMerchantTypeList} from "../../services/merchantServices";
import {getStoreList} from "../../services/storeServices";
import {getCategoryList} from "../../services/categoryServices";
import {getProductListAlt, getProductListSecondary} from "../../services/productServices";
import {IoIosArrowRoundBack, IoMdCheckmark} from "react-icons/all";
import {
  getCommissionSettingsDetails, updateCommissionSettings
} from "../../services/commissionSettingsServices";
import {toast} from "react-toastify";
import {Link, withRouter} from "react-router-dom";
import Pagination from "react-js-pagination";
import {getLocalAuthData} from "../../helper/utils";
import {activityLogPostData} from "../../services/commonServices";

class EditCustomCommissionSettings extends Component {
  state = {
    merchants: [],
    merchantList: [],
    selectedMerchantList: [],
    categoryList: [],
    products: [],
    selectedProductList: [],
    merchantTypes: [],
    selectedStoreList: [],
    selectedCategoryList: [],
    isLoading: true,
    selectedProducts: [],
    formData: {
      products: [],
      valueType: "2"
    },
    isProductModalProductShowing: false,
    isSubmitting: false,
    defaultQuerySettings: {
      merchantList: {lite_data: 1},
      storeList: {merchants: this.merchants || [], status: 1, approval_status: 1, lite_data: 1},
      productList: {limit: 20}
    },
    isProductLoading: false
  }

  componentDidMount() {
    const commissionId = this.props.match.params.id

    const merchantTypeList = getMerchantTypeList().then(res => {
      const merchantTypes = [...this.state.merchantTypes];
      res.forEach(item => {
        merchantTypes.push({label: item.type_name, value: item._id})
      });
      this.setState({merchantTypes: merchantTypes, merchants: res})
    })

    const merchantList = getMerchantList().then(res => {
      const merchantList = [...this.state.merchantList];
      res.forEach(item => {
        const merchant = {id: item._id, value: item._id, label: item.name}
        merchantList.push(merchant)
      })
      this.setState({merchants: res, merchantList})
    })

    const categoryList = getCategoryList().then(res => {
      const categories = []
      res.forEach(item => {
        categories.push({label: item.name, value: item._id})
      })
      this.setState({categoryList: categories})
    })

    const storeList = getStoreList({lite_data: 1}).then(res => {
      const stores = []
      res.items.forEach(item => {
        stores.push({label: item.store_name, value: item._id})
      })
      this.setState({storeList: stores})
    })

    const productList = getProductListSecondary(this.state.defaultQuerySettings.productList).then(res => {
      // const products = []
      // res.products.forEach(item => {
      //   products.push({id: item._id, value: item._id, label: item.name})
      // });
      this.setState({
        products: res.products,
        productPagination: res.pagination,
        limit: 20
      })
    })

    Promise.all([merchantTypeList, merchantList, categoryList, storeList, productList]).then(res => {
      getCommissionSettingsDetails(commissionId).then(res => {

        if(res.status === 1){

          const formData = {...this.state.formData}
          const selectedStoreList = []
          const selectedCategoryList = []
          const selectedProductList = []
          const selectedMerchantList = []
          const selectedMerchantTypeList = []
          let merchantTypeIds = []
          let merchantIds = []
          let storeIds = []
          let categoryIds = []
          let productIds = []

          let commission_info = res?.commission_info;

          formData.admin_commission_percentage = commission_info?.admin_commission_percentage
          formData.admin_max_commission_value = commission_info?.admin_max_commission_value
          formData.merchant_commission_percentage = commission_info?.merchant_commission_percentage
          formData.merchant_max_commission_value = commission_info?.merchant_max_commission_value
          formData.enable_override = commission_info?.enable_override?commission_info?.enable_override:false

          commission_info.merchant_types.forEach(item => {
            const merchantType = this.state.merchantTypes.filter(obj => {
              return obj.value === item._id
            })

            if (merchantType.length > 0) {
              selectedMerchantTypeList.push(merchantType[0])
              merchantTypeIds.push(merchantType[0]._id)
            }
          })

          commission_info.categories.forEach(item => {
            const category = this.state.categoryList.filter(obj => {
              return obj.value === item._id
            })

            if (category.length > 0) {
              selectedCategoryList.push(category[0])
              categoryIds.push(category[0].value)
            }
          })

          commission_info.merchants.forEach(item => {
            const merchant = this.state.merchantList.filter(obj => {
              return obj.value === item._id
            })

            if (merchant.length > 0) {
              selectedMerchantList.push(merchant[0])
              merchantIds.push(merchant[0].value)
            }
          })

          commission_info.stores.forEach(item => {
            const store = this.state.storeList.filter(obj => {
              return obj.value === item._id
            })

            if (store.length > 0) {
              selectedStoreList.push(store[0])
              storeIds.push(store[0].value)
            }
          })

          formData.merchant_types = merchantTypeIds
          formData.merchants = merchantIds
          formData.stores = storeIds
          formData.categories = categoryIds
          formData.products = productIds
          console.log("formData");
          console.log(formData);
          this.setState({
            formData,
            selectedStoreList,
            selectedCategoryList,
            selectedMerchantList,
            selectedMerchantTypeList,
            selectedProductList: commission_info.products,
            isLoading: false
          }, () => {
            console.log(this.state.selectedStoreList);
            console.log(this.state.selectedStoreList);
          })
        }


      }).catch(errMsg => {
        console.error(errMsg)
        this.setState({isLoading: false})
      });
    }).catch(err => {
      this.setState({isLoading: false})
    });
  }

  handleInputOnChange = (e) => {
    const formData = {...this.state.formData}
    formData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({formData})
  }

  handleStoreOnChange = (value) => {
    const formData = {...this.state.formData}
    const stores = []

    if (value?.length > 0) {
      value.forEach(item => {
        stores.push(item.value)
      })
      formData.stores = stores

      this.setState({formData})
    } else {
      getProductListAlt().then(res => {
        formData.stores = []
        this.setState({products: res, formData})
      })
    }
  }

  handleShowSelectProductModal = () => {
    this.setState({isProductLoading: true}, () => {
      getProductListSecondary({
        merchants: JSON.stringify(this.state.formData?.merchants || []),
        stores: JSON.stringify(this.state.formData?.stores || []),
        categories: JSON.stringify(this.state.formData?.categories || []),
        limit: 20
      }).then(res => {
        this.setState({
          products: res.products,
          productPagination: res.pagination,
          isProductModalProductShowing: true,
          isProductLoading: false
        })
      })
        .catch(err => {
          this.setState({isProductLoading: false})
        })
    })
  }

  handleAddProduct = (selectedProducts) => {
    const formData = {...this.state.formData}
    const products = []

    selectedProducts.forEach(item => {
      const product = this.state.products.filter(obj => {
        return obj._id === item
      })
      products.push(product[0])
    })

    formData.products = selectedProducts

    this.setState({
      selectedProductList: products,
      formData,
      isProductModalProductShowing: false,
      selectedProducts: []
    })
  }

  handleSelectOnChange = (name, value) => {
    const formData = {...this.state.formData}
    const items = []
    if (value?.length > 0) {
      value.forEach(item => {
        items.push(item.value)
      })
    }
    formData[name] = items

    console.log('Form Data: ', formData)

    this.setState({formData}, () => {
      if (name === 'merchants') {
        getStoreList({
          ...this.state.defaultQuerySettings.storeList,
          merchants: JSON.stringify(this.state.formData.merchants || [])
        })
          .then(res => {
            const stores = []
            res.items.forEach(item => {
              stores.push({
                label: item.store_name,
                value: item._id
              })
            })
            this.setState({storeList: stores})
          })
      } else if (name === 'merchant_types') {
        getMerchantList({
          ...this.state.defaultQuerySettings.merchantList,
          merchant_types: JSON.stringify(this.state.formData.merchant_types)
        }).then(res => {
          const merchantList = []
          res.forEach(item => {
            merchantList.push({id: item._id, value: item._id, label: item.name})
          })
          this.setState({merchantList: merchantList, isLoading: false})
        })
      }
    })
  }

  handleSelectedProductOnChange = (e, id) => {
    const selectedProducts = [...this.state.selectedProducts];

    if (e.target.checked) {
      if (e.target.type === 'checkbox') {
        selectedProducts.push(id);
      } else {
        selectedProducts[0] = id;
      }
    } else {
      selectedProducts.splice(selectedProducts.indexOf(id), 1);
    }

    this.setState({selectedProducts});
  }

  handleRemoveSelectedProduct = (index) => {
    const selectedProductList = [...this.state.selectedProductList]
    const formData = {...this.state.formData}
    selectedProductList.splice(index, 1);
    formData.products.splice(index, 1);
    this.setState({selectedProductList, formData})
  }

  handleHideProductModal = () => {
    this.setState({isProductModalProductShowing: false})
  }

  handleSubmitForm = (e) => {
    e.preventDefault()
    const commissionId = this.props.match.params.id

    this.setState({isSubmitting: true}, () => {
      updateCommissionSettings(commissionId, this.state.formData)
        .then(res => {
          this.props.history.push(`/commission-settings/custom-settings`)
          // TODO: It will be updated
          // toast.success(res.message)
          this.setState({isSubmitting: false})
          toast.success('Commission setting successfully added')
          activityLogPostData('was update this commission setting','/commission-settings/custom-settings/edit/'+commissionId,null)
        })
        .catch(errMsg => {
          toast.error(errMsg)
          this.setState({isSubmitting: false})
        })
    })
  }

  handleSearchProduct = (val) => {
    getProductListSecondary({
      merchants: JSON.stringify(this.state.formData?.merchants || []),
      stores: JSON.stringify(this.state.formData?.stores || []),
      categories: JSON.stringify(this.state.formData?.categories || []),
      limit: 20,
      search_string: val
    }).then(res => {
      this.setState({
        products: res.products,
        productPagination: res.pagination
      }, () => console.log(this.state));
    })
  }

  render() {
    const {formData} = this.state
    return (
      <App layout={`boxed`}>
        <div className="page-header">
          <div/>
          <div className="ml-auto">
            <Link to={`${process.env.PUBLIC_URL}/commission-settings/custom-settings`}><IoIosArrowRoundBack/> Back to
              List</Link>
          </div>
        </div>

        {getLocalAuthData()?.permission?.includes('custom_setting_update') ?
            <div className="card-block">
              <div className="block-header">
                <h4 className="block-title">Commission Settings Details</h4>
              </div>
              <div className="block-body">
                {this.state.isLoading ?
                    <div>Loading...</div>
                    :
                    <Form onSubmit={this.handleSubmitForm}>
                      {/*<Form.Group as={Row} controlId="formStore">
                  <Form.Label column sm="3">
                    Commission (%) <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control type={`number`} max={100} min={0}
                                  name={`value`}
                                  defaultValue={formData.value}
                                  onChange={this.handleInputOnChange}
                                  required placeholder={`Enter commission value`}/>
                  </Col>
                </Form.Group>*/}

                      <Form.Group as={Row} controlId="formAdminCommission">
                        <Form.Label column sm="3">
                          Admin Commission (%) <span className="text-danger">*</span>
                        </Form.Label>

                        <Col sm="4" md={4} lg={3}>
                          <Form.Control type={`number`}
                                        onChange={this.handleInputOnChange}
                                        defaultValue={this.state.formData?.admin_commission_percentage}
                                        name={'admin_commission_percentage'}
                                        min={0} max={100} step={.1} required placeholder={`Enter commission percentage`}/>
                        </Col>

                        <Col sm="4" md={4} lg={3}>
                          <Form.Control type={`number`}
                                        onChange={this.handleInputOnChange}
                                        defaultValue={this.state.formData?.admin_max_commission_value}
                                        name={'admin_max_commission_value'}
                                        min={0} placeholder={`Enter max commission value`}/>
                        </Col>

                      </Form.Group>

                      <Form.Group as={Row} controlId="formMerchantCommission">
                        <Form.Label column sm="3">
                          Merchant Commission (%) <span className="text-danger">*</span>
                        </Form.Label>

                        <Col sm="4" md={4} lg={3}>
                          <Form.Control type={`number`}
                                        onChange={this.handleInputOnChange}
                                        defaultValue={this.state.formData?.merchant_commission_percentage}
                                        name={'merchant_commission_percentage'}
                                        min={0} max={100} step={.1} required placeholder={`Enter commission percentage`}/>
                        </Col>

                        <Col sm="4" md={4} lg={3}>
                          <Form.Control type={`number`}
                                        onChange={this.handleInputOnChange}
                                        defaultValue={this.state.formData?.merchant_max_commission_value}
                                        name={'merchant_max_commission_value'}
                                        min={0} placeholder={`Enter max commission value`}/>
                        </Col>

                      </Form.Group>


                      <Form.Group as={Row} controlId="formEnableOverride">
                        <Form.Label column sm="3">Override Other Setting {this.state.formData?.enable_override?true:false}</Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Check
                              custom inline type="checkbox" name="enable_override" id={`enableOverride`}
                              label={``} value={true}
                              onChange={this.handleInputOnChange}
                              checked={this.state.formData?.enable_override?true:false}
                          />
                        </Col>
                      </Form.Group>


                      {this.state.merchantTypes.length > 0 &&
                      <Form.Group as={Row} controlId="formMerchantType">
                        <Form.Label column sm="3">
                          Merchant Type
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Select
                              isMulti
                              styles={styleReactSelect}
                              placeholder="Select Merchant Type..."
                              onChange={(value) => {
                                this.handleSelectOnChange('merchant_types', value)
                              }}
                              options={this.state.merchantTypes}
                              defaultValue={this.state.selectedMerchantTypeList}
                              name="merchant_types"
                              noOptionsMessage={() => 'Please insert new value...'}
                          />
                        </Col>
                      </Form.Group>
                      }


                      <Form.Group as={Row} controlId="formMerchant">
                        <Form.Label column sm="3">
                          Merchants
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Select
                              isMulti
                              styles={styleReactSelect}
                              placeholder="Select merchant..."
                              onChange={(value) => {
                                this.handleSelectOnChange('merchants', value)
                              }}
                              options={this.state.merchantList}
                              defaultValue={this.state.selectedMerchantList}
                              name="attributes"
                              noOptionsMessage={() => 'Please insert new value...'}
                          />
                          <Form.Text></Form.Text>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formStore">
                        <Form.Label column sm="3">
                          Stores
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Select
                              isMulti
                              styles={styleReactSelect}
                              placeholder="Select store..."
                              onChange={this.handleStoreOnChange}
                              options={this.state.storeList}
                              defaultValue={this.state.selectedStoreList}
                              name="attributes"
                              noOptionsMessage={() => 'Please insert new value...'}
                          />
                        </Col>
                      </Form.Group>


                      <Form.Group as={Row} controlId="formCategory">
                        <Form.Label column sm="3">
                          Categories
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Select
                              isMulti
                              styles={styleReactSelect}
                              defaultValue={this.state.selectedCategoryList}
                              placeholder="Select category..."
                              onChange={(value) => {
                                this.handleSelectOnChange('categories', value)
                              }}
                              options={this.state.categoryList}
                              name="attributes"
                              noOptionsMessage={() => 'Select store...'}
                          />
                        </Col>
                      </Form.Group>

                      <Modal show={this.state.isProductModalProductShowing}
                             onHide={this.handleHideProductModal}>
                        <Modal.Header closeButton>
                          <Modal.Title>Select Product</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="mb-3 form-inline">
                            <Form.Group>
                              <Form.Label srOnly>Search</Form.Label>
                              <Form.Control type={`search`}
                                            size={`sm`}
                                            onChange={(e) => {
                                              this.setState({productSearchString: e.target.value})
                                            }}
                                            placeholder={`Search...`}/>
                            </Form.Group>

                            <Button type={`button`} size={`sm`} variant={`info`}
                                    onClick={() => {
                                      this.handleSearchProduct(this.state.productSearchString || '')
                                    }} className={`ml-2`}>Search</Button>
                          </div>

                          {this.state.products.length < 1 &&
                          <div className="alert alert-warning text-bold text-center">
                            no product found
                          </div>}

                          {this.state.products.length > 0 &&
                          <>
                            <div className="product-checkbox-list">
                              {this.state.products.map((product, index) => (
                                  <label className={`product-item`} key={this.state.productPagination?.pagingCounter + index}
                                         htmlFor={`product-${index}`}>
                                    <input id={`product-${index}`}
                                           onChange={e => this.handleSelectedProductOnChange(e, product._id)}
                                           name={`product`}
                                           type="checkbox"/>
                                    <span className={`product`}>
                              <span className="product-img">
                                <img src={product.thumb_img} alt=""/>
                              </span>
                              <span className="product-short-info">
                                <span className="product-name">{product.name}</span>
                              </span>
                              <span className="icon"><IoMdCheckmark/></span>
                            </span>
                                  </label>
                              ))}
                            </div>

                            <br/>

                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={this.state.productPagination?.page}
                                itemsCountPerPage={this.state.productPagination?.limit}
                                totalItemsCount={this.state.productPagination?.totalDocs}
                                onChange={(page) => {
                                  getProductListSecondary({
                                    merchants: JSON.stringify(this.state.formData?.merchants || []),
                                    stores: JSON.stringify(this.state.formData?.stores || []),
                                    categories: JSON.stringify(this.state.formData?.categories || []),
                                    page: page,
                                    limit: 20
                                  }).then(res => {
                                    this.setState({
                                      products: res.products,
                                      productPagination: res.pagination
                                    }, () => console.log(this.state));
                                  })
                                }}
                            />
                          </>
                          }
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={this.handleHideProductModal}>
                            Close
                          </Button>
                          <Button variant="primary"
                                  onClick={() => this.handleAddProduct(this.state.selectedProducts)}>
                            Save Changes
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      <Form.Group as={Row} controlId="formProducts">
                        <Form.Label column sm="3">Products</Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <table className={`table table-striped table-bordered`}>
                            <thead className={`sm`}>
                            <tr>
                              <th style={{width: '50px'}}/>
                              <th>Product Name</th>
                              <th style={{width: '1%'}}/>
                            </tr>
                            </thead>

                            <tbody>
                            {this.state?.selectedProductList?.length > 0 ?
                                <>
                                  {this.state?.selectedProductList.map((product, index) => (
                                      <tr key={index}>
                                        <td>
                                          <img src={product?.thumb_img} style={{width: '40px'}} alt=""/>
                                        </td>
                                        <td>{product?.name}</td>
                                        <td><span style={{cursor: 'pointer'}}
                                                  onClick={() => this.handleRemoveSelectedProduct(index)}
                                                  className={`text-bold text-danger`}>&times;</span></td>
                                      </tr>
                                  ))}
                                </>
                                :
                                <tr>
                                  <td colSpan={3} className={`text-center text-bold`}>
                                    No product selected
                                  </td>
                                </tr>
                            }
                            </tbody>
                          </table>

                          <Button size="sm"
                                  disabled={this.state.isProductLoading}
                                  onClick={() => this.handleShowSelectProductModal()}
                                  variant="link"> {!this.state.isProductLoading ? 'Select Products' : 'Please wait...'}</Button>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formUpdate">
                        <Form.Label column sm="3"/>
                        <Col sm="9" md={8} lg={7}>
                          <Button type={`submit`}
                                  disabled={this.state.isSubmitting}
                                  variant={'primary'}>{this.state.isSubmitting ? 'Updating...' : 'Update'}</Button>
                        </Col>
                      </Form.Group>
                    </Form>
                }
              </div>
            </div>
            :null}



      </App>
    );
  }
}

export default withRouter(EditCustomCommissionSettings);
