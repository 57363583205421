import moment from "moment";
import React, { useEffect, useRef, useState } from 'react';
import Barcode from "react-barcode";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { FaPrint } from "react-icons/all";
import { useReactToPrint } from "react-to-print";
import logo from "../assets/img/logo.png";
import { SHOP_BASE_URL } from "../helper/env";
import { getLocalAuthData } from '../helper/utils';
import { fileDownload } from "../services/commonServices";

const InvoiceComponent = ({store, orderDetail, paymentStatus}) => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [offers, setOffers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    //console.log(store.order_items)
    const _offers = {};

    for (let i = 0; i < store.order_items.length; i++) {
      let itemDetail = store.order_items[i];
      if (_offers[itemDetail?.bundle_offer?._id] === undefined) {
        _offers[itemDetail?.bundle_offer?._id] = [];
      }
      _offers[itemDetail?.bundle_offer?._id].push(itemDetail);
    }

    setOffers(_offers);
    // this.setState({offers}, () => {
    //console.log(offers)
    // })
  }, []);

  const renderPromotionLabel = (type) => {
    if (type === '1') {
      return <span className={'promo-badge'}>Quantity</span>
    } else if (type === '2') {
      return <span className={'promo-badge'}>Buy 1 Get 1</span>
    } else if (type === '3') {
      return <span className={'promo-badge'}>Free Gift</span>
    } else if (type === '4') {
      return <span className={'promo-badge'}>Bundle Offer</span>
    }
  }

  const renderPaymentStatus = (status) => {
    let payment_status = {
      0: "Unpaid",
      1: "Partially Paid",
      2: "Paid",
      3: "Cancelled",
      4: "Failed",
      5: "Refund",
      6: "Bank deposit payment in review",
      7: "Bank deposit declined",
    }
    return payment_status[status];
  }

  const renderSelectedShippingEstimatedTime = (shippingOptions = [], shipping_option) => {
    if (shippingOptions.length > 0) {
      let selected_shipping_option = shippingOptions.filter(obj => obj.shipping_option === shipping_option)
      return selected_shipping_option[0].estimated_time;
    } else {
      return "";
    }
  }

  const showItemDetail = itemDetail => {
    let price = 0;
    itemDetail.forEach(item => {
      if (item?.price > price) {
        price = item?.price;
      }
    });
    return (
      itemDetail.map((eachItem) => {
        return (
          <>
            <tr>
              <td>
              <span className="d-block">
                <strong>
                <span>{eachItem?.product_name}
                  {eachItem?.bundle_offer?.promotion_type === "4" && parseInt(eachItem.price) === parseInt(price) && eachItem?.bundle_offer && renderPromotionLabel(eachItem?.bundle_offer?.promotion_type)}
                  {eachItem?.bundle_offer?.promotion_type === "3" && parseInt(eachItem.price) !== parseInt(price) && eachItem?.bundle_offer && renderPromotionLabel(eachItem?.bundle_offer?.promotion_type)}
                </span>
                </strong>
              </span>
                <span className="d-block text-muted">{eachItem?.product_sku_attr_combo?.join('-')}</span>
                {eachItem?.product_dispute?.status === 'Resolved' && <>
                  <span className="d-block text-muted">Refund Amount: {eachItem?.refund_amount ?? 0}</span>
                  <span className="d-block text-muted">Note: {eachItem?.disputeResolveInfo?.note}</span>
                  <a href={'#'} onClick={() => this.handleDisputeResolveInfoModalOpen(eachItem?._id, eachItem)}
                     size={"sm"}
                     variant={true}>Dispute Info
                  </a>
                </>}
                {eachItem?.product_dispute?.status === 'Pending' && <>
                  <span className="d-block text-muted">Dispute Status: {eachItem?.product_dispute?.status}</span>
                </>}
              </td>
              <td>{eachItem?.quantity}</td>
              <td className="text-right">{eachItem?.item_total_price}</td>
            </tr>
          </>
        )
      })
    );
  }

  return (
    <div className="card-block">
      <div className="block-header">
        <h4 className="block-title">{store.store_name}</h4>
        <div className="">
          <Button variant={"default"} onClick={handlePrint} size={"sm"}><FaPrint/> Print</Button>
          <Button disabled={isLoading} style={{marginLeft:'10px'}} variant={"default"} onClick={()=>{

            setIsLoading(true)
            fileDownload(`/order/parcel/${store._id}?export=true`, {export: true}).then((response)=>{
              console.log(response)
              const url = window.URL.createObjectURL(new Blob([response]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${store._id}.pdf`); //or any other extension
              document.body.appendChild(link);
              link.click();
              setIsLoading(false)
            })

            /*getData(`/order/parcel/${store._id}?export=true`).then((res)=>{
              console.log(res)
            })*/

          }} size={"sm"}>{isLoading ? <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
          /> : <FaPrint/>} Download Invoice</Button>
        </div>
      </div>

      <div ref={componentRef} className="print-outer block-body">
        <table className="invoice-table">
          <tbody>
          <tr className="v-top">
            <td>
              <img src={logo} alt="" className="brand-logo"/>
            </td>
            {store?.store_info?<td className="text-right">
              <span className="d-block text-bold">{store?.store_info?.store_name}</span>
              <span className="d-block">{store?.store_info?.address}</span>
              <span className="d-block">{store?.store_info?.phone}</span>
              <span className="d-block">{store?.store_info?.email}</span>
              <a target={`_blank`} href={`${SHOP_BASE_URL}/store/details/${store?.store_info?._id}`}
                 className="d-block">
                {`${SHOP_BASE_URL}/store/${store?.store_info?.store_url}`}
              </a>
            </td>:<td className="text-right">Sorry the store is unavailable</td>}
          </tr>
          <tr>
            <td colSpan="2">
              <h3 className="invoice-title">INVOICE</h3>
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <table>
                <tbody>
                <tr>
                  <td style={{paddingBottom: '20px'}} width={'20%'}>
                    <h4 className="invoice-title-alt">Bill To</h4>
                    {store?.billing_address ? <>
                      <span className="d-block">{store?.billing_address?.name}</span>
                      <span className="d-block">{store?.billing_address?.phone}</span>
                      <span className="d-block">{store?.billing_address?.address}</span>
                      <span className="d-block">
                      {store?.billing_address?.locations?.length > 0 ? store?.billing_address?.locations?.reverse()?.map((loc, index) => (
                        <span>{loc?.name}{store?.billing_address?.locations[index + 1] ? ", " : ""}</span>
                      )) : ""}
                    </span>
                    </> : <span className="d-block">Shipping address</span>}
                  </td>

                  <td style={{paddingBottom: '20px'}} width={'20%'}>
                    <h4 className="invoice-title-alt">Ship To</h4>
                    <span className="d-block">{store?.delivery_address?.recipient_name}</span>
                    <span className="d-block">{store?.delivery_address?.recipient_phone}</span>
                    <span className="d-block">{store?.delivery_address?.address}</span>
                    <span className="d-block">
                      {store?.delivery_address?.locations?.length > 0 ? store?.delivery_address?.locations?.reverse()?.map((loc, index) => (
                        <span>{loc?.name}{store?.delivery_address?.locations[index + 1] ? ", " : ""}</span>
                      )) : ""}
                    </span>
                  </td>


                  <td style={{paddingBottom: '20px'}}>
                    <h4 className="invoice-title-alt">Payment Method</h4>
                    <span className="d-block">
                                      {orderDetail?.payment_method?.name === 'online_payment' ? 'Online Payment' : orderDetail?.payment_method?.name}
                                  </span>
                    <br/>
                    <span className="d-block">Payment Status : {renderPaymentStatus(orderDetail?.payment_status)}</span>
                  </td>
                  {
                    <td style={{paddingBottom: '20px'}}>
                      <h4 className="invoice-title-alt">Shipping Method</h4>
                      <span
                        className="d-block">{store?.selected_shipping_option ? store?.selected_shipping_option : "ekShop Fulfillment"}</span>
                      <span
                        className="d-block">Fee: {store?.parcel_shipping_cost ? store?.parcel_shipping_cost?.toFixed(2) : '0.00'}</span>
                      <span
                        className="d-block">{renderSelectedShippingEstimatedTime(store?.possible_shipping_options, store?.selected_shipping_option || "ekShop Fulfillment")}</span>
                    </td>
                  }


                  <td style={{paddingBottom: '20px'}}>
                                <span
                                  className="d-block"><strong>Created Date:</strong> {moment(orderDetail.createdAt).format('ll')}</span>
                    <span className="d-block">
                                  <Barcode value={store.order_parcel_code} height={50} width={1}/>
                                </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <table className="table table-striped">
                <thead>
                <tr>
                  <th>Item</th>
                  <th>Quantity</th>
                  <th className="text-right">Amount (৳)</th>
                </tr>
                </thead>

                <tbody>
                {Object.keys(offers)?.map(item =>
                  showItemDetail(offers[item])
                )}


                <tr className="text-bold">
                  <td colSpan={2} className="text-right">Subtotal</td>
                  <td className="text-right">{store?.parcel_total_price.toFixed(2)}</td>
                </tr>

                <tr className="text-bold">
                  <td colSpan={2} className="text-right">Discount</td>
                  <td className="text-right">{store?.parcel_total_discount.toFixed(2)}</td>
                </tr>

                <tr className="text-bold">
                  <td colSpan={2} className="text-right">Shipping Cost</td>
                  <td className="text-right">{store?.parcel_shipping_cost}</td>
                </tr>

                <tr className="text-bold">
                  <td colSpan={2} className="text-right">Grand Total</td>
                  <td className="text-right">{store?.parcel_grand_total}</td>
                </tr>

                {(store?.parcel_wallet_amount > 0) &&
                <tr className="text-bold">
                  <td colSpan={2} className="text-right">Wallet Amount</td>
                  <td className="text-right">{store?.parcel_wallet_amount}</td>
                </tr>}

                {(store?.parcel_total_paid > 0) &&
                <tr className="text-bold">
                  <td colSpan={2} className="text-right">Total Paid</td>
                  <td className="text-right">{store?.parcel_total_paid}</td>
                </tr>}

                {(store?.parcel_wallet_amount > 0) &&
                <tr className="text-bold">
                  <td colSpan={2} className="text-right">Due</td>
                  <td className="text-right">{store?.parcel_total_due}</td>
                </tr>}

                {/* {this.state.orderDetails?.voucher_code_discount && <tr className="text-bold">
                  <td colSpan={2} className="text-right">Overall Ekshop Discount</td>
                  <td
                    className="text-right">{this.state.orderDetails?.voucher_code_discount}</td>
                  <td/>
                </tr>} */}
                {(getLocalAuthData()?.token && getLocalAuthData().type === "2") &&
                <tr className="text-bold">
                  <td colSpan={2} className="text-right">Merchant Commission</td>
                  <td className="text-right">{store?.merchant_commission}</td>
                </tr>}

                {(store?.refund_amount > 0) &&
                <tr className="text-bold">
                  <td colSpan={2} className="text-right">Refund Amount</td>
                  <td className="text-right">{store?.refund_amount}</td>
                </tr>}

                </tbody>
              </table>
            </td>
          </tr>
          </tbody>
        </table>
        {(orderDetail?.payment_method?.name === "Bank Payment") &&
        <>
          <div className="alert alert-info">
            <h6 className="d-block mb-3">Deposit Information</h6>
            <Row>
              <Col sm={6} lg={3}>
                <Form.Group className={'mb-2'} controlId={'deposit_date'}>
                  <Form.Label>Bank Name</Form.Label>
                  <strong className="d-block">Bank Asia</strong>
                </Form.Group>
              </Col>
              <Col sm={6} lg={3}>
                <Form.Group className={'mb-2'} controlId={'deposit_date'}>
                  <Form.Label>Account Name</Form.Label>
                  <strong className="d-block">ASPIRE</strong>
                </Form.Group>
              </Col>
              <Col sm={6} lg={3}>
                <Form.Group className={'mb-2'} controlId={'deposit_date'}>
                  <Form.Label>Branch</Form.Label>
                  <strong className="d-block">BALBBDDH</strong>
                </Form.Group>
              </Col>
              <Col sm={6} lg={3}>
                <Form.Group className={'mb-2'} controlId={'deposit_date'}>
                  <Form.Label>Account Number</Form.Label>
                  <strong className="d-block">06933001008</strong>
                </Form.Group>
              </Col>
              <Col sm={6} lg={3}>
                <Form.Group className={'mb-2'} controlId={'deposit_date'}>
                  <Form.Label>Routing No</Form.Label>
                  <strong className="d-block">070264034</strong>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className="alert alert-info mb-2">
            <Row>
              <Col md={6}>
                {/*account_number: "06933001008"
                                amount: "95243"
                                bank_name: "Bank Asia"
                                branch_name: "Gulshan"
                                deposit_by: "John Doe"
                                deposit_date: "2021-01-05T13:01:30.000Z"
                                deposit_slip: "https://ekshop-dev.s3-us-west-1.amazonaws.com/1609851712005_presentboxwithbigbowjpg.webp"
                                slip_number: "123456"*/}
                <strong className="d-block mb-2">Bank Information</strong>
                <table className="info-table ">
                  <tbody>
                  <tr>
                    <td style={{width: '200px'}}>Deposit By</td>
                    <td style={{width: '20px'}}>:</td>
                    <td>{orderDetail?.deposit_info?.deposit_by}</td>
                  </tr>
                  <tr>
                    <td style={{width: '200px'}}>Deposit Date</td>
                    <td style={{width: '20px'}}>:</td>
                    <td>{moment(orderDetail?.deposit_info?.deposit_date).format('ll')}</td>
                  </tr>
                  <tr>
                    <td style={{width: '200px'}}>Deposited Branch</td>
                    <td style={{width: '20px'}}>:</td>
                    <td>{orderDetail?.deposit_info?.branch_name}</td>
                  </tr>
                  <tr>
                    <td style={{width: '200px'}}>Slip Number</td>
                    <td style={{width: '20px'}}>:</td>
                    <td>{orderDetail?.deposit_info?.slip_number}</td>
                  </tr>
                  </tbody>
                </table>
              </Col>

              <Col md={6}>
                <strong className="d-block mb-2">Deposit Slip</strong>
                {orderDetail?.deposit_info?.deposit_slip &&
                <img src={orderDetail?.deposit_info?.deposit_slip}
                     style={{maxWidth: '100%', height: "auto"}} alt=""/>
                }
              </Col>
            </Row>
          </div>
        </>
        }
      </div>
    </div>
  );
};

export default InvoiceComponent;
