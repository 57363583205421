import axois from "axios";
import config, {API_BASE_URL} from "../helper/env";
import axios from "axios";
import {getDeviceType, getLocalAuthData} from "../helper/utils";


export const getNotifications = () => {
    const authData = getLocalAuthData();

    //console.log("Token ::: "+authData == null ? "" : authData.token)

    return new Promise((resolve, reject) => {
        axois.get(`${API_BASE_URL}/notification`, {
            headers: {
                'x-auth-token': authData == null ? "" : authData.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            if (res.data.status) {
                resolve(res.data)
            }
        }).catch(err => {
            reject(err.message)
        })
    })
}



export const updateReadStatus = (id) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axois.patch(`${API_BASE_URL}/notification/changeStatus/${id}`, {"read_from":"admin"}, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            if (res.data.status === 1) {
                resolve(res.data)
            } else {
                reject(res.data)
            }
        }).catch(err => {
            reject(err.message)
        })
    })
}



//
// export const submitSlider = () => {
//     return new Promise((resolve, reject) => {
//         axois.post(`${API_BASE_URL}/slider`)
//             .then(res => {
//                 if (res.data.status) {
//                     resolve(res.data.items)
//                 }
//             })
//             .catch(err => {
//                 reject(err.message)
//             })
//     })
// }
//
// export const deleteSlider = (id) => {
//     return new Promise((resolve, reject) => {
//         axios.delete(`${API_BASE_URL}/slider/${id}/permanent/`)
//             // axois.post(`${API_BASE_URL}/slider`)
//             .then(res => {
//                 if (res.data.status) {
//                     resolve(res.data)
//                 }
//             })
//             .catch(err => {
//                 reject(err.message)
//             })
//     })
// }
//
// export const deleteBanner = (id) => {
//     return new Promise((resolve, reject) => {
//         axios.delete(`${API_BASE_URL}/banner/${id}/permanent/`)
//             // axois.post(`${API_BASE_URL}/slider`)
//             .then(res => {
//                 if (res.data.status) {
//                     resolve(res.data)
//                 }
//             })
//             .catch(err => {
//                 reject(err.message)
//             })
//     })
// }
//
// export const updateBanner = (id) => {
//     return new Promise((resolve, reject) => {
//         axios.put(`${API_BASE_URL}/banner/${id}`)
//             // axois.post(`${API_BASE_URL}/slider`)
//             .then(res => {
//                 if (res.data.status) {
//                     resolve(res.data)
//                 }
//             })
//             .catch(err => {
//                 reject(err.message)
//             })
//     })
// }
//
// export const detailsBanner = (id) => {
//     const authData = getLocalAuthData();
//     return new Promise((resolve, reject) => {
//         axios.get(`${API_BASE_URL}/banner/${id}`, {
//             headers: {
//                 "x-auth-token": authData.token,
//                 "x-api-client": 'web'
//             }
//         })
//             .then(res => {
//                 if (res.data.status) {
//                     resolve(res.data)
//                 }
//             })
//             .catch(err => {
//                 reject(err.message)
//             })
//     })
// }
