import axios from "axios";
import {getDeviceType, getLocalAuthData} from "../helper/utils";
import {API_BASE_URL} from "../helper/env";

export const toggleStatusUpdate = (id, data) => {
    const authData = getLocalAuthData()
    return new Promise((resolve, reject) => {
        axios.patch(`${API_BASE_URL}/bank/changeStatus/${id}`, data, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            if (res.data.status === 1) {
                resolve(res.data)
            } else {
                reject(res.data.message)
            }
        }).catch(err => {
            console.error(err)
            reject('Something went wrong')
        })
    })
}



