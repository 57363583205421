import React, {Component} from 'react';
import "../assets/scss/delivery-progress-timeline.scss";
import moment from "moment";

class DeliveryProgressTimeline extends Component {
  render() {
    const {data, ...rest} = this.props

    return (
      <div {...rest} className={data?.length ? `delivery-progress-timeline grid-${data.length}` : `delivery-progress-timeline grid-1`}>
        <span className="progress-line"/>

        {(data && data?.length > 0) &&
          <div className="timeline-inner">
            {data.map((item, index) => (
              <div key={index} className={item.is_completed ? 'progress-block completed' : 'progress-block'}>
                <div className="date">
                  {item.is_completed ? moment(item.createdAt).format('ll')
                    :
                    (item?.possible_date ? moment(item.possible_date).format('ll') : '-')}
                </div>
                <div className="circle"/>
                <div className="text">
                  <h4>{item.status}</h4>
                  <p>{item.message}</p>
                </div>
              </div>
            ))}
          </div>}
      </div>
    );
  }
}

export default DeliveryProgressTimeline;
