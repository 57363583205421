import React, {Component} from 'react';
import App from "../../App";
import {Badge, Button, Col, Form, Row} from "react-bootstrap";
import BrowseFiles from "../../components/BrowseFiles";
// import Bank from "../../components/Bank";
import axios from "axios";
import "../../assets/scss/order-list.scss";
import config from "../../helper/env";
import {ToastsContainer, ToastsStore} from "react-toasts";
import {toast} from "react-toastify";
import {getDeviceType, getLocalAuthData, headerData} from "../../helper/utils";
import {Link} from "react-router-dom";
import {FiEdit, FiMessageSquare, FiTrash} from "react-icons/all";
import {confirmAlert} from "react-confirm-alert";
import Menu from "../../components/Menu";

// import {toggleStatusUpdate} from "../../services/bankServices";

class MenuManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            bankData: [],
            editFormData: null,
            editStatus: false
        }
    }

    componentDidMount() {
        this.renderData();
    }

    renderData = () => {
        axios.get(`${config.API_BASE_URL}/menu`, headerData)
            .then(res => {
                console.log(res.data.items);
                this.setState({bankData: res.data.items});
            }).catch(e => this.setState({error: true}));
    }

    handleImage = (files) => {
        this.setState({
            formData: {
                ...this.state.formData
            }
        });
    }

    handleFormDataChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value,
                [e.target.link]: e.target.value,
                [e.target.icon]: e.target.value,
                [e.target.sequence]: e.target.value,

            }
        });
        setTimeout(() => {
            console.log(this.state.formData);
        }, 3000);
    };

    deleteMenu = (id) => {

        this.setState({onClickDelete:true})

        axios.delete(`${config.API_BASE_URL}/menu/${id}`, headerData) //Permanent delete
            .then(response => {
                this.setState({onClickDelete:false})
                if (response.data.status === 1) {
                    ToastsStore.success(response.data.message);
                    this.renderData();
                }
                if (response.data.status === 2) {
                    ToastsStore.error(response.data.message);
                }
            }).catch(error => {
            ToastsStore.error("Error");
        });
    };

    editMenu = (id) => {
        axios.get(`${config.API_BASE_URL}/menu/${id}`, headerData).then(res => {

            console.log(res)

            this.setState({
                editFormData: res.data.menu,
                formData: {
                    // ...this.state.formData,
                    name: res.data.menu.name,
                    name_local: res.data.menu.name_local,
                    link: res.data.menu.link,
                    icon: res.data.menu.icon,
                    sequence: res.data.menu.sequence,


                },
                editStatus: true
            });
            window.scrollTo({top: 0, behavior: 'smooth'});
        }).catch(error => {
            ToastsStore.error("Error");
        });
    };

    renderBank = () => {
        const bankList = this.state.bankData;
        if (bankList) {
            return (
                <Row>
                    {bankList.map((bank, index) => {
                            return (
                                <Col md={3}>
                                    {/*<Bank
                    imageLink={bank.image} bankId={bank._id}
                    deleteBank={() => this.deleteBank(bank._id)}
                    editBank={() => this.editBank(bank._id)}
                  />*/}
                                </Col>
                            )
                        }
                    )}
                </Row>
            )
        }

        return (
            <Form.Control as="select">
                <option>Fetching Bank</option>
            </Form.Control>
        )
    };


    handleAddProductImages = async (files) => {

        console.log(files[0].mid_img)

        this.setState({
            formData: {
                ...this.state.formData,
                icon: files[0].mid_img,
            }
        });

    }


    handleImageOnRemove = (index) => {
        // const product = {...this.state.product}
        // const mainImages = [...this.state.mainImages]
        // product.images.splice(index, 1)
        // mainImages.splice(index, 1)
        // this.setState({
        //     product,
        //     mainImages
        // })
    }


    handleSubmitForm = () => {
        this.setState({onClickDelete:true})
        const data = this.state.formData;
        let url = `${config.API_BASE_URL}/menu`;
        if (this.state.editStatus === true) {
            url = `${config.API_BASE_URL}/menu/` + this.state.editFormData._id;
            axios.put(url, data, headerData).then(response => {
                this.setState({onClickDelete:false})
                if (response.data.status === 1) {
                    toast.success(response.data.message);
                    this.renderData();
                }
                if (response.data.status === 2) {
                    toast.error(response.data.message);
                }
            }).catch(error => {
                toast.error("Something went wrong please try again after refresh the page.");
            });
        } else {
            axios.post(url, data, headerData).then(response => {
                this.setState({onClickDelete:false})
                if (response.data.status === 1) {
                    toast.success(response.data.message);
                    this.renderData();
                }
                if (response.data.status === 2) {
                    toast.error(response.data.message);
                }
            }).catch(error => {
                toast.error("Something went wrong please try again after refresh the page.");
            });
        }
    };

    render() {
        return (
            <App layout={"boxed"}>
                <div className="page-header">
                    <h2 className="page-title">Menu Management</h2>
                </div>

                <div className="main-content">
                    <div className="card-block">

                        <div className="block-body">
                            <>
                                {getLocalAuthData()?.permission?.includes('bank_add') ?
                                    <Form id={"bankForm"}>
                                        <Form.Group as={Row} controlId="menuName">
                                            <Form.Label column sm="3">
                                                Menu Name <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control type={"text"}
                                                              defaultValue={this.state.editFormData ? this.state.editFormData.name : ""}
                                                              name="name" onChange={this.handleFormDataChange}
                                                              placeholder={"Menu Name"}/>
                                            </Col>
                                        </Form.Group>


                                        <Form.Group as={Row} controlId="menuName1">
                                            <Form.Label column sm="3">
                                                Menu Name (Local)<span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control type={"text"}
                                                              defaultValue={this.state.editFormData ? this.state.editFormData.name_local : ""}
                                                              name="name_local" onChange={this.handleFormDataChange}
                                                              placeholder={"Name Local"}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="menuLink">
                                            <Form.Label column sm="3">
                                                Menu Link <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control type={"text"}
                                                              defaultValue={this.state.editFormData ? this.state.editFormData.link : ""}
                                                              name="link" onChange={this.handleFormDataChange}
                                                              placeholder={"Menu Link"}/>
                                            </Col>
                                        </Form.Group>


                                        {/*<Form.Group as={Row} controlId="menuIcon">*/}
                                        {/*    <Form.Label column sm="3">*/}
                                        {/*        Menu Icon <span className="text-danger">*</span>*/}
                                        {/*    </Form.Label>*/}
                                        {/*    <Col sm="9" md={8} lg={7}>*/}
                                        {/*        <Form.Control type={"text"}*/}
                                        {/*                      defaultValue={this.state.editFormData ? this.state.editFormData.icon : ""}*/}
                                        {/*                      name="icon" onChange={this.handleFormDataChange}*/}
                                        {/*                      placeholder={"Menu Icon"}/>*/}
                                        {/*    </Col>*/}
                                        {/*</Form.Group>*/}

                                        <Form.Group as={Row} controlId="sequence">
                                            <Form.Label column sm="3">
                                                Sequence Number <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control type={"number"}
                                                              defaultValue={this.state.editFormData ? this.state.editFormData.sequence : ""}
                                                              name="sequence" onChange={this.handleFormDataChange}
                                                              placeholder={"Sequence Number"}/>
                                            </Col>
                                        </Form.Group>


                                        <Form.Group as={Row} controlId="formImages">
                                            <Form.Label column sm="3">
                                                Menu Icon <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                {/*<Form.Control type="file"/>*/}

                                                <BrowseFiles
                                                    userId={this.state.merchant}
                                                    image_category={'icon'}
                                                    onRemoveItem={this.handleImageOnRemove}
                                                    images={this.state.mainImages}
                                                    onGetFiles={this.handleAddProductImages}
                                                    helpText="Multiple images can be uploaded at once.Maximum 8 pictures. Size between 330x330 and 800x800 px. Obscene image is strictly prohibited."/>
                                            </Col>
                                        </Form.Group>


                                        <Form.Group as={Row} controlId="bankFormSubmit">
                                            <Form.Label column sm="3"/>
                                            <Col sm="9" md={8} lg={7}>
                                                <Button
                                                    variant={"primary"}
                                                    onClick={this.handleSubmitForm}
                                                >Save</Button>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                    : <span className={`text-bold text-center`}> Permission Denied</span>}
                            </>

                        </div>
                    </div>


                    <Menu onClickDelete={this.state.onClickDelete}/>

                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">Menu List</h4>
                        </div>

                        <div className="block-body">
                            {/*{this.renderBank()}*/}
                            {this.state.isLoading ?
                                <>Loading...</>
                                :
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th style={{width: '20px'}}>#</th>
                                        <th>Menu Name</th>
                                        <th>Name Local</th>
                                        <th style={{width: `1%`}}/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {(getLocalAuthData()?.permission?.includes('bank_list') && this.state.bankData?.length > 0) ?
                                        this.state.bankData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                {/*<td>{item.name}</td>*/}
                                                <td>
                                                    <div className="order-item">
                                                        {item?.name}
                                                        <Link
                                                            to={{pathname: item.icon}} target={'_blank'}>
                                                            <br/>Icon
                                                        </Link>
                                                    </div>
                                                </td>

                                                <td>
                                                    {item?.name_local}
                                                </td>


                                                <td className="nowrap text-center">

                                                    {getLocalAuthData()?.permission?.includes('bank_edit') ?
                                                        <span className="action text-bold text-default mr-3 cursor-pointer" onClick={() => this.editMenu(item._id)}><FiEdit/> Edit </span> : null}

                                                    {getLocalAuthData()?.permission?.includes('bank_delete') ?
                                                        <span
                                                            className="action text-bold text-danger mr-3 cursor-pointer"
                                                            onClick={() => this.deleteMenu(item._id)}><FiTrash/> Delete</span>
                                                        : null}
                                                </td>
                                            </tr>
                                        )) :
                                        <tr>
                                            <td colSpan={7} className={`text-bold text-center`}>No item found</td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                            }
                        </div>

                    </div>

                </div>
                <ToastsContainer store={ToastsStore}/>
            </App>
        );
    }
}

export default MenuManagement;
