import axios from 'axios';
import {getDeviceType, getLocalAuthData} from "../helper/utils";
import {API_BASE_URL} from "../helper/env";

export const getBankList = (paramsObj = {}) => {
    const authData = getLocalAuthData()
    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}/bank`, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            },
            params: paramsObj
        })
            .then(res => {
                if (res.data.status === 1 || res.data.status === 2) {
                    resolve(res.data.items)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                reject(err.message)
            })
    })
}

export const getWalletSetting = (paramsObj = {}) => {
    const authData = getLocalAuthData()
    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}/wallet/setting`, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            },
            params: paramsObj
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.message)
        })
    })
}

export const getDisputeSetting = (paramsObj = {}) => {
    const authData = getLocalAuthData()
    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}/dispute/setting`, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            },
            params: paramsObj
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.message)
        })
    })
}

export const saveWalletSetting = (dataObj = {}) => {
    const authData = getLocalAuthData()
    return new Promise((resolve, reject) => {
        axios.post(`${API_BASE_URL}/wallet-setting`, dataObj,{
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.message)
        })
    })
}

export const updateWalletSetting = (dataObj = {}, id) => {
    const authData = getLocalAuthData()
    return new Promise((resolve, reject) => {
        axios.put(`${API_BASE_URL}/wallet-setting/${id}`, dataObj,{
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.message)
        })
    })
}

export const saveDisputeSetting = (dataObj = {}) => {
    const authData = getLocalAuthData()
    return new Promise((resolve, reject) => {
        axios.post(`${API_BASE_URL}/dispute-setting`, dataObj,{
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.message)
        })
    })
}

export const updateDisputeSetting = (dataObj = {}, id) => {
    const authData = getLocalAuthData()
    return new Promise((resolve, reject) => {
        axios.put(`${API_BASE_URL}/dispute-setting/${id}`, dataObj,{
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.message)
        })
    })
}