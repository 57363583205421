import React, {Component, useState, useEffect} from 'react';
import App from "../../App";
import {Button, Col, Form, Row, ToggleButton} from "react-bootstrap";
import Pagination from 'react-js-pagination';
import {FaFileExport, FiPlus} from "react-icons/all";
import "../../assets/scss/order-list.scss";

import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import {Link, useHistory, useParams} from "react-router-dom";
import {getDeviceType, getLocalAuthData} from "../../helper/utils";
import {deleteItem, getDetail, getTableData, getTableDataV1, updateItem} from "../../services/commonServices";
import DatePicker from "react-datepicker";
import Moment from "moment";
import {BsCardImage} from "@react-icons/all-files/bs/BsCardImage";
import {confirmAlert} from "react-confirm-alert";
import {deleteLocation, getLocationList} from "../../services/locationServices";
import {toast} from "react-toastify";
import {GoLinkExternal} from "@react-icons/all-files/go/GoLinkExternal";
import {MdArrowBack} from "@react-icons/all-files/md/MdArrowBack";


const NoticeView = () => {
    const {id} = useParams();
    const history = useHistory()

    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({});
    const authData = getLocalAuthData();
    const [queryParams, setQueryParams] = useState({
        limit: 10,
        page: 1,
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const result = await getDetail('/notice/' + id, queryParams);
        console.log(result.items)
        setData(result.items)
    };

    const deleteData = async (id) => {

    }

    const handlePageChange = async (pageNumber) => {
        const params = {...queryParams, page: pageNumber};
        setQueryParams(params);
        fetchData()
    };


    const handleOnApply = (e, picker) => {
        const startDate = moment(picker.startDate).format('DD/MM/YYYY');
        const endDate = moment(picker.endDate).format('DD/MM/YYYY')
        const params = {
            ...queryParams,
            from_date: startDate,
            to_date: endDate
        };
        setQueryParams(params);
        console.log(queryParams)
    }

    const handleDeleteX = (id) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure to delete this notice?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteItem('/notice/' + id + '/permanent')
                        .then(res => {
                            toast.success(res.message);
                            fetchData()
                        })
                        .catch(errMsg => {
                            toast.error(errMsg)
                        })
                },
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });
    }

    return (
        <App layout="">
            <div className="main-content">
                <div className="page-header">
                    <h2 className="page-title">
                        Notice View
                        {/*<a target={'_blank'}
                           rel="noopener noreferrer"
                           className={'ml-3 text-primary'}
                           href="#"><GoLinkExternal/></a>*/}
                    </h2>
                    <div className="ml-auto">
                        <Link to={`/notice/list`}
                              className={`btn btn-sm btn-link`}><MdArrowBack/> Back to List</Link>
                        {/*<Link to={`${process.env.PUBLIC_URL}/promotions/campaign/add`}
                                  className="btn btn-sm btn-secondary"><FiPlus/> Add Campaign</Link>*/}
                    </div>
                </div>
                <div className="card-block">
                    <h5 className="page-title form-control-md px-2 py-4 ml-1">{data?.title}</h5>
                </div>
                {data?.img &&
                    <div className="card-block">
                        <div className="merchant-campaign-details">
                            <div className="campaign-details-banner">
                                <img src={data.img} alt="banner Image"/>
                            </div>
                        </div>
                    </div>
                }


                <div className="card-block">
                    <div className="campaign-text p-3 ml-1">
                        <p className="campaign-descriptin"><strong>Description: </strong>
                            {data?.message}
                        </p>
                    </div>

                    <div className="p-3">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="sent-to-title">
                                    <p className="mb-2"><strong>Sent to:</strong></p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="card-block-outer">
                                    <div className="card-block border-0 odd-card">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="d-flex justify-content-between align-items-end flex-column">
                                                    <div className="text-dark uddokta-mall-button w-100 text-center">Uddokta mall</div>
                                                    <div className="seen-unseen">
                                                        <p>Seen: 01</p>
                                                        <p>Unseen: 02</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="notice-merchant-right-bottom bg-white p-3">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="merchant-number-space mb-3">Store 1<p className="text-success">Seen: 1/6/2021, 5pm</p></div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="merchant-number-space mb-3">Store 2</div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="merchant-number-space mb-3">Store 3</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="card-block-outer">
                                    <div className="card-block border-0 even-card">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="d-flex justify-content-between align-items-end flex-column">
                                                    <div className="text-dark uddokta-mall-button w-100 text-center">Uddokta mall</div>
                                                    <div className="seen-unseen">
                                                        <p>Seen: 02</p>
                                                        <p>Unseen: 02</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="notice-merchant-right-bottom bg-white p-3">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="merchant-number-space mb-3">Store 4</div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="merchant-number-space mb-3">Store 5</div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="merchant-number-space mb-3">Store 6<p className="text-success">Seen: 1/6/2021, 5pm</p></div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="merchant-number-space">Store 7<p className="text-success">Seen: 1/6/2021, 5pm</p></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="send-text mt-3 h-100 d-flex align-items-center justify-content-start">
                                    <p>Notice seen by:</p>
                                    <button className="btn d-inline-block w-auto btn-sm btn-outline-success ml-3 mb-3">Super Admin</button>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="send-text mt-3 h-100 d-flex align-items-center justify-content-end">
                                    <button
                                        onClick={() => {
                                            history.push('/notice/list')
                                        }}
                                        className="btn d-inline-block w-auto btn-sm btn-outline-danger mb-3">Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </App>
    )

}

export default NoticeView;