import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {FaLongArrowAltLeft, IoMdCheckmark} from "react-icons/all";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Moment from "moment";
import styleReactSelect from "../../assets/styleReactSelect";
import Select from "react-select";
import {submitSellerVoucher} from "../../services/promotionServices";
import {toast} from "react-toastify";
import {getStoreList} from "../../services/storeServices";
import {getLocalAuthData} from "../../helper/utils"
import {activityLogPostData} from "../../services/commonServices";
import LoadingSpinner from "../../components/LoadingSpinner";
import {getSkuProductList} from "../../services/productServices";
import {getData} from "../../services/baseServices";
import moment from "moment";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import Pagination from "react-js-pagination";

const today = Moment(new Date()).format('Y-M-D');

class AddSellerVoucher extends Component {
    state = {
        startDate: moment(new Date("12:00 AM "+today)).format("hh:mm a Y-M-D"),
        endDate: moment(new Date("11:59 PM "+today)).format("hh:mm a Y-M-D"),
        isDiscountTypePercentage: false,
        isApplyForCat: false,
        isTypeVoucherCode: false,
        formData: {
            discount_type: "1",
            apply_to: "2",
            product_skus: null,
            voucher_type: "1",
            start_time: moment(new Date("12:00 AM "+today)).format("hh:mm a Y-M-D"),
            end_time:  moment(new Date("11:59 PM "+today)).format("hh:mm a Y-M-D"),
            collection_start_time: moment(new Date("12:00 AM "+today)).format("hh:mm a Y-M-D"),

        },
        isLoading: true,
        storeList: [],
        storeList2: [],

        formDataV2: {
            products: [],
            products_skus: [],
            /* gift_products: [],
             products_skus: [],
             gift_products_skus: []*/
        },
        block: null,
        isProductListLoading: false,
        productList: [],
        products: [],
        queryData: {status:1, deleted: false, publish_status: 2},
        selected_gift_products: [],
        selected_products: [],
        selected_products_v2: [],
        campaignsList: []
    }

    handleOnApply = (e, picker) => {
        const formData = {...this.state.formData};
        const startDate = Moment(picker.startDate).format('hh:mm a Y-M-D');
        const endDate = Moment(picker.endDate).format('hh:mm a Y-M-D')

        formData.start_time = startDate;
        formData.end_time = endDate;

        this.setState({
            formData,
            startDate: startDate,
            endDate: endDate,
        })
    }

    componentDidMount() {
        this.setState({isLoading: false});
        this.getStores({status: 1, approval_status: 1, lite_data: 1});
        this.getCampaign()

    }

    getCampaign=()=>{
        this.setState({fetchingStore:true})
        getData('/campaign' ,{lite:true}).then((res)=>{
            const campaignsList = [];
            res.items.forEach(item => {
                const store = {
                    id: item._id,
                    value: item._id,
                    label: item.campaign_title
                }
                campaignsList.push(store)
            })
            this.setState({campaignsList, fetchingStore: false},()=>{
                console.log(this.state)
            })

            //this.setState({campaignsList: res.items})
        })
    }

    handleOnChangeDiscountType = (e) => {
        const formData = {...this.state.formData}
        formData.discount_type = e.target.value
        this.setState({formData});

        if (e.target.value === "1") {
            this.setState({isDiscountTypePercentage: false})
        } else {
            this.setState({isDiscountTypePercentage: true})
        }
    }

    handleOnChangeApplicableFor = (e) => {
        const formData = {...this.state.formData}
        formData.apply_to = e.target.value
        formData.stores = []

        this.setState({block: e.target.value})
        if (e.target.value === '0') {
        } else if (e.target.value === '2') {
            this.getStores({status: 1, approval_status: 1, lite_data: 1});
        } else if (e.target.value === '6') {
            this.setState({isApplyForCat: true})
        } else if (e.target.value === '4') {
            console.log('ok')
            this.getCampaign()
        } else {
            this.setState({isApplyForCat: false})
        }
        this.setState({storeList: [], formData}, () => {
            //console.log(this.state.formData);
            //console.log(this.state.formData.apply_to);
        });
    }

    getStores = (paramsObj = {}) => {
        this.setState({fetchingStore: true})

        getStoreList(paramsObj).then(res => {
            const storeList = [];
            res.items.forEach(item => {
                const store = {
                    id: item._id,
                    value: item._id,
                    label: item.store_name
                }
                storeList.push(store)
            })
            this.setState({storeList, fetchingStore: false})
        }).catch(errMsg => {
            this.setState({isLoading: false})
        })
    }

    handleOnChangeVoucherType = (e) => {
        const formData = {...this.state.formData}
        formData.voucher_type = e.target.value;

        if (e.target.value === '2') {
            this.setState({isTypeVoucherCode: true, apply_to: "0"})
        } else {
            this.setState({isTypeVoucherCode: false, apply_to: "2"})
        }
        this.setState({formData})
    }

    handleSelectMultiOnChange = (array, name) => {
        const formData = {...this.state.formData};
        const data = [];
        if (array) {
            array.forEach(val => {
                data.push(val.id)
            })
        }
        formData[name] = data;
        this.setState({formData})
    }

    handleInputOnChange = (e) => {

        if (e.target.name === "search_string"){
            const queryData = {...this.state.queryData};
            queryData[e.target.name] = e.target.value
            this.setState({queryData},()=>{
                console.log(this.state.queryData)
            })
        }else {
            const formData = {...this.state.formData};
            formData[e.target.name] = e.target.value;
            this.setState({formData});
        }
    }

    handleFormSubmit = (e) => {
        console.log(this.state.formData);
        console.log(this.state.formDataV2);
        const data = {...this.state.formData, ...this.state.formDataV2}

        e.preventDefault();
        submitSellerVoucher(data).then(res => {
            if (res.status === 2) {
                toast.warning(res.message);
            } else {
                toast.success(res.message);
                this.props.history.push(`${process.env.PUBLIC_URL}/promotions/seller-voucher`);
                activityLogPostData('was create new seller voucher', '/promotions/seller-voucher/', e.target?.voucher_name)
            }
        }).catch(errMsg => {
            toast.error(errMsg)
        })
    }

    /*START HANDLE MODAL*/
    handleShowSelectProductModal = (type) => {
        console.log(type)
        this.setState({
            isProductModalProductShowing: true,
            productType: type,
        })
    }
    handleHideProductModal = () => {
        this.setState({isProductModalProductShowing: false})
    }

    getProducts = (queryData = {}) => {
        let query = {
            ...queryData,
            limit: this.state?.queryData?.limit,
            /*merchants: JSON.stringify(this.state.formData?.merchants || []),*/
            // stores: JSON.stringify(this.state.formData?.stores || []),
            status:1,
            deleted: false,
            publish_status: 2,
            store_id: this.state.formData?.stores?.[0],
            /*categories: JSON.stringify(this.state.formData?.categories || [])*/
        }

        if (this.state.queryData.search_string) {
            query.search_string = this.state.queryData.search_string;
        }

        this.setState({isProductListLoading: true});
        getSkuProductList(query).then(res => {
            if (res.status === 1) {
                this.setState({productList: res.products, productListPagination: res.pagination, isProductListLoading: false, errorMessage: ""},()=>{
                    console.log("======>>>",this.state)
                })
            } else {
                this.setState({productList: res.products, productListPagination: res.pagination, isProductListLoading: false, errorMessage: "No product available for this store"})
            }
        }).catch(err => {
            this.setState({isProductLoading: false, isProductListLoading: false})
        })
    }

    handleSearchProduct = (e) => {
        const formData = {...this.state.formData};
        const queryData = {...this.state.queryData };
        const finalQuery = {...queryData , ...this.state.customProductQuery}
        this.getProducts(finalQuery);

    }


    handleSearchV2 = (query) => {
        this.setState({ isLoadingStoreData: true }, () => {
            getStoreList({ search_string: query, lite_data: 1 })
                .then((res) => {
                    this.setState({ storeList2: res.items, isLoadingStoreData: false });
                })
                .catch((errMsg) => {
                    this.setState({ isLoadingStoreData: false });
                });
        });
    };

    isProductIncluded = (id) => {
        let type = "products_skus"

        if (this.state.formDataV2[type] === undefined) return false;
        if (this.state.formDataV2[type] === null) return false;
        if (this.state.formDataV2[type].length === 0) return false;
        console.log('sku', id)
        console.log("matched product", this.state.productList.filter(item => item.sku_info._id === id))
        if (this.state.formDataV2[type].includes(id))
            return true;
        else
            return false;
    }


    handleSelectedProductOnChange = (e, id, sku) => {
        let selected_products = [...this.state.selected_products];
        console.log(selected_products)
        let selected_gift_products = [...this.state.selected_gift_products];
        let productsX = [...this.state.products];

        let formDataV2 = {...this.state.formDataV2};
        let price_sum = this.state.price_sum;
        let gift_sum = this.state.gift_sum;

        let checkedProduct = this.state.productList.filter(item => item.sku_info._id === sku);

        if (this.state.productType === "products") {
            if (e.target.checked) {
                selected_products.push(checkedProduct[0])
                formDataV2['products_skus'].push(sku)
                formDataV2['products'].push(id)
                productsX.push(sku)
                //price_sum += checkedProduct[0].sku_info.price;
            } else {
                selected_products = selected_products.filter(item => item.sku_info._id !== checkedProduct[0].sku_info._id);
                formDataV2['products'] = formDataV2['products'].filter(item => item !== id);
                formDataV2['products_skus'] = formDataV2['products_skus'].filter(item => item !== sku);
                // price_sum -= checkedProduct[0].sku_info.price;
            }
        } else {
            /* if (e.target.checked) {
                 selected_gift_products.push(checkedProduct[0])
                 formData['gift_products'].push(id)
                 formData['gift_products_skus'].push(sku)
                 gift_sum += checkedProduct[0].sku_info.price;
             } else {
                 selected_gift_products = selected_gift_products.filter(item => item.sku_info._id !== checkedProduct[0].sku_info._id);
                 formData['gift_products'] = formData['gift_products'].filter(item => item !== id);
                 formData['gift_products_skus'] = formData['gift_products_skus'].filter(item => item !== sku);
                 gift_sum -= checkedProduct[0].sku_info.price;
             }*/
        }
        this.setState({selected_products, selected_gift_products, formDataV2}, () => {
            console.log(this.state.formData);
        });
    }

    handleRemoveSelectedProduct = (type, sku) => {
        let selected_products = [...this.state.selected_products];
        //let selected_gift_products = [...this.state.selected_gift_products];
        let formDataV2 = {...this.state.formDataV2};
        let price_sum = this.state.price_sum;
        let gift_sum = this.state.gift_sum;

        let checkedProduct = this.state.productList.filter(item => item.sku_info._id === sku);

        if (type === "products") {
            selected_products = selected_products.filter(item => item !== checkedProduct[0]);
            formDataV2['products'] = formDataV2['products'].filter(item => item !== sku);
            formDataV2['products_skus'] = formDataV2['products_skus'].filter(item => item !== sku);
            price_sum -= checkedProduct[0]?.sku_info?.price;
        } else {
            /*  selected_gift_products = selected_gift_products.filter(item => item !== checkedProduct[0]);
              formData['gift_products'] = formData['gift_products'].filter(item => item !== sku);
              gift_sum -= checkedProduct[0].sku_info.price;*/
        }

        this.setState({selected_products, formDataV2})
    }


    /*Campaigns*/

   /* handleSelectMultiOnChangeV2 = (array, name) => {

        console.log(":::::::::")
        console.log(array)
        console.log(name)

        const formData = {...this.state.formData};
        const data = [];
        if (array) {
            array.forEach(val => {
                data.push(val.id)
            })
        }
        formData[name] = data;
        this.setState({formData})
    }*/


    handlePageChange = async (pageNumber) => {
        const pageQuery = {...this.state.queryData , ...{page:pageNumber}}
        this.getProducts(pageQuery)
    }

    render() {
        return (
            <App layout="boxed">

                <Modal size={`lg`} show={this.state.isProductModalProductShowing}
                       onHide={this.handleHideProductModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Select Product
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="mb-3 form-inline">
                            <Form.Group className="mr-1 my-2">
                                <Form.Label srOnly>Search</Form.Label>
                                <Form.Control type={`search`} name="search_string" size={`sm`}
                                              placeholder={`Type & Search`}
                                              onChange={(e) => {
                                                  if (e.keyCode !== 13) {
                                                      this.setState({
                                                          customProductQuery: {
                                                              ...this.state.customProductQuery,
                                                              [e.target.name]: e.target.value,
                                                          },
                                                      });
                                                  }
                                              }}
                                />
                            </Form.Group>

                            <div className="position-relative m-1">
                                <AsyncTypeahead
                                    filterBy={() => true}
                                    id="inputStoreList"
                                    isLoading={this.state.isLoadingStoreData}
                                    labelKey="store_name"
                                    minLength={3}
                                    size={"sm"}
                                    onKeyDown={() => {
                                        this.setState({customProductQuery: {...this.state.customProductQuery, sid: ""}});
                                    }}
                                    onSearch={this.handleSearchV2}
                                    options={this.state.storeList2}
                                    placeholder="Search store..."
                                    renderMenuItemChildren={(store, props) => (
                                        <React.Fragment>
                                            <span onClick={() => {
                                                this.setState({customProductQuery: {...this.state.customProductQuery, sid: store._id}})
                                            }}>
                                              {store.store_name}
                                            </span>
                                        </React.Fragment>
                                    )}
                                />
                            </div>
                            <Form.Group className="m-1">
                                <Form.Label srOnly>Product Type</Form.Label>
                                <Form.Control
                                    className="m-1"
                                    as={"select"}
                                    size="sm"
                                    defaultValue={""}
                                    name="campaign_product"
                                    onChange={(e) => {
                                        this.setState({customProductQuery: {...this.state.customProductQuery, [e.target.name]: e.target.value}}, ()=>{
                                            console.log(this.state.customProductQuery);
                                        });
                                    }}
                                >
                                    <option value="">--Product Type--</option>
                                    <option value="true">Campaign Product</option>
                                    <option value="false">Regular Product</option>
                                </Form.Control>
                            </Form.Group>

                            <Button type={`button`} onClick={this.handleSearchProduct}
                                    size={`sm`} variant={`info`}
                                    className={`ml-2`}>Search</Button>
                        </div>


                        {/*<div className="mb-3 form-inline">
                            <Form.Group>
                                <Form.Label srOnly>Search</Form.Label>
                                <Form.Control type={`search`}
                                              onKeyDown={(e) => {
                                                  if (e.keyCode === 13) {
                                                      this.handleInputOnChange(e)
                                                  }
                                              }}
                                              onChange={(e)=>{this.handleInputOnChange(e)}}
                                              name="search_string"
                                              size={`sm`}
                                              placeholder={`Type & Search`}/>
                                 <Button va>Search</Button>
                            </Form.Group>
                            <Button type={`button`} onClick={e => this.handleSearchProduct(e)}
                                    size={`sm`} variant={`info`}
                                    className={`ml-2`}>Search</Button>
                        </div>*/}

                        {
                            this.state.errorMessage ?
                                <h5>{this.state.errorMessage}</h5> :
                                <>
                                    <div className="product-checkbox-list">
                                        {
                                            this.state.isProductListLoading && <LoadingSpinner text={`Loading...`}/>
                                        }

                                        {(this.state.productList && this.state.productList.length > 0) &&  this.state.productList.map((product, index) => (
                                            <label className={`product-item`} key={product.sku_info._id}
                                                   htmlFor={`product-${product.sku_info._id}`}>
                                                {this.state.formData.promotion_type === '3' ?
                                                    <input id={`product-${product.sku_info._id}`}
                                                           onChange={e => this.handleSelectedProductOnChange(e, product.sku_info._id)}
                                                           name={`product`}
                                                           defaultChecked={this.isProductIncluded(product.sku_info._id)}
                                                           type="checkbox"/>
                                                    :
                                                    <input id={`product-${product.sku_info._id}`}
                                                           onChange={e => this.handleSelectedProductOnChange(e, product._id, product.sku_info._id)}
                                                           name={`product`}
                                                           defaultChecked={this.isProductIncluded(product.sku_info._id)}
                                                           type="checkbox"/>
                                                }
                                                <span className={`product`}>
                            <span className="product-img">
                              <img
                                  src={product?.mid_img ? product?.mid_img : product?.thumb_img}
                                  alt=""/>
                            </span>
                            <span className="product-short-info">
                              <span
                                  className="product-name">{product.name} xxx {product?.sku_info?.attr_combo ? product?.sku_info?.attr_combo.join('-') : ''}</span>
                            </span>
                            <span className="icon"><IoMdCheckmark/></span>
                          </span>
                                            </label>
                                        ))}
                                    </div>
                                </>
                        }

                        <div className="mt-3">
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={this.state?.productListPagination?.page}
                                itemsCountPerPage={this.state?.productListPagination?.limit}
                                totalItemsCount={this.state?.productListPagination?.totalDocs ? this.state?.productListPagination?.totalDocs : 0}
                                pageRangeDisplayed={10}
                                onChange={this.handlePageChange.bind(this)}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleHideProductModal}>
                            Close
                        </Button>
                        {/*<Button variant="primary"
                            onClick={() => this.handleAddProduct(this.state.productType, this.state.selectedProducts, this.state.Products_sku)}>
                      Save Changes
                    </Button>*/}
                    </Modal.Footer>
                </Modal>


                <div className="page-header">
                    <h2 className="page-title">Add Seller Voucher</h2>
                    <div className="ml-auto">
                        <Link to={`${process.env.PUBLIC_URL}/promotions/seller-voucher`}
                              className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Seller
                            Voucher List</Link>
                    </div>
                </div>
                {getLocalAuthData()?.permission?.includes('seller_voucher_add') ?
                    <div className="main-content">
                        {this.state.isLoading ?
                            <div className="card-block">
                                <div className="block-body">Loading...</div>
                            </div>
                            :
                            <>
                            <div className="card-block">
                                <div className="block-body">
                                    <Form>
                                        <Form.Group as={Row} controlId="formVoucherName">
                                            <Form.Label column sm="3">
                                                Voucher Name <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control onChange={this.handleInputOnChange}
                                                              name={"voucher_name"}
                                                              type="text" placeholder=""/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formStartFrom">
                                            <Form.Label column sm="3">
                                                Start and End Date <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <label htmlFor="dateRange" className="sr-only">Date Range</label>
                                                <DateRangePicker
                                                    timePicker={true}
                                                    dateFormat="hh:mm a yyyy-MM-dd"
                                                    containerStyles={{
                                                    textAlign: 'left',
                                                    width: '100%',
                                                    display: 'block'
                                                }}
                                                                 onApply={this.handleOnApply}>
                                                    <input type="button"
                                                           value={`${Moment(this.state.startDate).format("hh:mm a Y-M-D")} to ${Moment(this.state.endDate).format("hh:mm a Y-M-D")}`}
                                                           style={{textAlign: 'left'}}
                                                           className="form-control"/>
                                                </DateRangePicker>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formVoucherType">
                                            <Form.Label column sm="3">
                                                Voucher Type <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control as="select"
                                                              onChange={this.handleOnChangeVoucherType}
                                                              placeholder="">
                                                    <option value="1">Collectible</option>
                                                    <option value="2">Voucher Code</option>
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>
                                        {this.state.isTypeVoucherCode &&
                                        <Form.Group as={Row} controlId="formVoucherCode">
                                            <Form.Label column sm="3">
                                                Voucher Code <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control name={"voucher_code"}
                                                              onChange={this.handleInputOnChange}
                                                              type="textarea" row={3} placeholder=""/>
                                            </Col>
                                        </Form.Group>}
                                       {/* {this.state.isTypeVoucherCode === false &&
                                        <Form.Group as={Row} controlId="formVoucherDisplayArea">
                                            <Form.Label column sm="3">
                                                Voucher Display Area
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Check
                                                    custom
                                                    inline
                                                    type="radio"
                                                    onChange={this.handleInputOnChange}
                                                    name="display_area"
                                                    value="1"
                                                    id={`custom-radio-1`}
                                                    label={`Logged In User`}
                                                />
                                                <Form.Check
                                                    custom
                                                    inline
                                                    type="radio"
                                                    onChange={this.handleInputOnChange}
                                                    name="display_area"
                                                    value="2"
                                                    id={`custom-radio-2`}
                                                    label={`Guest`}
                                                />
                                                <Form.Check
                                                    custom
                                                    inline
                                                    type="radio"
                                                    onChange={this.handleInputOnChange}
                                                    name="display_area"
                                                    value="3"
                                                    id={`custom-radio-3`}
                                                    label={`Store Follower`}
                                                />
                                            </Col>
                                        </Form.Group>}*/}
                                    </Form>
                                </div>
                            </div>

                            <div className="card-block">
                                <div className="block-body">
                                    {this.state.isTypeVoucherCode &&
                                    <Form.Group as={Row} controlId="applicableFor">
                                        <Form.Label column sm="3">
                                            Applicable For <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control
                                                name={'apply_to'}
                                                onChange={this.handleOnChangeApplicableFor}
                                                as="select" placeholder="">
                                                <option value="0">All</option>
                                                <option value="2">Stores</option>
                                                <option value="3">Product</option>
                                                <option value="4">Campaign</option>

                                            </Form.Control>
                                        </Col>
                                    </Form.Group>}


                                    {(!this.state.isTypeVoucherCode || this.state.formData.apply_to === "2") &&
                                    <Form.Group as={Row} controlId="forStores">
                                        <Form.Label column sm="3">
                                            Store(s) <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            {this.state.storeList.length > 0 ?
                                                <Select
                                                    isMulti
                                                    // value={this.state.attribute}
                                                    styles={styleReactSelect}
                                                    placeholder="Select Store..."
                                                    onChange={(value) => {
                                                        this.handleSelectMultiOnChange(value, 'stores')
                                                    }}
                                                    options={this.state.storeList}
                                                    name="attributes"
                                                    noOptionsMessage={() => 'No store found'}
                                                /> :
                                                <span>{this.state?.fetchingStore === true ? "Fetching Stores, Please Wait..." : "Store not found"}</span>}
                                        </Col>
                                    </Form.Group>}

                                    {this.state.isApplyForCat &&
                                    <Form.Group as={Row} controlId="forCategories">
                                        <Form.Label column sm="3">
                                            Categories <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Select
                                                isMulti
                                                // value={this.state.attribute}
                                                styles={styleReactSelect}
                                                placeholder="Select Attribute..."
                                                onChange={(value) => {
                                                    this.handleSelectMultiOnChange(value, 'categories')
                                                }}
                                                options={[{
                                                    id: 1,
                                                    value: 'Men\'s Fashion',
                                                    label: 'Men\'s Fashion'
                                                },
                                                    {
                                                        id: 2,
                                                        value: 'Electronic',
                                                        label: 'Electronic'
                                                    }]}
                                                name="attributes"
                                                noOptionsMessage={() => 'Select store...'}
                                            />
                                        </Col>
                                    </Form.Group>}
                                </div>
                            </div>


                            {this.state.block === '4' &&
                            <div className="card-block">
                                <div className="block-header">
                                    <h4 className="block-title">Campaign</h4>
                                </div>
                                <div className="block-body">
                                    <Form.Group as={Row} controlId="forStores">
                                        <Form.Label column sm="3">
                                            Campaigns <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            {this.state.campaignsList.length > 0 ?
                                                <Select
                                                    isMulti
                                                    // value={this.state.attribute}
                                                    styles={styleReactSelect}
                                                    placeholder="Select Campaigns..."
                                                    onChange={(value) => {
                                                        console.log(value)
                                                        this.handleSelectMultiOnChange(value, 'campaigns')
                                                    }}
                                                    options={this.state.campaignsList}
                                                    name="campaigns"
                                                    noOptionsMessage={() => 'No store found'}
                                                /> :
                                                <span>{this.state?.fetchingStore === true ? "Fetching Campaign, Please Wait..." : "Campaign not found"}</span>}
                                        </Col>
                                    </Form.Group></div></div>
                            }

                                {this.state.block === '3' && <div className="card-block">
                                    <div className="block-header">
                                        <h4 className="block-title">Product</h4>
                                    </div>
                                    <div className="block-body">

                                        <table className={`table table-striped`}>
                                            <thead className={`sm`}>
                                            <tr>
                                                <th style={{width: '50px'}}/>
                                                <th>Product Name</th>
                                                <th>Product Price</th>
                                                <th style={{width: '1%'}}/>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {this.state.selected_products && this.state.selected_products.length > 0 ?
                                                <>

                                                    {this.state.selected_products.map((product, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <img
                                                                        src={product?.thumb_img ? product?.thumb_img : product?.thumb_img}
                                                                        style={{width: '40px'}} alt=""/>
                                                                </td>
                                                                <td>{product?.name} {product?.sku_info?.attr_combo ? product?.sku_info?.attr_combo.join('-') : ''}</td>
                                                                <td>{product?.sku_info?.price}</td>
                                                                <td><span style={{cursor: 'pointer'}}
                                                                          onClick={() => this.handleRemoveSelectedProduct('products', product.sku_info._id)}
                                                                          className={`text-bold text-danger`}>&times;</span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>{this.state.price_sum}</td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                </>
                                                :
                                                <tr>
                                                    <td colSpan={3} className={`text-center text-bold`}>
                                                        No product selected
                                                    </td>
                                                </tr>
                                            }

                                            </tbody>
                                        </table>

                                        <Button size="sm"
                                                onClick={() => this.handleShowSelectProductModal('products')}
                                                variant="primary">
                                            {this.state.formData.promotion_type === '4' ?
                                                "Select Mother Product"
                                                :
                                                "Select Product"
                                            }
                                        </Button>

                                    </div>
                                </div>}


                                <div className="card-block">
                                    <div className="block-header">
                                        <h4 className="block-title">Voucher Settings</h4>
                                    </div>

                                    <div className="block-body">
                                        <Form>
                                            <Form.Group as={Row} controlId="formDiscountType">
                                                <Form.Label column sm="3">
                                                    Discount Type <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control
                                                        name={'discount_type'}
                                                        onChange={this.handleOnChangeDiscountType}
                                                        as="select" placeholder="">
                                                        <option value="1">Fixed Amount</option>
                                                        <option value="2">Percentage</option>
                                                    </Form.Control>
                                                </Col>
                                            </Form.Group>

                                            {this.state.formData.discount_type === "1" ?
                                                <>
                                                    <Form.Group as={Row} controlId="discountAmount">
                                                        <Form.Label column sm="3">
                                                            Discount Amount (BDT) <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Col sm="9" md={8} lg={7}>
                                                            <Form.Control onChange={this.handleInputOnChange}
                                                                          name={"discount_value"} type="text"
                                                                          placeholder=""/>
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} controlId="discountPercentage">
                                                        <Form.Label column sm="3">
                                                            Admin Discount Percentage (%) <span
                                                            className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Col sm="9" md={8} lg={7}>
                                                            <Form.Control type="text"
                                                                          onChange={this.handleInputOnChange}
                                                                          name="admin_discount_percentage" placeholder=""/>
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} controlId="discountPercentage">
                                                        <Form.Label column sm="3">
                                                            Store Discount Percentage (%) <span
                                                            className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Col sm="9" md={8} lg={7}>
                                                            <Form.Control type="number"
                                                                          onChange={this.handleInputOnChange}
                                                                          name="store_discount_percentage" placeholder=""/>
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} controlId="minAmountToApplyVoucher">
                                                        <Form.Label column sm="3">
                                                            Minimum amount to apply voucher (BDT) <span
                                                            className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Col sm="9" md={8} lg={7}>
                                                            <Form.Control name={"minimum_order_value"}
                                                                          onChange={this.handleInputOnChange}
                                                                          type="number" placeholder=""/>
                                                        </Col>
                                                    </Form.Group>
                                                </>
                                                :
                                                <>
                                                    <Form.Group as={Row} controlId="discountPercentage">
                                                        <Form.Label column sm="3">
                                                            Admin Discount Percentage (%) <span
                                                            className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Col sm="9" md={8} lg={7}>
                                                            <Form.Control type="number"
                                                                          onChange={this.handleInputOnChange}
                                                                          name="admin_discount_percentage" placeholder=""/>
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} controlId="discountPercentage">
                                                        <Form.Label column sm="3">
                                                            Store Discount Percentage (%) <span
                                                            className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Col sm="9" md={8} lg={7}>
                                                            <Form.Control type="number"
                                                                          onChange={this.handleInputOnChange}
                                                                          name="store_discount_percentage" placeholder=""/>
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} controlId="discountPercentage">
                                                        <Form.Label column sm="3">
                                                            Discount Percentage (%) <span
                                                            className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Col sm="9" md={8} lg={7}>
                                                            <Form.Control type="text"
                                                                          onChange={this.handleInputOnChange}
                                                                          name="discount_value" placeholder=""/>
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} controlId="maxDiscountPercentage">
                                                        <Form.Label column sm="3">
                                                            Max discount Amount <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Col sm="9" md={8} lg={7}>
                                                            <Form.Control type="text"
                                                                          name="maximum_discount_amount"
                                                                          onChange={this.handleInputOnChange}
                                                                          placeholder=""/>
                                                        </Col>
                                                    </Form.Group>
                                                </>}

                                            <Form.Group as={Row} controlId="totalVouchersToBeIssued">
                                                <Form.Label column sm="3">
                                                    Total vouchers to be issued <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control name="total_voucher"
                                                                  onChange={this.handleInputOnChange}
                                                                  type="text" placeholder=""/>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="usageLimitCustomer">
                                                <Form.Label column sm="3">
                                                    Usage Limit Per Customer <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control onChange={this.handleInputOnChange}
                                                                  name="usage_limit"
                                                                  type="text"/>
                                                </Col>
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </div>

                                <div className="card-block">
                                    <div className="block-body text-right">
                                        <Link to={`${process.env.PUBLIC_URL}/promotions/seller-voucher`}
                                              className="btn btn-lg btn-default mr-3">Cancel</Link>
                                        <Button size="lg" onClick={this.handleFormSubmit}
                                                variant="primary">Submit</Button>
                                    </div>
                                </div>
                            </>
                            }
                            </div> : null}
                    </App>
                    );
                }

}

//export default AddSellerVoucher;
export default withRouter(AddSellerVoucher);
