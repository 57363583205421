// Doc:
// https://github.com/wellyshen/use-places-autocomplete

import React from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import "../assets/scss/places-autocomplete.scss";

const PlacesAutocomplete = ({onSelectLocation, required, placeholder}) => {
  const {
    ready,
    value,
    suggestions: {status, data},
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = ({description}) => () => {
    setValue(description, false);
    clearSuggestions();

    getGeocode({address: description})
      .then((results) => getLatLng(results[0]))
      .then(({lat, lng}) => {
        onSelectLocation({
          lat: lat,
          lng: lng
        })
      })
      .catch((error) => {
        console.log("😱 Error: ", error);
      });
  };

  const renderSuggestions = () =>
    data.map((suggestion, index) => {
      const {
        structured_formatting: {main_text, secondary_text},
      } = suggestion;

      return (
        <li key={index} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div className={status === "OK" ? "places-autocomplete has-location" : "places-autocomplete"} ref={ref}>
      <input
        required={required}
        className={"form-control"}
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder={placeholder}
      />
      {status === "OK" && <ul className="location-list">{renderSuggestions()}</ul>}
    </div>
  );
};

export default PlacesAutocomplete;
