import React, {Component} from 'react';
import App from "../../App";
import {
    Button,
    Col,
    Form,
    Row,
    Modal, Badge, Spinner
} from "react-bootstrap";

import axios from "axios";
import config, {API_BASE_URL} from "../../helper/env";
import {ToastsContainer, ToastsStore} from "react-toasts";
import {toast} from "react-toastify";
import {getDeviceType, getLocalAuthData, headerData, urlPramsToObj} from "../../helper/utils";
import {Link, withRouter} from "react-router-dom";
import {FiEdit, FiTrash, FiPlus, IoMdCheckmark, AiOutlineLink, BsEye, FaFileExport} from "react-icons/all";
import {confirmAlert} from "react-confirm-alert";
import {toggleStatusUpdate} from "../../services/bankServices"
import {deleteItem, fileDownload, getTableData, updateItemPartial} from "../../services/commonServices";
import Pagination from "react-js-pagination";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Moment from "moment";
import {useJsonToCsv} from "react-json-csv"
import {FaFilePdf} from "@react-icons/all-files/fa/FaFilePdf";
const {saveAsCsv} = useJsonToCsv()

class Agent extends Component {


    // TODO: Action Example
    //{ getLocalAuthData()?.permission?.includes('pro_addhjgjh') ?  <Button>TEST</Button> : null}
    //

    constructor(props) {
        super(props);
        this.state = {
            formData: {
                permission: []
            },
            items: [],
            permission_list: [],
            selected_id: '',
            editFormData: null,
            editStatus: false,
            isShowModal: false,
            definedPermission: [],
            queryData: {limit: 20, page: 1},
            LiveList: [],
            LiveDetails: null,
            isLoading: false,
            pagination: {},
        }
    }


    componentDidMount() {
        this.getAgentData()
    }


    getAgentData = () => {
        const queryData = {...this.state.queryData};
        getTableData('/agent', this.state.queryData).then(res => {

            console.log(res)
            this.setState({LiveList: res.items, isLoading: false, pagination: res.pagination})
        }).catch(errMsg => {
            toast.error(errMsg);
        })
    }

    handlePageChange = async (pageNumber) => {
        console.log(pageNumber)
        const history = this.props.history
        history.push(`${history.location.pathname}?page=${pageNumber}`)
        await this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber}, () => {
            this.getAgentData();
        });
    }


    handleChangeStatus = (e, id) => {
        console.log(e.target.value)

        if (e.target.value !== "false") {
            updateItemPartial(`/agent/changeStatus/${id}`, {status: e.target.value}).then(res => {
                console.log(res)
                if (res.status === 1) {
                    this.getAgentData();
                    toast.success(res.message)
                } else {
                    toast.warning(res.message)
                }
                //  this.setState({LiveList: res.items, isLoading: false, pagination: res.pagination})
            }).catch(errMsg => {
                toast.error(errMsg);
            })
        }


    }

    handleDeleteItem = (id) => {
        confirmAlert({
            title: 'Permanent Delete',
            message: 'Are you sure to delete permanently this Agent?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteItem(`/agent/${id}/permanent`).then(res => {
                            if (res.status === 1) {
                                toast.success(res.message)
                                this.getAgentData()
                            } else {
                                toast.error(res.message)
                            }

                        }
                    ).catch(err => console.log(err))
                }
                ,
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });
    };



    handleExport = async () => {
        this.setState({export_loading: true})
        let queryData = {...this.state.queryData}
        queryData.export = 'xlsx'
        this.setState({queryData}, ()=>{
            console.log('this.state.queryData',this.state.queryData);

            fileDownload(`/agent`, this.state.queryData).then((response)=>{
                console.log(response)
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'ekshop-agent.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
                this.setState({
                    export_loading: false
                })
            })
            queryData.export = null
            this.setState({
                queryData
            })

        })


        // let queryData = ""
        // // noinspection JSMismatchedCollectionQueryUpdate
        // let mData = []
        //
        // this.state.LiveList && this.state.LiveList.map((data1, index1) => {
        //
        //     mData.push({
        //         'Index': index1 + 1,
        //         'Name': data1?.name,
        //         'Phone': data1?.phone,
        //         'Nid': data1?.nid,
        //         'Agent': data1?.agent,
        //         'Status': data1?.status,
        //         'Code': data1?.code,
        //         'Date': moment(data1?.createdAt).format("hh:mm a DD-MM-YYYY"),
        //
        //     })
        //
        // })
        //
        // const filename = 'EkShop-Agent List',
        //     fields = {
        //         'Index': "Index",
        //         'Name': "Name",
        //         'Phone': "Phone",
        //         'Nid': "Nid",
        //         'Agent': "Agent",
        //         'Status': "Status",
        //         'Code': "Code",
        //         'Date': "Date",
        //
        //
        //     },
        //     style = {
        //         padding: "30px",
        //         maxHeight: "20px"
        //     },
        //     data = mData,
        //     text = "Convert Json to Csv"
        // saveAsCsv({data, fields, filename})
    }

    handleExportFile = async (fileType) => {
        let queryData = "";
        Object.entries(this.state.queryData).forEach(([key, val]) => {
                queryData = queryData.concat(key + "=" + val + "&");
            }
        )
        await this.setState({isExporting: true});
        const authData = getLocalAuthData();
        axios.get(`${API_BASE_URL}/agent?export=${fileType}&${queryData}`, {
            headers: {"x-auth-token": authData.token, "x-api-client": getDeviceType()}
        }).then(res => {
            console.log(res)
            this.setState({isExporting: false});
        }).catch(e => console.log("Error"));
    }


    handleOnChangeInput = (e) => {
        const queryData = {...this.state.queryData}
        queryData[e.target.name] = e.target.value
        this.setState({queryData}, () => {
            this.getAgentData(this.state.queryData)
        })
    }

    handleOnApply = (e, picker) => {
        const queryData = {...this.state.queryData}
        const startDate = Moment(picker.startDate).format('DD-MM-YYYY')
        const endDate = Moment(picker.endDate).format('DD-MM-YYYY')
        queryData['from_date'] = startDate
        queryData['to_date'] = endDate

        this.setState({
            queryData
        }, () => {
            this.getAgentData(this.state.queryData)
        })
    }


    render() {
        return (
            <App layout={"boxed"}>
                <div className="page-header">
                    <h2 className="page-title">Agent</h2>
                    <div className="ml-auto">
                        <Button variant="primary"

                                size={`sm`}
                                onClick={() => this.handleExport('xlsx')}
                                disabled={this.state.export_loading}>
                            {this.state.export_loading ?
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span>Loading...</span>
                                </>
                                : <><FaFilePdf/><span>Export</span></>}
                        </Button>
                            {/*<Button*/}
                            {/*    onClick={()=>{this.handleExport()}}*/}
                            {/*    size={"sm"} variant={"default"}*/}
                            {/*        className="mb-0 ml-2"><FaFileExport/> Export</Button>*/}
                    </div>
                </div>

                <div className="main-content">

                    <div className="card-block">

                        <div className="block-header">
                            <h4 className="block-title">Agent List</h4>
                        </div>


                        <div className="block-body">
                            <div className="block-body">
                                <Form className="d-flex v-middle">
                                    <div className="form-inline">
                                        <Form.Group className={'mr-2'}>
                                            <Form.Label className={"sr-only"}>Date Range Picker</Form.Label>
                                            <input
                                                type="search"
                                                id="orderNumber"
                                                className='form-control form-control-sm'
                                                placeholder="Search Key"
                                                name="search_string"
                                                onChange={this.handleOnChangeInput}
                                            />
                                        </Form.Group>

                                        <Form.Group className={'mr-2'}>
                                            <Form.Label className={"sr-only"}>Date Range Picker</Form.Label>
                                            <DateRangePicker
                                                onApply={this.handleOnApply}>
                                                <input type="text"
                                                       style={{textAlign: 'left', minWidth: '150px'}}
                                                       placeholder={"Select date range"}
                                                       className="form-control form-control-sm"/>
                                            </DateRangePicker>
                                        </Form.Group>

                                    </div>

                                </Form>
                            </div>

                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th style={{width: '20px'}}>#</th>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Nid</th>
                                    <th>Status</th>
                                    <th>Change Status</th>
                                    <th>Date</th>
                                    <th style={{width: `1%`}}/>
                                </tr>
                                </thead>
                                <tbody>


                                {(this.state.LiveList?.length > 0) ?
                                    this.state.LiveList.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.name}</td>
                                            <td>{item?.phone}</td>
                                            <td>{item?.nid}</td>
                                            <td>
                                                {item.status === 'approved' ?
                                                    <Badge pill variant="warning">approved</Badge>
                                                    :
                                                    <>
                                                        {
                                                            item.status === 'pending' ?
                                                                <Badge pill variant="secondary">pending</Badge>
                                                                :
                                                                <Badge pill variant="danger">reject</Badge>
                                                        }
                                                    </>
                                                }
                                            </td>
                                            <td>
                                                <select
                                                    onChange={(e) => {
                                                        this.handleChangeStatus(e, item._id)
                                                    }}
                                                    style={{width: "60%"}} id="status" name="status"
                                                    className='form-control form-control-sm'>
                                                    <option value="false">--Change Status--</option>
                                                    <option value="approved">Approve</option>
                                                    <option value="pending">Pending</option>
                                                    <option value="reject">Reject</option>
                                                </select>
                                            </td>
                                            <td>{moment(item?.createdAt).format("hh:mm a DD-MM-YYYY")}</td>
                                            <td className="nowrap text-center">
                                                <Link
                                                    to={"/agent/details/" + item._id}>
                                                    <span className="btn btn-sm btn-outline-primary mr-3">
                                                        <BsEye/>View
                                                    </span>
                                                </Link>
                                                <span
                                                    onClick={() => this.handleDeleteItem(item._id)}
                                                    className="btn btn-sm btn-outline-danger mr-3"><FiTrash/> Delete</span>
                                            </td>
                                        </tr>
                                    )) :
                                    <tr>
                                        <td colSpan={7} className={`text-bold text-center`}>No item found</td>
                                    </tr>
                                }


                                </tbody>
                            </table>

                            <div className="mt-3">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={this.state?.pagination?.page}
                                    itemsCountPerPage={this.state?.pagination?.limit}
                                    totalItemsCount={this.state?.pagination?.totalDocs ? this.state?.pagination?.totalDocs : 0}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}/>
                            </div>
                        </div>

                    </div>

                </div>
            </App>
        );
    }
}

export default withRouter(Agent);
