import React, {Component} from 'react';
import App from "../../App";
import {Link} from "react-router-dom";
import {FiPlus} from "react-icons/all";
import {Button} from "react-bootstrap";
import {deleteCollection, getCollectionList} from "../../services/collectionServices";
import {confirmAlert} from "react-confirm-alert";
import {toast} from "react-toastify";
import {getLocalAuthData} from "../../helper/utils";
import {activityLogPostData} from "../../services/commonServices";

class Collections extends Component {
  state = {
    collections: []
  }

  componentDidMount() {
    getCollectionList()
      .then(res => {
        this.setState({collections: res})
      })
      .catch(errMsg => {
        console.log('Getting collection error: ', errMsg)
      })
  }

  handleDeleteCollection = (id,name) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to delete this collection?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteCollection(id)
            .then(res => {
              toast.success('Successfully deleted');
              activityLogPostData('was delete this collection','/collections/edit/'+id,name)
              getCollectionList()
                .then(res => {
                  this.setState({collections: res});
                })
                .catch(errMsg => {
                  console.log('Getting collection error: ', errMsg)
                })
            })
            .catch(errMsg => {
              toast.error(errMsg)
            })
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }

  render() {
    const {collections} = this.state;

    return (
      <App layout={"boxed"}>
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Collections</h2>
            <div className="ml-auto">
              { getLocalAuthData()?.permission?.includes('create_collection') ?
                  <Link to={`${process.env.PUBLIC_URL}/collections/new`} className="btn btn-sm btn-secondary"><FiPlus/> Create Collection</Link>
                  :null}

            </div>
          </div>

          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Collection List</h4>
            </div>
            <div className="block-body">
              { getLocalAuthData()?.permission?.includes('search_collection') ?
              <form onSubmit={(e)=> e.preventDefault()} className="form-inline mb-3">
                <div className="form-group mr-3">
                  <label htmlFor="searchInput" className="sr-only">Search</label>
                  <input type="search" id="searchInput" className='form-control form-control-sm'
                         placeholder="Search..."
                         onKeyDown={(e)=>{
                          if(e.keyCode === 13){
                            //Codes that need to run on enter key press
                          }
                        }}/>
                </div>
                <Button size="sm" variant="secondary">Search</Button>
              </form>
                  :null}

              { getLocalAuthData()?.permission?.includes('collection_list') ?
              <table className="table table-striped">
                <thead>
                <tr>
                  <th style={{width: '30px'}}>#</th>
                  <th>Collection Name</th>
                  <th>Products Quantity</th>
                  <th style={{width: '1%'}}/>
                </tr>
                </thead>

                <tbody>
                {collections.length > 0 ?
                  collections.map((collection, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{collection?.name}</td>
                      <td>{collection?.product_qnt || 0}</td>
                      <td className="nowrap">
                        <Link to={`${process.env.PUBLIC_URL}/collections/edit/${collection._id}`}
                              className="btn btn-sm btn-outline-success mr-2">Edit</Link>
                        <Button variant={"outline-danger"}
                                onClick={() => this.handleDeleteCollection(collection._id ,collection?.name)}
                                size={"sm"}>Delete</Button>
                      </td>
                    </tr>
                  ))
                  :
                  <tr>
                    <td colSpan={4} className="text-center text-bold">
                      No collection found. Please add <Link to={`${process.env.PUBLIC_URL}/collections/new`}>new collection</Link>
                    </td>
                  </tr>
                }
                </tbody>
              </table>
                  : <spna className={`text-danger`}>Permission Denied</spna>}
            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default Collections;
