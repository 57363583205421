import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {FaLongArrowAltLeft, IoMdCheckmark} from "react-icons/all";
import Select from "react-select";
import styleReactSelect from "../../assets/styleReactSelect";
import {getSkuProductList} from "../../services/productServices";
import {getStoreList} from "../../services/storeServices";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Moment from "moment";
import {getBundleOfferDetails, updateBundleOffer} from "../../services/promotionServices";
import {toast} from "react-toastify";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import BrowseFiles from "../../components/BrowseFiles";
import Pagination from "react-js-pagination";
import {getLocalAuthData} from '../../helper/utils'
import LoadingSpinner from '../../components/LoadingSpinner';
import {activityLogPostData} from "../../services/commonServices";

const today = Moment(new Date()).format('Y-M-D');

class EditBundleOffer extends Component {
    state = {
        brandData: {
            name: null,
            logo: null
        },
        formData: {
            promotion_type: '1',
            apply_to: '2',
            start_time: today,
            end_time: today,
            products_skus: [],
        },
        queryData: {limit: 20, search_string: '' ,status:1, deleted: false, publish_status: 2},
        productListPagination: {},
        bundleOffer: {},
        isProductModalProductShowing: false,
        products: [],
        storeList: [],
        gift_products: [],
        productList: [],
        isLoading: true,
        productType: '',
        selectedProducts: [],
        isFormValidated: false,
        isSubmitting: false,
        startDate: today,
        endDate: today,
        selectedStores: [],
        selectedStoresDefault: [],
        selectedProducts_sku: [],
        Products_sku: [],
        gift_products_skus: [],
        price_sum: 0,
        gift_sum: 0,
        selected_gift_products: [],
        selected_products: [],
        isProductListLoading: false,
        errorMessage: ""
    }

    componentDidMount() {
        let bundle_id = this.props.match.params.id;

        const storeList = getStoreList({lite_data: 1, limit: 10})
            .then(res => {
                const storeList = [...this.state.storeList];
                res.items.forEach(item => {
                    const store = {
                        id: item._id,
                        value: item._id,
                        label: item.store_name
                    }
                    storeList.push(store)
                    this.setState({storeList, productList: []})
                })
            })

        const BundleOffer = getBundleOfferDetails(bundle_id)
            .then(res => {
                let store_ids = res?.stores.map(function (item) {
                    return item._id;
                });
                const formData = {
                    offer_name: res.offer_name,
                    start_time: Moment(res.start_time).format('Y-M-D'),
                    end_time: Moment(res.end_time).format('Y-M-D'),
                    promotion_type: res?.promotion_type,
                    discount_type: res?.discount_type,
                    maximum_discount_amount: res?.maximum_discount_amount,
                    offer_value: res?.offer_value,
                    product_type: res?.product_type,
                    products: res?.products,
                    gift_products: res?.gift_products,
                    gift_products_skus: res?.gift_products_skus,
                    apply_to: res?.apply_to,
                    stores: store_ids,
                    store_id: store_ids,
                    bulk_price: res?.bulk_price,
                    brandData: res?.bulk_price,
                    products_skus: res?.products_skus,
                    minimum_spend_amount: res?.minimum_spend_amount,
                    admin_discount_percentage: res?.admin_discount_percentage,
                    store_discount_percentage: res?.store_discount_percentage

                }

                const selectedStores = []
                const selectedStoresDefault = []

                this.setState({
                    selected_products: res.product_infos,
                    selected_gift_products: res.gift_product_sku_infos,
                    formData
                }, () => {
                    this.setState({isProductListLoading: true, errorMessage: ""});
                    // let all_prod = [...res?.products, ...res?.gift_products]
                    getSkuProductList({
                        limit: this.state.queryData.limit,
                        status:1,
                        deleted: false,
                        publish_status: 2,
                        // product_ids: all_prod,
                        store_id: res?.stores[0]._id,
                    })
                        .then(new_res => {
                            // const products = []
                            // const gift_products = []
                            if (new_res.status === 2) {
                                this.setState({
                                    isProductListLoading: false,
                                    errorMessage: "No product available for this store"
                                });
                            } else {
                                this.setState({isProductListLoading: false, errorMessage: ""});
                            }
                            this.setState({
                                productList: new_res.products,
                                productListPagination: new_res.pagination
                            }, () => {

                                let selected_products = [...this.state.selected_products];
                                let selected_gift_products = [...this.state.selected_gift_products];
                                let price_sum = this.state.price_sum;
                                let gift_sum = this.state.gift_sum;

                                selected_gift_products.forEach((each) => {
                                    gift_sum += each?.sku_info?.price;
                                })

                                selected_products.forEach((each) => {
                                    price_sum += each?.sku_info?.price;
                                })

                                this.setState({price_sum, gift_sum, isProductListLoading: false}, () => {
                                    console.log("selectedProducts", this.state.selectedProducts)
                                    console.log("selected_gift_products", this.state.selected_gift_products)
                                    console.log("price_sum", this.state.price_sum)
                                    console.log("gift_sum", this.state.gift_sum)
                                });

                            })
                        })
                        .catch(errMsg => {
                            this.setState({
                                isProductListLoading: false,
                                errorMessage: "No product available for this store"
                            });
                        })
                })


                res.stores.forEach(item => {
                    selectedStoresDefault.push({label: item.store_name, value: item._id})
                    selectedStores.push(item._id)
                })

                this.setState({
                    formData,
                    selectedStores,
                    selectedStoresDefault,
                    isLoading: false
                }, () => {

                })

            })
            .catch(errMsg => {
                console.log(errMsg);
                this.setState({isLoading: false})
            })

        Promise.all([storeList, BundleOffer])
            .then(res => {
                this.setState({isLoading: false})
            })
            .catch((err) => {
                this.setState({isLoading: false})
            })
    }

    promiseOptions = inputValue => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(this.filterSearchable(inputValue));
            }, 0);
        });
    };

    handleImageOnBrowse = (images) => {
        console.log('Images: ', images)
        const brandData = {...this.state.brandData}
        if (images.length > 0) {
            brandData.logo = images[0].thumb_img

            const formData = {...this.state.formData}
            formData['bundle_banner'] = images[0].thumb_img
            this.setState({formData})
            console.log(formData)
        }
        this.setState({brandData})
    }

    handleSearchProduct = (e) => {
        const formData = {...this.state.formData};

        if (this.state.formData?.stores?.[0]) {
            getSkuProductList({
                ...this.state.queryData,
                limit: this.state.queryData.limit,
                merchants: JSON.stringify(this.state.formData?.merchants || []),
                status:1,
                deleted: false,
                publish_status: 2,
                stores: [],
                store_id: this.state.formData?.stores?.[0],
                categories: JSON.stringify(this.state.formData?.categories || [])
            }).then(res => {
                this.setState({productList: res.products, productListPagination: res.pagination})
            }).catch(err => {
                this.setState({isProductLoading: false})
            })
        }

        console.log(formData)
    }

    filterSearchable = (inputValue) => {

        if (inputValue.length >= 2) {
            getStoreList({search_string: inputValue, lite_data: 1, limit: 50})
                .then(res => {
                    const storeList = [];

                    res.items.forEach(item => {
                        const store = {
                            id: item._id,
                            value: item._id,
                            label: item.store_name
                        }

                        storeList.push(store)
                        this.setState({storeList}, () => {
                            //console.log(this.state.storeList)
                        })
                    })
                }).catch(errMsg => {
                console.log(errMsg)
            })
        }


        return this.state.storeList;
    };

    handlePromotionTypeOnChange = (e) => {
        const formData = {...this.state.formData}
        formData.products = []
        formData.gift_products = []
        formData.gift_products_skus = []
        formData.product_skus = []
        formData[e.target.name] = e.target.value
        this.setState({
            formData,
            products: [],
            gift_products: [],
            gift_products_skus: [],
            Products_sku: [],
            selectedProducts: []
        })
    }

    handleInputOnChange = (e) => {
        // const formData = {...this.state.formData};
        // formData[e.target.name] = e.target.value;
        // this.setState({formData})
        const queryData = {...this.state.queryData};
        queryData.search_string = e.target.value;
        this.setState({queryData, productList: []},()=>{
            console.log(this.state.queryData)
        });
        this.getProducts(queryData);
    }

    getProducts = (queryData = {}) => {
        let query = {
            ...this.state.queryData,
            limit: this.state.queryData.limit,
            merchants: JSON.stringify(this.state.formData?.merchants || []),
            // stores: JSON.stringify(this.state.formData?.stores || []),
            store_id: this.state.formData?.stores?.[0],
            categories: JSON.stringify(this.state.formData?.categories || [])
        }
        if (this.state.queryData.search_string) {
            query.search_string = this.state.queryData.search_string;
        }

        this.setState({isProductListLoading: true});
        getSkuProductList(queryData).then(res => {
            if (res.status === 1) {
                this.setState({
                    productList: res.products,
                    productListPagination: res.pagination,
                    isProductListLoading: false,
                    errorMessage: ""
                })
            } else {
                this.setState({
                    productList: res.products,
                    productListPagination: res.pagination,
                    isProductListLoading: false,
                    errorMessage: "No product available for this store"
                })
            }
        }).catch(err => {
            this.setState({
                isProductLoading: false,
                isProductListLoading: false,
                errorMessage: "No product available for this store"
            })
        })
    }

    handleRegisteredStorePageChange = async (page) => {
        this.setState({...this.state.queryData, page: page}, () => {
            this.getProducts();
        });
    }

    handleOfferTypeOnChange = (e) => {
        const formData = {...this.state.formData};
        formData.offer_value = [{
            item: '',
            discount_value: ''
        }];
        formData[e.target.name] = e.target.value;
        this.setState({formData})
    }

    addMoreOfferValue = () => {
        const formData = {...this.state.formData};
        formData.offer_value.push({
            item: '',
            discount_value: ''
        });
        this.setState({formData})
    }

    handleRemoveItem = (index) => {
        const formData = {...this.state.formData};
        formData.offer_value.splice(index, 1);
        this.setState({formData})
    }

    handleSelectMultiOnChange = (array, name) => {
        let query = {...this.state.queryData}
        query.search_string = "";
        const formData = {...this.state.formData};
        formData.products = [];
        formData.gift_products = [];
        formData.gift_products_skus = [];
        formData.product_skus = [];
        formData.bulk_price = "";

        this.setState({
            selected_products: [],
            selected_gift_products: [],
            queryData: query,
            formData,
            gift_sum: 0,
            price_sum: 0
        })
        // const promotion_type = this.state.formData?.promotion_type;
        const data = [];
        if (name === "stores") {
            data.push(array.id)
            this.setState({products: []})

            getSkuProductList({
                ...query,
                limit: 10,
                merchants: JSON.stringify(this.state.formData?.merchants || []),
                stores: [],
                store_id: array.id,
                categories: JSON.stringify(this.state.formData?.categories || [])
            }).then(res => {
                if (res.status === 1) {
                    this.setState({
                        productList: res.products,
                        productListPagination: res.pagination,
                        isProductListLoading: false,
                        errorMessage: ""
                    })
                } else {
                    this.setState({
                        productList: res.products,
                        productListPagination: res.pagination,
                        isProductListLoading: false,
                        errorMessage: "No product available for this store"
                    })
                }
                this.setState({productList: res.products, productListPagination: res.pagination})
            }).catch(err => {
                this.setState({isProductLoading: false, isProductListLoading: false})
            })
        } else {

            if (array) {
                array.forEach(val => {
                    data.push(val.id)
                })
            }
        }

        formData[name] = data;
        this.setState({formData})
    }

    handleOnChangeApplicableFor = (e) => {
        const formData = {...this.state.formData}
        formData.apply_to = e.target.value
        this.setState({
            formData,
        })
        if (e.target.value === '6') {
            this.setState({isApplyForCat: true})
        } else {
            this.setState({isApplyForCat: false})
        }
    }

    handleShowSelectProductModal = (type) => {
        this.setState({isProductModalProductShowing: true, productType: type})
    }

    handleHideProductModal = () => {
        this.setState({isProductModalProductShowing: false})
    }

    handleAddProduct = (productType, selectedProducts, Products_sku) => {
        const formData = {...this.state.formData};
        formData[productType] = selectedProducts;


        // const promotion_type = this.state.formData?.promotion_type;


        this.setState({formData}, () => {
            const products = [];
            const products_sku = [];
            selectedProducts.forEach(item => products.push(this.state.productList.filter(obj => obj._id === item)[0]));

            Products_sku.forEach(item => {
                const product = this.state.productList.filter(obj => {
                    return obj.sku_info._id === item
                })
                products_sku.push(product[0].sku_info._id)
            })

            let price_sum = 0;
            let gift_sum = 0;

            if (productType === "gift_products") {
                products.forEach(item => {
                    gift_sum += parseInt(item?.sku_info?.price);

                })
                price_sum = this.state.price_sum;
            } else {
                gift_sum = this.state.gift_sum;
                products.forEach(item => {
                    price_sum += parseInt(item?.sku_info?.price);

                })
            }

            console.log(this.state.selectedProducts);
            console.log(this.state.productList);
            const selectedProductList = [];
            this.state.selectedProducts.forEach(p => {
                console.log(this.state.productList.includes(item => item._id === p))
                // if () {
                //   selectedProductList.push(this.state.products.filter(item => item?.sku_info?._id === p)[0])
                // }
            })
            console.log(selectedProductList)

            formData[productType + "_skus"] = products_sku;
            this.setState({
                products: {...this.state.products, selectedProducts},
                price_sum: price_sum,
                gift_sum: gift_sum,
                [productType]: products,
                formData,
                isProductModalProductShowing: false,
                // selectedProducts: [],
                Products_sku: []
            }, () => {
                //console.log(productType, this.state[productType])
            })


        })
    }

    handleSelectedProductOnChange = (e, id, sku_info) => {
        let selected_products = [...this.state.selected_products];
        let selected_gift_products = [...this.state.selected_gift_products];
        let formData = {...this.state.formData};
        let price_sum = this.state.price_sum;
        let gift_sum = this.state.gift_sum;

        let checkedProduct = this.state.productList.filter(item => item.sku_info._id === sku_info._id);

        if (this.state.productType === "products") {
            if (e.target.checked) {
                selected_products.push(checkedProduct[0])
                formData['products_skus'].push(sku_info)
                price_sum += sku_info.price;
            } else {
                selected_products = selected_products.filter(item => item.sku_info._id !== sku_info._id);
                formData['products_skus'] = formData['products_skus'].filter(item => item._id !== sku_info._id);
                price_sum -= sku_info.price;
            }
        } else {
            if (e.target.checked) {
                selected_gift_products.push(checkedProduct[0])
                formData['gift_products_skus'].push(sku_info)
                gift_sum += sku_info.price;
            } else {
                selected_gift_products = selected_gift_products.filter(item => item.sku_info._id !== sku_info._id);
                formData['gift_products_skus'] = formData['gift_products_skus'].filter(item => item._id !== sku_info._id);
                gift_sum -= sku_info.price;
            }
        }

        // todo
        // check live submitted request value.. product, gift_product ids have been changed by boss
        this.setState({selected_products, selected_gift_products, formData, price_sum, gift_sum}, () => {
            console.log(this.state.formData);
        });
    }

    handleRemoveSelectedProduct = (type, sku) => {
        let selected_products = [...this.state.selected_products];
        let selected_gift_products = [...this.state.selected_gift_products];
        let formData = {...this.state.formData};
        let price_sum = this.state.price_sum;
        let gift_sum = this.state.gift_sum;

        let checkedProduct = this.state.productList.filter(item => item.sku_info._id === sku);

        if (type === "products") {
            selected_products = selected_products.filter(item => item !== checkedProduct[0]);
            formData['products'] = formData['products'].filter(item => item !== sku);
            price_sum -= checkedProduct[0].sku_info.price;
        } else {
            selected_gift_products = selected_gift_products.filter(item => item !== checkedProduct[0]);
            formData['gift_products'] = formData['gift_products'].filter(item => item !== sku);
            gift_sum -= checkedProduct[0].sku_info.price;
        }

        this.setState({selected_products, selected_gift_products, formData, price_sum, gift_sum})
    }

    handleOnApply = (e, picker) => {
        const formData = {...this.state.formData};
        const startDate = Moment(picker.startDate).format('Y-M-D');
        const endDate = Moment(picker.endDate).format('Y-M-D')

        formData.start_time = startDate;
        formData.end_time = endDate;

        this.setState({
            formData,
            startDate: startDate,
            endDate: endDate,
        })
    }

    handleDiscountValueOnChange = (e, index) => {
        const formData = {...this.state.formData};
        formData.offer_value[index][e.target.name] = e.target.value;
        this.setState({formData})
    }

    handleSubmitForm = (e) => {
        e.preventDefault();
        const offerId = this.props.match.params.id;
        const formData = {...this.state.formData};

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({isFormValidated: true})
        } else {
            formData.product_infos = this.state.selected_products;
            if (formData.promotion_type === "4") {
                formData.bulk_actual_price = this.state.gift_sum + this.state.price_sum;
                formData.gift_product_sku_infos = this.state.selected_gift_products;
            } else if (formData.promotion_type === "3") {
                formData.gift_product_sku_infos = this.state.selected_gift_products;
            }

            updateBundleOffer(offerId, formData)
                .then(res => {
                    this.props.history.push(`${process.env.PUBLIC_URL}/promotions/bundle-offer`);
                    toast.success(res.message);
                    activityLogPostData('was update this bundle', '/promotions/bundle-offer/edit/' + offerId, this.state.formData.offer_name)
                })
                .catch(errMsg => {
                    toast.error(errMsg)
                })
            this.setState({isSubmitting: true});
            setTimeout(() => {
                this.setState({isSubmitting: false, isFormValidated: false})
            }, 2000)
        }
    }

    isProductIncluded = (id) => {
        let type = "products_skus";
        console.log("productType", this.state.productType);
        if (this.state.productType === 'gift_products') {
            type = "gift_products_skus"
        }

        if (this.state.formData[type] === undefined) return false;
        if (this.state.formData[type] === null) return false;
        if (this.state.formData[type].length === 0) return false;
        let answer = false;
        this.state.formData[type].forEach(each => {
            if (each._id.toString() === id.toString())
                answer = true;
        })
        return answer;
    }

    isProductIncluded2 = (id) => {
        let type = "gift_products_skus"
        console.log('here this is ', id, ' => ', this.state.formData[type])

        if (this.state.formData[type] === undefined) return false;
        if (this.state.formData[type] === null) return false;
        if (this.state.formData[type].length === 0) return false;
        let answer = false;

        this.state.formData[type].forEach(each => {
            if (each._id.toString() === id.toString()) {
                answer = true;
            }
        })
        return answer;
    }

    handleInputOnChangeV2 = (e) => {
        const formData = {...this.state.formData};
        formData[e.target.name] = e.target.value;
        this.setState({formData,  productList: []})
    }


    render() {
        return (
            <App layout="boxed">
                <div className="page-header">
                    <h2 className="page-title">Edit Bundle Offer</h2>
                    <div className="ml-auto">
                        <Link to={`${process.env.PUBLIC_URL}/promotions/bundle-offer`}
                              className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Bundle
                            Offer List</Link>
                    </div>
                </div>

                <div className="main-content">
                    {(getLocalAuthData()?.permission?.includes('bundle_offer_edit') && !this.state.isLoading) ?
                        <Form
                            noValidate validated={this.state.isFormValidated}
                            onSubmit={this.handleSubmitForm}>
                            <div className="card-block">
                                <div className="block-header">
                                    <h4 className="block-title">Define Offer</h4>
                                </div>

                                <div className="block-body">
                                    <Form.Group as={Row} controlId="formPromotionName">
                                        <Form.Label column sm="3">
                                            Promotion Name <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control type="text"
                                                          onChange={this.handleInputOnChange}
                                                          name="offer_name"
                                                          defaultValue={this.state.formData?.offer_name}
                                                          required
                                                          placeholder=""/>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formStartFrom">
                                        <Form.Label column sm="3">
                                            Date Range <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <DateRangePicker
                                                containerStyles={{textAlign: 'left', width: '100%', display: 'block'}}
                                                onApply={this.handleOnApply}>
                                                <input type="button"
                                                       value={`${this.state.formData?.start_time} to ${this.state.formData?.end_time}`}
                                                       style={{textAlign: 'left'}}
                                                       className="form-control"/>
                                            </DateRangePicker>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formVoucherType">
                                        <Form.Label column sm="3">
                                            Promotion Type <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control as="select"
                                                          required
                                                          value={this.state.formData?.promotion_type}
                                                          name={`promotion_type`}
                                                          onChange={this.handlePromotionTypeOnChange} placeholder="">
                                                {/*<option value="1">Quantity</option>*/}
                                                <option value="1" label={'--Select--'}/>
                                                <option value="2">Buy 1 Get 1</option>
                                                <option value="3">Free Gift</option>
                                                <option value="4">Bundle</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className="card-block">
                                <div className="block-body">
                                    <Form.Group as={Row} controlId="applicableFor">
                                        <Form.Label column sm="3">
                                            Applicable For <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control
                                                required
                                                name="apply_to"
                                                value={this.state.formData?.apply_to}
                                                onChange={this.handleOnChangeApplicableFor}
                                                as="select" placeholder="">
                                                <option value="">--Select--</option>
                                                <option value="2">Specific Stores</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="forStores">
                                        <Form.Label column sm="3">
                                            Store(s) <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            {(this.state.formData?.stores?.length === 0 || this.state.selectedStores.length > 0) &&

                                            <AsyncSelect
                                                isMulti={false}
                                                required
                                                // value={this.state.attribute}
                                                styles={styleReactSelect}
                                                placeholder="Select Attribute..."
                                                defaultValue={this.state.selectedStoresDefault}
                                                onChange={(value) => {
                                                    this.handleSelectMultiOnChange(value, 'stores');
                                                }}
                                                cacheOptions
                                                defaultOptions
                                                loadOptions={this.promiseOptions}
                                                options={this.state.storeList}
                                                name="attributes"
                                                noOptionsMessage={() => 'Select store...'}
                                            />

                                            }
                                        </Col>
                                    </Form.Group>
                                    {this.state.isApplyForCat &&
                                    <Form.Group as={Row} controlId="forCategories">
                                        <Form.Label column sm="3">
                                            Categories <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Select
                                                isMulti
                                                // value={this.state.attribute}
                                                styles={styleReactSelect}
                                                placeholder="Select Attribute..."
                                                onChange={(value) => {
                                                    this.handleSelectMultiOnChange(value, 'categories')
                                                }}
                                                options={[{
                                                    id: 1,
                                                    value: 'Men\'s Fashion',
                                                    label: 'Men\'s Fashion'
                                                },
                                                    {
                                                        id: 2,
                                                        value: 'Electronic',
                                                        label: 'Electronic'
                                                    }]}
                                                name="attributes"
                                                noOptionsMessage={() => 'Select store...'}
                                            />
                                        </Col>
                                    </Form.Group>}
                                </div>
                            </div>

                            <div className="card-block">
                                <div className="block-header">
                                    <h4 className="block-title">Percentage</h4>
                                </div>
                                <div className="block-body">
                                    <Form.Group as={Row} controlId="discountPercentage">
                                        <Form.Label column sm="3">
                                            Admin Discount Percentage (%) <span
                                            className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control type="number"
                                                          defaultValue={this.state.formData?.admin_discount_percentage}
                                                          onChange={this.handleInputOnChangeV2}
                                                          name="admin_discount_percentage" placeholder=""/>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="discountPercentage">
                                        <Form.Label column sm="3">
                                            Store Discount Percentage (%) <span
                                            className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control type="number"
                                                          defaultValue={this.state.formData?.store_discount_percentage}
                                                          onChange={this.handleInputOnChangeV2}
                                                          name="store_discount_percentage" placeholder=""/>
                                        </Col>
                                    </Form.Group>

                                </div>
                            </div>

                            {this.state.formData?.promotion_type === '1' ?
                                <>
                                    <div className="card-block">
                                        <div className="block-header">
                                            <h4 className="block-title">Discount Details</h4>
                                        </div>

                                        <div className="block-body">
                                            <Form.Group as={Row} controlId="formBundleDiscountType">
                                                <Form.Label column sm="3">
                                                    Discount Type
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    {this.state.formData?.discount_type &&
                                                    <>
                                                        <Form.Check
                                                            custom
                                                            inline
                                                            required
                                                            type="radio"
                                                            value={`1`}
                                                            defaultChecked={this.state.formData?.discount_type === '1'}
                                                            onChange={this.handleOfferTypeOnChange}
                                                            name="discount_type"
                                                            id={`money-value-on-quantity`}
                                                            label={`Money Value`}
                                                        />
                                                        <Form.Check
                                                            custom
                                                            inline
                                                            required
                                                            value={`2`}
                                                            defaultChecked={this.state.formData?.discount_type === "2"}
                                                            onChange={this.handleOfferTypeOnChange}
                                                            type="radio"
                                                            name="discount_type"
                                                            id={`percentage-on-quantity`}
                                                            label={`Percentage`}
                                                        />
                                                    </>
                                                    }
                                                </Col>
                                            </Form.Group>

                                            {this.state.formData?.offer_value?.length > 0 &&
                                            <>
                                                {this.state.formData?.discount_type === '2' ?
                                                    <>
                                                        <table className={`table table-striped`}>
                                                            <thead className={`sm`}>
                                                            <tr>
                                                                <th>Item Quantity</th>
                                                                <th>Discount Amount (%)</th>
                                                                <th style={{width: '1%'}}/>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.state.formData?.offer_value.map((offer, index) => (

                                                                <tr key={index}>
                                                                    <td>
                                                                        <Form.Control type={`number`}
                                                                                      name={`item`}
                                                                                      required
                                                                                      defaultValue={this.state.formData?.offer_value[index].item}
                                                                                      onChange={(e) => this.handleDiscountValueOnChange(e, index)}
                                                                                      placeholder={`Quantity`}
                                                                                      size={`sm`}/>
                                                                    </td>
                                                                    <td>
                                                                        <Form.Control type={`number`}
                                                                                      name={`discount_value`}
                                                                                      required
                                                                                      defaultValue={this.state.formData?.offer_value[index].discount_value}
                                                                                      onChange={(e) => this.handleDiscountValueOnChange(e, index)}
                                                                                      placeholder={`Percentage`}
                                                                                      size={`sm`}/>
                                                                    </td>
                                                                    <td>
                                    <span className={`text-danger`}
                                          onClick={() => this.handleRemoveItem(index)}
                                          style={{fontWeight: 'bold', cursor: 'pointer'}}>&times;</span>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>

                                                        <span style={{fontWeight: 'bold', cursor: `pointer`}}
                                                              onClick={this.addMoreOfferValue}
                                                              className={`text-primary`}>+ Add more</span>

                                                        <hr/>

                                                        <Form.Group as={Row} controlId="formBundleDiscountType">
                                                            <Form.Label column sm="3">
                                                                Maximum Discount Amount
                                                            </Form.Label>
                                                            <Col sm="9" md={8} lg={7}>
                                                                <Form.Control
                                                                    onChange={this.handleInputOnChange}
                                                                    name="maximum_discount_amount"
                                                                    defaultValue={this.state.formData?.maximum_discount_amount}
                                                                    required placeholder={`Maximum discount amount`}/>
                                                            </Col>
                                                        </Form.Group>
                                                    </>
                                                    :
                                                    <>
                                                        <table className={`table table-striped`}>
                                                            <thead className={`sm`}>
                                                            <tr>
                                                                <th>Item Quantity</th>
                                                                <th>Discount Amount</th>
                                                                <th style={{width: '1%'}}/>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.state.formData?.offer_value.map((offer, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <Form.Control type={`number`}
                                                                                      name={`item`}
                                                                                      required
                                                                                      defaultValue={offer.item}
                                                                                      onChange={(e) => this.handleDiscountValueOnChange(e, index)}
                                                                                      placeholder={`Quantity`}
                                                                                      size={`sm`}/>
                                                                    </td>
                                                                    <td>
                                                                        <Form.Control type={`number`}
                                                                                      name={`discount_value`}
                                                                                      required
                                                                                      defaultValue={offer.discount_value}
                                                                                      onChange={(e) => this.handleDiscountValueOnChange(e, index)}
                                                                                      placeholder={`Percentage`}
                                                                                      size={`sm`}/>
                                                                    </td>
                                                                    <td><span className={`text-danger`}
                                                                              onClick={() => this.handleRemoveItem(index)}
                                                                              style={{
                                                                                  fontWeight: 'bold',
                                                                                  cursor: 'pointer'
                                                                              }}>&times;</span></td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>

                                                        <span style={{fontWeight: 'bold', cursor: `pointer`}}
                                                              onClick={this.addMoreOfferValue}
                                                              className={`text-primary`}>+ Add more</span>

                                                        <hr/>

                                                        <Form.Group as={Row} controlId="formBundleDiscountType">
                                                            <Form.Label column sm="3">
                                                                Minimum Spend Amount
                                                            </Form.Label>
                                                            <Col sm="9" md={8} lg={7}>
                                                                <Form.Control
                                                                    onChange={this.handleInputOnChange}
                                                                    defaultValue={this.state.formData?.minimum_spend_amount}
                                                                    name="minimum_spend_amount"
                                                                    required placeholder={`Minimum discount amount`}/>
                                                            </Col>
                                                        </Form.Group>
                                                    </>}
                                            </>
                                            }
                                        </div>
                                    </div>

                                    <Modal show={this.state.isProductModalProductShowing}
                                           onHide={this.handleHideProductModal}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                Select Product
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="mb-3 form-inline">
                                                <Form.Group>
                                                    <Form.Label srOnly>Search</Form.Label>
                                                    <Form.Control type={`search`}
                                                                  onKeyDown={(e) => {
                                                                      if (e.keyCode === 13) {
                                                                          this.handleInputOnChange(e)
                                                                      }
                                                                  }}
                                                                  name="search_product"
                                                                  size={`sm`}
                                                                  placeholder={`Search...`}/>
                                                </Form.Group>
                                                <Button type={`button`} onClick={e => this.handleSearchProduct(e)}
                                                        size={`sm`} variant={`info`}
                                                        className={`ml-2`}>Search</Button>
                                            </div>
                                            {
                                                this.state.errorMessage ?
                                                    <h5>{this.state.errorMessage}</h5> :
                                                    <>
                                                        <div className="product-checkbox-list">
                                                            {
                                                                this.state.isProductListLoading &&
                                                                <LoadingSpinner text={`Loading...`}/>
                                                            }
                                                            {this.state.productList.map((product, index) => (
                                                                <label className={`product-item`} key={product?.sku_info?._id}
                                                                       htmlFor={`product-${product?.sku_info?._id}`}>
                                                                    {this.state.formData.promotion_type === '3' ?
                                                                        <input id={`product-${product?.sku_info?._id}`}
                                                                               onChange={e => this.handleSelectedProductOnChange(e, product.sku_info)}
                                                                               name={`product`}
                                                                               type="checkbox"
                                                                               defaultChecked={this.isProductIncluded(product?.sku_info?._id)}/>
                                                                        :
                                                                        <input id={`product-${product?.sku_info?._id}`}
                                                                               onChange={e => this.handleSelectedProductOnChange(e, product._id, product.sku_info)}
                                                                               name={`product`}
                                                                               type="checkbox"
                                                                               defaultChecked={this.isProductIncluded(product?.sku_info?._id)}/>
                                                                    }
                                                                    <span className={`product`}>
                                <span className="product-img">
                                  <img
                                      src={product?.sku_info?.images[0] ? product?.sku_info?.images[0]?.thumb_img : product?.thumb_img}
                                      alt=""/>
                                </span>
                                <span className="product-short-info">
                                  <span
                                      className="product-name">{product.name} {product?.sku_info?.attr_combo ? product?.sku_info?.attr_combo.join('-') : ''}</span>
                                </span>
                                <span className="icon"><IoMdCheckmark/></span>
                              </span>
                                                                </label>
                                                            ))}
                                                        </div>
                                                    </>
                                            }


                                        </Modal.Body>
                                        <Modal.Footer>
                                            {/*<Button variant="secondary" onClick={this.handleHideProductModal}>*/}
                                            {/*  Close*/}
                                            {/*</Button>*/}
                                            {/*<Button variant="primary"*/}
                                            {/*        onClick={() => this.handleAddProduct(this.state.productType, this.state.selectedProducts, this.state.Products_sku)}>*/}
                                            {/*  Save Changes*/}
                                            {/*</Button>*/}
                                        </Modal.Footer>
                                    </Modal>

                                    <div className="card-block">
                                        <div className="block-header">
                                            <h4 className="block-title">Product</h4>
                                        </div>

                                        <div className="block-body">
                                            <table className={`table table-striped`}>
                                                <thead className={`sm`}>
                                                <tr>
                                                    <th style={{width: '50px'}}/>
                                                    <th>Product Name</th>
                                                    <th>Product Price</th>
                                                    <th style={{width: '1%'}}/>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                {this.state.products.length > 0 ?
                                                    <>

                                                        {this.state.products.map((product, index) => {

                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <img
                                                                            src={product?.sku_info?.images[0] ? product?.sku_info?.images[0]?.thumb_img : product?.thumb_img}
                                                                            style={{width: '40px'}} alt=""/>
                                                                    </td>
                                                                    <td>{product?.name} {product?.sku_info?.attr_combo ? product?.sku_info?.attr_combo.join('-') : ''}</td>
                                                                    <td>{product?.sku_info?.price}</td>
                                                                    <td><span style={{cursor: 'pointer'}}
                                                                              onClick={() => this.handleRemoveSelectedProduct('products', product.sku_info._id)}
                                                                              className={`text-bold text-danger`}>&times;</span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>{this.state.price_sum}</td>
                                                            <td>&nbsp;</td>
                                                        </tr>
                                                    </>
                                                    :
                                                    <tr>
                                                        <td colSpan={3} className={`text-center text-bold`}>
                                                            No product selected
                                                        </td>
                                                    </tr>
                                                }

                                                </tbody>
                                            </table>

                                            <Button size="sm"
                                                    onClick={() => this.handleShowSelectProductModal('products')}
                                                    variant="primary">
                                                {this.state.formData.promotion_type === '4' ?
                                                    "Select Mother Product"
                                                    :
                                                    "Select Product"
                                                }
                                            </Button>
                                        </div>
                                    </div>
                                </>
                                :
                                <>

                                    <Modal show={this.state.isProductModalProductShowing}
                                           onHide={this.handleHideProductModal}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                Select Product
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="mb-3 form-inline">
                                                <Form.Group>
                                                    <Form.Label srOnly>Search</Form.Label>
                                                    <Form.Control type={`search`}
                                                                  onKeyDown={(e) => {
                                                                      if (e.keyCode === 13) {
                                                                          this.handleInputOnChange(e)
                                                                      }
                                                                  }}
                                                                  name="search_product"
                                                                  size={`sm`}
                                                                  placeholder={`Seeeearch...`}/>
                                                </Form.Group>
                                                <Button type={`button`} onClick={e => this.handleSearchProduct(e)}
                                                        size={`sm`} variant={`info`}
                                                        className={`ml-2`}>Search</Button>
                                            </div>

                                            {
                                                this.state.errorMessage ?
                                                    <h5>{this.state.errorMessage}</h5> :
                                                    <>
                                                        <div className="product-checkbox-list">
                                                            {
                                                                this.state.isProductListLoading &&
                                                                <LoadingSpinner text={`Loading...`}/>
                                                            }
                                                            {this.state.productList.map((product, index) => (
                                                                <label className={`product-item`} key={index}
                                                                       htmlFor={`product-${index}`}>
                                                                    <input id={`product-${index}`}
                                                                           onChange={e => this.handleSelectedProductOnChange(e, product._id, product.sku_info)}
                                                                           name={`product`}
                                                                           defaultChecked={this.isProductIncluded(product?.sku_info?._id)}
                                                                           type="checkbox"/>
                                                                    <span className={`product`}>
                                <span className="product-img">
                                  <img
                                      src={product?.sku_info?.images && product?.sku_info?.images.length > 0 ? product?.sku_info?.images[0]?.thumb_img : product?.thumb_img}
                                      alt=""/>
                                </span>
                                <span className="product-short-info">
                                  <span
                                      className="product-name">{product.name} {product?.sku_info?.attr_combo ? product?.sku_info?.attr_combo.join('-') : ''}</span>
                                </span>
                                <span className="icon"><IoMdCheckmark/></span>
                              </span>
                                                                </label>
                                                            ))}
                                                        </div>
                                                        <Pagination
                                                            itemClass="page-item"
                                                            linkClass="page-link"
                                                            activePage={this.state?.productListPagination?.page}
                                                            itemsCountPerPage={this.state?.productListPagination?.limit}
                                                            totalItemsCount={this.state?.productListPagination?.totalDocs}
                                                            onChange={this.handleRegisteredStorePageChange.bind(this)}/>
                                                    </>
                                            }
                                        </Modal.Body>
                                        <Modal.Footer>
                                            {/*<Button variant="secondary" onClick={this.handleHideProductModal}>*/}
                                            {/*  Close*/}
                                            {/*</Button>*/}
                                            {/*<Button variant="primary"*/}
                                            {/*        onClick={() => this.handleAddProduct(this.state.productType, this.state.selectedProducts, this.state.Products_sku)}>*/}
                                            {/*  Save Changes*/}
                                            {/*</Button>*/}
                                        </Modal.Footer>
                                    </Modal>

                                    <div className="card-block">
                                        <div className="block-header">
                                            <h4 className="block-title">Product</h4>
                                        </div>

                                        <div className="block-body">
                                            <table className={`table table-striped`}>
                                                <thead className={`sm`}>
                                                <tr>
                                                    <th style={{width: '50px'}}/>
                                                    <th>Product Name</th>
                                                    <th>Product Price</th>
                                                    <th style={{width: '1%'}}/>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                {this.state.selected_products.length > 0 ?
                                                    <>

                                                        {this.state.selected_products.map((product, index) => {

                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <img
                                                                            src={product?.sku_info?.images[0] ? product?.sku_info?.images[0]?.thumb_img : product?.thumb_img}
                                                                            style={{width: '40px'}} alt=""/>
                                                                    </td>
                                                                    <td>{product?.name} {product?.sku_info?.attr_combo ? product?.sku_info?.attr_combo.join('-') : ''}</td>
                                                                    <td>{product?.sku_info?.price}</td>
                                                                    <td><span style={{cursor: 'pointer'}}
                                                                              onClick={() => this.handleRemoveSelectedProduct('products', product.sku_info._id)}
                                                                              className={`text-bold text-danger`}>&times;</span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>{this.state.price_sum}</td>
                                                            <td>&nbsp;</td>
                                                        </tr>
                                                    </>
                                                    :
                                                    <tr>
                                                        <td colSpan={3} className={`text-center text-bold`}>
                                                            No product selected
                                                        </td>
                                                    </tr>
                                                }

                                                </tbody>
                                            </table>

                                            <Button size="sm"
                                                    onClick={() => this.handleShowSelectProductModal('products')}
                                                    variant="primary">
                                                {this.state.formData.promotion_type === '4' ?
                                                    "Select Mother Product"
                                                    :
                                                    "Select Product"
                                                }
                                            </Button>
                                        </div>
                                    </div>

                                    {(this.state.formData.promotion_type === '3' || this.state.formData.promotion_type === '4') &&
                                    <div className="card-block">
                                        <div className="block-header">
                                            <h4 className="block-title">
                                                {this.state.formData.promotion_type === '4' ?
                                                    "Bundle Product"
                                                    :
                                                    "Gift Product"
                                                }

                                            </h4>
                                        </div>

                                        <div className="block-body">
                                            <table className={`table table-striped`}>
                                                <thead className={`sm`}>
                                                <tr>
                                                    <th style={{width: '50px'}}/>
                                                    <th>Product Name</th>
                                                    <th>Product Price</th>
                                                    <th style={{width: '1%'}}/>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                {this.state.selected_gift_products.length > 0 ?
                                                    <>
                                                        {this.state.selected_gift_products.map((product, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <img
                                                                        src={product?.sku_info?.images[0] ? product?.sku_info?.images[0]?.thumb_img : product?.thumb_img}
                                                                        style={{width: '40px'}} alt=""/>
                                                                </td>
                                                                <span
                                                                    className="product-name">{product?.name} {product?.sku_info?.attr_combo ? product?.sku_info?.attr_combo.join('-') : ''}</span>
                                                                <td>{product?.sku_info?.price}</td>
                                                                <td><span style={{cursor: 'pointer'}}
                                                                          onClick={() => this.handleRemoveSelectedProduct('gift_products', product?.sku_info?._id)}
                                                                          className={`text-bold text-danger`}>&times;</span>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>{this.state.gift_sum}</td>
                                                            <td>&nbsp;</td>
                                                        </tr>
                                                    </>
                                                    :
                                                    <tr>
                                                        <td colSpan={3} className={`text-center text-bold`}>
                                                            No product selected
                                                        </td>
                                                    </tr>
                                                }
                                                </tbody>
                                            </table>
                                            <Button size="sm"
                                                    onClick={() => this.handleShowSelectProductModal('gift_products')}
                                                    variant="primary"> + Add Product</Button>
                                        </div>
                                    </div>
                                    }

                                    {this.state.formData?.promotion_type === '4' &&
                                    <div className="card-block">
                                        <div className="block-header">
                                            <h4 className="block-title">Bundle Info</h4>
                                        </div>

                                        <div className="block-body">
                                            {/*<Form.Group as={Row} controlId="formBundleDiscountType">
                        <Form.Label column sm="3">
                          Bulk Price
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control
                            onChange={this.handleInputOnChange}
                            defaultValue={this.state.formData.bulk_price}
                            name="bulk_price"
                            required placeholder={`Bulk Price`}/>
                        </Col>

                      </Form.Group>*/}


                                            <Form.Group as={Row} controlId="formBundleDiscountType">
                                                <Form.Label column sm="3">
                                                    Bundle Actual Price
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <span>{this.state.gift_sum + this.state.price_sum}</span>
                                                </Col>

                                            </Form.Group>


                                            <Form.Group as={Row} controlId="formBundleDiscountType">
                                                <Form.Label column sm="3">
                                                    Bundle Discounted Price
                                                </Form.Label>
                                                {console.log("bulk_price", this.state.formData.bulk_price)}
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control
                                                        onChange={this.handleInputOnChange}
                                                        value={this.state.formData.bulk_price ? this.state.formData.bulk_price : ""}
                                                        name="bulk_price"
                                                        required placeholder={`Bulk Price`}/>
                                                </Col>

                                            </Form.Group>


                                            <Form.Group as={Row} controlId="formBundleDiscountType">

                                                <Form.Label column sm="3">
                                                    Bundle Image
                                                </Form.Label>

                                                <Col sm="9" md={8} lg={7}>
                                                    <BrowseFiles
                                                        name="bundle_banner"
                                                        isSingle={true}
                                                        image_category={'banner'}
                                                        onRemoveItem={this.handleOnRemoveItem}
                                                        images={this.state.brandData.logo ? [{thumb_img: this.state.brandData.logo}] : []}
                                                        onGetFiles={this.handleImageOnBrowse}/>
                                                </Col>

                                            </Form.Group>
                                        </div>
                                    </div>

                                    }
                                </>
                            }

                            {getLocalAuthData()?.permission?.includes('bundle_offer_edit') ?
                                <div className="card-block">
                                    <div className="block-body text-right">
                                        <Link to={`/promotions/bundle-offer`}
                                              className="btn btn-lg btn-default mr-3">Cancel</Link>
                                        <Button size="lg" type={`submit`}
                                                disabled={this.state.isSubmitting}
                                                variant="primary">{this.state.isSubmitting ? 'Updating...' : 'Update'}</Button>
                                    </div>
                                </div> : null}
                        </Form>
                        :
                        <div className="card-block">
                            <div className="block-body">
                                Loading...
                            </div>
                        </div>
                    }
                </div>
            </App>
        );
    }

}

export default withRouter(EditBundleOffer);
