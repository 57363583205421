import axios from 'axios';
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData, objectToFormData} from "../helper/utils";

export const add = (data) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.post(API_BASE_URL + '/wallet-redeem-package', data, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject('Something went wrong')
        })
    })
}

export const update = (id, data) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.put(`${API_BASE_URL}/wallet-redeem-package/${id}`, data, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            console.log(err);
            reject('Something went wrong')
        })
    });
}

let cancelToken;

export const list = (paramsObj = {}) => {
    const authData = getLocalAuthData();
    cancelToken = axios.CancelToken.source();

    return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + `/wallet-redeem-package`, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            },
            params: paramsObj,
            cancelToken: cancelToken.token
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.message)
        })
    })
}

export const getDetails = (id) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + `/wallet-redeem-package/${id}`, {
            headers: {'x-auth-token': authData.token, 'x-api-client': getDeviceType()}
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.message);
        })
    })
}

export const deleteItem = (id) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.delete(`${API_BASE_URL}/wallet-redeem-package/${id}`, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.message)
        })
    })
}

export const revertDeletedItem = (id) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.patch(`${API_BASE_URL}/wallet-redeem-package/revert-deleted/${id}`, {
            headers: {"x-auth-token": authData.token, "x-api-client": "web"}
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            console.log('Type delete error: ', err);
            reject('Something went wrong');
        })
    })
}

export const hardDeleteItem = (id) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.delete(`${API_BASE_URL}/wallet-redeem-package/${id}/permanent`,
            {
                headers: {
                    'x-auth-token': authData.token,
                    'x-api-client': getDeviceType()
                }
            }
        ).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.message)
        })
    })
}

export const changeItemStatus = (id, data) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.patch(`${API_BASE_URL}/wallet-redeem-package/changeStatus/${id}`, data, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.message)
        })
    })
}
