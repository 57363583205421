import React, {Component} from 'react';
import App from "../../App";
import {withRouter, Link} from "react-router-dom";
import {approvalStatusUpdate, deleteStore, getStoreList, statusUpdate} from "../../services/storeServices";
import {
  updateStorePaymentMethods,
  storePaymentMethods,
  productPaymentMethods,
  updateProductPaymentMethods
} from "../../services/paymentMethodServices";
import {toast} from "react-toastify";
import moment from "moment";
import {confirmAlert} from "react-confirm-alert";
import {getDeviceType, getLocalAuthData, urlPramsToObj} from "../../helper/utils";
import {Button, Col, Form, InputGroup, Modal, Row} from "react-bootstrap";
import {FiCheck, FiCheckCircle, FiEdit, FiSearch, FiTrash, IoMdClose} from "react-icons/all";
import Pagination from 'react-js-pagination';
import LoadingSpinner from "../../components/LoadingSpinner";
import {getChildLocations} from "../../services/locationServices";
import {
  shippingOptions,
  addShippingOption,
  updateShippingOption,
  deleteShippingOption,
  deleteShippingOptionPermanent, getSingleShippingOption
} from "../../services/shippingMethodServices";
import axios from "axios";
import {API_BASE_URL} from "../../helper/env";
import templateThumbOne from "../../assets/template-layouts/template-1.png";
import templateThumbTwo from "../../assets/template-layouts/template-2.png";
import {activityLogPostData} from "../../services/commonServices";
import store from "../../redux/store";

class StoreList extends Component {
  state = {
    storeList: [],
    loading: false,
    pagination: null,
    isDataLoading: false,
    merchant: '',
    settings: {
      limit: 50
    },

    locations: [],
    selectedStoreId: '',
    isShowingStoreSettingModal: false,
    isShowingPaymentMethodSettingModal: false,
    isSubmittingPaymentMethods: false,
    defaultPaymentMethod: ["cash on delivery", "pegepay", "Bank Payment", "sslcommerz"],
    selectedPaymentMethods: [],

    activeSettingTab: 'Shipping Options',
    shippingQueryData: {limit: 10, page: 1, store_id: ''},
    selectedShippingPartners: [],
    shippingChildLocations: [],
    shipping_methods: [],
    shippingFormData: {},
    existing_locations: [],
    selectedShippingId: '',
    editStatus: false,
    store_template_id: null
  }

  componentDidMount() {
    let merchant = null;
    this.setState({loading: true});
    const history = this.props.history;
    if (history.location.search) {
      let queryObj = urlPramsToObj(history);
      if (queryObj.merchant) {
        merchant = queryObj.merchant;
        this.setState({merchant: queryObj.merchant});
      }
    }
    getStoreList({...this.state.settings, merchant: merchant})
      .then(res => {
        this.setState({storeList: res.items, pagination: res.pagination, loading: false});
      })
      .catch(errMsg => {
        this.setState({loading: false});
      })

    axios.get(`${API_BASE_URL}/location?type=Country`)
        .then((res) => {
          let existing_locations = [...this.state.existing_locations];
          existing_locations[0] = res.data.locations;
          this.setState(
              {
                existing_locations,
                locations: res.data.locations,
                countries: res.data.locations,
              },
              () => {
                console.log(this.state);
              }
          );
        }).catch((e) => console.log("Error4"));

    this.setNewShippingFormData()
  }

  setNewShippingFormData = () =>{
    this.setState({
      shippingFormData: {
        shipping_option: "ekShop Fulfillment",
        location_hierarchy: [],
        locations: [],
        destination_location: "",
        estimated_time: "",
        fee: 0,
      },
      existing_locations: this.state.existing_locations.splice(0, 1)
    }, ()=>{

    })
  }

  renderStatus = (status) => {
    if (status === 0) {
      return <span className={"badge badge-primary"}>Pending</span>
    } else if (status === 1) {
      return <span className={"badge badge-success"}>Active</span>
    } else {
      return <span className={"badge badge-warning"}>Blocked</span>
    }
  }

  handleDeleteStore = (id,name) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to delete this store?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteStore(id)
            .then(res => {
              this.setState({isDataLoading: true}, () => {
                getStoreList({...this.state.settings, merchant: this.state.merchant})
                  .then(res => {
                    this.setState({storeList: res.items, pagination: res.pagination, isDataLoading: false});
                  })
                  .catch(errMsg => {
                    this.setState({isDataLoading: false});
                  })
              })

              toast.success(res.message);
              activityLogPostData('was delete this Store','/stores/details/'+id,name)
            })
            .catch(errMsg => {
              toast.error(errMsg);
            })
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }

  handleStorePaymentMethodsModal = (id) => {
    storePaymentMethods(id).then(res => {
      this.setState({
        selectedStoreId: id,
        isShowingPaymentMethodSettingModal: true,
        selectedPaymentMethods: res.payment_methods
      });
    }).catch(errMsg => {
      toast.error(errMsg.message);
    })
  }

  handleOnChangePaymentMethods = (e, methodName) => {
    const selectedPaymentMethods = [...this.state.selectedPaymentMethods];
    var index = selectedPaymentMethods.indexOf(methodName);
    if (e.target.checked) {
      if (index === -1) {
        selectedPaymentMethods.push(methodName);
      }
    } else {
      if (index > -1) {
        selectedPaymentMethods.splice(index, 1);
      }
    }
    this.setState({selectedPaymentMethods}, () => {
      console.log(this.state.selectedPaymentMethods);
    });
  }

  submitChangeStorePaymentMethods = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    let selectedPaymentMethods = [...this.state.selectedPaymentMethods];

    this.setState({isSubmittingPaymentMethods: true}, () => {
      console.log(selectedPaymentMethods);
      updateStorePaymentMethods({payment_methods: selectedPaymentMethods}, this.state.selectedStoreId).then(res => {
        if (res.status === 1) {
          this.setState({
            selectedStoreId: '',
            isShowingPaymentMethodSettingModal: false,
            isSubmittingPaymentMethods: false
          });
          toast.success(res.message);
        } else {
          this.setState({
            selectedStoreId: '',
            isShowingPaymentMethodSettingModal: false,
            isSubmittingPaymentMethods: false
          });
          toast.warning(res.message);
        }

      }).catch(errMsg => {
        toast.error(errMsg);
      })
    });
  }

  handleCloseStorePaymentMethodsModal = () => {
    this.setState({isShowingPaymentMethodSettingModal: false});
  }

  handleApprovalStatusUpdate = (storeId, status, action) => {
    confirmAlert({
      title: 'Confirm',
      message: `Are you sure to ${action.toLowerCase()} this store?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => approvalStatusUpdate(storeId, {approval_status: status})
            .then(res => {
              this.setState({isDataLoading: true}, () => {
                getStoreList({...this.state.settings, merchant: this.state.merchant})
                  .then(res => {
                    this.setState({storeList: res.items, pagination: res.pagination, isDataLoading: false});
                  })
                  .catch(errMsg => {
                    this.setState({isDataLoading: false});
                  })
              })
              toast.success(res.message);
              activityLogPostData('was change store status','/stores/edit/'+store,status)
            })
            .catch(errMsg => {
              toast.error(errMsg);
            })
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }

  renderProfileStatus = (storeId, status) => {
    if (status === 0 || status === undefined) {
      return (
        <>
          <span style={{cursor: 'pointer'}} onClick={() => this.handleApprovalStatusUpdate(storeId, 1, 'Approve')}
                className={`text-bold text-success mr-4`}><FiCheck/> Approve</span><br/>

          <span style={{cursor: 'pointer'}} onClick={() => this.handleApprovalStatusUpdate(storeId, 2, 'Declined')}
                className={`text-bold text-danger`}><IoMdClose/> Decline</span>
        </>
      )
    } else if (status === 1) {
      return (
        <>
          <span className={`badge badge-success`}>Approved</span><br/>

          <span style={{cursor: 'pointer'}} onClick={() => this.handleApprovalStatusUpdate(storeId, 2, 'Declined')}
                className={`text-bold text-danger`}><IoMdClose/> Decline</span>
        </>
      )

    } else {
      return (
        <>
          <span className={`badge badge-danger`}>Declined</span><br/>
          <span style={{cursor: 'pointer'}} onClick={() => this.handleApprovalStatusUpdate(storeId, 1, 'Approve')}
                className={`text-bold text-success mr-4`}><FiCheck/> Approve</span>
        </>
      )

    }
  }

  handleStoreFilter = (e) => {

  }

  handleOnChangeTemplate = (e) => {
    this.setState({store_template_id: e.target.value})
  }

  getStoreTemplate = (id) => {
    const authData = getLocalAuthData()

    axios.get(`${API_BASE_URL}/store/get-store-template/${id}`, {
      headers: {
        'x-auth-token': authData?.token || '',
        'x-api-client': getDeviceType()
      }
    })
      .then(res => {
        if(res.data.status === 1) {
          this.setState({store_template_id: res.data.store_template_id})
        }
      })
      .catch(err => {
        console.error(err.message)
      })
  }

  handleOnSaveTemplate = (storeId) => {
    const authData = getLocalAuthData()
    axios.patch(`${API_BASE_URL}/store/update-store-template/${storeId}`, {store_template_id: this.state.store_template_id}, {
      headers: {
        'x-auth-token': authData?.token || '',
        'x-api-client': getDeviceType()
      }
    })
      .then(res => {
        if(res.data.status === 1) {
          toast.success(res.data.message)
        }
      })
      .catch(err => {
        toast.error(err.message)
      })
  }

  /* START PAYMENT METHODS */

  handleStoreSettings = (id) => {
    let promises = [];
    promises.push(
      storePaymentMethods(id).then(res => {
        this.setState({selectedStoreId: id, selectedPaymentMethods: res.payment_methods});
      }).catch(errMsg => {
        toast.error(errMsg.message);
      })
    )

    promises.push(
      this.getShippingOptions({store_id: id})
    )

    this.getStoreTemplate(id)

    Promise.all(promises).then(res => {
      this.setState({
        selectedStoreId: id,
        isShowingStoreSettingModal: true,
        shippingFormData: {...this.state.shippingFormData, ["store_id"]: id}
      })
      activityLogPostData('was change store setting','/stores/edit/'+id,store?.store_name)
    }).catch(err => {
      console.log(err);
      this.setState({isLoading: false})
    });
  }

  getShippingOptions = (params = {}) => {
    shippingOptions(params).then(res => {
      this.setState({shipping_options: res.items, shippingMethodsPagination: res.pagination});
    }).catch(errMsg => {
      toast.error(errMsg.message);
    });
  }

  handleShippingMethodsPageChange = async (pageNumber) => {
    this.setState({shippingQueryData: {...this.state.shippingQueryData, page: pageNumber}}, () => {
      this.getShippingOptions({store_id: this.state.selectedStoreId, limit: 10, page: pageNumber});
    });
  }

  handleOnChangePaymentMethods = (e, methodName) => {
    const selectedPaymentMethods = [...this.state.selectedPaymentMethods];
    var index = selectedPaymentMethods.indexOf(methodName);
    if (e.target.checked) {
      if (index === -1) {
        selectedPaymentMethods.push(methodName);
      }
    } else {
      if (index > -1) {
        selectedPaymentMethods.splice(index, 1);
      }
    }
    this.setState({selectedPaymentMethods}, () => {
      console.log(this.state.selectedPaymentMethods);
    });
  }

  handleOnChangeShippingForm = (e) => {
    console.log(e.target.name);
    const shippingFormData = {...this.state.shippingFormData};
    shippingFormData[e.target.name] = e.target.value;
    this.setState({shippingFormData}, () => {
      console.log("this.state");
      console.log(this.state.shippingFormData);
    });
  }

  submitChangePaymentMethods = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    let selectedPaymentMethods = [...this.state.selectedPaymentMethods];

    this.setState({isSubmittingPaymentMethods: true}, () => {
      console.log(selectedPaymentMethods);
      updateStorePaymentMethods({payment_methods: selectedPaymentMethods}, this.state.selectedStoreId).then(res => {
        if (res.status === 1) {
          this.setState({selectedStoreId: '', isSubmittingPaymentMethods: false});
          toast.success(res.message);
        } else {
          this.setState({selectedStoreId: '', isSubmittingPaymentMethods: false});
          toast.warning(res.message);
        }

      }).catch(errMsg => {
        toast.error(errMsg);
      })
    });
  }

  submitChangeShippingMethods = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    let shippingFormData = {...this.state.shippingFormData};

    this.setState({isSubmittingShippingMethods: true}, () => {
      if (this.state.editStatus === false) {
        addShippingOption(this.state.shippingFormData)
            .then((res) => {
              if (res.status === 1) {
                this.getShippingOptions({store_id: shippingFormData.store_id});
                this.setState({isSubmittingShippingMethods: false});
                this.setNewShippingFormData()
                toast.success(res.message);
              } else {
                this.setState({isSubmittingShippingMethods: false});
                toast.warning(res.message);
              }
            })
            .catch((errMsg) => {
              toast.error(errMsg);
            });
      } else if (this.state.editStatus === true && this.state.selectedShippingId !== '') {
        updateShippingOption(this.state.shippingFormData, this.state.selectedShippingId).then((res) => {
          if (res.status === 1) {
            this.getShippingOptions({store_id: shippingFormData.store_id});
            this.setState({isSubmittingShippingMethods: false});
            this.setNewShippingFormData()
            toast.success(res.message);
          } else {
            this.setState({isSubmittingShippingMethods: false});
            toast.warning(res.message);
          }
        }).catch((errMsg) => {
          toast.error(errMsg);
        });
      } else {
        toast.warning("Required Data missing, refresh and try again or try later.");
      }
    });
  }

  handleCloseProductSettingModal = () => {
    this.setState({selectedStoreId: '', isShowingStoreSettingModal: false});
  }

  handleShippingLocationInputOnChange = (e, index, typePurpose) => {
    const existing_locations = [...this.state.existing_locations];
    if (typePurpose === "shippingChildLocations") {
    } else {
      this.handleReturnInputOnChange(e);
    }

    const shippingFormData = {...this.state.shippingFormData};
    // const updatedShippingAddress = {...this.state.updatedShippingAddress};
    let location_hierarchy = shippingFormData.location_hierarchy;
    let locations = shippingFormData.locations;

    if (e.target.value !== "") {
      if (index >= 0 && existing_locations.length > index) {
        existing_locations.splice(index + 1, existing_locations.length - index);
        location_hierarchy.splice(index, location_hierarchy.length - index);
        locations.splice(index, locations.length - index)
      }

      let selected_location_array = existing_locations[index];
      let selected_location = selected_location_array.find(
          (element) => element._id === e.target.value
      );
      location_hierarchy.push(selected_location._id);
      locations.push(selected_location)
      shippingFormData.location_hierarchy = location_hierarchy;
      shippingFormData.destination_location = selected_location._id;
      shippingFormData.locations = locations

      //   updatedShippingAddress.location_hierarchy = location_hierarchy
      //   updatedShippingAddress.locations = locations

      this.setState({shippingFormData}, () => {
        console.log(this.state.shippingFormData.location_hierarchy);
      });
      // this.setState({shippingFormData, updatedShippingAddress}, () => {
      //     console.log(this.state.shippingFormData.locations);
      // });

      getChildLocations(e.target.value)
          .then((res) => {
            if (res.length > 0) {
              existing_locations[index + 1] = res;
            }
            this.setState({existing_locations}, () => {
              console.log("locations");
              console.log(this.state);
            });
          })
          .catch((errMsg) => {
            console.log(errMsg);
            // childLocations.splice(index, childLocations.length - index)
            // this.setState({[typePurpose]: childLocations})
          });
    } else {
      if (index > 0 && existing_locations.length > index) {
        existing_locations.splice(index + 1, existing_locations.length - index);
        location_hierarchy.splice(index, location_hierarchy.length - 1);
        // locations.splice(index, locations.length - 1)
      }
      shippingFormData.location_hierarchy = location_hierarchy;
      // shippingFormData.locations = locations

      this.setState({shippingFormData, existing_locations}, () => {
        console.log(this.state.shippingFormData.locations);
      });
    }
  };

  editShippingOption = (id) => {
    getSingleShippingOption(id)
        .then((res) => {
          if (res.status === 1) {
            this.setState(
                {existing_locations: [this.state.existing_locations[0]], selectedShippingId: id, editStatus: true},
                () => {
                  console.log("this.state.selectedShippingId", this.state.selectedShippingId);
                  res.shipping_option.location_hierarchy.forEach((item, index) => {
                    getChildLocations(item)
                        .then((res) => {
                          const existing_locations = [
                            ...this.state.existing_locations,
                          ];
                          existing_locations[index + 1] = res;
                          this.setState({existing_locations});
                        })
                        .catch((errMsg) => {
                          console.log(errMsg);
                        });

                    this.setState({isShowEditShippingAddressModal: true});
                  });
                  this.setState({shippingFormData: res.shipping_option});
                }
            );
          } else {
            toast.error("Error2");
          }
        })
        .catch((err) => {
          toast.error("Error3");
        });
  };

  deleteShippingOption = (id) => {
    deleteShippingOptionPermanent(id).then(res => {
      if (res.status === 1) {
        this.getShippingOptions({store_id: this.state.selectedStoreId});
        this.setNewShippingFormData()
        toast.warning(res.message);
      } else if (res.status === 2) {
        toast.warning(res.message)
      }
    }).catch(errMsg => {
      console.log(errMsg);
      toast.error(errMsg.message);
    })
  }

  searchShippingOptions = async () => {
    this.getShippingOptions({...this.state.shippingQueryData,
      page: 1,
      destination_location: this.state.shippingFormData.destination_location,
      shipping_option: this.state.shippingFormData.shipping_option,
      store_id: this.state.selectedStoreId
    })
  }


  /* END PAYMENT METHODS */

  handleBlockTab = (label) => {
    this.setState({activeSettingTab: label})
  }

  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Stores</h2>
            <div className="ml-auto">
              { getLocalAuthData()?.permission?.includes('Create_stores') ? <Link to={"/stores/create"} className={"btn btn-sm btn-secondary"}>Create Store</Link> : null}
            </div>
          </div>

          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Store List</h4>
            </div>
            <div className="block-body position-relative">
              {this.state.isDataLoading &&
              <LoadingSpinner/>
              }

              {this.state.loading ?
                <div>Loading...</div>
                :
                <>
                  <div className={`d-flex mb-3`}>
                    <div className="form-inline">
                      <Form.Group className={`mr-2`}>
                        <Form.Label>Filter by: </Form.Label> &nbsp;&nbsp;
                        <Form.Control
                          onChange={(e) => {
                            const value = e.target.value
                            const settings = {...this.state.settings}
                            settings.approval_status = value
                            settings.page = 1

                            this.setState({isDataLoading: true, settings}, () => {
                              getStoreList({...this.state.settings, merchant: this.state.merchant})
                                .then(res => {
                                  this.setState({
                                    storeList: res.items,
                                    pagination: res.pagination,
                                    isDataLoading: false
                                  });
                                })
                                .catch(errMsg => {
                                  this.setState({isDataLoading: false});
                                })
                            })
                          }}
                          size={`sm`} as={`select`}>
                          <option value="">All</option>
                          <option value="0">In Review</option>
                          <option value="1">Approved</option>
                          <option value="2">Declined</option>
                        </Form.Control>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label srOnly>Filter by: </Form.Label>
                        <Form.Control size={`sm`}
                                      onChange={(e) => {
                                        const value = e.target.value
                                        const settings = {...this.state.settings}
                                        settings.status = value
                                        settings.page = 1

                                        this.setState({isDataLoading: true, settings}, () => {
                                          getStoreList({...this.state.settings, merchant: this.state.merchant})
                                            .then(res => {
                                              this.setState({
                                                storeList: res.items,
                                                pagination: res.pagination,
                                                isDataLoading: false
                                              });
                                            })
                                            .catch(errMsg => {
                                              this.setState({isDataLoading: false});
                                            })
                                        })
                                      }}
                                      as={`select`}>
                          <option value="">All</option>
                          <option value="1">Active</option>
                          <option value="2">Inactive</option>
                        </Form.Control>
                      </Form.Group>
                    </div>

                    <Form inline onSubmit={e => {
                      e.preventDefault();

                      const settings = {...this.state.settings}
                      settings.search_string = this.state.search_string
                      settings.page = 1

                      this.setState({isDataLoading: true, settings}, () => {
                        getStoreList({...this.state.settings, merchant: this.state.merchant})
                          .then(res => {
                            this.setState({storeList: res.items, pagination: res.pagination, isDataLoading: false});
                          })
                          .catch(errMsg => {
                            this.setState({isDataLoading: false});
                          })
                      })
                    }} className={"ml-auto"}>
                      <InputGroup>
                        <Form.Label srOnly>Search:</Form.Label>
                        <Form.Control name={'search_string'}
                                      onChange={(e) => this.setState({[e.target.name]: e.target.value})}
                                      size={"sm"} placeholder={'Search...'} type={`search`}/>
                        <InputGroup.Append>
                          <Button size={`sm`}
                                  type={`submit`}
                                  variant={`primary`}><FiSearch/></Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </Form>
                  </div>

                  <div className="table-responsive">
                    <table className="order-table table table-striped">
                      <thead>
                      <tr>
                        <th style={{width: '20px'}}>#</th>
                        <th>Store Name</th>
                        <th>Merchant Info.</th>
                        <th>Product</th>
                        <th>Order</th>
                        <th>Approval Status</th>
                        <th className={`text-center`}>Status</th>
                        <th style={{width: '1%'}}/>
                      </tr>
                      </thead>

                      {/*<Modal show={this.state.isShowingPaymentMethodSettingModal} onHide={this.handleCloseStorePaymentMethodsModal} size={'lg'}>
                        <Modal.Header closeButton>
                          <Modal.Title>Store Payment Methods</Modal.Title>
                        </Modal.Header>
                        <Form onSubmit={this.submitChangeStorePaymentMethods}>

                          <Modal.Body>

                            <Form.Group as={Row}>
                              <Form.Label column sm="3"></Form.Label>
                              <Col sm="9" md={8} lg={7}>
                                <Form.Check
                                    custom inline type="checkbox" name="payment_methods" id={`paymentMethodCOD`}
                                    label={`Cash On Delivery`} value={"cash on delivery"}
                                    onChange={e => this.handleOnChangePaymentMethods(e, "cash on delivery")}
                                    checked={this.state.selectedPaymentMethods.includes("cash on delivery")}/>
                              </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                              <Form.Label column sm="3"></Form.Label>
                              <Col sm="9" md={8} lg={7}>
                                <Form.Check
                                    custom inline type="checkbox" name="payment_methods" id={`paymentMethodBankPayment`}
                                    label={`Bank Payment`} value={"Bank Payment"}
                                    onChange={e => this.handleOnChangePaymentMethods(e, "Bank Payment")}
                                    checked={this.state.selectedPaymentMethods.includes("Bank Payment")}/>
                              </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                              <Form.Label column sm="3"></Form.Label>
                              <Col sm="9" md={8} lg={7}>
                                <Form.Check
                                    custom inline type="checkbox" name="payment_methods" id={`paymentMethodSSlCommerz`}
                                    label={`sslCommerz (Online Payment)`} value={"sslcommerz"}
                                    onChange={e => this.handleOnChangePaymentMethods(e, "sslcommerz")}
                                    checked={this.state.selectedPaymentMethods.includes("sslcommerz")}
                                />
                              </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                              <Form.Label column sm="3"></Form.Label>
                              <Col sm="9" md={8} lg={7}>
                                <Form.Check
                                    custom inline type="checkbox" name="payment_methods" id={`paymentMethodpegepay`}
                                    label={`pegepay (Malaysia Online Payment)`} value={"pegepay"}
                                    onChange={e => this.handleOnChangePaymentMethods(e, "pegepay")}
                                    checked={this.state.selectedPaymentMethods.includes("pegepay")}
                                />
                              </Col>
                            </Form.Group>

                          </Modal.Body>

                          <Modal.Footer>
                            <Button variant="default" onClick={this.handleCloseStorePaymentMethodsModal}>
                              Close
                            </Button>

                            <Button disabled={this.state.isSubmittingPaymentMethods}
                                    variant="primary" type={`submit`}>
                              {this.state.isSubmittingPaymentMethods ? 'Submitting...' : 'Save'}
                            </Button>

                          </Modal.Footer>
                        </Form>
                      </Modal>*/}

                      <Modal show={this.state.isShowingStoreSettingModal} onHide={this.handleCloseProductSettingModal}
                             size={'xl'}>
                        <Modal.Header closeButton>
                          <Modal.Title>Store Settings</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>

                          <div className="card-block mb-0">
                            <ul className="desc-header-nav">
                              <li className={this.state.activeSettingTab === 'Payment Methods' ? 'active' : ''}>
                                <span onClick={() => this.handleBlockTab('Payment Methods')}>Payment Methods</span>
                              </li>

                              <li className={this.state.activeSettingTab === 'Shipping Options' ? 'active' : ''}>
                                <span onClick={() => this.handleBlockTab('Shipping Options')}>Shipping Options</span>
                              </li>

                              <li className={this.state.activeSettingTab === 'Store Templates' ? 'active' : ''}>
                                <span onClick={() => this.handleBlockTab('Store Templates')}>Store Templates</span>
                              </li>

                            </ul>

                            <div className="block-body">
                              {this.state.activeSettingTab === 'Payment Methods' &&
                                  <>
                                    <div className="card-block">
                                      <Form onSubmit={this.submitChangePaymentMethods}>
                                        <div className="block-header">
                                          <h4 className="block-title">Payment Methods</h4>

                                          <Button disabled={this.state.isSubmittingPaymentMethods}
                                                  variant="primary" type={`submit`} className={'btn btn-sm'}>
                                            {this.state.isSubmittingPaymentMethods ? 'Submitting...' : 'Save'}
                                          </Button>
                                        </div>

                                        <div className="block-body">
                                          <Form.Group>
                                            <Col sm="12">
                                              <Form.Check
                                                  custom inline type="checkbox" name="payment_methods" id={`paymentMethodCOD`}
                                                  label={`Cash On Delivery`} value={"cash on delivery"}
                                                  onChange={e => this.handleOnChangePaymentMethods(e, "cash on delivery")}
                                                  checked={this.state.selectedPaymentMethods.includes("cash on delivery")}/>
                                              <Form.Check
                                                  custom inline type="checkbox" name="payment_methods"
                                                  id={`paymentMethodBankPayment`}
                                                  label={`Bank Payment`} value={"Bank Payment"}
                                                  onChange={e => this.handleOnChangePaymentMethods(e, "Bank Payment")}
                                                  checked={this.state.selectedPaymentMethods.includes("Bank Payment")}/>
                                              <Form.Check
                                                  custom inline type="checkbox" name="payment_methods"
                                                  id={`paymentMethodSSlCommerz`}
                                                  label={`sslCommerz (Online Payment)`} value={"sslcommerz"}
                                                  onChange={e => this.handleOnChangePaymentMethods(e, "sslcommerz")}
                                                  checked={this.state.selectedPaymentMethods.includes("sslcommerz")}/>
                                              <Form.Check
                                                  custom inline type="checkbox" name="payment_methods" id={`paymentMethodpegepay`}
                                                  label={`pegepay (Malaysia Online Payment)`} value={"pegepay"}
                                                  onChange={e => this.handleOnChangePaymentMethods(e, "pegepay")}
                                                  checked={this.state.selectedPaymentMethods.includes("pegepay")}/>
                                            </Col>
                                          </Form.Group>
                                        </div>
                                      </Form>
                                    </div>
                                  </>
                              }

                              {this.state.activeSettingTab === 'Shipping Options' &&
                                  <>
                                    <div className="card-block">
                                      <Form onSubmit={this.submitChangeShippingMethods}>
                                        <div className="block-header">
                                          <h4 className="block-title">Add Shipping Methods</h4>

                                          <div>

                                            <Button
                                                disabled={this.state.isSubmittingShippingMethods}
                                                variant="primary"
                                                type={`button`}
                                                onClick={this.searchShippingOptions}
                                                className={"btn btn-sm ml-5"}>
                                              {"Search"}
                                            </Button>

                                            <Button
                                                disabled={this.state.isSubmittingShippingMethods}
                                                variant="primary"
                                                type={`button`}
                                                onClick={this.setNewShippingFormData}
                                                className={"btn btn-sm ml-5"}>
                                              {"Reset"}
                                            </Button>


                                            <Button disabled={this.state.isSubmittingShippingMethods}
                                                    variant="primary" type={`submit`} className={'btn btn-sm ml-5'}>
                                              {this.state.isSubmittingShippingMethods ? 'Submitting...' : 'Save'}
                                            </Button>
                                          </div>
                                        </div>

                                        <div className="block-body">

                                          <Form.Group as={Row} controlId="formShippingOptions">
                                            <Form.Label column sm="2">Shipping Option</Form.Label>
                                            <Col sm="4" md={4} lg={4}>
                                              <Form.Check
                                                  custom inline type="radio" name="shipping_option" id={`shippingSellerDelivery`}
                                                  label={`Seller Delivery`} value={"Seller Delivery"}
                                                  onChange={e => this.handleOnChangeShippingForm(e)}
                                                  checked={
                                                    this.state.shippingFormData.shipping_option ===
                                                    "Seller Delivery"
                                                  }/>

                                              <Form.Check
                                                  custom inline type="radio" name="shipping_option" id={`shippingEkshopFulfillment`}
                                                  label={`ekShop Fulfillment`} value={"ekShop Fulfillment"}
                                                  onChange={e => this.handleOnChangeShippingForm(e)}
                                                  checked={
                                                    this.state.shippingFormData.shipping_option ===
                                                    "ekShop Fulfillment"
                                                  }/>
                                            </Col>
                                          </Form.Group>

                                          <Form.Group as={Row} controlId="formCountry">
                                            {this.state?.existing_locations.length > 0 &&
                                            this.state?.existing_locations.map((location, index) => {
                                              return (
                                                  <>
                                                    {location?.length > 0 && (
                                                        <>
                                                          <Form.Label column sm="2">
                                                            {location[0].type}
                                                          </Form.Label>
                                                          <Col sm="4" md={4} lg={4}>
                                                            <Form.Control
                                                                as="select"
                                                                className="mb-3"
                                                                name={location[0].type.toLowerCase()}
                                                                required={location[0].type === 'Country' ? true : false}
                                                                value={
                                                                  this.state.shippingFormData
                                                                      ?.location_hierarchy[index]
                                                                      ? this.state.shippingFormData
                                                                          ?.location_hierarchy[index]
                                                                      : ""
                                                                }
                                                                onChange={(e) =>
                                                                    this.handleShippingLocationInputOnChange(
                                                                        e,
                                                                        index,
                                                                        "shippingChildLocations"
                                                                    )
                                                                }
                                                            >
                                                              <option value="">--Select--</option>
                                                              {location.map((item, index) => (
                                                                  <option key={index} value={item._id}>
                                                                    {item.name}
                                                                  </option>
                                                              ))}
                                                            </Form.Control>

                                                            <Form.Control.Feedback type="invalid">
                                                              Please select {location[0].type.toLowerCase()}
                                                              .
                                                            </Form.Control.Feedback>
                                                          </Col>
                                                        </>
                                                    )}
                                                  </>
                                              );
                                            })}
                                          </Form.Group>

                                          <Form.Group key={'deliveryFee'} as={Row}>
                                            <Form.Label column sm="2">Fee <span className="text-danger">*</span></Form.Label>
                                            <Col sm="4" md={4} lg={4}>
                                              <Form.Control
                                                  key={`${Math.floor((Math.random() * 1000))}deliveryFeeInput`}
                                                  type="number"
                                                  onChange={(e) => this.handleOnChangeShippingForm(e)}
                                                  defaultValue={this.state.shippingFormData?.fee}
                                                  name={"fee"}
                                                  id={`deliveryFee`}
                                                  min={0}
                                                  step={0.5}
                                                  required
                                                  placeholder={`Enter Delivery Fee`}
                                              />
                                            </Col>

                                            <Form.Label column sm="2">Estimated Time <span
                                                className="text-danger">*</span></Form.Label>
                                            <Col sm="4" md={4} lg={4}>
                                              <Form.Control type="text"
                                                            key={`deliveryEstimatedTimeInput`}
                                                            onChange={e => this.handleOnChangeShippingForm(e)}
                                                            defaultValue={this.state.shippingFormData?.estimated_time}
                                                            name={'estimated_time'} id={`estimatedTime`}
                                                            required placeholder={`Estimated Delivery Time`}/>
                                            </Col>
                                          </Form.Group>

                                        </div>
                                      </Form>
                                    </div>

                                    <div className="card-block">
                                      <div className="block-header">
                                        <h4 className="block-title">Available Shipping Options</h4>
                                      </div>

                                      <div className="block-body">
                                        {this.state.isLoading ?
                                            <>Loading...</>
                                            :
                                            <>
                                              <table className="table table-striped">
                                                <thead>
                                                <tr>
                                                  <th style={{width: '20px'}}>#</th>
                                                  <th>Shipping Option</th>
                                                  <th>Location</th>
                                                  <th>Fee</th>
                                                  <th>Time</th>
                                                  <th style={{width: `1%`}}/>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.shipping_options?.length > 0 ?
                                                    this.state.shipping_options.map((item, index) => (
                                                        <tr key={index}>
                                                          <td>{index + 1}</td>
                                                          <td>{item.shipping_option}</td>
                                                          <td>{item?.destination_location_info}</td>
                                                          <td>{item?.fee}</td>
                                                          <td>{item?.estimated_time}</td>

                                                          <td className="nowrap text-center">
                                                             <span className="action text-bold text-default mr-3 btn btn-outline-info btn-sm"
                                                                   onClick={() => this.editShippingOption(item._id)}><FiEdit/> Edit </span>
                                                            {getLocalAuthData()?.permission?.includes("shipping_options_delete" ) ? (
                                                                <Button size={`sm`} onClick={() => this.deleteShippingOption(item._id)} variant={`outline-danger`}><FiTrash/>Delete</Button>
                                                            ) : null}
                                                          </td>
                                                        </tr>
                                                    )) :
                                                    <tr>
                                                      <td colSpan={7} className={`text-bold text-center`}>No item found</td>
                                                    </tr>
                                                }
                                                </tbody>
                                              </table>
                                              <div className="mt-3">
                                                <Pagination
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    activePage={this.state?.shippingMethodsPagination?.page}
                                                    itemsCountPerPage={this.state?.shippingMethodsPagination?.limit}
                                                    totalItemsCount={this.state?.shippingMethodsPagination?.totalDocs}
                                                    pageRangeDisplayed={20}
                                                    onChange={this.handleShippingMethodsPageChange.bind(this)}/>
                                              </div>
                                            </>
                                        }
                                      </div>
                                    </div>
                                  </>
                              }

                              {this.state.activeSettingTab === 'Store Templates' &&
                                  <>
                                    <div className="card-block">
                                      <div className="block-header">
                                        <h4 className="block-title">Choose Store Template</h4>
                                        <Button size={'sm'}
                                                onClick={() => this.handleOnSaveTemplate(this.state.selectedStoreId)}
                                                disabled={!this.state.store_template_id} variant={'primary'}>Save
                                          Template</Button>
                                      </div>

                                      <div className="block-body">
                                        <ul className="template-list">
                                          <li>
                                            <label className={'template'}
                                                   onChange={this.handleOnChangeTemplate}
                                                   htmlFor={'template-1'}>
                                              <input type="radio" defaultChecked
                                                     checked={this.state.store_template_id === "1"}
                                                     onChange={this.handleOnChangeTemplate}
                                                     name={'template'} value={1} id={'template-1'}/>
                                              <span className={'template-thumb'}>
                                      <span className="icon"><FiCheckCircle/></span>
                                      <img src={templateThumbOne} alt="Default"/>
                                    </span>
                                            </label>
                                          </li>

                                          <li>
                                            <label className={'template'} htmlFor={'template-2'}>
                                              <input type="radio" name={'template'}
                                                     checked={this.state.store_template_id === "2"}
                                                     onChange={this.handleOnChangeTemplate}
                                                     value={2} id={'template-2'}/>
                                              <span className={'template-thumb'}>
                                      <span className="icon"><FiCheckCircle/></span>
                                      <img className={'template-img'} src={templateThumbTwo} alt="Default"/>
                                    </span>
                                            </label>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </>
                              }

                            </div>

                          </div>
                        </Modal.Body>

                        <Modal.Footer>
                          <Button variant="default" onClick={this.handleCloseProductSettingModal}>Close</Button>
                        </Modal.Footer>
                      </Modal>

                      <tbody>
                      {this.state.storeList.length > 0 ?
                        this.state.storeList.map((store, index) => (
                          <tr key={index}>
                            <td>{this.state.pagination.pagingCounter + index}</td>
                            <td width={"20%"}>
                              <div className="order-item">
                                <h4 className="order-id mb-0">{store.store_name}</h4>
                                <ul className="ordered-items">
                                  <li>{"Created At:" + moment(store.createdAt).format('lll')}</li>
                                </ul>
                              </div>
                            </td>
                            <td>
                              {store?.user?.name}
                              <br/>
                              {store?.user?.phone}
                              <br/>
                              {store?.user?.email}
                            </td>
                            <td>{store.total_product ? store.total_product : 0}</td>
                            <td>{store?.total_order ? store.total_order : 0}</td>
                            <td>{this.renderProfileStatus(store._id, store?.approval_status)}</td>
                            <td className={`text-center`}>
                              {  store.approval_status === 1 ?

                                  <>
                                    {getLocalAuthData()?.permission?.includes("store_active_inactive") ?
                                      <select value={store.status}
                                              onChange={(e) => {
                                                const value = e.target.value
                                                const storeList = [...this.state.storeList]
                                                storeList[index].status = value

                                                this.setState({storeList}, () => {
                                                  statusUpdate(store._id, {status: value})
                                                      .then(res => {
                                                        toast.success(res.message)
                                                        activityLogPostData('was change store status','/stores/edit/'+store._id,store?.store_name)
                                                      })
                                                      .then(errMsg => {
                                                        toast.error(errMsg)
                                                      })
                                                })
                                              }}
                                              className="custom-control">

                                        <option value="1">Active</option>
                                        <option value="2">Inactive</option>
                                      </select> : null}
                                  </>
                                /*<Form.Control
                                  size={`sm`}
                                  value={store.status}
                                  onChange={(e) => {
                                    const value = e.target.value

                                    const storeList = [...this.state.storeList]
                                    storeList[index].status = value

                                    this.setState({storeList}, () => {
                                      statusUpdate(store._id, {status: value})
                                        .then(res => {
                                          toast.success(res.message)
                                        })
                                        .then(errMsg => {
                                          toast.error(errMsg)
                                        })
                                    })
                                  }}
                                  as={`select`}>
                                  <option value="1">Active</option>
                                  <option value="2">Inactive</option>
                                </Form.Control>*/
                                :
                                '--'
                              }
                            </td>
                            <td className="nowrap">
                              { getLocalAuthData()?.permission?.includes('store_ view_product') ?  <Link to={`/products/list?store_id=${store._id}`} className="text-bold text-success mr-3"
                                     target={'_blank'}>View Products</Link> : null}

                              { getLocalAuthData()?.permission?.includes('store_view') ? <Link to={`/stores/details/${store._id}`} className="text-bold text-default mr-3"
                                    target={'_blank'}>View</Link> : null}
                              { getLocalAuthData()?.permission?.includes('store_edit') ?
                                  <Link to={`/stores/edit/${store._id}`} className="text-bold text-info mr-3"
                                    target={'_blank'}>Edit</Link> : null}
                              { getLocalAuthData()?.permission?.includes('store_delete') ?
                              <span style={{cursor: "pointer"}}
                                    onClick={() => this.handleDeleteStore(store._id,store?.store_name)}
                                    className="text-bold text-danger">Delete</span> : null}
                              <br/>
                              { getLocalAuthData()?.permission?.includes('store_settings') ?
                              <span style={{cursor: "pointer"}}
                                    onClick={() => this.handleStoreSettings(store._id,store?.store_name)}
                                    className="text-bold text-default">Store Settings</span> : null}
                            </td>
                          </tr>
                        ))
                        :
                        <tr>
                          <td colSpan={5} className={"text-bold text-center"}>
                            No store found. Please create <Link to={"/stores/create"}>new store</Link>
                          </td>
                        </tr>
                      }
                      </tbody>
                    </table>
                  </div>

                  {this.state.pagination?.totalDocs > 0 &&
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={this.state.pagination?.page}
                    itemsCountPerPage={this.state.pagination?.limit}
                    totalItemsCount={this.state.pagination?.totalDocs}
                    onChange={(page) => {
                      const settings = {...this.state.settings}
                      settings.page = page

                      this.setState({isDataLoading: true, settings}, () => {
                        getStoreList({...this.state.settings, merchant: this.state.merchant})
                          .then(res => {
                            this.setState({storeList: res.items, pagination: res.pagination, isDataLoading: false});
                          })
                          .catch(errMsg => {
                            this.setState({isDataLoading: false});
                          })
                      })
                    }}
                  />
                  }
                </>
              }
            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(StoreList);
