import React, { Component } from 'react';
import App from "../../App";

export default class CommissionWallet extends Component {
    render() {
        return (
            <App layout="">
                <div className="main-content">
                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">Wallet</h4>
                        </div>
                        <div className="block-body">        
                            <div className="mt-4">
                                <table className="order-table table table-striped">
                                    <thead>
                                        <tr>
                                            <th style={{width: '1%'}}>Sl</th>
                                            <th>User Info</th>
                                            <th>Order Info</th>
                                            <th>Store Info</th>
                                            <th>Wallet Amount</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>
                                            <div class="order-item">
                                                <h4 class="order-id">
                                                    <a target="_blank" href="#">Aashikur Rahman</a>
                                                </h4>
                                                <ul class="ordered-items">
                                                    <li>1688523240</li>
                                                    <li>ratul@parallaxlogic.com</li>
                                                    <li>
                                                        <a target="_blank" href="/">
                                                            <span class="icon">
                                                                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
                                                            </span> Contact Customer</a>
                                                    </li>
                                                    <li>
                                                        <span class="badge badge-info">MuktoPath user</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="order-item">
                                                <h4 class="order-id">
                                                    <a target="_blank" href="#">BD-55653942625</a>
                                                </h4>
                                                <span class="order-date">Jun 20, 2021</span>
                                                <span class="order-time">7:48 PM</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="order-item">
                                                <h4 class="order-id">
                                                    <a target="_blank" href="#">Fashion &amp; store </a>
                                                </h4>
                                                <ul class="ordered-items">
                                                    <li>
                                                        <a href="/"><span class="icon">
                                                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
                                                            </span> Contact Seller</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                        <td>500</td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                            <td>
                                                <div class="order-item">
                                                    <h4 class="order-id">
                                                        <a target="_blank" href="#">Aashikur Rahman</a>
                                                    </h4>
                                                    <ul class="ordered-items">
                                                        <li>1688523240</li>
                                                        <li>ratul@parallaxlogic.com</li>
                                                        <li>
                                                            <a target="_blank" href="/">
                                                                <span class="icon">
                                                                    <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
                                                                </span> Contact Customer</a>
                                                        </li>
                                                        <li>
                                                            <span class="badge badge-info">MuktoPath user</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="order-item">
                                                    <h4 class="order-id">
                                                        <a target="_blank" href="#">BD-55653942625</a>
                                                    </h4>
                                                    <span class="order-date">Jun 20, 2021</span>
                                                    <span class="order-time">7:48 PM</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="order-item">
                                                    <h4 class="order-id">
                                                        <a target="_blank" href="#">Fashion &amp; store </a>
                                                    </h4>
                                                    <ul class="ordered-items">
                                                        <li>
                                                            <a href="/"><span class="icon">
                                                                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
                                                                </span> Contact Seller</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                            <td>500</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </App>
        
        )
    }
}
