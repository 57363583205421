import React, {Component} from 'react';
import App from "../../App";
import {
    Button,
    Col,
    Form,
    Row,
    Modal,Badge
} from "react-bootstrap";
import axios from "axios";
import config from "../../helper/env";
import {ToastsContainer, ToastsStore} from "react-toasts";
import {toast} from "react-toastify";
import {getDeviceType, getLocalAuthData, headerData} from "../../helper/utils";
import {Link} from "react-router-dom";
import {FiEdit, FiTrash, FiPlus, IoMdCheckmark} from "react-icons/all";
import {confirmAlert} from "react-confirm-alert";
import {toggleStatusUpdate} from "../../services/bankServices"
import SortableTree, {toggleExpandedForAll} from "react-sortable-tree";
import {AiOutlinePlusCircle} from "@react-icons/all-files/ai/AiOutlinePlusCircle";


class Roles extends Component {


    // TODO: Action Example
    //{ getLocalAuthData()?.permission?.includes('pro_addhjgjh') ?  <Button>TEST</Button> : null}
    //

    constructor(props) {
        super(props);
        this.state = {
            formData: {
                permission: []
            },
            items: [],
            permission_list: [],
            selected_id: '',
            editStatus: false,
            isShowModal: false,
            definedPermission: [],
            treeData: [],
            tree:[]
        }
    }


    handleShowModal = (selected_user_permission_list ,id) => {
        this.setState({
                formData: {
                    ...this.state.formData,
                    permission: selected_user_permission_list
                },
                  selected_id : id
            }, ()=> {

                this.setState({
                    isShowModal: true,
                    editStatus: true
                })

            });
    }

    handleHideModal = () => {
        this.setState({
            isShowModal: false,
            editStatus: false
        });
    }

    componentDidMount() {
        axios.get(`${config.API_BASE_URL}/acl-role`, headerData).then(res => {
            this.setState({items: res.data.items});
        }).catch(e => this.setState({error: true}));

        axios.get(`${config.API_BASE_URL}/acl-action`, headerData).then(res => {

            console.log("|||||",res.data.data)
            let treeData = [];

                //treeData = this.createChildren(res.data.data);
                let tree = []

                res.data.data.map((data , index)=>{
                    let x= []
                    data.children.map((data1,index1)=>{
                        x.push({ title: data1?.label ,tag: data1?.tag, children: [] })
                    })
                    tree.push({ title: data._id.type, children: x },)
                })

                this.setState({treeDataX: res.data.data ,tree: tree},()=>{

                    console.log(">>>>>>", this.state.tree)
                });



            this.setState({permission_list: res.data.items , treeData: res.data});
        }).catch(e => this.setState({error: true}));

    }


    createChildren = (data) => {
        let children = [];
        if (data.length > 0) {
            data.map((child,i) => {
                children.push({
                    id: i,
                    title: "child?.id.type"+i,
                    subtitle: '',
                    children: /*child.children.length > 0 ? this.createChildren(child.children) :*/ []
                })
            });
        }
        return children;
    }


    renderData = () => {
        axios.get(`${config.API_BASE_URL}/acl-role`, headerData).then(res => {
            this.setState({items: res.data.items});
        }).catch(e => this.setState({error: true}));
    }

    handleImage = (files) => {
        this.setState({formData: {...this.state.formData, image: files[0].thumb_img}});
    }

    handleFormDataChange = (e) => {
        this.setState({formData: {...this.state.formData, [e.target.name]: e.target.value}});
    };

    deleteItem = (id) => {
        axios.delete(`${config.API_BASE_URL}/acl-role/${id}/permanent/`, headerData).then(response => {
            if (response.data.status === 1) {
                ToastsStore.success(response.data.message);
                this.renderData();
            }
            if (response.data.status === 2) {
                ToastsStore.error(response.data.message);
            }
        }).catch(error => {
            ToastsStore.error("Error");
        });
    };

    edit = (id) => {
        axios.get(`${config.API_BASE_URL}/acl-role/${id}`, headerData).then(res => {
            //console.log("in Edit", res.data.aclRole);
            this.setState({
                selected_id: res.data.aclRole._id,
                formData: {
                    ...this.state.formData,
                    type: res.data.aclRole.type,
                    permission: res.data.aclRole.permission
                },
                editStatus: true
            }, ()=>{
               // console.log(this.state.formData);
            });
            window.scrollTo({top: 0, behavior: 'smooth'});
        }).catch(error => {
            ToastsStore.error("Error");
        });
    };

    handleToggleStatusUpdate = (id, status, index) => {
        confirmAlert({
            title: 'Status Update',
            message: 'Are you sure to change this status?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => toggleStatusUpdate(id, {status: status}).then(res => {

                        // Update row data
                        const items = [...this.state.items]
                        items[index].status = status
                        this.setState({items})
                        toast.success(res.message)

                    }).catch(errMsg => {
                        toast.error(errMsg)
                    })
                }, {
                    label: 'No',
                }
            ]
        });
    }

    handleSubmitForm = (e) => {
        e.preventDefault();
        const formData = {...this.state.formData};
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({isFormValidated: true})
        } else {
            //console.log(this.state.selected_id)
            let url = `${config.API_BASE_URL}/acl-role`;
            if (this.state.editStatus === true) {
                url = `${config.API_BASE_URL}/acl-role/` + this.state.selected_id;
                axios.put(url, formData, headerData).then(response => {
                    if (response.data.status === 1) {
                        toast.success(response.data.message);
                        this.renderData();
                        this.handleHideModal();
                        window.location.reload()
                    }
                    if (response.data.status === 2) {
                        toast.error(response.data.message);
                        this.renderData();
                        window.location.reload()
                    }
                }).catch(error => {
                    toast.error("Something went wrong please try again after refresh the page.");
                });
            } else {
                axios.post(url, formData, headerData).then(response => {
                    if (response.data.status === 1) {
                        toast.success(response.data.message);
                        this.renderData();
                        window.location.reload()
                    }
                    if (response.data.status === 2) {
                        toast.error(response.data.message);
                        this.renderData();
                        window.location.reload()
                    }
                }).catch(error => {
                    toast.error("Something went wrong please try again after refresh the page.");

                });
            }
        }
    };

    handleSelectedItemOnChange = (e, tag,row_info) => {
        console.log(row_info)

        if (e.target.checked === true){

            if (row_info.parentNode === null){
                let per = []
                row_info.node.children.map((data,i)=>{
                    per.push(data.tag)
                    console.log(data)
                })

                this.setState({
                    formData: {
                        ...this.state.formData,
                        permission:  [... this.state.formData.permission , ...per]
                    }
                }, ()=> {
                    console.log(this.state.formData)
                })

                this.reRender()

            }else {
                console.log("ok")
                // Add new tag permission to formData permission  Array
                this.setState({
                    formData: {
                        ...this.state.formData,
                        permission:  [... this.state.formData.permission , tag]
                    }
                }, ()=> {

                })
            }


        }else {


            if (row_info.parentNode === null){

                row_info.node.children.map((data1,i)=>{
                    this.state.formData.permission.map( (data , index) => {
                        if (data === data1.tag){
                            this.state.formData.permission.splice( index , 1);
                        }
                    })
                })

                this.reRender()

            }/*else if(row_info.parentNode?.children.length > 0){
                let y = 0;
                if (row_info.parentNode.children && rowInfo.parentNode.children.length > 0){
                    row_info.parentNode.children.map((d, i) => {
                        let a= this.state.formData.permission.includes(d.tag)
                        if (a){y++}
                    })
                }
                return y === row_info.node.children.length;
            }*/else {

                // remove new tag permission to formData permission Array if Found
                this.state.formData.permission.map( (data , index) => {
                    if (data === tag){
                        this.state.formData.permission.splice( index , 1);
                    }
                },()=> {
                })

            }
        }


    }

    isItemSelected = (tag) => {
        //
    }

    reRender = ()=>{
        let tree = []
        this.state.treeDataX.map((data , index)=>{
            let x= []
            data.children.map((data1,index1)=>{
                x.push({ title: data1?.label ,tag: data1?.tag, children: [] })
            })
            tree.push({ title: data._id.type, children: x },)
        })
        this.setState({tree: tree},()=>{
        });

    }

    checkUncheck =(rowInfo)=>{
       // console.log("RowInfo",rowInfo)
        if (rowInfo.parentNode === null){
            let y = 0;
            if (rowInfo.node.children && rowInfo.node.children.length > 0){
                rowInfo.node.children.map((d, i) => {
                   let a= this.state.formData.permission.includes(d.tag)
                    if (a){y++}
                })
            }
            return y === rowInfo.node.children.length;
        }
        else {
            return this.state.formData.permission.includes(rowInfo.node.tag)
        }
    }

    howMany =(rowInfo)=>{
        if (rowInfo.parentNode === null){
            let y = 0;
            if (rowInfo.node.children && rowInfo.node.children.length > 0){
                rowInfo.node.children.map((d, i) => {
                    let a= this.state.formData.permission.includes(d.tag)
                    if (a){y++}
                })
            }
            if (y === 0){
                return null
            }else {
                return y
            }
        }
    }


    render() {

        const {
            treeData
        } = this.state;

        return (
            <App layout={"boxed"}>
                <div className="page-header">
                    <h2 className="page-title">Role Management</h2>
                </div>

                <div className="main-content">
                    <div className="card-block">

                        <div className="block-body">
                            <Form id={"bankForm"} onSubmit={this.handleSubmitForm}>
                                <Form.Group as={Row} controlId="bankName">
                                    <Form.Label column sm="3">
                                        Role Name <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type={"text"} required
                                                      defaultValue={this.state.formData ? this.state.formData.type : ""}
                                                      name="type" onChange={this.handleFormDataChange}
                                                      placeholder={"Role Name"}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="bankFormSubmit">
                                    <Form.Label column sm="3"/>
                                    <Col sm="9" md={8} lg={7}>
                                        <Button type={'submit'} variant={"primary"}>{(this.state.editStatus === true) ? 'Update' : 'Save'}</Button>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </div>


                        <Modal show={this.state.isShowModal} onHide={() => this.handleHideModal()} size={'xl'}
                               dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title">
                            <Modal.Header closeButton>
                                <Modal.Title style={ {color: "#1BC5BD"} } id="example-custom-modal-styling-title">
                                    Set Permission
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <div style={{ height: 2000 }}>
                                    <SortableTree
                                        canDrag={false}
                                        treeData={this.state.tree}
                                        onChange={tree => {
                                            console.log('okkk')
                                            this.setState({tree})
                                        }}
                                        generateNodeProps={rowInfo => ({
                                            buttons: [
                                                <Badge bg="light" text="dark">{this.howMany(rowInfo)}</Badge>,
                                                <>
                                                    <>
                                                        <label key={rowInfo.node.tag}>
                                                            <input id={rowInfo.node.tag}
                                                                   key={rowInfo.node.tag}
                                                                   onChange={e => {
                                                                       console.log("::",rowInfo)
                                                                       this.handleSelectedItemOnChange(e, rowInfo.node.tag, rowInfo);

                                                                   }}
                                                                   name={`permissions`}
                                                                   label={rowInfo.node.title}
                                                                   defaultChecked={this.checkUncheck(rowInfo)}
                                                                   type="checkbox"/>
                                                        </label>
                                                    </>
                                                </>
                                            ]
                                        })}
                                    />
                                </div>

                                {/*{this.state?.permission_list.map((item, index) => (*/}
                                {/*    <>*/}
                                {/*        <label key={index} htmlFor={`permission-${index}`}>*/}
                                {/*            <input id={`permission-${index}`}*/}
                                {/*                   key={index}*/}
                                {/*                   onChange={e => this.handleSelectedItemOnChange(e, item.tag)}*/}
                                {/*                   name={`permissions`}*/}
                                {/*                   label={item.label}*/}
                                {/*                   defaultChecked={this.state.formData.permission.includes(item.tag)}*/}
                                {/*                   type="checkbox"/>*/}
                                {/*            <span className="product-name ml-3">{item.tag}</span>*/}
                                {/*        </label><br/>*/}
                                {/*    </>*/}
                                {/*))}*/}
                            </Modal.Body>

                            <Modal.Footer>
                                <Button onClick={ this.handleSubmitForm }>UPDATE</Button>
                            </Modal.Footer>

                        </Modal>


                    </div>


                    <div className="card-block">

                        <div className="block-header">
                            <h4 className="block-title">Role List</h4>
                        </div>

                        <div className="block-body">
                            {/*{this.renderBank()}*/}
                            {this.state.isLoading ?
                                <>Loading...</>
                                :
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th style={{width: '20px'}}>#</th>
                                        <th>Type</th>
                                        <th>Permission</th>
                                        <th style={{width: `1%`}}/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {( getLocalAuthData()?.permission?.includes('role_view') && this.state.items?.length > 0) ?
                                        this.state.items.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.type}</td>
                                                <td>
                                                    {getLocalAuthData()?.permission?.includes('role_set_permission') ?
                                                        <>
                                                        <span className="btn btn-sm btn-primary mr-3"
                                                              onClick={() => {this.handleShowModal(item.permission , item._id)}}>Set Permission</span>
                                                            {(item.permission.length > 0) && <span> total: {item.permission.length} </span>}
                                                            </>
                                                    :null}
                                                </td>
                                                <td className="nowrap text-center">
                                                    {getLocalAuthData()?.permission?.includes('role_edit') ?
                                                        <span className="btn btn-sm btn-outline-info mr-3" onClick={() => this.edit(item._id)}><FiEdit/> </span>
                                                        :null}
                                                    {getLocalAuthData()?.permission?.includes('role_delete') ?
                                                    <span className="btn btn-sm btn-outline-danger mr-3" onClick={() => this.deleteItem(item._id)}><FiTrash/> Delete</span>
                                                        :null}
                                                </td>
                                            </tr>
                                        )) :
                                        <tr>
                                            <td colSpan={7} className={`text-bold text-center`}>No item found</td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                            }
                        </div>

                    </div>

                </div>
            </App>
        );
    }
}

export default Roles;
