import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {
    getCommissionSettingDetails, getGeneralSettings,
    addCommissionSettings
} from "../../services/commissionSettingsServices";
import {toast} from "react-toastify";
import {withRouter} from "react-router-dom";
import {getLocalAuthData} from "../../helper/utils";
import {activityLogPostData} from "../../services/commonServices";

class GeneralCommissionSettings extends Component {
    state = {
        formData: {
            valueType: "1"
        },
        commissionId: null,
        isLoading: true,
        isSubmitting: false
    }

    componentDidMount() {
        /*getCommissionSettingDetails()
          .then(res => {
            const formData = {...this.state.formData}
            formData.value = res.value.toString()
            this.setState({formData, isLoading: false})
          })
          .catch(errMsg => {
            console.error('Error', errMsg)
            this.setState({isLoading: false})
          })*/

        getGeneralSettings()
            .then(res => {
                const formData = {...this.state.formData}
                formData.admin_commission_percentage = res.admin_commission_percentage.toString()
                formData.admin_max_commission_value = res.admin_max_commission_value.toString()
                formData.merchant_commission_percentage = res.merchant_commission_percentage.toString()
                formData.merchant_max_commission_value = res.merchant_max_commission_value.toString()
                this.setState({formData, commissionId: res._id, isLoading: false})
            })
            .catch(errMsg => {
                console.error('Error', errMsg)
                this.setState({isLoading: false})
            })
    }

    handleInputOnChange = (e) => {
        const formData = {...this.state.formData}
        formData[e.target.name] = e.target.value
        this.setState({formData}, () => {
            console.log(this.state)
        })
    }

    handleSubmitForm = (e) => {
        e.preventDefault()
        this.setState({isSubmitting: true}, () => {
            addCommissionSettings(this.state.formData).then(res => {
                // TODO: It will be updated
                // toast.success(res.message)
                this.setState({isSubmitting: false})
                toast.success('Commission setting successfully added')
                activityLogPostData('was update general setting','/commission-settings/general-settings/',null)
            }).catch(errMsg => {
                toast.error(errMsg)
                this.setState({isSubmitting: false})
            })
        })
    }

    render() {
        return (
            <App layout={'boxed'}>
                {getLocalAuthData()?.permission?.includes('comission_general_settings') ?
                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">General Settings</h4>
                        </div>
                        <div className="block-body">
                            <Form onSubmit={this.handleSubmitForm}>
                                {this.state.isLoading ?
                                    <>Loading...</>
                                    :
                                    <>
                                        <Form.Group as={Row} controlId="formStore">
                                            <Form.Label column sm="3">
                                                Admin Commission (%) <span className="text-danger">*</span>
                                            </Form.Label>

                                            <Col sm="4" md={4} lg={3}>
                                                <Form.Control type={`number`}
                                                              onChange={this.handleInputOnChange}
                                                              defaultValue={this.state.formData?.admin_commission_percentage}
                                                              name={'admin_commission_percentage'}
                                                              min={0} max={100} step={.1} required placeholder={`Enter commission percentage`}/>
                                            </Col>

                                            <Col sm="4" md={4} lg={3}>
                                                <Form.Control type={`number`}
                                                              onChange={this.handleInputOnChange}
                                                              defaultValue={this.state.formData?.admin_max_commission_value}
                                                              name={'admin_max_commission_value'}
                                                              min={0} placeholder={`Enter max commission value`}/>
                                            </Col>

                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formStore">
                                            <Form.Label column sm="3">
                                                Merchant Commission (%) <span className="text-danger">*</span>
                                            </Form.Label>

                                            <Col sm="4" md={4} lg={3}>
                                                <Form.Control type={`number`}
                                                              onChange={this.handleInputOnChange}
                                                              defaultValue={this.state.formData?.merchant_commission_percentage}
                                                              name={'merchant_commission_percentage'}
                                                              min={0} max={100} step={.1} required placeholder={`Enter commission percentage`}/>
                                            </Col>

                                            <Col sm="4" md={4} lg={3}>
                                                <Form.Control type={`number`}
                                                              onChange={this.handleInputOnChange}
                                                              defaultValue={this.state.formData?.merchant_max_commission_value}
                                                              name={'merchant_max_commission_value'}
                                                              min={0} placeholder={`Enter max commission value`}/>
                                            </Col>

                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formStore">
                                            <Form.Label column sm="3"/>
                                            <Col sm="9" md={8} lg={7}>
                                                <Button type={`submit`}
                                                        disabled={this.state.isSubmitting}
                                                        variant={'primary'}>{this.state.isSubmitting ? 'Saving...' : 'Save'}</Button>
                                            </Col>
                                        </Form.Group>
                                    </>
                                }
                            </Form>
                        </div>
                    </div>
                    : null}


            </App>
        );
    }
}

export default withRouter(GeneralCommissionSettings);
