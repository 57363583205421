import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {
    getWalletSetting, getDisputeSetting,
    saveWalletSetting, saveDisputeSetting,
    updateWalletSetting, updateDisputeSetting,
} from "../../services/settingServices";
import {toast} from "react-toastify";
import {Link, withRouter} from "react-router-dom";
import {getLocalAuthData} from "../../helper/utils";
import {IoMdSettings} from "@react-icons/all-files/io/IoMdSettings";
import {BiPackage} from "@react-icons/all-files/bi/BiPackage";

class Settings extends Component {
    state = {
        formData: {
            valueType: "1"
        },
        commissionId: null,
        isWalletSettingLoading: true,
        isDisputeSettingLoading: true,
        walletSettingFormData: {},
        isWalletSettingSubmitting: false,
        disputeSettingFormData: {},
        isDisputeSettingSubmitting: false,
        dispute_setting_id: false,

        walletSettingFormDataMuktopaath: {
            type: "muktopaath"
        },
    }

    componentDidMount() {
        getWalletSetting().then(res => {
            console.log(res.setting);

            res.setting.map((data,index)=>{
                console.log('TEST' , data)
                if (data.type === "general"){
                    this.setState({walletSettingFormData: data ? data : {}, wallet_setting_id: data ? data._id : {}, isWalletSettingLoading: false})
                }else if (data.type === "muktopaath"){
                    this.setState({walletSettingFormDataMuktopaath: data ? data : {}, wallet_setting_id_muktopaath: data ? data._id : {}, isWalletSettingLoading: false})
                }else {
                    this.setState({walletSettingFormData: data ? data : {}, wallet_setting_id: data ? data._id : {}, isWalletSettingLoading: false})
                }
            })

            console.log(this.state);
        }).catch(errMsg => {
            console.error('Error', errMsg)
            this.setState({isWalletSettingLoading: false})
        })

        getDisputeSetting().then(res => {
            console.log(res.setting);
            this.setState({disputeSettingFormData: res.setting ? res.setting : {}, dispute_setting_id: res.setting ? res.setting._id : '', isDisputeSettingLoading: false})
            console.log(this.state);
        }).catch(errMsg => {
            console.error('Error', errMsg)
            this.setState({isDisputeSettingLoading: false})
        })
    }

    handleWalletSettingInputOnChange = (e) => {
        const walletSettingFormData = {...this.state.walletSettingFormData}
        walletSettingFormData[e.target.name] = e.target.value
        this.setState({walletSettingFormData}, () => {
            console.log(this.state)
        })
    }

    handleWalletSettingInputOnChangeMuktoPaath = (e) => {
        const walletSettingFormDataMuktopaath = {...this.state.walletSettingFormDataMuktopaath}
        walletSettingFormDataMuktopaath[e.target.name] = e.target.value
        this.setState({walletSettingFormDataMuktopaath}, () => {
            console.log(this.state)
        })
    }

    handleWalletSettingSubmitForm = (e) => {
        e.preventDefault()
        this.setState({isWalletSettingSubmitting: true}, () => {
            if(this.state.wallet_setting_id){
                updateWalletSetting(this.state.walletSettingFormData, this.state.wallet_setting_id).then(res => {
                    // TODO: It will be updated
                    // toast.success(res.message)
                    this.setState({isWalletSettingSubmitting: false})
                    toast.success('Wallet setting successfully saved')
                }).catch(errMsg => {
                    toast.error(errMsg)
                    this.setState({isWalletSettingSubmitting: false})
                })
            }else{
                saveWalletSetting(this.state.walletSettingFormData).then(res => {
                    // TODO: It will be updated
                    // toast.success(res.message)
                    this.setState({isWalletSettingSubmitting: false})
                    toast.success('Wallet setting successfully saved')
                }).catch(errMsg => {
                    toast.error(errMsg)
                    this.setState({isWalletSettingSubmitting: false})
                })
            }

        })
    }

    handleWalletSettingSubmitFormMuktoPaath = (e) => {
        e.preventDefault()
        this.setState({isWalletSettingSubmitting: true}, () => {
            if(this.state.wallet_setting_id_muktopaath){
                updateWalletSetting(this.state.walletSettingFormDataMuktopaath, this.state.wallet_setting_id_muktopaath).then(res => {
                    // TODO: It will be updated
                    // toast.success(res.message)
                    this.setState({isWalletSettingSubmitting: false})
                    toast.success('Wallet setting successfully saved')
                }).catch(errMsg => {
                    toast.error(errMsg)
                    this.setState({isWalletSettingSubmitting: false})
                })
            }else{
                saveWalletSetting(this.state.walletSettingFormDataMuktopaath).then(res => {
                    // TODO: It will be updated
                    // toast.success(res.message)
                    this.setState({isWalletSettingSubmitting: false})
                    toast.success('Wallet setting successfully saved')
                }).catch(errMsg => {
                    toast.error(errMsg)
                    this.setState({isWalletSettingSubmitting: false})
                })
            }

        })
    }



    handleDisputeSettingInputOnChange = (e) => {
        const disputeSettingFormData = {...this.state.disputeSettingFormData}
        disputeSettingFormData[e.target.name] = e.target.value
        this.setState({disputeSettingFormData}, () => {
            console.log(this.state)
        })
    }

    handleDisputeSettingSubmitForm = (e) => {
        e.preventDefault()
        this.setState({isDisputeSettingSubmitting: true}, () => {
            if(this.state.dispute_setting_id){
                updateDisputeSetting(this.state.disputeSettingFormData, this.state.dispute_setting_id).then(res => {
                    this.setState({isDisputeSettingSubmitting: false})
                    toast.success('Dispute setting successfully saved')
                }).catch(errMsg => {
                    toast.error(errMsg)
                    this.setState({isDisputeSettingSubmitting: false})
                })
            }else{
                saveDisputeSetting(this.state.disputeSettingFormData).then(res => {
                    this.setState({isDisputeSettingSubmitting: false})
                    toast.success('Dispute setting successfully saved')
                }).catch(errMsg => {
                    toast.error(errMsg)
                    this.setState({isDisputeSettingSubmitting: false})
                })
            }
        })
    }

    render() {
        return (
            <App layout={'boxed'}>

                {getLocalAuthData()?.permission?.includes('menu_settings_settings') ?
                    <>

                        <div className="d-flex justify-content-center align-items-center">
                            <li className="list-unstyled mx-3">
                                <Link to={'/settings'} class={"d-flex justify-content-center align-items-center flex-column"}>
                                    <span className="card nav-icon card-body" style={{borderRadius: "10px"}}>
                                        <IoMdSettings size={35}/>
                                    </span>
                                    <span className="nav-text">Wallet Setting</span>
                                </Link>
                            </li>

                            <li className="list-unstyled mx-3">
                                <Link to={'/wallet/redeem-package'} class={"d-flex justify-content-center align-items-center flex-column"}>
                                    <span className="card nav-icon card-body" style={{borderRadius: "10px"}}>
                                        <BiPackage size={35}/>
                                    </span>
                                    <span className="nav-text">Package</span></Link>
                            </li>
                        </div>

                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">General Wallet Settings</h4>
                        </div>

                        <div className="block-body">
                            <Form onSubmit={this.handleWalletSettingSubmitForm}>
                                {this.state.isWalletSettingLoading ?
                                    <>Loading...</>
                                    :
                                    <>
                                        <Form.Group as={Row} controlId="formrRgistrationPoint">
                                            <Form.Label column sm="3">
                                                Registration Point
                                                <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control type={`number`} required
                                                              onChange={this.handleWalletSettingInputOnChange}
                                                              defaultValue={this.state.walletSettingFormData?.registration_point}
                                                              name={'registration_point'}
                                                              min={0} max={1000}
                                                              placeholder={`Enter registration point`}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formEmailVerificationPoint">
                                            <Form.Label column sm="3">
                                                Email Verification Point
                                                <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control type={`number`} required
                                                              onChange={this.handleWalletSettingInputOnChange}
                                                              defaultValue={this.state.walletSettingFormData?.email_verification_point}
                                                              name={'email_verification_point'}
                                                              min={0} max={1000}
                                                              placeholder={`Enter email verification point`}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formLoginPoint">
                                            <Form.Label column sm="3">
                                                Login Point
                                                <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control type={`number`} required
                                                              onChange={this.handleWalletSettingInputOnChange}
                                                              defaultValue={this.state.walletSettingFormData?.login_point}
                                                              name={'login_point'}
                                                              min={0} max={1000} placeholder={`Enter login point`}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formPlaceOrderpoint">
                                            <Form.Label column sm="3">
                                                Place Order Point
                                                <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control type={`number`} required
                                                              onChange={this.handleWalletSettingInputOnChange}
                                                              defaultValue={this.state.walletSettingFormData?.place_order_point}
                                                              name={'place_order_point'}
                                                              min={0} max={1000}
                                                              placeholder={`Enter place order point`}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formOnlinePaymentPoint">
                                            <Form.Label column sm="3">
                                                Online Payment Point
                                                <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control type={`number`} required
                                                              onChange={this.handleWalletSettingInputOnChange}
                                                              defaultValue={this.state.walletSettingFormData?.online_payment_point}
                                                              name={'online_payment_point'}
                                                              min={0} max={1000}
                                                              placeholder={`Enter online payment point`}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formProductLikePoint">
                                            <Form.Label column sm="3">
                                                Product Like Point
                                                <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control type={`number`} required
                                                              onChange={this.handleWalletSettingInputOnChange}
                                                              defaultValue={this.state.walletSettingFormData?.product_like_point}
                                                              name={'product_like_point'}
                                                              min={0} max={1000}
                                                              placeholder={`Enter product like point`}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formProductCommentPoint">
                                            <Form.Label column sm="3">
                                                Product Comment Point
                                                <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control type={`number`} required
                                                              onChange={this.handleWalletSettingInputOnChange}
                                                              defaultValue={this.state?.walletSettingFormData?.product_comment_point}
                                                              name={'product_comment_point'}
                                                              min={0} max={1000}
                                                              placeholder={`Enter product comment point`}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formProductReviewPoint">
                                            <Form.Label column sm="3">
                                                Product Review Point
                                                <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control type={`number`} required
                                                              onChange={this.handleWalletSettingInputOnChange}
                                                              defaultValue={this.state.walletSettingFormData?.product_review_point}
                                                              name={'product_review_point'}
                                                              min={0} max={1000}
                                                              placeholder={`Enter product review point`}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formStoreFollowPoint">
                                            <Form.Label column sm="3">
                                                Product Follow Point
                                                <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control type={`number`} required
                                                              onChange={this.handleWalletSettingInputOnChange}
                                                              defaultValue={this.state.walletSettingFormData?.store_follow_point}
                                                              name={'store_follow_point'}
                                                              min={0} max={1000}
                                                              placeholder={`Enter product follow point`}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formWalletConversion_Rate">
                                            <Form.Label column sm="3">
                                                Wallet Conversion Point
                                                <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control type={`number`} required
                                                              onChange={this.handleWalletSettingInputOnChange}
                                                              defaultValue={this.state.walletSettingFormData?.wallet_conversion_rate}
                                                              name={'wallet_conversion_rate'}
                                                              min={0} max={1000}
                                                              placeholder={`Enter wallet conversion point`}/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formWalletConversion_Rate">
                                            <Form.Label column sm="3">
                                                Wallet Used Percentage
                                                <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control type={`number`} required
                                                              onChange={this.handleWalletSettingInputOnChange}
                                                              defaultValue={this.state.walletSettingFormData?.wallet_used_percentage}
                                                              name={'wallet_used_percentage'}
                                                              min={0} max={100}
                                                              placeholder={`Enter wallet max used percentage`}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formStore">
                                            <Form.Label column sm="3"/>
                                            <Col sm="9" md={8} lg={7}>
                                                <Button type={`submit`}
                                                        disabled={this.state.isWalletSettingSubmitting}
                                                        variant={'primary'}>{this.state.isWalletSettingSubmitting ? 'Saving...' : 'Save'}</Button>
                                            </Col>
                                        </Form.Group>
                                    </>
                                }
                            </Form>
                        </div>
                    </div>

                    <div className="card-block">
                            <div className="block-header">
                                <h4 className="block-title">MuktoPaath Wallet Settings</h4>
                            </div>

                            <div className="block-body">
                                <Form onSubmit={this.handleWalletSettingSubmitFormMuktoPaath}>
                                    {this.state.isWalletSettingLoading ?
                                        <>Loading...</>
                                        :
                                        <>
                                            <Form.Group as={Row} controlId="formrRgistrationPoint">
                                                <Form.Label column sm="3">
                                                    Registration Point
                                                    <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control type={`number`} required
                                                                  onChange={this.handleWalletSettingInputOnChangeMuktoPaath}
                                                                  defaultValue={this.state.walletSettingFormDataMuktopaath?.registration_point}
                                                                  name={'registration_point'}
                                                                  min={0} max={1000}
                                                                  placeholder={`Enter registration point`}/>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="formEmailVerificationPoint">
                                                <Form.Label column sm="3">
                                                    Email Verification Point
                                                    <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control type={`number`} required
                                                                  onChange={this.handleWalletSettingInputOnChangeMuktoPaath}
                                                                  defaultValue={this.state.walletSettingFormDataMuktopaath?.email_verification_point}
                                                                  name={'email_verification_point'}
                                                                  min={0} max={1000}
                                                                  placeholder={`Enter email verification point`}/>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="formLoginPoint">
                                                <Form.Label column sm="3">
                                                    Login Point
                                                    <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control type={`number`} required
                                                                  onChange={this.handleWalletSettingInputOnChangeMuktoPaath}
                                                                  defaultValue={this.state.walletSettingFormDataMuktopaath?.login_point}
                                                                  name={'login_point'}
                                                                  min={0} max={1000} placeholder={`Enter login point`}/>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="formPlaceOrderpoint">
                                                <Form.Label column sm="3">
                                                    Place Order Point
                                                    <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control type={`number`} required
                                                                  onChange={this.handleWalletSettingInputOnChangeMuktoPaath}
                                                                  defaultValue={this.state.walletSettingFormDataMuktopaath?.place_order_point}
                                                                  name={'place_order_point'}
                                                                  min={0} max={1000}
                                                                  placeholder={`Enter place order point`}/>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="formOnlinePaymentPoint">
                                                <Form.Label column sm="3">
                                                    Online Payment Point
                                                    <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control type={`number`} required
                                                                  onChange={this.handleWalletSettingInputOnChangeMuktoPaath}
                                                                  defaultValue={this.state.walletSettingFormDataMuktopaath?.online_payment_point}
                                                                  name={'online_payment_point'}
                                                                  min={0} max={1000}
                                                                  placeholder={`Enter online payment point`}/>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="formProductLikePoint">
                                                <Form.Label column sm="3">
                                                    Product Like Point
                                                    <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control type={`number`} required
                                                                  onChange={this.handleWalletSettingInputOnChangeMuktoPaath}
                                                                  defaultValue={this.state.walletSettingFormDataMuktopaath?.product_like_point}
                                                                  name={'product_like_point'}
                                                                  min={0} max={1000}
                                                                  placeholder={`Enter product like point`}/>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="formProductCommentPoint">
                                                <Form.Label column sm="3">
                                                    Product Comment Point
                                                    <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control type={`number`} required
                                                                  onChange={this.handleWalletSettingInputOnChangeMuktoPaath}
                                                                  defaultValue={this.state?.walletSettingFormDataMuktopaath?.product_comment_point}
                                                                  name={'product_comment_point'}
                                                                  min={0} max={1000}
                                                                  placeholder={`Enter product comment point`}/>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="formProductReviewPoint">
                                                <Form.Label column sm="3">
                                                    Product Review Point
                                                    <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control type={`number`} required
                                                                  onChange={this.handleWalletSettingInputOnChangeMuktoPaath}
                                                                  defaultValue={this.state.walletSettingFormDataMuktopaath?.product_review_point}
                                                                  name={'product_review_point'}
                                                                  min={0} max={1000}
                                                                  placeholder={`Enter product review point`}/>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="formStoreFollowPoint">
                                                <Form.Label column sm="3">
                                                    Product Follow Point
                                                    <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control type={`number`} required
                                                                  onChange={this.handleWalletSettingInputOnChangeMuktoPaath}
                                                                  defaultValue={this.state.walletSettingFormDataMuktopaath?.store_follow_point}
                                                                  name={'store_follow_point'}
                                                                  min={0} max={1000}
                                                                  placeholder={`Enter product follow point`}/>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="formWalletConversion_Rate">
                                                <Form.Label column sm="3">
                                                    Wallet Conversion Point
                                                    <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control type={`number`} required
                                                                  onChange={this.handleWalletSettingInputOnChangeMuktoPaath}
                                                                  defaultValue={this.state.walletSettingFormDataMuktopaath?.wallet_conversion_rate}
                                                                  name={'wallet_conversion_rate'}
                                                                  min={0} max={1000}
                                                                  placeholder={`Enter wallet conversion point`}/>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="formWalletConversion_Rate">
                                                <Form.Label column sm="3">
                                                    Wallet Used Percentage
                                                    <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control type={`number`} required
                                                                  onChange={this.handleWalletSettingInputOnChangeMuktoPaath}
                                                                  defaultValue={this.state.walletSettingFormDataMuktopaath?.wallet_used_percentage}
                                                                  name={'wallet_used_percentage'}
                                                                  min={0} max={100}
                                                                  placeholder={`Enter wallet max used percentage`}/>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="formWalletConversion_Rate">
                                                <Form.Label column sm="3">
                                                    Muktopaath liability Percentage
                                                    <span className="text-danger">*</span>
                                                </Form.Label>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Form.Control type={`number`} required
                                                                  onChange={this.handleWalletSettingInputOnChangeMuktoPaath}
                                                                  defaultValue={this.state.walletSettingFormDataMuktopaath?.muktopaath_liability_percentage}
                                                                  name={'muktopaath_liability_percentage'}
                                                                  min={0} max={100}
                                                                  placeholder={`Enter muktopaath wallet liability percentage`}/>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="formStore">
                                                <Form.Label column sm="3"/>
                                                <Col sm="9" md={8} lg={7}>
                                                    <Button type={`submit`}
                                                            disabled={this.state.isWalletSettingSubmitting}
                                                            variant={'primary'}>{this.state.isWalletSettingSubmitting ? 'Saving...' : 'Save'}</Button>
                                                </Col>
                                            </Form.Group>
                                        </>
                                    }
                                </Form>
                            </div>
                        </div>

                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">Dispute Setting</h4>
                        </div>

                        <div className="block-body">
                            <Form onSubmit={this.handleDisputeSettingSubmitForm}>
                                {this.state.isLoading ?
                                    <>Loading...</>
                                    :
                                    <>
                                        <Form.Group as={Row} controlId="formDisputableTimeLimit">
                                            <Form.Label column sm="3">
                                                Disputable time limit
                                                <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control type={`number`} required
                                                              onChange={this.handleDisputeSettingInputOnChange}
                                                              defaultValue={this.state.disputeSettingFormData?.disputable_time_limit}
                                                              name={'disputable_time_limit'}
                                                              min={0} max={1000}
                                                              placeholder={`Enter Disputable time limit in Day`}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formStore">
                                            <Form.Label column sm="3"/>
                                            <Col sm="9" md={8} lg={7}>
                                                <Button type={`submit`}
                                                        disabled={this.state.isDisputeSettingSubmitting}
                                                        variant={'primary'}>{this.state.isDisputeSettingSubmitting ? 'Saving...' : 'Save'}</Button>
                                            </Col>
                                        </Form.Group>
                                    </>
                                }
                            </Form>
                        </div>
                    </div>
                    </> : null}

            </App>
        );
    }
}

export default withRouter(Settings);
