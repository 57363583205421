import React, {Component} from 'react';
import App from "../../App";
import {Button, Modal, Form} from "react-bootstrap";
import Pagination from 'react-js-pagination';
import {FaFileExport, FiMessageSquare} from "react-icons/all";
import {Link} from "react-router-dom";
import "../../assets/scss/order-list.scss";
import axios from "axios";
import {API_BASE_URL} from "../../helper/env";
import Moment from 'react-moment';
import {getDeviceType, getLocalAuthData} from "../../helper/utils";
import {toast} from "react-toastify";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import {getChildLocations} from "../../services/locationServices";

class OrderList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      merchantType: [],
      storeList: [],
      queryData: {
        from_date: '',
        to_date: ''
      },
      activePage: 1,
      pagination: {},
      activeBlockTab: 'All',
      isLoading: true,
      locations: [],
      businessChildLocations: [],
      status: null,
      changingStoreId: null,
      changeStatusMsg: '',
      isShowingStatusChangeConfirmModal: false,
      isSubmitting: false,
      showCancelMessageRequired: false,
    }
  }

  handleBlockTab = (label) => {
    this.setState({
      activeBlockTab: label,
      queryData: {
        ...this.state.queryData,
        order_status: label !== "All" ? label : "",
        page: 1,
      },
      activePage: 1
    }, () => {
      this.getQueryData();
    });
  };

  componentDidMount() {
    const authToken = getLocalAuthData();
    const headers = {
      'Content-Type': 'application/json',
      'x-api-client': 'web',
      'x-auth-token': authToken.token,
    }
    axios.get(`${API_BASE_URL}/order`, {headers: headers})
      .then(res => {
        this.setState({orders: res.data.items, pagination: res.data.pagination, isLoading: false});
      })
      .catch(err => {
        this.setState({isLoading: false})
      });

    axios.get(`${API_BASE_URL}/merchantType`, {headers: headers})
      .then(res => {
        this.setState({merchantType: res.data.merchant_type_obj});
      })
      .catch(err => {
        console.log(err)
      });

    axios.get(`${API_BASE_URL}/location?type=Country`)
      .then(res => {
        this.setState({locations: res.data.locations});
      }).catch(e => console.log("Error"));
  }

  handlePageChange = async (pageNumber) => {
    await this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber});
    await this.getQueryData();
  }

  changeStatus = (statusObj, order_id, callback = () => null) => {
    const headers = {
      'Content-Type': 'application/json',
      'x-api-client': getDeviceType(),
      'x-auth-token': getLocalAuthData().token,
    }
    axios.patch(`${API_BASE_URL}/order/${order_id}/change-status`, statusObj,
      {headers: headers}
    )
      .then(response => {
        callback()
        toast.success(response.data.message);
      })
      .catch(error => {
        callback()
        toast.error('Something went wrong');
      });
  };

  //getLocalAuthData().token,
  getQueryData = () => {
    const headers = {
      'Content-Type': 'application/json',
      'x-api-client': 'web',
      'x-auth-token':  getLocalAuthData().token
    }
    let queryData = "";
    // eslint-disable-next-line array-callback-return
    Object.entries(this.state.queryData).map(([key, val]) => {
        queryData = queryData.concat(key + "=" + val + "&");
      }
    )
      axios.get(`${API_BASE_URL}/order?${queryData}`, {headers: headers})
      .then(res => {
        console.log('TEST LIST',res)
        this.setState({orders: res.data.items,/*pagination:res.data.pagination*/});
      }).catch(e => console.log("Error"));

     
  }

  handleSearchFormDataChange = (e) => {
    const merchant_type = e.target.name;
    const merchant_type_id = e.target.value;
    if (merchant_type === "merchant_type") {
      const headers = {
        'Content-Type': 'application/json',
        'x-api-client': getDeviceType(),
        'x-auth-token': getLocalAuthData().token,
      }
      axios.get(`${API_BASE_URL}/store?merchant_type=${merchant_type_id}&lite_data=1`, {headers: headers})
        .then(res => {
          this.setState({storeList: res.data.items});
        })
        .catch(err => {
          console.log(err)
        });
    }
    this.setState({queryData: {...this.state.queryData, [e.target.name]: e.target.value}});
  };

  renderMerchantType = () => {
    const merchantType = this.state.merchantType;
    if (merchantType) {
      return (
        <>
          {merchantType.map((items, index) =>
            <option key={index} value={`${items._id}`}>{items.type_name}</option>
          )}
        </>
      )
    }
  };

  renderStoreList = () => {
    const storeList = this.state.storeList;
    if (storeList.length > 0) {
      return (
        storeList.map((items, index) =>
          <option key={index} value={`${items._id}`}>{items.store_name}</option>
        )
      )
    }
  };

  hideChangeStatusModal = () => {
    this.setState({isShowingStatusChangeConfirmModal: false})
  }

  submitChangeStatusForm = (e) => {
    e.preventDefault()
    if (this.state.changeStatusMsg === '') {
      return this.setState({showCancelMessageRequired: true});
    }
    this.setState({isSubmitting: true, showCancelMessageRequired: false}, () => {
      this.changeStatus({
        status: this.state.status,
        message: this.state.changeStatusMsg
      }, this.state.changingStoreId, () => {
        this.setState({
          isSubmitting: false,
          message: '',
          changingStoreId: null,
          isShowingStatusChangeConfirmModal: false
        })
      })
    })
  }
  handleLocationInputOnChange = (e, index, typePurpose) => {
    const childLocations = [...this.state[typePurpose]];
    if (typePurpose === 'businessChildLocations') {
      this.handleSearchFormDataChange(e)
    } else {
      this.handleSearchFormDataChange(e)
    }
    if (e.target.value !== '') {
      getChildLocations(e.target.value)
        .then(res => {
          childLocations.splice(index, childLocations.length - index);
          if (res.length > 0) {
            childLocations[index] = res
          }
          this.setState({[typePurpose]: childLocations})
        })
        .catch(errMsg => {
          console.log(errMsg)
          childLocations.splice(index, childLocations.length - index)
          this.setState({[typePurpose]: childLocations})
        })
    } else {
      childLocations.splice(index, childLocations.length - index);
      this.setState({[typePurpose]: childLocations})
    }
  }

  renderLocations = () => {
    const locations = this.state.locations;
    if (locations) {
      return (
        <>
          {locations.map((items, index) =>
            <option value={`${items._id}`}>{items.name}</option>
          )}
        </>
      )
    }
  };

  handleOnApply = (e, picker) => {
    const queryData = {...this.state.queryData};

    queryData.from_date = moment(picker.startDate).format('DD/MM/YYYY');
    queryData.to_date = moment(picker.endDate).format('DD/MM/YYYY');

    this.setState({queryData}, () => {
      console.log(this.state)
    })
  }

  renderPaymentStatus = (status) => {
    let payment_status = {
      0: "Unpaid",
      1: "Partially Paid",
      2: "Paid",
      3: "Cancelled",
      4: "Failed",
      5: "Refund",
      6: "Bank deposit payment in review",
      7: "Bank deposit declined",
    }
    return payment_status[status];
  }

  render() {
    return (
      <App>
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Order List</h2>
            <div className="ml-auto">
              {getLocalAuthData()?.permission?.includes('order_list_export') ?
              <Button size={"sm"} variant={"default"}
                      className="mb-0 ml-2"><FaFileExport/> Export</Button>
                  :null}
            </div>
          </div>

          {this.state.isLoading ?
            <div className={`card-block`}>
              <div className="block-body">
                Loading...
              </div>
            </div>
            :
              <>
                {getLocalAuthData()?.permission?.includes('order_list_view') ?
                <div className="card-block">
                  <ul className="desc-header-nav">
                    <li className={this.state.activeBlockTab === 'All' ? 'active' : ''} value="">
                      <span onClick={() => this.handleBlockTab('All')}>All</span>
                    </li>
                    <li className={this.state.activeBlockTab === 'Pending' ? 'active' : ''}>
                      <span onClick={() => this.handleBlockTab('Pending')}>Pending</span>
                    </li>
                    <li className={this.state.activeBlockTab === 'Confirmed' ? 'active' : ''}>
                      <span onClick={() => this.handleBlockTab('Confirmed')}>Confirm</span>
                    </li>
                    <li className={this.state.activeBlockTab === 'Processing' ? 'active' : ''}>
                      <span onClick={() => this.handleBlockTab('Processing')}>Processing</span>
                    </li>
                    <li className={this.state.activeBlockTab === 'Picked' ? 'active' : ''}>
                      <span onClick={() => this.handleBlockTab('Picked')}>Picked</span>
                    </li>
                    <li className={this.state.activeBlockTab === 'Shipped' ? 'active' : ''}>
                      <span onClick={() => this.handleBlockTab('Shipped')}>Shipped</span>
                    </li>
                    <li className={this.state.activeBlockTab === 'Delivered' ? 'active' : ''}>
                      <span onClick={() => this.handleBlockTab('Delivered')}>Delivered</span>
                    </li>
                    <li className={this.state.activeBlockTab === 'Canceled' ? 'active' : ''}>
                      <span onClick={() => this.handleBlockTab('Canceled')}>Canceled</span>
                    </li>
                  </ul>

                  <div className="block-body">
                    <form className="form-inline mb-3">

                      <div className="form-group mr-3 mt-2">
                        <label htmlFor="status" className="sr-only">Locations</label>
                        <select id="status" name="country" className='form-control form-control-sm'
                                onChange={(e) => this.handleLocationInputOnChange(e, 0, 'businessChildLocations')}>
                          <option value="">--Select Country--</option>
                          {this.renderLocations()}
                        </select>
                      </div>

                      {this.state.businessChildLocations.length > 0 &&
                      this.state.businessChildLocations.map((location, index) => (
                          <div className="form-group mr-3 mt-2" key={index}>
                            <label htmlFor={`location${location[0].type}`}
                                   className="sr-only">{location[0].type}</label>
                            <select id={`location${location[0].type}`}
                                    name={location[0].type.toLowerCase()}
                                    className='form-control form-control-sm'
                                    onChange={(e) => this.handleLocationInputOnChange(e, index + 1, 'businessChildLocations')}>
                              <option value="">--Select--</option>
                              {location.map((item, index) => (
                                  <option key={index} value={item._id}>{item.name}</option>
                              ))}
                            </select>
                          </div>
                      ))
                      }

                      <div className="form-group mr-3 mt-2">
                        <label htmlFor="barndName" className="sr-only">Merchant Type</label>
                        <select id="merchantType" name="merchant_type"
                                onChange={this.handleSearchFormDataChange}
                                className='form-control form-control-sm'>
                          <option value="">--Select Type--</option>
                          {this.renderMerchantType()}
                        </select>
                      </div>

                      {this.state.storeList?.length > 0 &&
                      <div className="form-group mr-3 mt-2">
                        <label htmlFor="barndName" className="sr-only">Store</label>
                        <select
                            id="store"
                            name="store_id"
                            className='form-control form-control-sm'
                            onChange={this.handleSearchFormDataChange}
                        >
                          <option value="">--Select Store--</option>
                          {this.renderStoreList()}
                        </select>
                      </div>}

                      <div className="form-group mr-3 mt-2">
                        <label htmlFor="searchInput" className="sr-only">Order Number</label>
                        <input type="search" id="orderNumber" className='form-control form-control-sm'
                               placeholder="Order Number" name="order_parcel_code"
                               onChange={this.handleSearchFormDataChange}/>
                      </div>

                      <div className="form-group mr-3 mt-2">
                        <label htmlFor="sellerSKU" className="sr-only">Customer</label>
                        <input type="search" id="customer" name="customer_search"
                               onChange={this.handleSearchFormDataChange}
                               className='form-control form-control-sm'
                               placeholder="Customer"/>
                      </div>

                      <div className="form-group mr-3 mt-2">
                        <label htmlFor="shopSKU" className="sr-only">Product</label>
                        <input type="search" id="product" name="product_search"
                               onChange={this.handleSearchFormDataChange}
                               className='form-control form-control-sm'
                               placeholder="Product"/>
                      </div>

                      <div className="form-group mr-3 mt-2">
                        <DateRangePicker
                            initialSettings={{}}
                            containerStyles={{textAlign: 'left', width: '100%', display: 'block'}}
                            onApply={this.handleOnApply}>
                          <input type="button"
                                 placeholder={`Select date range`}
                                 value={(this.state.queryData.from_date && this.state.queryData.to_date) ? `${this.state.queryData.from_date} - ${this.state.queryData.to_date}` : 'Select date range'}
                                 style={{textAlign: 'left', minWidth: '150px'}}
                                 className="form-control form-control-sm"/>
                        </DateRangePicker>
                      </div>

                      <Button className="mt-2" size="sm" variant="secondary" onClick={this.getQueryData}>Search</Button>
                    </form>

                    {this.state.orders?.length > 0 ?
                        <>
                          <Modal show={this.state.isShowingStatusChangeConfirmModal}
                                 onHide={this.hideChangeStatusModal}>
                            <Modal.Header closeButton>
                              <Modal.Title>Change Status Message</Modal.Title>
                            </Modal.Header>
                            <Form onSubmit={this.submitChangeStatusForm}>
                              <Modal.Body>
                                <Form.Control as={`textarea`}
                                              onChange={(e) => {
                                                this.setState({changeStatusMsg: e.target.value})
                                              }}
                                              placeholder={`Enter you message here...`} rows={3}/>
                                {this.state.showCancelMessageRequired ?
                                    <span><h5 style={{'color': "red"}}><i>Cancel message is required!</i></h5></span>
                                    : ''}
                              </Modal.Body>
                              <Modal.Footer>
                                <Button variant="default" onClick={this.hideChangeStatusModal}>
                                  Close
                                </Button>
                                <Button disabled={this.state.isSubmitting}
                                        variant="primary" type={`submit`}>
                                  {this.state.isSubmitting ? 'Submitting...' : 'Submit'}
                                </Button>
                              </Modal.Footer>
                            </Form>
                          </Modal>

                          <table className="order-table table table-striped">
                            <thead>
                            <tr>
                              <th style={{width: '1%'}}>#</th>
                              <th>Order Info</th>
                              <th>Customer Info</th>
                              <th>Order Amount (৳)</th>
                              <th>Payment Method</th>
                              <th>Payment Status</th>
                              <th>Status Message</th>
                              <th style={{width: '150px'}}>Status</th>
                              <th style={{width: '120px'}}/>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.orders.map((attr, index) => {
                              return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      <div className="order-item">
                                        <h4 className="order-id"><Link
                                            to={`${process.env.PUBLIC_URL}/orders/details/${attr._id}`}>{attr?.order_code}</Link>
                                        </h4>
                                        <span className="order-date"><Moment
                                            format="ll">{attr.createdAt}</Moment></span>
                                        <span className="order-time"><Moment
                                            format="HH:mma">{attr.createdAt}</Moment></span>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="order-item">
                                        <h4 className="order-id"><Link
                                            to={`${process.env.PUBLIC_URL}/customers/details/${attr.customer_info?._id}`}>{attr.customer_info?.name}</Link>
                                        </h4>
                                        <ul className="ordered-items">
                                          <li>{attr.customer_info?.phone}</li>
                                          <li>{attr.customer_info?.email}</li>
                                          <li>
                                            <Link to={`${process.env.PUBLIC_URL}/messenger/`}>
                                              <span className="icon"><FiMessageSquare/></span> Contact Customer
                                            </Link>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="order-item">
                                        <h4 className="order-id">
                                          <Link
                                              to={`${process.env.PUBLIC_URL}/store/details/${attr?.store_info?._id}`}>{attr?.store_info?.store_name}</Link>
                                        </h4>
                                        <ul className="ordered-items">
                                          <li>
                                            <Link to={`${process.env.PUBLIC_URL}/messenger/`}>
                                              <span className="icon"><FiMessageSquare/></span> Contact Seller
                                            </Link>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                    <td>

                                      <div className="order-item">
                                        <h4 className="order-id">
                                          ৳{Number(attr.grand_total).toFixed(2)}
                                        </h4>
                                        <ul className="ordered-items">
                                          <li>{attr.payment_method?.name}</li>
                                          <li>{this.renderPaymentStatus(attr.payment_status)}</li>
                                        </ul>
                                      </div>
                                    </td>
                                    <td>{attr?.status_message || '-'}</td>
                                    <td>
                                      <select style={{width: '150px'}}
                                              defaultValue={attr.status}
                                              className="form-control form-control-sm"
                                              onChange={(e) => {
                                                this.setState({
                                                  status: e.target.value,
                                                  changingStoreId: attr._id,
                                                  isShowingStatusChangeConfirmModal: true
                                                })
                                              }}>
                                        <option value="Pending">Pending</option>
                                        <option value="Confirmed">Confirmed</option>
                                        <option value="Processing">Processing</option>
                                        <option value="Picked">Picked</option>
                                        <option value="Shipped">Shipped</option>
                                        <option value="Delivered">Delivered</option>
                                        <option value="Cancelled">Cancelled</option>
                                      </select>
                                    </td>

                                    <td>
                                      <Link to={`${process.env.PUBLIC_URL}/orders/details/${attr._id}`}
                                            className="btn btn-block btn-sm btn-outline-secondary">View</Link>
                                      <Link to={`${process.env.PUBLIC_URL}/orders/invoice/${attr._id}`}
                                            className="btn btn-block btn-sm btn-outline-info">Invoice</Link>
                                    </td>
                                  </tr>
                              );
                            })}

                            </tbody>
                          </table>


                          <div className="mt-3">
                            {/*<Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.activePage}
                        // itemsCountPerPage={1}
                        totalItemsCount={this.state.pagination.totalDocs}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                      />*/}

                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={this.state.pagination?.page}
                                itemsCountPerPage={this.state.pagination?.limit}
                                totalItemsCount={this.state.pagination?.totalDocs}
                                onChange={this.handlePageChange.bind(this)}
                            />
                          </div>

                        </>
                        :
                        <div className={`alert alert-warning text-center text-bold`}>
                          No order found
                        </div>
                    }
                  </div>
                </div> : null}
              </>
          }

        </div>
      </App>
    );
  }
}

export default OrderList;
