import React, {Component} from 'react';
import App from "../../App";
import {Button, Form, Spinner} from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Moment from "moment";
import {FaFileExport, FaPrint, FiSearch} from "react-icons/all";
// import Chart from "chart.js";
// import axios from "axios";
// import {API_BASE_URL} from "../../helper/env";
import moment from "moment";
import {fileDownload, getTableData} from "../../services/commonServices";
import Pagination from 'react-js-pagination';
import {withRouter} from "react-router-dom";
import {useJsonToCsv} from "react-json-csv";
import {FaFilePdf} from "@react-icons/all-files/fa/FaFilePdf";
const {saveAsCsv} = useJsonToCsv();

class TransactionReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            queryData: {
                limit: 20
            },
            pagination: {},
            reportData: [],

            data: {
                dataset: {},
                status: '',
                value: ''
            }
        }
    }


    getData = () =>{
        getTableData('/reports/transactions' ,this.state.queryData).then(res => {
            this.setState({
                reportData: res.data,
                pagination: res.paginate
            })
        })
    }

    handleOnApply = (e, picker) => {
        const queryData = {...this.state.queryData}
        const startDate = Moment(picker.startDate).format('DD-MM-YYYY')
        const endDate = Moment(picker.endDate).format('DD-MM-YYYY')
        queryData['from_date'] = startDate;
        queryData['to_date'] = endDate;

        this.setState({
            queryData
        }, () => {
            this.getData(this.state.queryData)
        })
    }
    handleOnChangeInput = (e) => {
        const queryData = {...this.state.queryData}
        queryData[e.target.name] = e.target.value;
        this.setState({queryData}, () => {
            console.log(this.state.formData)
            this.getData(this.state.queryData)
        })
    }

    handlePageChange = async (pageNumber) => {
        const history = this.props.history
        history.push(`${history.location.pathname}?page=${pageNumber}`)
        await this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber}, ()=>{
            this.getData(this.state.queryData);
        });
    }



    componentDidMount() {
       this.getData()
    }



    handleExport = async () =>{
        this.setState({export_loading: true})
        let queryData = {...this.state.queryData}
        queryData.export = 'xlsx'
        this.setState({queryData}, ()=>{
            fileDownload(`/reports/transactions`, this.state.queryData).then((response)=>{
                console.log(response)
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'ekshop-transaction-report.xlsx');
                document.body.appendChild(link);
                link.click();
                this.setState({export_loading: false})
            })
            queryData.export = null
            this.setState({
                queryData
            })
        })

        /*let queryData = "";
        // noinspection JSMismatchedCollectionQueryUpdate
        let mData = [];

        this.state.reportData.length > 0 && this.state.reportData.map((data,index) => {

            mData.push({
                'Index': index+1,
                'Store Name':data?._id?.store_name,
                'Merchant Name':data?.merchant_name,
                'Merchant Type':data?.merchant_type,
                'Total Order Qty':data?.total_order_quantity,
                'Total Delivered Order':data?.delivered,
                'Total Cancel Order':data?.cencelled,
                'Total Pending Order':data?.pending,
                'Total Sales':data.total,
            });

        });

        const filename = 'EkShop-Transaction Report',
            fields = {
                'Index': "Index",
                'Store Name':"Store Name",
                'Merchant Name':"Merchant Name",
                'Merchant Type':"Merchant Type",
                'Total Order Qty':"Total Order Qty",
                'Total Delivered Order': "Total Delivered Order",
                'Total Cancel Order': "Total Cancel Order",
                'Total Pending Order': "Total Pending Order",
                'Total Sales': "Total Sales",
            },
            style = {
                padding: "30px",
                maxHeight: "20px"
            },
            data = mData,
            text = "Convert Json to Csv";
        saveAsCsv({data, fields, filename});*/

    }

    render() {
        return (
            <App>
                <div className="page-header">
                    <h2 className="page-title">Transaction Report</h2>

                    <div className="ml-auto">
                        <Button variant="primary"

                                size={`sm`}
                                onClick={() => this.handleExport('xlsx')}
                                disabled={this.state.export_loading}>
                            {this.state.export_loading ?
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span>Loading...</span>
                                </>
                                : <><FaFilePdf/><span>Export</span></>}
                        </Button>
                        {/*<Button onClick={() => {
                            this.handleExport()
                        }} size={"sm"} variant={"default"} className="mb-0 mr-2"><FaFileExport/> Export</Button>*/}
                    </div>
                </div>

                {/* <div className="card-block">
          <div className="block-body">
            <canvas
              style={{height: '320px', width: '100%'}}
              ref={this.dailyTransaction}
            />
          </div>
        </div> */}

                <div className="card-block">
                    <div className="block-body">
                        <Form className="d-flex v-middle">
                            <div className="form-inline mr-2">
                                <Form.Group className={'mr-2'}>
                                    <Form.Label className={"sr-only"}>Date Range Picker</Form.Label>
                                    <DateRangePicker
                                        onApply={this.handleOnApply}>
                                        <input type="text"
                                               style={{textAlign: 'left', minWidth: '150px'}}
                                               placeholder={"Select date range"}
                                               className="form-control form-control-sm"/>
                                    </DateRangePicker>
                                </Form.Group>
                            </div>

                        </Form>
                        <hr/>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th style={{width: '40px'}}>Sl</th>
                                    {/*<th>Store Id</th>*/}
                                    <th>Store Name</th>
                                    <th>Merchant Name</th>
                                    <th>Merchant Type</th>
                                    <th>Total Order Qty</th>
                                    <th>Total Delivered Order</th>
                                    <th>Total Cancel Order</th>
                                    <th>Total Pending Order</th>
                                    <th>Total Sales</th>
                                    {/* <th className="nowrap" style={{width: '1%'}}>Payable Amount (৳)</th> */}
                                </tr>
                                </thead>
                                <tbody>

                                {this.state.reportData.length > 0 && this.state.reportData.map((data, i) => {
                                    return (
                                        <tr>
                                            <td>{i + 1}</td>
                                            {/*<td>{data?._id?.store_id}</td>*/}
                                            <td>{data?._id?.store_name}</td>
                                            <td>{data?.merchant_name}</td>
                                            <td>{data?.merchant_type}</td>
                                            <td>{data?.total_order_quantity}</td>
                                            <td>{data?.delivered}</td>
                                            <td>{data?.cencelled}</td>
                                            <td>{data?.pending}</td>
                                            <td>{data.total}</td>
                                        </tr>
                                    )
                                })
                                }
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-3">
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={this.state?.pagination?.page}
                                itemsCountPerPage={this.state?.pagination?.limit}
                                totalItemsCount={this.state?.pagination?.totalDocs ? this.state?.pagination?.totalDocs : 0}
                                pageRangeDisplayed={10}
                                onChange={this.handlePageChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </App>
        );
    }
}

export default withRouter(TransactionReport);
