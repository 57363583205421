import React, {Component} from 'react'
import App from "../../App"
import {Button, Form, Spinner} from "react-bootstrap"
import Moment from "moment"
import {FaFileExport} from "react-icons/all"
import {getLocalAuthData} from "../../helper/utils"
import {fileDownload, getTableData} from "../../services/commonServices"
import {useJsonToCsv} from "react-json-csv"
import {withRouter} from "react-router-dom"
import Pagination from 'react-js-pagination'
import DateRangePicker from "react-bootstrap-daterangepicker"
import moment from "moment";
import {FaFilePdf} from "@react-icons/all-files/fa/FaFilePdf";

const {saveAsCsv} = useJsonToCsv()

class CustomerReport extends Component {
    state = {
        startDate: null,
        endDate: null,
        reportData: [],
        queryData: {
            limit: 20
        },
        pagination: {},
    }

    getData = (queryData) => {
        getTableData('/reports/customers', queryData).then(res => {
            this.setState({
                reportData: res.data,
                pagination: res.paginate
            })
        })
    }

    componentDidMount() {
        this.getData(this.state.queryData)
    }

    handlePageChange = async (pageNumber) => {
        const history = this.props.history
        history.push(`${history.location.pathname}?page=${pageNumber}`)
        await this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber}, () => {
            this.getData(this.state.queryData)
        })
    }

    handleOnApply = (e, picker) => {
        const queryData = {...this.state.queryData}
        const startDate = Moment(picker.startDate).format('DD-MM-YYYY')
        const endDate = Moment(picker.endDate).format('DD-MM-YYYY')
        queryData['from_date'] = startDate
        queryData['to_date'] = endDate

        this.setState({
            queryData
        }, () => {
            this.getData(this.state.queryData)
        })
    }

    handleExport = async () => {
        this.setState({export_loading: true})

        let queryData = {...this.state.queryData}
        queryData.export = 'xlsx'
        this.setState({queryData}, ()=>{
            fileDownload(`/reports/customers`, this.state.queryData).then((response)=>{
                console.log(response)
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'ekshop-transaction-report.xlsx');
                document.body.appendChild(link);
                link.click();
                this.setState({
                    export_loading: false
                })
            })
            queryData.export = null
            this.setState({
                queryData
            })
        })

      /*  let queryData = ""
        // noinspection JSMismatchedCollectionQueryUpdate
        let mData = []

        this.state.reportData && this.state.reportData.map((data1, index1) => {

            mData.push({
                'Index': index1 + 1,
                'Name': data1?.name,
                'Email': data1?.email,
                'Phone': data1?.phone,
                'Muktopaath User': data1?.muktopaath_user,
                'Pending Order': data1?.pending_order,
                'Received Order': data1?.received_order,
                'Total Order': data1?.total_order,

            })

        })

        const filename = 'EkShop-Customer Report',
            fields = {
                'Index': "Index",
                'Name': "Name",
                'Email': "Email",
                'Phone': "Phone",
                'Muktopaath User': "Muktopaath User",
                'Pending Order': "Pending Order",
                'Received Order': "Received Order",
                'Total Order': "Total Order",


            },
            style = {
                padding: "30px",
                maxHeight: "20px"
            },
            data = mData,
            text = "Convert Json to Csv"
        saveAsCsv({data, fields, filename})*/
    }

    handleOnChangeInput = (e) => {
        const queryData = {...this.state.queryData}
        queryData[e.target.name] = e.target.value
        this.setState({queryData}, () => {
            this.getData(this.state.queryData)
        })
    }

    render() {
        return (
            <App>
                <div className="page-header">
                    <h2 className="page-title">Customer Report</h2>
                    <Button variant="primary"

                            size={`sm`}
                            onClick={() => this.handleExport('xlsx')}
                            disabled={this.state.export_loading}>
                        {this.state.export_loading ?
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span>Loading...</span>
                            </>
                            : <><FaFilePdf/><span>Export</span></>}
                    </Button>
                    {/*<Button onClick={() => {
                        this.handleExport()
                    }} size={"sm"} variant={"default"} className="mb-0 mr-2"><FaFileExport/> Export</Button>*/}
                </div>

                <>{getLocalAuthData()?.permission?.includes('customer_list') ?
                    <div className="card-block">
                        <div className="block-body">
                            <Form className="d-flex v-middle">
                                <div className="form-inline">
                                    <Form.Group className={'mr-2'}>
                                        <Form.Label className={"sr-only"}>Date Range Picker</Form.Label>
                                        <input
                                            type="search"
                                            id="orderNumber"
                                            className='form-control form-control-sm'
                                            placeholder="Search Key"
                                            name="search_string"
                                            onChange={this.handleOnChangeInput}
                                        />
                                    </Form.Group>

                                    <Form.Group className={'mr-2'}>
                                        <Form.Label className={"sr-only"}>Date Range Picker</Form.Label>
                                        <DateRangePicker
                                            onApply={this.handleOnApply}>
                                            <input type="text"
                                                   style={{textAlign: 'left', minWidth: '150px'}}
                                                   placeholder={"Select date range"}
                                                   className="form-control form-control-sm"/>
                                        </DateRangePicker>
                                    </Form.Group>

                                </div>

                            </Form>
                            <hr/>
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th style={{width: '30px'}}>Sl</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Muktopaath User</th>
                                        <th>Date</th>
                                        <th>Pending Order</th>
                                        <th>Cancel Order</th>
                                        <th>Received Order</th>
                                        <th>Total Order</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.reportData.length > 0 && this.state.reportData.map((data, i) => {
                                        return (
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{data?.name}</td>
                                                <td>{data?.email}</td>
                                                <td>{data?.phone}</td>
                                                <td>{data?.muktopaath_user ? "Yes" : "No"}</td>
                                                <td>{moment(data?.date).format("hh:mm a DD/MM/YYYY")}</td>
                                                <td>{data?.pending_order}</td>
                                                <td>{data?.cancel_order}</td>
                                                <td>{data?.received_order}</td>
                                                <td>{data?.total_order}</td>
                                            </tr>
                                        )
                                    })}

                                    </tbody>
                                </table>
                            </div>
                            <div className="mt-3">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={this.state?.pagination?.page}
                                    itemsCountPerPage={this.state?.pagination?.limit}
                                    totalItemsCount={this.state?.pagination?.totalDocs ? this.state?.pagination?.totalDocs : 0}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                        </div>
                    </div> : null}
                </>


            </App>
        )
    }
}

export default withRouter(CustomerReport)
