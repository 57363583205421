import React, {Component} from "react"
import {Link, withRouter} from "react-router-dom"
import App from "../../App"
import {FiSkipBack} from "react-icons/all"
import {Button, Col, Form, Row} from "react-bootstrap"
import Select from "react-select"
import styleReactSelect from "../../assets/styleReactSelect"
import {getBrandList} from "../../services/brandServices"
import {toast} from "react-toastify"
import {getStoreList} from "../../services/storeServices"
import axios from "axios";
import {API_BASE_URL} from "../../helper/env";
import {getLocalAuthData} from "../../helper/utils";
import BrowseFiles from "../../components/BrowseFiles";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {UploadAdapter} from "../../adapters/ckUploadAdapter";
import {activityLogPostData} from "../../services/commonServices";
import {getData} from "../../services/baseServices";

// noinspection DuplicatedCode
class HoichoiServiceEdit extends Component {
    state = {
        brands: [],
        stores: [],
        formData: {
            name: null,
            price: null,
            old_price: null,
            planCode: null,
            time: null,
            time_type: null,
            device: null,
            simultaneous: null,
        },
        isLoading: false,
        isFormValidated: false,
        user: getLocalAuthData()
    }

    componentDidMount() {
        this.createTableSelectOnChange('1', 'time_type')
        const id = this.props.match.params.id;
        getData(`/hoichoi/${id}`).then((res)=>{
            this.setState({formData: res?.package})
        }).catch((err)=>{})
    }

    createTableSelectOnChange = (value, type) => {
        const formData = this.state.formData
        if (type === 'time_type') formData.time_type = value.value
        if (type === 'name') formData.name = value
        if (type === 'price') formData.price = value
        if (type === 'old_price') formData.old_price = value
        if (type === 'planCode') formData.planCode = value
        if (type === 'time') formData.time = value
        if (type === 'device') formData.device = value
        if (type === 'simultaneous') formData.simultaneous = value
        this.setState({
            formData: formData
        })
    }

    handleImageOnRemove = (index) => {
        const product = {...this.state.product}
        const mainImages = [...this.state.mainImages]
        product.images.splice(index, 1)
        mainImages.splice(index, 1)
        this.setState({
            product,
            mainImages
        })
    }

    handleAddProductImages = async (files) => {
        const formData = {...this.state.formData}
        formData.images = files
        this.setState({formData, mainImages: files});
    }

    handleCkEditorOnChange = (name, data) => {
        const formData = {...this.state.formData, [name]: data};
        this.setState({formData})
    }

    handleOnSubmit = (e) => {
        e.preventDefault()

        const form = e.currentTarget
        this.setState({attributeErrorMessage: "", attComboError: ""})

        if (form.checkValidity() === false) {
            e.stopPropagation()
            this.setState({isFormValidated: true})
            return
        }

        const authData = getLocalAuthData();
        axios.put(`${API_BASE_URL}/hoichoi/${this.props.match.params.id}`, this.state.formData, {
            headers: {
                'x-auth-token': authData.token,
                'x-api-client': 'web'
            }
        }).then(() => {
            toast.success('Package update successfully')
            //activityLogPostData('was added new hoichoi package','/services/hoichoi/list',e.target?.name)
            this.props.history.push('/services/hoichoi/list')
        }).catch(err => {
            toast.error(err.message)
        })
    }

    render() {
        return (
            <App layout="boxed">
                <div className="main-content">
                    <div className="page-header">
                        <h2 className="page-title">Hoichoi Services List</h2>
                        <div className="ml-auto">
                            <Link to={`${process.env.PUBLIC_URL}/services/hoichoi/list`}
                                  className="btn btn-sm btn-secondary"><FiSkipBack/> Back</Link>
                        </div>
                    </div>
                    <div className="card-block position-relative">
                        <div className="block-body">
                            {
                                this.state.isLoading ? (
                                    <div>
                                        Loading...
                                    </div>
                                ) : (
                                    <React.Fragment>
                                        {/*<LoadingSpinner/>*/}
                                        <Form noValidate validated={this.state.isFormValidated} onSubmit={this.handleOnSubmit}>
                                            <div className="card-block">
                                                <div className="block-header">
                                                    <h4 className="block-title">What You're Selling</h4>
                                                </div>
                                                <div className="block-body">
                                                    <Form.Group as={Row} controlId="name">
                                                        <Form.Label column sm="3">
                                                            Name <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Col sm="9" md={8} lg={7}>
                                                            <Form.Control type="text"
                                                                          required
                                                                          onChange={(e) => {
                                                                              this.createTableSelectOnChange(e.target.value, 'name')
                                                                          }}
                                                                          defaultValue={this.state.formData?.name}
                                                                          name="name" placeholder=""/>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid name.
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="price">
                                                        <Form.Label column sm="3">
                                                            Price <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Col sm="9" md={8} lg={7}>
                                                            <Form.Control type="number"
                                                                          min={0}
                                                                          required
                                                                          onChange={(e) => {
                                                                              this.createTableSelectOnChange(e.target.value, 'price')
                                                                          }}
                                                                          defaultValue={this.state.formData?.price}
                                                                          name="price" placeholder=""/>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid price.
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="old_price">
                                                        <Form.Label column sm="3">
                                                            Previous Price <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Col sm="9" md={8} lg={7}>
                                                            <Form.Control type="number"
                                                                          min={0}
                                                                          required
                                                                          onChange={(e) => {
                                                                              this.createTableSelectOnChange(e.target.value, 'old_price')
                                                                          }}
                                                                          defaultValue={this.state.formData?.old_price}
                                                                          name="old_price" placeholder=""/>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid old price.
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="planCode">
                                                        <Form.Label column sm="3">
                                                            Plan Code <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Col sm="9" md={8} lg={7}>
                                                            <Form.Control type="text"
                                                                          required
                                                                          onChange={(e) => {
                                                                              this.createTableSelectOnChange(e.target.value, 'planCode')
                                                                          }}
                                                                          defaultValue={this.state.formData?.planCode}
                                                                          name="planCode" placeholder=""/>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid product name.
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="time">
                                                        <Form.Label column sm="3">
                                                            Time <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Col sm="9" md={8} lg={7}>
                                                            <Form.Control type="number"
                                                                          min={0}
                                                                          required
                                                                          onChange={(e) => {
                                                                              this.createTableSelectOnChange(e.target.value, 'time')
                                                                          }}
                                                                          defaultValue={this.state.formData?.time}
                                                                          name="time" placeholder=""/>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid time.
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="store_id">
                                                        <Form.Label column sm="3">
                                                            Time Type <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Col sm="9" md={8} lg={7}>
                                                            <Select
                                                                required
                                                                styles={styleReactSelect}
                                                                placeholder="Type for Select store..."
                                                                options={[
                                                                    {
                                                                        id: 1,
                                                                        value: 1,
                                                                        label: 'Days'
                                                                    }, {
                                                                        id: 2,
                                                                        value: 2,
                                                                        label: 'Months'
                                                                    }, {
                                                                        id: 3,
                                                                        value: 3,
                                                                        label: 'Years'
                                                                    }
                                                                ]}
                                                                name="store_id"
                                                                onChange={(value) => this.createTableSelectOnChange(value, 'time_type')}
                                                                noOptionsMessage={() => 'Please insert new value...'}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="device">
                                                        <Form.Label column sm="3">
                                                            Device <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Col sm="9" md={8} lg={7}>
                                                            <Form.Control type="number"
                                                                          min={0}
                                                                          required
                                                                          onChange={(e) => {
                                                                              this.createTableSelectOnChange(e.target.value, 'device')
                                                                          }}
                                                                          defaultValue={this.state.formData?.device}
                                                                          name="device" placeholder=""/>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid device.
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="simultaneous">
                                                        <Form.Label column sm="3">
                                                            Simultaneous <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Col sm="9" md={8} lg={7}>
                                                            <Form.Control type="number"
                                                                          min={0}
                                                                          required
                                                                          onChange={(e) => {
                                                                              this.createTableSelectOnChange(e.target.value, 'simultaneous')
                                                                          }}
                                                                          defaultValue={this.state.formData?.simultaneous}
                                                                          name="simultaneous" placeholder=""/>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Simultaneous.
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formImages">
                                                        <Form.Label column sm="3">
                                                            Images <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Col sm="9" md={8} lg={7}>
                                                            <BrowseFiles
                                                                userId={this.state.user._id}
                                                                image_category={'hoichoi'}
                                                                onRemoveItem={this.handleImageOnRemove}
                                                                images={this.state.formData.images ? [{thumb_img: this.state.formData.images[0]?.thumb_img}] : []}

                                                                onGetFiles={this.handleAddProductImages}
                                                                helpText="Multiple images can be uploaded at once.Maximum 8 pictures. Size between 330x330 and 800x800 px. Obscene image is strictly prohibited."/>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formHighlight">
                                                        <Form.Label column sm="3">
                                                            Highlights <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Col sm="9" md={8} lg={7}>
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                data={this.state.formData?.highlights}
                                                                onInit={editor => {
                                                                    editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
                                                                        return new UploadAdapter(loader);
                                                                    };
                                                                }}
                                                                config={{

                                                                    image: {
                                                                        resizeUnit: 'px',
                                                                        toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],
                                                                        styles: [
                                                                            'full',
                                                                            'alignLeft',
                                                                            'alignRight'
                                                                        ]
                                                                    }
                                                                }
                                                                }
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    this.handleCkEditorOnChange('highlights', data);
                                                                }}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="card-block">
                                                <div className="block-body text-right">
                                                    <Link to={`/services/hoichoi/list`} className={`btn btn-lg btn-default mr-2`}>Cancel</Link>
                                                    <Button size="lg" type={`submit`} variant="primary">Publish</Button>
                                                </div>
                                            </div>
                                        </Form>
                                    </React.Fragment>
                                )
                            }
                        </div>
                    </div>
                </div>
            </App>
        )
    }
}

export default withRouter(HoichoiServiceEdit)
