import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const NavItem = ({to, label, icon, children, className, title}) => {
  const [isOpenNavDrawer, setIsOpenNavDrawer] = useState(false)

  const handleToggleNavDrawer = () => {
    setIsOpenNavDrawer(!isOpenNavDrawer)
  }

  if (to) {
    // noinspection RequiredAttributes
    return (
      // <div className={'nav-item'}>
      //   <Link to={to} className={'nav-link'}>
      //     <span className={'icon'}>{icon}</span>
      //     <span className="label">
      //       {label}
      //     </span>
      //   </Link>
      // </div>
      <div className={'nav-item'}>
        <Link to={to} className={'nav-link'}>
          <OverlayTrigger
            key={'right'}
            placement={'right'}
            overlay={
              <Tooltip id={`tooltip-right`}>
                <span className="label">{label}</span>
              </Tooltip>
            }
          >
            <span className={'icon'}>{icon}</span>
          </OverlayTrigger>
        </Link>
      </div>
    );
  } else {
    // noinspection RequiredAttributes
    return (
      <div className={isOpenNavDrawer ? 'nav-item open-drawer' : 'nav-item'}>
        <span onClick={handleToggleNavDrawer} className={'nav-link'}>
          <OverlayTrigger
            key={'right'}
            placement={'right'}
            overlay={
              <Tooltip id={`tooltip-right`}>
                <span className="label">{label}</span>
              </Tooltip>
            }
          >
            <span className={'icon'}>{icon}</span>
          </OverlayTrigger>
        </span>
        {children &&
        <>
          <div onClick={handleToggleNavDrawer} className="nav-overlay"/>
          <div className={className ? `nav-drawer ${className}` : 'nav-drawer'}>
            <button onClick={handleToggleNavDrawer} className={'btn-close-drawer'}>&times;</button>
            {title && <div className="drawer-header">
              <h4 className="header-title">{title}</h4>
            </div>}
            <div className="drawer-body">{children}</div>
          </div>
        </>
        }
      </div>
    );
  }
};

export default NavItem;
