import React, {Component} from 'react';
import "../assets/scss/product.scss";
import {Link} from "react-router-dom";
import moment from 'moment';
import {getLocalAuthData} from "../helper/utils";
import {FaStore, GoLinkExternal} from "react-icons/all";
import {SHOP_BASE_URL} from "../helper/env";
import {Button} from "react-bootstrap";

class Store extends Component {
    state = {
        userType: null
    }

    componentDidMount() {
        const authData = getLocalAuthData()
        this.setState({userType: authData?.type})
    }

    render() {
        const {data} = this.props;

        return (
            <div className="table-responsive">
                <table className="order-table table table-striped">
                    <thead>
                    <tr>
                        <th style={{width: '20px'}}>#</th>
                        <th>Store Name</th>
                        <th>Merchant Info.</th>
                        <th>Product</th>
                        <th>Order</th>
                        <th style={{width: '1%'}}/>
                    </tr>
                    </thead>

                    <tbody>
                    {data.stores.length > 0 ?
                        data.stores.map((store, index) => (
                            <tr key={index}>
                                <td>{data.pagingCounter + index}</td>
                                <td width={"20%"}>
                                    <div className="order-item">
                                        <h4 className="order-id mb-0">{store.store_name}</h4>
                                        <ul className="ordered-items">
                                            <li>{"Created At:" + moment(store.createdAt).format('lll')}</li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    {store?.user?.name}
                                    <br/>
                                    {store?.user?.phone}
                                    <br/>
                                    {store?.user?.email}
                                </td>
                                <td>{store.total_product ? store.total_product : 0}</td>
                                <td>{store?.total_order ? store.total_order : 0}</td>
                                <td className="nowrap">
                                    {/*<Link to={`/products/list?store_id=${store._id}`} className="text-bold text-success mr-3" target={'_blank'}>View Products</Link>*/}
                                    <Link to={`/stores/details/${store._id}`} className="btn btn-sm btn-outline-info mr-3" target={'_blank'}>View</Link>

                                    {this.props.handleDeleteStore && <span style={{cursor: "pointer"}} onClick={() => this.props.handleDeleteStore(store._id)} className="btn btn-sm btn-outline-danger mr-3">Delete</span>}

                                    {this.props.approveCampaignStore && this.props.approvedStores?.length > 0 && this.props.approvedStores.findIndex((elm) => elm._id.toString() === store._id.toString()) === -1 && <span style={{cursor: "pointer"}} onClick={() => this.props.approveCampaignStore(store._id)} className="btn btn-sm btn-outline-success mr-3">Approve</span>}
                                    {this.props.approveCampaignStore && this.props.approvedStores?.length === 0 && <span style={{cursor: "pointer"}} onClick={() => this.props.approveCampaignStore(store._id)} className="btn btn-sm btn-outline-success mr-3">Approve</span>}

                                    {this.props.removeCampaignStore && <span style={{cursor: "pointer"}} onClick={() => this.props.removeCampaignStore(store._id)} className="btn btn-sm btn-outline-danger mr-3">Remove</span>}

                                    {this.props.handleProductListModal && <button style={{cursor: "pointer"}} onClick={() => this.props.handleProductListModal(store._id, this.props.campaignInfo._id)} className="btn btn-sm btn-outline-primary ml-2">Store Product</button>}
                                </td>
                            </tr>
                        ))
                        :
                        <tr>
                            <td colSpan={5} className={"text-bold text-center"}>
                                No store found. Please create <Link to={"/stores/create"}>new store</Link>
                            </td>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Store;
