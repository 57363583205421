import axios from "axios";
import {API_BASE_URL} from "../helper/env";
import {getLocalAuthData} from "../helper/utils";

export const getMerchantType = () => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/merchantType`, {
        headers: {
            "x-auth-token": authData.token,
            "x-api-client": 'web'
        }
    })
      .then(res => {
        if(res.data.status === 1) {
          resolve(res.data.merchant_type_obj)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        console.log(err);
        reject('Something went wrong!')
      })
  })
}
