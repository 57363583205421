import React from 'react';
import "../assets/scss/info-card.scss";
import {numberWithCommas} from "../helper/utils";

const InfoCard = ({icon, title, value, className, isCurrency, total}) => {

    let xValue = value ? value.toString() : ""
    let finalVal = value;
    if (xValue.toString().indexOf(".") !== -1) {
        let a = xValue.split(".")
        finalVal = a[0]
    }


    let yValue = isCurrency ? isCurrency.toString() : ""
    let isCurrency2 = isCurrency;
    if (yValue.toString().indexOf(".") !== -1) {
        let a = yValue.split(".")
      isCurrency2 = a[0]
    }


    return (
        <div className={className ? `${className} info-card` : 'info-card'}>
            {icon &&
            <span className={'icon'}>
        {icon}
      </span>
            }
            {value &&
            <span className="value">{isCurrency2 ? numberWithCommas(finalVal) : finalVal}</span>
            }

            {total &&
            <span style={{fontSize: '15px'}} className="value">{isCurrency2 ? numberWithCommas(total) : total}</span>
            }

            {title && <h3 className="title">{title}</h3>}
        </div>
    );
};

export default InfoCard;