import React, {Component} from 'react';
import App from "../../App";
import {Button} from "react-bootstrap";
import {FaFileExport, FiPlus, FiUpload} from "react-icons/all";
import {Link} from "react-router-dom";
// import DateRangePicker from "react-bootstrap-daterangepicker";
import Moment from "moment";
import {deleteVoucher, getSellerVoucher} from "../../services/promotionServices";
import {toast} from "react-toastify";
import moment from "moment";
import {confirmAlert} from "react-confirm-alert";
import {getLocalAuthData} from "../../helper/utils";
import {activityLogPostData} from "../../services/commonServices";


const today = Moment(new Date()).format('M/D/Y');

class SellerVoucher extends Component {
  state = {
    startDate: today,
    endDate: today,
    promotions: []
  }

  componentDidMount() {
    getSellerVoucher()
      .then(res => {
        this.setState({promotions: res}, () => {
          console.log('State: ', this.state.promotions)
        })
      })
      .catch(errMsg => {
        toast.error(errMsg)
      })
  }

  handleOnApply = (e, picker) => {
    const startDate = Moment(picker.startDate).format('M/D/Y');
    const endDate = Moment(picker.endDate).format('M/D/Y')
    this.setState({
      startDate: startDate,
      endDate: endDate,
    })
  }

  handleVoucherDelete = (id,name) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to delete this voucher?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteVoucher(id)
              .then(msg => {
                getSellerVoucher()
                    .then(res => {
                      this.setState({promotions: res})
                    })
                toast.success(msg);
                activityLogPostData('was delete this voucher ','/promotions/seller-voucher/edit/'+id,name)
              })
              .catch(errMsg => {
                toast.error(errMsg)
              })
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }

  // All = 1, Stores = 2, EkShop Mall = 3, Uddakta Mall = 4, Local Merchant (EP) = 5, Global Product = 6
  renderApplyFor = (status) => {
    switch (parseInt(status)) {
      case 1:
        return 'All'
      case 2:
        return 'Stores'
      case 3:
        return 'Ekshop Mall'
      case 4:
        return 'Location Merchant'
      case 5:
        return 'Specific Categories'
      default:
        return 'All'
    }
  }

  renderDisplayArea = (status) => {
    switch (parseInt(status)) {
      case 1:
        return 'Regular'
      case 2:
        return 'Offline'
      case 3:
        return 'Store Follower'
      default:
        return 'Regular'
    }
  }

  render() {
    const {promotions} = this.state;

    return (
      <App layout="">
        <div className="page-header">
          <h2 className="page-title">Seller Voucher</h2>
          <div className="ml-auto">
            {/*<Button size={"sm"} variant={"default"} className="mb-0 mr-2"><FaFileExport/> Export</Button>*/}

            {getLocalAuthData()?.permission?.includes('seller_voucher_add') ?
            <Link to={`${process.env.PUBLIC_URL}/promotions/seller-voucher/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add Voucher</Link> : null}
          </div>
        </div>

        <div className="main-content">
          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Voucher List</h4>
            </div>

            <div className="block-body">
              {/*<form className="form-inline mb-3">
                <div className="form-group mr-3">
                  <label htmlFor="dateRange" className="sr-only">Date Range</label>
                  <DateRangePicker style={{margin: 0, padding: 0}} onApply={this.handleOnApply}
                                   startDate={this.state.startDate}
                                   endDate={this.state.endDate}>
                    <input readOnly={true}
                           type="button"
                           value={`${this.state.startDate} to ${this.state.endDate}`}
                           className="form-control form-control-sm"/>
                  </DateRangePicker>
                </div>

                <div className="form-group mr-3">
                  <label htmlFor="voucherName" className="sr-only">Voucher Name</label>
                  <input type="search" id="voucherName" className='form-control form-control-sm'
                         placeholder="Voucher Name"/>
                </div>
                <div className="form-group mr-3">
                  <label htmlFor="voucherCode" className="sr-only">Voucher Code</label>
                  <input type="search" id="voucherCode" className='form-control form-control-sm'
                         placeholder="Voucher Code"/>
                </div>

                <Button size="sm" variant="secondary">Search</Button>
              </form>*/}

              {getLocalAuthData()?.permission?.includes('seller_voucher_list') ?
              <table className="table table-striped">
                <thead>
                <tr>
                  <th style={{width: '1%'}}>#</th>
                  <th style={{width: '15%'}}>Voucher Name</th>
                  <th style={{width: '15%'}}>Duration</th>
                  <th style={{width: '35%'}}>Detail</th>
                  <th style={{width: '25%'}}>Voucher Setting</th>
                  <th style={{width: '10%'}}/>
                </tr>
                </thead>
                <tbody>
                {promotions.length > 0 ?
                  promotions.map((promo, index) => (

                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{promo.voucher_name}</td>
                      <td>
                        <span className="start-date d-block"><strong>Start Date: </strong>{moment(promo.start_time).format('ll')}</span>
                        <span className="end-date d-block"><strong>End Date: </strong>{moment(promo.end_time).format('ll')}</span>
                      </td>
                      <td>
                        <span className="start-date d-block"><strong>Applied For: </strong>{this.renderApplyFor(promo.apply_to)}</span>
                        <span className="end-date d-block"><strong>Display Area: </strong>{this.renderDisplayArea(promo.display_area)}</span>
                        <span className="end-date d-block text-primary"><strong>Discount (off) </strong>{parseInt(promo.discount_type) === 1 ? `${promo.discount_value} tk` : promo.discount_value + '%'}</span>
                        {(promo?.voucher_type==='2')?<><span className="end-date d-block"><strong>Voucher Codes: </strong>{promo?.voucher_codes?.join(', ')}</span></>:''}
                      </td>
                      <td>
                        <span className="start-date d-block"><strong>Minimum amount required: </strong>{promo.minimum_order_value}</span>
                        {promo.maximum_discount_amount?<span className="start-date d-block"><strong>Maximum discount amount: </strong>{promo.maximum_discount_amount}</span>:''}
                        <span className="start-date d-block"><strong>Total vouchers: </strong>{promo.total_voucher}</span>
                        <span className="start-date d-block"><strong>Usage Limit Per Customer: </strong>{promo.usage_limit}</span>
                      </td>
                      <td className="nowrap">
                        {/*<button className="btn btn-sm btn-link text-info">View</button>*/}
                        {getLocalAuthData()?.permission?.includes('seller_voucher_edit') ?
                        <Link to={`${process.env.PUBLIC_URL}/promotions/seller-voucher/edit/${promo._id}`} className="btn btn-sm btn-link text-primary">Edit</Link>: null}
                        {getLocalAuthData()?.permission?.includes('seller_voucher_delete') ?
                        <button className="btn btn-sm btn-link text-danger" onClick={() => this.handleVoucherDelete(promo._id ,promo.voucher_name)} >Delete</button> : null}
                      </td>
                    </tr>
                  ))
                  :
                  <tr>
                    <td colSpan={7} className="text-bold text-center">
                      No discount added yet. Please <Link to={`${process.env.PUBLIC_URL}/promotions/seller-voucher/add`}>Add Voucher</Link>
                    </td>
                  </tr>
                }
                </tbody>
              </table>
                  : <span className={`text-danger`}>Permission Denied</span>}

              {/*<div className="mt-3">
                <Pagination>
                  <Pagination.First/>
                  <Pagination.Prev/>
                  <Pagination.Item>{1}</Pagination.Item>
                  <Pagination.Ellipsis/>
                  <Pagination.Item>{10}</Pagination.Item>
                  <Pagination.Item>{11}</Pagination.Item>
                  <Pagination.Item active disabled>{12}</Pagination.Item>
                  <Pagination.Item>{13}</Pagination.Item>
                  <Pagination.Item>{14}</Pagination.Item>
                  <Pagination.Ellipsis/>
                  <Pagination.Item>{20}</Pagination.Item>
                  <Pagination.Next/>
                  <Pagination.Last/>
                </Pagination>
              </div>*/}
            </div>
          </div>
        </div>
      </App>
    );
  }



}

export default SellerVoucher;
