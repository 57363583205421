import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {getChildLocations, getLocationList} from "../../services/locationServices";
import BrowseFiles from "../../components/BrowseFiles";
import {getMerchantType} from "../../services/merchantTypeServices";
import {addMerchant} from "../../services/merchantServices";
import {toast} from "react-toastify";
import {getLocalAuthData} from "../../helper/utils";
import {activityLogPostData, addNewItem, checkIsExisting, getTableData, postData} from "../../services/commonServices";
import {getBankList} from "../../services/settingServices";

class CreatePartnerOnBoard extends Component {
    state = {
        activeBlockTab: 'General',
        countries: [],
        businessChildLocations: [],
        merchantTypes: [],
        formData: {
        },
        isLoading: false,
        merchant_id: null,
        isAdding: false,
        merchantInfo: null,
        isFormValidated: false,
        phoneExistingStatus: 2,
        phoneExistingMsg: null,
        emailExistingStatus: 2,
        emailExistingMsg: null,
        businessNameExistingStatus: 2,
        businessNameExistingMsg: null,
        splittedPhone: ['+880', ''],
        bankList: []
    };

    componentDidMount() {
        const authData = getLocalAuthData();
        this.setState({merchant_id: authData.merchant_id})

        getLocationList()
            .then(res => {
                console.log('Res: ', res)
                this.setState({countries: res})
            })

        getMerchantType()
            .then(res => {
                this.setState({merchantTypes: res}, () => {
                    console.log(this.state.merchantTypes);
                })
            })

        getBankList()
            .then(res => {
                this.setState({bankList: res})
            })
            .catch(errMsg => {
                console.log(errMsg)
            })
    }

    handleBlockTab = label => {
        this.setState({
            activeBlockTab: label
        })
    };

    handleLocationInputOnChange = (e, index, typePurpose) => {
        const childLocations = [...this.state[typePurpose]];
        this.handleInputOnChange(e);
        if (e.target.value !== '') {
            getChildLocations(e.target.value)
                .then(res => {
                    childLocations.splice(index, childLocations.length - index);
                    if (res.length > 0) {
                        childLocations[index] = res
                    }
                    this.setState({[typePurpose]: childLocations})
                })
                .catch(errMsg => {
                    console.log(errMsg)
                    childLocations.splice(index, childLocations.length - index)
                    this.setState({[typePurpose]: childLocations})
                })
        } else {
            childLocations.splice(index, childLocations.length - index);
            this.setState({[typePurpose]: childLocations})
        }
    }

    handleInputOnChange = (e) => {
        const formData = {...this.state.formData}
        formData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        if (e.target.name === 'com_name'){
            this.checkCompanyNameExist(e)
            this.setState({formData})
        }else {
            this.setState({formData})
        }
    }

    handleCommissionAvailability = (e) => {
        const formData = {...this.state.formData}
        formData['commission_available_for'][e.target.name] = e.target.checked;
        this.setState({formData})
        console.log(this.state.formData);
    }

    handleBankInputOnChange = (e) => {
        const formData = {...this.state.formData}
        formData.bank_info[e.target.name] = e.target.value;
        this.setState({formData})
    }

    handleSubmitForm = e => {
        e.preventDefault();
        const formData = {...this.state.formData};

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({isFormValidated: true})
        } else {
            console.log('========> <======',this.state.formData)
            this.setState({formData, isAdding: true}, () => {
                addNewItem('/partner' , this.state.formData)
                    .then(res => {
                        this.setState({isAdding: false, isFormValidated: false});
                        toast.success(res.message)
                        activityLogPostData('was create new partner onboard','/partner-onboard/list/',e.target.com_name)
                        this.props.history.push(`${process.env.PUBLIC_URL}/partner-onboard/list`);
                    })
                    .catch(errMsg => {
                        this.setState({isAdding: false, isFormValidated: false});
                        toast.error(errMsg);
                    })
            })
        }
    }

    handleOnBrowsePic = (files, name) => {
        const formData = {...this.state.formData}
        formData[name] = files[0].actual_img;
        this.setState({formData})
    }

    handleBankOnBrowsePic = (files, name) => {
        const formData = {...this.state.formData}
        formData.bank_info[name] = files[0].actual_img;
        this.setState({formData})
    }

    handleRemoveImage = (name) => {
        const formData = {...this.state.formData}
        formData[name] = ''
        this.setState({formData})
    }

    handleBankRemoveImage = (name) => {
        const formData = {...this.state.formData}
        formData.bank_info[name] = ''
        this.setState({formData})
    }

    checkCompanyNameExist = (e) => {
        postData('/partner/check-company-exist', {com_name :this.state.formData?.com_name}).then( (res)=>{
            console.log(res)
        }).catch( (err)=>{
            console.log(err)
        })
    }

    inputPhoneOnChange = (e, index) => {
        const splittedPhone = [...this.state.splittedPhone]
        splittedPhone[index] = e.target.value
        this.setState({splittedPhone})
    }

    render() {
        const {formData} = this.state;

        return (
            <App layout="boxed">

                {getLocalAuthData()?.permission?.includes('entrepreneurs_add') ?  <>
                    <div className="page-header">
                        <h2 className="page-title">Add Partner Onboard</h2>
                    </div>
                    <div className="card-block">
                        <div className="block-body">
                            {this.state.isLoading ?
                                <div>Loading...</div>
                                :
                                <>
                                    {this.state.activeBlockTab === 'General' &&
                                    <Form noValidate validated={this.state.isFormValidated}
                                          onSubmit={this.handleSubmitForm}>
                                        <Form.Group as={Row} controlId="">
                                            <Form.Label column sm="3"/>

                                            <Col sm="9" md={8} lg={7}>
                                                <strong></strong>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="nameId">
                                            <Form.Label column sm="3">
                                                Company Name <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control
                                                    name={"com_name"}
                                                    required
                                                    defaultValue={formData?.com_name}
                                                    onChange={this.handleInputOnChange} type="text"/>
                                                <Form.Control.Feedback type="invalid">Please enter company
                                                    name</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="sellerEmail">
                                            <Form.Label column sm="3">
                                                Website Url <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control name={"url"}
                                                              required
                                                              defaultValue={formData?.url}
                                                              onChange={this.handleInputOnChange}
                                                              type="text"/>

                                              {/*  {this.state.emailExistingMsg && <Form.Text className={'text-danger'}>
                                                    {this.state.emailExistingMsg}
                                                </Form.Text>}*/}

                                                <Form.Control.Feedback type="invalid">Please enter a valid url
                                                    </Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>


                                        <Form.Group as={Row} controlId="">
                                            <Form.Label column sm="3">
                                                Upload Company Logo <span className="text-danger">*</span>
                                            </Form.Label>

                                            <Col sm="9" md={8} lg={7}>
                                                <BrowseFiles
                                                    isSingle={true}
                                                    image_category={'profile'}
                                                    images={this.state.formData?.com_logo ? [{thumb_img: this.state.formData.com_logo}] : []}
                                                    onRemoveItem={() => this.handleRemoveImage('com_logo')}
                                                    onGetFiles={(files) => {
                                                        this.handleOnBrowsePic(files, 'com_logo')
                                                    }}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="">
                                            <Form.Label column sm="3">
                                                Upload Cover <span className="text-danger">*</span>
                                            </Form.Label>

                                            <Col sm="9" md={8} lg={7}>
                                                <BrowseFiles
                                                    image_category={'profile'}
                                                    images={this.state.formData?.banner ? [{thumb_img: this.state.formData.banner}] : []}
                                                    onRemoveItem={() => this.handleRemoveImage('banner')}
                                                    onGetFiles={(files) => {
                                                        this.handleOnBrowsePic(files, 'banner')
                                                    }}/>
                                            </Col>
                                        </Form.Group>
                                        

                                        <div className="mt-3">
                                            <Button size="lg"
                                                    type={"submit"}
                                                    disabled={this.state.isAdding}
                                                    variant="primary">{this.state.isAdding ? 'Adding...' : 'Add'}</Button>
                                        </div>
                                    </Form>
                                    }

                                    {this.state.activeBlockTab === 'Invoice Number' &&
                                    <>
                                        <Form.Group as={Row} controlId="generationType">
                                            <Form.Label column sm="3">
                                                Generation Type
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control as="select">
                                                    <option value="">Use Autoincrement Number</option>
                                                    <option value="">Use Order Number</option>
                                                    <option value="">Provide number manually</option>
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="generationType">
                                            <Form.Label column sm="3">
                                                Invoice Prefix
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control type="text"/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="generationType">
                                            <Form.Label column sm="3">
                                                Next Number (Insert numbers only)
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control type="number"/>
                                            </Col>
                                        </Form.Group>
                                    </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </>:null}

            </App>
        );
    }
}

//export default AddEntrepreneur;
export default withRouter(CreatePartnerOnBoard);
