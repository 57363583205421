import React, {useEffect, useState} from "react";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import slideSample from "../assets/img/slide-sample.jpg";
import "../assets/scss/main-slide.scss";
import {FiMove, FiPlus, FiTrash, FiEdit} from "react-icons/all";
import slideImg from "../temp/slide-img.jpg";
import {getLocalAuthData, Reorder} from "../helper/utils";
import BrowseFiles from "./BrowseFiles";
import {addItem, getData, permanentDeleteItem} from "../services/baseServices";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import {deleteStore, getStoreList} from "../services/storeServices";
/*import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";*/
import App from "../App";
import {getItemStyle, getSectionListStyle} from "../helper/dragDropStyles";
import { Container, Draggable } from 'react-smooth-dnd';
import {postData, updateItemPartial} from "../services/commonServices";
import { SketchPicker } from 'react-color'


const Menu = ({onClickDelete}) => {
    const [showAddSlideModal, setShowAddSlideModal] = useState(false)
    const [menu, setMenu] = useState([])
    const [formData, setFormData] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)


    useEffect(()=>{
        getmenu()
    },[onClickDelete])


    useEffect(() => {
        getmenu()

        //updateItemPartial('/update-index-banner-v2').then((res)=>{})

    }, [])

    const getmenu = () => {
        setMenu([])
        getData(`/menu` ,{sort_by_index: true})
            .then(res => {
                // res.items
                setMenu(res.items)
            })
    }

    const handleAddSlideModalClose = () => setShowAddSlideModal(false);
    const handleAddSlideModalShow = () => {
        setFormData({banner_type: 1, image_size_template: 'homepage_slider_web', location: 'home-slider'})
        setShowAddSlideModal(true)
    };

    const handleDeleteBanner = (id) => {
        confirmAlert({
            title: 'Permanent Delete',
            message: 'Are you sure to delete this banner?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => permanentDeleteItem(`/menu/${id}/permanent/`)
                        .then(res=> {
                            getmenu()
                            toast.success(res.message)
                        })
                        .catch(errMsg => {
                            toast.error(errMsg)
                        })
                },
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });


    }

    const handleInputOnChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const handleImageOnChange = (images, name) => {
        setFormData({...formData, [name]: images[0].actual_img})
    }

    const handleSaveSlider = () => {
        setIsSubmitting(true)
        addItem(`/menu`, formData)
            .then(res => {
                toast.success(res.message)
                handleAddSlideModalClose()
                getmenu()
                setIsSubmitting(false)
            })
            .catch(errMsg => {
                toast.error(errMsg)
                setIsSubmitting(false)
            })
    }


    const applyDrag = (arr, dragResult) => {
        const { removedIndex, addedIndex, payload } = dragResult;
        if (removedIndex === null && addedIndex === null) return arr;

        const result = menu;
        let itemToAdd = payload;

        if (removedIndex !== null) {
            itemToAdd = result.splice(removedIndex, 1)[0];
        }

        if (addedIndex !== null) {
            result.splice(addedIndex, 0, itemToAdd);
        }

        return result;
    };

    const generateItems = (count, creator) => {
        const result = [];
        for (let i = 0; i < count; i++) {
            result.push(creator(i));
        }
        return result;
    };



    function onDrop(dropResult) {
        const { removedIndex, addedIndex, payload, element } = dropResult;
        console.log("MENU::START::",dropResult)

        var data = menu;
        let newArray = [];
        for (var i=0;i<menu.length; i++){
            if (removedIndex > addedIndex){
                if (i === addedIndex){
                    newArray.push(menu[removedIndex])
                    newArray.push(menu[i])

                }else if (i === removedIndex){
                }
                else {
                    newArray.push(menu[i])
                }
            }else {
                if (i === addedIndex){
                    newArray.push(menu[i])
                    newArray.push(menu[removedIndex])
                }else if (i === removedIndex){
                }
                else {
                    newArray.push(menu[i])
                }
            }

        }
        for (var j=0; j<newArray.length; j++){
            newArray[j].index = j
        }

        postData('/menu-v2', {items: newArray}).then((res)=>{
            if (res.status === 1){
                toast.success(res.message)
                getmenu()
            }
        })

    }

    useEffect(()=>{
        console.log("MENU", menu)
    },[menu])


    return (
        <div className="card-block">
            <div className="block-header">
                <h4 className="block-title">Menu</h4>
                <div onClick={handleAddSlideModalShow} className="btn btn-outline-secondary btn-sm btn-add-slide">
                    {/* <img src={slideSample} alt=""/> */}
                    <span className="action"><FiPlus/></span>
                </div>
            </div>

            <div className="block-body pb-2">
                <div className="d-flex">
                    {/* <div>
                        <div orientation="horizontal" onDrop={e => {onDrop(e)}} className="w-100">
                            <div className="row">
                                {menu.map((item , index) => {
                                    return (
                                        <Draggable key={index} className="col-md-3 mb-3">
                                            <div className="menu-slide-area-single">
                                                <div className="main-slide">
                                                    <span>{item?.name}</span>
                                                    <div className="slide-actions">
                                                        <div className="actions">
                                                            <span className="action"><FiEdit/></span>
                                                            <span onClick={() => handleDeleteBanner(item?._id)} className="action"><FiTrash/></span>
                                                            <span className="action"><FiMove/></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Draggable>
                                    );
                                })}
                            </div>
                        </div>
                    </div> */}

                    <div className="w-100">
                        <Container orientation="horizontal" onDrop={e => {onDrop(e)}} style={{display: 'flex', flexWrap: 'wrap', marginLeft: '-15px', marginRight: '-15px', width: '100%'}}>
                            {menu.map((item , index) => {
                                return (
                                    <Draggable key={index} className="col-md-3 mb-3">
                                        <div className="menu-slide-area-single">
                                            <div className="main-slide">
                                                <span>{item?.name}</span>
                                                <div className="slide-actions">
                                                    <div className="actions">
                                                        <span className="action"><FiEdit/></span>
                                                        <span onClick={() => handleDeleteBanner(item?._id)} className="action"><FiTrash/></span>
                                                        <span className="action"><FiMove/></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Draggable>
                                );
                            })}
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Menu;
