import React, {Component} from "react";
import {Button, Modal} from "react-bootstrap";
import {FiChevronRight} from "react-icons/all";

class RenderCategory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
      categories: this.props.categories,
      childCategories: this.props.childCategories,
      selectedCatArr: this.props.selectedCatArr,
      cateGoryname: this.props.cateGorynameArr,
    }
  }

  handleChildCategory = async (index, id) => {
    let selectedCatArr = [...this.state.selectedCatArr];
    let childCategories = [...this.state.childCategories];
    let cateGoryname = [...this.state.cateGoryname];
    console.log("Index = " + index, "id= " + id, "selectedarr= " + selectedCatArr);

    if (index === 'root') {
      childCategories = [];
      selectedCatArr = [];
      cateGoryname = [];

      const categoryObj = this.state.categories.filter(obj => {
        return obj._id === id;
      });

      if (!selectedCatArr.length > 0) {
        selectedCatArr.push(id);
        cateGoryname.push(categoryObj[0]);
      } else {
        selectedCatArr[0] = id;
        cateGoryname[0] = cateGoryname.push(categoryObj[0]);
      }

      if (this.state.childCategories.length > 0) {
        if (categoryObj[0].child_cat?.length > 0) {
          childCategories[0] = categoryObj[0].child_cat;
        } else {
          childCategories = []
        }
      } else {
        if (categoryObj[0].child_cat?.length > 0) {
          childCategories.push(categoryObj[0].child_cat);
        } else {
          childCategories = []
        }
      }
    } else {
      childCategories.splice(index + 2, childCategories.length - 1);
      selectedCatArr.splice(index + 2, childCategories.length - 1);

      const childCategoriesObj = await this.state.childCategories[index].filter(obj => {
        return obj._id === id;
      });

      if (selectedCatArr[index + 1]) {
        selectedCatArr[index + 1] = id;
        cateGoryname[index + 1] = childCategoriesObj[0];
      } else {
        selectedCatArr.push(id);
        cateGoryname.push(childCategoriesObj[0]);
      }

      if (childCategories[index + 1]) {
        console.log('hello: ', childCategoriesObj[0]);
        if (childCategoriesObj[0].child_cat?.length > 0) {
          childCategories[index + 1] = childCategoriesObj[0].child_cat;
        }
      } else {
        if (childCategoriesObj[0].child_cat?.length > 0) {
          childCategories.push(childCategoriesObj[0].child_cat);
        }
      }
    }

    console.log(childCategories);

    await this.setState({selectedCatArr});
    await this.setState({childCategories});
    if (this.state.cateGoryname.length > 0) {
      let cateGorynameArr = [];
      cateGoryname.forEach((val, index) => {
        if (selectedCatArr.includes(val._id)) {
          cateGorynameArr.push(val);
        }
      });
      cateGoryname = cateGorynameArr;
    }
    await this.setState({cateGoryname});
  }

  render() {

    return (
      <Modal
        size="lg"
        dialogClassName="cateogry-change-modal"
        show={this.state.showModal} onHide={this.props.handleModalHide}>
        <Modal.Header closeButton>
          <Modal.Title>Change Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="target-category">Target Category: Home Appliances > Cooling & Heating > Water
            Heaters</p>

          <div className="category-row">
            <div className="category-col">
              <div className="form-group">
                <input type="search" className="form-control form-control-sm" placeholder="Search"/>
              </div>

              {this.state.categories.length > 0 &&
              <ul className="category-list">
                {this.state.categories.map(cat => (
                  <li key={cat._id}
                      className={this.state.selectedCatArr[0] === cat._id ? 'selected' : ''}
                      onClick={() => this.handleChildCategory('root', cat._id)}>
                    <span className='text'>{cat.name}</span>
                    {cat.child_cat?.length > 0 &&
                    <span className="caret-icon"><FiChevronRight/></span>}
                  </li>
                ))}
              </ul>
              }
            </div>

            {this.state.childCategories.map((childCategories, index) => (
              <div key={index} className="category-col">
                <div className="form-group">
                  <input type="search" className="form-control form-control-sm" placeholder="Search"/>
                </div>

                <ul className="category-list">
                  {childCategories.map(childCat => (
                    <li key={childCat._id}
                        onClick={() => this.handleChildCategory(index, childCat._id)}
                        className={this.state.selectedCatArr[index + 1] === childCat._id ? 'selected' : ''}>
                      <span className='text'>{childCat.name}</span>
                      {childCat.child_cat?.length > 0 &&
                      <span className="caret-icon"><FiChevronRight/></span>}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={this.props.handleModalHide}>
            Cancel
          </Button>
          <Button variant="primary"
                  onClick={() => this.props.selectedCat(this.state.cateGoryname, this.state.selectedCatArr/*.slice(-1).pop()*/, this.state.childCategories)}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default RenderCategory;
