import React, {Component} from 'react';
import App from "../../App";
import {Link, withRouter} from "react-router-dom";
import {FiArrowLeft} from "react-icons/all";
import {Button, Col, Form, Row} from "react-bootstrap";
import {
  getSingleCollection,
  updateCollection
} from "../../services/collectionServices";
import {toast} from "react-toastify";
import {activityLogPostData} from "../../services/commonServices";

class EditCollection extends Component {
  state = {
    isCreating: false,
    name: '',
    collection_slug: '',
    isLoading: false,
    isUpdating: false
  }

  componentDidMount() {
    this.setState({isLoading: true});
    const collectionId = this.props.match.params.id;
    getSingleCollection(collectionId)
      .then(res => {
        this.setState({
          name: res.name,
          collection_slug: res.collection_slug,
          isLoading: false
        }, () => {
          console.log('Response', this.state)
        })
      })
  }

  handleUpdateCollection = () => {
    const collectionId = this.props.match.params.id;
    this.setState({isUpdating: false})
    const data = {
      name: this.state.name,
      collection_slug: this.state.collection_slug
    }
    updateCollection(collectionId, data)
      .then(res => {
        toast.success('Collection successfully updated');
        activityLogPostData('was edit this collection','/collections/edit/'+collectionId,this.state.name)
        setTimeout(() => {
          this.props.history.push(`${process.env.PUBLIC_URL}/collections`);
        }, 3000)
      })
      .catch(errMsg => {
        toast.error(errMsg);
      })
  }

  handleOnInputChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    })
  }

  render() {
    return (
      <App layout={"boxed"}>
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Update Collection</h2>
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/collections`} className="btn btn-sm btn-link"><FiArrowLeft/> Back to Collection List</Link>
            </div>
          </div>

          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Collection Details</h4>
            </div>
            <div className="block-body">
              {!this.state.isLoading ?
                <>
                  <Form.Group as={Row} controlId="formCollectionName">
                    <Form.Label column sm="3">
                      Collection Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                        defaultValue={this.state.name}
                        onChange={this.handleOnInputChange}
                        type="text" placeholder="Collection Name"
                        name="name"/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formCollectionSlug">
                    <Form.Label column sm="3">
                      Collection Slug <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                        defaultValue={this.state.collection_slug}
                        onChange={this.handleUpdateCollection}
                        type="text" placeholder="Collection Slug" name="collection_slug"/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Col sm="3"/>
                    <Col sm="9" md={8} lg={7}>
                      <Button
                        disabled={this.state.isCreating}
                        onClick={() => this.handleUpdateCollection()}
                        variant={"primary"}>Update</Button>
                    </Col>
                  </Form.Group>
                </>
                :
                <>Loading...</>
              }
            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(EditCollection);
