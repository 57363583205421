import React, {Component} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import icSectionIcon from "../assets/img/section-icon.svg";
import {FiPlus, FiCheck, FiBox} from "react-icons/fi";
import {FiLayers} from "react-icons/all";
import {HOME_BLOCK_SECTIONS} from "../helper/constant";

class AddBlockButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowingModal: false,
      selectedBlocks: []
    }
  }

  handleShowModal = () => {
    this.setState({isShowingModal: true})
  };

  handleModalClose = () => {
    this.setState({isShowingModal: false, selectedBlocks: []});
  };

  handleOnchange = (e) => {
    const selectedBlocks = [...this.state.selectedBlocks]
    const selectedBlock = HOME_BLOCK_SECTIONS.filter(obj => {
      return obj.section_type === e.target.value
    })
    if (e.target.checked) {
      selectedBlocks.push(selectedBlock[0])
    } else {
      const index = selectedBlocks.indexOf(selectedBlock[0])
      selectedBlocks.splice(index, 1)
    }
    this.setState({selectedBlocks})
  }

  render() {
    const {data, action} = this.props

    return (
      <>
        <Modal show={this.state.isShowingModal} onHide={() => this.handleModalClose()} animation={true}>
          <Modal.Header closeButton>
            <Modal.Title>Select Sections</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="category-list">
              <div className="category-list-inner">
                {data.map((section, index) => (
                  <label htmlFor={`section-${index}`} className="category-item" key={`section-${index}`}>
                    <input id={`section-${index}`} value={section.section_type} onChange={this.handleOnchange} type="checkbox"/>

                    <span className="category-card">
                      <span className="category-image">
                        <FiLayers/>
                      </span>

                        <span className="category-name">
                        <span>{section.block_name}</span> <span className="check-marker"><FiCheck/></span>
                      </span>
                    </span>
                  </label>
                ))}
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleModalClose()}>
              Close
            </Button>
            <Button variant="primary" onClick={() => {
              action(this.state.selectedBlocks, () => {
                this.setState({selectedBlocks: []})
                this.handleModalClose()
              })
            }}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <button type="submit"
                onClick={() => this.handleShowModal()} className="btn-add-block">
          <FiPlus/>
        </button>
      </>
    );
  }
}

export default AddBlockButton;
