import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import Select from "react-select";
import styleReactSelect from "../../assets/styleReactSelect";
import {getMerchantList, getMerchantTypeList} from "../../services/merchantServices";
import {getStoreList} from "../../services/storeServices";
import {getCategoryList} from "../../services/categoryServices";
import {getProductListAlt, getProductListSecondary} from "../../services/productServices";
import {IoIosArrowRoundBack, IoMdCheckmark} from "react-icons/all";
import {addCommissionSettings} from "../../services/commissionSettingsServices";
import {toast} from "react-toastify";
import {Link, withRouter} from "react-router-dom";
import Pagination from "react-js-pagination";
import {getLocalAuthData} from "../../helper/utils";
import {activityLogPostData} from "../../services/commonServices";

class AddCustomCommissionSettings extends Component {
  state = {
    merchants: [],
    merchantList: [],
    categoryList: [],
    products: [],
    merchantTypes: [],
    selectedProductList: [],
    isLoading: true,
    selectedProducts: [],
    formData: {
      products: [],
      valueType: "2"
    },
    isProductModalProductShowing: false,
    isSubmitting: false,
    defaultQuerySettings: {
      merchantList: {lite_data: 1},
      storeList: {merchants: this.merchants || [], status: 1, approval_status: 1, lite_data: 1},
      productList: {limit: 20}
    },
    isProductLoading: false
  }

  componentDidMount() {
    getMerchantTypeList().then(res => {
      const merchantTypes = []
      res.forEach(item => {
        merchantTypes.push({label: item.type_name, value: item._id})
      });
      this.setState({merchant_types: merchantTypes})
    }).then(errMsg => {
      console.log('Error Message: ', errMsg)
    })

    /*CATEGORY LIST*/
    getCategoryList("product").then(res => {
      const categories = []
      res.forEach(item => {
        categories.push({label: item.name, value: item._id})
      })
      this.setState({categoryList: categories, isLoading: false})
    }).catch(errMsg => {
      console.log(errMsg)
      this.setState({isLoading: false})
    })

    getMerchantList(this.state.defaultQuerySettings.merchantList).then(res => {
      const merchantList = []
      res.forEach(item => {
        merchantList.push({id: item._id, value: item._id, label: item.name})
      });
      this.setState({merchantList: merchantList, isLoading: false})
    })

    getStoreList(this.state.defaultQuerySettings.storeList).then(res => {
      const stores = []
      res.items.forEach(item => {
        stores.push({label: item.store_name, value: item._id})
      })
      this.setState({storeList: stores})
    })

    getProductListSecondary(this.state.defaultQuerySettings.productList).then(res => {
      this.setState({
        products: res.products,
        productPagination: res.pagination,
        limit: 20
      })
    })
  }

  handleInputOnChange = (e) => {
    const formData = {...this.state.formData}
    formData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({formData})
  }

  handleStoreOnChange = (value) => {
    const formData = {...this.state.formData}

    /*if (value?.length > 0) {
      const stores = []

      value.forEach(item => {
        stores.push(item.value)
      })
      formData.stores = stores

      this.setState({formData}, () => {
        getProductListAlt({store_id: stores[0]})
          .then(res => {
            this.setState({products: res})
          })
      })
    } else {
      getProductListAlt()
        .then(res => {
          formData.stores = []
          this.setState({products: res, formData})
        })
    }*/

    getProductListAlt({sid: value.value}).then(res => {
      formData.stores = [value.value]
      this.setState({products: res, formData, isLoading: false})
    })
  }

  handleShowSelectProductModal = () => {
    this.setState({isProductLoading: true}, () => {
      getProductListSecondary({
        merchants: JSON.stringify(this.state.formData?.merchants || []),
        stores: JSON.stringify(this.state.formData?.stores || []),
        categories: JSON.stringify(this.state.formData?.categories || [])
      }).then(res => {
        this.setState({
          products: res.products,
          productPagination: res.pagination,
          isProductModalProductShowing: true,
          isProductLoading: false
        })
      })
        .catch(err => {
          this.setState({isProductLoading: false})
        })
    })

  }

  handleAddProduct = (selectedProducts) => {
    console.log(selectedProducts)
    const formData = {...this.state.formData}
    const products = []

    selectedProducts.forEach(item => {
      const product = this.state.products.filter(obj => {
        return obj._id === item
      })
      products.push(product[0])
    })

    formData.products = selectedProducts
    console.log("products");
    console.log(products);
    this.setState({
      selectedProductList: products,
      formData,
      isProductModalProductShowing: false,
      selectedProducts: []
    })
  }

  handleSelectOnChange = (name, value) => {
    const formData = {...this.state.formData}
    const items = []

    if (value?.length > 0) {
      value.forEach(item => {
        items.push(item.value)
      })
    }

    formData[name] = items

    this.setState({formData}, () => {
      if (name === 'merchants') {
        getStoreList({
          ...this.state.defaultQuerySettings.storeList,
          merchants: JSON.stringify(this.state.formData.merchants || [])
        })
          .then(res => {
            const stores = []
            res.items.forEach(item => {
              stores.push({
                label: item.store_name,
                value: item._id
              })
            })
            this.setState({storeList: stores})
          })
      } else if (name === 'merchant_types') {
        getMerchantList({
          ...this.state.defaultQuerySettings.merchantList,
          merchant_types: JSON.stringify(this.state.formData.merchant_types)
        }).then(res => {
          const merchantList = []
          res.forEach(item => {
            merchantList.push({id: item._id, value: item._id, label: item.name})
          })
          this.setState({merchantList: merchantList, isLoading: false})
        })
      }
    })
  }

  handleSelectedProductOnChange = (e, id) => {
    const selectedProducts = [...this.state.selectedProducts];

    if (e.target.checked) {
      if (e.target.type === 'checkbox') {
        selectedProducts.push(id);
      } else {
        selectedProducts[0] = id;
      }
    } else {
      selectedProducts.splice(selectedProducts.indexOf(id), 1);
    }

    this.setState({selectedProducts});
  }

  handleRemoveSelectedProduct = (index) => {
    const selectedProductList = [...this.state.selectedProductList]
    const formData = {...this.state.formData}
    selectedProductList.splice(index, 1);
    formData.products.splice(index, 1);
    this.setState({selectedProductList, formData})
  }

  handleHideProductModal = () => {
    this.setState({isProductModalProductShowing: false})
  }

  handleSubmitForm = (e) => {
    e.preventDefault()
    this.setState({isSubmitting: true}, () => {
      addCommissionSettings(this.state.formData)
        .then(res => {
          this.props.history.push(`/commission-settings/custom-settings`)
          // TODO: It will be updated
          // toast.success(res.message)
          this.setState({isSubmitting: false})
          toast.success('Commission setting successfully added')
          activityLogPostData('was create new commission setting','/commission-settings/custom-settings/',e.target.admin_commission_percentage)
        })
        .catch(errMsg => {
          toast.error(errMsg)
          this.setState({isSubmitting: false})
        })
    })
  }

  handleMerchantTypeOnChange = (e) => {
    const value = e.target.value
    this.setState({merchants: [], merchantList: []})
    getMerchantList({/*merchant_type: value*/})
      .then(res => {
        const merchantList = []
        const formData = {...this.state.formData}
        res.forEach(item => {
          const merchant = {
            id: item._id,
            value: item._id,
            label: item.name
          }
          merchantList.push(merchant)
        })
        formData.merchant_type = value
        formData.merchants = []
        this.setState({merchants: res, merchantList, formData})
      })
  }

  handleSearchProduct = (val) => {
    getProductListSecondary({
      merchants: JSON.stringify(this.state.formData?.merchants || []),
      stores: JSON.stringify(this.state.formData?.stores || []),
      categories: JSON.stringify(this.state.formData?.categories || []),
      limit: 20,
      search_string: val
    }).then(res => {
      this.setState({
        products: res.products,
        productPagination: res.pagination
      }, () => console.log(this.state));
    })
  }

  render() {
    return (
      <App layout={`boxed`}>
        <div className="page-header">
          <div/>
          <div className="ml-auto">
            <Link to={`${process.env.PUBLIC_URL}/commission-settings/custom-settings`}><IoIosArrowRoundBack/> Back to
              List</Link>
          </div>
        </div>


        {getLocalAuthData()?.permission?.includes('custom_setting_add') ?
            <>
              <div className="card-block">
                <div className="block-header">
                  <h4 className="block-title">Commission Settings Details</h4>
                </div>

                <div className="block-body">
                  {this.state.isLoading ?
                      <div>Loading...</div>
                      :
                      <Form onSubmit={this.handleSubmitForm}>
                        {/*<Form.Group as={Row} controlId="formStore">
                  <Form.Label column sm="3">
                    Commission (%) <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control type={`number`} max={100} min={0}
                                  name={`value`}
                                  onChange={this.handleInputOnChange}
                                  required placeholder={`Enter commission value`}/>
                  </Col>
                </Form.Group>*/}

                        <Form.Group as={Row} controlId="formAdminCommission">
                          <Form.Label column sm="3">
                            Admin Commission (%) <span className="text-danger">*</span>
                          </Form.Label>

                          <Col sm="4" md={4} lg={3}>
                            <Form.Control type={`number`}
                                          onChange={this.handleInputOnChange}
                                          defaultValue={this.state.formData?.admin_commission_percentage}
                                          name={'admin_commission_percentage'}
                                          min={0} max={100} step={.1} required placeholder={`Enter commission percentage`}/>
                          </Col>

                          <Col sm="4" md={4} lg={3}>
                            <Form.Control type={`number`}
                                          onChange={this.handleInputOnChange}
                                          defaultValue={this.state.formData?.admin_max_commission_value}
                                          name={'admin_max_commission_value'}
                                          min={0} placeholder={`Enter max commission value`}/>
                          </Col>

                        </Form.Group>

                        <Form.Group as={Row} controlId="formMerchantCommission">
                          <Form.Label column sm="3">
                            Merchant Commission (%) <span className="text-danger">*</span>
                          </Form.Label>

                          <Col sm="4" md={4} lg={3}>
                            <Form.Control type={`number`}
                                          onChange={this.handleInputOnChange}
                                          defaultValue={this.state.formData?.merchant_commission_percentage}
                                          name={'merchant_commission_percentage'}
                                          min={0} max={100} step={.1} required placeholder={`Enter commission percentage`}/>
                          </Col>

                          <Col sm="4" md={4} lg={3}>
                            <Form.Control type={`number`}
                                          onChange={this.handleInputOnChange}
                                          defaultValue={this.state.formData?.merchant_max_commission_value}
                                          name={'merchant_max_commission_value'}
                                          min={0} placeholder={`Enter max commission value`}/>
                          </Col>

                        </Form.Group>


                        <Form.Group as={Row} controlId="formEnableOverride">
                          <Form.Label column sm="3">Override Other Setting</Form.Label>
                          <Col sm="9" md={8} lg={7}>
                            <Form.Check
                                custom inline type="checkbox" name="enable_override" id={`enableOverride`}
                                label={``} value={true}
                                onChange={this.handleInputOnChange}
                                checked={this.state.formData?.enable_override}
                            />
                          </Col>
                        </Form.Group>


                        {/*{this.state.merchantTypes.length > 0 &&
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Merchant Type
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    {this.state.merchantTypes.map((type, index) => {
                      return (
                        <Form.Check
                          key={index}
                          custom
                          type="radio"
                          name="merchant_type"
                          value={type._id}
                          id={`merchantType-${index}`}
                          label={type.type_name}
                          onChange={this.handleMerchantTypeOnChange}
                        />
                      )
                    })
                    }
                  </Col>
                </Form.Group>
                }*/}

                        <Form.Group as={Row} controlId="formMerchantType">
                          <Form.Label column sm="3">
                            Merchant Types
                          </Form.Label>
                          <Col sm="9" md={8} lg={7}>
                            <Select
                                isMulti
                                styles={styleReactSelect}
                                placeholder="Select Merchant Type..."
                                onChange={(value) => {
                                  this.handleSelectOnChange('merchant_types', value)
                                }}
                                options={this.state.merchant_types}
                                name="merchant_types"
                                noOptionsMessage={() => 'Select Merchant Type...'}
                            />
                          </Col>
                        </Form.Group>

                        {this.state.merchantList.length > 0 &&
                        <Form.Group as={Row} controlId="formMerchant">
                          <Form.Label column sm="3">
                            Merchants
                          </Form.Label>
                          <Col sm="9" md={8} lg={7}>
                            <Select
                                isMulti
                                styles={styleReactSelect}
                                placeholder="Select merchant..."
                                onChange={(value) => {
                                  this.handleSelectOnChange('merchants', value)
                                }}
                                options={this.state.merchantList}
                                name="merchants"
                                noOptionsMessage={() => 'Please insert new value...'}
                            />
                          </Col>
                        </Form.Group>
                        }

                        {this.state?.storeList?.length > 0 &&
                        <Form.Group as={Row} controlId="formStore">
                          <Form.Label column sm="3">
                            Stores
                          </Form.Label>
                          <Col sm="9" md={8} lg={7}>
                            <Select
                                isMulti
                                styles={styleReactSelect}
                                placeholder="Select store..."
                                onChange={(value) => this.handleSelectOnChange('stores', value)}
                                options={this.state.storeList}
                                name="stores"
                                noOptionsMessage={() => 'Please insert new value...'}
                            />
                          </Col>
                        </Form.Group>
                        }


                        <Form.Group as={Row} controlId="formCategory">
                          <Form.Label column sm="3">
                            Categories
                          </Form.Label>
                          <Col sm="9" md={8} lg={7}>
                            <Select
                                isMulti
                                styles={styleReactSelect}
                                placeholder="Select category..."
                                onChange={(value) => {
                                  this.handleSelectOnChange('categories', value)
                                }}
                                options={this.state.categoryList}
                                name="categories"
                                noOptionsMessage={() => 'Select category...'}
                            />
                          </Col>
                        </Form.Group>

                        <Modal show={this.state.isProductModalProductShowing}
                               onHide={this.handleHideProductModal}>
                          <Modal.Header closeButton>
                            <Modal.Title>Select Product</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="mb-3 form-inline">
                              <Form.Group>
                                <Form.Label srOnly>Search</Form.Label>
                                <Form.Control type={`search`}
                                              size={`sm`}
                                              onChange={(e) => {
                                                this.setState({productSearchString: e.target.value})
                                              }}
                                              placeholder={`Search...`}/>
                              </Form.Group>

                              <Button type={`button`} size={`sm`} variant={`info`}
                                      onClick={() => {
                                        this.handleSearchProduct(this.state.productSearchString || '')
                                      }} className={`ml-2`}>Search</Button>
                            </div>

                            {this.state.products.length < 1 &&
                            <div className="alert alert-warning text-bold text-center">
                              no product found
                            </div>}

                            {this.state.products.length > 0 &&
                            <>
                              <div className="product-checkbox-list">
                                {this.state.products.map((product, index) => (
                                    <label className={`product-item`} key={this.state.productPagination?.pagingCounter + index} htmlFor={`product-${index}`}>
                                      <input id={`product-${index}`}
                                             onChange={e => this.handleSelectedProductOnChange(e, product._id)}
                                             name={`product`}
                                             type="checkbox"/>
                                      <span className={`product`}>
                              <span className="product-img">
                                <img src={product.thumb_img} alt=""/>
                              </span>
                              <span className="product-short-info">
                                <span className="product-name">{product.name}</span>
                              </span>
                              <span className="icon"><IoMdCheckmark/></span>
                            </span>
                                    </label>
                                ))}
                              </div>

                              <br/>

                              <Pagination
                                  itemClass="page-item"
                                  linkClass="page-link"
                                  activePage={this.state.productPagination?.page}
                                  itemsCountPerPage={this.state.productPagination?.limit}
                                  totalItemsCount={this.state.productPagination?.totalDocs}
                                  onChange={(page) => {
                                    getProductListSecondary({
                                      merchants: JSON.stringify(this.state.formData?.merchants || []),
                                      stores: JSON.stringify(this.state.formData?.stores || []),
                                      categories: JSON.stringify(this.state.formData?.categories || []),
                                      page: page,
                                      limit: 20
                                    }).then(res => {
                                      this.setState({
                                        products: res.products,
                                        productPagination: res.pagination
                                      }, () => console.log(this.state));
                                    })
                                  }}
                              />
                            </>
                            }
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleHideProductModal}>
                              Close
                            </Button>
                            <Button variant="primary"
                                    onClick={() => this.handleAddProduct(this.state.selectedProducts)}>
                              Save Changes
                            </Button>
                          </Modal.Footer>
                        </Modal>

                        {
                          <Form.Group as={Row} controlId="formProducts">
                            <Form.Label column sm="3">Products</Form.Label>
                            <Col sm="9" md={8} lg={7}>
                              <table className={`table table-striped table-bordered`}>
                                <thead className={`sm`}>
                                <tr>
                                  <th style={{width: '50px'}}/>
                                  <th>Product Name</th>
                                  <th style={{width: '1%'}}/>
                                </tr>
                                </thead>

                                <tbody>
                                {this.state.selectedProductList.length > 0 ?
                                    <>
                                      {this.state.selectedProductList.map((product, index) => (
                                          <tr key={index}>
                                            <td>
                                              <img src={product?.thumb_img} style={{width: '40px'}} alt=""/>
                                            </td>
                                            <td>{product?.name}</td>
                                            <td><span style={{cursor: 'pointer'}}
                                                      onClick={() => this.handleRemoveSelectedProduct(index)}
                                                      className={`text-bold text-danger`}>&times;</span></td>
                                          </tr>
                                      ))}
                                    </>
                                    :
                                    <tr>
                                      <td colSpan={3} className={`text-center text-bold`}>
                                        No product selected
                                      </td>
                                    </tr>
                                }
                                </tbody>
                              </table>

                              <Button size="sm"
                                      disabled={this.state.isProductLoading}
                                      onClick={() => this.handleShowSelectProductModal()}
                                      variant="link"> {!this.state.isProductLoading ? 'Select Products' : 'Please wait...'}</Button>
                            </Col>
                          </Form.Group>
                        }

                        <Form.Group as={Row} controlId="formSave">
                          <Form.Label column sm="3"/>
                          <Col sm="9" md={8} lg={7}>
                            <Button type={`submit`}
                                    disabled={this.state.isSubmitting}
                                    variant={'primary'}>{this.state.isSubmitting ? 'Saving...' : 'Save'}</Button>
                          </Col>
                        </Form.Group>
                      </Form>
                  }
                </div>
              </div>
            </>
            : null}


      </App>
    );
  }
}

export default withRouter(AddCustomCommissionSettings);
