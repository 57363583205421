import React, { Component, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../assets/scss/messages.scss";
import {
  FiMessageSquare,
  FiPlus,
  FaPaperPlane,
  FaRegUserCircle,
} from "react-icons/all";
import {getDetail, getTableData, postData, updateItemPartial} from "../../services/commonServices";
import hiLogo from "../../assets/img/hi.png";
import moment from "moment";
import App from "../../App";
import { getLocalAuthData } from "../../helper/utils";

const Messages = () => {
  const [chatList, setChatList] = useState([]);
  const [messages, setMessages] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState(localStorage.getItem("pairId"));
  const [writtenText, setWrittenText] = useState("");
  const [receiverData, setReceiverData] = useState(null);
  const [isChatsLoading, setIsChatsLoading] = useState(true);
  const [isMessagesLoading, setIsMessagesLoading] = useState(true);
  const authData = getLocalAuthData();
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const pairId = localStorage.getItem("pairId") || null;
    getDetail(`/paired`)
      .then((res) => {
        setChatList(res.items);
        if (pairId) {
          const selectedChat = res.items.find((chat) => chat._id === pairId);
          const currentUsers = [selectedChat.user1, selectedChat.user2];
          const receiver = currentUsers.find(
            (user) => user._id !== authData._id
          );
          setReceiverData(receiver);
          handleChatOnClick(pairId);
          setIsChatsLoading(false);
        }
      })
      .catch((err) => console.log(err));
  }, [selectedChatId]);


  useEffect(() => {

    const interval = setInterval(() => {

      const pairId = localStorage.getItem("pairId") || null;
      getDetail(`/paired`)
          .then((res) => {
            setChatList(res.items);
            if (pairId) {
              const selectedChat = res.items.find((chat) => chat._id === pairId);
              const currentUsers = [selectedChat.user1, selectedChat.user2];
              const receiver = currentUsers.find(
                  (user) => user._id !== authData._id
              );
              setReceiverData(receiver);
              handleChatOnClick(pairId);
              setIsChatsLoading(false);
            }
          })
          .catch((err) => console.log(err));

    }, 5000);
    return () => clearInterval(interval);


  }, [selectedChatId]);

  useEffect(() => {
    const interval = setInterval(() => {
      const pairId = localStorage.getItem("pairId") || null;
      handleChatOnClick(pairId)
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const updatePair = () =>{
    updateItemPartial('/chat/'+localStorage.getItem("pairId")).then((res)=>{
       console.log(res)
     })
  }


  const scrollToBottom = () => {
    // eslint-disable-next-line no-unused-expressions
    messagesEndRef.current?.scrollIntoView({block: "end", behavior: "smooth", inline: 'nearest' });
  }

  const handleMessageSend = () => {
    const data = {
      pair_id: selectedChatId,
      message: writtenText,
      type: "general",
    };

    postData(`/chat`, data)
      .then((res) => {
        const localMessages = messages;
        localMessages.push(res.chat);
        setMessages(localMessages);
        setWrittenText("");
        scrollToBottom();
      })
      .catch((err) => console.log(err));
  };

  const messageFieldOnChange = (e) => {
    setWrittenText(e.target.value);
  };

  const handleChatOnClick = (pairId) => {
    console.log(pairId);
    setSelectedChatId(pairId);
    localStorage.setItem("pairId", pairId);

    getDetail(`/chat/${pairId}`)
      .then((res) => {
        setMessages(res.chats || []);
        setIsMessagesLoading(false);
        scrollToBottom();
      })
      .catch((err) => console.log(err));
  };

  console.log("selectedChatId",selectedChatId)

  return (
    <App>
      <div className="main-content">
        <Container>
          <>
            <h4 className="mb-3">Ekshop Messaging</h4>
            <div className="section-block">
              <div className="block-body">
                <div className="message-block">
                  <div className="left-part">
                    <div className="part-header">
                      <span className="icon">
                        <FiMessageSquare />
                      </span>
                      <span className="text">Messages</span>
                    </div>

                    <div className="messenger-list">
                      {chatList &&
                        chatList.map((chat) => (
                          <div
                            className={`messenger ${
                              selectedChatId === chat._id ? "active" : ""
                            }`}
                            onClick={() => {
                              updatePair()
                              handleChatOnClick(chat?._id);
                            }}
                          >
                            <div className="avatar">
                              <img
                                src="https://static-01.daraz.com.bd/other/shop/745f0ca2a0eb5672da1b870ea3d09af7.jpeg"
                                alt=""
                              />
                            </div>
                            <div className="desc">
                              {chat.user1._id !== authData._id && (<>
                                    {chat.user2.unread_message_counter > 0 ?
                                        <h4 style={{fontWeight:'bold'}} className="name">{chat.user1.name}
                                        </h4>
                                        :
                                        <span className="name">{chat.user1.name}
                                        </span>}
                                  </>
                              )}
                              {chat.user2._id !== authData._id && (<>
                              {chat.user1.unread_message_counter > 0 ?
                                  <h4 style={{fontWeight:'bold'}}  className="name">{chat.user2.name}</h4>
                                  :
                                  <span className="name">{chat.user2.name}
                                        </span>}
                                </>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="right-part">
                    <div className="part-header">
                      <FaRegUserCircle /> {receiverData?.name || ""}
                    </div>
                    <div
                      // ref={el => this.scrollToBottom(el)}
                      className="message-body"
                    >
                      {messages.length > 0 ? (
                        <ul className="message-list">
                          {messages.map((message) =>
                            message.sender === authData.merchant_id ||
                            message.sender === authData._id ? (
                              <li className="msg-to">
                                <div className="msg-details">
                                  <span className="msg">
                                    <span className="text">
                                      {message.message}
                                    </span>
                                  </span>
                                  <span className="time">
                                    {moment(message.createdAt).fromNow()}
                                  </span>
                                </div>
                              </li>
                            ) : (
                              <li className="msg-from">
                                <div className="avatar">
                                  <img
                                    src="https://static-01.daraz.com.bd/other/shop/745f0ca2a0eb5672da1b870ea3d09af7.jpeg"
                                    alt=""
                                  />
                                </div>

                                <div className="msg-details">
                                  <span className="msg">
                                    <span className="text">
                                      {message.message}
                                    </span>
                                  </span>
                                  <span className="time">
                                    {moment(message.createdAt).fromNow()}
                                  </span>
                                </div>
                              </li>
                            )
                          )}

                          {/* <li className="msg-from">
                        </li><div className="avatar">
                        <img
                              src="https://static-01.daraz.com.bd/other/shop/745f0ca2a0eb5672da1b870ea3d09af7.jpeg"
                              alt=""
                            />
                          </div>

                          <div className="msg-details">
                            <span className="msg">
                              <span className="text">
                                Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit.
                              </span>
                              <span className="images">
                                <img
                                  src="https://images.pexels.com/photos/3105920/pexels-photo-3105920.png?auto=compress&cs=tinysrgb&dpr=1&w=500"
                                  alt=""
                                  className="img"
                                />
                                <img
                                  src="https://images.pexels.com/photos/3989816/pexels-photo-3989816.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                                  alt=""
                                  className="img"
                                />
                                <img
                                  src="https://images.pexels.com/photos/1667088/pexels-photo-1667088.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                                  alt=""
                                  className="img"
                                />
                              </span>
                            </span>
                            <span className="time">1 day ago</span>
                          </div>
                        </li> */}
                        </ul>
                      ) : (
                        <div className="d-flex flex-column align-items-center justify-content-center h-100">
                          {receiverData !== null ? (
                            <span className="text-center">
                              Start a conversation with{" "}
                              <span className="font-weight-bold">
                                {receiverData.name}
                              </span>
                            </span>
                          ) : (
                            <span className="text-center">
                              Welcome to ekshop messaging!! <br /> Start a
                              conversation
                            </span>
                          )}

                          <img className="hi-img mt-3" src={hiLogo} alt="hi" />
                        </div>
                      )}
                       <div style={{position: 'relative'}} ref={messagesEndRef} />
                    </div>
                    <div className="write-message">
                     {/* <label htmlFor="uploadImg">
                        <input type="file" id="uploadImg" />
                        <FiPlus />
                      </label>*/}
                      <input
                        onChange={messageFieldOnChange}
                        placeholder="Write your message..."
                        id
                        type="text"
                        value={writtenText}
                        onKeyPress={(e) => {
                          if (e.charCode === 13 || e.code === "NumpadEnter") {
                            handleMessageSend();
                          }
                        }}
                        disabled={receiverData === null}
                      />

                      <button className="btn-send" onClick={handleMessageSend}>
                        <FaPaperPlane />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Container>
      </div>
    </App>
  );
};

export default Messages;
