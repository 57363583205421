import React, {Component} from 'react';
import "../assets/scss/category.scss";
import {Link} from "react-router-dom";
import 'react-sortable-tree/style.css';
import SortableTree, {toggleExpandedForAll} from "react-sortable-tree";
import {Form ,Button} from "react-bootstrap";
import {deleteLocation, getLocationList} from "../services/locationServices";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import {getLocalAuthData} from "../helper/utils"
import {getData} from "../services/baseServices";
import {AiOutlinePlusCircle} from "@react-icons/all-files/ai/AiOutlinePlusCircle";

//https://openbase.io/js/react-sortable-tree

class LocationTree extends Component {
  state = {
    searchString: "",
    searchFocusIndex: 0,
    searchFoundCount: 0,
    treeData: [],
    isLoading: false,
    previousId: [],
    currentId: null
  };

  componentDidMount() {
    this.setState({isLoading: true})

    this.getLocationList()
  }

  getLocationList = ()=>{
    getLocationList({type: 'Country'})
        .then(res => {
          console.log('Location: ', res)
          this.setState({treeData: this.createParsableData(res), isLoading: false}, () => {
            console.log('length: ', this.state.treeData.length)
          })
        })
        .catch(errMsg => {
          this.setState({isLoading: false})
          console.log('Location fetching error: ', errMsg);
        })
  }

  createParsableData = (data) => {
    let items = [];
    if (data.length > 0) {
      data.forEach((item) => {
        items.push({
          id: item._id,
          title: item.name,
          children: item?.child_locations?.length > 0 ? this.createParsableData(item.child_locations) : []
        })
      });
    }
    return items;
  }

  handleTreeOnChange = treeData => {
    //console.log(treeData)
    this.setState({treeData});
  };

  handleSearchOnChange = e => {
    this.setState({
      searchString: e.target.value
    });
  };

  handleLocationDelete = (id) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to delete this location?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteLocation(id)
            .then(res => {
              toast.success(res.message);
              getLocationList()
                .then(res => {
                  this.setState({treeData: this.createParsableData(res)})
                })
            })
            .catch(errMsg => {
              toast.error(errMsg)
            })
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }

  /*selectPrevMatch = () => {
    const {searchFocusIndex, searchFoundCount} = this.state;

    this.setState({
      searchFocusIndex:
        searchFocusIndex !== null
          ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
          : searchFoundCount - 1
    });
  };*/

  /*selectNextMatch = () => {
    const {searchFocusIndex, searchFoundCount} = this.state;

    this.setState({
      searchFocusIndex:
        searchFocusIndex !== null
          ? (searchFocusIndex + 1) % searchFoundCount
          : 0
    });
  };*/

  toggleNodeExpansion = expanded => {
    this.setState(prevState => ({
      treeData: toggleExpandedForAll({treeData: prevState.treeData, expanded})
    }));
  };

  getChildLocaltion =(id,isPush )=>{
    if (id === undefined){
      this.getLocationList()
    }else {
      getData(`/location/child-location/${id}`).then((res)=>{
        if (res.locations.length > 0){
          this.setState({treeData: this.createParsableData(res.locations), isLoading: false}, () => {
            console.log('length: ', this.state.treeData.length)
            if (isPush){
              this.state.previousId.push(id)
            }

          })
        }
      }).catch((err)=>{
      })
    }
  }


  render() {
    const {
      treeData,
      searchString,
      searchFocusIndex,
      // searchFoundCount
    } = this.state;

    return (
      <div>
        <div className="bar-wrapper">
          <Form inline>
            <Form.Group controlId={"searchField"}>
              <Form.Label className={"mr-2"}>Search </Form.Label>
              <Form.Control size="sm"
                            onChange={this.handleSearchOnChange}
                            placeholder={"Search"}/>
            </Form.Group>

            <span className="text-primary ml-4 mr-4"
                  style={{cursor: 'pointer'}}
                  onClick={this.toggleNodeExpansion.bind(this, true)}>Expand all</span>
            <span className="text-info"
                  style={{cursor: 'pointer'}}
                  onClick={this.toggleNodeExpansion.bind(this, false)}>Collapse all</span>
            <span className="text-primary ml-4 mr-4"
                  style={{cursor: 'pointer'}}
                  onClick={()=>{
                    let chars = this.state.previousId;
                    let uniqueChars = [...new Set(chars)];
                    this.setState({
                      previousId: uniqueChars
                    },()=>{
                      this.state.previousId.pop();
                      this.getChildLocaltion(this.state.previousId[this.state.previousId.length-1],false)
                    })

                  }}>Back</span>
          </Form>
        </div>

        <hr/>

        {this.state.isLoading ?
          <div>Loading...</div>
          :
          (getLocalAuthData()?.permission?.includes('location_list') && treeData.length > 0) ?
            <div style={{height: 520}}>
              <SortableTree
                canDrag={false}
                treeData={treeData}
                onChange={this.handleTreeOnChange}
                searchQuery={searchString}
                searchFocusOffset={searchFocusIndex}
                searchFinishCallback={matches =>
                  this.setState({
                    searchFoundCount: matches.length,
                    searchFocusIndex:
                      matches.length > 0 ? searchFocusIndex % matches.length : 0
                  })
                }
                generateNodeProps={(rowInfo) => ({
                  buttons: [

                      <AiOutlinePlusCircle  style={{fontSize: '.8rem', textTransform: 'uppercase', letterSpacing: '.03rem'}}
                                            className="text-primary mr-3"
                                            onClick={()=>{

                                              this.getChildLocaltion(rowInfo.node.id,true)

                                            }}/>
                 ,
                    <Link
                      style={{fontSize: '.8rem', textTransform: 'uppercase', letterSpacing: '.03rem'}}
                      className="text-primary mr-3"
                      to={`${process.env.PUBLIC_URL}/locations/edit/${rowInfo.node.id}`}> Edit
                    </Link>,
                    <span
                      style={{
                        fontSize: '.8rem',
                        textTransform: 'uppercase',
                        cursor: 'pointer',
                        letterSpacing: '.03rem'
                      }}
                      onClick={() => this.handleLocationDelete(rowInfo.node.id)}
                      className="text-danger mr-2"> Delete
                    </span>
                  ]
                })}/>
            </div>
            :
              <>{getLocalAuthData()?.permission?.includes('location_add') ?
                  <div className={"alert alert-warning text-center"}>
                    <strong>No location added yet.</strong> Please add <Link to={`${process.env.PUBLIC_URL}/locations/add`}>New Location</Link>
                  </div>
                  :null}</>

        }
      </div>
    );
  }
}

export default LocationTree;
