import React, {useEffect, useState} from "react";
import { FiPlus, FiMinus } from "react-icons/all";
import "../assets/scss/quantity-counter.scss";

const QuantityCounter = ({ maxValue, handleOnChange, size, value }) => {
  let [initialValue, setInitialValue] = useState(1);

  useEffect(() => {
    setInitialValue(value || 1)
  }, [value])

  const handleQntChanger = val => {
    let qnt;

    if (val < 1) {
      qnt = initialValue === 1 ? (initialValue = 1) : initialValue + val;
    } else {
      qnt = initialValue + val;
    }

    if (maxValue !== undefined && qnt > parseInt(maxValue)) {
      qnt = parseInt(maxValue)
      alert(12)
    }

    setInitialValue(qnt);
    handleOnChange && handleOnChange(qnt);
  };

  return (
    <div className={size === 'sm' ? 'quantity-counter quantity-counter-sm' : 'quantity-counter'}>
      <button disabled={initialValue === 1} onClick={() => handleQntChanger(-1)} type="button">
        <FiMinus />
      </button>
      <input
        readOnly={true}
        className="quantity"
        onChange={() => null}
        value={initialValue}
      />
      <button
        disabled={maxValue !== undefined && initialValue >= parseInt(maxValue)}
        onClick={() => handleQntChanger(1)} type="button">
        <FiPlus />
      </button>
    </div>
  );
};

export default QuantityCounter;
