import {getLocalAuthData} from "../helper/utils";
import axios from "axios";
import {API_BASE_URL} from "../helper/env";
import {getDeviceType} from "../helper/utils";

export const getDisputes = (params = {}) => {
    const authData = getLocalAuthData();
    const defaultData = {
        user_id: authData._id || ''
    }
    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}/dispute/list`, {
            headers: {
                'x-auth-token': authData?.token,
                'x-api-client': getDeviceType()
            },
            params: params
        })
            .then(res => {
                if (res.data.status === 1) {
                    resolve(res.data)
                } else {
                    reject(res.data.message)
                }
            })
            .catch(err => {
                reject(err.message)
            })
    })
}

export const getDisputeDetail = (id, params = {}) => {
    const authData = getLocalAuthData();
    const defaultData = {
        user_id: authData._id || ''
    }
    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}/dispute/${id}`, {
            headers: {
                'x-auth-token': authData?.token,
                'x-api-client': getDeviceType()
            },
            params: params
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.message)
        })
    })
}


export const disputeResolve = (dataObj = {}) => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
        axios.post(`${API_BASE_URL}/dispute/resolve`, dataObj, {
            headers: {
                'x-auth-token': authData?.token,
                'x-api-client': getDeviceType()
            }
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.message)
        })
    })
}