import React, { Component } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { FiChevronRight, FiMove, FiSettings, FiTrash, IoMdCheckmark } from "react-icons/all";
import "../assets/scss/business-block-card.scss";
import { isEmptyObj } from "../helper/utils";
import {getDetail, updateItem} from "../services/commonServices";
import { getActiveProductList } from "../services/productServices";
import HomeProduct from "./HomeProduct";
import LoadingSpinner from "./LoadingSpinner";
import SectionSlide from "./SectionSlide";
import {toast} from "react-toastify";

// noinspection DuplicatedCode,JSUnresolvedVariable
class ProductsBlock extends Component {
  state = {
    isSettingModalShowing: false,
    showSelectTabModal: false,
    settingData: {params: {}},
    productList: [],
    customProductList: [],
    tabProductList: [],
    selectedRecommendedProductList: [],
    selectedBestProductList: [],
    selectedNewProductList: [],
    selectedSaleProductList: [],
    isDataFetching: false,
    isConfigured: false,
    tabProductModalShow: false,
    customProductQuery: '',
    selectedTabId: ''
  }

  componentDidMount() {
    if (!isEmptyObj(this.props?.settings)) {
      let obj = {
        isDataFetching: false,
        isConfigured: true,
        settingData: this.props.settings,
        selectedCategoryId: ""
      };
      if (this.props?.settings?.categories?.length > 0) {
        obj.selectedCategoryId = this.props?.settings?.categories[0]
      }
      this.setState(obj, () => {
        getDetail('/front/shopping-mall-section', {setting_id: this.props.setting_id}).then(res => {
          this.setState({isDataFetching: false})
          this.setState({selectedTabId: "recommended_product"});
          this.setState(
            {
              selectedRecommendedProductList: res.recommended_products ?? [],
              selectedBestProductList: res.best_products ?? [],
              selectedNewProductList: res.new_products ?? [],
              selectedSaleProductList: res.sale_products ?? [],
              sliders: res.sliders ?? [],
            }
          )
        }).catch(() => {
          this.setState({isDataFetching: false})
        })
      })

    }
  }

  handleSettingModalShow = () => {
    this.setState({isSettingModalShowing: true})
  }

  handleSettingModalHide = () => {
    this.setState({isSettingModalShowing: false})
  }

  handleSettingInputOnChange = (e) => {
    const settingData = {...this.state.settingData}
    if (e.target.type !== 'checkbox') {
      if (e.target.name === 'title' || e.target.name === 'title_local') {
        settingData[e.target.name] = e.target.value
      } else {
        settingData.params[e.target.name] = e.target.value
      }
    } else {
      if (e.target.checked) {
        settingData.params[e.target.name] = e.target.value
      } else {
        delete settingData.params[e.target.name]
      }
    }
    this.setState({settingData})
  }

  handleOnSaveSettingForm = () => {
    this.props.actions.onChangeSettings(this.state.settingData, () => {
      this.handleSettingModalHide()
      this.setState({isConfigured: true}, () => {

      })
    })
  }

  handleSelectedCategoryOnChange = (e, category) => {
    if (e.target.checked) {
      let selectedCategories = this.state.selectedCategories;
      selectedCategories.push(category)
      this.setState({
        selectedCategories: selectedCategories
      }, () => {

        /* SET CATEGORIES IN SETTING PARAMS */
        const categoryIds = this.state.selectedCategories.map((a) => a._id);
        this.props.settings.categories = categoryIds.filter((item, pos) => categoryIds.indexOf(item) === pos)

        const productIds = this.state.selectedCustomProductList.map((a) => a._id);
        this.props.settings.products = productIds.filter((item, pos) => productIds.indexOf(item) === pos)

        console.log("*********", this.props.settings);
      })
    } else {
      let selectedCategories = this.state.selectedCategories.filter(item => item._id !== category._id);
      this.setState({
        selectedCategories: selectedCategories
      }, () => {

        /* SET CATEGORIES IN SETTING PARAMS */
        const categoryIds = this.state.selectedCategories.map((a) => a._id);
        this.props.settings.categories = categoryIds.filter((item, pos) => categoryIds.indexOf(item) === pos)

        const productIds = this.state.selectedCustomProductList.map((a) => a._id);
        this.props.settings.products = productIds.filter((item, pos) => productIds.indexOf(item) === pos)

        console.log("*********", this.props.settings);
      })
    }
  }

  isCategoryIncluded = (category) => {
    if (this.state.selectedCategories.length === 0)
      return false;
    return this.state.selectedCategories.filter(item => item._id === category._id).length !== 0
  }

  isTrue = () => {
    let isDisabled
    if (typeof (this.props.settings?.is_disabled) !== 'string') {
      isDisabled = this.props?.is_disabled
    } else {
      isDisabled = this.props?.is_disabled === 'true'
    }

    return isDisabled
  }

  handleInputOnChange = (e) => {
    this.setState({isDataFetching: true, customProductQuery: e.target.value}, () => {
      getActiveProductList({search_string: this.state.customProductQuery})
        .then(data => this.setState({customProductList: data.products, isDataFetching: false}))
        .catch(() => this.setState({isDataFetching: false}));
    });
  }

  handleSearchProduct = () => {
    getActiveProductList({search_string: this.state.customProductQuery})
      .then(data => this.setState({customProductList: data.products, isDataFetching: false}))
      .catch(() => this.setState({isDataFetching: false}))
  }

  handleSelectedProductOnChange = (e, product) => {

    if (e.target.checked) {
      if (this.state.selectedTabId === "recommended_product") {


        if (this.state.selectedRecommendedProductList.length < 6) {

          let selectedRecommendedProductList = this.state.selectedRecommendedProductList;
          selectedRecommendedProductList.push(product)
          this.setState({
            selectedRecommendedProductList: selectedRecommendedProductList
          }, () => {
            /* SET PRODUCTS IN SETTING PARAMS */
            const productIds = this.state.selectedRecommendedProductList.map((a) => a._id);
            this.props.settings.recommended_products = productIds.filter((item, pos) => productIds.indexOf(item) === pos)
            console.log("selectedRecommendedProductList>>", this.state.selectedRecommendedProductList);
            console.log("selectedTabId>>", this.state.selectedTabId);
            console.log("settings>>", this.props.settings.recommended_products);

          });

        } else {
          this.setState({add_product_message: "You can add maximum 6 product for this section"});
        }

      } else if (this.state.selectedTabId === "best_sell") {


        if (this.state.selectedBestProductList.length < 6) {

          let selectedBestProductList = this.state.selectedBestProductList;
          selectedBestProductList.push(product)
          this.setState({
            selectedBestProductList: selectedBestProductList
          }, () => {

            /* SET PRODUCTS IN SETTING PARAMS */
            const productIds = this.state.selectedBestProductList.map((a) => a._id);
            this.props.settings.best_products = productIds.filter((item, pos) => productIds.indexOf(item) === pos)

            console.log("selectedBestProductList>>", this.state.selectedBestProductList);
            console.log("selectedTabId>>", this.state.selectedTabId);
            console.log("settings>>", this.props.settings.best_products);
          });

        } else {
          this.setState({add_product_message: "You can add maximum 6 product for this section"});
        }


      } else if (this.state.selectedTabId === "new_arrival") {

        if (this.state.selectedNewProductList.length < 6) {

          let selectedNewProductList = this.state.selectedNewProductList;
          selectedNewProductList.push(product)
          this.setState({
            selectedNewProductList: selectedNewProductList
          }, () => {

            /* SET PRODUCTS IN SETTING PARAMS */
            const productIds = this.state.selectedNewProductList.map((a) => a._id);
            this.props.settings.new_products = productIds.filter((item, pos) => productIds.indexOf(item) === pos)

            console.log("selectedNewProductList>>", this.state.selectedNewProductList);
            console.log("selectedTabId>>", this.state.selectedTabId);
            console.log("settings>>", this.props.settings.new_products);
          });

        } else {
          this.setState({add_product_message: "You can add maximum 6 product for this section"});
        }

      } else if (this.state.selectedTabId === "sale_products") {

        if (this.state.selectedSaleProductList.length < 6) {

          let selectedSaleProductList = this.state.selectedSaleProductList;
          selectedSaleProductList.push(product)
          this.setState({
            selectedSaleProductList: selectedSaleProductList
          }, () => {

            /* SET PRODUCTS IN SETTING PARAMS */
            const productIds = this.state.selectedSaleProductList.map((a) => a._id);
            this.props.settings.sale_products = productIds.filter((item, pos) => productIds.indexOf(item) === pos)

            console.log("selectedSaleProductList>>", this.state.selectedSaleProductList);
            console.log("selectedTabId>>", this.state.selectedTabId);
            console.log("settings>>", this.props.settings.sale_products);
          });

        } else {
          this.setState({add_product_message: "You can add maximum 6 product for this section"});
        }

      }


    } else {

      if (this.state.selectedTabId === "recommended_product") {

          let selectedRecommendedProductList = this.state.selectedRecommendedProductList.filter(item => item._id !== product._id);
          this.setState({
            selectedRecommendedProductList: selectedRecommendedProductList
          }, () => {

            /* SET PRODUCTS IN SETTING PARAMS */
            const productIds = this.state.selectedRecommendedProductList.map((a) => a._id);
            this.props.settings.recommended_products = productIds
            console.log("selectedRecommendedProductList>>", this.state.selectedRecommendedProductList);
            console.log("selectedTabId>>", this.state.selectedTabId);
            console.log("settings>>", this.props.settings.recommended_products);

          });

        // } else {
        //   this.setState({add_product_message: "You can add maximum 6 product for this section"});
        // }

      } else if (this.state.selectedTabId === "best_sell") {

          let selectedBestProductList = this.state.selectedBestProductList.filter(item => item._id !== product._id);
          this.setState({
            selectedBestProductList: selectedBestProductList
          }, () => {

            /* SET PRODUCTS IN SETTING PARAMS */
            const productIds = this.state.selectedBestProductList.map((a) => a._id);
            this.props.settings.best_products = productIds.filter((item, pos) => productIds.indexOf(item) === pos)

            console.log("selectedBestProductList>>", this.state.selectedBestProductList);
            console.log("selectedTabId>>", this.state.selectedTabId);
            console.log("settings>>", this.props.settings.best_products);
          });

        // } else {
        //   this.setState({add_product_message: "You can add maximum 6 product for this section"});
        // }


      } else if (this.state.selectedTabId === "new_arrival") {


          let selectedNewProductList = this.state.selectedNewProductList.filter(item => item._id !== product._id);
          this.setState({
            selectedNewProductList: selectedNewProductList
          }, () => {

            /* SET PRODUCTS IN SETTING PARAMS */
            const productIds = this.state.selectedNewProductList.map((a) => a._id);
            this.props.settings.new_products = productIds.filter((item, pos) => productIds.indexOf(item) === pos)

            console.log("selectedNewProductList>>", this.state.selectedNewProductList);
            console.log("selectedTabId>>", this.state.selectedTabId);
            console.log("settings>>", this.props.settings.new_products);
          });

        // } else {
        //   this.setState({add_product_message: "You can add maximum 6 product for this section"});
        // }

      } else if (this.state.selectedTabId === "sale_products") {

        // if (this.state.selectedSaleProductList.length < 6) {

          let selectedSaleProductList = this.state.selectedSaleProductList.filter(item => item._id !== product._id);
          this.setState({
            selectedSaleProductList: selectedSaleProductList
          }, () => {

            /* SET PRODUCTS IN SETTING PARAMS */
            const productIds = this.state.selectedSaleProductList.map((a) => a._id);
            this.props.settings.sale_products = productIds.filter((item, pos) => productIds.indexOf(item) === pos)

            console.log("selectedSaleProductList>>", this.state.selectedSaleProductList);
            console.log("selectedTabId>>", this.state.selectedTabId);
            console.log("settings>>", this.props.settings.sale_products);
          });

        // } else {
        //   this.setState({add_product_message: "You can add maximum 6 product for this section"});
        // }

      }

    }
  }

  /* TODO : NEED TO CHECK TAB ID */
  isProductIncluded = (product) => {

    if (this.state.selectedTabId === "recommended_product") {
      if (this.state.selectedRecommendedProductList.length > 0) {
        return this.state.selectedRecommendedProductList.filter(item => item._id === product._id).length !== 0
      }
    } else if (this.state.selectedTabId === "best_sell") {
      if (this.state.selectedBestProductList.length > 0) {
        return this.state.selectedBestProductList.filter(item => item._id === product._id).length !== 0
      }
    } else if (this.state.selectedTabId === "new_arrival") {
      if (this.state.selectedNewProductList.length > 0) {
        return this.state.selectedNewProductList.filter(item => item._id === product._id).length !== 0
      }
    } else if (this.state.selectedTabId === "sale_products") {
      if (this.state.selectedSaleProductList.length > 0) {
        return this.state.selectedSaleProductList.filter(item => item._id === product._id).length !== 0
      }
    }

    return false;
  }

  handleTabProduct = (tab_id) => {
    this.setState({selectedTabId: tab_id}, () => {
      console.log("this.props.settings$$$$$", this.props.settings);
    });
  }

  handleDeleteListener = async (product, index , categoryId ) => {

    if (this.state.selectedTabId === "recommended_product") {

      let selectedRecommendedProductList = this.state.selectedRecommendedProductList.filter(item => item._id !== product._id);
      this.setState({
        selectedRecommendedProductList: selectedRecommendedProductList
      }, () => {

        /* SET PRODUCTS IN SETTING PARAMS */
        const productIds = this.state.selectedRecommendedProductList.map((a) => a._id);
        this.props.settings.recommended_products = productIds
        console.log("selectedRecommendedProductList>>", this.state.selectedRecommendedProductList);
        console.log("selectedTabId>>", this.state.selectedTabId);
        console.log("settings>>", this.props.settings.recommended_products);

      });

      // } else {
      //   this.setState({add_product_message: "You can add maximum 6 product for this section"});
      // }

    } else if (this.state.selectedTabId === "best_sell") {

      let selectedBestProductList = this.state.selectedBestProductList.filter(item => item._id !== product._id);
      this.setState({
        selectedBestProductList: selectedBestProductList
      }, () => {

        /* SET PRODUCTS IN SETTING PARAMS */
        const productIds = this.state.selectedBestProductList.map((a) => a._id);
        this.props.settings.best_products = productIds.filter((item, pos) => productIds.indexOf(item) === pos)

        console.log("selectedBestProductList>>", this.state.selectedBestProductList);
        console.log("selectedTabId>>", this.state.selectedTabId);
        console.log("settings>>", this.props.settings.best_products);
      });

      // } else {
      //   this.setState({add_product_message: "You can add maximum 6 product for this section"});
      // }


    } else if (this.state.selectedTabId === "new_arrival") {


      let selectedNewProductList = this.state.selectedNewProductList.filter(item => item._id !== product._id);
      this.setState({
        selectedNewProductList: selectedNewProductList
      }, () => {

        /* SET PRODUCTS IN SETTING PARAMS */
        const productIds = this.state.selectedNewProductList.map((a) => a._id);
        this.props.settings.new_products = productIds.filter((item, pos) => productIds.indexOf(item) === pos)

        console.log("selectedNewProductList>>", this.state.selectedNewProductList);
        console.log("selectedTabId>>", this.state.selectedTabId);
        console.log("settings>>", this.props.settings.new_products);
      });

      // } else {
      //   this.setState({add_product_message: "You can add maximum 6 product for this section"});
      // }

    } else if (this.state.selectedTabId === "sale_products") {

      // if (this.state.selectedSaleProductList.length < 6) {

      let selectedSaleProductList = this.state.selectedSaleProductList.filter(item => item._id !== product._id);
      this.setState({
        selectedSaleProductList: selectedSaleProductList
      }, () => {

        /* SET PRODUCTS IN SETTING PARAMS */
        const productIds = this.state.selectedSaleProductList.map((a) => a._id);
        this.props.settings.sale_products = productIds.filter((item, pos) => productIds.indexOf(item) === pos)

        console.log("selectedSaleProductList>>", this.state.selectedSaleProductList);
        console.log("selectedTabId>>", this.state.selectedTabId);
        console.log("settings>>", this.props.settings.sale_products);
      });

      // } else {
      //   this.setState({add_product_message: "You can add maximum 6 product for this section"});
      // }

    }


    this.deleteProduct(categoryId , product._id)
  }

  deleteProduct =(categoryId , productId)=>{
    updateItem('/front/categories-with-products/'+categoryId+'/'+productId , {}).then((res)=>{
      console.log(res)
      if (res.status === 1){
        toast.success(res.message)
        window.location.href ='/homepage-management'
      }else {
        //toast.warning(res.message)
      }
    })
  }


  render() {
    const {settings, handler, actions, index, dragHandleProps} = this.props;

    return (
      <div className="card-block position-relative">
        {this.state.isDataFetching &&
        <LoadingSpinner/>
        }
        <div className="block-header">
          <h4 className="block-title">
            {handler && dragHandleProps && <span className={'btn-draggable'} {...handler.dragHandleProps}><FiMove/></span>}
            {settings?.title || 'Untitled'}
            {!dragHandleProps && <span className={"text-danger"} style={{"fontSize": "12px"}}>please save to drag</span>}
          </h4>

          <div className={'block-actions'}>
            <Form.Check
              inline
              checked={!settings?.is_disabled}
              type="switch"
              id={`isDisabled-${index}`}
              onChange={actions.toggleBlockSectionDisable}
              label={settings?.is_disabled ? 'Off' : 'On'}/>

            <span onClick={this.handleSettingModalShow} className={'btn-block-action'}>
              <FiSettings/>
            </span>

            <span onClick={actions.onDelete} className={'btn-block-action ml-3'}>
            <FiTrash/>
          </span>
          </div>
        </div>

        {/* CONFIGURATION MODAL */}
        <Modal size={"lg"} show={this.state.isSettingModalShowing} onHide={this.handleSettingModalHide}>

          <Modal.Header closeButton>
            <Modal.Title>Block Settings</Modal.Title>
          </Modal.Header>

          <Form>

            <Modal.Body>

              <Form.Group controlId="blockTitle">
                <Form.Label>Block Title (ENG)</Form.Label>
                <Form.Control type="text" name={'title'}
                              defaultValue={settings?.title}
                              onChange={this.handleSettingInputOnChange}
                              placeholder="Enter block title in english"/>
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Block Title (বাংলা)</Form.Label>
                <Form.Control type="text"
                              name={'title_local'}
                              defaultValue={settings?.title_local}
                              onChange={this.handleSettingInputOnChange}
                              placeholder="Enter block title in local language"/>
              </Form.Group>

            </Modal.Body>

            <Modal.Footer>

              <Button variant="secondary" onClick={this.handleSettingModalHide}>Close</Button>
              <Button variant="primary" onClick={this.handleOnSaveSettingForm}>Save Changes</Button>

            </Modal.Footer>

          </Form>

        </Modal>

        {/* CATEGORY WISE PRODUCT SECTION */}
        <div className="block-body">
          <Row>

            {(settings?.title && this.state.isConfigured) ?
              <>
                <Col lg={3} md={4} xs={4}>
                  {(settings?.title && this.state.isConfigured) ?
                    <>
                      <Row>

                        <Col lg={12} md={12} xs={12} style={{}} className={""}>

                          <ul className="category-list">
                            <li key={"recommended_product"}
                                onClick={() => this.handleTabProduct("recommended_product")}>
                              <span className='text'>Recommended Product for User</span>
                              <span className="caret-icon"><FiChevronRight/></span>
                            </li>
                            <li key={"best_sell"}
                                onClick={() => this.handleTabProduct("best_sell")}>
                              <span className='text'>New Arrival</span>
                              <span className="caret-icon"><FiChevronRight/></span>
                            </li>
                            <li key={"new_arrival"}
                                onClick={() => this.handleTabProduct("new_arrival")}>
                              <span className='text'>Best Sell</span>
                              <span className="caret-icon"><FiChevronRight/></span>
                            </li>
                            <li key={"sale_products"}
                                onClick={() => this.handleTabProduct("sale_products")}>
                              <span className='text'>Sale</span>
                              <span className="caret-icon"><FiChevronRight/></span>
                            </li>
                          </ul>

                        </Col>

                      </Row>

                    </>
                    :
                    <div className={'alert alert-warning text-center'}>
                      Please select required categories <span
                      onClick={this.handleSettingModalShow} style={{cursor: "pointer"}}
                      className="text-primary">click here</span>
                    </div>
                  }
                </Col>

                <Col lg={9} md={8} xs={8}>
                  <>


                    <Row>
                      {(this.state.selectedTabId === "recommended_product") &&
                      <>
                        {this.state.selectedRecommendedProductList.length > 0 && this.state.selectedRecommendedProductList.map((product) => {
                          return (
                            <>
                              <Col lg={2} md={3} xs={3}>
                                {/*<HomeProduct  id={this.props.setting_id}  data={product}/>*/}
                                <HomeProduct id={this.props.setting_id} data={product} sku_info={product.sku_info}
                                             handleDeleteListener={() => {
                                               this.handleDeleteListener(product, index, this.props.setting_id)
                                             }}/>
                              </Col>
                            </>
                          )
                        })}
                      </>
                      }

                      {(this.state.selectedTabId === "best_sell") &&
                      <>
                        {this.state.selectedBestProductList.map((product) => {
                          return (
                            <>
                              <Col lg={2} md={3} xs={3}>
                                {/*<HomeProduct
                                  id={this.props.setting_id}
                                data={product}/>*/}
                                <HomeProduct id={this.props.setting_id} data={product} sku_info={product.sku_info}
                                  handleDeleteListener={()=>{
                                  this.handleDeleteListener(product , index , this.props.setting_id)
                                }}/>
                              </Col>
                            </>
                          )
                        })}
                      </>
                      }


                      {(this.state.selectedTabId === "new_arrival") &&
                      <>
                        {this.state.selectedNewProductList.map((product) => {
                          return (
                            <>
                              <Col lg={2} md={3} xs={3}>
                                {/*<HomeProduct
                                  id={this.props.setting_id}
                                  data={product}/>*/}
                                <HomeProduct id={this.props.setting_id} data={product} sku_info={product.sku_info}
                                             handleDeleteListener={() => {
                                               this.handleDeleteListener(product, index, this.props.setting_id)
                                             }}/>
                              </Col>
                            </>
                          )
                        })}
                      </>
                      }


                      {(this.state.selectedTabId === "sale_products") &&
                      <>
                        {this.state.selectedSaleProductList.map((product) => {
                          return (
                            <>
                              <Col lg={2} md={3} xs={3}>
                               {/* <HomeProduct
                                  id={this.props.setting_id}
                                data={product}/>*/}
                                <HomeProduct
                                             handleDeleteListener={() => {
                                               this.handleDeleteListener(product, index, this.props.setting_id)
                                             }} id={this.props.setting_id} data={product}/>
                              </Col>
                            </>
                          )
                        })}
                      </>
                      }

                      <Col lg={2} md={3} xs={3}>
                        <Button onClick={() => this.setState({customProductModalShow: true})}>Add Product</Button>
                      </Col>

                    </Row>


                    <>
                      <Modal show={this.state.customProductModalShow} onHide={() => {
                        return this.setState({
                          customProductModalShow: false,
                        })
                      }}>

                        <Modal.Header closeButton>
                          <Modal.Title>Select Product</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                          <div className="mb-3 form-inline">
                            <Form.Group>
                              <Form.Label srOnly>Search</Form.Label>
                              <Form.Control type={`search`} name="search_product"
                                            size={`sm`} placeholder={`Type & Search`}
                                            onKeyDown={(e) => {
                                              if (e.keyCode === 13) {
                                                this.handleInputOnChange(e)
                                              } else {

                                              }
                                            }}
                                            onChange={(e) => {
                                              if (e.keyCode !== 13) {
                                                this.setState({customProductQuery: e.target.value})
                                              }
                                            }}
                              />
                            </Form.Group>
                            <Button type={`button`} onClick={this.handleSearchProduct}
                                    size={`sm`} variant={`info`}
                                    className={`ml-2`}>Search</Button>
                          </div>

                          <div className="product-checkbox-list">
                            {
                              this.state.isDataFetching &&
                              <LoadingSpinner text={`Loading...`}/>
                            }
                            {this.state.customProductList.map((product, index) => (
                              <label className={`product-item`} key={index}
                                     htmlFor={`product-${index}`}>
                                <input id={`product-${index}`} name={`product`}
                                       type="checkbox"
                                       onChange={e => this.handleSelectedProductOnChange(e, product)}
                                       defaultChecked={this.isProductIncluded(product)}
                                />
                                <span className={`product`}>
                                          <span className="product-img">
                                            <img src={product?.thumb_img} alt=""/>
                                          </span>
                                          <span className="product-short-info">
                                            <span
                                              className="product-name">{product.name} {product?.sku_info?.attr_combo ? product?.sku_info?.attr_combo.join('-') : ''}</span>
                                          </span>
                                          <span className="icon"><IoMdCheckmark/></span>
                                        </span>
                              </label>
                            ))}
                          </div>

                          <span> {this.state.add_product_message} </span>
                        </Modal.Body>

                        <Modal.Footer>
                          <Button variant="secondary" onClick={() => {
                            return this.setState({customProductModalShow: false})
                          }}>Close</Button>
                        </Modal.Footer>

                      </Modal>
                    </>


                  </>
                </Col>

                <hr/>
                {/* SLIDER */}
                <Col lg={12} md={12} xs={12}>
                  <SectionSlide section_info={settings}/>
                </Col>

              </>
              :
              <div className={'alert alert-warning text-center'}>
                This block has no configuration. Please <span onClick={this.handleSettingModalShow}
                                                              style={{cursor: "pointer"}}
                                                              className="text-primary">click here</span> to
                configure
              </div>
            }
          </Row>
        </div>

      </div>
    );
  }
}

export default ProductsBlock;
