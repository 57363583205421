import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FaLongArrowAltLeft} from "react-icons/all";
import {getLocalAuthData} from "../../helper/utils";

class AddRewardPointSetting extends Component {
    render() {
        return (
            <App layout="boxed">
                <div className="page-header">
                    <h2 className="page-title">Add Reward Point Setting</h2>
                    <div className="ml-auto">
                        <Link to={`${process.env.PUBLIC_URL}/promotions/reward-point`} className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Reward Point Setting List</Link>
                    </div>
                </div>

                {getLocalAuthData()?.permission?.includes('reward_point_setting_add') ?
                <div className="main-content">
                    <Form>
                        <div className="card-block">

                            <div className="block-header">
                                <h4 className="block-title">Define Offer</h4>
                            </div>

                            <div className="block-body">

                                <Form.Group as={Row} controlId="formPromotionName">
                                    <Form.Label column sm="3">
                                        Promotion Name * <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type="text" placeholder=""/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formStartFrom">
                                    <Form.Label column sm="3">
                                        Start From <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type="text" placeholder=""/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formEndTime">
                                    <Form.Label column sm="3">
                                        End Time <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type="text" placeholder=""/>
                                    </Col>
                                </Form.Group>

                            </div>
                        </div>

                        <div className="card-block">
                            <div className="block-body text-right">
                                <Button size="lg" className="mr-3" variant="default">Cancel</Button>
                                <Button size="lg" variant="primary">Save</Button>
                            </div>
                        </div>

                    </Form>
                </div>
                    : null}
            </App>
        );
    }
}

export default AddRewardPointSetting;
