import "../../assets/scss/add-product.scss";

import { Button, Col, Form, Row } from "react-bootstrap";
import React, { Component } from 'react';

import App from "../../App";
import BrowseFiles from "../../components/BrowseFiles";
import CreatableSelect from "react-select";
import { FaLongArrowAltLeft } from "react-icons/all";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router'
import RenderCategory from '../../components/RenderCategory';
import {activityLogPostData} from "../../services/commonServices";
import axios from "axios";
import config from "../../helper/env";
import styleReactSelect from "../../assets/styleReactSelect";
import { toast } from "react-toastify";

class AddCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        web_icon: '',
        mobile_icon: '',
        parent_categories: [],
        attributes: [],
      },
      isModalShowing: false,
      category: [],
      categories: [],
      childCategories: [],
      selectedCatArr: [],
      cateGoryname: [],
      cateGorynameArr: [],
      parent_category: null,
      variants: null,
      keyVariants: null,
      showVariant: false,
      showKeyVariant: false,
      attributes: null,
      redirect: false,
    
    }
  }

  handleCategoryConfirm = (value) => {
    console.log(value);
    this.setState({ selectedCategory: value }, () => {
      this.setState({ isModalShowing: false })
    })
  };

  componentDidMount() {
    axios.get(`${config.API_BASE_URL}/category`)
      .then(res => {
        this.setState({ categories: res.data.categories });
      }).catch(() => console.log("Error"));

    axios.get(`${config.API_BASE_URL}/attributeList`)
      .then(res => {
        console.log(res.data.items);
        let variants = [];
        let keyVariants = [];
        if (res.data.items) {
          res.data.items.map((item) => {
            if (item?.attribute_type === 2) {
              variants.push({ value: item._id, label: item.name });
            }
            if (item?.attribute_type === 1) {
              keyVariants.push({ value: item._id, label: item.name });
            }

          });
        }
        this.setState({ variants: variants, showVariant: true });
        this.setState({ keyVariants: keyVariants, showKeyVariant: true });
      }).catch(() => console.log("Error"));
    setTimeout(() => {
      console.log(this.state.variants);
    }, 2000);
  }

  handleChangeCategory = () => {
    this.setState({ isModalShowing: true })
  };

  handleModalHide = () => {
    this.setState({ isModalShowing: false })
  };

  handleFormDataChange = (e) => {
    this.setState({ formData: { ...this.state.formData, [e.target.name]: e.target.value } });
  };

  handleSubmitForm = async () => {
    await this.setState({
      formData: {
        ...this.state.formData,
        category_slug: this.state.formData.name.replace(/\s+/g, '-').toLowerCase(),
      }
    });
    const data = this.state.formData;
    data.attributes = data.attributes.concat(data.newAttributes);
    axios.post(`${config.API_BASE_URL}/category`, data)
      .then(response => {
        console.log("post Category :",response);
        if (response.data.status === 1) {
          toast.success(response.data.message);
          activityLogPostData('was added new category','/categories/list/',this.state.formData.name)
          setTimeout(() => {
            this.setState({ redirect: true });
          }, 1000);
        }
        if (response.data.status === 2) {
          toast.error(response.data.message);
        }
      }).catch(() => {
        toast.error("Error");
      });
  };

  selectedCat = (cateGorynameArr, selectedCatArr, childCategories) => {
    console.log(cateGorynameArr);
    console.log(selectedCatArr);
    console.log(childCategories);
    let catName = "";
    cateGorynameArr.map((val) => {
      if (catName) {
        catName = catName.concat(" > " + val.name);
      } else {
        catName = val.name;
      }
    });

    this.setState({
      cateGoryname: catName,
      cateGorynameArr: cateGorynameArr,
      selectedCatArr: selectedCatArr,
      childCategories: childCategories,
      isModalShowing: false,
      formData: {
        ...this.state.formData,
        parent_category: selectedCatArr.slice(-1).pop(),
        parent_categories: selectedCatArr
      }
    });
    setTimeout(() => {
      console.log(cateGorynameArr);
    }, 2000);
  }

  handleAttrOnChange = (newValue) => {
    console.log(newValue);
    let attributes = [];
    if (newValue) {
      newValue.map((val) => {
        attributes.push({ attribute_id: val.value, is_require: false });
      });
    }

    this.setState({
      formData: {
        ...this.state.formData,
        attributes: attributes
      }
    });

    setTimeout(() => {
      console.log(this.state.formData);
    }, 2000)
  }

  handleNewAttrOnChange = (newValue) => {
    let attributes = [];
    if (newValue) {
      newValue.map((val) => {
        attributes.push({ attribute_id: val.value, is_require: false });
      });
    }

    this.setState({
      formData: {
        ...this.state.formData,
        newAttributes: attributes
      }
    });

    setTimeout(() => {
      console.log(this.state.formData);
    }, 2000)
  }

  handleWebIconImage = (files) => {
    this.setState({
      formData: {
        ...this.state.formData,
        web_icon: files[0].thumb_img
      }
    });
  }

  handleMobileIconImage = (files) => {
    this.setState({
      formData: {
        ...this.state.formData,
        mobile_icon: files[0].thumb_img
      }
    });
  }

  handleOnRemoveItem = (key) => {
    const formData = { ...this.state.formData }
    formData[key] = null
    this.setState({ formData })
  }

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to='/categories/list' />;
    }
    return (
      <App layout="boxed">
        {this.state.isModalShowing ? <RenderCategory
          handleModalHide={this.handleModalHide}
          selectedCat={this.selectedCat}
          cateGorynameArr={this.state.cateGorynameArr}
          selectedCatArr={this.state.selectedCatArr}
          childCategories={this.state.childCategories}
          categories={this.state.categories}
        /> : ""}
        <div className="page-header">
          <h2 className="page-title">Add Category</h2>
          <div className="ml-auto">
            <Link to={`${process.env.PUBLIC_URL}/categories/list`}
              className="btn btn-sm btn-link"><FaLongArrowAltLeft /> Back to
              Category List</Link>
          </div>
        </div>

        <div className="main-content">
          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Define a category</h4>
            </div>

            <div className="block-body">
              <Form>
                <Form.Group as={Row} controlId="formName">
                  <Form.Label column sm="3">
                    Category Type <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Check
                      custom
                      inline required
                      type="radio"
                      value={'product'}
                      onChange={this.handleFormDataChange}
                      name={'category_type'}
                      id={`categoryType-1`}
                      label={`Product`}
                    />
                    <Form.Check
                      custom
                      inline required
                      type="radio"
                      value={'store'}
                      onChange={this.handleFormDataChange}
                      name={'category_type'}
                      id={`categoryType-2`}
                      label={`Store`}
                    />
                    <Form.Check
                      custom
                      inline required
                      type="radio"
                      value={'service'}
                      onChange={this.handleFormDataChange}
                      name={'category_type'}
                      id={`categoryType-3`}
                      label={`Service`}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formName">
                  <Form.Label column sm="3">
                    Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control type="text" name="name" onChange={this.handleFormDataChange}
                      placeholder=""
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formName">
                  <Form.Label column sm="3">
                    Name (Local) <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control type="text" name="name_local" onChange={this.handleFormDataChange}
                      placeholder=""
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formName">
                  <Form.Label column sm="3">
                  Sequence
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control type="number" name="sequence" onChange={this.handleFormDataChange}
                      placeholder=""
                    />
                  </Col>
                </Form.Group>


                {this.state.formData?.category_type !== 'store' && this.state.formData?.category_type !== 'service' &&
                  <Form.Group as={Row} controlId="formCategory">
                    <Form.Label column sm="3">
                      Parent Category
                  </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                        type="text"
                        value={this.state.cateGoryname}
                        name=""
                        onChange={this.handleFormDataChange}
                        onClick={this.handleChangeCategory}
                        readOnly placeholder=""
                      />
                    </Col>
                  </Form.Group>}

                {(this.state.showVariant && this.state.formData?.category_type !== 'store' && this.state.formData?.category_type !== 'service') ?
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Variants
                    </Form.Label>

                    <Col sm="9" md={8} lg={7}>
                      <CreatableSelect
                        isMulti
                        styles={styleReactSelect}
                        placeholder="Select value..."
                        onChange={(newValue) => {
                          this.handleAttrOnChange(newValue);
                          // console.log('New Value', newValue)
                          // console.log('Action Meta', actionMeta)
                        }}
                        options={this.state.variants}
                      // noOptionsMessage={() => 'Please insert new value...'}
                      />
                    </Col>
                  </Form.Group>
                  : ""}

                {(this.state.showKeyVariant && this.state.formData?.category_type !== 'store' && this.state.formData?.category_type !== 'service') ?
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Key product information
                    </Form.Label>

                    <Col sm="9" md={8} lg={7}>
                      <CreatableSelect
                        isMulti
                        styles={styleReactSelect}
                        placeholder="Select value..."
                        onChange={(newValue) => {
                          this.handleNewAttrOnChange(newValue);
                          // console.log('New Value', newValue)
                          // console.log('Action Meta', actionMeta)
                        }}
                        options={this.state.keyVariants}
                      // noOptionsMessage={() => 'Please insert new value...'}
                      />
                    </Col>
                  </Form.Group>
                  : ""}

                <Form.Group as={Row} controlId="formImages">
                  <Form.Label column sm="3">
                    Web Icon
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <BrowseFiles
                      isSingle={true}
                      image_category={'icon'}
                      onRemoveItem={() => this.handleOnRemoveItem('web_icon')}
                      images={this.state.formData?.web_icon ? [{ thumb_img: this.state.formData?.web_icon }] : []}
                      onGetFiles={this.handleWebIconImage}
                      helpText="Size between 330x330 and 800x800 px. Obscene image is strictly prohibited." />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formImages">
                  <Form.Label column sm="3">
                    Mobile Icon
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <BrowseFiles
                      isSingle={true}
                      image_category={'icon'}
                      onRemoveItem={() => this.handleOnRemoveItem('mobile_icon')}
                      images={this.state.formData?.mobile_icon ? [{ thumb_img: this.state.formData?.mobile_icon }] : []}
                      onGetFiles={this.handleMobileIconImage}
                      helpText="Size between 100x100 and 200x200 px. Obscene image is strictly prohibited." />
                  </Col>
                </Form.Group>

              </Form>
            </div>
          </div>

          <div className="card-block">
            <div className="block-body text-right">
            <Link to={`${process.env.PUBLIC_URL}/categories/list`} size="lg" className="btn btn-lg btn-default mr-3"
                variant="default">Cancel</Link>
              <Button size="lg" className="mr-3" variant="outline-primary"
                onClick={this.handleSubmitForm}>Save</Button>
            </div>
          </div>
        </div>
      </App>
    );
  }
}


export default AddCategory;
