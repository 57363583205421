import React, {Component} from 'react';
import App from "../../App";
import {Badge, Button, Col, Form, Row} from "react-bootstrap";
import BrowseFiles from "../../components/BrowseFiles";
import {
    getTableData,
    getDetail,
    addNewItem,
    updateItem,
    updateItemPartial,
    deleteItem,
    activityLogPostData
} from "../../services/commonServices";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import "../../assets/scss/barnd-list.scss";
import Pagination from "react-js-pagination";
import {getLocalAuthData} from '../../helper/utils'
import {FiEdit, FiTrash} from "react-icons/all";
import axios from "axios";
import {API_BASE_URL, SHOP_BASE_URL} from "../../helper/env";
import {MdBrokenImage} from "@react-icons/all-files/md/MdBrokenImage";
import {HiOutlineExternalLink} from "@react-icons/all-files/hi/HiOutlineExternalLink";

class District extends Component {
    state = {
        formData: {},
        districtList: [],
        districtDetails: null,
        isLoading: false,
        queryData: {
            sort_direction: -1,
            limit: 50,
        },
        activePage: 1,
        limit: 50,
        pagination: {},
        page: 1
    }

    componentDidMount() {
        this.getDistrict()
    }

    getDistrict = () => {
        const queryData = {...this.state.queryData};
        this.setState({isLoading: true, queryData: queryData});
        getTableData('/district', this.state.queryData).then(res => {
            this.setState({districtList: res.items, isLoading: false, pagination: res.pagination})
        }).catch(errMsg => {
            toast.error(errMsg);
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({queryData: {...this.state.queryData, page: pageNumber, limit: this.state.limit}}, () => {
            this.getDistrict();
        });
    }

    handleOnChangeInput = (e) => {
        const formData = {...this.state.formData};
        const queryData = {...this.state.queryData};
        formData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        console.log(e.target.name);
        if (e.target.name === 'name') {
            queryData.search_string = e.target.value;
        } else {
            queryData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        }

        this.setState({formData, queryData}, () => {
            console.log(this.state);
        });
    }

    handleSubmitDistrictForm = () => {
        addNewItem('/district', this.state.formData)
            .then(res => {
                this.setState({isLoading: false , queryData: {search_string: ""}},()=>{
                });
                if (res.status === 1) {
                    toast.success(res.message);
                    this.resetUpdateForm();
                    this.getDistrict();
                } else {
                    toast.warning(res.message);
                }
            })
            .catch(errMsg => {
                this.setState({isLoading: false});
                toast.error(errMsg);
            })
    }

    handleUpdateDistrict = () => {
        this.setState({isLoading: true}, () => {
            updateItem(`/district/${this.state.districtDetails._id}`, this.state.formData)
                .then(res => {
                    this.setState({isLoading: false, queryData: {search_string: ""}})
                    if (res.status === 1) {
                        toast.success(res.message);
                        this.resetUpdateForm();
                        this.getDistrict();
                    } else {
                        toast.warning(res.message);
                    }

                })
                .catch(errMsg => {
                    toast.error(errMsg);
                    this.setState({isLoading: false});
                    toast.error(errMsg);
                })
        });
    }

    handleGetDistrictDetails = (id) => {
        getDetail(`/district-for-admin/${id}`).then(res => {
            this.setState({districtDetails: res.result},()=>{

                //this.state.formData.logo
                let formData = {...this.state.formData}
                formData.logo = res?.result?.logo
                formData.banner = res?.result?.banner
                this.setState({
                    formData
                })

                window.scrollTo({top: 0, behavior: 'smooth'});
            })
        })
    }

    handleDeleteDistrict = (id) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure to delete this District?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteItem(`/district/${id}`).then(res => {

                        if (res.status === 1){
                            this.getDistrict()
                            toast.success(res.message)
                        }else {
                            toast.success(res.message)
                        }
                    }
                    ).catch(err => {
                        toast.error(err)
                    })
                }
                ,
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });
    };

    resetUpdateForm = () => {
        this.setState({districtDetails: null, formData: {}}, () => {
            document.getElementById('districtForm').reset()
        })
    }

    handleOnRemoveItem = () => {
        const formData = {...this.state.formData}
        formData.logo = null
        this.setState({formData})
    }


    changeStatus = async (status, _id) => {
        updateItemPartial(`/district/changeStatus/${_id}` , {status: status}).then((res)=>{

            if (res.status === 1) {
                toast.success(res.message);
                this.resetUpdateForm();
                this.getDistrict();
            } else {
                toast.warning(res.message);
            }

        }).catch((err)=>{
            toast.error(err)
        })
    };


    handleSearchFormDataChange = async (e) => {
        await this.setState({queryData: {...this.state.queryData, [e.target.name]: e.target.value}});
    };


    onLimitChange = (e) =>{
        const newQuery = {...this.state.queryData, limit: +e.target.value}
        this.setState({queryData: newQuery, isLoading: true}, ()=>{
            this.getDistrict();
        });
    }


    handleDistrictOnBrowsePic = (files, name) => {
        console.log('Files', files)
        console.log('Name', name)
        const formData = {...this.state.formData}
        formData[name] = files[0].actual_img;
        this.setState({formData})
    }


    handleRemoveBankImg = (key) => {
        const formData = {...this.state.formData};
        formData[key] = "";
        this.setState({formData});
    }

    
    render() {
        return (
            <App layout='boxed'>
                <div className="page-header">
                    <h2 className="page-title">District Branding</h2>
                </div>

                <div className="main-content">
                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">{!this.state.districtDetails ? 'Add District' : 'Update District'}</h4>
                        </div>

                        <div className="block-body">
                            <Form onSubmit={(e) => {
                                e.preventDefault()
                                if (this.state.districtDetails) {
                                    this.handleUpdateDistrict()
                                } else {
                                    this.handleSubmitDistrictForm()
                                }
                            }} id={"districtForm"}>



                                <Form.Group as={Row} controlId="districtForm">
                                    <Form.Label column sm="3">
                                        District Name <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type={"text"}
                                                      required
                                                      defaultValue={this.state.districtDetails?.name}
                                                      name="name"
                                                      onChange={this.handleOnChangeInput}
                                                      onClick={this.handleOnChangeInput}
                                                      placeholder={"Enter district name"}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="districtForm">
                                    <Form.Label column sm="3">
                                        District Name (Local)<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type={"text"}
                                                      required
                                                      defaultValue={this.state.districtDetails?.name_local}
                                                      name="name_local"
                                                      onChange={this.handleOnChangeInput}
                                                      onClick={this.handleOnChangeInput}
                                                      placeholder={"Enter district local name"}/>
                                    </Col>
                                </Form.Group>



                                <Form.Group as={Row} controlId="">
                                    <Form.Label column sm="3">
                                        Upload District Logo <span className="text-danger">*</span>
                                    </Form.Label>

                                    <Col sm="9" md={8} lg={7}>
                                        <BrowseFiles onGetFiles={(files) => {
                                            this.handleDistrictOnBrowsePic(files, 'logo')
                                        }} image_category={'district'} helpText={``}/>

                                        {this.state.formData.logo &&
                                        <div className="existing-images">
                                            <div className="existing-img">
                          <span onClick={() => this.handleRemoveBankImg('logo')}
                                className="btn-remove">&times;</span>
                                                <img src={this.state.formData.logo} alt=""/>
                                            </div>
                                        </div>}
                                    </Col>
                                </Form.Group>


                                <Form.Group as={Row} controlId="">
                                    <Form.Label column sm="3">
                                        Upload District Banner <span className="text-danger">*</span>
                                    </Form.Label>

                                    <Col sm="9" md={8} lg={7}>
                                        <BrowseFiles onGetFiles={(files) => {
                                            this.handleDistrictOnBrowsePic(files, 'banner')
                                        }} image_category={'district'} helpText={``}/>

                                        {this.state.formData.banner &&
                                        <div className="existing-images">
                                            <div className="existing-img">
                          <span onClick={() => this.handleRemoveBankImg('banner')}
                                className="btn-remove">&times;</span>
                                                <img src={this.state.formData.banner} alt=""/>
                                            </div>
                                        </div>}
                                    </Col>
                                </Form.Group>


                                <Form.Group as={Row} controlId="formCategory">
                                    <Col sm="3"/>
                                    <Col sm="9" md={8} lg={7}>
                                        {!this.state.districtDetails ?
                                            <>
                                                <Button type={`submit`}
                                                        disabled={this.state.isLoading}
                                                        variant={"primary"}>{this.state.isLoading ? 'Adding District...' : 'Add District'}
                                                </Button>
                                            </>
                                            :
                                            <div>
                                                <Button variant={"primary"}
                                                        type={`submit`}
                                                        disabled={this.state.isLoading}
                                                        className={"mr-2"}>{this.state.isLoading ? 'Updating...' : 'Update'}</Button>

                                                <Button type={"reset"} onClick={this.resetUpdateForm} variant={"default"}>Reset</Button>
                                            </div>
                                        }
                                    </Col>
                                </Form.Group>

                            </Form>
                        </div>
                    </div>

                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">District List</h4>
                        </div>

                        <div className="block-body">
                            <div className="block-body">

                                <form className="form-inline mb-3">
                                    <div className="form-group mr-3">
                                        <label htmlFor="status" className="sr-only">Status</label>
                                        <select id="status" name="status" className='form-control form-control-sm'
                                                onKeyDown={(e)=> {
                                                    if(e.keyCode === 13){
                                                        e.preventDefault()
                                                        this.getDistrict()
                                                    }
                                                }}
                                                onChange={this.handleSearchFormDataChange}>
                                            <option value="">All</option>
                                            <option value="1">Active</option>
                                            <option value="2">Inactive</option>
                                        </select>
                                    </div>

                                    <div className="form-group mr-3">
                                        <label htmlFor="searchInput" className="sr-only">Search Key</label>
                                        <input type="search" name="search_string" id="orderNumber"
                                               onChange={this.handleSearchFormDataChange}
                                               onKeyDown={(e)=> {
                                                   if(e.keyCode === 13){
                                                       e.preventDefault()
                                                       this.getDistrict()
                                                   }
                                               }}
                                               className='form-control form-control-sm' placeholder="Search Key"/>
                                    </div>

                                    <Button size="sm" variant="secondary" onClick={()=>{
                                        this.getDistrict()
                                    }}>Search</Button>

                                </form>


                                {this.state.isLoading ?
                                    <>Loading...</>
                                    :
                                    <table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th style={{width: '20px'}}>#</th>
                                            <th>Logo</th>
                                            <th>Banner</th>
                                            <th>Name</th>
                                            <th>Name (Local)</th>
                                            <th>Link</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                            <th style={{width: `1%`}}/>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {(getLocalAuthData().permission.includes('support_topic_list') && this.state.districtList?.length > 0) ?
                                            this.state.districtList.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.logo ? <img style={{borderRadius: '50%',backgroundPositionX:'center', backgroundPositionY:'center', backgroundRepeat:'no-repeat'}} src={item.logo} width={`50`} height={`50`} alt={``}/> : <MdBrokenImage color={`#737373`}/>}</td>
                                                    <td>{item.banner ? <img src={item.banner} width={`70`} height={`auto`} alt={``}/> : <MdBrokenImage color={`#737373`}/>}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.name_local}</td>
                                                    <td><a target={`blank`} href={`${SHOP_BASE_URL}/district/${item.slug}`}><HiOutlineExternalLink color={`#7477e0`}/></a></td>
                                                    <td>
                                                        {item.status === 1 ? <Badge pill varian="primary">active</Badge> : <Badge pill variant="danger">inactive</Badge>}
                                                    </td>
                                                    <td>
                                                        <select
                                                            style={{width: '150px'}}
                                                            className="form-control form-control-sm"
                                                            onChange={(e) => {
                                                                this.changeStatus(e.target.value, item._id)
                                                            }}
                                                            name="status">
                                                            <option value="1" selected={item.status === 1}>Active</option>
                                                            <option value="2" selected={item.status === 2}>Inactive</option>
                                                        </select>
                                                    </td>
                                                    <td className="nowrap text-center">
                                                        <button className="btn btn-sm btn-outline-primary mr-3" onClick={() => this.handleGetDistrictDetails(item._id)}>Edit</button>

                                                        <button className="btn btn-sm btn-outline-danger mr-3" onClick={() => this.handleDeleteDistrict(item._id)}>Delete</button>

                                                    </td>
                                                </tr>
                                            )) :
                                            <tr>
                                                <td colSpan={7} className={`text-bold text-center`}>No item found</td>
                                            </tr>
                                        }
                                        </tbody>
                                    </table>
                                }
                                <div className="mt-3 d-flex">
                                    <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={this.state.pagination?.page || 1}
                                        itemsCountPerPage={this.state.pagination?.limit || 10}
                                        totalItemsCount={this.state.pagination?.totalDocs || 10}
                                        pageRangeDisplayed={20}
                                        onChange={this.handlePageChange.bind(this)}
                                    />
                                    <div style={{width: '75px', marginLeft: '15px'}}>
                                        <Form.Control as="select" name="limit" value={this.state.queryData?.limit} onChange={this.onLimitChange}>
                                            <option value="20">20</option>
                                            <option value="40">40</option>
                                            <option value="50">50</option>
                                            <option value="70">70</option>
                                            <option value="100">100</option>
                                        </Form.Control>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </App>
        );
    }
}

export default District;
