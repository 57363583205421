import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import axios from "axios";
import config from "../../helper/env";
import {ToastsContainer, ToastsStore} from "react-toasts";
import {toast} from "react-toastify";
import {getDeviceType, getLocalAuthData, headerData} from "../../helper/utils";
import {Link} from "react-router-dom";
import {FiEdit, FiTrash} from "react-icons/all";
import {confirmAlert} from "react-confirm-alert";
import {updateItem, updateItemPartial} from "../../services/commonServices.js";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {UploadAdapter} from "../../adapters/ckUploadAdapter";
import CKEditor from "@ckeditor/ckeditor5-react";
import BrowseFiles from "../../components/BrowseFiles";

class News extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            newsData: [],
            editFormData: null,
            editStatus: false
        }
    }

    componentDidMount() {
        axios.get(`${config.API_BASE_URL}/news`, headerData).then(res => {
            console.log(res.data.items);
            this.setState({newsData: res.data.items , images: res.data?.news?.thumbnail});
        }).catch(e => this.setState({error: true}));
    }

    renderData = () => {
        axios.get(`${config.API_BASE_URL}/news`, headerData).then(res => {
            console.log(res.data.items);
            this.setState({newsData: res.data.items , images: res.data?.news?.thumbnail});
        }).catch(e => this.setState({error: true}));
    }

    handleImage = (files) => {
        this.setState({
            formData: {...this.state.formData, thumbnail: files[0].thumb_img},
            images: files
        });
    }

    handleFormDataChange = (e) => {
        this.setState({formData: {...this.state.formData, [e.target.name]: e.target.value}});
        setTimeout(() => {
            console.log(this.state.formData);
        }, 3000);
    };

    deleteNews = (id) => {
        axios.delete(`${config.API_BASE_URL}/news/${id}/permanent/`, headerData).then(response => {
            if (response.data.status === 1) {
                ToastsStore.success(response.data.message);
                this.renderData();
            }
            if (response.data.status === 2) {
                ToastsStore.error(response.data.message);
            }
        }).catch(error => {
            ToastsStore.error("Error");
        });
    };

    editNews = (id) => {
        axios.get(`${config.API_BASE_URL}/news/${id}`, headerData).then(res => {
            this.setState({
                editFormData: res.data.news,
                formData: {
                    ...this.state.formData,
                    title: res.data.news.title,
                },
                editStatus: true
            });
            window.scrollTo({top: 0, behavior: 'smooth'});
        }).catch(error => {
            ToastsStore.error("Error");
        });
    };

    renderNews = () => {
        const newsList = this.state.newsData;
        if (newsList) {
            return (
                <Row>
                    {newsList.map((news, index) => {
                            return (
                                <Col md={3}>
                                    {/*<News
                    imageLink={news.image} newsId={news._id}
                    deleteNews={() => this.deleteNews(news._id)}
                    editNews={() => this.editNews(news._id)}
                  />*/}
                                </Col>
                            )
                        }
                    )}
                </Row>
            )
        }

        return (
            <Form.Control as="select">
                <option>Fetching News</option>
            </Form.Control>
        )
    };

    handleToggleStatusUpdate = (id, status, index) => {
        confirmAlert({
            title: 'Status Update',
            message: 'Are you sure to change this status?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => updateItemPartial(`/news/changeStatus/${id}`, {status: status}).then(res => {

                        // Update row data
                        const newsData = [...this.state.newsData]
                        newsData[index].status = status
                        this.setState({newsData})
                        toast.success(res.message)

                    }).catch(errMsg => {
                        toast.error(errMsg)
                    })
                }, {
                    label: 'No',
                }
            ]
        });
    }

    handleSubmitForm = () => {
        const data = this.state.formData;
        let url = `${config.API_BASE_URL}/news`;
        if (this.state.editStatus === true) {
            url = `${config.API_BASE_URL}/news/` + this.state.editFormData._id;
            axios.put(url, data, headerData).then(response => {
                if (response.data.status === 1) {
                    toast.success(response.data.message);
                    this.renderData();
                }
                if (response.data.status === 2) {
                    toast.error(response.data.message);
                }
            }).catch(error => {
                toast.error("Something went wrong please try again after refresh the page.");
            });
        } else {
            axios.post(url, data, headerData).then(response => {
                if (response.data.status === 1) {
                    toast.success(response.data.message);
                    this.renderData();
                }
                if (response.data.status === 2) {
                    toast.error(response.data.message);
                }
            }).catch(error => {
                toast.error("Something went wrong please try again after refresh the page.");
            });
        }
    };

    handleInputOnChange = (e) => {
        const formData = {...this.state.formData};
        formData[e.target.name] = (e.target.type === 'checkbox' ? e.target.checked : e.target.value);
        this.setState({formData});
    }


    handleCkEditorOnChange = (name, data, isMultiLang, lang) => {
        const formData = {...this.state.formData, [name]: data};
        this.setState({formData})
    }


    /*handleAddProductImages = (files) => {
        const product = {...this.state.formData}
        product.images = files
        this.setState({product, images: files});
    }*/

    handleOnRemoveItem = (i) => {
        const images = [...this.state.images]
        const product = {...this.state.formData}
        images.splice(i, 1)
        product.images.splice(i, 1)
        this.setState({images, product}, () => {
            console.log(this.state)
        })
    }


    render() {
        return (
            <App layout={"boxed"}>
                <div className="page-header">
                    <h2 className="page-title">News Management</h2>
                </div>

                <div className="main-content">
                    <div className="card-block">

                        <div className="block-body">
                            <>
                                {getLocalAuthData()?.permission?.includes('bank_add') ?
                                    <Form id={"newsForm"}>
                                        <Form.Group as={Row} controlId="newsTitle">
                                            <Form.Label column sm="3">
                                                News Title <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                <Form.Control type={"text"}
                                                              defaultValue={this.state.editFormData ? this.state.editFormData.title : ""}
                                                              name="title" onChange={this.handleFormDataChange}
                                                              placeholder={"News Title"}/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formImages">
                                            <Form.Label column sm="3">
                                                Images <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>
                                                {/*<Form.Control type="file"/>*/}
                                                <BrowseFiles
                                                    image_category={'news'}
                                                    /*userId={this.state.merchant}*/
                                                    onRemoveItem={this.handleOnRemoveItem}
                                                    images={this.state?.images}
                                                    onGetFiles={this.handleImage}
                                                    helpText="image can be uploaded at once.Maximum 8 pictures. Size between 330x330 and 800x800 px. Obscene image is strictly prohibited."/>
                                            </Col>
                                        </Form.Group>


                                        <Form.Group as={Row} controlId="newsDetails">
                                            <Form.Label column sm="3">
                                                News Details <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Col sm="9" md={8} lg={7}>

                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    data={this.state.editFormData ? this.state.editFormData.details : ""}
                                                    onInit={editor => {
                                                        editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
                                                            return new UploadAdapter(loader);
                                                        };
                                                    }}
                                                    config={{
                                                        image: {
                                                            resizeUnit: 'px',
                                                            toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],
                                                            styles: [
                                                                'full',
                                                                'alignLeft',
                                                                'alignRight'
                                                            ]
                                                        }
                                                    }
                                                    }
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        this.handleCkEditorOnChange('details', data);
                                                    }}
                                                />

                                                {/*<Form.Control type={"textarea"}
                                                              defaultValue={this.state.editFormData ? this.state.editFormData.details : ""}
                                                              name="details" onChange={this.handleFormDataChange}
                                                              placeholder={"News Detail"}/>*/}
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId={'productSettings'}>
                                            <Form.Label column sm="3">
                                                Is Featured
                                            </Form.Label>
                                            <Col sm="2" md={8} lg={7}>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="isFeatured"
                                                    label="Make Featured"
                                                    name={"is_featured"}
                                                    onChange={this.handleInputOnChange}
                                                    checked={this.state?.editFormData?.is_featured}
                                                    custom
                                                />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="newsFormSubmit">
                                            <Form.Label column sm="3"/>
                                            <Col sm="9" md={8} lg={7}>
                                                <Button variant={"primary"} onClick={this.handleSubmitForm}>Save</Button>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                    : <span className={`text-bold text-center`}> Permission Denied</span>}
                            </>

                        </div>
                    </div>

                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">News List</h4>
                        </div>

                        <div className="block-body">
                            {/*{this.renderNews()}*/}
                            {this.state.isLoading ?
                                <>Loading...</>
                                :
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th style={{width: '20px'}}>#</th>
                                        <th>News Thumbnail</th>
                                        <th>News title</th>
                                        <th>Status</th>
                                        <th style={{width: `1%`}}/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {(getLocalAuthData()?.permission?.includes('bank_list') && this.state.newsData?.length > 0) ?
                                        this.state.newsData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td><img src={item?.thumbnail} style={{width: '50px'}} alt=""/></td>
                                                <td>{item?.title}</td>
                                                <td>{item?.status}</td>

                                                <td className="nowrap text-center">
                                                    {getLocalAuthData()?.permission?.includes('bank_edit') ?
                                                        <span className="action text-bold text-default mr-3 cursor-pointer" onClick={() => this.editNews(item._id)}><FiEdit/> </span> : null}
                                                    {getLocalAuthData()?.permission?.includes('bank_active_inactive') ?
                                                        <span className={item?.status === "1" ? 'text-bold text-warning mr-3' : 'text-bold text-success mr-3'}
                                                              onClick={() => this.handleToggleStatusUpdate(item._id, item?.status === 'Active' ? "Inactive" : "Active", index)}
                                                              style={{cursor: 'pointer'}}>{item?.status === 'Active' ? 'Inactive' : 'Active'}
                                                    </span> : null}
                                                    {getLocalAuthData()?.permission?.includes('bank_delete') ?
                                                        <span className="action text-bold text-danger mr-3 cursor-pointer" onClick={() => this.deleteNews(item._id)}><FiTrash/> Delete</span>
                                                        : null}
                                                </td>
                                            </tr>
                                        )) :
                                        <tr>
                                            <td colSpan={7} className={`text-bold text-center`}>No item found</td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                            }
                        </div>

                    </div>

                </div>
                <ToastsContainer store={ToastsStore}/>
            </App>
        );
    }
}

export default News;
