// noinspection JSUnresolvedVariable,SpellCheckingInspection

import React, {Component} from 'react';
import "../assets/scss/product.scss";
import {Link} from "react-router-dom";
import moment from 'moment';
import {getLocalAuthData} from "../helper/utils";
import {FaStore, GoLinkExternal} from "react-icons/all";
import {SHOP_BASE_URL} from "../helper/env";
import {Button} from "react-bootstrap";

class Product extends Component {
  state = {
    userType: null,
  }

  componentDidMount() {
    const authData = getLocalAuthData()
    this.setState({userType: authData?.type})
  }

  renderStatus = (status) => {
    if (status === 1) {
      return ""/*<>|| <span className="text-success -badge -badge-success">/!*Active*!/</span></>*/
    } else if (status === 2) {
      return <> || <span className="text-warning -badge -badge-warning">Blocked</span></>
    }
  }

  renderIsDeleted = (value) => {
    if (value === true)
      return <> || <span className="text-danger -badge -badge-warning">Deleted</span></>
  }

  renderBStatus = (status) => {
    let sta;
    if (status === 1) {
      sta = "2"
    } else {
      sta = "1"
    }
    return sta;
  }

  renderPublishStatus = (status) => {
    let sta = {1: "2", 2: "3", 3: "2"};
    return sta[status];
  }

  renderPublishStatusText = (status) => {
    let sta = {1: "Drafted", 2: "Published", 3: "Unpublished"};
    let staText = {1: "success", 2: "warning", 3: "danger"};
    let staBadge = {1: "badge-success", 2: "badge-warning", 3: "badge-danger"};
    return <>|| <span className={`text-${staText[status]} -badge -${staBadge[status]}`}> {sta[status]} </span></>;
  }

  renderBtnText = (status) => {
    let btnText;
    if (status === 1) {
      btnText = "Block"
    } else {
      btnText = "Unblock"
    }
    return btnText
  }

  renderPublishBtnText = (status) => {
    let btnText = {1: "Publish", 2: "Unpublish", 3: "Publish"};
    return btnText[status]
  }

  renderAvailabilityStatus = (qnt) => {
    if (qnt === 0) {
      return <span className="badge badge-warning">Out of Stock</span>
    } else if (qnt > 0 && qnt <= 5) {
      return <span className="badge badge-warning">Low</span>
    } else {
      return <span className="badge badge-success">Available</span>
    }
  }

  render() {
    const {data} = this.props;
    return (
      <div className="product">
        <Link to={`${process.env.PUBLIC_URL}/products/details/${data.slug?data.slug:data._id}`} className="product-thumb">
          <img src={data.thumb_img} alt=""/>
        </Link>
        <div className="product-desc">
          <h4 className="product-title">
            <Link to={`${process.env.PUBLIC_URL}/products/details/${data.slug ? data.slug : data._id}`}>{data.name}</Link>
            <a target={'_blank'}
               rel="noopener noreferrer"
               className={'ml-3 text-primary'}
               href={`${SHOP_BASE_URL}/products/details/${data.slug ? data.slug : data._id}`}><GoLinkExternal/></a>
          </h4>
          <p className="date mb-0"><em>Created at
            : </em> {moment(data.createdAt).format('LL')} {this.renderStatus(data.status)} {this.renderPublishStatusText(this.props.data.publish_status)}</p>
          <p className=""><span
            className="d-inline-block mr-2"><FaStore/></span>
            {data?.store_info?.store_name} {data?.store_info?.deleted ? this.renderIsDeleted(data?.store_info?.deleted) : ''} {data?.store_info?.status ? this.renderStatus(data?.store_info?.status) : ''}
          </p>
          {data?.sku_info &&
          <div className="product-attr">
            {data.sku_info?.special_price ?
              <div className="attr">
                <span className="attr-title">Sale Price</span>
                <span className="attr-value">৳ {data.sku_info?.special_price?.toFixed(2)}</span>
              </div> : ''}
            <div className="attr">
              <span className="attr-title">Regular Price</span>
              <span className="attr-value">৳ {data.sku_info?.price?.toFixed(2)}</span>
            </div>
            <div className="attr">
              <span className="attr-title">Available</span>
              <span className="attr-value">{data.sku_info.quantity}</span>
            </div>
            <div className="attr">
              <span className="attr-title">Stock</span>
              <span className="attr-value">{this.renderAvailabilityStatus(data.sku_info.quantity)}</span>
            </div>

            {data.campaign_product ?  <div className="attr">
              <span className="attr-title"><span className="badge badge-info">Campaign Product</span></span>
            </div> : null }

          </div>}

          <div className="product-attr mt-2">
            {data?.max_price ?
              <div className="attr">
                <span className="attr-title">Price Range</span>
                <span className="attr-value">৳ {data?.min_price?.toFixed(2)} {data?.max_price ? " - " + data?.max_price?.toFixed(2) : ''}</span>
              </div> : ''}
            <div className="attr">
              <span className="attr-title">Rating</span>
              <span className="attr-value">{data?.avg_score}/{data?.t_n_of_score}</span>
            </div>
            <div className="attr">
              <span className="attr-title">Total Sold </span>
              <span className="attr-value">{data?.t_sold}</span>
            </div>
          </div>
        </div>

        <div className="actions">
          {getLocalAuthData()?.permission?.includes('product_item_view') ? <Link to={`${process.env.PUBLIC_URL}/products/details/${data._id}`} className="btn btn-block btn-sm btn-outline-info">View</Link> : null}

          {getLocalAuthData()?.permission?.includes('product_edit') ? <Link to={`${process.env.PUBLIC_URL}/products/edit/${data._id}`} className="btn-action btn-block btn-outline-primary">Edit</Link> : null}
          {(getLocalAuthData()?.permission?.includes('product_edit') && this.props.showProductCloneModal) && <button className="btn btn-block btn-sm btn-outline-info" onClick={() => this.props.showProductCloneModal(this.props.data._id)}>Clone</button>}

          {(getLocalAuthData()?.permission?.includes('product_setting') && this.props.handleProductSettings) && <button className="btn btn-block btn-sm btn-outline-primary" onClick={() => this.props.handleProductSettings(this.props.data._id)}>Product Settings</button>}

          {(getLocalAuthData()?.permission?.includes('product_block_unblock') && this.props.handleProductStatus) && <button className="btn-action btn-block btn-outline-warning" onClick={() => this.props.handleProductStatus(this.props.data._id, this.renderBStatus(this.props.data.status), data?.name, data?.store_info?.store_name)}>{this.renderBtnText(this.props.data.status)}</button>}

          {(getLocalAuthData()?.permission?.includes('product_publish_unpublish') && this.props.handleProductPublishStatus) && <button className="btn btn-block btn-sm btn-outline-success" onClick={async () => this.props.handleProductPublishStatus(this.props.data._id, data?.name, this.renderPublishStatus(this.props.data.publish_status), data?.store_info?.store_name)}>{this.renderPublishBtnText(this.props.data.publish_status)}</button>}

          {(getLocalAuthData()?.permission?.includes('product_approve_remove') && this.props.approveCampaignProduct) && <Button block size={`sm`} onClick={() => this.props.approveCampaignProduct(this.props.data._id)} variant={`outline-danger`}>Approve{this.props.approvedIds.length > 0 && this.props?.approvedIds.map((data) => (<>{data === this.props.data._id && <>d</>}</>))}</Button>}

          {(getLocalAuthData()?.permission?.includes('product_approve_remove') && this.props.removeCampaignProduct) && <><Button block size={`sm`} onClick={() => this.props.removeCampaignProduct(this.props.data._id)} variant={`outline-danger`}>Remove</Button></>}

          {/*{(getLocalAuthData()?.permission?.includes('product_add_to_campaign') && this.props.addProductInCampaign) && <button onClick={() => this.props.addProductInCampaign(this.props.data._id)} className="btn btn-block btn-sm btn-outline-primary">Add to Campaign</button>}*/}

          {getLocalAuthData()?.permission?.includes('product_add_to_campaign') && this.props.addProductInCampaign && ((this.props.registeredProducts?.length > 0 && this.props.registeredProducts.findIndex((elm) => elm._id.toString() === data._id.toString()) === -1) && (this.props.approvedProducts?.length > 0 && this.props.approvedProducts.findIndex((elm) => elm._id.toString() === data._id.toString()) === -1)) && <button onClick={() => this.props.addProductInCampaign(this.props.data._id)} className="btn btn-block btn-sm btn-outline-primary">Add to Campaign</button>}
          {getLocalAuthData()?.permission?.includes('product_add_to_campaign') && this.props.addProductInCampaign && ((this.props.registeredProducts?.length > 0 && this.props.registeredProducts.findIndex((elm) => elm._id.toString() === data._id.toString()) === -1) && this.props.approvedProducts?.length === 0) && <button onClick={() => this.props.addProductInCampaign(this.props.data._id)} className="btn btn-block btn-sm btn-outline-primary">Add to Campaign</button>}
          {getLocalAuthData()?.permission?.includes('product_add_to_campaign') && this.props.addProductInCampaign && this.props.registeredProducts?.length === 0 && this.props.approvedProducts?.length === 0 && <button onClick={() => this.props.addProductInCampaign(this.props.data._id)} className="btn btn-block btn-sm btn-outline-primary">Add to Campaign</button>}
          {getLocalAuthData()?.permission?.includes('product_add_to_campaign') && this.props.addProductInCampaign && this.props.registeredProducts?.length > 0 && this.props.registeredProducts.findIndex((elm) => elm._id.toString() === data._id.toString()) !== -1 && <span className="badge badge-warning">Registered</span>}
          {getLocalAuthData()?.permission?.includes('product_add_to_campaign') && this.props.addProductInCampaign && this.props.approvedProducts?.length > 0 && this.props.approvedProducts.findIndex((elm) => elm._id.toString() === data._id.toString()) !== -1 && <span className="badge badge-success">Approved</span>}

          {/* {(getLocalAuthData()?.permission?.includes('product_delete') && this.props.productDelete && this.props.data?.deleted) &&
            <><Button block size={`sm`} onClick={() => this.props.handleRevertDeletedProduct(this.props.data._id, data?.name, data?.store_info?.store_name)} variant={`outline-danger`}>Revert Delete</Button></>
          }

          { (getLocalAuthData()?.permission?.includes('product_delete') &&  this.props.productDelete && this.props.data?.deleted === false) &&
            <><Button block size={`sm`} onClick={() => this.props.productDelete(this.props.data._id, data?.name, data?.store_info?.store_name)} variant={`outline-danger`}>Delete</Button></>
          }*/}

          {/*{(getLocalAuthData()?.permission?.includes('product_hard_delete') && this.state.userType && this.state.userType === '11') && <button onClick={() => this.props.productHardDelete(data._id, data?.name, data?.store_info?.store_name)} className="btn-action btn-block btn-outline-danger">Hard Delete</button>}*/}
        </div>
      </div>
    );
  }
}

export default Product;
