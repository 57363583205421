import React, {Component} from 'react';
import {
  FiSettings,
  GoDashboard,
  FaBoxes,
  FiFileText,
  FaStore,
  FaUser,
  GoRepoForked,
  FaHandHoldingUsd,
  FiCodepen,
  FiMapPin,
  GiVerticalBanner,
  MdFunctions,
  AiOutlineShop,
  FiPieChart,
  FaDollarSign,
  FiList,
  VscDashboard,
  HiOutlineClipboardList,
  HiClipboardList,
  FiBox,
  RiStoreLine,
  BiWallet,
  RiFlag2Line,
  BiLineChart,
  HiOutlineCog,
  AiOutlineTags,
  FiCodesandbox,
  BiUnlink,
  FiPlus,
  FiTag,
  FiUsers,
  FiUser,
  AiOutlineBank,
  SiBrandfolder,
  FiFlag,
  AiOutlineTransaction,
  AiOutlineOrderedList,
  AiOutlineUserSwitch,
  AiOutlinePercentage,
  BiSearchAlt,
  FiUserPlus,
  BsInboxes,
  RiCoupon3Line,
  FiTruck,
  SiGooglemaps,
  VscSettings,
  FiLayers,
  FiPlusCircle,
  RiUser2Fill,
  GrMoney,
  GiReceiveMoney,
  GiPayMoney,
  HiOutlineSupport,
  FiMessageCircle,
  MdOndemandVideo, CgWebsite, BsJustify, AiOutlineNotification, FcOnlineSupport,
  HiShoppingCart, AiOutlineWallet, RiNewspaperLine
} from "react-icons/all";
import {Link} from "react-router-dom";
import '../assets/scss/sidebar-nav.scss';
import NavItem from "./nav/NavItem";
import ReportGrid from "./ReportGrid";
import {getLocalAuthData, headerData} from "../helper/utils";
import {RiSendPlaneFill} from "@react-icons/all-files/ri/RiSendPlaneFill";
import {AiOutlineProject} from "@react-icons/all-files/ai/AiOutlineProject";
import {GrMapLocation} from "@react-icons/all-files/gr/GrMapLocation";
import {BiCurrentLocation} from "@react-icons/all-files/bi/BiCurrentLocation";

class SidebarNav extends Component {
  state = {
    navbar: [
      {
        id: 1,
        text: 'Dashboard1',
        path: '/',
        icon: <GoDashboard/>,
      },
      {
        id: 2,
        text: 'Products',
        hasSubMenu: true,
        icon: <FaBoxes/>,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: 'Product List',
            path: '/products/list',
          },
          {
            id: 2,
            text: 'Add Product',
            path: '/products/add'
          }
        ]
      },
      {
        id: 4,
        text: 'Orders',
        path: '/orders/parcel/list',
        icon: <FiFileText/>,
      },
      {
        id: 4,
        text: 'Bulk Requests',
        path: '/bulk-requests',
        icon: <FiList/>,
      },
      {
        id: 5,
        text: 'Entrepreneurs',
        hasSubMenu: true,
        icon: <FaStore/>,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: 'Entrepreneur List',
            path: '/entrepreneurs/list'
          },
          {
            id: 2,
            text: 'Add Entrepreneur',
            path: '/entrepreneurs/add'
          }
        ]
      },
      {
        id: 9,
        text: 'Stores',
        path: '/stores',
        icon: <AiOutlineShop/>,
        hasSubMenu: true,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: 'Stores',
            path: '/stores/list'
          },
          {
            id: 3,
            text: 'Create Stores',
            path: '/stores/create'
          }
        ]
      },
      /*{
        id: 13,
        text: 'Homepage Management',
        path: '/homepage-management',
        icon: <FiGrid/>,
      },*/
      {
        id: 6,
        text: 'Customers',
        path: '/customers',
        icon: <FaUser/>,
      },
      {
        id: 6,
        text: 'Assisted Sellers',
        path: '/assisted-sellers',
        icon: <FaUser/>,
      },
      {
        id: 7,
        text: 'Promotions',
        hasSubMenu: true,
        icon: <FaHandHoldingUsd/>,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: 'Seller Voucher',
            path: '/promotions/seller-voucher'
          },
          {
            id: 2,
            text: 'Bundle Offer',
            path: '/promotions/bundle-offer'
          },
          {
            id: 6,
            text: 'Campaigns',
            path: '/promotions/campaign'
          },
          /*{
            id: 3,
            text: 'Free Shipping',
            path: '/promotions/free-shipping'
          },
          {
            id: 4,
            text: 'Reward Point',
            path: '/promotions/reward-point'
          },
          {
            id: 5,
            text: 'Events',
            path: '/promotions/event'
          },
          */
        ]
      },
      {
        id: 34,
        text: 'Commission',
        path: '/commissions',
        icon: <FiSettings/>,
        hasSubMenu: true,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: 'General Settings',
            path: '/commission-settings/general-settings'
          },
          {
            id: 2,
            text: 'Custom Settings',
            path: '/commission-settings/custom-settings'
          },
          {
            id: 2,
            text: 'Add Custom Settings',
            path: '/commission-settings/custom-settings/add'
          },
          /*{
            id: 1,
            text: 'Commission Overview',
            path: '/finance/commission-overview'
          },*/
          {
            id: 2,
            text: 'Buyer Commissions',
            path: '/finance/buyer-commission'
          },
          {
            id: 3,
            text: 'EkShop Commissions',
            path: '/finance/ekshop-commission'
          },
          {
            id: 4,
            text: 'Seller Payment',
            path: '/finance/seller-payment'
          },
          {
            id: 5,
            text: 'Delivery Charge',
            path: '/finance/delivery-charge'
          },
          {
            id: 6,
            text: 'Wallet',
            path: '/finance/wallet'
          },
          {
            id: 35,
            text: 'Commissions',
            path: '/commissions',
            icon: <FaDollarSign/>,
          },
        ]
      },
      {
        id: 50,
        text: 'Disputes',
        path: '/disputes',
        icon: <FaBoxes/>,
        hasSubMenu: false,
        isCollapsed: false,
      },
      {
        id: 16,
        text: 'Services',
        path: '/services',
        icon: <MdFunctions/>,
      },
      {
        id: 30,
        text: 'Reports',
        path: '/reports',
        icon: <FiPieChart/>,
        hasSubMenu: true,
        isCollapsed: true,
        subMenu: [
          {
            id: 5,
            text: 'Summary Report',
            path: '/reports/summary'
          },
          {
            id: 5,
            text: 'Transaction Report',
            path: '/reports/transactions'
          },
          {
            id: 1,
            text: 'Order Report',
            path: '/reports/order'
          },
          {
            id: 2,
            text: 'Seller Report',
            path: '/reports/seller'
          },
          {
            id: 3,
            text: 'Category Report',
            path: '/reports/category'
          },
          {
            id: 4,
            text: 'Commission Report',
            path: '/reports/commission'
          },
          {
            id: 6,
            text: 'Search Report',
            path: '/reports/search'
          },
          {
            id: 7,
            text: 'New User Report',
            path: '/reports/new-user'
          },
          {
            id: 8,
            text: 'Products Report',
            path: '/reports/product'
          },
          {
            id: 9,
            text: 'Customer Report',
            path: '/reports/customers'
          },
          {
            id: 10,
            text: 'Delivery Report',
            path: '/reports/delivery'
          },
          {
            id: 11,
            text: 'Campign Report',
            path: '/reports/campaign'
          },
          {
            id: 12,
            text: 'Store Report',
            path: '/reports/store'
          },
          {
            id: 13,
            text: 'Voucher Report',
            path: '/reports/voucher'
          }
        ]
      },
      {
        id: 10,
        text: 'Categories',
        hasSubMenu: true,
        icon: <GoRepoForked/>,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: 'Category List',
            path: '/categories/list',
          },
          {
            id: 2,
            text: 'Add Category',
            path: '/categories/add'
          },
          {
            id: 2,
            text: 'Product Variants',
            path: '/variants/list'
          }
        ]
      },
      {
        id: 15,
        text: 'Wallet Redeem Package',
        hasSubMenu: true,
        icon: <GoRepoForked/>,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: 'Wallet Redeem Package List',
            path: '/wallet/redeem-package',
          },
          {
            id: 2,
            text: 'Add Wallet Redeem package',
            path: '/wallet/redeem-package/add'
          }
        ]
      },
      /*{
        id: 11,
        text: 'Messages',
        path: '/messages',
        icon: <FiMessageSquare/>,
      },*/
      {
        id: 14,
        text: 'Collections',
        path: '/collections',
        icon: <FiCodepen/>,
      },
      {
        id: 17,
        text: 'Locations',
        hasSubMenu: true,
        icon: <FiMapPin/>,
        isCollapsed: true,
        subMenu: [
          {
            id: 171,
            text: 'Location List',
            path: '/locations'
          },
          {
            id: 172,
            text: 'Add Location',
            path: '/locations/add'
          }
        ]
      },
      {
        id: 18,
        text: 'Shipping Options',
        path: '/shipping-options',
        icon: <GiVerticalBanner/>,
      },
      {
        id: 12,
        text: 'Settings',
        path: '/settings',
        icon: <FiSettings/>,
        hasSubMenu: true,
        isCollapsed: true,
        subMenu: [
          {
            id: 121,
            text: 'Settings',
            path: '/settings',
            icon: <GiVerticalBanner/>,
          },
          {
            id: 122,
            text: 'Slider & Banners',
            path: '/banners',
            icon: <GiVerticalBanner/>,
          },
          {
            id: 123,
            text: 'Brands',
            path: '/brands',
          },
          {
            id: 124,
            text: 'Banks',
            path: '/banks',
            icon: <MdFunctions/>,
          },
          {
            id: 2,
            text: 'Merchant Types',
            path: '/merchant-types'
          }
        ]
      }
    ]
  };

  toggleSubNavItems = async (id) => {
    const navbar = {...this.state.navbar};
    const itemObj = await this.state.navbar.filter(obj => {
      return obj.id === id
    });
    const index = this.state.navbar.indexOf(itemObj[0]);
    navbar[index].isCollapsed = !navbar[index].isCollapsed;
    this.setState(navbar);
  };

  render() {
    return (
      <div className="sidebar-nav">
        <div className="primary-navigation primary-navigation-sidebar">
          <NavItem icon={
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                   height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <path
          d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z"
          fill="#000000" opacity="0.3"/>
        <path
          d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z"
          fill="#000000"/>
    </g>
</svg></span>
          } label={'Dashboard'} to={'/'}/>


          {/*For support team*/}
          {/*<NavItem icon={
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                   height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <path
          d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z"
          fill="#000000" opacity="0.3"/>
        <path
          d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z"
          fill="#000000"/>
    </g>
</svg></span>
          } label={'Support Ticket'} to={'/support-ticket'}/>*/}

          <NavItem icon={
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                   height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <path d="M10.5,5 L19.5,5 C20.3284271,5 21,5.67157288 21,6.5 C21,7.32842712 20.3284271,8 19.5,8 L10.5,8 C9.67157288,8 9,7.32842712 9,6.5 C9,5.67157288 9.67157288,5 10.5,5 Z M10.5,10 L19.5,10 C20.3284271,10 21,10.6715729 21,11.5 C21,12.3284271 20.3284271,13 19.5,13 L10.5,13 C9.67157288,13 9,12.3284271 9,11.5 C9,10.6715729 9.67157288,10 10.5,10 Z M10.5,15 L19.5,15 C20.3284271,15 21,15.6715729 21,16.5 C21,17.3284271 20.3284271,18 19.5,18 L10.5,18 C9.67157288,18 9,17.3284271 9,16.5 C9,15.6715729 9.67157288,15 10.5,15 Z" fill="#000000"/>
        <path d="M5.5,8 C4.67157288,8 4,7.32842712 4,6.5 C4,5.67157288 4.67157288,5 5.5,5 C6.32842712,5 7,5.67157288 7,6.5 C7,7.32842712 6.32842712,8 5.5,8 Z M5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 C6.32842712,10 7,10.6715729 7,11.5 C7,12.3284271 6.32842712,13 5.5,13 Z M5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 C6.32842712,15 7,15.6715729 7,16.5 C7,17.3284271 6.32842712,18 5.5,18 Z" fill="#000000" opacity="0.3"/>
    </g>
</svg></span>}
                   title={'Orders'}
                   label={'Orders'}>
            <ul className={'nav-item-list'}>

              { getLocalAuthData()?.permission?.includes('menu_orders_list') ?
              <li><Link to={'/orders/parcel/list'}><span className="nav-icon"><HiOutlineClipboardList/></span> <span className="nav-text">Order List</span></Link>
              </li> :null}

              { getLocalAuthData()?.permission?.includes('menu_orders_product_list') ?
              <li><Link to={'/orders/product/list'}><span className="nav-icon"><HiClipboardList/></span> <span className="nav-text">Order Product List</span></Link>
              </li>:null}

              { getLocalAuthData()?.permission?.includes('menu_orders_product_list') ?
                  <li><Link to={'/orders/customer-orderplace'}><span className="nav-icon"><HiShoppingCart/></span> <span className="nav-text">Customer order placement</span></Link>
                  </li>:null}

              { getLocalAuthData()?.permission?.includes('menu_Order_Dispute') ?
              <li><Link to={'/disputes'}><span className="nav-icon"><BiUnlink/></span> <span className="nav-text">Dispute</span></Link>
              </li>:null}
              { getLocalAuthData()?.permission?.includes('menu_Order_Bulk_Request') ?
              <li><Link to={'/bulk-requests'}><span className="nav-icon"><FiBox/></span> <span
                className="nav-text">Bulk Request</span></Link></li>:null}


                <li><Link to={'/orders/hoichoi-subscription'}><span className="nav-icon"><HiOutlineClipboardList/></span> <span className="nav-text">Services</span></Link>
                </li>


            </ul>
          </NavItem>

          <NavItem icon={
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                   height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <path
          d="M4,9.67471899 L10.880262,13.6470401 C10.9543486,13.689814 11.0320333,13.7207107 11.1111111,13.740321 L11.1111111,21.4444444 L4.49070127,17.526473 C4.18655139,17.3464765 4,17.0193034 4,16.6658832 L4,9.67471899 Z M20,9.56911707 L20,16.6658832 C20,17.0193034 19.8134486,17.3464765 19.5092987,17.526473 L12.8888889,21.4444444 L12.8888889,13.6728275 C12.9050191,13.6647696 12.9210067,13.6561758 12.9368301,13.6470401 L20,9.56911707 Z"
          fill="#000000"/>
        <path
          d="M4.21611835,7.74669402 C4.30015839,7.64056877 4.40623188,7.55087574 4.5299008,7.48500698 L11.5299008,3.75665466 C11.8237589,3.60013944 12.1762411,3.60013944 12.4700992,3.75665466 L19.4700992,7.48500698 C19.5654307,7.53578262 19.6503066,7.60071528 19.7226939,7.67641889 L12.0479413,12.1074394 C11.9974761,12.1365754 11.9509488,12.1699127 11.9085461,12.2067543 C11.8661433,12.1699127 11.819616,12.1365754 11.7691509,12.1074394 L4.21611835,7.74669402 Z"
          fill="#000000" opacity="0.3"/>
    </g>
</svg></span>
          } title={'Products'} label={'Products'}>
            <ul className={'nav-item-list'}>


              { getLocalAuthData()?.permission?.includes("menu_product_list") ?
                  <li><Link to={'/products/list'}>
                    <span className="nav-icon"><HiOutlineClipboardList/>
                    </span>
                    <span className="nav-text">Product List</span>
                  </Link>
              </li> : null }

              { getLocalAuthData()?.permission?.includes('menu_product_add') ? <li><Link to={'/products/add'}><span className="nav-icon"><FiPlus/></span> <span
                className="nav-text">Add Product</span></Link></li> : null }

              <li><Link to={'/district-branding'}><span className="nav-icon"><BiCurrentLocation/></span> <span className="nav-text">District Branding</span></Link>
              </li>

              { getLocalAuthData().permission.includes('menu_product_categories') ?
              <li><Link to={'/categories/list'}><span className="nav-icon"><FiTag/></span> <span className="nav-text">Categories</span></Link>
              </li> : null }
              { getLocalAuthData()?.permission?.includes('menu_product_varients') ?
              <li><Link to={'/variants/list'}><span className="nav-icon"><FiTag/></span> <span className="nav-text">Product Varients</span></Link>
              </li> : null}
              { getLocalAuthData()?.permission?.includes('menu_product_collections') ?
              <li><Link to={'/collections'}><span className="nav-icon"><FiBox/></span> <span className="nav-text">Collections</span></Link>
              </li> : null }
              { getLocalAuthData()?.permission?.includes('menu_product_list') ?
              <li><Link to={'/complains/list'}><span className="nav-icon"><FiBox/></span> <span className="nav-text">Product Complain</span></Link>
              </li> : null }
            </ul>
          </NavItem>

          <NavItem icon={
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                   height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"/>
                    <path
                      d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                      fill="#000000" fillRule="nonzero" opacity="0.3"/>
                    <path
                      d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                      fill="#000000" fillRule="nonzero"/>
                </g>
            </svg>
            </span>
          } label={'Users'} title={'Users'}>
            <ul className={'nav-item-list'}>
              { getLocalAuthData()?.permission?.includes('menu_users_customers') ?
              <li><Link to={'/customers'}><span className="nav-icon"><FiUsers/></span> <span
                className="nav-text">Customers</span></Link></li> : null}
              { getLocalAuthData()?.permission?.includes('menu_users_entrepreneurs') ?
              <li><Link to={'/entrepreneurs/list'}><span className="nav-icon"><FiUsers/></span> <span
                className="nav-text">Entrepreneurs</span></Link></li>:null}
              { getLocalAuthData()?.permission?.includes('menu_users_assisted_sellers') ?
              <li><Link to={'/assisted-sellers'}><span className="nav-icon"><FiUsers/></span> <span
                className="nav-text">Assisted Sellers</span></Link></li> :  null}
              { getLocalAuthData()?.permission?.includes('menu_staff_management') ?
              <li><Link to={'/staff/list'}><span className="nav-icon"><FiUsers/></span> <span
                  className="nav-text">Staff Management</span></Link></li> : null}
              <li><Link to={'/agent'}><span className="nav-icon"><FiUsers/></span> <span
                  className="nav-text">Agent Management</span></Link></li>
            </ul>
          </NavItem>

          <NavItem icon={
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                   height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path
                      d="M3.95709826,8.41510662 L11.47855,3.81866389 C11.7986624,3.62303967 12.2013376,3.62303967 12.52145,3.81866389 L20.0429,8.41510557 C20.6374094,8.77841684 21,9.42493654 21,10.1216692 L21,19.0000642 C21,20.1046337 20.1045695,21.0000642 19,21.0000642 L4.99998155,21.0000673 C3.89541205,21.0000673 2.99998155,20.1046368 2.99998155,19.0000673 L2.99999828,10.1216672 C2.99999935,9.42493561 3.36258984,8.77841732 3.95709826,8.41510662 Z M10,13 C9.44771525,13 9,13.4477153 9,14 L9,17 C9,17.5522847 9.44771525,18 10,18 L14,18 C14.5522847,18 15,17.5522847 15,17 L15,14 C15,13.4477153 14.5522847,13 14,13 L10,13 Z"
                      fill="#000000"/>
                </g>
              </svg>
            </span>
          } label={'Stores'} title={'Store'}>
            <ul className={'nav-item-list'}>
              { getLocalAuthData()?.permission?.includes('menu_store_list') ?
              <li><Link to={'/stores/list'}><span className="nav-icon"><HiOutlineClipboardList/></span> <span className="nav-text">Store List</span></Link>
              </li> : null}
              { getLocalAuthData()?.permission?.includes('menu_add_store') ?
              <li><Link to={'/stores/create'}><span className="nav-icon"><FiPlus/></span> <span className="nav-text">Add Store</span></Link>
              </li> :null }

              { getLocalAuthData()?.permission?.includes('menu_store_list') ?
                  <li><Link to={'/stores/live'}><span className="nav-icon"><HiOutlineClipboardList/></span> <span className="nav-text"> Store Live</span></Link>
                  </li> : null}
            </ul>
          </NavItem>

          <NavItem icon={
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                   height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <rect x="0" y="0" width="24" height="24"/>
                      <path
                        d="M1,12 L1,14 L6,14 L6,12 L1,12 Z M0,10 L20,10 C20.5522847,10 21,10.4477153 21,11 L21,15 C21,15.5522847 20.5522847,16 20,16 L0,16 C-0.55228475,16 -1,15.5522847 -1,15 L-1,11 C-1,10.4477153 -0.55228475,10 0,10 Z"
                        fill="#000000" fillRule="nonzero"
                        transform="translate(10.000000, 13.000000) rotate(-225.000000) translate(-10.000000, -13.000000) "/>
                      <path
                        d="M17.5,12 L18.5,12 C18.7761424,12 19,12.2238576 19,12.5 L19,13.5 C19,13.7761424 18.7761424,14 18.5,14 L17.5,14 C17.2238576,14 17,13.7761424 17,13.5 L17,12.5 C17,12.2238576 17.2238576,12 17.5,12 Z M20.5,9 L21.5,9 C21.7761424,9 22,9.22385763 22,9.5 L22,10.5 C22,10.7761424 21.7761424,11 21.5,11 L20.5,11 C20.2238576,11 20,10.7761424 20,10.5 L20,9.5 C20,9.22385763 20.2238576,9 20.5,9 Z M21.5,13 L22.5,13 C22.7761424,13 23,13.2238576 23,13.5 L23,14.5 C23,14.7761424 22.7761424,15 22.5,15 L21.5,15 C21.2238576,15 21,14.7761424 21,14.5 L21,13.5 C21,13.2238576 21.2238576,13 21.5,13 Z"
                        fill="#000000" opacity="0.3"/>
                  </g>
              </svg></span>
          } label={'Promotions'} title={'Promotions'}>
            <ul className={'nav-item-list'}>
              { getLocalAuthData()?.permission?.includes('menu_seller_voucher') ?
              <li>
                <Link to={'/promotions/seller-voucher'}><span className="nav-icon"><RiCoupon3Line/></span> <span className="nav-text">Seller Voucher</span></Link>
              </li> : null }

              { getLocalAuthData()?.permission?.includes('menu_bundle_offers') ?
              <li>
                <Link to={'/promotions/bundle-offer'}><span className="nav-icon"><BsInboxes/></span> <span
                  className="nav-text">Bundle Offers</span></Link>
              </li> : null}
            </ul>
          </NavItem>

          <NavItem to={'/promotions/campaign'} icon={
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                   height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <circle fill="#000000" opacity="0.3" cx="12" cy="9" r="8"/>
        <path
          d="M14.5297296,11 L9.46184488,11 L11.9758349,17.4645458 L14.5297296,11 Z M10.5679953,19.3624463 L6.53815512,9 L17.4702704,9 L13.3744964,19.3674279 L11.9759405,18.814912 L10.5679953,19.3624463 Z"
          fill="#000000" fillRule="nonzero" opacity="0.3"/>
        <path d="M10,22 L14,22 L14,22 C14,23.1045695 13.1045695,24 12,24 L12,24 C10.8954305,24 10,23.1045695 10,22 Z"
              fill="#000000" opacity="0.3"/>
        <path
          d="M9,20 C8.44771525,20 8,19.5522847 8,19 C8,18.4477153 8.44771525,18 9,18 C8.44771525,18 8,17.5522847 8,17 C8,16.4477153 8.44771525,16 9,16 L15,16 C15.5522847,16 16,16.4477153 16,17 C16,17.5522847 15.5522847,18 15,18 C15.5522847,18 16,18.4477153 16,19 C16,19.5522847 15.5522847,20 15,20 C15.5522847,20 16,20.4477153 16,21 C16,21.5522847 15.5522847,22 15,22 L9,22 C8.44771525,22 8,21.5522847 8,21 C8,20.4477153 8.44771525,20 9,20 Z"
          fill="#000000"/>
    </g>
</svg></span>
          } label={'Campaigns'}/>

          <NavItem icon={
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                   height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path
                      d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z"
                      fill="#000000" opacity="0.3"
                      transform="translate(11.500000, 12.000000) rotate(-345.000000) translate(-11.500000, -12.000000) "/>
                    <path
                      d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z M11.5,14 C12.6045695,14 13.5,13.1045695 13.5,12 C13.5,10.8954305 12.6045695,10 11.5,10 C10.3954305,10 9.5,10.8954305 9.5,12 C9.5,13.1045695 10.3954305,14 11.5,14 Z"
                      fill="#000000"/>
                </g>
            </svg>
            </span>
          } label={'Commission'} title={'Commission'}>
            <ul className={'nav-item-list'}>
              { getLocalAuthData()?.permission?.includes('menu_commission_general_settings') ?
              <li>
                <Link to={'/commission-settings/general-settings'}><span className="nav-icon"><RiCoupon3Line/></span> <span className="nav-text">General Settings</span></Link>
              </li> :null}
              { getLocalAuthData()?.permission?.includes('menu_commission_custom_settings') ?
              <li>
                <Link to={'/commission-settings/custom-settings'}><span className="nav-icon"><BsInboxes/></span> <span
                  className="nav-text">Custom Settings</span></Link>
              </li> :null}
              { getLocalAuthData()?.permission?.includes('menu_commission_add_custom_settings') ?
              <li>
                <Link to={'/commission-settings/custom-settings/add'}><span className="nav-icon"><FiPlusCircle/></span> <span
                  className="nav-text">Add Custom Settings</span></Link>
              </li>:null}
              { getLocalAuthData()?.permission?.includes('menu_buyer_comissions') ?
              <li>
                <Link to={'/finance/buyer-commission'}><span className="nav-icon"><RiUser2Fill/></span> <span
                  className="nav-text">Buyer Commissions</span></Link>
              </li>:null}
              { getLocalAuthData()?.permission?.includes('menu_ekshop_comissions') ?
              <li>
                <Link to={'/finance/ekshop-commission'}><span className="nav-icon"><GiReceiveMoney/></span> <span
                  className="nav-text">EkShop Commissions</span></Link>
              </li> :null}
              { getLocalAuthData()?.permission?.includes('menu_ekshop_comissions') ?
              <li>
                <Link to={'/finance/seller-payment'}><span className="nav-icon"><AiOutlineWallet /></span> <span
                  className="nav-text">Seller Payment</span></Link>
              </li> :null}
              { getLocalAuthData()?.permission?.includes('menu_ekshop_comissions') ?
              <li>
                <Link to={'/finance/delivery-charge'}><span className="nav-icon"><AiOutlineWallet /></span> <span
                  className="nav-text">Delivery Charge</span></Link>
              </li> :null}
              { getLocalAuthData()?.permission?.includes('menu_ekshop_comissions') ?
              <li>
                <Link to={'/finance/wallet'}><span className="nav-icon"><AiOutlineWallet /></span> <span
                  className="nav-text">Wallet</span></Link>
              </li> :null}
              { getLocalAuthData()?.permission?.includes('menu_comissions') ?
              <li>
                <Link to={'commissions'}><span className="nav-icon"><GiPayMoney/></span> <span
                  className="nav-text">Commissions</span></Link>
              </li> :null}
            </ul>
          </NavItem>

          <NavItem icon={
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                   height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <circle fill="#000000" cx="5" cy="12" r="2"/>
                    <circle fill="#000000" cx="12" cy="12" r="2"/>
                    <circle fill="#000000" cx="19" cy="12" r="2"/>
                </g>
              </svg>
            </span>
          } label={'More'} title={'More'}>
            <ul className={'nav-item-list'}>
                { getLocalAuthData()?.permission?.includes('menu_more_wallet') ? <li>
                <Link to={'/wallet/redeem-package'}><span className="nav-icon"><RiCoupon3Line/></span> <span className="nav-text">Wallet</span></Link>
              </li> : null}
                { getLocalAuthData()?.permission?.includes('menu_more_services') ? <li>
                <Link to={'/services'}>
                  <span className="nav-icon"><FiSettings/></span>
                  <span className="nav-text">Services</span>
                </Link>
              </li> : null}
                { getLocalAuthData()?.permission?.includes('menu_product_collections') ? <li>
                <Link to={'/collections'}>
                  <span className="nav-icon"><FiCodepen/></span>
                  <span className="nav-text">Collections</span>
                </Link>
              </li> : null}

              <li>
                <Link to={'/support-ticket'}>
                  <span className="nav-icon"><HiOutlineSupport/></span>
                  <span className="nav-text">Support Ticket</span>
                </Link>
              </li>

              <li>
                <Link to={'/support-topic'}>
                  <span className="nav-icon"><FcOnlineSupport/></span>
                  <span className="nav-text">Support Topic</span>
                </Link>
              </li>
              <li>
                <Link to={'/support-faq'}>
                  <span className="nav-icon"><HiOutlineSupport/></span>
                  <span className="nav-text">Support FAQ</span>
                </Link>
              </li>

              <li>
                <Link to={'/messages'}>
                  <span className="nav-icon"><FiMessageCircle/></span>
                  <span className="nav-text">Messages</span>
                </Link>
              </li>

              <li>
                <Link to={'/partner-onboard/list'}>
                  <span className="nav-icon"><CgWebsite/></span>
                  <span className="nav-text">Partner Onboard</span>
                </Link>
              </li>

              <li>
                <Link to={'/stores/live'}>
                  <span className="nav-icon"><MdOndemandVideo/></span>
                  <span className="nav-text">Live</span>
                </Link>
              </li>

              <li>
                <Link to={'/notice/list'}>
                  <span className="nav-icon"><AiOutlineNotification/></span>
                  <span className="nav-text">Notices</span>
                </Link>
              </li>

              <li>
                <Link to={'/notification/list'}>
                  <span className="nav-icon"><RiSendPlaneFill/></span>
                  <span className="nav-text">Notification</span>
                </Link>
              </li>

              <li>
                <Link to={'/news'}>
                  <span className="nav-icon"><RiNewspaperLine/></span>
                  <span className="nav-text">News</span>
                </Link>
              </li>


            </ul>
          </NavItem>
        </div>

        <div className="primary-navigation">
          <NavItem icon={
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                   height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <rect fill="#000000" opacity="0.3" x="17" y="4" width="3" height="13" rx="1.5"/>
                  <rect fill="#000000" opacity="0.3" x="12" y="9" width="3" height="8" rx="1.5"/>
                  <path
                    d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z"
                    fill="#000000" fillRule="nonzero"/>
                  <rect fill="#000000" opacity="0.3" x="7" y="11" width="3" height="6" rx="1.5"/>
                </g>
              </svg>
            </span>
          } title={'Reports'} label={'Reports'}>

          <ReportGrid/>

          </NavItem>
          <NavItem icon={
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                   height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <rect x="0" y="0" width="24" height="24"/>
                      <path
                        d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z"
                        fill="#000000"/>
                      <path
                        d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z"
                        fill="#000000" opacity="0.3"/>
                  </g>
              </svg>
            </span>
          } title={'Settings'} label={'Settings'}>
            <ul className={'nav-item-list'}>
              <li>
                {getLocalAuthData()?.permission?.includes('menu_settings_homepage_management') ?
                <Link to={'/homepage-management'}>
                  <span className="nav-icon"><FiLayers/></span>
                  <span className="nav-text">Homepage Management</span>
                </Link>
                    : null}
              </li>

              <li>
                    <Link to={'/menu-management'}>
                      <span className="nav-icon"><BsJustify/></span>
                      <span className="nav-text">Menu Management</span>
                    </Link>

              </li>


              <li>
                {getLocalAuthData()?.permission?.includes('menu_settings_shipping-charges') ?
                <Link to={'/shipping-options'}>
                  <span className="nav-icon"><FiTruck/></span>
                  <span className="nav-text">Shipping Charges</span>
                </Link> : null}
              </li>
              <li>
                {getLocalAuthData()?.permission?.includes('menu_settings_locations') ?
                <Link to={'/locations'}><span className="nav-icon"><SiGooglemaps/></span> <span
                  className="nav-text">Locations</span></Link> : null}
              </li>
              <li>
                {getLocalAuthData()?.permission?.includes('menu_settings_slider_banners') ?
                <Link to={'/banners'}><span className="nav-icon"><FiFlag/></span> <span
                  className="nav-text">Slider &amp; Banner</span></Link>
                    :null}
              </li>
              <li>
                {getLocalAuthData()?.permission?.includes('menu_settings_slider_banners') ?
                <Link to={'/onboarding-screens'}><span className="nav-icon"><FiFlag/></span> <span
                  className="nav-text">App Onboarding Screen</span></Link>
                    :null}
              </li>
              <li>
                {getLocalAuthData()?.permission?.includes('menu_settings_Brands') ?
                <Link to={'/brands'}><span className="nav-icon"><SiBrandfolder/></span> <span
                  className="nav-text">Brands</span></Link> : null}
              </li>
              <li>
                {getLocalAuthData()?.permission?.includes('menu_settings_banks') ?
                <Link to={'/banks'}><span className="nav-icon"><AiOutlineBank/></span> <span
                  className="nav-text">Banks</span></Link> : null}
              </li>
              {getLocalAuthData()?.permission?.includes('menu_settings_merchant_types') ?
              <li>

                <Link to={'/merchant-types'}><span className="nav-icon"><FiUser/></span> <span
                    className="nav-text">Merchant Types</span></Link>
              </li>:null }
              {getLocalAuthData()?.permission?.includes('menu_settings_acl_roles') ?
              <li>

                    <Link to={'/acl-roles'}><span className="nav-icon"><FiUser/></span> <span
                        className="nav-text">User Roles</span></Link>

              </li>
               :null}
              {getLocalAuthData()?.permission?.includes('menu_settings_action_management') ?
              <li>

                <Link to={'/acl-actions'}><span className="nav-icon"><FiUser/></span> <span
                    className="nav-text">Role Permissions</span></Link>

              </li> :null}
              {getLocalAuthData()?.permission?.includes('menu_settings_settings') ?
              <li>

                    <Link to={'/project-settings'}>
                      <span className="nav-icon"><AiOutlineProject/></span>
                      <span className="nav-text">Project Settings</span>
                    </Link>
              </li>: null}

              {/*{getLocalAuthData()?.permission?.includes('menu_settings_settings') ?
              <li>
                    <Link to={'/settings'}>
                      <span className="nav-icon"><VscSettings/></span>
                      <span className="nav-text">Settings</span>
                    </Link>
              </li>: null}*/}
            </ul>
          </NavItem>
        </div>
      </div>
    );
  }
}

export default SidebarNav;
