import React, {Component} from "react"
import "../../assets/scss/business-block-card.scss"
import "../../assets/scss/dashboard.scss"
import Chart from "chart.js"

class MerchantReport extends Component {
    salesAndOrderChartRef = React.createRef()


    componentDidMount() {

    }

    render() {

        console.log("~~~~~~~~~~~~~~~~~~~~~", this.props)

        return (
            <>


                <div className="card-block">
                    <div className="block-header">
                        <h4 className="block-title">Merchant Report</h4>
                    </div>

                    <div className="block-body">
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th>Type</th>
                                <th>Total</th>
                                <th>Unpaid</th>
                                <th>Partial</th>
                                <th>Paid Payment</th>
                                <th>Cancelled Payment</th>
                                <th>Failed Payment</th>
                                <th>Refund Payment</th>
                                <th>Deposit Review</th>
                                <th>Deposit Declined</th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr>
                                <td>11</td>
                                <td>11</td>
                                <td>11</td>
                                <td>11</td>
                                <td>11</td>
                                <td>11</td>
                                <td>11</td>
                                <td>11</td>
                                <td>11</td>

                            </tr>
                           {/* {this.props.test.merchant_types_reports && this.props.test.merchant_types_reports.map((data, i) => {
                                return (
                                    <tr>
                                        <td>{data?.merchant_type_info?.type_name}</td>
                                        <td>{data?.partially_cod+data?.paid_cod+data?.cancelled_cod+data?.failed_cod
                                        +data?.refund_cod+data?.bank_deposit_review_cod+data?.bank_deposit_declined_cod

                                        }</td>
                                        <td>{data?.unpaid_cod}</td>
                                        <td>{data?.partially_cod}</td>
                                        <td>{data?.paid_cod}</td>
                                        <td>{data?.cancelled_cod}</td>
                                        <td>{data?.failed_cod}</td>
                                        <td>{data?.refund_cod}</td>
                                        <td>{data?.bank_deposit_review_cod}</td>
                                        <td>{data?.bank_deposit_declined_cod}</td>
                                    </tr>
                                )
                            })}*/}
                            </tbody>
                        </table>
                    </div>
                </div>



            </>

        )
    }
}

export default MerchantReport
