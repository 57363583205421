import axios from "axios";
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData} from "../helper/utils";

export const getVariantList = () => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/attributeList`, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": getDeviceType()
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data.items)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        //console.log('err: ', err);
        reject('Something went wrong')
      })
  })
}

export const changeStatus = (data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.patch(`${API_BASE_URL}/changeAttributeStatus`, data, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": getDeviceType()
      }
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        //console.log('err: ', err)
        reject('Something went wrong!!')
      })
  })
}

export const addVariant = (data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.post(`${API_BASE_URL}/addAttribute`, data, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": getDeviceType()
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        //console.log('Variant Error: ', err);
        reject(err.message)
      })
  })
}

export const deleteVariant = (id, isPermanent = false) => {
  const authData = getLocalAuthData();
  let url = '';
  if (isPermanent) {
    url = `${API_BASE_URL}/attribute/${id}/permanent`
  } else {
    url = `${API_BASE_URL}/attribute/${id}`;
  }

  return new Promise((resolve, reject) => {
    axios.delete(url, {
      headers: {
        "x-auth-token": authData.token,
        "x-api-client": getDeviceType()
      }
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        //console.log('Error: ', err);
        reject('Something went wrong')
      })
  })
}
