import React, {Component} from 'react';
import App from "../../App";
import {Button} from "react-bootstrap";
import {FaFileExport, FiPlus} from "react-icons/all";
import {Link} from "react-router-dom";
import {deleteBundle, getBundleOffer, changeBundleStatus} from "../../services/promotionServices";
import {toast} from "react-toastify";
import moment from "moment";
import Pagination from 'react-js-pagination';
import {confirmAlert} from "react-confirm-alert";
import {getLocalAuthData} from "../../helper/utils";
import axios from "axios";
import {API_BASE_URL} from "../../helper/env";
import {activityLogPostData} from "../../services/commonServices";

class BundleOfferList extends Component {
  state = {
    status: '',
    promotion_type: '',
    promotions: [],
    showAdvanceSearchOptions: false,
    entrepreneurType: [],
    productList: [],
    queryData: [],
    activePage: 1,
    isLoading: true,
    pagination: {},
    limit: 10,
    locations: [],
    businessChildLocations: [],
  }

  componentDidMount() {
    getBundleOffer(this.state.limit)
      .then(res => {
        this.setState({promotions: res.items, pagination: res.pagination}, () => {
          console.log('Pagination: ', this.state.promotions)
        })
      })
      .catch(errMsg => {
        toast.error(errMsg)
      })
  }

  handleBundleDelete = (id,name) => {

    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to delete this Bundle offer?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteBundle(id)
            .then(msg => {
              toast.success(msg);
              activityLogPostData('was delete bundle offer','/promotions/bundle-offer/edit/'+id, name)
              getBundleOffer()
                .then(res => {
                  this.setState({promotions: res.items})
                })
            })
            .catch(errMsg => {
              toast.error(errMsg)
            })
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }

  handleBundleStatusChange = (id, status ,name) => {


    if (status == 1) {
      var data = {"status": "2"};
    } else {
      var data = {"status": "1"};
    }

    confirmAlert({
      title: 'Status Update',
      message: 'Are you sure to change this Bundle offer status?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => changeBundleStatus(id, data)
            .then(msg => {
              toast.success("Status Updated");
              activityLogPostData('was change this bundle status','/promotions/bundle-offer/edit/'+id, name)
              getBundleOffer()
                .then(res => {
                  this.setState({promotions: res.items})
                })
            })
            .catch(errMsg => {
              toast.error(errMsg)
            })
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  }

  getQueryData = async () => {
    const authData = getLocalAuthData();
    let queryData = "";
    await Object.entries(this.state.queryData).map(([key, val]) => {
        queryData = queryData.concat(key + "=" + val + "&");
      }
    )


    this.setState({isLoading: true});

    axios.get(`${API_BASE_URL}/bundle-offer?limit=${this.state.limit}&${queryData}`)
      .then(res => {
        this.setState({promotions: res.data.items, pagination: res.data.pagination}, () => {
          console.log('Pagination: ', this.state.promotions)
        })
      }).catch(errMsg => {
      toast.error(errMsg)
    })
  }

  handlePageChange = async (pageNumber) => {
    await this.setState({queryData: {...this.state.queryData, page: pageNumber}, activePage: pageNumber});
    this.getQueryData();
  }

  handleOnChangePromotionType = (e) => {
    const formData = {...this.state.formData}
    formData.apply_to = e.target.value
    this.setState({
      formData,
    })
    if (e.target.value === '6') {
      this.setState({isApplyForCat: true})
    } else {
      this.setState({isApplyForCat: false})
    }
  }

  handleOnChangeStatus = (e) => {
    let formData = this.state.status

    formData = e.target.value
    this.setState({
      formData,
    })
  }

  handleSubmitForm = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    const promotion_type = e.target.elements.promotion_type.value;
    const status = e.target.elements.status.value;

    getBundleOffer(this.state.limit, this.state.activePage, promotion_type, status)
      .then(res => {
        this.setState({promotions: res.items, pagination: res.pagination}, () => {
          console.log('Pagination: ', this.state.promotions)
        })
      })
      .catch(errMsg => {
        toast.error(errMsg)
      })
  }

  render() {
    const promotions = this.state.promotions;
    var promotionType = [];
    promotionType[1] = "Quantity";
    promotionType[2] = "Buy X Get Y";
    promotionType[3] = "Free Gift";
    promotionType[4] = "Bundle";

    var applicableType = [];
    applicableType[1] = "All";
    applicableType[2] = "Specific Stores";
    applicableType[3] = "Ekshop Mall";
    applicableType[3] = "Local Merchant";
    applicableType[3] = "Specific Categories";

    var statusType = [];
    statusType[2] = "Activate";
    statusType[1] = "Block";

    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Bundle Offer</h2>
          <div className="ml-auto">
          {/*  <Button size={"sm"} variant={"default"} className="mb-0 mr-2"><FaFileExport/> Export</Button>*/}
            <Link to={`${process.env.PUBLIC_URL}/promotions/bundle-offer/add`}
                  className="btn btn-sm btn-secondary"><FiPlus/> Add Bundle Offer</Link>
          </div>
        </div>

        <div className="main-content">
          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Bundle Offer List</h4>
            </div>

            <div className="block-body">

              { getLocalAuthData()?.permission?.includes('bundle_offer_search') ?
                  <form className="form-inline mb-3" onSubmit={this.handleSubmitForm}>
                    {/*    <div className="form-group mr-3">
                  <label htmlFor="startTime" className="sr-only">Duration</label>
                  <input type="search" id="startTime" className='form-control form-control-sm' placeholder="Start Time"/>
                </div>*/}

                    <div className="form-group mr-3">
                      <label htmlFor="brandName" className="sr-only">Promotion Type</label>
                      <select id="promotion_type" onChange={this.handleOnChangePromotionType} name='promotion_type'
                              className='form-control form-control-sm'>
                        <option value="">Select Promotion Type</option>
                        {/*<option value="1">Quantity</option>*/}
                        <option value="2">Buy X Get Y</option>
                        <option value="3">Free Gift</option>
                        <option value="4">Bundle</option>
                      </select>
                    </div>

                    <div className="form-group mr-3">
                      <label htmlFor="brandStatus" className="sr-only">Status</label>
                      <select id="status" onChange={this.handleOnChangeStatus} name='status'
                              className='form-control form-control-sm'>
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="2">Blocked</option>
                      </select>
                    </div>

                    <Button size="sm" type={`submit`} variant="secondary">Search</Button>
                  </form>
                  : null}

              <table className="table table-striped">
                <thead>
                <tr>
                  <th style={{width: '1%'}}>#</th>
                  <th>Promotion Name</th>
                  <th>Promotion Type</th>
                  <th>Duration</th>
                  <th>Level</th>
                  <th style={{width: '120px'}}/>
                </tr>
                </thead>
                <tbody>
                {( getLocalAuthData()?.permission?.includes('bundle_offer_list') && promotions?.length > 0) ?
                  promotions.map((promo, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{promo.offer_name}</td>
                      <td>{promotionType[promo.promotion_type]}</td>
                      <td>
                        <span className="start-date">{moment(promo.start_time).format('ll')}</span><br/>
                        <span className="end-date">{moment(promo.end_time).format('ll')}</span>
                      </td>
                      <td>{applicableType[promo.apply_to]}</td>
                      <td className="nowrap">

                        { getLocalAuthData()?.permission?.includes('bundle_offer_edit') ?
                            <Link to={`${process.env.PUBLIC_URL}/promotions/bundle-offer/edit/${promo._id}`}
                                  className="btn btn-sm btn-link text-primary"> Edit</Link>
                            : null}

                        { getLocalAuthData()?.permission?.includes('bundle_offer_active_block') ?
                        <button className="btn btn-sm btn-link text-warning"
                                onClick={() => this.handleBundleStatusChange(promo._id, promo.status,promo.offer_name)}>
                          {statusType[promo.status]}
                        </button> : null}
                        { getLocalAuthData()?.permission?.includes('bundle_offer_delete') ?
                        <button className="btn btn-sm btn-link text-danger"
                                onClick={() => this.handleBundleDelete(promo._id,promo.offer_name)}>Delete
                        </button> : null}
                      </td>
                    </tr>
                  ))
                  :
                  <tr>
                    {getLocalAuthData()?.permission?.includes('bundle_offer_add_bundle_offer') ?
                        <td colSpan={7} className="text-bold text-center">
                          No Bundle Offer added yet. Please <Link
                            to={`${process.env.PUBLIC_URL}/promotions/bundle-offer/add`}>Add Bundle Offer</Link>
                        </td>
                        :null }

                  </tr>
                }

                </tbody>
              </table>


              <div className="mt-3">
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pagination?.limit}
                  totalItemsCount={this.state.pagination?.totalDocs}
                  pageRangeDisplayed={10}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>

            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default BundleOfferList;
